import { Row } from 'antd';
import React from 'react'
import styled from 'styled-components';
import UI from '../../shared/Components/UI/js';
import TouchableOpacity, { mem, StyleSheet, Text, View } from '../../shared/custom-react-native';
import ServiceDoctorsApi from "../../api_services/doctors";

const PatientRescheduleAppointmentAcceptedModal = ({ _this, appointment }) => {
    const doctor = JSON.parse(mem.get('onboard_data'))

    const closeAccept = () => {
      ServiceDoctorsApi.rescheduleDoctorAppointmentRead(appointment._id, {
        userType: "practitioner",
        readType: "accept"
    }).then( res => {
        if (res.status === 200) {
          _this.hide_loading();
          _this.hide_modal();
          _this.load_consultations();
        } else {
          _this.hide_loading();
          _this.hide_modal()
        }
      });
    }

  return (
    <div className='flex items-center justify-center'>
      <TableBox>
        <View
          style={{ ...styles.md_header, backgroundColor: UI.colors.secondary }}
          >
          <TouchableOpacity
              onClick={() => {
                closeAccept()
              }}
              style={styles.md_close}
          >
              <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="CLOSE" />
          </TouchableOpacity>

          <View style={{ ...styles.md_header_text_container, marginLeft: 50 }}>
              <Text style={styles.md_header_text}>RESCHEDULE APPOINTMENT</Text>
          </View>
        </View>

        <div style={{ width: "100%", maxHeight: 560, fontSize:18 }}> 
          <div className='flex flex-col px-10 pt-10 gap-5'>
            <span className='flex-1' style={{color: "#58595B"}}>
              Hi “{doctor?.firstName + " " + doctor?.lastName}”, Your Patient “{UI.getPatientName(appointment)}” accepted your change schedule request.
            </span>
          </div>

          <div className='flex flex-col px-10 pt-10 gap-2'>
            <strong className='flex-1' style={{color: "black"}}>Appointment Date From:</strong>
            <div className='flex'>
                <strong className='flex-1' style={{color: "black"}}>Date:</strong>
                <span className='flex-1' style={{color: "#58595B"}}>{UI.getTimezone({date: appointment.reschedule?.practitioner?.previousStarDate, format: "LL"})}</span>
            </div>
            <div className='flex'>
                <strong className='flex-1' style={{color: "black"}}>Time:</strong>
                <span className='flex-1' style={{color: "#58595B"}}>{UI.getTimezone({date: appointment.reschedule?.practitioner?.previousStarDate, format: "h:mm a"})}</span>
            </div>
          </div>

          <div className='flex flex-col px-10 pt-10 gap-2'>
            <strong className='flex-1' style={{color: "black"}}>Appointment Date To:</strong>
            <div className='flex'>
                <strong className='flex-1' style={{color: "black"}}>Date:</strong>
                <span className='flex-1' style={{color: "#58595B"}}>{UI.getTimezone({date: appointment.reschedule?.practitioner?.start, format: "LL"})}</span>
            </div>
            <div className='flex'>
                <strong className='flex-1' style={{color: "black"}}>Time:</strong>
                <span className='flex-1' style={{color: "#58595B"}}>{UI.getTimezone({date: appointment.reschedule?.practitioner?.start, format: "h:mm a"})}</span>
            </div>
          </div>
            
        </div>
      </TableBox>
    </div>
  )
}

export default PatientRescheduleAppointmentAcceptedModal

const TableBox = styled(Row)`
  display: flex;
  padding: 0px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  align-items: center;
  justify-content: center;
  width: 602px;
`;

const styles = StyleSheet.create({
    md_header_text: {
      fontSize: 18,
      color: "white",
      fontWeight: "bold",
      whiteSpace:'nowrap',
    },
    md_header_text_container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    md_close: {
      height: 60,
      width: 60,
      justifyContent: "center",
      alignItems: "center",
    },
    md_header: {
      // marginBottom: 33,
      height: 60,
      width: "100%",
      backgroundColor: "#00CBFF",
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      flexDirection: "row-reverse",
    },
  });