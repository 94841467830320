import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { CustomFailed, DeleteConfirmation } from "../_components/Modal";
import "./css.css";
const context = "admin";

export default class AdminCMSHospitals extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "App Content",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],

      selected_tab: "quicklinks", //can be quicklinks, banner_slide, mwell_banner
      context: context,

      specialties: [],
    });
  }

  sample_alert = () => {};

  onCreate = () => {
    setTimeout(() => {
      this.execute_resizes(this);
      this.execute_resizes(this);
    }, 300);

    this.get_data(true);
  };

  get_data = async (reload) => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/hospitals",
      params: {},
      onFail: (err) => {},
      onSuccess: (response) => {
        const data = UI.extract_data(response);

        if (reload) {
          this.setState({
            all_data: data.data,
          });
        }

        this.reload_table("hospitals");
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  delete = async (id) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this item?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();
          this.show_loading();
          UI.webRequest({
            method: "delete",
            url: "admin/hospitals/" + id,
            params: {},
            onFail: async (err) => {},
            onSuccess: async (response) => {},
            onFinish: () => {
              this.hide_modal();
              this.get_data(true);
            },
          });
        }}
      />
    );
  };

  validate_csv = async (items) => {
    return new Promise((resolve, reject) => {
      if (items.length === 0) {
        return;
      }

      let template =
        "NAME OF HEALTH FACILITY,Hospital Level,Province,BEDS,TEL_NO,EMAIL,STREET,MUNICIPALITY,START DATE,EXPIRE DATE,SEC,HEAD";
      if (items[0] !== template) {
        this.show_modal(
          <CustomFailed
            title={"Process Failed"}
            description_title={""}
            description={"Invalid CSV Format"}
            _this={this}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      } else {
        resolve();
      }
    });
  };

  process_hospital_csv = async (content) => {
    return new Promise((resolve, reject) => {
      if (content.length === 0) {
        resolve([]);
        return;
      }
      const regex1 = "/n/n/";
      const regex2 = "\r\n";
      content = UI.replaceAll(content, regex2, regex1);
      content = UI.replaceAll(content, "\n", "");
      content = UI.replaceAll(content, regex1, regex2);
      var array = content.split(regex2);
      let result = [];

      let headers = array[0].split(",");

      for (let i = 1; i < array.length - 1; i++) {
        let obj = {};
        let str = array[i];
        let s = "";
        let flag = 0;
        for (let ch of str) {
          if (ch === '"' && flag === 0) {
            flag = 1;
          } else if (ch === '"' && flag === 1) flag = 0;
          if (ch === "," && flag === 0) ch = "|";
          if (ch !== '"') s += ch;
        }

        let properties = s.split("|");

        for (let j in headers) {
          obj[headers[j]] = properties[j];
        }
        result.push(obj);
      }

      result.shift();
      result.pop();
      resolve(result);
    });
  };

  process_hospital_name_address = async (arr = []) => {
    return new Promise((resolve, reject) => {
      if (arr.length === 0) {
        resolve([]);
      }

      const new_data = [];
      arr.forEach((item, index) => {
        const hospital_name = item["NAME OF HEALTH FACILITY"].replace(
          /,\s*$/,
          ""
        );
        const hospital_address = (
          item["STREET"] +
          ", " +
          item["MUNICIPALITY"] +
          ", " +
          item["Province"]
        ).replace(/,\s*$/, "");

        const obj = { hospital_name, hospital_address };
        new_data.push(obj);

        if (index + 1 === arr.length) {
          resolve(new_data);
        }
      });
    });
  };

  import_csv = async () => {
    const content = await UI.get_csv_contents();
    const all_text = content.split("\r\n");
    await this.validate_csv(all_text);
    const new_list = await this.process_hospital_csv(content);
    const hospital_list = await this.process_hospital_name_address(new_list);
    //hospital_list = [{hospital_name: "", hospital_address: ""}]
    this.auto_import(hospital_list, 0);
  };

  auto_import = async (arr = [], index) => {
    const item = arr[index];
    const percentage = (((index + 1) / arr.length) * 100).toFixed(2);
    this.show_loading("", "Importing Hospitals: " + percentage + "%");
    UI.webRequest({
      method: "post",
      url: "admin/hospitals",
      params: {
        name: item.hospital_name ?? "",
        address: item.hospital_address ?? "",
        fieldSpecialties: [],
        isMPHHI: false,
      },
      isMultiPart: false,
      onFail: (err) => {},
      onSuccess: (response) => {},
      onFinish: async () => {
        if (index + 1 < arr.length) {
          setTimeout(() => {
            this.auto_import(arr, index + 1);
          }, 400);
        } else {
          this.hide_loading();
        }
      },
    });
  };

  render() {
    const _this = this;
    const { 
      // height,
       width, 
      //  isMobile, selected_tab
       } = _this.state;

    return (
      <MainLayout context={context} additional={"hospitals"} _this={_this}>
        <View
          style={{
            ...styles.main_box_content,
            padding: 0 /*  marginTop: 20  */,
          }}
        >
          <View style={{ padding: 20 }}>
            <Text style={styles.box_header_text}>Hospitals CMS</Text>
          </View>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={950}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"hospitals"} _this={_this} />
              </View>
            </View>

            <InputForm
              onChange={(e) => {
                UI.search_table("hospitals", e, this.state.all_data, "data");
              }}
              is_keyword={true}
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
            />

            <View
              style={{
                alignSelf: width > 950 ? "flex-start" : "center",
                marginTop: 8,
                marginLeft: 10,
              }}
            >
              <Layout.Btn
                color={UI.colors.primary}
                text={"Add Hospital"}
                onClick={() => {
                  UI.goTo("/admin/add-hospital");
                }}
                height={40}
                width={140}
              />
              {UI.box(10)}
              <Layout.Btn
                color={"#27ae60"}
                text={"Import CSV"}
                onClick={async () => {
                  this.import_csv();
                }}
                height={40}
                width={140}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            state_name={"hospitals"}
            _this={_this}
            columns={[
              // { ID: "id" },
              { "Hospital Name": "hospital_name" },
              { "Hospital Address": "hospital_address" },
              // { Specialties: "specialties" },
              { "Added On": "added_on" },
              // { Status: "status" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              const date_added = UI.timestampToDate(
                new Date(item.createdAt).getTime()
              );

              const ellipsis = item.fieldSpecialties.length > 3 ? ", ..." : "";

              return {
                id: "001",
                hospital_name: (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image
                      height={35}
                      width={35}
                      tintColor={"transparent"}
                      source={UI.TRANSACTION_USER}
                    />
                    {UI.box(5)}
                    {item.name}
                  </View>
                ),
                hospital_address: item.address,
                specialties:
                  item.fieldSpecialties
                    .splice(0, 3)
                    .join(", ")
                    .replace(/,+$/, "") + ellipsis,
                added_on:
                  date_added.month +
                  "/" +
                  date_added.day +
                  "/" +
                  date_added.year,
                status: <Text style={{ color: "#00C700" }}>Active</Text>,
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        mem.save("view_hospital", JSON.stringify(item));
                        UI.goTo("/admin/edit-hospital");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={() => {
                        this.delete(item._id);
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.DELETE_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO CONSULTATION DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  menu_main_text: {
    fontSize: 18,
    fontWeight: "bold",
    color: UI.colors.secondary,
    padding: 20,
  },
  active_design: {
    height: "100%",
    width: 4,
    backgroundColor: UI.colors.primary,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  menu_btn: {
    fontSize: 16,
    fontWeight: "bold",
    padding: 10,
    paddingLeft: 20,
  },
  timeline_circle: {
    height: 22,
    width: 22,
    borderRadius: 100,
    backgroundColor: UI.colors.primary,
    borderWidth: 4,
    borderColor: "#E3E3E3",
    marginTop: -10,
    marginLeft: -12,
  },
  timeline_item: {
    height: 100,
    width: "100%",
    borderLeftStyle: "solid",
    borderLeftWidth: 2,
    borderLeftColor: "#E3E3E3",
    marginLeft: 10,
  },
  unused_text: {
    fontSize: 12,
    fontWeight: "bold",
    color: "white",
  },
  unused_box: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 10,
    backgroundColor: "#D3D3D3",
    justifyContent: "center",
    alignItems: "center",
  },
  detail_value: {
    color: "black",
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  detail_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  flex_1: {
    flex: 1,
  },
  detail_left: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  detail_item: {
    flexDirection: "row",
    alignItems: "center",
    width: 370,
    marginBottom: 5,
  },
  line: {
    width: "100%",
    height: 3,
    backgroundColor: "#EFEFEF",
  },
  ai_fl: {
    flexDirection: "row",
    alignItems: "center",
  },
  sub_name: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#898989",
  },
  main_name: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
