/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import UI from '../../../shared/Components/UI/js'
import TouchableOpacity, { mem } from '../../../shared/custom-react-native'
import FormItem from '../../ui/forms/FormItem'
import Text from '../../ui/Text'
import { Badge, Button, Col, Form, Modal, } from 'antd'
import styled from 'styled-components'
import OtpInput from 'react-otp-input'
import ServiceAuthApi from '../../../api_services/auth'
import Loading from '../../../modules/_components/Loading'
import ModalCloseIcon from '../../../icons/ModalClose'
import { Failed, Success } from '../../../modules/_components/Modal'

const OtpCodeModal = (props) => {
  // const [otpCode, setOtpCode] = useState("");
  // const [errorMessage, props._this.setState]{ otpErrorMessage: ""} useState(""); 
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const [count, setCount] = useState(60);
  const counterValid = count > 0;
  useEffect(() => {
    if (props.verifyType) {
      const interval = setInterval(() => {
        counterValid && setCount(count => count - 1);
      }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }
  }, [counterValid, props.verifyType]);

  const handleChange = otp => {
    props._this.setState({ otpErrorMessage: ""});
    props._this.setState({ otpCode: otp});
  };

  const verifyOtpCode = (e) => {
    const payload = {
      userId: props._this.state.onboard_data._id,
      otpNumber: props._this.state.otpCode,
      role: "provider"
    };

    setIsScreenLoading(true);
    console.log(props.verifyType)
    if (props.verifyType) {
      if (props.verifyType === "phoneNumber") {
        ServiceAuthApi.verifyOtpNumber(payload).then(res => {
          if (res.status === 200) {
            setIsScreenLoading(false);
            props._this.show_modal(
              <Success
                title={"Success!"}
                description={"Your mobile number has been Successfully verified."}
                onDismiss={() => {
                  props._this.hide_modal();
                  props.this.setState({ isShowOtpModal: false });
                  mem.update("onboard_data", "isPhoneNumberVerified", true);
                  props._this.load_data();
                  props._this.setState({ otpCode: ""})
                  props._this.setState({ otpErrorMessage: ""})
                }}
              />
            );
          } else {
            setIsScreenLoading(false);
            if (res.message.includes("wrong") || res.message.includes("expired")) {
              props._this.setState({ otpErrorMessage: res.message});
            } else {
              props._this.setState({ otpErrorMessage: res.message});
            }
          }
        });
      } else {
        ServiceAuthApi.verifyOtpEmail(payload).then(res => {
          if (res.status === 200) {
            props._this.show_modal(
              <Success
                title={"Success!"}
                description={"Your email address has been Successfully verified."}
                onDismiss={() => {
                  props._this.hide_modal();
                  props.this.setState({ isShowOtpModal: false });
                  mem.update("onboard_data", "isEmailVerified", true)
                  props._this.load_data();
                  props._this.setState({ otpCode: ""})
                  props._this.setState({ otpErrorMessage: ""})
                }}
              />
            );
            setIsScreenLoading(false);
          } else {
            setIsScreenLoading(false);
            if (res.message.includes("wrong") || res.message.includes("expired")) {
              props._this.setState({ otpErrorMessage: res.message });
            } else {
              props._this.setState({ otpErrorMessage: res.message });
            }
          }
        });
      };;
    } 
  };

  const resendCode = () => {
      if (props.verifyType === "email") {
        const payload = {
          email: props._this.state.email,
          role: "provider"
        };
        ServiceAuthApi.verifyEmail(payload).then(res => {
          if (res.status === 200) {
            props._this.hide_loading();
          } else {
            props._this.hide_loading();
            if (res.message.includes("wrong") || res.message.includes("expired")) {
              props._this.show_modal(
                <Failed
                  title={"Failed"}
                  description={res.message}
                  onDismiss={() => {
                    props._this.hide_modal();
                    UI.logout();
                  }}
                />
              );
            } else {
              props._this.show_modal(
                <Failed
                  title={"Failed"}
                  description={res.message}
                  onDismiss={() => {
                    props._this.hide_modal();
                    UI.logout();
                  }}
                />
              );
            }
          }
        });
      } else if (props.verifyType === "phoneNumber") {
        const payload = {
          phoneNumber: props._this.state.contact_no,
          phoneNumberPrefix: "+63",
          role: "provider"
        };
        ServiceAuthApi.verifyMobileNumber(payload).then(res => {
          if (res.status === 200) {
            props._this.hide_loading();
          } else {
            props._this.hide_loading();
            props._this.show_modal(
              <Failed
                title={"Failed"}
                description={res.message}
                onDismiss={() => {
                  props._this.hide_modal();
                }}
              />
            );
          }
        });
      } else {
        return null
      }
  };

  return (
    <StyledModal
      {...props}
      title={`Verify ${props.verifyType === "email" ? "Email Address" : "Mobile Number" }`}
      // visible={true}
      headercolor={UI.colors.secondary}
      closeIcon={<ModalCloseIcon />}
      okText={"Verify"}
      // buttoncolor={props?.buttoncolor}
      cancelButtonProps={{ style: { display: "none" }  }}
      okButtonProps={{ style: { display: "none" } }}
    >
      {/* {props?.banner}
      <StyledBodyDescription>{props?.description}</StyledBodyDescription>
      {props?.extrajsx && <StyledExtraJSX>{props?.extrajsx}</StyledExtraJSX>} */}
      <>
        <Loading
          visible={isScreenLoading}
          title={""}
          text={`Verifying OTP...`}
          modal_opaque={false}
        />
        {/* <ProcessFailedModal
          title={"Process Failed"}
          banner={<ProcessFailedModalCrossIcon />}
          description={errorMessage}
          buttonText={"Dismiss"}
          buttoncolor={"#004f99"}
          visible={isFailed}
          onOk={() => {
            setIsFailed(false)
          }}
          onCancel={() => setIsFailed(false)} // x and cancel button
        /> */}
        <Col>
          <Col className='flex flex-col items-center gap-5'>
            <Col className='flex flex-col items-center gap-5'>
              <StyledText level={4} >
                Please enter the Verification Code sent to your {props.verifyType === "email" ? "Email Address" : "Mobile Number"}
              </StyledText>
            </Col>
            <Text level={4}>  {props.verifyType === "email" ? props._this.state?.email : ("+63" + props._this.state.contact_no) } 
            </Text>
            <StyledForm
              layout={"vertical"}
              onFinish={(e) => { }}
            >
              <OtpInput
                placeholder={"------"}
                containerStyle={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                inputStyle={{
                  border: "2px solid #DFE5EB",
                  color: "#000000",
                  fontWeight: "bold",
                  fontSize: 20,
                  borderRadius: "10px",
                  width: 70,
                  height: 70
                }}
                hasErrored={true}
                errorStyle={{
                  border: props._this.state.otpErrorMessage ? "2px solid #CC0415" : "2px solid #DFE5EB"
                }}
                value={props._this.state.otpCode}
                onChange={handleChange}
                numInputs={6}
                separator={<span> </span>}
              />

              <Text level={4} style={{ textAlign: "center", color: "red", margin: 20, lineHeight: 100 }}>{props._this.state.otpErrorMessage}</Text>

              <Text level={5} style={{ color: "#00CBFF", textAlign: "center", margin: 20, display: "flex", alignItems: "center", gap: 10, justifyContent: "center", }}>
                <TouchableOpacity
                  style={{ ...count !== 0 && { cursor: "not-allowed", fontSize: 14, fontWeight: "bold", backgroundColor: "#ffff", color: "rgba(0, 0, 0, 0.25)" } }}
                  onClick={() => {
                    if (count === 0) {
                      resendCode()
                      return setCount(60)
                    }
                  }}
                >Resend Code
                </TouchableOpacity>
                <StyledBadge count={count}
                  style={{ backgroundColor: '#00CBFF', borderRadius: 6 }}
                />
              </Text>

              <FormItem>
                <StyledButton
                  disabled={props._this.state.otpCode?.length > 5 ? false : true}
                  id="submit-button"
                  htmlType="submit"
                  onClick={() => {
                    verifyOtpCode()
                  }}
                >
                  VERIFY
                </StyledButton>
              </FormItem>
            </StyledForm>
          </Col>
        </Col>
      </>
    </StyledModal>
  )
}

export default OtpCodeModal

const StyledModal = styled(Modal)`
  font-family: Poppins;
  letter-spacing: 0px;

  .ant-modal-content {
    border-radius: 15px;
    .ant-modal-close {
      padding-top: 12px;
      padding-right: 38px;
    }
  }

  .ant-modal-header {
    text-align: center;
    background-color: ${(props) =>
      props.headercolor ? props.headercolor : "#035099"};
    border-radius: 15px 15px 0 0;
    padding: 32px 38px 26px 38px;
    height: 90px;

    .ant-modal-title {
      font-size: 1.625rem; // 26px
      color: #ffffff;
      letter-spacing: 0px;
      /* text-transform: uppercase; */
    }
  }
  .ant-modal-body {
    text-align: center;
    font-size: 1.4375rem; //23px
    font-weight: 500;
    color: #004f99;
    padding: 55px 45px 20px 45px;
  }
  .ant-modal-footer {
    text-align: center;
    border-top: 0;
    padding-top: 0;
    padding-bottom: 30px;
    & .ant-btn-primary {
      color: #ffffff;
      font-size: 1.4375rem; //23px
      background-color: ${(props) =>
        props.buttoncolor ? props.buttoncolor : "#004f99"};
      border-radius: 12px;
      border-color: ${(props) =>
        props.buttoncolor ? props.buttoncolor : "#004f99"};
      width: 162px;
      height: 74px;
      :hover {
        background-color: #356a9c;
      }
    }
  }
`;

const StyledBadge = styled(Badge)`
  
`;

const StyledText = styled(Text)`
  text-align: center;
  line-height: 30px !important;
  width: 390px;
`

const StyledForm = styled(Form)`
  width: 100%;
`;

// const StyledInputField = styled(InputField)`
//   font-size: 18px;
//   font-weight: bold;
//   text-align: center;
// `

// const StyledOtpInput = styled(OtpInput)`

//   .sc-cKajLJ {
//     background-color: red !important;
//   }
// `

// const StyledLinkTo = styled("a")`
// `;

// const StyledInputPasswordField = styled(InputPasswordField)`
//   .ant-input {
//     font-size: 18px !important;
//   }
// `

const StyledButton = styled(Button)`
  background-color: #004F99;
  border-radius: 12px;
  width: 100%;
  height: 70px;
  border: none !important;

  color: #ffffff;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
 
  &:disabled {
    background-color: #D7E2EB;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #00CBFF;
    color: #ffffff;
    border: none !important;
  }
`;