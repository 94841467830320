import React from 'react'
import { Text, View, Image, StyleSheet } from '../../custom-react-native'
import UI from '../UI/js'

const NoDataTable = (props) => {
  return (
    <View
      style={{
        ...styles.no_info_container,
        display: props._this.state.data.length > 0 ? "none" : "flex",
      }}
    >
      <UI.Row
        breakpoint={820}
        style={{ maxWidth: 360, alignItems: "center" }}
        _this={props._this}
      >
        <Image
          height={100}
          width={100}
          tintColor={"transparent"}
          source={UI.NO_INFO}
          style={{ marginRight: 10 }}
        />
        <Text
          style={{
            fontSize: 20,
            fontWeight: "bold",
            color: "#BFBFBF",
            textAlign: props._this.state.width > 820 ? "left" : "center",
            marginLeft: props._this.state.width > 820 ? 20 : 0,
          }}
        >
          NO DATA AVAILABLE YET
        </Text>
      </UI.Row>
    </View>
  )
}

export default NoDataTable

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
})