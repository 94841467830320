import React, { useCallback, useState, useEffect } from "react";
import DataTable from "../../ComponentsFC/DataTable";
import UI from "../../shared/Components/UI/js";
import styled from "styled-components";
import { Row, Col, Button, Input, Select, Typography } from "antd";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import ServiceSubscriptionsApi from "../../api_services/subscriptions";
import TouchableOpacity, { Image, mem, View } from "../../shared/custom-react-native";
import { DeleteConfirmation, Failed, Success } from "../_components/Modal";

const EcommerceUserGroupSubscriptionListTable = ({ _this }) => {
  const [tableData, setTableData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(30);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [sortByName, setsortByName] = useState("");
  const [sortByCreatedAt, setsortByCreatedAt] = useState("");
  const [filterByStatus, setFilterByStatus] = useState("");

  const getSubscriptions = useCallback(async () => {
      const data = mem.get("admin");
      const record = JSON.parse(data);

      setIsLoading(true);

      let params = {};

      if (sortByName !== "") {
        params.planName = sortByName;
      }
      
      if (sortByCreatedAt !== "") {
        params.createdAt = sortByCreatedAt;
      }

      if (filterByStatus !== "") {
        params.status = filterByStatus;
      }

      if (searchQuery) {
        params.keyword = searchQuery;
      }

      if (currentPageNum) {
        params.current_page  = currentPageNum;
        params.per_page = tableEntries;
      }

      ServiceSubscriptionsApi.getAllUserGroupSubscriptionsByMerchant(params, record?._id).then( res => {
        setIsLoading(false);
        if (res.status === 200 || res.status === 204) {
          let data = res.data;
          setTotalRecords(res.total);
          setTableData(data);
        }
      });
  }, [currentPageNum, tableEntries, sortByName, searchQuery, sortByCreatedAt, filterByStatus]);

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  const handlePaginateTable = (pageNum) => {
    // console.log("Currently Selected Page # of Antd Table: ", pageNum);
    setCurrentPageNum(pageNum);
  };

  const tableColumnHeader = [
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   render: (imgSrc, item) => {
    //     const subscriptinImage = item.imagePath ? `${Constants.STORAGE_BASE_URL}?path=${item.imagePath}` : UI.SAMPLE_PRODUCT;
    //     return (
    //       <img src={subscriptinImage} width={100} alt={"img-" + item.planName}/>
    //     )
    //   },
    //   width: 120,
    // },
    {
      title: "Product Name",
      dataIndex: "subName",
      key: "subName",
      render: (sub, item) => <>{item.planName}</>,
    },
    {
      title: "Product Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <>{status ? UI.get_color_status_text(status) : "--"}</>,
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => <>{createdAt ? UI.momentPHTimezone(createdAt).format("MM/DD/YYYY") : "--"}</>,
    },
    
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (key, item) => {
        return <View style={{ flexDirection: "row", gap: 10 }}>
          <TouchableOpacity
            onClick={() => {
              const account = JSON.parse(mem.get("admin"));
              const newItem = {accountId: account._id, ...item};
              mem.save("edit_user_group_subscription", JSON.stringify(newItem));
              UI.goTo('/merchant/user-group-subscription-edit');
            }}
          >
            <Image
              height={28}
              width={28}
              tintColor={"transparent"}
              source={UI.EDIT_BTN}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onClick={() => {
              deleteSubscription(item._id)
            }}
          >
            <Image
              height={28}
              width={28}
              tintColor={"transparent"}
              source={UI.DELETE_BTN}
            />
          </TouchableOpacity>
        </View>
      },
      width: 50,
    },
  ];

  const deleteSubscription = (subscriptionId) => {
    _this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this item?"}
        onCancel={() => {
          _this.hide_modal();
        }}
        onDelete={async () => {
          _this.hide_modal();
          setIsLoading(true);
          ServiceSubscriptionsApi.deleteSubscriptionPlan(subscriptionId).then( res => {
            setIsLoading(false);
            if (res.status === 200) {
              _this.show_modal(
                <Success
                  title={"Success!"}
                  description={"Successfully subscription item"}
                  onDismiss={() => {
                    getSubscriptions()
                    _this.hide_modal();
                  }}
                />
              )
            } else {
              _this.show_modal(
                <Failed 
                  description={res.message} 
                  onDismiss={_this.hide_modal} 
                />
              );
            }
          });
        }}
      />
    );
  }

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClearViaSelectField = (fieldName) => {
    console.log("Handle CLEAR", fieldName);
    if (fieldName === "Date Filter by") {
      setSearchResults([]);
    }
  };

  return (
    <>
      <FilterBox>
        <Title level={5}>Subscription Product</Title>
        <Toolbar gutter={14}>
          <ToolItem span={8}>
            <StyledFieldLabel>Subscription Name</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Select Option"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => setsortByName(value)}
              onClear={() => handleClearViaSelectField("Subscription Name")}
            >
              <Select.Option title="Action" value="asc">
                Ascending
              </Select.Option>
              <Select.Option title="Action" value="desc">
                Descending
              </Select.Option>
            </StyledSelect>
          </ToolItem>
          <ToolItem span={8}>
            <StyledFieldLabel>Date Created</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Select Option"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => setsortByCreatedAt(value)}
              onClear={() => handleClearViaSelectField("Date Created")}
            >
              <Select.Option title="Action" value="asc">
                Ascending
              </Select.Option>
              <Select.Option title="Action" value="desc">
                Descending
              </Select.Option>
            </StyledSelect>
          </ToolItem>
          <ToolItem span={8}>
            <StyledFieldLabel>Status</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Select Option"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => setFilterByStatus(value)}
              onClear={() => handleClearViaSelectField("Status")}
            >
              <Select.Option title="Action" value="Active">
                Active
              </Select.Option>
              <Select.Option title="Action" value="Inactive">
                Inactive
              </Select.Option>
            </StyledSelect>
          </ToolItem>
        </Toolbar>
      </FilterBox>
      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="30"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="5">5 Entries</Select.Option>
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>
          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getSubscriptions}
            style={{ width: 200 }}
          />
          <StyledButton type="primary" onClick={() => {
            const account = JSON.parse(mem.get("admin"));
            mem.save("accountId", account._id);
            UI.goTo('/merchant/user-group-subscription-create')
          }}>
            Add Product
          </StyledButton>
        </Toolbar>
        <SubscriptionListTable
          tableColumnHeader={tableColumnHeader}
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          defaultPageSize={30}
          pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default EcommerceUserGroupSubscriptionListTable;

// CSS Styled Components ----------------------
const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const ToolItem = styled(Col)`
  position: relative;
  .ant-picker {
    margin-left: 102px;
  }
  .ant-picker-range {
    margin-left: 62px;
  }
`;

const Title = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.4375rem !important; // 15px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
  padding-left: 35px;
  margin-bottom: 25px !important;
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

// const ActionStyledSelect = styled(Select)`
//   .ant-select-selector {
//     border-radius: 10px !important;
//     border-width: 2px !important;
//     height: 40px !important;
//     align-items: center;
//     font-size: 10px;
//     line-height: 1.4375rem;
//     & .ant-select-selection-placeholder {
//       color: #2e2e2e;
//     }
//     // Options
//     & .ant-select-selection-item {
//       z-index: 1;
//     }
//   }

//   // The Wrapper of Icon
//   .ant-select-arrow {
//     margin-right: 10px;
//     top: 47% !important;
//   }
//   // The Wrapper of Icon for clear btn, when there's a value
//   .ant-select-clear {
//     top: 45%;
//     right: 45px;
//     opacity: 1 !important;
//   }
// `;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;

const SubscriptionListTable = styled(DataTable)`
  .ant-table-cell {
    font-size: 1rem; 
  }
`;
