import Icon from "@ant-design/icons";
import React from "react";

const DataTableEdit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <g id="edit" transform="translate(-0.5)">
      <rect
        id="Rectangle_1914"
        data-name="Rectangle 1914"
        width="50"
        height="50"
        rx="11.5"
        transform="translate(0.5)"
        fill="#004f99"
      />
      <path
        id="edit-2"
        data-name="edit"
        d="M415.354,290.642v-15.3c0-2.857,1.508-4.337,4.377-4.337h14.185l-2.616,2.615H419.891a1.729,1.729,0,0,0-1.922,1.948v14.852a1.729,1.729,0,0,0,1.922,1.948H435.13c.894,0,1.588-.654,1.588-1.948V279.153l2.616-2.616v14.1c0,2.843-1.495,4.324-4.044,4.324H419.731C416.862,294.965,415.354,293.5,415.354,290.642Zm8.914-5.11,1.2-2.629L437.6,270.76l2.027,2.015L427.5,284.918l-2.722,1.147A.41.41,0,0,1,424.268,285.532Zm14.306-15.76,1.027-1.014a1.207,1.207,0,0,1,1.694-.106l.348.36a1.222,1.222,0,0,1-.04,1.761l-1,1.041Z"
        transform="translate(-402.354 -257.351)"
        fill="#fff"
      />
    </g>
  </svg>
);

const DataTableEditIcon = (props) => {
  return <Icon component={DataTableEdit} {...props} />;
};

export default DataTableEditIcon;
