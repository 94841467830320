import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
// **Commented out, currently not used
// import { StyleSheet } from "../../shared/custom-react-native";
import CalendarSchedule from "../_components/CalendarSchedule";
import MainLayout from "../_components/MainLayout";
import "./css.css";

const context = "finance";

export default class FinanceAppointments extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Appointments",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;
    // **Commented out, currently unused
    // **Alternatively you can use @media screen only (min-width: 470px) { cssSelector {cssPropsHere: cssValue}}
    // const {
    //   height,
    //   width,
    //   isMobile
    // } = _this.state;

    // let add_style = {};
    // if (width <= 470) {
    //   add_style = { flexDirection: "row" };
    // }

    return (
      <MainLayout context={context} _this={_this}>
        <CalendarSchedule
          data={[{ item: {}, timestamp: Date.now() + 100000000 }]}
          _this={_this}
          id={"appointments"}
          onItemClick={(event) => {
            UI.goTo("/finance/view-appointment");
          }}
        />
      </MainLayout>
    );
  }
}

// const styles = StyleSheet.create({});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
