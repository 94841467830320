import React from "react";
import DataTable from "../../../../../ComponentsFC/DataTable";
import { Row } from "antd";
import styled from "styled-components";

const AnthropometricMeasurements = ({ tableData = [] }) => {
  // const [tableData, setTableData] = useState([]);
  // const [isTableLoading, setIsTableLoading] = useState(false);

  const tableColumnHeader = [
    {
      title: "Date and Time",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Height or Recumbent Length (cm)",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Weight (kg)",
      dataIndex: "indication",
      key: "indication",
    },
    {
      title: "Body Mass Index (BMI; based on WHO Classification)",
      dataIndex: "indication",
      key: "indication",
    },
  ];
  return (
    <TableBox>
      <DataTable tableColumnHeader={tableColumnHeader} dataSource={tableData} />
    </TableBox>
  );
};

export default AnthropometricMeasurements;

const TableBox = styled(Row)`
  position: relative;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  min-width: 100%;
  .ant-table-cell {
    font-size: 20px;
  }
`;
