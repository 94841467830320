import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { DeleteConfirmation, Success } from "../_components/Modal";
import "./css.css";

const context = "voucher";

export default class VoucherList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Voucher List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,
    });
  }

  onCreate = () => {
    this.get_data();
  };

  get_data = async () => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/vouchers",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            data: data.data,
            all_data: data.data,
          },
          () => {
            this.reload_table("voucher_list");
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  previews = async (data) => {
    UI.set_images(data, "imagePath", "preview", "merchant_list", "admin");
  };

  delete = async (id) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this item?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();
          this.show_loading();
          UI.webRequest({
            method: "delete",
            url: "admin/vouchers/" + id,
            params: {},
            onFail: async (err) => {},
            onSuccess: async (response) => {},
            onFinish: () => {
              this.hide_modal();
              this.get_data();
            },
          });
        }}
      />
    );
  };

  export = async (id) => {
    this.show_loading();

    // id = "61efacb0ab0eb492a7b389fd";
    UI.webRequest({
      method: "get",
      url: "admin/vouchers/user-voucher?id=" + id,
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        const { title, 
          // description 
        } = data;

        if (data.user.length === 0) {
          if (data.user.length === 0) {
            this.show_modal(
              <Success
                title={"Success!"}
                description={"No user found to process the voucher record."}
                onDismiss={() => {
                  this.hide_modal();
                }}
              />
            );
          }
          return;
        }

        const csv_data = data.user.map((item, index) => {
          let doctor_name = " ";
          let appointment_id = " ";
          let appointment_start = " ";
          let appointment_end = " ";
          let appointment_amount = 0;

          if (item.payments.length > 0) {
            // let amounts = item.payments.map((i, idx) => {
            //   let amt = 0;
            //   if (i.method == "Voucher") {
            //     amt = i.amount;
            //     appointment_amount += parseFloat(amt);
            //   }
            //   if (idx == 0) {
            //     doctor_name = i.appointment.participant[0].actor.reference;
            //     appointment_id = i.appointment._id;
            //     appointment_start = UI.get_date_string_by_date(
            //       i.appointment.start
            //     );
            //     appointment_end = UI.get_date_string_by_date(i.appointment.end);
            //   }

            //   return i;
            // });
          }

          const d = {
            Row: index + 1,
            "Patient Name": item?.fName ?? " " + item?.lName ?? " ",
            "Doctor ID": doctor_name,
            "Appointment ID": appointment_id,
            "Appointment Start": appointment_start,
            "Appointment End": appointment_end,
            "Voucher Status": item?.voucherStatus,
            "Amount (PHP)": appointment_amount,
            "Voucher Validity":
              item.voucherValidity === "none"
                ? "None"
                : UI.get_date_string_by_date(item?.voucherValidity),
          };

          return d;
        });
        UI.DownloadCSV(
          "Voucher Record-" + title + " " + UI.get_current_date_hr_mm_string(),
          csv_data
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;
    const { 
      // height, 
      width, 
      // isMobile 
    } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={{ ...styles.main_box_content, padding: 0 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={950}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"voucher_list"} _this={_this} />
              </View>
            </View>

            <View style={{ alignSelf: "center", marginTop: 8, marginLeft: 10 }}>
              <Layout.Btn
                color={UI.colors.primary}
                text={"Add Voucher"}
                onClick={() => {
                  UI.goTo("/voucher/add-voucher");
                }}
                height={40}
                width={140}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            state_name={"voucher_list"}
            _this={_this}
            columns={[
              // { ID: "id" },
              { Title: "title" },
              { Available: "availableQuantity" },
              // { "Total Count": "initialQuantity" },
              { "Allowed Users": "allowed_user" },
              { "Start Date": "startDate" },
              { "End Date": "endDate" },
              { "Date Added": "date_added" },
              { Status: "status" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              const date_added = UI.timestampToDate(
                new Date(item.createdAt).getTime()
              );
              const startDate = UI.get_date_string_by_date(item.startDate);
              const endDate = UI.get_date_string_by_date(item.endDate);

              const is_active = item.status === "Active" ? true : false;
              return {
                startDate: startDate,
                endDate: endDate,
                title: item.title,
                availableQuantity: item.availableQuantity,
                initialQuantity: item.initialQuantity,
                date_added:
                  date_added.month +
                  "/" +
                  date_added.day +
                  "/" +
                  date_added.year,
                status: is_active ? (
                  <Text style={{ color: "#00C700" }}>Active</Text>
                ) : (
                  <Text style={{ color: "#f39c12" }}>Inactive</Text>
                ),
                allowed_user: item.allowAllUsers
                  ? "All"
                  : item.allowedUsers?.length,
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        mem.save("edit_voucher", JSON.stringify(item));
                        UI.goTo("/voucher/edit-voucher");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={() => {
                        this.delete(item._id);
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.DELETE_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={() => {
                        this.export(item._id);
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EXPORT_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
