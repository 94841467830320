import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import { StyleSheet, Text, View } from "../../shared/custom-react-native";
import InputForm, { Checkbox } from "../_components/InputForm";
import Layout from "../_components/Layout";
import Specialty from "./Specialty";
import "./css.css";

export default class SecondForm extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const { width } = _this.state;
    const row_width = width > 1000 ? width - 400 : "100%";
    let gender = "male";
    if (_this.state.gender) {
      gender = _this.state.gender;
    }

    return (
      <UI.Column
        style={{ marginBottom: 10 }}
        _this={_this}
        breakpoint={1000}
        breakpoint_2={720}
      >
        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <View style={{ flex: 1 }}>
            <Text style={styles.title}>
              Gender<Text style={styles.asterisk}> *</Text>
            </Text>
            {UI.box(8)}

            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Checkbox
                _this={_this}
                text={"Male"}
                onClick={(value) => {
                  _this.setState({
                    gender: "male",
                  });
                }}
                is_checked={gender === "male" ? true : false}
                color={"#2E2E2E"}
              />
              <View style={{ width: 50 }}></View>
              <Checkbox
                _this={_this}
                text={"Female"}
                onClick={(value) => {
                  _this.setState({
                    gender: "female",
                  });
                }}
                is_checked={gender === "female" ? true : false}
                color={"#2E2E2E"}
              />
            </View>
          </View>
          {UI.box(10)}
          
          <Specialty _this={_this} />
          
          {UI.box(10)}

          <Layout.DatePicker
            _this={_this}
            title={"Qualification Date"}
            isRequired={false}
            placeholder={"mm/dd/yyyy"}
            state_name={"qualification_date"}
            date_props={{
              maxDate: new Date(),
            }}
          />
        </UI.Row>
        {UI.box(20)}
        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <InputForm
            _this={_this}
            title={"PRC Number"}
            placeholder={"PRC Number"}
            state_name={"prc_number"}
            style={{ flex: 1 }}
            isNumber={true}
            maxLength={7}
            isRequired={true}
          />
          {UI.box(10)}

          <Layout.DatePicker
            _this={_this}
            title={"PRC License Start Date"}
            isRequired={true}
            placeholder={"mm/dd/yyyy"}
            state_name={"license_start_date"}
            date_props={{
              maxDate: new Date(),
            }}
            will_correct = {true}
          />
          {UI.box(10)}

          <Layout.DatePicker
            _this={_this}
            title={"PRC License End Date"}
            isRequired={true}
            placeholder={"mm/dd/yyyy"}
            state_name={"license_end_date"}
            date_props={
              {
                //maxDate: new Date(),
              }
            }
            will_correct = {true}
          />
        </UI.Row>
      </UI.Column>
    );
  }
}

const styles = StyleSheet.create({
  asterisk: {
    color: "#FF0000",
  },
  option_container: {
    height: 40,
    width: "100%",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 2,
    padding: 5,
    borderColor: "#CACACA",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  btn_text_1: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
  },
  blue_btn_2: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  text_1: {
    color: "#AFAFAF",
    fontSize: 14,
  },
  image_container: {
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  header_3: {
    fontSize: 18,
    color: UI.colors.primary,
    marginTop: 2,
    fontWeight: "bold",
  },
});
