import React, { useState, useEffect } from "react";
import { EPrescriptionModal, DoctorsOrderModal } from "../EDocs";
import UI from "../../../../shared/Components/UI/js";
import { Text, View } from "../../../../shared/custom-react-native";
import { Checkbox } from "../../../_components/InputForm";

const OrderPrescription = ({
  onAddOrderPrescriptions,
  setOrderPrescriptionFormInput,
  orderPrescriptionFormInput,
  saveEntry,
  _this,
  encounterNotesData,
  isIssueOrderToPatient,
  setIsIssueOrderToPatient,
  isIssuePrescriptionToPatient,
  setIsIssuePrescriptionToPatient,
}) => {
  const [data, setData] = useState([]);

  const [validationMessage] = useState({
    error: "",
    success: "",
  });

  const [suggestion, setsuggestion] = useState([]);
  const [getIndex, setGetIndex] = useState("");

  const onHandleChangeOrder = async (e, i, type) => {
    const { name, value } = e.target;

    let matches = [];

    if (type === "orders") {
      const list = {
        ...orderPrescriptionFormInput,
        orders: [...orderPrescriptionFormInput.orders],
      };
      list.orders[i][name] = value;

      matches =
        data &&
        data.filter((a) => {
          if (a.groupTitle === "Diagnostic Item") {
            return a.option.toLowerCase().startsWith(value.toLowerCase());
          }
          return false;
        });

      setOrderPrescriptionFormInput({
        ...orderPrescriptionFormInput,
        orders: list.orders,
      });
      setsuggestion(matches);
      setGetIndex(value === "" ? "" : i);
    } else if (type === "prescriptions") {
      const list = {
        ...orderPrescriptionFormInput,
        prescriptions: [...orderPrescriptionFormInput.prescriptions],
      };
      list.prescriptions[i][name] = value;

      if (name === "medication") {
        matches =
          data &&
          data.filter((a) => {
            if (a.groupTitle === "Medication")
              return a.option.toLowerCase().startsWith(value.toLowerCase());
            return false;
          });
      }

      setOrderPrescriptionFormInput({
        ...orderPrescriptionFormInput,
        prescriptions: list.prescriptions,
      });
      setsuggestion(matches);
      setGetIndex(name === "" ? "" : { name, i });
    }

    const payload = onAddOrderPrescriptions(orderPrescriptionFormInput);
    saveEntry("order prescription", payload);
  };

  const addInputField = (type) => {
    if (type === "orders") {
      setOrderPrescriptionFormInput({
        ...orderPrescriptionFormInput,
        orders: [...orderPrescriptionFormInput.orders, { diagnosticsItem: "" }],
      });
    } else if (type === "prescriptions") {
      setOrderPrescriptionFormInput({
        ...orderPrescriptionFormInput,
        prescriptions: [
          ...orderPrescriptionFormInput.prescriptions,
          {
            medication: "",
            number: "",
            notes: "",
          },
        ],
      });
    }
  };

  useEffect(() => {
    function getAutocomplete() {
      UI.authRequest({
        method: "get",
        url: "app/mwell/cms/autocomplete/get",

        onSuccess: async (response) => {
          setData(response.data.d);
        },
      });
    }
    getAutocomplete();
  }, []);

  return (
    <div style={{ background: "#F5FAFF " }} className="rounded-b-lg p-3">
      <div>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: "15px",
            color: "#0E4D94",
          }}
        >
          Orders
        </Text>

        {UI.box(10)}
        {orderPrescriptionFormInput.orders.map((input, i) => (
          <div className="relative">
            <input
              autocomplete="off"
              className={`${
                suggestion.length > 0 ? "rounded-t-lg" : "rounded-lg"
              }`}
              style={{
                fontSize: "14px",
                resize: "none",
                color: "black",
                width: "100%",
                padding: "10px 14.44px",
                // borderRadius:suggestion.length > 0 : : '10px',
                border: "2px solid #C9C9C9",
                marginTop: "5px",
              }}
              placeholder={"Diagnostic Item"}
              onChange={(e) => onHandleChangeOrder(e, i, "orders")}
              onBlur={() => {
                setTimeout(() => {
                  setsuggestion([]);
                }, 200);
              }}
              name="diagnosticsItem"
              value={input.diagnosticsItem}
            />
            {i === getIndex && (
              <div
                className="absolute inset-0 z-10"
                style={{ marginTop: "50px" }}
              >
                {suggestion.map((res) => (
                  <div
                    className="bg-white px-3 py-2 z-10 hover:bg-blue-100 cursor-default "
                    onClick={() => {
                      const list = {
                        ...orderPrescriptionFormInput,
                        orders: [...orderPrescriptionFormInput.orders],
                      };
                      list.orders[i].diagnosticsItem = res.option;
                      setOrderPrescriptionFormInput({
                        ...orderPrescriptionFormInput,
                        orders: list.orders,
                      });
                      setsuggestion([]);
                    }}
                  >
                    <span style={{ fontSize: "16px" }}>{res.option}</span>
                  </div>
                ))}
              </div>
            )}
            {UI.box(10)}
          </div>
        ))}
        {UI.box(10)}

        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <button
            className="my-3 flex items-center gap-2 py-2 px-4 rounded-md font-semibold"
            style={{ background: "none", border: "3px solid #0E4D94" }}
            onClick={() => addInputField("orders")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={3}
              style={{ color: "#0E4D94" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
            <strong style={{ fontSize: "15px", color: "#0E4D94" }}>
              Add Order
            </strong>
          </button>
        </View>

        <div className="flex justify-between items-center my-3">
          <Checkbox
            text="Issue to Patient"
            is_disabled={
              Object.keys(orderPrescriptionFormInput.orders[0]).every(key => key === "_id" ? true : orderPrescriptionFormInput.orders[0][key] === '')
            }
            is_checked={isIssueOrderToPatient} 
            onClick={() => setIsIssueOrderToPatient(!isIssueOrderToPatient)}
          />
        </div>

      </div>

      <Text
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#0E4D94",
        }}
      >
        Prescription
      </Text>

      {UI.box(10)}

      {orderPrescriptionFormInput.prescriptions.map((input, i) => (
        <>
          <div className="flex">
            <div className="relative" style={{ flex: "100%" }}>
              <input
                autocomplete="off"
                style={{
                  fontSize: "14px",
                  resize: "none",
                  color: "black",
                  width: "100%",
                  padding: "10px 14.44px",
                  borderRadius: "10px",
                  border: "2px solid #C9C9C9",
                  marginTop: "5px",
                }}
                placeholder={"Generic Drug Name with Strength/Form"}
                onChange={(e) => onHandleChangeOrder(e, i, "prescriptions")}
                onBlur={() =>
                  setTimeout(() => {
                    setsuggestion([]);
                  }, 200)
                }
                name="medication"
                value={input.medication}
              />
              {getIndex.name === "medication" && getIndex.i === i && (
                <div
                  className="absolute inset-0 z-10 "
                  style={{ marginTop: "52px" }}
                >
                  {suggestion.map((res) => (
                    <div
                      className="bg-white px-3 py-2 hover:bg-blue-100 cursor-default"
                      onClick={() => {
                        const list = {
                          ...orderPrescriptionFormInput,
                          prescriptions: [
                            ...orderPrescriptionFormInput.prescriptions,
                          ],
                        };
                        list.prescriptions[i].medication = res.option;
                        setOrderPrescriptionFormInput({
                          ...orderPrescriptionFormInput,
                          prescriptions: list.prescriptions,
                        });
                        setsuggestion([]);
                      }}
                    >
                      <span style={{ fontSize: "16px" }}>{res.option}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {UI.box(10)}
            <div className="relative" style={{ flex: "100%" }}>
              <input
                autocomplete="off"
                style={{
                  fontSize: "14px",
                  resize: "none",
                  color: "black",
                  width: "100%",
                  padding: "10px 14.44px",
                  borderRadius: "10px",
                  border: "2px solid #C9C9C9",
                  marginTop: "5px",
                }}
                placeholder={"Quantity"}
                onChange={(e) => onHandleChangeOrder(e, i, "prescriptions")}
                onBlur={() =>
                  setTimeout(() => {
                    setsuggestion([]);
                  }, 100)
                }
                name="number"
                type="number"
                value={input.number}
              />
            </div>
          </div>

          {UI.box(10)}

          <input
            autocomplete="off"
            style={{
              fontSize: "14px",
              resize: "none",
              color: "black",
              width: "100%",
              padding: "10px 14.44px",
              borderRadius: "10px",
              border: "2px solid #C9C9C9",
              marginTop: "5px",
            }}
            placeholder={"Brand Name"}
            onChange={(e) => onHandleChangeOrder(e, i, "prescriptions")}
            onBlur={() =>
              setTimeout(() => {
                setsuggestion([]);
              }, 200)
            }
            name="brandname"
            value={input.brandname}
          />
          {/* 
            {
              getIndex.name === "medicalTest" && getIndex.i === i &&
              suggestion.map(res => (
                <p style={{fontSize:'16px'}} className='bg-white px-3 py-1 m-0  hover:bg-blue-100 cursor-default ' onClick={() => {
                  const list = {...orderPrescriptionFormInput, prescriptions: [...orderPrescriptionFormInput.prescriptions]};
                  list.prescriptions[i].medicalTest = res.option;
                  setOrderPrescriptionFormInput({...orderPrescriptionFormInput, prescriptions: list.prescriptions});
                  setsuggestion([])
                }}> 
                  {res.option}  
                </p>
              ))
            } */}

          {UI.box(10)}

          <textarea
            style={{
              fontSize: "14px",
              resize: "none",
              color: "black",
              height: "90px",
              width: "100%",
              padding: "10px 14.44px",
              borderRadius: "10px",
              border: "2px solid #C9C9C9",
              marginTop: "5px",
            }}
            placeholder={"Signa"}
            onChange={(e) => onHandleChangeOrder(e, i, "prescriptions")}
            onBlur={() =>
              setTimeout(() => {
                setsuggestion([]);
              }, 100)
            }
            name="notes"
            value={input.notes}
          />

          {UI.box(10)}
        </>
      ))}

      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <button
          className="my-3 flex items-center gap-2 py-2 px-4 rounded-md font-semibold"
          style={{ background: "none", border: "3px solid #0E4D94" }}
          onClick={() => addInputField("prescriptions")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={3}
            style={{ color: "#0E4D94" }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4v16m8-8H4"
            />
          </svg>
          <strong style={{ fontSize: "15px", color: "#0E4D94" }}>
            Add Prescription
          </strong>
        </button>
      </View>

      <div className="flex justify-between items-center">
        <Checkbox
          text="Issue to Patient"
          is_disabled={
            Object.keys(orderPrescriptionFormInput.prescriptions[0]).every(key => key === "_id" ? true : orderPrescriptionFormInput.prescriptions[0][key] === '')
          }
          is_checked={isIssuePrescriptionToPatient} 
          onClick={() => setIsIssuePrescriptionToPatient(!isIssuePrescriptionToPatient)}
        />

        <div className="flex gap-1 items-center">
          <button
            onClick={() =>
              _this.show_modal(
                <EPrescriptionModal
                  appointment={_this.state.appointment}
                  encounterNotesData={encounterNotesData}
                  onHideModal={() => {
                    _this.hide_modal();
                  }}
                />
              )
            }
            className="p-3 font-semibold flex rounded-md text-white"
            style={{ background: "#0E4D94" }}
          >
            View E-Prescription Document
          </button>

          <button
            onClick={() =>
              _this.show_modal(
                <DoctorsOrderModal
                  appointment={_this.state.appointment}
                  encounterNotesData={encounterNotesData}
                  onHideModal={() => _this.hide_modal()}
                />
              )
            }
            className="p-3 font-semibold flex rounded-md text-white ml-4"
            style={{ background: "#0E4D94" }}
          >
            View Order Document
          </button>
        </div>
        
      </div>
      {
        <>
          <p className="text-lg text-red-500 m-0 font-semibold">
            {validationMessage.error}
          </p>
          <p className="text-lg text-green-500 m-0 font-semibold">
            {validationMessage.success}
          </p>
        </>
      }

      

      {/* <View
      style={{
        width: "100%",
        flexDirection: "row",
        justifyContent:'flex-end'
      }}
    >
      <button  className='my-3 flex items-center gap-2 py-2 px-4 rounded-md font-semibold'style={{background:'#0E4D94'}} onClick={submit}>    
        <img height={15} width={15} src={UI.UPDATE_PEN} alt=""/>
        <strong className='text-white' style={{fontSize:'15px'}}>Save Entry</strong>
      </button>
    </View> */}
    </div>
  );
};

export default OrderPrescription;

// const StyledAntdCheckbox = styled(AntdCheckbox)`

//   & span {
//     color: #707070;
//   }

//   & .ant-checkbox-wrapper {
//     display: flex;
//     /* align-items: center; */
//   }

//   & .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus .ant-checkbox-inner {
//       border-color: none;
//     }

//   & .ant-checkbox {
//     border: 2px solid #C2C2C2;
//     border-radius: 5px;
//   }

//   & .ant-checkbox-checked .ant-checkbox-inner {
//     background: ${UI.colors.secondary};
//     border: 2px solid ${UI.colors.secondary};
    
//   }
// `