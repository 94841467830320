import React from "react";
import styled from "styled-components";
import { Button } from "antd";

const SubmitButton = (props) => {
  const handleClick = () => {
    document.getElementById("submit-button").blur();
  };

  return (
    <StyledSubmitButton
      {...props}
      id="submit-button"
      type="primary"
      htmlType="submit"
      onClick={handleClick}
    >
      {props.children}
    </StyledSubmitButton>
  );
};

export default SubmitButton;

const StyledSubmitButton = styled(Button)`
  border-radius: 12px;
  border-color: #0e4d94;
  width: 123px;
  height: 55px;
  background-color: #0e4d94;
  color: #ffffff;

  font-size: 1.125rem; //18px
  font-family: Poppins;
  line-height: 1.25rem; //20px
  letter-spacing: 0px;
  text-align: center;
`;
