import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import PromoWinnersTable from "./PromoWinnersTable";
import { mem } from "../../shared/custom-react-native";

const context = "marketing";

export default class MarketingPromoWinners extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Promo Winners",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
    });
  }

  onCreate = () => {
    this.setState({id: JSON.parse(mem.get("admin")).id});
  };

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <PromoWinnersTable _this={_this}/>
      </MainLayout>
    );
  }
}