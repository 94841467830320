import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import "./css.css";
// import PromotionStats from "./PromotionStats";
import RegistrationPopUpTable from "../../ComponentsFC/Marketing/registrationpopup/RegistrationPopUpTable";
// import ServiceMarketingsApi from "../../api_services/marketings";
// import { Failed } from "../_components/Modal";

const context = "marketing";
export default class MarketingPopUpBanners extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "App Content",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
    });
  }

  render() {
    const _this = this;

    return (
      <MainLayout
        context={context}
        _this={_this}
        additional={"registration-popup"}
      >
        <RegistrationPopUpTable _this={_this} />
      </MainLayout>
    );
  }
}
