import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";

const context = "finance";

export default class FinanceDoctorsMasterfile extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Doctors Masterfile",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,

      sort: "-createdAt",
      status: "",
      name: "",
    });
  }

  onCreate = async () => {
    setTimeout(() => {
      this.get_data();
    }, 100);
  };

  get_data = async () => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/doctors",
      params: {},
      onFail: (err) => {},
      onSuccess: (response) => {
        const data = UI.extract_data(response);

        let new_data = data.data.map((item, index) => {
          item.doctor_name = UI.DataHelper.carespan.get_name(item.name);
          item.fullname = UI.DataHelper.carespan.get_name(item.name);
          item.phone = UI.DataHelper.carespan.get_phone(item.telecom);
          item.address = "-";
          item.tin = "-";
          item.withholding_tax = item.withholdingTax;
          item.withholding = item.withholdingTax;
          item.vatable_string = item.vatable ? "Yes" : "No";
          item.bank_name = item?.financialInformation?.bankName ?? " ";
          item.bank_branch = item?.financialInformation?.bankBranch ?? " ";
          item.bank_account_number =
            item?.financialInformation?.bankAccountNumber ?? " ";
          item.bank_account_name =
            item?.financialInformation?.bankAccountName ?? " ";

          return item;
        });

        new_data = new_data.filter(
          (item) => item?.provider?.isDoctorsOnboardingApproved === "Approved"
        );

        this.setState(
          {
            all_data: new_data,
            filtered_data: new_data,
          },
          () => {
            this.reload_table("doctors_list");
          }
        );
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  compare = (a, b) => {
    if (a.last_nom < b.last_nom) {
      return -1;
    }
    if (a.last_nom > b.last_nom) {
      return 1;
    }
    return 0;
  };

  sort_by = (format, key) => {
    //format can be asc or desc
    const { all_data } = this.state;

    all_data.sort(UI.dynamicSort(format === "asc" ? key : "-" + key));
    UI.set_input_value("search", "");

    this.setState(
      {
        data: all_data,
      },
      () => {
        this.reload_table("doctors_list");
      }
    );
  };

  export_to_csv = async () => {
    const { all_data } = this.state;

    const csv_data = all_data.map((item, index) => {
      const d = {
        Row: index + 1,
        "Doctor Name": item.doctor_name ?? " ",
        Phone: item.phone ?? " ",
        Address: item.address ?? " ",
        TIN: item.tin ?? " ",
        "Withholding tax %": item.withholdingTax ?? " ",
        Vatable: item.vatable ? "Yes" : "No" ?? " ",
        "Bank Name": item.bank_name ?? " ",
        "Bank Branch": item.bank_branch ?? " ",
        "Bank Account Number": item.bank_account_number ?? " ",
        "Bank Account Name": item.bank_account_name ?? " ",
        "Disbursement Schedule": item.disbursement_schedule ?? " ",
      };

      return d;
    });

    UI.DownloadCSV(
      "Doctors Masterfile " + UI.get_current_date_string(),
      csv_data
    );
  };

  custom_sort = (key, value) => {
    const { all_data } = this.state;
    let items = all_data.filter((item) => item[key] + "" === value);
    UI.set_input_value("search", "");
    if (value) {
      this.reload_table("doctors_list", items);
    } else {
      this.reload_table("doctors_list", all_data);
    }
  };

  render() {
    const _this = this;
    const {
      // height,
      width,
      // isMobile
    } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <Text style={styles.box_header_text}>Doctors Masterfile</Text>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Schedule"}
              isRequired={false}
              onChange={(e) => {
                this.custom_sort("disbursement_schedule", e);
              }}
            >
              <option value="">Select Status</option>
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Withholding Tax"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "withholding");
              }}
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Doctor Name"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "fullname");
              }}
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={880}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"doctors_list"} _this={_this} />
              </View>
            </View>

            <InputForm
              onChange={(e) => {
                UI.search_table("doctors_list", e, this.state.all_data, "data");
              }}
              is_keyword={true}
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
            />

            <View
              style={{
                alignSelf: width > 880 ? "flex-start" : "center",
                marginTop: 8,
                marginLeft: 10,
              }}
            >
              <Layout.Btn
                color={UI.colors.primary}
                text={"Export to CSV"}
                onClick={() => {
                  this.export_to_csv();
                }}
                height={40}
                width={150}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            disable_pagination={false}
            state_name={"doctors_list"}
            _this={_this}
            columns={[
              // { ID: "id" },
              { Name: "doctor_name" },
              { Phone: "phone" },
              { Address: "address" },
              { TIN: "tin" },
              { "Withholding Tax %": "withholding_tax" },
              { Vatable: "vatable" },
              { "Bank Name": "bank_name" },
              { "Disbursement Schedule ": "disbursement_schedule" },
              // { Status: "status" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              const edit_doctor = async () => {
                mem.save("edit_doctor", JSON.stringify(item));

                UI.goTo("/finance/edit-masterfile");
              };

              return {
                doctor_name: (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginLeft: 10,
                    }}
                  >
                    <Text
                      style={{
                        maxWidth: 80,
                        marginRight: 10,
                        textAlign: "center",
                      }}
                    >
                      {UI.DataHelper.carespan.get_name(item.name)}
                    </Text>
                  </View>
                ),

                phone: item.phone,
                address: item.address,
                tin: item.tin,
                withholding_tax: item.withholding_tax,
                vatable: item.vatable_string,
                bank_name: item.bank_name,
                disbursement_schedule: item.disbursement_schedule,

                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onClick={edit_doctor}>
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
