// import bundle from "interpolate-range";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm, { Checkbox } from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import "./css.css";

const context = "voucher";

export default class VoucherHealthpassEdit extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Health Pass List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
      id:"",
      title: "",
      short_description:"",
      bundled_sku:[],
      sku_bundle:[],
      quantity:"",
      price:"",
      discount:"",
      start_date:"",
      end_date:"",
      remember_me: false,
      isAddMoreVouchers: false,
      allowed_users:[],
      users: [],
    });
  }

  onCreate = () => {
    this.load_sku();
  };
  load_sku = async () => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/healthpass/bundles",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        this.setState(
          {
            sku_bundle: data.data,
          }
        );
        this.load_data();
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }
  load_users = async () => {
    this.show_loading();
    this.setState({
      isLoading: true,
    });
    UI.webRequest({
      method: "get",
      url: "admin/cms/users",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        const new_data = data.map((item, index) => {
          return { label: item.email, value: item._id };
        });
        this.setState({
          users: new_data,
        });
      },
      onFinish: () => {
        this.hide_loading();
        this.setState({
          isLoading: false,
        });
      },
    });
  };

  load_data = async () => {
    const data = await mem.get("edit_healthpass");
    if (data) {
      let campaign = JSON.parse(data);

      const {
        _id,
        title,
        numVouchersToGenerate,
        shortDescription,
        bundledSKU,
        // initialQuantity,
        description,
        status,
        price,
        discount,
        startDate,
        endDate
      } = campaign;

      this.setState({
        id: _id,
        remember_me: numVouchersToGenerate === 0 ? true : false
      });
      UI.set_input_value("title", title ?? "");
      // UI.set_input_value("quantity", numVouchersToGenerate === 0 ? initialQuantity : numVouchersToGenerate);
      UI.set_input_value("description", description ?? "");
      UI.set_input_value("short_description", shortDescription ?? "");
      UI.set_multiple_select_value("bundled_sku",  bundledSKU?.map(sku => sku));
      UI.set_select_value("status", status ?? "");
      UI.set_input_value("price", price ?? "");
      UI.set_input_value("discount", discount ?? "");
      UI.set_date_value("start_date",  new Date(startDate ?? ""));
      UI.set_date_value("end_date", new Date(endDate ?? ""));

    }
  };


  submit = async () => {
    UI.clear_errors();

    let {
      title,
      // numVouchersToGenerate,
      // initialQuantity,
      // description,
      status,
      // quantity,
      description,
      short_description,
      bundled_sku,
      price,
      discount,
      start_date,
      end_date,
      allowed_users,
      quantity,
      isAddMoreVouchers
    } = this.state;

    if (!title) {
      await UI.error_form("title", "Title is required.");
    }

    // if (!quantity) {
    //   await UI.error_form("quantity", "Quantity is required.");
    // }

    if (!status) {
      await UI.error_form("status", "Status is required.");
    }

    if (isAddMoreVouchers) {
      if (allowed_users.length === 0) {
        await UI.error_form("quantity", "Supply Stock is required.");
      }
      if (allowed_users.length === 0) {
        await UI.error_form(
          "allowed_users",
          "Please select at least one user."
        );
      }
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      this.hide_loading();
      return;
    }

    let addMore = ""

    if (isAddMoreVouchers) {
      addMore = {
        numVouchersToGenerate: parseInt(quantity),
        allowedUsers: allowed_users,
       }
    }

    this.show_loading();

    UI.webRequest({
      method: "put",
      url: "admin/healthpass/" + this.state.id,
      params: {
        title: title,
        isAddMore: isAddMoreVouchers,
        description: description,
        shortDescription:short_description,
        bundledSKU:bundled_sku,
        status: status,
        price: price,
        discount: discount,
        startDate: start_date,
        endDate: end_date,
        ...addMore
      },
      onFail: async (err) => {
        this.show_modal(
          <Failed description={err.data.message} onDismiss={this.hide_modal} />
        );
      },
      onSuccess: async (response) => {
        this.show_modal(
          <Success
            descriptionCenter={"Successfully edited the healthpass."}
            onDismiss={() => {
              this.hide_modal();
              UI.goTo("/voucher/healthpass-list");
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Edit Healthpass
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to complete registration
            </Text>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Healthpass Title"}
              placeholder={"Enter Healthpass Title"}
              state_name={"title"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.title}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Price"}
              placeholder={"Enter Price"}
              state_name={"price"}
              style={{ flex: 1 }}
              isRequired={false}
              isNumber={true}
              maxLength={UI.Constants.limits.price}
            />
  
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Discount"}
              placeholder={"Enter Percent Discount"}
              state_name={"discount"}
              style={{ flex: 1 }}
              isRequired={false}
              isNumber={true}
              maxLength={UI.Constants.limits.price}
            />
            {UI.box(10)}
          </UI.Row>

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Short Description"}
              placeholder={"Short Description"}
              state_name={"short_description"}
              style={{ flex: 1, flexBasis:"67%"}}
              isRequired={false}
              maxLength={UI.Constants.limits.description}
            />

            {UI.box(10)}
            
            <View style={{flex:1, flexBasis:"33%"}}>
              <Layout.SelectPicker
                _this={_this}
                title={"Bundle with SKU"}
                isRequired={false}
                isMultiple={true}
                state_name={"bundled_sku"}
              >
                <option value="">Select SKU</option>
                {this.state.sku_bundle.map((item, index) => {
                 return <option key={index} value={item._id}>{item.name}</option>
                })}
              </Layout.SelectPicker>
            </View>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <Layout.DatePicker
              _this={_this}
              title={"Start Date"}
              isRequired={true}
              placeholder={"mm/dd/yyyy"}
              state_name={"start_date"}
              date_props={{}}
            />
            {UI.box(10)}
            <Layout.DatePicker
              _this={_this}
              title={"End Date"}
              isRequired={true}
              placeholder={"mm/dd/yyyy"}
              state_name={"end_date"}
              date_props={{}}
              
            />
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={true}
              state_name={"status"}
            >
              <option value="">Select Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Layout.SelectPicker>
          </UI.Row>

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <View style={{flex: 1, justifyContent:'flex-end'}}>
            {UI.box(15)}
              <UI.Row _this={_this} breakpoint={1070}>
                <span className="font-semibold" style={{color: UI.colors.primary}}>
                  Add more voucher
                </span>
                {UI.box(15)}
                <Checkbox
                  _this={_this}
                  text={""}
                  onClick={(value) => {
                    this.setState({
                      isAddMoreVouchers: !_this.state.isAddMoreVouchers,
                    }, () => {
                      if(this.state.users.length === 0) {
                        this.load_users();
                      }
                      if (!_this.state.isAddMoreVouchers) {
                        this.setState({
                          allowed_users: [],
                          quantity: 0
                        })
                      }
                    });
                  }}
                  is_checked={_this.state.isAddMoreVouchers}
                  color={"#2E2E2E"}
                />
              </UI.Row>
            {UI.box(10)}
            </View>
            {UI.box(10)}
            <View style={{flex: 1}}>
              {   
              _this.state.isAddMoreVouchers &&     
                <InputForm
                  _this={_this}
                  title={"Number of Vouchers"}
                  placeholder={"Enter Number of Vouchers"}
                  state_name={"quantity"}
                  style={{ flex: 1 }}
                  isRequired={true}
                  isNumber={true}
                  maxLength={UI.Constants.limits.price}
                /> 
              }
            </View>
            {UI.box(10)}
            <View style={{flex: 1}}>
              {_this.state.isAddMoreVouchers && (
                <UI.Row breakpoint={970} _this={_this}>
                  <Layout.SelectPicker
                      _this={_this}
                      title={"Select Users"}
                      isRequired={true}
                      state_name={"allowed_users"}
                      isMultiple={true}
                      onChange={(e) => {}}
                    >
                      <option value="">Select Users</option>
                      {
                        this.state.users.map((item) => {
                          return <option value={item.value}>{item.label}</option>;
                        })
                      }
                  </Layout.SelectPicker>
                </UI.Row>
              )}
            </View>
          </UI.Row>

          {UI.box(20)}

          <UI.Row _this={_this} breakpoint={1070}>
            <InputForm
              _this={_this}
              title={"Description"}
              placeholder={"Enter Description Here"}
              state_name={"description"}
              style={{ flex: 1 }}
              height={100}
              isRequired={false}
              type={"big"}
              maxLength={UI.Constants.limits.description}
            />
          </UI.Row>

          {UI.box(20)}

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"EDIT HEALTHPASS"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});
