import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import "./css.css";
export default class Statistics extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const _this = this.props._this;
    const { data } = _this.state

    const approved = data.filter(item => item.approvalStatus === 'Approved').length;
    const pending = data.filter(item => item.approvalStatus === 'Pending').length;
    const disapproved = data.filter(item => item.approvalStatus === 'Disapproved').length;
    
    return (
      <UI.Row breakpoint={1175} _this={_this} style={{justifyContent: "space-between"}}>
        <View style={styles.main_container}>
          <View style={{ flex: 1, minWidth: 260 }}>
            <Text style={styles.header_1}>Total User Group Created</Text>
            {UI.box(10)}
            <View style={{ flexDirection: "row", alignItems: "center"}}>
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.USER_GFX_2}
              />
              {UI.box(20)}
              <View>
                <Text style={styles.content_1}>{data.length}</Text>
                <Text style={styles.label_1}>Total User Group</Text>
              </View>
            </View>
          </View>
        </View>
        {UI.box(20)}
        <View style={{ ...styles.main_container, flex: 1 }}>
          <UI.Row breakpoint={1170} _this={_this} style={{ width: "100%", justifyContent: "space-between" }}>
              <Text style={styles.header_1}>Site Stats: {UI.get_current_date_string()}</Text>
              {UI.box(10)}
              <Layout.SortFilter
                btn_1={{
                  text: "Year",
                  onClick: (text) => {
                      _this.getSiteStats('year')
                  },
                }}
                btn_2={{
                  text: "Month",
                  onClick: (text) => {
                      _this.getSiteStats('month')
                  },
                }}
                btn_3={{
                  text: "Day",
                  onClick: (text) => {
                      _this.getSiteStats('day')
                  },
                }}
                onChange={(val) => {
                  _this.setState({
                    [`${"sort_filter_"}${"site_stats"}`]: val,
                  });
                }}
              _this={_this}
              state_name={"site_stats"}
            />
          </UI.Row>
          {UI.box(25)}
          <UI.Row breakpoint={1175} _this={_this} style={{justifyContent:'space-around'}}>
            <DashItem
              _this={_this}
              icon={UI.USER_GROUP_PENDING}
              num={pending}
              color={UI.colors.secondary}
              text={"Total Pending"}
            />

            {UI.box(10)}
            <DashItem
              _this={_this}
              icon={UI.USER_GROUP_APPROVED}
              num={approved}
              color={UI.colors.secondary}
              text={"Total Approved"}
            />

            {UI.box(10)}
            <DashItem
              _this={_this}
              icon={UI.USER_GROUP_DISAPPROVED}
              num={disapproved}
              color={UI.colors.secondary}
              text={"Total Disapproved"}
            />
            </UI.Row>
        </View>
      </UI.Row>
    );
  }
}

const DashItem = (
  props = { _this: null, icon: "", num: "", text: "", color: "" }
) => {
  return (
    <View style={{ flexDirection: "row", flex: 1 }}>
      <Image
        height={60}
        width={60}
        tintColor={"transparent"}
        source={props.icon}
      />

      <View style={{ marginLeft: 15 }}>
        <Text
          style={{ ...styles.main_header, color: props.color, fontSize: 22 }}
        >
          {props.num}
        </Text>
        <Text style={{ ...styles.header_2, fontSize: 12 }}>{props.text}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  main_container: {
    borderRadius: 5,
    backgroundColor: "white",
    padding: 20,
    ...Shadow._3(),
    maxHeight: 180
  },

  header_1: {
    fontSize: 16,
    color: UI.colors.secondary,
    fontWeight: "bold",
  },

  content_1: {
    fontSize: 24,
    color: UI.colors.secondary,
    fontWeight: "900",
  },

  label_1: {
    fontSize: 12,
    color: "#535353",
    fontWeight: "bold",
  },

  sort_filter_text: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#BFBFBF",
  },
  sort_filter: {
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 2,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#C9C9C9",
  },
  box_header_subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#C9C9C9",
    alignSelf: "flex-end",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.secondary,
    fontSize: 25,
    fontWeight: "bold",
  }
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
