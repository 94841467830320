import React from 'react'
import UI from '../../../shared/Components/UI/js'
import TouchableOpacity, { mem } from '../../../shared/custom-react-native'
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { Image } from 'antd';
import ServiceAuthApi from '../../../api_services/auth'

const LoginUsingGoogle = ({_this}) => {
  return (
    <TouchableOpacity>
      <GoogleOAuthProvider 
        clientId={UI.Constants.GOOGLE_CLIENT_ID}
      >
        <LoginGoogle _this={_this}/>
      </GoogleOAuthProvider>
    </TouchableOpacity>
  )
}

const LoginGoogle = ({_this}) => {

  const login = useGoogleLogin({
    onSuccess: (response) => {
      const accessToken = response.access_token;

      const payload = {
        accessToken: accessToken,
        role: "provider",
      };
      
      _this.setState({ isLoading:true });
      ServiceAuthApi.signInUsingGoogle(payload).then(res => {
        _this.setState({ isLoading:false });
        if (res.status === 200) {
          let role = res.role || "provider"
          const current_role = UI.role_map[role];
          mem.save("jwt_token", res.token);
          mem.save("role", role);
          UI.goTo(`/${current_role}/dashboard`);
        }
      });
    },
  });

  return (
    <Image preview={false} src={UI.GOOGLE_ICON} alt="google_icon" className='cursor-pointer' onClick={() => login()} />
  )
}

export default LoginUsingGoogle
