import UI from "../shared/Components/UI/js";
import ServiceApi from "./service";

class LguPartnersApi {

    baseURL = 'admin';

    async getAllLgu (params) {
        const response = await ServiceApi.get(this.baseURL+'/lgu', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async addLgu (payload) {
        const response = await ServiceApi.post(this.baseURL+`/lgu`, payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async deleteLgu (id) {
        const response = await ServiceApi.delete(this.baseURL+`/lgu/${id}`);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }
}

export default new LguPartnersApi()