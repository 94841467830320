import React, { useState } from "react";
import { animated, Spring } from "react-spring";
import UI from "../../shared/Components/UI/js";
import {
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import ServiceDoctorsApi from "../../api_services/doctors";
import { Success } from "../_components/Modal";
import mem from "../../shared/Components/Memory/js";
import { Alert, Space } from 'antd'
const zIndex = 10002;

const initialError = {
  name: "",
  email: "",
  emailOrNumber: null,
  emailExist: null
}

const Modal = ({ 
  _this,
  actionType, 
  onDismiss,
  item = null,
  getNotificationCMS
}) => {

const [name, setName] = useState(item?.name ?? "");
const [email, setEmail] = useState(item?.email ?? "");
const [contactNumber, setContactNumber] = useState(item?.contactNumber ?? "");
const [selectedId, setSelectedId] = useState(item?.id ?? null);
const [errors, setErrors] = useState(initialError);
const [submitting, setSubmitting] = useState(false);
const [errorNumber, setErrorNumber] = useState(false);
const [maxLength, setMaxLength] = useState(16 - contactNumber.length);

const initial = {
  backgroundColor: "white",
  borderRadius: "18px",
  ...Shadow._3(),
  zIndex: zIndex + 1,
  display: "inline-flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: 800,
};

const before = { ...styles.modal_before, ...initial };
const during = { ...styles.modal_during, ...initial };

const onCancel = () => {
  if (onDismiss) {
    setSelectedId(null)
    onDismiss();
  }
};

const handleSubmit = () => {

  const data = mem.get("onboard_data");
  const user = JSON.parse(data);
  const userId = user?.id
  
  let payload = Object.assign({},
    name === "" ? null : {name},
    email === "" ? null : {email},
    contactNumber === "" ? null : {contactNumber}
  );
  
  setErrors(initialError);
  setSubmitting(true);

  if(!name) {
    setErrors(prevState => ({...prevState, name: "Name is required"}));
  }
  
  if(email && !UI.validate_email(email)){
    setErrors(prevState => ({...prevState, email: "Please enter valid email"}));
  }

  if(!email && !contactNumber){
    setErrors(prevState => ({...prevState, emailOrNumber: "Email or Phone is required!"}));
  }

  if(!name || (email && !UI.validate_email(email)) || (!email && !contactNumber)) {
    setSubmitting(false);
    return;
  }

  if(!selectedId) {
    ServiceDoctorsApi.createNotificationCMS(userId, payload)
    .then(() => {
      _this.show_modal(
        <Success
          title={"Success!"}
          description={"Successfully Added Contact CMS"}
          onDismiss={() => {
            _this.hide_modal();
            getNotificationCMS();
          }}
        />
      );
      setSubmitting(false);
    })
    .catch(err => {

      if(err.message) {
        const error = err.message;

        setErrors(prevState => ({
          ...prevState,
          emailExist: error === 'Email already exists' || 'Doctor current email not allowed!' ? error : null
        }))
      }
      setSubmitting(false);
    })

  } else {

    payload = { ...payload, id: selectedId }

    ServiceDoctorsApi.updateNotificationCMS(userId, payload)
      .then(() => {
        _this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully Updated Contact CMS"}
            onDismiss={() => {
              _this.hide_modal();
              getNotificationCMS();
            }}
          />
        );
      }).catch(err => console.log(err))
  }
}

return (
  <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
    {(style) => (
      <animated.div style={{ ...style, justifyContent: "flex-start" }}>
        <View
          style={{
            ...styles.md_header,
            backgroundColor: UI.colors.secondary,
          }}
        >
          <TouchableOpacity onClick={onCancel} style={styles.md_close}>
            <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="" />
          </TouchableOpacity>

          <View style={styles.md_header_text_container}>
            <Text style={{ ...styles.md_header_text, textAlign: "center" }}>
              {actionType} Reference
            </Text>
          </View>

          {UI.box(60)}
        </View>
        <div className="pt-8 pb-10 px-10">
          <div className="text-center font-bold text-lg" style={{ color: "#535353" }}>{actionType} Reference Information?</div>

          {errors.emailOrNumber && 
            <Space className="mt-4" direction="vertical" style={{ width: '100%' }}>
              <Alert
                message={errors.emailOrNumber}
                type="error"
                closable
              />
            </Space> 
          }
          
          <div className="mt-5">
            <label 
              style={{color: UI.colors.primary}} 
              className="block text-base mb-1 font-medium" 
              htmlFor="name"
            >
              Name
            </label>
            <input
              id="name"
              style={{ border: `2px solid ${errors.name !== '' ? '#F05566' : '#CACACA'}` }}
              className="border rounded-xl w-80 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline text-base" 
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            { errors.name !== "" && <div style={{ color: "#F05566" }} className="font-medium">{errors.name}</div> }
          </div>

          <div className="mt-5">
            <label 
              style={{color: UI.colors.primary}} 
              className="block text-base mb-1 font-medium" 
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              id="email"
              style={{ border: `2px solid ${errors.email !== '' || errors.emailExist ? '#F05566' : '#CACACA'}` }}
              className="border rounded-xl w-80 py-2 px-3 focus:outline-none focus:shadow-outline text-base" 
              type="text"
              value={email} 
              onChange={(e) => setEmail(e.target.value)}
            />
            { errors.email !== "" && <div style={{ color: "#F05566" }} className="font-medium">{errors.email}</div> }
            { errors.emailExist && <div style={{ color: "#F05566" }} className="font-medium">{errors.emailExist}</div> }
          </div>

          <div className="mt-5 relative">
            <label 
              style={{color: UI.colors.primary}} 
              className="block text-base mb-1 font-medium" 
              htmlFor="number"
            >
              Contact Number
            </label>
            <input
              id="number"
              style={{border: "2px solid #CACACA"}} 
              className="border rounded-xl w-80 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline text-base" 
              type="text" 
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              onInput={(e) => {
                if(e.target.value.match(/[a-z]/i)?.index >= 0 ? true : false){
                  setErrorNumber(true)
                } else {
                  setMaxLength(16 - e.target.value.length);
                  setErrorNumber(false)
                }
                e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1')}
              }
              maxLength={16}
            />
            <span style={{ fontSize: 10, color: "#CACACA" }} className="absolute bottom-0 right-0 mr-2">{maxLength}</span>
          </div>
          { errorNumber && <div style={{ color: "#F05566" }} className="font-medium">Only numbers are accepted.</div> }

          <div className="mt-12 flex justify-center">
            <button
              onClick={handleSubmit} 
              className={`p-3 font-semibold rounded-md text-white text-center flex items-center justify-center ${submitting ? 'cursor-wait' : 'cursor-pointer'}`} 
              style={{ background: "#0E4D94" , minWidth: 135}}
            >{submitting ? `Saving...` : 'Submit'}</button>
            <button 
              className="p-3 font-semibold flex rounded-md bg-white ml-4" 
              style={{ color: "#0E4D94", border: "1px solid #0E4D94" }}
              onClick={onCancel}
            >No, Don't Save</button>
          </div>
        </div>
      </animated.div>
    )}
  </Spring>
)
}

const styles = StyleSheet.create({
  md_btn_text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
  md_button: {
    padding: 30,
    backgroundColor: "#004F99",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  md_button_outline: {
    padding: 30,
    backgroundColor: "#FFFFFF",
    border: "1px solid #004F99",
    color: "#004F99",
    fontWeight: "bold",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  optionLabel: {
    marginLeft: "1em",
    color: "black",
    fontWeight: "bold",
    fontSize: 18,
  },
  simpleBody: {
    padding: 2,
    color: "black",
    fontSize: 16,
  },
  md_text_content: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 14,
    color: "#004F99",
    width: 230,
  },
  md_content_container: {
    padding: 25,
    alignItems: "center",
  },
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: "#00CBFF",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
  modal_description_text: {
    color: "#585858",
    fontSize: 16,
    alignSelf: "flex-start",
    fontWeight: "bold",
  },
  modal_header_text: {
    color: "#3E3E3E",
    fontSize: 22,
    fontWeight: "bold",
    alignSelf: "flex-start",
  },
  modal_btn_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: "white",
  },
  modal_black_btn: {
    padding: 20,
    borderRadius: 10,
    backgroundColor: "#111111",
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 20,
  },
  modal_description: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#434343",
    textAlign: "center",
  },
  modal_title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#0E0E0E",
    marginTop: 20,
    textAlign: "center",
  },
  modal_during: {
    opacity: 1,
    minHeight: 220,
    minWidth: 220,
    marginTop: 0,
  },
  modal_before: {
    opacity: 0,
    minHeight: 100,
    minWidth: 100,
    marginTop: 200,
  },
  default_modal: {
    backgroundColor: "white",
    borderRadius: 10,
    ...Shadow._3(),
    zIndex: zIndex + 1,
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    maxWidth: 320,
  },
  modal_container: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    zIndex: zIndex,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    // backgroundColor: "rgba(255,255,255,0.85)",
  },
  option_label: {
    color: "black",
    fontSize: "1.2em",
  },
  option_button: {
    width: 30,
    height: 30,
    border: 0,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: "black",
  },
});

export default Modal;