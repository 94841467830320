import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import AdminCmsLguPartnersDoctorsTable from "./AdminCmsLguPartnersDoctorsTable";

const context = "admin";
const Constants = new APIConstants();
export default class AdminCmsLguPartnersEdit extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "App Content",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,

      name: "",
      status: "",
      image_file: "",

    });
  }

  onCreate = async () => {
    this.load_data();
  };

  upload_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        image: file.uri,
        image_file: file.file,
        did_upload: true,
      }
    );
  };

  load_data = async () => {
    const data = mem.get("edit_lgu_partner");
    if (data) {
      const record = JSON.parse(data);

      UI.set_input_value("name", record.name ?? "");
      UI.set_select_value("status", record.status ?? "");
      this.setState({
        id: record._id,
        image_path: record.imagePath,
      });
    }
  };

  submit = async () => {
    UI.clear_errors();
    const { name, status, id, image_path, image_file } = this.state;

    if (!image_path) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Upload LGU Partner Image"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      return;
    }

    if (!name) {
      await UI.error_form("name", "LGU Name is required.");
    }

    if (!status) {
      await UI.error_form("status", "Status is required.");
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    this.show_loading();
    UI.webRequest({
      method: "put",
      url: "admin/lgu",
      params: {
        name: name,
        id: id,
        status: status,
        image: this.state.image ? image_file : image_path,
      },
      isMultiPart: true,
      onFail: (err) => {
        console.log(err);
        const data = err.data;
        const error_message = data.error;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (response) => {
        console.log(response);
        UI.clear_forms();
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully Updated the Specialty"}
            onDismiss={() => {
              UI.clear_forms();
              UI.goBack();
              this.hide_modal();
            }}
          />
        );
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;
    const {  did_upload } = _this.state;
    const image = `${Constants.STORAGE_BASE_URL}?path=${this.state.image_path}`;

    return (
      <MainLayout context={context} additional={"lgu-partners"} _this={_this}>
        <View style={{...styles.main_box_content, marginBottom: 30}}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Update LGU Partner
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add item
            </Text>
          </UI.Row>
          <UI.Row breakpoint={970} _this={_this}>
              <View style={{ height: 120, width: 120 }}>
                <Layout.FilePicker
                  _this={_this}
                  title={""}
                  additional={""}
                  text={""}
                  state_name={"icon_specialty"}
                  onChange={async (file) => {
                  }}
                  height={120}
                  component={
                    this.state.image ?
                    <img
                      style={{ 
                        borderRadius: 5
                      }}
                      src={this.state.image}
                      alt=""
                    />
                    : 
                    <img
                      style={{ 
                        borderRadius: 5
                      }}
                      src={this.state.image_path ? image : UI.SAMPLE_PRODUCT }
                      alt=""
                    />
                  }
                />
              </View>
              {UI.box(20)}
              <strong className="text-red-500">*</strong>
              <View style={{ flex: 1.5, justifyContent: "center" }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
                >
                  {did_upload
                    ? "Specialty Icon Uploaded."
                    : " No Icon Uploaded."}{" "}
                  {/* No Icon Uploaded.  */}
                  Recommended Size: 200 px x 200 px, svg or png file
                </Text>
                {UI.box(10)}

                <Layout.Btn
                  color={UI.colors.secondary}
                  text={"Upload Quicklink/Button Icon"}
                  onClick={() => {
                    this.upload_picture();
                  }}
                  width={220}
                />
              </View>
          </UI.Row>

          {UI.box(50)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"LGU Partner Name"}
              placeholder={"Enter LGU Partner Name"}
              state_name={"name"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.name}
            />
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={true}
              state_name={"status"}
            >
              <option value="">Item Status (Active or Inactive)</option>
              <option value="Active" selected={this.state.status === "Active" ? true : false}>Active</option>
              <option value="Inactive" selected={this.state.status === "Inactive" ? true : false}>Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style = {{flex: 1}}></View>
            <View style={{ flex: 1 }}></View>
          </UI.Row>

          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.secondary}
              onClick={() => {
                this.submit();
              }}
              text={"UPDATE"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>

        <AdminCmsLguPartnersDoctorsTable _this={this}/>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});
