import React from "react";
import UI from "../../shared/Components/UI/js";
import {
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CalendarHelper from "./CalendarHelper";
import "./css.css";

const border_color = "#EBEBEB";

const MonthView = (
  props = {
    _this: null,
    context: null,
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    container_width: 0,
    width_limit: 0,
  }
) => {
  const week_days = CalendarHelper.get_weekly_days(props.month, props.year);
  let index = -1;
  const WeekRows = week_days.map((item) => {
    index++;
    return (
      <MonthRows
        _this={props._this}
        context={props.context}
        days={item}
        container_width={props.container_width}
        width_limit={props.width_limit}
        index={index}
      />
    );
  });

  return (
    <View style={{ width: "100%" }}>
      <CalendarHeaders _this={props._this} context={props.context} />
      {WeekRows}
    </View>
  );
};

const MonthRows = (
  props = { days: [], _this: null, context: null, container_width: 0, index: 0 }
) => {
  const { context, 
    // _this 
  } = props;
  let index = 0;
  // let month = _this.state.current_month;
  // let year = _this.state.current_year;

  const Cells = props.days.map((item) => {
    index++;
    const events = context.props.data.filter((ev) => {
      return CalendarHelper.is_in_range(
        ev.timestamp,
        item.range.start,
        item.range.end
      );
    });

    return (
      <MonthCell
        _this={props._this}
        day={parseInt(item.day)}
        is_active={item.is_active}
        range={item.range}
        is_trailing={index === props.days.length}
        events={events}
        month={item.month}
        year={item.year}
        context={props.context}
        container_width={props.container_width}
        width_limit={props.width_limit}
        index={props.index}
      />
    );
  });

  return <View style={styles.month_row}>{Cells}</View>;
};

const MonthCell = (
  props = {
    day: "",
    is_active: false,
    is_trailing: false,
    events: [],
    _this: null,
    range: { start: 0, end: 0 },
    month: 0,
    year: 0,
    context: null,
    container_width: 0,
    width_limit: 0,
    index: 0,
  }
) => {
  const { context, _this } = props;
  let text_color = props.is_active ? "#2E2E2E" : "#BFBFBF";
  let bg_color = "white";
  if (props.events.length > 0) {
    text_color = UI.colors.secondary;
    bg_color = "#EDFBFF";
  }
  let id = props.day + "_" + props.is_active;
  let cell_width = UI.measure(props.day + "_" + props.is_active).width;
  if (!cell_width) {
    cell_width = 54;
  }
  let additional_style = { backgroundColor: bg_color, borderRightWidth: 2 };

  let onClick = () => {
    const select_month =
      props.month > 11 ? 0 : props.month < 0 ? 11 : props.month;
    const select_day = props.day;
    const select_year =
      props.month > 11
        ? props.year + 1
        : props.month < 0
        ? props.year - 1
        : props.year;
    let d = new Date();
    d.setMonth(select_month);
    d.setDate(select_day);
    d.setFullYear(select_year);

    context.setState(
      {
        selected_month: select_month,
        selected_date: select_day,
        selected_year: select_year,
        selected_events: props.events,
        selected_week_items: context.state.week_items[props.index],
        selected_timestamp: d.getTime(),
        calendar_type: "day",
      },
      () => {
        _this.setState({
          [context.state.my_filter_id]: "day",
        });
        context.animate_sidebar();
      }
    );
  };

  let is_today = CalendarHelper.get_is_today(props.range.start);
  let active_style = {
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "rgba(0, 79, 153, 0.5)",
  };

  let is_active = false;
  let { selected_month, selected_date, selected_year } = context.state;

  if (
    selected_month === props.month &&
    selected_date === props.day &&
    selected_year === props.year
  ) {
    is_active = true;
  }
  let selected_style = {
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: "rgba(4, 204, 255, 0.5)",
  };

  return (
    <View
      id={id}
      style={
        is_active
          ? { ...styles.calendar_cell, ...selected_style }
          : is_today
          ? { ...styles.calendar_cell, ...active_style }
          : props.is_trailing
          ? { ...styles.trailing_date, ...additional_style }
          : { ...styles.calendar_cell, ...additional_style }
      }
      onClick={onClick}
    >
      <Text
        style={{
          fontSize: 12,
          fontWeight: "bold",
          color: text_color,
          marginTop: 5,
          marginLeft: 5,
        }}
      >
        {props.day}
      </Text>
      <View style={{ flex: 1 }}></View>

      {props.events.length > 0 && (
        <TouchableOpacity
          style={{ ...styles.event_item, maxWidth: cell_width / 2 }}
        >
          <Text style={styles.event_text}>
            {props.events.length} {cell_width < 70 ? "" : "Consults"}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

const CalendarHeaders = () => {
  return (
    <View style={styles.header_container}>
      <HeaderItem text={"Sun"} align={"right"} />
      <HeaderItem text={"Mon"} align={"right"} />
      <HeaderItem text={"Tue"} align={"right"} />
      <HeaderItem text={"Wed"} align={"right"} />
      <HeaderItem text={"Thu"} align={"right"} />
      <HeaderItem text={"Fri"} align={"right"} />
      <HeaderItem text={"Sat"} align={"none"} />
    </View>
  );
};

const HeaderItem = (props = { text: "", align: "left" }) => {
  let align = props.align.charAt(0).toUpperCase() + props.align.slice(1);
  return (
    <View
      style={{
        ...styles.header_item,
        ["border" + align + "Width"]: 2,
      }}
    >
      <Text style={styles.header_text}>{props.text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  no_events: {
    width: "100%",
    alignItems: "center",
    paddingTop: 40,
  },
  empty_text: {
    color: "#BFBFBF",
    width: 200,
    textAlign: "center",
    fontSize: 15,
    fontWeight: "bold",
    marginTop: 30,
  },
  event_text: {
    fontSize: 10,
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  event_item: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: UI.colors.primary,
    alignSelf: "flex-end",
    margin: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  month_row: {
    borderColor: border_color,
    flex: 1,
    borderStyle: "solid",
    borderWidth: 2,
    marginTop: -2,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    flexDirection: "row",
  },
  calendar_cell: {
    minHeight: 91,
    maxHeight: 91,
    flex: 1,
    borderStyle: "solid",
    borderWidth: 0,
  },
  trailing_date: {
    minHeight: 91,
    maxHeight: 91,
    flex: 1,
    borderStyle: "solid",
    borderWidth: 0,
  },
  patient_item: {
    flexDirection: "row",
    padding: 10,
    paddingBottom: 15,
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  bottom_border: {
    padding: 20,
    borderStyle: "solid",
    borderColor: border_color,
    borderBottomWidth: 2,
  },
  overlay: {
    position: "absolute",
    backgroundColor: "black",
    borderRadius: 5,
  },
  menu_btn: {
    height: 25,
    width: 25,
    justifyContent: "center",
    alignItems: "center",
    marginTop: -2,
    marginRight: 20,
  },
  header_item: {
    flex: 1,
    borderColor: border_color,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
  },
  header_text: {
    fontSize: 15,
    color: "#2E2E2E",
    fontWeight: "bold",
    textAlign: "center",
  },
  header_container: {
    minHeight: 35,
    width: "100%",
    borderColor: border_color,
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    flexDirection: "row",
  },
  side_bar: {
    height: "100%",
    width: 230,
    borderRightWidth: 2,
    borderRightStyle: "solid",
    borderColor: border_color,
    backgroundColor: "white",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    zIndex: 9,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  main_content_container: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    width: "100%",
  },
});

export default MonthView;
