import React from "react";
import styled from "styled-components";
// import moment from "moment";
import { Row, Upload, Typography, Col } from "antd";
import UploadFolderIcon from "../../../icons/UploadFileFolder";

const DropFiles = React.memo((props) => {
  return (
    <StyledUploadFile {...props}>
      <StyledUploadRow>
        <UploadFolderIcon />
        <Col>
          <StyledUploadTitle>Drop Your Files Here</StyledUploadTitle>
          <StyledExtraDesc>{props.extradescription}</StyledExtraDesc>
        </Col>
      </StyledUploadRow>
    </StyledUploadFile>
  );
});

export default DropFiles;

const StyledUploadFile = styled(Upload.Dragger)`
  width: 454px !important;
  height: 137px !important;

  border-radius: 12px !important;
  border: 2px dashed #c9c9c9 !important;
`;

const StyledUploadRow = styled(Row)`
  align-items: center;
  justify-content: center;
`;

const StyledUploadTitle = styled(Typography.Paragraph)`
  color: #02488d;
  margin-left: 33.5px;
  margin-bottom: 0 !important;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500; // medium
  line-height: 20px; // 23px
  letter-spacing: 0px;
`;

const StyledExtraDesc = styled(Typography.Paragraph)`
  color: #acacac;
  margin-left: 33.5px;
  margin-top: 5px;
  margin-bottom: 0 !important;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 500; // medium
  line-height: 12px;
  letter-spacing: 0px;
`;
