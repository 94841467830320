import React from "react";
import UI from "../../shared/Components/UI/js";
import { Text, View } from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import { AgreementConfirmation } from "../_components/Modal";
import MarketingConsentModal from "./Modal/MarketingConsent";
import MarketingConsentPDF from "./PDF/MarketingConsent";
import { pdf } from "react-pdf-renderer-v2";

const MarketingConsent = ({ _this }) => {
  return (
    <View style={{}}>
      <Text
        style={{
          color: UI.colors.primary,
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        Marketing Consent
      </Text>
      <Text style={{ color: "#333333", fontSize: 14, fontWeight: "bold" }}>
        Please sign the Marketing Consent.
      </Text>
      {UI.box(20)}

      <View>
        <Layout.Btn
          color={UI.colors.primary}
          onClick={() => {
            _this.show_modal(
              <AgreementConfirmation
                isConfirm={_this.state.confirmMarketingConsent}
                setIsConfirm={(cb) =>
                  _this.setState({ confirmMarketingConsent: cb })
                }
                title="Marketing Consent"
                checkboxTitle="By clicking this box, I agree to the terms and conditions of the Marketing Consent Form and to displaying my e-signature on it"
                onOk={{
                  text: "Proceed",
                  method: () => {
                    pdf(<MarketingConsentPDF _this={_this} />)
                      .toBlob()
                      .then((response) => {
                        return response;
                      })
                      .then((myBlob) => {
                        var file = new File([myBlob], "Marketing-Consent.pdf");

                        _this.auth_upload("marketing_consent_path", file);
                      });

                    _this.hide_modal();
                  },
                }}
                onDismiss={() => {
                  _this.hide_modal();
                }}
              >
                <MarketingConsentModal _this={_this} />
              </AgreementConfirmation>
            );
          }}
          width={300}
          text={"View and sign Marketing Consent"}
        />
        {UI.box(20)}
      </View>
    </View>
  );
};

export default MarketingConsent;
