import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import AdminMedicalAssociationsListTable from "./AdminMedicalAssociationsListTable";
import MainLayout from "../_components/MainLayout";
const context = "admin";

export default class AdminMedicalAssociationsList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "App Content",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
    });
  }

  render() {
    const _this = this;
    return (
      <MainLayout context={context} additional={"medical-associations"} _this={_this}>
        <AdminMedicalAssociationsListTable _this={_this} />
      </MainLayout>
    );
  }
}
