import React from "react";
import TeleconsultationRecords from "./TeleconsultationRecords";
import ElectronicMedicalRecords from "./ElectronicMedicalRecords";

const MedicalRecords = ({
  electronicMedicalRecords,
  setElectronicMedicalRecords,
  patient,
  showModal,
  hideModal,
}) => {
  return (
    <div style={{ background: "#F5FAFF" }} className="rounded-b-lg p-3">
      <div className="mt-4">
        <TeleconsultationRecords
          patient={patient}
          showModal={showModal}
          hideModal={hideModal}
        />
        <div className="mt-4">
          <ElectronicMedicalRecords
            patient={patient}
            electronicMedicalRecords={electronicMedicalRecords}
            setElectronicMedicalRecords={(data) =>
              setElectronicMedicalRecords(data)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MedicalRecords;
