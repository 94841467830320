import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import {
  CustomFailed,
  DeleteConfirmation,
  Failed,
  Success,
} from "../_components/Modal";
import "./css.css";

const context = "merchant";

export default class EcommerceProducts extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Products",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,
      groupSortAscDesc: ["name", "createdAt", "updatedAt"],
      groupSortLowToHigh: ["price", "salePrice", "quantity"],
      groupSortAscDescSelected: "",
      groupSortLowToHighSelected: "",
      productCategorySelected: "",
      productStatusSelected: "",

      sort_status: "",
      sort_product: "",
      sort_created: "",

      product_names: [],
    });
  }

  onCreate = () => {
    this.get_data(
      {
        sort: "-createdAt",
      },
      true
    );
  };

  get_data = async (params, reload) => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/products/merchant/me",
      params: params,
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.previews(data.data);

        let new_data = data.data.map((item) => {
          item.timestamp = new Date(item.createdAt).getTime();
          item.salePrice = parseInt(item.salePrice || 0);
          item.price = parseInt(item.price);
          return item;
        });
        if (reload) {
          this.setState({
            all_data: new_data,
            products_data: new_data,
          });
        }
        this.reload_table("products");
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  previews = async (data) => {
    UI.set_images(data, "imagePath", "preview", "products", "ecommerce");
  };

  sortGroupItems = (group, key, value) => {
    this.setState({ [group + "Selected"]: key + ":" + value });

    let counter = 0;
    this.state[group].forEach((keyName) => {
      this.setState(
        {
          [keyName]: keyName === key ? true : false,
        },
        () => {
          counter++;
          if (counter === this.state[group].length) {
            this.filterBySelections();
          }
        }
      );
    });
  };

  delete = async (id) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this item?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();
          this.show_loading();
          UI.webRequest({
            method: "delete",
            url: "admin/products/" + id,
            params: {},
            onFail: async (err) => {},
            onSuccess: async (response) => {
              const filter = this.state.data.filter((a) => a._id !== id);
              this.setState({ data: filter, all_data: filter });
              this.reload_table("products");
            },
            onFinish: () => {
              this.onCreate();
            },
          });
        }}
      />
    );
  };

  filterBySelections = () => {
    const {
      // all_data,
      productCategorySelected,
      productStatusSelected,
      groupSortAscDescSelected,
      groupSortLowToHighSelected,
      // isChangeProductCategory,
      // isChangeProductStatus,
      products_data,
    } = this.state;

    // if (isChangeProductCategory) {
    //   if (productStatusSelected && isChangeProductCategory) {
    //     const data = this.state.all_data
    //     let result = data.filter((item) => item['category'] == productCategorySelected);
    //     this.setState({
    //       all_data:result,
    //       data:result
    //     }, () => {
    //       this.setState({isChangeProductCategory: false})
    //       this.reload_table("products");
    //     })
    //   } else
    //   this.onCreate()
    //     setTimeout(() => {
    //       const data = this.state.all_data
    //       let result = data.filter((item) => item['category'] == productCategorySelected);
    //       this.setState({
    //         all_data:result,
    //         data:result
    //       }, () => {
    //         this.setState({isChangeProductCategory: false})
    //         this.reload_table("products");
    //       })
    //     }, 1000)
    // }  else if (productCategorySelected && isChangeProductStatus) {
    //   const data = this.state.all_data
    //   let result = data.filter((item) => item['status'] == productStatusSelected);
    //   this.setState({
    //     all_data:result,
    //     data:result
    //   }, () => {
    //     this.setState({isChangeProductStatus: false})
    //     this.reload_table("products");
    //   })
    // } else if (isChangeProductStatus) {
    //   if (productCategorySelected && isChangeProductStatus) {
    //     const data = this.state.all_data
    //     let result = data.filter((item) => item['status'] == productStatusSelected);
    //     this.setState({
    //       all_data:result,
    //       data:result
    //     }, () => {
    //       this.setState({isChangeProductStatus: false})
    //       this.reload_table("products");
    //     })
    //   }
    //   // this.setState({isChangeProductCategory: false})
    //   this.onCreate()
    //   setTimeout(() => {
    //     const data = this.state.all_data
    //     let result = data.filter((item) => item['status'] == productStatusSelected);
    //     this.setState({
    //       all_data:result,
    //       data:result
    //     }, () => {
    //       this.setState({isChangeProductStatus: false})
    //       this.reload_table("products");
    //     })
    //   }, 1000)
    // } else if (productStatusSelected && isChangeProductCategory) {
    //   const data = this.state.all_data
    //   let result = data.filter((item) => item['category'] == productCategorySelected);
    //   this.setState({
    //     all_data:result,
    //     data:result
    //   }, () => {
    //     this.setState({isChangeProductCategory: false})
    //     this.reload_table("products");
    //   })
    // } else {
    //   const data = this.state.all_data
    //   const groupResult = groupSortAscDescSelected.split(":");
    //   let result3 = data.sort(UI.dynamicSort(groupResult[1] == "asc" ? groupResult[0] : "-" + groupResult[0]));

    //   const groupResult2 = groupSortLowToHighSelected.split(":");
    //   let finalResult = result3.sort(UI.dynamicSort(groupResult2[1] == "asc" ? groupResult2[0] : "-" + groupResult2[0]));

    //   this.reload_table("products", finalResult);
    // }
    // products_data
    let result = products_data.filter(
      (item) => item["category"] === productCategorySelected
    );
    if (!productCategorySelected) {
      result = products_data;
    }

    let result2 = result.filter(
      (item) => item["status"] === productStatusSelected
    );
    if (!productStatusSelected) {
      result2 = result;
    }

    const groupResult = groupSortAscDescSelected.split(":");
    let result3 = result2.sort(
      UI.dynamicSort(
        groupResult[1] === "asc" ? groupResult[0] : "-" + groupResult[0]
      )
    );

    const groupResult2 = groupSortLowToHighSelected.split(":");
    let finalResult = result3.sort(
      UI.dynamicSort(
        groupResult2[1] === "asc" ? groupResult2[0] : "-" + groupResult2[0]
      )
    );

    this.setState({ all_data: finalResult });
    this.reload_table("products", finalResult);
  };

  // sort_by = (format, key) => {
  //   //format can be asc or desc
  //   const { all_data } = this.state;

  //   all_data.sort(UI.dynamicSort(format == "asc" ? key : "-" + key));
  //   UI.set_input_value("search", "");

  //   this.setState(
  //     {
  //       data: all_data,
  //     },
  //     () => {
  //       this.reload_table("products");
  //     }
  //   );
  // };

  // custom_sort = (key, value) => {
  //   const { all_data } = this.state;
  //   let items = all_data.filter((item) => item[key] + "" == value);
  //   UI.set_input_value("search", "");
  //   if (value) {
  //     this.reload_table("products", items);
  //   } else {
  //     this.reload_table("products", all_data);
  //   }
  // };

  set_sort_value = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      () => {
        this.filterBySelections();
      }
    );
  };

  import_csv = async () => {
    this.show_modal(
      <View style={{ backgroundColor: "white", borderRadius: 10 }}>
        <View
          style={{ ...styles.md_header, backgroundColor: UI.colors.secondary }}
        >
          <TouchableOpacity
            onClick={() => this.hide_modal()}
            style={styles.md_close}
          >
            <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="Close" />
          </TouchableOpacity>

          <View style={styles.md_header_text_container}>
            <Text style={{ ...styles.md_header_text, textAlign: "center" }}>
              {""}
            </Text>
          </View>

          {UI.box(60)}
        </View>

        <View style={{ ...styles.md_content_container, width: "100%" }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <TouchableOpacity
              onClick={() => {
                const csv_data = [""].map((item, index) => {
                  const d = {
                    Name: "",
                    SKU: "",
                    Category: "",
                    Description: "",
                    Status: "",
                    "Inventory Count": "",
                    "Regular Price": "",
                    "Sale Price": "",
                    "Purchase Limit": "",
                  };

                  return d;
                });

                UI.DownloadCSV(
                  "Sample CSV Format" + UI.get_current_date_string(),
                  csv_data
                );
                // this.deleteTransaction(item._id);
              }}
              style={{
                ...styles.md_button,
                backgroundColor: UI.colors.secondary,
              }}
            >
              <Text style={styles.md_btn_text}>Download Sample CSV</Text>
            </TouchableOpacity>
            {UI.box(10)}
            <TouchableOpacity
              onClick={async () => {
                const content = await UI.get_csv_contents();
                const all_text = content.split("\r\n");
                await this.validate_csv(all_text);
                const product_list = await this.proccess_products_csv(content);
                await this.process_product_name_address(
                  product_list
                );
                this.show_loading();
                this.hide_modal();
                UI.webRequest({
                  method: "post",
                  url: "admin/products/bulk-upload",
                  params: {
                    bulk: product_list,
                  },
                  // isMultiPart: true,
                  onFail: async (err) => {
                    const data = err.data;
                    const error_message = data.m;
                    console.log(err.data)
                    this.hide_loading();
                    // Done uploading file with 0 uploaded product and found 2 existing SKUs!
                    this.show_modal(
                      <Failed
                        title={"Failed"}
                        description={error_message}
                        onDismiss={() => {
                          this.hide_modal();
                        }}
                      />
                    );
                  },
                  onSuccess: async (response) => {
                    this.hide_loading();
                    const data = response.data;
                    const message = data.m;
                    this.onCreate();
                    const duplicateItem = data.d.duplicate.map((res) => {
                      res.a = res.name;
                      res.b = res.sku;
                      return res;
                    });
                    if (message.includes("found 0")) {
                      this.show_modal(
                        <Success
                          title={"Success!"}
                          description={`Successfully upload all the product.`}
                          onDismiss={() => {
                            this.hide_modal();
                          }}
                        />
                      );
                    } else {
                    this.hide_loading();
                      this.show_modal(
                        <Failed
                          modal_icon={UI.ERROR_MINUS}
                          header_title={`Incomplete Upload ${
                            data.d.uploaded.length
                          }/${
                            data.d.duplicate.length + data.d.uploaded.length
                          }`}
                          isScrollableContent={true}
                          title={"failed!"}
                          description={`Total Uploaded SKU: ${data.d.uploaded.length}`}
                          descriptionOne={`Total Failed SKU: ${data.d.duplicate.length}`}
                          // descriptionTwo={`Product A - SKU: `}
                          descriptionThree={duplicateItem}
                          onDismiss={() => {
                            this.hide_modal();
                          }}
                        />
                      );
                    }
                  },
                  onFinish: () => {
                    this.hide_loading();
                  },
                });
              }}
              style={{
                ...styles.md_button,
                backgroundColor: UI.colors.secondary,
                // border:'1px solid ' + UI.colors.secondary,
              }}
            >
              <Text style={styles.md_btn_text}>Upload Product CSV</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  validate_csv = async (items) => {
    return new Promise((resolve, reject) => {
      if (items.length === 0) {
        return;
      }

      const uploadProductTemplate =
        "Name,SKU,Category,Description,Status,Inventory Count,Regular Price,Sale Price";

      if (
        items[0] === uploadProductTemplate ||
        items[0].includes(uploadProductTemplate)
      ) {
        resolve();
      } else {
        this.show_modal(
          <CustomFailed
            title={"Process Failed"}
            description_title={""}
            description={"Invalid CSV Format"}
            _this={this}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      }
    });
  };

  proccess_products_csv = async (content) => {
    return new Promise((resolve, reject) => {
      if (content.length === 0) {
        resolve([]);
        return;
      }
      const regex1 = "/n/n/";
      const regex2 = "\r\n";
      content = UI.replaceAll(content, regex2, regex1);
      content = UI.replaceAll(content, "\n", "");
      content = UI.replaceAll(content, regex1, regex2);
      var array = content.split(regex2);
      let result = [];

      let headers = array[0].split(",");

      for (let i = 1; i < array.length - 1; i++) {
        let obj = {};
        let str = array[i];
        let s = "";
        let flag = 0;
        for (let ch of str) {
          if (ch === '"' && flag === 0) {
            flag = 1;
          } else if (ch === '"' && flag === 1) flag = 0;
          if (ch === "," && flag === 0) ch = "|";
          if (ch !== '"') s += ch;
        }

        let properties = s.split("|");

        for (let j in headers) {
          obj[headers[j]] = properties[j];
        }
        result.push(obj);
      }

      // result.shift();
      // result.pop();
      resolve(result);
    });
  };

  process_product_name_address = async (arr = []) => {
    return new Promise((resolve, reject) => {
      if (arr.length === 0) {
        resolve([]);
      }

      const new_data = [];
      arr.forEach((item, index) => {
        item.name = item["Name"];
        item.sku = item["SKU"];
        item.category = item["Category"];
        item.description = item["Description"] ? item["Description"] : "--";
        item.status = item["Status"] ? item["Status"] : "Inactive";
        item.inventoryCount =  item["Inventory Count"] ? parseInt(item["Inventory Count"]) : 0;
        item.price = item["Regular Price"] ? parseInt(item["Regular Price"]) : 0;
        item.salePrice = item["Sale Price"];
        // item.mwellService = item["mWell Service"]
        item.limit = item["Purchase Limit"];
        item.salePrice = item["Sale Price"];
        item.skuWebLink = item["SKU Web Link"];
        // item.otherInformation = item["SKU Web Link"]

        delete item["Name"];
        delete item["SKU"];
        delete item["Category"];
        delete item["Description"];
        delete item["Status"];
        delete item["Inventory Count"];
        delete item["Regular Price"];
        delete item["Sale Price"];
        // delete item["mWell Service"]
        delete item["Purchase Limit"];
        delete item["SKU Web Link"];
        // new_data.push(item);

        if (item.price) {
          new_data.push(item);
        }

        if (index + 1 === arr.length) {
          resolve(new_data);
        }
      });
    });
  };

  render() {
    const _this = this;
    const { width } = _this.state;
    const Constants = new APIConstants();

    let product_id = 0;

    const { name, createdAt, updatedAt, price, salePrice, quantity } =
      this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <Text style={styles.box_header_text}>Product List</Text>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Product Name"}
              isRequired={false}
              onChange={(e) => {
                this.sortGroupItems("groupSortAscDesc", "name", e);
              }}
            >
              <option value="" selected={!name}>
                Sort by
              </option>
              <option value="asc">A - Z</option>
              <option value="desc">Z - A</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Date Created"}
              isRequired={false}
              onChange={(e) => {
                this.sortGroupItems("groupSortAscDesc", "createdAt", e);
              }}
            >
              <option value="" selected={!createdAt}>
                Sort by
              </option>
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Date Updated"}
              isRequired={false}
              onChange={(e) => {
                // this.sort_by(e, "updatedAt");
                this.sortGroupItems("groupSortAscDesc", "updatedAt", e);
              }}
            >
              <option value="" selected={!updatedAt}>
                Sort by
              </option>
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>
          </UI.Row>

          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Product Price"}
              isRequired={false}
              onChange={(e) => {
                // this.sort_by(e, "price");
                this.sortGroupItems("groupSortLowToHigh", "price", e);
              }}
            >
              <option value="" selected={!price}>
                Sort by
              </option>
              <option value="asc">Low to High</option>
              <option value="desc">High to Low</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Product Sales"}
              isRequired={false}
              onChange={(e) => {
                // this.sort_by(e, "salePrice");
                this.sortGroupItems("groupSortLowToHigh", "salePrice", e);
              }}
            >
              <option value="" selected={!salePrice}>
                Sort by
              </option>
              <option value="asc">Low to High</option>
              <option value="desc">High to Low</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Product Inventory"}
              isRequired={false}
              onChange={(e) => {
                // this.sort_by(e, "quantity");
                this.sortGroupItems("groupSortLowToHigh", "quantity", e);
              }}
            >
              <option value="" selected={!quantity}>
                Sort by
              </option>
              <option value="asc">Low to High</option>
              <option value="desc">High to Low</option>
            </Layout.SelectPicker>
          </UI.Row>

          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Product Category"}
              isRequired={false}
              onChange={(e) => {
                // if (!e) {
                //   this.setState({productCategorySelected: ""})
                //   return this.onCreate()
                // }
                // this.setState({isChangeProductCategory: true})
                this.set_sort_value("productCategorySelected", e);
              }}
            >
              <option value="">Filter All</option>
              {Constants.product_category?.map((category) => {
                const value = category === "Labs and Clinic" ? "Laboratory" : category
                const option = category;

                return (
                  <option value={value}>{option}</option>
                );
              })}
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Product Status"}
              isRequired={false}
              onChange={(e) => {
                // if (!e) {
                //   this.setState({productStatusSelected: ""})
                //   return this.onCreate()
                // }
                // this.setState({isChangeProductStatus: true})
                this.set_sort_value("productStatusSelected", e);
                // this.custom_sort("status", e);
              }}
            >
              <option value="">Filter All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={850}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"products"} _this={_this} />
              </View>
            </View>

            <InputForm
              onChange={(e) => {
                UI.search_table("products", e, this.state.all_data, "data");
              }}
              is_keyword={true}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
            />

            <View
              style={{
                alignSelf: width > 950 ? "flex-start" : "center",
                marginTop: 8,
                marginLeft: 10,
              }}
            >
              <Layout.Btn
                color={UI.colors.header}
                text={"Add Product"}
                onClick={() => {
                  UI.goTo("/merchant/add-product");
                }}
                height={40}
                width={120}
              />
            </View>
            <View
              style={{
                alignSelf: width > 950 ? "flex-start" : "center",
                marginTop: 8,
                marginLeft: 10,
              }}
            >
              <Layout.Btn
                color={UI.colors.header}
                text={"Bulk Upload"}
                onClick={() => {
                  this.import_csv();
                }}
                height={40}
                width={120}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            state_name={"products"}
            _this={_this}
            columns={[
              // { ID: "id" },
              // { Preview: "preview" },
              { "Product Name": "product_name" },
              { "Product SKU": "product_sku" },
              { "Product Status": "status" },
              { "Product Price": "price" },
              { "Product Inventory": "inventory" },
              // { "Limit": "limit" },
              { "Date Created": "date_added" },
              { "Date Update": "date_updated" },
              { "Product Sales": "product_sales" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              const date_added = UI.timestampToDate(
                new Date(item.createdAt).getTime()
              );
              const date_updated = UI.timestampToDate(
                new Date(item.updatedAt ?? item.createdAt).getTime()
              );

              // **Comment out, currently unused
              // const image = this.state["preview" + item._id] ?? UI.PRODUCT_BOX;
              product_id++;
              const id = UI.pad(product_id, 12);
              return {
                // id: id,
                // preview: (
                //   <View style={{ flexDirection: "row", alignItems: "center" }}>
                //     <img style={{ height: 35, width: 35 }} src={image} />
                //   </View>
                // ),
                product_name: item.name,
                product_sku: item.sku ?? "N/A",
                status: UI.get_active_text(item),
                price: "Php " + item.price,
                inventory: item.quantity ?? 0,
                // limit: item.limit ?? 0,
                date_added:
                  date_added.month +
                  "/" +
                  date_added.day +
                  "/" +
                  date_added.year,
                date_updated:
                  date_updated.month +
                  "/" +
                  date_updated.day +
                  "/" +
                  date_updated.year,
                product_sales: item.salePrice ? parseInt(item.salePrice) : 0,
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        mem.save("view_product", JSON.stringify(item));
                        mem.save("product_id", id);
                        UI.goTo("/merchant/view-product");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.VIEW_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={async () => {
                        mem.save("edit_product", JSON.stringify(item));
                        mem.save("product_id", id);
                        UI.goTo("/merchant/edit-product");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={async () => {
                        this.delete(item._id);
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.DELETE_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.all_data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 18,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.header,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: "#00CBFF",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
  md_content_container: {
    padding: 25,
    alignItems: "start",
  },
  md_btn_text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
  md_button: {
    padding: 30,
    // backgroundColor: "#004F99",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
});
