import React
// { useState, useCallback, useEffect } 
from "react"; // useState
import styled from "styled-components";
// import moment from "moment";
import { 
  // Avatar, 
  Row, 
  // Col,
   Typography } from "antd";
// import ServiceAttendanceApi from "../../../api_services/Doctors-Portal/DPAttendanceApi";
// import StatisticsBlueBoxIcon from "../../../icons/StatisticsBlueBox";
// import StatisticsRedBoxIcon from "../../../icons/StatisticsRedBox";

const LeaveRequest = React.memo(() => {
  // const [
    // totalDays,
    //  setTotalDays] = useState(null);
  // const [
    // leaveUsed,
    //  setLeaveUsed] = useState(null);

  // const getLeaveStatistics = useCallback(async () => {
  //   try {
  //     let urlParams = {};

  //     const apiResponse = await ServiceAttendanceApi.getLeaveStatistics(
  //       urlParams
  //     );

  //     setTotalDays(apiResponse?.leaveCountThisMonth ?? "0");
  //     setLeaveUsed(apiResponse?.totalLeaveCount ?? "0");
  //   } catch (err) {
  //     // Handle Errors Here
  //     console.error(err);
  //     if (err.response) {
  //       let statusCode = err.response.data.c;
  //       let errorMsg = err.response.data.m;
  //       if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
  //       if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
  //     }
  //   }
  // }, [setTotalDays, setLeaveUsed]);

  // useEffect(() => {
  //   getLeaveStatistics();
  // }, [getLeaveStatistics]);

  return (
    <StatisticsWrapper align="middle">
      {/* <Col> */}
      <FeatureSoonTitle level={5}>{"Feature Coming Soon"}</FeatureSoonTitle>
      {/* </Col> */}
      {/* <Col flex="300px">
        <DataGroupRow>
          <Col>
            <StyledAvatar
              size={{
                xs: 24,
                sm: 32,
                md: 40,
                lg: 64,
                xl: 73,
                xxl: 73,
              }}
              icon={<StatisticsBlueBoxIcon />}
            />
          </Col>
          <DataLabel>
            <StyledDataPointText>{totalDays}</StyledDataPointText>
            <StyledTitleSmall>{"Total Leave Count"}</StyledTitleSmall>
          </DataLabel>
        </DataGroupRow>
      </Col>
      <Col>
        <DataGroupRow>
          <Col>
            <StyledAvatar
              size={{
                xs: 24,
                sm: 32,
                md: 40,
                lg: 64,
                xl: 73,
                xxl: 73,
              }}
              icon={<StatisticsRedBoxIcon />}
            />
          </Col>
          <DataLabel>
            <StyledDataPointText>{leaveUsed}</StyledDataPointText>
            <StyledTitleSmall>
              {"Total Leave Count This Month"}
            </StyledTitleSmall>
          </DataLabel>
        </DataGroupRow>
      </Col> */}
    </StatisticsWrapper>
  );
});

export default LeaveRequest;

const StatisticsWrapper = styled(Row)`
  margin-top: -27px;
`;

// const StyledAvatar = styled(Avatar)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const StyledTitleSmall = styled(Typography.Paragraph)`
//   margin-bottom: 0 !important;
//   font-size: 18px;
//   font-family: Poppins;
//   font-weight: 500;
//   color: #535353;
//   letter-spacing: 0px;
//   line-height: 27px;
//   text-align: left;
// `;

// const StyledDataPointText = styled(Typography.Paragraph)`
//   margin-bottom: 0 !important;
//   font-size: 35px;
//   font-family: Poppins;
//   font-weight: 500;
//   color: #00cbff;
//   letter-spacing: 0px;
//   line-height: 53px;
//   text-align: left;
// `;

// const DataGroupRow = styled(Row)`
//   align-items: center;
// `;

// const DataLabel = styled(Col)`
//   margin-left: 20px;
// `;

const FeatureSoonTitle = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.625rem !important; // 26px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #ccdeea !important;
  padding-left: 35px;

  margin-top: 72px;
  margin-bottom: 64px !important;
  margin-left: 163px;
  /* visibility: hidden; */
`;
