import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Radio, Typography, Col } from "antd";

const DoctorTypeEdit = ({ currentType, onChangeType }) => {
  const [doctorTypeValue, setDoctorTypeValue] = useState();
  const handleChangeDoctorType = (e) => {
    setDoctorTypeValue(e.target.value);
    onChangeType(e);
  };

  useEffect(() => {
    if (currentType === true || currentType === "contracted")
      setDoctorTypeValue("contracted");
    if (currentType === false || currentType === "consulting")
      setDoctorTypeValue("consulting");
  }, [currentType]);

  return (
    <FieldGroup>
      <FieldLabel>
        Doctor Type<span>*</span>
      </FieldLabel>
      <Radio.Group onChange={handleChangeDoctorType} value={doctorTypeValue}>
        <Radio value={"contracted"}>Contracted</Radio>
        <Radio value={"consulting"}>Consulting</Radio>
      </Radio.Group>
    </FieldGroup>
  );
};

export default DoctorTypeEdit;

const FieldGroup = styled(Col)`
  margin-right: 10px;
`;

const FieldLabel = styled(Typography.Title)`
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: Poppins !important;
  color: rgb(4, 204, 255) !important;
  margin-bottom: 1.5rem !important; //24px
  & span {
    color: #ff4d4d !important;
  }
`;
