import UI from "../../shared/Components/UI/js";
import ServiceApi from "../service";
// import APIConstants from "../../shared/modules/APIConstants";
// const Constants = new APIConstants();

class DPReferralApi {
  baseURL = "doctors_onboarding";

  async getReferralList(params) {
    const response = await ServiceApi.get(this.baseURL + `/referrals/list`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response?.status,
        data: response?.data?.d,
        message: response?.data?.m
      }
    }
  }

  async acceptOrDeclineReferral(referralId, approvedOrDisapproved) {
    const response = await ServiceApi.post(this.baseURL + `/referrals/update/${referralId}?type=${approvedOrDisapproved}`);

    if (response?.status === 200) {
      // const data = UI.extract_data(response);
      // data.status = 200;
      response.status = 200;
      return response;
    } else {
      return {
        status: response?.status,
        data: response?.data?.d,
        message: response?.data?.m
      }
    }
  }


}

export default new DPReferralApi();
