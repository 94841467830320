import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import { PatientReferralDetails } from "../../ComponentsFC/onboard/referral/index";

const context = "onboard";
export default class AdminDoctorAttendance extends Component {
  constructor() {
    super();
    this.state = {};
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Referral",
      context: context,
    });
  }

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <PatientReferralDetails />
      </MainLayout>
    );
  }
}
