import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";

const context = "admin";
export default class AdminInquiries extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Inquiries",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;
    const { 
      // height, 
      width, 
      // isMobile 
    } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <Text style={styles.box_header_text}>Product Inquiries</Text>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Product Name"}
              isRequired={false}
            >
              <option value="">Select Product</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Payment Method"}
              isRequired={false}
            >
              <option value="">Ascending</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Inquiry Date"}
              isRequired={false}
            >
              <option value="">Ascending</option>
            </Layout.SelectPicker>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={950}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <Layout.EntryPicker />
              </View>
            </View>

            <InputForm
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
            />
          </UI.Row>

          <CustomDatatable
            state_name={"nutrition_programs"}
            _this={_this}
            columns={[
              { ID: "id" },
              { "Customer Name": "customer_name" },
              { "Product Name": "product_name" },
              { Inquiry: "inquiry" },
              { "Inquiry Date": "inquiry_date" },
              { Action: "action" },
            ]}
            data={[
              {
                id: "001010",
                customer_name: (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image
                      height={35}
                      width={35}
                      tintColor={"transparent"}
                      source={UI.SAMPLE_USER}
                    />
                    <Text style={{ marginLeft: 10 }}>Customer Name</Text>
                  </View>
                ),
                product_name: (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image
                      height={35}
                      width={35}
                      tintColor={"transparent"}
                      source={UI.SAMPLE_PRODUCT}
                    />
                    <Text style={{ marginLeft: 10 }}>Product Name</Text>
                  </View>
                ),
                inquiry: "Inquiry description here",
                inquiry_date: "mm/dd/yyyy",
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        UI.goTo("/admin/view-inquiry");
                      }}
                    >
                      <Image
                        height={32}
                        width={32}
                        tintColor={"transparent"}
                        source={UI.VIEW_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              },
            ]}
            current_page={1}
            number_of_pages={20}
            on_page={(page, callback) => {}}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO CONSULTATION DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
