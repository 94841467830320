import Icon from "@ant-design/icons";
import React from "react";

const NotificationBellSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.491"
    height="30.116"
    viewBox="0 0 28.491 30.116"
  >
    <path
      id="notifications"
      d="M251,191.143c1.106-.953,1.316-2.788,1.61-5.281.336-4.931,2.074-8.447,5.618-9.721a4.6,4.6,0,0,1,8.8,0c3.544,1.274,5.281,4.79,5.617,9.721.295,2.493.5,4.328,1.611,5.281,1.358,1.19,2.619,2.493,2.619,4.132a2.453,2.453,0,0,1-2.731,2.465h-5.505a6.064,6.064,0,0,1-12.019,0h-5.5a2.446,2.446,0,0,1-2.731-2.465C248.379,193.636,249.64,192.333,251,191.143Zm11.626,9.119a2.516,2.516,0,0,0,2.549-2.522h-5.1A2.517,2.517,0,0,0,262.625,200.262Z"
      transform="translate(-248.379 -172.877)"
      fill="#fff"
    />
  </svg>
);

const NotificationBellIcon = (props) => {
  return <Icon component={NotificationBellSvg} {...props} />;
};

export default NotificationBellIcon;
