import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";

const context = "admin";
const default_enddate = "2050-09-29";
const default_startdate = "1970-01-01";
export default class AdminDoctorsOnCallAvailability extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Doctors On Call Availability",
      is_drawer_open: false,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,

      entries:30,

      start_date_dashed: default_startdate,
      end_date_dashed: default_enddate,

      doctors_on_call_availability_number_of_pages: 1,
      doctors_on_call_availability_current_page: 1,
    });
  }

  onCreate = async () => {
    setTimeout(() => {
      this.get_data({
        page: 1,
        limit:
          this.state[`${"doctors_on_call_availability"}${"_entries"}`] ??
          30,
      });
    }, 100);
  };

  apply_date_filter = async (page) => {
    await UI.clear_errors();
    if (default_startdate === this.state.start_date_dashed) {
      await UI.error_form("start_date", "Please enter start date");
    }
    if (default_enddate === this.state.end_date_dashed) {
      await UI.error_form("end_date", "Please enter end date");
    } else {
      console.log(this.state.entries)
      this.get_data({
        startDate: this.state.start_date_dashed,
        endDate: this.state.end_date_dashed,
        page: page ? page : 1,
        limit: parseInt(this.state.entries),
      });
    }
  };

  get_data = async (params, callback) => {
    let limit = parseInt(params.limit);
    params.limit = limit >= 30 ? 30 : limit

    this.show_loading();
    if (!params) {
      params = {};
    }
    this.setState({
      doctors_on_call_availability_current_page: params?.page ?? 1,
    });

    UI.webRequest({
      method: "get",
      url: "admin/doctors/attendance-reports",
      params: params,
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response).data;
        const results = UI.get_results(response);

        if (!results?.total) {
          results.total = results.docCount;
        }

        this.setState(
          {
            data: data,
            all_data: response.data.d,
          },
          () => {
            UI.get_entries(
              data,
              params.limit ?? 30,
              "doctors_on_call_availability",
              params.page,
              {
                total: results.total,
                pages: results.pages,
              }
            );
            this.hide_loading();
          }
        );
      },
      onFinish: () => {
        if (callback) {
          callback();
        }
        this.hide_loading();
      },
    });
  };

  export_to_csv = async () => {
    const { data } = this.state;
    const csv_data = data.map((item, index) => {
    const d = {
        Row: index + 1,
        "Doctor ID": UI.DataHelper.carespan.get_name(item.doctorID?.name) ?? " ",
        "Login Date": item.login ?? " ",
        "Logout Date": item.logout ?? " ",
        "Duration Time": item.duration ?? " ",
      };
      return d;
    });

    UI.DownloadCSV(
      "All Doctors On Call Availability" + UI.get_current_date_string(),
      csv_data
    );
     
    // this.show_loading("", 'Exporting all CSV data...');
    
    // UI.webRequest({
    //   method: "get",
    //   url: "admin/doctors/attendance-reports",
    //   params: {
    //     limit: all_data.results.total
    //   },
    //   onFail: async (err) => {},
    //   onSuccess: async (response) => {
    //     const data = UI.extract_data(response).data;
    //     const csv_data = data.map((item, index) => {
    //       const d = {
    //         Row: index + 1,
    //         "Doctor ID": UI.DataHelper.carespan.get_name(item.doctorID?.name) ?? " ",
    //         "Login Date": item.login ?? " ",
    //         "Logout Date": item.logout ?? " ",
    //         "Duration Time": item.duration ?? " ",
    //       };

    //       return d;
    //     });

    //     UI.DownloadCSV(
    //       "All Doctors On Call Availability" + UI.get_current_date_string(),
    //       csv_data
    //     );
    //     this.hide_loading();
    //   },
    //   onFinish: () => {
    //   },
    // });

    // const csv_data = all_data.map((item, index) => {
    //   const d = {
    //     Row: index + 1,
    //     "Doctor ID": UI.DataHelper.carespan.get_name(item.doctorID?.name) ?? " ",
    //     "Login Date": item.login ?? " ",
    //     "Logout Date": item.logout ?? " ",
    //     "Duration Time": item.duration ?? " ",
    //   };

    //   return d;
    // });

    // UI.DownloadCSV(
    //   "All Doctors On Call Availability" + UI.get_current_date_string(),
    //   csv_data
    // );
  };

  render() {
    const _this = this;
    const { width } = _this.state;
    let filters_doctors_on_call_availability =
      UI.measure("filters_doctors_on_call_availability").width ?? width;
    return (
      <MainLayout context={context} _this={_this}>
        <View id={"filters_doctors_on_call_availability"} style={styles.main_box_content}>
          <Text style={styles.title}>Date Filter</Text>

          <UI.Row breakpoint={540} _this={_this} basis={filters_doctors_on_call_availability}>
            <View style={{ width: 200 }}>
              <Layout.DatePicker
                _this={_this}
                placeholder={"Start Date"}
                state_name={"start_date"}
                will_correct={true}
              />
            </View>
            {UI.box(10)}
            <View style={{ width: 200 }}>
              <Layout.DatePicker
                _this={_this}
                placeholder={"End Date"}
                state_name={"end_date"}
                will_correct={true}
              />
            </View>
            {UI.box(10)}
            <Layout.Btn
              style={{
                alignSelf: filters_doctors_on_call_availability < 540 ? "flex-start" : "center",
                marginTop: 5,
                width: filters_doctors_on_call_availability < 540 ? 200 : null,
              }}
              color={UI.colors.primary}
              onClick={() => {
                this.apply_date_filter();
              }}
              text={"Apply"}
              height={50}
            />
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={880}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker
                  isAppointmentPage={true}
                  stateless={true}
                  callback={this.onCreate}
                  table_name={"doctors_on_call_availability"}
                  _this={_this}
                />
              </View>
            </View>
            <View
              style={{
                alignSelf: width > 880 ? "flex-start" : "center",
                marginTop: 8,
                marginLeft: 10,
              }}
            >
              <Layout.Btn
                color={UI.colors.primary}
                text={"Export to CSV"}
                onClick={() => {
                  this.export_to_csv();
                }}
                height={40}
                width={150}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            disable_pagination={false}
            state_name={"doctors_on_call_availability"}
            _this={_this}
            columns={[
              { "Doctor Name": "doctor_name" },
              { "Login Date": "login_date" },
              { "Logout Date": "logout_date" },
              { "Duration Time": "duration_time" },
            ]}
            data={this.state.data.map((item) => {
              const login_date = UI.timestampToDate(
                new Date(item.login).getTime()
              );
              const logout_date = UI.timestampToDate(
                new Date(item.logout).getTime()
              );
     
              return {
                doctor_name: (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginLeft: 10,
                    }}
                  >
                    <Text
                      style={{
                        maxWidth: 80,
                        marginRight: 10,
                        textAlign: "center",
                      }}
                    >
                      {UI.DataHelper.carespan.get_name(item.doctorID?.name)}
                    </Text>
                  </View>
                ),
                login_date:
                  login_date.month +
                  "/" +
                  login_date.day +
                  "/" +
                  login_date.year,
                logout_date:
                  logout_date.month +
                  "/" +
                  logout_date.day +
                  "/" +
                  logout_date.year,
                duration_time: item.duration
              };
            })}
            number_of_pages={this.state.doctors_on_call_availability_number_of_pages}
            current_page={this.state.doctors_on_call_availability_current_page}
            on_page={(page, callback, table_name) => {
              if (this.state.start_date_dashed !== default_startdate) {
                this.apply_date_filter(page)
              } else {
                this.get_data({
                  page: page,
                  limit: parseInt(this.state.entries)
                });
              }
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()

/* 
  Sample Usage

  <CustomDatatable
    state_name={"consultation_records"}
    _this={_this}
    columns={[
      { ID: "id" },
      { Patient: "patient" },
      { Status: "status" },
      { "Created On": "created_on" },
      { "Start Date/Time": "start_date" },
      { Duration: "duration" },
      { "Pay Method": "pay_method" },
      { "Offer ID": "offer_id" },
      { "Offer Name": "offer_name" },
      { Actions: "actions" },
    ]}
    data={[
      {
        id: "13121",
        patient: "John Doe",
        status: "Scheduled",
        created_on: "mm/dd/yyyy",
        start_date: "mm/dd/yyyy/00:00am",
        duration: "N/A",
        pay_method: "Bayad App",
        offer_id: "001010",
        offer_name: "Offer Name Here",
        actions: (
          <TouchableOpacity>
            <Text>Testing</Text>
          </TouchableOpacity>
        ),
      },
    ]}
    current_page={this.state.current_page}
    number_of_pages={20}
    on_page={(page, callback) => {
      this.setState(
        {
          current_page: page,
        },
        () => {
          callback();
        }
      );
    }}
  />


*/
