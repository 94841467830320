import React, { useCallback, useState, useEffect } from "react";
import DataTable from "../../ComponentsFC/DataTable";
import UI from "../../shared/Components/UI/js";
import styled from "styled-components";
import { Row, Col, Input, Select, Typography, Button,
  // DatePicker 
} from "antd";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import TouchableOpacity, { Image, mem, Text, View } from "../../shared/custom-react-native";
import ServiceMarketingsApi from "../../api_services/marketings";
// import moment from "moment";

// import moment from "moment";

const ReferralTable = ({ _this }) => {
  const [tableData, setTableData] = useState([]);
  const [searchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortByReferralCount, setSortByReferralCount] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(10);
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const getPatientReferrals = useCallback(async () => {
    try {
      setIsLoading(true);

      let params = {};

      if (searchQuery) {
        params.keyword = searchQuery;
      }

      if (sortByReferralCount) {
        params.sort = sortByReferralCount;
      }

      if (currentPageNum) {
        params.page = currentPageNum;
        params.limit = tableEntries;
      }

      ServiceMarketingsApi.getUserReferrals(params).then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setTotalRecords(res.count)
          setTableData(res.data);
        }
      });
    } catch (err) {
      // Handle Errors Here
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }, [currentPageNum, tableEntries, searchQuery, sortByReferralCount]);

  useEffect(() => {
    getPatientReferrals();
  }, [getPatientReferrals]);

  const handlePaginateTable = (pageNum) => {
    setCurrentPageNum(pageNum);
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleCSVExport = () => {
    const csv_data = tableData.map((item, index) => {
      const d = {
        "Patient ID": (item?.id || item?._id) ?? "--",
        "User Name": item?.name ?? "--",
        "Email Address": item?.email ?? "--",
        "Mobile Number": item?.phone ?? "--",
        "Registered Referrals": item.referralCount ?? "--",
        // "Date Registered": item?.registeredDate ? UI.momentPHTimezone(item?.registeredDate).format('MM/DD/YYYY') : "--",
      };
      return d;
    });

    UI.DownloadCSV(`Patient Referrals ${UI.get_current_date_string()}`, csv_data);
  };

  const tableColumnHeader = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      render: (_id) => _id,
    },
    {
      title: "Photo",
      dataIndex: "photo",
      key: "photo",
      render: (photo, item) => 
      <img
        className=''
        style={{ height: 50, width: 50, borderRadius: 5 }}
        src={item?.imageFilename?.includes('https') ? item?.imageFilename  : item?.imageFilename  ? UI.getUserAvatarLink(item?.imageFilename ) : UI.DEFAULT_AVATAR_PATIENT}
        alt="N/A"
      />
    },
    {
      title: "Patient Name",
      dataIndex: "username",
      key: "username",
      render: (username, item) => 
        item?.name ?? ""
      ,
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      render: (email) => email ?? "--",
    },
    {
      title: "Mobile Number",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => phone ? phone.split("")[0] === "9" ? `0${phone}` : phone : "--",
    },
    {
      title: "Registered Referrals",
      dataIndex: "referrals",
      key: "referrals",
      render: (referrals, item) => item.referralCount ?? "--",
  },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (key, item) => {
            return <View style={{ flexDirection: "row", gap: 10 }}>
              <TouchableOpacity
                onClick={() => {
                    UI.goTo('/marketing/patient-referrals-view')
                    mem.save('patient_referral_view', JSON.stringify(item))
                }}
              >
                <Image
                  height={28}
                  width={28}
                  tintColor={"transparent"}
                  source={UI.VIEW_BTN_1}
                />
              </TouchableOpacity>
            </View>
        },
    },
  ];

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPageNum(1);
    setTableEntries(30);
  };


  return (
    <>
      <FilterBox>
        <Text style={{
          fontWeight: "bold",
          color:UI.colors.secondary,
          paddingLeft: 35,
          fontSize: 17
        }}>Patient Referrals
        </Text>
        <Toolbar gutter={14}>
          <ToolItem span={8}>
            <StyledFieldLabel>Referrals Count</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Sort Options"
              style={{
                width: "100%",
              }}
            //   allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setSortByReferralCount(value)
              }}
            //   onClear={() => handleClearViaSelectField("Registration Date")}
            >
              <Select.Option value="desc">Highest to Lowest</Select.Option>
              <Select.Option value="asc">Lowest to Highest</Select.Option>
            </StyledSelect>
          </ToolItem>
        </Toolbar>
      </FilterBox>

      <TableBox>
        <Toolbar>
          <ToolItem span={8} style={{display:'flex', gap: 10, alignItems:'center'}}>
            <FieldLabel>Only Show</FieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              defaultValue="10"
              style={{
                width: 160,
              }}
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeNumOfEntries}
            >
              <Select.Option value="10">10 Entries</Select.Option>
              <Select.Option value="30">30 Entries</Select.Option>
              <Select.Option value="100">100 Entries</Select.Option>
            </StyledSelect>
            {/* <StyledFieldLabel style={{ color:'black' }}>Sort By:</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="by number of referrals"
              style={{
                width: 380,
              }}
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                handleSorting(value)
              }}
            >
                <Select.Option value="createdAt:asc">By date - Newest</Select.Option>
                <Select.Option value="createdAt:desc">By date - Newest</Select.Option>
                <Select.Option value="referralCount:largest">By referrals count - Largest to smallest</Select.Option>
                <Select.Option value="referralCount:smallest">By referrals count - Smallest to largest</Select.Option>
            </StyledSelect> */}
          </ToolItem>
          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getPatientReferrals}
            style={{ width: 200 }}
          />
          <StyledButton type="primary" onClick={handleCSVExport}>
            Export to CSV
          </StyledButton>
        </Toolbar>
        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          defaultPageSize={30}
          pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default ReferralTable;

// CSS Styled Components ----------------------

const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

// const StyledRangePicker = styled(DatePicker.RangePicker)`
//   flex-direction: row;
//   border-radius: 12px;
//   border-width: 2px !important;
//   height: 50px;
//   margin-left: auto;

//   .ant-picker:hover,
//   .ant-picker-focused {
//     border-color: #d9d9d9 !important;
//     box-shadow: none !important;
//   }

//   .ant-picker-range-separator {
//     display: block !important;
//   }
// `;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const ToolItem = styled(Col)`
  position: relative;
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;
