import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  ImageBackground,
  ImageFit,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
// import { Failed, Success } from "../_components/Modal";
// import "./css.css";
const Constants = new APIConstants();

export default class UserGroupEdit extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "User Group List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: "usergroupadmin",

      name: "",
      contact_no: "",
      profile_image: UI.PRODUCT_BOX,
      partner_image: UI.PRODUCT_BOX,
      image_file:"",
      item: ""
    });
  }

  onCreate = () => {
    this.load_data()
  };

  load_data = async () => {
    const data = JSON.parse(mem.get("edit_user_group"));

    if (data) {
      UI.set_input_value("name", data.name ?? "")
      UI.set_input_value("contact_no", data.contactNumber ?? "")
    }

    const partnerLogoImage = `${Constants.STORAGE_BASE_URL}?path=${data.partnerLogoImage}` ?? UI.SAMPLE_PRODUCT
    const profileLogoImage = `${Constants.STORAGE_BASE_URL}?path=${data.profileLogoImage}` ?? UI.SAMPLE_PRODUCT

    this.setState({
      item: data,
      partner_image: partnerLogoImage,
      profile_image: profileLogoImage,
      partner_image_path: data.partnerLogoImage,
      profile_image_path: data.profileLogoImage,
      profile_did_upload: data.profileLogoImage ? true : false,
      partner_did_upload: data.partnerLogoImage ? true : false,
    })
  }

  submit = async () => {
    UI.clear_errors();
    const { contact_no, partner_image_file, profile_image_file } = this.state;
  
    const overrideProfileLogoImage = profile_image_file && {
      profileLogoImage: profile_image_file,
    } 

    const overridePartnerLogoImage = partner_image_file && {
      partnerLogoImage: partner_image_file,
    } 

    const payload = {
      contactNumber: contact_no,
      ...overrideProfileLogoImage,
      ...overridePartnerLogoImage
    }

    console.log(payload);

    UI.webRequest({
      method: "put",
      url: "admin/usergroup/update-logo-and-info/" + this.state.item._id,
      params: payload,
      isMultiPart: true,
      onFail: async (err) => {
        this.show_modal(
          <Failed description={err.data.message} onDismiss={this.hide_modal} />
        );
      },
      onSuccess: async (response) => {
        this.show_modal(
          <Success
            descriptionCenter={"Successfully edited the user group."}
            onDismiss={() => {
              UI.clear_forms();
              this.setState({
                title: "",
              });
              this.hide_modal();
              UI.goBack();
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });

  };
  
  upload_profile_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        profile_image: file.uri,
        profile_image_file: file.file,
        profile_did_upload: true,
      }
    );

  };

  upload_partner_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        partner_image: file.uri,
        partner_image_file: file.file,
        partner_did_upload: true,
      }
    );

  };
  
  render() {
    const _this = this;

    return (
      <MainLayout context={this.state.context} additional={"subscriptions"} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Update User Group
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add item
            </Text>
          </UI.Row>
          {UI.box(10)}
          <UI.Row breakpoint={970} _this={_this}>
              <View style={{ height: 120, width: 120 }}>
                <Layout.FilePicker
                  _this={_this}
                  title={""}
                  additional={""}
                  text={""}
                  state_name={"image_profile"}
                  onChange={async (file) => {
                  }}
                  height={120}
                  component={
                    <ImageBackground
                      style={{ height: 120, width: 120, borderRadius: 10 }}
                      source={this.state.profile_image}
                      imageFit={ImageFit.CONTAIN}
                    />
                  }
                />
              </View>
              {UI.box(20)}
              {/* <strong className="text-red-500">*</strong> */}
              <View style={{ flex: 1.5, justifyContent: "center" }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
                >
                  {this.state.profile_did_upload
                    ? "Profile Picture Uploaded."
                    : "No Profile Picture Uploaded."}{" "}
                  {/* No Icon Uploaded.  */}
                  Recommended Size: 500 px x 500 px
                </Text>
                {UI.box(10)}

                <Layout.Btn
                  color={UI.colors.secondary}
                  text={"Upload Profile Picture"}
                  onClick={() => {
                    // this.pick_image();

                    this.upload_profile_picture();
                  }}
                  width={220}
                />
              </View>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
              <View style={{ height: 120, width: 120 }}>
                <Layout.FilePicker
                  _this={_this}
                  title={""}
                  additional={""}
                  text={""}
                  state_name={"partner_image"}
                  onChange={async (file) => {
                  }}
                  height={120}
                  component={
                    <ImageBackground
                      style={{ height: 120, width: 120, borderRadius: 10 }}
                      source={this.state.partner_image}
                      imageFit={ImageFit.CONTAIN}
                    />
                  }
                />
              </View>
              {UI.box(20)}
              <View style={{ flex: 1.5, justifyContent: "center" }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
                >
                  {this.state.partner_did_upload
                    ? "Partner Picture Uploaded."
                    : "No Partner Picture Uploaded."}{" "}
                  Recommended Size: 500 px x 500 px
                </Text>
                {UI.box(10)}

                <Layout.Btn
                  color={UI.colors.secondary}
                  text={"Upload Partner Picture"}
                  onClick={() => {
                    this.upload_partner_picture();
                  }}
                  width={220}
                />
              </View>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Group Name"}
              placeholder={"Enter Group Name"}
              state_name={"name"}
              disabled={true}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.name}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Contact Number"}
              placeholder={"Contact Number"}
              state_name={"contact_no"}
              prefix={"+63"}
              isRequired={true}
              isNumber={true}
              maxLength={10}
              style={{ flex: 1 }}
            />
            {UI.box(10)}
            <View style = {{flex: 1}}></View>
          </UI.Row>

          {UI.box(20)}

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"UPDATE ITEM"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
