import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import APIConstants from "../../../shared/modules/APIConstants";
// import moment from "moment";
import { Row, Col, Button, Image } from "antd";
import styled from "styled-components";
import UI from "../../../shared/Components/UI/js";
import Container from "../../../ComponentsFC/ui/Container";
import ContainerLabelPrimary from "../../../ComponentsFC/ui/ContainerLabelPrimary";
import FieldBirthDate from "../../../icons/FieldBirthDate";
import FieldDateIcon from "../../../icons/FieldDate";
import FieldGenderIcon from "../../../icons/FieldGender";
import FieldHeightIcon from "../../../icons/FieldHeight";
import FieldWeightIcon from "../../../icons/FieldWeight";
import FieldBMIIcon from "../../../icons/FieldBMI";
import FieldHeartIcon from "../../../icons/FieldHeart";
import FieldAddressIcon from "../../../icons/FieldAddress";
// import toTitleCase from "../../../../utils/toTitleCase";
// import ServiceAdminDoctorAccountsApi from "../../../../api_services/Admin/doctor-accounts-api";
// import FileDownload from "js-file-download";
// import Loading from "../../../../modules/_components/Loading";

const Constants = new APIConstants();
const imageStorage = Constants.STORAGE_BASE_URL;
const imageContainer = "doctors-onboarding";
// const new_src =
//         Constants.STORAGE_BASE_URL +
//         "?path=" +
//         path +
//         "&container=" +
//         container;

const PatientReferralDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const [details, setDetails] = useState();

  useEffect(() => {
    // console.log("LOCATION: ", location.state.data);
    setDetails(location.state.data);
  }, [location.state.data]);

  const removePlus63 = (phoneNumber) => {
    const hasPlus63Beginning =
      phoneNumber?.charAt(0) === "+" ||
      phoneNumber?.charAt(1) === "6" ||
      phoneNumber?.charAt(2) === "3";

    if (hasPlus63Beginning) {
      return phoneNumber.replace("+63", "");
    } else {
      return phoneNumber;
    }
  };

  useEffect(() => {
    removePlus63("+639056188980");
  }, []);

  return (
    <>
      {/* <Loading
        visible={isScreenLoading}
        title={""}
        text={""}
        modal_opaque={false}
      /> */}
      <Container>
        {/* ANTD ROW 24 GRID LAYOUT 8x8x8 (span) = 24 */}
        <RowWrapper>
          <FirstColumn>
            <ContainerLabelPrimary>Patient Details</ContainerLabelPrimary>
            <Row>
              <Col style={{ marginRight: 25 }}>
                <StyledImage
                  src={
                    details?.patientInfo[0]?.avatarLink
                      ? `${imageStorage}?path=${details?.patientInfo[0]?.avatarLink}&container=${imageContainer}`
                      : UI.DEFAULT_AVATAR_PATIENT
                  }
                  preview={false}
                  width={141}
                  height={144}
                />
              </Col>
              <StyledColWrapper>
                <StyledDoctorsName>
                  {/* {`${details?.name[0]?.given.join(" ")} ${
                     details?.name[0]?.family
                   }`} */}
                  {`${details?.patientInfo[0]?.fullname ?? "No name filed"}`}
                </StyledDoctorsName>
                <StyledDoctorsContactDetails>
                  {/* {details?.telecom[1]?.value ?? "--"} */}
                  {`${details?.patientInfo[0]?.email ?? "No email filed"}`}
                </StyledDoctorsContactDetails>
                <StyledDoctorsContactDetails>
                  {details?.patientInfo[0]?.phoneNumber
                    ? `+63 ${removePlus63(
                        details?.patientInfo[0]?.phoneNumber
                      )}` ?? "--"
                    : "No contact number filed"}
                </StyledDoctorsContactDetails>
              </StyledColWrapper>
            </Row>
          </FirstColumn>

          <SecondColumn>
            <Row>
              <Col style={{ marginRight: 120 }}>
                <FieldWrapper>
                  <Col>
                    <FieldLabel>
                      <FieldBirthDate
                        style={{ marginRight: 20, verticalAlign: 0 }}
                      />
                      Birthdate
                    </FieldLabel>
                    <FieldLabel>
                      <FieldDateIcon
                        style={{ marginRight: 20, verticalAlign: 0 }}
                      />
                      Age
                    </FieldLabel>
                    <FieldLabel>
                      <FieldGenderIcon
                        style={{ marginRight: 20, verticalAlign: 0 }}
                      />
                      Gender
                    </FieldLabel>
                    <FieldLabel>
                      <FieldHeightIcon
                        style={{ marginRight: 20, verticalAlign: 0 }}
                      />
                      Height
                    </FieldLabel>
                    <FieldLabel>
                      <FieldWeightIcon
                        style={{ marginRight: 20, verticalAlign: 0 }}
                      />
                      Weight
                    </FieldLabel>
                    <FieldLabel>
                      <FieldBMIIcon
                        style={{ marginRight: 20, verticalAlign: 0 }}
                      />
                      BMI
                    </FieldLabel>
                  </Col>
                  <Col style={{ marginLeft: 39 }}>
                    <FieldValue>{`${
                      details?.patientInfo[0]?.birthday ?? "--"
                    }`}</FieldValue>
                    <FieldValue>{`${
                      details?.patientInfo[0]?.age ?? "--"
                    }`}</FieldValue>
                    <FieldValue>{`${
                      details?.patientInfo[0]?.gender ?? "--"
                    }`}</FieldValue>
                    <FieldValue>
                      {`${details?.patientInfo[0]?.height ?? "--"}`}
                    </FieldValue>
                    <FieldValue>
                      {`${details?.patientInfo[0]?.weight ?? "--"}`}
                    </FieldValue>
                    <FieldValue>
                      {`${details?.patientInfo[0]?.bmi ?? "--"}`}
                    </FieldValue>
                  </Col>
                </FieldWrapper>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <FieldLabel>
                      <FieldHeartIcon
                        style={{ marginRight: 20, verticalAlign: 0 }}
                      />
                      Blood Type
                    </FieldLabel>
                    <FieldLabel>
                      <FieldAddressIcon
                        style={{ marginRight: 20, verticalAlign: 0 }}
                      />
                      Street Address
                    </FieldLabel>
                    <FieldLabel>
                      <FieldDateIcon
                        style={{
                          marginRight: 20,
                          verticalAlign: 0,
                          visibility: "hidden",
                        }}
                      />
                      Barangay
                    </FieldLabel>
                    <FieldLabel>
                      <FieldDateIcon
                        style={{
                          marginRight: 20,
                          verticalAlign: 0,
                          visibility: "hidden",
                        }}
                      />
                      City
                    </FieldLabel>
                    <FieldLabel>
                      <FieldDateIcon
                        style={{
                          marginRight: 20,
                          verticalAlign: 0,
                          visibility: "hidden",
                        }}
                      />
                      Province
                    </FieldLabel>
                    <FieldLabel>
                      <FieldDateIcon
                        style={{
                          marginRight: 20,
                          verticalAlign: 0,
                          visibility: "hidden",
                        }}
                      />
                      Zip Code
                    </FieldLabel>
                  </Col>
                  <Col style={{ marginLeft: 39 }}>
                    <FieldValue>{`${
                      details?.patientInfo[0]?.bloodType ?? "--"
                    }`}</FieldValue>
                    <FieldValue>{`${
                      details?.patientInfo[0]?.street ?? "--"
                    }`}</FieldValue>
                    <FieldValue>{`${
                      details?.patientInfo[0]?.baranggay ?? "--"
                    }`}</FieldValue>
                    <FieldValue>{`${
                      details?.patientInfo[0]?.city ?? "--"
                    }`}</FieldValue>
                    <FieldValue>{`${
                      details?.patientInfo[0]?.province ?? "--"
                    }`}</FieldValue>
                    <FieldValue>{`${
                      details?.patientInfo[0]?.zipCode ?? "--"
                    }`}</FieldValue>
                  </Col>
                </Row>
              </Col>
            </Row>
          </SecondColumn>
        </RowWrapper>
      </Container>

      <Container style={{ width: "46%", minWidth: "46%" }}>
        <ContainerLabelPrimary>Referral Details</ContainerLabelPrimary>
        <FieldLabel style={{ fontSize: 20, marginBottom: 20, marginTop: 5 }}>
          Referral Type
        </FieldLabel>
        <FieldValue style={{ marginBottom: 30 }}>{`${
          details?.type === "teleconsult"
            ? "Teleconsult"
            : details?.type === "face-to-face"
            ? "F2F Appointment"
            : "--"
        }`}</FieldValue>
        <FieldLabel style={{ fontSize: 20, marginBottom: 20 }}>
          Reason for Referral
        </FieldLabel>
        <FieldValue style={{ marginBottom: 30 }}>
          {`${details?.reasonForReferral ?? "No referral reason filed"}`}
        </FieldValue>
        <FieldLabel style={{ fontSize: 20, marginBottom: 20 }}>
          Chief Complaint
        </FieldLabel>
        <FieldValue style={{ marginBottom: 30 }}>
          {`${details?.chiefComplaint ?? "No chief complaint filed"}`}
        </FieldValue>
        <FieldLabel style={{ fontSize: 20, marginBottom: 20 }}>
          Pertinent Information
        </FieldLabel>
        <FieldValue style={{ marginBottom: 30 }}>
          {`${
            details?.pertinentInformation ?? "No pertinent information filed"
          }`}
        </FieldValue>
      </Container>
      <StyledButton onClick={() => history.push("/onboard/referral")}>
        BACK TO LIST
      </StyledButton>
    </>
  );
};

export default PatientReferralDetails;

// CSS Styled Components ----------------------

const RowWrapper = styled(Row)``;

const StyledImage = styled(Image)`
  border-radius: 12px;
`;

const FirstColumn = styled(Col)`
  width: 462.26px; // 497 (based on UI Design) - 35px (existing padding), 462.26px
  border-right: 3px solid #efefef;

  // Breakpoint where AntD Row wraps (tablets, 1755px) and down)
  @media only screen and (max-width: 1755px) {
    border-right: none;
    border-bottom: 3px solid #efefef;
  }
`;

const SecondColumn = styled(Col)`
  padding-left: 46.44px; //based on UI Design Spacing

  @media only screen and (max-width: 1755px) {
    padding-top: 20px;
  }

  // Breakpoint where AntD Row wraps (Larger Laptops, 1440px) and down)
  @media only screen and (max-width: 1440px) {
    padding-top: 20px;
    padding-left: 0;
  }
`;

const StyledColWrapper = styled(Col)`
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledDoctorsName = styled.p`
  margin-bottom: 10px;
  font-size: 22px;
  font-family: Poppins;
  font-weight: 500;
  color: #535353;
`;

const StyledDoctorsContactDetails = styled.p`
  margin-bottom: 10px;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
  color: #898989;
`;
const StyledButton = styled(Button)`
  width: 193px;
  height: 60px;
  background-color: #0e4d94;
  border: 2px solid #0e4d94;
  border-radius: 12px;

  font-size: 20px;
  font-family: Poppins;
  font-weight: 500; // medium
  color: #ffffff;

  &:hover,
  &:focus,
  &:active {
    background-color: #0e4d94;
    color: #ffffff;
  }
`;

const FieldWrapper = styled(Row)`
  /* width: 400px; //382.25px */
`;

const FieldLabel = styled.p`
  margin-bottom: 6.5px;

  font-family: Poppins;
  font-size: 18px; //18px
  font-weight: 500;
  color: #4fc2ed;
`;

const FieldValue = styled.p`
  margin-bottom: 6.5px;

  font-family: Poppins;
  font-size: 18px; //18px
  font-weight: 500;
  color: #333333;
`;
