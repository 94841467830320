import UI from "../shared/Components/UI/js";
import ServiceApi from "./service";

class UsersGroupApi {

    baseURL = 'admin';

    async getAllUserGroups (params) {
        const response = await ServiceApi.post(this.baseURL+`/usergroup/all`, params);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getAllUserGroupsByUserGroupAdmin (id, params) {
        const response = await ServiceApi.get(this.baseURL+`/usergroupadmin/get-usergroup-by-userid/${id}`, params);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }


    async getUserGroupMembersByUserGroupId (id, params) {
        const response = await ServiceApi.get(this.baseURL+`/usergroup/${id}/members`, params);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getUserGroupAdminByUserGroupId (id) {
        const response = await ServiceApi.get(this.baseURL+`/usergroupadmin/byUserGroupId/${id}`);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getInvitedUserGroupAdminByUserGroupId (id) {
        const response = await ServiceApi.get(this.baseURL+`/usergroupadmin/byInvitedListAndUserGroup/${id}`);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getUserGroupAllInvitedMembers () {
        const response = await ServiceApi.get(this.baseURL+`/usergroup/invitedlist/`);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getUserGroupInvitedMembersByUserGroupId (id) {
        const response = await ServiceApi.get(this.baseURL+`/usergroup/invitedlist/${id}`);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async userGroupInviteMembers (payload) {
        const response = await ServiceApi.post(this.baseURL+`/usergroup/add-member-by-emails-or-phonenumber`, payload);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async userGroupInviteUserGroupAdmin (payload) {
        const response = await ServiceApi.post(this.baseURL+`/userGroupAdmin`, payload);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async deleteUserGroup (id) {
        const response = await ServiceApi.delete(this.baseURL+`/usergroup/${id}`);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async deleteUserGroupMember (userGroupId, memberUserGroupId) {
        const response = await ServiceApi.delete(this.baseURL+`/usergroup/${userGroupId}/member/${memberUserGroupId}`);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async deleteUserGroupMemberWithValidateSubscriptions (userGroupId, memberUserGroupId) {
        const response = await ServiceApi.post(this.baseURL+`/usergroup/${userGroupId}/scheduled/${memberUserGroupId}`);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async deleteUserGroupAdmin (id) {
        const response = await ServiceApi.delete(this.baseURL+`/usergroupadmin/${id}`);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

}

export default new UsersGroupApi()