import React from "react";
import {
  Image,
  Document,
  Page,
  StyleSheet,
  Text,
  View,
} from "react-pdf-renderer-v2";
import moment from "moment";

const MarketingConsent = ({ _this }) => {
  const {
    first_name,
    middle_name,
    last_name,
    file_signature_path
  } = _this.state;

  const signature = file_signature_path;

  return (
    <Document size="LETTER">
      <Page style={styles.page}>
        <Image style={styles.logo} src={"/img/metro_pacific_logo.png"} />
        <Text style={styles.heading}>Consent Form</Text>
        <Text style={styles.text}>
          I consent to Metro Pacific Health Tech Corporation (“MPHTC”) using my
          photographs and/or video recordings including my name, employment,
          field of medical expertise, and years of medical practice experience
          to allow MPHTC to share to the public my images and personal
          information for marketing and promotional purposes. I agree that these
          images and personal information will be used in digital media formats,
          which shall be published in its website, mobile application, and
          social media accounts.
        </Text>
        <Text style={styles.text}>
          I hereby agree to irrevocably assign all property rights to the
          aforementioned photographs and recordings to MPHTC.
        </Text>
        <Text style={styles.text}>
          I understand that images on MPHTC’s website and mobile application can
          be viewed throughout the world and not just in the Philippines and
          that some overseas countries may not provide the same level of
          protection to the rights of individuals as the Data Privacy Act of
          2012 provides.
        </Text>
        <Text style={styles.text}>
          I understand that some images or recordings may be kept permanently
          once they are published and be kept as an archive of MPHTC.
        </Text>
        <Text style={styles.text}>
          I have read and understand the conditions and consent to my images
          and/or recordings being used as described.
        </Text>
        <View style={{ ...styles.row, marginTop: 20, borderBottomWidth: 0 }}>
          <Text
            style={[styles.text, styles.td, { borderRight: "1px solid black" }]}
          >
            Print Name
          </Text>
          <Text
            style={[styles.text, styles.td]}
          >{`${first_name} ${middle_name} ${last_name}`}</Text>
        </View>
        <View style={{ ...styles.row, borderBottomWidth: 0 }}>
          <Text style={[styles.text, styles.td]}>Signature</Text>

          {signature.url && <Image style={styles.image} src={signature.url} />}
        </View>
        <View style={styles.row}>
          <Text
            style={[styles.text, styles.td, { borderRight: "1px solid black" }]}
          >
            Date
          </Text>
          <Text style={[styles.text, styles.td]}>
            {moment().format("MM/DD/YYYY")}
          </Text>
        </View>
        <Text style={styles.text}>
          MPHTC is committed to processing information in accordance with the
          Data Privacy Act of 2012. The images and video footage will not be
          used for any commercial purposes outside of MPHTC. The information
          that you give us here will only be used to contact you about the
          images or video footage that we have taken. We will not pass the
          details record on this form to any third party without your
          permission.
        </Text>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    paddingLeft: 80,
    paddingRight: 80,
    paddingTop: 16,
    paddingBottom: 16,
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderWidth: 1,
    borderColor: "rgba(0,0,0)",
    borderStyle: "solid",
    alignItems: "flex-start",
  },
  td: {
    padding: 10,
    minWidth: 100,
    alignSelf: "flex-start",
    marginTop: 0,
  },
  heading: {
    textTransform: "uppercase",
    textAlign: "center",
    fontFamily: "Times-Roman",
    display: "block",
  },
  text: {
    fontFamily: "Times-Roman",
    display: "block",
    marginTop: 20,
    fontSize: 12,
    lineHeight: 1.2,
  },
  image: {
    padding: 10,
    width: "100%",
    maxWidth: 100,
    height: "auto",
    display: "block",
    borderLeft: "1px solid black",
  },
  logo: {
    width: 300,
    display: "block",
    textAlign: "center",
    margin: "0 auto",
    paddingLeft: 20,
  },
});

export default MarketingConsent;
