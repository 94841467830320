import React from "react";
import Images from "../Images/js";
import { View, Text } from "../../custom-react-native";
import mem from "../Memory/js";
import APINavigation from "../../modules/APINavigation";
import APIConstants from "../../modules/APIConstants";
import { axiosRequestAuth, axiosRequest, axiosRequestWeb } from "../Request/js";
import axios from "axios";
import moment from "moment";
import DataHelper from "../../../modules/_components/DataHelper";
import { Failed } from "../../../modules/_components/Modal";
import momentTimezone from "moment-timezone";
import _ from "lodash";

const Constants = new APIConstants();

class ui extends Images {
  // **Commented out unused/useless constructor
  // **you don't necessarily have to define an empty constructor or just super inside (derived classes)
  // **since it's automatically handled by JavaScript/TypeScript

  // constructor(props) {
  //   super(props);
  // }

  DataHelper = DataHelper;

  getDoctorFileURL = (path, container = "doctors_onboarding") => {
    const url = `${Constants.STORAGE_BASE_URL}?path=${path}&container=doctors-onboarding`;
    return url;
  };

  getUserAvatarLink = (path, container = "doctors_onboarding") => {
    const url = `${Constants.STORAGE_BASE_URL}?path=${path}&container=${path?.includes('users') ? 'mwell-app' : 'users'}`;
    // let url = `${Constants.STORAGE_BASE_URL}?path=${path}&container=users`;
    // try {
    //   console.log('first')
    //   axios.get(url)
    // } catch (error) {
    //   url = `${Constants.STORAGE_BASE_URL}?path=${path}&container=mwell-app`;
    //   console.log(error)
    // }

    return url;
  };

  webRequest = async (
    props = {
      method: "",
      url: "",
      params: {},
      onSuccess: () => {},
      onFail: () => {},
      isMultiPart: false,
      subscription_key: false,
    }
  ) => {
    this.newWebRequest(props);
  };

  simpleRequest = async (
    props = {
      method: "",
      url: "",
      params: {},
      onSuccess: () => {},
      onFail: () => {},
      isMultiPart: false,
      subscription_key: false,
      strict: false,
    }
  ) => {
    const newSimpleRequest = axios.create({
      baseURL: props.url,
      headers: {
        "Ocp-Apim-Subscription-Key": props.subscription_key
          ? props.subscription_key
          : Constants.SUBSCRIBTION_KEY,
        Authorization: "Bearer " + mem.get(Constants.JWT_TOKEN),
      },
    });
    if (props.isMultiPart) {
      newSimpleRequest.defaults.headers.common["Content-Type"] =
        "multipart/form-data";
    }

    let final_url = "";

    let queryParams = "";

    if (!props.isMultiPart) {
      if (props.params) {
        queryParams = await this.encodeParams(props.params);
      }
    }

    final_url = "?" + queryParams;
    let params = props.params;

    if (props.isMultiPart) {
      if (props.params) {
        params = new FormData();
        Object.keys(props.params).forEach((key) => {
          params.append(key, props.params[key]);
        });
      }
    }

    if (props.strict) {
      final_url = this.replaceAll(final_url, "/?", "");
      final_url = this.replaceAll(final_url, "?", "");
    }

    const response = await newSimpleRequest[props.method.toLowerCase()](
      final_url,
      params
    ).catch((err) => {
      this.process_fail(err);
      if (props.onFail) {
        props.onFail(err.response);
        if (err.response?.status === 403) {
          this.logout_error(err.data?.error);
        }
      }
      if (props.onFinish) {
        props.onFinish();
      }
    });
    if (response) {
      if (props.onSuccess) {
        props.onSuccess(response);
      }
      setTimeout(() => {
        if (props.onFinish) {
          props.onFinish();
        }
      }, 300);
    }
  };
  newWebRequest = async (
    props = {
      method: "",
      url: "",
      params: {},
      onSuccess: () => {},
      onFail: () => {},
      isMultiPart: false,
    }
  ) => {
    const newRequestWeb = axios.create({
      baseURL: (props.isSpecialCall && Constants.ENV_TYPE === "PRODUCTION-RIZAL") ? Constants.API_HOST_WEB_SPECIAL : Constants.API_HOST_WEB,
      headers: {
        "Ocp-Apim-Subscription-Key": Constants.SUBSCRIBTION_KEY,
        Authorization: "Bearer " + mem.get(Constants.JWT_TOKEN),
      },
    });
    if (props.isMultiPart) {
      newRequestWeb.defaults.headers.common["Content-Type"] =
        "multipart/form-data";
    }

    let final_url = "";

    let queryParams = "";

    if (!props.isMultiPart) {
      if (props.params) {
        if (props.method.toLowerCase() === "get") {
          queryParams = await this.encodeParams(props.params);
        }
      }
    }

    if (props.url.includes("?")) {
      final_url = props.url + "&" + queryParams;
    } else {
      final_url = props.url + "?" + queryParams;
    }

    let params = props.params;

    if (props.isMultiPart) {
      if (props.params) {
        params = new FormData();
        Object.keys(props.params).forEach((key) => {
          params.append(key, props.params[key]);
        });
      }
    }

    const response = await newRequestWeb[props.method.toLowerCase()](
      final_url,
      params
    ).catch((err) => {
      this.process_fail(err);
      if (props.onFail) {
        props.onFail(err.response);
        if (err.response?.status === 403) {
          this.logout_error(err.data?.error);
        }
      }
      if (props.onFinish) {
        props.onFinish();
      }
    });
    if (response) {
      if (props.onSuccess) {
        props.onSuccess(response);
      }
      setTimeout(() => {
        if (props.onFinish) {
          props.onFinish();
        }
      }, 300);
    }
  };

  getDisplayMessageDate = (date) => {
    return "Sent " + moment(date).fromNow();
  };

  setTodecimalTwo(amount) {
    return Math.round(parseFloat(amount) * 100) / 100;
  }

  getCurrentTimeStampPH = (date = new Date()) => {
    return momentTimezone(date).tz("Asia/Manila").format("YYYY-MM-DD HH:mm:ss");
  };

  momentPHTimezone = (date = new Date()) => {
    return momentTimezone(date).tz("Asia/Manila")
  }

  getTimezone = ({
    date = new Date(),
    format = "YYYY-MM-DD HH:mm:ss",
    defaultTz = "Asia/Manila",
  }) => {
    return momentTimezone(date).tz(defaultTz).format(format);
  };

  getPatientName = (item) => {
    let patient_name = "N/A";

    if (item.participant.length > 0) {
      patient_name = item.participant.filter((participant) => {
        const ref = participant.actor.reference ?? "";
        // const name = participant.actor.display ?? "";
        if (ref.toLowerCase().includes("patient")) {
          return true;
        } else {
          return false;
        }
      });
      patient_name = patient_name[0].actor?.display ?? "";
    }

    patient_name = UI.DataHelper.carespan.get_name(item.patient?.name) ?? "";

    return patient_name
  }

  convertGTMTimzone24HoursToUTC = (date) => {
    return moment
      .utc(moment.utc(date, "HH:mm").format())
      .local()
      .format("HH:mm");
  };

  getCurrentTimeStampPHConsult = (date = new Date()) => {
    return momentTimezone(date).tz("Asia/Manila").format("YYYY/MM/DD h:mm a");
  };

  getCurrentTimeStampPHByMonthNameDayYear24H = (date = new Date()) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let timeStamp = momentTimezone(date)
      .tz("Asia/Manila")
      .format("MM DD, YYYY HH:mm:ss");
    timeStamp =
      monthNames[parseInt(timeStamp.substring(0, 2)) - 1] +
      " " +
      timeStamp.slice(2);
    return timeStamp;
  };

  getDateOnly = (date = new Date()) => {
    return moment(date).format("YYYY-MM-DD");
  };

  getDateByMMDDYYYY = (date = new Date()) => {
    // const splitter = splitter ?? "/";
    return moment(date).format("MM/DD/YYYY");
  };

  getDateByMMYYYY = (date = new Date()) => {
    return moment(date).format("MM/YYYY");
  };

  getDateByYYYY = (date = new Date()) => {
    return moment(date).format("YYYY");
  };

  getPHTime12HourFormat = (date = new Date()) => {
    return momentTimezone(date)
      .tz("Asia/Manila")
      .format("YYYY-MM-DD h:mm:ss a");
  };

  getAge = (birthday) => {
    const age = moment().diff(birthday, "years");
    return age;
  };

  capitalizeFirstLetter = (user) => {
    if (user) {
      const first =
        user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1);
      const last =
        user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1);
      return first + " " + last;
    }
  };

  capitalizeName = (name) => {
    const regEx = /(\b[a-z](?!\s))/g;
    const fullName = name.replace(regEx, (x) => {
      return x.toUpperCase();
    });
    return fullName;
  };

  capitalize = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  authRequest = async (
    props = {
      method: "",
      url: "",
      params: {},
      onSuccess: () => {},
      onFail: () => {},
      isMultiPart: false,
    }
  ) => {
    this.newAuthRequest(props);
  };
  newAuthRequest = async (
    props = {
      method: "",
      url: "",
      params: {},
      onSuccess: () => {},
      onFail: () => {},
      isMultiPart: false,
    }
  ) => {
    const newRequestAuth = axios.create({
      baseURL: Constants.API_HOST,
      headers: {
        "Ocp-Apim-Subscription-Key": Constants.SUBSCRIBTION_KEY,
        Authorization: "Bearer " + mem.get(Constants.JWT_TOKEN),
      },
    });

    if (props.isMultiPart) {
      newRequestAuth.defaults.headers.common["Content-Type"] =
        "multipart/form-data";
    }
    let final_url = "";

    let queryParams = "";

    if (!props.isMultiPart) {
      if (props.params) {
        queryParams = await this.encodeParams(props.params);
      }
    }

    if (props.url.includes("?")) {
      final_url = props.url + "&" + queryParams;
    } else {
      final_url = props.url + "?" + queryParams;
    }

    final_url = final_url.replace(/&+$/, "");

    let params = props.params;

    if (props.isMultiPart) {
      if (props.params) {
        params = new FormData();
        Object.keys(props.params).forEach((key) => {
          params.append(key, props.params[key]);
        });
      }
    }
    const response = await newRequestAuth[props.method.toLowerCase()](
      final_url,
      params
    ).catch((err) => {
      this.process_fail(err);
      if (props.onFail) {
        props.onFail(err.response);
      }
      if (props.onFinish) {
        props.onFinish();
      }
    });
    if (response) {
      if (props.onSuccess) {
        props.onSuccess(response);
      }
      if (props.onFinish) {
        props.onFinish();
      }
    }
  };

  process_fail = async (err) => {
    const _this = this._this;
    const handle_errors = [401]; // can be multiple error codes

    if (this.isAuth(_this)) {
      return;
    }

    if (handle_errors.includes(err?.response?.status)) {
      console.log(err.response);
      _this.show_modal(
        <Failed
          description={err.response.data.m}
          onDismiss={() => {
            _this.hide_modal();
            this.logout();
          }}
        />
      );
    }
  };

  isAuth = (_this) => {
    return this.isLogin(_this) || this.isSignup(_this);
  };

  isLogin = (_this) => {
    const url = window.location.href;
    const isLogin = url.includes("/login");
    return isLogin;
  };
  isSignup = (_this) => {
    const url = window.location.href;
    const isSignup = url.includes("/signup");
    return isSignup;
  };

  _this = null;
  search = async (url, parameters = {}) => {
    let add_to_link = await this.encodeParams(parameters);
    url = url + "?" + add_to_link;
    this.goTo(url);
  };

  Constants = Constants;

  role_map = {
    //role : equivalent sub slash
    provider: "onboard",
    admin: "admin",
    finance: "finance",
    transaction: "transaction",
    merchant: "merchant",
    marketing: "marketing",
    voucher: "voucher",
    advocacy: "advocacy",
    usergroupadmin: "usergroupadmin",
    legal: "legal"
  };

  api_call = null;

  axiosRequestAuth = axiosRequestAuth;
  axiosRequest = axiosRequest;
  axiosRequestWeb = axiosRequestWeb;

  encodeParams = (parameters = {}) => {
    return new Promise((resolve, reject) => {
      let new_query_string = "";
      let index = 0;
      let parameter_array = [];
      let param_keys = Object.keys(parameters);
      if (param_keys.length === 0) {
        resolve("");
      }
      param_keys.forEach((param_key) => {
        index++;
        let param_value = parameters[param_key];
        if (!param_value) {
          param_value = "";
        }
        parameter_array.push(param_key + "=" + param_value);
        if (index === param_keys.length) {
          new_query_string = parameter_array.join("&");
          resolve(new_query_string);
        }
      });
    });
  };

  goBack = () => {
    window.history.back();
  };

  goTo = (url) => {
    this._this.props.history.push(url);
  }; /* 
  goTo = (url) => {
    window.location.href = url;
  };
 */
  getWidth = () => {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  };
  getHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );
  };

  colors = {
    primary: "#04CCFF",
    secondary: "#004F99",
    black: "#5D5D5D",
    yellow: "#FCC203",
    red: "#D10016",
    blue: "#43C8FB",
    green: "#2DCB70",
    lightGray: "#AAAAAA",

    // doctor record colors
    dark_blue: "#035099",
    light_green: "#07cf7f",
    light_blue: "#4f93ed",
    aqua: "#30c7ff",

    active: "#00C700",
    inactive: "#f39c12",
    unset: "#7f8c8d",
    header: "#005099",
  };

  box = (size) => {
    return <div style={{ height: size, width: size }}></div>;
  };

  PadView = (props = { _this: null, style: {} }) => {
    const _this = props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;

    return (
      <View
        style={{
          width: "100%",
          paddingRight: paddingX,
          paddingLeft: paddingX,
          ...props.style,
        }}
      >
        {props.children}
      </View>
    );
  };

  checkIfMobile = (_this) => {
    let width = _this.state.width;
    if (width < 720) {
      _this.setState({
        isMobile: true,
      });
    } else {
      _this.setState({
        isMobile: false,
      });
    }
  };

  add_resize_function = (name, method) => {
    let _this = this._this;
    if (_this.resize_functions) {
      let temp_functions = _this.resize_functions.filter(
        (obj) => obj.name !== name
      );
      temp_functions.push({
        name,
        method,
      });
      _this.resize_functions = temp_functions;
    } else {
      _this.resize_functions = [];
      this.add_resize_function(name, method);
    }
  };

  add_scroll_function = (name, method) => {
    let _this = this._this;
    if (_this.scroll_functions) {
      let temp_functions = _this.scroll_functions.filter(
        (obj) => obj.name !== name
      );
      temp_functions.push({
        name,
        method,
      });
      _this.scroll_functions = temp_functions;
    } else {
      _this.scroll_functions = [];
      this.add_scroll_function(name, method);
    }
  };

  set_input_value = (state_name, value) => {
    const inputs = document.getElementsByClassName("input_" + state_name);
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      input.value = value;
    }

    this._this.setState({
      [state_name]: value,
    });
  };
  set_date_value = (state_name, date, will_correct) => {
    const timestamp = date.getTime();
    let time = this.timestampToDate(timestamp);
    time.day = this.pad2(time.day);
    let value = time.month + "/" + time.day + "/" + time.year;
    const carespan = time.year + "-" + time.month + "-" + time.day;

    let dashed =
      time.year + "-" + UI.pad2(time.month) + "-" + UI.pad2(time.day);

    if (will_correct) {
      const new_date = new Date(date.getTime());
      dashed =
        new_date.getFullYear() +
        "-" +
        UI.pad2(new_date.getMonth() + 1) +
        "-" +
        UI.pad2(new_date.getDate());
    }

    this._this.setState({
      [state_name]: value,
      [state_name + "_date"]: date,
      [state_name + "_timestamp"]: timestamp,
      [state_name + "_dashed"]: dashed,
      [state_name + "_carespan"]: carespan,
    });
  };

  set_select_value = (state_name, value) => {
    const els = document.getElementsByClassName("select_" + state_name);
    for (let i = 0; i < els.length; i++) {
      const el = els[i];
      el.value = value;
    }
    this._this.setState({
      [state_name]: value,
    });
  };
  set_multiple_select_value = (state_name, value) => {
    const _this = this._this;

    _this["reload_multiple_option_" + state_name]();
    if (_this["multiple_select_" + state_name]) {
      const options = _this.state["options_" + state_name];
      const selected = value.map((item) => {
        return options.filter((obj) => obj.value === item)[0];
      });
      _this["multiple_select_" + state_name].setValue(selected);
    }
    _this.setState({
      [state_name]: value,
    });
  };

  reload_multiple_select = (state_name) => {
    const _this = this._this;
    _this.setState({
      ["options_" + state_name]: null,
    });
  };

  form_errors = [];
  form_messages = [];

  error_form = (state_name, message) => {
    return new Promise((resolve, reject) => {
      const _this = this._this;
      clearTimeout(_this.error_timeout);
      _this.setState({
        error_count: (_this.state.error_count ?? 0) + 1,
      });

      this.form_errors.push(state_name);
      this.form_messages.push({ [state_name]: message });

      _this.error_timeout = setTimeout(() => {
        _this.setState({
          form_errors: this.form_errors,
          form_messages: this.form_messages,
        });

        //clear errors after 10 seconds
        _this.error_timeout = setTimeout(this.clear_errors, 10000);
      }, 100);
      resolve();
    });
  };

  clear_errors = () => {
    const _this = this._this;
    _this.setState({
      form_errors: [],
      form_messages: [],
      error_count: 0,
    });
    this.form_errors = [];
    this.form_messages = [];
  };

  is_check_special_characters = (str) => {
    // **Removed unnecessary escaped chars in this logic
    // const specialChars = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`
    // const has_special_characters = specialChars
    //   .split("")
    //   .some((res) => char.includes(res));
    // return has_special_characters;

    // **This returns true if string doesn't contain special chars or numbers or both
    // !/^[A-Za-z0-9]*$/ including space
    return /[^a-zA-Z0-9\s]/gi.test(str); // not including space
  };

  clear_forms = () => {
    const inputs = document.getElementsByTagName("input");
    this.clear_el(inputs);
    const textareas = document.getElementsByTagName("textarea");
    this.clear_el(textareas);
  };

  clear_el = (els) => {
    for (let i = 0; i < els.length; i++) {
      const el = els[i];
      el.value = "";
    }
  };

  search_table = (
    table_name,
    keyword = "",
    all_data = [],
    data_name = "data"
  ) => {
    const _this = this._this;
    if (all_data.length === 0) {
      return;
    }
    if (keyword.length === 0) {
      _this.setState(
        {
          [data_name]: all_data,
        },
        () => {
          this.reload_table(table_name);
        }
      );
    }
    keyword = keyword.toLocaleLowerCase();

    let new_array = all_data.filter((item) => {
      if (typeof item == "string") {
        item = { item };
      }
      return this.getObject(item, keyword, item);
    });
    _this.setState(
      {
        [data_name]: new_array,
        [data_name + "_temp"]: new_array,
      },
      () => {
        this.reload_table(table_name, new_array);
      }
    );
  };

  reload_table = (state_name, all_data, data_name) => {
    // console.log("----- reload table -----");
    // console.log("state name:",state_name);
    const table = this._this["table_" + state_name];
    if (table) {
      if (!all_data) {
        all_data = this._this.state.all_data;
        // console.log("all_data:",all_data);
      }
      if (!data_name) {
        data_name = "data";
        // console.log("data_name:",data_name);
      }

      if (this._this.state.will_filter) {
        all_data = this._this.state.filtered_data;
      }

      if (this._this.state.search) {
        all_data = this._this.state[data_name + "_temp"];
      }

      let sliced_data = this.get_entries(all_data, null, state_name);

      this._this.setState(
        {
          [data_name]: sliced_data,
        },
        () => {
          table.set_data();
        }
      );
    }
    // console.log("----- end reload table -----");
  };

  paginate = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  on_page = (
    all_data,
    callback,
    table_name,
    page,
    data_name = "data",
    search_name = "search"
  ) => {
    const _this = this._this;
    const table = _this["table_" + table_name];
    if (_this.state[search_name]) {
      all_data = _this.state[data_name + "_temp"];
    }

    if (this._this.state.will_filter) {
      all_data = this._this.state.filtered_data;
    }

    let sliced_data = this.get_entries(all_data, null, table_name, page);

    this._this.setState(
      {
        [data_name]: sliced_data,
      },
      () => {
        table.set_data();
      }
    );
  };

  get_entries = (
    all_data = [],
    size = 1,
    table_name,
    page_number = 1,
    config = {}
  ) => {
    const context = this._this;
    page_number = parseInt(page_number);
    if (!size) {
      size = context.state[table_name + "_entries"];
    }

    if (!all_data) {
      all_data = context.state.all_data;
    }

    const slicedArray = this.paginate(all_data, size, page_number);
    // const slicedArray = all_data.slice(0, size);
    let totalPage =
      all_data.length < size ? 1 : Math.ceil(all_data.length / size);
    if (config?.total) {
      context.setState(
        {
          [table_name + "_entries"]: size,
          [table_name + "_current_page"]: page_number,
          [table_name + "_number_of_pages"]: config.pages,
          [table_name + "_total_entries"]: config.total,
        },
        () => {
          context.set_select_value(table_name, size);

          const table = context["table_" + table_name];
          table?.set_data();
        }
      );
    } else {
      context.setState(
        {
          [table_name + "_entries"]: size,
          [table_name + "_current_page"]: page_number,
          [table_name + "_number_of_pages"]: totalPage,
          [table_name + "_total_entries"]: all_data.length,
        },
        () => {
          context.set_select_value(table_name, size);

          const table = context["table_" + table_name];
          table?.set_data();
        }
      );
    }

    return slicedArray;
  };

  initiateView = (_this, initialState = {}) => {
    this._this = _this;
    const api = new APINavigation({ _this });
    this.api = api;

    _this.get_entries = this.get_entries;
    _this.set_select_value = this.set_select_value;

    _this.resize_functions = [];
    _this.scroll_functions = [];
    _this.error_form = this.error_form;
    _this.clear_errors = this.clear_errors;
    _this.reload_table = this.reload_table;
    _this.add_resize_function = this.add_resize_function;
    _this.add_scroll_function = this.add_scroll_function;
    _this.execute_scrolls = this.execute_scrolls;

    _this.error_timeout = setTimeout(() => {}, 1);

    _this.state = {
      width: this.getWidth(),
      height: this.getHeight(),
      isMobile: this.getWidth() < 720 ? true : false,
      ...initialState,
    };
    _this.componentDidMount = async () => {
      _this.resize = this.resize;
      _this.execute_resizes = this.execute_resizes;

      _this.setState({
        height: this.measure("body").height,
      });

      window.addEventListener("resize", () => {
        this.execute_resizes(_this);
        _this.setState({
          height: this.measure("body").height,
        });
      });

      // if (_this.props.location.pathname === "/advocacy-homepage") {
      //   return this.goTo("/" + _this.state.context);
      // }

      // if (_this.props.location.pathname === "/advocacy-doctor-view-details") {
      //   return this.goTo("/" + _this.state.context);
      // }

      if (_this.props.location.pathname === "/advocacy-doctor-register") {
        if (_this.props.location.search) {
          _this.state.context =
            _this.state.context + _this.props.location.search;
        }
        return this.goTo("/" + _this.state.context);
      }

      await api.init();
      const url = window.location.href;
      if (await this.account_check()) {
        const isLogin = url.includes("/" + _this.state.context + "/login");
        if (isLogin) {
          this.goTo("/" + _this.state.context + "/dashboard");
        }
      } else {
        this.logout();
      }

      //Blocker for unauthorized access
      const role = mem.get(Constants.ROLE);
      
      const current_context = window.location.pathname.replace(
        // **Removed unnecessary escaped char /^\/([^\/]*).*$/,
        /^\/([^/]*).*$/,
        "$1"
      );

      const current_role = this.role_map[role];

      if (current_role !== current_context) {
        if (!current_role) {
          mem.remove(Constants.JWT_TOKEN);
          mem.remove(Constants.ROLE);
          this.goTo("/" + current_context + "/login");
        } else {
          if (_this.props.location.pathname === "/onboard/forget-password-step-1") {
            return this.goTo("/onboard/forget-password-step-1");
          } else {
            return this.goTo("/" + current_role + "/dashboard");
          }
        }
      }

      if (_this.onCreate) {
        _this.onCreate();
      }
    };

    _this.componentWillUnmount = () => {
      window.removeEventListener("resize", () => {
        this.execute_resizes(_this);
      });

      if (_this.onDestroy) {
        _this.onDestroy();
      }
    };
  };

  execute_resizes = (_this) => {
    this.resize(_this);
    if (_this.on_resize) {
      _this.on_resize();
    }
    if (_this.resize_functions) {
      _this.resize_functions.forEach((item) => {
        item.method();
      });
    }
  };

  execute_scrolls = (_this) => {
    if (_this.scroll_functions) {
      _this.scroll_functions.forEach((item) => {
        item.method();
      });
    }
  };

  resize(_this) {
    const width = this.getWidth();
    if (width !== _this.state.width) {
      _this.setState({
        width: width,
        height: this.getHeight(),
      });
      this.checkIfMobile(_this);
    }
  }

  pad = (num, size, isDecimal = false) => {
    if (num === undefined || num == null) {
      return "";
    }
    let n = num.toString();
    while (n.length < size) n = "0" + n;
    if (isDecimal) {
      if (num.length === 0) {
        num = "0";
      }
      num = Number.parseInt(num);
      let temp = num.toFixed(2);
      temp = temp.toString();
      const arr = temp.split(".");
      const suffix = arr[1];

      return n + "." + suffix;
    } else {
      return n;
    }
  };

  pad2 = (number) => {
    return this.pad(number, 2);
  };

  measure = (id) => {
    let el = document.getElementById(id);
    if (el) {
      let bounds = el.getBoundingClientRect(el);
      return bounds;
    }
    return {};
  };

  random_number_in_range = (min, max) => {
    return Math.ceil(Math.random() * (max - min) + min);
  };

  uniqid = (prefix = "", random = false) => {
    const sec = Date.now() * 1000 + Math.random() * 1000;
    const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
    return `${prefix}${id}${
      random ? `.${Math.trunc(Math.random() * 100000000)}` : ""
    }`;
  };

  Row = (props = { _this: null, style: {} }) => {
    const _this = props._this;
    const isMobile = _this.state.isMobile;
    let width = _this.state.width;
    if (props.basis) {
      width = props.basis;
    }
    let flexDirection = "row";
    if (isMobile) {
      flexDirection = "column";
    } else {
      flexDirection = "row";
    }
    if (props.breakpoint !== undefined) {
      if (width <= props.breakpoint) {
        flexDirection = "column";
        if (props.breakpoint_2 !== undefined) {
          if (width <= props.breakpoint_2) {
            flexDirection = "row";
            if (props.breakpoint_3 !== undefined) {
              if (width <= props.breakpoint_3) {
                flexDirection = "column";
              } else {
                flexDirection = "row";
              }
            }
          } else {
            flexDirection = "column";
          }
        }
      } else {
        flexDirection = "row";
      }
    }
    return (
      <View style={{ ...props.style, flexDirection: flexDirection }}>
        {props.children}
      </View>
    );
  };

  Column = (props = { _this: null, style: {} }) => {
    const _this = props._this;
    const isMobile = _this.state.isMobile;
    let width = _this.state.width;
    if (props.basis) {
      width = props.basis;
    }
    let flexDirection = "column";
    if (isMobile) {
      flexDirection = "row";
    } else {
      flexDirection = "column";
    }
    if (props.breakpoint !== undefined) {
      if (width <= props.breakpoint) {
        flexDirection = "row";
        if (props.breakpoint_2 !== undefined) {
          if (width <= props.breakpoint_2) {
            flexDirection = "column";
            if (props.breakpoint_3 !== undefined) {
              if (width <= props.breakpoint_3) {
                flexDirection = "row";
              } else {
                flexDirection = "column";
              }
            }
          } else {
            flexDirection = "row";
          }
        }
      } else {
        flexDirection = "column";
      }
    }
    return (
      <View style={{ ...props.style, flexDirection: flexDirection }}>
        {props.children}
      </View>
    );
  };
  timestampToDate = (timestamp) => {
    timestamp = parseInt(timestamp);

    if (!timestamp) {
      return {
        year: "",
        month: "",
        day: "",
        am_pm: "",
        h_m: "",
        hour: "",
        minute: "",
        month_string: "",
        fail: true,
      };
    }

    var dateObj = new Date(timestamp);
    // var month = dateObj.getMonth() + 1; //months from 1-12
    // var day = dateObj.getDay();
    // var year = dateObj.getFullYear();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    let h_m_am_pm = dateObj.toLocaleString("en-US", {
      hour: "numeric",
      hour12: true,
      minute: "2-digit",
    });
    // **Currently unused variable
    // let newdate = year + "/" + month + "/" + day + " " + h_m_am_pm;
    let am_pm = h_m_am_pm.split(" ")[1]?.toLowerCase();
    let h_m = h_m_am_pm.split(" ")[0];
    let h = h_m.split(":")[0];
    let m = h_m.split(":")[1];
    let s = dateObj.getSeconds();

    let month_string = "";
    if (month === 1) {
      month_string = "January";
    } else if (month === 2) {
      month_string = "February";
    } else if (month === 3) {
      month_string = "March";
    } else if (month === 4) {
      month_string = "April";
    } else if (month === 5) {
      month_string = "May";
    } else if (month === 6) {
      month_string = "June";
    } else if (month === 7) {
      month_string = "July";
    } else if (month === 8) {
      month_string = "August";
    } else if (month === 9) {
      month_string = "September";
    } else if (month === 10) {
      month_string = "October";
    } else if (month === 11) {
      month_string = "November";
    } else if (month === 12) {
      month_string = "December";
    }

    let obj = {
      year: year,
      month: month,
      day: day,
      am_pm: am_pm,
      AM_PM: (am_pm + "").toUpperCase(),
      h_m: h_m,
      hour: h,
      minute: m,
      h: h,
      m: m,
      s: s,
      month_string: month_string,
    };

    return obj;
  };

  get_year_month_from_date = (date, delimiter = "-") => {
    const d = new Date(date);
    return d.getFullYear() + delimiter + (d.getMonth() + 1);
  };

  get_current_date = () => {
    const date = this.timestampToDate(Date.now());

    return date.month + "/" + this.pad2(date.day) + "/" + date.year;
  };
  get_date_string_by_timestamp = (timestamp) => {
    const date = this.timestampToDate(timestamp);

    return date.month + "/" + this.pad2(date.day) + "/" + date.year;
  };
  get_date_string_by_date = (d) => {
    const date = this.timestampToDate(new Date(d).getTime());

    const value = date.month + "/" + this.pad2(date.day) + "/" + date.year;
    if (!date.month || !date.day || !date.year) {
      return "";
    }
    return value;
  };
  get_dateobj_from_string = (mmddyyyy) => {
    const split = mmddyyyy.split("/");
    const month = parseInt(split[0]);
    const day = parseInt(split[1]);
    const year = parseInt(split[2]);
    const d = new Date();
    d.setMonth(month - 1);
    d.setDate(day);
    d.setFullYear(year);
    return d;
  };
  get_date_time_string_by_date = (d) => {
    const date = this.timestampToDate(new Date(d).getTime());

    const value =
      date.month +
      "/" +
      this.pad2(date.day) +
      "/" +
      date.year +
      " " +
      date.h_m +
      " " +
      date.am_pm;
    if (!date.month || !date.day || !date.year) {
      return "";
    }
    return value;
  };
  get_current_date_string = () => {
    const date = this.timestampToDate(Date.now());
    return date.month_string + " " + this.pad2(date.day) + ", " + date.year;
  };
  get_current_date_hr_mm_string = () => {
    const date = this.timestampToDate(Date.now());

    return (
      date.month_string +
      " " +
      this.pad2(date.day) +
      ", " +
      date.year +
      " " +
      date.h_m +
      " " +
      date.am_pm
    );
  };

  get_hhmmss_from_date = (date) => {
    let d = new Date(date);
    const timestamp = d.getTime();
    const obj = this.timestampToDate(timestamp);
    const value =
      this.pad2(parseInt(obj.h)) +
      ":" +
      this.pad2(parseInt(obj.m)) +
      ":" +
      this.pad2(parseInt(obj.s)) +
      " " +
      obj.AM_PM;

    return value; /* 
    let datetext = d.toTimeString();
    datetext = datetext.split(" ")[0];
    return datetext; */
  };

  msToHMS = (ms) => {
    // 1- Convert to seconds:
    let seconds = ms / 1000;
    // 2- Extract hours:
    const hours = parseInt(seconds / 3600);
    seconds = seconds % 3600;
    const minutes = parseInt(seconds / 60);
    seconds = seconds % 60;
    return hours + ":" + minutes + ":" + seconds;
  };

  title_case = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  generate_password = () => {
    var length = 16,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  validate_email = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  rand = () => {
    return Math.random() * 100;
  };

  get_param = (param_name) => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get(param_name);
    return c;
  };

  date_diff = (first, second) => {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  };

  get_age = (date) => {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  get_file = async () => {
    return new Promise((resolve, reject) => {
      let input = document.createElement("input");
      input.type = "file";

      input.onchange = (ev) => {
        const file = input.files[0];
        resolve(file);
      };
      input.click();
    });
  };

  get_file_extension = (filename) => {
    return filename?.substring(filename.lastIndexOf(".") + 1).toLowerCase();
  };

  get_specific_file = async (allowed_extensions = []) => {
    return new Promise((resolve, reject) => {
      let input = document.createElement("input");
      input.type = "file";
      console.log(input);

      input.onchange = (ev) => {
        const file = input.files[0];
        var url = input.value;
        var ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
        if (input.files && input.files[0] && allowed_extensions.includes(ext)) {
          var reader = new FileReader();

          reader.onload = (e) => {
            resolve({ file, uri: e.target.result });
          };
          reader.readAsDataURL(input.files[0]);
        } else {
          const _this = this._this;
          if (_this) {
            if (_this.failed_modal) {
              _this.failed_modal("Invalid file format.");
            }
          }
        }
      };
      input.click();
    });
  };

  get_doctor_profile_pic = async (other_extension = []) => {
    return new Promise((resolve, reject) => {
      let input = document.createElement("input");
      input.type = "file";

      console.log("test");
      input.onchange = (ev) => {
        const file = input.files[0];
        var url = input.value;
        var ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
        if (
          input.files &&
          input.files[0] &&
          (ext === "png" ||
            ext === "jpeg" ||
            ext === "jpg" ||
            other_extension.includes(ext))
        ) {
          var reader = new FileReader();
          const _this = this._this;

          reader.readAsDataURL(input.files[0]);
          reader.onload = (e) => {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = e.target.result;

            var stringLength =
              e.target.result.length - "data:image/png;base64,".length;
            var sizeInBytes =
              4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
            var sizeInKb = sizeInBytes / 1000;

            if (Math.ceil(sizeInKb) <= 2000) {
              resolve({ file, uri: e.target.result });
            } else {
              _this.failed_modal("Invalid file size.");
            }
          };
        } else {
          const _this = this._this;
          if (_this) {
            if (_this.failed_modal) {
              _this.failed_modal("Invalid file format.");
            }
          }
        }
      };
      input.click();
    });
  };

  get_image = async (other_extension = []) => {
    return new Promise((resolve, reject) => {
      let input = document.createElement("input");
      input.type = "file";
      console.log(input);

      input.onchange = (ev) => {
        const file = input.files[0];
        
        const blob = file.slice(0, file.size, file.type);
        const newFile = new File([blob], file.name.replace(/\s/g, '-'), {type: file.type});

        var url = input.value;
        var ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
        if (
          input.files &&
          input.files[0] &&
          (ext === "gif" ||
            ext === "png" ||
            ext === "jpeg" ||
            ext === "jpg" ||
            other_extension.includes(ext))
        ) {
          var reader = new FileReader();

          reader.onload = (e) => {
            resolve({ file: newFile, uri: e.target.result });
          };
          reader.readAsDataURL(input.files[0]);
        } else {
          const _this = this._this;
          if (_this) {
            if (_this.failed_modal) {
              _this.failed_modal("Invalid file format.");
            }
          }
        }
      };
      input.click();
    });
  };

  url_to_base64 = (url) => {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  };

  download_file = (image_link, tag) => {
    var a = document.createElement("a");
    a.href = image_link;
    a.download = tag;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  get_uri_from_file = async (file /* files[0] */) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  get_csv_contents = async () => {
    return new Promise(async (resolve, reject) => {
      const file = await this.get_specific_file(["csv"]);

      resolve(await this.read_csv_file(file.file));
    });
  };

  read_csv_file = async (csv_file) => {
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = function () {
        resolve(fr.result);
      };

      fr.readAsText(csv_file);
    });
  };

  logout = async () => {
    /*    mem.remove(Constants.JWT_TOKEN);
    mem.remove(Constants.ROLE); */

    // return url https://appsvc-backoffice-galen-001.azurewebsites.net/
    // return url https://platform.mwell.com.ph/
    const _this = this._this;

    mem.clear();
    _this.show_loading();

    const open_url = this.Constants.LOGOUT_URL;

    window.open(open_url, "_top");

    _this.hide_loading();
  };

  logout_context = async (_this) => {
    mem.clear();
    _this.show_loading();

    const open_url = this.Constants.LOGOUT_URL;

    window.open(open_url, "_top");

    _this.hide_loading();
  };

  logout_error = async (err_message) => {
    mem.remove(Constants.JWT_TOKEN);
    mem.remove(Constants.ROLE);

    this.goTo("/" + this._this.state.context + "/login");
  };

  account_check = async () => {
    return new Promise((resolve, reject) => {
      const token = mem.get(Constants.JWT_TOKEN);
      if (token) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  extract_data = (response) => {
    return response.data.d ?? response.data.data;
  };

  get_results = (response) => {
    const res = response?.data?.d;
    console.log(res);
    return {
      docCount: res.count,
      total: res.results.total,
      pages: res.results.pages,
    };
    // return (
    //   response?.data?.d?.total_pages ?? {
    //     docCount: 0,
    //     total: 0,
    //     pages: 1,
    //     next: {
    //       page: 1,
    //       limit: 100,
    //     },
    //   }
    // );
  };

  upload_image = async (
    props = {
      url: "",
      file: null,
      onFail: () => {},
      onSuccess: () => {},
      params: "",
    },
    text
  ) => {
    const _this = this._this;
    _this.show_loading("", text ? text : "Uploading photo...");

    this.newWebRequest({
      method: "post",
      url: props.url,
      params: {
        image: props.file,
        ...props.params,
      },
      onFail: (response) => {
        _this.hide_loading();
        props.onFail(response);
      },
      onSuccess: (response) => {
        _this.hide_loading();
        props.onSuccess(response.data.d.path, response);
      },
      isMultiPart: true,
    });

    this.axiosRequestWeb.defaults.headers.common["Content-Type"] = null;
  };
  upload_image_auth = async (
    props = {
      url: "",
      file: null,
      onFail: () => {},
      onSuccess: () => {},
    }
  ) => {
    const _this = this._this;
    _this.show_loading("", "Uploading photo...");

    this.newAuthRequest({
      method: "post",
      url: props.url,
      params: {
        image: props.file,
      },
      onFail: props.onFail,
      onSuccess: (response) => {
        props.onSuccess(response.data.d.path, response);
      },
      isMultiPart: true,
    });

    _this.hide_loading();

    this.axiosRequestWeb.defaults.headers.common["Content-Type"] = null;
  };

  download_image = async (
    path,
    container = "doctors_onboarding",
    defaultValue
  ) => {
    return new Promise(async (resolve, reject) => {
      // **Currently unused variable due to unreachable code below
      // const src =
      //   Constants.API_HOST_WEB + container + "/image/retrieve?path=" + path;

      const new_src =
        Constants.STORAGE_BASE_URL +
        "?path=" +
        path +
        "&container=" +
        container;

      resolve(new_src);
      return;
      // **Commented out due to the existing return keyword above
      // **making the code below unreachable
      // const options = {
      //   headers: {
      //     "Ocp-Apim-Subscription-Key": Constants.SUBSCRIBTION_KEY,
      //     Authorization: "Bearer " + mem.get(Constants.JWT_TOKEN),
      //   },
      // };

      // fetch(src, options)
      //   .then((res) => res.blob())
      //   .then((blob) => {
      //     if (blob.type.includes("undefined")) {
      //       resolve(defaultValue);
      //     } else {
      //       resolve(URL.createObjectURL(blob));
      //     }
      //   });
    });
  };

  method_queue = (method = () => {}) => {
    setTimeout(method, 1);
  };

  container_filename = (path = "") => {
    let arr = path.split("-");
    arr.shift();
    return arr.join("-");
  };

  bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  get_file_size = (file) => {
    if (file) {
      let file_size = this.bytesToSize(file.size);
      return file_size;
    }
  };

  get_file_size_from_url = (url) => {
    return new Promise(async (resolve, reject) => {
      const imageURL = url;
      if (imageURL) {
        const response = await fetch(imageURL);
        const blob = await response.blob();
        resolve(this.bytesToSize(blob.size));
      }
    });
  };

  set_images = (
    data,
    path,
    image_identifier,
    table,
    container,
    default_image
  ) => {
    const _this = this._this;
    data.map(async (item, index) => {
      try {
        if (!path) {
          return;
        }
        if (!item) {
          return;
        }
        const arr = path.split(".");
        let imagePath = "";
        if (arr.length === 0) {
          return;
        }

        if (arr.length === 1) {
          imagePath = item[arr[0]];
        }
        if (arr.length === 2) {
          imagePath = item[arr[0]][arr[1]];
        }
        if (arr.length === 3) {
          imagePath = item[arr[0]][arr[1]][arr[2]];
        }
        if (arr.length === 4) {
          imagePath = item[arr[0]][arr[1]][arr[2]][arr[3]];
        }
        if (arr.length === 5) {
          imagePath = item[arr[0]][arr[1]][arr[2]][arr[3]][arr[4]];
        }

        if (!imagePath) {
          return;
        }

        let image = await this.download_image(
          imagePath,
          container,
          default_image
        );
        if (image) {
          _this.setState({
            [image_identifier + item._id]: image,
          });
        }
        if (data.length === index + 1) {
          setTimeout(() => {
            _this.reload_table(table);
          }, 1000);
        }
      } catch {}
    });
  };

  get_active_text = (item) => {
    return item.status ? (
      item.status === "Active" ? (
        <Text style={{ color: this.colors.active }}>Active</Text>
      ) : (
        <Text style={{ color: this.colors.inactive }}>Inactive</Text>
      )
    ) : (
      <Text style={{ color: this.colors.unset }}>Unset</Text>
    );
  };

  get_transaction_status_text = (item) => {
    if (item.status === "Paid") {
      return <Text style={{ color: this.colors.active }}>Paid</Text>;
    } else if (item.status === "Complete") {
      return <Text style={{ color: this.colors.active }}>Complete</Text>;
    } else if (item.status === "Completed") {
      return <Text style={{ color: this.colors.active }}>Completed</Text>;
    } else if (item.status === "Confirmed") {
      return <Text style={{ color: this.colors.active }}>Confirmed</Text>;
    } else if (item.status === "Pending") {
      return <Text style={{ color: this.colors.inactive }}>Pending</Text>;
    } else if (item.status === "Refund") {
      return <Text style={{ color: this.colors.inactive }}>Refund</Text>;
    } else if (item.status === "Cancelled") {
      return <Text style={{ color: this.colors.inactive }}>Cancelled</Text>;
    } else if (item.status === "Unpaid") {
      return <Text style={{ color: "#f32c12" }}>Unpaid</Text>;
    } else {
      return <Text style={{ color: this.colors.unset }}>{item.status}</Text>;
    }
  };

  
  get_color_status_text = (status) => {
    let newStatus = status.toLocaleLowerCase()

    if (newStatus === "completed") {
      status = <Text style={{ color: "#27ae60" }}>Completed</Text>;
    } else if (newStatus === "active") {
      status = <Text style={{ color: "#27ae60" }}>Active</Text>;
    } else if (newStatus === "paid") {
      status = <Text style={{ color: "#27ae60" }}>Paid</Text>;
    } else if (newStatus === "requested") {
      status = <Text style={{ color: UI.colors.primary }}>Requested</Text>;
    } else if (newStatus === "pending") {
      status = <Text style={{ color: "#FFCE00" }}>Pending</Text>;
    } else if (newStatus === "inactive") {
      status = <Text style={{ color: "#FFCE00" }}>Inactive</Text>;
    } else if (newStatus === "failed") {
      status = <Text style={{ color: "#e74c3c" }}>Failed</Text>;
    } else if (newStatus === "unpaid") {
      status = <Text style={{ color: "#e74c3c" }}>Failed</Text>;
    } else if (newStatus === "cancelled") {
      status = <Text style={{ color: "#e74c3c" }}>Cancelled</Text>;
    } else {
      status = (
        <Text>{status}</Text>
      )
    }
    
    return status;
  };

  get_bg_color_status_text = (status) => {
    let newStatus = status.toLocaleLowerCase()

    if (newStatus === "completed") {
      status = (
        <Text
          style={{
            background: "#27ae60",
            padding: "5px 10px",
            color: "white",
            borderRadius: 5,
          }}
        >
          Completed
        </Text>
      );
    } else if (newStatus === "active") {
      status = (
        <Text
          style={{
            background: "#27ae60",
            padding: "5px 10px",
            color: "white",
            borderRadius: 5,
          }}
        >
          Active
        </Text>
      );
    } else if (newStatus === "paid") {
      status = (
        <Text
          style={{
            background: "#27ae60",
            padding: "5px 10px",
            color: "white",
            borderRadius: 5,
          }}
        >
          Paid
        </Text>
      );
    } else if (newStatus === "requested") {
      status = (
        <Text
          style={{
            background: UI.colors.primary,
            padding: "5px 10px",
            color: "white",
            borderRadius: 5,
          }}
        >
          Requested
        </Text>
      );
    } else if (newStatus === "pending") {
      status = (
        <Text
          style={{
            background: "#FFCE00",
            padding: "5px 10px",
            color: "white",
            borderRadius: 5,
          }}
        >
          Pending
        </Text>
      );
    } else if (newStatus === "inactive") {
      status = (
        <Text
          style={{
            background: "#FFCE00",
            padding: "5px 10px",
            color: "white",
            borderRadius: 5,
          }}
        >
          Inactive
        </Text>
      );
    } else if (newStatus === "failed") {
      status = (
        <Text
          style={{
            background: "#e74c3c",
            padding: "5px 10px",
            color: "white",
            borderRadius: 5,
          }}
        >
          Failed
        </Text>
      );
    } else if (newStatus === "unpaid") {
      status = (
        <Text 
          style={{
            background: "#e74c3c",
            padding: "5px 10px",
            color: "white",
            borderRadius: 5,
          }}
        >
          Unpaid
        </Text>
      );
    } else if (newStatus === "cancelled") {
      status = (
        <Text
          style={{
            background: "#e74c3c",
            padding: 5,
            color: "white",
            borderRadius: 5,
          }}
        >
          Cancelled
        </Text>
      );
    } else {
      status = (
        <Text>{status}</Text>
      );
    }
    return status;
  };

  change_text_color = (text, color) => {
    const textColor = color ? color : "#000";
    return <Text style={{ color: textColor }}>{text}</Text>;
  };

  download_moa = async () => {
    // **Opens the linked document in a new window or tab
    // **Old Implementation: var win = window.open("/files/MOA-Template.pdf", "_blank");
    // removed unused variable
    window.open("/files/MOA-Template.pdf", "_blank");
  };

  setState = this._this ? this._this.setState : () => {};

  getObject = (theObject, keyword, actual_item, excluded_props = []) => {
    var result = null;
    if (theObject instanceof Array) {
      for (var i = 0; i < theObject.length; i++) {
        result = this.getObject(theObject[i], keyword, actual_item);
        if (result) {
          break;
        }
      }
    } else {
      for (var prop in theObject) {
        const value = theObject[prop]?.toString().toLowerCase();
        const does_include = value?.includes(keyword);
        if (does_include) {
          if (excluded_props.includes(prop)) {
          } else {
            return actual_item;
          }
        }
        if (
          theObject[prop] instanceof Object ||
          theObject[prop] instanceof Array
        ) {
          result = this.getObject(theObject[prop], keyword, actual_item);
          if (result) {
            break;
          }
        }
      }
    }
    return result;
  };

  escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  };

  replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(this.escapeRegExp(find), "g"), replace);
  };

  change_password = async (_this) => {
    _this.show_loading();
    UI.authRequest({
      method: "get",
      url: "app/mwell/auth/authorize?policy_name=B2C_1_ForgotPassword&platform=web",
      params: {},
      onFail: async (err) => {
        _this.hide_loading();
      },
      onSuccess: async (response) => {
        window.location.href = response.data.d.url;
      },
      onFinish: () => {
        // _this.hide_loading();
      },
    });
  };

  dynamicSort = (property) => {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      let result;

      if (typeof a[property] == "number") {
        result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      } else {
        result =
          a[property]?.toLowerCase() < b[property]?.toLowerCase()
            ? -1
            : a[property]?.toLowerCase() > b[property]?.toLowerCase()
            ? 1
            : 0;
      }

      return result * sortOrder;
    };
  };

  isVideo = (ext) => {
    if (Constants.video_formats.includes(ext.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  ConvertToCSV = (objArray) => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  };

  DownloadCSV = (title, data) => {
    if (data.length > 0) {
      const headers = Object.keys(data[0]);
      let columns = {};
      headers.forEach((s) => {
        columns[s] = s;
      });

      const final_data = [columns].concat(data);

      const csv = UI.ConvertToCSV(final_data);
      const csvContent = "data:text/csv;charset=utf-8," + csv;
      const encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", title + ".csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  set_context = (_this) => {
    this._this = _this;
  };

  getDialCountryCode = async (countryCode) => {
    let final;
    try {
      let response = await axios.get(
        "https://restcountries.com/v3.1/alpha/" +
          countryCode.toLocaleLowerCase()
      );
      const data = response.data;
      const code = data[0].idd;
      final = code.root + code.suffixes[0];
      return final;
    } catch (error) {
      console.log("dial code request error:", error);
      return "";
    }
  };
  
  get_fullname = (firstName = "", middleName = "", lastName = "") => {
    const first_name = UI.capitalize(firstName) ?? ""
    const middle_name = UI.capitalize(middleName) ?? ""
    const last_name = UI.capitalize(lastName) ?? ""

    return first_name + " " + middle_name + " " + last_name;
  };

  get_city_by_region = (regions, value) => {
    const findProvince = regions.find(region => region[_.keys(region)].region_name === value)
    const listOfProvince = findProvince[Object.keys(findProvince)[0]].province_list
    const listofCity = []

    Object.keys(listOfProvince).forEach(key => listofCity.push(...Object.keys(listOfProvince[key].municipality_list)))

    return listofCity
  };

}

const UI = new ui();
export default UI;
