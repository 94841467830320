import React from "react";
import UI from "../../shared/Components/UI/js";
import {
  // mem,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import Layout, { PreviewFile } from "../_components/Layout";
import DigitalESignature from "../../ComponentsFC/onboard/create/DigitalESignature";

const FouthForm = ({ _this }) => {
  const { width, downloaded_mpc, file_signature_path } = _this.state;

  const row_width = width > 1000 ? width - 400 : "100%";

  return (
    <UI.Column
      style={{ marginBottom: 10, marginTop: 40 }}
      _this={_this}
      // breakpoint={1000}
      // breakpoint_2={720}
    >
      <UI.Row
        style={{ width: row_width, justifyContent: "space-between" }}
        _this={_this}
        // breakpoint={1350}
      >
        <View style={{ width: "100%" }}>
          <Layout.FilePicker
            _this={_this}
            title={"Upload Medical Practice Document"}
            // isRequired={true}
            additional={
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  onClick={() => {
                    _this.download_file_object(downloaded_mpc);
                    // _this.download_path(moa_path);
                  }}
                  style={styles.download_button}
                >
                  <Text style={styles.download_text}>Download</Text>
                </TouchableOpacity>
              </View>
            }
            text={"Drop Your File Here"}
            state_name={"mpc"}
            onChange={(file) => {
              _this.auth_upload("mpc", file);
            }}
            allowed_files={["jpg", "jpeg", "pdf", "png"]}
            display_type={"filename_size"}
          />
          {UI.box(10)}
          <Text
            style={{
              width: "90%",
              fontSize: 14,
              fontWeight: "bold",
              color: "rgb(191, 191, 191)",
            }}
          >
            Any certificate that shows additional level of learning received
            like being a Diplomate or Fellow.
          </Text>
          <PreviewFile _this={_this} {...downloaded_mpc} />
        </View>
        {UI.box(20)}
        <View style={{ width: "100%" }}>
          <DigitalESignature _this={_this} />
          <PreviewFile _this={_this} {...file_signature_path} />
        </View>
        {UI.box(20)}
        <View style={{ width: "100%" }}></View>
      </UI.Row>
    </UI.Column>
  );
};

const styles = StyleSheet.create({
  download_text: {
    color: "white",
    fontWeight: "bold",
    fontSize: 12,
  },
  download_button: {
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: UI.colors.primary,
    marginLeft: 10,
    borderRadius: 5,
    height: 30,
    marginTop: -5,
  },
  file_text: {
    fontSize: 15,
    fontWeight: "bold",
    color: UI.colors.secondary,
  },
  file_container: {
    width: "100%",
    height: 80,
    backgroundColor: "#EFF3F7",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#C9C9C9",
    borderStyle: "dashed",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  fade: {
    color: "#BFBFBF",
  },
  asterisk: {
    color: "#FF0000",
  },
  option_container: {
    height: 40,
    width: "100%",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 2,
    padding: 5,
    borderColor: "#CACACA",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  btn_text_1: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
  },
  blue_btn_2: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  text_1: {
    color: "#AFAFAF",
    fontSize: 14,
  },
  image_container: {
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  header_3: {
    fontSize: 18,
    color: UI.colors.primary,
    marginTop: 2,
    fontWeight: "bold",
  },
});

export default FouthForm;
