import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm, { Checkbox } from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
// import AddSchedule from "./AddSchedule";
import "./css.css";

const context = "voucher";

export default class VoucherAddVoucher extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Voucher List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      isUse24Hours: false,

      title: "",
      quantity: "",
      discount: "",
      max_usage_per_user: "",
      description: "",
      status: "",
      type: "",
      allowed_users: [],
      recurringDays:[],
      arrayOfSchedule:[],
      doctorGroup:[],
      patientGroup:[],
      doctor_group:"",
      patient_group:"",

      validityServices: {
        cn:false,
        cl:false,
      },

      is_allowed_all: true,
      users: [],
      isLoading: true,

      startDate: "",
      endDate: "",
      startDate_dashed: "",
      endDate_dashed: "",
    });
  }

  onCreate = () => {
    this.loadPatientGroup();
    this.loadDoctorGroup();
  };

  loadPatientGroup = async () => {
    this.show_loading();

    UI.webRequest({
      method: "post",
      url: "admin/usergroup/all",
      params: {
        names: ['patients-group']
      },
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            patientGroup: data,
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  loadDoctorGroup = async () => {
    this.show_loading();

    UI.webRequest({
      method: "post",
      url: "admin/usergroup/all",
      params: {
        names: ['doctors-group']
      },
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            doctorGroup: data,
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  load_users = async () => {
    this.show_loading();
    this.setState({
      isLoading: true,
    });
    UI.webRequest({
      method: "get",
      url: "admin/cms/users",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        const new_data = data.map((item, index) => {
          return { label: item.name, value: item._id, email: item.email };
        });
        this.setState({
          users: new_data,
        });
      },
      onFinish: () => {
        this.hide_loading();
        this.setState({
          isLoading: false,
        });
      },
    });
  };

  saveSchedule = async () => {
    
    let {
      recurringDays
    } = this.state;


    this.setState({
      arrayOfSchedule: [...this.state.arrayOfSchedule, recurringDays]
    },
    () => {
      this.reload_table("schedule_list");
    })
  }

  submit = async () => {
    UI.clear_errors();

    let {
      title,
      quantity,
      discount,
      max_usage_per_user,
      description,
      status,
      type,
      allowed_users,
      is_allowed_all,
      startDate_dashed,
      endDate_dashed,
      arrayOfSchedule,
      doctor_group,
      patient_group,
      validityServices,
      // isUse24Hours
    } = this.state;

    let validity_services = []

    if (validityServices.cn) {
      validity_services.push("CN")
    }

    if (validityServices.cl) {
      validity_services.push("CL")
    }

    if (!title) {
      await UI.error_form("title", "Title is required.");
    }
    if (!quantity) {
      await UI.error_form("quantity", "Quantity is required.");
    }
    if (!discount) {
      await UI.error_form("discount", "Discount Percentage is required.");
    }
    if (!max_usage_per_user) {
      await UI.error_form(
        "max_usage_per_user",
        "Max Usage Per User is required."
      );
    }
    if (!description) {
      await UI.error_form("description", "Description is required.");
    }
    if (!status) {
      await UI.error_form("status", "Status is required.");
    }

    // if (!type) {
    //   await UI.error_form("type", "Status is required.");
    // }

    if (!is_allowed_all) {
      if (allowed_users.length === 0) {
        await UI.error_form(
          "allowed_users",
          "Please select at least one user."
        );
      }
    }

    if (validity_services.length === 0) {
      return this.show_modal(
        <Failed
          description={"Validity Service is required."}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      )
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      this.hide_loading();
      return;
    }

    this.show_loading();
    UI.webRequest({
      method: "post",
      url: "admin/vouchers",
      params: {
        allowedUsers: allowed_users,
        allowAllUsers: is_allowed_all,
        title: title,
        initialQuantity: quantity,
        description: description,
        status: status,
        validityService: validity_services,
        type: type,
        discount: discount,
        maxUsagePerPerson: max_usage_per_user,
        startDate: startDate_dashed,
        endDate: endDate_dashed,
        doctorsGroup: doctor_group,
        patientsGroup: patient_group,
        schedule:arrayOfSchedule,
        // is24Hours: isUse24Hours ? false : true,
      },
      onFail: async (err) => {
        this.show_modal(<Failed onDismiss={this.hide_modal} />);
      },
      onSuccess: async (response) => {
        // UI.set_multiple_select_value("allowed_users", []);

        this.show_modal(
          <Success
            description={"Successfully added the voucher."}
            onDismiss={() => {
              UI.clear_forms();
              UI.set_select_value("status", "");
              this.setState({
                title: "",
                quantity: "",
                discount: "",
                max_usage_per_user: "",
                description: "",
                status: "",
                type: "",
                allowed_users: [],

                is_allowed_all: true,
              });
              UI.goBack()
              this.hide_modal();
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;
    const { 
      // height, 
      // width, 
      // isMobile, 
      is_allowed_all, 
      // isLoading 
    } = _this.state;
    const Users = this.state.users.map((item) => {
      return <option value={item.value}>{`${item.label} - ${item.email}`}</option>;
    });
    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Add New Voucher
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to complete registration
            </Text>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Voucher Title"}
              placeholder={"Enter Voucher Title"}
              state_name={"title"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.title}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Quantity"}
              placeholder={"Enter Voucher Quantity"}
              state_name={"quantity"}
              style={{ flex: 1 }}
              isRequired={true}
              isNumber={true}
              maxLength={UI.Constants.limits.price}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Discount Percentage"}
              placeholder={"Enter Discount Percentage"}
              state_name={"discount"}
              style={{ flex: 1 }}
              isRequired={true}
              isNumber={true}
              maxLength={3}
            />
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Max Usage Per User"}
              placeholder={"Enter Max Usage Per User"}
              state_name={"max_usage_per_user"}
              style={{ flex: 1 }}
              isRequired={true}
              isNumber={true}
              maxLength={UI.Constants.limits.price}
            />
            {UI.box(10)}

            <Layout.DatePicker
              _this={_this}
              title={"Start Date"}
              isRequired={false}
              placeholder={"mm/dd/yyyy"}
              state_name={"startDate"}
              date_props={{}}
            />
            {UI.box(10)}
            <Layout.DatePicker
              _this={_this}
              title={"End Date"}
              isRequired={false}
              placeholder={"mm/dd/yyyy"}
              state_name={"endDate"}
              date_props={{}}
            />
          </UI.Row>

          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={true}
              state_name={"status"}
            >
              <option value="">Select Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Type"}
              isRequired={false}
              state_name={"type"}
            >
              <option value="">Select type</option>
              <option value="mWellHealthPass">mWellHealthPass</option>
            </Layout.SelectPicker>
            {/* <View style={{ flex: 1 }}>
              <Layout.SelectPicker
                _this={_this}
                title={"Doctor Group"}
                isRequired={true}
                state_name={"doctor_group"}
              >
                <option value="">Select Doctor Group</option>
                {this.state.doctorGroup?.map((item) => {
                  return item ? (
                    <option value={item._id}>{item.name}</option>
                  ) : (
                    <View></View>
                  );
                })}
              </Layout.SelectPicker>
            </View>
            {UI.box(10)}
            <View style={{ flex: 1 }}>
            <Layout.SelectPicker
                _this={_this}
                title={"Doctor Group"}
                isRequired={true}
                state_name={"patient_group"}
              >
                <option value="">Select Patient Group</option>
                {this.state.patientGroup?.map((item) => {
                  return item ? (
                    <option value={item._id}>{item.name}</option>
                  ) : (
                    <View></View>
                  );
                })}
              </Layout.SelectPicker>
            </View> */}
          </UI.Row>

          {UI.box(20)}

            <View style={{flex:1}}>

              <span className="font-semibold" style={{color: UI.colors.primary}}>
                Validity Service
              </span>
              {UI.box(10)}

              <UI.Row _this={_this} breakpoint={1070} style={{gap:5}}>
                <View>
                  <div className="flex gap-5">
                    <Checkbox
                      _this={_this}
                      text={"Consult Now"}
                      onClick={(value) => {
                        this.setState({
                          validityServices: Object.assign({}, _this.state.validityServices, {cn: !_this.state.validityServices.cn}) ,
                        });
                        console.log(_this.state.validityServices)
                      }}
                      is_checked={_this.state.validityServices.cn}
                      color={"#2E2E2E"}
                    />
                    {/* {UI.box(10)} */}
                    <Checkbox
                      _this={_this}
                      text={
                        "Consult Later"
                      }
                      onClick={(value) => {
                        this.setState({
                          validityServices: Object.assign({}, _this.state.validityServices, {cl: !_this.state.validityServices.cl}),
                        });
                        console.log(_this.state.validityServices)
                      }}
                      is_checked={_this.state.validityServices.cl}
                      color={"#2E2E2E"}
                    />
                  </div>
                  {UI.box(5)}
                  
                </View>
                
              </UI.Row>
              
            </View>

            {UI.box(10)}
            
            {/* <View style={{flex:1}}>

              <span className="font-semibold" style={{color: UI.colors.primary}}>
                Can be used for 24 hrs?
              </span>

              {UI.box(10)}

              <UI.Row _this={_this} breakpoint={1070}>
                <Checkbox
                  _this={_this}
                  text={"Yes"}
                  onClick={(value) => {
                    this.setState({
                      isUse24Hours: false,
                    });
                  }}
                  is_checked={!_this.state.isUse24Hours}
                  color={"#2E2E2E"}
                />
                {UI.box(10)}
                <Checkbox
                  _this={_this}
                  text={
                    "No"
                  }
                  onClick={(value) => {
                    this.setState({
                      isUse24Hours: true,
                    });
                  }}
                  is_checked={_this.state.isUse24Hours}
                  color={"#2E2E2E"}
                />
              </UI.Row>
            </View>

            {UI.box(20)} */}

          {/* <AddSchedule _this={this}/>

          {UI.box(20)} */}
     
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Description"}
              placeholder={"Enter Description Here"}
              state_name={"description"}
              style={{ flex: 1 }}
              height={100}
              isRequired={false}
              type={"big"}
              maxLength={UI.Constants.limits.description}
            />
          </UI.Row>
          {UI.box(20)}

          <Checkbox
            onClick={() => {
              this.setState(
                {
                  is_allowed_all: !this.state.is_allowed_all,
                },
                () => {
                  if (is_allowed_all) {
                    if (this.state.users.length === 0) {
                      this.load_users();
                    }
                  }
                }
              );
            }}
            is_checked={is_allowed_all}
            text={"Allow all users"}
          />

          {!is_allowed_all && (
            <View style={{ marginTop: 10 }}>
              <Layout.SelectPicker
                _this={_this}
                title={"Select Users"}
                isRequired={true}
                state_name={"allowed_users"}
                isMultiple={true}
                onChange={(e) => {}}
              >
                <option value="">Select Users</option>
                {Users}
              </Layout.SelectPicker>
            </View>
          )}

          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"ADD VOUCHER"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});