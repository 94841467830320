import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";
const context = "onboard";

export default class OnboardUpdateNutrition extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Nutrition Program",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;
    const { 
      // height,
      width, 
      // isMobile
    } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={1000} style={{ width: "100%" }}>
            <Text style={{ ...styles.header_3, flex: 1 }}>
              Update Nutrition Program
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to complete registration
            </Text>
          </UI.Row>

          <UI.Row
            style={{
              marginTop: 20,
              alignItems: width > 800 ? "center" : "flex-start",
            }}
            breakpoint={800}
            _this={_this}
          >
            <Image
              height={90}
              width={90}
              tintColor={"transparent"}
              source={UI.SAMPLE_USER}
            />

            <UI.Row
              style={{
                marginLeft: width > 800 ? 30 : 0,
                marginTop: width > 800 ? 0 : 30,
                width: width > 1000 ? null : "100%",
              }}
              breakpoint={1068}
              breakpoint_2={800}
              _this={_this}
            >
              <Description main={"001010"} sub={"Patient id"} />
              {UI.box(30)}
              <Description main={"Patient Name Here"} sub={"Patient Name"} />
            </UI.Row>
            {UI.box(20)}

            <UI.Row
              style={{ width: width > 1000 ? null : "100%" }}
              breakpoint={1068}
              breakpoint_2={800}
              _this={_this}
            >
              {UI.box(width > 1068 ? 30 : 0)}
              <Description
                main={"mm/dd/yyyy 00:00am"}
                sub={"Date/Time Requested"}
              />
              {UI.box(30)}
              <Description
                main={"mm/dd/yyyy 00:00am"}
                sub={"Date/Time Requested"}
              />
            </UI.Row>
          </UI.Row>

          {UI.box(20)}

          <View style={{ width: width > 720 ? 300 : "100%" }}>
            <Layout.FilePicker
              _this={_this}
              title={"Upload Nutrition Plan File (.docx or .pdf)"}
              additional={""}
              isRequired={true}
              text={"Drop Your File Here"}
              state_name={"license_photo"}
              onChange={(file) => {}}
            />

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Layout.Btn
                color={UI.colors.primary}
                onClick={() => {
                  // UI.goTo("/onboard/dashboard");
                }}
                text={"SAVE CHANGES"}
              />
              {UI.box(20)}

              <Layout.Btn
                color={"#E00000"}
                onClick={() => {}}
                text={"CANCEL"}
              />
            </View>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const Description = (props = { main: "", sub: "" }) => {
  return (
    <View style={{ width: "100%" }}>
      <Text style={styles.main_text}>{props.main}</Text>
      <Text style={styles.sub_text}>{props.sub}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  sub_text: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#B4B4B4",
  },
  main_text: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#535353",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },

  option_container: {
    height: 40,
    width: "100%",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 2,
    padding: 5,
    borderColor: "#CACACA",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  btn_text_1: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
  },
  blue_btn_2: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  text_1: {
    color: "#AFAFAF",
    fontSize: 14,
  },
  image_container: {
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  header_3: {
    fontSize: 16,
    color: UI.colors.primary,
    marginTop: 2,
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
