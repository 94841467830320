import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  // Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
// import CustomDatatable from "../_components/CustomDatatable";
// import EntryPicker from "../_components/EntryPicker";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
// import { DeleteConfirmation, Failed } from "../_components/Modal";
import "./css.css";

const context = "voucher";

export default class VoucherHealthpassHistoryView extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Health Pass History",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      healthpass_details:null,
      context: context,
    });
  }

  onCreate = () => {
    this.load_data();
  };

  load_data = async () => {
    const data = mem.get("view_healthpass_history");
    let healthpass_history = JSON.parse(data);

    this.setState({
      healthpass_details: healthpass_history
    })
  };

  exportCSV = () => {
    const csv_data = [""].map((a) => {
    let valid_until = UI.get_date_string_by_date(this.state.healthpass_details?.healthpass.endDate);
    const date_used = UI.get_date_string_by_date(this.state.healthpass_details.appointment?.createdAt);
      console.log(valid_until)
      const d = {
        Row: this.state.healthpass_details._id + 1,
        "Health Pass Title": this.state.healthpass_details?.healthpass.title ?? " ",
        "Valid Until": valid_until ?? " ",
        "Date Used": date_used ?? " ",
        "Validity Period": "Weekday",
        "Validity Service": this.state.healthpass_details?.healthpass?.validityService === "CN" ? "Consult Now" : "Consult Later",
        "Payment Type": this.state.healthpass_details?.appointment?.payType,
        "Applicable Payment": this.state.healthpass_details?.appointment?.payType,
        "Heatlh Pass Use": 1,
        "For User Type": "Existing"
      };
      return d
    })

    UI.DownloadCSV(
      "Healthpass History Details" + UI.get_current_date_hr_mm_string(),
      csv_data
    );
  }

  render() {
    const _this = this;
    const { 
      // width, 
      healthpass_details } = _this.state;
    let booking_time = UI.getCurrentTimeStampPHConsult(healthpass_details?.appointment?.start);
    let valid_until = UI.get_date_string_by_date(healthpass_details?.healthpass?.endDate);
    const date_used = UI.get_date_string_by_date(healthpass_details?.appointment?.createdAt);

    return (
      <MainLayout context={context} _this={_this}>
        {/* <View style={{ ...styles.main_box_content, padding: 0 }}> */}
          <UI.Row breakpoint={840} _this={_this} style={{gap: 10}}>
            <View style={{ ...styles.main_box_content, padding: 20, flex: 1 }}>
              <Text style={{color:UI.colors.secondary, fontSize: 17, fontWeight:600}}>Consultation Details</Text>

              {UI.box(20)}

              <UI.Row breakpoint={840} _this={_this}  style={{
                ...styles.border_style,
                borderBottomWidth: 2,
                paddingBottom: 20,
                gap:20,
                alignItems:'center'
              }}
              >
                <img
                  style={{ height: 100, width: 100, borderRadius: 5 }}
                  src={UI.SAMPLE_USER}
                  alt=""
                />
                <View>
                  <Text style={{ fontSize: 16, fontWeight:600}}>Patient {healthpass_details?.appointment?.patient?.name[0].given[0] + healthpass_details?.appointment?.patient?.name[0].family}</Text>
                  {/* <Text style={{color:UI.colors.unset, fontSize: 14, fontWeight:600}}>juanadelacruz@gmail.com</Text> */}
                  {/* <Text style={{color:UI.colors.unset, fontSize: 14, fontWeight:600}}>094624584147</Text> */}
                </View>
              </UI.Row>

              {UI.box(20)}

              <UI.Row breakpoint={840} _this={_this} style={{
                gap:20,
                alignItems:'center'
              }}>
                <img
                  style={{ height: 100, width: 100, borderRadius: 5 }}
                  src={UI.DOCTOR_USER}
                  alt=""
                />
                <View>
                  <Text style={{ fontSize: 16, fontWeight:600}}>Doctor {healthpass_details?.appointment?.practitioner?.name[0].given[0] + " " + healthpass_details?.appointment?.practitioner?.name[0].family}</Text>
                  {/* <Text style={{color:UI.colors.unset, fontSize: 14, fontWeight:600}}>Doctor's Specialty</Text> */}
                  <Text style={{color:UI.colors.unset, fontSize: 14, fontWeight:600}}>ID# {healthpass_details?.appointment?.practitioner?._id}</Text>
                </View>
              </UI.Row>

              {UI.box(20)}

              <View>
                <UI.Row breakpoint={840} _this={_this} style={{justifyContent:'space-between', alignItems: 'center'}}>
                  <Text style={{color:UI.colors.primary, fontWeight:600}}>Appointment ID</Text>
                  <Text style={{fontWeight:600}}>{healthpass_details?.appointment?._id}</Text>
                </UI.Row>
                {UI.box(5)}
                <UI.Row breakpoint={840} _this={_this} style={{justifyContent:'space-between', alignItems: 'center'}}>
                  <Text style={{color:UI.colors.primary, fontWeight:600}}>Consult Price</Text>
                  <Text style={{fontWeight:600}}>Php 000.000</Text>
                </UI.Row>
                {UI.box(5)}
                <UI.Row breakpoint={840} _this={_this} style={{justifyContent:'space-between', alignItems: 'center'}}>
                  <Text style={{color:UI.colors.primary, fontWeight:600}}>Payment Method</Text>
                  <Text style={{fontWeight:600}}>{healthpass_details?.appointment?.payType}</Text>
                </UI.Row>
                {UI.box(5)}
                <UI.Row breakpoint={840} _this={_this} style={{justifyContent:'space-between', alignItems: 'center'}}>
                  <Text style={{color:UI.colors.primary, fontWeight:600}}>Booking Type</Text>
                  <Text style={{fontWeight:600}}>{healthpass_details?.healthpass?.validityService === "CN" ? "Consult Now" : "Consult Later"}</Text>
                </UI.Row>
                {UI.box(5)}
                <UI.Row breakpoint={840} _this={_this} style={{justifyContent:'space-between', alignItems: 'center'}}>
                  <Text style={{color:UI.colors.primary, fontWeight:600}}>Booking Date</Text>
                  <Text style={{fontWeight:600}}>{booking_time.slice(0,11)}</Text>
                </UI.Row>
                {UI.box(5)}
                <UI.Row breakpoint={840} _this={_this} style={{justifyContent:'space-between', alignItems: 'center'}}>
                  <Text style={{color:UI.colors.primary, fontWeight:600}}>Booking Time</Text>
                  <Text style={{fontWeight:600}}>{booking_time.slice(11,18)}</Text>
                </UI.Row>
              </View>
            </View>
            {UI.box(10)}
            <View style={{ ...styles.main_box_content, padding: 20, flex: 2 }}>
              <UI.Row breakpoint={840} _this={_this} style={{justifyContent:'space-between', alignItems: 'start'}}>
                <Text style={{color:UI.colors.secondary, fontSize: 17, fontWeight:600}}>Health Pass Details</Text>
                <View style={{ alignSelf: "center" }}>
                  <Layout.Btn
                    color={UI.colors.primary}
                    text={"Download Report"}
                    onClick={() => {
                      this.exportCSV()
                    }}
                    height={40}
                    width={150}
                  />
                </View>
              </UI.Row>

              <View 
              // style={{flex:1, justifyContent:'center'}}
              >
                <UI.Row breakpoint={840} _this={_this}>
                  <View style={{ alignSelf: "center", flex:1 }}>
                    <Text style={{color:UI.colors.secondary, fontSize: 15, fontWeight:600}}>{healthpass_details?.healthpass?.title}</Text>
                    <Text style={{color:UI.colors.unset, fontWeight:600}}>Valid Until {valid_until}</Text>

                    {UI.box(10)}
                    <View>
                      <UI.Row breakpoint={840} _this={_this} style={{justifyContent:'space-between', alignItems: 'center'}}>
                        <Text style={{color:UI.colors.primary, fontWeight:600}}>Date Used</Text>
                        <Text style={{fontWeight:600}}>{date_used}</Text>
                      </UI.Row>
                      {UI.box(5)}
                      <UI.Row breakpoint={840} _this={_this} style={{justifyContent:'space-between', alignItems: 'center'}}>
                        <Text style={{color:UI.colors.primary, fontWeight:600}}>Validity Period</Text>
                        <Text style={{fontWeight:600}}>Weekday</Text>
                      </UI.Row>
                      {UI.box(5)}
                      <UI.Row breakpoint={840} _this={_this} style={{justifyContent:'space-between', alignItems: 'center'}}>
                        <Text style={{color:UI.colors.primary, fontWeight:600}}>Validity Service</Text>
                        <Text style={{fontWeight:600}}>{healthpass_details?.healthpass?.validityService === "CN" ? "Consult Now" : "Consult Later"}</Text>
                      </UI.Row>
                      {UI.box(5)}
                      <UI.Row breakpoint={840} _this={_this} style={{justifyContent:'space-between', alignItems: 'center'}}>
                        <Text style={{color:UI.colors.primary, fontWeight:600}}>Payment Type</Text>
                        <Text style={{fontWeight:600}}>{healthpass_details?.appointment?.payType}</Text>
                      </UI.Row>
                      {UI.box(5)}
                      <UI.Row breakpoint={840} _this={_this} style={{justifyContent:'space-between', alignItems: 'center'}}>
                        <Text style={{color:UI.colors.primary, fontWeight:600}}>Applicable Payment</Text>
                        <Text style={{fontWeight:600}}>{healthpass_details?.appointment?.payType}</Text>
                      </UI.Row>
                      {UI.box(5)}
                      <UI.Row breakpoint={840} _this={_this} style={{justifyContent:'space-between', alignItems: 'center'}}>
                        <Text style={{color:UI.colors.primary, fontWeight:600}}>Heatlh Pass Use</Text>
                        <Text style={{fontWeight:600}}>1</Text>
                      </UI.Row>
                      {UI.box(5)}
                      <UI.Row breakpoint={840} _this={_this} style={{justifyContent:'space-between', alignItems: 'center'}}>
                        <Text style={{color:UI.colors.primary, fontWeight:600}}>For User Type</Text>
                        <Text style={{fontWeight:600}}>Existing</Text>
                      </UI.Row>
                    </View>

                  </View>
                  <View style={{flex:1}}></View>
                </UI.Row>
              </View>

              <View style={{display:'flex'}}>

              </View>
              {UI.box(10)}
            </View>
         </UI.Row>
        {/* </View> */}
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
});
