import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import StartupLayout from "../_components/StartupLayout";
import "./css.css";

const context = "advocacy";

export default class AdvocacyLogin extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      remember_me: false,
      context: context,
    });
  }

  onCreate = () => {};

  submit = async () => {
    mem.save(
      UI.Constants.JWT_TOKEN,
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxZDkxODJkYjYxNThiZDgwMGNmYTlmMyIsImlhdCI6MTY0MTY2OTc4NCwiZXhwIjoxNjQxNzU2MTg0fQ.GKwocpmSyCjCUz_xm1FXqyfYA578wWCmCxzl8gYPbsE"
    );
    mem.save(UI.Constants.ROLE, "advocacy");

    UI.goTo("/advocacy/dashboard");
  };

  authenticate = async () => {
    UI.api.azure_b2c_login();
  };

  render() {
    return (
      <StartupLayout context={context} image={UI.BG_SIGNIN} _this={this}>
        <ScrollView>
          <View style={styles.content_container}>
            <Text style={styles.header_1}>Welcome to mWell!</Text>
            {UI.box(20)}
            <Text style={styles.header_2}>Please Sign-in to your account.</Text>
            {UI.box(20)}

            <TouchableOpacity
              onClick={() => {
                this.authenticate();
              }}
              style={styles.blue_btn}
            >
              <Text style={styles.blue_text}>SIGN IN</Text>
            </TouchableOpacity>
            {UI.box(25)}
          </View>
        </ScrollView>
      </StartupLayout>
    );
  }
}

const styles = StyleSheet.create({
  sign_up_sub: {
    fontSize: 12,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  sign_up_main: {
    fontSize: 12,
    fontWeight: "bold",
    color: "black",
  },
  sign_up_btn: {
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  blue_text: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
  blue_btn: {
    width: "100%",
    height: 40,
    backgroundColor: UI.colors.secondary,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 360,
    alignSelf: "center"
  },
  forgot_password_text: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  forgot_container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: -68,
    marginBottom: 50,
  },
  header_2: {
    fontSize: 14,
    color: UI.colors.black,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 20,
    color: UI.colors.primary,
    fontWeight: "bold",
  },
  content_container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },
});
