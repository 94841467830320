import React, { useCallback, useState, useEffect } from "react";
import DataTable from "../../ComponentsFC/DataTable";
import UI from "../../shared/Components/UI/js";
import moment from "moment";
import styled from "styled-components";
import { Row, Col, Button, Input, Select, Typography } from "antd";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import ServiceMerchantTransactionsApi from "../../api_services/Merchant/transactionsApi";
import TouchableOpacity, {
  Image,
  mem,
  View,
} from "../../shared/custom-react-native";

const EcommerceTransactionsTable = ({ _this }) => {
  const [tableData, setTableData] = useState([]);
  const [searchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(30);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [sortByProductName, setSortByProductName] = useState("");
  const [filterByPatmentMethod, setFilterByPaymentMethod] = useState("");
  const [sortByPurchaseDate, setSortByPurchaseDate] = useState("");

  const getAllTransactions = useCallback(async () => {
    setIsLoading(true);

    let params = {};

    if (searchQuery) {
      params.keyword = searchQuery;
    }

    if (sortByProductName) {
      params.productName = sortByProductName;
    }

    if (filterByPatmentMethod) {
      params.paymentMethod = filterByPatmentMethod;
    }

    if (sortByPurchaseDate) {
      params.datePurchaseSort = sortByPurchaseDate;
    }

    if (currentPageNum) {
      params.page = currentPageNum;
      params.limit = tableEntries;
    }

    ServiceMerchantTransactionsApi.getAllTransactions(params).then((res) => {
      console.log(res);
      setIsLoading(false);
      if (res.status === 200) {
        let data = res.data;
        setTotalRecords(res.count);
        setTableData(data);
      }
    });
  }, [
    currentPageNum,
    tableEntries,
    searchQuery,
    filterByPatmentMethod,
    sortByPurchaseDate,
    sortByProductName,
  ]);

  useEffect(() => {
    getAllTransactions();
  }, [getAllTransactions]);

  const handleCSVExport = () => {
    const csv_data = tableData.map((item, index) => {
      const d = {
        Row: index + 1,
        "Reference ID": item.referenceNumber ?? "--",
        "Paymaya ID": item.paymentId ?? "--",
        "Merchant Name": item.merchantName ?? "--",
        "Customer Name": item.user?.firstName
          ? item.user?.firstName + " " + item.user?.lastName
          : "N/A",
        "Product Name": item.product?.name.replace(",", " ") ?? "--",
        "Transaction Fee": item.transactionFee ?? "--",
        // "Product Name": item.product?.name ?? " ",
        // "Purchased With": (item.method !== 'HealthLink') ? (item?.payType || '') : item.method,
        "Purchased With": item.method ?? "--",
        Total: item.amount ?? "--",
        "Purchase Date": item.createdAt ? moment(item.createdAt).format("YYYY/MM/DD") : "--",
        "Payment Status": item.status ?? "--",
      };

      return d;
    });

    UI.DownloadCSV(
      "All Transactions " + UI.get_current_date_string(),
      csv_data
    );
  };

  const handlePaginateTable = (pageNum) => {
    setCurrentPageNum(pageNum);
  };

  const tableColumnHeader = [
    {
      title: "Order ID",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
      render: (referenceNumber) => referenceNumber ?? "--",
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      render: (name, item) => {
        if (item.user) {
          return item.user?.firstName
          ? item.user?.firstName + " " + item.user?.lastName
          : "N/A"
        } else if (item.formData?.formId) {
          return item.formData?.firstName
          ? item.formData?.firstName + " " + item.formData?.lastName
          : "N/A"
        } else if (item.formDataLaboratory) {
          return item.formDataLaboratory?.firstName
          ? item.formDataLaboratory?.firstName + " " + item.formDataLaboratory?.lastName
          : "N/A"
        } else {
          return "N/A"
        }
      }
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      render: (name, item) => item.product?.name ?? "--",
    },
    {
      title: "Purchased With",
      dataIndex: "method",
      key: "method",
      render: (method) => method ?? "--",

      // render: (createdAt) => createdAt ? moment(createdAt).format("YYYY/MM/DD") : "--" ,
    },
    {
      title: "Total",
      dataIndex: "amount",
      key: "amount",
      render: (data, item) => parseInt(item.amount) + item.transactionFee ?? 0,
    },
    {
      title: "Purchase Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) =>
        createdAt ? moment(createdAt).format("YYYY/MM/DD") : "--",
    },
    {
      title: "Payment ID",
      dataIndex: "paymentId",
      key: "paymentId",
      render: (paymentId) => paymentId ?? "--",
    },
    {
      title: "Payment Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (orderStatus, item) =>
        item?.status ? UI.get_transaction_status_text(item) : "--",
    },
    {
      title: "Action",
      dataIndex: "createdAt",
      key: "date_added",
      render: (data, item) => {
        return (
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              onClick={() => {
                mem.save("view_transaction", JSON.stringify(item));
                UI.goTo("/admin/view-transaction");
              }}
            >
              <Image
                height={32}
                width={32}
                tintColor={"transparent"}
                source={UI.VIEW_BTN_1}
              />
            </TouchableOpacity>
          </View>
        );
      },
    },
  ];

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPageNum(1);
    setTableEntries(30);
  };

  return (
    <>
      <FilterBox>
        <Title level={5}>Transactions History</Title>
        <Toolbar gutter={14}>
          <ToolItem span={8}>
            <StyledFieldLabel>Product Name</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Sort By"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setSortByProductName(value);
              }}
            >
              <Select.Option value="asc">Ascending</Select.Option>
              <Select.Option value="desc">Descending</Select.Option>
            </StyledSelect>
          </ToolItem>
          <ToolItem span={8}>
            <StyledFieldLabel>Payment Method</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Filter By"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setFilterByPaymentMethod(value);
                // setSortByCreatedAt("")
              }}
            >
              <Select.Option value="Laboratory">Laboratory</Select.Option>
              <Select.Option value="Paymaya">Paymaya</Select.Option>
              <Select.Option value="PaymayaCard">PaymayaCard</Select.Option>
              <Select.Option value="HealthPass">HealthPass</Select.Option>
              <Select.Option value="BayadOTC">BayadOTC</Select.Option>
              <Select.Option value="HealthLink">HealthLink</Select.Option>
              <Select.Option value="MedExpress">MedExpress</Select.Option>
              <Select.Option value="BayadCenter">BayadCenter</Select.Option>
            </StyledSelect>
          </ToolItem>
          <ToolItem span={8}>
            <StyledFieldLabel>Purchase Date</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Sort By"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setSortByPurchaseDate(value);
              }}
            >
              <Select.Option value="asc">Ascending</Select.Option>
              <Select.Option value="desc">Descending</Select.Option>
            </StyledSelect>
          </ToolItem>
        </Toolbar>
      </FilterBox>
      <TableBox>
        <Toolbar style={{ marginBottom: 20 }}>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="30"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="30">30 Entries</Select.Option>
            <Select.Option value="100">100 Entries</Select.Option>
            <Select.Option value={totalRecords}>All Entries</Select.Option>
            {/* <Select.Option value={totalRecords}>All Entries</Select.Option> */}
          </StyledSelect>
          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getAllTransactions}
            style={{ width: 200 }}
          />
          <StyledButton type="primary" onClick={handleCSVExport}>
            Export to CSV
          </StyledButton>
        </Toolbar>
        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          defaultPageSize={30}
          pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default EcommerceTransactionsTable;

// CSS Styled Components ----------------------
const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const ToolItem = styled(Col)`
  position: relative;
`;

const Title = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.4375rem !important; // 15px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
  padding-left: 35px;
  margin-bottom: 25px !important;
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;
