import React from "react";
// import styled from "styled-components";

import DeletedDoctorsList from "./DeletedDoctorsList";

const DeletedDoctors = () => {
  return (
    <>
      <DeletedDoctorsList />
    </>
  );
};

export default DeletedDoctors;
