import Icon from "@ant-design/icons";
import React from "react";

const DoctorTypeRequest = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 26.788 26.788"
  >
    <path
      id="Path_19160"
      data-name="Path 19160"
      d="M15.394,18.575a.837.837,0,1,0,.837.837A.837.837,0,0,0,15.394,18.575Zm0-1.842h0a.669.669,0,0,0,.669-.67V10.706a.67.67,0,1,0-1.339,0v5.358A.669.669,0,0,0,15.394,16.733ZM15.394,2A13.394,13.394,0,1,0,28.788,15.394,13.394,13.394,0,0,0,15.394,2Zm0,25.449A12.055,12.055,0,1,1,27.449,15.394,12.055,12.055,0,0,1,15.394,27.449Z"
      transform="translate(-2 -2)"
      fill="#b90c0c"
    />
  </svg>
);

const DoctorTypeRequestIcon = (props) => {
  return <Icon component={DoctorTypeRequest} {...props} />;
};

export default DoctorTypeRequestIcon;
