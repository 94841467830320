import { Button, Form } from "antd";
import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import UI from "../../shared/Components/UI/js";
import TouchableOpacity, {
  Image,
  ImageBackground,
  ImageFit,
  mem,
  Shadow,
  // Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import { DownloadPDF } from "../_components/CustomPDF";
// import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import {
  DeleteConfirmation,
  Failed,
  // Failed,
  Success,
} from "../_components/Modal";
import "./css.css";
import TextArea from "antd/lib/input/TextArea";

const context = "merchant";

export default class EcommerceViewNormalTransaction extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Transactions",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
      orderStatus: "",
      testResults: [],

      item: {
        _id: "",
        product: {
          _id: "",
          name: "",
          price: "",
          imagePath: "",
        },
        merchant: null,
        user: {
          _id: "",
          name: "",
          firstName: "",
          lastName: "",
          email: "",
          id: "",
        },
        method: "",
        referenceNumber: "",
        paymentId: "",
        currency: "",
        amount: "",
        quantity: "",
        status: "",
        createdAt: "",
        __v: 0,
      },

      is_this_gift: "",
      recipient_name: "",
      recipient_email_address: "",
      recipient_mobile_number: "",
      recipient_date_of_birth: "",

      customer_image: UI.TRANSACTION_USER,

      product_image: UI.PRODUCT_BOX,

      is_rendering: false,
    });
  }

  onCreate = async () => {
    const data = mem.get("view_transaction");
    const admin = JSON.parse(mem.get("admin"));

    if (data) {
      const transaction = JSON.parse(data);
      console.log(admin);
      this.setState(
        {
          item: transaction,
          merchantType: admin.merchantType ?? "",
          orderStatus: transaction.orderStatus,
          testResults: transaction?.laboratoryResultPath ?? []
        },
        this.load_data
      );
    }
  };

  load_data = async () => {
    setTimeout(() => {
      this.reload_table("transaction");
    }, 100);
  };

  download = async (type) => {
    this.show_loading("", "Processing Request...", true);
    this.setState(
      {
        is_rendering: true,
      },
      () => {
        UI.execute_resizes(this);
        setTimeout(() => {
          DownloadPDF(
            "main_transaction_content",
            "Transaction_" + this.state.item.referenceNumber,
            type
          );
          this.hide_loading();
          this.setState(
            {
              is_rendering: false,
            },
            () => {
              setTimeout(() => {
                UI.execute_resizes(this);
              }, 100);
              setTimeout(() => {
                UI.execute_resizes(this);
              }, 200);
              setTimeout(() => {
                UI.execute_resizes(this);
              }, 300);
            }
          );
        }, 300);
      }
    );

    //DownloadPDF("main_transaction_content")
  };

  deleteTransaction = (item) => {
    this.show_loading();
    UI.webRequest({
      method: "delete",
      url: "admin/transactions/" + item,
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully delete the transaction."}
            onDismiss={() => {
              UI.goTo("/merchant/transactions");
              this.hide_modal();
            }}
          />
        );
      },
      onFinish: () => {
        // this.onCreate();
      },
    });
  };

  auth_upload = async (state_name, file, callback) => {
    UI.upload_image({
      url: "admin/upload/lab-test-result",
      file: "",
      params:{
        file:this.state.image_file,
        transactionId: this.state.item._id 
      },
      onFail: (err) => {
        this.show_modal(
          <Failed
            title={"Failed"}
            description={err?.data?.m}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (path, response) => {
        this.setState({ testResults: [...this.state.testResults, response.data.d] });
        this.show_modal(<Success
          title={"Success!"}
          description={`Successfully uploaded test results`}
          onDismiss={() => {
            this.hide_modal()
          }}
        />)
      },
    });
  };

  changeOrderStatus = (orderStatus) => {
    // if (this.state.item.paymentStatus === "Unpaid" && orderStatus === "Completed") {
    //   return this.show_modal(
    //     <Failed
    //       title={"Failed"}
    //       description={"Please change order status to Confirmed before selecting Completed."}
    //       onDismiss={() => {
    //         this.hide_modal();
    //       }}
    //     />
    //   )
    // }
    this.show_loading();
      UI.webRequest({
        method: "put",
        url: "admin/merchant/order-status/" + this.state.item._id,
        params: {
          ...this.state.merchantType === "laboratory" && { merchantType: "laboratory" },
          orderStatus: orderStatus,
          reason:this.state.reason,
        },
        onFail: async (err) => {
          this.show_modal(
            <Failed
              title={"Failed"}
              description={err?.data?.m}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        },
        onSuccess: async (response) => {
          this.show_modal(
            <Success
              title={"Success!"}
              description={`Transaction has been ${orderStatus}.`}
              onDismiss={() => {
                const cloneItem = this.state.item
                const newItem = {...cloneItem, status: response.data?.d?.status ?? ""}
                this.setState({
                  item: newItem,
                  orderStatus: response.data?.d?.orderStatus ?? "",
                })
                mem.update('view_transaction', 'orderStatus', response.data?.d?.orderStatus)
                mem.update('view_transaction', 'status', response.data?.d?.status)
                this.reload_table("transaction");
                this.hide_modal()
              }}
            />
          );
        },
        onFinish: () => {
          this.hide_loading();
        },
      });
  }

  cancelOrderStatus = (orderStatus) => {
    this.show_modal(
      <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0px',
        left: '0px',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
      }}
      >
      <div className="absolute" style={{top:'40%', left:'50%'}}>
        <View
          className="shadow-2xl"
          style={{
            // padding: 30,
            minWidth: 100,
            maxWidth: 300,
            borderRadius: 10,
            backgroundColor: "white",
            ...Shadow._3(),
            // paddingTop: 15,
            paddingBottom: 15,
          }}
        >
          <View style={{ ...styles.md_header, backgroundColor: UI.colors.red }}>
            <TouchableOpacity 
            onClick={() => {
              this.hide_modal()
            }} 
            style={styles.md_close}>
              <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="close"/>
            </TouchableOpacity>

            <View style={styles.md_header_text_container}>
              <Text style={styles.md_header_text}>Cancel Transaction</Text>
            </View>

            {UI.box(60)}
          </View>
          <Text style={{ color: UI.colors.secondary, fontSize: 14, fontWeight: "bold", textAlign:'center', padding: "20px 20px 0px 20px" }}>
            Are you sure you want to cancel the laboratory order?
            <View style={{color:"black", width:'100%'}}>
              {UI.box(20)}
               <Form
                  name="basic"
                  onFinish={(item) => { 
                    this.setState({ reason: item.reason }, () => {
                      this.changeOrderStatus(orderStatus)
                    })
                  }}
                >
                  <Form.Item
                    name="reason"
                  
                    rules={[
                      {
                        required: true,
                        message: 'Reason for cancellation is required.',
                      },
                    ]}
                  >
                    <TextArea 
                      onChange={(e, b) => {
                        console.log(e.target)
                        console.log(e, b)
                        // this.setState({ reason: e.targe.value})
                      }}
                      placeholder="Enter Reason" rows={3} style={{ borderRadius: 8}}/>
                  </Form.Item>

                  {UI.box(10)}

                  <Form.Item >
                    <div style={{display:"flex", justifyContent: "space-around", gap: 10}}>
                      <Button htmlType="submit" style={{ background: UI.colors.secondary, color:'white', borderRadius: 8, height: 40, width: 120}}>
                        Confirm
                      </Button>
                      <Button 
                        onClick={() => {
                          this.hide_modal()
                        }}
                        style={{ background: UI.colors.red, color:'white', borderRadius: 8, height: 40, width: 120}}>
                        Cancel
                      </Button>
                    </div>
                  </Form.Item>
              </Form>
            </View>
          </Text>
        </View>
      </div>
    </View>
    )
  }

  deleteLabResultFile = (item) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this laboratory result file?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.show_loading();
          UI.webRequest({
            method: "put",
            url: "admin/merchant/order-status/" + this.state.item._id,
            params: {
              isDeletedFile:true,
              merchantType: "laboratory"
            },
            onFail: async (err) => {
              this.show_modal(
                <Failed
                  title={"Failed"}
                  description={err?.data?.m}
                  onDismiss={() => {
                    this.hide_modal();
                  }}
                />
              );
            },
            onSuccess: async (response) => {
              this.show_modal(
                <Success
                  title={"Success!"}
                  description={`Successfully deleted laboratory result files`}
                  onDismiss={() => {
                    this.setState({testResults: []})
                    this.hide_modal();
                    this.hide_loading();
                  }}
                />
              );
            },
            onFinish: () => {
              this.hide_loading();
            },
          });
        }}
      />
    );
  }

  get_image = async (other_extension) => {
    return new Promise((resolve, reject) => {
      let input = document.createElement("input");
      input.type = "file";

      input.onchange = (ev) => {
        const file = input.files[0];
        console.log(input.files[0])
        var url = input.value;
        var ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
        if (
          input.files &&
          input.files[0] &&
          ext === other_extension
        ) {
          var reader = new FileReader();

          reader.onload = (e) => {
            resolve({ file, uri: e.target.result });
          };
          reader.readAsDataURL(input.files[0]);
        } else {
          this.failed_modal("Only accept pdf file.")
        }
      };
      input.click();
    });
  }

  upload_picture = async () => {
    const file = await this.get_image("pdf");
    this.setState(
      {
        image: file.uri,
        image_file: file.file,
        did_upload: true,
      },
      () => {
        this.auth_upload();
      }
    );
  };

  render() {
    const _this = this;
    const {
      // height,
      width,
      // isMobile,
      item,
      // customer_image,
      // product_image,
      is_rendering,
    } = _this.state;

    const price = parseInt(item.amount ?? 0);
    const transactionFee = parseInt(item.transactionFee ?? 0);
    const discount = parseInt(item.discount ?? 0);
    const tax = parseInt(item.vat_amount ?? 0);

    const fixed_style = is_rendering
      ? {
          width: 874.54541015625,
          height: 650,
          flex: null,
          // ...Shadow._0(),
        }
      : {};

    const getTotal = () => {
      return (transactionFee + price + tax) - discount;
    };

    const getLabAddress = () => {
      const formDataLabAddress = `${item?.formDataLaboratory?.street ?? " "} ${
        item?.formDataLaboratory?.brgyOrTown ?? " "
      } ${item?.formDataLaboratory?.cityOrMunicipality ?? " "} ${
        item?.formDataLaboratory?.region ?? " "
      } ${item?.formDataLaboratory?.postalCode ?? " "}`;
      return formDataLabAddress
    }

    return (
      <MainLayout context={context} _this={_this}>
        <UI.Row breakpoint={1220} _this={_this} style={{ width: "100%" }}>
          <View
            id={"main_transaction_content"}
            style={{
              ...styles.main_box_content,
              flex: 1,
              padding: 0,
              ...fixed_style,
            }}
          >
            <UI.Row
              _this={_this}
              style={{
                width: "100%",
                padding: 20,
                justifyContent: "space-between",
              }}
              breakpoint={is_rendering ? 10 : 930}
            >
              <ImageBackground
                style={{ height: 90 * 0.45, width: 537 * 0.45 }}
                source={UI.METRO_WELL}
                imageFit={ImageFit.CONTAIN}
              />
              {UI.box(10)}

              <View style={{ alignItems: "flex-end" }}>
                <Text
                  style={{ ...styles.box_header_text, textAlign: "center" }}
                >
                  Transaction {item.referenceNumber}
                </Text>

                <View style={styles.transaction_date_container}>
                  <img
                    style={{
                      height: 25,
                      width: 25,
                      marginTop: is_rendering ? 12 : 0,
                    }}
                    src={UI.DETAILS_AGE}
                    alt="Details Age"
                  />
                  <Text style={styles.transaction_date}>
                    Transaction Date:{" "}
                    {UI.get_date_string_by_date(item.createdAt)}
                  </Text>
                </View>
              </View>
            </UI.Row>

            <View style={styles.border_line}></View>

            <UI.Row
              breakpoint={is_rendering ? 10 : 940}
              style={{ width: "100%", padding: 20 }}
              _this={_this}
            >
              {
                this.state.merchantType === "laboratory" ? 
                  <View>
                    <Text
                      style={{  fontSize: 16,
                        fontWeight: "bold",
                        color: UI.colors.secondary, 
                      }}
                    >
                      Laboratory Test Details
                    </Text>

                    {UI.box(10)}

                    <UserDetail
                      icon={UI.INFO_HOSPITAL}
                      name={"Clinic/Hospital"}
                      value={item.merchant?.name ?? "None"}
                      is_rendering={is_rendering}
                    />
                    <UserDetail
                      icon={UI.AVAILABILITY}
                      name={"Booking Schedule"}
                      value={item.formDataLaboratory?.preferredSchededule ? UI.momentPHTimezone(item.formDataLaboratory?.preferredSchededule).format('MM/DD/YYYY h:mm a') : "None"}
                      is_rendering={is_rendering}
                    />
                    <UserDetail
                      icon={UI.INFO_SOURCE}
                      name={"Booking Type"}
                      value={item.formDataLaboratory?.laboratoryTestHeldType ?? "None"}
                      is_rendering={is_rendering}
                    />
                    <UserDetail
                      icon={UI.INFO_SOURCE}
                      name={"Patient Type"}
                      value={item?.formDataLaboratory?.relationship || "None"}
                      is_rendering={is_rendering}
                    />
                    <UserDetail
                      icon={UI.INFO_SOURCE}
                      name={"Patient Name"}
                      value={(`${item?.formDataLaboratory?.firstName} ${item?.formDataLaboratory?.middleName} ${item?.formDataLaboratory?.lastName}`)}
                      is_rendering={is_rendering}
                    />
                    <UserDetail
                      icon={UI.INFO_SOURCE}
                      name={"Address"}
                      value={getLabAddress()}
                      is_rendering={is_rendering}
                    />
                    <UserDetail
                      icon={UI.INFO_SOURCE}
                      name={"Contact Number"}
                      value={item?.formDataLaboratory?.mobileNumber || "None"}
                      is_rendering={is_rendering}
                    />
                  </View> 
                  
                  :

                  <View
                    id={"sample"}
                    style={{ flexDirection: "row", alignItems: "center" }}
                    >

                    {UI.box(15)}

                    {item.formData ? (
                      <View>
                        <Text style={styles.patient_name}>
                          {item.formData?.firstName + " " + item.formData?.lastName}
                        </Text>
                        <Text style={styles.patient_details}>
                          {item.user?.email ?? ""}
                        </Text>
                        <Text style={styles.patient_details}>
                          {item.user && item.user.phone ? item.user.phone : ""}
                        </Text>
                        <Text style={styles.patient_details}>
                          {item.formData?.dateOfBirth ? UI.getDateByMMDDYYYY(`${item.formData?.dateOfBirth}`) : item.user?.birthDate ? UI.getDateByMMDDYYYY(`${item.user?.birthDate}`) : "N/A"}
                        </Text>
                        <Text style={styles.patient_details}>
                          {item.user?.gender ? "Male" : "Female"}
                        </Text>
                        <Text style={styles.patient_details}>
                          {item.formData?.maritalStatus ?? ""}
                        </Text>
                        <Text style={styles.patient_details}>
                          {`${item.formData?.street ?? ""} ${
                            item.formData?.brgyOrTown ?? ""
                          } ${item.formData?.cityOrMunicipality ?? ""} ${
                            item.formData?.region ?? ""
                          } ${item.formData?.postalCode ?? ""}`}
                        </Text>
                      </View>
                    ) : (
                      <View>
                        <Text style={styles.patient_name}>
                          {item.user?.name ?? ""}
                        </Text>
                        <Text style={styles.patient_details}>
                          {item.user?.email ?? ""}
                        </Text>
                        <Text style={styles.patient_details}>
                          {item.user?.phone ?? ""}
                        </Text>
                        <Text style={styles.patient_details}>
                          {UI.getDateByMMDDYYYY(`${item.user?.birthDate || ""}`)}
                        </Text>
                        <Text style={styles.patient_details}>
                          {item.user?.gender ? "Male" : "Female"}
                        </Text>
                        <Text style={styles.patient_details}>
                          {item.formData?.maritalStatus ?? ""}
                        </Text>
                      </View>
                    )}
                    </View>
              }
              
              <View style={{ flex: 1 }}></View>

              <View>
                {UI.box(width > 940 ? 0 : 20)}
                <UserDetail
                  icon={UI.PAYMENT_METHOD}
                  name={"Payment Reference ID"}
                  value={item.referenceNumber}
                  is_rendering={is_rendering}
                />
                <UserDetail
                  icon={UI.BOOKING_DATE}
                  name={"Date Purchased"}
                  value={moment(item.createdAt).format("MM/DD/YYYY")}
                  // value={UI.get_date_string_by_date(item.createdAt)}
                  is_rendering={is_rendering}
                />
                <UserDetail
                  icon={UI.CONSULT_PRICE}
                  name={"Total"}
                  value={"Php " + UI.pad(item.amount, 3, true)}
                  is_rendering={is_rendering}
                />
                <UserDetail
                  icon={UI.PAYMENT_METHOD}
                  name={"Payment Method"}
                  value={item.method}
                  is_rendering={is_rendering}
                />
                <UserDetail
                  icon={UI.PAYMENT_METHOD}
                  name={"Payment Status"}
                  value={UI.get_transaction_status_text(item) ?? ""}
                  is_rendering={is_rendering}
                />
                {!this.state.is_rendering && (
                  <Layout.SelectPicker
                    _this={_this}
                    title={"Order Status"}
                    isRequired={false}
                    onChange={(e) => {
                      if (!e) {
                        return true;
                      }
                      if (e === "Cancelled") {
                        return this.cancelOrderStatus(e)
                      }
                      this.changeOrderStatus(e)
                    }}
                  >
                    <option value="">
                      Change Order Status
                    </option>
                    <option
                      value="Confirmed"
                      selected={this.state.orderStatus === "Confirmed"}
                    >
                      Confirmed
                    </option>
                    <option
                      value="Completed"
                      selected={this.state.orderStatus === "Completed"}
                    >
                      Completed
                    </option>
                    <option
                      value="Cancelled"
                      selected={this.state.orderStatus === "Cancelled"}
                    >
                      Cancelled{" "}
                    </option>
                  </Layout.SelectPicker>
                )}
              </View>
            </UI.Row>

            <CustomDatatable
              state_name={"transaction"}
              _this={_this}
              columns={[
                // { ID: "id" },
                // { Preview: "preview" },
                { "Product Name": "product_name" },
                { "Product SKU": "product_sku" },
                { Quantity: "quantity" },
                { "Unit Price": "unit_price" },
                { "Total Price": "price" },
              ]}
              data={[
                {
                  id: item._id,
                  // preview: (
                  //   <img
                  //     style={{ height: 35, width: 35 }}
                  //     src={product_image}
                  //   />
                  // ),
                  product_name: item.product?.name,
                  product_sku: item.product?.sku ?? "N/A",
                  quantity: "x" + (item.quantity ?? 1),
                  unit_price: item.product?.price ?? "",
                  price: "Php " + UI.pad(item.amount, 3, true),
                  order_status: this.state.orderStatus
                    ? this.state.orderStatus
                    : item.orderStatus ?? " ",
                },
              ]}
              current_page={1}
              number_of_pages={20}
              on_page={(page, callback) => {}}
              disable_pagination={true}
            />
            <View style={styles.border_line}></View>

            <View style={{ width: "100%", alignItems: "flex-end" }}>
              <View
                style={{
                  padding: "20px 20px 20px 20px",
                  paddingTop: !this.state.is_rendering && 0,
                }}
              >
                {/* <View style={{ flexDirection: "row", padding: 5 }}>
                  <View style={{ alignItems: "flex-end" }}>
                    <Text style={styles.receipt_legend}>Subtotal</Text>
                    <Text style={styles.receipt_legend}>Transaction Fee</Text>
                    <Text style={styles.receipt_legend}>
                      Discount/Voucher Used
                    </Text>
                    <Text style={styles.receipt_legend}>VAT</Text>
                  </View>
                  {UI.box(50)}
                  <View style={{ alignItems: "flex-end", minWidth: 50 }}>
                    <Text style={styles.receipt_legend}>
                      {"Php " + UI.pad(item.amount, 3, true)}
                    </Text>
                    <Text style={styles.receipt_legend}>
                      {"Php " + UI.pad(item.transactionFee ?? 0, 3, true)}
                    </Text>
                    <Text style={styles.receipt_legend}>
                      {"Php " + UI.pad(item.amountPaidVoucher ?? 0, 3, true)}
                    </Text>
                    <Text style={styles.receipt_legend}>
                      {"Php " + UI.pad(item.vatFee ?? 0, 3, true)}
                    </Text>
                  </View>
                </View> */}
              {UI.box(20)}
              <View style={{ flexDirection: "row" }}>
                  <View>
                    <Text style={styles.receipt_legend}>Price: </Text>
                    <Text style={styles.receipt_legend}>Service fee: </Text>
                    <Text style={styles.receipt_legend}>Discount: </Text>
                    <Text style={styles.receipt_legend}>Tax: </Text>
                    {UI.box(10)}
                  </View>
                  {UI.box(20)}
                  <View style={{ minWidth: 50, alignItems: "flex-end" }}>
                    <Text style={{ fontWeight: 'bold'}}>
                      {"Php " + UI.pad(price, price.toString().length, true)}
                    </Text>
                    <Text style={{ fontWeight: 'bold'}}>
                      {"Php " + UI.pad(transactionFee, transactionFee.toString().length, true)}
                    </Text>
                    <Text style={{ fontWeight: 'bold'}}>
                      {"Php " + UI.pad(discount, discount.toString().length, true)}
                    </Text>
                    <Text style={{ fontWeight: 'bold'}}>
                      {"Php " + UI.pad(tax, tax.toString().length, true)}
                    </Text>
                  </View>
                </View>
                <View style={styles.total_border_line}></View>

                <View style={styles.total_container}>
                  <Text style={styles.total_text}>TOTAL</Text>
                  <Text style={styles.total_text_value}>
                    {"Php " + UI.pad(getTotal(), 3, true)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {UI.box(20)}
          <View
            style={{
              ...styles.main_box_content,
              width: 300,
              alignSelf: "flex-start",
            }}
          >
            <Text style={{ ...styles.box_header_text, textAlign: "center" }}>
              Other Options
            </Text>

            {UI.box(10)}
            <Layout.Btn
              color={UI.colors.secondary}
              text={"Download File"}
              onClick={() => {
                this.download("download");
              }}
            />
            {UI.box(10)}
            <Layout.Btn
              color={UI.colors.secondary}
              text={"Print File"}
              onClick={() => {
                this.download("print");
              }}
            />
            {UI.box(10)}
            <Layout.Btn
              color={"#CC0415"}
              text={"Delete Transaction"}
              onClick={() => {
                this.show_modal(
                  <View style={{ backgroundColor: "white", borderRadius: 10 }}>
                    <View
                      style={{ ...styles.md_content_container, width: "100%" }}
                    >
                      <Text style={{ fontSize: 20, fontWeight: 600 }}>
                        Delete Transaction
                      </Text>
                      {UI.box(10)}
                      <Text style={{ fontSize: 20, fontWeight: 600 }}>
                        Are you sure you want to delete the transaction?
                      </Text>
                      {UI.box(20)}
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <TouchableOpacity
                          onClick={() => {
                            this.deleteTransaction(item._id);
                          }}
                          style={{
                            ...styles.md_button,
                            backgroundColor: UI.colors.secondary,
                          }}
                        >
                          <Text style={styles.md_btn_text}>Yes</Text>
                        </TouchableOpacity>
                        {UI.box(10)}
                        <TouchableOpacity
                          onClick={() => {
                            this.hide_modal();
                          }}
                          style={{
                            ...styles.md_button,
                            border: "1px solid " + UI.colors.secondary,
                          }}
                        >
                          <Text
                            style={{
                              ...styles.md_btn_text,
                              color: UI.colors.secondary,
                            }}
                          >
                            No
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                );
              }}
            />

            {
              ((this.state.orderStatus === "Confirmed" || this.state.orderStatus === "Completed") && this.state.merchantType === "laboratory") &&
              <>
                {UI.box(50)}
                <Layout.Btn
                  color={UI.colors.secondary}
                  text={"Upload Results"}
                  onClick={() => {
                    this.upload_picture();
                  }}
                />
                {UI.box(10)}
                <Text style={{ ...styles.box_header_text, textAlign: "center" }}>
                  Lab Results
                </Text>
                {UI.box(10)}
                {
                  this.state.testResults?.map(item => (
                    <div className="flex gap-3 items-center">
                      <LinkButton
                        type="link"
                        style={{ color: UI.colors.primary, width: "30%" }}
                        onClick={async () => {
                          const image = await UI.download_image(item.path, "merchants");
                          window.open(image, "_blank");
                        }}
                      >
                        <Text style={{ textDecoration: "underline"}}>{item.filename.slice(0, 25) + "..." ?? ""}</Text>
                      </LinkButton>
                      <TouchableOpacity
                        onClick={() => {
                          this.deleteLabResultFile()
                        }}
                      >
                        <Image
                          height={28}
                          width={28}
                          tintColor={"transparent"}
                          source={UI.DELETE_BTN}
                        />
                      </TouchableOpacity>
                    </div>
                  ))
                }
                
              </>
            }

          </View>
        </UI.Row>
      </MainLayout>
    );
  }
}

const UserDetail = (
  props = { icon: "", name: "", value: "", is_rendering: false }
) => {
  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}
    >
      <View style={{ width: 180, ...styles.fl_ai }}>
        <View style={styles.detail_icon}>
          <img
            style={{
              height: 16,
              width: 16,
              marginTop: props.is_rendering ? 8 : 0,
            }}
            src={props.icon}
            alt="User Detail"
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};

const LinkButton = styled(Button)`
  flex: 1;
  padding: 0;
  height: 100%;
`;

const styles = StyleSheet.create({
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
    whiteSpace:'nowrap',
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    marginBottom: 33,
    height: 60,
    width: "100%",
    backgroundColor: "#00CBFF",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
  md_content_container: {
    padding: 25,
    alignItems: "start",
  },
  md_btn_text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
  md_button: {
    padding: 30,
    // backgroundColor: "#004F99",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  total_border_line: {
    height: 2,
    width: "100%",
    backgroundColor: "#888888",
  },
  total_text_value: {
    fontSize: 17,
    fontWeight: "bold",
    color: UI.colors.secondary,
  },
  total_text: {
    fontSize: 17,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  total_container: {
    width: "100%",
    padding: 2,
    flexDirection: "row",
  },
  receipt_legend: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.secondary,
  },
  border_line: {
    height: 1,
    width: "100%",
    backgroundColor: "#EBEBEB",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  transaction_date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
  transaction_date: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 10,
  },
});