import React, { useCallback, useState, useEffect } from "react";
import DataTable from "../../ComponentsFC/DataTable";
import UI from "../../shared/Components/UI/js";
import styled from "styled-components";
import { Row, Col, Input, Select, Typography, Button,
  // DatePicker 
} from "antd";
import DropdownIcon from "../../icons/Dropdown";
import { Failed } from "../_components/Modal";
import SearchIcon from "../../icons/Search";
import TouchableOpacity, { Image, mem, Text, View } from "../../shared/custom-react-native";
import ServiceMarketingsApi from "../../api_services/marketings";
// import moment from "moment";

// import moment from "moment";

const PromoWinnersTable = ({ _this }) => {
  const [tableData, setTableData] = useState([]);
  const [searchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(10);
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const getPatientReferrals = useCallback(async () => {
    try {
      setIsLoading(true);

      let params = {};

      if (searchQuery) {
        params.keyword = searchQuery;
      }

      if (currentPageNum) {
        params.page = currentPageNum;
        params.limit = tableEntries;
      }

      ServiceMarketingsApi.getAllPromoWinners(params).then((res) => {
        setIsLoading(false);
        if (res.status === 200 || res.status === 204) {
          setTotalRecords(res.count)
          setTableData(res.data);
        }
      });
    } catch (err) {
      // Handle Errors Here
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404 || statusCode === 500) {
          _this.show_modal(
            <Failed
              title={"Failed"}
              description={errorMsg}
              onDismiss={() => {
                _this.hide_modal();
              }}
            />
          );
        }
      }
    }
  }, [currentPageNum, tableEntries, searchQuery, _this]);

  useEffect(() => {
    getPatientReferrals();
  }, [getPatientReferrals]);

  const handlePaginateTable = (pageNum) => {
    setCurrentPageNum(pageNum);
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleCSVExport = () => {
    const csv_data = tableData.map((item, index) => {
      const form = item?.formData;
      const user = item?.user;
      const itemFullName = (item?.fullname === "-- --") ? null : item?.fullname;
      const userFullName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
      const userEmail = `${user?.email ?? '--'}`;
      const userPhone = `${user?.phoneNumber ?? (user?.phone ?? '')}`
      let address = null;
      if(item?.prizeType === 'non-digital') {
        address = form ?
          `"${form?.street}, ${form?.brgyOrTown}, ${form?.cityOrMunicipality}, ${form?.region}, ${form?.postalCode}"`
          : 
          "no address provided";
      }

      const d = {
        "User ID": user?.id ?? "--",
        "User Name": itemFullName ?? (userFullName ?? "--"),
        "Email Address": item?.formData?.emailAddress ?? userEmail,
        "Mobile Number": item?.formData?.mobileNumber ? ("63 " + item?.formData?.mobileNumber) : 
          (userPhone ? `63 ${userPhone}` : "--"),
        "Date Registered": item?.createdAt ? UI.momentPHTimezone(item?.createdAt).format('MM/DD/YYYY hh:mm A') : "--",
        "Prize": item?.prizeName ?? "--",
        "address": address ?? "N/A" 
      };

      return d;
    });
    
    UI.DownloadCSV(`In App Promo Winners ${UI.get_current_date_string()}`, csv_data);
  };

  const tableColumnHeader = [
    {
      title: "User ID",
      dataIndex: "_id",
      key: "_id",
      render: (_id, item) => {
        return (item?.user?.id ?? "--");
      },
    },
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
      render: (fullname, item) => {
        let name = fullname;
        if(name === '-- --') {
          const form = item?.formData;
          name = form ? `${form?.firstName} ${form?.middleName} ${form?.lastName}` : "--";
        }
        if(name === "--") {
          const user = item?.user;
          name = user ? `${user?.firstName ?? ''} ${user?.lastName ?? ''}` : "--";
        }
        return name;
      }
      ,
    },
    {
      title: "Mobile",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (mobileNumber, item) => {
        let finalPhoneNum = item?.formData?.mobileNumber ? ("63 " + item?.formData?.mobileNumber) : "--";
        if(finalPhoneNum === '--') {
          const user = item?.user;
          finalPhoneNum = user ? `${user?.phoneNumber ?? (user?.phone ?? '--')}` : "--";
          if(finalPhoneNum !== '--') {
            finalPhoneNum = `63 ${finalPhoneNum}`;
          }
        }
        return finalPhoneNum;
      }
    },
    {
      title: "Email Address",
      dataIndex: "emailAddress",
      key: "emailAddress",
      render: (emailAddress, item) => {
        let finalEmail = item?.formData?.emailAddress ?? "--";
        if(finalEmail === '--') {
          finalEmail = item?.user?.email ?? '--';
        }
        return finalEmail;
      },
    },
    {
      title: "Date/Time of Registration",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => {
        return createdAt ? UI.momentPHTimezone(createdAt).format('MM/DD/YYYY hh:mm A') :"--"
      },
    },
    {
      title: "Prize",
      dataIndex: "prizeName",
      key: "prizeName",
      render: (prizeName) => prizeName ?? "--",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (key, item) => {
          return <View style={{ flexDirection: "row", gap: 10 }}>
            <TouchableOpacity
              onClick={() => {
                  UI.goTo('/marketing/promo-winners-view')
                  mem.save('promo-winners-view', JSON.stringify(item))
              }}
            >
              <Image
                height={28}
                width={28}
                tintColor={"transparent"}
                source={UI.VIEW_BTN_1}
              />
            </TouchableOpacity>
          </View>
      },
    },
  ];

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPageNum(1);
    setTableEntries(30);
  };


  return (
    <>
      <TableBox>
        <Text style={{
          fontWeight: "bold",
          color:UI.colors.secondary,
          paddingLeft: 35,
          paddingBottom: 35,
          fontSize: 17
        }}>
          Promo Winners
        </Text>
        <Toolbar>
          <ToolItem span={8} style={{display:'flex', gap: 10, alignItems:'center'}}>
            <FieldLabel>Only Show</FieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              defaultValue="10"
              style={{
                width: 160,
              }}
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeNumOfEntries}
            >
              <Select.Option value="10">10 Entries</Select.Option>
              <Select.Option value="30">30 Entries</Select.Option>
              <Select.Option value="100">100 Entries</Select.Option>
            </StyledSelect>
            {/* <StyledFieldLabel style={{ color:'black' }}>Sort By:</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="by number of referrals"
              style={{
                width: 380,
              }}
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                handleSorting(value)
              }}
            >
                <Select.Option value="createdAt:asc">By date - Newest</Select.Option>
                <Select.Option value="createdAt:desc">By date - Newest</Select.Option>
                <Select.Option value="referralCount:largest">By referrals count - Largest to smallest</Select.Option>
                <Select.Option value="referralCount:smallest">By referrals count - Smallest to largest</Select.Option>
            </StyledSelect> */}
          </ToolItem>
          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getPatientReferrals}
            style={{ width: 200 }}
          />
          <StyledButton type="primary" onClick={handleCSVExport}>
            Export to CSV
          </StyledButton>
        </Toolbar>
        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          defaultPageSize={30}
          pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default PromoWinnersTable;

// CSS Styled Components ----------------------

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const ToolItem = styled(Col)`
  position: relative;
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;
