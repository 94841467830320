import React from "react";
import DataTable from "../../../../../ComponentsFC/DataTable";
import { Row } from "antd";
import styled from "styled-components";

export const Drug = ({ medication = [] }) => {
  const tableColumnHeader = [
    {
      title: "Drug",
      dataIndex: "substance",
      key: "substance",
      render: (data) => data || "n/a",
    },
  ];
  return (
    <TableBox>
      <DataTable
        tableColumnHeader={tableColumnHeader}
        dataSource={medication}
        hideTotalSummaryOnSinglePage={true}
        hidePaginationOnSinglePage={true}
      />
    </TableBox>
  );
};

export const Contact = ({ environment = [] }) => {
  const tableColumnHeader = [
    {
      title: "Contact",
      dataIndex: "substance",
      key: "substance",
      render: (data) => data || "n/a",
    },
  ];
  return (
    <TableBox>
      <DataTable
        tableColumnHeader={tableColumnHeader}
        dataSource={environment}
        hideTotalSummaryOnSinglePage={true}
        hidePaginationOnSinglePage={true}
      />
    </TableBox>
  );
};

export const Food = ({ food = [] }) => {
  const tableColumnHeader = [
    {
      title: "Food",
      dataIndex: "substance",
      key: "substance",
      render: (data) => data || "n/a",
    },
  ];
  return (
    <TableBox>
      <DataTable
        tableColumnHeader={tableColumnHeader}
        dataSource={food}
        hideTotalSummaryOnSinglePage={true}
        hidePaginationOnSinglePage={true}
      />
    </TableBox>
  );
};

export const Other = ({ biologic = [] }) => {
  const tableColumnHeader = [
    {
      title: "Other",
      dataIndex: "substance",
      key: "substance",
      render: (data) => data || "n/a",
    },
  ];
  return (
    <TableBox>
      <DataTable
        tableColumnHeader={tableColumnHeader}
        dataSource={biologic}
        hideTotalSummaryOnSinglePage={true}
        hidePaginationOnSinglePage={true}
      />
    </TableBox>
  );
};

const Allergies = ({ medical_history }) => {
  const allergyGroupBy = medical_history.reduce((result, value, key) => {
    const group = "allergy_category_name";

    (result[value[group]] = result[value[group]] || []).push(value);
    return result;
  }, []);

  return (
    <div className="grid grid-rows-2 grid-flow-col gap-x-12 gap-y-4">
      <Drug medication={allergyGroupBy.MEDICATION} />
      <Contact environment={allergyGroupBy.ENVIRONMENT} />
      <Food food={allergyGroupBy.FOOD} />
      <Other biologic={allergyGroupBy.BIOLOGIC} />
    </div>
  );
};

export default Allergies;

const TableBox = styled(Row)`
  position: relative;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  min-width: 100%;
  .ant-table-cell {
    font-size: 20px;
  }
`;
