import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { DeleteConfirmation } from "../_components/Modal";
import "./css.css";
const context = "admin";

export default class AdminCMSSpecialties extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "App Content",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],

      selected_tab: "quicklinks", //can be quicklinks, banner_slide, mwell_banner
      context: context,
    });
  }

  onCreate = () => {
    setTimeout(() => {
      this.execute_resizes(this);
      this.execute_resizes(this);
    }, 300);

    this.get_data(true);
  };

  get_data = async (reload) => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/specializations",
      params: {},
      onFail: (err) => {},
      onSuccess: (response) => {
        const data = UI.extract_data(response);

        if (reload) {
          this.setState({
            all_data: data.data,
          }, () => {
            this.reload_table("specializations");
          });
        }

        this.reload_table("specializations");
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  delete = async (id) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this item?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();
          this.show_loading();
          UI.webRequest({
            method: "delete",
            url: "admin/specializations/" + id,
            params: {},
            onFail: async (err) => {},
            onSuccess: async (response) => {},
            onFinish: () => {
              this.hide_modal();
              this.get_data(true);
            },
          });
        }}
      />
    );
  };

  render() {
    const _this = this;
    const { 
      // height, 
      width,
      //  isMobile,
      //   selected_tab 
      } = _this.state;

    // const content_main_container = UI.measure("content_main_container").width;

    return (
      <MainLayout context={context} additional={"specialties"} _this={_this}>
        <View
          style={{
            ...styles.main_box_content,
            padding: 0 /* , marginTop: 20  */,
          }}
        >
          <View style={{ padding: 20 }}>
            <Text style={styles.box_header_text}>Doctor Specialties CMS</Text>
          </View>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={950}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"specializations"} _this={_this} />
              </View>
            </View>

            <InputForm
              onChange={(e) => {
                UI.search_table(
                  "specializations",
                  e,
                  this.state.all_data,
                  "data"
                );
              }}
              is_keyword={true}
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
            />

            <View
              style={{
                alignSelf: width > 950 ? "flex-start" : "center",
                marginTop: 8,
                marginLeft: 10,
              }}
            >
              <Layout.Btn
                color={UI.colors.primary}
                text={"Add Specialty"}
                onClick={() => {
                  UI.goTo("/admin/add-specialty");
                }}
                height={40}
                width={140}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            state_name={"specializations"}
            _this={_this}
            columns={[
              // { "#": "id" },
              // { Preview: "preview" },
              { "Specialty Name": "specialty_name" },
              { "Date Added": "date_added" },
              // { Status: "status" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              const date_added = UI.timestampToDate(
                new Date(item.createdAt).getTime()
              );

              return {
                id: "001",
                preview: (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image
                      height={35}
                      width={35}
                      tintColor={"transparent"}
                      source={UI.TRANSACTION_USER}
                    />
                  </View>
                ),
                specialty_name: item.label,
                date_added:
                  date_added.month +
                  "/" +
                  date_added.day +
                  "/" +
                  date_added.year,
                status: <Text style={{ color: "#00C700" }}>Active</Text>,
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        mem.save("view_specialization", JSON.stringify(item));
                        UI.goTo("/admin/edit-specialty");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={() => {
                        this.delete(item._id);
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.DELETE_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  menu_main_text: {
    fontSize: 18,
    fontWeight: "bold",
    color: UI.colors.secondary,
    padding: 20,
  },
  active_design: {
    height: "100%",
    width: 4,
    backgroundColor: UI.colors.primary,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  menu_btn: {
    fontSize: 16,
    fontWeight: "bold",
    padding: 10,
    paddingLeft: 20,
  },
  timeline_circle: {
    height: 22,
    width: 22,
    borderRadius: 100,
    backgroundColor: UI.colors.primary,
    borderWidth: 4,
    borderColor: "#E3E3E3",
    marginTop: -10,
    marginLeft: -12,
  },
  timeline_item: {
    height: 100,
    width: "100%",
    borderLeftStyle: "solid",
    borderLeftWidth: 2,
    borderLeftColor: "#E3E3E3",
    marginLeft: 10,
  },
  unused_text: {
    fontSize: 12,
    fontWeight: "bold",
    color: "white",
  },
  unused_box: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 10,
    backgroundColor: "#D3D3D3",
    justifyContent: "center",
    alignItems: "center",
  },
  detail_value: {
    color: "black",
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  detail_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  flex_1: {
    flex: 1,
  },
  detail_left: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  detail_item: {
    flexDirection: "row",
    alignItems: "center",
    width: 370,
    marginBottom: 5,
  },
  line: {
    width: "100%",
    height: 3,
    backgroundColor: "#EFEFEF",
  },
  ai_fl: {
    flexDirection: "row",
    alignItems: "center",
  },
  sub_name: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#898989",
  },
  main_name: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
