import UI from "../../shared/Components/UI/js";
import ServiceApi from "../service";

class DoctorsLeaveRequestApi {
  baseURL = "admin";

  async getDoctorsLeaveRequest(params) {
    const response = await ServiceApi.get(this.baseURL + `/doctors/leave-requests/status`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response?.status,
        data: response?.data?.d,
        message: response?.data?.m
      }
    }
  }


  async changeLeaveStatus(id,status,params) {
    const response = await ServiceApi.post(this.baseURL + `/doctors/leave-requests/update/${id}?type=${status}`, params);
    console.log("testing", response)
    if (response?.status === 201) {
      // const data = UI.extract_data(response);
    //   data.status = 200;
      // console.log("if")
      return response;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  } 

  async getDtrCSV(params) {
    const response = await ServiceApi.getBlobFile(this.baseURL + `/doctors/leave-requests`, params);

    if (response?.status === 200) {
      return response;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async viewDoctorFile(params) {
    const response = await ServiceApi.getBlobFile(this.baseURL + `/image/retrieve`, params);

    if (response?.status === 200) {
      return response;
    } else {
      return {
        status: response?.status,
        data: response?.data.d,
        message: response?.data.m
      }
    }
  }

  async getLeaveRequestListCSV(params) {
    const response = await ServiceApi.getBlobFile(this.baseURL + `/doctors/leave-requests/status`, params);

    if (response?.status === 200) {
      return response;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }
}

export default new DoctorsLeaveRequestApi();
