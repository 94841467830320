import Icon from "@ant-design/icons";
import React from "react";

const DraggableDotsSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.859"
    height="17.757"
    viewBox="0 0 24.859 17.757"
  >
    <path
      id="grip-vertical-solid"
      d="M2.22,49.757A2.219,2.219,0,0,0,0,51.976V54.64a2.219,2.219,0,0,0,2.22,2.22H4.883A2.219,2.219,0,0,0,7.1,54.64V51.976a2.219,2.219,0,0,0-2.22-2.22Zm10.654,0a2.219,2.219,0,0,0-2.22,2.22V54.64a2.219,2.219,0,0,0,2.22,2.22h2.663a2.219,2.219,0,0,0,2.22-2.22V51.976a2.219,2.219,0,0,0-2.22-2.22ZM2.22,47.981H4.883a2.219,2.219,0,0,0,2.22-2.22V43.1a2.219,2.219,0,0,0-2.22-2.22H2.22A2.219,2.219,0,0,0,0,43.1v2.663A2.219,2.219,0,0,0,2.22,47.981Zm10.654-7.1a2.219,2.219,0,0,0-2.22,2.22v2.663a2.219,2.219,0,0,0,2.22,2.22h2.663a2.219,2.219,0,0,0,2.22-2.22V43.1a2.219,2.219,0,0,0-2.22-2.22ZM2.22,39.1H4.883a2.219,2.219,0,0,0,2.22-2.22V34.22A2.219,2.219,0,0,0,4.883,32H2.22A2.219,2.219,0,0,0,0,34.22v2.663A2.219,2.219,0,0,0,2.22,39.1ZM12.874,32a2.219,2.219,0,0,0-2.22,2.22v2.663a2.219,2.219,0,0,0,2.22,2.22h2.663a2.219,2.219,0,0,0,2.22-2.22V34.22A2.219,2.219,0,0,0,15.537,32Z"
      transform="translate(56.859) rotate(90)"
      fill="#535353"
    />
  </svg>
);

const DraggableDotsIcon = (props) => {
  return <Icon component={DraggableDotsSvg} {...props} />;
};

export default DraggableDotsIcon;
