import Icon from "@ant-design/icons";
import React from "react";

const UploadFolderSvg = () => (
  <svg
    id="Folder"
    xmlns="http://www.w3.org/2000/svg"
    width="53.294"
    height="59.352"
    viewBox="0 0 53.294 59.352"
  >
    <path
      id="Path_3046"
      data-name="Path 3046"
      d="M5.593,0H45.56V29.725H5.593Z"
      transform="translate(2.706)"
      fill="#06bbea"
    />
    <path
      id="Path_3047"
      data-name="Path 3047"
      d="M2.417,3.7H42.383V33.43H2.417Z"
      transform="translate(1.169 1.792)"
      fill="#00cbff"
    />
    <path
      id="Path_3049"
      data-name="Path 3049"
      d="M27.26,10.662l-1.6,2.582a5.442,5.442,0,0,1-4.629,2.581H5.442A5.442,5.442,0,0,0,0,21.267V50a5.442,5.442,0,0,0,5.442,5.442h42.41A5.442,5.442,0,0,0,53.294,50V13.523a5.442,5.442,0,0,0-5.442-5.442H31.889A5.441,5.441,0,0,0,27.26,10.662Z"
      transform="translate(0 3.909)"
      fill="#109cf1"
    />
    <path
      id="Path_3050"
      data-name="Path 3050"
      d="M21.8,13.244l1.6-2.582a5.441,5.441,0,0,1,4.63-2.581h-3a5.442,5.442,0,0,0-4.63,2.581l-1.6,2.582a5.443,5.443,0,0,1-4.63,2.581h3A5.443,5.443,0,0,0,21.8,13.244Z"
      transform="translate(6.858 3.909)"
      fill="#0078c0"
    />
    <path
      id="Path_3051"
      data-name="Path 3051"
      d="M3,47.475V18.742A5.442,5.442,0,0,1,8.44,13.3h-3A5.442,5.442,0,0,0,0,18.742V47.475a5.442,5.442,0,0,0,5.442,5.442h3A5.442,5.442,0,0,1,3,47.475Z"
      transform="translate(0 6.434)"
      fill="#0078c0"
    />
  </svg>
);

const UploadFolderIcon = (props) => {
  return <Icon component={UploadFolderSvg} {...props} />;
};

export default UploadFolderIcon;
