import React from "react";
import styled from "styled-components";
import { Input } from "antd";

const TextArea = React.memo((props) => {
  return <StyledTextArea {...props} />;
});

export default TextArea;

const StyledTextArea = styled(Input.TextArea)`
  border-radius: 12px;
  border: 2px solid #cacaca;
  padding: 31px 23px 31px 28px;

  font-size: 1.25rem; // 20px
  font-family: Poppins;
  line-height: 1.25rem; // 23px
  font-weight: 500; //medium
  letter-spacing: 0px;
`;
