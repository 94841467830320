import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { CustomConfirmation } from "../_components/Modal";
import "./css.css";

const context = "finance";

// **Commented out, currently not used
// const SampleData = [
//   {
//     id: "transaction_1",
//     createdAt: "2022-01-24T11:07:21.691+00:00",
//     status: "Refund",
//     doctor_name: "Jacqueline Jordan",
//     patient_name: "Blanche Hodges",
//     reference_number: "Metrobank",
//     amount: 1200,
//   },
//   {
//     id: "transaction_4",
//     createdAt: "2022-01-08T18:31:25.516+00:00",
//     status: "Pending",
//     doctor_name: "Glenda Matthews",
//     patient_name: "Jared Bowers",
//     reference_number: "BDO",
//     amount: 450,
//   },
//   {
//     id: "transaction_5",
//     createdAt: "2022-01-12T12:18:38.387+00:00",
//     status: "Refund",
//     doctor_name: "Margaret Reyes",
//     patient_name: "Johnnie Murray",
//     reference_number: "BDO",
//     amount: 500,
//   },
// ];

export default class FinanceRefund extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Doctors Transactions",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,

      sort: "-createdAt",
      status: "",
      name: "",
    });
  }

  onCreate = async () => {
    setTimeout(() => {
      this.get_data();
    }, 100);
  };

  get_data = async () => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/doctors/transactions",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        let new_data = data.map((item) => {
          item.timestamp = new Date(
            item?.doctorAppointments?.createdAt
          ).getTime();
          item.createdAt = item?.doctorAppointments?.createdAt;
          item.order_date = UI.get_date_string_by_timestamp(item.timestamp);
          item.consult_date = item?.doctorAppointments?.appointment?.start;
          item.consult_date_string = UI.get_date_string_by_date(
            item.consult_date
          );

          item.patient_name =
            (item?.doctorAppointments?.user?.firstName ?? "") +
            " " +
            (item?.doctorAppointments?.user?.lastName ?? "");

          if (!item.patient_name) {
            item.patient_name = item?.doctorAppointments?.user.name;
          }

          item.doctor_name = UI.DataHelper.carespan.get_name(
            item?.doctor?.name
          );
          item.reference_number = item?.doctorAppointments?.referenceNumber;
          item.paymentId = item?.doctorAppointments?.paymentId;
          item.amount = item?.doctorAppointments?.amount;
          item.status =
            item.doctorAppointments.method === "Voucher"
              ? "UseVoucher"
              : item?.doctorAppointments?.status?.status;
          item.appointment_status =
            UI.replaceAll(
              item?.doctorAppointments?.appointment?.status ?? "",
              "_",
              " "
            ) ?? "Completed";

          if (item.status === "Complete") {
            item.status = "Completed";
          }

          return item;
        });
        //new_data = new_data.filter((item) => item.reference_number == "162781218789");

        console.log(new_data);
        this.setState(
          {
            data: new_data,
            all_data: new_data,
          },
          () => {
            this.reload_table("transactions");
            this.hide_loading();
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  sort_by = (format, key) => {
    //format can be asc or desc
    const { all_data } = this.state;

    all_data.sort(UI.dynamicSort(format === "asc" ? key : "-" + key));
    UI.set_input_value("search", "");

    this.setState(
      {
        data: all_data,
      },
      () => {
        this.reload_table("transactions");
        this.hide_loading();
      }
    );
  };

  custom_sort = (key, value) => {
    const { all_data } = this.state;
    let items = all_data.filter((item) => item[key] + "" === value);
    UI.set_input_value("search", "");
    if (value) {
      this.reload_table("transactions", items);
    } else {
      this.reload_table("transactions", all_data);
    }
  };

  refund_confirm = () => {
    this.show_modal(
      <CustomConfirmation
        title={"Confirmation"}
        text={"Are you sure you want to refund this transaction?"}
        backgroundColor={"#3498db"}
        onOk={{
          text: "CONTINUE",
          method: () => {
            this.hide_modal();
          },
        }}
        onCancel={{
          text: "CANCEL",
          method: () => {
            this.hide_modal();
          },
        }}
      />
    );
  };

  export_to_csv = async () => {
    const { all_data } = this.state;

    const csv_data = all_data.map((item, index) => {
      const d = {
        Row: index + 1,
        "Reference ID": item.reference_number ?? " ",
        "Paymaya ID": item.paymentId ?? " ",
        "Order Date": item.order_date ?? " ",
        "Doctor Name": item.doctor_name ?? " ",
        "Patient Name": item.patient_name ?? " ",
        "Date of consult": item.consult_date_string ?? " ",
        "Appointment Status": item.appointment_status ?? " ",
        Status: item.status ?? " ",
        Amount: item.amount ?? " ",
      };

      return d;
    });

    UI.DownloadCSV(
      "Doctor Transactions " + UI.get_current_date_string(),
      csv_data
    );
  };

  render() {
    const _this = this;
    const { 
      // height, 
      width, 
      // isMobile 
    } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <Text style={styles.box_header_text}>Patients Refund</Text>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={false}
              onChange={(e) => {
                this.custom_sort("status", e);
              }}
            >
              <option value="">Select Status</option>
              <option value="Completed">Completed</option>
              <option value="UseVoucher">Voucher Used</option>
              <option value="Pending">Pending</option>
              <option value="Refund">For Refund</option>
              <option value="Failed">Failed</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Date Added"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "timestamp");
              }}
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Patient Name"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "patient_name");
              }}
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={880}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"transactions"} _this={_this} />
              </View>
            </View>
            <InputForm
              onChange={(e) => {
                UI.search_table("transactions", e, this.state.all_data, "data");
              }}
              is_keyword={true}
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
            />{" "}
            <View
              style={{
                alignSelf: width > 880 ? "flex-start" : "center",
                marginTop: 8,
                marginLeft: 10,
              }}
            >
              <Layout.Btn
                color={UI.colors.primary}
                text={"Export to CSV"}
                onClick={() => {
                  this.export_to_csv();
                }}
                height={40}
                width={150}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            disable_pagination={false}
            state_name={"transactions"}
            _this={_this}
            columns={[
              // { ID: "id" },
              { "Reference ID": "reference_number" },
              { "Paymaya ID": "paymentId" },
              { "Order Date": "date_added" },
              { "Patient Name": "patient_name" },
              // { "Doctor Name": "doctor_name" },
              { "Date of consult": "consult_date" },
              { Amount: "amount" },

              { "Appointment Status": "appointment_status" },
              { Status: "status" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              const edit_refund = async () => {
                mem.save("edit_transaction", JSON.stringify(item));
                UI.goTo("/finance/refund-doctor-transaction");
              };

              const date_added = UI.timestampToDate(
                new Date(item.createdAt).getTime()
              );
              const consult_date = UI.timestampToDate(
                new Date(item.consult_date).getTime()
              );

              let status = "";
              /*

                  status == "Approved" ? (
                    <Text style={{ color: "#27ae60" }}>Approved</Text>
                  ) : status == "Disapproved" ? (
                    <Text style={{ color: "#e74c3c" }}>Disapproved</Text>
                  ) : (
                    <Text style={{ color: "#FFCE00" }}>Pending</Text>
                  )


                */
              if (item.status === "Completed") {
                status = <Text style={{ color: "#27ae60" }}>Completed</Text>;
              } else if (item.status === "Complete") {
                status = <Text style={{ color: "#27ae60" }}>Completed</Text>;
              } else if (item.status === "UseVoucher") {
                status = <Text style={{ color: "#3498db" }}>Voucher Used</Text>;
              } else if (item.status === "Pending") {
                status = <Text style={{ color: "#FFCE00" }}>Pending</Text>;
              } else if (item.status === "Refund") {
                status = <Text style={{ color: "#3498db" }}>For Refund</Text>;
              } else if (item.status === "Failed") {
                status = <Text style={{ color: "#e74c3c" }}>Failed</Text>;
              }

              return {
                doctor_name: (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginLeft: 10,
                    }}
                  >
                    <Text
                      style={{
                        maxWidth: 80,
                        marginRight: 10,
                        textAlign: "center",
                      }}
                    >
                      {item.doctor_name}
                    </Text>
                  </View>
                ),
                patient_name: item.patient_name,
                paymentId: item.paymentId,
                reference_number: item.reference_number,
                amount: item.amount,
                appointment_status: item.appointment_status,
                date_added:
                  date_added.month +
                  "/" +
                  date_added.day +
                  "/" +
                  date_added.year,
                consult_date:
                  consult_date.month +
                  "/" +
                  consult_date.day +
                  "/" +
                  consult_date.year,
                status: status,
                action: (
                  <View
                    style={{
                      flexDirection: "row",
                      display: item.status === "Refund" ? "flex" : "none",
                    }}
                  >
                    <TouchableOpacity onClick={edit_refund}>
                      <View
                        style={{
                          height: 25,
                          width: 50,
                          borderRadius: 4,
                          backgroundColor: "#3498db",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 12,
                          }}
                        >
                          Refund
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
