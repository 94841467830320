import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
// import CustomDatatable from "../_components/CustomDatatable";
// import EntryPicker, { autoPopulate } from "../_components/EntryPicker";
// import InputForm from "../_components/InputForm";
// import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";

const context = "advocacy";
const Constants = new APIConstants();

export default class AdvocacyTransactionsView extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Transactions",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
    });
  }

  render() {
    const _this = this;
    const {
      width,
    } = _this.state;
    const data = mem.get("view_advocacy");
    const advocacy = JSON.parse(data);
    const start_date = moment(advocacy.startDate).format('MM/DD/YYYY');
    const valid_until = moment(advocacy.endDate).format('MM/DD/YYYY');
    const image = `${Constants.STORAGE_BASE_URL}?path=${advocacy.imagePath}` ?? UI.SAMPLE_PRODUCT 

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row style={{ width: "100%", justifyContent:'space-between' }} _this={_this}>
            <Text style={styles.box_header_text}>View Advocacy</Text>
            <Text style={{...styles.box_header_text, textAlign:'right', color:UI.colors.unset}}>View and manage advocacy transaction details</Text>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                // borderRightWidth: width >= 1250 ? 2 : 0,
                // borderBottomWidth: width < 1250 ? 2 : 0,
                // paddingRight: width < 1250 ? 0 : 20,
                // marginRight: width < 1250 ? 0 : 20,
                // paddingBottom: 10,
                // marginBottom: width < 1250 ? 10 : 0,
              }}
            >
              <View style={styles.fl_ai}>
                <img
                  width={250}
                  height={300}
                  style={{ borderRadius: 5 }}
                  src={advocacy.imagePath ? image : UI.SAMPLE_PRODUCT}
                  alt=""
                />

                {UI.box(width < 980 ? 5 : 10)}

              </View>
            </View>

            <View style={{ flex: 1, justifyContent:'center' }}>

              <View style={{ flexDirection: width > 800 ? "row" : 'column', alignItems: "center", justifyContent:'space-between' }}>

                <View style={{ flexDirection: "row", alignItems: "center"}}>
                  <Text style={{...styles.details_name, fontSize:'20px'}}>{advocacy.title}</Text>
                  {UI.box(20)}
                </View>

                {UI.box(10)}

              </View>

              {UI.box(20)}

              <UI.Row _this={_this}>
                <UI.Row breakpoint={970} _this={_this} style={{ flex: 1, justifyContent:'space-between', alignItems:'center' }}>
                  <View style={{textAlign:'center'}}>
                    <Text style={{...styles.details_name, display:'flex'}}>
                      <Image
                        height={25}
                        width={25}
                        tintColor={UI.colors.primary}
                        source={UI.CALENDAR_3}
                      />
                    {UI.box(5)}
                      {start_date}
                    </Text>
                    <Text style={{color:UI.colors.unset, fontWeight:600}}>Start Date</Text>
                  </View>

                  {UI.box(20)}

                  <View style={{textAlign:'center'}}>
                    <Text style={{...styles.details_name, display:'flex'}}>
                      <Image
                        height={25}
                        width={25}
                        tintColor={UI.colors.primary}
                        source={UI.CALENDAR_3}
                      />
                    {UI.box(5)}
                      {valid_until}
                    </Text>
                    <Text style={{color:UI.colors.unset, fontWeight:600}}>End Date</Text>
                  </View>

                  {UI.box(20)}

                  <View style={{textAlign:'center'}}>
                    <Text style={{...styles.details_name, display:'flex'}}>
                      <Image
                        height={25}
                        width={25}
                        tintColor={UI.colors.primary}
                        source={UI.SETTINGS}
                      />
                    {UI.box(5)}
                    {advocacy.promotionCode ?? '0001212021012'}
                      
                    </Text>
                    <Text style={{color:UI.colors.unset, fontWeight:600}}>Promotion Code/Healthpass</Text>
                    
                  </View>

                  {UI.box(20)}

                  <View style={{textAlign:'center'}}>
                    <Text style={{...styles.details_name, display:'flex', color:advocacy.status === "Active" ? UI.colors.active : UI.colors.inactive}}>
                      <Image
                        height={25}
                        width={25}
                        tintColor={UI.colors.primary}
                        source={UI.FILE}
                      />
                    {UI.box(5)}
                      {advocacy.status}
                    </Text>
                    <Text style={{color:UI.colors.unset, fontWeight:600}}>Activity Status</Text>
                    
                  </View>

                </UI.Row>
              </UI.Row>
              {UI.box(10)}

            </View>
          </UI.Row>

          {UI.box(20)}
          
          <View style={{border:'2px dashed #C9C9C9', padding:'30px', borderRadius:'12px'}}>
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent:'space-between'}}>
              <Text style={{color:UI.colors.primary, fontWeight:600, fontSize:'17px'}}>Activity Details</Text>
              <Text style={{color:UI.colors.unset, fontWeight:600}}>View activity summary here</Text>
            </View>
            {UI.box(20)}
              <UI.Row breakpoint={970} _this={_this} style={{ flex: 1, justifyContent:'space-between', alignItems:'center' }}>
                <img
                  style={{ height: 50, width: 50, borderRadius: 5 }}
                  src={UI.SAMPLE_PRODUCT}
                  alt=""
                />

                <View>
                  <Text style={{fontWeight:'bold', fontSize:'17px'}}>
                    {advocacy?.patient?.name[0].given[0] + " " + advocacy?.patient?.name[0].family}
                  </Text>
                  <Text style={{color:UI.colors.unset, fontWeight:600}}>Joined: 08/15/2022</Text>
                </View>

                {UI.box(20)}

                <View style={{textAlign:'center'}}>
                  <Text style={{...styles.details_name, display:'flex'}}>
                    <Image
                      height={25}
                      width={25}
                      tintColor={UI.colors.primary}
                      source={UI.CALENDAR_3}
                    />
                  {UI.box(5)}
                    {valid_until}
                  </Text>
                  <Text style={{color:UI.colors.unset, fontWeight:600}}>Activity Date</Text>
                </View>

                {UI.box(20)}

                <View style={{textAlign:'center'}}>
                  <Text style={{...styles.details_name, display:'flex'}}>
                    <Image
                      height={25}
                      width={25}
                      tintColor={UI.colors.primary}
                      source={UI.SETTINGS}
                    />
                  {UI.box(5)}
                  {advocacy.appointmentType ? 'Consult Now' : 'Consult Later' ?? ''}
                    
                  </Text>
                  <Text style={{color:UI.colors.unset, fontWeight:600}}>Activity Type</Text>
                </View>

                {UI.box(20)}

                <View style={{textAlign:'center'}}>
                  <Text style={{...styles.details_name, display:'flex'}}>
                    <Image
                      height={25}
                      width={25}
                      tintColor={UI.colors.primary}
                      source={UI.FILE}
                    />
                  {UI.box(5)}
                    {advocacy?.practitioner?.name[0].given[0] + " " + advocacy?.practitioner?.name[0].family}
                  </Text>
                  <Text style={{color:UI.colors.unset, fontWeight:600}}>Doctor Name</Text>
                </View>

            </UI.Row>
          </View>
        </View>
     
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  product_stat_item: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  product_stats_text: {
    color: UI.colors.primary,
    fontWeight: "bold",
    fontSize: 32,
    marginLeft: 15,
    flex: 1,
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize:'17px',
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
