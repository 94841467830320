import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { DeleteConfirmation, 
  // Failed 
} from "../_components/Modal";
import "./css.css";

const context = "voucher";

export default class VoucherHealthpassHistoryList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Health Pass History",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      healthpass:[],
      context: context,
    });
  }

  onCreate = () => {
    this.get_data();
    this.load_healthpass();
  };

  load_healthpass = async () => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/healthpass",
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        console.log(data.data)
        this.setState(
          {
            healthpass: data.data,
          },
          () => {
            this.reload_table("healthpass_history_list");
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  get_data = async (params) => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/healthpass/history",
      params: params,
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            data: data.data,
            all_data: data.data,
          },
          () => {
            this.reload_table("healthpass_history_list");
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  exportAllCSV = () => {
    console.log(this.state.data)
    const csv_data = this.state.data.map((item, index) => {
      const date_used = UI.get_date_string_by_date(item.appointment?.createdAt);

      const d = {
        Row: item._id + 1,
        "Patient Name": "Patient Juana Delacruz" ?? " ",
        "Health Pass Title": item?.healthpass?.title ?? " ",
        "Doctor ID": item?.appointment?.practitioner._id ?? " ",
        "Appointment ID": item?.appointment?._id ?? " ",
        "Date Used": date_used ?? " ",
      };

      return d;
    });
    UI.DownloadCSV(
      "Healthpass History " + UI.get_current_date_hr_mm_string(),
      csv_data
    );
  }

  exportCSV = (item) => {
    const csv_data = [""].map((a) => {
      const date_used = UI.get_date_string_by_date(item.appointment?.createdAt);

      const d = {
        Row: item._id + 1,
        "Patient Name": "Patient Juana Delacruz" ?? " ",
        "Health Pass Title": item?.healthpass?.title ?? " ",
        "Doctor ID": item?.appointment?.practitioner._id ?? " ",
        "Appointment ID": item?.appointment?._id ?? " ",
        "Date Used": date_used ?? " ",
      };
      return d
    })

    UI.DownloadCSV(
      "Healthpass Single History " + UI.get_current_date_hr_mm_string(),
      csv_data
    );
  }


  delete = async (id) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this item?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();
          this.show_loading();
          UI.webRequest({
            method: "delete",
            url: "admin/healthpass/" + id,
            params: {},
            onFail: async (err) => {},
            onSuccess: async (response) => {},
            onFinish: () => {
              this.hide_modal();
              this.get_data();
            },
          });
        }}
      />
    );
  };

  custom_sort = (key, value) => {
    this.get_data({
      healthpassName :value,
    });
  
  };

  render() {
    const _this = this;
    const { width } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <Text style={{color:UI.colors.secondary, fontSize: 18, fontWeight:600}}>Health Pass History</Text>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Healthpass Name"}
              isRequired={false}
              onChange={(e) => {
                this.custom_sort("healthpass_name", e);
              }}
            >
              <option value="">Filter By Healthpass Name</option>
              {this.state.healthpass?.map((item) => {
                return item ? (
                  <option value={item.title}>{item.title}</option>
                ) : (
                  <View></View>
                );
              })}
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style={{flex: 1}}></View>
            {UI.box(10)}
            <View style={{flex: 1}}></View>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={950}
            _this={_this}
          >

            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              {UI.box(10)}
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"healthpass_history_list"} _this={_this} />
              </View>
            </View>

            <InputForm
              _this={_this}
              title={""}
              is_keyword={true}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              onChange={(e) => {
                UI.search_table("healthpass_history_list", e, this.state.all_data, "data");
              }}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
            />

            <View style={{ alignSelf: "center", marginTop: 8, marginLeft: 10 }}>
              <Layout.Btn
                color={UI.colors.primary}
                text={"Download Report"}
                onClick={() => {
                  this.exportAllCSV()
                }}
                height={40}
                width={150}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            state_name={"healthpass_history_list"}
            _this={_this}
            columns={[
              { "Patient Name": "patient_name" },
              { "Health Pass Title": "healthpass_title" },
              { "Doctor ID": "doctor_id" },
              { "Appointment ID": "appointment_id" },
              { "Date Used": "date_used" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              const date_used = UI.get_date_string_by_date(item.appointment?.createdAt);

              return {
                patient_name: item.appointment?.patient?.name[0].given[0] + item.appointment?.patient?.name[0].family,
                healthpass_title: item.healthpass.title,
                doctor_id: item?.appointment?.practitioner?._id,
                appointment_id: item?.appointment?._id,
                date_used: date_used,
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        mem.save("view_healthpass_history", JSON.stringify(item));
                        UI.goTo("/voucher/healthpass-history-view");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.VIEW_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    {
                      // item.numVouchersToGenerate > 1 && 
                      <TouchableOpacity
                      onClick={() => {
                        this.exportCSV(item);
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EXPORT_BTN}
                      />
                    </TouchableOpacity>
                    }
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
});
