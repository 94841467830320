import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
// import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";

const context = "merchant";
export default class EcommerceViewTransactionDetails extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Transactions",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      item: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        birthDate: '',
        id: '',
        customer_id: '',
        customerImagePath: '',
        product: '',
        method: '', // payment method
        currency: '',
        amount: '',
        quantity: '',
        status: '',
        createdAt: '',
        orderStatus: '',
      },
      context: context,
      laboratoryOrderStatus: "",
      orderStatus: ""
    });
  }

  onCreate = async () => {
    this.loadData();
  };

  loadData = async () => {
    const transactionData = mem.get('view_transaction_details');
    if (transactionData) {
      const transactionDetails = JSON.parse(transactionData);
      this.setState({
        item: {...transactionDetails},
        image: transactionDetails?.user?.avatarLink?.includes('https') ? transactionDetails?.user?.avatarLink : UI.getUserAvatarLink(transactionDetails?.user?.avatarLink)
      },
      () => {
        // TO-DO: load customer image and product image here?
        console.log("transactionDetails state:",this.state);
      })
    }
  }

  getPurchaseDate = (param) => {
    const rawDate = UI.timestampToDate(new Date(param).getTime());
    const finalDate = rawDate.month + "/" + rawDate.day + "/" + rawDate.year;
    return finalDate;
  }

  render() {
    const _this = this;
    const { 
      // height,
      // width,
      // isMobile,
      item,
    } = _this.state;
    console.log(item)
    return (
      <MainLayout context={context} _this={_this}>
        <UI.Row breakpoint={1144} _this={_this} style={{ width: "100%" }}>
          <View style={{ ...styles.main_box_content, flex: 1 }}>
            <Text style={styles.box_header_text}>Transaction Details</Text>
            {UI.box(20)}

            <UserBox
              name={(item?.user?.firstName ?? "") + "" + (item?.user?.lastName ?? "")}
              next={item?.user?.email}
              sub={item?.user?.phone}
              gender={item?.gender ?? ""}
              civilStatus={item?.civilStatus ?? ""}
              image={this.state.image} // TO-DO: customer image goes here
            />

            {UI.box(20)}
            <View style={styles.line}></View>
            {UI.box(20)}
            <UserBox
              name={item?.product?.name ?? ""}
              next={
                <Text
                  style={{
                    fontSize: 14,
                    color: UI.colors.primary,
                    fontWeight: "bold",
                  }}
                >
                  {item?.currency + " " + item?.amount}
                </Text>
              }
              sub={
                <Text
                  style={{
                    fontSize: 14,
                    color: "#454545",
                    fontWeight: "bold",
                  }}
                >
                  Status: {UI.get_transaction_status_text(item)}
                </Text>
              }
              image={UI.SAMPLE_PRODUCT} // TO-DO: product image goes here
            />
            {UI.box(20)}

            <DetailItem
              name={"Purchase Price"}
              value={item?.currency + " " + item?.amount} // TO-DO: get purchase price
              image={UI.CONSULT_PRICE}
            />
            <DetailItem
              name={"Payment Method"}
              value={item?.method}
              image={UI.PAYMENT_METHOD}
            />
            <DetailItem
              name={"Purchase Date"}
              value={this.getPurchaseDate(item?.createdAt)}
              image={UI.BOOKING_DATE}
            />
          </View>
          {UI.box(20)}
          {/* <View
            style={{
              ...styles.main_box_content,
              flex: 1.4,
              alignSelf: width > 1145 ? "flex-start" : "stretch",
            }}
          >
            <UI.Row _this={_this}>
              <Text style={styles.box_header_text}>Subscription Timeline</Text>
            </UI.Row>
            {UI.box(20)}
            <TimelineItem>
              <View style={{ flexDirection: "row", paddingRight: 10 }}>
                <View style={{ flex: 1 }}>
                  <Text style={{ ...styles.detail_name, flex: 1 }}>
                    Product has been added to cart
                  </Text>
                  <Text
                    style={{ ...styles.detail_name, flex: 1, color: "#535353" }}
                  >
                    Product Name Here
                  </Text>
                  <Text
                    style={{ ...styles.detail_name, flex: 1, color: "#B4B4B4" }}
                  >
                    mm/dd/yyyy
                  </Text>
                </View>

                <Text style={{ ...styles.detail_name, color: "#BCBCBC" }}>
                  1 Day ago
                </Text>
              </View>
            </TimelineItem>
            <TimelineItem>
              <View style={{ flexDirection: "row", paddingRight: 10 }}>
                <View style={{ flex: 1 }}>
                  <Text style={{ ...styles.detail_name, flex: 1 }}>
                    Product paid through Bayad App
                  </Text>
                  <Text
                    style={{ ...styles.detail_name, flex: 1, color: "#535353" }}
                  >
                    Product Name Here
                  </Text>
                  <Text
                    style={{ ...styles.detail_name, flex: 1, color: "#B4B4B4" }}
                  >
                    mm/dd/yyyy
                  </Text>
                </View>

                <Text style={{ ...styles.detail_name, color: "#BCBCBC" }}>
                  1 Day ago
                </Text>
              </View>
            </TimelineItem>
            <TimelineItem>
              <View style={{ flexDirection: "row", paddingRight: 10 }}>
                <View style={{ flex: 1 }}>
                  <Text
                    style={{ ...styles.detail_name, flex: 1, color: "#BCBCBC" }}
                  >
                    No Other Update
                  </Text>
                  <Text
                    style={{ ...styles.detail_name, flex: 1, color: "#BCBCBC" }}
                  >
                    Subscription Name Here
                  </Text>
                  <Text
                    style={{ ...styles.detail_name, flex: 1, color: "#BCBCBC" }}
                  >
                    mm/dd/yyyy
                  </Text>
                </View>

                <Text style={{ ...styles.detail_name, color: "#BCBCBC" }}>
                  1 Day ago
                </Text>
              </View>
            </TimelineItem>
          </View> */}
        </UI.Row>

        {/* {UI.box(20)}
        {
          this.state.orderStatus === "toUploadResult" ? 

          <UI.Row breakpoint={1144} _this={_this} style={{ width: "100%" }}>
            <View style={{ ...styles.main_box_content, flex: 1, gap: 5 }}>
              <Text style={styles.box_header_text}>Laboratory Order Actions</Text>
              <Text style={{...styles.box_header_text, fontSize: 13}}>Upload Laboratory Results</Text>
              <View style={{gap: 10}}>
                <div className="flex gap-20">
                  <div className="flex gap-3 w-32 items-center">
                    <img src={UI.INFO_SPECIALTY} height={15} width={15} className="" />
                    <Text style={{...styles.box_header_text, fontSize: 14}}>CBC + Platelet</Text>
                  </div>
                  <div className="flex items-center gap-3">
                    <button className="w-32 flex items-center justify-around text-white rounded-md p-2" style={{ background: UI.colors.primary}}>
                      <img src={UI.DOWNLOAD} height={15} width={15}/>
                      Upload File
                    </button>
                    <Text style={{...styles.box_header_text, fontSize: 14, color: UI.colors.unset}}>No file Uploaded</Text>
                  </div>
                </div>
                <div className="flex gap-20">
                  <div className="flex gap-3 w-32 items-center">
                    <img src={UI.INFO_SPECIALTY} height={15} width={15} className="" />
                    <Text style={{...styles.box_header_text, fontSize: 14}}>Rapid Antigen, Nasal</Text>
                  </div>
                  <div className="flex items-center gap-3">
                    <button className="w-32 flex items-center justify-around text-white rounded-md p-2" style={{ background: UI.colors.primary}}>
                      <img src={UI.DOWNLOAD} height={15} width={15}/>
                      Upload File
                    </button>
                    <Text style={{...styles.box_header_text, fontSize: 14, color: UI.colors.unset}}>No file Uploaded</Text>
                  </div>
                </div>
                <div className="flex gap-20">
                  <div className="flex gap-3 w-32 items-center">
                    <img src={UI.INFO_SPECIALTY} height={15} width={15} className="" />
                    <Text style={{...styles.box_header_text, fontSize: 14}}>Fasting Blood Sugar (FBS)</Text>
                  </div>
                  <div className="flex items-center gap-3">
                    <button className="w-32 flex items-center justify-around text-white rounded-md p-2" style={{ background: UI.colors.primary}}>
                      <img src={UI.DOWNLOAD} height={15} width={15}/>
                      Upload File
                    </button>
                    <Text style={{...styles.box_header_text, fontSize: 14, color: UI.colors.unset}}>No file Uploaded</Text>
                  </div>
                </div>
              </View>
            </View>
          </UI.Row>
          
          :
        
          <UI.Row breakpoint={1144} _this={_this} style={{ width: "100%" }}>
            <View style={{ ...styles.main_box_content, flex: 1 }}>
              <Text style={styles.box_header_text}>Transaction Details</Text>
              <UI.Row breakpoint={970} _this={_this}>
                <Layout.SelectPicker
                  _this={_this}
                  title={"Laboratory Order Status"}
                  state_name={"laboratoryOrderStatus"}
                  onChange={(e) => {
                    this.setState({ laboratoryOrderStatus: e})
                  }}
                >
                  <option value="">Select Item</option>
                  <option value="">For Lab Confirmation</option>
                  <option value="">Scheduled</option>
                  <option value="">Cancelled</option>
                  <option value="">Rescheduled</option>
                  <option value="toUploadResult">To Upload Result</option>
                </Layout.SelectPicker>
                {UI.box(10)}
                <View style={{ flexDirection: "row", alignSelf: "end" }}>
                  <Layout.Btn
                    color={UI.colors.secondary}
                    onClick={() => {
                      this.setState({ orderStatus: this.state.laboratoryOrderStatus })
                      // this.submit();
                    }}
                    height={49}
                    text={"Apply"}
                  />
                  </View>
                  {UI.box(10)}
                  <View style={{flex: 1}}></View>
                  {UI.box(10)}
                  <View style={{flex: 1}}></View>
                </UI.Row>
              </View>
            </UI.Row>
        } */}
        
      </MainLayout>
    );
  }
}

const DetailItem = (props = { name: "", value: "", image: "" }) => {
  return (
    <View style={styles.detail_item}>
      <View style={styles.detail_left}>
        <img src={props.image} alt="Item" />
        <Text style={styles.detail_name}>{props.name}</Text>
      </View>
      <View style={styles.flex_1}>
        <Text style={styles.detail_value}>{props.value}</Text>
      </View>
    </View>
  );
};

const UserBox = (props = { name: "", next: "", sub: "", image: "", gender: "", civilStatus: "" }) => {
  return (
    <View style={styles.ai_fl}>
      <Image
        height={90}
        width={90}
        tintColor={"transparent"}
        source={props.image}
      />
      {UI.box(15)}

      <View>
        <Text style={styles.main_name}>{props.name}</Text>
        <Text style={styles.sub_name}>{props.next}</Text>
        <Text style={styles.sub_name}>{props.sub}</Text>
        <Text style={styles.sub_name}>{props.gender}</Text>
        <Text style={styles.sub_name}>{props.civilStatus}</Text>
      </View>
    </View>
  );
};

// **Comment out, currently unused
// const TimelineItem = (props = {}) => {
//   return (
//     <View style={styles.timeline_item}>
//       <View style={styles.timeline_circle}></View>
//       <View style={{ marginLeft: 20, marginTop: -22 }}>{props.children}</View>
//     </View>
//   );
// };

const styles = StyleSheet.create({
  timeline_circle: {
    height: 22,
    width: 22,
    borderRadius: 100,
    backgroundColor: UI.colors.primary,
    borderWidth: 4,
    borderColor: "#E3E3E3",
    marginTop: -10,
    marginLeft: -12,
  },
  timeline_item: {
    height: 100,
    width: "100%",
    borderLeftStyle: "solid",
    borderLeftWidth: 2,
    borderLeftColor: "#E3E3E3",
    marginLeft: 10,
  },
  detail_value: {
    color: "black",
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  detail_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  flex_1: {
    flex: 1,
  },
  detail_left: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  detail_item: {
    flexDirection: "row",
    alignItems: "center",
    width: 300,
    marginBottom: 5,
  },
  line: {
    width: "100%",
    height: 3,
    backgroundColor: "#EFEFEF",
  },
  ai_fl: {
    flexDirection: "row",
    alignItems: "center",
  },
  sub_name: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#898989",
  },
  main_name: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
