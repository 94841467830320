import React from 'react'

const PageNotFound = () => {
  return (
    <div className='table w-full h-screen text-center'>
    	<div className="table-cell  align-middle">
          <h1 className='inline-block' style={{fontSize:'50px', paddingRight:'12px'}}>Error 404</h1>
          <h1>Look like you're lost</h1>
          <p>the page you are looking for is not avaible!</p>
    	</div>
    </div>
  )
}

export default PageNotFound