import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { DeleteConfirmation } from "../_components/Modal";

const Constants = new APIConstants();
const context = "advocacy";
export default class AdvocacyList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Advocacy List",
      is_drawer_open: false,
      current_page: 1,
      number_of_pages: 1,
      data: [],
      all_data: [],
      context: context,
    });
  }

  onCreate = async () => {
    this.get_data({
      page: 1,
      limit:
        this.state["advocacy_entries"] ??
        30,
    });
  };

  get_data = async (params, reload) => {
    let limit = parseInt(params.limit);
    params.limit = limit >= 30 ? 30 : limit
    this.show_loading();
    if (!params) {
      params = {};
    }

    this.setState({
      current_page: params?.page ?? 1,
    });

    UI.webRequest({
      method: "get",
      url: "admin/advocacies",
      params: params,
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        const results = UI.get_results(response);

        if (!results?.total) {
          results.total = results.docCount;
        }
        this.setState(
          {
            data: data.data,
            all_data: data.data,
          },
          () => {
            UI.get_entries(
              data.data,
              params.limit ?? 30,
              "advocacy",
              params.page,
              {
                total: results.total,
                pages: results.pages,
              }
            );
            this.hide_loading();
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  sort_by = (format, key, page) => {
    //format can be asc or desc
    if(key === "valid_until") {
      this.get_data({
        validUntil:format,
        page: page ? page : 1,
        limit:
          this.state["advocacy_entries"] ??
          30,
      });
    } else if (key === "created_at") {
      this.get_data({
        startDate:format,
        page: page ? page : 1,
        limit:
          this.state["advocacy_entries"] ??
          30,
      });
    }
  };

  custom_sort = (key, value, page) => {
    if(value === "default") {
      this.get_data({
        page: 1,
        limit:
          this.state["advocacy_entries"] ??
          30,
      });
    } else {
      this.get_data({
        status :value,
        page: page ? page : 1,
        limit:
          this.state["advocacy_entries"] ??
          30,
      });
    }
  };

  delete = async (id) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this advocacy?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();
          this.show_loading();
          UI.webRequest({
            method: "delete",
            url: "admin/advocacies/delete-advocacy/" + id,
            params: {},
            onFail: async (err) => {},
            onSuccess: async (response) => {},
            onFinish: () => {
              this.onCreate();
            },
          });
        }}
      />
    );
  };

  render() {
    const _this = this;
    const {  width } = _this.state;
    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <Text style={styles.box_header_text}>Advocacy List</Text>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={false}
              onChange={(e) => {
                this.custom_sort("status", e);
              }}
            >
              <option value="default">Select Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Valid Until"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "valid_until");
              }}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Layout.SelectPicker>
            
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Date Created"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "created_at");
              }}
            >
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Layout.SelectPicker>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={950}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker
                  isAppointmentPage={true}
                  stateless={true}
                  callback={this.onCreate}
                  table_name={"advocacy"}
                  _this={_this}
                />
              </View>
            </View>

            <InputForm
              _this={_this}
              title={""}
              is_keyword={true}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              onChange={(e) => {
                UI.search_table("advocacy", e, this.state.all_data, "data");
              }}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
            />

            <View
              style={{
                alignSelf: width > 950 ? "flex-start" : "center",
                marginTop: 8,
                marginLeft: 10,
              }}
            >
              <Layout.Btn
                color={UI.colors.secondary}
                text={"Add Advocacy"}
                onClick={() => {
                  UI.goTo("/advocacy/add-advocacy");
                }}
                height={40}
                width={150}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            state_name={"advocacy"}
            _this={_this}
            columns={[
              // { "ID": "id" },
              { "Preview": "preview" },
              { "Advocacy Name": "title" },
              { "Start Date": "start_date" },
              { "Patients": "patients" },
              { "Doctors": "doctors" },
              { "Valid Until": "valid_until" },
              { "Status": "status" },
              { "Action": "action" },
            ]}
            data={this.state.data.map((item) => {
              const start_date = moment(item.createdAt).format('MM/DD/YYYY');
              const valid_until = moment(item.endDate).format('MM/DD/YYYY');
              const image = `${Constants.STORAGE_BASE_URL}?path=${item.imagePath}` ?? UI.SAMPLE_PRODUCT
              return {
                // id: item._id,
                preview: (
                  <img src={item.imagePath ? image : UI.SAMPLE_PRODUCT} alt="" width={135}/>
                ),
                title: <Text>{item.title}</Text>,
                start_date:  start_date,
                patients: item.patient.length > 0 ? item.patient.length : 0,
                doctors: item.doctor.length > 0 ? item.doctor.length : 0,
                valid_until: valid_until,
                status: item.status === "Active" ? <Text style={{ color: UI.colors.active }}>Active</Text>
                 : <Text style={{ color: UI.colors.inactive }}>Inactive</Text>,
                action: (
                  <View style={{ flexDirection: "row" }}>
                     <TouchableOpacity
                      onClick={() => {
                        mem.save("view_advocacy", JSON.stringify(item));
                        mem.save("advocacy_id", item._id);
                        UI.goTo("/advocacy/view");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.VIEW_BTN_1}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={async () => {
                        mem.save("edit_advocacy", JSON.stringify(item));
                        mem.save("advocacy_id", item._id);
                        UI.goTo("/advocacy/edit");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN_1}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={async () => {
                        this.delete(item._id);
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.DELETE_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            number_of_pages={this.number_of_pages}
            current_page={this.current_page}
            on_page={(page) => {
              this.get_data({
                page: page,
                limit: parseInt(this.state.entries)
              });
            }}
          />

          <View 
            style={{
              ...styles.no_info_container,
              display: this.state.all_data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});
