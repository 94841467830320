import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";

import MainLayout from "../_components/MainLayout";
import Statistics from "./Statistics";
import ApprovalTable from "./ApprovalTable";
import "./css.css";

const context = "voucher";

export default class VoucherUserGroupDashboard extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Membership Requests",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,
      usergroups: [],
      sort_filter_site_stats: "year",
      filter_data: [],
    });
  }

  onCreate = () => {
    this.get_data();
    this.load_usergroups();
  };

  load_usergroups = async () => {
    this.show_loading();

    UI.webRequest({
      method: "post",
      url: "admin/usergroup/all",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        this.setState({ usergroups: data }, () => {
          this.reload_table("user_group_approval");
        });
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  get_data = async () => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/usergroup/user-registration/all",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        this.setState(
          {
            data: data,
            all_data: data,
            filter_data: data,
          },
          () => {
            this.getSiteStats();
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  getSiteStats(date = "year") {
    let stats;

    switch (date) {
      case "month":
        stats = this.state.filter_data.filter(
          (item) => UI.getDateByMMYYYY(item.createdAt) === UI.getDateByMMYYYY()
        );
        break;
      case "day":
        stats = this.state.filter_data.filter(
          (item) =>
            UI.getDateByMMDDYYYY(item.createdAt) === UI.getDateByMMDDYYYY()
        );
        break;
      default:
        stats = this.state.filter_data.filter(
          (item) => UI.getDateByYYYY(item.createdAt) === UI.getDateByYYYY()
        );
        break;
    }

    this.setState(
      {
        data: stats,
        all_data: stats,
      },
      () => {
        this.reload_table("user_group_approval");
      }
    );
  }

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <Statistics _this={_this} />

        <ApprovalTable _this={_this} type="dashboard" />
      </MainLayout>
    );
  }
}
