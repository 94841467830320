import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import { 
  // StyleSheet, 
  Text, View } from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import "./css.css";

export default class FourthForm extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const _this = this.props._this;
    const { 
      // height, 
      width,
      // isMobile
     } = _this.state;
    const row_width = width > 1000 ? width - 400 : "100%";
    return (
      <UI.Column
        style={{ marginBottom: 10 }}
        _this={_this}
        breakpoint={360}
        breakpoint_2={820}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: UI.colors.primary,
            marginTop: 30,
            marginBottom: 10,
          }}
        >
          FINANCIAL INFORMATION{" "}
          <Text style={{ color: "red" }}>*</Text>
        </Text>

        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <Layout.SelectPicker
            _this={_this}
            title={""}
            // isRequired={true}
            onChange={(e) => {
              _this.setState({ financialType: e });
            }}
            state_name={"financialType"}
          >
            <option value="">Select Type of Financial</option>
            <option value="bank">Bank</option>
            <option value="paymaya">Maya</option>
          </Layout.SelectPicker>
          {UI.box(10)}
          <View style={{ flex: 1 }}></View>
          {UI.box(10)}
          <View style={{ flex: 1 }}></View>
        </UI.Row>

        {UI.box(20)}

        {_this.state.financialType === "bank" && (
          <>
            <UI.Row
              style={{ width: row_width, justifyContent: "space-between" }}
              _this={_this}
              breakpoint={1000}
            >
              <InputForm
                _this={_this}
                title={"Bank Name"}
                placeholder={"Bank Name"}
                state_name={"bankName"}
                style={{ flex: 1 }}
                isRequired={true}
                maxLength={UI.Constants.limits.name}
              />
              {UI.box(10)}
              <InputForm
                _this={_this}
                title={"Bank Branch"}
                placeholder={"Bank Branch"}
                state_name={"bankBranch"}
                style={{ flex: 1 }}
                isRequired={true}
                maxLength={UI.Constants.limits.name}
              />
              {UI.box(10)}
              <InputForm
                _this={_this}
                title={"Name on the Bank Account"}
                placeholder={"Name on the Bank Account"}
                state_name={"bankAccountName"}
                style={{ flex: 1 }}
                isRequired={true}
                maxLength={UI.Constants.limits.name}
              />
            </UI.Row>
            {UI.box(20)}
            <UI.Row
              style={{ width: row_width, justifyContent: "space-between" }}
              _this={_this}
              breakpoint={1000}
            >
              <InputForm
                _this={_this}
                title={"Bank Account Number"}
                placeholder={"Bank Account Number"}
                state_name={"bankAccountNumber"}
                isRequired={true}
                isNumber={true}
                style={{ flex: 1 }}
                maxLength={UI.Constants.limits.bank_account_number}
              />
              {UI.box(10)}
              <View style={{ flex: 1 }}></View>
              {UI.box(10)}
              <View style={{ flex: 1 }}></View>
            </UI.Row>
          </>
        )}

        {_this.state.financialType === "paymaya" && (
          <UI.Row
            style={{ width: row_width, justifyContent: "space-between" }}
            _this={_this}
            breakpoint={1000}
          >
            <InputForm
              _this={_this}
              title={"Maya Account Number"}
              placeholder={"Maya Account Number"}
              state_name={"paymayaAccountNumber"}
              isRequired={true}
              isNumber={true}
              style={{ flex: 1 }}
              maxLength={UI.Constants.limits.bank_account_number}
            />
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row>
        )}
      </UI.Column>
    );
  }
}
