import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import DoctorLeaveList from "./DotorLeaveList";

const context = "admin";
export default class AdminDoctorLeaveListPage extends Component {
  constructor() {
    super();
    this.state = {};
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Daily Time Report",
      context: context,
    });
  }

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <DoctorLeaveList _this={_this} />
      </MainLayout>
    );
  }
}
