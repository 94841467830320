import React from 'react'
import LoginRightContainer from './LoginRightContainer'
import LoginLeftContainer from './LoginLeftContainer'
import styled from 'styled-components'

const DefaultLoginUsingPhoneNumber = ({_this} ) => {
  return (
    <LoginContainer>
      <LoginLeftContainer _this={_this}/>
      <LoginRightContainer _this={_this}/>
    </LoginContainer>
  )
}

export default DefaultLoginUsingPhoneNumber

const LoginContainer = styled(`div`)`
  display: flex;
  height: 100vh;
`;