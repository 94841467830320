import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import UI from "../../shared/Components/UI/js";
import { StyleSheet, View } from "../../shared/custom-react-native";
import "./css.css";

function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    );
  }
  throw new Error("Bad Hex");
}

function hexOpacity(hex, opacity) {
  let rgba = hexToRgbA(hex);

  return rgba.replace(/[^,]+(?=\))/, opacity);
}

export default class AreaChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_resizing: false,
      key: "key_" + Date.now() + "_" + UI.uniqid(),
    };
  }

  update_key = () => {
    this.setState({
      key: "key_" + Date.now() + "_" + UI.uniqid(),
    });
  };

  componentDidMount = () => {
    let _this = this.props._this;
    _this.setState({
      current_scroll: 0,
    });
    _this.add_resize_function("area_resize_" + this.props.id, this.on_resize);
  };

  resizing_timeout = setTimeout(() => {}, 300);

  on_resize = () => {
    let _this = this.props._this;
    this.setState(
      {
        is_resizing: true,
      },
      () => {
        if (this.resizing_timeout) {
          clearTimeout(this.resizing_timeout);
        }

        this.resizing_timeout = setTimeout(() => {
          this.setState(
            {
              is_resizing: false,
            },
            () => {
              setTimeout(() => {
                _this.setState({
                  current_scroll:
                    document.getElementById("main_layout").scrollTop,
                });
                this.update_key();
              }, 100);
            }
          );
        }, 100);
      }
    );
  };

  render() {
    let _this = this.props._this;
    let { is_resizing } = this.state;
    let current_scroll = _this.state.current_scroll;
    if (current_scroll === undefined) {
      current_scroll = 0;
    }

    let datasets = this.props.data.map((item) => {
      let obj = {
        label: item.title,
        fill: {
          target: "origin",
          above: hexOpacity(item.color, 0.9),
          below: hexOpacity(item.color, 0.9),
        },
        data: item.data,
        borderColor: "white",
        borderWidth: 0,
        pointRadius: 7,
        pointBorderWidth: 0,
        pointHoverRadius: 6,
        pointHoverBorderWidth: 3,
        pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: item.color,
        pointBorderColor: "white",
        hoverBorderColor: "white",
      };

      return obj;
    });

    const getOrCreateTooltip = (chart) => {
      let tooltipEl = chart.canvas.parentNode.querySelector("div");

      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.style.background = "white";
        tooltipEl.style.borderRadius = "3px";
        tooltipEl.style.color = "#535353";
        tooltipEl.style.boxShadow = "0px 2px 8px -4px #888888";
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.transform = "translate(-50%, 0)";
        tooltipEl.style.transition = "all .1s ease";
        // tooltipEl.style.marginTop = marginTop;
        tooltipEl.style.padding = "0px";

        const table = document.createElement("table");
        table.style.margin = "0px";

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
      // Tooltip Element
      const { chart, tooltip } = context;
      const tooltipEl = getOrCreateTooltip(chart);

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set Text
      if (tooltip.body) {
        let bg_color = tooltip.dataPoints[0].dataset.fill.above;
        let title = tooltip.title[0];
        let value = tooltip.dataPoints[0].formattedValue;
        const tableRoot = tooltipEl.querySelector("table");

        let el = [
          "<div style = 'display: flex; min-width: 100px; flex-direction: column; '>",
          " <div style = 'font-weight: bold; font-size: 14px; padding: 10px;'>",
          "   <span>",
          title,
          "   </span>",
          " </div>",
          " <div style = 'width: 100%; height: 1px; background-color: #DBDBDB'>",
          " </div>",
          " <div style = 'font-weight: bold; font-size: 14px; padding: 10px; display: flex; align-items: center;'>",
          "   <div style = 'height: 15px; width: 15px; border-radius: 100px; background-color: " +
            bg_color +
            "'>",
          "   </div>",
          "   <span style = 'margin-left: 10px;'>",
          value,
          "   </span>",
          " </div>",
          "</div>",
        ];

        el = el.join("");
        // Remove old children
        tableRoot.innerHTML = el;
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + "px";
      tooltipEl.style.top =
        positionY + tooltip.caretY + (-current_scroll - 50) + "px";
      // tooltipEl.style.top = positionY + tooltip.caretY + "px";

      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding =
        tooltip.options.padding + "px " + tooltip.options.padding + "px";
    };

    if (is_resizing) {
      return <View></View>;
    }
    return (
      <View key={this.state.key} style={styles.main_container}>
        <Line
          ref={(line) => {
            this.line = line;
          }}
          data={{
            labels: this.props.labels,
            datasets: datasets,
          }}
          height={100}
          options={{
            plugins: {
              filler: {
                propagate: true,
              },
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
                position: "average",
                external: externalTooltipHandler,
              },
            },
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  main_container: {
    backgroundColor: "white",
  },
});
