import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import ModalCloseIcon from "../../../icons/ModalClose";

const ImgInModal = (props) => {
  return (
    <StyledModal
      {...props}
      closeIcon={<ModalCloseIcon />}
      okText={props?.buttonText}
      buttoncolor={props?.buttoncolor}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
    >
      {props?.banner}
    </StyledModal>
  );
};

export default ImgInModal;

const StyledModal = styled(Modal)`
  font-family: Poppins;
  letter-spacing: 0px;
  /* width: 1px !important; */
  padding-bottom: 0;
  .ant-image {
    display: block;
  }

  .ant-modal-content {
    border-radius: 15px;
    .ant-modal-close {
      background-color: #d8d8d873;
      border-radius: 50%;
    }
  }

  .ant-modal-header {
    // For future use if Img in Modal needs Header
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-footer {
    text-align: center;
    border-top: 0;
    padding: 0;
  }
`;
