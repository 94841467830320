import React, { useCallback, useState, useEffect } from "react";
import DataTable from "../../ComponentsFC/DataTable";
import styled from "styled-components";
import { Row, Input, Select, Typography, Col, Button, DatePicker } from "antd";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import ServiceMarketingsApi from "../../api_services/marketings";
import { mem, Text } from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";
import moment from "moment";
// import ServiceAdminsApi from "../../api_services/admins";
// import _ from "lodash";

const ReferralTable = ({ _this }) => {
  const [tableData, setTableData] = useState([]);
  const [searchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // const [selectedCity, setselectedCity] = useState("");
  // const [selectedRegion, setselectedRegion] = useState("");
  const [startDate, setsStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [regions, setRegions] = useState([]);
  // const [cities, setCities] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(5);
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const getUserReferralOfReferrers = useCallback(async () => {
    try {
      setIsLoading(true);

      let params = {};

      if (searchQuery) {
        params.keyword = searchQuery;
      }

      if (currentPageNum) {
        params.page = currentPageNum;
        params.limit = tableEntries;
      }

      // if (selectedCity) {
      //   params.city = selectedCity;
      // }

      // if (selectedRegion) {
      //   params.region = selectedRegion;
      // }

      if (startDate) {
        params.startDate = startDate;
      }

      if (endDate) {
        params.endDate = endDate;
      }

      const data = JSON.parse(mem.get('patient_referral_view'))
      ServiceMarketingsApi.getUserReferralOfReferrers(data._id, params).then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setTableData(res.data)
          setTotalRecords(res.count)
        }
      });
    } catch (err) {
      // Handle Errors Here
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }, [currentPageNum, tableEntries, searchQuery, startDate, endDate]);

  // const getAllRegions = () => {
  //   _this.show_loading();
  //   ServiceAdminsApi.getAllRegions().then((res) => {
  //     if (res.status === 200) {
  //       setRegions(res)
  //       _this.hide_loading();
  //     } 
  //   });
  // };

  useEffect(() => {
    getUserReferralOfReferrers();
    // getAllRegions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserReferralOfReferrers]);

  const handlePaginateTable = (pageNum) => {
    // console.log("Currently Selected Page # of Antd Table: ", pageNum);
    setCurrentPageNum(pageNum);
  };

  const handleCSVExport = () => {
    const csv_data = tableData.map((item, index) => {
      const d = {
        "Patient ID": (item?._id || item.id) ?? "--",
        "User Name": item?.name ?? "--",
        "Email": item?.email ?? "--",
        "Mobile Number": item?.phone ?? "--",
        // "Region": item?.region ?? "--",
        // "City": item?.city ?? "--",
        "Date Registered": item?.registeredDate ? UI.momentPHTimezone(item?.registeredDate).format('MM/DD/YYYY') : "--",
      };

      return d;
    });

    UI.DownloadCSV(`User Referrals ${UI.get_current_date_string()}`, csv_data);
  };

  const tableColumnHeader = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id) => id,
    },
    {
        title: "Patient Name",
        dataIndex: "customer_name",
        key: "customer_name",
        // render: (username, item) => UI.get_fullname(item.firstName, item.middleName, item.lastName) ?? "--",
        render: (username, item) => item?.name ?? "",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      render: (email) => email ?? "--",
    },
    {
      title: "Mobile Number",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => phone ? phone.split("")[0] === "9" ? `0${phone}` : phone : "--",
    },
    // {
    //   title: "Region",
    //   dataIndex: "region",
    //   key: "region",
    //   render: (region, item) => region ?? "--",
    // },
    // {
    //   title: "City",
    //   dataIndex: "city",
    //   key: "city",
    //   render: (city, item) => city ?? "--",
    // },
    {
      title: "Date Registered",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt, item) => item?.registeredDate ? UI.momentPHTimezone(item.registeredDate).format('MM/DD/YYYY') : "--",
    },
  ];

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPageNum(1);
    setTableEntries(30);
  };

  const handleFilterByShiftDateRange = (dates, dateStrings) => {
    setsStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  return (
    <div className="flex flex-col">
      <FilterBox>
        <Text style={{
          fontSize: 16,
          fontWeight: "bold",
          color: UI.colors.secondary,
          flex: 1,
          paddingLeft: 35,
          paddingBottom: 10
        }}>
          Referral Details List
        </Text>
        {/* <Toolbar gutter={14}>
          <ToolItem span={8}>
            <StyledFieldLabel>Region</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Select Region"
              style={{
                width: "100%",
              }}
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                if (!value) {
                  setselectedRegion("")
                  return setCities([])
                }

                const listofCity = UI.get_city_by_region(regions, value)
                
                setselectedRegion(value)
                setCities(listofCity)
              }}
            >
              <Select.Option value={""}>Select Region</Select.Option>
              {
                regions?.map((region, key) => {
                  return (
                    <Select.Option value={region[_.keys(region)].region_name}>{region[_.keys(region)].region_name}</Select.Option>
                  )
                })
              }
            </StyledSelect>
          </ToolItem>
          <ToolItem span={8}>
            <StyledFieldLabel>City</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Select City"
              style={{
                width: "100%",
              }}
              disabled={cities.length > 0 ? false : true}
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setselectedCity(value)
              }}
            >
              <Select.Option value={""}>Select City</Select.Option>
              {
                cities?.map((city, key) => {
                  return (
                    <Select.Option value={city}>{city}</Select.Option>
                  )
                })
              }
            </StyledSelect>
          </ToolItem>
        </Toolbar> */}
        <Toolbar gutter={14}>
          <ToolItem span={8}>
            <StyledFieldLabel>Date</StyledFieldLabel>
            <StyledRangePicker
              style={{
                width: "100%",
              }}
              separator={<span className="mr-6">-</span>}
              size="large"
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
              onChange={handleFilterByShiftDateRange}
            />
          </ToolItem>
        </Toolbar>
      </FilterBox>
      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              defaultValue="5"
              style={{
                width: 160,
              }}
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeNumOfEntries}
            >
              <Select.Option value="5">5 Entries</Select.Option>
              <Select.Option value="10">10 Entries</Select.Option>
              <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>
          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getUserReferralOfReferrers}
            style={{ width: 200 }}
          />
          <StyledButton type="primary" onClick={handleCSVExport}>
            Export to CSV
          </StyledButton>
        </Toolbar>
        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          defaultPageSize={5}
          pageSizeOptions={["5", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </div>
  );
};

export default ReferralTable;

// CSS Styled Components ----------------------

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 75%;
`;

const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 20px 0 0 0;
  margin-bottom: 30px;
`;

const ToolItem = styled(Col)`
  position: relative;
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  flex-direction: row;
  border-radius: 12px;
  border-width: 2px !important;
  height: 50px;
  margin-left: auto;

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  .ant-picker-range-separator {
    display: block !important;
  }
`;


const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;