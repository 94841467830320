import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  ImageBackground,
  ImageFit,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import styled from "styled-components";
import { Row, Col, Button, Input, Select, Typography } from "antd";
import DropdownIcon from "../../icons/Dropdown";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import UsersGroupSubscriptionApi from "../../api_services/subscriptions";
import { Failed, Success } from "../_components/Modal";
import APIConstants from "../../shared/modules/APIConstants";

const Constants = new APIConstants();
const context = "merchant";

export default class EcommerceUserGroupSubscriptionEdit extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "User Group",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      planName: "",
      isAllGroup: false,
      targetGroupLoaded: false,
      bundleItemsLoaded: false,
      targetGroup: [],
      targetGroupId: [],
      idCounter: 1,
      targetGroupList: [],
      bundleList: [],
      bundleItem: [],
      image: UI.PRODUCT_BOX,
      image_file: "",
    });
  }

  onCreate = () => {
    this.show_loading();
    this.getTargetGroups();
    this.getBundleItems();
  }

  getTargetGroups = async () => {
    const apiResponse = await UsersGroupSubscriptionApi.getAlltargetGroups(this.accountId);
    if(apiResponse.status === 200) {
      this.setState({
        targetGroupList: [...apiResponse]
      }, () => {
        this.targetGroupLoaded = true;
        if(this.targetGroupLoaded && this.bundleItemsLoaded) {
          this.load_data();
        }
      });
    }
  }

  getBundleItems = async () => {
    const apiResponse = await UsersGroupSubscriptionApi.getAllBundleItems(this.accountId);
    if(apiResponse.status === 200) {
      const bundles = apiResponse.map((item) => {
        const bundle = { bundleProduct: item._id, name: item.name }
        return bundle;
      });
      bundles.sort( (a, b) => {
        return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
      })
      this.setState({
        bundleList: [...bundles]
      }, () => {
        this.bundleItemsLoaded = true;
        if(this.targetGroupLoaded && this.bundleItemsLoaded) {
          this.load_data();
        }
      });
    }
  }

  load_data = () => {
    const data = JSON.parse(mem.get("edit_user_group_subscription"));

    if (data) {
      this.setState( { item: {...data} },
      () => {
        console.log('my init state:',this.state);
        this.targetGroup = [];
        const subscriptinImage = data.imagePath ? `${Constants.STORAGE_BASE_URL}?path=${data.imagePath}` : UI.SAMPLE_PRODUCT;
        
        const stateItem = this.state.item;
        let bundles = (stateItem.bundleItem.length > 0) ? stateItem.bundleItem : stateItem.subscriberUserGroup[0].memberBundleItemStatus;
        let index = 0;
        bundles = bundles.map((bundle) => {
          const newBundle = { index: index, ...bundle, bundleProduct: bundle.bundleProduct }
          index++;
          return newBundle;
        })
        let targetGroupIds;
        let targetGroupValue;
        if(this.state.targetGroupList.length === stateItem.subscriberUserGroup.length) {
          targetGroupIds = ['All'];
        } else {
          targetGroupIds = Object.keys(stateItem.subscriberUserGroup).map((key) => stateItem.subscriberUserGroup[key].userGroupId);
          targetGroupValue = this.state.targetGroupList.filter(item => targetGroupIds.includes(item.id)).map(res => res.name)
        }
        this.setState({ bundleItem: bundles, targetGroup: targetGroupValue, targetGroupId: targetGroupIds}, () => {
          this.idCounter = this.state.item.bundleItem.length;
          // console.log('test',this.state);
          if (this.state.item.logoPath || this.state.item.imagePath) {
            this.setState({ image: subscriptinImage, planDescription: this.state.item.planDescription });
            this.setState({ did_upload: true });
          }
          this.hide_loading();
        });
        //
      });
    }
  }

  handleSubscriptionInfo = (key, value) => {
    const updatedData = {...this.state.item};
    updatedData[key] = value;
    this.setState({ item: updatedData });
  }

  handleDescription = (value) => {
    this.setState({planDescription: value})
  }

  handleTargetGroups = (val, id) => {
    const ids = id.map((item) => item.id );

    let allGroup = false;
    let labels = val;
    let newValue = ids;
    if (ids[ids.length - 1] === 'All') {
      allGroup = true;
      labels = ['All'];
      newValue = [];
    } else if (ids.includes('All')) {
      labels.shift();
      newValue.shift();
    }
    this.setState({
      isAllGroup: allGroup,
      targetGroup: labels,
      targetGroupId: newValue
    }, () => {
      // console.log(this.state);
    });
  }

  handleBundleDetails = (bundle, value, key, nameList) => {
    // console.log("bundle:", bundle, " value:", value, " key:", key);
    this.setState(prevState => ({
      ...prevState,
      bundleItem: prevState.bundleItem.map(item => {
        if (item.index === bundle.index) {
          const obj = {...item, [key]: value};
          if(key === 'bundleProduct') {
            obj.bundleItemName = nameList.filter(( obj ) => obj.bundleProduct === value)[0].name;
          }
          return obj;
        } else {
          return { ...item }
        }
      })
    }), () => {
      // console.log("bundles:",this.state.bundleItem);
    })
  }

  handleAddItem = () => {
    this.setState(prevState => ({
      bundleItem: [...prevState.bundleItem,
      {
        index: this.idCounter++,
        bundleProduct: null,
        bundleItemName: null,
        amount: null,
        billingTerm: null,
        fixTermLineItemStatus: "ONGOING",
        currentCycleCount: null,
      }]
    }))
  }

  handleDeleteItem = (index) => {
    this.setState({
      bundleItem: this.state.bundleItem.filter((item) => item.index !== index)
    }, () => {
      // this.idCounter--;
    });
  }

  submit = async () => {
    UI.clear_errors();
    const {
      image_file,
      targetGroupId,
      status,
      bundleItem,
      isAllGroup,
      item,
    } = this.state;

    if (item.planName) {
      await UI.error_form("name", "Subscription Name is required.");
    }

    if (targetGroupId.length < 1) {
      await UI.error_form("targetGroup", "Target Group is required.");
    }

    if (status) {
      await UI.error_form("name", "Subscription Status is required.");
    }

    if (item.planDescription) {
      await UI.error_form("description", "Description is required.");
    }

    /* IMPORTANT!!
    * Remind backend dev to remove unnecessary keys in the payload
    * (ex: 'price' is not part of this module. But is being required by backend.)
    */
    const payload = {
      ownerUserId: item.accountId,
      isAllGroup: isAllGroup,
      planName: item.planName,
      imagePath: image_file ? '' : item.imagePath,
      planDescription: item.planDescription,
      status: item.status,
      bundleItem: [...bundleItem],
      subscriberUserGroup: {
        targetGroupIds: targetGroupId,
      },
    };

    const apiResponse = await UsersGroupSubscriptionApi.updateSubscriptionList(this.state?.item?._id, image_file, payload);
    // console.log(apiResponse);
    if (apiResponse.status === 400 || apiResponse.status === 404) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"An Error has occured. Please make sure all fields have value."}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
    }
    if (apiResponse.status === 200 || apiResponse.status === 204) {
      this.show_modal(
        <Success
          title={"Success!"}
          description={"Successfully Updated Subscription"}
          onDismiss={() => {
            this.hide_modal();
            UI.goBack();
          }}
        />
      );
    }

    this.hide_loading();
  };
  
  upload_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        image: file.uri,
        image_file: file.file,
        did_upload: true,
      }
    );
    // console.log(this.state)
  };
  
  render() {
    const _this = this;
    const { item, targetGroupList, bundleList, targetGroup, bundleItem, did_upload, image, } = _this.state;

    return (
      <MainLayout context={context} additional={"subscriptions"} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Edit Subscription Product
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add item
            </Text>
          </UI.Row>
          {UI.box(10)}
          <UI.Row breakpoint={970} _this={_this}>
              <View style={{ height: 120, width: 120 }}>
                <Layout.FilePicker
                  _this={_this}
                  title={""}
                  additional={""}
                  // isRequired={true}
                  text={""}
                  state_name={"icon_specialty"}
                  onChange={async (file) => {
                    // this.pick_image();

                    // const uri = await UI.get_uri_from_file(file);
                    // this.setState(
                    //   {
                    //     image: uri,
                    //     image_file: file,
                    //     did_upload: true,
                    //   }
                    // );
                  }}
                  height={120}
                  component={
                    <ImageBackground
                      style={{ height: 120, width: 120, borderRadius: 10 }}
                      source={image}
                      imageFit={ImageFit.CONTAIN}
                    />
                  }
                />
              </View>
              {UI.box(20)}
              <strong className="text-red-500">*</strong>
              <View style={{ flex: 1.5, justifyContent: "center" }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF", maxWidth: "480px" }}
                >
                  {did_upload
                    ? "Subscription Picture Uploaded."
                    : "No Subscription Picture Uploaded. Recommended Size: 500 px x 500 px (Optional, for Merchant Edit)"}{" "}
                </Text>
                {UI.box(10)}

                <Layout.Btn
                  color={UI.colors.secondary}
                  text={"Upload Subscription Picture"}
                  onClick={() => {
                    // this.pick_image();

                    this.upload_picture();
                  }}
                  width={220}
                />
              </View>
          </UI.Row>
          {UI.box(20)}

          <SubscriptionRow>
            <ColBar gutter={16}>
              <ToolItem className="gutter-row" span={8}>
                <StyledFieldLabel>Subscription Name</StyledFieldLabel>
                <StyledInput
                  placeholder="Enter Name"
                  value={item?.planName}
                  allowClear
                  onChange={(e) => {
                    this.handleSubscriptionInfo('planName', e.target.value);
                  }}
                />
              </ToolItem>
              <ToolItem className="gutter-row" span={8}>
                <StyledFieldLabel>Target Group</StyledFieldLabel>
                <StyledSelect
                  getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                  placeholder="Select Group"
                  mode="multiple"
                  defaultValue={[]}
                  maxTagCount={'responsive'}
                  value={targetGroup}
                  style={{
                    width: "100%",
                  }}
                  allowClear
                  onChange={(value, ids) => this.handleTargetGroups(value, ids) }
                >
                  <Select.Option id={"All"} title="Action" value="All">
                    All
                  </Select.Option>
                  {
                    targetGroupList?.map((targetGroup, index) => {
                      return (
                        <Select.Option id={targetGroup.id} title="Action" value={targetGroup.name}>
                          {targetGroup.name}
                        </Select.Option>
                      )
                    })
                  }
                </StyledSelect>
              </ToolItem>
              <ToolItem className="gutter-row" span={8}>
                <StyledFieldLabel>Status</StyledFieldLabel>
                <StyledSelect
                  getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                  placeholder="Select Status"
                  value={item?.status}
                  style={{
                    width: "100%",
                  }}
                  suffixIcon={<DropdownIcon />}
                  onChange={(value) => this.handleSubscriptionInfo('status', value)}
                >
                  <Select.Option title="Action" value="Active">
                    Active
                  </Select.Option>
                  <Select.Option title="Action" value="Inactive">
                    Inactive
                  </Select.Option>
                </StyledSelect>
              </ToolItem>
            </ColBar>
            <ColBar style={{paddingTop: 10}} span={24}>
              <StyledFieldLabel>Description</StyledFieldLabel>
              <StyledInput.TextArea
                className='my-text-area'
                autoSize={{ minRows: 3, maxRows: 6 }}
                value={item?.planDescription}
                style={{borderRadius: 12, borderWidth: 2}}
                onChange={(e) => this.handleSubscriptionInfo('planDescription', e.target.value)}
              />
            </ColBar>
          </SubscriptionRow>

          <SubscriptionRow>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Select Item
            </Text>
            {
              bundleItem?.map((bundle, index) => {
                return (
                  <>
                    <ColBar id={'bundle#' + index} className='select-item-section' gutter={16}>
                      <ToolItem className="gutter-row" span={6}>
                        <StyledFieldLabel>Bundle Item</StyledFieldLabel>
                        <StyledSelect
                          getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                          defaultValue={[]}
                          value={bundle.bundleItemName}
                          placeholder="Select Item"
                          style={{
                            width: "100%",
                          }}
                          suffixIcon={<DropdownIcon />}
                          onChange={(value) => this.handleBundleDetails(bundle, value, "bundleProduct", bundleList)}
                        >
                          {
                            bundleList?.map((bundleProductItem, itemIndex) => {
                              return (
                                <Select.Option id={`bundle-`+itemIndex} title="Action" value={bundleProductItem.bundleProduct}>
                                  {bundleProductItem.name}
                                </Select.Option>
                              )
                            })
                          }
                        </StyledSelect>
                      </ToolItem>
                      <ToolItem className="gutter-row" span={6}>
                        <StyledFieldLabel>Amount</StyledFieldLabel>
                        <StyledInput
                          placeholder="00.00"
                          type="number"
                          value={bundle.amount}
                          onChange={(e) => this.handleBundleDetails(bundle, e.target.value, "amount")}
                        />
                      </ToolItem>
                      <ToolItem className="gutter-row" span={6}>
                        <StyledFieldLabel>Billing Term</StyledFieldLabel>
                        <StyledSelect
                          getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                          defaultValue={[]}
                          value={bundle.billingTerm}
                          placeholder="Select Billing Term"
                          style={{
                            width: "100%",
                          }}
                          suffixIcon={<DropdownIcon />}
                          onChange={(value) => this.handleBundleDetails(bundle, value, "billingTerm")}
                        >
                          <Select.Option title="Action" value="6_MONTHS">
                            6 Months
                          </Select.Option>
                          <Select.Option title="Action" value="12_MONTHS">
                            12 Months
                          </Select.Option>
                          <Select.Option title="Action" value="1_TIME_PAYMENT">
                            1 Time Payment
                          </Select.Option>
                          <Select.Option title="Action" value="FREEBIES">
                            Freebies
                          </Select.Option>
                          <Select.Option title="Action" value="RECURRING">
                            Recurring
                          </Select.Option>
                        </StyledSelect>
                      </ToolItem>
                      <ToolItem className="gutter-row" span={6}>
                        <StyledFieldLabel>Billing Cycle</StyledFieldLabel>
                        <StyledSelect
                          disabled={
                            bundleItem.find((a, i) => i === index && (bundle.billingTerm === "1_TIME_PAYMENT" || bundle.billingTerm === "FREEBIES"))
                          }
                          getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                          defaultValue={[]}
                          value={bundle.currentCycleCount}
                          placeholder="Select Billing Cycle"
                          style={{
                            width: "100%",
                          }}
                          suffixIcon={<DropdownIcon />}
                          onChange={(value) => this.handleBundleDetails(bundle, value, "currentCycleCount")}
                        >
                          {
                            bundleItem.find((a, i) => i === index && (bundle.billingTerm === "6_MONTHS")) ? 
                              <Select.Option title="Action" value={30}>
                              30 Days
                              </Select.Option>
                            :
                            <>
                              <Select.Option title="Action" value={365}>
                                Yearly
                              </Select.Option>
                              <Select.Option title="Action" value={30}>
                                30 Days
                                </Select.Option>
                            </>
                          }
                        </StyledSelect>
                      </ToolItem>
                    </ColBar>
                    {
                      !index ? '' : 
                      <Button
                        style={{display: 'flex', border: 'none', alignSelf: 'end'}}
                        icon={<img src={UI.HIDE} alt="remove_icon"/>}
                        onClick={() => this.handleDeleteItem(bundle.index)}
                      />
                    }
                  </>
                )
              })
            }
            <StyledAddItemButton
              style={{
                marginTop: item?.bundleItem?.length === 1 && 20
              }}
              onClick={() => this.handleAddItem()}
            >
              + Add Item
            </StyledAddItemButton>
          </SubscriptionRow>

          {UI.box(20)}
          
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"SAVE ITEM"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// CSS Styled Components ----------------------
const SubscriptionRow = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 33px 0 0 0;
  margin-bottom: 30px;

  .ant-row {
    padding-left: 0;
    padding-right: 0;
  }

  .select-item-section {
    .ant-typography {
      margin-top: 10px;
    }
  }
`;

const ColBar = styled(Row)`
  display: inline-flex;
  align-items: flex-start;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 0;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const ToolItem = styled(Col)`
  position: relative;
  .ant-picker {
    margin-left: 102px;
  }
  .ant-picker-range {
    margin-left: 62px;
  }
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;

const StyledAddItemButton = styled(Button)`
  background-color: none;
  border: 0;
  display: flex;
  align-self: flex-start;
  color: #004F99;
  font-family: Poppins;
  font-weight: bolder;
  font-size: 1.1rem;
`;
