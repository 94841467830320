import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import styled from "styled-components";
import { Row, Col, Input, Select, Typography } from "antd";
import DropdownIcon from "../../icons/Dropdown";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";


export default class UserGroupSubscriptionsView extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "User Group List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: mem.get(UI.Constants.ROLE),

      planName: "",
      targetGroupLoaded: false,
      bundleItemsLoaded: false,
      targetGroup: [],
      idCounter: 1,
      targetGroupList: [],
      bundleList: [],
      bundleItem: [],
      image: UI.PRODUCT_BOX,
      image_file: "",
    });
  }

	onCreate = () => {
		this.getSpecificSubscriptions()
	}

  getSpecificSubscriptions = () => {
    const data = JSON.parse(mem.get("user_group_subscriptions_view"));
    console.log(data)
		if (data) {
      this.setState( { item: {...data} },
      () => {
        
        const stateItem = this.state.item;
        let bundles = (stateItem.bundleItem.length > 0) ? stateItem.bundleItem : stateItem.subscriberUserGroup[0].memberBundleItemStatus;
        let index = 0;
        bundles = bundles.map((bundle) => {
          const newBundle = { index: index, ...bundle, bundleProduct: bundle.bundleProduct }
          index++;
          return newBundle;
        })
        let targetGroupIds;
        if(this.state.targetGroupList.length === stateItem.subscriberUserGroup.length) {
          targetGroupIds = ['All'];
        } else {
          targetGroupIds = Object.keys(stateItem.subscriberUserGroup).map((key) => stateItem.subscriberUserGroup[key].userGroupId );
        }
        
        this.setState({ bundleItem: bundles, targetGroup: targetGroupIds }, () => {
          this.idCounter = this.state.item.bundleItem.length;
          // console.log('test',this.state);
          if (this.state.item.logoPath || this.state.item.imagePath) {
            // this.setState({ image: subscriptinImage, planDescription: this.state.item.planDescription });
            this.setState({ did_upload: true });
          }
          this.hide_loading();
        });
        //
      });
    }
  }
  
  render() {
    const _this = this;
    const { item, bundleItem } = _this.state;

    return (
      <MainLayout context={this.state.context} additional={"subscriptions"} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Subscription Product
            </Text>
          </UI.Row>

          <SubscriptionRow>
            <ColBar gutter={16}>
              <ToolItem className="gutter-row" span={8}>
                <StyledFieldLabel>Subscription Name</StyledFieldLabel>
                <StyledInput
                  placeholder="Enter Name"
									disabled={true}
                  value={item?.planName}
                  allowClear
                  onChange={(e) => {
                    this.handleSubscriptionInfo('planName', e.target.value);
                  }}
                />
              </ToolItem>
              <ToolItem className="gutter-row" span={8}>
                <StyledFieldLabel>Status</StyledFieldLabel>
                <StyledSelect
                  getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
									disabled={true}
                  placeholder="Select Status"
                  value={item?.status}
                  style={{
                    width: "100%",
                  }}
                  suffixIcon={<DropdownIcon />}
                  onChange={(value) => this.handleSubscriptionInfo('status', value)}
                >
                  <Select.Option title="Action" value="Active">
                    Active
                  </Select.Option>
                  <Select.Option title="Action" value="Inactive">
                    Inactive
                  </Select.Option>
                </StyledSelect>
              </ToolItem>
            </ColBar>
            <ColBar style={{paddingTop: 10}} span={24}>
              <StyledFieldLabel>Description</StyledFieldLabel>
              <StyledInput.TextArea
								disabled={true}
                className='my-text-area'
                autoSize={{ minRows: 3, maxRows: 6 }}
                value={item?.planDescription}
                style={{borderRadius: 12, borderWidth: 2}}
                onChange={(e) => this.handleSubscriptionInfo('planDescription', e.target.value)}
              />
            </ColBar>
          </SubscriptionRow>

          <SubscriptionRow>
            {
              bundleItem?.map((bundle, index) => {
                return (
                  <>
                    <ColBar id={'bundle#' + index} className='select-item-section' gutter={16}>
                      <ToolItem className="gutter-row" span={6}>
                        <StyledFieldLabel>Bundle Item</StyledFieldLabel>
                        <StyledSelect
													disabled={true}
                          getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                          defaultValue={[]}
                          value={bundle.bundleItemName}
                          placeholder="Select Item"
                          style={{
                            width: "100%",
                          }}
                          suffixIcon={<DropdownIcon />}
                        >
                        </StyledSelect>
                      </ToolItem>
                      <ToolItem className="gutter-row" span={6}>
                        <StyledFieldLabel>Amount</StyledFieldLabel>
                        <StyledInput
													disabled={true}
                          placeholder="00.00"
                          value={bundle.amount}
                        />
                      </ToolItem>
                      <ToolItem className="gutter-row" span={6}>
                        <StyledFieldLabel>Billing Term</StyledFieldLabel>
                        <StyledSelect
                          getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                          defaultValue={[]}
													disabled={true}
                          value={bundle.billingTerm}
                          placeholder="Select Billing Term"
                          style={{
                            width: "100%",
                          }}
                          suffixIcon={<DropdownIcon />}
                        >
                          <Select.Option title="Action" value="6_MONTHS">
                            6 Months
                          </Select.Option>
                          <Select.Option title="Action" value="12_MONTHS">
                            12 Months
                          </Select.Option>
                          <Select.Option title="Action" value="1_TIME_PAYMENT">
                            1 Time Payment
                          </Select.Option>
                          <Select.Option title="Action" value="FREEBIES">
                            Freebies
                          </Select.Option>
                          <Select.Option title="Action" value="OCCURRING">
                            Occurring
                          </Select.Option>
                        </StyledSelect>
                      </ToolItem>
                      <ToolItem className="gutter-row" span={6}>
                        <StyledFieldLabel>Billing Cycle</StyledFieldLabel>
                        <StyledSelect
                          getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                          defaultValue={[]}
                          value={bundle.currentCycleCount}
                          placeholder="Select Billing Cycle"
													disabled={true}
                          style={{
                            width: "100%",
                          }}
                          suffixIcon={<DropdownIcon />}
                        >
                          <Select.Option title="Action" value={30}>
                            30 Days
                          </Select.Option>
                          <Select.Option title="Action" value={365}>
                            Yearly
                          </Select.Option>
                        </StyledSelect>
                      </ToolItem>
                    </ColBar>
                  </>
                )
              })
            }
          </SubscriptionRow>

          {UI.box(20)}
          
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goTo(`/${this.state.context}/usergroup-view?activeTab=Subscriptions`)
              }}
              text={"Back"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});

// CSS Styled Components ----------------------
const SubscriptionRow = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 33px 0 0 0;
  /* margin-bottom: 30px; */

  .ant-row {
    padding-left: 0;
    padding-right: 0;
  }

  .select-item-section {
    .ant-typography {
      margin-top: 10px;
    }
  }
`;

const ColBar = styled(Row)`
  display: inline-flex;
  align-items: flex-start;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 0;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const ToolItem = styled(Col)`
  position: relative;
  .ant-picker {
    margin-left: 102px;
  }
  .ant-picker-range {
    margin-left: 62px;
  }
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      /* color: #2e2e2e; */
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;