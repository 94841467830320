import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  ImageBackground,
  ImageFit,
  Shadow,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import {
  DeleteConfirmation,
  SimpleDeleteConfirmation,
  Failed,
  Success,
  // RadioButtonModal,
} from "../_components/Modal";
import "./css.css";

const context = "merchant";

export default class EcommerceAccount extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Account",
      is_drawer_open: false,
      selected: null,
      did_upload_banner: false,
      did_upload_logo: false,
      logoImgPath: "",
      bannerImgPath: "",
      image_banner_file: null,
      image_logo_file: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,
      show_modal: false,
      modal_content: "",
      bank_name: "",
      bank_account_number: "",
      branch: "",
    });
  }

  onCreate = () => {
    this.get_data();
  };

  get_data = async (params, reload) => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/merchant/me",
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        const merchant_data = data;
        const rep_data = data.user;
        console.log(merchant_data);
        UI.set_input_value("merchant_name", merchant_data?.merchantName ?? "");
        UI.set_input_value(
          "merchant_email",
          merchant_data?.customerSupportEmail ?? ""
        );
        UI.set_input_value(
          "merchant_hotline",
          merchant_data?.supportHotline ?? ""
        );
        UI.set_input_value("rep_name", rep_data?.name ?? "");
        UI.set_input_value("rep_email", rep_data?.email ?? "");
        UI.set_input_value("rep_number", rep_data?.phone ?? "");
        UI.set_input_value(
          "merchant_address",
          merchant_data?.merchantBusinessAddress ?? ""
        );
        UI.set_input_value("bank_name", merchant_data.bankInfo?.bankName ?? "");
        UI.set_input_value(
          "bank_account_number",
          merchant_data?.bankInfo?.bankAccountNumber ?? ""
        );
        UI.set_input_value("branch", merchant_data?.bankInfo?.bankBranch ?? "");

        // Loading of images
        const bannerImage = await UI.download_image(
          merchant_data.bannerPath,
          "ecommerce"
        );
        this.setState(
          {
            // bannerImgPath: merchant_data.imagePath,
            // bannerId: merchant_data._id,
            banner: bannerImage,
          },
          () => {
            // callbacks here
          }
        );

        const logoImage = await UI.download_image(
          merchant_data.logoPath,
          "ecommerce"
        );
        this.setState(
          {
            // logoImgPath: merchant_data.imagePath,
            // logoId: merchant_data._id,
            logo: logoImage,
          },
          () => {
            // callbacks here
          }
        );
        //
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  submit = async () => {
    UI.clear_errors();
    //no merchant yet
    const {
      image_banner_file,
      image_logo_file,
      merchant_name,
      merchant_email,
      merchant_hotline,
      rep_name,
      rep_email,
      rep_number,
      merchant_address,
      bank_name,
      bank_account_number,
      branch,
    } = this.state;

    if (!merchant_name) {
      await UI.error_form("name", "Product Name is required.");
    }

    // if (!logoImgPath) {
    //   this.show_modal(
    //     <Failed
    //       title={"Failed"}
    //       description={"Invalid Merchant Logo"}
    //       onDismiss={() => {
    //         this.hide_modal();
    //       }}
    //     />
    //   );
    //   return;
    // }
    // const error_count = this.state.error_count;
    // if (error_count > 0) {
    //   return;
    // }

    this.show_loading();

    UI.webRequest({
      method: "put",
      url: "admin/merchant/me",
      params: {
        bannerImage: image_banner_file,
        logoImage: image_logo_file,
        merchantName: merchant_name,
        customerSupportEmail: merchant_email,
        supportHotline: merchant_hotline,
        repName: rep_name,
        repEmail: rep_email,
        repNumber: rep_number,
        merchantBusinessAddress: merchant_address,
        bankName: bank_name,
        bankAccountNumber: bank_account_number,
        bankBranch: branch,
      },
      isMultiPart: true,
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.error;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        this.setState({
          did_upload_logo: false,
          did_upload_banner: false,
        });
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully updated account."}
            onDismiss={() => {
              window.location.reload();
              this.hide_modal();
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  show_modal = (modal_content) => {
    this.setState({
      show_modal: true,
      modal_content: modal_content,
    });
  };

  hide_modal = () => {
    this.setState({
      show_modal: false,
      modal_content: "",
    });
  };

  showDeleteModal = (deleteType) => {
    const modalBody =
      deleteType === "deactivate"
        ? "Deactivating the account will disable the profile and removing the information."
        : "Permanently delete the account and all the data associated with it";

    this.show_modal(
      <SimpleDeleteConfirmation
        title={"Are you sure you want to " + deleteType + " the account?"}
        text={modalBody}
        onOk={{
          label:
            deleteType === "deactivate" ? "DEACTIVATE NOW" : "DELETE ACCOUNT",
          method: () => {
            this.hide_modal();
          },
        }}
        onCancel={{
          label: "CANCEL",
          method: () => {
            this.hide_modal();
          },
        }}
      />
    );
  };

  // TO-DO: Delete account functionality
  delete = async (id) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this item?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();
          this.show_loading();
          UI.webRequest({
            method: "delete",
            url: "admin/merchant/me",
            params: {}, // TO-DO: determine params if delete or activate
            onFail: async (err) => {},
            onSuccess: async (response) => {},
            onFinish: () => {
              this.onCreate();
            },
          });
        }}
      />
    );
  };

  upload_merchant_image = async (type) => {
    const file = await UI.get_image();
    if (type === "banner") {
      this.setState(
        {
          banner: file.uri,
          image_banner_file: file.file,
          did_upload_banner: true,
        },
        () => {
          // this.auth_upload("banner");
        }
      );
    } else {
      this.setState(
        {
          logo: file.uri,
          image_logo_file: file.file,
          did_upload_logo: true,
        },
        () => {
          // this.auth_upload("logo");
        }
      );
    }
  };

  auth_upload = async (type) => {
    const file =
      type === "banner"
        ? this.state.image_banner_file
        : this.state.image_logo_file;
    const stateKey = type === "banner" ? "bannerImgPath" : "logoImgPath";
    UI.upload_image({
      url: "admin/merchant/upload",
      file: file,
      onFail: (err) => {},
      onSuccess: (path, response) => {
        this.setState({
          [stateKey]: path,
        });
        // console.log("state logo:",this.state.logoImgPath)
      },
    });
  };

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <View>
            <Text style={styles.box_header_text}>Account Details</Text>
            {UI.box(20)}
            <UI.Row breakpoint={970} _this={_this}>
              <View>
                <Layout.FilePicker
                  _this={_this}
                  title={""}
                  additional={""}
                  isRequired={false}
                  text={""}
                  state_name={"merchant_banner"}
                  onChange={async (file) => {
                    const uri = await UI.get_uri_from_file(file);
                    this.setState(
                      {
                        banner: uri,
                        image_banner_file: file,
                        did_upload_banner: true,
                      },
                      () => {
                        //this.auth_upload();
                      }
                    );
                  }}
                  height={"auto"}
                  component={
                    <View
                      style={{ height: 240, width: 560, cursor: "pointer" }}
                      onClick={() => {
                        this.upload_merchant_image("banner");
                      }}
                    >
                      <ImageBackground
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: 10,
                          border: "2px dashed #CCCCCC",
                          backgroundColor: "#EFEFEF",
                        }}
                        source={this.state.banner}
                        imageFit={ImageFit.CONTAIN}
                        children={
                          !this.state.banner ? (
                            <View
                              style={{
                                height: "100%",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Image
                                height={80}
                                width={80}
                                tintColor={"transparent"}
                                source={UI.UPLOAD_IMAGE}
                              />
                              <Text
                                style={{
                                  ...styles.box_header_text,
                                  flex: 0,
                                  marginTop: 10,
                                }}
                              >
                                Click Here to Upload Merchant Banner
                              </Text>
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontWeight: "bold",
                                  color: "#AFAFAF",
                                }}
                              >
                                Recommended Size: 500x500
                              </Text>
                            </View>
                          ) : (
                            ""
                          )
                        }
                      />
                    </View>
                  }
                />
              </View>
              {UI.box(20)}
            </UI.Row>

            {UI.box(20)}
            <UI.Row breakpoint={970} _this={_this}>
              <View style={{ height: 120, width: 120 }}>
                <Layout.FilePicker
                  _this={_this}
                  title={""}
                  additional={""}
                  isRequired={false}
                  text={""}
                  state_name={"merchant_logo"}
                  onChange={async (file) => {
                    const uri = await UI.get_uri_from_file(file);
                    this.setState(
                      {
                        logo: uri,
                        image_logo_file: file,
                        did_upload_logo: true,
                      },
                      () => {
                        //this.auth_upload();
                      }
                    );
                  }}
                  height={120}
                  component={
                    <ImageBackground
                      style={{
                        height: 120,
                        width: 120,
                        borderRadius: 10,
                        border: "2px dashed #CCCCCC",
                      }}
                      source={this.state.logo}
                      imageFit={ImageFit.CONTAIN}
                    />
                  }
                />
              </View>
              {UI.box(20)}

              <View style={{ flex: 1.5, justifyContent: "center" }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
                >
                  {this.state.logo
                    ? "Merchant Logo Uploaded."
                    : "No Merchant Logo Uploaded."}{" "}
                  Recommended Size: 500 px x 500 px
                </Text>
                {UI.box(10)}

                <Layout.Btn
                  color={UI.colors.header}
                  text={"Upload Official Merchant Logo"}
                  onClick={() => {
                    this.upload_merchant_image("logo");
                  }}
                  width={250}
                />
              </View>
            </UI.Row>

            {UI.box(20)}
            <UI.Row breakpoint={840} _this={_this}>
              <InputForm
                _this={_this}
                title={"Official Merchant Name"}
                placeholder={"Enter Official Merchant Name"}
                state_name={"merchant_name"}
                style={{ flex: 1 }}
                isRequired={false}
              />

              {UI.box(10)}
              <InputForm
                _this={_this}
                title={"Customer Support Email"}
                placeholder={"Enter Customer Support Email"}
                state_name={"merchant_email"}
                style={{ flex: 1 }}
                disabled={true}
                isRequired={false}
              />

              {UI.box(10)}
              <InputForm
                _this={_this}
                title={"Customer Support Hotline"}
                placeholder={"Enter Customer Support Hotline"}
                state_name={"merchant_hotline"}
                style={{ flex: 1 }}
                isRequired={false}
              />
            </UI.Row>
            {UI.box(20)}
            <UI.Row breakpoint={840} _this={_this}>
              <InputForm
                _this={_this}
                title={"Representative Name"}
                placeholder={"Enter Representative Name"}
                state_name={"rep_name"}
                style={{ flex: 1 }}
                disabled={true}
                isRequired={false}
              />

              {UI.box(10)}
              <InputForm
                _this={_this}
                title={"Representative Email Address"}
                placeholder={"Enter Representative Email Address"}
                state_name={"rep_email"}
                style={{ flex: 1 }}
                disabled={true}
                isRequired={false}
              />

              {UI.box(10)}
              <InputForm
                _this={_this}
                title={"Representative Mobile Number"}
                placeholder={"Enter Representative Mobile Number"}
                state_name={"rep_number"}
                disabled={true}
                style={{ flex: 1 }}
                isRequired={false}
              />
            </UI.Row>
            {UI.box(20)}
            <UI.Row breakpoint={840} _this={_this}>
              <InputForm
                _this={_this}
                title={"Official Merchant Business Address"}
                placeholder={"Enter Official Merchant Business Address"}
                state_name={"merchant_address"}
                style={{ flex: 1 }}
                isRequired={false}
              />
            </UI.Row>
          </View>

          {UI.box(30)}
          <View>
            <Text style={styles.merchant_bank_header}>
              Official Merchant Business Bank Information (for settlement)
            </Text>
            {UI.box(10)}
            <UI.Row breakpoint={840} _this={_this}>
              <InputForm
                _this={_this}
                title={"Bank Name"}
                placeholder={"Bank Name here"}
                state_name={"bank_name"}
                style={{ flex: 1 }}
                isRequired={false}
              />

              {UI.box(10)}
              <InputForm
                _this={_this}
                title={"Bank Account Number"}
                placeholder={"Enter Bank Account Number"}
                state_name={"bank_account_number"}
                style={{ flex: 1 }}
                isRequired={false}
              />

              {UI.box(10)}
              <InputForm
                _this={_this}
                title={"Bank Branch"}
                placeholder={"Bank Branch Name Here"}
                state_name={"branch"}
                style={{ flex: 1 }}
                isRequired={false}
              />
            </UI.Row>
          </View>

          {UI.box(30)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.header}
              onClick={() => {
                this.submit();
              }}
              text={"SAVE CHANGES"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
            {/* <Layout.Btn
              style={{display: "flex", marginLeft: "auto"}}
              color={"#E00000"}
              onClick={() => {
                this.show_modal(
                  <RadioButtonModal
                    title="Please Select"
                    name="account"
                    width={320}
                    options={
                      [
                        {label: "Deactivate Account", value: "deactivate"},
                        {label: "Delete Account", value: "delete"},
                      ]
                    }
                    onOk={{
                      label: "CONTINUE",
                      method: (val) => {
                        if(val) {
                          this.hide_modal();
                          this.showDeleteModal(val);
                        }
                      }
                    }}
                    onCancel={{
                      label: "CANCEL",
                      method: () => {
                        this.hide_modal();
                      }
                    }}
                  />
                )
              }}
              text={"DEACTIVATE/DELETE ACCOUNT"}
            /> */}
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  merchant_bank_header: {
    fontSize: 18,
    fontWeight: "bold",
    color: UI.colors.lightGray,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
