import UI from "../../shared/Components/UI/js";
import ServiceApi from "../service";
import APIConstants from "../../shared/modules/APIConstants";
const Constants = new APIConstants();
class DPAttendanceApi {
  baseURL = "doctors_onboarding";

  async getDoctorsAttendance(params) {
    const response = await ServiceApi.get(this.baseURL + `/doctors/attendance-reports`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async createDoctorAttendanceTimeLogs(urlParams, payload, headers) {
    const response = await ServiceApi.post(this.baseURL + `/doctors/add-timelogs${urlParams}`, payload, headers);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  baseURLApp = Constants.API_HOST;
  async getClockStatus () {
      // const isMobileRequest = true;
      // const response = await ServiceApi.request(isMobileRequest).get('/auth/me');
      const response = await ServiceApi.get(this.baseURLApp + 'app/mwell/auth/me');
      if (response?.status === 200) {
          const data = UI.extract_data(response);
          data.status = 200;
          return data;
      } else {
        return {
          status: response.status,
          data: response.data.d,
          message: response.data.m
        }
      }
  }

  async getLeaveStatistics(params) {
    const response = await ServiceApi.get(this.baseURL + `/doctors/leave-statistics`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response?.status,
        data: response?.data?.d,
        message: response?.data?.m
      }
    }
  }

  async createLeaveRequest( payload, headers) {
    const response = await ServiceApi.post(this.baseURL + `/doctors/leave-request`, payload, headers);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response?.status,
        data: response?.data?.d,
        message: response?.data?.m
      }
    }
  }

  async uploadLeaveRequestFiles( payload, headers) {
    const response = await ServiceApi.post(this.baseURL + `/doctors/upload/multiple`, payload, headers);

    if (response?.status === 200) {
      response.data.status = 200;
      return response?.data ?? response?.d;
    } else {
      return {
        status: response?.status,
        data: response?.data?.d,
        message: response?.data?.m
      }
    }
  }

  async getLeaveRequestList(params) {
    const response = await ServiceApi.get(this.baseURL + `/doctors/leave-request`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response?.status,
        data: response?.data?.d,
        message: response?.data?.m
      }
    }
  }

  async viewDoctorFile(params) {
    const response = await ServiceApi.getBlobFile(this.baseURL + `/image/retrieve`, params);

    if (response?.status === 200) {
      return response;
    } else {
      return {
        status: response?.status,
        data: response?.data.d,
        message: response?.data.m
      }
    }
  }

}

export default new DPAttendanceApi();
