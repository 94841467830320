import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";

const context = "merchant";

export default class EcommerceCustomerList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Customers",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,
    });
  }

  onCreate = () => {
    this.get_data();
  };

  get_data = async (params, reload) => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/merchant/customers",
      params: "",
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        const new_data = data.map((item) => {
          let user = item.user ?? {};
          const formData = item.formData ?? {};
          
          user.customer_id = item._id;
          user.firstName = formData?.firstName ? formData?.firstName : user.firstName
          user.gender = formData?.gender ? formData?.gender : user.gender;
          user.maritalStatus = (formData?.maritalStatus ? formData?.maritalStatus: user.maritalStatus) ?? "N/A"
        
          return user;
        });

        // if (reload) {
        this.setState({
          all_data: new_data, // TO-DO: chnage to new_data
          data: new_data, // TO-DO: chnage to new_data
          customer_id: data._id,
        });
        // }
        this.reload_table("customers");
      },
      onFinish: () => {
        this.hide_loading();
        // console.log("Inventory item?:", this.state.data); // for viewing response data
      },
    });
  };

  previews = async (data) => {
    UI.set_images(data, "preview", "customers", "ecommerce");
  };

  clear_filters = () => {
    this.get_data({
      name: "",
      sort: "name",
    });
  };

  sort_by = (format, key) => {
    //format can be asc or desc
    const { all_data } = this.state;

    all_data.sort(UI.dynamicSort(format === "asc" ? key : "-" + key));

    UI.set_input_value("search", "");

    this.setState(
      {
        data: all_data,
      },
      () => {
        this.reload_table("customers");
      }
    );
  };

  render() {
    const _this = this;
    const {
      // height,
      width,
      // isMobile
    } = _this.state;
    // let customer_id = 0; // **Commented out because it's currently unused
    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <Text style={styles.box_header_text}>Customers</Text>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Customer Name"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "firstName");
              }}
            >
              <option value="">Sort By</option>
              <option value="asc">A-Z</option>
              <option value="desc">Z-A</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={850}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"customers"} _this={_this} />
              </View>
            </View>

            <InputForm
              onChange={(e) => {
                UI.search_table("customers", e, this.state.all_data, "data");
              }}
              is_keyword={true}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
            />
          </UI.Row>

          <CustomDatatable
            state_name={"customers"}
            _this={_this}
            columns={[
              { "Customer Name": "name" }, //TO-DO: Finalize: end points
              { "Email Address": "email" },
              { "Mobile Number": "mobile_number" },
              { "Date of Birth": "birthday" },
              { "Gender"       : "gender" },
              { Action: "action" },
            ]}
            data={this.state.data.map((customer) => {
              // vars and constants here
              const user = customer;
              console.log(user);
              const userGender = user.gender ? user.gender === "1" ? 'Male' : 'Female' : "N/A"; 
              let birthday = user?.birthDate ? UI.getDateByMMDDYYYY(user?.birthDate) : "N/A";

              // customer_id++; // **Commented out because it's currently unused, as well as the declaration above (let customer_id = 0)
              // const id = UI.pad(customer_id, 12); Commented out unused variable
              return {
                name: (user.firstName || "") + " " + (user?.lastName || ""),
                email: user?.email,
                mobile_number: user?.phone,
                birthday: birthday,
                gender: userGender,
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        mem.save("view_customer", JSON.stringify(customer));
                        mem.save("customer_id", user.customer_id);
                        UI.goTo("/merchant/view-customer");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.VIEW_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO INQUIRY DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
