import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import { StyleSheet, Text, View } from "../../shared/custom-react-native";
import { Checkbox } from "../_components/InputForm";
import Layout from "../_components/Layout";
import "./css.css";
export default class Affiliation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: UI.uniqid(),
    };
  }

  reset_key = () => {
    this.setState({
      key: UI.uniqid(),
    });
  };
  render() {
    const _this = this.props._this;
    let {
      // height,
      width,
      //  isMobile,
      isAffiliated,
      hospitals,
    } = _this.state;

    return (
      <UI.Row style={{ marginBottom: 10, marginTop: 20 }} _this={_this} breakpoint={1000}>
        <View>
          <Text style={styles.title}>
            Are you affiliated with a Hospital/Clinic?
          </Text>
          {UI.box(width > 1070 ? 25 : 15)}

          <UI.Row _this={_this} breakpoint={1070}>
            <Checkbox
              _this={_this}
              text={"Yes"}
              onClick={(value) => {
                _this.setState({
                  isAffiliated: true,
                });
              }}
              is_checked={isAffiliated}
              color={"#2E2E2E"}
            />
            {UI.box(10)}
            <Checkbox
              _this={_this}
              text={
                "No, I'm a solo practitioner/I did not find the Hospital/Clinic from the list."
              }
              onClick={(value) => {
                _this.setState({
                  isAffiliated: false,
                });
              }}
              is_checked={!isAffiliated}
              color={"#2E2E2E"}
            />
          </UI.Row>
        </View>
        {UI.box(20)}
        <View
          style={{
            display: isAffiliated ? "flex" : "none",
            flex: 1,
          }}
        >
          <Layout.SelectPicker
            _this={_this}
            title={"Hospital/Clinic Affiliations"}
            isRequired={true}
            state_name={"hospital"}
            isMultiple={true}
          >
            <option value="">Select Hospital</option>
            {hospitals.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </Layout.SelectPicker>
        </View>
      </UI.Row>
    );
  }
}

const styles = StyleSheet.create({
  option_container: {
    height: 40,
    width: "100%",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 2,
    padding: 5,
    borderColor: "#CACACA",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  btn_text_1: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
  },
  blue_btn_2: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  text_1: {
    color: "#AFAFAF",
    fontSize: 14,
  },
  image_container: {
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  header_3: {
    fontSize: 16,
    color: UI.colors.primary,
    marginTop: 2,
    fontWeight: "bold",
  },
});
