import React from 'react'
import { Button } from 'antd';
import styled from 'styled-components';

import Layout from "../../../../modules/_components/Layout";

const SelectFile = ({ _this }) => {

  return (
    <>
      <div 
        className="text-center flex justify-center items-center flex-col rounded-xl px-8" 
        style={{ height: 315, backgroundColor: "#E8F2FD" }}
      >
        <p className="text-base">Upload image of your handwriting signature</p>
        <p>Image types can include PNG, JPG, and JPEG</p>
        
        <div>
          <StyledFilePicker
            _this={_this}
            text={"Drop Your File Here"}
            state_name={"signature_path"}
            onChange={async (file) => {
              _this.auth_upload("signature_path", file);
              _this.hide_modal();
            }}
            allowed_files={["jpg", "jpeg", "png"]}
            display_type={"button"}
            component={<></>}
          />
        </div>
      </div>
      <div className="flex flex-row-reverse mt-4">
        <StyledPrimaryButton 
          size={"large"}
          type="primary"
          onClick={() => {
            _this.hide_modal();
          }}
        >Close</StyledPrimaryButton>
        <StyledDefaultButton
          size={"large"}
          type="default"
          onClick={() => {
            _this.setState({
              signature_path: "",
              file_signature_path: { url: null, extension: null, filename: "" },
            });
          }}
        >Clear</StyledDefaultButton>
      </div>
    </>
  )
}

const StyledDefaultButton = styled(Button)`
  border-radius: 12px;
  background: #fff;
  color: #004F99;
  padding: 4px 40px;
  height: 50px;
  font-weight: bold;
`

const StyledPrimaryButton = styled(Button)`
  margin-left: 1rem;
  border-radius: 12px;
  background: #004F99;
  color: #fff;
  padding: 4px 40px;
  height: 50px;
  font-weight: bold;
`

const StyledFilePicker = styled(Layout.FilePicker)`
  #drop_signature {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export default SelectFile;