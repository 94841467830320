import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";

const context = "admin";
export default class AdminViewProduct extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Store Merchant List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;
    const { 
      // height, 
      width, 
      // isMobile 
    } = _this.state;

    return (
      <MainLayout
        context={context}
        additional={"store-merchant-list"}
        _this={_this}
      >
        <View style={styles.main_box_content}>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                borderRightWidth: width >= 1250 ? 2 : 0,
                borderBottomWidth: width < 1250 ? 2 : 0,
                paddingRight: width < 1250 ? 0 : 20,
                marginRight: width < 1250 ? 0 : 20,
                paddingBottom: 10,
                marginBottom: width < 1250 ? 10 : 0,
              }}
            >
              <View style={styles.fl_ai}>
                <Image
                  height={100}
                  width={100}
                  tintColor={"transparent"}
                  source={UI.SAMPLE_PRODUCT}
                />

                {UI.box(width < 980 ? 5 : 10)}

                <View>
                  <Text style={styles.patient_name}>Product Name Here</Text>
                  <Text
                    style={{
                      ...styles.patient_name,
                      color: UI.colors.primary,
                      fontSize: 14,
                    }}
                  >
                    Php 000.00
                  </Text>
                  <Text
                    style={{
                      ...styles.patient_name,
                      color: "#454545",
                      fontSize: 14,
                    }}
                  >
                    Status: <Text style={{ color: "#00EB2D" }}>Active</Text>
                  </Text>
                </View>
              </View>

              <View style={styles.date_container}>
                <Image
                  height={30}
                  width={30}
                  tintColor={UI.colors.primary}
                  source={UI.CALENDAR_3}
                />
                <Text style={styles.appointment_text}>
                  Product ID: 00001010101
                </Text>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <UI.Row _this={_this}>
                <UI.Row breakpoint={970} _this={_this} style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.PAYMENT_METHOD}
                    name={"Purchase Limit"}
                    value={"000"}
                  />
                  <UserDetail
                    icon={UI.COMMERCE_INVENTORY}
                    name={"Stock"}
                    value={"000"}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"Date Added"}
                    value={"mm/dd/yyyy"}
                  />
                </UI.Row>
              </UI.Row>
              {UI.box(10)}
              <Text style={styles.details_name}>Product Description</Text>

              <Text
                style={{
                  ...styles.patient_name,
                  color: "#454545",
                  fontSize: 14,
                }}
              >
                Enter product description here. Lorem Ipsum is simply dummy text
                of the printing and typesetting industry. Lorem Ipsum has been
                the industry’s standard dummy text ever since the 1500s.
              </Text>
            </View>
          </UI.Row>
        </View>

        <UI.Row
          _this={_this}
          breakpoint={1080}
          style={{ width: "100%", flexDirection: "row", marginTop: 20 }}
        >
          <View
            style={{
              ...styles.main_box_content,
              padding: 20,
              flex: 1,
              maxWidth: width > 1080 ? 340 : null,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Text style={styles.box_header_text}>Product Stats</Text>
              <View style={{ width: 150 }}>
                <Layout.SelectPicker _this={_this}>
                  <option>Today</option>
                </Layout.SelectPicker>
              </View>
            </View>

            <ProductStat
              image={UI.IC_DASH_1}
              main={"100"}
              color={UI.colors.primary}
              text={"Finished Product Purchases"}
            />

            <ProductStat
              image={UI.IC_DASH_6}
              main={"100"}
              color={"#00EBBE"}
              text={"Total Purchasing Customers"}
            />

            <ProductStat
              image={UI.IC_DASH_11}
              main={"20"}
              color={"#00EB2D"}
              text={"Product Customer Inquiries"}
            />
          </View>

          {UI.box(20)}
          <View
            style={{
              ...styles.main_box_content,
              padding: 0,
              flex: 1,
              alignSelf: width <= 1080 ? "stretch" : "flex-start",
            }}
          >
            <UI.Row
              style={styles.main_container_header}
              breakpoint={1200}
              _this={_this}
            >
              <View
                style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
              >
                <Text style={styles.entry_header}>Only Show</Text>
                {UI.box(10)}
                <View>
                  <Layout.EntryPicker />
                </View>
              </View>

              <InputForm
                _this={_this}
                title={""}
                placeholder={"Enter Keyword"}
                state_name={"search"}
                right={
                  <TouchableOpacity style={styles.search_btn}>
                    <Image
                      height={17}
                      width={17}
                      tintColor={"transparent"}
                      source={UI.SEARCH}
                    />
                  </TouchableOpacity>
                }
              />
            </UI.Row>

            <CustomDatatable
              state_name={"nutrition_programs"}
              _this={_this}
              columns={[
                { ID: "id" },
                { "Customer Name": "customer_name" },
                { "Purchase Date": "purchased_date" },
                { "Purchased With": "purchased_with" },
                { Action: "action" },
              ]}
              data={[
                {
                  id: "001010",
                  customer_name: (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Image
                        height={35}
                        width={35}
                        tintColor={"transparent"}
                        source={UI.SAMPLE_USER}
                      />
                      <Text style={{ marginLeft: 10 }}>Patient Name</Text>
                    </View>
                  ),
                  purchased_date: "mm/dd/yyyy",
                  purchased_with: "Bayad App",
                  action: (
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        onClick={() => {
                          UI.goTo("/admin/view-transaction");
                        }}
                      >
                        <Image
                          height={32}
                          width={32}
                          tintColor={"transparent"}
                          source={UI.VIEW_BTN}
                        />
                      </TouchableOpacity>
                    </View>
                  ),
                },
              ]}
              current_page={1}
              number_of_pages={20}
              on_page={(page, callback) => {}}
            />

            <View
              style={{
                ...styles.no_info_container,
                display: this.state.data.length > 0 ? "none" : "flex",
              }}
            >
              <UI.Row
                breakpoint={820}
                style={{ maxWidth: 360, alignItems: "center" }}
                _this={_this}
              >
                <Image
                  height={100}
                  width={100}
                  tintColor={"transparent"}
                  source={UI.NO_INFO}
                />
                {UI.box(10)}
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#BFBFBF",
                    textAlign: width > 820 ? "left" : "center",
                    marginLeft: width > 820 ? 20 : 0,
                  }}
                >
                  NO CONSULTATION DATA AVAILABLE YET
                </Text>
              </UI.Row>
            </View>
          </View>
        </UI.Row>
      </MainLayout>
    );
  }
}

const ProductStat = (
  props = { image: null, main: "", color: "", text: "" }
) => {
  return (
    <View style={styles.product_stat_item}>
      <Image
        height={70}
        width={70}
        tintColor={"transparent"}
        source={props.image}
      />
      <Text style={{ ...styles.product_stats_text, color: props.color }}>
        {props.main}
      </Text>

      <Text
        style={{
          ...styles.patient_name,
          color: "#535353",
          fontSize: 14,
          textAlign: "right",
          maxWidth: 130,
        }}
      >
        {props.text}
      </Text>
    </View>
  );
};

const UserDetail = (props = { icon: "", name: "", value: "" }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
        flex: 1,
      }}
    >
      <View style={{ ...styles.fl_ai, width: 140 }}>
        <View style={styles.detail_icon}>
          <Image
            height={16}
            width={16}
            tintColor={UI.colors.primary}
            source={props.icon}
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  product_stat_item: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  product_stats_text: {
    color: UI.colors.primary,
    fontWeight: "bold",
    fontSize: 32,
    marginLeft: 15,
    flex: 1,
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
