import moment from "moment";
import UI from "../../shared/Components/UI/js";

class Format {
  create(item, index) {
    // console.log("payment:");
    // console.log(item);
    item.param = " ";
    const created =
      UI.get_date_string_by_date(item.payment?.createdAt ?? item.createdAt) ??
      " ";
    const payment = item?.payment;

    let totalFee = 0;
    if (payment) {
      let { consultationFee = 0, platformFee = 0, VATFee = 0, transactionFee = 0 } = payment?.price

      totalFee = parseInt(consultationFee) + parseInt(platformFee) + parseInt(VATFee) + parseInt(transactionFee); 
      console.log(totalFee)
    }

    // let refund = 0;

    if (parseInt(payment?.penalty?.refundAmount ?? "0")) {
      // refund = payment?.penalty?.refundAmount;
    } else {
      // refund = -totalFee;
      if (totalFee === 0) {
        // refund = "0";
      }
    }

    if (totalFee === 0) {
      totalFee = "0";
    }

    if (item.conferenceMode === "ITS") {

      if(item.patientLogged.length > 0) {
        let firstLogged = item.patientLogged[0]?.in ? item.patientLogged[0]?.in : null;
        let lastLogged = item.patientLogged.at(-1)

        item.patientLoggedIn = firstLogged;
        item.patientLoggedOut = lastLogged?.out || "";

        if (firstLogged && lastLogged?.out) {
          const start = moment(firstLogged);
          const end = moment(lastLogged?.out);
          let duration = moment.duration(end.diff(start));
          let durationMinutes = duration.asMinutes();

          item.patientLoggedOut = moment(lastLogged?.out).format("h:mm a");
          item.patientDuration = durationMinutes.toFixed();
        }

        item.patientLoggedIn = firstLogged ? moment(firstLogged).format("h:mm a") : ""
      }

      if(item.practitionerLogged.length > 0) {
        let firstLogged = item.practitionerLogged[0]?.in ? item.practitionerLogged[0]?.in : null;
        let lastLogged = item.practitionerLogged.at(-1)

        item.practitionerLoggedIn = firstLogged;
        item.practitionerLoggedOut = lastLogged?.out || "";

        if (firstLogged && lastLogged?.out) {
          const start = moment(firstLogged);
          const end = moment(lastLogged?.out);
          let duration = moment.duration(end.diff(start));
          let durationMinutes = duration.asMinutes();

          item.practitionerLoggedOut = moment(lastLogged?.out).format("h:mm a");
          item.practitionerDuration = durationMinutes.toFixed();
        }

        item.practitionerLoggedIn = firstLogged ? moment(firstLogged).format("h:mm a") : ""
      }

      if (item.practitionerLogged.length > 0 && item.patientLogged.length > 0) {
        console.log(item)
      }

    } else {
      item.patientLoggedIn = item.carespanData?._doc?.["Patient In"] ?? "";
      item.patientLoggedOut = item.carespanData?._doc?.["Patient Out"] ?? "";
      item.practitionerLoggedIn = item.carespanData?._doc?.["Clinician In"] ?? "";
      item.practitionerLoggedOut = item.carespanData?._doc?.["Clinician Out"] ?? "";
    }

    let obj = {
      index: index + 1,
      "CreateDate": created ?? " ",
      "CreateTime": UI.getPHTime12HourFormat(item.payment?.createdAt ?? item.createdAt).slice(11, 22) ?? " ",
      "Order ID": item._id ?? " ",
      "Advocacy Code Entered": "",
      "Advocacy": "",
      // "CSorderID": item.carespanAppointmentID ?? " ",
      "OrderType ( Appointment Type )":
        item.appointmentType === "instant" ? "Consult Now" : "Consult Later" ?? " ",
      "Order Status": item.status_order ?? " ",
      // "Trigger Scenario": item.cancelledTrigger ?? " ",
      // "Patient Date and Time Cancelled":  item?.patientCancelledDate ? moment(item.patientCancelledDate).format("YYYY-MM-DD hh:mm a") : "",
      // "Doctor Date and Time Cancelled":  item?.practitionerCancelledDate ? moment(item.practitionerCancelledDate).format("YYYY-MM-DD hh:mm a"): "",
      // "Cancel Reason":  item?.cancelationReason?.text || "",
      "Consult Date": item.consult_date_string ?? " ",
      "Consult Time": UI.get_hhmmss_from_date(item.start) ?? " ",
      "PatientID":  item.patient._id ?? "",
      "PatientCSID":  item.carespanData?._doc?.["Patient MRN"] ?? "",
      "PatientName": item.patient_name ?? " ",
      "RelationshipToUser": item?.patient?.relationshipToUser ?? " ",
      "UserID": item?.patient?.user ?? " ",
      "DoctorID": item.practitioner?._id ?? " ",
      "DoctorCSID": item.practitioner?.carespanID ?? " ",
      "DoctorName": item.doctor_name ?? " ",
      "Specialty":
      UI.DataHelper.carespan.get_specialties_slashed(
        item.practitioner?.fieldSpecialties
      ) ?? " ",
      "DoctorType": item.practitioner?.isMwellPlus
        ? "Contracted"
        : "Consulting" ?? " ",
      "PF": payment?.price?.consultationFee ?? "0",
      "BF": payment?.price?.platformFee ?? "0",
      "TF": payment?.price?.transactionFee ?? "0",
      "TotalFee": totalFee ?? "0",
      "Refund": payment?.penalty?.refundAmount ?? " ",
      "Payment Status": payment?.status?.status === "Voucher" ? "Completed" : payment?.status?.status ?? " ",
      "PaymentID": payment?._id ?? "",
      // "Mode of payment": payment?.method === "Voucher" ? "Voucher" : payment?.payType ?? "" ,
      "Offer Name":  payment?.promo?.voucher?.title ?? "",
      "Offer ID": payment?.promo?.voucher?._id ?? "",
      "Rrn": payment?.referenceNumber ?? "",
      "CSApptID":  item.carespanAppointmentID ?? "",
      "Cancelled":  item.carespanData?._doc?.["Cancelled"] ?? "",
      "Patient_No_Show":  item.appointment_status === "Patient missed" ? "Yes" : "No",
      "Clinician_No_Show":  item.appointment_status === "Practitioner missed" ? "Yes" : "No",
      "State":  item.appointment_status,
      "PatientIn":  item.patientLoggedIn || "",
      "PatientOut":  item.patientLoggedOut || "",
      "PatientDuration":  item.patientDuration || "",
      "DoctorIn":  item.practitionerLoggedIn || "",
      "DoctorOut":  item.practitionerLoggedOut || "",
      "DoctorDuration":  item.practitionerDuration || "",
      "EncounterSigned": item?.encounterCompleted ? UI.getPHTime12HourFormat(item?.encounterCompleted) : "",
      "DateCompleted": item?.encounterCompleted ? UI.getPHTime12HourFormat(item?.encounterCompleted) : "",
      "Decision / User Refund Decision": item?.refundDecision ?? "",
      // "ITS/Carespan Doctor": item?.conferenceMode ?? "Carespan"
    };

    // if(Object.keys(item.carespanData ? item.carespanData : {}).length === 0 || !item.carespanData) {
    //   obj = {
    //     ...obj,
    //     ...csData
    //   }
    // } else {
    //   for(let i in item.carespanData?._doc){
    //     obj['cs.' + i] = item.carespanData?._doc[i]
    //   }
    // }
   
    return obj;
  }

}

const CSVFormat = new Format();
export default CSVFormat;
