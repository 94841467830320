import React from "react";
import { Collapse } from "antd";
import { ChevronUpSvg, ChevronRightSvg } from "../../../../../icons";
import Allergies from "./Allergies";
import MedicalHistory from "./MedicalHistory";
import SurgicalHistory from "./SurgicalHistory";
import MedicationHistory from "./MedicationHistory";
import ImmunizationHistory from "./ImmunizationHistory";
import FamilyHistory from "./FamilyHistory";
import PersonalAndSocialHistory from "./PersonalAndSocialHistory";
import "./css.css";

const { Panel } = Collapse;

const MedicalHistoryTab = ({ medical_history }) => {
  return (
    <div className="mt-6">
      <Collapse
        defaultActiveKey={[]}
        expandIconPosition={"end"}
        bordered={false}
        // accordion={true}
        expandIcon={(panelProps) => {
          return (
            <div
              style={{ backgroundColor: "#42C8FB", display: "flex" }}
              className="h-5 w-5 3xl:h-6 3xl:w-6 rounded-full justify-center items-center"
            >
              <div className="w-3/5">
                {panelProps.isActive ? <ChevronUpSvg /> : <ChevronRightSvg />}
              </div>
            </div>
          );
        }}
      >
        <Panel header="Allergies" key="1">
          <Allergies medical_history={medical_history.allergies} />
        </Panel>
        <Panel header="Medical History" key="2">
          <MedicalHistory medical_history={medical_history.medical_history} />
        </Panel>
        <Panel header="Surgical History" key="3">
          <SurgicalHistory
            surgical_history={medical_history.surgical_history}
          />
        </Panel>
        <Panel header="Medication History" key="4">
          <MedicationHistory
            medication_history={medical_history.medication_history}
          />
        </Panel>
        <Panel header="Immunization History" key="5">
          <ImmunizationHistory
            immunization_history={medical_history.immunization_history}
          />
        </Panel>
        <Panel header="Family History" key="6">
          <FamilyHistory family_history={medical_history.family_history} />
        </Panel>
        <Panel header="Personal and Social History" key="7">
          <PersonalAndSocialHistory
            personal_social_history={medical_history["personal-social_history"]}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default MedicalHistoryTab;
