import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { DeleteConfirmation, Failed, Success } from "../_components/Modal";
import "./css.css";
const context = "admin";

export default class AdminWaitingRoom extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Waiting Room CMS",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],

      // selected_tab: "quicklinks", //can be quicklinks, banner_slide, mwell_banner
      context: context,

      image: UI.TRANSACTION_USER,

      message: "",
      time_limit: "",
      id: "",
    });
  }

  onCreate = () => {
    setTimeout(() => {
      this.execute_resizes(this);
      this.execute_resizes(this);
    }, 300);

    this.get_data();
  };

  get_data = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "admin/waiting_room/",
      params: {},
      isMultiPart: false,
      onFail: (err) => {},
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        console.log(data);

        UI.set_input_value("counter", data[0].counter);
        UI.set_input_value("message", data[0].message);
        this.setState(
          {
            all_data: data[0].imageVideos,
            data: data[0].imageVideos,
            id: data[0]._id,
          },
          () => {
            this.reload();
          }
        );
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  submit = async () => {
    UI.clear_errors();

    const { all_data, message, counter, id } = this.state;

    if (!message) {
      await UI.error_form("message", "Message is required.");
    }
    if (!counter) {
      await UI.error_form("counter", "Time Limit is required.");
    }
    if (all_data.length < 2) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Please add at least two images or videos."}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      await UI.error_form(
        "all_data",
        "Please add at least two images or videos."
      );
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    this.show_loading();
    UI.webRequest({
      method: "put",
      url: "admin/waiting_room/" + id,
      params: {
        message: message,
        counter: counter,
        imageVideos: all_data, // ["imagepath", "imagePath"]
      },
      isMultiPart: false,
      onFail: (err) => {},
      onSuccess: (response) => {
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully Updated the Waiting Room CMS"}
            onDismiss={() => {
              this.get_data();
              this.hide_modal();
            }}
          />
        );
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  process_previews = async () => {
    this.state.all_data.map(async (image_obj, index) => {
      const ext = await UI.get_file_extension(image_obj.imageLink);
      const isVideo = UI.isVideo(ext);

      const image = await UI.download_image(image_obj.imageLink, "ecommerce");
      this.setState(
        {
          ["image_" + index]: image,
          ["image_type_" + index]: isVideo ? "video" : "image",
        },
        () => {
          this.reload_table("files_images");
        }
      );
    });
  };

  delete = async (index) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this item?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();

          const new_data = this.state.all_data.filter((o, i) => i !== index);
          this.update_table(new_data);
        }}
      />
    );
  };

  reload = () => {
    this.reload_table("files_images");
    this.process_previews();
  };

  update_table = (new_data) => {
    this.setState(
      {
        all_data: new_data,
        data: new_data,
      },
      () => {
        this.reload();
      }
    );
  };

  upload = (file, index) => {
    this.show_loading();
    UI.upload_image(
      {
        url: "admin/waiting_room/upload",
        file: file,
        onFail: (err) => {
          this.show_modal(
            <Failed
              title={"Failed"}
              description={"Upload Failed. Application Error."}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        },
        onSuccess: async (path, response) => {
          let img = response.data.d;

          const ext = await UI.get_file_extension(img);

          if (UI.isVideo(ext)) {
            this.show_modal(
              <Success
                title={"Success!"}
                description={
                  "Successfully uploaded a video. Please upload its thumbnail to proceed."
                }
                onDismiss={() => {
                  this.upload_thumbnail(img, index);
                }}
              />
            );
          } else {
            img = { imageLink: img };
            const all_data = this.state.all_data;
            if (index === 0 || index !== null || index !== undefined) {
              const new_data = all_data.map((item, i) => {
                if (i === index) {
                  return img;
                } else {
                  return item;
                }
              });
              this.update_table(new_data);
            } else {
              all_data.push(img);
              this.update_table(all_data);
            }
          }

          this.hide_loading();
        },
      },
      "Uploading..."
    );
  };

  upload_thumbnail = async (path, index) => {
    this.hide_modal();
    let image = await UI.get_image();
    let file = image.file;

    this.show_loading();
    UI.upload_image(
      {
        url: "admin/waiting_room/upload",
        file: file,
        onFail: (err) => {
          this.hide_loading();
        },
        onSuccess: async (p, response) => {
          let img = response.data.d;

          img = { imageLink: img, videoLink: path };
          const all_data = this.state.all_data;
          if (index === 0 || index != null || index !== undefined) {
            const new_data = all_data.map((item, i) => {
              if (i === index) {
                return img;
              } else {
                return item;
              }
            });
            this.update_table(new_data);
          } else {
            all_data.push(img);
            this.update_table(all_data);
          }

          this.hide_loading();
        },
      },
      "Uploading..."
    );
  };

  render() {
    const _this = this;
    const { 
      // height,
       width, 
      //  isMobile, 
      //  selected_tab, 
      //  image 
      } = _this.state;

    // const content_main_container = UI.measure("content_main_container").width;

    return (
      <MainLayout context={context} _this={_this}>
        <View
          style={{
            ...styles.main_box_content,
            padding: 0 /* , marginTop: 20  */,
          }}
        >
          <View style={{ padding: 20 }}>
            <Text style={styles.box_header_text}>Waiting Room CMS</Text>
          </View>

          <UI.Row
            breakpoint={1090}
            style={{ padding: 20, paddingTop: 10 }}
            _this={_this}
          >
            <InputForm
              _this={_this}
              title={"Message"}
              placeholder={"Enter Message Here"}
              state_name={"message"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.description}
              type={"big"}
              height={100}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Time Limit (in minutes)"}
              placeholder={"Enter Time Limit Here"}
              state_name={"counter"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.minutes}
              isNumber={true}
            />
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row>

          <View style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 40 }}>
            <Text style={styles.box_header_text}>Images/Videos</Text>
          </View>

          <UI.Row
            style={styles.main_container_header}
            breakpoint={950}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"files_images"} _this={_this} />
              </View>
            </View>
            <InputForm
              onChange={(e) => {
                UI.search_table("files_images", e, this.state.all_data, "data");
              }}
              is_keyword={true}
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
            />
            <View
              style={{
                alignSelf: width > 950 ? "flex-start" : "center",
                marginTop: 8,
                marginLeft: 10,
              }}
            >
              <Layout.Btn
                color={UI.colors.primary}
                text={"Add Image/Video"}
                onClick={async () => {
                  let image = await UI.get_image(UI.Constants.video_formats);
                  this.upload(image.file, null);
                }}
                height={40}
                width={180}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            state_name={"files_images"}
            _this={_this}
            columns={[
              { Preview: "preview" },
              { "File Name": "filename" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item, index) => {
              let constant_image = (
                <img style={{ height: 20, width: 20 }} src={UI.LOADER_2} alt="loader" />
              );

              if (this.state["image_type_" + index] === "image") {
                constant_image = (
                  <img
                    style={{ height: 35, width: 35 }}
                    src={this.state["image_" + index] ?? UI.PRODUCT_BOX}
                    alt="product box"
                  />
                );
              }

              if (this.state["image_type_" + index] === "video") {
                constant_image = (
                  <video width="35" height="35" controls>
                    <source
                      src={this.state["image_" + index]}
                      type="video/mp4"
                    />
                    <source
                      src={this.state["image_" + index]}
                      type="video/ogg"
                    />
                  </video>
                );
              }

              return {
                preview: (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    {constant_image}
                  </View>
                ),
                filename: UI.container_filename(item.imageLink),
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={async () => {
                        let image = await UI.get_image(
                          UI.Constants.video_formats
                        );
                        this.upload(image.file, index);
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={() => {
                        this.delete(index);
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.DELETE_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
          {UI.box(20)}
          <View
            style={{
              flexDirection: "row-reverse",
              alignItems: "center",
              padding: 20,
            }}
          >
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"SAVE CHANGES"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                window.location.reload();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  menu_main_text: {
    fontSize: 18,
    fontWeight: "bold",
    color: UI.colors.secondary,
    padding: 20,
  },
  active_design: {
    height: "100%",
    width: 4,
    backgroundColor: UI.colors.primary,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  menu_btn: {
    fontSize: 16,
    fontWeight: "bold",
    padding: 10,
    paddingLeft: 20,
  },
  timeline_circle: {
    height: 22,
    width: 22,
    borderRadius: 100,
    backgroundColor: UI.colors.primary,
    borderWidth: 4,
    borderColor: "#E3E3E3",
    marginTop: -10,
    marginLeft: -12,
  },
  timeline_item: {
    height: 100,
    width: "100%",
    borderLeftStyle: "solid",
    borderLeftWidth: 2,
    borderLeftColor: "#E3E3E3",
    marginLeft: 10,
  },
  unused_text: {
    fontSize: 12,
    fontWeight: "bold",
    color: "white",
  },
  unused_box: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 10,
    backgroundColor: "#D3D3D3",
    justifyContent: "center",
    alignItems: "center",
  },
  detail_value: {
    color: "black",
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  detail_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  flex_1: {
    flex: 1,
  },
  detail_left: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  detail_item: {
    flexDirection: "row",
    alignItems: "center",
    width: 370,
    marginBottom: 5,
  },
  line: {
    width: "100%",
    height: 3,
    backgroundColor: "#EFEFEF",
  },
  ai_fl: {
    flexDirection: "row",
    alignItems: "center",
  },
  sub_name: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#898989",
  },
  main_name: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
