import Icon from "@ant-design/icons";
import React from "react";

const ModalCloseSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
  >
    <path
      id="close"
      d="M20242-4748l-10,10,10-10-10-10,10,10,10-10-10,10,10,10Z"
      transform="translate(-20230.5 4759.5)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="3"
    />
  </svg>
);

const ModalCloseIcon = (props) => {
  return <Icon component={ModalCloseSvg} {...props} />;
};

export default ModalCloseIcon;
