import React, { useState, useEffect, useCallback } from "react";
import { Row, Button, Typography, Select } from "antd";
import styled from "styled-components";
import DropdownIcon from "../../../../icons/Dropdown";
import DataTable from "../../../../ComponentsFC/DataTable";
import ServiceApi from "../../../../api_services/Doctors-Portal/DPAppointmentsApi";
import {
  ConsultationNotesSvg,
  MedicalCertificateSvg,
  PrescriptionSvg,
  DoctorsOrderSvg,
} from "../../../../icons";
import UI from "../../../../shared/Components/UI/js";
import {
  ConsultationNotesModal,
  DoctorsOrderModal,
  EPrescriptionModal,
  MedicalCertificateModal,
} from "../EDocs";

const TransformRowsTableHeaderLabel = ({ props }) => (
  <div className="flex flex-col items-center">
    {props.map((text) => (
      <div>{text}</div>
    ))}
  </div>
);

const DEFAULT_PAGE_LIMIT = 5;

const TeleconsultationRecords = ({ patient, showModal, hideModal }) => {
  const [tableData, setTableData] = useState([]);
  const [tableEntries, setTableEntries] = useState(DEFAULT_PAGE_LIMIT);
  const [totalRecords, setTotalRecords] = useState();
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const getTeleconsultationRecords = useCallback(async () => {
    try {
      let params = {
        firstName: patient.name[0].given[0],
        lastName: patient.name[0].family,
        gender: patient.gender,
        birthDate: patient.birthDate,
        page: currentPageNum,
        limit: tableEntries,
      };

      params = Object.keys(params)
        // eslint-disable-next-line array-callback-return
        .map((key) => {
          if (params[key]) {
            return key + "=" + params[key];
          }
        })
        .join("&");

      setIsTableLoading(true);

      const apiResponse = await ServiceApi.getTeleconsultationRecords(params);

      setIsTableLoading(false);
      setTotalRecords(apiResponse.count);
      setTableData(apiResponse.data.map((obj) => ({ ...obj, key: obj._id })));
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNum, tableEntries]);

  useEffect(() => {
    getTeleconsultationRecords();
  }, [getTeleconsultationRecords]);

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handlePaginateTable = (antdSelectedPageNum) => {
    setCurrentPageNum(antdSelectedPageNum);
  };

  const getPractionerAndPatientData = (data) => {
    const { signature, prcLicenseNumber, ptrNumber, s2Number } =
      data.practitioner;

    const { birthDate, gender } = data.patient;

    const appointment = {
      start: data.appointmentDate,
      patient: {
        name: [
          {
            family: data?.patient?.lastName,
            given: data?.patient?.firstName,
            middle: data?.patient?.middleName,
          },
        ],
        birthDate,
        gender
      },
      practitioner: {
        name: [
          {
            family: data?.practitioner?.lastName,
            given: data?.practitioner?.firstName,
            middle: data?.practitioner?.middleName,
          },
        ],
        signature,
        prcLicenseNumber,
        ptrNumber,
        s2Number,
      },
    };

    return appointment;
  };

  const tableColumnHeader = [
    {
      title: "Date",
      dataIndex: "appointmentDate",
      key: "appointmentDate",
      render: (appointmentDate) => {
        return appointmentDate
          ? UI.momentPHTimezone(appointmentDate).format("MM/DD/YYYY")
          : "n/a";
      },
    },
    {
      title: "Doctor Name",
      dataIndex: "practitioner",
      key: "practitioner",
      render: (practitioner) => {
        return (
          practitioner?.firstName +
          " " +
          practitioner?.middleName +
          " " +
          practitioner?.lastName
        );
      },
    },
    {
      title: (
        <TransformRowsTableHeaderLabel props={["Consultation", "Notes"]} />
      ),
      align: "center",
      dataIndex: "clinicalNotes",
      key: "clinicalNotes",
      width: 175,
      render: (notes, data) => {
        if (notes.data) {

          return (
            <Button
              onClick={() => {
                showModal(
                  <ConsultationNotesModal
                    appointment={getPractionerAndPatientData(data)}
                    encounterNotesData={notes.data}
                    onHideModal={() => hideModal()}
                  />
                );
              }}
              ghost={true}
              type="text"
              icon={<ConsultationNotesSvg />}
            />
          );
        } else {
          return "n/a";
        }
      },
    },
    {
      title: (
        <TransformRowsTableHeaderLabel props={["Medical", "Certificate"]} />
      ),
      dataIndex: "medicalCertificate",
      key: "medicalCertificate",
      align: "center",
      width: 175,
      render: (notes, data) => {
        if (notes.data) {
          const encounterNotesData = {
            medicalcertificates: [
              {
                fitToWorkStatus: notes?.data?.fitToWorkStatus,
                fitToWorkOthers: notes?.data?.fitToWorkOthers,
                recommendation: notes?.data?.recommendation,
              },
            ],
          };

          return (
            <Button
              onClick={() => {
                showModal(
                  <MedicalCertificateModal
                    appointment={getPractionerAndPatientData(data)}
                    encounterNotesData={encounterNotesData}
                    onHideModal={() => hideModal()}
                  />
                );
              }}
              ghost={true}
              type="text"
              icon={<MedicalCertificateSvg />}
            />
          );
        } else {
          return "n/a";
        }
      },
    },
    {
      title: "Prescription",
      dataIndex: "prescriptions",
      key: "prescriptions",
      align: "center",
      width: 175,
      render: (notes, data) => {
        if (notes.data) {
          const encounterNotesData = {
            prescriptions: notes?.data,
          };

          return (
            <Button
              onClick={() => {
                showModal(
                  <EPrescriptionModal
                    appointment={getPractionerAndPatientData(data)}
                    encounterNotesData={encounterNotesData}
                    onHideModal={() => hideModal()}
                  />
                );
              }}
              ghost={true}
              type="text"
              icon={<PrescriptionSvg />}
            />
          );
        } else {
          return "n/a";
        }
      },
    },
    {
      title: <TransformRowsTableHeaderLabel props={["Doctors", "Order"]} />,
      dataIndex: "order",
      key: "order",
      width: 175,
      align: "center",
      render: (notes, data) => {
        if (notes.data) {
          const encounterNotesData = {
            consultations: [
              {
                assessment: data?.clinicalNotes?.data?.assessment,
                followupSchedule: data?.clinicalNotes?.data?.followupSchedule,
              },
            ],
            orders: notes?.data,
          };
          return (
            <Button
              onClick={() => {
                showModal(
                  <DoctorsOrderModal
                    appointment={getPractionerAndPatientData(data)}
                    encounterNotesData={encounterNotesData}
                    onHideModal={() => hideModal()}
                  />
                );
              }}
              ghost={true}
              type="text"
              icon={<DoctorsOrderSvg />}
            />
          );
        } else {
          return "n/a";
        }
      },
    },
  ];

  return (
    <TableBox>
      <Toolbar>
        <div className="text-blue-300 text-xl">Online Consultation Records</div>
        <div className="flex items-center">
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue={DEFAULT_PAGE_LIMIT.toString()}
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="5">5 Entries</Select.Option>
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>
        </div>
      </Toolbar>
      <DataTable
        tableColumnHeader={tableColumnHeader}
        dataSource={tableData}
        defaultPageSize={tableEntries}
        pageSizeOptions={["5", "10", "30"]}
        tableEntries={tableEntries}
        isBackendPaginated={true}
        totalRecords={totalRecords}
        onPaginate={handlePaginateTable}
        loading={isTableLoading}
        scroll={{
          x: 600,
        }}
      />
    </TableBox>
  );
};

export default TeleconsultationRecords;

// CSS Styled Components ----------------------

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 3rem 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  // width: 60vw;
  min-width: 100%;
  .ant-table-cell {
    font-size: 20px;
  }

  /* // 2ND COLUMN OF TABLE
  .ant-table-row .ant-table-cell:nth-child(2) {
    white-space: pre;
  } */
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const Toolbar = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 1rem;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;
