import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import LaboratoryTable from "./LaboratoryTable";
// import "./css.css";

const context = "admin";

export default class AdminLaboratoryTransactionList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Transaction",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <LaboratoryTable _this={_this}/>
      </MainLayout>
    );
  }
}