import React from "react";
import styled from "styled-components";
import { Row, Col, Divider, Image } from "antd";
import UI from "../../../shared/Components/UI/js";
import Container from "../../ui/Container";
import ContainerLabelSecondary from "../../ui/ContainerLabelSecondary";
import FieldLabel from "../../ui/forms/FieldLabel";

const DiskartechHeader = ({ membersCount }) => {

  return (
    <StyledContainer>
      <Row style={{ alignItems: "center" }}>
        <HeaderTitle>
          <span>Diskartech</span>
        </HeaderTitle>

        <VerticalDivider type="vertical" />

        <Col style={{ padding: "6.5px 0 9.5px 0" }}>
          <StatsTitle>Data Statistics</StatsTitle>
          <Row style={{ alignItems: "center" }}>
            <Image
              height={60}
              width={60}
              src={UI.IC_DASH_1}
              preview={false}
              alt="N/A"
            />

            <Col style={{ marginLeft: 10, display: "flex", flexDirection: "column", gap: 5 }}>
              <NumOfMembers>
                {UI.pad(membersCount, 3)}
              </NumOfMembers>
              <FieldLabel style={{ color: "black" }}>
                Total Members
              </FieldLabel>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default DiskartechHeader;

const StyledContainer = styled(Container)`
  padding: 20px 20px 20px 20px;
`;

const HeaderTitle = styled(Row)`
  padding-left: 10px;
  min-width: 149px; //-20px of divider
  max-width: 149px; //-20px of divider
  font-size: 18px;
`;

const StatsTitle = styled(ContainerLabelSecondary)`
  color: #02488d;
  font-size: 18px !important;
  margin-bottom: 10px;
`;

const NumOfMembers = styled(ContainerLabelSecondary)`
  color: #00cbff !important;
  font-size: 20px !important;
  margin-bottom: 0 !important;
`;

const VerticalDivider = styled(Divider)`
  background-color: #e5e7eb;
  width: 2;
  height: 100%;
  margin-left: 30px;
  margin-right: 30px;
`;
