import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Row, Col } from "antd";
import PatientReferralModalBannerIcon from "../../../icons/PatientReferralModalBanner";
import SuccessModalCheckIcon from "../../../icons/SuccessModalCheck";
import DeclinedModalCrossIcon from "../../../icons/DeclinedModalCross";
import ProcessFailedModalCrossIcon from "../../../icons/ProcessFailedModalCross";
import CustomModal from "../../ui/modals/CustomModal";
import ProcessFailedModal from "../../ui/modals/ProcessFailedModal";
import DPReferralApi from "../../../api_services/Doctors-Portal/DPReferralApi";

const PatientReferralModal = (props) => {
  const { open, onChangeOpenPatientReferral, data, refreshNotificationsBell } =
    props;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isDeclinedModalOpen, setIsDeclinedModalOpen] = useState(false);

  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const [processFailedBody, setProcessFailedBody] = useState(<></>);

  const [patientDetails, setPatientDetails] = useState();

  useEffect(() => {
    if (open) {
      setIsConfirmationModalOpen(true);
    }
  }, [open]);

  useEffect(() => {
    // console.log("PATIENT DETAILS: ", data);
    setPatientDetails(data);
  }, [data]);

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    onChangeOpenPatientReferral();
  };
  const handleCloseModal = () => {
    setIsFailedModalOpen(false);
  };

  const handleAcceptPatientReferral = async () => {
    try {
      setIsConfirmationModalOpen(false);

      const apiResponse = await DPReferralApi.acceptOrDeclineReferral(
        patientDetails?._id,
        "Approved"
      );
      // console.log("ACCEPT REFERRAL API(POST): ", apiResponse);
      //Then closes patient referral modal and clears patient details 👇
      onChangeOpenPatientReferral();
      if (apiResponse?.status === 200 || apiResponse?.status === 201) {
        refreshNotificationsBell();
        setIsSuccessModalOpen(true);
      } else if (apiResponse?.status === 401) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {
              "Accept/Decline Failed. Referral Id was not found. Please try again. (401)"
            }
          </>
        );
      } else if (apiResponse?.status === 403) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {
              "Accept/Decline Failed. Forbidden Request. Please try again. (403)"
            }
          </>
        );
      } else if (apiResponse?.status === 404) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {
              "Accept/Decline Failed. API resource not found. Please try again. (404)"
            }
          </>
        );
      } else if (apiResponse?.status === 500) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>{"Something went wrong!. Server Error. Please try again. (500)"}</>
        );
      } else if (
        apiResponse?.status > 503 ||
        apiResponse === undefined ||
        apiResponse?.status === undefined
      ) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {
              "Service Unavailable/Forbidden Request. Please try again later. (503/403)"
            }
          </>
        );
      }
    } catch (err) {
      console.err(err);
    }
  };
  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const handleDeclinePatientReferral = async () => {
    try {
      setIsConfirmationModalOpen(false);

      const apiResponse = await DPReferralApi.acceptOrDeclineReferral(
        patientDetails?._id,
        "Disapproved"
      );
      // console.log("DECLINE REFERRAL API(POST): ", apiResponse);
      //Then closes patient referral modal and clears patient details 👇
      onChangeOpenPatientReferral();
      if (apiResponse?.status === 200 || apiResponse?.status === 201) {
        refreshNotificationsBell();
        setIsDeclinedModalOpen(true);
      } else if (apiResponse?.status === 401) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {
              "Accept/Decline Failed. Referral Id was not found. Please try again. (401)"
            }
          </>
        );
      } else if (apiResponse?.status === 403) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {
              "Accept/Decline Failed. Forbidden Request. Please try again. (403)"
            }
          </>
        );
      } else if (apiResponse?.status === 404) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {
              "Accept/Decline Failed. API resource not found. Please try again. (404)"
            }
          </>
        );
      } else if (apiResponse?.status === 500) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>{"Something went wrong!. Server Error. Please try again. (500)"}</>
        );
      } else if (
        apiResponse?.status > 503 ||
        apiResponse === undefined ||
        apiResponse?.status === undefined
      ) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {
              "Service Unavailable/Forbidden Request. Please try again later. (503/403)"
            }
          </>
        );
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleCloseDeclinedModal = () => {
    setIsDeclinedModalOpen(false);
  };

  return (
    <>
      <ProcessFailedModal
        title={"PROCESS FAILED"}
        banner={<ProcessFailedModalCrossIcon />}
        description={processFailedBody}
        buttonText={"Dismiss"}
        buttoncolor={"#004f99"}
        visible={isFailedModalOpen}
        width={530}
        // visible={true}
        onOk={handleCloseModal}
        onCancel={handleCloseModal} // x and cancel button
      />
      <StyledCustomModal
        title={"Patient Referral"}
        headercolor={"#004F99"}
        banner={<PatientReferralModalBannerIcon />}
        description={<LeftTitle>{"Patient Information"}</LeftTitle>}
        extrajsx={
          <XtraJSXWrapper>
            <FieldRow>
              <Col span={11} style={{ textAlign: "left" }}>
                Patient Name:
              </Col>
              <Col>
                {patientDetails?.patientInfo[0].fullname ??
                  "No patient name filed"}
              </Col>
            </FieldRow>
            <FieldRow>
              <Col span={11} style={{ textAlign: "left" }}>
                Mobile Number:
              </Col>
              <Col>
                {patientDetails?.patientInfo[0].phoneNumber !== null
                  ? `0${patientDetails?.patientInfo[0].phoneNumber}`
                  : "No mobile number filed"}
              </Col>
            </FieldRow>

            <FieldRow>
              <Col span={11} style={{ textAlign: "left" }}>
                Email Address:
              </Col>
              <Col>
                {patientDetails?.patientInfo[0].email ??
                  "No email address filed"}
              </Col>
            </FieldRow>
            <FieldRow>
              <Col span={11} style={{ textAlign: "left" }}>
                Reason for Referral:
              </Col>
              <Col>
                {patientDetails?.reasonForReferral ??
                  "No referral reason filed"}
              </Col>
            </FieldRow>
            <FieldRow>
              <Col span={11} style={{ textAlign: "left" }}>
                Chief Complaint:
              </Col>
              <Col>
                {patientDetails?.chiefComplaint ?? "No chief complaint filed"}
              </Col>
            </FieldRow>
            <FieldRow>
              <Col span={11} style={{ textAlign: "left" }}>
                Pertinent Information:
              </Col>
              <Col>
                {patientDetails?.pertinentInformation ??
                  "No pertinent information filed"}
              </Col>
            </FieldRow>
          </XtraJSXWrapper>
        }
        buttonText={"YES"}
        buttoncolor={"#004F99"}
        visible={isConfirmationModalOpen}
        // visible={true}
        width={710}
        // onOk={handleCloseModal}
        onCancel={handleCloseConfirmationModal} // x and cancel button
        footer={[
          <FooterWrapper>
            <StyledUpperFooter>
              <StyledAcceptButton onClick={handleAcceptPatientReferral}>
                ACCEPT
              </StyledAcceptButton>
              <StyledDeclineButton onClick={handleDeclinePatientReferral}>
                DECLINE
              </StyledDeclineButton>
            </StyledUpperFooter>
          </FooterWrapper>,
        ]}
      />
      <StyledAcceptModal
        title={"Confirmation"}
        headercolor={"#64C31E"}
        banner={<SuccessModalCheckIcon />}
        description={"Patient referral successfully accepted!"}
        buttonText={"Continue"}
        buttoncolor={"#004f99"}
        visible={isSuccessModalOpen}
        // visible={true}
        width={530}
        onCancel={handleCloseSuccessModal} // x and cancel button
        footer={[
          <FooterWrapper>
            <ContinueButton onClick={handleCloseSuccessModal}>
              Continue
            </ContinueButton>
          </FooterWrapper>,
        ]}
      />
      <StyledDeclineModal
        title={"Confirmation"}
        headercolor={"#E00017"}
        banner={<DeclinedModalCrossIcon />}
        description={"Patient referral successfully declined!"}
        buttonText={"Continue"}
        buttoncolor={"#004f99"}
        visible={isDeclinedModalOpen}
        // visible={true}
        width={530}
        onCancel={handleCloseDeclinedModal} // x and cancel button
        footer={[
          <FooterWrapper>
            <ContinueButton onClick={handleCloseDeclinedModal}>
              Continue
            </ContinueButton>
          </FooterWrapper>,
        ]}
      />
    </>
  );
};

export default PatientReferralModal;

const StyledCustomModal = styled(CustomModal)`
  .ant-modal-close {
    visibility: hidden;
  }
`;

const StyledAcceptModal = styled(CustomModal)`
  .ant-modal-body {
    padding: 55px 42px 20px 42px;
  }
`

const StyledDeclineModal = styled(CustomModal)`
  .ant-modal-body {
    padding: 55px 42px 20px 42px;
  }
`

const LeftTitle = styled.div`
  text-align: left;
`;

const XtraJSXWrapper = styled.div`
  margin-bottom: 50px;
  width: 100%;
`;

const FieldRow = styled(Row)`
  /* height: 40px; */
  width: 100%;
  flex-flow: nowrap;
  text-align: left;
`;

const FooterWrapper = styled.div``;

const StyledUpperFooter = styled(Row)`
  align-items: center;
  justify-content: center;
`;

const ContinueButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 26px;
  font-size: 23px;
  font-family: Poppins;
  font-weight: 500; //medium
  color: #ffffff;
  background-color: #004f99;

  border: 2px solid #004f99;
  border-radius: 12px;
  width: 189px;
  height: 70px;

  &:hover {
    background-color: #004f99;
    color: #ffffff;
  }

  &:focus {
    background-color: #004f99;
    color: #ffffff;
  }

  &:active {
    background-color: #004f99;
    color: #ffffff;
  }
`;

const StyledAcceptButton = styled(Button)`
  font-size: 23px;
  font-family: Poppins;
  font-weight: 500; //medium
  color: #ffffff;
  background-color: #004f99;

  border: 2px solid #004f99;
  border-radius: 12px;
  width: 189px;
  height: 70px;

  &:hover {
    background-color: #004f99;
    color: #ffffff;
  }

  &:focus {
    background-color: #004f99;
    color: #ffffff;
  }

  &:active {
    background-color: #004f99;
    color: #ffffff;
  }
`;

const StyledDeclineButton = styled(Button)`
  margin-left: 20px !important;
  font-size: 23px;
  font-family: Poppins;
  font-weight: 500; //medium
  color: #e00017;
  background-color: #ffffff;

  border: 2px solid #e00017;
  border-radius: 12px;
  width: 189px;
  height: 70px;

  &:hover {
    color: #e00017;
    border: 2px solid #e00017;
    background-color: #ffffff;
  }

  &:focus {
    color: #e00017;
    background-color: #ffffff;
  }

  &:active {
    color: #e00017;
    background-color: #ffffff;
  }
`;
