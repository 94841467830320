import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import "./css.css";
import AdminTransactionsTable from "./AdminTransactionsTable";

const context = "admin";
export default class AdminTransactions extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Transactions",
      is_drawer_open: false,
    });
  }

  onCreate = async () => {};


  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <AdminTransactionsTable _this={this} />
      </MainLayout>
    );
  }
}