import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import // Shadow,
// StyleSheet,
// Text,
// View,
"../../shared/custom-react-native";
import MainLayout from "../_components/MainLayout";
import BookedConsultation from "./BookedConsultation";
import "./css.css";
import OnCallConsultation from "./OnCallConsultation";
import Statistics from "./Statistics";

const context = "transaction";

export default class TransactionDashboard extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Dashboard",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;
    // const {
    //    height,
    //     width,
    //      isMobile
    //     } = _this.state;

    // **Commented out, currently not used
    // **Alternatively you can use media queries (e.g. @media only screen (min-width: 470px){ cssSelector { cssPropsHere: cssValue }})
    // let add_style = {};
    // if (width <= 470) {
    //   add_style = { flexDirection: "row" };
    // }

    return (
      <MainLayout context={context} _this={_this}>
        <Statistics _this={_this} />

        <BookedConsultation _this={_this} />
        <OnCallConsultation _this={_this} />
      </MainLayout>
    );
  }
}

// **Commented out, currently unused
// const Legend = (props = { text: "", color: "" }) => {
//   return (
//     <View style={styles.legend_container}>
//       <View
//         style={{ ...styles.dot_color, backgroundColor: props.color }}
//       ></View>
//       <Text style={styles.legend_text}>{props.text}</Text>
//     </View>
//   );
// };

// const styles = StyleSheet.create({
//   legend_text: {
//     color: "#535353",
//     fontWeight: "bold",
//     fontSize: 12,
//     marginLeft: 10,
//   },
//   legend_container: {
//     flexDirection: "row",
//     alignItems: "center",
//     marginRight: 10,
//   },
//   dot_color: {
//     height: 12,
//     width: 12,
//     borderRadius: 10,
//     backgroundColor: UI.colors.primary,
//   },
//   main_box_content: {
//     borderRadius: 5,
//     backgroundColor: "white",
//     ...Shadow._3(),
//   },
//   box_header_subtitle: {
//     fontSize: 12,
//     fontWeight: "bold",
//     color: "#C9C9C9",
//     alignSelf: "flex-end",
//   },
//   box_header_text: {
//     fontSize: 16,
//     fontWeight: "bold",
//     color: UI.colors.secondary,
//     flex: 1,
//   },
//   header_2: {
//     color: "#535353",
//     fontSize: 14,
//     fontWeight: "bold",
//   },
//   fade_header: {
//     fontWeight: "bold",
//     fontSize: 16,
//     color: "#B7B7B7",
//   },
//   main_header: {
//     color: UI.colors.primary,
//     fontSize: 25,
//     fontWeight: "bold",
//   },
//   header_1: {
//     fontSize: 14,
//     color: "#535353",
//     fontWeight: "bold",
//   },
//   earnings_container: {
//     borderRadius: 5,
//     backgroundColor: "white",
//     padding: 20,
//     ...Shadow._3(),
//     flexDirection: "row",
//     maxHeight: 180,
//   },
// });

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
