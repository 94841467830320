import React from "react";

const PersonalAndSocialHistory = ({ personal_social_history }) => {
  return (
    <div className="p-8 border rounded-xl font-medium">
      <div className="text-blue-300 text-sm 3xl:text-lg">
        Personal and Social History
      </div>
      {personal_social_history.map((history) => (
        <div className="grid grid-flow-col gap-4">
          <div className="flex">
            <ul className="text-sm 3xl:text-base text-gray-1200">
              <li className="mt-4">Smoking</li>
              <li className="mt-4">Alcohol Beverage Intake</li>
              <li className="mt-4">Illicit Drug Use</li>
            </ul>
            <ul className="text-sm 3xl:text-base text-blue-300 ml-8">
              <li className="mt-4">{history.smoking || "-"}</li>
              <li className="mt-4">{history.alcohol || "-"}</li>
              <li className="mt-4">{history.drugs || "-"}</li>
            </ul>
          </div>
          <div className="flex">
            <ul className="text-sm 3xl:text-base text-gray-1200">
              <li className="mt-4">Diet</li>
              <li className="mt-4">Physical Activity</li>
              <li className="mt-4">Other/Remarks</li>
            </ul>
            <ul className="text-sm 3xl:text-base text-blue-300 ml-8">
              <li className="mt-4">{history.diet || "-"}</li>
              <li className="mt-4">{history.physical_activity || "-"}</li>
              <li className="mt-4">{history.remarks || "-"}</li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PersonalAndSocialHistory;
