import React from "react";

import Layout from "../../../../modules/_components/Layout";
import { Text, View } from "../../../../shared/custom-react-native";
import UI from "../../../../shared/Components/UI/js";
import { DefaultModal } from "../../../../modules/_components/Modal";

import DigitalESignatureTabs from "./Tabs";

const DigitalESignature = ({ _this }) => {

  return (
    <View style={{}}>
      <Text
        style={{
          color: UI.colors.primary,
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        eSignature{" "}
        <span className="text-red-500"> *</span>
      </Text>
      {UI.box(10)}
      <Layout.Btn
        color={UI.colors.primary}
        onClick={() => 
          _this.show_modal(
            <DefaultModal
              title={"ESIGNATURE"}
              onDismiss={() => _this.hide_modal()}
            >
              <DigitalESignatureTabs _this={_this}/>
            </DefaultModal>
          )
        }
        width={300}
        text={"Click here to add esignature"}
      />
    </View>
  )
}

export default DigitalESignature;