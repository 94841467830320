import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";
const context = "admin";

export default class AdminViewAppointment extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Appointments",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;
    const { 
      // height,
       width,
        // isMobile
      } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                borderRightWidth: width >= 1250 ? 2 : 0,
                borderBottomWidth: width < 1250 ? 2 : 0,
                paddingRight: width < 1250 ? 0 : 20,
                marginRight: width < 1250 ? 0 : 20,
                paddingBottom: 10,
                marginBottom: width < 1250 ? 10 : 0,
                flex: 1,
              }}
            >
              <View style={styles.fl_ai}>
                <Image
                  height={120}
                  width={120}
                  tintColor={"transparent"}
                  source={UI.SAMPLE_USER}
                />

                {UI.box(width < 980 ? 5 : 10)}

                <View>
                  <Text style={styles.patient_name}>Patient Name Here</Text>
                  <Text style={styles.patient_details}>
                    emailaddress@email.com
                  </Text>
                  <Text style={styles.patient_details}>+63 995 123 4567</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                ...styles.border_style,
                borderBottomWidth: width < 1250 ? 2 : 0,
                paddingRight: width < 1250 ? 0 : 20,
                marginRight: width < 1250 ? 0 : 20,
                paddingBottom: 10,
                marginBottom: width < 1250 ? 10 : 0,
                flex: 1,
              }}
            >
              <View style={styles.fl_ai}>
                <Image
                  height={120}
                  width={120}
                  tintColor={"transparent"}
                  source={UI.DOCTOR_USER}
                />

                {UI.box(width < 980 ? 5 : 10)}

                <View>
                  <Text style={styles.patient_name}>Doctor Name Here</Text>
                  <Text style={styles.patient_details}>Doctor's Specialty</Text>
                  <Text style={styles.patient_details}>Hospital Name Here</Text>
                </View>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <UI.Row
                breakpoint={980}
                breakpoint_2={720}
                breakpoint_3={590}
                _this={_this}
              >
                <View style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.CONSULT_PRICE}
                    name={"Consult Price"}
                    value={"Php 000.00"}
                  />
                  <UserDetail
                    icon={UI.PAYMENT_METHOD}
                    name={"Payment Method"}
                    value={"Bayad App"}
                  />
                  <UserDetail
                    icon={UI.BOOKING_TYPE}
                    name={"Booking Type"}
                    value={"Booked"}
                  />
                  <UserDetail
                    icon={UI.BOOKING_DATE}
                    name={"Booking Date"}
                    value={"mm/dd/yyyy"}
                  />
                  <UserDetail
                    icon={UI.BOOKING_TIME}
                    name={"Booking Time"}
                    value={"00:00 am"}
                  />
                </View>
              </UI.Row>
            </View>
          </UI.Row>
        </View>

        <UI.Row
          _this={_this}
          style={{ width: "100%", marginTop: 20 }}
          breakpoint={1150}
        >
          <View style={{ ...styles.main_box_content, padding: 20, flex: 1.2 }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Text style={styles.box_header_text}>Clinical Notes</Text>
              <View style={{ flex: 1 }}></View>

              <Layout.Btn
                color={UI.colors.primary}
                text={"Download File"}
                onClick={() => {}}
                height={40}
              />
            </View>

            {UI.box(20)}

            <Title text={"Doctor's Notes:"} />
            <Description
              text={
                "Doctor’s Notes: Enter doctors notes here. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s."
              }
            />

            {UI.box(20)}
            <Title text={"Doctor's Prescription:"} />
            <Description
              text={"Doctor’s Precription: Amoxicillin + Clavulanic Acid"}
            />
            <Description text={"(Co-Amoxiclav) 21 Pieces 500/125 mg/tab"} />
            <Description text={"Take one with food every 8 Hours for 7 Days"} />
            <Description
              text={
                "Paracetamol 5 Pieces 500 mg/tab Take one with food every 4 Hours as needed for fever (temp > 37.8 C)"
              }
            />
          </View>
          {UI.box(20)}
          <View style={{ ...styles.main_box_content, padding: 20, flex: 1 }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Text style={styles.box_header_text}>Medical Documents</Text>
              <View style={{ width: 150 }}>
                <Layout.SelectPicker _this={_this}>
                  <option>Filter By</option>
                </Layout.SelectPicker>
              </View>
            </View>

            <DocumentItem />
            <DocumentItem />
            <DocumentItem />
            <DocumentItem />
          </View>
        </UI.Row>
      </MainLayout>
    );
  }
}

const DocumentItem = (props = { title: "", description: "" }) => {
  return (
    <View style={styles.document_item}>
      <Image
        height={45}
        width={45}
        tintColor={"transparent"}
        source={UI.TRANSACTION_ITEM}
      />
      {UI.box(10)}
      <View>
        <Text style={styles.details_value}>File Type Here</Text>
        <Text style={styles.details_name}>
          PDF File 00kb | Uploaded dd/mm/yyyy 00:00 am
        </Text>
      </View>
      <View style={{ flex: 1 }}></View>
      <TouchableOpacity style={{ alignSelf: "center" }}>
        <Image
          height={25}
          width={25}
          tintColor={UI.colors.primary}
          source={UI.CHEVRON_RIGHT}
        />
      </TouchableOpacity>
    </View>
  );
};

const Description = (props = { text: "" }) => {
  return (
    <Text
      style={{
        ...styles.box_header_text,
        fontSize: 14,
        color: "#333333",
        flex: null,
      }}
    >
      {props.text}
    </Text>
  );
};

const Title = (props = { text: "" }) => {
  return (
    <Text
      style={{
        ...styles.box_header_text,
        fontSize: 14,
        flex: null,
        marginBottom: 15,
      }}
    >
      {props.text}
    </Text>
  );
};

const UserDetail = (props = { icon: "", name: "", value: "" }) => {
  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}
    >
      <View style={{ width: 150, ...styles.fl_ai }}>
        <View style={styles.detail_icon}>
          <Image
            height={16}
            width={16}
            tintColor={UI.colors.primary}
            source={props.icon}
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  document_item: {
    paddingTop: 20,
    paddingBottom: 20,
    flexDirection: "row",
    width: "100%",
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#EBEBEB",
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
