import ServiceApi from "../service";
import UI from "../../shared/Components/UI/js";
class DPMwellMDApi {
  baseURL = "doctors_onboarding";

  async getPatientMedicalRecords(params) {
    const response = await ServiceApi.get(this.baseURL + `/mwell-md/patient-medical-records?${params}`);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }
}

export default new DPMwellMDApi();
