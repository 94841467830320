// import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  Shadow,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
import { PRCValidator } from "../onboard-create-user/PRCValidator";
import Layout, { PreviewFile } from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { CustomFailed, Failed, Success } from "../_components/Modal";
import Affiliation from "./Affiliation";
import "./css.css";
import FifthForm from "./FifthForm";
import FirstForm from "./FirstForm";
import AddressInformation from "./AddressInformation";
import MOA from "./MOA";
import ProfilePicture from "./ProfilePicture";
import SecondForm from "./SecondForm";
import ThirdForm from "./ThirdForm";
import FourthForm from "./FourthForm";
import PTR from "./PTR";

const context = "onboard";

const Constants = new APIConstants();
export default class OnboardUserProfile extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "User Profile",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
      isAffiliated: false,

      doctor: {},
      onboard_data: {},

      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      contact_no: "",
      dob: "",
      gender: "male",
      specialty: [],
      languanges: [],
      languange: [],
      hmo_affiliations: [],
      hmo_affiliation: [],
      qualification_date: "",
      prc_number: "",
      license_start_date: "",
      license_end_date: "",

      years_experience: "",

      // floor_number: "",
      house_number: "",
      street_name: "",
      barangay: "",
      city_municipality: "",
      province: "",
      zip_code: "",

      license_photo_file: null,
      id: "",

      profile_picture_path: "",
      prc_license_path: "",
      signature_path: "",
      image: "",
      image_file: null,

      nutrition_price: "",

      picture: "",
      prcLicensePhoto: "",
      mpc: "",
      or: "",
      cor: "",

      profile_picture: "",

      doctorType: "",
      consultation_fee: "",

      bankName: "",
      bankBranch: "",
      bankAccountName: "",
      bankAccountNumber: "",

      moa_file: null,
      moa_path: "",

      mpc_file: null,
      mpc_path: "",

      downloaded_prc_license_photo: { url: "", extension: "" },
      downloaded_moa: { url: "", extension: "" },
      downloaded_mpc: { url: "", extension: "" },
      downloaded_or: { url: "", extension: "" },
      downloaded_cor: { url: "", extension: "" },
      downloaded_marketing_consent: { url: "", extension: "" },
      downloaded_ptr: { url: "", extension: "" },
      downloaded_s2: { url: "", extension: "" },

      file_signature_path: { url: null, extension: null, filename: "" },

      file_marketing_consent_path: { url: null, extension: null, filename: "" },
      file_user_profile_path: { url: null, extension: null, filename: "" },

      specializations: [],
      hospitals: [],

      hospital: [],

      is_check_marketing_consent: false,
      is_check_moa_user: false,

      medicalAssociation: [],
      confirmMarketingConsent: false,
      marketing_consent_path: "",

      ptrNumber: "",
      ptr: "",

      s2Number: "",
      s2: "",
    });
  }

  onCreate = async () => {
    // this.load_doctor();
    this.load_specialization();
    this.load_languanges();
    this.load_hospitals();
    this.load_hmo_affiliation();
    this.load_medical_associations();
  };

  load_medical_associations = async () => {
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/medical-associations",
      onFail: (err) => {
        // console.log(err);
      },
      onSuccess: (response) => {
        const data = UI.extract_data(response);

        this.setState({
          medicalAssociations: data,
        });
      },
      onFinish: async () => {},
    });
  };

  load_hospitals = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/hospitals",
      params: {},
      onFail: (err) => {
        console.log(err);
      },
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        this.setState({
          hospitals: data.data,
        });
      },
      onFinish: async () => {
        this.load_doctor();
        // this.hide_loading();
      },
    });
  };

  load_specialization = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/doctors/specializations",
      params: {},
      onFail: (err) => {
        console.log(err);
      },
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        const new_data = data.sort(UI.dynamicSort("label"));

        this.setState(
          {
            specializations: new_data,
          },
          () => {
            this[`${"reload_multiple_option_"}${"specialty"}`]();
          }
        );
      },
      onFinish: async () => {
        // this.load_doctor();
        // this.hide_loading();
      },
    });
  };

  load_hmo_affiliation = async () => {
    this.setState({
      // hmo_affiliations: data.data,
      hmo_affiliations: Constants.hmo_affiliations,
    });
    // this.show_loading();
    // UI.webRequest({
    //   method: "get",
    //   url: "doctors_onboarding/hmo_affiliation",
    //   params: {},
    //   onFail: (err) => {
    //     // console.log(err);
    //   },
    //   onSuccess: (response) => {
    //     const data = UI.extract_data(response);
    //     this.setState({
    //       hmo_affiliations: data.data,
    //     });
    //   },
    //   onFinish: async () => {
    //     this.load_doctor();
    //     this.hide_loading();
    //   },
    // });
  };

  load_languanges = async () => {
    this.setState({
      // languanges: data.data,
      languanges: Constants.languanges,
    });
    // this.show_loading();
    // UI.webRequest({
    //   method: "get",
    //   url: "doctors_onboarding/languanges",
    //   params: {},
    //   onFail: (err) => {
    //     // console.log(err);
    //   },
    //   onSuccess: (response) => {
    //     const data = UI.extract_data(response);

    //     // console.log(data);

    //     this.setState({
    //       languanges: data.data,
    //     });
    //   },
    //   onFinish: async () => {
    //     this.load_doctor();
    //     this.hide_loading();
    //   },
    // });
  };

  load_doctor = () => {
    const _this = this;
    if (_this.layout) {
      if (_this.layout.set_onboard_data) {
        _this.layout.set_onboard_data();

        setTimeout(() => {
          if (
            _this.layout.state.onboard_data.isDoctorsOnboardingApproved ===
            "Disapproved"
          ) {
            this.show_modal(
              <CustomFailed
                title={"ADMIN REMARKS"}
                description_title={
                  "Your account has been disapproved due to reason/s below:"
                }
                description={
                  _this.layout.state.onboard_data.remarks ??
                  "You have been disapproved by the admin."
                }
                onDismiss={() => {
                  this.hide_modal();
                }}
              />
            );
          }
        }, 10);
      }
    }

    const onboard_data = _this.layout.state.onboard_data;
    const data = mem.get(UI.Constants.USER_DOCTOR);
    if (data) {
      const doctor = JSON.parse(data);
      this.setState(
        {
          doctor,
          onboard_data,
        },
        () => {
          this.process_doctor(onboard_data);
        }
      );
    }
  };

  process_doctor = async (onboard_data) => {
    const { doctor } = this.state;
    // console.log("DOCTOR DATA: ", doctor);
    const name_obj = UI.DataHelper.carespan.get_name_obj(doctor.name);
    const {
      picture,
      prcLicensePhoto,
      signature,
      moaPath,
      mpc,
      certificateOfRegistration,
      officalReceipt,
      medicalAssociations,
      marketingConsentPath,
      ptrNumber,
      ptr,
      s2Number,
      s2,
    } = doctor;

    let { hospital, hospitals } = doctor;
    if (!hospital) {
      hospital = [];
    }
    if (!hospitals) {
      hospitals = [];
    }
    if (typeof hospital == "string") {
      hospital = [hospital];
    }
    if (typeof hospitals == "string") {
      hospitals = [hospitals];
    }

    let image = await UI.download_image(picture, "doctors-onboarding");

    if (doctor.financialInformation) {
      UI.set_input_value("bankName", doctor.financialInformation.bankName);
      UI.set_input_value("bankBranch", doctor.financialInformation.bankBranch);
      UI.set_input_value(
        "bankAccountName",
        doctor.financialInformation.bankAccountName
      );
      UI.set_input_value(
        "bankAccountNumber",
        doctor.financialInformation.bankAccountNumber
      );
    }

    UI.set_input_value("first_name", name_obj.first_name);
    UI.set_input_value("middle_name", name_obj.middle_name);
    UI.set_input_value("last_name", name_obj.last_name);
    UI.set_input_value("years_experience", doctor.yearsExperience ?? "");

    UI.set_input_value(
      "paymayaAccountNumber",
      doctor.financialInformation?.mayaAccountNumber ?? ""
    );

    UI.set_input_value("city_municipality", doctor.address[0]?.city ?? "");
    UI.set_input_value("zip_code", doctor.address[0]?.postalCode ?? "");
    UI.set_input_value("province", doctor.address[0]?.state ?? "");

    // UI.set_input_value("floor_number", doctor.address[0]?.floorNumber ?? "");
    UI.set_input_value("house_number", doctor.address[0]?.houseNumber ?? "");
    UI.set_input_value("street_name", doctor.address[0]?.streetName ?? "");
    UI.set_input_value("barangay", doctor.address[0]?.barangay ?? "");

    UI.set_input_value("email", onboard_data.email);
    UI.set_input_value("consultation_fee", doctor.pricePerConsultation ?? "");

    let phoneNumber =
      onboard_data.phoneNumber?.split("")[0] === "0"
        ? onboard_data.phoneNumber.slice(1, 13)
        : onboard_data.phoneNumber;
    // console.log(phoneNumber);
    UI.set_input_value(
      "contact_no",
      doctor.telecom.length === 0
        ? phoneNumber ?? ""
        : UI.DataHelper.carespan.get_phone(doctor.telecom) ?? ""
    );

    UI.set_input_value("prc_number", doctor.prcLicenseNumber);

    if (doctor.qualificationDate) {
      UI.set_date_value(
        "qualification_date",
        new Date(doctor.qualificationDate)
      );
    }

    doctor.birthDate &&
      UI.set_date_value("dob", new Date(doctor.birthDate), true);

    doctor.prcLicenseStartDate &&
      UI.set_date_value(
        "license_start_date",
        new Date(doctor.prcLicenseStartDate),
        true
      );

    doctor.prcLicenseEndDate &&
      UI.set_date_value(
        "license_end_date",
        new Date(doctor.prcLicenseEndDate),
        true
      );

    /* UI.set_select_value(
      "specialty",
      UI.DataHelper.carespan.get_specialty(doctor.fieldSpecialties)
    ); */
    UI.set_multiple_select_value("specialty", doctor.fieldSpecialties);
    // UI.set_multiple_select_value("hospital", hospital ?? []);

    UI.set_multiple_select_value("languange", doctor.languange);
    UI.set_multiple_select_value("hmo_affiliation", doctor.hmoAffiliations);
    UI.set_multiple_select_value("medicalAssociation", medicalAssociations);

    // const languanges = hospitals.map((item) => {
    //   return item._id;
    // });

    const hospitals_ids = hospitals.map((item) => {
      return item._id;
    });

    UI.set_multiple_select_value("hospital", hospitals_ids ?? []);

    if (hospitals.length > 0) {
      this.setState(
        {
          isAffiliated: true,
        },
        () => {}
        //['62344420d487c65c593f932d', '62344440c379de7b1a9b3f7e', '62370c2c9ed08259e3fafcd3']
      );
    } else {
      this.setState({
        isAffiliated: false,
      });
    }

    this.setState({
      id: doctor._id,
      picture,
      mpc,
      prcLicensePhoto,
      signature_path: signature,
      cor: certificateOfRegistration ?? "",
      or: officalReceipt ?? "",
      profile_picture: image,
      moa_path: moaPath,
      gender: doctor.gender ?? "male",
      professionalType: doctor.professionalType ?? "",
      is_check_marketing_consent: doctor.marketingConsent ?? false,
      is_check_moa_user: doctor.moaAdvocacy ?? false,
      marketing_consent_path: marketingConsentPath,
      officalReceipts: doctor.officalReceipts ?? [],
      ptrNumber: ptrNumber,
      s2Number: s2Number,
      doctorType: doctor.isMwellPlus,
    });

    this.download_file(prcLicensePhoto, "downloaded_prc_license_photo");
    this.download_file(signature, "file_signature_path");
    this.download_file(moaPath, "downloaded_moa");
    this.download_file(mpc, "downloaded_mpc");
    this.download_file(certificateOfRegistration, "downloaded_cor");
    // this.download_file(officalReceipt, "downloaded_or");
    this.download_file(
      doctor.officalReceipts.length > 0
        ? doctor.officalReceipts.slice(-1).pop().path
        : officalReceipt,
      "downloaded_or"
    );
    this.download_file(marketingConsentPath, "downloaded_marketing_consent");
    this.download_file(ptr, "downloaded_ptr");
    this.download_file(s2, "downloaded_s2");
    this.hide_loading();
  };

  download_file = async (path, state) => {
    if (path) {
      const ext = await UI.get_file_extension(path);
      const image_link = await UI.download_image(path, "doctors-onboarding");
      this.setState({
        [state]: {
          url: image_link,
          extension: ext,
          filename: UI.container_filename(path),
        },
      });
    }
  };

  download_file_object = (
    obj = {
      url: "",
      extension: "",
      filename: "",
    }
  ) => {
    UI.download_file(obj.url, obj.filename, obj.extension);
  };

  submit = async () => {
    UI.clear_errors();
    let {
      id,

      first_name,
      middle_name,
      last_name,
      email,
      contact_no,
      dob_carespan,
      // dob_dashed,
      dob_date,
      dob_timestamp,
      gender,
      specialty,
      qualification_date_date,
      prc_number,
      license_start_date_date,
      license_end_date_date,
      // license_start_date_carespan,
      // license_end_date_carespan,
      license_start_date_dashed,
      license_end_date_dashed,

      // floor_number,
      house_number,
      street_name,
      barangay,
      city_municipality,
      province,
      zip_code,

      languange,
      hmo_affiliation,

      picture,
      prcLicensePhoto,
      signature_path,
      mpc,
      years_experience,

      nutrition_price,

      doctorType,
      consultation_fee,

      bankName,
      bankBranch,
      bankAccountName,
      bankAccountNumber,

      moa_path,
      marketing_consent_path,

      hospital,
      onboard_data,
      cor,
      or,
      paymayaAccountNumber,
      is_check_marketing_consent,
      is_check_moa_user,

      prc_license_path,

      medicalAssociation,
      professionalType,

      ptrNumber,
      ptr,

      s2Number,
      s2,
    } = this.state;

    this.show_loading("", "Please wait while we process your request...");
    const convertedDoctorType =
      doctorType === true ? "contracted" : "consulting";
    // console.log("SUBMITTED DOCTOR TYPE", convertedDoctorType);

    if (!first_name) {
      await UI.error_form("first_name", "First Name is required.");
    }
    if (!last_name) {
      await UI.error_form("last_name", "Last Name is required.");
    }

    const cd = new Date();
    const d = new Date();
    d.setFullYear(cd.getFullYear() - 20);

    if (d.getTime() < dob_timestamp) {
      await UI.error_form("dob", "You have to be at least 20 years old");
    }
    /*     if (!email) {
      await UI.error_form("email", "Email is required.");
    }
 */
    if (email) {
      if (!UI.validate_email(email)) {
        await UI.error_form("email", "Please enter a valid email.");
      }
    }

    if (!contact_no) {
      await UI.error_form("contact_no", "Contact Number is required.");
    }
    if (contact_no.length !== 10) {
      await UI.error_form(
        "contact_no",
        "Format should be 9xxxxxxxxx is required."
      );
    }

    if (!prc_number) {
      await UI.error_form("prc_number", "PRC Number is required.");
    }

    if (prc_number.length !== 7) {
      await UI.error_form("prc_number", "PRC Number should be 7 digits.");
    }

    if (specialty.length === 0) {
      await UI.error_form("specialty", "Specialty is required.");
    }

    if (languange.length === 0) {
      await UI.error_form("languange", "Language/Dialect is required.");
    }

    // if (hmo_affiliation.length == 0) {
    //   await UI.error_form("hmo_affiliation", "HMO Affiliation is required.");
    // }

    if (specialty.includes("Nutritionist")) {
      if (!nutrition_price) {
        await UI.error_form(
          "nutrition_price",
          "Nutrition Plan Price is required."
        );
      }
    }

    if (!consultation_fee && doctorType === false) {
      await UI.error_form(
        "consultation_fee",
        Constants.consultation_fee_validation
      );
    } else {
      if (consultation_fee < Constants.consultation_fee && doctorType === false) {
        await UI.error_form(
          "consultation_fee",
          Constants.consultation_fee_validation
        );
      }

      if (consultation_fee % 50 !== 0 && doctorType === false) {
        await UI.error_form(
          "consultation_fee",
          Constants.consultation_fee_validation
        );
      }
    }

    if (!years_experience) {
      await UI.error_form(
        "years_experience",
        "Years of Experience is required."
      );
    }

    if (!dob_carespan) {
      await UI.error_form("dob", "Date of Birth is required.");
    }

    /* 
    if (!qualification_date_date) {
      await UI.error_form(
        "qualification_date",
        "Qualification Date is required."
      );
    } */

    if (!license_start_date_date) {
      await UI.error_form(
        "license_start_date",
        "PRC License Start Date is required."
      );
    }

    if (!license_end_date_date) {
      await UI.error_form(
        "license_end_date",
        "PRC License End Date is required."
      );
    }

    /*     if (!license_photo_file) {
      await UI.error_form("license_photo", "PRC License Photo is required.");
    } */

    if (!signature_path) {
      await UI.error_form("signature_path", "Signature is required.");
    } 

    if (!city_municipality) {
      await UI.error_form(
        "city_municipality",
        "City/Municipality is required."
      );
    }

    // if (this.state.doctor.financialInformation.mayaAccountNumber) {
    //   await UI.error_form(
    //     "paymayaAccountNumber",
    //     "Maya Number is required."
    //   );
    // }

    // if (this.state.doctor.financialInformation.bankAccountNumber) {
    //   if (!bankName) {
    //     await UI.error_form("bankName", "Bank Branch is required.");
    //   }

    //   if (!bankBranch) {
    //     await UI.error_form("bankBranch", "Bank Branch is required.");
    //   }

    //   if (!bankAccountName) {
    //     await UI.error_form(
    //       "bankAccountName",
    //       "Name of the Branch is required."
    //     );
    //   }

    //   if (!bankAccountNumber) {
    //     await UI.error_form(
    //       "bankAccountNumber",
    //       "Bank Account Number is required."
    //     );
    //   }
    // }

    // if (!bankAccountNumber) {
    //   await UI.error_form(
    //     "paymayaAccountNumber",
    //     "Maya Number is required."
    //   );
    // }

    // if (!mpc) {
    //   await UI.error_form("mpc", "MPC is required.");
    // }

    // if ((doctor_onboard_data?.isDoctorsOnboardingApproved === "Pending"
    // //  || onboard_data?.isDoctorsOnboardingApproved === "Approved"
    //  )
    // // && !onboard_data?.isAdvocacyOnboardingApproved?.hasOwnProperty("step3")
    // ) {
    if (!moa_path) {
      await UI.error_form("moa_file", "Signed MOA is required.");
    }
    if (!street_name) {
      await UI.error_form("street_name", "Street Name is required.");
    }
    if (!barangay) {
      await UI.error_form("barangay", "Floor Number is required.");
    }
    if (!province) {
      await UI.error_form("province", "Province is required.");
    }
    if (!zip_code) {
      await UI.error_form("zip_code", "Zip Code is required.");
    }
    // }

    /*
    const date_difference = UI.date_diff(
      license_start_date_date,
      license_end_date_date
    );
    //365 days by 5 years 
    if (date_difference >= 0) {
      if (date_difference >= 365 * 5) {
      } else {
        await UI.error_form(
          "license_start_date",
          "License covered date should be at least 5 years."
        );
        await UI.error_form(
          "license_end_date",
          "License covered date should be at least 5 years."
        );
      }
    } else {
      await UI.error_form("license_start_date", "Invalid license start date.");
      await UI.error_form("license_end_date", "Invalid license end date.");
    }

    */

    const error_count = this.state.error_count;
    if (error_count > 0) {
      this.hide_loading();
      return;
    }
    let add = { hospital };
    let add2 = { hospitals: hospital };
    if (this.state.isAffiliated === false) {
      add = { hospital: undefined };
      add2 = { hospitals: undefined };
    }

    // console.log(doctor_onboard_data)
    // (onboard_data?.isDoctorsOnboardingApproved === "Pending" || onboard_data?.isDoctorsOnboardingApproved === "Approved")
    // console.log(onboard_data?.isDoctorsOnboardingApproved)
    // console.log(onboard_data?.isAdvocacyOnboardingApproved?.hasOwnProperty("step3"), onboard_data?.isDoctorsOnboardingApproved === "Pending")
    // (doctor_onboard_data?.isDoctorsOnboardingApproved === "Pending" || onboard_data?.isDoctorsOnboardingApproved === "Approved")  &&
    if (onboard_data?.isAdvocacyOnboardingApproved?.hasOwnProperty("step3")) {
      if (!is_check_moa_user) {
        this.hide_loading();
        return this.failed_modal("Please view and check the MOA");
      }

      if (!is_check_marketing_consent) {
        this.hide_loading();
        return this.failed_modal("Please view and check the Marketing Consent");
      }
    }

    let given = [first_name];
    if (middle_name) {
      given = [first_name, middle_name];
    }

    // let request = ""

    // if (onboard_data?.isDoctorsOnboardingApproved === "Pending") {
    //   request = {
    //     method: "put",
    //     url: "doctors_onboarding/doctors/" + id,
    //   }
    // } else {
    //   request = {
    //     method: "post",
    //     url: "doctors_onboarding/doctors",
    //   }
    // }
    // console.log(this.state);
    UI.webRequest({
      method: "put",
      url: "doctors_onboarding/doctors/" + id,
      params: {
        resourceType: "Practitioner",
        name: [
          {
            given: given,
            family: last_name,
            prefix: ["DR"],
          },
        ],
        qualification: [
          {
            identifier: [
              {
                use: "official",
                system: "https://online2.prc.gov.ph/Verification",
                value: prc_number,
              },
            ],
            code: {
              coding: [
                {
                  system: "http://snomed.info/sct",
                  code: "260385009",
                  display: "MA",
                },
              ],
              text: "MA",
            },
            issuer: {
              /* 
              reference: "internal",
              type: "CarePlan",
              identifier: {
                use: "official",
                system: "https://online2.prc.gov.ph/Verification",
                value: "12345",
              }, */
              display: "PH PRC",
            },
            period: {
              start: license_start_date_dashed,
              end: license_end_date_dashed,
            },
          },
        ],
        address: [
          {
            // line: [`${floor_number} ${house_number} ${street_name} ${barangay}`],
            line: [`${house_number} ${street_name} ${barangay}`],
            city: city_municipality,
            state: province,
            postalCode: zip_code,
            // floorNumber: floor_number,
            houseNumber: house_number,
            streetName: street_name,
            barangay: barangay,
          },
        ],
        telecom: [
          {
            system: "phone",
            value: contact_no,
            use: "home",
            rank: 1,
            period: {
              start: "2020-09-10",
              end: "2021-09-10",
            },
          },
          {
            system: "email",
            value: email,
            use: "email",
            rank: 1,
            period: {
              start: "2020-09-10",
              end: "2021-09-10",
            },
          },
        ],
        gender: gender,
        birthDate: dob_date,
        communication: [
          {
            coding: [
              {
                system: "urn:ietf:bcp:47",
                code: "en",
                display: "English",
              },
            ],
          },
        ],
        professionalType,
        picture: picture,
        fieldSpecialties: specialty,
        yearsExperience: years_experience,
        doctorType: convertedDoctorType,
        isMwellPlus: false,
        qualificationDate: qualification_date_date,
        prcLicenseNumber: prc_number,
        prcLicenseStartDate: license_start_date_date,
        prcLicenseEndDate: license_end_date_date,
        signature: signature_path,
        rating: 5,
        pricePerConsultation: Number(consultation_fee),
        prcLicensePhoto: prc_license_path ? prc_license_path : prcLicensePhoto,
        moaPath: moa_path,
        languanges: languange,
        hmoAffiliations: hmo_affiliation,
        medicalAssociations: medicalAssociation,
        certificateOfRegistration: cor,
        officalReceipt: or,
        paymayaAccountNumber: paymayaAccountNumber,
        bankName,
        bankBranch,
        bankAccountName,
        bankAccountNumber,
        mpc: mpc,
        moaAdvocacy: is_check_moa_user,
        marketingConsent: is_check_marketing_consent,
        marketingConsentPath: marketing_consent_path,
        ptrNumber,
        ptr,

        s2Number,
        s2,
        ...add,
        ...add2,
      },
      onFail: (err) => {
        const data = err.data;
        let error_message = data.message;
        if (!error_message) {
          error_message = data.m;
        }
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        // mem.save(UI.Constants.USER_DOCTOR, JSON.stringify(data));
        // console.log("SUCCESS isMwellPlus", data?.isMwellPlus);
        let additional = "";
        let doctorTypeMsg = "";
        let convertedRequestedTypeOfDoctor = "";

        const currTypeOfDoctor =
          // this?.layout?.state?.onboard_data?.doctor?.isMwellPlus;
          data?.isMwellPlus ?? this?.state?.doctorType;
        const convertedCurrTypeOfDoctor =
          currTypeOfDoctor === true ? "contracted" : "consulting";
        const requestedTypeOfDoctor = this?.state?.doctorType;

        if (
          requestedTypeOfDoctor === false ||
          requestedTypeOfDoctor === "consulting"
        ) {
          convertedRequestedTypeOfDoctor = "consulting";
        }
        if (
          requestedTypeOfDoctor === true ||
          requestedTypeOfDoctor === "contracted"
        ) {
          convertedRequestedTypeOfDoctor = "contracted";
        }

        if (
          this?.layout?.state?.onboard_data?.isDoctorsOnboardingApproved !==
          "Approved"
        ) {
          additional =
            " Please wait a maximum of 24 hours for our team to validate your documents. Thank you!";
        }

        // console.log("CURRENT DOCTOR TYPE: ", convertedCurrTypeOfDoctor);
        // console.log("REQUESTED DOCTOR TYPE: ", convertedRequestedTypeOfDoctor);

        if (convertedRequestedTypeOfDoctor !== convertedCurrTypeOfDoctor) {
          doctorTypeMsg =
            "Your request to change doctor type is subject to approval.";
        }

        this.show_modal(
          <Success
            title={"Success!"}
            description={
              doctorTypeMsg.length > 0
                ? doctorTypeMsg
                : "Submitted your documents successfully." + additional
            }
            onDismiss={() => {
              this.componentDidMount();
              this.hide_modal();
            }}
          />
        );
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  auth_upload = async (state_name, file, callback) => {
    UI.upload_image({
      url: "doctors_onboarding/doctors/upload",
      file: file,
      onFail: (err) => {
        this.show_modal(
          <Failed
            title={"Failed"}
            description={"Upload Failed. Application Error."}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (path, response) => {
        this.setState({
          [state_name]: path,
        });
        if (callback) {
          callback(path);
        }
        this.process_file(state_name, file, path);
      },
    });
  };

  process_file = async (state_name, file, path) => {
    const filename = UI.container_filename(path);
    const extension = await UI.get_file_extension(path);
    const url = URL.createObjectURL(file);
    const obj = {
      url: url,
      extension: extension,
      filename: filename,
    };

    this.setState({
      ["file_" + state_name]: obj,
    });
  };

  download_path = async (path) => {
    this.show_loading("", "Downloading File");
    const image_link = await UI.download_image(path, "doctors-onboarding");
    var a = document.createElement("a");
    a.href = image_link;
    a.download = UI.container_filename(path);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.hide_loading();
  };

  render() {
    const _this = this;
    const {
      // height,
      // width,
      // isMobile,
      // prcLicensePhoto,
      // picture,
      // moa_path,
      downloaded_prc_license_photo,
      // file_user_profile_path,
      doctor,
    } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <PRCValidator _this={this} />
        <View style={styles.content_container}>
          <ProfilePicture _this={_this} />
          <View style={{ width: "100%" }}>
            <Layout.FilePicker
              _this={_this}
              title={"PRC License Photo"}
              additional={
                <View style={{ flexDirection: "row" }}>
                  (Jpeg or Png)
                  <TouchableOpacity
                    onClick={() => {
                      //_this.download_path(prcLicensePhoto);
                      _this.download_file_object(downloaded_prc_license_photo);
                    }}
                    style={styles.download_button}
                  >
                    <Text style={styles.download_text}>Download</Text>
                  </TouchableOpacity>
                </View>
              }
              isRequired={false}
              text={"Drop Your File Here"}
              state_name={"license_photo"}
              onChange={(file) => {
                _this.auth_upload(
                  "prc_license_path",
                  file,
                  _this.PRCValidator.validate_prc
                );
              }}
              display_type={"filename_size"}
              allowed_files={["jpg", "jpeg", "png"]}
            />
            <Text
              style={{
                color: "#FF4D4D",
                fontWeight: "bold",
                fontSize: 14,
                marginTop: 10,
              }}
            >
              Upload PRC License to autofill information (Name, PRC details)
            </Text>
            <PreviewFile _this={_this} {...downloaded_prc_license_photo} />
            {UI.box(20)}
          </View>

          <FirstForm _this={_this} />
          <SecondForm
            ref={(view) => {
              this.second = view;
            }}
            _this={_this}
          />
          <Affiliation
            ref={(v) => {
              this["affiliation"] = v;
            }}
            _this={_this}
          />
          <ThirdForm _this={_this} />
          <AddressInformation _this={_this} />
          <FourthForm _this={_this} />
          <MOA _this={_this} />
          <PTR _this={_this} />
          {UI.box(20)}

          <FifthForm
            _this={_this}
            onChangeDoctorType={(e) => {
              const typeOfDoctor = e.target.value;
              if (typeOfDoctor === "contracted")
                this.setState({
                  doctorType: true,
                  consultation_fee: doctor?.pricePerConsultation ?? "",
                });

              UI.set_input_value(
                "consultation_fee",
                doctor?.pricePerConsultation ?? ""
              );
              if (typeOfDoctor === "consulting")
                this.setState({ doctorType: false });
            }}
          />
          {UI.box(20)}

          <p className="text-red-500">
            {this.state.error_count > 0 &&
              this.state.error_count +
                " required field are need to be filled up."}
          </p>

          {_this.state.form_messages?.map((err) => {
            const key = Object.keys(err);
            return <span className="text-red-500">* {err[key]}</span>;
          })}

          {UI.box(10)}

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={
                this?.layout?.state?.onboard_data
                  ?.isDoctorsOnboardingApproved === "Disapproved"
                  ? "SUBMIT"
                  : "SAVE CHANGES"
              }
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                if (
                  _this?.layout?.state?.onboard_data
                    ?.isDoctorsOnboardingApproved === "Approved"
                ) {
                  UI.goTo("/onboard/dashboard");
                } else {
                  UI.logout();
                }
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  download_text: {
    color: "white",
    fontWeight: "bold",
    fontSize: 12,
  },
  download_button: {
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: UI.colors.primary,
    marginLeft: 10,
    borderRadius: 5,
    height: 30,
    marginTop: -5,
  },
  red_btn: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "#E00000",
    height: 45,
    paddingLeft: 20,
    paddingRight: 20,
  },
  blue_btn: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: UI.colors.primary,
    height: 45,
    paddingLeft: 20,
    paddingRight: 20,
  },
  blue_text: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
  content_container: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 10,
    backgroundColor: "white",
    width: "100%",
    ...Shadow._2(),
  },
  boxPadding: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 10,
  },
  header_2: {
    color: "#535353",
    fontSize: 15,
  },
  header_1: {
    fontSize: 15,
    color: UI.colors.primary,
    marginTop: 2,
    fontWeight: "bold",
  },
  header_left: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  header_container: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 10,
    ...Shadow._2(),
  },
  main_container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#EFF3F7",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
