import React from "react";
import CustomDatatable from "../_components/CustomDatatable";
import UI from "../../shared/Components/UI/js";
import TouchableOpacity, {
  Image,
  Text,
  StyleSheet,
  View,
  mem,
} from "../../shared/custom-react-native";
import EntryPicker from "../_components/EntryPicker";
import Layout from "../_components/Layout";
import InputForm from "../_components/InputForm";
import { Failed } from "../_components/Modal";

const ApprovalTable = ({ _this, type }) => {
  const {
    width,
    all_data,
    data,
    data_temp,
    allowed_tagging,
    name,
    sub_group,
    status,
    filter_data,
    usergroups
  } = _this.state;

  const columns =
    type === "view"
      ? [
          { Name: "name" },
          { "Email Address": "email_address" },
          { "Contact Number": "contact_number" },
          { Status: "status" },
          { Action: "action" },
        ]
      : [
          { Name: "name" },
          { Organizations: "user_group" },
          { Department: "department" },
          { "Email Address": "email_address" },
          { "Contact Number": "contact_number" },
          { Status: "status" },
          { Action: "action" },
        ];

  const handle_approval = async (id, approvalStatus) => {
    _this.show_loading();

    UI.webRequest({
      method: "put",
      url: "admin/usergroup/user-reg-update-status/" + id,
      params: { approvalStatus },
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.error;
        _this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              _this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        console.log("response");
      },
      onFinish: () => {
        _this.hide_loading();
        _this.get_data();
      },
    });
  };

  const filterBy = (format, value) => {
    let filter;

    switch (format) {
      case "search":
        if (value !== " ") {
          filter = data_temp;
        } else {
          filter = filter_data;
        }

        if (allowed_tagging.length > 0) {
          filter = filter.filter((item) =>
            allowed_tagging.some((id) => item.userGroup.tags.includes(id))
          );
        }

        if (name.length > 0) {
          filter = filter.filter((item) =>
            name.some((id) => item.userGroup._id === id)
          );
        }

        if (sub_group.length > 0) {
          filter = filter.filter((item) =>
            sub_group.some((id) => item.userGroup.teams._id === id)
          );
        }

        if (status !== "") {
          filter = filter.filter((item) => item.approvalStatus === status);
        }

        break;

      default:
        break;
    }

    _this.setState(
      {
        data: filter,
        all_data: filter,
      },
      () => {
        _this.reload_table("user_group_approval");
      }
    );
  };

  return (
    <View style={{ ...styles.main_box_content, marginTop: 20, padding: 0 }}>
      <UI.Row
        style={styles.main_container_header}
        breakpoint={950}
        _this={_this}
      >
        <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
          <Text style={styles.entry_header}>Only Show</Text>
          {UI.box(10)}
          <View>
            <EntryPicker table_name={"user_group_approval"} _this={_this} />
          </View>
        </View>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <InputForm
            _this={_this}
            title={""}
            is_keyword={true}
            placeholder={"Enter Keyword"}
            state_name={"search"}
            onChange={(e) => {
              UI.search_table("user_group_approval", e, all_data, "data");

              if (type === "view") {
                filterBy("search", e);
              }
            }}
            right={
              <TouchableOpacity style={styles.search_btn}>
                <Image
                  height={17}
                  width={17}
                  tintColor={"transparent"}
                  source={UI.SEARCH}
                />
              </TouchableOpacity>
            }
            disableLength={true}
            maxLength={UI.Constants.limits.keyword}
          />

          {type === "dashboard" && (
            <View style={{ justifyContent: "end", marginLeft: 15 }}>
              {UI.box(8)}
              <Layout.Btn
                color={UI.colors.primary}
                text={"Advance Filter"}
                onClick={() => {
                  mem.remove("view_user_group");
                  UI.goTo("/voucher/usergroup-view");
                }}
                height={50}
              />
            </View>
          )}
        </View>
      </UI.Row>
      <CustomDatatable
        state_name={"user_group_approval"}
        _this={_this}
        columns={columns}
        data={_this.state.data.map((item) => {

          const user_group = item?.userGroup?.hasOwnProperty('parentUserGroup') ? usergroups.filter(_item => _item._id === item?.userGroup?.parentUserGroup)[0]?.name: item?.userGroup?.name;

          return {
            name: item?.user?.name,
            department: item?.userGroup?.hasOwnProperty('parentUserGroup') ? item?.userGroup?.name : "",
            user_group: user_group,
            email_address: item?.user?.email,
            contact_number: item?.user?.phone,
            status: (
              <Text
                style={{
                  color:
                    item.approvalStatus === "Approved"
                      ? UI.colors.green
                      : "#3E3E3E",
                }}
              >
                {item.approvalStatus}
              </Text>
            ),
            action: (
              <View style={{ flexDirection: "row" }}>
                {item.approvalStatus === "Pending" && (
                  <>
                    <TouchableOpacity
                      onClick={() => {
                        handle_approval(item._id, "Approved");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.CHECK_SQUARE_FILL}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={() => {
                        handle_approval(item._id, "Disapproved");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.X_SQUARE_FILL}
                      />
                    </TouchableOpacity>
                  </>
                )}

                {item.approvalStatus === "Disapproved" && (
                  <TouchableOpacity
                    onClick={() => {
                      handle_approval(item._id, "Approved");
                    }}
                  >
                    <Image
                      height={28}
                      width={28}
                      tintColor={"transparent"}
                      source={UI.CHECK_SQUARE_FILL}
                    />
                  </TouchableOpacity>
                )}

                {item.approvalStatus === "Approved" && (
                  <TouchableOpacity
                    onClick={() => {
                      handle_approval(item._id, "Disapproved");
                    }}
                  >
                    <Image
                      height={28}
                      width={28}
                      tintColor={"transparent"}
                      source={UI.X_SQUARE_FILL}
                    />
                  </TouchableOpacity>
                )}
              </View>
            ),
          };
        })}
        on_page={(page, callback, table_name) => {
          UI.on_page(all_data, callback, table_name, page);
        }}
      />
      <View
        style={{
          ...styles.no_info_container,
          display: data.length > 0 ? "none" : "flex",
        }}
      >
        <UI.Row
          breakpoint={820}
          style={{ maxWidth: 360, alignItems: "center" }}
          _this={_this}
        >
          <Image
            height={100}
            width={100}
            tintColor={"transparent"}
            source={UI.NO_INFO}
          />
          {UI.box(10)}
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: "#BFBFBF",
              textAlign: width > 820 ? "left" : "center",
              marginLeft: width > 820 ? 20 : 0,
            }}
          >
            NO DATA AVAILABLE YET
          </Text>
        </UI.Row>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    // ...Shadow._3(),
    padding: 20,
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
});

export default ApprovalTable;
