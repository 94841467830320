import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
// import {
//   StyleSheet,
// } from "../../shared/custom-react-native";
import MainLayout from "../_components/MainLayout";
// import "./css.css";

const context = "usergroupadmin";

export default class UserGroupDashboard extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Dashboard",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
    });
  }

  onCreate = () => {
    // UI.goTo('/usergroupadmin/usergroup-list') /// TEMPORARILY REDIRECT TO USER GROUP LIST SINCE NO DASHBOARD TO BE DISPlAY
  }
  
  render() {
    const _this = this;
    console.log(context)
    return (
      <MainLayout context={context} _this={_this}>
        {/* <h1>USER GROUP</h1> */}
      </MainLayout>
    );
  }
}


// const styles = StyleSheet.create({
// });

