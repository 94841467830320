import UI from "../../shared/Components/UI/js";
import ServiceApi from "../service";

class LaboratoryOrdersApi {
  baseURL = "doctors_onboarding";

  async getAllLaboratoryOrders(params) {
    const response = await ServiceApi.get(this.baseURL + `/appointments/laboratory/order`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async getLaboratoryBySpecificAppointment(appointmentId, params) {
    const response = await ServiceApi.get(this.baseURL + `/appointments/laboratory/order/patient/${appointmentId}`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async requestLaboratoryOrders(appointmentId, payload) {
    const response = await ServiceApi.put(this.baseURL + `/appointments/laboratory/order/${appointmentId}`, payload);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

}

export default new LaboratoryOrdersApi();
