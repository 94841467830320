import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import { StyleSheet, Text, View } from "../../shared/custom-react-native";
// import APIConstants from "../../shared/modules/APIConstants";
import InputForm
// , { Checkbox } 
from "../_components/InputForm";
import Layout, { PreviewFile } from "../_components/Layout";
import "./css.css";
// const Constants = new APIConstants();

export default class SecondForm extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const _this = this.props._this;
    const { width,file_prc_license_path } = _this.state;
    const row_width = width > 1000 ? width - 400 : "100%";
    return (
      <UI.Column
        style={{ marginBottom: 10 }}
        _this={_this}
        breakpoint={1000}
        breakpoint_2={720}
      >
        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <View style={styles.content_container}>
            <View style={{ width: "100%" }}>
              <Layout.FilePicker
                _this={_this}
                title={"OCR/PR Picture"}
                additional={"(Jpeg or Png Only)"}
                isRequired={true}
                text={"Drop Your File Here"}
                state_name={"license_photo"}
                onChange={(file) => {
                  _this.auth_upload(
                    "prc_license_path",
                    file,
                    _this.PRCValidator.validate_prc
                  );
                }}
                allowed_files={["jpg", "jpeg", "png"]}
                display_type={"filename_size"}
              />
              <Text
                style={{
                  color: "#FF4D4D",
                  fontWeight: "bold",
                  fontSize: 14,
                  marginTop: 10,
                }}
              >
                Uploading your PRC license above will auto-fill your information below.
              </Text>
              <PreviewFile _this={_this} {...file_prc_license_path} />
            </View>
          </View>
        </UI.Row>

        {UI.box(20)}

        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <InputForm
            disabled={true}
            _this={_this}
            title={"License Number/PRC"}
            placeholder={"Enter License Number"}
            state_name={"prc_number"}
            style={{ flex: 1 }}
            isNumber={true}
            maxLength={7}
            isRequired={true}
          />
          {UI.box(10)}
          <Layout.DatePicker
            disabled={true}
            _this={_this}
            title={"PRC License Start Date"}
            isRequired={true}
            placeholder={"mm/dd/yyyy"}
            state_name={"license_start_date"}
            date_props={{
              maxDate: new Date(),
            }}
            will_correct = {true}
          />
          {UI.box(10)}

          <Layout.DatePicker
            _this={_this}
            disabled={true}
            title={"PRC License End Date"}
            isRequired={true}
            placeholder={"mm/dd/yyyy"}
            state_name={"license_end_date"}
            date_props={
              {
                //maxDate: new Date(),
              }
            }
            will_correct = {true}
          />
        </UI.Row>
      </UI.Column>
    );
  }
}

const styles = StyleSheet.create({
  asterisk: {
    color: "#FF0000",
  },
  option_container: {
    height: 40,
    width: "100%",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 2,
    padding: 5,
    borderColor: "#CACACA",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  btn_text_1: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
  },
  blue_btn_2: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  text_1: {
    color: "#AFAFAF",
    fontSize: 14,
  },
  image_container: {
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  header_3: {
    fontSize: 18,
    color: UI.colors.primary,
    marginTop: 2,
    fontWeight: "bold",
  },
});
