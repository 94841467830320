import axios from "axios"
import { mem } from "../shared/custom-react-native";
import APIConstants from "../shared/modules/APIConstants";
const Constants = new APIConstants();

class ServiceApi {

    baseURL = Constants.API_HOST_WEB;
    baseURLApp = Constants.API_HOST;
    token = mem.get(Constants.JWT_TOKEN);
    subscriptionKey = Constants.SUBSCRIBTION_KEY;

    request ( isMobileRequest = false) {
        const headers = {
            'Ocp-Apim-Subscription-Key': this.subscriptionKey
        }

        if (mem.get(Constants.JWT_TOKEN)) {
            headers['Authorization'] = `Bearer ${mem.get(Constants.JWT_TOKEN)}`
        }

        if ( isMobileRequest ) {
            this.baseURL = this.baseURLApp + '/app/mwell'
        }

        const request = axios.create({
            baseURL: this.baseURL,
            headers: headers
        });
        
        return request
    }

    post (url, payload, headers) {
        const response = this.request().post(url, payload, headers).catch(err => err.response);
        return response
    }

    put (url, payload, headers) {
        const response = this.request().put(url, payload, headers).catch(err => err.response);
        return response
    }

    delete (url, payload, headers) {
        const response = this.request().delete(url, payload, headers).catch(err => err.response);
        return response
    }

    get (url, params, isMobile = false) {
        return this.request(isMobile).get(url, {
            params
        }).catch(err => err.response);
    }

    getBlobFile (url, params) {
        return this.request().get(url, {
            params,
            responseType: 'blob'
        }).catch(err => err.response);
    }
}

export default new ServiceApi();