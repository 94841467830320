import Icon from "@ant-design/icons";
import React from "react";

const FieldWeightSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.905"
    height="20.435"
    viewBox="0 0 18.905 20.435"
  >
    <path
      id="weight"
      d="M280.572,136.15a2.931,2.931,0,0,1,3.059-2.392h3.18v-.8a2.907,2.907,0,1,1,2.068,0v.8h3.171a2.942,2.942,0,0,1,3.069,2.392l2.031,8.252c.528,2.132-.4,3.394-2.475,3.394H281.016c-2.077,0-3-1.261-2.485-3.394Zm.76,9.587h13.017c.677,0,.983-.408.788-1.2l-1.975-7.779a1.1,1.1,0,0,0-1.168-.936h-8.307a1.092,1.092,0,0,0-1.159.936l-1.975,7.779C280.348,145.329,280.655,145.737,281.332,145.737Zm7.714-15.465a1.2,1.2,0,1,0-2.4,0,1.2,1.2,0,0,0,2.4,0Z"
      transform="translate(-278.39 -127.36)"
      fill="#4fc2ed"
    />
  </svg>
);

const FieldWeightIcon = (props) => {
  return <Icon component={FieldWeightSvg} {...props} />;
};

export default FieldWeightIcon;
