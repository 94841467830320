import React from "react";

const ConsultationNotesSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.345"
      height="33.794"
      viewBox="0 0 25.345 33.794"
    >
      <path
        id="file-lines-solid"
        d="M4.224,0A4.228,4.228,0,0,0,0,4.224V29.569a4.228,4.228,0,0,0,4.224,4.224h16.9a4.228,4.228,0,0,0,4.224-4.224V10.561H16.9a2.11,2.11,0,0,1-2.112-2.112V0ZM16.9,0V8.448h8.448Zm-9.5,16.9H17.953a1.056,1.056,0,0,1,0,2.112H7.392a1.056,1.056,0,1,1,0-2.112Zm0,4.224H17.953a1.056,1.056,0,0,1,0,2.112H7.392a1.056,1.056,0,0,1,0-2.112Zm0,4.224H17.953a1.056,1.056,0,0,1,0,2.112H7.392a1.056,1.056,0,0,1,0-2.112Z"
        fill="#035099"
      />
    </svg>
  );
};

export default ConsultationNotesSvg;
