import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import CategoriesTable from "./CategoriesTable";
import "./css.css";

const context = "merchant";

export default class EcommerceCategoriesList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Product Categories",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;

    return (
      <MainLayout context={context} 
        // additional="categories_list" 
        _this={_this}
      >
        <CategoriesTable _this={_this}/>
      </MainLayout>
    );
  }
}