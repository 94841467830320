import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";
const context = "admin";

export default class AdminViewDoctorDetails extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Hospital Admins",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;
    const { 
      // height,
       width,
      //  isMobile
      } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                borderRightWidth: width >= 1250 ? 2 : 0,
                borderBottomWidth: width < 1250 ? 2 : 0,
                paddingRight: width < 1250 ? 0 : 20,
                marginRight: width < 1250 ? 0 : 20,
                paddingBottom: 10,
                marginBottom: width < 1250 ? 10 : 0,
              }}
            >
              <View style={styles.fl_ai}>
                <Image
                  height={120}
                  width={120}
                  tintColor={"transparent"}
                  source={UI.DOCTOR_USER}
                />

                {UI.box(width < 980 ? 5 : 10)}

                <View>
                  <Text style={styles.patient_name}>Doctor Name Here</Text>
                  <Text style={styles.patient_details}>
                    emailaddress@email.com
                  </Text>
                  <Text style={styles.patient_details}>+63 995 123 4567</Text>
                </View>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <UI.Row
                breakpoint={980}
                breakpoint_2={720}
                breakpoint_3={590}
                _this={_this}
              >
                <View style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.DETAILS_GENDER}
                    name={"Gender"}
                    value={"Male"}
                  />
                  <UserDetail
                    icon={UI.DETAILS_BIRTHDATE}
                    name={"Birthdate"}
                    value={"mm/dd/yyyy"}
                  />
                  <UserDetail
                    icon={UI.DETAIL_QUALIFICATION}
                    name={"Qualification"}
                    value={"mm/dd/yyyy"}
                  />
                  <UserDetail
                    icon={UI.DETAILS_SPECIALIZATION}
                    name={"Specialty"}
                    value={"Doctor Specialty"}
                  />
                  <UserDetail
                    icon={UI.DETAILS_HOSPITAL}
                    name={"Hospital"}
                    value={"Hospital Name Here"}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.DETAILS_PRC}
                    name={"PRC Number"}
                    value={"000101010101"}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"PRC Start Date"}
                    value={"mm/dd/yyyy"}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"PRC End Date"}
                    value={"mm/dd/yyyy"}
                  />
                </View>
              </UI.Row>
            </View>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, marginTop: 20, padding: 0 }}>
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Text style={styles.box_header_text}>Consultations</Text>
            <View style={{ width: 150 }}>
              <Layout.SelectPicker _this={_this}>
                <option>Filter By</option>
              </Layout.SelectPicker>
            </View>
          </View>

          <CustomDatatable
            state_name={"nutrition_programs"}
            _this={_this}
            columns={[
              { ID: "id" },
              { "Patient Name": "patient" },
              { "Appointment Date": "appointment_date" },
              { Type: "type" },
              { "Doctor Name": "doctor_name" },
              { "Payment Method": "payment_method" },
              { Action: "action" },
            ]}
            data={[
              {
                id: "001010",
                patient: (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image
                      height={35}
                      width={35}
                      tintColor={"transparent"}
                      source={UI.SAMPLE_USER}
                    />
                    <Text style={{ marginLeft: 10 }}>Jeane Doe</Text>
                  </View>
                ),
                appointment_date: "mm/dd/yyyy 10:00am",
                type: (
                  <Text style={{ color: UI.colors.secondary }}>Booked</Text>
                ),
                doctor_name: "Doctor's Name",
                payment_method: "Bayad App",
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        // UI.goTo("/transaction/booked-details");
                      }}
                    >
                      <Image
                        height={32}
                        width={32}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              },
            ]}
            current_page={1}
            number_of_pages={20}
            on_page={(page, callback) => {}}
          />
        </View>
      </MainLayout>
    );
  }
}

const UserDetail = (props = { icon: "", name: "", value: "" }) => {
  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}
    >
      <View style={{ width: 150, ...styles.fl_ai }}>
        <View style={styles.detail_icon}>
          <Image
            height={16}
            width={16}
            tintColor={UI.colors.primary}
            source={props.icon}
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
