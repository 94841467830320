import React from 'react'
import styled from 'styled-components';
// import ForgetPasswordForm from './ForgetPasswordForm';
import { Col } from 'antd';
import UI from '../../shared/Components/UI/js';
import VerifyEmailForm from './VerifyEmailForm';

// import ForgetPasswordForm from './ForgetPasswordForm';

const ForgetPasswordStep1 = ({ _this }) => {
  
  // const [step, setStep] = useState(1);

  return (
    <>
     
      <StyledImage image={UI.BG_BLUE_MWELL}>
        <Col className='w-full h-full flex justify-center items-center'>
          <LoginContainer className='bg-white'>
            <VerifyEmailForm 
              _this={_this} 
            /> 
          </LoginContainer>
        </Col>
      </StyledImage>
    </>
  )
}

export default ForgetPasswordStep1

const StyledImage = styled(`div`)`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  background-image: ${(props) => `url(${props.image})`};
`;

const LoginContainer = styled(`div`)`
  border-radius: 10px;
  padding: 47px 32.3px 53px 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 571px;
  width: 100%;
  float: none;
  display: inline-block;
  vertical-align: top;
`;
