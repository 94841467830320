import Icon from "@ant-design/icons";
import React from "react";

const DropdownSvg = () => (
  // SVG PATH OF ICON REQUIRED NOT IMAGE, temporary image.
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="170%"
    height="170%"
    viewBox="0 0 45 26"
  >
    <image
      width="45"
      height="26"
      xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAaCAYAAAAjZdWPAAACwElEQVRYhc3XO0wacRwH8P/9D4UTLNi0VuzptSAt2AhNatvNkU23jm7nVre62cTBTnarW9k6duzW0aELFgP4ABOMr5ZHeYg8PEVC8zuFwN0BZ+4IfDf+v/uHD+H3fxxRqVTYy6sSk0xn5/QDOr/pnmED3cSDeiO8L5U5d1O6/qMh4+BPonjBrf36vf3h+rrMCxl6BNmt4ws9AmfPc4W3m8EwWypd8wMTT+gsPv4br4EhR6cxFD44+dpFaDVsoci5fNv7NTDk8DRqxJjAnPDpw5Mo4JdhYhewqAr2+vfeX16VGgoakkSYoUc+afv7RLNCkWOmS/CmYIiVeRzA/X2a2GuXY71H4C3Bduv4EW1++AXDYoNdowfgbcEMPbIKXnw71m24bDB8xnW1bsH5fXhrZ18SbBkf5erBQnQNPu20r5OksHQDjyZSCyrCebDXv7dcKIo2Mf7MsD2lF4XnhViGkMegp/yvpp5/l4IHQwdvYsn0vArwtuC6Q64h5MrKitS4j9JpjUPGwcvYv/SLSqXSUEwkM4xeT5kMA5QGnu0wWHQqN0N3Eq4I3A7dCbhisBy0mnC2XC6bvIHQar5wISqOmYeRw8bIuqhJLUSpeOBKCIsT48Yp8CMCu5GZVOZ8rsXi5MGbwfBaLl8UFR89uA/gRTngu6BRFe50WDYIghDBt3b2ZzPZnFsCXgOfZfOSYNekdZEgCE7uVVhOe9THB21g0FOmeDLDCOHQPtBG0E63raI6GEIIe1Rm2HgyPe/fjcwI58PeDm0E/0onwErQqB385aTtR+T4z6zaYKXolvBmUQqGaJSI4UtvEAjJgZuMg8jpsCgCozvuHs0C8G+OCSbcDjw99WwJY6wIrBYa4hkbHf4M916pYhVMkuSZGm/4aqEhHrj3CuFqgyFKF6JU2ETq7F0skXJTlJazjJk/qglGCKH/qhHvQf8nk/cAAAAASUVORK5CYII="
    />
  </svg>
);

const DropdownIcon = (props) => {
  return <Icon component={DropdownSvg} {...props} />;
};

export default DropdownIcon;
