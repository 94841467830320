import UI from "../../shared/Components/UI/js";
import ServiceApi from "../service";

class FinancialWellnessApi {
  baseURL = "admin";

  async getFinancialWellness(params) {
    const response = await ServiceApi.get(this.baseURL + `/mwellness-financial`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

}

export default new FinancialWellnessApi();
