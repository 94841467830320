import {
  mem,
} from "../../custom-react-native";
import APIConstants from "../../modules/APIConstants.js";
const Constants = new APIConstants();

class Websocket {
  ws;
  constructor(props = {
      onopen: () => {},
      onmessage: () => {}
    }) {

    this.opened = props.onopen;
    this.messaged = props.onmessage;
    // this.ws = new WebSocket("wss://apimgmt-mwelltelemed-hippo-001.azure-api.net/doctors", null, {
    //   'subscription-key': 'a22488b80fd3403293de4b7f810a22c6'
    // });
    const accessToken = mem.get("jwt_token");
    // this.ws = new WebSocket("wss://apimgmt-mwelltelemed-hippo-001.azure-api.net/doctors?subscription-key=a22488b80fd3403293de4b7f810a22c6&accessToken="+accessToken);
    // this.ws = new WebSocket("wss://api-web-mwell-doctors-onboarding-hippo-001.azurewebsites.net/");
    this.ws = new WebSocket(`${Constants.API_HOST_WEB_SOCKET}&accessToken=${accessToken}`);
    // this.ws = new WebSocket("ws://localhost:19120");
    //this.ws = new WebSocket("wss://proggrind.herokuapp.com/");
    this.ws.onopen = () => {
      if (typeof this.opened !== "undefined" && this.opened) {
        console.log("open")
        this.opened();
      }
    };

    this.ws.onmessage = (event) => {
      if (typeof this.messaged !== "undefined" && this.messaged) {
        let data = JSON.parse(event.data);
        this.messaged(data);
      }
    };

    this.ws.onclose = function(event) {
      if (event.wasClean) {
        console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        console.log('[close] Connection died');
      }
    };

    this.ws.onerror = function(error) {
      console.log(`[error] ${error.message}`);
    };
  }

  send = (object) => {
    this.ws.send(JSON.stringify(object));
  };

  close = () => {
    this.ws.close();
  };
}
export default Websocket;
