import React, { useCallback, useEffect, useState } from 'react'
import TouchableOpacity, { Image, mem } from '../../../shared/custom-react-native';
// import { DeleteConfirmation } from '../_components/Modal';
import DataTable from "../../../ComponentsFC/DataTable";
import { Button, Input, Popover, Row, Select, Space, Typography } from "antd";
import styled from 'styled-components';
import DropdownIcon from '../../../icons/Dropdown';
import ServiceUsergroupsApi from "../../../api_services/usergroups";
import UI from '../../../shared/Components/UI/js';
import { DeleteConfirmation, Failed, Success } from '../../_components/Modal';
import moment from 'moment';

const MemberList = ({ isSuccessInviteMembers, _this }) => {

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(30);
  // eslint-disable-next-line no-unused-vars
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const getMemberList = useCallback( async () => {

    const data = mem.get("view_user_group");
    const user_group = JSON.parse(data);
    const id = user_group?._id

    let params = {};
    if (currentPageNum) {

      params.page = currentPageNum;
      params.limit = tableEntries;
    }

    if (searchQuery) {
      params.email = searchQuery;
    }

    try {
      setIsLoading(true);
      ServiceUsergroupsApi.getUserGroupMembersByUserGroupId(id, params).then( res => {
        setIsLoading(false);
        if (res.status === 200) {
          let data = res.data;
          console.log(res);
          setTotalRecords(res.results.total);
          setTableData(data);
        }
      });
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }, [currentPageNum, tableEntries, searchQuery])

  useEffect(() => {
    getMemberList();
    if (isSuccessInviteMembers) {
      console.log('object');
      getMemberList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMemberList, isSuccessInviteMembers]);

  const handlePaginateTable = (pageNum) => {
    setCurrentPageNum(pageNum);
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const tableColumnHeader = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => email ?? " ",
      width: 200,
    },
    {
      title: "First Name",
      dataIndex: "name",
      key: "name",
      render: (name, item) => item.firstName ?? "N/A",
      width: 200,
    },
    {
      title: "Middle Name",
      dataIndex: "name",
      key: "name",
      render: (name, item) => item.middleName ?? "N/A",
      width: 200,
    },
    {
      title: "Last Name",
      dataIndex: "name",
      key: "name",
      render: (name, item) => item.lastName ?? "N/A",
      width: 200,
    },
    {
      title: "Birth Date",
      dataIndex: "birthd_date",
      key: "birthd_date",
      render: (birthd_date, item) => item.birthDate ? moment(item.birthDate).format("MM/DD/YYYY") : "N/A",
      width: 200,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (gender, item) => item.gender?.toString() ? item.gender === 1 ? "Male" : "Female" : "N/A",
      width: 200,
    },
    {
      title: "Country Code",
      dataIndex: "phoneNumberPrefix",
      key: "phoneNumberPrefix",
      render: (phoneNumberPrefix) => phoneNumberPrefix ? `(${phoneNumberPrefix.includes("+") ? phoneNumberPrefix : `+${phoneNumberPrefix}`})` : "N/A",
      width: 200,
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (phone_number, item) => item.phoneNumber ?? "N/A",
      width: 200,
    },
    {
      title: "Special Condition",
      dataIndex: "special_condition",
      key: "special_condition",
      render: (specialCondition, item) => item.specialCondition ?? "N/A",
      width: 200,
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (isAlreadyScan, item) => item.isAlreadyScan ? (item.isAlreadyScan ? "Yes" : "No") : "No",
      width: 380,
    },
    // {
    //   title: "Already Scan QR/ID?",
    //   dataIndex: "id",
    //   key: "id",
    //   render: (isAlreadyScan, item) => item.isAlreadyScan ? (item.isAlreadyScan ? "Yes" : "No") : "No",
    //   width: 380,
    // },
    // {
    //   title: "User ID",
    //   dataIndex: "special_condition",
    //   key: "special_condition",
    //   render: (specialCondition, item) => item._id ?? "N/A",
    //   width: 200,
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (key, item) => {
        return (
        <>
          <Popover
            overlayStyle={{
              width: "220px",
            }}
            style={{}}
            content={
              <Space direction="vertical" style={{background:'red !important'}}>
                <TouchableOpacity
                  onClick={() => {
                    mem.save("edit_user_group_member", JSON.stringify(item));
                    if (mem.get(UI.Constants.ROLE) === "admin") {
                      UI.goTo("/admin/usergroup-members-create-qr-user");
                    }

                    if (mem.get(UI.Constants.ROLE) === "usergroupadmin") {
                      UI.goTo("/usergroupadmin/usergroup-members-create-qr-user");
                    }

                    if (mem.get(UI.Constants.ROLE) === "merchant") {
                      UI.goTo("/merchant/usergroup-members-create-qr-user");
                    }
                  }}
                >
                  <ActionRow onClick={() => true
                    // editDoctor()
                  }>
                    <Image
                      style={{ marginRight: 10 }}
                      height={27}
                      width={27}
                      tintColor={"transparent"}
                      source={UI.EDIT_BTN}
                    />
                    <ActionLabel>{"Update Member Account"}</ActionLabel>
                  </ActionRow>
                </TouchableOpacity>

                {
                  _this.state.context !== "merchant" && 
                  <TouchableOpacity
                    onClick={() => {
                        deleteMember(item)
                      }}
                    >
                    <ActionRow onClick={() => true
                      // editDoctor()
                    }>
                      <Image
                        style={{ marginRight: 10 }}
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.DELETE_BTN}
                      />
                      <ActionLabel>{"Delete Member Account"}</ActionLabel>
                    </ActionRow>
                  </TouchableOpacity>
                }
                
              </Space>
            }
            trigger={"hover"}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <ActionButton
              onClick={() => {
              }}
              type="link"
            >
              <ThreeDotsTypo>...</ThreeDotsTypo>
            </ActionButton>
          </Popover>
        </>)
      },
      width: 200,
    },
  ];

  const handleCSVExport = () => {
    const csv_data = tableData.map((item, index) => {
      const d = {
        "Email": item.email ?? "N/A",
        "First Name": item.firstName ?? "N/A",
        "Middle Name": item.middleName ?? "N/A",
        "Last Name": item.lastName ?? "N/A",
        // "Full Name": item.firstName ? item.firstName.concat(" ", item.middleName ?? "", " ", item.lastName ?? "") : "N/A",
        "Phone Number": item?.phoneNumber ?? " ",
        "Date of Birth": item.birthDate ? moment(item.birthDate).format("MM/DD/YYYY") : "N/A",
        Gender: item.gender === 0 ? "Female" : "Male" ,
        "Special Condition": item.specialCondition ?? "N/A",
      };

      return d;
    });

    UI.DownloadCSV("User Group Members" + UI.get_current_date_string(), csv_data);
  };

  const deleteMember = (item, index) => {
    // const userGroup = JSON.parse(mem.get("view_user_group"));

    // ServiceUsergroupsApi.deleteUserGroupMemberWithValidateSubscriptions(userGroup._id, item._id).then(
    //   (res) => {
    //     if (res.status === 200) {
    //       _this.hide_loading()
    //       _this.show_modal(
    //         <Success
    //           title={"Success"}
    //           description={`
    //             The member has been scheduled for deletion.
    //           `}
    //           onDismiss={() => {
    //             _this.hide_modal();
    //           }}
    //         />
    //       );
    //     } else {
    //       _this.show_modal(
    //         <Failed
    //           description={res.message}
    //           onDismiss={() => {
    //             _this.hide_loading();
    //             _this.hide_modal();
    //           }}
    //         />
    //       );
    //     }
    //   }
    // );

    // return
    // _this.show_loading()
    // _this.show_modal(
    //   <Failed
    //     description={<span>
    //       Cannot cancel subscription now, earliest possible date of cancellation is <strong className='decoration-slice font-bold'>{"sceduleDate".format("LL")}</strong>. 
    //       Would you like to cancel on this date?
    //     </span>}
    //     onOk={{
    //       method: () => {
    //         ServiceUsergroupsApi.deleteUserGroupMemberWithValidateSubscriptions(userGroup._id, item._id).then(
    //           (res) => {
    //             if (res.status === 200) {
    //               _this.hide_loading()
    //               _this.show_modal(
    //                 <Success
    //                   title={"Success"}
    //                   description={`
    //                     The subscription has been scheduled for cancellation
    //                   `}
    //                   onDismiss={() => {
    //                     _this.hide_modal();
    //                   }}
    //                 />
    //               );
    //             } else {
    //               _this.show_modal(
    //                 <Failed
    //                   description={res.message}
    //                   onDismiss={() => {
    //                     _this.hide_loading();
    //                     _this.hide_modal();
    //                   }}
    //                 />
    //               );
    //             }
    //           }
    //         );
    //       },
    //       text: "Yes",
    //       style: {
    //         backgroundColor: UI.colors.secondary
    //       }
    //     }}
    //     onDismissText="No"
    //     onDismiss={() => {
    //       _this.hide_loading();
    //       _this.hide_modal();
    //     }}
    //   />
    // );
    _this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to remove this member?"}
        onCancel={() => {
          _this.hide_modal();
        }}
        onDelete={async () => {
          setIsLoading(true);
          const userGroup = JSON.parse(mem.get("view_user_group"));
          ServiceUsergroupsApi.deleteUserGroupMember(userGroup._id, item._id).then( res => {
            if (res.status === 200) {
              // let data = res.data;
              _this.show_modal(<Success
                descriptionCenter={"Successfully deleted member."}
                onDismiss={() => {
                  setIsLoading(false);
                  getMemberList()
                  _this.hide_modal();
                }}
              />);
            } else {
              if (res.message.includes("Ongoing")) {
                setIsLoading(true);
                _this.show_modal(
                  <Failed
                    description={<span>
                      Cannot delete member now, earliest possible date of cancellation is <strong className='decoration-slice font-bold'>{UI.momentPHTimezone(res.data?.[0]?.scheduledDelete).format("LL")}</strong>. 
                      Would you like to delete on this date?
                    </span>}
                    onOk={{
                      method: () => {
                        setIsLoading(true);
                        ServiceUsergroupsApi.deleteUserGroupMemberWithValidateSubscriptions(userGroup._id, item._id).then(
                          (res) => {
                            if (res.status === 200) {
                              setIsLoading(false);
                              _this.show_modal(
                                <Success
                                  title={"Success"}
                                  description={`
                                    The member has been scheduled for deletion.
                                  `}
                                  onDismiss={() => {
                                    setIsLoading(false);
                                    getMemberList()
                                    _this.hide_modal();
                                  }}
                                />
                              );
                            } else {
                              _this.show_modal(
                                <Failed
                                  description={res.message}
                                  onDismiss={() => {
                                    _this.hide_loading();
                                    _this.hide_modal();
                                  }}
                                />
                              );
                            }
                          }
                        );
                      },
                      text: "Yes",
                      style: {
                        backgroundColor: UI.colors.secondary
                      }
                    }}
                    onDismissText="No"
                    onDismiss={() => {
                      setIsLoading(false);
                      _this.hide_modal();
                    }}
                  />
                );
              } else {
                _this.show_modal(
                  <Failed
                    description={res.message} 
                    onDismiss={() => { 
                      setIsLoading(false);
                      _this.hide_loading();
                      _this.hide_modal()
                    }} 
                  />
                );
              }
            }
          })
        }}
      />
    );
  
  }

  return (
    <>
      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="30"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="5">5 Entries</Select.Option>
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>

          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            // suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getMemberList}
            style={{ width: 200 }}
          />
          <StyledButton type="primary" onClick={() => {
            handleCSVExport()
          }}>
              Export CSV
          </StyledButton>
        </Toolbar>

        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={tableData}
          defaultPageSize={30}
          // pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />

      </TableBox>
    </>
  )
}

export default MemberList

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  /* box-shadow: 0px 3px 10px #bfbfbf29; */
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const ActionRow = styled(Row)`
  align-items: center;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
`;

const ThreeDotsTypo = styled(Typography)`
  font-family: Poppins;
  font-size: 30px;
  line-height: 2px;
  padding-bottom: 18px;
`;

const ActionLabel = styled(Typography.Paragraph)`
  font-family: Poppins;
  font-weight: 400;
  color: ${(props) => (props.color ? "black" : "black")};
  font-size: 12px;
  margin-bottom: 0 !important;
  margin-left: 10px;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;


// import React from 'react'
// import UI from '../../shared/Components/UI/js';
// import TouchableOpacity, { Image, mem, StyleSheet, Text, View } from '../../shared/custom-react-native';
// import CustomDatatable from '../_components/CustomDatatable';
// import Layout from '../_components/Layout';
// import { DeleteConfirmation } from '../_components/Modal';

// const MemberList = (props) => {

//   const deleteMembers = (item, index) => {

//     props._this.show_modal(<DeleteConfirmation
//       text={"Are you sure you want to delete this member?"}
//       onCancel={() => {
//         this.hide_modal();
//       }}
//       onDelete={async () => {
//         props._this.hide_modal();
//         props._this.show_loading();
//         UI.webRequest({
//           method: "delete",
//           url: `admin/usergroup/${props._this.state.user_group?._id}/member/${item._id}`,
//           params: {},
//           onFail: async (err) => {},
//           onSuccess: async (response) => {
//             console.log(response);
//             const filter = props._this.state.members.filter((a, f) =>  f !== index)
//             props._this.setState({
//               members: filter,
//             },
//             () => {
//               props._this.reload_table("member_list");
//             });
//           },
//           onFinish: () => {
//             props._this.hide_loading();
//           },
//         });
//       }}
//     />)
    
//   }

//   return (
//     <>
//       <UI.Row
//         style={styles.main_container_header}
//         breakpoint={950}
//         _this={props._this}
//       >
//         {/* <View
//           style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
//         >
//           <Text style={styles.entry_header}>Only Show</Text>
//           {UI.box(10)}
//           <View>
//             <EntryPicker table_name={"member_list"} _this={props._this} />
//           </View>
//         </View> */}
//         <View
//             style={{ flexDirection: "row", alignItems: "center", flex: 1, justifyContent:'flex-start' }}
//           >
//             <Text style={styles.entry_header}>Only Show</Text>
//             {UI.box(10)}
//             <View>
//               <View style={{ width: 120 }}>
//                 <Layout.SelectPicker
//                   _this={props._this}
//                   onChange={(e) => {
//                     props._this.setState({ members_entries: parseInt(e)})
//                     props._this.get_members({ current_page:1, per_page: parseInt(e)})
//                   }}
//                 >
//                   <option value={"5"} selected={props._this.state.members_entries === 5}>5 entries</option>
//                   <option value={"10"} selected={props._this.state.members_entries === 10}>10 entries</option>
//                   <option value={"30"} selected={props._this.state.members_entries === 30}>30 entries</option>
//                 </Layout.SelectPicker>
//               </View>
//             </View>
//           </View>

//       </UI.Row>

//       <CustomDatatable
//         state_name={"member_list"}
//         _this={props._this}
//         columns={[
//           { "Fullname": "fullname" },
//           { "Email": "email" },
//           { Action : "action"}
//         ]}
//         data={props._this.state.members.map((item, index) => {
//           return {
//             fullname: item.name ?? item.firstName + " " + item.lastName,
//             email: item.email,
//             action: (
//               <View style={{ flexDirection: "row" }}>
//                 <TouchableOpacity
//                   onClick={() => {
//                     deleteMembers(item, index)
//                   }}
//                 >
//                   <Image
//                     height={28}
//                     width={28}
//                     tintColor={"transparent"}
//                     source={UI.DELETE_BTN}
//                   />
//                 </TouchableOpacity>
//               </View>
//             ),
//           };
//         })}
//         on_page={(page, callback, table_name) => {
//           // props._this.get_members({
//           //   page: page,
//           //   limit: parseInt(props._this.state.entries)
//           // });
//           props._this.get_members({
//             current_page:page,
//             per_page: props._this.state.members_entries
//           })
//         }}
//       />

//       <View
//         style={{
//           ...styles.no_info_container,
//           display: props._this.state.members?.length > 0 ? "none" : "flex",
//         }}
//       >
//         <UI.Row
//           breakpoint={820}
//           style={{ maxWidth: 360, alignItems: "center" }}
//           _this={props._this}
//         >
//           <Image
//             height={100}
//             width={100}
//             tintColor={"transparent"}
//             source={UI.NO_INFO}
//           />
//           {UI.box(10)}
//           <Text
//             style={{
//               fontSize: 20,
//               fontWeight: "bold",
//               color: "#BFBFBF",
//               textAlign: props._this.state.width > 820 ? "left" : "center",
//               marginLeft: props._this.state.width > 820 ? 20 : 0,
//             }}
//           >
//             NO DATA AVAILABLE YET
//           </Text>
//         </UI.Row>
//       </View>
//     </>
//   )
// }

// export default MemberList

// const styles = StyleSheet.create({
//   no_info_container: {
//     paddingTop: 50,
//     paddingBottom: 50,
//     alignItems: "center",
//   },
//   entry_header: {
//     fontSize: 14,
//     fontWeight: "bold",
//     color: "#2E2E2E",
//   },
//   main_container_header: {
//     padding: 10,
//     paddingLeft: 20,
//     paddingRight: 20,
//   },
//   main_box_content: {
//     borderRadius: 5,
//     backgroundColor: "white",
//     // ...Shadow._3(),
//     padding: 20,
//   },
//   detail_icon: {
//     height: 16,
//     width: 16,
//     marginRight: 12,
//   },
//   details_name: {
//     color: UI.colors.primary,
//     fontSize: 14,
//     fontWeight: "bold",
//   },
//   details_value: {
//     color: "#333333",
//     fontSize: 14,
//     fontWeight: "bold",
//   },
//   border_style: {
//     borderStyle: "solid",
//     borderColor: "#EFEFEF",
//   },
//   fl_ai: {
//     flexDirection: "row",
//     alignItems: "center",
//   },
// });