import React from 'react'
import styled from 'styled-components';
import OtpForm from './OtpForm';
import { Row } from 'antd';
import UI from '../../shared/Components/UI/js';

const Otp = ({ _this }) => {
  return (
    <StyledImage image={UI.BG_BLUE_MWELL}>
      <Row className='h-full justify-center items-center'>
        <LoginContainer className='bg-white'>
          <OtpForm _this={_this}/>
        </LoginContainer>
      </Row>
    </StyledImage>
  )
}

export default Otp

const StyledImage = styled(`div`)`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  background-image: ${(props) => `url(${props.image})`};
`;

const LoginContainer = styled(`div`)`
  border-radius: 10px;
  padding: 47px 32.3px 53px 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 571px;
  width: 100%;
  float: none;
  display: inline-block;
  vertical-align: top;
`;
