import React, { Component } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";
const context = "admin";

export default class TelemedicineStats extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "DOH Stats",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      start_date_dashed: moment().subtract(7, "d").format("YYYY-MM-D"),
      end_date_dashed: moment().format("YYYY-MM-D"),
      context: context,
    });
  }

  onCreate = () => {
    this.get_data();
  };

  get_data = async (params = null) => {
    this.show_loading();

    UI.newAuthRequest({
      method: "get",
      url: "web/admin/reports/doh",
      params: {
        start: params?.startDate ?? this.state.start_date_dashed,
        end: params?.endDate ?? this.state.end_date_dashed,
      },
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        this.setState({
          all_data: data,
        });

        this.reload_table("telemedicine_stats");
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  apply_date_filter = async () => {
    this.get_data({
      startDate: this.state.start_date_dashed,
      endDate: this.state.end_date_dashed,
    });
  };

  export_to_csv = async () => {
    const { data } = this.state;
    const csv_data = data.map((item, index) => {
      let top10Diagnosis = item?.top10Diagnosis;

      const d = item && {
        Date: moment(item?.date).format("D MMM YYYY") ?? "",
        "No. of Doctors": item?.doctors ?? "",
        "Total Consultation": item?.totalConsultation ?? " ",
        "Referred to ER": item?.referredToEr ?? " ",
        "Requiring Specialist": item?.requiringSpecialist ?? " ",
        "COVID-19 Related": item?.covid19Related ?? " ",
        "Non COVID-19": item?.nonCovid19Related ?? " ",
        "COVID-19 Inquiry": item?.covid19Inquiry ?? " ",
        "COVID-19 Suspect": item?.covid19Suspected ?? " ",
        "COVID-19 Probable": item?.covid19Probable ?? " ",
        "COVID-19 Confirmed": item?.covid19Confirmed ?? " ",
        "No. of ePrescription issued": item?.ePrescriptionsIssued ?? " ",
        "No. of Med Certificate issued": item?.medicalCertsIssued ?? " ",
        "No. of Clinical Notes  issued": item?.clinicalNotesIssued ?? " ",
        "Top 10 Diagnosis": top10Diagnosis.split(", ").join(";") ?? "",
      };

      return d;
    });

    UI.DownloadCSV("DOH Stats " + UI.get_current_date_string(), csv_data);
  };

  render() {
    const _this = this;
    const { width } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <Text style={styles.title}>Date Filter</Text>

          <UI.Row breakpoint={540} _this={_this}>
            <View style={{ width: 200 }}>
              <Layout.DatePicker
                _this={_this}
                placeholder={"Start Date"}
                state_name={"start_date"}
                will_correct={true}
              />
            </View>
            {UI.box(10)}
            <View style={{ width: 200 }}>
              <Layout.DatePicker
                _this={_this}
                placeholder={"End Date"}
                state_name={"end_date"}
                will_correct={true}
                disabledPresentDates={true}
              />
            </View>
            {UI.box(10)}
            <Layout.Btn
              style={{
                marginTop: 5,
              }}
              color={UI.colors.primary}
              onClick={() => {
                this.apply_date_filter();
              }}
              text={"Apply"}
              height={50}
            />
          </UI.Row>
        </View>
        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={880}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"telemedicine_stats"} _this={_this} />
              </View>
            </View>

            <View
              style={{
                alignSelf: width > 950 ? "flex-start" : "center",
                marginTop: 12,
                marginLeft: 10,
                flexDirection: width > 950 ? "row" : "column",
              }}
            >
              <Layout.Btn
                color={UI.colors.primary}
                text={"Export to CSV"}
                onClick={() => {
                  this.export_to_csv();
                }}
                height={40}
                width={150}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            state_name={"telemedicine_stats"}
            _this={_this}
            disable_pagination={false}
            columns={[
              { Date: "date" },
              { "No. of Doctors": "doctors" },
              { "Total Consultation": "totalConsultation" },
              { "Referred to ER": "referredToEr" },
              { "Requiring Specialist": "requiringSpecialist" },
              { "COVID-19 Related": "covid19Related" },
              { "Non COVID-19": "nonCovid19Related" },
              { "COVID-19 Inquiry": "covid19Inquiry" },
              { "COVID-19 Suspect": "covid19Suspected" },
              { "COVID-19 Probable": "covid19Probable" },
              { "COVID-19 Confirmed": "covid19Confirmed" },
              { "No. of ePrescription issued": "ePrescriptionsIssued" },
              { "Top 10 Diagnosis": "top10Diagnosis" },
            ]}
            data={this.state.data.map((item) => {
              return {
                date: moment(item?.date).format("D MMM YYYY"),
                doctors: item?.doctors,
                totalConsultation: item?.totalConsultation,
                referredToEr: item?.referredToEr,
                requiringSpecialist: item?.requiringSpecialist,
                covid19Related: item?.covid19Related,
                nonCovid19Related: item?.nonCovid19Related,
                covid19Inquiry: item?.covid19Inquiry,
                covid19Suspected: item?.covid19Suspected,
                covid19Probable: item?.covid19Probable,
                covid19Confirmed: item?.covid19Confirmed,
                ePrescriptionsIssued: item?.ePrescriptionsIssued,
                top10Diagnosis: item?.top10Diagnosis,
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
