import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
// import PromotionStats from "./PromotionStats";
import ReferralTable from "./ReferralTable";
import Statistics from "./Statistics";
import ServiceMarketingsApi from "../../api_services/marketings";
// import { Failed } from "../_components/Modal";

const context = "marketing";

export default class MarketingRegisteredReferrals extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Registered Referrals",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
      duration: "year",
      stats: {
        referrerCount: 0,
        totalNumberCampaign: 0,
        registeredReferralCount: 0,
      },
    });
  }

  onCreate = () => {
    this.getUserReferralStats();
  };

  getUserReferralStats = () => {
    this.show_loading();
    ServiceMarketingsApi.getRegisteredReferralStats({
      duration: this.state.duration,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    }).then((res) => {
      if (res.status === 200) {
        this.hide_loading();
        this.setState({ stats: res})
      }
    });
  };

  filterStats = (value) => {
    console.log(value);
    this.getUserReferralStats(value);
  };

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <Statistics _this={_this} />
        <ReferralTable _this={_this} />
      </MainLayout>
    );
  }
}
