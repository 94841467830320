import React from "react";
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    console.log("ERROR BOUNDARY: ", error, errorInfo);
  }
  render() {
    // if (this.state.errorInfo) {
    //   // You can display any custom fallback UI
    //   return (
    //     <div>
    //       <h2>Something went wrong.</h2>
    //       <details style={{ whiteSpace: "pre-wrap" }}>
    //         {this.state.error && this.state.error.toString()}
    //         <br />
    //         {this.state.errorInfo.componentStack}
    //       </details>
    //     </div>
    //   );
    // }
    // Normally, just render children
    return this.props.children;
  }
}
