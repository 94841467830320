import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  // mem,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import Layout, { PreviewFile } from "../_components/Layout";
import "./css.css";
import MarketingConsent from "../onboard-create-user/MarketingConsent";

export default class MOA extends Component {
  render() {
    const _this = this.props._this;
    const {
      // height,
      width,
      // isMobile,
      // moa_path,
      downloaded_moa,
      onboard_data,
      downloaded_marketing_consent,
    } = _this.state;

    const row_width = width > 1000 ? width - 400 : "100%";

    return (
      <UI.Row
        style={{
          width: row_width,
          marginTop: 40,
        }}
        _this={_this}
        breakpoint={1350}
      >
        {onboard_data?.isAdvocacyOnboardingApproved?.hasOwnProperty(
          "step3"
        ) && (
          <View style={{ flex: 1 }}>
            <Layout.FilePicker
              _this={_this}
              title={"Upload Signed MOA"}
              isRequired={true}
              additional={
                <View style={{ flexDirection: "row" }}>
                  (JPEG/PNG/PDF)
                  <TouchableOpacity
                    onClick={() => {
                      _this.download_file_object(downloaded_moa);
                      // _this.download_path(moa_path);
                    }}
                    style={styles.download_button}
                  >
                    <Text style={styles.download_text}>Download</Text>
                  </TouchableOpacity>
                </View>
              }
              text={"Drop Your File Here"}
              state_name={"moa_file"}
              onChange={(file) => {
                _this.auth_upload("moa_path", file);
              }}
              allowed_files={["jpg", "jpeg", "pdf", "png"]}
              display_type={"filename_size"}
            />

            <PreviewFile _this={_this} {...downloaded_moa} />
          </View>
        )}

        {!onboard_data.hasOwnProperty("isAdvocacyOnboardingApproved") && (
          <View style={{ flex: 1 }}>
            <Text
              style={{
                color: UI.colors.primary,
                fontWeight: "bold",
                marginBottom: 10,
              }}
            >
              Digitally Signed MOA
            </Text>

            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Layout.Btn
                color={UI.colors.primary}
                onClick={() => {
                  window.open(downloaded_moa.url, "_blank");
                }}
                text={"View MOA"}
              />
              {UI.box(20)}
            </View>
          </View>
        )}

        {onboard_data.isDoctorsOnboardingApproved === 'Approved' && (
          <>
            {UI.box(20)}
            <View style={{ flex: 1 }}>
              {downloaded_marketing_consent.url !== "" ? (
                <>
                  <Text
                    style={{
                      color: UI.colors.primary,
                      fontWeight: "bold",
                      marginBottom: 10,
                    }}
                  >
                    Digitally Signed Marketing Consent
                  </Text>

                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Layout.Btn
                      color={UI.colors.primary}
                      onClick={() => {
                        window.open(downloaded_marketing_consent.url, "_blank");
                      }}
                      text={"View Marketing Consent"}
                    />
                    {UI.box(20)}
                  </View>
                </>
              ) : (
                <MarketingConsent _this={_this} />
              )}
            </View>
            {/* <View style={{ flex: 1 }}></View> */}
            <View style={{ flex: 1 }}></View>
          </>
        )}
      </UI.Row>
    );
  }
}

const styles = StyleSheet.create({
  download_text: {
    color: "white",
    fontWeight: "bold",
    fontSize: 12,
  },
  download_button: {
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: UI.colors.primary,
    marginLeft: 10,
    borderRadius: 5,
    height: 30,
    marginTop: -5,
  },
  file_text: {
    fontSize: 15,
    fontWeight: "bold",
    color: UI.colors.secondary,
  },
  file_container: {
    width: "100%",
    height: 80,
    backgroundColor: "#EFF3F7",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#C9C9C9",
    borderStyle: "dashed",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  fade: {
    color: "#BFBFBF",
  },
  asterisk: {
    color: "#FF0000",
  },
  option_container: {
    height: 40,
    width: "100%",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 2,
    padding: 5,
    borderColor: "#CACACA",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  btn_text_1: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
  },
  blue_btn_2: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  text_1: {
    color: "#AFAFAF",
    fontSize: 14,
  },
  image_container: {
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  header_3: {
    fontSize: 18,
    color: UI.colors.primary,
    marginTop: 2,
    fontWeight: "bold",
  },
});
