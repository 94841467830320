import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  mem,
} from "../../shared/custom-react-native";
import MainLayout from "../_components/MainLayout";
import { DeleteConfirmation } from "../_components/Modal";
import "./css.css";
import VoucherUserGroupListTable from "./VoucherUserGroupListTable";

export default class VoucherUserGroupList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "User Group List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: mem.get(UI.Constants.ROLE), // RESUSEABLE PAGES TO ADMIN, USER GROUP ADMIN, VOUCHER ADMIN ROLE
    });
  }

  onCreate = () => {
    // window.location.reload();
    this.get_data();
  };

  getAllUserGroups = () => {
    this.show_loading();

    UI.webRequest({
      method: "post",
      url: "admin/usergroup/all",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        this.setState(
          {
            data: data,
            all_data: data,
          },
          () => {
            this.reload_table("usergroup_list");
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }
  
  usegroupAdminGroup = () => { 
    const data = mem.get("admin");
    let usergroupadmin = JSON.parse(data);
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: `admin/usergroupadmin/get-usergroup-by-userid/${usergroupadmin?._id}`,
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        let user_groups = []

        data.forEach(item => {
          if (item.userGroup) {
            user_groups.push(item.userGroup)
          } 
        })

        this.setState(
          {
            data: user_groups,
            all_data: user_groups,
          },
          () => {
            this.reload_table("usergroup_list");
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
    
  }

  get_data = async () => {
    if (this.state.context === "usergroupadmin") {
      this.usegroupAdminGroup()
    } else {
      this.getAllUserGroups()
    }
  };

  // handleInactive = async (id) => {
  //   this.show_modal(
  //     <DeleteConfirmation
  //       text={"Are you sure you want to inactive this item?"}
  //       onCancel={() => {
  //         this.hide_modal();
  //       }}
  //       onDelete={async () => {
  //         this.hide_modal();
  //         this.show_loading();
  //         UI.webRequest({
  //           method: "put",
  //           url: "admin/usergroup/" + id,
  //           params: {
  //             payload: {
  //               status: "Inactive",
  //             },
  //           },
  //           onFail: async (err) => {},
  //           onSuccess: async (response) => {},
  //           onFinish: () => {
  //             this.hide_modal();
  //             this.get_data();
  //           },
  //         });
  //       }}
  //     />
  //   );
  // }

  delete = async (id) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this item?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();
          this.show_loading();
          UI.webRequest({
            method: "delete",
            url: "admin/usergroup/" + id,
            params: {},
            onFail: async (err) => {},
            onSuccess: async (response) => {},
            onFinish: () => {
              this.hide_modal();
              this.get_data();
            },
          });
        }}
      />
    );
  };

  render() {
    const _this = this;
    // const { width } = _this.state;

    return (
      <MainLayout context={this.state.context} additional="usergroup-list" _this={_this}>
        <VoucherUserGroupListTable _this={this}/>
      </MainLayout>
    );
  }
}