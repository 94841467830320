import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import InputForm, { Checkbox } from "../_components/InputForm";
import StartupLayout from "../_components/StartupLayout";
import "./css.css";
const context = "transaction";
export default class TransactionLogin extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      remember_me: false,
      context: context,
    });
  }

  render() {
    // **Commented out, currently not used.
    // const { width, height, isMobile } = this.state;
    const _this = this;
    return (
      <StartupLayout context={context} image={UI.BG_SIGNIN} _this={this}>
        <ScrollView>
          <View style={styles.content_container}>
            <Text style={styles.header_1}>
              Welcome to the mWell Admin Site!
            </Text>
            {UI.box(20)}
            <Text style={styles.header_2}>Please Sign-in to your account.</Text>
            {UI.box(20)}

            <InputForm
              _this={_this}
              title={"Email Address"}
              placeholder={"Email Address"}
              state_name={"email"}
            />
            {UI.box(20)}
            <InputForm
              _this={_this}
              title={"Password"}
              placeholder={"Password"}
              state_name={"password"}
              isPassword={true}
              additional={
                <TouchableOpacity>
                  <Text style={styles.forgot_password_text}>
                    Forgot Password?
                  </Text>
                </TouchableOpacity>
              }
            />
            {UI.box(20)}

            <Checkbox
              _this={this}
              text={"Remember Me"}
              onClick={(value) => {
                this.setState({
                  remember_me: value,
                });
              }}
              is_checked={this.state.remember_me}
            />

            {UI.box(25)}

            <TouchableOpacity
              onClick={() => {
                UI.goTo("/transaction/dashboard");
              }}
              style={styles.blue_btn}
            >
              <Text style={styles.blue_text}>SIGN IN</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </StartupLayout>
    );
  }
}

const styles = StyleSheet.create({
  sign_up_sub: {
    fontSize: 12,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  sign_up_main: {
    fontSize: 12,
    fontWeight: "bold",
    color: "black",
  },
  sign_up_btn: {
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  blue_text: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
  blue_btn: {
    width: "100%",
    height: 40,
    backgroundColor: UI.colors.secondary,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 360,
    alignSelf: "center"
  },
  forgot_password_text: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  forgot_container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: -68,
    marginBottom: 50,
  },
  header_2: {
    fontSize: 14,
    color: UI.colors.black,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 20,
    color: UI.colors.primary,
    fontWeight: "bold",
  },
  content_container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },
});
