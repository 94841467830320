import React from "react";
import { StyleSheet, Text, View, Font } from "react-pdf-renderer-v2";
import EDocsWrapperComponent from "./EDocsWrapperComponent";

const ConsultationNotesModal = ({
  appointment,
  encounterNotesData,
  onHideModal,
}) => {
  const isCovidRelated = encounterNotesData.covidAssessment?.isCovidRelated
  const isIquireCovidConsultation = encounterNotesData.covidAssessment?.isIquireCovidConsultation

  return (
    <EDocsWrapperComponent
      title="Consultation Notes"
      appointment={appointment}
      onHideModal={onHideModal}
    >
      <View style={styles.body}>
        <View style={styles.content}>
          <Text style={styles.subtitle}>Notes:</Text>
          <Text style={styles.text}>{encounterNotesData.notes}</Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.subtitle}>Covid-19 Assessment</Text>
          <Text style={styles.text}>Is this COVID related?</Text>
          <Text style={styles.text}>
            { isCovidRelated ? isCovidRelated ? "Yes": "No" : "" }
          </Text>
          <View style={styles.content}>
            <Text style={styles.text}>
              Did the patient Schedule this consultation to inquire about
              COVID-19?
            </Text>
            <Text style={styles.text}>
              { isIquireCovidConsultation ? isIquireCovidConsultation ? "Yes": "No" : "" }
            </Text>
          </View>
          <View style={styles.content}>
            <Text style={styles.text}>Patient’s COVID-19 assessment?</Text>
            <Text style={styles.text}>
              {encounterNotesData.covidAssessment?.paitentsCovidAssessment || ""}
            </Text>
          </View>
          <View style={styles.content}>
            <Text style={styles.subtitle}>Assessment:</Text>
            <Text style={styles.text}>
              {encounterNotesData.assessment || ""}
            </Text>
          </View>
          <View style={styles.content}>
            <Text style={styles.subtitle}>ICD:</Text>
            {encounterNotesData.icd.map(icd => (
              <Text style={styles.text}>
                {icd.code || ""}: {icd.description || ""}
              </Text>
            ))}
          </View>
          <View style={styles.content}>
            <Text style={styles.subtitle}>Patient Instructions:</Text>
            <Text style={styles.text}>
              {encounterNotesData.patientInstruction || ""}
            </Text>
          </View>
        </View>
      </View>
    </EDocsWrapperComponent>
  );
};

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {},
  content: {
    marginTop: 20,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 16,
  },
  text: {
    fontSize: 14,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    borderTop: "1px solid black",
    paddingTop: 10,
  },
});

export default ConsultationNotesModal;
