import React from 'react'
import styled from 'styled-components';
import UI from '../../shared/Components/UI/js';

const LoginLeftContainer = () => {
  return (
    <StyledLoginContainer 
      style={{ backgroundImage: `url(${UI.LOGIN_BG})` }}
    >
    </StyledLoginContainer>
  )
}

export default LoginLeftContainer

const StyledLoginContainer = styled(`div`)`
  flex:50% ;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;

  @media only screen and (max-width: 820px){
    flex: 0;
    width: 0px;
    background-image: none !important;
  }
`;