import React, { useCallback, useState, useEffect, useRef } from "react";
import DataTable from "../../DataTable";
import UI from "../../../shared/Components/UI/js";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Row, Col, Input, Typography, Button, Tooltip } from "antd";
import dragula from "dragula";
import "dragula/dist/dragula.css";

import SearchIcon from "../../../icons/Search";
import DraggableDotsIcon from "../../../icons/DraggableDots";
import DataTableEditIcon from "../../../icons/DataTableEdit";
import DataTableDeleteIcon from "../../../icons/DataTableDelete";

import ContainerLabelPrimary from "../../ui/ContainerLabelPrimary";
import CustomModal from "../../../ComponentsFC/ui/modals/CustomModal";
import TrashBinIcon from "../../../icons/TrashBinModal";
import SuccessModal from "../../ui/modals/SuccessModal";
import SuccessModalCheckIcon from "../../../icons/SuccessModalCheck";
import ProcessFailedModal from "../../ui/modals/ProcessFailedModal";
import ProcessFailedModalCrossIcon from "../../../icons/ProcessFailedModalCross";

import ServiceMarketingsApi from "../../../api_services/marketings";
import moment from "moment";
import toTitleCase from "../../../utils/toTitleCase";

const getIndexInParent = (el) => Array.from(el.parentNode.children).indexOf(el);

const MwellnessScoreTable = ({ _this }) => {
  const history = useHistory();
  const tableRef = useRef();
  const [tableData, setTableData] = useState([]);
  const [searchResults] = useState([]);
  const [isTableLoading, setTableIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const [processFailedBody, setProcessFailedBody] = useState(<></>);
  const [processFailedModalWidth, setProcessFailedModalWidth] = useState();
  const [selectedBannerId, setSelectedBannerId] = useState();

  const [hasErrorBannerImg, setHasErrorBannerImg] = useState([]);

  useEffect(() => {
    const childList = tableRef.current.querySelector(".ant-table-tbody");
    //Detach default .ant-table-measure-row element in tbody children
    if (childList.hasChildNodes()) {
      childList.removeChild(childList.children[0]);
    }
  }, []);

  const handleReOrderOnDrop = async (dragIndex, draggedIndex, bannerId) => {
    console.log(
      "HANDLE REORDER (MWELLNESS BANNERS): ",
      dragIndex,
      draggedIndex
    );
    // setTableData((oldState) => {
    //   const newState = [...oldState];
    //   const item = newState.splice(dragIndex, 1)[0];
    //   newState.splice(draggedIndex, 0, item);
    //   return Array(...newState);
    // });
    console.log(
      "SELECTED BANNER ID,START,END (MWELLNESS BANNER): ",
      bannerId,
      dragIndex,
      draggedIndex
    );
    try {
      let payload = {
        newIndex: draggedIndex,
        bannerType: "MWELLNESS_SCORE",
      };

      const config = {
        headers: {
          "Content-Type": `application/json`,
        },
      };

      const apiResponse = await ServiceMarketingsApi.updateBannerIndex(
        bannerId,
        payload,
        config
      );

      console.log(
        "PUT API Response CHANGE INDEX of (MWELLNESS BANNER): ",
        apiResponse
      );
      getMwellnessScore();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    let start;
    let end;
    const container = document.querySelector(".ant-table-tbody");
    const drake = dragula([container], {
      moves: (el) => {
        start = getIndexInParent(el);
        return true;
      },
    });

    drake.on("drop", (el) => {
      end = getIndexInParent(el);
      handleReOrderOnDrop(start, end, el.getAttribute("data-row-key"));
    });
    // eslint-disable-next-line
  }, []);

  const getMwellnessScore = useCallback(async () => {
    try {
      setTableIsLoading(true);

      let urlParams = {};

      if (searchQuery) {
        urlParams.keyword = searchQuery;
      }

      const apiResponse = await ServiceMarketingsApi.getMwellnessScore(
        urlParams
      );
      console.log("GET MWELLNESS SCORE (apiResponse): ", apiResponse);
      if (apiResponse.status === 200) {
        setTableData(
          apiResponse.map((obj) => ({
            ...obj,
            key: obj._id,
            title: <StyledGripIcon type="swap" />,
          }))
        );
      } else if (apiResponse.status === 400) {
        setTableData([]);
      }
      setTableIsLoading(false);
    } catch (err) {
      console.error(err);
    } finally {
      //Always turn off table loading regardless of API response status
      setTableIsLoading(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    getMwellnessScore();
  }, [getMwellnessScore]);

  const tableColumnHeader = [
    {
      title: "",
      dataIndex: "title",
      key: "title",
      width: 80,
    },
    {
      title: "Banner Image",
      dataIndex: "photo",
      key: "photo",
      render: (photo, item) => (
        <Tooltip
          id={item._id}
          placement="top"
          title={
            "Couldn't load img due to resource not found in database. (404)"
          }
          overlayStyle={
            hasErrorBannerImg.includes(item._id)
              ? undefined
              : { display: "none" }
          }
        >
          <img
            className=""
            style={{ height: 50, width: 50, borderRadius: 5 }}
            onError={(e) => {
              setHasErrorBannerImg((oldArr) => [...oldArr, item._id]);
            }}
            src={
              item?.imageUrl?.includes("https://")
                ? item?.imageUrl
                : item?.imageUrl
                ? UI.getUserAvatarLink(item?.imageUrl)
                : UI.DEFAULT_AVATAR_PATIENT
            }
            alt="error"
          />
        </Tooltip>
      ),
    },
    {
      title: "Banner Name",
      dataIndex: "bannerName",
      key: "bannerName",
      render: (bannerName) => bannerName ?? "--",
    },
    {
      title: "Date Added",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => {
        if (createdAt) {
          return moment(createdAt).utc().format("MM/DD/YYYY");
        } else {
          return "--";
        }
      },
    },
    {
      title: "Start Date and Time",
      dataIndex: "startDateAndTime",
      key: "startDateAndTime",
      render: (startDateAndTime) => {
        if (startDateAndTime) {
          return moment(startDateAndTime).utc().format("MM/DD/YYYY h:mm a");
        } else {
          return "--";
        }
      },
    },
    {
      title: "End Date and Time",
      dataIndex: "endDateAndTime",
      key: "endDateAndTime",
      render: (endDateAndTime) => {
        if (endDateAndTime) {
          return moment(endDateAndTime).utc().format("MM/DD/YYYY h:mm a");
        } else {
          return "--";
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        if (status) {
          return <StyledText status={status}>{toTitleCase(status)}</StyledText>;
        } else {
          return "--";
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            <EditButton
              icon={<DataTableEditIcon />}
              onClick={() => {
                history.push("/marketing/mwellness-score-edit", {
                  data: {
                    imageFileName: record?.attachment.imageFileName,
                    attachment: record?.imageUrl,
                    bannerName: record?.bannerName,
                    deepLink: record?.deepLink,
                    status: record?.status,
                    startDateAndTime: record?.startDateAndTime,
                    endDateAndTime: record?.endDateAndTime,
                    pages: record?.pages,
                    _id: record?._id,
                  },
                });
              }}
            />

            <DeleteButton
              icon={<DataTableDeleteIcon />}
              onClick={() => {
                setSelectedBannerId(record._id);
                setIsConfirmationModalOpen(true);
              }}
            />
          </>
        );
      },
    },
  ];

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleDeleteBanner = async () => {
    try {
      const apiResponse = await ServiceMarketingsApi.deleteBanner(
        selectedBannerId
      );
      console.log("DELETE BANNER (MWELLNESS Banner): ", apiResponse);
      setIsConfirmationModalOpen(false);
      getMwellnessScore();

      if (apiResponse.status === 200) {
        setIsSuccessModalOpen(true);
      } else if (apiResponse?.status === 400) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(<>{"Delete Failed. Please try again. (400)"}</>);
        setProcessFailedModalWidth(530);
      } else if (apiResponse?.status === 403) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>{"Delete Failed. Forbidden Request. Please try again. (403)"}</>
        );
        setProcessFailedModalWidth(530);
      } else if (apiResponse?.status === 404) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {"Delete Failed. API resource not found. Please try again. (404)"}
          </>
        );
        setProcessFailedModalWidth(530);
      } else if (apiResponse?.status === 500) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>{"Something went wrong!. Server Error. Please try again. (500)"}</>
        );
        setProcessFailedModalWidth(530);
      } else if (
        apiResponse?.status > 503 ||
        apiResponse === undefined ||
        apiResponse?.status === undefined
      ) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {
              "Service Unavailable/Forbidden Request. Please try again later. (503/403)"
            }
          </>
        );
        setProcessFailedModalWidth(530);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleCloseModal = () => {
    setIsSuccessModalOpen(false);
    // setIsFailedModalOpen(false);
    history.push("/marketing/mwellness-score");
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  return (
    <>
      <SuccessModal
        style={{ marginTop: 130 }}
        title={"Success"}
        banner={<SuccessModalCheckIcon />}
        description={"Banner successfully Deleted!"}
        buttonText={"Continue"}
        buttoncolor={"#004f99"}
        visible={isSuccessModalOpen}
        width={530}
        onOk={() => {
          setIsSuccessModalOpen(false);
        }}
        onCancel={handleCloseModal} // x and cancel button
      />
      <ProcessFailedModal
        style={{ marginTop: 130 }}
        title={"PROCESS FAILED"}
        banner={<ProcessFailedModalCrossIcon />}
        description={processFailedBody}
        buttonText={"Dismiss"}
        buttoncolor={"#004f99"}
        visible={isFailedModalOpen}
        width={processFailedModalWidth}
        onOk={handleCloseModal}
        onCancel={handleCloseModal} // x and cancel button
      />
      <CustomModal
        title={"Banner Deletion"}
        headercolor={"#B90C0C"}
        style={{ marginTop: 130 }}
        banner={<TrashBinIcon />}
        description={
          <p style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 20 }}>
            Are you sure you want to delete this banner?
          </p>
        }
        buttonText={"YES"}
        buttoncolor={"#004f99"}
        visible={isConfirmationModalOpen}
        width={530}
        // onOk={handleCloseModal}
        onCancel={handleCloseConfirmationModal} // x and cancel button
        footer={[
          <FooterWrapper>
            <StyledUpperFooter>
              <StyledButtonYesOrOk onClick={handleDeleteBanner}>
                YES
              </StyledButtonYesOrOk>
              <StyledButtonNo onClick={handleCloseConfirmationModal}>
                NO
              </StyledButtonNo>
            </StyledUpperFooter>
          </FooterWrapper>,
        ]}
      />

      <TableBox ref={tableRef}>
        <Toolbar gutter={14}>
          <ToolItem span={12}>
            <ContainerLabelPrimary style={{ marginTop: 10 }}>
              mWellness Score
            </ContainerLabelPrimary>
            <FieldLabel>
              Banners may be made visible on the following pages: mWellness
              Score, Fitness, Food & Nutrition.
            </FieldLabel>
          </ToolItem>
          <ToolItem flex={true}>
            <StyledSearch
              placeholder="Enter Keyword"
              allowClear
              suffix={<SearchIcon />}
              value={searchQuery}
              onChange={(e) => {
                handleChangeSearchQuery(e);
              }}
              onPressEnter={getMwellnessScore}
              style={{ width: 200 }}
            />
            <StyledButton
              type="primary"
              onClick={() => UI.goTo("/marketing/mwellness-score-add")}
            >
              Add Banner
            </StyledButton>
          </ToolItem>
        </Toolbar>
        <DataTable
          hidePaginationOnSinglePage={true}
          hideTotalSummaryOnSinglePage={true}
          tableColumnHeader={tableColumnHeader}
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          defaultPageSize={30}
          pageSizeOptions={["30", "100"]}
          tableEntries={tableData?.length ?? 32767}
          isBackendPaginated={true}
          loading={isTableLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default MwellnessScoreTable;

// CSS Styled Components ----------------------

const Toolbar = styled(Row)`
  display: flex;
  align-items: start;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 20px;
  justify-content: space-between;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #afafaf;
  font-size: 16px;
  line-height: 1.5;
  margin-right: 0;
  display: block;
  margin-top: 15px;
  margin-bottom: 20px !important;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const EditButton = styled(Button)`
  height: 50px;
  width: 50px;
  border: none;
`;

const DeleteButton = styled(Button)`
  margin-left: 5px;
  height: 50px;
  width: 50px;
  border: none;
`;

const StyledGripIcon = styled(DraggableDotsIcon)`
  font-size: 1.2rem;
  cursor: grab;

  //Mimic onMouseDown
  :active:hover {
    cursor: grabbing;
  }
`;

const ToolItem = styled(Col)`
  position: relative;
  display: ${(props) => (props.flex ? "flex" : "block")};
  align-items: ${(props) => (props.center ? "center" : "start")};
`;

const StyledButton = styled(Button)`
  width: 189px;
  height: 60px;
  border-radius: 12px;
  border: none;
  background-color: #004f99;
  color: #ffffff;
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledText = styled(Typography.Text)`
  color: ${(props) => (props.status === "ACTIVE" ? "#2DCB70" : "#535353")};
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 60px;
  width: 411.72px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 1.125rem; // 18px
    line-height: 1.4375rem; // 23px
  }
`;

const FooterWrapper = styled.div`
  margin-bottom: 25px;
`;

const StyledUpperFooter = styled(Row)`
  align-items: center;
  justify-content: center;
`;

const StyledButtonYesOrOk = styled(Button)`
  font-size: 23px;
  font-family: Poppins;
  font-weight: 600; //medium
  color: #ffffff;
  background-color: #004f99;

  border: 2px solid #004f99;
  border-radius: 12px;
  min-width: 162px;
  height: 74px;

  &:hover {
    background-color: #004f99;
    color: #ffffff;
  }

  &:focus {
    background-color: #004f99;
    color: #ffffff;
  }

  &:active {
    background-color: #004f99;
    color: #ffffff;
  }
`;

const StyledButtonNo = styled(Button)`
  margin-left: 20px !important;
  font-size: 23px;
  font-family: Poppins;
  font-weight: 600; //medium
  color: #004f99;
  background-color: #ffffff;

  border: 2px solid #004f99;
  border-radius: 12px;
  width: 162px;
  height: 74px;

  &:hover {
    color: #004f99;
    background-color: #ffffff;
  }

  &:focus {
    color: #004f99;
    background-color: #ffffff;
  }

  &:active {
    color: #004f99;
    background-color: #ffffff;
  }
`;
