import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import { 
  // StyleSheet,
   Text, View } from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
// import InputForm from "../_components/InputForm";
import Layout, { PreviewFile } from "../_components/Layout";
import { MemorandumOfAgrrementModal } from "../_components/Modal";
import "./css.css";

const Constants = new APIConstants();
export default class ThirdForm extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const _this = this.props._this;
    const { 
      // height,
      width, 
      //  isMobile,
      // file_moa_path,
      file_mpc, file_signature_path, hospitals,
      // languanges, 
      // downloaded_mpc,
     file_profile_path, 
     years } = _this.state;
    const row_width = width > 1000 ? width - 400 : "100%";

    const Hospitals = hospitals.map((item) => {
      return <option value={item.id}>{item.name}</option>;
    });
    const Languanges = Constants.languanges.map((item) => {
      return <option value={item}>{item}</option>;
    });

    const HOM_AFFILIATIONS = Constants.hmo_affiliations.map((item) => {
      return <option value={item}>{item}</option>;
    });
    
    return (
      <UI.Column
        style={{ marginBottom: 10 }}
        _this={_this}
        breakpoint={360}
        breakpoint_2={720}
      >
        <UI.Row
          style={{
            width: row_width,
            justifyContent: "space-between",
            marginTop: 20,
          }}
          _this={_this}
          breakpoint={1000}
        >

          {UI.box(10)}

          <View style={{ flex: 1 }}>
            {/* <ProfilePicture _this={_this}/> */}
            <Layout.FilePicker
              _this={_this}
              title={"Profile Picture"}
              additional={"(.jpeg or .png only)"}
              isRequired={true}
              text={"Drop Your File Here"}
              state_name={"profile"}
              onChange={(file) => {
                _this.auth_upload("profile_path", file);
                console.log(_this.state)
              }}
              allowed_files={["jpg", "jpeg", "png"]}
              display_type={"filename_size"}
            />

            {UI.box(10)}

            <Text style={{ marginTop: -3, fontSize: 14, fontWeight:'bold', color:'#5E6D7D'  }}>
              For Profile photo: (Close up photo wearing your coat and without distracting background).
            </Text>
            
            <PreviewFile _this={_this} {...file_profile_path} />
          </View>

          {UI.box(5)}
          <View style={{ flex: 1 }}>
            <Layout.FilePicker
              _this={_this}
              title={"Signature"}
              additional={"(.jpeg or .png only)"}
              isRequired={true}
              text={"Drop Your File Here"}
              state_name={"signature"}
              onChange={async (file) => {
                _this.auth_upload("signature_path", file);
              }}
              allowed_files={["jpg", "jpeg", "png"]}
              display_type={"filename_size"}
            />

            {UI.box(10)}

            <Text style={{ marginTop: -3, fontSize: 14, fontWeight:'bold', color:'#5E6D7D'  }}>
              For Signature: (Must be black ink on white background) 
            </Text>

            <PreviewFile _this={_this} {...file_signature_path} />
          </View>

          {UI.box(10)}

          <View style={{ flex: 1 }}>
            <Layout.FilePicker
              _this={_this}
              title={"Medical Practitioner Certificate"}
              additional={"(.jpeg, .png or .pdf only)"}
              isRequired={false}
              text={"Drop Your File Here"}
              state_name={"mpc"}
              onChange={(file) => {
                _this.auth_upload("mpc", file);
              }}
              allowed_files={["jpg", "jpeg", "pdf", "png"]}
              display_type={"filename_size"}
            />

            {UI.box(10)}

            <Text style={{ marginTop: -3, fontSize: 14, fontWeight:'bold', color:'#5E6D7D'  }}>
              Certificate/Proof of Specialization (e.g. Diplomate Certificate/Certificate of Passing Diplomate Board Exams/Certificate of Completion of Residency Training). If none, your PRC Certificate.
            </Text>

            <PreviewFile _this={_this} {...file_mpc} />
          </View>

        </UI.Row>
        {UI.box(20)}
        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <View
            style={{
              flex: 1
            }}
          >
          <Layout.SelectPicker
            _this={_this}
            title={"Hospital Affiliation"}
            isRequired={true}
            state_name={"hospital"}
            isMultiple={true}
          >
              <option value="">Select Hospital</option>
            {Hospitals}
          </Layout.SelectPicker>
            
          </View>

          {/* {UI.box(20)}

          <div className='flex items-end'>
            <TouchableOpacity onClick={() => {
              console.log('first')
            }}>
              <button className="flex items-center px-4 py-3 text-white gap-2 rounded-md font-semibold" style={{background:'#0056AE'}} 
              >
                Add Hospital
              </button>
            </TouchableOpacity>
          </div> */}

          {UI.box(20)}
 
          <View
            style={{
              flex: 1
            }}
          >
            <Layout.SelectPicker
            _this={_this}
            title={"Language/s Spoken"}
            isRequired={true}
            state_name={"languange"}
            isMultiple={true}
          >
              <option value="">Select Specialty</option>
            {Languanges}
          </Layout.SelectPicker>
    
          </View>

          {/* {UI.box(20)}

          <div className='flex items-end'>
            <TouchableOpacity onClick={() => {
            }}>
              <button className="flex items-center px-4 py-3 text-white gap-2 rounded-md font-semibold" style={{background:'#0056AE'}} 
              >
                Add Language
              </button>
            </TouchableOpacity>
          </div> */}
          {/* <MOA _this={_this}/> */}

          {UI.box(10)}

          {/* <InputForm
            _this={_this}
            title={"Years of Practice"}
            placeholder={"Enter Years of Practice"}
            state_name={"years_of_practice"}
            style={{ flex: 1 }}
            isNumber={true}
            maxLength={7}
            isRequired={true}
          /> */}

            <Layout.SelectPicker
              _this={_this}
              title={"Start Year of Practice"}
              isRequired={true}
              state_name={"years_of_practice"}
            >
              <option value="">Select Years</option>
              {years?.map((year) => {
                return year ? (
                  <option value={year}>{year}</option>
                ) : (
                  <View></View>
                );
              })}
            </Layout.SelectPicker>

          {/* <View style={{flex: 1}}></View> */}
        </UI.Row>
        {UI.box(20)}

        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <View
            style={{
              flex: 1
            }}
          >
          <Layout.SelectPicker
            _this={_this}
            title={"HMO Affiliation"}
            state_name={"hmo_affiliation"}
            isMultiple={true}
          >
              <option value="">Select HMO Affiliation</option>
            {HOM_AFFILIATIONS}
          </Layout.SelectPicker>
            
          </View>

          {UI.box(20)}

          <View style={{flex:1}}>
            <Text
              style={{ color: UI.colors.primary, fontSize: 14, fontWeight: "bold" }}
            >
              Memorandum of Agreement <span className="text-red-500">*</span>
            </Text>

            {UI.box(10)}

            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Layout.Btn
                color={UI.colors.primary}
                onClick={() => {
                  _this.show_modal(
                    <MemorandumOfAgrrementModal
                      _this={_this}
                      title="Memorandum Of Agreement"
                      descriptionOne="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available."
                      descriptionTwo="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available."
                      descriptionThree="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available."
                      checkboxTitle="I confirm that I have read and that I agree to the terms stated in this Terms of Use."
                      onOk={{
                        text:'Continue',
                        method: () => {
                          _this.hide_modal();
                          // window.location.href = "http://www.mwell.com.ph/advocacy/"
                        },
                      }}
                      onDismiss={() => {
                        _this.hide_modal();
                      }}
                    />
                  )
                }}
                text={"View And Accept Terms"}
              />
            </View>
            {UI.box(10)}
            <Text
                style={{ color: UI.colors.sunset, fontSize: 13, fontWeight: "bold" }}
              >
                Please review the MOA and accept terms.
              </Text>
          </View>

          {UI.box(10)}

          <View style={{flex: 1}}></View>

        </UI.Row>

        {UI.box(20)}

        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
        </UI.Row>
      </UI.Column>
    );
  }
}

// const styles = StyleSheet.create({
//   download_text: {
//     color: "white",
//     fontWeight: "bold",
//     fontSize: 12,
//   },
//   download_button: {
//     padding: 10,
//     justifyContent: "center",
//     alignItems: "center",
//     backgroundColor: UI.colors.primary,
//     marginLeft: 10,
//     borderRadius: 5,
//     height: 30,
//     marginTop: -5,
//   },
// });
