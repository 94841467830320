import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import "./css.css";
import EcommerceTransactionsTable from "./EcommerceTransactionsTable";

const context = "merchant";
export default class EcommerceTransactions extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Transactions",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,
      groupSortAscDesc: ["createdAt"],

      groupSortAscDescSelected: "",
      paymentStatusSelected: "",
      orderStatusSelected: "",

      sort_method: "",
      sort_product: "",
      sort_created: "",

      product_names: [],
      product_ids: [],
      method_names: [],
      payment_status_names: [],
    });
  }

  onCreate = async () => {};


  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <EcommerceTransactionsTable _this={this} />
      </MainLayout>
    );
  }
}