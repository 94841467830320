import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, PreviewMarketingQrCodeReferralModal, Success } from "../_components/Modal";
import CopyToClipboard from "react-copy-to-clipboard";
import ServiceMarketingsApi from "../../api_services/marketings";
import styled from "styled-components";
import { Col, Input, Row, Typography } from "antd";

const context = "marketing";

export default class MarketingCampaignReferralsAdd extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Campaign Referrals",
      is_drawer_open: false,
      selected: null,
      context: context,
      name: "",
      link: "",
      image_file:"",
      isCopyLink:false,
      status: "Inactive",
      start_date: "",
      end_date: "",
      description: ""
    });
  }

  onCreate = () => {};

  // generateCode = () => {
  //   const randomCode = randomstring.generate({
  //     length: 20,
  //     charset: 'alphanumeric'
  //   });

  //   this.setState({
  //     code: randomCode
  //   })
  // }

  submit = async (statusValue) => {
    UI.clear_errors();
    const { status , name, link, start_date, end_date, description} = this.state;

    if (!name) {
      await UI.error_form("name", "Name is required.");
    }

    // if (!region) {
    //   await UI.error_form("region", "Region is required.");
    // }

    // if (!status) {
    //   await UI.error_form("status", "Status is required.");
    // }
    
    if (!link) {
      await UI.error_form("link", "Link is required.");
    }
    
    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    const payload = {
      link: link,
      name: name,
      // status: status ? status : statusValue,
      status: status,
      startDate: start_date,
      endDate: end_date,
      description: description
      // region: region
    }

    this.show_loading();
    ServiceMarketingsApi.createCampaignReferral(payload).then( res => {
      if (res.status === 200) {
        let data = res;
        this.setState({ link: data.link})
        this.show_modal(<Success
          descriptionCenter={"Successfully Created campaign referral."}
          onDismiss={() => {
            this.hide_loading();
            UI.goBack()
            this.hide_modal();
          }}
        />);
      } else {
        this.show_modal(
          <Failed
            description={res.message} 
            onDismiss={() => { 
              UI.goBack()
              this.hide_loading();
            }} 
          />
        );
      }
    })
  };
  
  
  render() {
    const _this = this;

    return (
      <MainLayout context={context} additional={"specialties"} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Add Campaign
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add campaign
            </Text>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Event Name"}
              placeholder={"Enter Name"}
              state_name={"name"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.name}
            />
            {/* {UI.box(10)}
          
            <Layout.SelectPicker
              _this={_this}
              title={"Region"}
              isRequired={true}
              state_name={"region"}
            >
              <option value="">--select region--</option>
              <option value="Calabarzon">Calabarzon</option>
              <option value="NCR">NCR</option>
            </Layout.SelectPicker> */}
            {UI.box(10)}

            <Layout.DatePicker
              disabledPastDates={true}
              _this={_this}
              title={"Start Date"}
              // isWithTimePicker={true}
              placeholder={"Start Date"}
              state_name={"start_date"}
              will_correct={true}
              // isRequired={true}
            />

            {UI.box(10)}
            
            <Layout.DatePicker
              disabledPastDates={true}
              _this={_this}
              title={"End Date"}
              // isWithTimePicker={true}
              placeholder={"End Date"}
              // isRequired={true}
              state_name={"end_date"}
              // will_correct={true}
            />

            {UI.box(10)}

            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={true}
              state_name={"status"}
              onChange={(e) => {
                // this.setState({ status: e})
                // if (e === "Active") {
                //   this.setState({
                //     link: "www://samplelinkforlink.com/abcde312543"
                //   })
                  
                //   // this.submit(e)
                // } else {
                //   this.setState({
                //     link: ""
                //   })
                // }
              }}
            >
              <option value="Active">Active</option>
              <option value="Inactive" selected={true}>Inactive</option>
            </Layout.SelectPicker>
            {/* <View style={{ flex: 1 }}></View> */}
          </UI.Row>
          {UI.box(20)}
          
          {/* <MarketingAddRow> */}
            <ColBar gutter={16}>
              <ToolItem className="gutter-row" span={6}>
                <StyledFieldLabel>Attribution Link <strong style={{color:'red'}}>*</strong></StyledFieldLabel>
                <StyledInput
                  placeholder=""
                  suffix={this.state.link && 
                    <>
                      <div className="">
                        <CopyToClipboard
                          text={this.state.link}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg"
                              onClick={() => {
                                this.setState({ isCopyLink: true });
                                setTimeout(() => {
                                  this.setState({ isCopyLink: false });
                                }, 2000);
                              }}
                            fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer" style={{ color: UI.colors.primary}}>
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                            </svg>
                        </CopyToClipboard>
                      </div>
                    
                      <span
                        style={{
                          position: "absolute",
                          top: 0,
                          left: "100%",
                          marginLeft: 10,
                          marginTop: 12,
                          color: UI.colors.secondary,
                        }}
                      >
                        {this.state.isCopyLink && "Copied"}
                      </span>
                    </>}
                  onChange={(e) => {
                    this.setState({ link: e.target.value})
                  }}
                />
              </ToolItem>
            </ColBar>
          {/* </MarketingAddRow> */}

          {/* <UI.Row breakpoint={970} _this={_this}>

            <View style={{ flex: 1, position:'relative' }}>
            </View>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row> */}

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Campaign Description (Optional)"}
              placeholder={"Enter Description Here"}
              state_name={"description"}
              style={{ flex: 1 }}
              height={100}
              isRequired={false}
              type={"big"}
              maxLength={UI.Constants.limits.description}
            />
          </UI.Row>

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <Layout.Btn
              isDisabled={this.state.link ? false : true}
              color={UI.colors.secondary}
              onClick={() => {
                this.show_modal(
                  <PreviewMarketingQrCodeReferralModal _this={this}/>
                )
              }}
              style={{ height:50 }}
              text={"Generate QR Code"}
            />
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row>


          {UI.box(30)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.secondary}
              onClick={() => {
                this.submit();
              }}
              text={"CREATE"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const ColBar = styled(Row)`
  display: inline-flex;
  align-items: flex-start;
  /* padding-left: 35px;
  padding-right: 35px; */
  padding-bottom: 0;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;


const StyledInput = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const ToolItem = styled(Col)`
  position: relative;
  .ant-picker {
    margin-left: 102px;
  }
  .ant-picker-range {
    margin-left: 62px;
  }
`;

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
