import React, { Component } from 'react'
import { TimePicker } from "antd";
import UI from '../../shared/Components/UI/js';
import TouchableOpacity, { Image, Shadow, StyleSheet, Text, View } from '../../shared/custom-react-native';
import CustomDatatable from '../_components/CustomDatatable';
import { Checkbox } from '../_components/InputForm';
// import Layout from '../_components/Layout';

export default class AddSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: [
        { id: 0, day: "Sunday", isChecked: false, startTime:"", endTime:"" },
        { id: 1, day: "Monday", isChecked: false, startTime:"", endTime:"" },
        { id: 2, day: "Tuesday", isChecked: false, startTime:"", endTime:"" },
        { id: 3, day: "Wednesday", isChecked: false, startTime:"", endTime:"" },
        { id: 4, day: "Thursday", isChecked: false, startTime:"", endTime:"" },
        { id: 5, day: "Friday", isChecked: false, startTime:"", endTime:"" },
        { id: 6, day: "Saturday", isChecked: false, startTime:"", endTime:"" },
      ]
    };
  }

  setRecurringDays = (day) => {
    console.log(day)
    let index = this.props._this.state.recurringDays?.findIndex(val => val.day === day.id )

    if(index === -1) {
      this.props._this.setState({ recurringDays: [...this.props._this.state.recurringDays, { day: day.id, startTime: "", endTime:"" }] })
    } else {
      this.props._this.setState({ 
        recurringDays: this.props._this.state.recurringDays.filter(item => item.day !== day.id)
      })
    }

    let days = this.state.days.map((row) => {
        if (row.id === day.id) {
          if (row.isChecked) {
            row.isChecked = false
          } else {
            row.isChecked = true;
          }
      }
      return row
    });
    this.setState({ days: days })
  }

  onChangeTime = (time, timeString, day , id, isStart=false) => {
    let index = this.props._this.state.recurringDays?.findIndex(val => val.day === day.id)

    if (isStart) {
      this.props._this.state.recurringDays[index].endTime = timeString 
    } else {
      this.props._this.state.recurringDays[index].startTime = timeString 
    }
  }

  render() {
    const {_this} = this.props
    return (
      <>
        {UI.box(20)}

        <h1 h1 className="font-semibold" style={{color:UI.colors.primary, fontSize:18}}>Schedule</h1>

          <CustomDatatable
            state_name={"schedule_list"}
            _this={_this}
            columns={[
              // { ID: "id" },
              { "Recurring Days": "recurring_days"},
              { "Start Time": "start_time" },
              { "End Time": "end_time" },
              { Action : "action"}
            ]}
            data={_this.state.arrayOfSchedule.map((item, index) => {
              // const startDate = UI.get_date_string_by_date(item.startDate);
              // const endDate = UI.get_date_string_by_date(item.endDate);

              return {
                recurring_days: item.map(day => {
                  
                  if (day.day === 1) {
                    day.days = "Monday"
                  }
                  if (day.day === 2) {
                    day.days = "Tuesday"
                  }
                  if (day.day === 3) {
                    day.days = "Wednesday"
                  }
                  if (day.day === 4) {
                    day.days = "Thursday"
                  }
                  if (day.day === 5) {
                    day.days = "Friday"
                  }
                  if (day.day === 6) {
                    day.days = "Saturday"
                  }
                  if (day.day === 0) {
                    day.days = "Sunday"
                  }
              
                  return <span>{day.days} <br></br></span>
                }),
                start_time:  item.map(start => <span>{ start.startTime} <br></br></span> ),
                end_time: item.map(start => <span>{ start.endTime} <br></br></span>),
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        const filter = _this.state.arrayOfSchedule.filter((a, f) =>  f !== index)
                        _this.setState({
                          arrayOfSchedule: filter
                        },
                        () => {
                          _this.reload_table("schedule_list");
                        });
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.DELETE_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
          />

          {UI.box(15)}

          <View
            style={{
              ...styles.no_info_container,
              display: _this.state.arrayOfSchedule.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center", height: 0, marginTop: 0 }}
              _this={_this}
            >
              <Image
                height={50}
                width={50}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  // textAlign: width > 820 ? "left" : "center",
                  // marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO AVAILABLE SCHEDULE
              </Text>
            </UI.Row>
          </View>
        
        {/* <UI.Row breakpoint={970} _this={_this}>

          <Layout.DatePicker
            _this={_this}
            title={"Start Date and Time"}
            isRequired={true}
            placeholder={"mm/dd/yyyy"}
            isWithTimePicker={true}
            state_name={"startDateTime"}
            date_props={{}}
          />
          {UI.box(10)}
          <Layout.DatePicker
            _this={_this}
            title={"End Date and Time"}
            isRequired={true}
            isWithTimePicker={true}
            placeholder={"mm/dd/yyyy"}
            state_name={"endDateTime"}
            date_props={{}}
            
          />
        </UI.Row> */}

        {UI.box(20)}

        <UI.Row breakpoint={970} _this={_this}>
          <View style={{flex:1}}>
            <span className="font-semibold" style={{color: UI.colors.primary}}>
              Recurring Days
            </span>
            
            {UI.box(20)}

            <View>
              {
                this.state.days.map((day) => (
                  <>
                  <View className="grid grid-cols-2" style={{flexDirection:'row'}}>
                    <Checkbox
                      _this={_this}
                      text={day.day}
                      onClick={() => this.setRecurringDays(day)}
                      is_checked={day.isChecked}
                      color={"#2E2E2E"}
                    />
                    {day.isChecked && <>
                      {UI.box(20)}
                      <TimePicker
                        // value={this.setDefaultValueTime(slots, "friday", "afternoon", "end")}
                        minuteStep={30}
                        use12Hours 
                        format="h:mm a"
                        placeholder='Start Time'
                        onChange={(time, timeString) => { 
                        this.onChangeTime(time, timeString, day, day);
                      }}/>
                      {UI.box(20)}
                      <TimePicker
                        // value={this.setDefaultValueTime(slots, "friday", "afternoon", "end")}
                        minuteStep={30}
                        use12Hours 
                        format="h:mm a"
                        placeholder='End Time'
                        onChange={(time, timeString) => { 
                        const id = 12;
                        const isStart = true;
                        this.onChangeTime(time, timeString, day, id, isStart);
                      }}/>
                    </>}
                  </View>
                  {UI.box(20)}
                  </>
                )) 
            }
              
            </View>

          </View>
        </UI.Row>

        {UI.box(20)}
        
        {this.state.scheduleSuccessMessage && <h1 style={{color:UI.colors.active}}>{this.state.scheduleSuccessMessage}</h1>}

        <Text style={{color:'gray'}}>- You can add multiple schedule once you save.</Text>

        {UI.box(5)}

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TouchableOpacity>
            <button className="text-white py-1 px-2 rounded" style={{background:UI.colors.primary}} onClick={() => {
              _this.saveSchedule()
            }}>
              Add Schedule
            </button>
          </TouchableOpacity>
        </View>

    </>
    )
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
});
