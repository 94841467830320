import React, { useCallback, useState, useEffect } from "react";
import DataTable from "../../ComponentsFC/DataTable";
import styled from "styled-components";
import { Row, Col, Button, Input, Select, Typography, DatePicker } from "antd";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import ServiceMarketingsApi from "../../api_services/marketings";
import UI from "../../shared/Components/UI/js";
import TouchableOpacity, { Image, mem, Text, View } from "../../shared/custom-react-native";
import moment from "moment";

const MarketingEventReferralsTable = ({ _this }) => {
  const [tableData, setTableData] = useState([]);
  const [searchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortByCreatedAt, setSortByCreatedAt] = useState("");
  const [sortByReferralCount, setSortByReferralCount] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(10);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [startDate, setsStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getCampaignReferrals = useCallback(async () => {
    try {
      setIsLoading(true);

      let params = {};

      if (searchQuery) {
        params.keyword = searchQuery;
      }

      if (sortByCreatedAt) {
        params.createdAt = sortByCreatedAt;
      }

      if (sortByReferralCount) {
        params.sort = sortByReferralCount;
      }

      if (currentPageNum) {
        params.page = currentPageNum;
        params.limit = tableEntries;
      }

      if (startDate) {
        params.startDate = startDate;
      }

      if (endDate) {
        params.endDate = endDate;
      }

      ServiceMarketingsApi.getCampaignReferrals(params).then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          let data = res.data;
          setTotalRecords(res.count);
          setTableData(data.map((obj) => ({ ...obj, key: obj._id })));
        }
      });
    } catch (err) {
      // Handle Errors Here
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }, [currentPageNum, tableEntries, searchQuery, sortByCreatedAt, sortByReferralCount, startDate, endDate]);

  useEffect(() => {
    getCampaignReferrals();
  }, [getCampaignReferrals]);

  const handlePaginateTable = (pageNum) => {
    // console.log("Currently Selected Page # of Antd Table: ", pageNum);
    setCurrentPageNum(pageNum);
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleCSVExport = () => {
    const csv_data = tableData.map((item, index) => {
      const d = {
        "Name": item.name ?? "--",
        "Date Created": item.createdAt ? UI.momentPHTimezone(item.createdAt).format('MM/DD/YYYY') : "--",
        "Status": item.status ?? "--",
        "Registered Referrals": item.members?.length ?? "--",
      };

      return d;
    });

    UI.DownloadCSV(`Campaign Referrals ${UI.get_current_date_string()}`, csv_data);
  };

  const tableColumnHeader = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => name ?? "",
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => UI.momentPHTimezone(createdAt).format('MM/DD/YYYY') ?? "--",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, item) => UI.get_active_text(item ?? "") ?? "--",
    },
    {
      title: "Registered Referrals",
      dataIndex: "referralCount",
      key: "referralCount",
      render: (referralCount, item) => item.members?.length ?? "--",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (key, item) => {
          return <View style={{ flexDirection: "row", gap: 10 }}>
            <TouchableOpacity
              onClick={() => {
                console.log(item)
                  UI.goTo('/marketing/campaign-referrals-view')
                  mem.save('campaign_referral_view', JSON.stringify(item))
              }}
            >
              <Image
                height={28}
                width={28}
                tintColor={"transparent"}
                source={UI.VIEW_BTN_1}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onClick={() => {
                  UI.goTo('/marketing/campaign-referrals-edit')
                  mem.save('campaign_referral_edit', JSON.stringify(item))
              }}
            >
              <Image
                height={28}
                width={28}
                tintColor={"transparent"}
                source={UI.EDIT_BTN_1}
              />
            </TouchableOpacity>
          </View>
      },
  },
  ];

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPageNum(1);
    setTableEntries(30);
  };

  const handleFilterByShiftDateRange = (dates, dateStrings) => {
    setsStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  return (
    <>
      <FilterBox>
        <Text style={{
          fontWeight: "bold",
          color:UI.colors.secondary,
          paddingLeft: 35,
          fontSize: 17
        }}>
          Campaign Referrals
        </Text>
        <Toolbar gutter={14}>
          <ToolItem span={8}>
            <StyledFieldLabel>Date</StyledFieldLabel>
            <StyledRangePicker
              style={{
                width: "100%",
              }}
              separator={<span className="mr-6">-</span>}
              size="large"
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
              onChange={handleFilterByShiftDateRange}
            />
          </ToolItem>
          <ToolItem span={8}>
            <StyledFieldLabel>Referrals Count</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Sort Options"
              style={{
                width: "100%",
              }}
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setSortByReferralCount(value)
                setSortByCreatedAt("")
              }}
            >
              <Select.Option value="desc">Highest to Lowest</Select.Option>
              <Select.Option value="asc">Lowest to Highest</Select.Option>
            </StyledSelect>
          </ToolItem>
        </Toolbar>
    </FilterBox>

    <TableBox>
      <Toolbar>
        <ToolItem span={8} style={{display:'flex', gap: 10, alignItems:'center'}}>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="10"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
            <Select.Option value="100">100 Entries</Select.Option>
          </StyledSelect>
        </ToolItem>
        <StyledSearch
          placeholder="Enter Keyword"
          allowClear
          suffix={<SearchIcon />}
          value={searchQuery}
          onChange={(e) => {
            handleChangeSearchQuery(e);
          }}
          onPressEnter={getCampaignReferrals}
          style={{ width: 200 }}
        />
          <StyledButton type="primary" onClick={handleCSVExport}>
            Export to CSV
          </StyledButton>
          <StyledButton type="primary" onClick={() => {
          UI.goTo('/marketing/campaign-referrals-add')
          }}>
          Add Campaign
        </StyledButton>
      </Toolbar>
      <DataTable
        tableColumnHeader={tableColumnHeader}
        dataSource={searchResults.length > 0 ? searchResults : tableData}
        defaultPageSize={10}
        pageSizeOptions={["10", "30", "100"]}
        tableEntries={tableEntries}
        isBackendPaginated={true}
        totalRecords={totalRecords}
        onPaginate={handlePaginateTable}
        loading={isLoading}
        scroll={{
          x: 600,
        }}
      />
    </TableBox>
    </>
  );
};

export default MarketingEventReferralsTable;

const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  flex-direction: row;
  border-radius: 12px;
  border-width: 2px !important;
  height: 50px;
  margin-left: auto;

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  .ant-picker-range-separator {
    display: block !important;
  }
`;

const ToolItem = styled(Col)`
  position: relative;
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;