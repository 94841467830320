import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  ImageBackground,
  ImageFit,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
// import ServiceLGUSApi from "../../api_services/lgu_partners";

const context = "admin";

export default class AdminCmsLguPartnersAdd extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "App Content",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      name: "",
      status: "",
      image: UI.PRODUCT_BOX,
      professionalType: "",

    });
  }

  onCreate = () => {};

  upload_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        image: file.uri,
        image_file: file.file,
        did_upload: true,
      }
    );
  };

  submit = async () => {
    UI.clear_errors();
    const { name, status, image_file } = this.state;

    if (!name) {
      await UI.error_form("label", "LGU Name is required.");
    }

    if (!status) {
      await UI.error_form("status", "Status is required.");
    }

    if (!image_file) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Upload Icon Specialty"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      return;
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    const payload = {
      name,
      status, 
      image: image_file,
      tags: JSON.stringify(["62e163f3709eac95f3c3c19b"])
    }

    this.show_loading();

    UI.webRequest({
      method: "post",
      url: "admin/lgu",
      params: payload,
      isMultiPart: true,  
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.error;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully add item"}
            onDismiss={() => {
              this.hide_modal();
              UI.goBack();
            }}
          />
        )
      },
      onFinish: () => {
        this.hide_loading();
      },
    });

    // ServiceLGUSApi.addLgu(payload).then( res => {
    //   this.hide_loading();
    //   if (res.status === 200) {
    //     // let data = res;
    //     this.show_modal(
    //       <Success
    //         title={"Success!"}
    //         description={"Successfully add item"}
    //         onDismiss={() => {
    //           this.hide_modal();
    //           UI.goBack();
    //         }}
    //       />
    //     )
    //   }
    // });
  };
  
  render() {
    const _this = this;
    // const { height, width, isMobile } = _this.state;
    const {  did_upload } = _this.state;

    return (
      <MainLayout context={context} additional={"lgu-partners"} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Add LGU Partner
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add item
            </Text>
          </UI.Row>
          {UI.box(10)}
          <UI.Row breakpoint={970} _this={_this}>
              <View style={{ height: 120, width: 120 }}>
                <Layout.FilePicker
                  _this={_this}
                  title={""}
                  additional={""}
                  text={""}
                  state_name={"icon_specialty"}
                  onChange={async (file) => {
                  }}
                  height={120}
                  component={
                    <ImageBackground
                      style={{ height: 120, width: 120, borderRadius: 10 }}
                      source={this.state.image}
                      imageFit={ImageFit.CONTAIN}
                    />
                  }
                />
              </View>
              {UI.box(20)}
              <strong className="text-red-500">*</strong>
              <View style={{ flex: 1.5, justifyContent: "center" }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
                >
                  {did_upload
                    ? "Specialty Icon Uploaded."
                    : "No Icon Uploaded."}{" "}
                  {/* No Icon Uploaded.  */}
                  Recommended Size: 200 px x 200 px
                </Text>
                {UI.box(10)}

                <Layout.Btn
                  color={UI.colors.secondary}
                  text={"Upload Quicklink/Button Icon"}
                  onClick={() => {
                    this.upload_picture();
                  }}
                  width={220}
                />
              </View>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"LGU Partner Name"}
              placeholder={"Enter LGU Partner Name"}
              state_name={"name"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.name}
            />
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={true}
              state_name={"status"}
            >
              <option value="">Item Status (Active or Inactive)</option>
              <option value="Active" selected={this.state.status ? true : false}>Active</option>
              <option value="Inactive" selected={this.state.status ? true : false}>Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style = {{flex: 1}}></View>
            <View style={{ flex: 1 }}></View>
          </UI.Row>

          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.secondary}
              onClick={() => {
                this.submit();
              }}
              text={"SAVE"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  }
});