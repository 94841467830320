import React, { useState } from 'react'
// useEffect,
import UI from '../../../../shared/Components/UI/js'
import TouchableOpacity, { Image, ImageBackground, ImageFit, ScrollView, StyleSheet, Text, View } from '../../../../shared/custom-react-native'
import CustomDatatable from '../../../_components/CustomDatatable';
import InputForm, 
{ 
  // Checkbox,
  RadioBtn } from '../../../_components/InputForm';
import Layout from '../../../_components/Layout';
import "./../css.css";
import ReactDatePicker from 'react-datepicker';
// import { unescape } from 'he';

const ConsultationNotes = ({ _this, onAddConsultationNotes, setconsultationNotes, consultationNotes, notes, saveEntry }) => {

  const [validationMessage] = useState({
    error:'',
    success:''
  });

  const onChange = async (e) => {
    const {name, value} = e.target;
    consultationNotes[name] = value;
    setconsultationNotes({...consultationNotes});
    await triggerDebounce();
  };

  const triggerDebounce = async () => {
    const payload = onAddConsultationNotes({...consultationNotes})
    await saveEntry("consultation", payload);
  }

  return (
    <div style={{ width:'100%', background:'#F5FAFF'}} className="rounded-b-lg p-3">
      <Text
        style={{
          fontWeight: "bold",
          fontSize: '15px',
          color: "#0E4D94",
        }}
      >
        Notes
      </Text>

      {UI.box(10)}

      <textarea
        style={{
          fontSize: '14px',
          resize: "none",
          color: 'black',
          height: '149px',
          width: "100%",
          padding:'10px 14.44px' ,
          borderRadius:'10px',
          border: '2px solid #C9C9C9',
          marginTop:'5px'
        }}
        placeholder={"You can now write your Subjective, Objective and other notes here..."}
        onChange={onChange}
        name="notes"
        value={consultationNotes.notes}
      >
    </textarea>

    {UI.box(10)}

    {
      (_this.state.appointment?.practitioner.professionalType === false) ? 
    <></>
      : 
    <>

      <div>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: '15px',
            color: "#0E4D94",
          }}
        >
          COVID-19 Assessment
        </Text>
      </div>

      {UI.box(10)}
      
      <div style={{display:'flex', gap:'20px'}}>
        <Text
          style={{
            fontSize: '15px',
            color: "#0E4D94",
          }}
        >
          Is this COVID related?
        </Text>

        <RadioBtn
          style={{borderColor: UI.colors.secondary, borderWidth: 2, borderRadius:7, height:25, width:25}}
          _this={_this}
          text={"Yes"}
          onClick={(value) => {
            const consultation = consultationNotes
            consultation.covidAssessment.isCovidRelated =  true
            consultation.dohCovidReferral.isPatientForErReferral =  ""
            consultation.dohCovidReferral.isPatientForSpecialistReferral =  ""
            consultation.covidAssessment.isIquireCovidConsultation =  ""
            setconsultationNotes({...consultationNotes, consultation});
            triggerDebounce();
          }}
          is_checked={consultationNotes.covidAssessment.isCovidRelated === true}
          color={"#2E2E2E"}
        />
        {/* {UI.box(10)} */}
        <RadioBtn
          style={{borderColor: UI.colors.secondary, borderWidth: 2, borderRadius:7, height:25, width:25}}
          _this={_this}
          text={
            "No"
          }
          onClick={(value) => {
            const consultation = consultationNotes
            consultation.covidAssessment.isCovidRelated =  false
            consultation.covidAssessment.isIquireCovidConsultation =  false
            consultation.covidAssessment.paitentsCovidAssessment= ""
            setconsultationNotes({...consultationNotes, consultation});
            triggerDebounce();
          }}
          is_checked={consultationNotes.covidAssessment?.isCovidRelated === false}
          color={"#2E2E2E"}
        />
      </div>

      {UI.box(10)}

      <div style={{display:'flex', gap:'20px'}}>
        <Text
          style={{
            fontSize: '15px',
            color: "#0E4D94",
          }}
        >
          Did the patient schedule this consultation to inquire about COVID-19?
        </Text>

        <RadioBtn
          disabled={consultationNotes.covidAssessment.isCovidRelated === false}
          style={{borderColor: UI.colors.secondary, borderWidth: 2, borderRadius:7, height:25, width:25}}
          _this={_this}
          text={"Yes"}
          onClick={(value) => {
            const consultation = consultationNotes
            consultation.covidAssessment.isIquireCovidConsultation =  true
            setconsultationNotes({...consultationNotes, consultation});
            triggerDebounce();
          }}
          is_checked={consultationNotes.covidAssessment.isIquireCovidConsultation === true}
          color={"#2E2E2E"}
        />
        {/* {UI.box(10)} */}
        <RadioBtn
          style={{borderColor: UI.colors.secondary, borderWidth: 2, borderRadius:7, height:25, width:25}}
          _this={_this}
          text={
            "No"
          }
          onClick={(value) => {
            const consultation = consultationNotes
            consultation.covidAssessment.isIquireCovidConsultation =  false
            setconsultationNotes({...consultationNotes, consultation});
            triggerDebounce();
          }}
          is_checked={consultationNotes.covidAssessment.isIquireCovidConsultation === false}
          color={"#2E2E2E"}
        />
      </div>

      {UI.box(10)}

      <div style={{display:'flex', gap:'20px'}}>
        <Text
          style={{
            fontSize: '15px',
            color: "#0E4D94",
          }}
        >
          Patient's COVID-19 assessment?
        </Text>

        <RadioBtn
          disabled={consultationNotes.covidAssessment.isCovidRelated === false}
          style={{borderColor: UI.colors.secondary, borderWidth: 2, borderRadius:7, height:25, width:25}}
          _this={_this}
          text={"Suspected"}
          onClick={(value) => {
            const consultation = consultationNotes
            consultation.covidAssessment.paitentsCovidAssessment =  "Suspected"
            setconsultationNotes({...consultationNotes, consultation});
            triggerDebounce();
          }}
          is_checked={consultationNotes.covidAssessment.paitentsCovidAssessment === "Suspected"}
          color={"#2E2E2E"}
        />
        {/* {UI.box(10)} */}
        <RadioBtn
          disabled={consultationNotes.covidAssessment.isCovidRelated === false}
          style={{borderColor: UI.colors.secondary, borderWidth: 2, borderRadius:7, height:25, width:25}}
          _this={_this}
          text={
            "Probable"
          }
          onClick={(value) => {
            const consultation = consultationNotes
            consultation.covidAssessment.paitentsCovidAssessment =  "Probable"
            setconsultationNotes({...consultationNotes, consultation});
            triggerDebounce();
          }}
          is_checked={consultationNotes.covidAssessment.paitentsCovidAssessment === "Probable"}
          color={"#2E2E2E"}
        />
        <RadioBtn
          disabled={consultationNotes.covidAssessment.isCovidRelated === false}
          style={{borderColor: UI.colors.secondary, borderWidth: 2, borderRadius:7, height:25, width:25}}
          _this={_this}
          text={
            "Confirmed"
          }
          onClick={(value) => {
            const consultation = consultationNotes
            consultation.covidAssessment.paitentsCovidAssessment =  "Confirmed"
            setconsultationNotes({...consultationNotes, consultation});
            triggerDebounce();
          }}
          is_checked={consultationNotes.covidAssessment.paitentsCovidAssessment === "Confirmed"}
          color={"#2E2E2E"}
        />
      </div>

      {UI.box(10)}

        <Text
          style={{
            fontWeight: "bold",
            fontSize: '15px',
            color: "#0E4D94",
          }}
        >
          Assessment
        </Text>

        {UI.box(10)}

        <textarea
          style={{
            fontSize: '14px',
            resize: "none",
            color: 'black',
            height: '109px',
            width: "100%",
            padding:'10px 14.44px' ,
            borderRadius:'10px',
            border: '2px solid #C9C9C9',
            marginTop:'5px'
          }}
          placeholder={"Write your impression and assessment here..."}
          onChange={onChange}
          name="assessment"
          value={consultationNotes.assessment}
        >
        </textarea>

        <Text
        style={{
            fontWeight: "bold",
            fontSize: '15px',
            color: "#0E4D94",
          }}
        >
          Select ICD 10
      </Text>

      <div id='icd_10' 
        ref={_this.btn_show_modal}
        style={{
          fontSize: '14px',
          resize: "none",
          color: 'black',
          minHeight: '50px',
          width: "100%",
          padding:'10px 14.44px' ,
          borderRadius:'10px',
          border: '2px solid #C9C9C9',
          marginTop:'5px',
          position: 'relative'
        }}
        onClick={() => {
          _this.show_modal(
          <ScrollView style={{justifyContent:'auto', margin:"30px 0px"}}>
            <View style={{backgroundColor:'white', borderRadius: 10, width:'50%', margin:'auto'}}>
              <div style={{width:'100%'}}>
                <View style={{ ...styles.md_header, backgroundColor: UI.colors.secondary }}>
                  <TouchableOpacity 
                  onClick={() => {
                    _this.hide_modal()
                  }} 
                  style={styles.md_close}>
                    <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="close"/>
                  </TouchableOpacity>

                  <View style={styles.md_header_text_container}>
                    <Text style={styles.md_header_text}>ICD 10 Selection</Text>
                  </View>

                  {UI.box(60)}
                </View>
                <View style={{...styles.md_content_container, width:'100%'}}>

                  <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20, width:'100%' }}>
                    <UI.Row
                      style={styles.main_container_header}
                      breakpoint={950}
                      _this={_this}
                    >

                      <Text style={{ fontWeight:'bold', fontSize: 18, color:UI.colors.secondary, display:'flex', alignItems:'center', flex:1}}>ICD 10 Selection</Text>

                      <InputForm
                        _this={_this}
                        title={""}
                        is_keyword={true}
                        placeholder={"Enter Keyword"}
                        state_name={"search"}
                        onChange={(e) => {
                          _this.setState({search: e})
                        }}
                        right={
                          <TouchableOpacity style={styles.search_btn}>
                            <Image
                              height={17}
                              width={17}
                              tintColor={"transparent"}
                              source={UI.SEARCH}
                            />
                          </TouchableOpacity>
                        }
                        disableLength={true}
                        maxLength={UI.Constants.limits.keyword}
                      />
                      {UI.box(20)}
                      <View style={{display:'flex', justifyContent:'flex-end'}}>
                        <Layout.Btn
                          style={{
                            display:'flex',
                            alignItems:'center'
                          }}
                          color={UI.colors.secondary}
                          onClick={() => {
                          _this.hide_modal()
                            _this.get_cl({
                              name: _this.state.search,
                              page:1
                            });
                          }}
                          text={"Apply Search"}
                          height={50}
                        />
                      </View>
                    </UI.Row>
                    {UI.box(20)}

                    <CustomDatatable
                      state_name={"icd_10_list"}
                      _this={_this}
                      columns={[
                        { "ICD 10 Code": "icd_10_code" },
                        { "ICD 10 Description": "icd_10_description" },
                      ]}
                      data={_this.state.cl?.filter(row => !consultationNotes.icd.includes(row._id)).map((item) => {
                        const consultation = consultationNotes

                        return {
                          icd_10_code: 
                            <TouchableOpacity
                              onClick={() => {
                                
                                const {_id, code, description} = item;

                                const selectedIcd = {
                                  _id,
                                  code,
                                  description
                                }

                                consultation.icd.push(selectedIcd);
                                triggerDebounce();
                                _this.hide_modal();
                              }}
                            >
                              <Text style={{color:UI.colors.secondary}} >{item.code}</Text>
                            </TouchableOpacity>,
                          icd_10_description: item.description,
                        };
                      })}
                      on_page={(page, callback, table_name) => {
                        _this.hide_modal()
                        if (_this.state.search) {
                          _this.get_cl({
                            name: _this.state.search,
                            page:page
                          });
                        } else {
                          _this.get_cl({
                            page: page,
                            limit:
                              10,
                          });
                        }
                      
                      }}
                    />
                    </View>
                </View>
              </div>
            </View>
          </ScrollView>
        )
      }}>

        {
        consultationNotes.icd.length > 0 ? 
        consultationNotes.icd.map((item) => {
        return <Text 
          style={{
            color: UI.colors.secondary, 
            background: "rgb(203 231 255)",  
            marginRight:10, padding:5, 
            fontWeight:'bold',
            borderRadius:'5px'}}
          >
            {  }
            {item?.code} | {item?.description} 

            <strong className='icd_10_remove' style={{marginLeft: 5, fontSize:17, cursor:'pointer'}} onClick={(e) => {
              e.stopPropagation(); 
              const consultation = consultationNotes
              const res = consultation.icd.filter(id => id !== item)
              consultation.icd = res
              setconsultationNotes({...consultationNotes, consultation});
            }
            }>x</strong>
          </Text>
        })
          : <Text style={{color:'black'}}>Select Item</Text>
        }

      </div>

      {UI.box(10)}

        <Text
          style={{
            fontWeight: "bold",
            fontSize: '15px',
            color: "#0E4D94",
          }}
        >
          Patient Instructions
        </Text>

        {UI.box(10)}
        
        <textarea
          style={{
            fontSize: '14px',
            resize: "none",
            color: 'black',
            height: '109px',
            width: "100%",
            padding:'10px 14.44px' ,
            borderRadius:'10px',
            border: '2px solid #C9C9C9',
            marginTop:'5px'
          }}
          placeholder={"Patient Instructions"}
          onChange={onChange}
          name="patientInstruction"
          value={consultationNotes.patientInstruction}
        >
        </textarea> 

        {UI.box(10)}
    </> 
    
    }

      <div>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: '15px',
            color: "#0E4D94",
          }}
        >
          DOH Referral
        </Text>
      </div>
  
      {UI.box(10)}
      
      <div style={{display:'flex', gap:'20px'}}>
        <Text
          style={{
            fontSize: '15px',
            color: "#0E4D94",
          }}
        >
          Is the patient for ER referral?
        </Text>

        <RadioBtn
          style={{borderColor: UI.colors.secondary, borderWidth: 2, borderRadius:7, height:25, width:25}}
          _this={_this}
          text={"Yes"}
          onClick={(value) => {
            const consultation = consultationNotes
            consultation.dohCovidReferral.isPatientForErReferral =  true
            setconsultationNotes({...consultationNotes, consultation});
            triggerDebounce();
          }}
          is_checked={consultationNotes.dohCovidReferral.isPatientForErReferral === true}
          color={"#2E2E2E"}
          />
        {/* {UI.box(10)} */}
        <RadioBtn
          style={{borderColor: UI.colors.secondary, borderWidth: 2, borderRadius:7, height:25, width:25}}
          _this={_this}
          text={
            "No"
          }
          onClick={(value) => {
            const consultation = consultationNotes
            consultation.dohCovidReferral.isPatientForErReferral =  false
            setconsultationNotes({...consultationNotes, consultation});
            triggerDebounce();
          }}
          is_checked={consultationNotes.dohCovidReferral.isPatientForErReferral === false}
          color={"#2E2E2E"}
        />
      </div>
  
      {UI.box(10)}

      <div style={{display:'flex', gap:'20px'}}>
        <Text
          style={{
            fontSize: '15px',
            color: "#0E4D94",
          }}
        >
          Is the patient for specialist referral?
        </Text>
        <RadioBtn
          style={{borderColor: UI.colors.secondary, borderWidth: 2, borderRadius:7, height:25, width:25}}
          _this={_this}
          text={"Yes"}
          onClick={(value) => {
            const consultation = consultationNotes
            consultation.dohCovidReferral.isPatientForSpecialistReferral =  true
            setconsultationNotes({...consultationNotes, consultation});
            triggerDebounce();
          }}
          is_checked={consultationNotes.dohCovidReferral.isPatientForSpecialistReferral === true}
          color={"#2E2E2E"}
          />
          {/* {UI.box(10)} */}
          <RadioBtn
            style={{borderColor: UI.colors.secondary, borderWidth: 2, borderRadius:7, height:25, width:25}}
            _this={_this}
            text={
              "No"
            }
            onClick={(value) => {
              const consultation = consultationNotes
              consultation.dohCovidReferral.isPatientForSpecialistReferral =  false
              setconsultationNotes({...consultationNotes, consultation});
              triggerDebounce();
            }}
            is_checked={consultationNotes.dohCovidReferral.isPatientForSpecialistReferral === false}
            color={"#2E2E2E"}
          />
      </div>
  
      {UI.box(10)}

      <Text
        style={{
          fontWeight: "bold",
          fontSize: '15px',
          color: "#0E4D94",
        }}
      >
        Follow up schedule on
      </Text>

      { UI.box(10) }
      
      <View style={{ width: "100%", background:'white', paddingBottom:'7px', paddingLeft:'7px', paddingRight:'7px' }}>
        {/* <Layout.DatePicker
          _this={_this}
          // isWithTimePicker={true}
          placeholder={"Select Schedule Date"}
          state_name={"start_date"}
          will_correct={true}
        /> */}
        <View
          onClick={() => {
            _this.date.setOpen(true);
          }}
          style={{ ...styles.date_input }}
        >
          <View style={{ flex: 1 }}>
            <ReactDatePicker
              ref={(view) => {
                // console.log(view)
                _this.date = view;
              }}
        
              placeholderText={"Select Schedule Date"}
              selected={_this.state.start_date_date}
              // minDate={ disabledPastDates ? moment().toDate() : ''}
              // maxDate={ disabledPresentDates ? moment().toDate() : ''}
              onChange={(date) => {
                if(!date) {
                  return false
                }
                const timestamp = date.getTime();
                let time = UI.timestampToDate(timestamp);
                time.day = UI.pad2(time.day);
                let value = time.month + "/" + time.day + "/" + time.year;

                _this.setState({
                  state_date: value,
                  start_date_date: date,
                }, async () => {
                  const payload = onAddConsultationNotes({...consultationNotes, followupSchedule: date})
                  // // , followupSchedule: notes?.consultations.length > 0 && notes?.consultations[0].followupSchedule ? notes?.consultations[0].followupSchedule : _this.state.start_date_date
                  await saveEntry("consultation", payload);
                  // console.log(_this.state)
                });
              }}
            />
          </View>

          <ImageBackground
            style={{ height: 20, width: 20 }}
            imageFit={ImageFit.CONTAIN}
            source={UI.CALENDAR_2}
          />
        </View>
      </View>
      
      { UI.box(10) }

      {
        <>
          <p className='text-lg text-red-500 m-0 font-semibold'>{validationMessage.error}</p>
          <p className='text-lg text-green-500 m-0 font-semibold'>{validationMessage.success}</p>
        </>
      }
      
    {/* <View
      style={{
        width: "100%",
        flexDirection: "row",
        justifyContent:'flex-end'
      }}
    >
      <button  className='my-3 flex items-center gap-2 py-2 px-4 rounded-md font-semibold'style={{background:'#0E4D94'}} onClick={submit}>    
        <img height={15} width={15} src={UI.UPDATE_PEN} alt=""/>
        <strong className='text-white' style={{fontSize:'15px'}}>Save Entry</strong>
      </button>
    </View> */}
  </div>
  )
}

export default ConsultationNotes

const styles = StyleSheet.create({
  md_content_container: {
    padding: 25,
    alignItems: "start",
  },
  md_btn_text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
  md_button: {
    padding: 30,
    // backgroundColor: "#004F99",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: "#00CBFF",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
  date_input: {
    borderWidth: 2,
    borderRadius: 5,
    borderColor: "#C9C9C9",
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
    width: "100%",
    height: 50,
  },
})