import Icon from "@ant-design/icons";
import React from "react";

const FieldAddressSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.6"
    height="18.627"
    viewBox="0 0 19.6 18.627"
  >
    <path
      id="address"
      d="M335.967,220.877v-12.47a1.751,1.751,0,0,1,1-1.669l4.441-2.522a2.4,2.4,0,0,1,1.177-.306,2.551,2.551,0,0,1,1.234.324l4.756,2.856,4.543-2.522a2.173,2.173,0,0,1,1.066-.371,1.278,1.278,0,0,1,1.382,1.372v12.424a1.78,1.78,0,0,1-1,1.706l-4.459,2.512a2.489,2.489,0,0,1-1.224.325,2.628,2.628,0,0,1-1.279-.343l-4.8-2.68-4.385,2.374a2.168,2.168,0,0,1-1.066.343A1.264,1.264,0,0,1,335.967,220.877Zm5.859-14.593-.287.157-3.32,1.919a.614.614,0,0,0-.361.6v10.561c0,.148.065.25.2.25a.5.5,0,0,0,.185-.065l3.588-1.965Zm5.591,13.49c.121.074.241.13.362.2V208.916l-3.728-2.225c-.093-.056-.2-.111-.3-.166v11.2Zm2.726.074,3.171-1.827a.615.615,0,0,0,.361-.6v-10.5a.215.215,0,0,0-.213-.232.394.394,0,0,0-.166.056l-3.579,1.957v11.386C349.856,220.015,350,219.931,350.143,219.848Z"
      transform="translate(-335.967 -203.91)"
      fill="#4fc2ed"
    />
  </svg>
);

const FieldAddressIcon = (props) => {
  return <Icon component={FieldAddressSvg} {...props} />;
};

export default FieldAddressIcon;
