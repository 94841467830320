import React from "react";
import moment from "moment";

const MarketingConsent = ({ _this }) => {
  const {
    first_name,
    middle_name,
    last_name,
    file_signature_path
  } = _this.state;

  const signature = file_signature_path;

  return (
    <div className="px-20 py-6">
      <img
        style={{ width: 300 }}
        className="pl-4 m-auto"
        src={"/img/metro_pacific_logo.png"}
        alt="logo"
      />
      <p className="text-center text-lg text-bold">Consent Form</p>
      <p>
        I consent to Metro Pacific Health Tech Corporation (“MPHTC”) using my
        photographs and/or video recordings including my name, employment, field
        of medical expertise, and years of medical practice experience to allow
        MPHTC to share to the public my images and personal information for
        marketing and promotional purposes. I agree that these images and
        personal information will be used in digital media formats, which shall
        be published in its website, mobile application, and social media
        accounts.
      </p>
      <p>
        I hereby agree to irrevocably assign all property rights to the
        aforementioned photographs and recordings to MPHTC.
      </p>
      <p>
        I understand that images on MPHTC’s website and mobile application can
        be viewed throughout the world and not just in the Philippines and that
        some overseas countries may not provide the same level of protection to
        the rights of individuals as the Data Privacy Act of 2012 provides.
      </p>
      <p>
        I understand that some images or recordings may be kept permanently once
        they are published and be kept as an archive of MPHTC.
      </p>
      <p>
        I have read and understand the conditions and consent to my images
        and/or recordings being used as described.
      </p>
      <table className="border w-full text-left">
        <tr>
          <td className="p-2 border-r">Print Name</td>
          <td className="p-2">{`${first_name} ${middle_name} ${last_name}`}</td>
        </tr>
        <tr className="border-t">
          <td className="p-2 border-r">Signature</td>
          <td className="p-2">
            {signature.url && (
              <img className="w-32" src={signature.url} alt="signature" />
            )}
          </td>
        </tr>
        <tr className="border-t">
          <td className="p-2 border-r">Date</td>
          <td className="p-2">{moment().format("MM/DD/YYYY")}</td>
        </tr>
      </table>
      <p className="mt-4">
        MPHTC is committed to processing information in accordance with the Data
        Privacy Act of 2012. The images and video footage will not be used for
        any commercial purposes outside of MPHTC. The information that you give
        us here will only be used to contact you about the images or video
        footage that we have taken. We will not pass the details record on this
        form to any third party without your permission.
      </p>
    </div>
  );
};

export default MarketingConsent;
