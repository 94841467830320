import React, { useEffect, useState } from 'react'
import TouchableOpacity, { Image, mem, View } from '../../../shared/custom-react-native';
// import { DeleteConfirmation } from '../_components/Modal';
import { Row, Select, Typography } from "antd";
import styled from 'styled-components';
import DropdownIcon from '../../../icons/Dropdown';
import ServiceUsergroupsApi from "../../../api_services/usergroups";
import { DeleteConfirmation, Failed, Success } from '../../_components/Modal';
import UI from '../../../shared/Components/UI/js';
import DataTable from '../../../ComponentsFC/DataTable';

const AdminList = ({_this, isSuccessInvite, setIsSuccessInvite}) => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(30);
  // eslint-disable-next-line no-unused-vars
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const getAdminList = (params) => {
    const data = mem.get("view_user_group");
    const user_group = JSON.parse(data);
    const id = user_group?._id

    try {
      setIsLoading(true);
      ServiceUsergroupsApi.getUserGroupAdminByUserGroupId(id).then( res => {
        setIsLoading(false);
        if (res.status === 200) {
          let data = res;
          console.log(data);
          setIsSuccessInvite(false);
          const listOnlyJoined = data.filter(item => item.journey === "JOINED" || item.journey === "JOINED_SENT")
          setTotalRecords(listOnlyJoined.length);
          setTableData(listOnlyJoined);
        } else {
          _this.show_modal(
            <Failed
              description={res.message} 
              onDismiss={() => { 
                _this.hide_loading();
                _this.hide_modal()
              }} 
            />
          );
        }
      });
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }

  useEffect(() => {
    getAdminList();
    if (isSuccessInvite) {
      getAdminList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessInvite]);

  const handlePaginateTable = (pageNum) => {
    setCurrentPageNum(pageNum);
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const tableColumnHeader = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => email ?? " ",
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (key, item) => {
        return <View style={{ flexDirection: "row", gap: 10 }}>
          <TouchableOpacity
            onClick={() => {
              deleteUserGroupAdmin(item)
            }}
          >
            <Image
              height={28}
              width={28}
              tintColor={"transparent"}
              source={UI.DELETE_BTN}
            />
          </TouchableOpacity>
        </View>
      },
      width: 200,
    },
  ];

  const deleteUserGroupAdmin = (item, index) => {
    _this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to remove this admin?"}
        onCancel={() => {
          _this.hide_modal();
        }}
        onDelete={async () => {
          setIsLoading(true);
          ServiceUsergroupsApi.deleteUserGroupAdmin(item._id).then( res => {
            if (res.status === 200) {
              // let data = res.data;
              _this.show_modal(<Success
                descriptionCenter={"Successfully removed user group admin."}
                onDismiss={() => {
                  getAdminList()
                  setIsLoading(false);
                  _this.hide_modal();
                }}
              />);
            } else {
              _this.show_modal(
                <Failed
                  description={res.message} 
                  onDismiss={() => { 
                    setIsLoading(false);
                    _this.hide_loading();
                    _this.hide_modal()
                  }} 
                />
              );
            }
          })
          // _this.show_loading();

          // UI.webRequest({
          //   method: "delete",
          //   url: `admin/usergroupadmin/${item._id}`,
          //   params: {},
          //   onFail: async (err) => {},
          //   onSuccess: async (response) => {
          //     _this.show_modal(
          //       <Success
          //         descriptionCenter={"Successfully removed admin."}
          //         onDismiss={() => {
          //           _this.hide_loading();
          //           getAdminList()
          //           _this.hide_modal();
          //         }}
          //       />
          //     );
          //   },
          //   onFinish: () => {
          //     _this.hide_loading();
          //   },
          // });
        }}
      />)
    }

  return (
    <>
      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="30"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="5">5 Entries</Select.Option>
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>
        </Toolbar>
        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={tableData}
          defaultPageSize={30}
          // pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>

    </>
  )
}

export default AdminList

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;