import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import LoginSelect from "./modules/login-select/LoginSelect";
//Admin Start

import AdminAddBanner from "./modules/admin-add-banner/AdminAddBanner";
import AdminAddFAQCategory from "./modules/admin-add-faq-category/AdminAddFAQCategory";
import AdminAddFAQItem from "./modules/admin-add-faq-item/AdminAddFAQItem";
import AdminAddForceUpdate from "./modules/admin-add-force-update/AdminAddForceUpdate";
import AdminAddHospital from "./modules/admin-add-hospital/AdminAddHospital";
import AdminAddHospitalAdmin from "./modules/admin-add-hospital-admin/AdminAddHospitalAdmin";
import AdminAddMaintenanceAlert from "./modules/admin-add-maintenance-alert/AdminAddMaintenanceAlert";
import AdminAddMerchant from "./modules/admin-add-merchant/AdminAddMerchant";
import AdminAddMwellBanner from "./modules/admin-add-mwell-banner/AdminAddMwellBanner";
import AdminAddPromo from "./modules/admin-add-promo/AdminAddPromo";
import AdminAddQuicklink from "./modules/admin-add-quicklink/AdminAddQuicklink";
import AdminAddSpecialty from "./modules/admin-add-specialty/AdminAddSpecialty";
import AdminAppointments from "./modules/admin-appointments/AdminAppointments";
import AdminCMSHomescreen from "./modules/admin-cms-homescreen/AdminCMSHomescreen";
import AdminCMSHospitals from "./modules/admin-cms-hospitals/AdminCMSHospitals";
import AdminCMSSpecialties from "./modules/admin-cms-specialties/AdminCMSSpecialties";
import AdminDashboard from "./modules/admin-dashboard/AdminDashboard";
import AdminDoctorAccounts from "./modules/admin-doctor-accounts/AdminDoctorAccounts";
// import AdminDoctorAccountsDeactivatedDoctors from "./modules/admin-doctor-accounts/AdminDoctorAccountsDeactivatedDoctorsPage";
import AdminDoctorAccountsDeletedDoctors from "./modules/admin-doctor-accounts/AdminDoctorAccountsDeletedDoctorsPage";
import AdminDoctorAccountsDeletedDoctorDetails from "./modules/admin-doctor-accounts/AdminDoctorAccountsDeletedDoctorDetailsPage";
import AdminEditFAQCategory from "./modules/admin-edit-faq-category/AdminEditFAQCategory";
import AdminEditFAQItem from "./modules/admin-edit-faq-item/AdminEditFAQItem";
import AdminEditHospital from "./modules/admin-edit-hospital/AdminEditHospital";
import AdminEditHospitalAdmin from "./modules/admin-edit-hospital-admin/AdminEditHospitalAdmin";
import AdminEditMerchant from "./modules/admin-edit-merchant/AdminEditMerchant";
import AdminEditPromo from "./modules/admin-edit-promo/AdminEditPromo";
import AdminEditSpecialty from "./modules/admin-edit-specialty/AdminEditSpecialty";
import AdminFAQCategories from "./modules/admin-faq-categories/AdminFAQCategories";
import AdminFAQItems from "./modules/admin-faq-items/AdminFAQItems";
import AdminForceUpdate from "./modules/admin-force-update/AdminForceUpdate";
import AdminHospitalAdmins from "./modules/admin-hospital-admins/AdminHospitalAdmins";
import TelemedicineStats from "./modules/admin-telemedicine-stats/TelemedicineStats";
import AdminInquiries from "./modules/admin-inquiries/AdminInquiries";
import AdminLogin from "./modules/admin-login/AdminLogin";
import AdminMaintenanceAlert from "./modules/admin-maintenance-alert/AdminMaintenanceAlert";
import AdminMerchantList from "./modules/admin-merchant-list/AdminMerchantList";
import AdminDoctorsOncallAvailability from "./modules/admin-doctors-on-call-availabilty/AdminDoctorsOncallAvailability";
import AdminPatientAccounts from "./modules/admin-patient-accounts/AdminPatientAccounts";
import AdminPrivacyPolicy from "./modules/admin-privacy-policy/AdminPrivacyPolicy";
import AdminPromoCMS from "./modules/admin-promo-cms/AdminPromoCMS";
import AdminStoreMerchantList from "./modules/admin-store-merchant-list/AdminStoreMerchantList";
import AdminSubscriptionDetails from "./modules/admin-subscription-details/AdminSubscriptionDetails";
import AdminSubscriptions from "./modules/admin-subscriptions/AdminSubscriptions";
import AdminTransactions from "./modules/admin-transactions";
import AdminTermsAndConditions from "./modules/admin-terms-and-conditions/AdminTermsAndConditions";
import AdminViewAppointment from "./modules/admin-view-appointment/AdminViewAppointment";
import AdminViewDoctorDetails from "./modules/admin-view-doctor-details/AdminViewDoctorDetails";
import AdminViewDoctorRecord from "./modules/admin-view-doctor-record/AdminViewDoctorRecord";
import AdminViewHospitalAdmin from "./modules/admin-view-hospital-admin/AdminViewHospitalAdmin";
import AdminViewInquiry from "./modules/admin-view-inquiry/AdminViewInquiry";
import AdminViewMerchant from "./modules/admin-view-merchant/AdminViewMerchant";
import AdminViewPatientRecord from "./modules/admin-view-patient-record/AdminViewPatientRecord";
import AdminViewProduct from "./modules/admin-view-product/AdminViewProduct";
import AdminViewService from "./modules/admin-view-service/AdminViewService";
import AdminViewTransaction from "./modules/admin-view-transaction/AdminViewTransaction";
import AdminViewTransactionDetails from "./modules/admin-view-transaction-details/AdminViewTransactionDetails";
import AdminWaitingRoom from "./modules/admin-waiting-room/AdminWaitingRoom";
// import AdminUserGroupAdmin from "./modules/admin-user-group-admin";
// import AdminUserGroupList from "./modules/admin-user-group-list";
// import AdminUserGroupAddUserList from "./modules/admin-user-group-add-user-list";
// import AdminUserGroupAdd from "./modules/admin-user-group-add";
// import AdminUserGroupEdit from "./modules/admin-user-group-edit";
// import AdminUserGroupView from "./modules/admin-user-group-view";
// import AdminUserGroupAdminCreate from "./modules/admin-user-group-admin-create";
import AdminDoctorAttendance from "./modules/admin-doctors-attendance/AdminDoctorAttendancePage";
import AdminDoctorDtr from "./modules/admin-doctors-attendance-dtr/AdminDoctorDailyTimeReportPage";
import AdminDoctorLeaveList from "./modules/admin-doctors-leave-list/AdminDoctorLeaveListPage";

import AdminCmsLguPartners from "./modules/admin-cms-lgu-partners/AdminCmsLguPartners";
import AdminCmsLguPartnersAdd from "./modules/admin-cms-lgu-partners-add/AdminCmsLguPartnersAdd";
import AdminCmsLguPartnersEdit from "./modules/admin-cms-lgu-partners-edit/AdminCmsLguPartnersEdit";
// import AdminCmsLguPartnersDoctorsAdd from "./modules/admin-cms-lgu-partners-doctors-add/AdminCmsLguPartnersDoctorsAdd";
import AdminDoctorFileRepository from "./modules/admin-doctor-file-repository"
//Admin End

//User Group Start

// import UserGroupAdminList from "./modules/user-group-admin-list";
// import UserGroupAdminAddUserList from "./modules/user-group-admin-add-user-list";

//User Group End


//merchant Start
import EcommerceAccount from "./modules/ecommerce-account/EcommerceAccount";
import EcommerceLogin from "./modules/ecommerce-login/EcommerceLogin";
import EcommerceCustomerList from "./modules/ecommerce-customer-list/EcommerceCustomerList";
import EcommerceDashboard from "./modules/ecommerce-dashboard/EcommerceDashboard";
import EcommerceProducts from "./modules/ecommerce-products/EcommerceProducts";
import EcommerceAddProduct from "./modules/ecommerce-add-product/EcommerceAddProduct";
import EcommerceViewCustomer from "./modules/ecommerce-view-customer/EcommerceViewCustomer";
import EcommerceViewProduct from "./modules/ecommerce-view-product/EcommerceViewProduct";
import EcommerceViewProductDetails from "./modules/ecommerce-view-product-details/EcommerceViewProductDetails";
import EcommerceViewTransactionDetails from "./modules/ecommerce-view-transaction-details/EcommerceViewTransactionDetails";
import EcommerceInventory from "./modules/ecommerce-inventory/EcommerceInventory";
import EcommerceViewInventory from "./modules/ecommerce-view-inventory/EcommerceViewInventory";
import EcommerceTransactions from "./modules/ecommerce-transactions";
import EcommerceViewTransaction from "./modules/ecommerce-view-transaction/EcommerceViewTransaction";
import EcommerceInquiries from "./modules/ecommerce-inquiries/EcommerceInquiries";
import EcommerceViewInquiry from "./modules/ecommerce-view-inquiry/EcommerceViewInquiry";
import EcommerceEditProduct from "./modules/ecommerce-edit-product/EcommerceEditProduct";
import EcommerceViewProductInventory from "./modules/ecommerce-view-product-inventory/EcommerceViewProductInventory";
//merchant End


//Finance Start

import FinanceAddPromo from "./modules/finance-add-promo/FinanceAddPromo";
import FinanceAppointments from "./modules/finance-appointments/FinanceAppointments";
import FinanceDashboard from "./modules/finance-dashboard/FinanceDashboard";
import FinanceDisburseDoctorPayment from "./modules/finance-disburse-doctor-payment/FinanceDisburseDoctorPayment";
import FinanceDoctorsDisbursement from "./modules/finance-doctors-disbursement/FinanceDoctorsDisbursement";
import FinanceDoctorsMasterfile from "./modules/finance-doctors-masterfile/FinanceDoctorsMasterfile";
import FinanceDoctorsSchedule from "./modules/finance-doctors-schedule/FinanceDoctorsSchedule";
import FinanceDoctorsTransactions from "./modules/finance-doctors-transactions/FinanceDoctorsTransactions";
import FinanceEditDoctorSchedule from "./modules/finance-edit-doctor-schedule/FinanceEditDoctorSchedule";
import FinanceEditMasterfile from "./modules/finance-edit-masterfile/FinanceEditMasterfile";
import FinanceEditPromo from "./modules/finance-edit-promo/FinanceEditPromo";
import FinanceEditWithholding from "./modules/finance-edit-withholding/FinanceEditWithholding";
import FinanceLogin from "./modules/finance-login/FinanceLogin";
import FinancePatientRecords from "./modules/finance-patient-records/FinancePatientRecords";
import FinancePromoCMS from "./modules/finance-promo-cms/FinancePromoCMS";
import FinanceRefund from "./modules/finance-refund/FinanceRefund";
import FinanceRefundDoctorTransaction from "./modules/finance-refund-doctor-transaction/FinanceRefundDoctorTransaction";
import FinanceRefundTransaction from "./modules/finance-refund-transaction/FinanceRefundTransaction";
import FinanceSubscriptionDetails from "./modules/finance-subscription-details/FinanceSubscriptionDetails";
import FinanceSubscriptions from "./modules/finance-subscriptions/FinanceSubscriptions";
import FinanceViewAppointment from "./modules/finance-view-appointment/FinanceViewAppointment";
import FinanceViewPatientRecord from "./modules/finance-view-patient-record/FinanceViewPatientRecord";
import FinanceWithholdingTax from "./modules/finance-withholding-tax/FinanceWithholdingTax";

//Finance End



//Onboard Start
import OnboardSignup from "./modules/onboard-signup/OnboardSignup";
import OnboardLogin from "./modules/onboard-login/OnboardLogin";
import OnboardCreateUser from "./modules/onboard-create-user/OnboardCreateUser";
import OnboardCreateUserPDF from "./modules/onboard-create-user/OnboardCreateUserPDF";
import OnboardDashboard  from "./modules/onboard-dashboard/OnboardDashboard";
import OnboardTax from "./modules/onboard-tax/OnboardTax";
import OnboardUserProfile from "./modules/onboard-user-profile/OnboardUserProfile";
import OnboardDeleteAccount from "./modules/onboard-settings/OnboardDeleteAccountPage";
import OnboardNutritionProgram from "./modules/onboard-nutrition-program/OnboardNutritionProgram";
import OnboardUpdateNutrition from "./modules/onboard-update-nutrition/OnboardUpdateNutrition";
import OnboardChangePassword from "./modules/onboard-change-password/OnboardChangePassword";
import OnBoardEncounter from "./modules/onboard-encounter";
// import OnBoardEncounterChimeChime from "./modules/onboard-encounter-chime";
import OnboardAvailability from "./modules/onboard-availability/OnboardAvailability";
import OnboardConsultLaterCalendar from "./modules/onboard-consult-later-calendar/OnboardConsultLaterCalendar";
import DoctorNotificationCMS from "./modules/doctor-notification-cms";
import OnboardAttendance from "./modules/onboard-attendance/OnboardAttendancePage";
import OnboardLeaveRequest from "./modules/onboard-attendance/LeaveRequestPage";
import OnboardLeaveRequestList from "./modules/onboard-attendance/LeaveRequestListPage";
import OnboardAdvocacyAvailability from "./modules/onboard_advocacy_availability/OnboardAdvocacyAvailability";
// import OnboardDeactivateAccount from "./modules/onboard-settings/OnboardDeactivateAccountPage";

//Onboard End


//Transaction Start
import TransactionLogin from "./modules/transaction-login/TransactionLogin";
import TransactionDashboard from "./modules/transaction-dashboard/TransactionDashboard";
import TransactionBookedConsults from "./modules/transaction-booked-consults/TransactionBookedConsults";
import TransactionBookedDetails from "./modules/transaction-booked-details/TransactionBookedDetails";
import TransactionOncallConsults from "./modules/transaction-oncall-consults/TransactionOncallConsults";
import TransactionOncallDetails from "./modules/transaction-oncall-details/TransactionOncallDetails";
//Transaction End



//User Start
import UserRetrieve from "./modules/retrieve/UserRetrieve";
//User End


//Voucher Admin Start

import VoucherAddVoucher from "./modules/voucher-add-voucher/VoucherAddVoucher";
import VoucherDashboard from "./modules/voucher-dashboard/VoucherDashboard";
import VoucherEditVoucher from "./modules/voucher-edit-voucher/VoucherEditVoucher";
import VoucherList from "./modules/voucher-list/VoucherList";
import VoucherLogin from "./modules/voucher-login/VoucherLogin";


//Voucher Admin End


//Advocacy Admin Start

import AdvocacyLogin from "./modules/advocacy-login/AdvocacyLogin";
import AdvocacyDashboard from "./modules/advocacy-dashboard/AdvocacyDashboard";
import AdvocacyList from "./modules/advocacy-list/AdvocacyList";
import AddAvocacy from "./modules/advocacy-add/AddAvocacy";
import AdvocacyView from "./modules/advocacy-view/AdvocacyView";
import EditAdvocacy from "./modules/advocacy-edit/EditAdvocacy";
import EditQuestionnaire from "./modules/advocacy-edit/EditQuestionnaire";
import EditSchedule from "./modules/advocacy-edit/EditSchedule";
import AdvocacyTransactions from "./modules/advocacy-transactions/AdvocacyTransactions";
import AdvocacyTransactionsView from "./modules/advocacy-transactions-view/AdvocacyTransactionsView";
import AdvocacyReports from "./modules/advocacy-reports/AdvocacyReports";

//Advocacy Admin End

// Advocacy Doctors View Start
import AdvocacyDoctorRegister from "./modules/advocacy-doctor-register/AdvocacyDoctorRegister";
import VoucherHealthpassList from "./modules/voucher-healthpass-list/VoucherHealthpassList";
import VoucherCampaignList from "./modules/voucher-campaign-list/VoucherCampaignList";
import VoucherCampaignAdd from "./modules/voucher-campaign-add/VoucherCampaignAdd";
import VoucherCampaignEdit from "./modules/voucher-campaign-edit/VoucherCampaignEdit";
import VoucherHealthpassEdit from "./modules/voucher-healthpass-edit/VoucherHealthpassEdit";
// import VoucherDoctorGroupList from "./modules/voucher-doctor-group-list/VoucherDoctorGroupList";
// import VoucherDoctorGroupAdd from "./modules/voucher-doctor-group-add/VoucherDoctorGroupAdd";
// import VoucherDoctorGroupEdit from "./modules/voucher-doctor-group-edit/VoucherDoctorGroupEdit";
import VoucherHealthpassAdd from "./modules/voucher-healthpass-add/VoucherHealthpassAdd";
import VoucherHealthpassView from "./modules/voucher-healthpass-view/VoucherHealthpassView";
import VoucherCampaignView from "./modules/voucher-campaign-view/VoucherCampaignView";
import VoucherUserGroupList from "./modules/voucher-user-group-list";
import VoucherUserGroupDashboard from "./modules/voucher-usergroup-dashboard/VoucherUserGroupDashboard";
import VoucherUserGroupAdd from "./modules/voucher-user-group-add/VoucherUserGroupAdd";
// import VoucherUserGroupView from "./modules/voucher-user-group-view/VoucherUserGroupView";
// import VoucherDoctorGroupView from "./modules/voucher-doctor-group-view/VoucherDoctorGroupView";
import VoucherUserGroupEdit from "./modules/voucher-user-group-edit/VoucherUserGroupEdit";
import VoucherHealthpassHistoryList from "./modules/voucher-healthpass-history-list/VoucherHealthpassHistoryList";
import VoucherHealthpassHistoryView from "./modules/voucher-healthpass-history-view/VoucherHealthpassHistoryView";
import VoucherHealthpassDashboard from "./modules/voucher-healthpass-dashboard/VoucherHealthpassDashboard";

//  USER GROUP

import UserGroupDashboard from "./modules/user-group-dashborad/UserGroupDashboard";

// Advocacy Doctors View End

// Legal Start
import LegalDoctorFileRepository from "./modules/legal-doctor-file-repository"
// Legal End

//Google Analytics
import ReactGA from 'react-ga';

import UserGroupLogin from "./modules/user-group -login/UserGroupLogin";
import PageNotFound from "./modules/page-not-found/PageNotFound";
import UserGroupView from "./modules/user-group-view";
// import AdminUserGroupSubscriptionList from "./modules/finance-user-group-subscription-list";
import UserGroupMembersCreateQrUser from "./modules/user-group-members-create-qr-user";
// import FinanceUserGroupSubscriptionList from "./modules/finance-user-group-subscription-list";
import FinanceUserGroupSubscriptionRequestList from "./modules/finance-user-group-subscription-request-list";
import FinanceUserGroupSubscriptionBilling from "./modules/finance-user-group-subscription-billing";
import FinanceUserGroupSubscriptioList from "./modules/finance-user-group-subscription-list";
import FinanceUserGroupSubscriptionCreate from "./modules/finance-user-group-subscription-create/FinanceUserGroupSubscriptionCreate";
import FinanceUserGroupSubscriptionEdit from "./modules/finance-user-group-susbscription-edit/FinanceUserGroupSubscriptionEdit";
import UserGroupEdit from "./modules/user-group-edit/UserGroupEdit";

import AdminMedicalAssociationsList from "./modules/admin-cms-medical-associations-list/AdminMedicalAssociationsList";
import AdminCmsMedicalAssociationsAdd from "./modules/admin-cms-medical-associations-add/AdminCmsMedicalAssociationsAdd";
import AdminCmsMedicalAssociationsEdit from "./modules/admin-cms-medical-associations-edit/AdminCmsMedicalAssociationsEdit";
import OnboardReferrals from "./modules/onboard-referral/OnboardReferrals";
import EcommerceCategoriesList from "./modules/ecommerce-categories-list/EcommerceCategoriesList";
// import EcommerceServicesList from "./modules/ecommerce-services-list/EcommerceServicesList";
import EcommerceCategoriesAdd from "./modules/ecommerce-categories-add/EcommerceCategoriesAdd";
import EcommerceCategoriesEdit from "./modules/ecommerce-categories-edit/EcommerceCategoriesEdit";
import AdminLaboratoryList from "./modules/admin-laboratory-list/AdminLaboratoryList";
import AdminLaboratoryAdd from "./modules/admin-laboratory-add/AdminLaboratoryAdd";
import AdminLaboratoryEdit from "./modules/admin-laboratory-edit/AdminLaboratoryEdit";
import AdminLaboratoryTransactionList from "./modules/admin-laboratory-trasanction-list/AdminLaboratoryTransactionList";
import AdminLaboratoryTransactionView from "./modules/admin-laboratory-transaction-view/AdminLaboratoryTransactionView";
import EcommerceUserGroupSubscriptionBilling from "./modules/ecommerce-user-group-subscription-billing";
import EcommerceUserGroupSubscriptionRequestList from "./modules/ecommerce-user-group-subscription-request-list";
import EcommerceUserGroupSubscriptionList from "./modules/ecommerce-user-group-subscription-list";
import EcommerceUserGroupSubscriptionCreate from "./modules/ecommerce-user-group-subscription-create/EcommerceUserGroupSubscriptionCreate";
import EcommerceUserGroupSubscriptionEdit from "./modules/ecommerce-user-group-susbscription-edit/EcommerceUserGroupSubscriptionEdit";
import OnboardOtp from "./modules/onboard-otp/OnboardOtp";
import OnboardEmailLogin from "./modules/onboard-email-login/OnboardEmailLogin";
import UserGroupSubscriptionsView from "./modules/user-group-subscriptions-view";
import MarketingDashboard from "./modules/marketing-dashboard/MarketingDashboard";
import MarketingPatientReferralsView from "./modules/marketing-patient-referral-view/MarketingPatientReferralsView";
import MarketingPatientReferrals from "./modules/marketing-patient-referrals/MarketingPatientReferrals";
import MarketingDoctorReferralsView from "./modules/marketing-doctor-referral-view/MarketingDoctorReferralsView";
import MarketingDoctorReferrals from "./modules/marketing-doctor-referrals/MarketingDoctorReferrals";
import MarketingCampaignReferrals from "./modules/marketing-campaign-referrals/MarketingCampaignReferrals";
import MarketingCampaignReferralsAdd from "./modules/marketing-campaign-referrals-add/MarketingCampaignReferralsAdd";
import MarketingCampaignReferralsView from "./modules/marketing-campaign-referrals-view/MarketingCampaignReferralsView";
import MarketingCampaignReferralsEdit from "./modules/marketing-campaign-referrals-edit/MarketingCampaignReferralsEdit";
import MarketingRegisteredReferrals from "./modules/marketing-registered-referrals/MarketingRegisteredReferrals";
import MarketingMainBanners from "./modules/marketing-main-banners/MarketingMainBanners";
import MarketingMainBannersAdd from "./modules/marketing-main-banners/MarketingMainBannersAdd";
import MarketingMainBannersEdit from "./modules/marketing-main-banners/MarketingMainBannersEdit";
import MarketingPopUpBanners from "./modules/marketing-popup-banners/MarketingPopUpBanners";
import MarketingPopUpBannersAdd from "./modules/marketing-popup-banners/MarketingPopUpBannersAdd";
import MarketingPopUpBannersEdit from "./modules/marketing-popup-banners/MarketingPopUpBannersEdit";
import MarketingMindHealthBanners from "./modules/marketing-mind-health/MarketingMindHealthBanners";
import MarketingMindHealthBannersAdd from "./modules/marketing-mind-health/MarketingMindHealthBannersAdd";
import MarketingMindHealthBannersEdit from "./modules/marketing-mind-health/MarketingMindHealthBannersEdit";
import MarketingMwellnessBanners from "./modules/marketing-mwellness-banners/MarketingMwellnessBanners";
import MarketingMwellnessBannersAdd from "./modules/marketing-mwellness-banners/MarketingMwellnessBannersAdd";
import MarketingMwellnessBannersEdit from "./modules/marketing-mwellness-banners/MarketingMwellnessBannersEdit";
import MarketingPromoWinners from "./modules/marketing-promo-winners/MarketingPromoWinners";
import MarketingPromoWinnersView from "./modules/marketing-promo-winners-view/MarketingPromoWinnersView";
import OnboardForgetPasswordStep1 from "./modules/onboard-forget-password-step-1/OnboardForgetPasswordStep1";
import OnboardForgetPasswordStep2 from "./modules/onboard-forget-password-step-2/OnboardForgetPasswordStep2";
import OnboardReferralDetails from "./modules/onboard-referral/OnboardReferralDetailsPage";
import UdbPage from "./modules/admin-udb/UdbPage";
import DiskartechPage from "./modules/admin-diskartech/DiskartechPage";

const trackingID = 'UA-235237031-1';

function AppRouter() {
  
  useEffect(() => {
    ReactGA.initialize(trackingID);
  }, [])

  return (
      <Switch>
        <Route exact path="/" component={OnboardLogin} />
        {/* Admin Start */}
        <Route exact path="/admin" component={AdminLogin} />
        <Route exact path="/admin/add-banner" component={AdminAddBanner} />
        <Route exact path="/admin/add-faq-category" component={AdminAddFAQCategory} />
        <Route exact path="/admin/add-faq-item" component={AdminAddFAQItem} />
        <Route exact path="/admin/add-force-update" component={AdminAddForceUpdate} />
        <Route exact path="/admin/add-hospital" component={AdminAddHospital} />
        <Route exact path="/admin/add-hospital-admin" component={AdminAddHospitalAdmin} />
        <Route exact path="/admin/add-maintenance-alert" component={AdminAddMaintenanceAlert} />
        <Route exact path="/admin/add-merchant" component={AdminAddMerchant} />
        <Route exact path="/admin/add-mwell-banner" component={AdminAddMwellBanner} />
        <Route exact path="/admin/add-promo" component={AdminAddPromo} />
        <Route exact path="/admin/add-quicklink" component={AdminAddQuicklink} />
        <Route exact path="/admin/add-specialty" component={AdminAddSpecialty} />
        <Route exact path="/admin/appointments" component={AdminAppointments} />
        <Route exact path="/admin/cms-homescreen" component={AdminCMSHomescreen} />
        <Route exact path="/admin/cms-hospitals" component={AdminCMSHospitals} />
        <Route exact path="/admin/cms-specialties" component={AdminCMSSpecialties} />
        <Route exact path="/admin/cms-lgu-partners" component={AdminCmsLguPartners} />
        <Route exact path="/admin/cms-lgu-partners-add" component={AdminCmsLguPartnersAdd} />
        <Route exact path="/admin/cms-lgu-partners-edit" component={AdminCmsLguPartnersEdit} />
        {/* <Route exact path="/admin/cms-lgu-partners-doctors-add" component={AdminCmsLguPartnersDoctorsAdd} /> */}

        <Route exact path="/admin/cms-medical-associations" component={AdminMedicalAssociationsList} />
        <Route exact path="/admin/cms-medical-associations-add" component={AdminCmsMedicalAssociationsAdd} />
        <Route exact path="/admin/cms-medical-associations-edit" component={AdminCmsMedicalAssociationsEdit} />

        <Route exact path="/admin/dashboard" component={AdminDashboard} />
        <Route exact path="/admin/doctor-accounts" component={AdminDoctorAccounts} />
        {/* <Route exact path="/admin/doctor-accounts/deactivated-doctors" component={AdminDoctorAccountsDeactivatedDoctors} /> */}
        <Route exact path="/admin/doctor-accounts/deleted-doctors" component={AdminDoctorAccountsDeletedDoctors} />
        <Route exact path="/admin/doctor-accounts/deleted-doctors/view-details" component={AdminDoctorAccountsDeletedDoctorDetails} />
        <Route exact path="/admin/doctor-file-repository" component={AdminDoctorFileRepository} />
        <Route exact path="/admin/edit-faq-category" component={AdminEditFAQCategory} />
        <Route exact path="/admin/edit-faq-item" component={AdminEditFAQItem} />
        <Route exact path="/admin/edit-hospital" component={AdminEditHospital} />
        <Route exact path="/admin/edit-hospital-admin" component={AdminEditHospitalAdmin} />
        <Route exact path="/admin/edit-merchant" component={AdminEditMerchant} />
        <Route exact path="/admin/edit-promo" component={AdminEditPromo} />
        <Route exact path="/admin/edit-specialty" component={AdminEditSpecialty} />
        <Route exact path="/admin/faq-categories" component={AdminFAQCategories} />
        <Route exact path="/admin/faq-items" component={AdminFAQItems} />
        <Route exact path="/admin/force-update" component={AdminForceUpdate} />
        <Route exact path="/admin/hospital-admins" component={AdminHospitalAdmins} />
        <Route exact path="/admin/doh-stats" component={TelemedicineStats} />
        <Route exact path="/admin/doctor-attendance" component={AdminDoctorAttendance} />
        <Route exact path="/admin/doctor-dtr" component={AdminDoctorDtr} />
        <Route exact path="/admin/doctor-leave" component={AdminDoctorLeaveList} />
        <Route exact path="/admin/inquiries" component={AdminInquiries} />
        <Route exact path="/admin/login" component={AdminLogin} />
        <Route exact path="/admin/maintenance-alert" component={AdminMaintenanceAlert} />
        <Route exact path="/admin/merchant-list" component={AdminMerchantList} />
        <Route exact path="/admin/doctors-on-call-availability" component={AdminDoctorsOncallAvailability} />
        <Route exact path="/admin/patient-accounts" component={AdminPatientAccounts} />
        <Route exact path="/admin/privacy-policy" component={AdminPrivacyPolicy} />
        <Route exact path="/admin/promo-cms" component={AdminPromoCMS} />
        <Route exact path="/admin/store-merchant-list" component={AdminStoreMerchantList} />
        <Route exact path="/admin/subscription-details" component={AdminSubscriptionDetails} />
        <Route exact path="/admin/subscriptions" component={AdminSubscriptions} />
        <Route exact path="/admin/terms-and-conditions" component={AdminTermsAndConditions} />
        <Route exact path="/admin/transactions" component={AdminTransactions} />
        <Route exact path="/admin/view-appointment" component={AdminViewAppointment} />
        <Route exact path="/admin/view-doctor-details" component={AdminViewDoctorDetails} />
        <Route exact path="/admin/view-doctor-record" component={AdminViewDoctorRecord} />
        <Route exact path="/admin/view-hospital-admin" component={AdminViewHospitalAdmin} />
        <Route exact path="/admin/view-inquiry" component={AdminViewInquiry} />
        <Route exact path="/admin/view-merchant" component={AdminViewMerchant} />
        <Route exact path="/admin/view-patient-record" component={AdminViewPatientRecord} />
        <Route exact path="/admin/view-product" component={AdminViewProduct} />
        <Route exact path="/admin/view-service" component={AdminViewService} />
        <Route exact path="/admin/view-transaction" component={AdminViewTransaction} />
        <Route exact path="/admin/view-transaction-details" component={AdminViewTransactionDetails} />
        <Route exact path="/admin/waiting-room" component={AdminWaitingRoom} />
        <Route exact path="/admin/udb" component={UdbPage} />
        <Route exact path="/admin/diskartech" component={DiskartechPage} />
        {/* <Route exact path="/admin/usergroup-admin" component={AdminUserGroupAdmin} /> */}

        <Route exact path="/admin/usergroup-list" component={VoucherUserGroupList} />
        <Route exact path="/admin/usergroup-view" component={UserGroupView} />
        <Route exact path="/admin/usergroup-members-create-qr-user" component={UserGroupMembersCreateQrUser} />
        <Route exact path="/admin/usergroup-edit" component={VoucherUserGroupEdit} />
        <Route exact path="/admin/usergroup-add" component={VoucherUserGroupAdd} />
        <Route exact path="/admin/usergroup-subscriptions-view" component={UserGroupSubscriptionsView} />

        <Route exact path="/admin/laboratory-list" component={AdminLaboratoryList} />
        <Route exact path="/admin/laboratory-add" component={AdminLaboratoryAdd} />
        <Route exact path="/admin/laboratory-edit" component={AdminLaboratoryEdit} />
        <Route exact path="/admin/laboratory-transaction-list" component={AdminLaboratoryTransactionList} />
        <Route exact path="/admin/laboratory-transaction-view" component={AdminLaboratoryTransactionView} />
        {/* <Route exact path="/admin/usergroup-subscription-list" component={AdminUserGroupSubscriptionList} /> */}
        
        <Route exact path="/usergroupadmin/usergroupadmin-view" component={UserGroupView} />
        <Route exact path="/usergroupadmin/usergroup-members-create-qr-user" component={UserGroupMembersCreateQrUser} />
        <Route exact path="/admin/refund" component={FinanceRefund} />


        {/* Admin End */}

        {/* <Route exact path="/user-group/usergroup-list" component={AdminUserGroupList} /> */}
        {/* <Route exact path="/user-group/usergroup-add-user-list" component={AdminUserGroupAddUserList} /> */}

        {/* Admin End */}

        {/* Advocacy Start */}

        <Route exact path="/advocacy/login" component={AdvocacyLogin} />
        <Route exact path="/advocacy/list" component={AdvocacyList} />
        <Route exact path="/advocacy/add-advocacy" component={AddAvocacy} />
        <Route exact path="/advocacy/dashboard" component={AdvocacyDashboard} />
        <Route exact path="/advocacy/view" component={AdvocacyView} />
        <Route exact path="/advocacy/edit" component={EditAdvocacy} />
        <Route exact path="/advocacy/edit-questionnaire" component={EditQuestionnaire} />
        <Route exact path="/advocacy/edit-schedule" component={EditSchedule} />
        <Route exact path="/advocacy/transactions" component={AdvocacyTransactions} />
        <Route exact path="/advocacy/transactions-view" component={AdvocacyTransactionsView} />
        <Route exact path="/advocacy/reports" component={AdvocacyReports} />
        
        {/* Advocacy End */}

        {/* Advocacy Doctor Start */}
        
        <Route exact path="/advocacy-doctor-register" component={AdvocacyDoctorRegister} />

        {/* Advocacy Doctor End */}

        {/* merchant Start */}

        <Route exact path="/merchant/account" component={EcommerceAccount} />
        <Route exact path="/merchant/add-product" component={EcommerceAddProduct} />
        <Route exact path="/merchant/customers" component={EcommerceCustomerList} />
        <Route exact path="/merchant/dashboard" component={EcommerceDashboard} />
        <Route exact path="/merchant/edit-product" component={EcommerceEditProduct} />
        <Route exact path="/merchant/inquiries" component={EcommerceInquiries} />
        <Route exact path="/merchant/inventory" component={EcommerceInventory} />
        <Route exact path="/merchant/login" component={EcommerceLogin} />
        <Route exact path="/merchant/products" component={EcommerceProducts} />
        <Route exact path="/merchant/transactions" component={EcommerceTransactions} />
        <Route exact path="/merchant/view-customer" component={EcommerceViewCustomer} />
        <Route exact path="/merchant/view-inquiry" component={EcommerceViewInquiry} />
        <Route exact path="/merchant/view-inventory" component={EcommerceViewInventory} />
        <Route exact path="/merchant/view-product" component={EcommerceViewProduct} />
        <Route exact path="/merchant/view-product-details" component={EcommerceViewProductDetails} />
        <Route exact path="/merchant/view-product-inventory" component={EcommerceViewProductInventory} />
        <Route exact path="/merchant/view-transaction" component={EcommerceViewTransaction} />
        <Route exact path="/merchant/view-transaction-details" component={EcommerceViewTransactionDetails} />
        <Route exact path="/merchant/user-group-subscription-billing" component={EcommerceUserGroupSubscriptionBilling} />
        <Route exact path="/merchant/user-group-subscription-request-list" component={EcommerceUserGroupSubscriptionRequestList} />
        <Route exact path="/merchant/user-group-subscriptions" component={EcommerceUserGroupSubscriptionList} />
        <Route exact path="/merchant/user-group-subscription-create" component={EcommerceUserGroupSubscriptionCreate} />
        <Route exact path="/merchant/user-group-subscription-edit" component={EcommerceUserGroupSubscriptionEdit} />
        <Route exact path="/merchant/usergroup-list" component={VoucherUserGroupList} />
        <Route exact path="/merchant/usergroup-list" component={VoucherUserGroupList} />
        <Route exact path="/merchant/usergroup-view" component={UserGroupView} />
        <Route exact path="/merchant/usergroup-members-create-qr-user" component={UserGroupMembersCreateQrUser} />
        <Route exact path="/merchant/usergroup-subscriptions-view" component={UserGroupSubscriptionsView} />
        <Route exact path="/merchant/categories-list" component={EcommerceCategoriesList} />
        <Route exact path="/merchant/categories-add" component={EcommerceCategoriesAdd} />
        <Route exact path="/merchant/categories-edit" component={EcommerceCategoriesEdit} />
        {/* <Route exact path="/merchant/services-list" component={EcommerceServicesList} /> */}

        {/* merchant End */}

        {/* Finance Start */}
        
        <Route exact path="/finance/add-promo" component={FinanceAddPromo} />
        <Route exact path="/finance/appointments" component={FinanceAppointments} />
        <Route exact path="/finance/dashboard" component={FinanceDashboard} />
        <Route exact path="/finance/disburse-doctor-payment" component={FinanceDisburseDoctorPayment} />
        <Route exact path="/finance/doctors-disbursement" component={FinanceDoctorsDisbursement} />
        <Route exact path="/finance/doctors-masterfile" component={FinanceDoctorsMasterfile} />
        <Route exact path="/finance/doctors-schedule" component={FinanceDoctorsSchedule} />
        <Route exact path="/finance/doctors-transactions" component={FinanceDoctorsTransactions} />
        <Route exact path="/finance/edit-doctor-schedule" component={FinanceEditDoctorSchedule} />
        <Route exact path="/finance/edit-masterfile" component={FinanceEditMasterfile} />
        <Route exact path="/finance/edit-promo" component={FinanceEditPromo} />
        <Route exact path="/finance/edit-withholding-tax" component={FinanceEditWithholding} />
        <Route exact path="/finance/login" component={FinanceLogin} />
        <Route exact path="/finance/patient-records" component={FinancePatientRecords} />
        <Route exact path="/finance/promo-cms" component={FinancePromoCMS} />
        <Route exact path="/finance/refund" component={FinanceRefund} />
        <Route exact path="/finance/refund-doctor-transaction" component={FinanceRefundDoctorTransaction} />
        <Route exact path="/finance/refund-transaction" component={FinanceRefundTransaction} />
        <Route exact path="/finance/subscription-details" component={FinanceSubscriptionDetails} />
        <Route exact path="/finance/subscriptions" component={FinanceSubscriptions} />
        <Route exact path="/finance/view-appointment" component={FinanceViewAppointment} />
        <Route exact path="/finance/view-patient-record" component={FinanceViewPatientRecord} />
        <Route exact path="/finance/withholding-tax" component={FinanceWithholdingTax} />
        <Route exact path="/finance/user-group-subscription-billing" component={FinanceUserGroupSubscriptionBilling} />
        <Route exact path="/finance/user-group-subscription-request-list" component={FinanceUserGroupSubscriptionRequestList} />
        <Route exact path="/finance/user-group-subscriptions" component={FinanceUserGroupSubscriptioList} />
        <Route exact path="/finance/user-group-subscription-create" component={FinanceUserGroupSubscriptionCreate} />
        <Route exact path="/finance/user-group-subscription-edit" component={FinanceUserGroupSubscriptionEdit} />

        {/* Finance End */}
      

        {/* Onboard Start */}

        <Route exact path="/onboard" component={OnboardLogin} />
        <Route exact path="/onboard/login-select" component={LoginSelect} />
        <Route exact path="/onboard/change-password" component={OnboardChangePassword} />
        <Route exact path="/onboard/create-user" component={OnboardCreateUser} />
        <Route exact path="/onboard/create-user-pdf" component={OnboardCreateUserPDF} />
        <Route exact path="/onboard/dashboard" component={OnboardDashboard} />
        <Route exact path="/onboard/login" component={OnboardLogin} />
        <Route exact path="/onboard/email-login" component={OnboardEmailLogin} />
        <Route exact path="/onboard/otp" component={OnboardOtp} />
        <Route exact path="/onboard/register" component={OnboardLogin} />
        <Route exact path="/onboard/forget-password-step-1" component={OnboardForgetPasswordStep1} />
        <Route exact path="/onboard/forget-password-step-2" component={OnboardForgetPasswordStep2} />
        <Route exact path="/onboard/nutrition-program" component={OnboardNutritionProgram} />
        <Route exact path="/onboard/signup" component={OnboardSignup} />
        <Route exact path="/onboard/update-nutrition" component={OnboardUpdateNutrition} />
        <Route exact path="/onboard/tax" component={OnboardTax} />
        <Route exact path="/onboard/user-profile" component={OnboardUserProfile} />
        <Route exact path="/onboard/encounter" component={OnBoardEncounter} />
        <Route exact path="/onboard/availability" component={OnboardAvailability} />
        <Route exact path="/onboard/advocacy-availability" component={OnboardAdvocacyAvailability} />
        <Route exact path="/onboard/calendar" component={OnboardConsultLaterCalendar} />
        <Route exact path="/onboard/referral" component={OnboardReferrals} />
        <Route exact path="/onboard/referral/details" component={OnboardReferralDetails} />
        <Route exact path="/onboard/notification" component={DoctorNotificationCMS} />
        {/* <Route exact path="/onboard/settings/deactivate-account" component={OnboardDeactivateAccount} /> */}

        <Route exact path="/onboard/attendance" component={OnboardAttendance} />
        <Route exact path="/onboard/settings/delete-account" component={OnboardDeleteAccount} />
        <Route exact path="/onboard/attendance/leave-request" component={OnboardLeaveRequest} />
        <Route exact path="/onboard/attendance/leave-request/list" component={OnboardLeaveRequestList} />

        {/* Onboard End */}

        {/* Transaction Start */}

        <Route exact path="/transaction/booked-consults" component={TransactionBookedConsults} />
        <Route exact path="/transaction/booked-details" component={TransactionBookedDetails} />
        <Route exact path="/transaction/dashboard" component={TransactionDashboard} />
        <Route exact path="/transaction/login" component={TransactionLogin} />
        <Route exact path="/transaction/oncall-consults" component={TransactionOncallConsults} />
        <Route exact path="/transaction/oncall-details" component={TransactionOncallDetails} />

        {/* Transaction End */}


        
        {/* User Start */}
        <Route exact path="/user/retrieve" component={UserRetrieve} />
        {/* User End */}

        
        {/* Voucher Admin Start */}
        <Route exact path="/voucher" component={VoucherLogin} />
        <Route exact path="/voucher/add-voucher" component={VoucherAddVoucher} />
        <Route exact path="/voucher/dashboard" component={VoucherDashboard} />
        <Route exact path="/voucher/edit-voucher" component={VoucherEditVoucher} />
        <Route exact path="/voucher/login" component={VoucherLogin} />
        <Route exact path="/voucher/voucher-list" component={VoucherList} />
        <Route exact path="/voucher/healthpass-list" component={VoucherHealthpassList} />
        <Route exact path="/voucher/healthpass-view" component={VoucherHealthpassView} />
        <Route exact path="/voucher/add-healthpass" component={VoucherHealthpassAdd} />
        <Route exact path="/voucher/healthpass-edit" component={VoucherHealthpassEdit} />
        <Route exact path="/voucher/campaign-list" component={VoucherCampaignList} />
        <Route exact path="/voucher/campaign-add" component={VoucherCampaignAdd} />
        <Route exact path="/voucher/campaign-edit" component={VoucherCampaignEdit} />
        <Route exact path="/voucher/campaign-view" component={VoucherCampaignView} />

        {/* <Route exact path="/voucher/doctorgroup-list" component={VoucherDoctorGroupList} />
        <Route exact path="/voucher/doctorgroup-add" component={VoucherDoctorGroupAdd} />
        <Route exact path="/voucher/doctorgroup-edit" component={VoucherDoctorGroupEdit} /> */}
        {/* <Route exact path="/voucher/doctorgroup-view" component={VoucherDoctorGroupView} /> */}

        <Route exact path="/voucher/usergroup-dashboard" component={VoucherUserGroupDashboard} />
        <Route exact path="/voucher/usergroup-list" component={VoucherUserGroupList} />
        <Route exact path="/voucher/usergroup-add" component={VoucherUserGroupAdd} />
        <Route exact path="/voucher/usergroup-edit" component={VoucherUserGroupEdit} />
        {/* <Route exact path="/voucher/usergroup-view" component={VoucherUserGroupView} /> */}
        <Route exact path="/voucher/usergroup-view" component={UserGroupView} />

        <Route exact path="/voucher/healthpass-history-list" component={VoucherHealthpassHistoryList} />
        <Route exact path="/voucher/healthpass-history-view" component={VoucherHealthpassHistoryView} />
        <Route exact path="/voucher/healthpass-dashboard" component={VoucherHealthpassDashboard} />

        {/* Voucher Admin End */}


        {/* User Group  Start */}

        <Route exact path="/usergroupadmin/dashboard" component={UserGroupDashboard} />
        <Route exact path="/usergroupadmin/usergroup-list" component={VoucherUserGroupList} />
        <Route exact path="/usergroupadmin/usergroup-view" component={UserGroupView} />
        <Route exact path="/usergroupadmin/usergroup-edit" component={UserGroupEdit} />
        <Route exact path="/usergroupadmin/usergroup-subscriptions-view" component={UserGroupSubscriptionsView} />

        {/* <Route exact path="/usergroupadmin/usergroup-edit" component={VoucherUserGroupEdit} />
        <Route exact path="/usergroupadmin/usergroup-add" component={VoucherUserGroupAdd} /> */}
        <Route exact path="/usergroupadmin/login" component={UserGroupLogin} />

        {/* User Group End */}

        {/* Legal Role */}
        <Route exact path="/legal/dashboard" component={LegalDoctorFileRepository} />

        {/* Marketing  Start */}

        <Route exact path="/marketing/dashboard" component={MarketingDashboard} />

        <Route exact path="/marketing/registered-referrals" component={MarketingRegisteredReferrals} />

        <Route exact path="/marketing/patient-referrals" component={MarketingPatientReferrals} />
        <Route exact path="/marketing/patient-referrals-view" component={MarketingPatientReferralsView} />

        <Route exact path="/marketing/doctor-referrals" component={MarketingDoctorReferrals} />
        <Route exact path="/marketing/doctor-referrals-view" component={MarketingDoctorReferralsView} />

        <Route exact path="/marketing/campaign-referrals" component={MarketingCampaignReferrals} />
        <Route exact path="/marketing/campaign-referrals-add" component={MarketingCampaignReferralsAdd} />
        <Route exact path="/marketing/campaign-referrals-view" component={MarketingCampaignReferralsView} />
        <Route exact path="/marketing/campaign-referrals-edit" component={MarketingCampaignReferralsEdit} />
        <Route exact path="/marketing/main-banners" component={MarketingMainBanners} />
        <Route exact path="/marketing/main-banners-add" component={MarketingMainBannersAdd} />
        <Route exact path="/marketing/main-banners-edit" component={MarketingMainBannersEdit} />
        <Route exact path="/marketing/mind-health" component={MarketingMindHealthBanners} />
        <Route exact path="/marketing/mind-health-add" component={MarketingMindHealthBannersAdd} />
        <Route exact path="/marketing/mind-health-edit" component={MarketingMindHealthBannersEdit} />
        <Route exact path="/marketing/registration-popup" component={MarketingPopUpBanners} />
        <Route exact path="/marketing/registration-popup-add" component={MarketingPopUpBannersAdd} />
        <Route exact path="/marketing/registration-popup-edit" component={MarketingPopUpBannersEdit} />
        <Route exact path="/marketing/mwellness-score" component={MarketingMwellnessBanners} />
        <Route exact path="/marketing/mwellness-score-add" component={MarketingMwellnessBannersAdd} />
        <Route exact path="/marketing/mwellness-score-edit" component={MarketingMwellnessBannersEdit} />

        <Route exact path="/marketing/promo-winners" component={MarketingPromoWinners} />
        <Route exact path="/marketing/promo-winners-view" component={MarketingPromoWinnersView} />
        
        {/* Marketing End */}
        
        <Route path="*" component={PageNotFound} />

      </Switch>
  );
}


export default AppRouter;
