import UI from "../../shared/Components/UI/js";
class DH {
  escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  };
  carespan = {
    get_name: (arr) => {
      if (!(arr instanceof Array)) {
        return "-";
      }
      const len = arr.length;
      if (len === 0) {
        return "-";
      }
      const item = arr[0];
      const last_name = item.family;
      const given_arr = item.given;
      const first_name = given_arr[0];
      let middle_name = "";
      if (given_arr.length > 1) {
        middle_name = given_arr[1];
      }
      if (middle_name) {
        return first_name + " " + middle_name + " " + last_name;
      } else {
        return first_name + " " + last_name;
      }
    },
    get_name_obj: (arr) => {
      const len = arr.length;
      const item = arr[len - 1];
      const last_name = item.family;
      const given_arr = item.given;
      const first_name = given_arr[0];
      let middle_name = "";
      if (given_arr.length > 1) {
        middle_name = given_arr[1];
      }

      return { first_name, middle_name, last_name };
    },
    get_specialty: (arr) => {
      if (!(arr instanceof Array)) {
        return "-";
      }
      if (arr.length > 0) {
        return arr[0];
      } else {
        return "-";
      }
    },
    get_specialty_slashed: (arr) => {
      if (!(arr instanceof Array)) {
        return "-";
      }
      if (arr.length > 0) {
        return (arr[0]??"").replace(new RegExp(this.escapeRegExp(","), "g"), "/");
      } else {
        return "-";
      }
    },
    get_specialties: (arr) => {
      if (!(arr instanceof Array)) {
        return "-";
      }
      if (arr.length > 0) {
        return arr.join(", ").replace(/,\s*$/, "");
      } else {
        return "-";
      }
    },
    get_languages: (arr) => {
      if (!(arr instanceof Array)) {
        return "-";
      }
      if (arr.length > 0) {
        return arr.join(", ").replace(/,\s*$/, "");
      } else {
        return "-";
      }
    },
    get_address: (arr) => {
      if (!(arr instanceof Array)) {
        return "-";
      }
      if (arr.length > 0) {
        return `${arr[0].line} ${arr[0].city} ${arr[0].state} ${arr[0].country} ${arr[0].postalCode}`
      } else {
        return "-";
      }
    },
    get_specialties_slashed: (arr) => {
      if (!(arr instanceof Array)) {
        return "-";
      }
      if (arr.length > 0) {
        return (arr.join(", ").replace(/,\s*$/, "")??"").replace(new RegExp(this.escapeRegExp(","), "g"), "/");
      } else {
        return "-";
      }
    },
    get_date: (date_obj, will_correct) => {
      const now = new Date(date_obj);
      if (will_correct) {
        now.setDate(now.getDate() - 1);
      }
      const date = UI.timestampToDate(now.getTime() + 86400000);
      if (!date.month) {
        return "-";
      }
      return date.month + "/" + date.day + "/" + date.year;
    },

    get_date_obj: (yyyymmdd) => {
      const arr = yyyymmdd.split("-");
      const y = arr[0];
      const m = arr[1];
      const d = arr[2];
      let date = new Date();
      date.setFullYear(parseInt(y));
      date.setMonth(parseInt(m) - 1);
      date.setDate(parseInt(d));

      return date;
    },

    get_email: (telecom = []) => {
      if (telecom.length > 0) {
        return (
          telecom.filter((item) => item["system"] === "email")[0].value + ""
        );
      } else {
        return " ";
      }
    },
    get_phone: (telecom = []) => {
      if (telecom.length > 0) {
        return (
          telecom.filter((item) => item["system"] === "phone")[0].value + ""
        );
      } else {
        return " ";
      }
    },

    remove_pad2: (date/*  */) => {
      const spl = date.split("-");
      const year = spl[0];
      const month = spl[1];
      const day = spl[2];
      
      let number = parseInt(month, 10);

      return year + "-" + number + "-" + day;
    }

  };

  title = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return " ";
    }
  };
}

const DataHelper = new DH();
export default DataHelper;
