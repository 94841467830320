import React, { useCallback, useState, useEffect } from "react";
import DataTable from "../../ComponentsFC/DataTable";
import UI from "../../shared/Components/UI/js";
import styled from "styled-components";
import { Row, Button, Input, Select, Typography, Col } from "antd";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import ServiceUsersGroupSubscriptionApi from "../../api_services/subscriptions";
import { Failed, Success } from "../_components/Modal";
import ServiceSubscriptionsApi from "../../api_services/subscriptions";
// import toTitleCase from "../../utils/toTitleCase";
// import { Text } from "../../shared/custom-react-native";

const EcommerceUserGroupSubscriptionBillingTable = ({ _this }) => {
  const [tableData, setTableData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(30);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [filterByStatus, setFilterByStatus] = useState("");
  const [filterByBillingStatus, setFilterByBillingStatus] = useState("");

  const getAllBillingSubscriptionsByLoggedInMerchant = useCallback(async () => {
    setIsLoading(true);

    let params = {};

    if (searchQuery) {
      params.keyword = searchQuery;
    }

    if (currentPageNum) {
      params.page = currentPageNum;
      params.limit = tableEntries;
    }

    if (filterByBillingStatus) {
      params.paymentStatus = filterByBillingStatus;
    }

    if (filterByStatus) {
      params.userGroupStatus = filterByStatus;
    }

    ServiceSubscriptionsApi.getAllBillingSubscriptionsByLoggedInMerchant(
      params
    ).then((res) => {
      setIsLoading(false);
      if (res.status === 200) {
        let data = res.data;
        setTotalRecords(res.count);
        setTableData(data);
        console.log("GET ALL BILLING USERGROUP ", data);
      }
    });
  }, [
    currentPageNum,
    tableEntries,
    searchQuery,
    filterByBillingStatus,
    filterByStatus,
  ]);

  useEffect(() => {
    getAllBillingSubscriptionsByLoggedInMerchant();
  }, [getAllBillingSubscriptionsByLoggedInMerchant]);

  const handleFilterbyStatus = (status) => {
    setFilterByStatus(status);
  };

  const handlePaginateTable = (pageNum) => {
    // console.log("Currently Selected Page # of Antd Table: ", pageNum);
    setCurrentPageNum(pageNum);
  };

  const tableColumnHeader = [
    {
      title: "Group Name",
      dataIndex: "groupName",
      key: "groupName",
      render: (groupName, item) => item?.userGroupName ?? "--",
      width: 200,
    },
    {
      title: "Subscription Type",
      dataIndex: "planName",
      key: "planName",
      render: (planName, item) => item?.subscription?.planName ?? "--",
      width: 200,
    },
    {
      title: "Billing Date",
      dataIndex: "billingDueDate",
      key: "billingDueDate",
      render: (billingDueDate, item) => {
        if (billingDueDate) {
          return UI.momentPHTimezone(billingDueDate).format("MM/DD/YYYY");
        } else {
          return "--";
        }
      },
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "userGroupActiveStatus",
      key: "userGroupActiveStatus",
      render: (userGroupActiveStatus) => {
        if (userGroupActiveStatus) {
          return userGroupActiveStatus === "ACTIVATED"
            ? "Active"
            : userGroupActiveStatus === "DEACTIVATED"
            ? "Deactivated"
            : userGroupActiveStatus === "CANCELLED"
            ? "Cancelled"
            : userGroupActiveStatus === "MERCHANT_CANCEL"
            ? "Merchant Cancelled"
            : "--";
        } else {
          return "--";
        }
      },
      width: 200,
    },
    // {
    //   title: "Start Date",
    //   dataIndex: "startDate",
    //   key: "startDate",
    //   render: (startDate, item) => {
    //     return item?.userGroupDateActivated  ? UI.momentPHTimezone(item?.userGroupDateActivated ).format('MM/DD/YYYY') : "--"
    //   },
    //   width: 200,
    // },
    // {
    //   title: "End Date",
    //   dataIndex: "endDate",
    //   key: "endDate",
    //   render: (endDate, item) => {
    //     return item?.userGroupDateEnd ? UI.momentPHTimezone(item?.userGroupDateEnd).format('MM/DD/YYYY') : "--"
    //   },
    //   width: 300,
    // },
    // {
    //   title: "Status",
    //   dataIndex: "paymentStatus",
    //   key: "paymentStatus",
    //   render: (paymentStatus, item) => {
    //     return (
    //       <Text style={{ color: "#27ae60" }}>{ paymentStatus ? UI.get_color_status_text(paymentStatus) : "--" }</Text>
    //       // <ActionStyledSelect
    //       //   getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
    //       //   placeholder="Set Status"
    //       //   style={{
    //       //     width: "100%",
    //       //     height: "20px !important"
    //       //   }}
    //       //   value={item.userGroupActiveStatus  ?? "" }
    //       //   // allowClear
    //       //   suffixIcon={<DropdownIcon />}
    //       //   onChange={(value) => {
    //       //     updateRequestStatus(value, item.subscription, item.userGroup)
    //       //     // setFilterByStatus(value)
    //       //   }}
    //       //   onClear={() => handleClearViaSelectField("Status")}
    //       // >
    //       //   <Select.Option title="Action" value="ACTIVATED">
    //       //    Activated
    //       //   </Select.Option>
    //       //   <Select.Option title="Action" value="DEACTIVATED">
    //       //     Deactivate
    //       //   </Select.Option>
    //       //   <Select.Option title="Action" value="CANCELLED">
    //       //     Cancel
    //       //   </Select.Option>
    //       // </ActionStyledSelect>
    //     )
    //   },
    //   width: 200,
    // },
    {
      title: "Billing Status",
      dataIndex: "createdAt",
      key: "date_added",
      render: (user, item) => {
        return (
          <ActionStyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            placeholder="Set status"
            style={{
              width: "100%",
              height: "20px !important",
            }}
            value={item.paymentStatus}
            suffixIcon={<DropdownIcon />}
            onChange={(value) => {
              updateBillingStatus(value, item);
            }}
            onClear={() => handleClearViaSelectField("Status")}
          >
            <Select.Option title="Action" value="PAID">
              Paid
            </Select.Option>
            <Select.Option title="Action" value="UNPAID">
              Unpaid
            </Select.Option>
            <Select.Option title="Action" value="PENDING">
              Pending
            </Select.Option>
          </ActionStyledSelect>
        );
      },
      width: 200,
    },
  ];

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCSVExport = () => {
    const csv_data = tableData.map((item, index) => {
      const d = {
        "Group Name": item?.userGroupName ?? "--",
        "Subscription Type": item?.subscription?.planName ?? "--",
        "Start Date": item?.userGroupDateActivated
          ? UI.momentPHTimezone(item?.userGroupDateActivated).format(
              "MM/DD/YYYY"
            )
          : "--",
        "End Date": item?.userGroupDateEnd
          ? UI.momentPHTimezone(item?.userGroupDateEnd).format("MM/DD/YYYY")
          : "--",
        // "Status": item?.user?.phoneNumber ?? " ",
        Billing: item.paymentStatus ?? "--",
      };

      return d;
    });

    UI.DownloadCSV(
      "Subscription Billing " + UI.get_current_date_string(),
      csv_data
    );
  };

  const handleClearViaSelectField = (fieldName) => {
    console.log("Handle CLEAR", fieldName);
    if (fieldName === "Date Filter by") {
      setSearchResults([]);
    }
  };

  // const updateRequestStatus = (status, subscriptionId, usergroupId) => {
  //   const payload = {
  //     userGroupId: usergroupId,
  //     status: status
  //   }

  //   ServiceUsersGroupSubscriptionApi.updateUserGroupSubscriptionPlan(subscriptionId, payload).then( res => {
  //     if (res.status === 200) {
  //       _this.show_modal(<Success
  //         title={"Success"}
  //         description={`Successfully ${status} subscription`}
  //         onDismiss={() => {
  //           _this.hide_modal();
  //         }}
  //       />);
  //     } else {
  //       _this.show_modal(
  //         <Failed
  //           description={res.message}
  //           onDismiss={() => {
  //             _this.hide_loading();
  //             _this.hide_modal()
  //           }}
  //         />
  //       );
  //     }
  //   });
  // }

  const updateBillingStatus = (status, item) => {
    const payload = {
      paymentStatus: status,
    };
    setIsLoading(true);
    ServiceUsersGroupSubscriptionApi.updateUserGroupSubscriptionPaymentStatusByMerchant(
      item._id,
      payload
    ).then((res) => {
      setIsLoading(false);
      if (res.status === 200) {
        _this.show_modal(
          <Success
            title={"UPDATED"}
            // description={`Successfully ${status} subscription`}
            description={`Payment status updated`}
            onDismiss={() => {
              getAllBillingSubscriptionsByLoggedInMerchant();
              _this.hide_modal();
            }}
          />
        );
      } else {
        _this.show_modal(
          <Failed
            description={res.message}
            onDismiss={() => {
              _this.hide_modal();
            }}
          />
        );
      }
    });
  };

  return (
    <>
      <FilterBox>
        <Title level={5}>Billing</Title>
        <Toolbar gutter={14}>
          <ToolItem span={8}>
            <StyledFieldLabel>Status</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Filter Option"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={handleFilterbyStatus}
              // onChange={(value) => setFilterByStatus(value)}
              // onClear={() => handleClearViaSelectField("Status")}
            >
              <Select.Option title="Action" value="ACTIVATED">
                Active
              </Select.Option>
              <Select.Option title="Action" value="DEACTIVATED">
                Deactivated
              </Select.Option>
              <Select.Option title="Action" value="CANCELLED">
                Cancelled
              </Select.Option>
              {/* <Select.Option title="Action" value="MERCHANT_CANCEL">
                Merchant Cancelled
              </Select.Option> */}

              {/* <Select.Option title="Action" value="WAITING_ACTIVATION">
                  Pending
                </Select.Option> */}
            </StyledSelect>
          </ToolItem>
          <ToolItem span={8}>
            <StyledFieldLabel>Billing</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Filter Option"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => setFilterByBillingStatus(value)}
              // onClear={() => handleClearViaSelectField("Status")}
            >
              <Select.Option title="Action" value="PAID">
                Paid
              </Select.Option>
              <Select.Option title="Action" value="UNPAID">
                Unpaid
              </Select.Option>
              <Select.Option title="Action" value="PENDING">
                Pending
              </Select.Option>
            </StyledSelect>
          </ToolItem>
        </Toolbar>
      </FilterBox>
      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="30"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="5">5 Entries</Select.Option>
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>
          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getAllBillingSubscriptionsByLoggedInMerchant}
            style={{ width: 200 }}
          />
          <StyledButton type="primary" onClick={handleCSVExport}>
            Export to CSV
          </StyledButton>
        </Toolbar>
        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          defaultPageSize={30}
          pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default EcommerceUserGroupSubscriptionBillingTable;

// CSS Styled Components ----------------------
const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const ToolItem = styled(Col)`
  position: relative;
  .ant-picker {
    margin-left: 102px;
  }
  .ant-picker-range {
    margin-left: 62px;
  }
`;

const Title = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.4375rem !important; // 15px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
  padding-left: 35px;
  margin-bottom: 25px !important;
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const ActionStyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 10px !important;
    border-width: 2px !important;
    height: 40px !important;
    align-items: center;
    font-size: 10px;
    line-height: 1.4375rem;
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;

// const CellStyle = styled(Typography.Text)`
//   // Dynamically render style using props of this component
//   color: ${(props) =>
//     props.status === "ACTIVATED"
//       ? "#27AE60"
//       : props.status === "DISAPPROVED"
//       ? "#CC0404"
//       : "#BBBBBB"};
// `;
