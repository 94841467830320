import React, { useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'; 
import { Button } from 'antd';
import styled from 'styled-components';

const DigitalESignatureCanvas = ({ _this }) => {
  const sigCanvas = useRef();

  const createDigitalSignature = () => {
    if(!sigCanvas.current.isEmpty()) {
      const dataUrl = sigCanvas.current.getCanvas().toDataURL("image/png");

      fetch(dataUrl)
        .then(response => response.blob())
        .then(blob => {
            const file = new File([blob], "signature.png", { type: blob.type })
            _this.auth_upload("signature_path", file)
            _this.hide_modal();
        })
    } else {
      _this.hide_modal();
    }
  }

  return (
    <>
      <div
        style={{ height: 315, backgroundColor: "#E8F2FD" }}
        className="rounded-xl px-8" 
      >
        <SignatureCanvas
            penColor="black"
            canvasProps={{
              width: 400,
              height: 250,
              className: 'sigCanvas',
            }}
            ref={sigCanvas}
        />

        <div className='border-t pt-4 pb-2 text-base'>
          <p className='text-center'>I agree to the terms and conditions</p>
        </div>
      </div>
      <div className="flex flex-row-reverse mt-4">
        <StyledPrimaryButton 
          size={"large"}
          type="primary"
          onClick={() => {
            createDigitalSignature();
          }}
        >Save</StyledPrimaryButton>

        <StyledDefaultButton
          size={"large"}
          type="default"
          onClick={() => {
            sigCanvas.current.clear();
          }}
        >Clear</StyledDefaultButton>
      </div>
    </>
  )
}

const StyledPrimaryButton = styled(Button)`
  margin-left: 1rem;
  border-radius: 12px;
  background: #004F99;
  color: #fff;
  padding: 4px 40px;
  height: 50px;
  font-weight: bold;
`

const StyledDefaultButton = styled(Button)`
  border-radius: 12px;
  background: #fff;
  color: #004F99;
  padding: 4px 40px;
  height: 50px;
  font-weight: bold;
`

export default DigitalESignatureCanvas;