import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import styled from "styled-components";
import { Row, Col, Input, Select, Typography } from "antd";
import DropdownIcon from "../../icons/Dropdown";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
// import UsersGroupCategoryApi from "../../api_services/subscriptions";
import { Failed, Success } from "../_components/Modal";
import LaboratoryServicesApi from "../../api_services/Merchant/laboratoryServices";

const context = "merchant";

export default class EcommerceCategoriesEdit extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Product Categories",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      categoryName: "",
      status: "",
    });
  }

  onCreate = () => {
    this.load_data();
  };

  load_data = () => {
    const data = JSON.parse(mem.get("categories_edit"));
    console.log(data)
    if (data) {
     this.setState({
      id: data._id,
      categoryName: data.name,
      status: data.status ? 'true' : 'false',
     })
    }
  }

  submit = async () => {
    UI.clear_errors();
    const {
      categoryName,
      status,
      id
    } = this.state;

    const payload = {
      name: categoryName,
      status: status === 'true' ? true : false,
    }

    LaboratoryServicesApi.updateCategory(id, payload).then((res) => {
      // setIsLoading(false);
      if (res.status === 200) {
        this.show_modal(<Success
          title={"Success"}
          description={`Successfully updated category`}
          onDismiss={() => {
            UI.goBack()
          }}
        />);
      }  else {
        this.show_modal(
          <Failed
            description={res.message} 
            onDismiss={() => { 
              this.hide_loading();
              this.hide_modal()
            }} 
          />
        );
      }
    });
  };

  render() {
    const _this = this;
    
    const {
      categoryName,
      status,
    } = _this.state;

    return (
      <MainLayout context={context} 
        // additional={"categories_list"} 
        _this={_this}
      >
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Edit Laboratory Service Category
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add item
            </Text>
          </UI.Row>

          <CategoryRow>
            <ColBar gutter={16}>
              <ToolItem className="gutter-row" span={8}>
                <StyledFieldLabel>Category Name</StyledFieldLabel>
                <StyledInput
                  placeholder="Enter Category Name"
                  value={categoryName}
                  // allowClear
                  onChange={(e) => {
                    this.setState({ categoryName: e.target.value})
                  }}
                />
              </ToolItem>
              <ToolItem className="gutter-row" span={8}>
                <StyledFieldLabel>Status</StyledFieldLabel>
                <StyledSelect
                  getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                  placeholder="Select Status"
                  // defaultValue={null}
                  value={status}
                  style={{
                    width: "100%",
                  }}
                  suffixIcon={<DropdownIcon />}
                  onChange={(value) => {
                    this.setState({ status: value})
                  }}
                >
                  <Select.Option title="Action" value="true">
                    Active
                  </Select.Option>
                  <Select.Option title="Action" value="false">
                    Inactive
                  </Select.Option>
                </StyledSelect>
              </ToolItem>
            </ColBar>
          </CategoryRow>

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.secondary}
              onClick={() => {
                this.submit();
              }}
              text={"Save Changes"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"Cancel"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});

// CSS Styled Components ----------------------
const CategoryRow = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 33px 0 0 0;
  margin-bottom: 30px;

  .ant-row {
    padding-left: 0;
    padding-right: 0;
  }

  .select-item-section {
    .ant-typography {
      margin-top: 10px;
    }
  }
`;

const ColBar = styled(Row)`
  display: inline-flex;
  align-items: flex-start;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 0;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const ToolItem = styled(Col)`
  position: relative;
  .ant-picker {
    margin-left: 102px;
  }
  .ant-picker-range {
    margin-left: 62px;
  }
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;