import React from "react";

const MedicalCertificateSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.345"
      height="33.794"
      viewBox="0 0 25.345 33.794"
    >
      <path
        id="file-medical-solid"
        d="M4.224,0A4.228,4.228,0,0,0,0,4.224V29.569a4.228,4.228,0,0,0,4.224,4.224h16.9a4.228,4.228,0,0,0,4.224-4.224V10.561H16.9a2.11,2.11,0,0,1-2.112-2.112V0ZM16.9,0V8.448h8.448ZM10.561,15.841a1.059,1.059,0,0,1,1.056-1.056h2.112a1.059,1.059,0,0,1,1.056,1.056v3.168h3.168a1.059,1.059,0,0,1,1.056,1.056v2.112a1.059,1.059,0,0,1-1.056,1.056H14.785V26.4a1.059,1.059,0,0,1-1.056,1.056H11.617A1.059,1.059,0,0,1,10.561,26.4V23.233H7.392a1.059,1.059,0,0,1-1.056-1.056V20.065a1.059,1.059,0,0,1,1.056-1.056h3.168Z"
        fill="#035099"
      />
    </svg>
  );
};

export default MedicalCertificateSvg;
