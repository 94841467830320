import React, { useState, useEffect, useCallback } from "react";
// import { useHistory } from "react-router-dom";
import { Badge, Avatar, Dropdown, Row } from "antd";
import styled from "styled-components";
import NotificationBellIcon from "../../icons/NotificationBell";
import DPReferralApi from "../../api_services/Doctors-Portal/DPReferralApi";

const NotificationsBell = (props) => {
  // const history = useHistory();
  const { isRefresh, resetRefreshNotificationsBell } = props;
  const [patientReferralData, setPatientReferralData] = useState();
  const [notificationCount, setNotificationCount] = useState(0);

  const getReferralsList = useCallback(async () => {
    try {
      let urlParams = {};
      urlParams.sort = "createdAt";
      urlParams.type = "notification";
      const apiResponse = await DPReferralApi.getReferralList(urlParams);
      console.log("GET NOTIFICATION REFERRAL LIST: ", apiResponse);
      setPatientReferralData(apiResponse);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getReferralsList();
    }, 3000);
    return () => clearInterval(interval);
  }, [getReferralsList]);

  useEffect(() => {
    if (isRefresh === false) return; //blocker
    if (isRefresh === true) {
      getReferralsList();
      resetRefreshNotificationsBell();
    }
  }, [getReferralsList, resetRefreshNotificationsBell, isRefresh]);

  useEffect(() => {
    // INSERT DATA STATE HERE in if statement 👇
    if (
      patientReferralData
      // || nameYourOwnData
    ) {
      setNotificationCount(patientReferralData.length);
    }
  }, [patientReferralData]);

  // LIST YOUR DATA HERE 👇
  const patientReferralList =
    patientReferralData?.length > 0
      ? patientReferralData?.map((item) => (
          <StyledList
            key={item?._id}
            onClick={() => props?.onPatientReferralModalOpen(item)}
          >{`Patient Referral: ${item?.patientInfo[0]?.fullname}`}</StyledList>
        ))
      : "";

  // const nameYourOwnDataList =
  //   nameYourOwnData?.length > 0
  //     ? nameYourOwnData?.map((item) => (
  //         <StyledList
  //           key={item?.doctorAppointment}
  //           onClick={() => history.push("/onboard/dashboard")}
  //         >{`Appointment w/ ${item?.doctorAppointment}`}</StyledList>
  //       ))
  //     : "";

  // ADD THE DATA VARIABLE LIST HERE 👇
  const combinedDropDownList = (
    <StyledRow>
      {patientReferralList}
      {/* {nameYourOwnDataList} */}
    </StyledRow>
  );

  return (
    <Dropdown
      overlay={combinedDropDownList}
      placement="bottomLeft"
      trigger={"click"}
      overlayStyle={
        notificationCount > 4
          ? {
              maxHeight: "225px",
              maxWidth: "300px",
              overflow: "auto",
              borderRadius: "8px",
              visibility: "visible",
            }
          : notificationCount === 0
          ? { visibility: "hidden" }
          : { maxWidth: "300px" }
      }
    >
      <StyledBadge count={notificationCount}>
        <Avatar
          src={<NotificationBellIcon style={{ fontSize: 10 }} />}
          style={{ cursor: "pointer" }}
        />
      </StyledBadge>
    </Dropdown>
  );
};

export default NotificationsBell;

const StyledBadge = styled(Badge)`
  //Since badge is the parent of Bell, styles apply as whole
  transform: scale(0.9);
  margin-right: 15px;
  margin-left: 15px;
`;

const StyledRow = styled(Row)`
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const StyledList = styled.li`
  padding: 5px 10px 5px 10px;

  font-family: Poppins;
  font-size: 16px;

  &:hover {
    background-color: #43c8ff;
    color: #ffffff;
    cursor: pointer;
  }
`;
