import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Select,
  Typography,
  Image,
  Space,
  DatePicker,
  // Spin,
} from "antd";
import styled from "styled-components";
import UI from "../../shared/Components/UI/js";
// import { mem } from "../../shared/custom-react-native";
import DropdownIcon from "../../icons/Dropdown";
import DatePickerCalendarIcon from "../../icons/DatePickerCalendar";
import DataTable from "../../ComponentsFC/DataTable";
import ServiceAttendanceApi from "../../api_services/Doctors-Portal/DPAttendanceApi.js";
import toTitleCase from "../../utils/toTitleCase.js";

const OnboardAttendance = () => {
  const history = useHistory();

  const [tableData, setTableData] = useState([]);
  const [tableEntries, setTableEntries] = useState(30);
  const [totalRecords, setTotalRecords] = useState();
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [isLoading, setTableIsLoading] = useState(false);
  const [isTimeIn, setIsTimeIn] = useState();
  const [isTimeOut, setTimeOut] = useState();
  const [doctorName, setDoctorName] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [appliedStartDate, setAppliedStartDate] = useState();
  const [appliedEndDate, setAppliedEndDate] = useState();

  const [isClockedIn, setIsClockedIn] = useState();
  const [lastClockedIn, setLastClockedIn] = useState(null);
  const [latestClockedIn, setLatestClockedIn] = useState();

  const [isClicked, setIsClicked] = useState();

  const getDoctorsAttendance = useCallback(async () => {
    try {
      let urlParams = {};

      if (currentPageNum) {
        urlParams.page = currentPageNum;
        urlParams.limit = tableEntries;
      }

      if (appliedStartDate && appliedEndDate) {
        urlParams.startDate = appliedStartDate;
        urlParams.endDate = appliedEndDate;
      }

      setTableIsLoading(true);
      const apiResponse = await ServiceAttendanceApi.getDoctorsAttendance(
        urlParams
      );
      setTableIsLoading(false);
      // console.log("GET DOCTOR ATTENDANCE API RESPONSE", apiResponse);
      setLastClockedIn(apiResponse?.lastClockIn);
      setTotalRecords(apiResponse?.results?.total);
      setDoctorName(apiResponse?.data[0]?.name);
      setTableData(apiResponse?.data?.map((obj) => ({ ...obj, key: obj._id })));
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.c;
        let errorMsg = err.response.data.m;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }, [currentPageNum, tableEntries, appliedStartDate, appliedEndDate]);

  useEffect(() => {
    getDoctorsAttendance();
  }, [getDoctorsAttendance]);

  const handleApplyShiftDateRange = (startDate, endDate) => {
    setAppliedStartDate(startDate);
    setAppliedEndDate(endDate);
  };
  const handleChangeFilterByShiftDateRange = (dates, dateStrings) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  const getClockStatus = async () => {
    try {
      // console.log("GET CLOCK STATUS API RESPONSE BEFORE");
      const apiResponse = await ServiceAttendanceApi.getClockStatus();
      setIsClockedIn(apiResponse.doctor.isClockIn);
      setIsTimeIn(apiResponse.doctor.isClockIn ? true : false);
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.c;
        let errorMsg = err.response.data.m;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  };

  useEffect(() => {
    // let userDoctor = mem.get(Constants.USER_DOCTOR);
    // let userDoctorObj = JSON.parse(userDoctor);
    // let isClockIn = userDoctorObj.isClockIn;
    // let lastClockedIn = userDoctorObj.doctorLoggedDateOnline;
    getClockStatus();
  }, []);

  const handleTimeIn = async () => {
    try {
      let urlParams = `?type=${"in"}`;

      setIsTimeIn(true);
      setIsClicked(true);
      const apiResponse =
        await ServiceAttendanceApi.createDoctorAttendanceTimeLogs(urlParams);
      setLatestClockedIn(
        moment(apiResponse.doctorLoggedDateOnline)
          .format("hh:mm A")
          .toLowerCase()
      );
      console.log(
        "Clocked In at: ",
        moment(apiResponse.doctorLoggedDateOnline)
          .format("hh:mm A")
          .toLowerCase()
      );
      console.log("Clock In status: ", apiResponse.status);
      getDoctorsAttendance();
      setIsClockedIn(true);
      setIsClicked(false);
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.c;
        let errorMsg = err.response.data.m;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  };

  const handleTimeOut = async () => {
    try {
      let urlParams = `?type=${"out"}`;
      setTimeOut(true);
      setIsClicked(true);
      const apiResponse =
        await ServiceAttendanceApi.createDoctorAttendanceTimeLogs(urlParams);
      console.log("Clock Out status: ", apiResponse.status);
      getDoctorsAttendance();
      setIsTimeIn(false);
      setIsClicked(false);
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.c;
        let errorMsg = err.response.data.m;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handlePaginateTable = (antdTablePageNum) => {
    setCurrentPageNum(antdTablePageNum);
  };

  const tableColumnHeader = [
    {
      title: "Attendance Date",
      dataIndex: "login",
      key: "login",
      render: (longDateString) => {
        if (longDateString) {
          return (
            UI.momentPHTimezone(longDateString, ["MMMM D, YYYY"]).format(
              "MMMM D, YYYY"
            ) ?? "--"
          );
        } else {
          return "--";
        }
      },
    },
    {
      title: "Time in",
      dataIndex: "login",
      key: "login",
      render: (timeStringLogin) => {
        if (timeStringLogin) {
          return (
            UI.momentPHTimezone(timeStringLogin)
              .format("hh:mm A")
              .toLowerCase() ?? "--"
          );
        } else {
          return "--";
        }
      },
    },
    {
      title: "Time Out",
      dataIndex: "logout",
      key: "logout",
      render: (timeStringLogout) => {
        if (timeStringLogout) {
          return (
            UI.momentPHTimezone(timeStringLogout)
              .format("hh:mm A")
              .toLowerCase() ?? "--"
          );
        } else {
          return "--";
        }
      },
    },
    {
      title: "Total Hours",
      dataIndex: "noOfHours",
      key: "noOfHours",
      render: (noOfHours) => {
        if (noOfHours) {
          // const extractedStr = noOfHours?.substring(0, 5).trim(); // Get string ("HHhrs" or "Hhrs ") and remove whitespace on both ends
          // const hrs = extractedStr.slice(0, extractedStr.length - 3); // Extract number in ("HHhrs" or "Hhrs ")
          // const mins = noOfHours?.substring(noOfHours.length - 6).slice(0, 2); //Get mins (MMmins) and get 1st 2 chars
          return (
            noOfHours ?? "--"
            // <>
            //   {/* SHOW HOURS IF VALUE IS NOT 0 */}
            //   {hrs === "" ? "" : `${hrs ?? "--"} Hours `}
            //   {/* SHOW MINS IF VALUE IS NOT 0 and SHOW SECS IF MINS === 0*/}
            //   {mins === "0" || mins === "" || mins === " 0"
            //     ? ""
            //     : `${mins ?? "--"} Minutes`}
            // </>
          );
        } else {
          return "0 Hours 0 Minutes";
        }
      },
    },
  ];

  return (
    <>
      <Row gutter={32}>
        <Col span={12}>
          <ActionBox>
            <Col span={18}>
              <CardTitle>
                Kamusta Ka Dr {doctorName ? toTitleCase(doctorName) : " "}
              </CardTitle>
              {!isClockedIn ? (
                <ClockInOutTitle level={5}>
                  {"You have not clocked in yet"}
                </ClockInOutTitle>
              ) : isTimeIn ? (
                <ClockInWithTime level={5}>
                  {`Clocked In: ${
                    latestClockedIn
                      ? latestClockedIn
                      : lastClockedIn
                      ? moment(lastClockedIn).format("hh:mm A").toLowerCase()
                      : " "
                  }`}
                </ClockInWithTime>
              ) : (
                <ClockInOutTitle level={5}>
                  {"You have successfully clocked out"}
                </ClockInOutTitle>
              )}

              <Toolbar gutter={14}>
                <ToolItem span={4}>
                  {!isClockedIn ? (
                    <TimeInButton
                      type="primary"
                      loading={isClicked}
                      onClick={handleTimeIn}
                      disabled={isTimeIn}
                    >
                      Clock In Now!
                    </TimeInButton>
                  ) : (
                    <TimeOutButton
                      type="danger"
                      onClick={handleTimeOut}
                      isdisabledcolor={isTimeOut ? "true" : "false"}
                      disabled={isTimeOut}
                      loading={isClicked}
                    >
                      Clock Out
                    </TimeOutButton>
                  )}
                </ToolItem>
              </Toolbar>
            </Col>

            <Col span={6}>
              <Image
                preview={false}
                height={"133px"}
                width={"133px"}
                src={UI.DOCTOR_ATTENDANCE}
              />
            </Col>
          </ActionBox>
        </Col>
        <Col span={12}>
          <LeaveRequestBox>
            <Col span={18}>
              <CardTitle>Leave Request</CardTitle>
              <FeatureSoonTitle level={5}>
                {"Feature Coming Soon"}
              </FeatureSoonTitle>
              <Toolbar gutter={14}>
                <ToolItem>
                  <LeaveRequestButton
                    type="primary"
                    onClick={() =>
                      history.push("/onboard/attendance/leave-request")
                    }
                  >
                    File Leave Request
                  </LeaveRequestButton>
                </ToolItem>
              </Toolbar>
            </Col>
            <Col span={6}>
              <Image
                preview={false}
                height={"133px"}
                width={"133px"}
                src={UI.DOCTOR_LEAVEREQUEST}
              />
            </Col>
          </LeaveRequestBox>
        </Col>
      </Row>
      <TableBox>
        <Title level={5} style={{ fontSize: "23px" }}>
          Attendance Records
        </Title>
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "20px",
          }}
        >
          <Col>
            <FieldLabel>Only Show</FieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              defaultValue="30"
              style={{
                width: 160,
              }}
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeNumOfEntries}
            >
              <Select.Option value="5">5 Entries</Select.Option>
              <Select.Option value="10">10 Entries</Select.Option>
              <Select.Option value="30">30 Entries</Select.Option>
            </StyledSelect>
          </Col>
          <Col>
            <Space direction="horizontal" style={{ gap: "0px" }}>
              <StyledRangePicker
                separator={<DatePickerCalendarIcon />}
                suffixIcon={<DatePickerCalendarIcon />}
                placeholder={["Start Date", "End Date"]}
                ranges={{
                  Today: [moment(), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                onChange={handleChangeFilterByShiftDateRange}
              />
              <ApplyButton
                type="primary"
                onClick={() => {
                  handleApplyShiftDateRange(startDate, endDate);
                }}
              >
                Apply
              </ApplyButton>
            </Space>
          </Col>
        </Toolbar>

        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={tableData}
          defaultPageSize={5}
          pageSizeOptions={["5", "10", "30"]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default OnboardAttendance;

// CSS Styled Components ----------------------

const StyledRangePicker = styled(DatePicker.RangePicker)`
  flex-direction: row;
  border-radius: 12px;
  border: 1px solid transparent;
  box-shadow: none !important;
  height: 50px;
  &:hover {
    border-color: transparent !important;
  }
  .ant-picker-input {
    border: 2px solid #d9d9d9;
    border-radius: 12px;
    padding-left: 10px;
    padding-right: 10px;
    width: 191px;
    height: 55px;
  }
  .ant-picker-range-separator {
    display: block !important; //Need this bcoz the this selector css.css interferes globally
    margin-left: -43px; //To insert built-in icon inside input field
    margin-right: 16px;
  }
  .ant-picker-suffix {
    margin-left: -35px; //To insert built-in icon inside input field
  }
  .ant-picker-active-bar {
    display: none;
  }
  .ant-picker-clear {
    /* display: none; */
    right: 18px;
  }
`;

const ActionBox = styled(Row)`
  position: relative;
  display: flex;
  position: relative;
  flex-direction: row;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
  width: 100%;
`;

const LeaveRequestBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: row;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
  width: 100%;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
  display: inline-block;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
  // ALL TABLE BODY CELLS
  .ant-table-thead > tr > th {
    height: 70px;
  }
  // ALL TABLE BODY CELLS
  .ant-table-tbody > tr > td {
    height: 114.7px;
  }
`;

const FeatureSoonTitle = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.625rem !important; // 26px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #ccdeea !important;
  padding-left: 35px;
  margin-bottom: 25px !important;
  visibility: hidden;
`;

const ClockInWithTime = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.625rem !important; // 26px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #37cfb1 !important;
  padding-left: 35px;
  margin-bottom: 25px !important;
`;

const ClockInOutTitle = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.625rem !important; // 26px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #035099 !important;
  padding-left: 35px;
  margin-bottom: 25px !important;
`;

const Title = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.4375rem !important; // 15px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
  padding-left: 35px;
  margin-bottom: 25px !important;
`;

const CardTitle = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.25rem !important; // 20px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #535353 !important;
  padding-left: 35px;
  margin-bottom: 19px !important;
`;

const Toolbar = styled(Row)`
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 40px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const ToolItem = styled(Col)`
  position: relative;
  min-width: 135px;
  max-width: 135px;
`;

const TimeInButton = styled(Button)`
  width: 183px;
  height: 67px;
  border-radius: 14px;
  border: none;
  color: #ffffff;
  background-color: #00cbff;
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.25rem; // 20px
  line-height: 1.4375rem; // 23px
  margin-left: 0;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const LeaveRequestButton = styled(Button)`
  width: 244px;
  height: 67px;
  border-radius: 14px;
  border: none;
  color: #ffffff;
  background-color: #00cbff;
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.25rem; // 20px
  line-height: 1.4375rem; // 23px
  margin-left: 0;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const TimeOutButton = styled(Button)`
  width: 183px;
  height: 67px;
  border-radius: 12px;
  border: none;
  background-color: ${(props) =>
    props.isdisabledcolor === "true" ? "#CCDEEA !important" : "#d63939"};
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 0;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: ${(props) =>
      props.isdisabledcolor === "true" ? "#CCDEEA !important" : "#d63939"};
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const ApplyButton = styled(Button)`
  width: 96px;
  height: 55px;
  border-radius: 12px;
  border: none;
  background-color: #02488d;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 16px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover,
  &:focus {
    background-color: #02488d;
  }
`;
