import React, { Component } from "react";
import styled from "styled-components";
import { Col, Row, Button, Typography, Popover, Space } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import DoctorTypeRequestIcon from "../../icons/DoctorTypeRequest.jsx";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import ToggleElement from "../_components/ToggleElement";
import { DeleteConfirmation, Success, Failed } from "../_components/Modal";
import "./css.css";
import NavigationButton from "../../ComponentsFC/ui/NavigationButton";

import ServiceDoctorsApi from "../../api_services/doctors";

const context = "admin";

export default class AdminDoctorAccounts extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Doctor Accounts",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      default_data: [],
      context: context,

      sort: "-createdAt",
      status: "",
      name: "",
      show_option: false,

      filterStatus: null,
      filterDoctorType: null,
      sortDate: null,
      sortName: null,
      visiblePopOverDoctorId: "",
      showPopOver: null,
      isVisible: false,
      currentWindowInnerWidthSize: null,
    });
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.elementRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  onCreate = async () => {
    setTimeout(() => {
      this.get_data({
        page: 1,
        limit: this.state["doctors_list_entries"] ?? 30,
      });
    }, 100);
  };

  filter_by = (state, value) => {
    this.setState(
      {
        [state]: value,
      },
      () => {
        this.filter_all();
      }
    );
  };

  filter_all = () => {
    const { status, sort } = this.state;
    let add = status ? { "provider.isDoctorsOnboardingApproved": status } : {};
    let hos = this.state["name"] ? { name: this.state["name"] } : {};
    this.get_data({
      ...hos,
      sort: sort,
      ...add,
    });
    this.setState({
      will_filter: true,
    });
    UI.set_input_value("search", "");
  };

  get_data = async (params) => {
    this.show_loading();

    if (params.doctorType === "advocacy_doctor") params.doctorType = "advocacy";

    ServiceDoctorsApi.getDoctors(params).then((res) => {
      if (res.status === 200) {
        let new_data = res.data;
        const results = {
          docCount: res.count,
          total: res.count,
          pages: res.pagination?.totalPages,
        };
        this.setState(
          {
            all_data: new_data,
            data: new_data,
          },
          () => {
            UI.get_entries(
              new_data,
              params.limit ?? 30,
              "doctors_list",
              params.page,
              {
                total: results.total,
                pages: results.pages,
              }
            );
            this.hide_loading();
          }
        );
      }
    });
  };

  get_data_search = async (params) => {
    this.show_loading();

    const searchCountCharacters = params.fullname.trim().length;
    try {
      if (this.shouldFetchData(params)) {
        const res = await ServiceDoctorsApi.getDoctors(params);

        if (res.status === 200) {
          let new_data = res.data;
          const results = {
            docCount: res.count,
            total: res.count,
            pages: res.pagination?.totalPages,
          };

          if (params.fullname && searchCountCharacters >= 3) {
            const dataResult = new_data.filter(
              (item) =>
                item.fullname
                  .toLowerCase()
                  .includes(params.fullname.trim().toLowerCase()) ||
                item.fullname.includes(params.fullname.trim())
            );
            const { page = 1, limit = 30 } = params;
            const startIndex = (page - 1) * limit;
            const endIndex = startIndex + limit;
            const paginatedData = dataResult.slice(startIndex, endIndex);

            this.setState(
              {
                all_data: dataResult,
                data: paginatedData,
              },
              () => {
                UI.get_entries(dataResult, limit, "doctors_list", page, {
                  total: results.total,
                  pages: results.pages,
                });
              }
            );
          } else if (searchCountCharacters === 0) {
            const { page = 1, limit = 30 } = params;
            const startIndex = (page - 1) * limit;
            const endIndex = startIndex + limit;
            const paginatedData = new_data.slice(startIndex, endIndex);
            this.setState(
              {
                all_data: new_data,
                data: paginatedData,
              },
              () => {
                UI.get_entries(new_data, limit, "doctors_list", page, {
                  total: results.total,
                  pages: results.pages,
                });
              }
            );
          }
        }
      } else {
        this.setState({ data: null });
        this.hide_loading();
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      this.hide_loading();
    }
  };

  // Add a function to check if the search parameters have changed
  shouldFetchData = (newParams) => {
    const { searchParams } = this.state; // Store the previous searchParams in the component state
    return JSON.stringify(newParams) !== JSON.stringify(searchParams);
  };

  update_mwell_plus = async (id, val) => {
    this.show_loading();
    UI.webRequest({
      method: "put",
      url: "admin/doctors/set_mwellplus/" + id,
      params: { isMwellPlus: val },
      onFail: (err) => {
        // console.log(err);
        const data = err.data;
        const error_message = data.m;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        if (this.state.search) {
          const res = this.state.all_data.map((item) => {
            if (data.doctorIsMwellPlus._id === id) {
              item.isMwellPlus = val;
            }
            return item;
          });

          this.setState({ all_data: res });
        }
      },
      onFinish: async () => {
        this.hide_loading();
        this.onCreate();
      },
    });
  };

  update_is_mindhealth = async (id, val) => {
    this.show_loading();
    UI.webRequest({
      method: "put",
      url: "admin/doctors/set_mindhealth/" + id,
      params: { isMindHealth: val },
      onFail: (err) => {
        // console.log(err);
        const data = err.data;
        const error_message = data.m;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        if (this.state.search) {
          const res = this.state.all_data.map((item) => {
            if (data.doctorIsMindHealth._id === id) {
              item.isCounselor = val;
            }
            return item;
          });

          this.setState({ all_data: res });
        }
      },
      onFinish: async () => {
        this.hide_loading();
        this.onCreate();
      },
    });
  };

  export_to_csv = async () => {
    const { all_data } = this.state;
    const csv_data = all_data.map((item, index) => {
      // const advocacySteps = Object.entries(item.provider?.isAdvocacyOnboardingApproved  ?? "")
      // advocacySteps.map(a=> console.log(a));
      // console.log(item.provider?.isAdvocacyOnboardingApproved);
      // const advocacies = advocacySteps.map(advocacy => item[0] === "step1")

      const d = item.provider && {
        ID: item.provider._id,
        Fullname: UI.DataHelper.carespan.get_name(item.name) ?? "N/A",
        // "Gender":UI.DataHelper.title(item.gender),
        // "Birth Date": UI.DataHelper.carespan.get_date(item.birthDate) ?? "N/A",
        // "Medical School":  item.medicalAssociations.map(item => item.name).join(";").replace(/,/g, ' ') ?? "N/A",
        // "Qualification": UI.DataHelper.carespan.get_date(
        //   item.qualificationDate
        // ) ?? "N/A",
        // "Specialty":item.fieldSpecialties.map(item => item).join(";") ?? "N/A",
        // "Languages": UI.DataHelper.carespan.get_languages(item.languange) ?? " ",
        // "Languages": item.languange.map(item => item).join(";") ?? " ",
        // "Hospital Affiliations": item.hospitals.map((item) => ( item.hospital.name )).join(";") ?? "N/A",
        // "Address":  UI.DataHelper.carespan.get_address(item.address) ?? "N/A",
        Email: item.provider?.email ?? "N/A",
        "Prc License Number": item.prcLicenseNumber ?? "N/A",
        "Status mWell Approval":
          item.provider?.isDoctorsOnboardingApproved ?? "N/A",
        "Date/Time Application Submission": UI.getCurrentTimeStampPHConsult(
          item?.createdAt
        ),
        "Date Approved": item.provider?.mwellApprovedDate ?? "--",
        "Joined Advocacy": item.advocacies?.map((advocacy) => advocacy.title),
        "Date/Time Approval Step 1": item.provider?.isAdvocacyOnboardingApproved
          ?.step1?.approvedDate
          ? UI.getCurrentTimeStampPHConsult(
              item.provider?.isAdvocacyOnboardingApproved[0]?.approvedDate
            )
          : "N/A",
        "Date/Time Approval Step 2": item.provider?.isAdvocacyOnboardingApproved
          ?.step2?.approvedDate
          ? UI.getCurrentTimeStampPHConsult(
              item.provider?.isAdvocacyOnboardingApproved[1]?.approvedDate
            )
          : "N/A",
        "Date/Time Approval Step 3": item.provider?.isAdvocacyOnboardingApproved
          ?.step3?.approvedDate
          ? UI.getCurrentTimeStampPHConsult(
              item.provider?.isAdvocacyOnboardingApproved[2]?.approvedDate
            )
          : "N/A",
        "Advocacy Status Approval Step 1":
          item.provider?.isAdvocacyOnboardingApproved?.step1?.status ?? "N/A",
        "Advocacy Status Approval Step 2":
          item.provider?.isAdvocacyOnboardingApproved?.step2?.status ?? "N/A",
        "Advocacy Status Approval Step 3":
          item.provider?.isAdvocacyOnboardingApproved?.step3?.status ?? "N/A",
        "Bank Name": item.financialInformation?.bankName ?? "N/A",
        "Bank Branch": item.financialInformation?.bankBranch ?? "N/A",
        "Bank Account Name":
          item.financialInformation?.bankAccountName ?? "N/A",
        "Bank Account Number":
          item.financialInformation?.bankAccountNumber ?? "N/A",
        "Maya Account Number":
          item.financialInformation?.mayaAccountNumber ?? "N/A",
        "Marketing Consent": item.hasOwnProperty("marketingConsentPath")
          ? item.marketingConsentPath
            ? "Yes"
            : "No"
          : "N/A",

        // [["Date/Time Approval", "", ""].map(item => item)]:
        //   advocacySteps.map(item => (
        //        item[0] === "step1" ? (
        //       "Step 1 Registration"
        //     ) : item[0] === "step2" ? (
        //       "Step 2 OCR"
        //     ) : item[0] === "step3" ? (
        //       "Step 3 Ready to Upload"
        //     ) : ""  - item[1].approvedDate ? (UI.getCurrentTimeStampPHConsult(item[1].approvedDate)) : ""
        //   )).join(),
        //   [["Status Advocacy Approval", "", ""].map(item => item)]:
        //   advocacySteps.map(item => {
        //     return  item[0] === "step1" ? (
        //       "Step 1 Registration"
        //     ) : item[0] === "step2" ? (
        //       "Step 2 OCR"
        //     ) : item[0] === "step3" ? (
        //       "Step 3 Ready to Upload"
        //     ) : ""  - item[1].status === "Approved" || item[1].status === "Approve" ? (
        //       "Approved"
        //     )  : item[1].status === "Pending" ?
        //     (
        //       "Pending"
        //     ) : item[1].status === "Disapproved" ?
        //     (
        //      "Disapproved"
        //     ) : "N/A"
        //   }).join(),
      };
      return d;
    });

    UI.DownloadCSV("Doctor Account " + UI.get_current_date_string(), csv_data);
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      alert("You clicked outside of me!");
    }
  }

  handleScroll = () => {
    // this.setState({ isVisible: true });
    // if (this.state.isVisible === true) {
    //   console.log("INNER SCROLL TRIGGERED");
    //   this.elementRef.current?.click();
    //   this.elementRef.current?.blur();
    // this.setState({ isVisible: false });
    // }
    // this.setState({ isVisible: false });
  };

  // handleClickAnywhere = () => {
  //   console.log("Click Anywhere Triggered");
  //   // this.setState({ isVisible: false });
  // };

  handleResize = () => {
    this.setState({ currentWindowInnerWidthSize: window.innerWidth });
  };

  componentWillMount() {
    // document.body.addEventListener("wheel", this.handleScroll);
    // document.body.addEventListener("keydown", this.handleScroll);
    // document.body.addEventListener("click", this.handleClickAnywhere);
    window.addEventListener("resize", this.handleResize);
  }

  render() {
    const _this = this;
    const { width } = _this.state;

    // const showOption = (id) => {
    //   document.querySelectorAll(".action_option").forEach(function (elem) {
    //     elem.classList.add("hidden");
    //   });
    //   let isOpen = document.getElementById(id).classList.contains("hidden");
    //   if (isOpen) {
    //     document.getElementById(id).classList.remove("hidden");
    //   } else {
    //     document.getElementById(id).classList.add("hidden");
    //   }
    // };

    // const hide_action = () => {
    //   document.querySelectorAll(".action_option").forEach(function (elem) {
    //     elem.classList.add("hidden");
    //   });
    // };
    return (
      <MainLayout context={context} _this={_this}>
        {UI.box(20)}

        <View style={styles.main_box_content}>
          <Text style={styles.box_header_text}>Doctor's Account</Text>
          <Row style={{ marginTop: "20px", alignItems: "end" }}>
            <Layout.SelectPicker
              _this={_this}
              title={"mWell Approval Status"}
              isRequired={false}
              onChange={(e) => {
                this.setState({ filterStatus: e });
                this.get_data({
                  status: e,
                  sort: this.state.sort,
                  doctorType: this.state.filterDoctorType,
                  page: 1,
                  limit: 30,
                });
              }}
            >
              <option value="">Select Option</option>
              <option value="Approved">Approved</option>
              <option value="Pending">Pending</option>
              <option value="Disapproved">Disapproved</option>
              {/* <option value="Deleted">Deleted</option> */}
            </Layout.SelectPicker>
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Registration Date"}
              isRequired={false}
              onChange={(e) => {
                let sortDate = e === "asc" ? "createdAt" : "-createdAt";
                this.setState({ sort: sortDate });
                this.get_data({
                  status: this.state.filterStatus,
                  sort: sortDate,
                  doctorType: this.state.filterDoctorType,
                  page: 1,
                  limit: 30,
                });
              }}
            >
              <option value="">Select Option</option>
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Doctor Name"}
              isRequired={false}
              onChange={(e) => {
                let sortName = e === "asc" ? "doctorName" : "-doctorName";
                this.setState({ sort: sortName });
                this.get_data({
                  status: this.state.filterStatus,
                  sort: e === "asc" ? "doctorName" : "-doctorName",
                  doctorType: this.state.filterDoctorType,
                  page: 1,
                  limit: 30,
                });
              }}
            >
              <option value="">Select Option</option>
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>
          </Row>
          <Row style={{ marginTop: "20px", alignItems: "end" }}>
            <Col span={8}>
              <Layout.SelectPicker
                style={{ width: "490px" }}
                _this={_this}
                title={"Doctor type"}
                isRequired={false}
                onChange={(e) => {
                  this.get_data({
                    status: this.state.filterStatus,
                    sort: this.state.sort,
                    doctorType: e,
                    fullname: this.state.sort.fullname,
                    page: 1,
                    limit: 30,
                  });
                }}
              >
                <option value="">Select Option</option>
                <option value="Contracted">Contracted</option>
                <option value="Consulting">Consulting</option>
                <option value="advocacy_doctor">Advocacy Doctor</option>
                {/* <option value="mwell_doctor">mWell Doctor</option> */}
              </Layout.SelectPicker>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              {/* <NavigationButton
                style={{
                  height: "50px",
                  width: "215px", // UI Design 264px, but needs to scales w/ existing
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
                onClick={() =>
                  UI.goTo("/admin/doctor-accounts/deactivated-doctors")
                }
              >
                Deactivated Accounts
              </NavigationButton> */}
              <NavigationButton
                style={{
                  height: "50px",
                  width: "160px", //UI Design 208px, but needs to scales w/ existing
                  marginLeft: "28px",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
                onClick={() =>
                  UI.goTo("/admin/doctor-accounts/deleted-doctors")
                }
              >
                Deleted Doctors
              </NavigationButton>
            </Col>
          </Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={880}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker
                  stateless={true}
                  callback={this.onCreate}
                  table_name={"doctors_list"}
                  _this={_this}
                />
              </View>
            </View>

            <InputForm
              onChange={(e) => {
                this.get_data_search({
                  sort: this.state.sort,
                  doctorType: this.state.filterDoctorType,
                  fullname: e,
                  page: 1,
                  limit: 30,
                });
              }}
              is_keyword={true}
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={""}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
            />

            <View
              style={{
                alignSelf: width > 950 ? "flex-start" : "center",
                marginTop: 8,
                marginLeft: 10,
                flexDirection: width > 950 ? "row" : "column",
              }}
            >
              <Layout.Btn
                color={UI.colors.secondary}
                textstyle={{
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
                text={"Export to CSV"}
                onClick={() => {
                  this.export_to_csv();
                }}
                height={50}
                width={150}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            disable_pagination={false}
            state_name={"doctors_list"}
            _this={_this}
            columns={[
              // { ID: "id" },
              { "Doctor Name": "doctor_name" },
              { Specialty: "specialty" },
              { "Doctor Type": "doctorType" },
              { "Last Consult": "date_of_last_consult" },
              { "mWell Approval": "status_mwell_approval" },
              // { Source: "source" },
              { "Application Submission": "date_time_application_submission" },
              { "Date/Time Approval": "date_time_approval" },
              { "Joined Advocacy": "joined_advocacy" },
              { "Advocacy Approval": "status_advocacy_approval" },
              // { "Number of Consults": "number_of_consults" },
              // { "Training Attended": "training_attended" },
              { "mWell Plus?": "isMwellPlus" },
              { "MH Coach?": "isMindHealth" },
              { Online: "online" },
              { Action: "action" },
            ]}
            popOverDoctorId={this.state.visiblePopOverDoctorId}
            data={this.state.data.map((item, index) => {
              const doctorId = item._id;
              const doctorTypeInTable = item.isMwellPlus;

              const requestDoctorTypeContracted =
                item?.requestDoctorType?.contracted?.isRequest;
              const requestDoctorTypeConsulting =
                item?.requestDoctorType?.consulting?.isRequest;
              const hasPendingDoctorTypeRequest =
                requestDoctorTypeContracted || requestDoctorTypeConsulting;

              const editDoctor = async () => {
                mem.save("edit_doctor", JSON.stringify(item));

                UI.goTo("/admin/view-doctor-record");
              };

              const deleteDoctor = async (id) => {
                this.show_modal(
                  <DeleteConfirmation
                    text={"Are you sure you want to delete this Doctor?"}
                    onCancel={() => {
                      document
                        .querySelectorAll(".action_option")
                        .forEach(function (elem) {
                          elem.classList.add("hidden");
                        });
                      this.hide_modal();
                    }}
                    onDelete={async () => {
                      this.hide_modal();
                      this.show_loading();
                      document
                        .querySelectorAll(".action_option")
                        .forEach(function (elem) {
                          elem.classList.add("hidden");
                        });
                      let params = {};

                      params.approved = "Deleted";

                      ServiceDoctorsApi.updateDoctorRegistrationStatus(
                        id,
                        params
                      ).then((res) => {
                        // setIsLoading(false);
                        if (res.status === 200) {
                          // let data = res.data;
                          // const status = this.state.status === "false" ? "disapproved" : "approved";
                          this.hide_loading();
                          this.show_modal(
                            <Success
                              title={"Success!"}
                              descriptionCenter={
                                "Doctor is successfully Deleted."
                              }
                              onDismiss={() => {
                                this.hide_modal();
                                this.get_data({
                                  page: 1,
                                  limit:
                                    this.state["doctors_list_entries"] ?? 30,
                                });
                              }}
                            />
                          );
                        }
                      });
                    }}
                  />
                );
              };

              let isOnline = false;
              if (item.encounter) {
                isOnline = item.encounter.communication.status;
              }

              let status = false;
              if (item.provider) {
                status = item.provider.isDoctorsOnboardingApproved;

                if (!item.provider?.isDoctorsOnboardingApproved) {
                  status = "Pending";
                }
              }

              let isMPHHI = false;

              if (Array.isArray(item.hospital)) {
                if (item.hospital.length > 0) {
                  isMPHHI = true;
                }
              } else if (typeof item.hospital == "object") {
                if (item.hospital) {
                  if (item["name"]) {
                    isMPHHI = true;
                  }
                }
              }
              const advocacySteps = Object.entries(
                item.provider?.isAdvocacyOnboardingApproved ?? ""
              );
              let lastDateConsult = "N/A";
              if (item?.lastConsult) {
                lastDateConsult = UI.getTimezone({
                  date: item.lastConsult,
                  format: "YYYY-MM-DD HH:mm:ss a",
                });
              }

              return {
                id: item._id,
                isMPHHI: isMPHHI ? "Yes" : "No",
                doctor_name: (
                  <View
                    key={doctorId}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginLeft: 10,
                    }}
                  >
                    <Text
                      style={{
                        maxWidth: 80,
                        marginRight: 10,
                        textAlign: "center",
                      }}
                    >
                      {UI.DataHelper.carespan.get_name(item.name)}
                    </Text>
                  </View>
                ),
                specialty: (
                  <View key={doctorId} style={{ marginLeft: 10 }}>
                    <Text style={{ maxWidth: 90, textAlign: "center" }}>
                      {UI.DataHelper.carespan.get_specialties(
                        item.fieldSpecialties
                      )}
                    </Text>
                  </View>
                ),
                doctorType: (
                  <>
                    <TableData
                      typecolor={
                        doctorTypeInTable === true ? "Contracted" : "Consulting"
                      }
                    >
                      {doctorTypeInTable === true ? "Contracted" : "Consulting"}
                    </TableData>
                  </>
                ),
                date_of_last_consult: lastDateConsult,
                status_mwell_approval:
                  // !item.provider?.hasOwnProperty('isAdvocacyOnboardingApproved')
                  //   || item.provider?.isDoctorsOnboardingApproved === "Approved"
                  //   // || item.provider?.isDoctorsOnboardingApproved === "Pending"
                  //   || item.provider?.isDoctorsOnboardingApproved === "Disapproved"
                  //   || item.provider?.isAdvocacyOnboardingApproved.step3?.status === "Approved"
                  //   || item.provider?.isAdvocacyOnboardingApproved.step3?.status === "Approve"  ?
                  status === "Approved" ? (
                    <Text style={{ color: "#27ae60" }}>Approved</Text>
                  ) : status === "Disapproved" ? (
                    <Text style={{ color: "#e74c3c" }}>Disapproved</Text>
                  ) : status === "Pending" ? (
                    <Text style={{ color: "#FFCE00" }}>Pending</Text>
                  ) : status === "Deleted" ? (
                    <Text style={{ color: "#e74c3c" }}>Deleted</Text>
                  ) : (
                    <Text style={{ color: "#FFCE00" }}></Text>
                  ),
                // : ""
                // source:'N/A',
                date_time_application_submission:
                  UI.getCurrentTimeStampPHConsult(item?.createdAt),
                date_time_approval: advocacySteps.map((item, index) => (
                  <div key={index}>
                    {item[0] === "step1" ? (
                      <Text>Step 1 Registration</Text>
                    ) : item[0] === "step2" ? (
                      <Text>Step 2 OCR</Text>
                    ) : (
                      <Text>Step 3 Ready to Upload</Text>
                    )}{" "}
                    -{" "}
                    <Text>
                      {item[1].approvedDate
                        ? UI.getCurrentTimeStampPHConsult(item[1].approvedDate)
                        : ""}
                    </Text>
                  </div>
                )),
                joined_advocacy: (
                  <View>
                    {item.advocacies?.map((advocacy, index) => (
                      <Text key={index} style={{ textAlign: "center" }}>
                        {advocacy.title}
                      </Text>
                    ))}
                  </View>
                ),
                status_advocacy_approval: advocacySteps.map((item, index) => {
                  return (
                    <div key={index}>
                      {item[0] === "step1" ? (
                        <Text>Step 1 Registration</Text>
                      ) : item[0] === "step2" ? (
                        <Text>Step 2 OCR</Text>
                      ) : (
                        <Text>Step 3 Ready to Upload</Text>
                      )}{" "}
                      -{" "}
                      {item[1].status === "Approved" ||
                      item[1].status === "Approve" ? (
                        <Text style={{ color: "#27ae60" }}>Approved</Text>
                      ) : item[1].status === "Pending" ? (
                        <Text style={{ color: "#FFCE00" }}>Pending</Text>
                      ) : item[1].status === "Disapproved" ? (
                        <Text style={{ color: "red" }}>Disapproved</Text>
                      ) : (
                        "N/A"
                      )}
                      {/* : item[1].status === "Disapproved" ? (
                    <Text style={{ color: "#e74c3c" }}>Disapproved</Text>
                  ) */}
                    </div>
                  );
                }),
                // number_of_consults:  'N/A' ,
                // training_attended:  'N/A' ,
                isMwellPlus: (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    {/* {item.isMwellPlus ? "Yes" : "No"} */}
                    {UI.box(10)}
                    <ToggleElement
                      id={item._id}
                      onChange={(val) => {
                        this.update_mwell_plus(item._id, val);
                      }}
                      defaultValue={item.isMwellPlus ? true : false}
                    />
                  </View>
                ),
                isMindHealth: (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    {/* {item.isMwellPlus ? "Yes" : "No"} */}
                    {UI.box(10)}
                    <ToggleElement
                      id={item._id}
                      onChange={(val) => {
                        this.update_is_mindhealth(item._id, val);
                      }}
                      defaultValue={item.isCounselor ? true : false}
                    />
                  </View>
                ),
                online: (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    {status === "Approved" && isOnline === "Online" ? (
                      <Text style={{ color: "#27ae60" }}>Yes</Text>
                    ) : status === "Approved" && isOnline === "Offline" ? (
                      <Text style={{ color: "#d1d1d1" }}>No</Text>
                    ) : (
                      "No"
                    )}
                  </View>
                ),
                action: (
                  <StyledPopOver
                    highlightRow={hasPendingDoctorTypeRequest}
                    key={doctorId}
                    placement={
                      this.state.currentWindowInnerWidthSize <= 1571
                        ? "topLeft"
                        : "topRight"
                    }
                    overlayStyle={{
                      width: "220px",
                    }}
                    content={
                      <Space direction="vertical">
                        <ActionRow onClick={() => editDoctor()}>
                          <Image
                            style={{ marginRight: 10 }}
                            height={28}
                            width={28}
                            tintColor={"transparent"}
                            source={UI.EDIT_BTN}
                          />
                          <ActionLabel>{"Update Doctor's Account"}</ActionLabel>
                        </ActionRow>
                        {status !== "Deleted" && (
                          <ActionRow
                            onClick={() => deleteDoctor(item.provider._id)}
                          >
                            <Image
                              style={{ marginRight: 10 }}
                              height={28}
                              width={28}
                              tintColor={"transparent"}
                              source={UI.DELETE_BTN}
                            />
                            <ActionLabel>
                              {"Delete Doctor's Account"}
                            </ActionLabel>
                          </ActionRow>
                        )}
                      </Space>
                    }
                    trigger={"hover"}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  >
                    <ActionButton
                      ref={this.elementRef}
                      onClick={() => {
                        // console.log(
                        //   "isVisible onClick: ",
                        //   this.state.isVisible
                        // );
                        // if (this.state.isVisible === true) {
                        // if (this.state.isVisible === true) return;
                        // this.setState({ isVisible: true });
                        // }
                      }}
                      type="link"
                      style={{
                        paddingLeft: `${
                          hasPendingDoctorTypeRequest ? 0 : "22.7px"
                        }`,
                      }}
                    >
                      {hasPendingDoctorTypeRequest && (
                        <DoctorTypeRequestIcon
                          style={{ marginRight: "3.7px" }}
                        />
                      )}
                      <ThreeDotsTypo>...</ThreeDotsTypo>
                    </ActionButton>
                  </StyledPopOver>
                  // <View style={{ flexDirection: "row", position: "relative" }}>
                  //   <button
                  //     onClick={(e) => showOption(`data_${index}`)}
                  //     style={{ fontWeight: 800, fontSize: 30 }}
                  //   >
                  //     ...
                  //   </button>
                  //   <Row
                  //     id={`data_${index}`}
                  //     onMouseLeave={hide_action}
                  //     ref={this.setWrapperRef}
                  //     className="hidden action_option"
                  //     style={{
                  //       ...styles.show_option,
                  //       height: status === "Deleted" ? "75px" : "105px",
                  //     }}
                  //   >
                  //     <TouchableOpacity
                  //       onClick={editDoctor}
                  //       style={{ ...styles.detail_box }}
                  //     >
                  //       <Image
                  //         style={{ marginRight: 10 }}
                  //         height={28}
                  //         width={28}
                  //         tintColor={"transparent"}
                  //         source={UI.EDIT_BTN}
                  //       />
                  //       <span style={{ marginLeft: 10 }}>
                  //         Update Doctors Account
                  //       </span>
                  //     </TouchableOpacity>
                  //     {UI.box(10)}
                  //     {status !== "Deleted" && UI.box(5) && (
                  //       <TouchableOpacity
                  //         onClick={() => deleteDoctor(item.provider._id)}
                  //         style={{ ...styles.detail_box }}
                  //       >
                  //         <Image
                  //           style={{ marginRight: 10 }}
                  //           height={28}
                  //           width={28}
                  //           tintColor={"transparent"}
                  //           source={UI.DELETE_BTN}
                  //         />
                  //         <span style={{ marginLeft: 10 }}>
                  //           Delete Doctors Account
                  //         </span>
                  //       </TouchableOpacity>
                  //     )}
                  //   </Row>
                  // </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              this.get_data({
                status: this.state.filterStatus,
                sort: this.state.sort,
                doctorType: this.state.filterDoctorType,
                fullname: this.state.fullname,
                page: page,
                limit: parseInt(this.state.doctors_list_entries),
              });
              // UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const TableData = styled(Typography.Paragraph)`
  font-family: Poppins;
  font-weight: 500;
  color: ${(props) => (props.color ? "black" : "black")};
  font-size: 12px;
  margin-bottom: 0 !important;
`;

const ActionRow = styled(Row)`
  align-items: center;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
  }
`;

const ActionLabel = styled(Typography.Paragraph)`
  font-family: Poppins;
  font-weight: 400;
  color: ${(props) => (props.color ? "black" : "black")};
  font-size: 12px;
  margin-bottom: 0 !important;
  margin-left: 10px;
`;

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
`;

const ThreeDotsTypo = styled(Typography)`
  font-family: Poppins;
  font-size: 30px;
  line-height: 2px;
  padding-bottom: 18px;
`;

const StyledPopOver = styled(Popover)`
  /* div {
    left: -160.859px;
    top: -92.109px;
  } */
`;
const styles = StyleSheet.create({
  show_options: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    ...Shadow._3(),
    backgroundColor: "white",
    right: 50,
    margin: "auto",
    width: "max-content",
    padding: 10,
  },
  aligned: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },

  show_option: {
    position: "absolute",
    right: 0,
    width: 250,
    top: 0,
    bottom: 0,
    margin: "auto",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    borderRadius: 12,
    padding: 20,
    ...Shadow._3(),
  },

  detail_box: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
