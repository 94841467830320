import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import UI from "../../../shared/Components/UI/js";
import styled from "styled-components";
import { Row, Form, Col, Upload, Button, Image } from "antd";

import Container from "../../ui/Container";
import ContainerLabelPrimary from "../../ui/ContainerLabelPrimary";
import FieldLabel from "../../ui/forms/FieldLabel";
import FormItem from "../../ui/forms/FormItem";
import InputField from "../../ui/forms/InputField";
import SelectField from "../../ui/forms/SelectField";
import SelectFieldMultiple from "../../ui/forms/SelectFieldMultiple";
import DatePickerFC from "../../ui/forms/DatePicker";
import SubmitButton from "../../ui/forms/SubmitButton";
import SuccessModal from "../../ui/modals/SuccessModal";
import SuccessModalCheckIcon from "../../../icons/SuccessModalCheck";
import ProcessFailedModal from "../../ui/modals/ProcessFailedModal";
import ProcessFailedModalCrossIcon from "../../../icons/ProcessFailedModalCross";
import ImgInModal from "../../ui/modals/ImgInModal";

import ServiceMarketingsApi from "../../../api_services/marketings";
import Loading from "../../../modules/_components/Loading";
import moment from "moment";

const bannerStatusOptions = [
  { type: "Active", value: "ACTIVE" },
  { type: "Inactive", value: "INACTIVE" },
];
const PagesSelectOptions = [
  { type: "Homepage", value: "homepage" },
  { type: "Appointments", value: "appointments" },
  { type: "Available Doctors", value: "availableDoctors" },
  { type: "Consultation Complete", value: "consultationComplete" },
];

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const MainBannersEditForm = ({ _this }) => {
  const location = useLocation();
  const history = useHistory();

  const [startBannerDate, setStartBannerDate] = useState();
  const [endBannerDate, setEndBannerDate] = useState();

  const [isScreenLoading, setIsScreenLoading] = useState();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const [processFailedBody, setProcessFailedBody] = useState(<></>);
  const [processFailedModalWidth, setProcessFailedModalWidth] = useState();

  const [bannerData, setBannerData] = useState();
  const [initialFormValues, setInitialFormValues] = useState();

  const [bannerFile, setBannerFile] = useState();
  const [visibleBannerImg, setVisibleBannerImg] = useState(false);

  const [bannerFileName, setBannerFileName] = useState();
  const [isNoBannerImgError, setIsNoBannerImgError] = useState(false);

  const [form] = Form.useForm();

  const data = location.state;

  useEffect(() => {
    console.log("ROW DATA", data);
    if (data) {
      setBannerData(data);
    }
  }, [data]);

  const getRowBannerFileFromFileUrl = useCallback(
    async (url) => {
      let response = await fetch(bannerData?.data.attachment);
      let data = await response.blob();

      setBannerFile(
        new File([data], bannerData?.data.imageFileName, {
          lastModified: new Date().getTime(),
          type: data.type,
        })
      );
    },
    [bannerData?.data.attachment, bannerData?.data.imageFileName]
  );

  useEffect(() => {
    if (bannerData) {
      getRowBannerFileFromFileUrl();
    }
  }, [getRowBannerFileFromFileUrl, bannerData]);

  useEffect(() => {
    // console.log("TRIGGER RESET FIELDS ");
    form.resetFields();
  }, [initialFormValues, form]); //to remount form component
  useEffect(() => {
    //FieldName: Value
    if (bannerFile) {
      console.log("Banner File: ", bannerFile);
      getBase64(bannerFile, (imageUrl) => setImageUrl(imageUrl));
    }
    let removeHTTPSInInitialValue = bannerData?.data?.deepLink;
    if (
      bannerData?.data?.deepLink &&
      bannerData?.data?.deepLink?.substring(0, 8).includes("https://")
    ) {
      removeHTTPSInInitialValue = `${bannerData?.data?.deepLink
        ?.substring(8, bannerData?.data?.deepLink?.length)
        .trim()}`;
    }
    if (bannerData) {
      setInitialFormValues({
        attachment: [
          {
            uid: "1",
            originFileObj: bannerFile,
          },
        ],
        bannerName: bannerData?.data?.bannerName
          ? `${bannerData?.data?.bannerName}`
          : undefined,
        bannerStatus: bannerData?.data?.status
          ? `${bannerData?.data?.status}`
          : undefined,
        deepLink: bannerData?.data?.deepLink
          ? `${removeHTTPSInInitialValue}`
          : undefined,
        startDateAndTime: bannerData?.data.startDateAndTime
          ? moment(bannerData?.data?.startDateAndTime).utc()
          : undefined,
        endDateAndTime: bannerData?.data.endDateAndTime
          ? moment(bannerData?.data?.endDateAndTime).utc()
          : undefined,
        pagesActive:
          bannerData?.data?.pages?.length > 0
            ? bannerData?.data?.pages
            : undefined, //BE returns [] as default so we make this instead
      });
    }
  }, [bannerData, bannerFile]);

  // const getDefaultBannerFileFromFilePath = async (url) => {
  //   let response = await fetch(url);
  //   let data = await response.blob();

  //   return new File([data], "defaultBanner.png", {
  //     lastModified: new Date().getTime(),
  //     type: data.type,
  //   });
  // };

  useEffect(() => {
    //CLEARS endDateAndTime FIELD (if startDateAndTime is greater)
    if (
      (startBannerDate &&
        endBannerDate &&
        moment(startBannerDate).isAfter(endBannerDate, "minute")) ||
      (startBannerDate &&
        initialFormValues?.endDateAndTime &&
        moment(startBannerDate).isAfter(
          initialFormValues?.endDateAndTime,
          "minute"
        ))
    ) {
      console.log("TRIGGERED Start > end");
      form.resetFields(["endDateAndTime"]);
      setEndBannerDate(undefined);
      setInitialFormValues((prevState) => ({
        ...prevState,
        endDateAndTime: undefined,
      }));
    }
  }, [startBannerDate, endBannerDate, form, initialFormValues?.endDateAndTime]);

  const handleChangeStartDate = (date, dateString) => {
    // console.log("startDate", date.format("MM/DD/YYYY hh:mm a"));
    setStartBannerDate(date);
  };
  const handleDisabledStartDates = (current) => {
    // Disable dates before today
    return current < moment().startOf("day");
  };

  const handleChangeEndDate = (date, dateString) => {
    // console.log("startDate",date);
    setEndBannerDate(date);
  };
  const handleDisabledEndDates = (current) => {
    // Disable dates before today
    return current < moment().startOf("day");
  };

  const handleOnSubmitBanner = async (fieldValues) => {
    // console.log("SUBMIT LEAVE REQUEST: ", uploadFilesApiResponse?.d)
    console.log("HANDLE ON SUBMIT MAIN BANNER (fieldValues): ", fieldValues);

    // Engages default UI Banner to allow formData Img File alternative
    // const defaultBannerFile = await getDefaultBannerFileFromFilePath(
    //   UI.DEFAULT_MARKETING_BANNER,
    //   "image/png"
    // );

    try {
      const {
        bannerName,
        deepLink,
        bannerStatus,
        startDateAndTime,
        endDateAndTime,
        pagesActive,
      } = fieldValues;
      // // let urlParams = `?name=${requestor.trim()}`;
      const formData = new FormData();

      let checkedDeepLink = `https://${deepLink?.trim()}`;
      if (deepLink && deepLink?.substring(0, 8).includes("https://")) {
        checkedDeepLink = `${deepLink?.trim()}`;
      } else if (deepLink && deepLink?.substring(0, 7).includes("http://")) {
        checkedDeepLink = `https://${deepLink
          ?.substring(7, deepLink?.length)
          .trim()}`;
      }

      let payload = {
        bannerName: bannerName?.trim(),
        deepLink: deepLink ? checkedDeepLink : null,
        status: bannerStatus,
        startDateAndTime: startDateAndTime?.format(
          `YYYY-MM-DD[T]${startDateAndTime?.format("HH:mm:ss.SSS")}[Z]`
        ),
        endDateAndTime: endDateAndTime?.format(
          `YYYY-MM-DD[T]${endDateAndTime?.format("HH:mm:ss.SSS")}[Z]`
        ),
        bannerType: "MAIN_BANNER",
        pages: pagesActive,
      };

      if (fieldValues?.attachment) {
        formData.append(
          "image",
          fieldValues?.attachment[0]?.originFileObj
          // : defaultBannerFile
        );
      }
      formData.append("payload", JSON.stringify(payload));

      const config = {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      };

      setIsScreenLoading(true);
      const apiResponse = await ServiceMarketingsApi.updateBanner(
        bannerData?.data?._id,
        formData,
        config
      );

      console.log("PUT API Response (MAIN BANNER): ", apiResponse);
      setIsScreenLoading(false);

      if (apiResponse?.status === 200 || apiResponse?.status === 201) {
        setIsSuccessModalOpen(true);
      } else if (apiResponse?.status === 400) {
        setIsFailedModalOpen(true);
        setIsNoBannerImgError(true);
        setProcessFailedBody(
          <>
            {"Submit Failed. No image banner attached. Please try again. (400)"}
          </>
        );
        setProcessFailedModalWidth(530);
      } else if (apiResponse?.status === 403) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>{"Submit Failed. Forbidden Request. Please try again. (403)"}</>
        );
        setProcessFailedModalWidth(530);
      } else if (apiResponse?.status === 404) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {"Submit Failed. API resource not found. Please try again. (404)"}
          </>
        );
        setProcessFailedModalWidth(530);
      } else if (apiResponse?.status === 500) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>{"Something went wrong!. Server Error. Please try again. (500)"}</>
        );
        setProcessFailedModalWidth(530);
      } else if (
        apiResponse?.status > 503 ||
        apiResponse === undefined ||
        apiResponse?.status === undefined
      ) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {
              "Service Unavailable/Forbidden Request. Please try again later. (503/403)"
            }
          </>
        );
        setProcessFailedModalWidth(530);
      } else {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>{`${apiResponse?.status}. ${apiResponse?.message}`}</>
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [fileList, setFileList] = useState([
    {
      uid: "1",
      name: bannerData?.data.imageFileName,
      status: "done",
      url: bannerData?.data.attachment,
    },
  ]);
  const [imageUrl, setImageUrl] = useState();

  const onChange = ({ fileList: newFileList }) => {
    console.log("onChange FileList", newFileList);
    setFileList(newFileList);
    setBannerFileName(newFileList[0]?.name);
    if (newFileList[0]?.originFileObj) {
      getBase64(newFileList[0]?.originFileObj, (imageUrl) =>
        setImageUrl(imageUrl)
      );
    }
  };

  const onPreview = async (file) => {
    console.log("preview", file);
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleCloseModal = () => {
    setIsSuccessModalOpen(false);
    setIsFailedModalOpen(false);
    if (isNoBannerImgError) {
      setIsNoBannerImgError(false);
      return;
    }
    history.push("/marketing/main-banners");
  };

  return (
    <>
      <Loading
        visible={isScreenLoading}
        title={""}
        text={`Adding Main Banner...`}
        modal_opaque={false}
      />
      <SuccessModal
        style={{ marginTop: 130 }}
        title={"Successfully Added"}
        banner={<SuccessModalCheckIcon />}
        description={"Banner successfully Updated!"}
        buttonText={"OK"}
        buttoncolor={"#004f99"}
        visible={isSuccessModalOpen}
        width={530}
        onOk={() => {
          history.push("/marketing/main-banners");
          setIsSuccessModalOpen(false);
        }}
        onCancel={handleCloseModal} // x and cancel button
      />
      <ProcessFailedModal
        title={"PROCESS FAILED"}
        banner={<ProcessFailedModalCrossIcon />}
        description={processFailedBody}
        buttonText={"Dismiss"}
        buttoncolor={"#004f99"}
        visible={isFailedModalOpen}
        width={processFailedModalWidth}
        onOk={handleCloseModal}
        onCancel={handleCloseModal} // x and cancel button
      />
      <ImgInModal
        style={{ marginTop: 130 }}
        banner={
          imageUrl ? (
            <Image src={imageUrl} alt="uploaded-banner" preview={false} />
          ) : (
            <Image
              src={UI.DEFAULT_MARKETING_BANNER}
              alt="default-placeholder"
              preview={false}
            />
          )
        }
        visible={visibleBannerImg}
        onOk={() => {
          setVisibleBannerImg(false);
        }}
        onCancel={() => {
          setVisibleBannerImg(false);
        }} // x and cancel button
      />
      <Container>
        <ContainerLabelPrimary>Edit Banner</ContainerLabelPrimary>
        <Form
          form={form}
          layout={"vertical"}
          onFinish={handleOnSubmitBanner}
          initialValues={initialFormValues}
        >
          <StyledRow gutter={30}>
            <Col span={24}>
              <Row>
                {imageUrl ? (
                  <Image
                    src={imageUrl}
                    height={144}
                    width={311}
                    alt="uploaded-banner"
                    preview={false}
                    style={{ position: "absolute", objectFit: "cover" }}
                  />
                ) : (
                  <Image
                    src={UI.DEFAULT_MARKETING_BANNER}
                    height={144}
                    width={311}
                    alt="default-placeholder"
                    preview={false}
                  />
                )}
                <Col
                  style={{
                    marginLeft: 30,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <UploadDescription>
                    {`${
                      bannerFileName ? "Banner Uploaded" : "No Banner Uploaded"
                    }. Recommended Size: 1870 px x 858 px, svg,
                    jpg or png file`}
                  </UploadDescription>
                  <ActionGroup>
                    <FormItem
                      style={{ marginBottom: 0 }}
                      name="attachment"
                      valuePropName="fileList" // To avoid antd Upload.js error Warning: [ant upload] 'value' is not a valid prop, do you mean fileList?
                      getValueFromEvent={(e) => e?.fileList} // To avoid Uncaught TypeError: (fileList || []).forEach is not a fn
                    >
                      <StyledUpload
                        accept=".svg,.jpeg,.png"
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                        maxCount={1}
                        name="attachment"
                        beforeUpload={() => {
                          return false;
                        }}
                      >
                        {"Upload image"}
                      </StyledUpload>
                    </FormItem>
                    <ViewImgButton
                      type="link"
                      onClick={() => setVisibleBannerImg(true)}
                    >
                      View Image
                    </ViewImgButton>
                  </ActionGroup>
                </Col>
              </Row>
            </Col>
          </StyledRow>

          <StyledRow gutter={30}>
            <Col span={8}>
              <FormItem
                name="bannerName"
                label={<FieldLabel>Banner Name</FieldLabel>}
              >
                <InputField placeholder="Banner Name" />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name="deepLink"
                label={<FieldLabel>Deep Link</FieldLabel>}
              >
                <InputField placeholder="Deep Link" />
              </FormItem>
            </Col>

            <Col span={8}>
              <FormItem
                name="bannerStatus"
                label={<FieldLabel>Banner Status</FieldLabel>}
              >
                <SelectField
                  placeholder="Select Status"
                  allowClear
                  selectoptions={bannerStatusOptions}
                  onChange={(e) => console.log("BANNER STATUS (MAINBANNER)", e)}
                />
              </FormItem>
            </Col>
          </StyledRow>

          <StyledRow gutter={30}>
            <Col span={8}>
              <FormItem
                name="startDateAndTime"
                label={<FieldLabel>Start Date and Time</FieldLabel>}
              >
                <DatePickerFC
                  showTime={{ format: "hh:mm a" }}
                  placeholder={"mm/dd/yyyy"}
                  format={"MM/DD/YYYY h:mm a"}
                  disabledDate={handleDisabledStartDates}
                  onChange={handleChangeStartDate} // use to add disabled dates
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name="endDateAndTime"
                label={<FieldLabel>End Date and Time</FieldLabel>}
              >
                <DatePickerFC
                  showTime={{ format: "hh:mm a" }}
                  placeholder={"mm/dd/yyyy"}
                  format={"MM/DD/YYYY h:mm a"}
                  disabledDate={handleDisabledEndDates}
                  onChange={handleChangeEndDate}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                name="pagesActive"
                label={<FieldLabel>Apply to Pages</FieldLabel>}
              >
                <SelectFieldMultiple
                  placeholder="Select Pages"
                  allowClear
                  selectoptions={PagesSelectOptions}
                  onChange={(e) => console.log(e)}
                />
              </FormItem>
            </Col>
          </StyledRow>

          <FormItem>
            <StyledSubmit>UPDATE BANNER</StyledSubmit>
            <CancelButton
              onClick={() => history.push("/marketing/main-banners")}
            >
              Cancel
            </CancelButton>
          </FormItem>
        </Form>
      </Container>
    </>
  );
};

export default MainBannersEditForm;

// CSS Styled Components ----------------------
const StyledSubmit = styled(SubmitButton)`
  width: 212px;
  height: 70px;
  text-align: center;
  font-size: 20px;
`;
const CancelButton = styled(Button)`
  color: #ffffff;
  border-color: #e00000;
  background-color: #e00000;
  width: 140px;
  height: 70px;
  margin-left: 20px;
  font-size: 20px;
  border-radius: 12px;
  text-transform: uppercase;
  font-family: Poppins;
  line-height: 1.25rem; //20px
  letter-spacing: 0px;
  text-align: center;
  &:hover {
    border-color: #ff2c2c;
    background: #ff2c2c;
    color: white;
  }
  &:focus {
    border-color: #e00000;
    background-color: #e00000;
    color: white;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 30px;
`;

const UploadDescription = styled.p`
  font-family: Poppins;
  font-size: 20px;
  letter-spacing: 0px;
  color: #afafaf;
  margin-top: 27px;
  margin-bottom: 15px;
`;

const StyledUpload = styled(Upload)`
  .ant-upload-list-picture-card {
    display: flex;
    align-items: center;
  }
  .ant-upload-list-picture-card-container {
    width: 311px;
    height: 144px;
    margin-bottom: 0; // avoid creation of extra margin if picture card is shown
    animation: none !important;
    display: none; // Don't display default antd picture card
  }

  .ant-upload-list-item {
    padding: 0;
  }
  .ant-upload.ant-upload-select-picture-card {
    border: none;
  }
  .ant-upload.ant-upload-select {
    color: white;
    width: 209px;
    height: 100%;
    margin: 0;
    position: relative;
  }
  .ant-upload-select::before {
    /* content: "Banner Uploaded. Recommended Size: 1870 px x 858 px, svg, jpg or png file"; */
    position: relative;
    color: #afafaf;
    width: max-width;
    height: 30px;
    font-size: 20px;
    top: -25px;
    display: block;
    text-align: left;
    left: 0;
    line-height: 1.15;
  }
  .ant-upload-select span.ant-upload {
    color: white !important;
    width: 209px;
    height: 70px !important;
    background: #03519a;
    border-radius: 12px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 20px;
  }
`;

const ViewImgButton = styled(Button)`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  color: #03519a;
  align-items: center;
  display: flex;

  &:active,
  &:focus {
    color: #03519a;
  }
  &:hover {
    color: #007df1;
  }
`;

const ActionGroup = styled(Row)`
  align-items: center;
`;
