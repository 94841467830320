import UI from "../shared/Components/UI/js";
import ServiceApi from "./service";

class MarketingsApi {

    baseURL = 'admin';

    async getCampaignReferrals (params) {
        const response = await ServiceApi.get(this.baseURL+'/campaign-referral', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getCampaignReferralById (id, params) {
        const response = await ServiceApi.get(`${this.baseURL}/campaign-referral/${id}`, params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getCampaignReferralMembers (id, params) {
        const response = await ServiceApi.get(`${this.baseURL}/campaign-referral/${id}`, params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getCampaignReferralsStats (params) {
        const response = await ServiceApi.get(this.baseURL+'/campaign-referral/stats', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async singleCampaignReferralStats (id) {
        const response = await ServiceApi.get(`${this.baseURL}/campaign-referral/stats/`);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }
    
    async createCampaignReferral (payload) {
        const response = await ServiceApi.post(this.baseURL+'/campaign-referral', payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async updatedCampaignReferral (id, payload) {
        const response = await ServiceApi.put(`${this.baseURL}/campaign-referral/${id}`, payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getUserReferrals (params) {
        const response = await ServiceApi.get(this.baseURL+'/user-referral/patient-referrals', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getDoctorReferrals (params) {
        const response = await ServiceApi.get(this.baseURL+'/user-referral/doctors-referrals', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getDoctorReferralsStats (params) {
        const response = await ServiceApi.get(this.baseURL+'/user-referral/doctors-referrals-stats', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getDoctorReferralOfReferrers (id, params) {
        const response = await ServiceApi.get(`${this.baseURL}/user-referral/doctors-referrals/${id}`, params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getRegisteredReferrals (params) {
        const response = await ServiceApi.get(this.baseURL+'/user-referral/registered-referrals', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getRegisteredReferralStats (params) {
        const response = await ServiceApi.get(this.baseURL+'/user-referral/registered-referrals/stats/', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getUserReferralOfReferrers (id, params) {
        const response = await ServiceApi.get(`${this.baseURL}/user-referral/patient-referrals/${id}`, params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getUserReferralStats (params) {
        const response = await ServiceApi.get(this.baseURL+'/user-referral/patient-referral/stats', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }   
    
    async getUserReferralStatsUnderSpecificUser (id, params) {
        const response = await ServiceApi.get(`${this.baseURL}/user-referral/stats/${id}`, params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

     // TO-DO: get API for promo winners
    async getAllPromoWinners (params) {
        const response = await ServiceApi.get(`${this.baseURL}/raffle/prize/winners/`, params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response?.status,
                data: response?.data?.d,
                message: response?.data?.m
            }
        }
    }

    async addBanner (payload, headers) {
        const response = await ServiceApi.post(this.baseURL+'/home/sections/cms-banner', payload, headers);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response?.status,
                data: response?.data?.d,
                message: response?.data?.m
            }
        }
    }

    
    async updateBanner (id, payload, headers) {
        const response = await ServiceApi.put(this.baseURL+`/home/sections/cms-banner/${id}`, payload, headers);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response?.status,
                data: response?.data?.d,
                message: response?.data?.m
            }
        }
    }

    async getMainBanners (params) {
        const response = await ServiceApi.get(this.baseURL+'/home/sections/cms-banner/MAIN_BANNER', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response?.data?.d,
                message: response?.data?.m
            }
        }
    }
    async getMindHealth (params) {
        const response = await ServiceApi.get(this.baseURL+'/home/sections/cms-banner/MIND_HEALTH', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            console.log("data",data)
            return data;
        } else {
            return {
                status: response?.status,
                data: response?.data?.d,
                message: response?.data?.m
            }
        }
    }
    async getRegistrationPopUp (params) {
        const response = await ServiceApi.get(this.baseURL+'/home/sections/cms-banner/POPUP', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            // console.log("data",data)
            return data;
        } else {
            return {
                status: response?.status,
                data: response?.data?.d,
                message: response?.data?.m
            }
        }
    }
    async getMwellnessScore (params) {
        const response = await ServiceApi.get(this.baseURL+'/home/sections/cms-banner/MWELLNESS_SCORE', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response?.status,
                data: response?.data?.d,
                message: response?.data?.m
            }
        }
    }
    
    async deleteBanner (id) {
        const response = await ServiceApi.delete(this.baseURL+`/home/sections/cms-banner/${id}`);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            console.log("data",data)
            return data;
        } else {
            return {
                status: response?.status,
                data: response?.data?.d,
                message: response?.data?.m
            }
        }
    }

    async updateBannerIndex (id, payload, headers) {
        const response = await ServiceApi.put(this.baseURL+`/home/sections/cms-banner/sortBanner/${id}`, payload, headers);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response?.status,
                data: response?.data?.d,
                message: response?.data?.m
            }
        }
    }
}

export default new MarketingsApi()