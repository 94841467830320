import React from "react";
import { Collapse } from "antd";
import ChevronUpSvg from "../../../../../icons/ChevronUpSvg";
import ChevronRightSvg from "../../../../../icons/ChevronRightSvg";
import ClinicalDocuments from "./ClinicalDocuments";
import AnthropometricMeasurements from "./AnthropometricMeasurements";
import Attachments from "./Attachments";
import Referrals from "./Referrals";

const { Panel } = Collapse;

const ClinicalDocumentationsTab = () => {
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <div className="mt-6">
      <Collapse
        defaultActiveKey={["1"]}
        onChange={onChange}
        expandIconPosition={"end"}
        bordered={false}
        // accordion={true}
        expandIcon={(panelProps) => {
          return (
            <div
              style={{ backgroundColor: "#42C8FB", display: "flex" }}
              className="h-6 w-6 rounded-full justify-center items-center"
            >
              {panelProps.isActive ? <ChevronUpSvg /> : <ChevronRightSvg />}
            </div>
          );
        }}
      >
        <Panel header="Clinical Documents" key="1">
          <ClinicalDocuments />
        </Panel>
        <Panel header="Anthropometric Measurements" key="2">
          <AnthropometricMeasurements />
        </Panel>
        <Panel header="Attachments" key="3">
          <Attachments />
        </Panel>
        <Panel header="Referrals" key="4">
          <Referrals />
        </Panel>
      </Collapse>
    </div>
  );
};

export default ClinicalDocumentationsTab;
