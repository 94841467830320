import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import OnboardTaxTable from "./OnboardTaxTable";

const context = "onboard";

export default class OnboardTax extends Component {
  constructor() {
    super();
    this.state = {};
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Tax",
      context: context,
    });
  }

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <OnboardTaxTable />
        {/* <h1> Hello World</h1> */}
      </MainLayout>
    );
  }
}
