import { Button, Col, Input, Row, Select, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import DropdownIcon from '../../icons/Dropdown'
import UI from '../../shared/Components/UI/js'
import { mem, Text } from '../../shared/custom-react-native'
import ServiceSubscriptionApi from '../../api_services/subscriptions'
import { Failed, Success } from '../_components/Modal'

const Subscription = ({ _this }) => {
  const [membersLineItem, setMembersLineItem] = useState([])

  const getMembersLineItem = () => {
    _this.show_loading()
    const member = JSON.parse(mem.get("edit_user_group_member")) 
    ServiceSubscriptionApi.getMembersLineItem(member._id).then( res => {
      if (res.status === 200) {
        let data = res;
        _this.hide_loading()
        setMembersLineItem(data)
      } else {
        _this.show_modal(
          <Failed 
            description={res.message} 
            onDismiss={() => { 
              _this.hide_loading();
              _this.hide_modal()
            }} 
          />
        );
      }
    });
  }

  const onChangeStatus = (value, bundleItem, subscription) => {
      const membersSubscription = membersLineItem.map(a => {
        if (subscription._id === a._id) {
          a.subscriberUserGroup[0].memberBundleItemStatus.forEach(b => {
            if (b._id === bundleItem._id) {
              b.fixTermLineItemStatus = value
            }
          })
        }
        return a
      })
      setMembersLineItem(membersSubscription)
  }

  const updateMemberLineItem = (value, bundleItem, subscription) => {

    let newData = []

    membersLineItem.map(a => a.subscriberUserGroup[0].memberBundleItemStatus.forEach(b => {
      newData.push({
        id: b._id,
        newStatus: b.fixTermLineItemStatus
      })
    }))

    const payload = {
      lineItems: newData
    }

    _this.show_loading()

    ServiceSubscriptionApi.updateMembersLineItem(
      payload).then( res => {
      if (res.status === 200) {
        let data = res;
        console.log(data)
        _this.hide_loading()
        _this.show_modal(<Success
          title={"Success!"}
          description={"Subscription status change successful"}
          onDismiss={() => {
            getMembersLineItem()
            _this.hide_modal();
          }}
        />);
      } else {
        _this.show_modal(
          <Failed 
            description={res.message} 
            onDismiss={() => { 
              _this.hide_loading();
              _this.hide_modal()
            }} 
          />
        );
      }
    });
  }
  
  useEffect(() => {
    getMembersLineItem()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <FilterBox>
      {
        membersLineItem?.map(subscription => {
          return (
            <div className='mt-5'>
              <Text style={{
                fontWeight: "bold",
                color:UI.colors.secondary,
                fontSize: 15
              }}>
                {subscription?.planName}
              </Text>

              {
                subscription?.subscriberUserGroup &&

                <Toolbar gutter={14}>
                  {
                    subscription?.subscriberUserGroup[0]?.memberBundleItemStatus?.map(item => {
                      return (
                        <>
                          <ToolItem span={8}>
                              <StyledFieldLabel>Item Subscription</StyledFieldLabel>
                              <StyledInput
                                  getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                                  placeholder="--"
                                  style={{
                                    width: "100%",
                                  }}
                                  value={item.bundleProduct?.name}
                                  disabled={true}
                                >
                              </StyledInput>
                            </ToolItem>
                            <ToolItem span={8}>
                              <StyledFieldLabel>Term</StyledFieldLabel>
                              <StyledInput
                                  getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                                  placeholder="--"
                                  style={{
                                    width: "100%",
                                  }}
                                  value={item.billingTerm}
                                  disabled={true}
                                >
                              </StyledInput>
                            </ToolItem>
                            <ToolItem span={8}>
                              <StyledFieldLabel>Status</StyledFieldLabel>
                              <StyledSelect
                                getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                                placeholder="Select Status"
                                style={{
                                  width: "100%",
                                }}
                                value={item.fixTermLineItemStatus}
                              //   allowClear
                                suffixIcon={<DropdownIcon />}
                                onChange={(value) => {
                                  onChangeStatus(value, item, subscription)
                                }}
                              >
                                <Select.Option value="PENDING">Pending</Select.Option>
                                <Select.Option value="ONGOING">Ongoing</Select.Option>
                                <Select.Option value="COMPLETED">Completed</Select.Option>
                                <Select.Option value="RECURRING">Recurring</Select.Option>
                              </StyledSelect>
                            </ToolItem>
                        </>
                      )
                    })
                  }
                </Toolbar>
              }
              
            </div>
          )
        })  
      }
     
      {
        membersLineItem &&
        <StyledFieldLabel>No Line Item Available for this members</StyledFieldLabel>
      }

      <div className='flex gap-5 mt-10'>
        <StyledButton type="primary"
          disabled={membersLineItem.length > 0 ? false : true}
          onClick={updateMemberLineItem}
        >
          Save Changes
        </StyledButton>

        <StyledButton type="primary" style={{ backgroundColor: UI.colors.red}}
          onClick={() => {
            UI.goBack()
          }}
        >
          Cancel
        </StyledButton>
      </div>
    </FilterBox>
  )
}

export default Subscription

const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 0px 0 0 0;
  margin-bottom: 30px;
`;

const Toolbar = styled(Row)`
  /* background-color: red; */
  display: flex;
  align-items: center;
  /* padding-left: 35px;
  padding-right: 35px; */
  margin-top: 20px;
  padding: 33px 16.7px;
  border-radius: 12px;
  border: 1px solid #C9C9C9;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;


const ToolItem = styled(Col)`
  position: relative;
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledButton = styled(Button)`
  height: 50px;
  width: 130px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4375rem; // 23px
  /* margin-left: 20px; */
  /* padding-left: 20px; */
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px !important;
  align-items: center;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
`;