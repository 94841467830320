import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import TouchableOpacity, {
  Shadow,
  StyleSheet,
  Text,
  View,
  Image,
  mem,
} from "../../shared/custom-react-native";
// import CustomPieChart from "../_components/CustomPieChart";
import MainLayout from "../_components/MainLayout";
import "./css.css";
import Statistics from "./Statistics";
import CustomDatatable from "../_components/CustomDatatable";
import Layout from "../_components/Layout";
// import moment from "moment";

const context = "voucher";

export default class VoucherHealthpassDashboard extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Health Pass Dashboard",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
      top5Advocacies: [],
      healthpassData: [],
      siteStats:{},
      isLoading: true,
      mostUsedHealthpass:[],
      activeHealthpass:0,
      inActiveHealthpass:0,
      usedHealthpass:0,
      date_time: "",

      campaigns:[],
      data: [],
      all_data: [],
    });
  }

  onCreate = async () => {
    this.getHealthpassData()
    this.getSiteStats()
    this.loadCampaigns();
  };

  componentWillMount = () => {
    console.log('first')
    this.update_time();
    this.time_interval = setInterval(() => {
      this.update_time();
    }, 5000);
  }
  
  update_time = async () => {
    this.setState({
      date_time: UI.getTimezone({ format: "MMMM D, YYYY" }),
    });
  };

  loadCampaigns = async () => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/campaigns",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            campaigns: data.data,
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  getSiteStats = (params="year") => {
    this.show_loading();
    console.log('first')
    UI.webRequest({
      method: "get",
      url: `admin/healthpass/stats?statsType=most-used&dateUnit=${params}`,
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        console.log(data)
        this.setState(
          {
            mostUsedHealthpass: data.data.mostUsed,
            activeHealthpass: data.data.activeCount,
            inActiveHealthpass: data.data.inactiveCount,
            usedHealthpass: data.data.usedCount,
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  getHealthpassData = (params) => {
    console.log(params)
    UI.webRequest({
      method: "get",
      url: "admin/healthpass",
      params: {
        params
      },
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            healthpassData: data.data,
            data: data.data.slice(0,5),
            all_data: data.data.slice(0,5),
          },
          () => {
            this.reload_table("healthpass_list_dashboard");
          }
        );
      },
      onFinish: () => {
        this.setState({
          isLoading: false,
        });
        this.hide_loading();
      },
    });
  }

  custom_sort = (key, value) => {
    this.getHealthpassData({
      filterBy :value,
      filterByOrder :'asc'
    });
  };

  render() {
    const _this = this;
    const { width,
      //  isLoading 
      } = _this.state;
    console.log(_this.state)
    console.log(this.state.data)
    // let user_stats_container = UI.measure("user_stats_container").width;

    return (
      <MainLayout context={context} _this={_this}>
        <Statistics _this={_this} />

        <UI.Row breakpoint={1070} _this={_this} style={{ width: "100%" }}>
          

          <View
            style={{
              borderRadius: 5,
              backgroundColor: "white",
              ...Shadow._3(),
              flex: 1,
              width: width > 1070 ? null : "100%",
              alignSelf: "flex-start",
              marginTop:20
            }}
          >
            <View
              _this={_this}
              style={{ width: "100%", padding: 20, paddingBottom: 10, flexDirection:"row", justifyContent:'space-between', alignItems:'center' }}
            >
              <Text style={styles.box_header_text}>Health Pass List</Text>
              <View
                style={{
                  alignSelf: width > 950 ? "flex-start" : "center",
                  marginTop: 8,
                  marginLeft: 10,
                }}
              >
                <Layout.SelectPicker
                  _this={_this}
                  // title={"Campaign Name"}
                  isRequired={false}
                  onChange={(e) => {
                    this.custom_sort("campaign_name", e);
                  }}
                >
                  <option value="">Filter By</option>
                  <option value="campaign">Campaign Name</option>
                  <option value="healthpass">Healthpass Name</option>
                  <option value="createdAt">Date Created</option>
                  <option value="expiry">Date Expired</option>

                  {/* {this.state.campaigns?.map((item) => {
                    return item ? (
                      <option value={item.title}>{item.title}</option>
                    ) : (
                      <View></View>
                    );
                  })} */}
                </Layout.SelectPicker>
              </View>

            </View>

            <CustomDatatable
              state_name={"healthpass_list_dashboard"}
              _this={_this}
              columns={[
                // { ID: "id" },
                { "Healthpass Name": "healthpass_title" },
                // { "Generation Progress": "percentage" },
                // { "Total Count": "initialQuantity" },
                { "Start Date": "startDate" },
                { "End Date": "endDate" },
                { "Campaign Title": "campaign_title" },
                // { "Discount": "percent_discount" },
                { Supply: "supply" },
                // { "No. of Vouchers Utilized": "number_of_vouchers_utilized" },
                // { Price: "price" },
                { Status: "status" },
                { Action: "action" },
              ]}
              data={this.state.all_data.map((item) => {
                const startDate = UI.get_date_string_by_date(item.startDate);
                const endDate = UI.get_date_string_by_date(item.endDate);
                // let percentage = "";

                // if (item.numVouchersToGenerate === 0) {
                //   percentage = "100%"
                // } else {
                //   percentage = Math.round(item.vouchersGenerated / item.numVouchersToGenerate * 100) + '%'
                // }

                const is_active = item.status === "Active" ? true : false;
                return {
                  healthpass_title: item.title,
                  // percentage: percentage,
                  startDate: startDate,
                  endDate: endDate,
                  campaign_title: item.campaign?.title ?? " ",
                  // percent_discount: item.discount + "%" ?? " ",
                  supply: item.numVouchersToGenerate === 0 ? item.initialQuantity : item.numVouchersToGenerate,
                  // number_of_vouchers_utilized: item.usedQuantity,
                  // price: item.price ?? " ",
                  status: is_active ? (
                    <Text style={{ color: "#00C700" }}>Active</Text>
                  ) : (
                    <Text style={{ color: "#f39c12" }}>Inactive</Text>
                  ),
                  action: (
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        onClick={() => {
                          mem.save("view_healthpass", JSON.stringify(item));
                          UI.goTo("/voucher/healthpass-view");
                        }}
                      >
                        <Image
                          height={28}
                          width={28}
                          tintColor={"transparent"}
                          source={UI.VIEW_BTN}
                        />
                      </TouchableOpacity>
                    </View>
                  ),
                };
              })}
              on_page={(page, callback, table_name) => {
                UI.on_page(this.state.all_data, callback, table_name, page);
              }}
            />

            <View
              style={{
                ...styles.no_info_container,
                height: "100%",
                justifyContent:
                  this.state.all_data.length > 0 ? "flex-start" : "center",
                display: this.state.all_data.length > 0 ? "none" : "flex",
              }}
            >
              <UI.Row
                breakpoint={820}
                style={{ maxWidth: 360, alignItems: "center" }}
                _this={_this}
              >
                <Image
                  height={100}
                  width={100}
                  tintColor={"transparent"}
                  source={UI.NO_INFO}
                />
                {UI.box(10)}
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#BFBFBF",
                    textAlign: width > 820 ? "left" : "center",
                    marginLeft: width > 820 ? 20 : 0,
                  }}
                >
                  NO DATA AVAILABLE YET
                </Text>
              </UI.Row>
            </View>
          </View>

          {UI.box(20)}

          <View
            id={"user_stats_container"}
            style={{ 
              // ...styles.main_content_container,
              flex: 1, padding: 0, marginTop: 20 }}
          >
            {/* <UI.Row
              breakpoint={420}
              _this={_this}
              basis={user_stats_container}
              style={{ width: "100%", padding: 20 }}
            >
              {UI.box(10)}
              <UI.Row breakpoint={1170} _this={_this} style={{ width: "100%" }}>
              <Text style={styles.box_header_text}>Health Pass Statistics</Text>
            </UI.Row>
              
            </UI.Row>

            <UI.Row
              breakpoint={820}
              style={{ width: "100%", alignItems: "center", justifyContent:'space-around' }}
              _this={_this}
            >
            {!isLoading && (
              <CustomPieChart
                ref={(pie) => {
                  this.pie = pie;
                }}
                data={[
                  {
                    color: UI.colors.active,
                    label: "Total Vouchers Count",
                    count: 800,
                    onSelect: (item) => {},
                  },
                  {
                    color: UI.colors.primary,
                    label: "Total Vouchers Count",
                    count: 1000,
                    onSelect: (item) => {},
                  },
                ]}
                _this={_this}
                id={"user_stats"}
              />
            )}
            <View>
              <Text style={{color: UI.colors.primary, fontWeight:600, fontSize:30}}>1000</Text>
              <Text style={{fontWeight:600}}>Total Active Health Pass</Text>

              {UI.box(40)}

              <Text style={{color: UI.colors.active, fontWeight:600, fontSize:30}}>800</Text>
              <Text style={{fontWeight:600}}>Total Active Health Pass</Text>

            </View>
          </UI.Row> */}
          
          </View>
          
        </UI.Row>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  main_content_container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    flex: 1,
  },
  legend_text: {
    color: "#535353",
    fontWeight: "bold",
    fontSize: 12,
    marginLeft: 10,
  },
  legend_container: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  dot_color: {
    height: 16,
    width: 16,
    borderRadius: 10,
    backgroundColor: UI.colors.primary,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});
