import { Input, Row, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components';
import LaboratoryOrdersApi from '../../../../api_services/Doctors-Portal/LaboratoryOrdersApi';
import DataTable from '../../../../ComponentsFC/DataTable';
import SearchIcon from '../../../../icons/Search';
import UI from '../../../../shared/Components/UI/js';
import TouchableOpacity, { StyleSheet, Text, View } from '../../../../shared/custom-react-native';
import { Failed } from '../../../_components/Modal';
import DOMPurify from 'dompurify';


const LaboratoryOrderTableList = ({ _this, laboratorySelected, setLaboratorySelected }) => {
	const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [tableEntries] = useState(5);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");

  const getLaboratoryOrderList = useCallback( async () => {
    
		let params = {};
    if (currentPageNum) {
      params.page = currentPageNum;
      params.limit = 5;
    }

    if (searchKeyword) {
      params.keyword = searchKeyword;
    }

    setIsLoading(true);
    LaboratoryOrdersApi.getAllLaboratoryOrders(params).then( res => {
      setIsLoading(false);
      if (res.status === 200) {
         let data = res.data;
         setTotalRecords(res.count);
         setTableData(data)
      } else {
        _this.show_modal(
          <Failed 
            description={res.message} 
            onDismiss={() => { 
              _this.hide_loading();
              _this.hide_modal()
            }} 
          />
        );
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNum, searchKeyword])

  useEffect(() => {
    getLaboratoryOrderList();
  }, [getLaboratoryOrderList]);

  const handlePaginateTable = (pageNum) => {
    setCurrentPageNum(pageNum);
  };

  // const issueRequest = () => {
  //   const laboratorySelect = laboratorySelected.map(item => {
  //     return {
  //       id: item._id,
  //       name : item.name,
  //     }
  //   })

  //   const payload = {
  //     laboratoryOrders: laboratorySelect
  //   }

  //   setIsLoading(true);
  //   LaboratoryOrdersApi.requestLaboratoryOrders(_this.state.appointment._id, payload).then( res => {
  //     setIsLoading(false);
  //     if (res.status === 200) {
  //       // let data = res.data;
  //       _this.show_modal(<Success
  //         title={"Success!"}
  //         description={"You have successfully updated the selected information"}
  //         onDismiss={() => {
  //           _this.get_appointment(_this.state.appointment?._id)
  //           _this.hide_modal();
  //         }}
  //       />)
  //     } else {
  //       _this.show_modal(
  //         <Failed 
  //           description={res.message} 
  //           onDismiss={() => { 
  //             _this.hide_loading();
  //             _this.hide_modal()
  //           }} 
  //         />
  //       );
  //     }
  //   });
  // }

  const tableColumnHeader = [
    {
      title: "Lab Order Name",
      dataIndex: "name",
      key: "name",
      render: (name, data) => 
			<TouchableOpacity
				onClick={() => {
					for (const item of laboratorySelected) {
						if (item.name === data.name) {
							return _this.show_modal(
      					<Failed description={`${item.name} is already selected`} onDismiss={_this.hide_modal} />
							)
						}
					}
					setLaboratorySelected(oldArray => [...oldArray, data] );
					_this.hide_modal();
				}}
			>
			<Text style={{color:UI.colors.secondary}} >{name ?? "--"}</Text>
		</TouchableOpacity>,
		width: 200,
    },
    {
      title: "Lab order Description",
      dataIndex: "description",
      key: "description",
      render: (description) => {
        
        const sanitizeData = () => ( {
          __html: DOMPurify.sanitize(description ?? "")
        } ) 

        return (
          <Tooltip 
            zIndex={20000} 
            title={<div
              dangerouslySetInnerHTML={sanitizeData()}    
            />}
          >
            {
              description.length < 50 ? 
                <div
                  dangerouslySetInnerHTML={sanitizeData()}    
                /> : 
                <div
                  dangerouslySetInnerHTML={{
                    __html: description.slice(0, 50) + "..."
                  }}    
                />
            }
        </Tooltip>
        )
      },
      width: 200,
    },
  ];

  const handleChangeSearchQuery = (value) => {
    setSearchKeyword(value)
    getLaboratoryOrderList()
  }

	return (
    <div>
      <TableBox>
        <View
          style={{ ...styles.md_header, backgroundColor: UI.colors.secondary }}
          >
          <TouchableOpacity
              onClick={() => {
                _this.hide_modal();
              }}
              style={styles.md_close}
          >
              <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="CLOSE" />
          </TouchableOpacity>

          <View style={{ ...styles.md_header_text_container, marginLeft: 50 }}>
              <Text style={styles.md_header_text}>Laboratory Order</Text>
          </View>
        </View>

        <Toolbar>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: '15px',
              color: "#0E4D94",
            }}
          >
            Laboratory Record
          </Text>

          {UI.box(10)}

          <StyledSearch
            placeholder="Enter Order Name/Description Keyword"
            suffix={<SearchIcon />}
            onChange={(e) => {
              handleChangeSearchQuery(e.target.value);
            }}
            style={{ width: 200 }}
          />
        </Toolbar>

          <DataTable
            tableColumnHeader={tableColumnHeader}
            dataSource={tableData}
            defaultPageSize={30}
            tableEntries={tableEntries}
            isBackendPaginated={true}
            totalRecords={totalRecords}
            onPaginate={handlePaginateTable}
            loading={isLoading}
            scroll={{
                x: 600,
            }}
          /> 
      </TableBox>
    </div>
  )

}

export default LaboratoryOrderTableList

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  /* min-width: 50%; */
  /* height: 100%; */
  flex:1;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 400px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;

const styles = StyleSheet.create({
    md_header_text: {
      fontSize: 18,
      color: "white",
      fontWeight: "bold",
      whiteSpace:'nowrap',
    },
    md_header_text_container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    md_close: {
      height: 60,
      width: 60,
      justifyContent: "center",
      alignItems: "center",
    },
    md_header: {
      marginBottom: 33,
      height: 60,
      width: "100%",
      backgroundColor: "#00CBFF",
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      flexDirection: "row-reverse",
    },
  });