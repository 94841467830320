import Icon from "@ant-design/icons";
import React from "react";

const FieldHeightSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.89"
    height="17.217"
    viewBox="0 0 20.89 17.217"
  >
    <path
      id="height"
      d="M172.829,171.293l4.344,4.614a1.193,1.193,0,0,1,.31.771.974.974,0,0,1-1,1.039.936.936,0,0,1-.707-.3l-1.767-1.909-.973-1.183.051,1.8V187.1a1.009,1.009,0,1,1-2.017,0V176.132l.052-1.8-.973,1.183-1.767,1.909a.937.937,0,0,1-.707.3.974.974,0,0,1-1-1.039,1.193,1.193,0,0,1,.31-.771l4.344-4.614A.985.985,0,0,1,172.829,171.293Zm8.584,16.521-4.344-4.631a1.167,1.167,0,0,1-.31-.762.988.988,0,0,1,1.009-1.048.907.907,0,0,1,.7.3l1.767,1.909.982,1.174-.06-1.8V172a1.013,1.013,0,1,1,2.025,0v10.958l-.061,1.8.974-1.174,1.767-1.909a.913.913,0,0,1,.707-.3.981.981,0,0,1,1,1.048,1.17,1.17,0,0,1-.31.762l-4.344,4.631A.984.984,0,0,1,181.413,187.814Z"
      transform="translate(-166.676 -170.944)"
      fill="#4fc2ed"
    />
  </svg>
);

const FieldHeightIcon = (props) => {
  return <Icon component={FieldHeightSvg} {...props} />;
};

export default FieldHeightIcon;
