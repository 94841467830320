// import UI from "../../shared/Components/UI/js";
import ServiceApi from "../service";
import APIConstants from "../../shared/modules/APIConstants";
const Constants = new APIConstants();

class SettingsApi {
  baseURL = Constants.API_HOST;
  baseURLDoctor = "doctors_onboarding";

  async deleteDoctorAccount( payload, headers) {
    const response = await ServiceApi.delete(this.baseURL + `app/mwell/users/me`, payload, headers);

    if (response?.status === 200) {
      response.status=200;
      return response;
    } else {
      return {
        status: response?.status,
        data: response?.data?.d,
        message: response?.data?.m
      }
    }
  }

  async cancelDoctorAppointments(id, payload) {
    const response = await ServiceApi.put(this.baseURLDoctor + `/doctors/${id}/cancel-appointments`, payload);

    if (response?.status === 200) {
      response.status=200;
      return response;
    } else {
      return {
        status: response?.status,
        data: response?.data?.d,
        message: response?.data?.m
      }
    }
  }
}

export default new SettingsApi();
