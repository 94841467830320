import { Button, Col, Typography, Upload, DatePicker } from 'antd';
import React, { useState } from 'react'
import styled from 'styled-components';
import UI from '../../shared/Components/UI/js';
// import TouchableOpacity, { Shadow, StyleSheet, Text, View } from '../../../shared/custom-react-native';
import TouchableOpacity, { StyleSheet, Text, View } from '../../shared/custom-react-native';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { Failed } from '../_components/Modal';
import moment from 'moment';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const OfficialReceiptModal = ({_this}) => {

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(_this.state.downloaded_or?.url ? _this.state.downloaded_or?.url : "");
  const [date, setDate] = useState(
    _this.state?.officalReceipts?.length > 0 ? _this.state?.officalReceipts?.slice(-1).pop().orDate ? moment(new Date(_this.state.officalReceipts?.slice(-1).pop().orDate).getDate(),'DD/MM/YYYY') ?? ""  : "" : "");

  const beforeUpload = (file) => {

    auth_upload("or", file);
  
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (file, b) => {
    if (file.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    getBase64(file.file.originFileObj, (url) => {
      setLoading(false);
      setImageUrl(url);
    });
  };

  const auth_upload = async (state_name, file, callback) => {
    // console.log(file);
    UI.upload_image({
      url: "doctors_onboarding/upload/official_receipt",
      // file: file.file,
      params:{
        file:file,
        date: date,
      },
      onFail: (err) => {
        // console.log(err);
        console.log("ERROR: ", err.data.c, err.data.m)
        let errMsg = "Upload Failed. Application Error."
        if(err.data.c === 400) {
          errMsg = err.data.m;
        }

        _this.show_modal(
          <Failed
            title={"Failed"}
            description={errMsg}
            onDismiss={() => {
              _this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (path, response) => {
        // console.log(state_name);
        // console.log(path);
        _this.setState({
          [state_name]: path,
        });
        if (callback) {
          callback(path);
        }
      },
    });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onChangeDate = (date, dateString) => {
    setDate(dateString)
  };

  const initial = { 
    backgroundColor: "white",
    borderRadius: "18px",
    // ...Shadow._3(),
    zIndex: 10003 + 1,
    overflowY: "auto",
    alignItems: "center",
    width: 500,
    maxWidth: 1200,
  };
  // console.log(moment(_this.state.officalReceipts.slice(-1).pop().orDate).format('YYYY-MM-DD'));
  return (
    <div className="" style={{
      ...initial,
    }}>
      <div style={{width:'100%'}}>
        <View
          style={{
            ...styles.md_header,
            // ...props.titleStyles ?? "",
          }}
          >
          <TouchableOpacity
            onClick={() => {
              _this.hide_modal()
            }}
            style={styles.md_close}
          >
            <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt=""/>
          </TouchableOpacity>

          <View style={styles.md_header_text_container}>
            <Text style={styles.md_header_text}>Official Receipt</Text>
          </View>
          {UI.box(60)}
        </View>
        <div className="p-5 flex flex-col gap-5" >
          <ToolItem span={25}>
            <StyledFieldLabel>Input Date</StyledFieldLabel>
            <StyledDatePicker 
              defaultValue={date}  
              onChange={onChangeDate} 
              popupStyle={{zIndex: 20000}}
            />
          </ToolItem>

          <ToolItem span={25}>
            <StyledFieldLabel>Upload File</StyledFieldLabel>
            <UploadReceiptImg
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={beforeUpload}
              onChange={handleChange}
              isgrayed={date === "" ? "true" : "false"}
              disabled={date === "" ? true : false}
            >
              {
                // _this.state.downloaded_or?.url ? <img src={_this.state.downloaded_or?.url} alt="avatar" style={{ width: '100%' }} /> :
                imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%', height: "102px" }} /> : uploadButton
              }
            </UploadReceiptImg>
          </ToolItem>
          
          <div className='flex justify-end items-center'>
            <div className=''>
              <StyledButton type="primary" onClick={() => { _this.hide_modal() }}>
                Close
              </StyledButton>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default OfficialReceiptModal

const ToolItem = styled(Col)`
  position: relative;
  .ant-picker {
    margin-left: 102px;
  }
  .ant-picker-range {
    margin-left: 62px;
  }
`; 

const StyledDatePicker = styled(DatePicker)`
    margin-left: 0px !important;  
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    width:50%;
`;

const StyledButton = styled(Button)`
  height: 50px;
  width: 130px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  margin-bottom: 10px !important;
  font-size: 18; // 15px
  font-weight: bold;
`;

const UploadReceiptImg = styled(Upload)`
  .anti-icon {
    color: red;
  }
 `

const styles = StyleSheet.create({
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: UI.colors.secondary,
    // borderTopRightRadius: 10,
    // borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
});