import React from "react";

const PrescriptionSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.345"
      height="33.794"
      viewBox="0 0 25.345 33.794"
    >
      <path
        id="file-prescription-solid"
        d="M4.224,0A4.228,4.228,0,0,0,0,4.224V29.569a4.228,4.228,0,0,0,4.224,4.224h16.9a4.228,4.228,0,0,0,4.224-4.224V10.561H16.9a2.11,2.11,0,0,1-2.112-2.112V0ZM16.9,0V8.448h8.448ZM6.864,12.937h4.752a3.959,3.959,0,0,1,1.432,7.65l2.838,2.66L18.118,21.2a1.322,1.322,0,1,1,1.789,1.947l-2.086,1.914,2.092,1.96A1.321,1.321,0,1,1,18.1,28.949l-2.238-2.1-2.3,2.112a1.322,1.322,0,0,1-1.789-1.947l2.152-1.973L9.65,21.029a1.23,1.23,0,0,1-.152-.172H8.184v2.9a1.32,1.32,0,0,1-2.64,0v-9.5A1.324,1.324,0,0,1,6.864,12.937Zm4.752,5.28a1.32,1.32,0,1,0,0-2.64H8.184v2.64Z"
        fill="#035099"
      />
    </svg>
  );
};

export default PrescriptionSvg;
