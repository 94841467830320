import { Row, Select, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components';
import LaboratoryOrdersApi from '../../../../api_services/Doctors-Portal/LaboratoryOrdersApi';
import DataTable from '../../../../ComponentsFC/DataTable';
import DropdownIcon from '../../../../icons/Dropdown';
import UI from '../../../../shared/Components/UI/js';
import TouchableOpacity, { Text } from '../../../../shared/custom-react-native';
import { Failed } from '../../../_components/Modal';

const LaboratoryOrderFilesTable = ({ _this }) => {
	const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableEntries, setTableEntries] = useState(5);
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const getSubscriptionViewBilling = useCallback( async () => {

		let params = {};
    if (currentPageNum) {

      params.page = currentPageNum;
      params.limit = tableEntries;
    }
		setIsLoading(false)
		setTableData(_this.state.appointment?.notes?.laboratory?.orders);
		// return
    // setIsLoading(true);
    LaboratoryOrdersApi.getLaboratoryBySpecificAppointment(_this.state.appointment?._id, params).then( res => {
      setIsLoading(false);
      if (res.status === 200) {
         let data = res.data;
         setTableData(data)
      } else {
        _this.show_modal(
          <Failed 
            description={res.message} 
            onDismiss={() => { 
              _this.hide_loading();
              _this.hide_modal()
            }} 
          />
        );
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNum, tableEntries])

  useEffect(() => {
    getSubscriptionViewBilling();
  }, [getSubscriptionViewBilling]);

  const handlePaginateTable = (pageNum) => {
    setCurrentPageNum(pageNum);
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const tableColumnHeader = [
    {
      title: "Date",
      dataIndex: "name",
      key: "name",
      render: (name, item) => 
        <Text style={{color:UI.colors.secondary}} >
          {
            item.laboratoryResultPath?.uploadedAt ? UI.momentPHTimezone(item.laboratoryResultPath?.uploadedAt).format('MM/DD/YYYY') : "--"
          }
        </Text>,
		  width: 200,
    },
    {
      title: "Laboratory Order",
      dataIndex: "laboratory_order",
      key: "laboratory_order",
      render: (description, item) => item.laboratoryOrderName ?? "--",
      width: 200,
    },
    {
      title: "Filename",
      dataIndex: "filename",
      key: "filename",
      render: (filename, item) => 
        <TouchableOpacity
          onClick={() => {
            window.open(item?.urlFile, "_blank");
          }}
          style={{ textDecoration: "underline", textDecorationColor: UI.colors.primary }}
        >
          <Text style={{color:UI.colors.primary}} >{item.laboratoryResultPath?.filename ?? "--"}</Text>
        </TouchableOpacity>,
      width: 200,
    },
  ];

  return (
    <div className=''>
			<TableBox>
				<Toolbar>
					<Text
						style={{
							fontWeight: "bold",
							fontSize: '15px',
							color: "#0E4D94",
						}}
					>
						Laboratory Order
					</Text>

					{UI.box(10)}

          <div className='flex items-center'>
            <FieldLabel>Only Show</FieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              defaultValue="5"
              style={{
                width: 160,
              }}
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeNumOfEntries}
            >
              <Select.Option value="5">5 Entries</Select.Option>
              {/* <Select.Option value="10">10 Entries</Select.Option> */}
              {/* <Select.Option value="30">30 Entries</Select.Option> */}
              {/* <Select.Option value={totalRecords}>All Entries</Select.Option> */}
            </StyledSelect>
          </div>
				</Toolbar>

					<DataTable
						tableColumnHeader={tableColumnHeader}
						dataSource={tableData}
						defaultPageSize={5}
						pageSizeOptions={["5", "10", "30"]}
						tableEntries={tableEntries}
						isBackendPaginated={true}
						// totalRecords={totalRecords}
						onPaginate={handlePaginateTable}
						loading={isLoading}
						scroll={{
								x: 600,
						}}
					/> 
			</TableBox>
    </div>
  )
}

export default LaboratoryOrderFilesTable

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 20px 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  /* min-width: 50%; */
  /* height: 100%; */
  flex:1;
  margin-bottom: 25px;
`;

const Toolbar = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;