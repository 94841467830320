import { Row } from 'antd';
import React from 'react'
import styled from 'styled-components';
import UI from '../../shared/Components/UI/js';
import TouchableOpacity, { mem, StyleSheet, Text, View } from '../../shared/custom-react-native';
import Layout from '../_components/Layout';
import ServiceDoctorsApi from "../../api_services/doctors";
import { CustomModal, Failed, Success } from '../_components/Modal';

const PatientRescheduleAppointmentModal = ({ _this, appointment }) => {
  const doctor = JSON.parse(mem.get('onboard_data'))

  const acceptReschedule = async () => {
    _this.show_loading();
    _this.hide_modal();
    ServiceDoctorsApi.AcceptDeclineReScheduleAppointment(appointment._id, 'accept').then( res => {
      if (res.status === 200) {
        _this.hide_loading();
        _this.show_modal(
          <Success
          title={"Success!"}
          // description={"You have successfully updated the selected information"}
          description={"You have successfully accepted Rescheduled appointment."}
          onDismiss={() => {
            _this.hide_modal();
            _this.load_consultations();
          }}
        />)
      } else {
        _this.hide_loading();
        _this.show_modal(
          <Failed
            description={res.message} 
            onDismiss={() => { 
              _this.hide_modal()
            }} 
          />
        );
      }
    });
  }

  const declineReschedule = async () => {
    _this.show_loading();
    _this.hide_modal();
 
    ServiceDoctorsApi.AcceptDeclineReScheduleAppointment(appointment._id, { status: 'decline' }).then( res => {
      if (res.status === 200) {
        _this.hide_loading();
        // let data = res;
        _this.show_modal(
          <CustomModal
            headerStyle={{ backgroundColor:UI.colors.red}}
            bannerImage={UI.FAILED_BANNER}
            title="DECLINE"
            description_title="Appointment Declined"
            buttons={
              [
                {
                  title: "Continue",
                  style:{},
                  onClick:() => {
                    _this.load_consultations();
                    _this.hide_loading();
                    _this.hide_modal();
                  },
                },
              ]
            }
            onDismiss={() => { 
              _this.hide_modal()
            }} 
          />
        )
      } else {
        _this.hide_loading();
        _this.show_modal(
          <Failed
            description={res.message} 
            onDismiss={() => { 
              _this.hide_modal()
            }} 
          />
        );
      }
    });
  }

  return (
    <div className='flex items-center justify-center'>
      <TableBox>
        <View
          style={{ ...styles.md_header, backgroundColor: UI.colors.secondary }}
          >
          <TouchableOpacity
            onClick={() => {
              _this.hide_modal();
            }}
            style={styles.md_close}
          >
              <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="CLOSE" />
          </TouchableOpacity>

          <View style={{ ...styles.md_header_text_container, marginLeft: 50 }}>
              <Text style={styles.md_header_text}>RESCHEDULE APPOINTMENT</Text>
          </View>
        </View>

        <div style={{ width: "100%", maxHeight: 560, fontSize:18 }}> 
          <div className='flex flex-col px-10 pt-10 gap-5'>
            <span className='flex-1' style={{color: "#58595B"}}>
              Hi “{doctor?.firstName + " " + doctor?.lastName}”, Your Patient “{UI.getPatientName(appointment)}” is requesting to change his/her schedule from.
            </span>
          </div>

          <div className='flex flex-col px-10 pt-10 gap-2'>
            <strong className='flex-1' style={{color: "black"}}>Appointment Date From:</strong>
            <div className='flex'>
              <strong className='flex-1' style={{color: "black"}}>Date:</strong>
              <span className='flex-1' style={{color: "#58595B"}}>{UI.getTimezone({date: appointment.reschedule?.patient?.previousStarDate, format: "LL"})}</span>
            </div>
            <div className='flex'>
              <strong className='flex-1' style={{color: "black"}}>Time:</strong>
              <span className='flex-1' style={{color: "#58595B"}}>{UI.getTimezone({date: appointment.reschedule?.patient?.previousStarDate, format: "h:mm a"})}</span>
            </div>
          </div>

          <div className='flex flex-col px-10 pt-10 gap-2'>
            <strong className='flex-1' style={{color: "black"}}>Appointment Date To:</strong>
            <div className='flex'>
              <strong className='flex-1' style={{color: "black"}}>Date:</strong>
              <span className='flex-1' style={{color: "#58595B"}}>{UI.getTimezone({date: appointment.reschedule?.patient?.start, format: "LL"})}</span>
            </div>
            <div className='flex'>
              <strong className='flex-1' style={{color: "black"}}>Time:</strong>
              <span className='flex-1' style={{color: "#58595B"}}>{UI.getTimezone({date: appointment.reschedule?.patient?.start, format: "h:mm a"})}</span>
            </div>
          </div>
            
          <div className='items-center mt-5 flex justify-end border-t-2'>
            <div className='mr-10 mt-5 flex gap-5'>
              <Layout.Btn
                color={UI.colors.secondary}
                text={"Accept"}
                onClick={() => {
                  acceptReschedule()
                }}
                style={{float:'right'}}
                height={60}
                width={160}
              />
              <Layout.Btn
                color={UI.colors.red}
                text={"Decline"}
                onClick={() => {
                  declineReschedule()
                }}
                style={{float:'right'}}
                height={60}
                width={160}
              />
            </div>
          </div>

        </div>
      </TableBox>
    </div>
  )
}

export default PatientRescheduleAppointmentModal

const TableBox = styled(Row)`
  display: flex;
  padding: 0px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  align-items: center;
  justify-content: center;
  width: 602px;
`;

const styles = StyleSheet.create({
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
    whiteSpace:'nowrap',
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: "#00CBFF",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
});