import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  ImageBackground,
  ImageFit,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
// import UsersGroupCategoryApi from "../../api_services/subscriptions";
import { Failed, Success } from "../_components/Modal";
import LaboratoryPartnerApi from "../../api_services/Merchant/LaboratoryPartnerApi";
import InputForm from "../_components/InputForm";
import laboratoryServices from "../../api_services/Merchant/laboratoryServices";
import styled from "styled-components";
import { Col, Select, Typography } from "antd";

const context = "admin";

export default class AdminLaboratoryEdit extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Laboratory List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      name: "",
      emailAddress: "",
      contactNumber: "",
      productCategory: [],
      status: "",
      serviceType:"",
      image_url: UI.PRODUCT_BOX,
      product_categories: [],
      selected_product_categories: [],
    });
  }

  onCreate = () => {
    this.load_data()
    this.get_categories()
  };

  load_data = async () => {
    const data = mem.get("laboratory_edit");
    if (data) {
      const merchant = JSON.parse(data);

      UI.set_input_value("name", merchant.name ?? "");
      UI.set_input_value("emailAddress", merchant.email ?? "");
      UI.set_input_value("contactNumber", merchant.phone ?? "");
      UI.set_select_value("status", merchant.status ?? "");
      UI.set_select_value("serviceType", merchant.serviceType ?? "");

      const image = await UI.download_image((merchant.merchantOtherInfo?.merchantLogoPath || merchant.merchantLogoPath), "merchants");

      this.setState({ item: merchant, image: image, image_path: merchant.merchantOtherInfo?.merchantLogoPath, 
      productCategory: merchant?.productCategory?.length > 0 && merchant.productCategory.map(item => item.name),
      selected_product_categories: merchant.productCategory.map(item => item._id)
    });
    }
  }

  submit = async () => {
   
    UI.clear_errors();
    const {
      image_file,
      image_path,
      name,
      emailAddress,
      contactNumber,
      selected_product_categories,
      status,
      serviceType
    } = this.state;

    const payload = {
      image: image_file ? image_file : image_path,
      name: name,
      email: emailAddress,
      phone: contactNumber,
      productCategory: selected_product_categories,
      status: status === 'Active' ? "Active" : "Inactive",
      serviceType
    }

    if (!name) {
      await UI.error_form("name", "Laboratory name is required.");
    }

    if (!status) {
      await UI.error_form("status", "Status is required.");
    }

    if (!serviceType) {
      await UI.error_form("serviceType", "Service Type is required.");
    }

    if (!emailAddress) {
      if (!UI.validate_email(emailAddress)) {
        await UI.error_form("emailAddress", "Please enter a valid email.");
      }
    }

    const error_count = this.state.error_count;

    if (error_count > 0) {
      return true
    }

    LaboratoryPartnerApi.updateLaboratoryPartner(this.state.item?._id, payload).then((res) => {
      // setIsLoading(false);
      if (res.status === 200) {
        this.show_modal(<Success
          title={"Success"}
          description={`Successfully updated laboratory`}
          onDismiss={() => {
            UI.goBack()
          }}
        />);
      }  else {
        this.show_modal(
          <Failed
            description={res.message} 
            onDismiss={() => { 
              this.hide_loading();
              this.hide_modal()
            }} 
          />
        );
      }
    });
  };

  handleTargetProductCategory = (id, value) => {
    this.setState({
      selected_product_categories: id,
      productCategory: value
    });
  }

  get_categories = () => {
    laboratoryServices.getAllCategory().then((res) => {
      if (res.status === 200) {
        this.setState({ product_categories: res.data })
      }  else {
        this.show_modal(
          <Failed
            description={res.message} 
            onDismiss={() => { 
              this.hide_loading();
              this.hide_modal()
            }} 
          />
        );
      }
    });
  }

  pick_image = async () => {
    // const _this = this.props._this;
    let image = await UI.get_image();
    this.auth_upload("image_url", image.file);
    // this.setState(
    //   {
    //     icon: image.uri,
    //     icon_file: image.file,
    //     image_file: image.file,
    //   },
    //   () => {
    //     this.auth_upload("icon_url", image.file);
    //   }
    // );
    // this.setState({
    //   image_key: "key_" + Date.now(),
    // });
  };

  upload_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        image: file.uri,
        image_file: file.file,
        did_upload: true,
      }
    );

  };

  render() {
    const _this = this;
    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Edit Laboratory Account
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add item
            </Text>
          </UI.Row>

          <UI.Row breakpoint={970} _this={_this}>
              <View style={{ height: 120, width: 120 }}>
                <Layout.FilePicker
                  _this={_this}
                  title={""}
                  additional={""}
                  // isRequired={true}
                  text={""}
                  state_name={"laboratoryImage"}
                  onChange={async (file) => {
                  }}
                  height={120}
                  component={
                    <ImageBackground
                      style={{ height: 120, width: 120, borderRadius: 10 }}
                      source={this.state.image}
                      imageFit={ImageFit.CONTAIN}
                    />
                  }
                />
              </View>
              {UI.box(20)}
              <strong className="text-red-500">*</strong>
              <View style={{ flex: 1.5, justifyContent: "center" }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
                >
                  {_this.state.did_upload
                    ? "Upload Product Listing Image."
                    : "No Icon Uploaded."}{" "}
                  {/* No Icon Uploaded.  */}
                  Recommended Size: 500 px x 500 px
                </Text>
                {UI.box(10)}

                <Layout.Btn
                  color={UI.colors.secondary}
                  text={"Upload Image"}
                  onClick={() => {
                    this.upload_picture();
                  }}
                  width={130}
                />
              </View>
          </UI.Row>

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Laboratory Name"}
              placeholder={"Enter Laboratory Name"}
              state_name={"name"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.name}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Email Address"}
              placeholder={"Enter Email Address"}
              state_name={"emailAddress"}
              style={{ flex: 1 }}
              // isRequired={false}
              maxLength={UI.Constants.limits.email}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Contact Number"}
              placeholder={"Contact Number"}
              state_name={"contactNumber"}
              prefix={"+63"}
              // isRequired={true}
              isNumber={true}
              maxLength={10}
              style={{ flex: 1 }}
            />
            {UI.box(10)}
          </UI.Row>

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <ToolItem className="gutter-row" span={8}>
              <StyledFieldLabel>Product Category</StyledFieldLabel>
              <StyledSelect
                getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                placeholder="Select Group"
                mode="multiple"
                maxTagCount={'responsive'}
                value={this.state.productCategory}
                style={{
                  width: "100%",
                }}
                allowClear
                onChange={(value, items) => {
                  const getIds = items.map(item => item.id);
                  this.handleTargetProductCategory(getIds, value)
                }}
              >
                {
                  this.state.product_categories.map(category => {
                    return (
                      <Select.Option title="Action" value={category.name} id={category._id}>{category.name}</Select.Option>
                    )
                  })
                }
              </StyledSelect>
            </ToolItem>
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Service Type"}
              isRequired={true}
              state_name={"serviceType"}
            >
              <option value="">Select Service Type</option>
              <option value="Home">Home</option>
              <option value="Clinic">Clinic</option>
              <option value="Home and Clinic">Home and Clinic</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={true}
              state_name={"status"}
            >
              <option value="">Select Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)}
          </UI.Row>
          {UI.box(20)}
        </View>

        {UI.box(20)}

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Layout.Btn
            color={UI.colors.secondary}
            onClick={() => {
              this.submit();
            }}
            text={"Save Changes"}
          />
          {UI.box(20)}

          <Layout.Btn
            color={"#E00000"}
            onClick={() => {
              UI.goBack();
            }}
            text={"Cancel"}
          />
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});

const ToolItem = styled(Col)`
  position: relative;
  .ant-picker {
    margin-left: 102px;
  }
  .ant-picker-range {
    margin-left: 62px;
  }
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
  font-weight: 600;
  font-family: Poppins;
`;

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    color: #0E4D94;
    background-color:#E3EDF8;
  }

  .ant-select-selection-item-remove {
    color: #0E4D94;
    font-size: 10px;
  }

  .anticon {
    vertical-align: -0em !important;
  }

  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
`;