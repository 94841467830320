import React, { Component } from "react";
// import UI from "../UI/js"; // **Commented out due to unused import
import { View, Text } from "../../custom-react-native";
import "./css.css";
import Helper from "../helper";

export default class Item extends Component {
  // **Commented out unused/useless constructor
  // **you don't necessarily have to define an empty constructor or just super inside (derived classes)
  // **since it's automatically handled by JavaScript/TypeScript

  // constructor(props) {
  //   super(props);
  // }
  id = Helper.uniqid("list");

  // mockData = [
  //   {
  //     header: 'Product has been added to cart',
  //     name: 'Ibuprofen',
  //     date: '1/1/2000'
  //   },
  //   {
  //     header: 'Product has not been added to cart',
  //     name: 'Paracetamol',
  //     date: '2/2/2001'
  //   },
  //   {
  //     header: 'Product is pending',
  //     name: 'Analgesic',
  //     date: '3/3/2002'
  //   },
  // ];

  colors = {
    primary: "#04CCFF",
    secondary: "#111111",
    sub: "#7f8c8d",

    active: "#00C700",
    inactive: "#f39c12",
    unset: "#7f8c8d",
    header: "#005099"
  };

  styles = {
    header1: {
      color: this.colors.primary,
      fontWeight: "bolder"
    },
    fdc_fs: {
      flexDirection: "column",
      alignItems: "flex-start"
    },
    fdr_fs: {
      flexDirection: "row",
      alignItems: "flex-start"
    }
  }

  render() {
    return (
      <View style={{marginBottom: "2em"}}>
        {
          this.props.data.map( (item) => {
            return (
              <View style={{ ...this.styles.fdc_fs, marginBottom: 20 }}>
                <View style={this.styles.fdr_fs}>
                  <Text style={{
                    height: "12px",
                    width: "12px",
                    backgroundColor: this.colors.primary,
                    borderRadius: "50%",
                    display: "inline-block",
                    margin: "auto 0",
                    marginRight: "1em"
                  }}/>
                  <Text style={{ ...this.styles.header1, minWidth: "300px" }}>{item.header}</Text>
                  <Text style={{ color: this.colors.sub }}>1 day ago</Text>
                </View>
                <View style={this.styles.fdr_fs}>
                  <View style={{ flex: 1, width: "12px", marginRight: "1em" }}></View>
                  <Text style={{ color: this.colors.secondary }}>{item.name}</Text>
                </View>
                <View style={this.styles.fdr_fs}>
                  <View style={{ flex: 1, width: "12px", marginRight: "1em" }}></View>
                  <Text style={{ color: this.colors.sub }}>{item.date}</Text>
                </View>
              </View>
            )
          })
        }
      </View>
    );
  }
}
