import React, { useState, useEffect } from "react";
import { View } from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";

const Specialty = ({ _this }) => {
  const { specializations, specialty, professionalType } = _this.state;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const filter_specialties = specializations.filter((specialty) => {
      if (specialty.professionalType) {
        if (professionalType === "medical" || professionalType === true) {
          return specialty.professionalType === "Medical";
        } else if (
          professionalType === "Non medical" ||
          professionalType === false
        ) {
          return specialty.professionalType === "Non Medical";
        }
      }
      return false;
    });

    setOptions(filter_specialties);
  }, [professionalType, specializations]);

  return (
    <View style={{ flex: 1 }}>
      <Layout.SelectPicker
        _this={_this}
        title={"Specialty"}
        isRequired={true}
        state_name={"specialty"}
        isMultiple={true}
      >
        <option value="">Select Specialty</option>
        {options.map((item) => (
          <option key={item._id} value={item.label}>
            {item.label}
          </option>
        ))}
        ;
      </Layout.SelectPicker>

      <InputForm
        _this={_this}
        title={"Nutrition Plan Price"}
        placeholder={"Enter price here"}
        state_name={"nutrition_price"}
        isRequired={true}
        isNumber={true}
        maxLength={13}
        style={{
          flex: 1,
          marginTop: 10,
          display: specialty.includes("Nutritionist") ? "flex" : "none",
        }}
      />
    </View>
  );
};

export default Specialty;
