import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import { Failed } from "../_components/Modal";
import "./css.css";
// import PromotionStats from "./PromotionStats";
import MarketingCampaignReferralsTable from "./MarketingCampaignReferralsTable";
import Statistics from "./Statistics";
import ServiceMarketingsApi from "../../api_services/marketings";

const context = "marketing";

export default class MarketingCampaignReferrals extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Campaign Referrals",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
      duration: "year",
      stats: {
        totalNumberOfEvents: 0,
        totalCodeReferrals: 0,
        totalQrReferrals: 0,
        totalReferrals: 0,
      }
    });
  }

  onCreate = () => {
    this.getUserReferralStats();
  };

  getUserReferralStats = () => {
    try {
      this.show_loading();

      ServiceMarketingsApi.getCampaignReferralsStats({
        duration: this.state.duration,
        start: this.state.startDate,
        end: this.state.endDate,
      }).then((res) => {
        this.hide_loading();

        if (res.status === 200) {
          this.hide_loading();
          console.log(res)
          this.setState({ stats: res})
        } else {
          this.show_modal(
            <Failed
              description={res.message} 
              onDismiss={() => { 
                UI.goBack()
                this.hide_loading();
              }} 
            />
          );
        }
      });
    } catch (err) {
      // Handle Errors Here
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <Statistics _this={_this} />
        <MarketingCampaignReferralsTable _this={_this}/>
      </MainLayout>
    );
  }
}
