import React from 'react'
import styled from 'styled-components';
import UI from '../../shared/Components/UI/js'
import Text from "../ui/Text";
import { Image } from 'antd';

const LoginHeader = () => {
  return (
    <LoginHeaderContainer>
      <StyledText style={{color:UI.colors.primary}} level={2} > Welcome To </StyledText>
      <Image src={UI.LOGIN_LOGO_MWELL} preview={false} alt="login_logo"/>
      <StyledText style={{color:UI.colors.primary}} level={2}> Your Wellness Partner </StyledText>
    </LoginHeaderContainer>
  )
}

export default LoginHeader

const LoginHeaderContainer = styled(`div`)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;

  
`;

const StyledText = styled(Text)`
  line-height: 35px !important;
  text-align: center;
`