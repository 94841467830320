import React from "react";
import styled from "styled-components";
import { Row, Col, Divider } from "antd";
import { Container, ContainerLabelSecondary } from "../ui";
import ContainerLabelTertiary from "../ui/ContainerLabelTertiary";

const UserGroupHeader = (props) => {
  const profileLogoImg = props.this.state.profile_logo_image;
  const partnerLogoImg = props.this.state.partner_logo_image;

  return (
    <StyledContainer>
      <Row>
        {(profileLogoImg || partnerLogoImg) && (
          <Col>
            <div className="flex justify-between gap-5">
              {profileLogoImg && profileLogoImg && (
                <img
                  width={100}
                  // style={{ marginTop:props.this.state.is_rendering && 5 }}
                  src={profileLogoImg}
                  alt="N/A"
                />
              )}

              {partnerLogoImg && partnerLogoImg && (
                <img
                  width={100}
                  // style={{ marginTop:props.this.state.is_rendering && 5 }}
                  src={partnerLogoImg}
                  alt="N/A"
                />
              )}
            </div>
          </Col>
        )}

        <VerticalDivider type="vertical" />

        <Col>
          <Col>
            <StyledContainerLabelSecondary>
              {props.this.state.user_group?.name}
            </StyledContainerLabelSecondary>
            {/* <span style={{fontWeight:700, fontSize:'16px'}}>
                +63 947654211
              </span> */}
            <ContainerLabelTertiary style={{ margin: "12px 0 0 0" }}>
              {props.this.state.user_group?.description}
            </ContainerLabelTertiary>
          </Col>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default UserGroupHeader;

const StyledContainer = styled(Container)`
  padding: 20px 20px 20px 20px;
`;

const StyledContainerLabelSecondary = styled(ContainerLabelSecondary)`
  color: rgb(4, 204, 255) !important;
  font-size: 18px !important;
  font-weight: 600 !important;
`;

const VerticalDivider = styled(Divider)`
  background-color: rgba(229, 231, 235, 1);
  width: 2;
  height: 100%;
  margin-left: 40px;
  margin-right: 40px;
`;
