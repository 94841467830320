import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  ImageBackground,
  ImageFit,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import "./css.css";
const context = "admin";

export default class AdminAddHospitalAdmin extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Hospital Admins",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      context: context,

      did_upload: false,

      image: UI.DOCTOR_PROFILE,
      image_file: null,

      name: "",
      email: "",
      phone: "",
      status: "",
      hospital: "",

      hospitals: [],
    });
  }

  onCreate = () => {
    setTimeout(() => {
      this.setState({});
    }, 10);

    this.load_hospitals();
  };

  load_hospitals = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "admin/hospitals",
      params: {},
      onFail: (err) => {},
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        this.setState({
          hospitals: data.data,
        });
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  upload_picture = async () => {
    const file = await UI.get_image();
    this.setState({
      image: file.uri,
      image_file: file.file,
      did_upload: true,
    });
  };

  submit = async () => {
    UI.clear_errors();
    const { image_file, name, email, phone, status, hospital } = this.state;

    if (!name) {
      await UI.error_form("name", "Hospital Admin Name is required.");
    }
    if (!email) {
      await UI.error_form("email", "Email is required.");
    }

    if (!UI.validate_email(email)) {
      await UI.error_form("email", "Please enter a valid email address.");
    }
    if (!phone) {
      await UI.error_form("phone", "Contact Number is required.");
    }
    if (!status) {
      await UI.error_form("status", "Status is required.");
    }
    if (!hospital) {
      await UI.error_form("hospital", "Hospital is required.");
    }
    if (phone.length !== 10) {
      await UI.error_form(
        "phone",
        "Contact Number should be in 9XX XXX XXXX format."
      );
    }

    if (!image_file) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Invalid Profile Picture"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      return;
    }
    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    this.show_loading();

    UI.webRequest({
      method: "post",
      url: "admin/hospital-admins",
      params: {
        image: image_file,
        name,
        email,
        phone,
        status,
        hospital,
      },
      isMultiPart: true,
      onFail: (err) => {
        const data = err.data;
        const error_message = data.error;
        UI.set_select_value("status", "");
        UI.set_select_value("hospital", "");
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (response) => {
        UI.clear_forms();
        UI.set_select_value("hospital", "");
        UI.set_select_value("status", "");
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully Added the Hospital Admin"}
            onDismiss={() => {
              this.setState({
                image: UI.DOCTOR_PROFILE,
                image_file: null,
              });
              this.hide_modal();
            }}
          />
        );
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;
    const {
      //  height, width, isMobile,
        did_upload, image, hospitals } =
      _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Add New Hospital Admin
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add hospital admin
            </Text>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <View style={{ height: 120, width: 120 }}>
              <Layout.FilePicker
                _this={_this}
                title={""}
                additional={""}
                isRequired={false}
                text={""}
                state_name={"product_image"}
                onChange={async (file) => {
                  const uri = await UI.get_uri_from_file(file);
                  this.setState(
                    {
                      image: uri,
                      image_file: file,
                      did_upload: true,
                    } /* ,
                    () => {
                      this.auth_upload();
                    } */
                  );
                }}
                height={120}
                component={
                  <ImageBackground
                    style={{ height: 120, width: 120, borderRadius: 10 }}
                    source={image}
                    imageFit={ImageFit.CONTAIN}
                  />
                }
              />
            </View>
            {UI.box(20)}

            <View style={{ flex: 1.5, justifyContent: "center" }}>
              <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
              >
                {did_upload
                  ? "Profile Picture Uploaded."
                  : "No Profile Picture Uploaded."}{" "}
                Recommended Size: 500 px x 500 px (Optional, for Hospital Admin
                Edit)
              </Text>
              {UI.box(10)}

              <Layout.Btn
                color={UI.colors.primary}
                text={"Upload Product Picture"}
                onClick={() => {
                  this.upload_picture();
                }}
                width={200}
              />
            </View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={1100} _this={_this}>
            <View style={{ flex: 1 }} id={"hospital_admin"}>
              <InputForm
                _this={_this}
                title={"Hospital Admin Name"}
                placeholder={"Enter Hospital Admin Name"}
                state_name={"name"}
                style={{ flex: 1 }}
                isRequired={false}
                maxLength={UI.Constants.limits.name}
              />
            </View>
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Email Address (Used as username)"}
              placeholder={"Enter Email Address"}
              state_name={"email"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.email}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Contact Number"}
              placeholder={"Enter Contact Number"}
              state_name={"phone"}
              style={{ flex: 1 }}
              isNumber={true}
              prefix={"+63"}
              isRequired={false}
              maxLength={10}
            />
          </UI.Row>
          {UI.box(20)}

          <UI.Row style={{ width: "100%" }} breakpoint={1130} _this={_this}>
            <UI.Row
              breakpoint={1130}
              style={{
                width: "100%",
              }}
              _this={_this}
            >
              <Layout.SelectPicker
                _this={_this}
                title={"Hospital Name"}
                isRequired={true}
                state_name={"hospital"}
                isMultiple={false}
              >
                <option value="">Select Hospital</option>
                {hospitals.map((item) => {
                  return <option value={item._id}>{item.name}</option>;
                })}
              </Layout.SelectPicker>
              {UI.box(10)}
              <View style={{ width: 280 }}>
                <Layout.SelectPicker
                  _this={_this}
                  title={"User Status"}
                  isRequired={false}
                  state_name={"status"}
                >
                  <option value=""></option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Layout.SelectPicker>
              </View>
              <View style={{ flex: 1 }}></View>
            </UI.Row>
          </UI.Row>
          {UI.box(20)}
          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"ADD HOSPITAL ADMIN"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  ai_fl_100: {
    alignItems: "center",
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
