import React, { useState } from 'react'
import UI from '../../shared/Components/UI/js'
import { Image } from '../../shared/custom-react-native'
import FormItem from '../ui/forms/FormItem'
import Text from '../ui/Text'
import InputField from '../ui/forms/InputField'
import { Button, Col, Form, Row } from 'antd'
import styled from 'styled-components'
import InputPasswordField from '../ui/forms/InputPasswordField'
import { Link } from 'react-router-dom'
import Loading from '../../modules/_components/Loading'
import SuccessModal from '../ui/modals/SuccessModal'
import ProcessFailedModal from '../ui/modals/ProcessFailedModal'
import ServiceAuthApi from '../../api_services/auth'
import ProcessFailedModalCrossIcon from '../../icons/ProcessFailedModalCross'
import SuccessModalCheckIcon from "../../icons/SuccessModalCheck";
import { Image as AntdImage } from 'antd';
import jwt from 'jsonwebtoken'

const EmailSignupForm = ({ _this }) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const submitSignUp = (e) => {    
    const payload = {
      ...e,
      role: "provider",
    };

    setIsScreenLoading(true);
    ServiceAuthApi.signupUsingEmail(payload).then(res => {
      if (res.status === 200) {
        setIsScreenLoading(false);
        // UI.goTo(`/${"onboard"}/otp?userId=${res.user}&loginType=email-signup&email=${e.email}&token=${jwt.sign(e.password,'secret')}`);
        UI.goTo(`/${"onboard"}/otp?loginType=email-signup&credentials=${jwt.sign({...payload, userId: res.user},'secret')}`);
      } else {
        setIsScreenLoading(false);
        setIsFailed(true);
        setErrorMessage(res.message);
      }
    });
  };

  return (
    <>
      <Loading
        visible={isScreenLoading}
        title={""}
        text={`Signing Up...`}
        modal_opaque={false}
      />
      <SuccessModal
        title={"Success!"}
        banner={<SuccessModalCheckIcon />}
        description={""}
        buttonText={"Done"}
        buttoncolor={"#004f99"}
        visible={isSuccess}
        width={530}
        onOk={() => setIsSuccess(false)}
        onCancel={() => setIsSuccess(false)} // x and cancel button
      />
      <ProcessFailedModal
        title={"Process Failed"}
        banner={<ProcessFailedModalCrossIcon />}
        description={errorMessage}
        buttonText={"Dismiss"}
        buttoncolor={"#004f99"}
        visible={isFailed}
        onOk={() => setIsFailed(false)}
        onCancel={() => setIsFailed(false)} // x and cancel button
      />
      <Col>
        <Link to="/onboard/email-login">
          <Row className='cursor-pointer'>
            <Image
              height={20}
              width={20}
              tintColor={"#999999"}
              source={UI.CHEVRON_LEFT}
            />
            <Text level={5} style={{color: "#999999"}}>Cancel</Text>
          </Row>
        </Link>
        <Col className='flex flex-col items-center gap-5'>
          <Col className='flex flex-col items-center gap-5'>
            <AntdImage src={UI.MWELL_LOGO_1} preview={false} alt="login_logo"/>
            <Text level={4}>Sign-Up with your email address</Text>
          </Col>
          <StyledForm
            layout={"vertical"}
            onFinish={(e) => {
              submitSignUp(e)
            }}
          >
            <FormItem 
              name="email"
              style={{ display: "flex", alignSelf: "end"}}
              rules={[
                {
                  type: "email",
                  message: "Please input a valid email",
                },
                {
                  required: true,
                  message: "Email Address is required",
                },
              ]}
            >
              <StyledInputField 
                style={{ width: "100%" }}
                placeholder="Email Address"
              />
            </FormItem>
            <FormItem 
              name="password"
              style={{ display: "flex", alignSelf: "end"}}
              rules={[
                {
                  required: true,
                  message: "Password is required",
                }
              ]}
            >
              <StyledInputPasswordField placeholder="Password" />
            </FormItem>

            <FormItem 
              name="cpassword"
              dependencies={['password']}
              // hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <StyledInputPasswordField placeholder="Confirm Password" />
            </FormItem>

            <FormItem>
              <StyledButton
                id="submit-button"
                htmlType="submit"
              >
                SIGNUP
              </StyledButton>
            </FormItem>

          </StyledForm>
        </Col>
      </Col>
    </>
    
  )
}

export default EmailSignupForm

const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledInputField = styled(InputField)`
  font-size: 18px;
  font-weight: bold;
`
const StyledInputPasswordField = styled(InputPasswordField)`
  .ant-input {
    font-size: 18px !important;
  }
`

const StyledButton = styled(Button)`
  background-color: #004F99;
  border-radius: 12px;
  width: 100%;
  height: 70px;

  color: #ffffff;
  font-weight: 600;
  font: normal normal medium 18px/19px Avenir LT Pro;
  font-size: 18px;

  &:hover,
  &:active,
  &:focus {
    background-color: #00cbff;
    color: #ffffff;
    border-color: #00cbff;
  }
`;