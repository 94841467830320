import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
// import APIConstants from "../../shared/modules/APIConstants";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { 
  // Failed,
   Success } from "../_components/Modal";
import MedicalAssociationApi from "../../api_services/medical_associations";

const context = "admin";
// const Constants = new APIConstants();

export default class AdminCmsMedicalAssociationsEdit extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "App Content",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,

      name: "",
      id: "",

    });
  }

  onCreate = async () => {
    this.load_data();
  };

  load_data = async () => {
    const data = mem.get("edit_medical_association");
    if (data) {
      const record = JSON.parse(data);

      UI.set_input_value("name", record.name ?? "");
      this.setState({id: record._id})
  
    }
  };

  submit = async () => {
    UI.clear_errors();
    const { name  } = this.state;

    if (!name) {
      await UI.error_form("name", "Medical Association Name is required.");
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    const payload = {
      name,
    }

    this.show_loading();

    MedicalAssociationApi.updateMedicalAssociation(this.state.id, payload).then( res => {
      this.hide_loading();
      if (res.status === 200) {
        // let data = res;
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully updated item"}
            onDismiss={() => {
              this.hide_modal();
              UI.goBack();
            }}
          />
        )
      }
    });
    // UI.webRequest({
    //   method: "put",
    //   url: "admin/lgu",
    //   params: {
    //     name: name,
    //     // status: status,
    //   },
    //   isMultiPart: true,
    //   onFail: (err) => {
    //     console.log(err);
    //     const data = err.data;
    //     const error_message = data.error;
    //     this.show_modal(
    //       <Failed
    //         title={"Failed"}
    //         description={error_message}
    //         onDismiss={() => {
    //           this.hide_modal();
    //         }}
    //       />
    //     );
    //   },
    //   onSuccess: (response) => {
    //     console.log(response);
    //     UI.clear_forms();
    //     this.show_modal(
    //       <Success
    //         title={"Success!"}
    //         description={"Successfully Updated the Specialty"}
    //         onDismiss={() => {
    //           UI.clear_forms();
    //           UI.goBack();
    //           this.hide_modal();
    //         }}
    //       />
    //     );
    //   },
    //   onFinish: async () => {
    //     this.hide_loading();
    //   },
    // });
  };

  render() {
    const _this = this;

    return (
      <MainLayout context={context} additional={"medical-associations"} _this={_this}>
        <View style={{...styles.main_box_content, marginBottom: 30}}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Update Medical Association
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add item
            </Text>
          </UI.Row>

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Medical Association Name"}
              placeholder={"Enter Medical Association Name"}
              state_name={"name"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.name}
            />
            {UI.box(10)}
            {/* <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={true}
              state_name={"status"}
            >
              <option value="">Item Status (Active or Inactive)</option>
              <option value="Active" selected={this.state.status === "Active" ? true : false}>Active</option>
              <option value="Inactive" selected={this.state.status === "Inactive" ? true : false}>Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)} */}
            <View style = {{flex: 1}}></View>
            <View style={{ flex: 1 }}></View>
          </UI.Row>

          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.secondary}
              onClick={() => {
                this.submit();
              }}
              text={"UPDATE"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>

      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});
