import Icon from "@ant-design/icons";
import React from "react";

const DataTableDelete = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <g id="delete" transform="translate(-0.5)">
      <rect
        id="Rectangle_1914"
        data-name="Rectangle 1914"
        width="50"
        height="50"
        rx="11.5"
        transform="translate(0.5)"
        fill="#d10000"
      />
      <path
        id="delete-2"
        data-name="delete"
        d="M354.088,301.105l-.908-19.242H351.7a1.168,1.168,0,1,1,0-2.336h5.925v-2c0-2.042,1.307-3.256,3.509-3.256H366.2c2.215,0,3.509,1.214,3.509,3.256v2h5.938a1.168,1.168,0,1,1,0,2.336h-1.468l-.907,19.229a3.217,3.217,0,0,1-3.456,3.3H357.558A3.2,3.2,0,0,1,354.088,301.105Zm15.439.921a1.283,1.283,0,0,0,1.321-1.321l.868-18.842H355.61l.894,18.842a1.3,1.3,0,0,0,1.308,1.321ZM358.825,299l-.4-13.984a.834.834,0,0,1,.907-.881.866.866,0,0,1,.921.881l.4,13.984a.835.835,0,0,1-.908.88A.866.866,0,0,1,358.825,299Zm1.255-19.47h7.179v-1.881a1.106,1.106,0,0,0-1.228-1.135h-4.723a1.106,1.106,0,0,0-1.228,1.135ZM362.749,299V285.013a.936.936,0,0,1,1.868,0V299a.936.936,0,0,1-1.868,0Zm3.949,0,.414-13.984a.866.866,0,0,1,.921-.881.84.84,0,0,1,.907.881L368.527,299a.855.855,0,0,1-.908.88A.845.845,0,0,1,366.7,299Z"
        transform="translate(-338.525 -264.271)"
        fill="#fff"
      />
    </g>
  </svg>
);

const DataTableDeleteIcon = (props) => {
  return <Icon component={DataTableDelete} {...props} />;
};

export default DataTableDeleteIcon;
