
import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import "./css.css";
// import Loading from "./Loading";

const zIndex = 10003;
export default class ImageViewerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speed: 1.1,
    };
  }

  componentDidMount = () => {};

  render() {
    let props = this.props;
    let { visible, _this } = props;
    return (
      <View
        style={{
          display: visible ? "flex" : "none",
          ...styles.modal_container,
        }}
      >
        {_this.state.imageViewerModalContent}
      </View>
    );
  }
}

export class ImageViewer extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    let props = this.props;
    const initial = { 
      backgroundColor: "white",
      // borderRadius: "18px",
      ...Shadow._3(),
      zIndex: zIndex + 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      overflowY: "auto",
      alignItems: "center",
      // padding: 20,
      width: 600,
      maxWidth: 1200,
    };

    return (
      <View style={{
        ...initial,
      }}>
        <div style={{width:'100%'}}>
          {
            props.title ?
              <View
                style={{
                  ...styles.md_header,
                  ...props.titleStyles ?? "",
                }}
                >
                <TouchableOpacity
                  onClick={() => {
                    if (props.onCancel.method) {
                      props.onCancel.method();
                    }
                  }}
                  style={styles.md_close}
                >
                  <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="close" />
                </TouchableOpacity>

                <View style={styles.md_header_text_container}>
                  <Text style={styles.md_header_text}>{this.props.title}</Text>
                </View>
                {UI.box(60)}
              </View>
            : ""
          }
          <View style={{width: "100%", padding: 30}}>
            <img style={styles.imageStyle} src={props.content} alt="content"/>
          </View>
        </div>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: "#035099",
    // borderTopRightRadius: 10,
    // borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: "black",
  },
  imageStyle: {
    alignItems: "center",
    height: "auto",
    width: "100%",
    maxWidth: 800,
  },
  modal_container: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    zIndex: zIndex,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    // backgroundColor: "rgba(255,255,255,0.85)",
  },
});
