import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import CustomDatatable from "../_components/CustomDatatable";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import "./css.css";
const context = "merchant";

export default class EcommerceViewInventory extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Inventory",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      all_data: [],
      context: context,

      image: UI.PRODUCT_BOX,
      image_file: null,

      quantity: "",
      status: "",

      item: {
        _id: "",
        merchant: "",
        name: "",
        price: "",
        sku: "00000",
        addQuantity: 0,
        quantity: 0,
        limit: 0,
        description: "",
        imagePath: "",
        imageFilename: "",
        createdAt: "",
        __v: 0,
        status: "",
      },
      product_image: UI.SAMPLE_PRODUCT,

      id: "",
    });
  }

  onCreate = async () => {
    this.load_data();
  };

  load_data = async () => {
    this.show_loading();
    const data = mem.get("edit_inventory");
    if (data) {
      const product = JSON.parse(data);

      UI.set_input_value("quantity", product.quantity ?? 0);
      UI.set_select_value("status", product.status ?? "");

      const image = await UI.download_image(product.imagePath, "ecommerce");

      this.setState({
        imagePath: product.imagePath,
        id: product._id,
        image,
        item: product,
        all_data: product.stockAudit,
      });
      this.reload_table("inventory");
    }
    this.hide_loading();
  };
  submit = async () => {
    UI.clear_errors();
    //no merchant yet
    const {
      quantity,
      // status,
      addQuantity,
    } = this.state;
    // const specialChars = `/[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]+/;`;
    const textChars = `abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`;
     // /^[A-Za-z ]*$/.test(addQuantity)
    // const isSpecialChars = specialChars
    //   .split("")
    //   .some((char) => addQuantity.includes(char));

    // **Checks if addQuantity contains special chars
    // console.log(parseInt(addQuantity))
    // const isSpecialChars = /^[A-Za-z0-9 ]*$/.test(parseInt(addQuantity))
    const isTextChars = textChars
      .split("")
      .some((char) => addQuantity.includes(char));

    if (!quantity) {
      // await UI.error_form("quantity", "Quantity is required.");
    }

    // if (isSpecialChars) {
    //   await UI.error_form("addQuantity", "Characters is not allowed.");
    // }

    if (isTextChars) {
      await UI.error_form("addQuantity", "Text is not allowed.");
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    this.show_loading();

    UI.webRequest({
      method: "put",
      url:
        "admin/products/quantity-adjustment/" +
        this.state.id +
        "/?inventoryAdjustment=" +
        parseInt(this.state.addQuantity ?? 0),
      // params: {
      //   inventoryAdjustment: parseInt(this.state.addQuantity) ,
      // },
      // isMultiPart: true,
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.error.replace("A", " a").concat(".");
        if (data.success === false) {
          this.show_modal(
            <Failed
              title={"Failed"}
              description={error_message}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        }
      },
      onSuccess: async (response) => {
        UI.webRequest({
          method: "put",
          url: "admin/products/" + this.state.id,
          params: {
            sku: this.state.item.sku,
            // quantity: this.state.addQuantity.includes("-") ? quantity - parseInt(this.state.addQuantity.split('-')[1]) : quantity + parseInt(this.state.addQuantity)  ?? 0,
          },
          onSuccess: async (response) => {},
        });
        UI.clear_forms();

        const data = UI.extract_data(response);
        this.setState({
          // image: UI.PRODUCT_BOX,
          // id: dataproduct._id,
          // image: data.imagePath,
          item: data,
          all_data: data.stockAudit,
        });
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully updated the product."}
            onDismiss={() => {
              // this.load_data()
              mem.save("view_inventory", JSON.stringify(data));
              UI.set_input_value(
                "quantity",
                this.state.addQuantity.includes("-")
                  ? quantity - parseInt(this.state.addQuantity.split("-")[1])
                  : quantity + parseInt(this.state.addQuantity) ?? 0
              );
              UI.set_input_value("addQuantity", "");
              this.hide_modal();
              this.reload_table("inventory");
              // window.history.back();
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;
    const {
      //  height,
      width,
      //  isMobile,
      image,
      item,
      //  mockStocks
    } = _this.state;

    // let product_id = 0;
    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                borderRightWidth: width >= 1250 ? 2 : 0,
                borderBottomWidth: width < 1250 ? 2 : 0,
                paddingRight: width < 1250 ? 0 : 20,
                marginRight: width < 1250 ? 0 : 20,
                paddingBottom: 10,
                marginBottom: width < 1250 ? 10 : 0,
              }}
            >
              <View style={styles.fl_ai}>
                <img
                  style={{ height: 100, width: 100, borderRadius: 5 }}
                  src={image}
                  alt=""
                />

                {UI.box(width < 980 ? 5 : 10)}

                <View>
                  <Text style={styles.patient_name}>{item.name}</Text>
                  <Text
                    style={{
                      ...styles.patient_name,
                      color: UI.colors.primary,
                      fontSize: 14,
                    }}
                  >
                    Php {item.price}
                  </Text>
                  <Text
                    style={{
                      ...styles.patient_name,
                      color: "#454545",
                      fontSize: 14,
                    }}
                  >
                    Status: {UI.get_active_text(item)}
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <View style={{ flex: 1 }}>
                <UI.Row breakpoint={970} _this={_this} style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.PAYMENT_METHOD}
                    name={"Sale Price"}
                    value={"Php " + item.price}
                  />
                  <UserDetail
                    icon={UI.COMMERCE_INVENTORY}
                    name={"Inventory"}
                    value={UI.pad(item.quantity ?? 0, 3)}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"Product SKU"}
                    value={item.sku} // TO-DO: endpoint for product sku
                  />
                </UI.Row>
              </View>
              <View style={{ flex: 1 }}>
                <UI.Row breakpoint={970} _this={_this} style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.PAYMENT_METHOD}
                    name={"Category"}
                    value={UI.pad(item.limit ?? 0, 3)}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"Date Added"}
                    value={UI.get_date_string_by_date(item.createdAt)}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"Date Created"}
                    value={UI.get_date_string_by_date(item.createdAt)}
                  />
                </UI.Row>
              </View>
              <View style={{ flex: 1 }}></View>
            </View>
          </UI.Row>
        </View>

        {UI.box(20)}

        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Edit Product Inventory
            </Text>

            <Text style={styles.text_2}>Enter product stocks here</Text>
          </UI.Row>

          {UI.box(20)}

          <View style={{ flexDirection: "row", width: "100%" }}>
            <InputForm
              _this={_this}
              title={"Product Quantity"}
              placeholder={"0"}
              state_name={"addQuantity"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.quantity}
              isNumber={true}
            />

            {UI.box(20)}
            <InputForm
              _this={_this}
              title={"Total Quantity"}
              placeholder={"000"}
              state_name={"quantity"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.quantity}
              isNumber={true}
              disabled={true}
            />
            {UI.box(20)}
            <View style={{ flex: 1 }}></View>
          </View>

          {UI.box(20)}

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.header}
              onClick={() => {
                this.submit();
              }}
              text={"SAVE CHANGES"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={850}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                {/* TO-DO: fix entry functionality . */}
                <EntryPicker table_name={"inventory"} _this={_this} />
              </View>
            </View>
          </UI.Row>

          <CustomDatatable
            state_name={"inventory"}
            _this={_this}
            columns={[
              { Date: "date_changed" }, // TO-DO: Finalize: end points
              { "Stock Added / Deducted": "stock_added_deducted" },
            ]}
            data={this.state.all_data.map((item) => {
              let date_added = UI.timestampToDate(
                new Date(item.dateAdjustment).getTime()
              );

              let quantity = item.newQuantity;
              if (!quantity) {
                quantity = 0;
              }

              quantity = Number.parseInt(item.newQuantity);

              // product_id++;
              // const id = UI.pad(product_id, 12);
              return {
                date_changed:
                  date_added.month +
                  "/" +
                  date_added.day +
                  "/" +
                  date_added.year,
                stock_added_deducted: <Text>{quantity}</Text>,
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const UserDetail = (props = { icon: "", name: "", value: "" }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
        flex: 1,
      }}
    >
      <View style={{ ...styles.fl_ai, width: 140 }}>
        <View style={styles.detail_icon}>
          <Image
            height={16}
            width={16}
            tintColor={UI.colors.primary}
            source={props.icon}
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  product_stat_item: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  product_stats_text: {
    color: UI.colors.primary,
    fontWeight: "bold",
    fontSize: 32,
    marginLeft: 15,
    flex: 1,
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
