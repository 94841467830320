import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import MainLayout from "../_components/MainLayout";
// import { Failed } from "../_components/Modal";
import ReferralTable from "./ReferralTable";
import ServiceMarketingsApi from "../../api_services/marketings";

const context = "marketing";

export default class MarketingDoctorReferralsView extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Doctor Referrals",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
      user:null,
      stats: {
        totalCodeReferrals: "",
        totalQrReferrals: "",
        totalReferrals: "",
        totalReferrers: "",
      }
    });
  }

  onCreate = () => {
    this.getUserData();
    this.getUserReferralStatsUnderSpecificUser();
  };

  getUserData = () => {
    const data = JSON.parse(mem.get('doctor_referral_view'))
    this.setState({ 
      user: data,
      avatar: data?.imageFilename?.includes('https') ? data?.imageFilename : data?.imageFilename ? UI.getUserAvatarLink(data?.imageFilename) : UI.DEFAULT_AVATAR_doctor
    })
  }

  getUserReferralStatsUnderSpecificUser = () => {
    try {
      this.show_loading();
      const data = JSON.parse(mem.get('doctor_referral_view'))
      console.log(data)
      ServiceMarketingsApi.getUserReferralStatsUnderSpecificUser(data._id).then((res) => {
        if (res.status === 200) {
          this.hide_loading();
          this.setState({ stats: res})
        } 
      });
    } catch (err) {
      // Handle Errors Here
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  };

  render() {
    const _this = this;
    return (
      <MainLayout context={context} _this={_this}>
        <UI.Row
          _this={_this}
          breakpoint={1080}
          style={{  flexDirection: "row", gap: 20 }}
        >
          <View style={{ gap: 20, flex: 1 }}>
            <View style={{
              backgroundColor: "white",
              borderRadius: 5,
              gap:10,
              ...Shadow._3(),
              padding: 20,
            }}>
              <Text style={styles.box_header_text}>
                Doctor Details
              </Text>
              <View style={styles.earnings_container}>
                <img
                  className=''
                  style={{ height: 100, width: 100, borderRadius: 5 }}
                  src={UI.DEFAULT_AVATAR_DOCTOR}
                  alt="N/A"
                  onError={(e) => {
                    if (e) {
                      this.setState({ avatar: UI.DEFAULT_AVATAR_DOCTOR });
                    }
                  }}
                />
                {UI.box(10)}
                <View style={{ flex: 1, minWidth: 160, justifyContent:'center', gap: 5 }}>
                  <Text style={{...styles.header_1, fontSize: 18}}>{this.state.user?.name ?? "- -"}</Text>
                  {/* <Text style={styles.header_1}> Region: <strong style={{ color:UI.colors.primary }}> {this.state.user?.region ?? "- -"} </strong>  </Text>
                  <Text style={styles.header_1}> City: <strong style={{ color:UI.colors.primary }}> {this.state.user?.city ?? "- -"} </strong>  </Text> */}
                  <Text style={styles.header_1}> Date Registered: <strong style={{ color:UI.colors.primary }}>{this.state.user?.registeredDate ? UI.momentPHTimezone(this.state.user?.registeredDate).format('MM/DD/YYYY') : "--"}</strong></Text>
                </View>
              </View>
            </View>

            <View
              style={{
                ...styles.main_box_content,
                padding: 20,
                // flex: 1,
              }}
            >
              <UI.Row _this={_this}>
                <Text style={styles.box_header_text}>Referral Stats</Text>
              </UI.Row>

              <ReferralStat
                image={UI.IC_DASH_18}
                main={UI.pad(this.state.user?.referralCount ?? 0, this.state.user?.referralCount === 0 ? 1 : 3)}
                color={UI.colors.primary}
                text={"Total Number of Referrals"}
              />
            </View>
          </View>
          <ReferralTable _this={_this}/>
        </UI.Row>
      </MainLayout>
    );
  }
}

const ReferralStat = (
  props = { image: null, main: "", color: "", text: "" }
) => {
  return (
    <View style={styles.referral_stat_item}>
      <Image
        height={70}
        width={70}
        tintColor={"transparent"}
        source={props.image}
      />
      <Text style={{ ...styles.referral_stats_text, color: props.color }}>
        {props.main}
      </Text>

      <Text
        style={{
          ...styles.stats_name,
          color: "#535353",
          fontSize: 14,
          textAlign: "right",
          maxWidth: 130,
        }}
      >
        {props.text}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  referral_stat_item: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  referral_stats_text: {
    color: UI.colors.primary,
    fontWeight: "bold",
    fontSize: 32,
    marginLeft: 15,
    flex: 1,
  },
  stats_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    flexDirection: "row",
    maxHeight: 180,
  },
});
