import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import APIConstants from "../../../../shared/modules/APIConstants";
// import moment from "moment";
import { Row, Col, Button, Image } from "antd";
import styled from "styled-components";
import UI from "../../../../shared/Components/UI/js";
import Container from "../../../../ComponentsFC/ui/Container";
import FieldIdIcon from "../../../../icons/FieldId";
import FieldDateIcon from "../../../../icons/FieldDate";
import FieldQualificationIcon from "../../../../icons/FieldQualification";
// import toTitleCase from "../../../../utils/toTitleCase";
// import ServiceAdminDoctorAccountsApi from "../../../../api_services/Admin/doctor-accounts-api";
// import FileDownload from "js-file-download";
// import Loading from "../../../../modules/_components/Loading";

const Constants = new APIConstants();
const imageStorage = Constants.STORAGE_BASE_URL;
const imageContainer = "doctors-onboarding";
// const new_src =
//         Constants.STORAGE_BASE_URL +
//         "?path=" +
//         path +
//         "&container=" +
//         container;

const DeletedDoctorsList = () => {
  const history = useHistory();
  const location = useLocation();
  const [details, setDetails] = useState();

  useEffect(() => {
    setDetails(location.state.data);
  }, [location.state.data]);

  return (
    <>
      {/* <Loading
        visible={isScreenLoading}
        title={""}
        text={""}
        modal_opaque={false}
      /> */}
      <Container>
        {/* ANTD ROW 24 GRID LAYOUT 8x8x8 (span) = 24 */}
        <RowWrapper>
          <FirstColumn>
            <Row>
              <Col style={{ marginRight: 25 }}>
                <StyledImage
                  src={
                    details?.picture
                      ? `${imageStorage}?path=${details?.doctor?.picture}&container=${imageContainer}`
                      : UI.DEFAULT_AVATAR_DOCTOR
                  }
                  preview={false}
                  width={141}
                  height={144}
                />
              </Col>
              <StyledColWrapper>
                <StyledDoctorsName>
                  {/* {`${details?.name[0]?.given.join(" ")} ${
                     details?.name[0]?.family
                   }`} */}
                  {`${details?.name}`}
                </StyledDoctorsName>
                <StyledDoctorsContactDetails>
                  {/* {details?.telecom[1]?.value ?? "--"} */}
                  {`${details?.email}`}
                </StyledDoctorsContactDetails>
                <StyledDoctorsContactDetails>
                  {details?.doctor?.telecom[0].value.length > 0
                    ? `+63 ${details?.doctor?.telecom[0]?.value}` ?? "--"
                    : "No contact number filed"}
                </StyledDoctorsContactDetails>
              </StyledColWrapper>
            </Row>
          </FirstColumn>

          <SecondColumn>
            <Row>
              <Col style={{ marginRight: 120 }}>
                <FieldWrapper>
                  <Col>
                    <FieldLabel>
                      <FieldIdIcon
                        style={{ marginRight: 20, verticalAlign: 0 }}
                      />
                      PTR Number
                    </FieldLabel>
                    <FieldLabel>
                      <FieldDateIcon
                        style={{ marginRight: 20, verticalAlign: 0 }}
                      />
                      S2 Number
                    </FieldLabel>
                    <FieldLabel>
                      <FieldDateIcon
                        style={{ marginRight: 20, verticalAlign: 0 }}
                      />
                      PRC License Number
                    </FieldLabel>
                    <FieldLabel>
                      <FieldDateIcon
                        style={{ marginRight: 20, verticalAlign: 0 }}
                      />
                      Specialization
                    </FieldLabel>
                  </Col>
                  <Col style={{ marginLeft: 39 }}>
                    <FieldValue>
                      {details?.doctor?.ptrNumber === "" ||
                      !details?.doctor?.ptrNumber
                        ? "--"
                        : details?.doctor?.ptrNumber}
                    </FieldValue>
                    <FieldValue>
                      {details?.doctor?.s2Number === "" ||
                      !details?.doctor?.s2Number
                        ? "--"
                        : details?.doctor?.s2Number}
                    </FieldValue>
                    <FieldValue>
                      {details?.doctor?.prcLicenseNumber === "" ||
                      !details?.doctor?.prcLicenseNumber
                        ? "--"
                        : details?.doctor?.prcLicenseNumber}
                    </FieldValue>
                    {details?.doctor?.fieldSpecialties.length > 0
                      ? details?.doctor?.fieldSpecialties?.map((specialty) => {
                          if (specialty) {
                            return (
                              <FieldValue>
                                {details?.doctor?.fieldSpecialties?.length > 1
                                  ? `${specialty},`
                                  : specialty}
                              </FieldValue>
                            );
                          } else {
                            return "--";
                          }
                        })
                      : "--"}
                  </Col>
                </FieldWrapper>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <FieldLabel>
                      <FieldQualificationIcon
                        style={{ marginRight: 20, verticalAlign: 0 }}
                      />
                      E-sign
                    </FieldLabel>
                  </Col>
                  <Col>
                    <ESignImage
                      src={
                        details?.doctor?.signature
                          ? `${imageStorage}?path=${details?.doctor?.signature}&container=${imageContainer}`
                          : UI.DEFAULT_AVATAR_ESIGN
                      }
                      preview={false}
                      width={details?.doctor?.signature ? 236 : 166} //126+75%
                      height={details?.doctor?.signature ? 122 : 104.53} //64.53+61%
                      style={
                        details?.doctor?.signature ? {} : { marginTop: 25 }
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </SecondColumn>
        </RowWrapper>
      </Container>

      <StyledButton
        onClick={() => history.push("/admin/doctor-accounts/deleted-doctors")}
      >
        BACK TO LIST
      </StyledButton>
    </>
  );
};

export default DeletedDoctorsList;

// CSS Styled Components ----------------------

const RowWrapper = styled(Row)``;

const StyledImage = styled(Image)`
  border-radius: 12px;
`;

const FirstColumn = styled(Col)`
  width: 462.26px; // 497 (based on UI Design) - 35px (existing padding), 462.26px
  border-right: 3px solid #efefef;

  // Breakpoint where AntD Row wraps (tablets, 1755px) and down)
  @media only screen and (max-width: 1755px) {
    border-right: none;
    border-bottom: 3px solid #efefef;
  }
`;

const SecondColumn = styled(Col)`
  padding-left: 46.44px; //based on UI Design Spacing

  @media only screen and (max-width: 1755px) {
    padding-top: 20px;
  }

  // Breakpoint where AntD Row wraps (Larger Laptops, 1440px) and down)
  @media only screen and (max-width: 1440px) {
    padding-top: 20px;
    padding-left: 0;
  }
`;

const ESignImage = styled(Image)`
  margin-left: 80px;
`;

const StyledColWrapper = styled(Col)`
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledDoctorsName = styled.p`
  margin-bottom: 10px;
  font-size: 22px;
  font-family: Poppins;
  font-weight: 500;
  color: #535353;
`;

const StyledDoctorsContactDetails = styled.p`
  margin-bottom: 10px;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
  color: #898989;
`;
const StyledButton = styled(Button)`
  width: 193px;
  height: 60px;
  background-color: #0e4d94;
  border: 2px solid #0e4d94;
  border-radius: 12px;

  font-size: 20px;
  font-family: Poppins;
  font-weight: 500; // medium
  color: #ffffff;

  &:hover,
  &:focus,
  &:active {
    background-color: #0e4d94;
    color: #ffffff;
  }
`;

const FieldWrapper = styled(Row)`
  /* width: 400px; //382.25px */
`;

const FieldLabel = styled.p`
  margin-bottom: 10px;

  font-family: Poppins;
  font-size: 18px; //18px
  font-weight: 500;
  color: #4fc2ed;
`;

const FieldValue = styled.p`
  margin-bottom: 10px;

  font-family: Poppins;
  font-size: 18px; //18px
  font-weight: 500;
  color: #333333;
`;
