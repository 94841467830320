import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";

const context = "merchant";
export default class EcommerceInquiries extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Inquiries",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],

      context: context,

      product_names: [],
      customer_names: [],

      sort_customer: "",
      sort_method: "",
      sort_created: "",
    });
  }

  onCreate = () => {
    this.get_data({
      sort: "-createdAt",
    });
  };

  clear_filters = () => {
    this.get_data({
      // **It’s unnecessary to use computed properties with literals, no need [ ] to wrap obj prop (e.g. ["product.name"])
      "product.name": "",
      sort: "-createdAt",
      method: "",
    });
  };

  filter_by = (state, value) => {
    this.setState(
      {
        ["sort_" + state]: value,
      },
      () => {
        this.filter_all();
      }
    );
  };

  filter_all = () => {
    const { sort_customer, sort_product, sort_created } = this.state;
    this.get_data({
      // **It’s unnecessary to use computed properties with literals, no need [ ] to wrap obj prop (e.g. ["product.name"])
      "product.name": sort_product,
      "customer.name": sort_customer,
      sort: sort_created,
    });
  };

  get_data = async (params) => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/inquiries/merchant/me",
      params: params,
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.customers(data.data);
        this.products(data.data);

        let new_data = data.data.map((item) => {
          item.timestamp = new Date(item.createdAt).getTime();
          item.product_name = item?.product?.name;
          item.customer_name = item?.customer?.name;
          return item;
        });

        if (this.state.all_data.length === 0) {
          this.setState({
            all_data: new_data,
          });

          new_data.forEach((item) => {
            if (!this.state.product_names.includes(item.product?.name)) {
              this.setState({
                product_names: [
                  ...this.state.product_names,
                  item.product?.name,
                ],
              });
            }
            if (!this.state.customer_names.includes(item.customer?.name)) {
              this.setState({
                customer_names: [
                  ...this.state.customer_names,
                  item.customer?.name,
                ],
              });
            }
          });
        }

        this.reload_table("inquiries");
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  customers = async (data) => {
    UI.set_images(
      data,
      "customer.imagePath",
      "customer",
      "inquiries",
      "ecommerce"
    );
  };

  products = async (data) => {
    UI.set_images(
      data,
      "product.imagePath",
      "product",
      "inquiries",
      "ecommerce"
    );
  };

  sort_by = (format, key) => {
    //format can be asc or desc
    const { all_data } = this.state;

    all_data.sort(UI.dynamicSort(format === "asc" ? key : "-" + key));
    UI.set_input_value("search", "");

    this.setState(
      {
        data: all_data,
      },
      () => {
        this.reload_table("inquiries");
      }
    );
  };

  custom_sort = (key, value) => {
    const { all_data } = this.state;
    let items = all_data.filter((item) => item[key] + "" === value);
    UI.set_input_value("search", "");
    if (value) {
      this.reload_table("inquiries", items);
    } else {
      this.reload_table("inquiries", all_data);
    }
  };

  render() {
    const _this = this;
    const {
      // height,
      width,
      // isMobile
    } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <Text style={styles.box_header_text}>Product Inquiries</Text>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Product Name"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "product_name");
              }}
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Customer Name"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "customer_name");
              }}
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Inquiry Date"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "timestamp");
              }}
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={950}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"inquiries"} _this={_this} />
              </View>
            </View>

            <InputForm
              onChange={(e) => {
                UI.search_table("inquiries", e, this.state.all_data, "data");
              }}
              is_keyword={true}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
            />
          </UI.Row>

          <CustomDatatable
            state_name={"inquiries"}
            _this={_this}
            disable_pagination={false}
            columns={[
              // { ID: "id" },
              { "Customer Name": "customer_name" },
              { "Product Name": "product_name" },
              { Inquiry: "inquiry" },
              { "Inquiry Date": "inquiry_date" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              const date_added = UI.timestampToDate(
                new Date(item.createdAt).getTime()
              );

              const customer_image =
                this.state["customer" + item._id] ?? UI.TRANSACTION_USER;
              const product_image =
                this.state["product" + item._id] ?? UI.PRODUCT_BOX;
              return {
                id: item._id,
                customer_name: (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <img
                      style={{ height: 35, width: 35 }}
                      src={customer_image}
                      alt="Customer"
                    />
                    <Text style={{ marginLeft: 10 }}>
                      {item.customer.firstName} {item.customer.lastName}
                    </Text>
                  </View>
                ),
                product_name: (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <img
                      style={{ height: 35, width: 35 }}
                      src={product_image}
                      alt="Product"
                    />
                    <Text style={{ marginLeft: 10 }}>{item.product?.name}</Text>
                  </View>
                ),
                inquiry: item.description,
                inquiry_date:
                  date_added.month +
                  "/" +
                  date_added.day +
                  "/" +
                  date_added.year,
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        mem.save("view_inquiry", JSON.stringify(item));
                        UI.goTo("/merchant/view-inquiry");
                      }}
                    >
                      <Image
                        height={32}
                        width={32}
                        tintColor={"transparent"}
                        source={UI.VIEW_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
