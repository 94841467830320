import React from "react";
import styled from "styled-components";
import { Typography, Modal, Row } from "antd";
import ModalCloseIcon from "../../../icons/ModalClose";

const ProcessFailedModal = (props) => {
  return (
    <StyledModal
      {...props}
      title={props?.title}
      closeIcon={<ModalCloseIcon />}
      okText={props?.buttonText}
      buttoncolor={props?.buttoncolor}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      {props?.banner}
      <StyledBodyDescription>{props?.description}</StyledBodyDescription>
      {props?.extrajsx && <StyledExtraJSX>{props?.extrajsx}</StyledExtraJSX>}
    </StyledModal>
  );
};

export default ProcessFailedModal;

const StyledModal = styled(Modal)`
  font-family: Poppins;
  letter-spacing: 0px;

  .ant-modal-content {
    border-radius: 15px;
    .ant-modal-close {
      margin-top: 15px;
      margin-right: 21.5px;
    }
  }

  .ant-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b90c0c;
    border-radius: 15px 15px 0 0;
    padding: 28px 38px 26px 38px;
    height: 90px;
    .ant-modal-title {
      font-size: 1.625rem; // 26px
      color: #ffffff;
      letter-spacing: 0px;
      text-transform: uppercase;
    }
  }
  .ant-modal-body {
    text-align: center;
    font-size: 1.4375rem; //23px
    font-weight: 500;
    color: #004f99;
    padding: 55px 64px 33px 64px;
  }
  .ant-modal-footer {
    text-align: center;
    border-top: 0;
    padding-top: 0;
    padding-bottom: 55px;
    & .ant-btn-primary {
      color: #ffffff;
      font-size: 1.4375rem; //23px
      background-color: ${(props) =>
        props.buttoncolor ? props.buttoncolor : "#004f99"};
      border-radius: 12px;
      border-color: ${(props) =>
        props.buttoncolor ? props.buttoncolor : "#004f99"};
      width: 215px;
      height: 74px;
      :hover {
        background-color: #356a9c;
      }
    }
  }
`;

const StyledBodyDescription = styled(Typography.Paragraph)`
  margin-top: 39.51px;
  margin-bottom: 0 !important;
  color: #004f99;
`;

const StyledExtraJSX = styled(Row)`
  margin-top: 33px !important;
`;
