import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";
const context = "finance";

export default class FinanceAddPromo extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Promo CMS",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;
    // **Commented out, currently unused variables
    // const { height, width, isMobile } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Add New Promo
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to complete registration
            </Text>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <Layout.FilePicker
              _this={_this}
              title={""}
              additional={""}
              isRequired={false}
              text={"Upload Promo Banner"}
              state_name={"promo_banner"}
              onChange={(file) => {}}
              height={120}
            />
            {UI.box(20)}

            <View style={{ flex: 1.5, justifyContent: "center" }}>
              <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
              >
                No Promotional Picture Uploaded. Recommended Size: 1000 px x 500
                px
              </Text>
              {UI.box(10)}

              <Layout.Btn
                color={UI.colors.primary}
                text={"Upload Banner Picture"}
                onClick={() => {}}
                width={200}
              />
            </View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Promotion Title"}
              placeholder={"Enter Promotion Title"}
              state_name={"promotion_title"}
              style={{ flex: 1 }}
              isRequired={false}
            />
            {UI.box(10)}
            <Layout.DatePicker
              _this={_this}
              title={"Promotion Start Date"}
              isRequired={false}
              placeholder={"mm/dd/yyyy"}
              state_name={"promotion_start_date"}
            />
            {UI.box(10)}
            <Layout.DatePicker
              _this={_this}
              title={"Promotion End Date"}
              isRequired={false}
              placeholder={"mm/dd/yyyy"}
              state_name={"promotion_end_date"}
            />
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Promotion Limit"}
              placeholder={"No. of Promotions per Month"}
              state_name={"promotion_limit"}
              style={{ flex: 1 }}
              isRequired={false}
              isNumber={true}
            />
            {UI.box(10)}

            <Layout.SelectPicker
              _this={_this}
              title={"Promotion Status"}
              isRequired={false}
            >
              <option value="">Status (Active or inactive)</option>
            </Layout.SelectPicker>

            <View style={{ flex: 1 }}></View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Promotion Description"}
              placeholder={"Enter Description Here"}
              state_name={"promotion_description"}
              style={{ flex: 1 }}
              height={100}
              isRequired={false}
              type={"big"}
            />
          </UI.Row>
          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {}}
              text={"ADD PROMO"}
            />
            {UI.box(20)}

            <Layout.Btn color={"#E00000"} onClick={() => {}} text={"CANCEL"} />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
