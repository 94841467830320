import React from "react";
import UI from "../../../../shared/Components/UI/js";
import { StyleSheet, Text, View } from "react-pdf-renderer-v2";
import moment from "moment";

const Header = ({ appointment, title }) => {
  const patient = appointment.patient;

  const patientName =
    patient?.name[0].family +
    ", " +
    patient?.name[0].given +
    " " +
    (patient?.name[0].middle ?? "");

  const gender = patient.gender === "female" ? "F" : "M";

  return (
    <View style={styles.header} fixed>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 16,
          }}
        >
          <Text style={{ textTransform: "uppercase" }}>{patientName}</Text>
          <Text>
            DOB: {moment(patient.birthDate).format("MMM-DD-YYYY")} (
            {UI.getAge(patient.birthDate)}/{gender})
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text>
            Date:{" "}
            {UI.momentPHTimezone(appointment.start).format("MMM. DD, YYYY")}
          </Text>
          <Text>
            Time: {UI.momentPHTimezone(appointment.start).format("hh:mmA")}
          </Text>
        </View>
      </View>
      <Text style={styles.title}>{title}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    textAlign: "center",
    borderBottom: "3px solid black",
    borderTop: "3px solid black",
    paddingTop: 10,
    paddingBottom: 5,
    textTransform: "uppercase",
  },
  header: {
    fontSize: 14,
    fontWeight: "bold",
  },
});

export default Header;
