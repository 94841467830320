import React from "react";
import styled from "styled-components";
import { Input } from "antd";

const NumericInput = (props) => {
  const { onChange } = props;
  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^[0-9]+$/;

    if (!reg.test(inputValue)) onChange(""); //avoid bypassing Numeric Input if NaN
    if (reg.test(inputValue) || inputValue === "") {
      onChange(inputValue);
    }
  };

  return (
    <StyledInput
      {...props}
      onChange={handleChange}
    />
  );
};

export default NumericInput;

const StyledInput = styled(Input)`
  height: 60px;
  border-radius: 12px;
  border: 2px solid #DFE5EB;
  font-size: 1.25rem !important; // 20px
  font-family: Poppins;
  line-height: 1.25rem; // 23px
  font-weight: 500; //medium
  letter-spacing: 0px;

  .ant-input {
    font-size: 1.25rem !important; // 20px
    font-family: Poppins;
    line-height: 1.25rem; // 23px
    font-weight: 500; //medium
    letter-spacing: 0px;
  }

  ::placeholder {
    font-size: 1.25rem !important; // 20px
    font-family: Poppins;
    line-height: 1.25rem; // 23px
    font-weight: 500; //medium
    letter-spacing: 0px;
  }

  :disabled {
    color: #222222 !important;
    font-size: 1.25rem !important; // 20px
    font-family: Poppins;
    line-height: 1.25rem; // 23px
    font-weight: 500; //medium
    letter-spacing: 0px;
  }
`;
