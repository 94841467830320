import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";

const context = "finance";
export default class FinanceRefundTransaction extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Refunds",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      context: context,
      item: {
        id: "",
        createdAt: "",
        status: "",
        doctor_name: "",
        patient_name: "",
        bank_name: "",
        amount: 0,
        timestamp: 0,
      },
      amount: "",
    });
  }

  onCreate = () => {
    this.load_data();
  };

  load_data = async () => {
    const data = mem.get("edit_transaction");
    if (data) {
      const transaction = JSON.parse(data);

      UI.set_input_value("amount", transaction.amount);

      this.setState({
        item: transaction,
      });
    }
  };

  submit = async () => {
    UI.clear_errors();
    //no merchant yet

    const { amount } = this.state; // can be "true" or "false"

    if (!amount) {
      await UI.error_form("amount", "Amount is required.");
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    this.show_loading();

    setTimeout(() => {
      UI.goBack();
      this.hide_loading();
    }, 1000);
  };

  render() {
    const _this = this;
    const {
      // height,
      width,
      // isMobile,
      item,
    } = _this.state;

    // const {} = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <UI.Row style={{ width: "100%" }} _this={_this} breakpoint={1000}>
          <View
            style={{
              ...styles.main_box_content,
              width: width > 1000 ? 300 : "100%",
            }}
          >
            <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
              <Text style={{ ...styles.box_header_text, flex: 1 }}>
                Transaction Details
              </Text>
            </UI.Row>
            {UI.box(10)}

            <UserDetail
              icon={UI.DETAILS_GENDER}
              name={"Doctor Name"}
              value={item.doctor_name}
            />

            <UserDetail
              icon={UI.PAYMENT_METHOD}
              name={"Bank Name"}
              value={item.bank_name}
            />

            <UserDetail
              icon={UI.DETAILS_GENDER}
              name={"Patient Name"}
              value={item.patient_name}
            />

            <UserDetail
              icon={UI.DETAILS_AGE}
              name={"Date Added"}
              value={UI.get_date_string_by_timestamp(item.timestamp)}
            />
            <UserDetail
              icon={UI.CONSULT_PRICE}
              name={"Amount"}
              value={item.amount}
            />
          </View>
          {UI.box(20)}

          <View style={{ ...styles.main_box_content, flex: 1 }}>
            <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
              <Text style={{ ...styles.box_header_text, flex: 1 }}>
                Refund Transaction
              </Text>
            </UI.Row>

            {UI.box(20)}

            <View style={{ alignSelf: "flex-start", width: 280 }}>
              <InputForm
                _this={_this}
                title={"Amount To Be Refunded"}
                placeholder={"Enter Amount To Be Refunded"}
                state_name={"amount"}
                style={{ flex: 1 }}
                isNumber={true}
                isRequired={false}
                maxLength={UI.Constants.limits.price}
              />
            </View>

            {UI.box(20)}

            <View style={{ width: width > 720 ? 300 : "100%" }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Layout.Btn
                  color={UI.colors.primary}
                  onClick={() => {
                    this.submit();
                  }}
                  text={"SUBMIT"}
                />
                {UI.box(20)}

                <Layout.Btn
                  color={"#E00000"}
                  onClick={() => {
                    UI.goBack();
                  }}
                  text={"CANCEL"}
                />
              </View>
            </View>
          </View>
        </UI.Row>
      </MainLayout>
    );
  }
}
const UserDetail = (props = { icon: "", name: "", value: "" }) => {
  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}
    >
      <View style={{ width: 150, ...styles.fl_ai }}>
        <View style={styles.detail_icon}>
          <Image
            height={16}
            width={16}
            tintColor={UI.colors.primary}
            source={props.icon}
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
