import { Button, Checkbox, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import UI from '../../../../shared/Components/UI/js';
import jwt from "jsonwebtoken";
import TouchableOpacity, { Shadow, StyleSheet, Text, View } from '../../../../shared/custom-react-native';
import { Failed, Success } from '../../../_components/Modal';
import { Link } from 'react-router-dom';
// import SpecializationsnApi from "../../api_services/specializations.js";

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

const ReferPatient = ({_this}) => {
  const formRef = React.createRef();
  const [specializations, setSpecializations] = useState([])
  const [doctors, setDoctors] = useState([])

  const [referType, setReferType] = useState("")
  const [isCheckBox, setIsCheckBox] = useState("")

  const [referreringDoctorName, setReferreringDoctorName] = useState("")
  const [referreringDoctorEmail, setReferreringDoctorEmail] = useState("")
  const [referreringDoctorSpecialty, setReferreringDoctorSpecialty] = useState("")

  const [reasonForReferral, setReasonForReferral] = useState("")
  const [chiefComplaint, setChiefComplaint] = useState("")
  const [pertinentInformation, setPertinentInformation] = useState("")

  const [isSpecialtyPlaceHolderCleared, setIsSpecialtyPlaceHolderCleared] = useState(false);
  const [isDoctorNamePlaceHolderCleared, setIsDoctorNamePlaceHolderCleared] = useState(false);

  const get_specializations = (params) => {
    // _this.show_loading();
    
    UI.webRequest({
      method: "get",
      url: "/doctors_onboarding/doctors/specializations",
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        const res = data.map((res, key) => {
          const new_label = <div className='flex gap-3 items-center'><img src={res.icon} width={20} alt={res.label}/>{res.label}</div>
          res.value = (key+1).toString()
          res.title = res.label
          res.label = new_label
          return res
        })
        setSpecializations(res)
        _this.hide_loading();
      }
    });
  }

  const getDoctors = (specialty) => {
    UI.webRequest({
      method: "get",
      url: "/doctors_onboarding/referrals/doctors",
      params: {
        fieldSpecialties: specialty
      },
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        const res = data.map((res, key)=> {
          res.value = (key+1).toString()
          res.label = UI.DataHelper.carespan.get_name(res?.name)
          return res
        })
        setDoctors(res)
        _this.hide_loading();
      }
    });
  }

  useEffect(() => {
    get_specializations()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const referAdoctor = () => {
    const payload = {
      referralType: referType,
      appointmentId:_this.state.appointment?._id,
      reasonForReferral,
      chiefComplaint,
      pertinentInformation,
      referredDoctor: {
        email:referreringDoctorEmail,
        name:referreringDoctorName,
        specialization: referreringDoctorSpecialty
      },
    }

    const encode = jwt.sign(payload, 'secret').replace(/\//g,'slash').replace(/\+/g,'plus')

    UI.webRequest({
      method: "post",
      params: {
        encode
      },
      url: "/doctors_onboarding/referrals/refer",
      onFail: (err) => {
        const data = err.data;
        const error_message = data.m;
        _this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              _this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        // const data = UI.extract_data(response);
        _this.show_modal(
          <Success
          title={"Success!"}
          description={"Successfully Refer a doctor"}
          onDismiss={() => {
            _this.hide_modal();
          }}
        />)
        _this.hide_loading();
      }
    });
  }

  const handleToggleClearPlaceHolderDoctorName = () => {
    setIsDoctorNamePlaceHolderCleared(prevVal => !prevVal);
  }
    
  const handleToggleClearPlaceHolderSpecialty = () => {
    setIsSpecialtyPlaceHolderCleared(prevVal => !prevVal);
  }

  return (
    <div className="" style={{
      backgroundColor: "white",
      borderRadius: "18px",
      ...Shadow._3(),
      zIndex: 10003 + 1,
      overflowY: "auto",
      alignItems: "center",
      width: 550,
      maxWidth: 1200,
    }}>
      <div style={{width:'100%'}}>
        <View
          style={{
            ...styles.md_header,
            // ...props.titleStyles ?? "",
          }}
          >
          <TouchableOpacity
            onClick={() => {
              _this.hide_modal()
            }}
            style={styles.md_close}
          >
            <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt=""/>
          </TouchableOpacity>

          <View style={styles.md_header_text_container}>
            <Text style={styles.md_header_text}>REFER A PATIENT</Text>
          </View>
          {UI.box(60)}
        </View>

        <div className="p-5 flex flex-col gap-5" >
          <Form 
          ref={formRef}
            name="validate_other"
            style={{
              marginBottom: "10px !important",
              fontSize: 18, // 15px
              fontWeight: "bold",
              }} 
            validateMessages={validateMessages}
            onFinish={referAdoctor}
          >

          <StyledFormItem
            name="referType"
            label="Refer Type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <StyledSelect
              label="Refer Type"
              dropdownStyle={{zIndex:300000}}
              // showSearch
              style={{
                width: "100%",
              }}
              placeholder="Select Refer Type"
              onChange={(value) => setReferType(value)}
              options={[
                { label: 'Teleconsult', value: 'teleconsult' },
                { label: 'Face to Face Appointment', value: 'face-to-face' },
              ]}
            />
          </StyledFormItem>

          <StyledFormItem
            name="specialty"
            label="Specialty"
          >
            <StyledSelect
              label="Specialty"
              dropdownStyle={{zIndex:300000}}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder={isSpecialtyPlaceHolderCleared ? "" : "Search to Select"}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.title.toLowerCase() ?? '').includes(input.toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.title ?? '').toLowerCase().localeCompare((optionB?.title ?? '').toLowerCase())
              }
              onSelect={(value, item ,c) => {
                formRef.current.setFieldsValue({ name: undefined })
                setReferreringDoctorSpecialty(item.title)
                getDoctors(item.title)
              }}
              onFocus={handleToggleClearPlaceHolderSpecialty}
              onBlur={handleToggleClearPlaceHolderSpecialty}
              options={specializations}
            />
          </StyledFormItem>

          <StyledFormItem
            name="name"
            label="Doctor Name"
          >
            <StyledSelect
              label="Doctor Name"
              dropdownStyle={{zIndex:300000}}
              showSearch
              style={{
                width: "100%",
              }}
              placeholder={isDoctorNamePlaceHolderCleared ? "" : "Select Doctor Name"}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.label.toLowerCase() ?? '').localeCompare((optionB?.label.toLowerCase() ?? '').toLowerCase())
              }
              onSelect={(value, item ,c) => {
                setReferreringDoctorName(item.label)
                setReferreringDoctorEmail(item.provider?.email)
              }}
              onFocus={handleToggleClearPlaceHolderDoctorName}
              onBlur={handleToggleClearPlaceHolderDoctorName}
              options={doctors}
            />
          </StyledFormItem>

          <StyledFormItem 
            name="reasonForReferral"
            label="Reason For Referral"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <StyledInput
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Reason For Referral"
              style={{
                width: "100%",
              }}
              allowClear
              onChange={(e) => setReasonForReferral(e.target.value)}
            >
            </StyledInput>
          </StyledFormItem>
          
          <StyledFormItem 
            name="chiefComplaint"
            label="Chief Complaint"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <StyledInput
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Chief Complaint"
              style={{
                width: "100%",
              }}
              allowClear
              onChange={(e) => setChiefComplaint(e.target.value)}
            >
            </StyledInput>
          </StyledFormItem>

          <StyledFormItem 
            name="pertinentInformation"
            label="Pertinent Information"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <StyledTextArea 
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Enter Pertinent Information"
              allowClear
              onChange={(e) => setPertinentInformation(e.target.value)}
            />
          </StyledFormItem>

          <StyledFormItem name="remember" valuePropName="checked">
            <div  style={{ display: "flex" }}>
              <StyledCheckbox onChange={(e, a) => setIsCheckBox(e.target.checked)}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: '16px',
                    color: "#0E4D94",
                  }}
                >
                  By clicking this button, I agree to share all patient records with this referral form and understood the {" "}
                  <Link onClick={() => window.open("https://www.mwell.com.ph/privacy-policy", "_blank")}>
                    mWell Privacy Policy.
                  </Link>
                </Text>
              </StyledCheckbox>
              
            </div>
          </StyledFormItem>

            <div className='items-center flex justify-end'>
              <div className='mt-5'>
              <StyledFormItem >
                <StyledButton type="primary" htmlType="submit" 
                disabled={ isCheckBox ? false : true}
                >
                  Proceed
                </StyledButton>
              </StyledFormItem>
              </div>
            </div>

          </Form>
        </div>

      </div>
    </div>
  )
}

export default ReferPatient

const StyledFormItem = styled(Form.Item)`
  flex-direction: column !important;
  justify-content: flex-start !important;
  width:100%;
  margin-bottom: 5px !important;
  
 .ant-form-item-label {
    text-align: left !important;
  }

  .ant-form-item-label > label {
    color:rgb(4, 204, 255) !important;
  }
`;


const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border: 2px solid #0E4D94 !important;
    border-radius: 5px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #1890ff;
    border-radius: 5px !important;
    background-color: #0E4D94 !important;
  }
`;

const StyledButton = styled(Button)`
  height: 55px;
  width: 150px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px !important;
  align-items: center;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
`;

const StyledTextArea = styled(Input.TextArea)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 120px !important;
  align-items: center;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
`;

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    height: 50px !important;
    display: flex;
    align-items: center;
  }
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }
  
  .ant-select-selector input {
    height: 50px !important;
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

// const StyledFieldLabel = styled(Typography.Paragraph)`
//   margin-bottom: 10px !important;
//   font-size: 18; // 15px
//   font-weight: bold;
// `;

const styles = StyleSheet.create({
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: UI.colors.secondary,
    // borderTopRightRadius: 10,
    // borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
});