import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
// import { StyleSheet } from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import "./css.css";

export default class FirstForm extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const _this = this.props._this;
    const {
      // height,
      width,
      // isMobile,
      //  dob_date
    } = _this.state;
    const row_width = width > 1000 ? width - 400 : "100%";

    // let age = ""; //Age is 24 y/o
    // if (dob_date) {
    //   age = "Age is " + UI.get_age(dob_date) + " y/o";
    // }

    return (
      <UI.Column
        style={{ marginBottom: 10 }}
        _this={_this}
        breakpoint={1000}
        breakpoint_2={820}
      >
        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <InputForm
            _this={_this}
            title={"First Name"}
            placeholder={"First Name"}
            state_name={"first_name"}
            style={{ flex: 1 }}
            isRequired={true}
            maxLength={UI.Constants.limits.name}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Middle Name"}
            placeholder={"Middle Name"}
            state_name={"middle_name"}
            style={{ flex: 1 }}
            isRequired={false}
            maxLength={UI.Constants.limits.name}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Last Name"}
            placeholder={"Last Name"}
            state_name={"last_name"}
            style={{ flex: 1 }}
            isRequired={true}
            maxLength={UI.Constants.limits.name}
          />
        </UI.Row>
        {UI.box(20)}
        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <InputForm
            _this={_this}
            title={"Email Address"}
            placeholder={"Email Address"}
            state_name={"email"}
            style={{ flex: 1 }}
            isRequired={false}
            disabled={_this.state.onboard_data?.isEmailVerified}
            maxLength={UI.Constants.limits.email}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Contact Number"}
            placeholder={"Contact Number"}
            state_name={"contact_no"}
            prefix={"+63"}
            isRequired={true}
            isNumber={true}
            maxLength={10}
            disabled={_this.state.onboard_data?.isPhoneNumberVerified}
            style={{ flex: 1 }}
          />
          {UI.box(10)}

          <Layout.DatePicker
            _this={_this}
            title={"Date of Birth"}
            isRequired={true}
            placeholder={"mm/dd/yyyy"}
            state_name={"dob"}
            date_props={{
              maxDate: new Date(),
            }}
            will_correct={true}
          />
        </UI.Row>
      </UI.Column>
    );
  }
}

// const styles = StyleSheet.create({
//   asterisk: {
//     color: "#FF0000",
//   },
//   option_container: {
//     height: 40,
//     width: "100%",
//     borderRadius: 5,
//     borderStyle: "solid",
//     borderWidth: 2,
//     padding: 5,
//     borderColor: "#CACACA",
//   },
//   title: {
//     fontSize: 14,
//     fontWeight: "bold",
//     color: UI.colors.primary,
//   },
//   text_2: {
//     fontSize: 14,
//     color: "#D6D6D6",
//   },
//   btn_text_1: {
//     fontSize: 14,
//     color: "white",
//     fontWeight: "bold",
//   },
//   blue_btn_2: {
//     paddingLeft: 15,
//     paddingRight: 15,
//     paddingTop: 12,
//     paddingBottom: 12,
//     backgroundColor: UI.colors.primary,
//     borderRadius: 8,
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   text_1: {
//     color: "#AFAFAF",
//     fontSize: 14,
//   },
//   image_container: {
//     alignItems: "center",
//     flexDirection: "row",
//     alignSelf: "flex-start",
//   },
//   header_3: {
//     fontSize: 18,
//     color: UI.colors.primary,
//     marginTop: 2,
//     fontWeight: "bold",
//   },
// });
