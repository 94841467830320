import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import "./css.css";
export default class TransactionNumber extends Component {
  // **Commented out, currently not used. No need to define empty constructor
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const _this = this.props._this;
    const { 
      // height, 
      // width, 
      // isMobile, 
      item } = _this.state;
    const {
      successTransaction,
      pending,
      refund,
      failed,
      totalTransacCount,
      overAllMonth,
    } = item;

    return (
      <UI.Row
        breakpoint={1000}
        _this={_this}
        style={{ width: "100%", marginBottom: 20 }}
      >
        <View style={styles.earnings_container}>
          <View style={{ flex: 1, minWidth: 160 }}>
            <Text style={styles.header_1}>Total Number of Transactions</Text>
            <Text style={styles.main_header}>{totalTransacCount}</Text>
            {UI.box(10)}
            <Text
              style={{
                ...styles.main_header,
                fontSize: 22,
                color: UI.colors.primary,
              }}
            >
              {overAllMonth}
            </Text>
            <Text style={styles.header_1}>Transactions this Month</Text>
          </View>
          {UI.box(25)}
          <Image
            height={100}
            width={100}
            tintColor={"transparent"}
            source={UI.PAY}
          />
        </View>
        {UI.box(20)}
        <View
          style={{
            padding: 20,
            borderRadius: 5,
            backgroundColor: "white",
            ...Shadow._3(),
            flex: 1,
          }}
        >
          <UI.Row breakpoint={1170} _this={_this} style={{ width: "100%" }}>
            <Text style={styles.box_header_text}>
              <Text style={styles.box_header_text}>
                Site Stats: {UI.get_current_date_string()}
              </Text>
            </Text>
            {UI.box(10)}
            <Layout.SortFilter
              btn_1={{
                text: "Year",
                onClick: _this.filter,
              }}
              btn_2={{
                text: "Month",
                onClick: _this.filter,
              }}
              btn_3={{
                text: "Day",
                onClick: _this.filter,
              }}
              onChange={(val) => {
                _this.setState({
                   // **Removed warning: Unexpected string concat literals by fixing string concat
                  // **Previous: "sort_filter_" + "transaction_filter"
                  [`${"sort_filter_"}${"transaction_filter"}`]: val,
                });
              }}
              _this={_this}
              state_name={"transaction_filter"}
            />
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={1175} _this={_this}>
            <UI.Row _this={_this} breakpoint={1300}>
              <DashItem
                _this={_this}
                icon={UI.IC_DASH_1}
                num={UI.pad(successTransaction, 3)}
                color={UI.colors.primary}
                text={"Successful Transactions"}
              />
              {UI.box(10)}
              <DashItem
                _this={_this}
                icon={UI.IC_DASH_7}
                num={UI.pad(pending, 3)}
                color={"#004F99"}
                text={"Pending Transactions"}
              />
            </UI.Row>
            {UI.box(10)}
            <UI.Row _this={_this} breakpoint={1300}>
              <DashItem
                _this={_this}
                icon={UI.IC_DASH_5}
                num={UI.pad(refund, 3)}
                color={"#E6BA00"}
                text={"Refunded Transactions"}
              />
              {UI.box(10)}
              <DashItem
                _this={_this}
                icon={UI.IC_DASH_8}
                num={UI.pad(failed, 3)}
                color={"#D90000"}
                text={"Cancelled Transactions"}
              />
            </UI.Row>
          </UI.Row>
        </View>
      </UI.Row>
    );
  }
}

const DashItem = (
  props = { _this: null, icon: "", num: "", text: "", color: "" }
) => {
  return (
    <View style={{ flexDirection: "row", flex: 1 }}>
      <Image
        height={60}
        width={60}
        tintColor={"transparent"}
        source={props.icon}
      />

      <View style={{ marginLeft: 15 }}>
        <Text
          style={{ ...styles.main_header, color: props.color, fontSize: 22 }}
        >
          {props.num}
        </Text>
        <Text style={{ ...styles.header_2, fontSize: 12 }}>{props.text}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sort_filter_text: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#BFBFBF",
  },
  sort_filter: {
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 2,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#C9C9C9",
  },
  box_header_subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#C9C9C9",
    alignSelf: "flex-end",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.secondary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    backgroundColor: "white",
    padding: 20,
    ...Shadow._3(),
    flexDirection: "row",
    maxHeight: 180,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
