import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import { StyleSheet, Text, View } from "../../shared/custom-react-native";
// import { Checkbox } from "../_components/InputForm";
import Layout, { Asterisk } from "../_components/Layout";
import "./css.css";

export default class ProfilePicture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image_key: "key_" + Date.now(),
    };
  }

  pick_image = async () => {
    const _this = this.props._this;
    let image = await UI.get_image();
    _this.setState(
      {
        profile_picture: image.uri,
        profile_file: image.file,
        image_file: image.file,
      },
      () => {
        _this.auth_upload("picture", image.file);
      }
    );
    this.setState({
      image_key: "key_" + Date.now(),
    });
  };
  render() {
    const _this = this.props._this;
    const { profile_picture } = _this.state;

    return (
      <View style={{ width: "100%", paddingBottom: 20 }}>
        <UI.Row _this={_this} breakpoint={950} style={{ width: "100%" }}>
          <Text style={{ ...styles.header_3, flex: 1 }}>
            Update User Profile
          </Text>

          <Text style={styles.text_2}>
            Enter the required information below to complete registration
          </Text>
        </UI.Row>
        {UI.box(15)}

        <UI.Row _this={_this} breakpoint={720}>
          <View style={styles.image_container}>
            <img
              key={this.state.image_key}
              style={{ height: 135 * 0.55, width: 135 * 0.55, borderRadius: 5 }}
              src={profile_picture === "" ? UI.DOCTOR_PROFILE : profile_picture}
              alt="profile_picture"
              onError={(e) => {
                if (e) {
                  _this.setState({ profile_picture: UI.DOCTOR_PROFILE });
                }
              }}
            />
            {UI.box(15)}
            <View>
              <Text style={styles.text_1}>
                {" "}
                Uploaded
                <Asterisk />
              </Text>
              {UI.box(5)}
              <Layout.Btn
                onClick={() => {
                  this.pick_image();
                }}
                text={"Upload Profile Picture"}
                color={UI.colors.primary}
              />
            </View>
          </View>
          {/* {UI.box(50)}
          <View style={{ flex: 1 }}>
            <Text style={styles.title}>
              Profession Type
            </Text>
            {UI.box(8)}

            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Checkbox
                _this={_this}
                text={"Medical"}
                onClick={(value) => {
                  _this.setState({
                    professionalType: _this.state.professionalType === "medical" ?  "" : "medical",
                  });
                }}
                is_checked={_this.state.professionalType === "medical" ? true : false}
                color={"#2E2E2E"}
              />
              <View style={{ width: 50 }}></View>
              <Checkbox
                _this={_this}
                text={"Non Medical"}
                onClick={(value) => {
                  _this.setState({
                    professionalType: _this.state.professionalType === "Non medical"  ?  "" : "Non medical" ,
                  });
                }}
                is_checked={_this.state.professionalType === "Non medical" ? true : false}
                color={"#2E2E2E"}
              />
            </View>
          </View> */}
        </UI.Row>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  option_container: {
    height: 40,
    width: "100%",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 2,
    padding: 5,
    borderColor: "#CACACA",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  btn_text_1: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
  },
  blue_btn_2: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  text_1: {
    color: "#AFAFAF",
    fontSize: 14,
  },
  image_container: {
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  header_3: {
    fontSize: 16,
    color: UI.colors.primary,
    marginTop: 2,
    fontWeight: "bold",
  },
});
