import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import "./css.css";
// import PromotionStats from "./PromotionStats";
import RegistrationPopUpAddForm from "../../ComponentsFC/Marketing/registrationpopup/RegistrationPopUpAddForm";
import PageNotFound from "../page-not-found/PageNotFound";
import ServiceMarketingsApi from "../../api_services/marketings";
// import { Failed } from "../_components/Modal";

const context = "marketing";

export default class MarketingPopUpBannersAdd extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "App Content",
      is_drawer_open: true,
      selected: null,
      current_page: 1,
      context: context,
      hasRegPopUpData: true,
    });
  }

  onCreate = async () => {
    this.getRegistrationPopUpBanners();
  };

  getRegistrationPopUpBanners = async () => {
    try {
      let urlParams = {};
      const apiResponse = await ServiceMarketingsApi.getRegistrationPopUp(
        urlParams
      );
      if (apiResponse.status === 200 && apiResponse.length >= 1)
        this.setState({ hasRegPopUpData: false });
      else if (apiResponse.status === 200 && apiResponse.length === 0)
        this.setState({ hasRegPopUpData: true });
      else if (apiResponse.status === 400)
        this.setState({ hasRegPopUpData: true });
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const _this = this;

    return (
      <MainLayout
        context={context}
        _this={_this}
        additional={"registration-popup"}
      >
        {this.state.hasRegPopUpData === true ? (
          <RegistrationPopUpAddForm _this={_this} />
        ) : (
          <PageNotFound />
        )}
      </MainLayout>
    );
  }
}
