import Icon from "@ant-design/icons";
import React from "react";

const FieldIdSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.504"
    height="19.504"
    viewBox="0 0 19.504 19.504"
  >
    <path
      id="id"
      d="M143.433,359.386v-2.352c0-2.146,1.151-3.278,3.308-3.278h2.351a1.088,1.088,0,1,1,0,2.176h-2.166a1.171,1.171,0,0,0-1.318,1.326v2.127a1.088,1.088,0,1,1-2.175,0Zm0,10.6V367.62a1.088,1.088,0,1,1,2.175,0v2.137a1.172,1.172,0,0,0,1.318,1.327h2.166a1.088,1.088,0,1,1,0,2.175h-2.351A2.951,2.951,0,0,1,143.433,369.981Zm4.381-1.951c0-1.151,1.678-4.108,5.376-4.108s5.376,2.957,5.376,4.108a.714.714,0,0,1-.741.79h-9.27A.714.714,0,0,1,147.814,368.03Zm2.673-7.874a2.713,2.713,0,1,1,2.7,2.947A2.807,2.807,0,0,1,150.487,360.156Zm10.274-.771v-2.127a1.175,1.175,0,0,0-1.318-1.326h-2.166a1.088,1.088,0,1,1,0-2.176h2.352c2.166,0,3.308,1.141,3.308,3.278v2.352a1.088,1.088,0,1,1-2.176,0Zm-4.586,12.782a.987.987,0,0,1,1.1-1.083h2.166a1.175,1.175,0,0,0,1.318-1.327V367.62a1.088,1.088,0,1,1,2.176,0v2.361c0,2.137-1.142,3.278-3.308,3.278h-2.352A.99.99,0,0,1,156.175,372.167Z"
      transform="translate(-143.433 -353.756)"
      fill="#4fc2ed"
    />
  </svg>
);

const FieldIdIcon = (props) => {
  return <Icon component={FieldIdSvg} {...props} />;
};

export default FieldIdIcon;
