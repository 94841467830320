import UI from "../../shared/Components/UI/js";
import ServiceApi from "../service";

class DoctorAccountsApi {
  baseURL = "admin";

  async getDeletedDoctorsList(params) {
    const response = await ServiceApi.get(this.baseURL + `/doctors/deleted?`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      // response.status = 200;
      return data;
    } else {
      return {
        status: response?.status,
        data: response?.data?.d,
        message: response?.data?.m
      }
    }
  }

  async getDeactivatedDoctorsList(params) {
    const response = await ServiceApi.get(this.baseURL + `/path`, params);

    if (response?.status === 200) {
      // const data = UI.extract_data(response);
      // data.status = 200;
      response.status = 200;
      return response;
    } else {
      return {
        status: response?.status,
        data: response?.data?.d,
        message: response?.data?.m
      }
    }
  }

}

export default new DoctorAccountsApi();
