import React, { Component } from "react";
import EmailSignup from "../../ComponentsFC/email_signup/EmailSignup";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  // StyleSheet,
} from "../../shared/custom-react-native";
import "./css.css";

const context = "signup";
// const font_increment = 2;

export default class OnboardSignup extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      remember_me: false,
      did_signup: false,
      is_verifying: false,
      context: context,

      is_agree: false,
    });
  }

  onCreate = async () => { };
  authenticate = async () => {
    mem.save("auth_btn_type", "signup");
    this.show_loading("", "Redirecting to Azure...");

    UI.authRequest({
      method: "get",
      url: "app/mwell/auth/authorize?policy_name=B2C_1_Registration_Doctor&platform=web",
      params: {
        ...UI.Constants.ADDITIONAL_ENVIRONMENT
      },
      onFail: async (err) => {
        this.hide_loading();
      },
      onSuccess: async (response) => {
        window.location.href = response.data.d.url;
      },
      onFinish: () => { },
    });
  };

  render() {
    // const { 
    //   // width, height, isMobile, 
    //   is_agree } = this.state;
    // // const _this = this;

    // const update_agree = () => {
    //   this.setState({
    //     is_agree: !this.state.is_agree,
    //   });
    // };

    return (
      <>
        <EmailSignup _this={this} />
      </>
      // <StartupLayout context={context} image={UI.BG_SIGNUP} _this={this}>
      //   <ScrollView
      //     style={{ display: this.state.did_signup ? "none" : "flex" }}
      //   >
      //     <View style={styles.content_container}>
      //       <View style={{ flex: 1, paddingTop: 50 }}></View>
      //       <Text style={styles.header_1}>Sign Up with mWell!</Text>
      //       {UI.box(10)}
      //       <Text style={styles.header_2}>
      //         Create your Doctor Account Here!
      //       </Text>
      //       {UI.box(20)}

      //       <TouchableOpacity
      //         onClick={() => {
      //           this.authenticate();
      //         }}
      //         style={{
      //           ...styles.blue_btn,
      //           backgroundColor: is_agree ? UI.colors.secondary : "#636e72",
      //           pointerEvents: is_agree ? "all" : "none",
      //         }}
      //       >
      //         <Text style={styles.blue_text}>SIGN UP</Text>
      //       </TouchableOpacity>
      //       {UI.box(20)}

      //       <View style={{ flexDirection: "row" }}>
      //         <View style={{ alignSelf: "flex-start" }}>
      //           <Checkbox
      //             is_checked={this.state.is_agree}
      //             onClick={update_agree}
      //           />
      //         </View>

      //         <Text style={{ marginTop: -3, fontSize: 12 + font_increment }}>
      //           <span onClick={update_agree}>I agree to the</span>{" "}
      //           <a href={"https://www.mwell.com.ph/support"}>
      //             mWell Terms of Service
      //           </a>{" "}
      //           <span onClick={update_agree}>and</span>{" "}
      //           <a href={"https://www.mwell.com.ph/support"}>
      //             mWell Privacy Policy
      //           </a>
      //           .
      //         </Text>
      //       </View>

      //       {UI.box(25)}

      //       <TouchableOpacity
      //         onClick={() => {
      //           UI.goTo("/onboard/login");
      //         }}
      //         style={styles.sign_up_btn}
      //       >
      //         <Text style={styles.sign_up_main}>Already have an account?</Text>
      //         {UI.box(5)}
      //         <Text style={styles.sign_up_sub}>Sign In</Text>
      //       </TouchableOpacity>
      //       <View style={{ flex: 1, paddingBottom: 50, paddingTop: 30 }}>
      //         <Text style={styles.header_5}>
      //           Thank you for your interest in signing up with mWell!
      //         </Text>
      //         {UI.box(20)}
      //         <Text style={styles.header_5}>
      //           To facilitate the onboarding process, please have the following
      //           items ready:
      //         </Text>
      //         {UI.box(20)}
      //         <Text style={styles.header_5}>-Image of PRC ID (jpeg)</Text>
      //         <Text style={styles.header_5}>
      //           -Professional Profile Pic (jpeg)
      //         </Text>
      //         <Text style={styles.header_5}>
      //           -Image of your signature (jpeg)
      //         </Text>

      //         {UI.box(20)}
      //       </View>
      //     </View>
      //   </ScrollView>
      // </StartupLayout>
    );
  }
}

// const styles = StyleSheet.create({
//   resend_text: {
//     fontSize: 14,
//     color: UI.colors.primary,
//     fontWeight: "bold",
//     marginTop: 2,
//   },
//   resend_btn: {
//     height: 35,
//     justifyContent: "center",
//     marginLeft: 10,
//   },
//   primary_color: {
//     color: UI.colors.primary,
//   },
//   sign_up_sub: {
//     fontSize: 12 + font_increment,
//     fontWeight: "bold",
//     color: UI.colors.primary,
//   },
//   sign_up_main: {
//     fontSize: 12 + font_increment,
//     fontWeight: "bold",
//     color: "black",
//   },
//   sign_up_btn: {
//     alignSelf: "center",
//     flexDirection: "row",
//     alignItems: "center",
//   },
//   blue_text: {
//     color: "white",
//     fontSize: 12 + font_increment,
//     fontWeight: "bold",
//   },
//   blue_btn: {
//     width: "100%",
//     height: 40,
//     backgroundColor: UI.colors.secondary,
//     borderRadius: 10,
//     justifyContent: "center",
//     alignItems: "center",
//     maxWidth: 360,
//     alignSelf: "center",
//   },
//   forgot_password_text: {
//     fontSize: 14,
//     fontWeight: "bold",
//     color: UI.colors.primary,
//   },
//   forgot_container: {
//     width: "100%",
//     flexDirection: "row",
//     justifyContent: "flex-end",
//     marginTop: -68,
//     marginBottom: 50,
//   },
//   header_2: {
//     fontSize: 12 + font_increment,
//     color: UI.colors.black,
//     fontWeight: "bold",
//   },
//   header_5: {
//     fontSize: 12 + font_increment,
//     color: UI.colors.black,
//     fontWeight: "bold",
//   },
//   header_3: {
//     fontSize: 13,
//     color: UI.colors.black,
//     fontWeight: "bold",
//     textAlign: "center",
//   },
//   header_4: {
//     fontSize: 16,
//     color: UI.colors.primary,
//     fontWeight: "bold",
//     textAlign: "center",
//   },
//   header_1: {
//     fontSize: 23 + font_increment,
//     color: UI.colors.primary,
//     fontWeight: "bold",
//   },
//   content_container: {
//     width: "100%",
//     height: "100%",
//     justifyContent: "center",
//   },
// });
