import React from 'react'
import styled from 'styled-components'
import LoginForm from './LoginForm'
import LoginHeader from './LoginHeader'
import TermsAndCondition from '../ui/TermsAndCondition'
import SocialButtons from './social-login'

const LoginRightContainer = ({_this}) => {
  return (
    <LoginContainer>
      <RightContainer>
        <LoginHeader/>
        <LoginForm/>
        <SocialButtons _this={_this}/>
        <TermsAndCondition/>
      </RightContainer>
    </LoginContainer>
  )
}

export default LoginRightContainer

const LoginContainer = styled(`div`)`
  flex:40% ;
`;

const RightContainer = styled(`div`)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 85.5px 50px;

  @media only screen and (max-width: 820px){
    padding: 25px;
  }
`;

