import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { CustomConfirmation, Success } from "../_components/Modal";
import "./css.css";

const role = mem.get(UI.Constants.ROLE);
const context = role;
const default_enddate = "2050-09-29";
const default_startdate = "1970-01-01";

export default class FinanceRefund extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Patients Refund",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      default_all_data:[],
      all_data: [],
      context: context,
      start_date_dashed: default_startdate,
      end_date_dashed: default_enddate,
      sort: "-createdAt",
      sortAdded: "desc",
      sortName: "desc",
      status: "",
      name: "",
    });
  }

  onCreate = async () => {
    setTimeout(() => {
      this.get_data();
    }, 100);
  };

  get_data = async (params) => {
    const { start_date_dashed, end_date_dashed } = this.state;
    this.show_loading();

    // return;
    UI.webRequest({
      method: "get",
      url: "admin/refunds/refundables",
      params: {
        startDate: start_date_dashed,
        endDate: end_date_dashed,
        ...params
      },
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        let new_data = data.map((item) => {
          let {
            platformFee = 0,
            transactionFee = 0,
            consultationFee = 0,
          } = item.price;
          let amountPaid =
            parseFloat(platformFee) +
            parseFloat(transactionFee) +
            parseFloat(consultationFee);
          let refundAmount = parseFloat(
            item?.penalty?.refundAmount || 0
          ).toFixed(2);
          let refundedAmount = parseFloat(item?.refund?.amount || 0).toFixed(2);
          item.doctorsFee = parseFloat(
            item?.price?.consultationFee || 0
          ).toFixed(2);
          item.timestamp = new Date(item?.appointment?.createdAt).getTime();
          item.createdAt = item?.appointment?.createdAt;
          item.order_date = UI.get_date_string_by_timestamp(item.timestamp);
          item.consult_date = item?.appointment?.createdAt;
          item.consult_date_string = UI.get_date_string_by_date(
            item.consult_date
          );
          item.patient_name =
            (item?.user?.firstName ?? " ") +
            " " +
            (item?.user?.lastName ?? " ");
          item.doctor_name = UI.DataHelper.carespan.get_name(
            item?.doctor?.name
          );
          item.reference_number = item?.referenceNumber;
          item.amount = refundAmount;
          item.amountPaid = amountPaid.toFixed(2);
          item.refund_amount = refundedAmount;
          item.refund_date = UI.get_date_string_by_date(item?.refund?.refundAt);
          item.status = item?.status?.status;
          item.appointment_status =
            UI.replaceAll(item?.appointment.status ?? "", "_", " ") ??
            "Completed";
          item.appointment_status0 = item?.appointment.status;

          return item;
        });

        new_data = new_data.filter((item) => item.method !== "Voucher");

        const dateFrom = start_date_dashed ? start_date_dashed : "2022-06-01";
        const dateTo = end_date_dashed
          ? moment(end_date_dashed).add("1", "days").format("YYYY-MM-DD")
          : "2022-06-02";

        new_data = new_data.filter(
          (row) => row.createdAt >= dateFrom && row.createdAt <= dateTo
        );

        this.setState(
          {
            data: new_data,
            all_data: new_data,
            default_all_data: new_data
          },
          () => {
            this.reload_table("transactions");
            this.hide_loading();
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  sort_by = (format, key) => {
    //format can be asc or desc
    const { all_data } = this.state;

    all_data.sort(UI.dynamicSort(format === "asc" ? key : "-" + key));
    UI.set_input_value("search", "");

    this.setState(
      {
        data: all_data,
      },
      () => {
        this.reload_table("transactions");
        this.hide_loading();
      }
    );
  };

  custom_sort = (key, value) => {
    this.get_data({ [key]: value });
    // const { all_data } = this.state;
    // let items = all_data.filter((item) => item[key] + "" == value);
    // UI.set_input_value("search", "");
    // if (value) {
    //   this.setState({ all_data: items })
    //   this.reload_table("transactions", items);
    // } else {
    //   this.reload_table("transactions", all_data);
    // }
  };

  refund_confirm = () => {
    this.show_modal(
      <CustomConfirmation
        title={"Confirmation"}
        text={"Are you sure you want to refund this transaction?"}
        backgroundColor={"#3498db"}
        onOk={{
          text: "CONTINUE",
          method: () => {
            this.hide_modal();
          },
        }}
        onCancel={{
          text: "CANCEL",
          method: () => {
            this.hide_modal();
          },
        }}
      />
    );
  };

  export_to_csv = async () => {
    const { all_data } = this.state;

    const csv_data = all_data.map((item, index) => {
      // const amountTobeRefunded = parseInt(item.price.total) + parseInt(item.price.transactionFee);
      let message = ""

      if (item.status === "Failed Refund") {
        message = item.refund.message ?? " ";
      } else {
        message = item.status ?? " ";
      }
      const ap = item.appointment;
      const refundStatus =
        item?.refund?.status_2 || item?.refund?.status === "Completed"
          ? "Refunded"
          : item?.refund?.status
          ? item?.refund?.status
          : "For Refund";

      const d = {
        index: index + 1,
        "Appointment ID": ap.id || " ",
        "Create Date": item?.order_date || " ",
        "Consult date": item?.consult_date_string || " ",
        "Order Status": item?.appointment_status || " ",
        "Username": `${item?.user?.firstName || ""} ${item?.user?.lastName || ""}`,
        "Patient Name": item?.patient_name || " ",
        "Doctor Name":
          UI.DataHelper.carespan.get_name(ap.practitioner.name) || " ",
        Platform: ap.conferenceMode || " ",
        "Order Type":
          ap.appointmentType === "instant"
            ? "Consult Now"
            : "Consult Later" || " ",
        "Doctor Type":
          typeof ap.doctorType === "undefined" ? "" : ap.doctorType,
        "Total Billing":
          `${parseFloat(item?.amountPaid || 0)
            .toFixed(3)
            .toString()}` || " ",
        "Refund Amount":
          `${parseFloat(item?.amount || 0)
            .toFixed(3)
            .toString()}` || " ",
        // "Doctors Fee": item?.doctorsFee ? item.doctorsFee : "",
        // "Total Refund": `${parseFloat(item?.refund_amount || 0).toFixed(3).toString() }` || " ",
        "Amount Refunded":
          item?.status === "Manual Refund" ? 0 : item?.refund_amount || 0,
        "Payment Status": "Refund",
        "Refund Status":
          item?.status === "Manual Refund" ? item?.status : refundStatus,
        "Reason for Failed Refund":
          message !== "Refunded"
            ? message === "Refund" || message === "Manual Refund"
              ? ""
              : message
            : "",
        "Decision Code": item?.appointment?.decision || "",
        "Mode of Payment": item.payType,
        "Payment RRN": item.reference_number,
        "Paymaya ID": item.paymentId || " ",
        "Auth Code": "",
        "Masked Card Number": item?.cardDetails?.maskCardNumber || "",
        // "Merchant Name": "",
        // "MID": "",

        // "Payment Mode"        : item.payType ?? " ",
        // Amount: item.amount ?? " ",
      };
      return d;
    });

    console.log("csv data:", csv_data);
    UI.DownloadCSV(
      "Patients Refunds " + UI.get_current_date_string(),
      csv_data
    );
  };

  export_refunds = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "admin/refunds/process",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        const csv_data = data.map((item, index) => {
          let name = item?.user?.name ?? " ";

          if (item.user.firstName) {
            name = item?.user?.firstName + " " + item?.user?.lastName ?? " ";
          }

          const d = {
            Row: index + 1,
            Name: name,
            Email: item.user?.email ?? " ",
            "Payment Method": item?.payment?.method ?? " ",
            Currency: item.currency ?? " ",
            "Refund Amount": item.refundableAmount ?? " ",
            "Refund Type": item.type ?? " ",
            "Payment ID (Paymaya)": item.payment.paymentId ?? " ",
            Status: item.status ?? " ",
            "Refund Date": UI.get_date_string_by_date(item.createdAt) ?? " ",
          };
          return d;
        });

        UI.DownloadCSV(
          "Processed Refunds " + UI.get_current_date_hr_mm_string(),
          csv_data
        );

        if (data.length === 0) {
          this.show_modal(
            <Success
              title={"Success!"}
              description={"No data found to process the refund."}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        }
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  // apply_date_filter = async (page) => {

  //   await UI.clear_errors();
  //   if (default_startdate === this.state.start_date_dashed) {
  //     await UI.error_form("start_date", "Please enter start date");
  //   }
  //   if (default_enddate === this.state.end_date_dashed) {
  //     await UI.error_form("end_date", "Please enter end date");
  //   } else {
  //     await this.get_data();
  //   }
  // };

  render() {
    const _this = this;
    const { width } = _this.state;
    let filters_finance_refund =
      UI.measure("filters_finance_refund").width ?? width;

    const filterData = {
      sortAdded: this.state.sortAdded,
      sortName: this.state.sortName,
      startDate: this.state.start_date_dashed,
      endDate: this.state.end_date_dashed,
      status: this.state.status
    }

    return (
      <MainLayout context={context} _this={_this}>
        <View id={"filters_finance_refund"} style={styles.main_box_content}>
          <Text style={styles.box_header_text}>Patients Refund</Text>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={false}
              onChange={(e) => {
                filterData.status = e;
                this.setState({ status: e });
                this.get_data(filterData);
              }}
            >
              <option value="">Select Status</option>
              {/* <option value="Pending">Pending</option> */}
              <option value="Refunded">Refunded</option>
              <option value="Manual Refund">Manual Refund</option>
              <option value="Failed Refund">Failed Refund</option>
              <option value="Refund">For Refund</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Date Added"}
              isRequired={false}
              onChange={(e) => {
                filterData.sortAdded = e;
                this.setState({ sortAdded: e });
                this.get_data(filterData);
              }}
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Patient Name"}
              isRequired={false}
              onChange={(e) => {
                filterData.sortName = e;
                this.setState({ sortName: e });
                this.get_data(filterData);
              }}
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>
          </UI.Row>
          {UI.box(20)}
          {/* <Text style={styles.title}>Date Filter</Text> */}

          <UI.Row breakpoint={540} _this={_this} basis={filters_finance_refund}>
            <View style={{ width: 200 }}>
              <Layout.DatePicker
                _this={_this}
                isRequired={false}
                title="Order Date From"
                placeholder={"Start Date"}
                state_name={"start_date"}
                will_correct={true}
              />
            </View>
            {UI.box(10)}
            <View style={{ width: 200 }}>
              <Layout.DatePicker
                _this={_this}
                isRequired={false}
                title="Order Date To"
                placeholder={"End Date"}
                state_name={"end_date"}
                will_correct={true}
              />
            </View>
            {UI.box(10)}
            <Layout.Btn
              style={{
                alignSelf:
                  filters_finance_refund < 540 ? "flex-start" : "center",
                marginTop: 30,
                width: filters_finance_refund < 540 ? 200 : null,
              }}
              color={UI.colors.secondary}
              onClick={() => {
                this.get_data();
              }}
              text={"Apply"}
              height={50}
            />
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={1135}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"transactions"} _this={_this} />
              </View>
            </View>
            <InputForm
              onChange={(e) => {
                UI.search_table("transactions", e, this.state.all_data, "data");
              }}
              is_keyword={true}
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
            />
            <View
              style={{
                alignSelf: width > 1135 ? "flex-start" : "center",
                marginTop: 8,
                marginLeft: 10,
                flexDirection: "row",
              }}
            >
              <Layout.Btn
                color={UI.colors.secondary}
                text={"Export to CSV"}
                onClick={() => {
                  this.export_to_csv();
                }}
                height={40}
                width={130}
              />
              {UI.box(10)}
              {/* <Layout.Btn
                color={UI.colors.primary}
                text={"Process Refunds"}
                onClick={() => {
                  this.export_refunds();
                }}
                height={40}
                width={140}
              /> */}
            </View>
          </UI.Row>

          <CustomDatatable
            disable_pagination={false}
            state_name={"transactions"}
            _this={_this}
            columns={[
              // { "Appointment ID": "appointment_id" },
              { "Reference ID": "reference_number" },
              { "Paymaya ID": "paymentId" },
              { "Order Date": "date_added" },
              { "Patient Name": "patient_name" },
              // { "Doctor Name": "doctor_name" },
              // { "Date of consult": "consult_date" },
              { "Amount Paid": "amount" },
              { "Appointment Status": "appointment_status" },
              // { "Payment Type": "pay_type" },
              // { "Doctor Fee": "doctor_fee" },
              { 'Amount to be Refunded': "refund_amount" },
              { 'Amount Refunded': "refunded_amount" },
              { 'Date Refunded': "refund_date" },
              { Status: "status" },
              // { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              // const edit_refund = async () => {
              //   mem.save("edit_transaction", JSON.stringify(item));
              //   UI.goTo("/finance/refund-doctor-transaction");
              // };

              const date_added = UI.timestampToDate(
                new Date(item.createdAt).getTime()
              );
              const consult_date = UI.timestampToDate(
                new Date(item.consult_date).getTime()
              );

              let status = "";
              /*

                  status == "Approved" ? (
                    <Text style={{ color: "#27ae60" }}>Approved</Text>
                  ) : status == "Disapproved" ? (
                    <Text style={{ color: "#e74c3c" }}>Disapproved</Text>
                  ) : (
                    <Text style={{ color: "#FFCE00" }}>Pending</Text>
                  )


                */
              if (item.status ==="Success") {
                status = <Text style={{ color: "#27ae60" }}>Success</Text>;
              } else if (item.status === "Completed") {
                status = <Text style={{ color: "#27ae60" }}>Completed</Text>;
              } else if (item.status === "Refunded") {
                status = <Text style={{ color: "#27ae60" }}>Refunded</Text>;
              } else if (item.status === "Pending") {
                status = <Text style={{ color: "#FFCE00" }}>Pending</Text>;
              } else if (item.status === "Refund") {
                status = <Text style={{ color: "#3498db" }}>For Refund</Text>;
              } else if (item.status === "Cancelled") {
                status = <Text style={{ color: "#e74c3c" }}>Cancelled</Text>;
              } else if (item.status === "Manual Refund") {
                status = <Text style={{ color: "#3498db" }}>Manual Refund</Text>;
              } else if (item.status === "Failed Refund") {
                status = <Text style={{ color: "#e74c3c" }}>Failed Refund</Text>;
              }

              return {
                doctor_name: (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginLeft: 10,
                    }}
                  >
                    <Text
                      style={{
                        maxWidth: 80,
                        marginRight: 10,
                        textAlign: "center",
                      }}
                    >
                      {item.doctor_name}
                    </Text>
                  </View>
                ),
                appointment_id: item.appointment._id,
                patient_name: item.patient_name,
                reference_number: item.reference_number,
                amount: item.amountPaid,
                doctor_fee: item?.doctorsFee,
                refund_amount: item.amount,
                refunded_amount: item?.refund_amount,
                refund_date: item.refund_date,
                paymentId: item.paymentId,
                pay_type: item.payType,
                date_added:
                  date_added.month +
                  "/" +
                  date_added.day +
                  "/" +
                  date_added.year,
                consult_date:
                  consult_date.month +
                  "/" +
                  consult_date.day +
                  "/" +
                  consult_date.year,
                status: status,
                appointment_status: item.appointment_status,
                // action: (
                //   <View
                //     style={{
                //       flexDirection: "row",
                //       display: item.status == "Refund" ? "flex" : "none",
                //     }}
                //   >
                //     <TouchableOpacity onClick={edit_refund}>
                //       <View
                //         style={{
                //           height: 25,
                //           width: 50,
                //           borderRadius: 4,
                //           backgroundColor: "#3498db",
                //           justifyContent: "center",
                //           alignItems: "center",
                //         }}
                //       >
                //         <Text
                //           style={{
                //             color: "white",
                //             fontWeight: "bold",
                //             fontSize: 12,
                //           }}
                //         >
                //           Refund
                //         </Text>
                //       </View>
                //     </TouchableOpacity>
                //   </View>
                // ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
