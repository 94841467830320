import React from "react";
import DataTable from "../../../../../ComponentsFC/DataTable";
import { Row } from "antd";
import styled from "styled-components";

const Referrals = ({ tableData = [] }) => {
  // const [tableData, setTableData] = useState([]);
  // const [isTableLoading, setIsTableLoading] = useState(false);

  const tableColumnHeader = [
    {
      title: "Doctor’ Name",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Email Address",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Reason for Referral",
      dataIndex: "indication",
      key: "indication",
    },
    {
      title: "Chief Complaint",
      dataIndex: "indication",
      key: "indication",
    },
    {
      title: "Pertinent Information",
      dataIndex: "indication",
      key: "indication",
    },
    {
      title: "File Name",
      dataIndex: "indication",
      key: "indication",
    },
    {
      title: "Uploaded On",
      dataIndex: "indication",
      key: "indication",
    },
    {
      title: "Action",
      dataIndex: "indication",
      key: "indication",
    },
  ];
  return (
    <TableBox>
      <DataTable tableColumnHeader={tableColumnHeader} dataSource={tableData} />
    </TableBox>
  );
};

export default Referrals;

const TableBox = styled(Row)`
  position: relative;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  min-width: 100%;
  .ant-table-cell {
    font-size: 20px;
  }
`;
