import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  // ImageBackground,
  // ImageFit,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
  List,
} from "../../shared/custom-react-native";
import { DownloadPDF } from "../_components/CustomPDF";
import MainLayout from "../_components/MainLayout";
import "./css.css";

const context = "merchant";

export default class EcommerceViewProductDetails extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Products",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
      orderStatus:'',

      item: {
        _id: "",
        product: {
          _id: "",
          name: "",
          price: "",
          imagePath: "",
        },
        merchant: null,
        user: {
          _id: "",
          name: "",
          firstName: "",
          lastName: "",
          email: "",
          id: "",
        },
        method: "",
        referenceNumber: "",
        paymentId: "",
        currency: "",
        amount: "",
        quantity: "",
        status: "",
        createdAt: "",
        __v: 0,
      },

      customer_image: UI.TRANSACTION_USER,

      product_image: UI.PRODUCT_BOX,

      is_rendering: false,
    });
  }

  onCreate = async () => {
    const data = mem.get("view_transaction");

    if (data) {
      const transaction = JSON.parse(data);
      this.setState(
        {
          item: transaction,
        },
        this.load_data
      );
    }
  };

  load_data = async () => {
    const { item } = this.state;

    setTimeout(() => {
      this.reload_table("transaction");
    }, 100);

    this.customer(item);
    this.product(item);
  };

  customer = async (item) => {
    const image = await UI.download_image(
      item.user?.imagePath,
      "ecommerce",
      UI.TRANSACTION_USER
    );
    this.setState({
      customer_image: image,
    });
  };
  product = async (item) => {
    const image = await UI.download_image(
      item.product?.imagePath,
      "ecommerce",
      UI.PRODUCT_BOX
    );
    this.setState({
      product_image: image,
    });
  };

  download = async (type) => {
    this.show_loading("", "Processing Request...", true);
    this.setState(
      {
        is_rendering: true,
      },
      () => {
        UI.execute_resizes(this);
        setTimeout(() => {
          DownloadPDF(
            "main_transaction_content",
            "Transaction_" + this.state.item.referenceNumber,
            type
          );
          this.hide_loading();
          this.setState(
            {
              is_rendering: false,
            },
            () => {
              setTimeout(() => {
                UI.execute_resizes(this);
              }, 100);
              setTimeout(() => {
                UI.execute_resizes(this);
              }, 200);
              setTimeout(() => {
                UI.execute_resizes(this);
              }, 300);
            }
          );
        }, 300);
      }
    );

    //DownloadPDF("main_transaction_content")
  };

  render() {
    const _this = this;
    let { // TO-DO: switch back to 'const' after mock data testing
      // height,
      // width,
      // isMobile,
      item,
      mockListData,
      customer_image,
      // product_image,
      is_rendering,
    } = _this.state;

    const fixed_style = is_rendering
      ? {
          width: 874.54541015625,
          height: 539.84375,
          flex: null,
          ...Shadow._0(),
        }
      : {};

    /* mock data for item */
    item = {
      amount: 100,
      createdAt: '1/1/2000',
      currency: 'Php',
      merchant: 'test merchant',
      method: '',
      paymentId: '1234567890',
      product: { _id: 'p-12345678', name: 'test-product', price: 100, imagePath: '' },
      quantity: 10,
      referenceNumber: 'r-12345678',
      status: 'Confirmed',
      user: { 
        id: '', email: 'john.doe@gmail.com', 
        firstName: 'John', lastName: 'Doe', 
        name: 'John Doe', contact_number: '+63 916 461 6171'
      },
      _id: 'id-12345',
    }
    /* */
    /* mock data for subscription */
    mockListData = [
      {
        header: 'Product has been added to cart',
        name: 'Ibuprofen',
        date: '1/1/2000'
      },
      {
        header: 'Product has not been added to cart',
        name: 'Paracetamol',
        date: '2/2/2001'
      },
      {
        header: 'Product is pending',
        name: 'Analgesic',
        date: '3/3/2002'
      },
    ]
    /* */
    console.log("ViewTransaction [item]:",item);
    return (
      <MainLayout context={context} _this={_this}>
        <UI.Row breakpoint={840} _this={_this}>
          <View
            id={"main_transaction_content"}
            style={{
              // ...styles.main_box_content,
              flex: 1,
              padding: 0,
              ...fixed_style,
            }}
          >
            <View style={styles.border_line}></View>
            <UI.Row
              breakpoint={is_rendering ? 10 : 940}
              style={{ width: "100%", padding: 20 }}
              _this={_this}
            >
              <View style={{...styles.main_box_content, flex: "1 1 0%"}}>
                <View>
                  <Text style={styles.box_header_text}>Transaction Details</Text>
                  {UI.box(20)}
                  <UI.Row breakpoint={840} _this={_this}>
                    <img style={{ height: 100, width: 100 }} src={customer_image} alt=""/>
                    {UI.box(10)}
                    <View style={{ margin: 'auto' }}>
                      <Text style={styles.patient_name}>{item.user.firstName + " " + item.user.lastName}</Text>
                      <Text style={styles.sub_name}>{item.user.email}</Text>
                      <Text style={styles.sub_name}>{item.user.contact_number}</Text>
                    </View>
                  </UI.Row>
                  {UI.box(20)}
                </View>
                
                <View style={styles.border_line}></View>

                <View>
                  {UI.box(20)}
                  <UI.Row breakpoint={840} _this={_this}>
                    <img style={{ height: 100, width: 100 }} src={customer_image} alt=""/>
                    {UI.box(10)}
                    <View style={{ margin: 'auto' }}>
                      <Text style={styles.patient_name}>{item.product.name}</Text>
                      <Text style={styles.total_text}>{item.currency} {item.product.price}</Text>
                      <Text style={styles.stat_name}>Status: {UI.get_transaction_status_text(item)}</Text>
                    </View>
                  </UI.Row>
                </View>

                {UI.box(15)}
                <View>
                  <UserDetail
                    icon={UI.CONSULT_PRICE}
                    name={"Purchase Price"}
                    value={"Php " + UI.pad(item.amount, 3, true)}
                    is_rendering={is_rendering}
                  />
                  <UserDetail
                    icon={UI.CONSULT_PRICE}
                    name={"Payment Method"}
                    value={"Bayad App"}
                    is_rendering={is_rendering}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"Purchase Date"}
                    value={UI.get_date_string_by_date(item.createdAt)}
                  />
                </View>
              </View>

              <View style={{ ...styles.main_box_content, marginLeft: "1em", flex: "1 1 0%" }}>
                <View>
                  <Text style={styles.box_header_text}>Subscription Timeline</Text>
                </View>
                {UI.box(20)}
                <View>
                  <List _this={_this} data={mockListData} />
                </View>
              </View>
            </UI.Row>
          </View>
        </UI.Row>
      </MainLayout>
    );
  }
}

const UserDetail = (
  props = { icon: "", name: "", value: "", is_rendering: false }
) => {
  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}
    >
      <View style={{ width: 180, ...styles.fl_ai }}>
        <View style={styles.detail_icon}>
          <img
            style={{
              height: 16,
              width: 16,
              marginTop: props.is_rendering ? 8 : 0,
            }}
            src={props.icon}
            alt="User"
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};
const styles = StyleSheet.create({
  total_border_line: {
    height: 2,
    width: "100%",
    backgroundColor: "#888888",
  },
  total_text_value: {
    fontSize: 17,
    fontWeight: "bold",
    color: UI.colors.secondary,
  },
  total_text: {
    fontSize: 17,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  total_container: {
    width: "100%",
    padding: 15,
    flexDirection: "row",
  },
  receipt_legend: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#535353",
  },
  border_line: {
    height: 1,
    width: "100%",
    backgroundColor: "#EBEBEB",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  transaction_date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
  transaction_date: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 10,
  },
  timeline_circle: {
    height: 22,
    width: 22,
    borderRadius: 100,
    backgroundColor: UI.colors.primary,
    borderWidth: 4,
    borderColor: "#E3E3E3",
    marginTop: -10,
    marginLeft: -12,
  },
  timeline_item: {
    height: 100,
    width: "100%",
    borderLeftStyle: "solid",
    borderLeftWidth: 2,
    borderLeftColor: "#E3E3E3",
    marginLeft: 10,
  },
  detail_value: {
    color: "black",
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  detail_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  flex_1: {
    flex: 1,
  },
  detail_left: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  detail_item: {
    flexDirection: "row",
    alignItems: "center",
    width: 300,
    marginBottom: 5,
  },
  line: {
    width: "100%",
    height: 3,
    backgroundColor: "#EFEFEF",
  },
  ai_fl: {
    flexDirection: "row",
    alignItems: "center",
  },
  sub_name: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#898989",
  },
  stat_name: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#000000",
  },
  main_name: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

