import UI from "../../shared/Components/UI/js";
import ServiceApi from "../service";

class TransactionsApi {
  baseURL = "admin";

  async getAllTransactionsByMerchant(params) {
    const response = await ServiceApi.get(this.baseURL + `/transactions/merchant/me`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async getAllTransactions(params) {
    const response = await ServiceApi.get(this.baseURL + `/transactions`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async updateTransacitonOrderStatus(transactionId, payload) {
    const response = await ServiceApi.put(this.baseURL + `/merchant/order-status/${transactionId}`, payload);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }
}

export default new TransactionsApi();
