import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import MainLayout from "../_components/MainLayout";
import "./css.css";
const context = "merchant";

export default class EcommerceViewInquiry extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Inquiries",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],

      context: context,
      item: {
        _id: "",
        customer: {
          _id: "",
          name: "",
          email: "",
          phone: "",
          imagePath: "",
          id: "",
        },
        product: {
          _id: "",
          name: "",
          price: "",
          quantity: 0,
          description: "",
          imagePath: "",
          limit: 0,
        },
        description: "",
        transactionDate: "",
        inquiryDate: "",
        createdAt: "",
        __v: 0,
      },

      product_image: UI.SAMPLE_PRODUCT,
      customer_image: UI.TRANSACTION_USER,
    });
  }

  onCreate = () => {
    this.show_loading();
    const data = mem.get("view_inquiry");
    if (data) {
      const item = JSON.parse(data);
      this.setState(
        {
          item,
        },
        () => {
          this.load_images();
        }
      );
    }
  };

  load_images = async () => {
    const { item } = this.state;
    const product_image = await UI.download_image(
      item.product.imagePath,
      "ecommerce",
      UI.SAMPLE_PRODUCT
    );
    const customer_image = await UI.download_image(
      item.customer.imagePath,
      "ecommerce",
      UI.TRANSACTION_USER
    );

    this.setState({
      product_image,
      customer_image,
    });
    this.hide_loading();
  };

  render() {
    const _this = this;
    const {
      // height,
      width,
      // isMobile,
      product_image,
      customer_image,
      item,
    } = _this.state;

    const transaction_date = item.transactionDate
      ? UI.timestampToDate(new Date(item.transactionDate).getTime())
      : UI.timestampToDate(Date.now());

    const inquiry_date = item.inquiryDate
      ? UI.timestampToDate(new Date(item.inquiryDate).getTime())
      : UI.timestampToDate(Date.now());

    const created_date = item.inquiryDate
      ? UI.timestampToDate(new Date(item.createdAt).getTime())
      : UI.timestampToDate(Date.now());

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                borderRightWidth: width >= 1250 ? 2 : 0,
                borderBottomWidth: width < 1250 ? 2 : 0,
                paddingRight: width < 1250 ? 0 : 20,
                marginRight: width < 1250 ? 0 : 20,
                paddingBottom: 10,
                marginBottom: width < 1250 ? 10 : 0,
              }}
            >
              <View style={styles.fl_ai}>
                <img
                  style={{ height: 100, width: 100, borderRadius: 5 }}
                  src={product_image}
                  alt="Product"
                />

                {UI.box(width < 980 ? 5 : 10)}

                <View>
                  <Text style={styles.patient_name}>{item.product.name}</Text>
                  <Text
                    style={{
                      ...styles.patient_name,
                      color: UI.colors.primary,
                      fontSize: 14,
                    }}
                  >
                    Php {item.product.price}
                  </Text>
                  <Text
                    style={{
                      ...styles.patient_name,
                      color: "#454545",
                      fontSize: 14,
                    }}
                  >
                    Status: <Text style={{ color: "#00EB2D" }}>Active</Text>
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <UI.Row _this={_this}>
                <UI.Row breakpoint={970} _this={_this} style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.PAYMENT_METHOD}
                    name={"Purchase Limit"}
                    value={UI.pad(item.product.limit ?? 0, 3)}
                  />
                  <UserDetail
                    icon={UI.COMMERCE_INVENTORY}
                    name={"Stock"}
                    value={UI.pad(item.product.quantity ?? 0, 3)}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"Date Added"}
                    value={
                      created_date.month +
                      "/" +
                      created_date.day +
                      "/" +
                      created_date.year
                    }
                  />
                </UI.Row>
              </UI.Row>
              {UI.box(10)}
              <Text style={styles.details_name}>Product Description</Text>

              <Text
                style={{
                  ...styles.patient_name,
                  color: "#454545",
                  fontSize: 14,
                }}
              >
                {item.product.description}
              </Text>
            </View>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, marginTop: 20 }}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Product Inquiry
            </Text>

            <Text style={styles.text_2}>View Product Inquiry Here</Text>
          </UI.Row>

          <UI.Row breakpoint={1010} _this={_this} style={{ width: "100%" }}>
            <UI.Row
              _this={_this}
              breakpoint={480}
              style={{
                paddingTop: 20,
                paddingBottom: 20,
                alignItems: "center",
              }}
            >
              <img
                style={{ height: 60, width: 60, borderRadius: 5 }}
                src={customer_image}
                alt="Customer"
              />

              {UI.box(15)}

              <View>
                <Text style={styles.patient_name}>
                  Product Inquiry by: {item.customer.name}
                </Text>
                <Text style={styles.patient_details}>
                  {item.customer.email} | {item.customer.phone}
                </Text>
              </View>
            </UI.Row>

            {UI.box(width <= 1010 ? 0 : 30)}

            <UI.Row
              breakpoint={1280}
              _this={_this}
              style={{ flexDirection: "row", flex: 1 }}
            >
              <View style={{ ...styles.fl_ai, flex: 1 }}>
                <Image
                  height={25}
                  width={25}
                  tintColor={"transparent"}
                  source={UI.DETAILS_AGE}
                />
                {UI.box(10)}

                <Text style={styles.details_value}>
                  Transaction Date:{" "}
                  {transaction_date.month +
                    "/" +
                    transaction_date.day +
                    "/" +
                    transaction_date.year}
                </Text>
              </View>
              {UI.box(10)}
              <View style={{ ...styles.fl_ai, flex: 1 }}>
                <Image
                  height={25}
                  width={25}
                  tintColor={"transparent"}
                  source={UI.DETAILS_AGE}
                />
                {UI.box(10)}

                <Text style={styles.details_value}>
                  Inquiry Date:{" "}
                  {inquiry_date.month +
                    "/" +
                    inquiry_date.day +
                    "/" +
                    inquiry_date.year}
                </Text>
              </View>
            </UI.Row>
          </UI.Row>

          {UI.box(10)}

          <Text style={styles.sub_header}>Customer Inquiry</Text>

          <View style={styles.inquiry_container}>
            <Text style={styles.inquiry_description}>{item.description}</Text>
          </View>
        </View>
      </MainLayout>
    );
  }
}
const UserDetail = (props = { icon: "", name: "", value: "" }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
        flex: 1,
      }}
    >
      <View style={{ ...styles.fl_ai, width: 140 }}>
        <View style={styles.detail_icon}>
          <Image
            height={16}
            width={16}
            tintColor={UI.colors.primary}
            source={props.icon}
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  inquiry_description: {
    color: "#454545",
    fontSize: 16,
    fontWeight: "bold",
  },
  sub_header: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  inquiry_container: {
    minHeight: 120,
    width: "100%",
    borderRadius: 10,
    borderWidth: 2,
    borderColor: "#C9C9C9",
    marginTop: 10,
    borderStyle: "dashed",
    padding: 12,
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  product_stat_item: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  product_stats_text: {
    color: UI.colors.primary,
    fontWeight: "bold",
    fontSize: 32,
    marginLeft: 15,
    flex: 1,
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
