import TouchableOpacity from "./Components/TouchableOpacity/js";
import ScrollView from "./Components/ScrollView/js";
import View from "./Components/View/js";
import List from "./Components/List/js";
import ImageBackground, { ImageFit, Image } from "./Components/Image/js";
import Text from "./Components/Text/js";
import r from "./Components/Request/js";
import Shadow from "./Components/Shadow/js";
import StyleSheet from "./Components/StyleSheet/js";
import mem from "./Components/Memory/js";
import Wrap from "./Components/Wrap/js";
import NoDataTable from "./Components/NoDataTable";
import IconTint from "react-icon-tint";
const Icon = IconTint;

export default TouchableOpacity;
export { TouchableOpacity };
export { ScrollView };
export { View };
export { List };
export { Image };
export { ImageBackground };
export { ImageFit };
export { Text };
export { StyleSheet };
export { Icon };
export { r };
export { Shadow };
export { Wrap };
export { mem };
export { NoDataTable };
