import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  ImageBackground,
  ImageFit,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm, { Checkbox } from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import "./css.css";

const context = "admin";

export default class AdminEditSpecialty extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "App Content",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      label: "",
      system: "",
      code: "",

      status: "",
      professionalType:"",
      icon_url: UI.PRODUCT_BOX,

      id: "",
    });
  }

  onCreate = async () => {
    this.load_data();
  };

  load_data = async () => {
    const data = mem.get("view_specialization");
    if (data) {
      const record = JSON.parse(data);

      UI.set_input_value("label", record.label ?? "");
      UI.set_select_value("status", record.status ?? "");
      // UI.set_input_value("system", record.system ?? "");
      // UI.set_input_value("code", record.code ?? "");
      this.setState({
        id: record._id,
        icon_url: record.icon,
        professionalType: record.professionalType ?? "",
      });

      /* 
      this.setState({
        id: merchant._id,
        image: image,
        imagePath,
        imageFilename,
      }); */
    }
  };

  submit = async () => {
    UI.clear_errors();
    const { label, status, professionalType, id, icon_url, icon_path } = this.state;
    console.log(  {
      label: label,
      status: status,
      professionalType: professionalType,
      imagePath: icon_url,
    })

    if (!label) {
      await UI.error_form("label", "Specialty Name is required.");
    }

    if (!icon_url) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Upload Icon Specialty"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      return;
    }

    if (!professionalType) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Professional Type is required"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      return;
    }

    if (!status) {
      await UI.error_form("status", "Status is required.");
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    this.show_loading();
    UI.webRequest({
      method: "put",
      url: "admin/specializations/" + id,
      params: {
        label: label,
        status: status,
        professionalType: professionalType,
        imagePath: icon_path,
      },
      isMultiPart: false,
      onFail: (err) => {
        console.log(err);
        const data = err.data;
        const error_message = data.error;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (response) => {
        console.log(response);
        UI.clear_forms();
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully Updated the Specialty"}
            onDismiss={() => {
              UI.clear_forms();
              UI.goBack();
              this.hide_modal();
            }}
          />
        );
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  auth_upload = async (state_name, file, callback) => {
    console.log(file)
    UI.upload_image({
      url: "admin/specializations/upload",
      file: file,
      params:{
        id:this.state.id
      },
      onFail: (err) => {
        this.show_modal(
          <Failed
            title={"Failed"}
            description={"Upload Failed. Application Error."}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (path, response) => {
        const data = UI.extract_data(response);
        mem.update("view_specialization", "icon", data.url)

        this.setState(
          {
            [state_name]: data.url,
            icon_path: path
          },
          // () => {
          //   if (callback) {
          //     callback(path);
          //   }
          //   this.process_file(state_name, file, path);
          // }
        );
      },
    });
  };

  pick_image = async () => {
    // const _this = this.props._this;
    let image = await UI.get_image();
    this.setState(
      {
        icon: image.uri,
        icon_file: image.file,
        image_file: image.file,
      },
      () => {
        this.auth_upload("icon_url", image.file);
      }
    );
    this.setState({
      image_key: "key_" + Date.now(),
    });
  };
  
  render() {
    const _this = this;
    const {  did_upload } = _this.state;

    return (
      <MainLayout context={context} additional={"specialties"} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Add Specialty
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add item
            </Text>
          </UI.Row>
          <UI.Row breakpoint={970} _this={_this}>
              <View style={{ height: 120, width: 120 }}>
                <Layout.FilePicker
                  _this={_this}
                  title={""}
                  additional={""}
                  // isRequired={true}
                  text={""}
                  state_name={"icon_specialty"}
                  onChange={async (file) => {
                    // this.pick_image();

                    // const uri = await UI.get_uri_from_file(file);
                    // this.setState(
                    //   {
                    //     image: uri,
                    //     image_file: file,
                    //     did_upload: true,
                    //   }
                    // );
                  }}
                  height={120}
                  component={
                    <ImageBackground
                      style={{ height: 120, width: 120, borderRadius: 10 }}
                      source={this.state.icon_url}
                      imageFit={ImageFit.CONTAIN}
                    />
                  }
                />
              </View>
              {UI.box(20)}
              <strong className="text-red-500">*</strong>
              <View style={{ flex: 1.5, justifyContent: "center" }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
                >
                  {did_upload
                    ? "Specialty Icon Uploaded."
                    : "No Icon Uploaded."}{" "}
                  {/* No Icon Uploaded.  */}
                  Recommended Size: 500 px x 500 px
                </Text>
                {UI.box(10)}

                <Layout.Btn
                  color={UI.colors.secondary}
                  text={"Upload Quicklink/Button Icon"}
                  onClick={() => {
                    this.pick_image();

                    // this.upload_picture();
                  }}
                  width={220}
                />
              </View>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
          
            <View style={{ flex: 1 }}>
              <Text style={{fontSize: 14, fontWeight: "bold", color: UI.colors.primary,}}>
                Professional Type <strong className="text-red-500">*</strong>
              </Text>
              {UI.box(8)}

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Checkbox
                  _this={_this}
                  text={"Medical"}
                  onClick={(value) => {
                    _this.setState({
                      professionalType: _this.state.professionalType === "Medical" ?  "" : "Medical",
                    });
                  }}
                  is_checked={_this.state.professionalType === "Medical" ? true : false}
                  color={"#2E2E2E"}
                />
                <View style={{ width: 50 }}></View>
                <Checkbox
                  _this={_this}
                  text={"Non Medical"}
                  onClick={(value) => {
                    _this.setState({
                      professionalType: _this.state.professionalType === "Non Medical"  ?  "" : "Non Medical" ,
                    });
                  }}
                  is_checked={_this.state.professionalType === "Non Medical" ? true : false}
                  color={"#2E2E2E"}
                />
              </View>
            </View>
          </UI.Row>

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Specialty Name"}
              placeholder={"Enter Specialty Name"}
              state_name={"label"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.name}
            />
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={true}
              state_name={"status"}
            >
              <option value="">Item Status (Active or Inactive)</option>
              <option value="Active" selected={this.state.status ? true : false}>Active</option>
              <option value="Inactive" selected={this.state.status ? true : false}>Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style = {{flex: 1}}></View>
         {/*    {UI.box(10)}

            <InputForm
              _this={_this}
              title={"System"}
              placeholder={"hipaa"}
              state_name={"system"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.code}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Code"}
              placeholder={"171100000X"}
              state_name={"code"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.code}
            /> */}

            <View style={{ flex: 1 }}></View>
          </UI.Row>

          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"ADD ITEM"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
          {/* {UI.box(10)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Specialty Name"}
              placeholder={"Enter Specialty Name"}
              state_name={"label"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.name}
            />
            {UI.box(10)}

            <View style={{ flex: 1 }}></View>
            <View style={{ flex: 1 }}></View>
          </UI.Row>

          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"UPDATE ITEM"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View> */}
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
