import Icon from "@ant-design/icons";
import React from "react";

const DeclinedCrossBannerSVG = () => (
  <svg
    id="gfx"
    xmlns="http://www.w3.org/2000/svg"
    width="218.852"
    height="157.486"
    viewBox="0 0 218.852 157.486"
  >
    <rect
      id="Rectangle_239"
      data-name="Rectangle 239"
      width="218.852"
      height="48.664"
      rx="24"
      transform="translate(0 108.822)"
      fill="rgba(67,200,255,0.1)"
    />
    <path
      id="Path_3362"
      data-name="Path 3362"
      d="M60.041,188.6a3.59,3.59,0,0,1-1.274-2.1l-1.37-5.792c-.43-.148-.854-.3-1.272-.476s-.819-.334-1.19-.5L49.9,182.834a3.73,3.73,0,0,1-2.352.586,3.467,3.467,0,0,1-2.18-1.063L41.787,178.8a3.628,3.628,0,0,1-1.048-2.224,3.535,3.535,0,0,1,.63-2.392l3.048-5c-.168-.392-.331-.784-.491-1.176s-.3-.793-.433-1.2l-5.818-1.367a3.465,3.465,0,0,1-2.1-1.248,3.678,3.678,0,0,1-.755-2.334v-5.008a3.51,3.51,0,0,1,2.854-3.553l5.763-1.37a8.652,8.652,0,0,1,.462-1.286c.177-.392.34-.766.488-1.121l-3.074-5.121a3.612,3.612,0,0,1-.644-2.375,3.3,3.3,0,0,1,1.089-2.154l3.611-3.58a3.639,3.639,0,0,1,4.419-.56l5.118,3.19c.374-.189.775-.369,1.2-.549s.856-.34,1.286-.491l1.37-5.818a3.628,3.628,0,0,1,1.274-2.081,3.576,3.576,0,0,1,2.308-.769h5.118a3.588,3.588,0,0,1,2.308.769,3.5,3.5,0,0,1,1.245,2.081l1.4,5.876c.45.151.877.308,1.289.476s.793.345,1.147.531l5.2-3.217a3.628,3.628,0,0,1,2.267-.462,3.9,3.9,0,0,1,2.1.993l3.638,3.609a3.249,3.249,0,0,1,1.077,2.154,3.9,3.9,0,0,1-.6,2.375l-3.106,5.121c.148.354.308.729.476,1.121s.325.819.476,1.286l5.789,1.37a3.514,3.514,0,0,1,2.825,3.553v5.008a3.676,3.676,0,0,1-.752,2.334,3.459,3.459,0,0,1-2.073,1.248L86.323,166.8c-.131.412-.276.813-.435,1.2s-.331.784-.517,1.176l3.077,5a3.627,3.627,0,0,1,.645,2.392,3.5,3.5,0,0,1-1.065,2.224l-3.58,3.553a3.481,3.481,0,0,1-2.183,1.063,3.722,3.722,0,0,1-2.349-.586l-5.063-3.106c-.375.168-.766.337-1.176.5s-.83.328-1.26.476l-1.4,5.792a3.47,3.47,0,0,1-1.245,2.1,3.643,3.643,0,0,1-2.308.755H62.349A3.632,3.632,0,0,1,60.041,188.6Zm9.749-20.9a9.943,9.943,0,0,0,3.524-3.539,9.473,9.473,0,0,0,1.315-4.909,9.263,9.263,0,0,0-1.315-4.854,10.027,10.027,0,0,0-3.524-3.51,9.691,9.691,0,0,0-9.749,0,10.181,10.181,0,0,0-3.527,3.51,9.192,9.192,0,0,0-1.327,4.854,9.473,9.473,0,0,0,1.315,4.909,9.92,9.92,0,0,0,3.524,3.539,9.717,9.717,0,0,0,9.763,0Z"
      transform="translate(104.593 -129.208)"
      fill="#e00017"
      opacity="0.24"
    />
    <path
      id="Path_3363"
      data-name="Path 3363"
      d="M60.041,188.6a3.59,3.59,0,0,1-1.274-2.1l-1.37-5.792c-.43-.148-.854-.3-1.272-.476s-.819-.334-1.19-.5L49.9,182.834a3.73,3.73,0,0,1-2.352.586,3.467,3.467,0,0,1-2.18-1.063L41.787,178.8a3.628,3.628,0,0,1-1.048-2.224,3.535,3.535,0,0,1,.63-2.392l3.048-5c-.168-.392-.331-.784-.491-1.176s-.3-.793-.433-1.2l-5.818-1.367a3.465,3.465,0,0,1-2.1-1.248,3.678,3.678,0,0,1-.755-2.334v-5.008a3.51,3.51,0,0,1,2.854-3.553l5.763-1.37a8.652,8.652,0,0,1,.462-1.286c.177-.392.34-.766.488-1.121l-3.074-5.121a3.612,3.612,0,0,1-.644-2.375,3.3,3.3,0,0,1,1.089-2.154l3.611-3.58a3.639,3.639,0,0,1,4.419-.56l5.118,3.19c.374-.189.775-.369,1.2-.549s.856-.34,1.286-.491l1.37-5.818a3.628,3.628,0,0,1,1.274-2.081,3.576,3.576,0,0,1,2.308-.769h5.118a3.588,3.588,0,0,1,2.308.769,3.5,3.5,0,0,1,1.245,2.081l1.4,5.876c.45.151.877.308,1.289.476s.793.345,1.147.531l5.2-3.217a3.628,3.628,0,0,1,2.267-.462,3.9,3.9,0,0,1,2.1.993l3.638,3.609a3.249,3.249,0,0,1,1.077,2.154,3.9,3.9,0,0,1-.6,2.375l-3.106,5.121c.148.354.308.729.476,1.121s.325.819.476,1.286l5.789,1.37a3.514,3.514,0,0,1,2.825,3.553v5.008a3.676,3.676,0,0,1-.752,2.334,3.459,3.459,0,0,1-2.073,1.248L86.323,166.8c-.131.412-.276.813-.435,1.2s-.331.784-.517,1.176l3.077,5a3.627,3.627,0,0,1,.645,2.392,3.5,3.5,0,0,1-1.065,2.224l-3.58,3.553a3.481,3.481,0,0,1-2.183,1.063,3.722,3.722,0,0,1-2.349-.586l-5.063-3.106c-.375.168-.766.337-1.176.5s-.83.328-1.26.476l-1.4,5.792a3.47,3.47,0,0,1-1.245,2.1,3.643,3.643,0,0,1-2.308.755H62.349A3.632,3.632,0,0,1,60.041,188.6Zm9.749-20.9a9.943,9.943,0,0,0,3.524-3.539,9.473,9.473,0,0,0,1.315-4.909,9.263,9.263,0,0,0-1.315-4.854,10.027,10.027,0,0,0-3.524-3.51,9.691,9.691,0,0,0-9.749,0,10.181,10.181,0,0,0-3.527,3.51,9.192,9.192,0,0,0-1.327,4.854,9.473,9.473,0,0,0,1.315,4.909,9.92,9.92,0,0,0,3.524,3.539,9.717,9.717,0,0,0,9.763,0Z"
      transform="translate(-22.827 -108.911)"
      fill="#e00017"
      opacity="0.08"
    />
    <path
      id="Path_3366"
      data-name="Path 3366"
      d="M128.586,218.344q-3.574-3.591-3.583-10.959v-11.09a3.288,3.288,0,0,0-.939-2.35l-7.844-7.9q-5.236-5.178-5.233-10.2t5.233-10.254l7.844-7.9a3.125,3.125,0,0,0,.939-2.3V144.255q0-7.426,3.561-10.96t10.927-3.528h11.144a3.129,3.129,0,0,0,2.3-.945l7.9-7.844q5.227-5.227,10.254-5.254t10.194,5.254l7.9,7.844a3.294,3.294,0,0,0,2.35.945h11.09q7.377,0,10.96,3.583t3.583,10.905v11.139a3.108,3.108,0,0,0,.945,2.3l7.844,7.9q5.178,5.236,5.2,10.254t-5.2,10.2l-7.844,7.9a3.271,3.271,0,0,0-.945,2.35v11.09q0,7.377-3.583,10.959t-10.96,3.583h-11.09a3.311,3.311,0,0,0-2.35.939l-7.9,7.849q-5.17,5.178-10.194,5.2t-10.254-5.2l-7.9-7.849a3.145,3.145,0,0,0-2.3-.939H139.491Q132.171,221.927,128.586,218.344Zm29.633-21.789,12.919-12.963,12.973,12.963a5.122,5.122,0,0,0,3.87,1.569,5.358,5.358,0,0,0,5.439-5.439,4.951,4.951,0,0,0-1.623-3.762L178.775,175.9l13.077-13.028a5.432,5.432,0,0,0,.027-7.61,5.175,5.175,0,0,0-3.794-1.542,5.252,5.252,0,0,0-3.87,1.569l-13.076,13.022-13.022-12.973a5.45,5.45,0,0,0-3.925-1.569,5.138,5.138,0,0,0-3.789,1.547,5.209,5.209,0,0,0-1.547,3.843,5.1,5.1,0,0,0,1.569,3.713L163.5,175.9l-13.077,13.076a5.083,5.083,0,0,0-1.569,3.707,5.28,5.28,0,0,0,1.547,3.87,5.075,5.075,0,0,0,3.789,1.569A5.53,5.53,0,0,0,158.219,196.555Z"
      transform="translate(-61.647 -95.427)"
      fill="#e00017"
    />
  </svg>
);

const DeclinedModalCrossIcon = (props) => {
  return <Icon component={DeclinedCrossBannerSVG} {...props} />;
};

export default DeclinedModalCrossIcon;
