import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  ImageBackground,
  ImageFit,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
// import "./css.css";

const context = "finance";

export default class FinanceUserGroupSubscriptionCreate extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "User Group",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      name: "",
      amount: "",
      image: UI.PRODUCT_BOX,
      image_file:"",
    });
  }

  onCreate = () => {};

  // pick_image = async () => {
  //   let image = await UI.get_image();
  //   this.auth_upload("icon_url", image.file);
  // };

  // auth_upload = async (state_name, file, callback) => {
  //   UI.upload_image({
  //     url: "admin/specializations/upload",
  //     file: file,
  //     onFail: (err) => {
  //       this.show_modal(
  //         <Failed
  //           title={"Failed"}
  //           description={"Upload Failed. Application Error."}
  //           onDismiss={() => {
  //             this.hide_modal();
  //           }}
  //         />
  //       );
  //     },
  //     onSuccess: (path, response) => {
  //       const data = UI.extract_data(response);

  //       console.log(path)
  //       console.log(response)
  //       this.setState(
  //         {
  //           [state_name]: data.url,
  //           icon_path: path
  //         },
  //         // () => {
  //         //   if (callback) {
  //         //     callback(path);
  //         //   }
  //         //   this.process_file(state_name, file, path);
  //         // }
  //       );
  //     },
  //   });
  // };

  submit = async () => {
    UI.clear_errors();
    const { 
      name, 
      amount, 
      image_file, 
      description, 
      // image_url, 
      // image_path
    } = this.state;

    console.log(name, amount, description, image_file);
    return
    // if (!image_url) {
    //   this.show_modal(
    //     <Failed
    //       title={"Failed"}
    //       description={"Upload Image Subscription"}
    //       onDismiss={() => {
    //         this.hide_modal();
    //       }}
    //     />
    //   );
    //   return;
    // }

    // if (!name) {
    //   await UI.error_form("name", "Subscription Name is required.");
    // }

    // if (!amount) {
    //   await UI.error_form("amount", "Amount is required.");
    // }

    // if (!description) {
    //   await UI.error_form("description", "Description is required.");
    // }

    // const error_count = this.state.error_count;
    // if (error_count > 0) {
    //   return;
    // }

    // this.show_loading();
    // UI.webRequest({
    //   method: "post",
    //   url: "admin/specializations",
    //   params: {
    //     name: name,
    //     amount: amount,
    //     description:description,
    //     imagePath: image_file,
    //   },
    //   isMultiPart: false,
    //   onFail: (err) => {
    //     console.log(err);
    //     const data = err.data;
    //     const error_message = data.error;
    //     this.show_modal(
    //       <Failed
    //         title={"Failed"}
    //         description={error_message}
    //         onDismiss={() => {
    //           this.hide_modal();
    //         }}
    //       />
    //     );
    //   },
    //   onSuccess: (response) => {
    //     console.log(response);
    //     UI.clear_forms();
    //     this.show_modal(
    //       <Success
    //         title={"Success!"}
    //         description={"Successfully Added the Specialty"}
    //         onDismiss={() => {
    //           UI.clear_forms();
    //           this.hide_modal();
    //         }}
    //       />
    //     );
    //   },
    //   onFinish: async () => {
    //     this.hide_loading();
    //   },
    // });
  };
  
  upload_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        image: file.uri,
        image_file: file.file,
        did_upload: true,
      }
    );

    console.log(this.state.image_file)
  };
  
  render() {
    const _this = this;
    const {  did_upload } = _this.state;

    return (
      <MainLayout context={context} additional={"subscriptions"} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Add Subscription
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add item
            </Text>
          </UI.Row>
          {UI.box(10)}
          <UI.Row breakpoint={970} _this={_this}>
              <View style={{ height: 120, width: 120 }}>
                <Layout.FilePicker
                  _this={_this}
                  title={""}
                  additional={""}
                  // isRequired={true}
                  text={""}
                  state_name={"icon_specialty"}
                  onChange={async (file) => {
                    // this.pick_image();

                    // const uri = await UI.get_uri_from_file(file);
                    // this.setState(
                    //   {
                    //     image: uri,
                    //     image_file: file,
                    //     did_upload: true,
                    //   }
                    // );
                  }}
                  height={120}
                  component={
                    <ImageBackground
                      style={{ height: 120, width: 120, borderRadius: 10 }}
                      source={this.state.image}
                      imageFit={ImageFit.CONTAIN}
                    />
                  }
                />
              </View>
              {UI.box(20)}
              <strong className="text-red-500">*</strong>
              <View style={{ flex: 1.5, justifyContent: "center" }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
                >
                  {did_upload
                    ? "Subscription Picture Uploaded."
                    : "No Subscription Picture Uploaded."}{" "}
                  {/* No Icon Uploaded.  */}
                  Recommended Size: 500 px x 500 px
                </Text>
                {UI.box(10)}

                <Layout.Btn
                  color={UI.colors.secondary}
                  text={"Upload Subscription Picture"}
                  onClick={() => {
                    // this.pick_image();

                    this.upload_picture();
                  }}
                  width={220}
                />
              </View>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Subscription Name"}
              placeholder={"Enter Subscription Name"}
              state_name={"name"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.name}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Amount"}
              placeholder={"Enter Amount"}
              state_name={"amount"}
              style={{ flex: 1 }}
              isRequired={true}
              isNumber={true}
              maxLength={UI.Constants.limits.price}
            />
            {UI.box(10)}
            <View style = {{flex: 1}}></View>
          </UI.Row>

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Description"}
              placeholder={"Enter Description Here"}
              state_name={"description"}
              style={{ flex: 1 }}
              height={100}
              isRequired={false}
              type={"big"}
              maxLength={UI.Constants.limits.description}
            />
          </UI.Row>

          {UI.box(20)}
          
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"SAVE ITEM"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
