import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import "./css.css";

const context = "finance";
export default class FinanceEditWithholding extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Withholding Tax",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      context: context,

      gender: "",
      birthdate: "",
      qualification: "",
      specialty: "",
      hospital: "",
      prc_number: "",
      prc_start_date: "",
      prc_end_date: "",

      name: "",
      email: "",
      phone: "",

      status: "",

      picture: UI.DOCTOR_PROFILE,
      prcLicensePhoto: "",
      signature: "",
      mpc: "",
      moaPath: "",

      picture_filename: "",
      prcLicensePhoto_filename: "",
      signature_filename: "",
      moa_filename: "",
      mpc_filename: "",

      picture_size: "",
      prcLicensePhoto_size: "",
      signature_size: "",
      moa_size: "",
      mpc_size: "",

      remarks: "",
    });
  }

  onCreate = () => {
    this.load_data();
  };

  load_data = async () => {
    const data = mem.get("edit_doctor");
    if (data) {
      const doctor = JSON.parse(data);

      let { picture, prcLicensePhoto, signature, moaPath, mpc } = doctor;

      if (doctor.provider) {
        if (doctor.provider.remarks) {
          UI.set_input_value("remarks", doctor.provider.remarks);
        }
      }

      UI.set_input_value("withholding", doctor.withholding);

      let picture_filename = UI.container_filename(picture);
      let prcLicensePhoto_filename = UI.container_filename(prcLicensePhoto);
      let signature_filename = UI.container_filename(signature);
      let moa_filename = UI.container_filename(moaPath);
      let mpc_filename = UI.container_filename(mpc);

      let status_value = "";
      if (doctor.provider.isDoctorsOnboardingApproved === "Approved") {
        status_value = "true";
      } else if (doctor.provider.isDoctorsOnboardingApproved === "Disapproved") {
        status_value = "false";
      } else {
        status_value = "";
      }

      console.log(doctor);

      UI.set_select_value("status", status_value);

      this.setState({
        mpc_filename,
        picture_filename,
        prcLicensePhoto_filename,
        signature_filename,
        moa_filename,
        gender: UI.DataHelper.title(doctor.gender),
        birthdate: UI.DataHelper.carespan.get_date(doctor.birthDate),
        qualification: UI.DataHelper.carespan.get_date(
          doctor.qualificationDate
        ) /* 
        specialty: UI.DataHelper.carespan.get_specialty(
          doctor.fieldSpecialties
        ), */,
        specialty: UI.DataHelper.carespan.get_specialties(
          doctor.fieldSpecialties
        ),
        hospital: "",
        prc_number: doctor.prcLicenseNumber,
        prc_start_date: UI.DataHelper.carespan.get_date(
          doctor.prcLicenseStartDate
        ),
        prc_end_date: UI.DataHelper.carespan.get_date(doctor.prcLicenseEndDate),

        name: UI.DataHelper.carespan.get_name(doctor.name),
        email: doctor.provider.email,
        // email: UI.DataHelper.carespan.get_email(doctor.telecom),
        phone: "+63 " + UI.DataHelper.carespan.get_phone(doctor.telecom),
        id: doctor.provider._id,
      });
    }
  };

  submit = async () => {
    UI.clear_errors();
    //no merchant yet

    const { status, id, remarks } = this.state; // can be "true" or "false"

    if (!status) {
      await UI.error_form("status", "Status is required.");
    }

    //when a doctor is being disapproved
    if (status !== "true") {
      if (!remarks) {
        await UI.error_form("remarks", "Remarks is required.");
      }
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    this.show_loading();

    UI.webRequest({
      method: "put",
      url: "admin/doctors/approved/" + id,
      params: {
        approved: status === "true" ? "Approved" : "Disapproved",
        remarks: status === "true" ? "" : remarks,
      },
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.error;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        const status =
          this.state.status === "false" ? "disapproved" : "approved";
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Doctor is successfully " + status + "."}
            onDismiss={() => {
              this.hide_modal();
              UI.goBack();
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;
    const {
      //  height, 
      width, 
      // isMobile 
    } = _this.state;

    const {
      gender,
      birthdate,
      qualification,
      specialty,
      // hospital,
      prc_number,
      prc_start_date,
      prc_end_date,
      name,
      email,
      phone,
      picture,
      // prcLicensePhoto,
      // signature,
      // picture_filename,
      // prcLicensePhoto_filename,
      // signature_filename,
      // picture_size,
      // prcLicensePhoto_size,
      // signature_size,

      // moa_filename,
      // moa_size,
      // moaPath,

      // mpc_filename,
      // mpc_size,
      // mpc,
    } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                borderRightWidth: width >= 1250 ? 2 : 0,
                borderBottomWidth: width < 1250 ? 2 : 0,
                paddingRight: width < 1250 ? 0 : 20,
                marginRight: width < 1250 ? 0 : 20,
                paddingBottom: 10,
                marginBottom: width < 1250 ? 10 : 0,
              }}
            >
              <View style={styles.fl_ai}>
                <img
                  style={{ height: 120, width: 120, borderRadius: 5 }}
                  src={picture}
                  alt="Patient"
                />

                {UI.box(width < 980 ? 5 : 10)}

                <View>
                  <Text style={styles.patient_name}>{name}</Text>
                  <Text style={styles.patient_details}>{email}</Text>
                  <Text style={styles.patient_details}>{phone}</Text>
                </View>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <UI.Row
                breakpoint={980}
                breakpoint_2={720}
                breakpoint_3={590}
                _this={_this}
              >
                <View style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.DETAILS_GENDER}
                    name={"Gender"}
                    value={gender}
                  />
                  <UserDetail
                    icon={UI.DETAILS_BIRTHDATE}
                    name={"Birthdate"}
                    value={birthdate}
                  />
                  <UserDetail
                    icon={UI.DETAIL_QUALIFICATION}
                    name={"Qualification"}
                    value={qualification}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.DETAILS_PRC}
                    name={"PRC Number"}
                    value={prc_number}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"PRC Start Date"}
                    value={prc_start_date}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"PRC End Date"}
                    value={prc_end_date}
                  />
                </View>
              </UI.Row>
              <UserDetail
                icon={UI.DETAILS_SPECIALIZATION}
                name={"Specialty"}
                value={specialty}
              />
            </View>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, marginTop: 20 }}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Update Doctor Withholding Tax
            </Text>
          </UI.Row>

          {UI.box(20)}

          <View style={{ alignSelf: "flex-start", width: 280 }}>
            <InputForm
              _this={_this}
              title={"Withholding Tax %"}
              placeholder={"Enter Withholding Tax %"}
              state_name={"withholding"}
              style={{ flex: 1 }}
              isNumber={true}
              isRequired={false}
              maxLength={3}
            />
          </View>

          {UI.box(20)}

          <View style={{ width: width > 720 ? 300 : "100%" }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Layout.Btn
                color={UI.colors.primary}
                onClick={() => {
                  this.show_loading();
                  setTimeout(async () => {
                    let list = mem.get("withholding_list");
                    if (!list) {
                      list = "[]";
                    }

                    list = JSON.parse(list);
                    const isExist = list.filter(
                      (item) => item.id === this.state.id
                    );
                    if (isExist.length === 0) {
                      list.push({
                        id: this.state.id,
                        value: this.state.withholding,
                      });

                      mem.save("withholding_list", JSON.stringify(list));
                    }

                    UI.goBack();
                  }, 1000);
                }}
                text={"SAVE CHANGES"}
              />
              {UI.box(20)}

              <Layout.Btn
                color={"#E00000"}
                onClick={() => {
                  UI.goBack();
                }}
                text={"CANCEL"}
              />
            </View>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const UserDetail = (props = { icon: "", name: "", value: "" }) => {
  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}
    >
      <View style={{ width: 150, ...styles.fl_ai }}>
        <View style={styles.detail_icon}>
          <Image
            height={16}
            width={16}
            tintColor={UI.colors.primary}
            source={props.icon}
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
