import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import TouchableOpacity, {
  mem,
  ScrollView,
  Shadow,
  StyleSheet,
  View,
  Text,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
import Layout, { PreviewFile } from "../_components/Layout";
import Loading from "../_components/Loading";
import Modal, { Failed, Success } from "../_components/Modal";
import OnboardHeader from "../_components/OnboardHeader";
import "./css.css";
import FirstForm from "./FirstForm";
import FourthForm from "./FourthForm";
import Instructions from "./Instructions";
import MOA from "./MOA";
import { PRCValidator } from "./PRCValidator";
import ProfilePicture from "./ProfilePicture";
import SecondForm from "./SecondForm";
import ThirdForm from "./ThirdForm";
import AddressInformation from "./AddressInformation";
import PTRForm from "./PTRForm";
import S2Form from "./S2Form";

const context = "onboard";

const Constants = new APIConstants();
export default class OnboardCreateUser extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      show_loading: false,
      loading_title: "",
      loading_text: "",
      isAffiliated: false,
      profile_picture: false,
      professionalType: true,
      context: context,

      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      contact_no: "",
      dob: "",
      gender: "male",
      specialty: [],
      createUserLanguages: [],
      qualification_date: "",
      prc_number: "",
      license_start_date: "",
      license_end_date: "",
      consultation_fee: "",

      ptrNumber: "",
      ptr: "",

      s2Number: "",
      s2: "",

      license_photo_file: null,
      moa_file: null,
      moa_path: "",
      marketing_consent_path: "",

      mpc: "",
      mpc_file: null,

      image: "",
      image_file: null,

      imagePath: "",

      show_modal: false,
      modal_content: "",

      sample_image: UI.DEFAULT_HOSPITAL,

      profile_picture_path: "",
      prc_license_path: "",
      signature_path: "",
      cor: "",

      nutrition_price: "",

      // floor_number:"",
      house_number: "",
      street_name: "",
      barangay: "",
      city_municipality: "",
      province: "",
      zip_code: "",

      financialType: "",
      bankName: "",
      bankBranch: "",
      bankAccountName: "",
      bankAccountNumber: "",
      paymayaAccountNumber: "",

      file_prc_license_path: { url: null, extension: null, filename: "" },
      file_signature_path: { url: null, extension: null, filename: "" },
      file_moa_path: { url: null, extension: null, filename: "" },
      file_marketing_consent_path: { url: null, extension: null, filename: "" },
      file_mpc: { url: null, extension: null, filename: "" },
      downloaded_cor: { url: "", extension: "" },

      specializations: [],
      hospitals: [],

      createUserHospital: [],
      createUserMedicalAssociations: [],
      confirmMOA: false,
      confirmMarketingConsent: false,
      error_count: 0,

      otpCode: "",
      otpErrorMessage: "",
    });
  }
  show_loading = (title, text) => {
    this.setState({
      show_loading: true,
      loading_title: title,
      loading_text: text,
    });
  };

  hide_loading = () => {
    this.setState({
      show_loading: false,
    });
  };

  load_data = async () => {
    let data = await mem.get("onboard_data");

    if (data) {
      let doctor = JSON.parse(data);
      const { email, firstName, lastName } = doctor;
      UI.set_input_value("first_name", firstName ?? "");
      UI.set_input_value("last_name", lastName ?? "");
      UI.set_input_value("email", email ?? "");
      UI.set_input_value("contact_no", doctor.phoneNumber || doctor.phone);
      this.setState({ onboard_data: doctor });
    }
  };

  load_onboard_data = async () => {
    this.show_prc_validation();
    let data = await mem.get("create_user");

    if (data) {
      let doctor = JSON.parse(data);
      const { email, firstName, lastName } = doctor;

      // PROFILE PHOTO
      let image = await UI.download_image(
        doctor.doctor.picture,
        "doctors-onboarding"
      );
      this.setState({ profile_picture: image });
      this.download_file(doctor.doctor.picture, "file_profile_picture_path");

      // Professional Type
      this.setState({
        professionalType: doctor.doctor.professionalType
          ? "medical"
          : "Non medical",
      });

      this.setState({
        isAffiliated: doctor.doctor.hospitals.length > 0 ? true : false,
      });

      // PRC LICENSE PHOTO
      this.download_file(
        doctor.doctor.prcLicensePhoto,
        "file_prc_license_path"
      );
      this.setState({ prc_license_path: doctor.doctor.prcLicensePhoto });

      // FIRST FORM
      UI.set_input_value("first_name", doctor.doctor.name[0].given[0] ?? "");
      UI.set_input_value("last_name", doctor.doctor.name[0].family ?? "");
      UI.set_input_value("middle_name", doctor.doctor.name[0].given[1] ?? "");
      UI.set_input_value("email", email ?? "");
      UI.set_input_value("contact_no", doctor.doctor.telecom[0].value ?? "");
      if (doctor.doctor.birthDate) {
        UI.set_date_value("dob", new Date(doctor.doctor.birthDate));
      }

      UI.set_multiple_select_value(
        "createUserLanguages",
        doctor.doctor.languange
      );

      // SECOND FORM
      this.setState({ gender: doctor.doctor.gender ?? "male" });

      if (doctor.doctor.qualificationDate) {
        UI.set_date_value(
          "qualification_date",
          new Date(doctor.doctor.qualificationDate)
        );
      }

      if (doctor.doctor.prcLicenseStartDate) {
        UI.set_date_value(
          "license_start_date",
          new Date(doctor.doctor.prcLicenseStartDate),
          true
        );
      }

      if (doctor.doctor.prcLicenseEndDate) {
        UI.set_date_value(
          "license_end_date",
          new Date(doctor.doctor.prcLicenseEndDate),
          true
        );
      }

      UI.set_input_value("prc_number", doctor.doctor.prcLicenseNumber ?? "");

      // THIRD FORM
      UI.set_input_value(
        "consultation_fee",
        doctor.doctor.pricePerConsultation ?? ""
      );

      this.download_file(doctor.doctor.signature, "file_signature_path");
      this.download_file(doctor.doctor.certificateOfRegistration, "file_cor_path");

      this.setState({ signature_path: doctor.doctor.signature });
      this.setState({ cor_path: doctor.doctor.certificateOfRegistration });


      if (doctor.doctor.hasOwnProperty("moaPath")) {
        this.setState({
          moa_path: doctor.doctor?.moaPath,
          confirmMOA: doctor.doctor?.moaPath === " " ? false : true,
        });
      }

      if (doctor.doctor.hasOwnProperty("marketingConsentPath")) {
        this.setState({
          marketingConsentPath: doctor.doctor?.marketingConsentPath,
          confirmMarketingConsent:
            doctor.doctor?.marketingConsentPath === "" ? false : true,
        });
      }

      UI.set_input_value("ptr", "");
      UI.set_input_value("s2", "");

      // FOURTH FORM

      // UI.set_input_value('floor_number', doctor.doctor.address[0].floorNumber)
      UI.set_input_value(
        "house_number",
        doctor.doctor.address[0].houseNumber ?? ""
      );
      UI.set_input_value("street_name", doctor.doctor.address[0].streetName);
      UI.set_input_value("barangay", doctor.doctor.address[0].barangay);
      UI.set_input_value("city_municipality", doctor.doctor.address[0].city);
      UI.set_input_value("province", doctor.doctor.address[0].state);
      UI.set_input_value("zip_code", doctor.doctor.address[0].postalCode);

      const paymayaAccountNumber =
        doctor.doctor.financialInformation.mayaAccountNumber ?? "";

      if (doctor.doctor.financialInformation) {
        UI.set_select_value(
          "financialType",
          paymayaAccountNumber !== "" ? "paymaya" : "bank"
        );

        UI.set_input_value(
          "bankName",
          doctor.doctor.financialInformation.bankName ?? ""
        );
        UI.set_input_value(
          "bankBranch",
          doctor.doctor.financialInformation.bankBranch ?? ""
        );
        UI.set_input_value(
          "bankAccountName",
          doctor.doctor.financialInformation.bankAccountName ?? ""
        );
        UI.set_input_value(
          "bankAccountNumber",
          doctor.doctor.financialInformation.bankAccountNumber ?? ""
        );
        UI.set_input_value("paymayaAccountNumber", paymayaAccountNumber ?? "");
      }
      this.download_file(doctor.doctor.mpc, "file_mpc");
      this.setState({ mpc: doctor.doctor.mpc });
    }
  };

  download_file = async (path, state) => {
    if (path) {
      const ext = await UI.get_file_extension(path);
      const image_link = await UI.download_image(path, "doctors-onboarding");
      this.setState({
        [state]: {
          url: image_link,
          extension: ext,
          filename: UI.container_filename(path),
        },
      });
    }
  };

  onCreate = async () => {
    this.load_data();
    this.load_onboard_data();
    this.load_specialization();
    this.load_languages();
    this.load_hospitals();
    this.load_medical_associations();
    //this.show_instructions();
  };

  load_medical_associations = async () => {
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/medical-associations",
      onFail: (err) => {
        // console.log(err);
      },
      onSuccess: (response) => {
        const data = UI.extract_data(response);

        this.setState({
          options_medicalAssociations: data,
        });
      },
      onFinish: async () => {
        let data = await mem.get("create_user");

        if (data) {
          let doctor = JSON.parse(data);

          UI.set_multiple_select_value(
            "createUserMedicalAssociations",
            doctor.doctor.medicalAssociations
          );
        }
      },
    });
  };

  load_languages = async () => {
    this.setState({
      options_languages: Constants.languanges,
    });
  };

  show_instructions = async () => {
    this.show_modal(<Instructions _this={this} />);
  };

  show_prc_validation = () => {};

  load_specialization = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/doctors/specializations",
      params: {},
      onFail: (err) => {},
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        const new_data = data.sort(UI.dynamicSort("label"));
        this.setState(
          {
            specializations: new_data,
          },
          () => {
            this["reload_multiple_option_specialty"]();
          }
        );
      },
      onFinish: async () => {
        let data = await mem.get("create_user");

        if (data) {
          let doctor = JSON.parse(data);
          UI.set_multiple_select_value(
            "specialty",
            doctor.doctor.fieldSpecialties
          );
        }
        this.hide_loading();
      },
    });
  };

  load_hospitals = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/hospitals",
      params: {},
      onFail: (err) => {},
      onSuccess: (response) => {
        const data = UI.extract_data(response);

        this.setState({
          hospitals: data.data,
        });
      },
      onFinish: async () => {
        let data = await mem.get("create_user");

        if (data) {
          let doctor = JSON.parse(data);

          //Affiliations Hospital/Clinic
          const hospitals_ids = doctor.doctor.hospitals.map((item) => {
            return item._id;
          });

          UI.set_multiple_select_value(
            "createUserHospital",
            hospitals_ids ?? []
          );
        }

        this.hide_loading();
      },
    });
  };

  show_modal = (modal_content) => {
    this.setState({
      show_modal: true,
      modal_content: modal_content,
    });
  };

  hide_modal = () => {
    this.setState({
      show_modal: false,
      modal_content: "",
    });
  };

  failed_modal = (message) => {
    this.show_modal(
      <Failed
        title={"Failed"}
        description={message}
        onDismiss={() => {
          this.hide_loading();
          this.hide_modal();
        }}
      />
    );
  };

  save_as_draft = async () => {
    UI.clear_errors();

    let {
      first_name,
      middle_name,
      last_name,
      email,
      contact_no,
      dob_dashed,
      gender,
      specialty,
      qualification_date_date,
      prc_number,
      license_start_date_date,
      license_end_date_date,
      license_start_date_dashed,
      license_end_date_dashed,
      consultation_fee,
      imagePath,
      mpc,
      profile_picture_path,
      prc_license_path,
      signature_path,
      moa_path,
      marketing_consent_path,

      ptrNumber,
      ptr,

      s2Number,
      s2,

      // floor_number,
      house_number,
      street_name,
      barangay,
      city_municipality,
      province,
      zip_code,

      financialType,
      bankName,
      bankBranch,
      bankAccountName,
      bankAccountNumber,
      paymayaAccountNumber,
      createUserHospital,

      createUserLanguages,
      createUserMedicalAssociations,
      professionalType,
      cor_path
    } = this.state;

    this.show_loading("", "Please wait while we process your request...");

    let add = { createUserHospital };
    if (this.state.isAffiliated === false) {
      add = {};
    }

    let given = [first_name];
    if (middle_name) {
      given = [first_name, middle_name];
    }

    const params = {
      resourceType: "Practitioner",
      name: [
        {
          given: given,
          family: last_name,
          prefix: ["DR"],
        },
      ],
      hospital: this.state.isAffiliated === false ? [] : createUserHospital,
      qualification: [
        {
          identifier: [
            {
              use: "official",
              system: "https://online2.prc.gov.ph/Verification",
              value: prc_number,
            },
          ],
          code: {
            coding: [
              {
                system: "http://snomed.info/sct",
                code: "260385009",
                display: "MA",
              },
            ],
            text: "MA",
          },
          issuer: {
            display: "PH PRC",
          },
          period: {
            start: license_start_date_dashed,
            end: license_end_date_dashed,
          },
        },
      ],
      address: [
        {
          line: [`${house_number} ${street_name} ${barangay}`],
          // line: [`${floor_number} ${house_number} ${street_name} ${barangay}`],
          city: city_municipality,
          state: province,
          postalCode: zip_code,
          // floorNumber: floor_number,
          houseNumber: house_number,
          streetName: street_name,
          barangay: barangay,
        },
      ],
      telecom: [
        {
          system: "phone",
          value: contact_no,
          use: "home",
          rank: 1,
          period: {
            start: "2020-09-10",
            end: "2021-09-10",
          },
        },
        {
          system: "email",
          value: email,
          use: "email",
          rank: 1,
          period: {
            start: "2020-09-10",
            end: "2021-09-10",
          },
        },
      ],
      gender: gender,
      birthDate: dob_dashed,
      communication: [
        {
          coding: [
            {
              system: "urn:ietf:bcp:47",
              code: "en",
              display: "English",
            },
          ],
        },
      ],
      picture: profile_picture_path,
      professionalType: (professionalType === true || professionalType === "medical") ? "medical" : (professionalType === false || professionalType === "Non medical") ? "Non medical" : professionalType,
      fieldSpecialties: specialty,
      yearsExperience: 9,
      isMwellPlus: true,
      qualificationDate: qualification_date_date,
      prcLicenseNumber: prc_number,
      prcLicenseStartDate: license_start_date_date,
      prcLicenseEndDate: license_end_date_date,
      prcLicensePhoto: prc_license_path,
      signature: signature_path,
      certificateOfRegistration: cor_path,
      rating: 5,
      pricePerConsultation: consultation_fee,

      ptrNumber,
      ptr,

      s2Number,
      s2,

      imagePath: imagePath,
      moaPath: moa_path,
      marketingConsentPath: marketing_consent_path,

      // floor_number,
      house_number,
      street_name,
      barangay,
      city_municipality,
      province,
      zip_code,

      bankName: financialType === "bank" ? bankName : "",
      bankBranch: financialType === "bank" ? bankBranch : "",
      bankAccountName: financialType === "bank" ? bankAccountName : "",
      bankAccountNumber: financialType === "bank" ? bankAccountNumber : "",
      paymayaAccountNumber:
        financialType === "paymaya" ? paymayaAccountNumber : "",
      mpc,
      ...add,

      languange: createUserLanguages,
      medicalAssociations: createUserMedicalAssociations,
      isDraft: true,
    };

    UI.webRequest({
      method: "post",
      url: "doctors_onboarding/doctors/save-draft",
      params: params,
      onFail: (err) => {
        const data = err.data;
        let error_message = data.message;
        if (!error_message) {
          error_message = data.m;
        }

        if (
          data.c === 404 &&
          data.m ===
            "Admin emails not sent Someting wrong in the server please try again !!"
        ) {
          error_message =
            "But admin email is not sent. Something went wrong in the server. please Try again!!";
          this.setState({ savedButNoEmail: true });
        }

        if (data.c === 500) {
          error_message = "Server Error. Something went wrong in backend";
        }

        this.show_modal(
          <Failed
            title={"Failed"}
            savedButNoEmail={this.state.savedButNoEmail}
            unableToSaveAsDraft={this.state.savedButNoEmail ? false : true}
            description={error_message}
            onDismiss={() => {
              this.setState({ savedButNoEmail: false });
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: () => {
        this.show_modal(
          <Success
            title={"Congratulations dear Doctor!"}
            descriptionTop={
              "You are a few steps away from being part of our community. "
            }
            descriptionCenter={
              "We have saved all the information you shared and will continue to keep it up until you finish the rest of the fields needed to complete your onboarding."
            }
            descriptionBottom={""}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  submit = async () => {
    UI.clear_errors();
    let {
      first_name,
      middle_name,
      last_name,
      email,
      contact_no,
      dob_carespan,
      dob_timestamp,
      dob_dashed,
      gender,
      specialty,
      qualification_date_date,
      prc_number,

      license_start_date_date,
      license_end_date_date,

      license_start_date_dashed,
      license_end_date_dashed,

      consultation_fee,

      ptrNumber,
      ptr,

      s2Number,
      s2,

      imagePath,

      mpc,
      cor_path,

      profile_picture_path,
      prc_license_path,
      signature_path,
      nutrition_price,
      moa_path,
      marketing_consent_path,

      // floor_number,
      house_number,
      street_name,
      barangay,
      city_municipality,
      province,
      zip_code,

      bankName,
      bankBranch,
      bankAccountName,
      bankAccountNumber,

      paymayaAccountNumber,

      createUserHospital,

      createUserLanguages,
      createUserMedicalAssociations,
      professionalType,
    } = this.state;
    this.show_loading("", "Please wait while we process your request...");
    console.log(this.state.form_messages);

    const cd = new Date();
    const d = new Date();
    d.setFullYear(cd.getFullYear() - 20);

    if (d.getTime() < dob_timestamp) {
      await UI.error_form("dob", "You have to be at least 20 years old");
    }

    if (!first_name) {
      await UI.error_form("first_name", "First Name is required.");
    }

    if (!last_name) {
      await UI.error_form("last_name", "Last Name is required.");
    }

    if (!email) {
      await UI.error_form("email", "Email is required.");
    }

    if (email) {
      if (!UI.validate_email(email)) {
        await UI.error_form("email", "Please enter a valid email.");
      }
    }

    if (!contact_no) {
      await UI.error_form("contact_no", "Contact Number is required.");
    }
    if (contact_no.length !== 10) {
      await UI.error_form(
        "contact_no",
        "Format should be 9xxxxxxxxx is required."
      );
    }

    if (!prc_number) {
      await UI.error_form("prc_number", "PRC Number is required.");
    }
    if (prc_number.length !== 7) {
      await UI.error_form("prc_number", "PRC Number should be 7 digits.");
    }

    if (specialty.length === 0) {
      await UI.error_form("specialty", "Specialty is required.");
    }

    if (createUserLanguages.length === 0) {
      await UI.error_form("languages", "Language/Dialect is required.");
    }

    if (specialty.includes("Nutritionist")) {
      if (!nutrition_price) {
        await UI.error_form(
          "nutrition_price",
          "Nutrition Plan Price is required."
        );
      }
    }

    if (!consultation_fee) {
      await UI.error_form(
        "consultation_fee",
        Constants.consultation_fee_validation
      );
    } else {
      if (consultation_fee < Constants.consultation_fee) {
        await UI.error_form(
          "consultation_fee",
          Constants.consultation_fee_validation
        );
      }

      if (consultation_fee % 50 !== 0) {
        await UI.error_form(
          "consultation_fee",
          Constants.consultation_fee_validation
        );
      }
    }

    if (!dob_carespan) {
      await UI.error_form("dob", "Date of Birth is required.");
    }

    if (!license_start_date_date) {
      await UI.error_form(
        "license_start_date",
        "PRC License Start Date is required."
      );
    }

    if (!license_end_date_date) {
      await UI.error_form(
        "license_end_date",
        "PRC License End Date is required."
      );
    }

    if (!prc_license_path) {
      await UI.error_form("license_photo", "PRC License Photo is required.");
    }
    if (!signature_path) {
      await UI.error_form("signature_path", "Signature is required.");
    }

    if (!moa_path) {
      await UI.error_form(
        "moa_path",
        "Please sign the Memorandum of Agreement."
      );
    }
    // if (!floor_number) {
    //   await UI.error_form("floor_number", "Floor Number is required.");
    // }
    if (!street_name) {
      await UI.error_form("street_name", "Street Name is required.");
    }
    if (!barangay) {
      await UI.error_form("barangay", "Floor Number is required.");
    }
    if (!city_municipality) {
      await UI.error_form(
        "city_municipality",
        "City/Municipality  is required."
      );
    }
    if (!province) {
      await UI.error_form("province", "Province is required.");
    }
    if (!zip_code) {
      await UI.error_form("zip_code", "Zip Code is required.");
    }

    if (!this.state.financialType) {
      await UI.error_form(
        "financialType",
        "Financial Information is required."
      );
    } else {
      if (this.state.financialType === "bank") {
        if (!bankName) {
          await UI.error_form("bankName", "Bank Name is required.");
        }

        if (!bankBranch) {
          await UI.error_form("bankBranch", "Bank Branch is required.");
        }

        if (!bankAccountName) {
          await UI.error_form(
            "bankAccountName",
            "Name of the Branch is required."
          );
        }

        if (!bankAccountNumber) {
          await UI.error_form(
            "bankAccountNumber",
            "Bank Account Number is required."
          );
        }
      }

      if (this.state.financialType === "paymaya") {
        if (!paymayaAccountNumber) {
          await UI.error_form(
            "paymayaAccountNumber",
            "Maya Account Number is required."
          );
        }
      }
    }

    /*
    const date_difference = UI.date_diff(
      license_start_date_date,
      license_end_date_date
    );
    //365 days by 5 years 
    if (date_difference >= 0) {
      if (date_difference >= 365 * 5) {
      } else {
        await UI.error_form(
          "license_start_date",
          "License covered date should be at least 5 years."
        );
        await UI.error_form(
          "license_end_date",
          "License covered date should be at least 5 years."
        );
      }
    } else {
      await UI.error_form("license_start_date", "Invalid license start date.");
      await UI.error_form("license_end_date", "Invalid license end date.");
    }

    */

    const error_count = this.state.error_count;

    if (error_count > 0) {
      const initial = { 
        backgroundColor: "white",
        borderRadius: "18px",
        ...Shadow._3(),
        zIndex: 10003 + 1,
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "flex-start",
        overflowY: "auto",
        alignItems: "center",
        // padding: 20,
        width: 900,
        maxWidth: 1200,
      };
      this.hide_loading();
      setTimeout(() => {
        this.show_modal(
          <div className="" style={{
            ...initial,
          }}>
            <div style={{width:'100%'}}>
              <View
                style={{
                  ...styles.md_header,
                  // ...props.titleStyles ?? "",
                }}
                >
                <TouchableOpacity
                  onClick={() => {
                    this.hide_modal()
                  }}
                  style={styles.md_close}
                >
                  <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt=""/>
                </TouchableOpacity>

                <View style={styles.md_header_text_container}>
                  <Text style={styles.md_header_text}>Oops!</Text>
                </View>
                {UI.box(60)}
              </View>
              

              <View style={{width: "100%", gap: 15, padding: 30}}>

                <span className="text-black font-semibold">One of the required fields is empty or contains invalid data. Please check below:</span>

                <div className="grid grid-cols-2" >
                  {this.state.form_messages?.map((err) => {
                    const key = Object.keys(err);
                    return <span className="text-red-500 ">* {err[key]}</span>;
                  })}
                </div>
              </View>

            </div>
          </div>
          // <Failed
          //   title={"Failed"}
          //   description={<>
          //     {/* <p className="text-red-500">
          //       {this.state.error_count > 0 &&
          //         this.state.error_count +
          //           " required field are need to be filled up."}
          //     </p> */}

          //     {this.state.form_messages?.map((err) => {
          //       const key = Object.keys(err);
          //       return <span className="text-red-500 grid grid-cols-1">* {err[key]}</span>;
          //     })}
          //   </>}
          //   onDismiss={() => {
          //     this.hide_modal();
          //   }}
          // />
      );
      }, 100);
        
      // console.log(this.state);
      return;
    }

    let add = { createUserHospital };
    if (this.state.isAffiliated === false) {
      add = {};
    }

    let given = [first_name];
    if (middle_name) {
      given = [first_name, middle_name];
    }

    const params = {
      resourceType: "Practitioner",
      name: [
        {
          given: given,
          family: last_name,
          prefix: ["DR"],
        },
      ],
      hospital: this.state.isAffiliated === false ? [] : createUserHospital,
      qualification: [
        {
          identifier: [
            {
              use: "official",
              system: "https://online2.prc.gov.ph/Verification",
              value: prc_number,
            },
          ],
          code: {
            coding: [
              {
                system: "http://snomed.info/sct",
                code: "260385009",
                display: "MA",
              },
            ],
            text: "MA",
          },
          issuer: {
            /* 
            reference: "internal",
            type: "CarePlan",
            identifier: {
              use: "official",
              system: "https://online2.prc.gov.ph/Verification",
              value: "12345",
            }, */
            display: "PH PRC",
          },
          period: {
            start: license_start_date_dashed,
            end: license_end_date_dashed,
          },
        },
      ],
      address: [
        {
          // line: [`${floor_number} ${house_number} ${street_name} ${barangay}`],
          line: [`${house_number} ${street_name} ${barangay}`],
          city: city_municipality,
          state: province,
          postalCode: zip_code,
          // floorNumber: floor_number,
          houseNumber: house_number,
          streetName: street_name,
          barangay: barangay,
        },
        // {
        //   use: "home",
        //   type: "postal",
        //   text: "101 Street",
        //   line: ["1"],
        //   city: "New york",
        //   district: "New york",
        //   state: "New york",
        //   postalCode: "11004–05",
        //   country: "USA",
        //   period: {
        //     start: "2020-09-10",
        //     end: "2021-09-10",
        //   },
        // },
      ],
      telecom: [
        {
          system: "phone",
          value: contact_no,
          use: "home",
          rank: 1,
          period: {
            start: "2020-09-10",
            end: "2021-09-10",
          },
        },
        {
          system: "email",
          value: email,
          // value: "dummy_" + UI.uniqid() + "@email.com",
          use: "email",
          rank: 1,
          period: {
            start: "2020-09-10",
            end: "2021-09-10",
          },
        },
      ],
      gender: gender,
      birthDate: dob_dashed,
      communication: [
        {
          coding: [
            {
              system: "urn:ietf:bcp:47",
              code: "en",
              display: "English",
            },
          ],
        },
      ],
      picture: profile_picture_path,
      professionalType: (professionalType === true || professionalType === "medical") ? "medical" : (professionalType === false || professionalType === "Non medical") ? "Non medical" : professionalType,
      fieldSpecialties: specialty,
      yearsExperience: 9,
      isMwellPlus: true,
      qualificationDate: qualification_date_date,
      prcLicenseNumber: prc_number,
      prcLicenseStartDate: license_start_date_date,
      prcLicenseEndDate: license_end_date_date,
      prcLicensePhoto: prc_license_path,
      signature: signature_path,
      rating: 5,
      pricePerConsultation: consultation_fee,

      ptrNumber,
      ptr,

      s2Number,
      s2,

      imagePath: imagePath,
      moaPath: moa_path,
      marketingConsentPath: marketing_consent_path,
      certificateOfRegistration: cor_path,

      bankName,
      bankBranch,
      bankAccountName,
      bankAccountNumber,
      paymayaAccountNumber: paymayaAccountNumber,
      mpc,
      ...add,

      languange: createUserLanguages,
      medicalAssociations: createUserMedicalAssociations,
    };

    let data = await mem.get("onboard_data");
    if (data) {
      let item = JSON.parse(data);
      UI.webRequest({
        method: item.doctor ? "PUT" : "POST",
        url: item.doctor ? `doctors_onboarding/doctors/${item.id}` : "doctors_onboarding/doctors",
        params: params,
        onFail: (err) => {
          const data = err.data;
          let error_message = data.message;
          if (!error_message) {
            error_message = data.m;
          }
          this.show_modal(
            <Failed
              title={"Failed"}
              description={error_message}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        },
        onSuccess: (response) => {
          this.show_modal(
            <Success
              title={"Success! Documents Complete."}
              descriptionTop={
                "Validation in progress. Expect same day approval or less!"
              }
              descriptionCenter={
                "Gentle reminder to always check your Spam Folder so you won't miss our emails."
              }
              descriptionBottom={
                "Same-day validation cut-off is based on regular business operating hours."
              }
              onDismiss={() => {
                this.hide_modal();
                /* mem.remove("jwt_token");
                UI.logout(); */
                window.location.reload();
              }}
            />
          );
        },
        onFinish: async () => {
          this.hide_loading();
        },
      });
    }
  };

  auth_upload = async (state_name, file, callback) => {
    UI.upload_image({
      url: "doctors_onboarding/doctors/upload",
      file: file,
      onFail: (err) => {
        this.show_modal(
          <Failed
            title={"Failed"}
            description={"Upload Failed. Application Error."}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (path, response) => {
        this.setState(
          {
            [state_name]: path,
          },
          () => {
            if (callback) {
              callback(path);
            }
            this.process_file(state_name, file, path);
          }
        );
      },
    });
  };

  process_file = async (state_name, file, path) => {
    const filename = UI.container_filename(path);
    const extension = await UI.get_file_extension(path);
    const url = URL.createObjectURL(file);
    const obj = {
      url: url,
      extension: extension,
      filename: filename,
    };

    this.setState({
      ["file_" + state_name]: obj,
    });
  };

  render() {
    let _this = this;
    if (this.props._this) {
      _this = this.props._this;
    }
    const {
      // height, width, isMobile,
      show_modal,
      file_prc_license_path,
    } = this.state;

    const { show_loading, loading_title, loading_text } = this.state;

    return (
      <ScrollView style={styles.main_container}>
        <Modal _this={this} visible={show_modal} />
        <PRCValidator _this={this} />

        <Loading
          visible={show_loading}
          title={loading_title}
          text={loading_text}
        />
        <OnboardHeader _this={_this} />
        {/* BODY 👇 ------------------------------------------ */}
        <View style={styles.content_container}>
          <ProfilePicture _this={_this} />
          <View style={{ width: "100%" }}>
            <Layout.FilePicker
              _this={_this}
              title={"PRC License Photo"}
              additional={"(Jpeg or Png)"}
              isRequired={true}
              text={"Drop Your File Here"}
              state_name={"license_photo"}
              onChange={(file) => {
                _this.auth_upload(
                  "prc_license_path",
                  file,
                  _this.PRCValidator.validate_prc
                );
              }}
              allowed_files={["jpg", "jpeg", "png"]}
              display_type={"filename_size"}
            />
            <Text
              style={{
                color: "#FF4D4D",
                fontWeight: "bold",
                fontSize: 14,
                marginTop: 10,
              }}
            >
              Upload PRC License to autofill information (Name, PRC details)
            </Text>
            <PreviewFile _this={_this} {...file_prc_license_path} />
            {UI.box(20)}
          </View>

          <FirstForm _this={_this} />
          <SecondForm _this={_this} />
          <ThirdForm _this={_this} />
          <PTRForm _this={_this} />
          <S2Form _this={_this} />
          <AddressInformation _this={_this} />
          <MOA _this={_this} />
          <FourthForm _this={_this} />

          {UI.box(25)}

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={"#0E4D94"}
              onClick={() => {
                this.save_as_draft();
              }}
              text={"SAVE AS DRAFT"}
            />

            {UI.box(20)}

            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
                //UI.goTo("/onboard/dashboard");
              }}
              text={"SUBMIT REGISTRATION"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.logout();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  download_text: {
    color: "white",
    fontWeight: "bold",
    fontSize: 12,
  },
  download_button: {
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: UI.colors.primary,
    marginLeft: 10,
    borderRadius: 5,
    height: 30,
    marginTop: -5,
  },
  red_btn: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "#E00000",
    height: 45,
    paddingLeft: 20,
    paddingRight: 20,
  },
  blue_btn: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: UI.colors.primary,
    height: 45,
    paddingLeft: 20,
    paddingRight: 20,
  },
  blue_text: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
  content_container: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 10,
    backgroundColor: "white",
    width: "100%",
    ...Shadow._2(),
    marginTop: 20,
  },
  boxPadding: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 10,
  },
  header_2: {
    color: "#535353",
    fontSize: 15,
  },
  header_1: {
    fontSize: 15,
    color: UI.colors.primary,
    marginTop: 2,
    fontWeight: "bold",
  },
  header_left: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  header_container: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 10,
    ...Shadow._2(),
  },
  main_container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#EFF3F7",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: "#B90C0C",
    // borderTopRightRadius: 10,
    // borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
});
