import React from "react";
import styled from "styled-components";
import { Button } from "antd";

const NavigationButton = (props) => {
  return (
    <StyledNavigationButton {...props} type="primary">
      {props.children}
    </StyledNavigationButton>
  );
};

export default NavigationButton;

const StyledNavigationButton = styled(Button)`
  width: 244px;
  height: 67px;
  border-radius: 14px;
  border: none;
  color: #ffffff;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.25rem; // 20px
  line-height: 1.4375rem; // 23px
  margin-left: 0;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;
