import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import AdminPatientAccountsTable from "./AdminPatientAccountsTable";
import MainLayout from "../_components/MainLayout";
import "./css.css";
const context = "admin";

export default class AdminPatientAccounts extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Patient Accounts",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,
    });
  }

  render() {
    const _this = this;
    return (
      <MainLayout context={context} _this={_this}>
        <AdminPatientAccountsTable this={_this} />
      </MainLayout>
    );
  }
}
