import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
// import 
// { 
  // StyleSheet,
  // View
// } from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import "./css.css";
import OtpCodeModal from "../../ComponentsFC/ui/modals/OtpCodeModal";
// import { Button } from "antd";
// import ServiceAuthApi from '../../api_services/auth'
// import { Failed } from "../_components/Modal";

export default class FirstForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowOtpModal: false,
      verifyType: "",
      // Initialize state properties here
    };
  }

  render() {
    const _this = this.props._this;

    const {
      // height,
      width,
      // isMobile,
      dob_date,
      options_languages,
      options_medicalAssociations,
    } = _this.state;
    const row_width = width > 1000 ? width - 400 : "100%";

    let age = ""; //Age is 24 y/o

    if (dob_date) {
      age = "Age is " + UI.get_age(dob_date) + " y/o";
    }

    const Languages = options_languages?.map((item, index) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });

    const MedicalAssociations = options_medicalAssociations?.map((item) => {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      );
    });

    // const verifyEmailOrPhoneNumber = async (type) => {
    //   this.setState({ verifyType: type })
    //   _this.show_loading();
    //   if (type === "email") {
    //     const payload = {
    //       email: _this.state.email,
    //       role: "provider"
    //     };
    //     ServiceAuthApi.verifyEmail(payload).then(res => {
    //       if (res.status === 200) {
    //         this.setState({ isShowOtpModal: true });
    //         _this.hide_loading();
    //       } else {
    //         _this.hide_loading();
    //         if (res.message.includes("wrong") || res.message.includes("expired")) {
    //           _this.show_modal(
    //             <Failed
    //               title={"Failed"}
    //               description={res.message}
    //               onDismiss={() => {
    //                 _this.hide_modal();
    //                 UI.logout();
    //               }}
    //             />
    //           );
    //         } else {
    //           _this.show_modal(
    //             <Failed
    //               title={"Failed"}
    //               description={res.message}
    //               onDismiss={() => {
    //                 _this.hide_modal();
    //                 UI.logout();
    //               }}
    //             />
    //           );
    //         }
    //       }
    //     });
    //   } else if (type === "phoneNumber") {
    //     const payload = {
    //       phoneNumberPrefix: "+63",
    //       phoneNumber: _this.state.contact_no,
    //       role: "provider"
    //     };
    //     ServiceAuthApi.verifyMobileNumber(payload).then(res => {
    //       if (res.status === 200) {
    //         this.setState({ isShowOtpModal: true });
    //         _this.hide_loading();
    //       } else {
    //         _this.hide_loading();
    //         _this.show_modal(
    //           <Failed
    //             title={"Failed"}
    //             description={res.message}
    //             onDismiss={() => {
    //               _this.hide_modal();
    //             }}
    //           />
    //         );
    //       }
    //     });
    //   } else {
    //     return null
    //   }
    // }

    // const emailVerifiedText = () => {
    //   if (_this.state.onboard_data?.isEmailVerified) {
    //     return <strong style={{ color: UI.colors.green}}>Verified</strong>
    //   } else {
    //     return <Button 
    //     disabled={!UI.validate_email(_this.state.email)} 
    //     onClick={() => {
    //       verifyEmailOrPhoneNumber("email")
    //     }} 
    //     style={{ ...UI.validate_email(_this.state.email) && { background: UI.colors.secondary, color: "white", borderRadius: 8 },padding: "0px 10px" }}>
    //       Verify
    //     </Button>
    //   }
    // }

    // const phoneNumberVerifiedText = () => {
    //   if (_this.state.onboard_data?.isPhoneNumberVerified) {
    //     return <strong style={{ color: UI.colors.green}}>Verified</strong>
    //   } else {
    //     return <Button 
    //     disabled={_this.state.contact_no.length !== 10} 
    //     onClick={() => {
    //       verifyEmailOrPhoneNumber("phoneNumber")
    //     }} 
    //     style={{ ..._this.state.contact_no.length === 10 && { background: UI.colors.secondary, color: "white", borderRadius: 8 },padding: "0px 10px" }}>
    //       Verify
    //     </Button>
    //   }
    // }

    return (
      <UI.Column
        style={{ marginBottom: 10 }}
        _this={_this}
        breakpoint={1000}
        breakpoint_2={720}
      >
        <OtpCodeModal 
          verifyType={this.state.verifyType}
          _this={_this}
          this={this}
          visible={this.state.isShowOtpModal}
          onCancel={()=> {
            _this.setState({ otpCode: ""})
            _this.setState({ otpErrorMessage: ""})
            this.setState({ isShowOtpModal: false });
          }}
        />
        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <InputForm
            _this={_this}
            title={"First Name"}
            placeholder={"First Name"}
            state_name={"first_name"}
            style={{ flex: 1 }}
            isRequired={true}
            maxLength={UI.Constants.limits.name}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Middle Name"}
            placeholder={"Middle Name"}
            state_name={"middle_name"}
            style={{ flex: 1 }}
            isRequired={false}
            maxLength={UI.Constants.limits.name}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Last Name"}
            placeholder={"Last Name"}
            state_name={"last_name"}
            style={{ flex: 1 }}
            isRequired={true}
            maxLength={UI.Constants.limits.name}
          />
        </UI.Row>
        {UI.box(20)}
        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <InputForm
            // additional={emailVerifiedText()}
            _this={_this}
            title={"Email Address"}
            placeholder={"Email Address"}
            state_name={"email"}
            style={{ flex: 1 }}
            isRequired={true}
            disabled={_this.state.onboard_data?.isEmailVerified}
            maxLength={UI.Constants.limits.email}
          />
          {UI.box(10)}
          <InputForm
            // additional={phoneNumberVerifiedText()}
            _this={_this}
            title={"Contact Number"}
            placeholder={"Contact Number"}
            state_name={"contact_no"}
            prefix={"+63"}
            isRequired={true}
            isNumber={true}
            maxLength={10}
            disabled={_this.state.onboard_data?.isPhoneNumberVerified}
            style={{ flex: 1 }}
          />
          {UI.box(10)}

          <Layout.DatePicker
            _this={_this}
            title={"Date of Birth"}
            additional={age}
            isRequired={true}
            placeholder={"mm/dd/yyyy"}
            state_name={"dob"}
            date_props={{
              maxDate: new Date(),
            }}
            will_correct={true}
          />
        </UI.Row>
        {UI.box(10)}
        <UI.Row style={{ width: row_width }} _this={_this} breakpoint={1000}>
          <Layout.SelectPicker
            _this={_this}
            title={"Languages/Dialects"}
            isRequired={true}
            state_name={"createUserLanguages"}
            isMultiple={true}
          >
            <option value="">Select Language</option>
            {Languages}
          </Layout.SelectPicker>
          {UI.box(10)}
          <Layout.SelectPicker
            _this={_this}
            title={"Medical Association"}
            state_name={"createUserMedicalAssociations"}
            isMultiple={true}
          >
            <option value="">Select Medical Association</option>
            {MedicalAssociations}
          </Layout.SelectPicker>
        </UI.Row>
      </UI.Column>
    );
  }
}

// const styles = StyleSheet.create({
//   asterisk: {
//     color: "#FF0000",
//   },
//   option_container: {
//     height: 40,
//     width: "100%",
//     borderRadius: 5,
//     borderStyle: "solid",
//     borderWidth: 2,
//     padding: 5,
//     borderColor: "#CACACA",
//   },
//   title: {
//     fontSize: 14,
//     fontWeight: "bold",
//     color: UI.colors.primary,
//   },
//   text_2: {
//     fontSize: 14,
//     color: "#D6D6D6",
//   },
//   btn_text_1: {
//     fontSize: 14,
//     color: "white",
//     fontWeight: "bold",
//   },
//   blue_btn_2: {
//     paddingLeft: 15,
//     paddingRight: 15,
//     paddingTop: 12,
//     paddingBottom: 12,
//     backgroundColor: UI.colors.primary,
//     borderRadius: 8,
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   text_1: {
//     color: "#AFAFAF",
//     fontSize: 14,
//   },
//   image_container: {
//     alignItems: "center",
//     flexDirection: "row",
//     alignSelf: "flex-start",
//   },
//   header_3: {
//     fontSize: 18,
//     color: UI.colors.primary,
//     marginTop: 2,
//     fontWeight: "bold",
//   },
// });
