import React, { useCallback, useState, useEffect } from "react";
import DataTable from "../../ComponentsFC/DataTable";
import UI from "../../shared/Components/UI/js";
import styled from "styled-components";
import { Row, Col, Input, Select, Typography, Button,
  // DatePicker 
} from "antd";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import TouchableOpacity, { Image, mem, Text, View } from "../../shared/custom-react-native";
// import LaboratoryServicesApi from "../../api_services/Merchant/laboratoryServices";
// import { Failed, Success } from "../_components/Modal";
import LaboratoryPartnerApi from "../../api_services/Merchant/LaboratoryPartnerApi";
import moment from "moment";
// import moment from "moment";

// import moment from "moment";

const LaboratoryTable = ({ _this }) => {
  const [tableData, setTableData] = useState([]);
  const [searchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(30);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [sortByProductName, setSortByProductName] = useState("");
  const [filterByPatmentMethod, setFilterByPaymentMethod] = useState("");
  const [sortByPurchaseDate, setSortByPurchaseDate] = useState("");

  const getCategories = useCallback(async () => {
      setIsLoading(true);

      let params = {
        category: "Laboratory"
      };

      if (searchQuery) {
        params.keyword = searchQuery;
      }

      if (sortByProductName) {
        params.productName  = sortByProductName;
      }
  
      if (filterByPatmentMethod) {
        params.paymentMethod = filterByPatmentMethod;
      }
  
      if (sortByPurchaseDate) {
        params.datePurchaseSort = sortByPurchaseDate;
      }
      
      if (currentPageNum) {
        params.page = currentPageNum;
        params.limit = tableEntries;
      }

      LaboratoryPartnerApi.getAllLaboratoryPartnerTransactions(params).then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setTotalRecords(res.count)
          console.log(res)
          setTableData(res.data);
        }
      });
  }, [currentPageNum, tableEntries, searchQuery, filterByPatmentMethod, sortByPurchaseDate, sortByProductName]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const handlePaginateTable = (pageNum) => {
    setCurrentPageNum(pageNum);
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleCSVExport = () => {
    const csv_data = tableData.map((item, index) => {
      const formDataLabAddress = `${item?.formDataLaboratory?.street ?? " "} ${
        item?.formDataLaboratory?.brgyOrTown ?? " "
      } ${item?.formDataLaboratory?.cityOrMunicipality ?? " "} ${
        item?.formDataLaboratory?.region ?? " "
      } ${item?.formDataLaboratory?.postalCode ?? " "}`;

      const price = parseInt(item.amount ?? 0);
      const transactionFee = parseInt(item.transactionFee ?? 0);
      const discount = parseInt(item.discount ?? 0);
      const tax = parseInt(item.vat_amount ?? 0);

      const getTotal = () => {
        return (transactionFee + price + tax) - discount;
      };

      const d = {
        "Parent Id": item._id ?? "--",
        "Order ID": item._id ?? "--",
        "Patient Name": `${(item?.formDataLaboratory?.firstName ?? "--")} ${(item?.formDataLaboratory?.lastName ?? "--")}`,
        "Address": formDataLabAddress ?? "--",
        "Contact number": item?.formDataLaboratory?.mobileNumber || "None",
        "Booking Type": item.formDataLaboratory?.laboratoryTestHeldType ?? "None",
        "Patient Type": item?.formDataLaboratory?.relationship || "None",
        "Product Name": item.product?.name.replace(",", ' ') ?? "--",
        "Unit Price": item.product?.price ?? "--",
        "Qty": item.quantity ?? "--",
        "Discount": item.discount ?? "--",
        "Tax": item.vat_amount ?? "--",
        "Service Fee": item.transactionFee,
        // "Purchased With": item.method ?? "--",
        "Total Amount": getTotal(),
        "Merchat Name": item.merchant?.name ?? "",
        "1st Preferred Schedule": item.formDataLaboratory?.preferredSchededule ? UI.momentPHTimezone(item.formDataLaboratory?.preferredSchededule).format('MM/DD/YYYY') : "--",
        "1st Preferred Time": item.formDataLaboratory?.preferredSchededule ? UI.momentPHTimezone(item.formDataLaboratory?.preferredSchededule).format('h:mm a') : "--",
        // "2st Preferred Schedule": item.formDataLaboratory?.preferredSchededule ? moment(item.formDataLaboratory.preferredSchededule).format("YYYY/MM/DD") : "--",
        "Total Payment": getTotal(),
        "Payment Method": item.method ?? "--",
        "Payment Status": item?.status ?? "--",
        "Order Status": item.orderStatus ?? "--",
        "Purchase Date": item.createdAt ? moment(item.createdAt).format("YYYY/MM/DD") : "--",

        // "Purchase Date": item.createdAt ? moment(item.createdAt).format("YYYY/MM/DD") : "--",
        // "Parent ID": item._id ?? "--",
        // "First Appointment": item.formDataLaboratory?.firstAppointmentDate ? moment(item.formDataLaboratory?.firstAppointmentDate).format("YYYY/MM/DD") : "--",
        // "Payment Method": item.method ?? "--",
        // "Remarks": item.remarks ?? "--",
      };

      return d;
    });

    UI.DownloadCSV(`Laboratory Transactions ${UI.get_current_date_string()}`, csv_data);
  };

  const tableColumnHeader = [
    {
      title: "Order ID",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
      render: (referenceNumber) => referenceNumber ?? "--",
    },
    {
      title: "Lab Partner",
      dataIndex: "merchantName",
      key: "merchantName",
      render: (merchantName) => merchantName ?? "--",
    },
    {
      title: "Patient Name",
      dataIndex: "name",
      key: "name",
      render: (data, item) => `${(item?.formDataLaboratory?.firstName ?? "--")} ${(item?.formDataLaboratory?.lastName ?? "--")}` ,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (data, item) => item.product?.name ?? "--",
    },
    // {
    //   title: "Purchased With",
    //   dataIndex: "method",
    //   key: "method",
    //   render: (method) => method ?? "--" ,
      
    //   // render: (createdAt) => createdAt ? moment(createdAt).format("YYYY/MM/DD") : "--" ,
    // },
    {
      title: "Date Purchased",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => createdAt ? moment(createdAt).format("YYYY/MM/DD") : "--" ,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (data, item) => (parseInt(item.amount) + item.transactionFee ?? 0),
    },
    {
      title: "Appointment Date",
      dataIndex: "appointmentDate",
      key: "appointmentDate",
      render: (data, item) => item.formDataLaboratory?.preferredSchededule ? moment(item.formDataLaboratory.preferredSchededule).format("YYYY/MM/DD") : "--",
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (data, item) => item?.status ? UI.get_transaction_status_text(item) : "--",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (key, item) => {
          return (
            <View style={{ flexDirection: "row", gap: 10 }}>
              <TouchableOpacity
                onClick={() => {
                  UI.goTo('/admin/laboratory-transaction-view')
                  mem.save('laboratory_transaction_view', JSON.stringify(item))
                }}
              >
                <Image
                  height={28}
                  width={28}
                  tintColor={"transparent"}
                  source={UI.VIEW_BTN_1}
                />
              </TouchableOpacity>
            </View>
          )
      },
    },
  ];

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPageNum(1);
    setTableEntries(30);
  };


  return (
    <>
      <FilterBox>
        <Text style={{
            fontSize: 18,
            fontWeight: "bold",
            color: UI.colors.secondary,
            flex: 1,
            paddingLeft: 35,
            paddingBottom: 35,
          }}
        >
          Transaction History
        </Text>
        <Toolbar gutter={14}>
          <ToolItem span={8}>
            <StyledFieldLabel>Product Name</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Sort By"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setSortByProductName(value)
              }}
            >
              <Select.Option value="asc">Ascending</Select.Option>
              <Select.Option value="desc">Descending</Select.Option>
            </StyledSelect>
          </ToolItem>
          <ToolItem span={8}>
            <StyledFieldLabel>Payment Method</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Filter By"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setFilterByPaymentMethod(value)
                // setSortByCreatedAt("")
              }}
            >
              <Select.Option value="Laboratory">Laboratory</Select.Option>
              <Select.Option value="Paymaya">Paymaya</Select.Option>
              <Select.Option value="PaymayaCard">PaymayaCard</Select.Option>
              <Select.Option value="HealthPass">HealthPass</Select.Option>
              <Select.Option value="BayadOTC">BayadOTC</Select.Option>
              <Select.Option value="HealthLink">HealthLink</Select.Option>
              <Select.Option value="MedExpress">MedExpress</Select.Option>
              <Select.Option value="BayadCenter">BayadCenter</Select.Option>
            </StyledSelect>
          </ToolItem>
          <ToolItem span={8}>
            <StyledFieldLabel>Purchase Date</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Sort By"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setSortByPurchaseDate(value)
              }}
            >
              <Select.Option value="asc">Ascending</Select.Option>
              <Select.Option value="desc">Descending</Select.Option>
            </StyledSelect>
          </ToolItem>
        </Toolbar>
      </FilterBox>

      <TableBox>
        <Toolbar>
          <ToolItem span={8} style={{display:'flex', gap: 10, alignItems:'center'}}>
            <FieldLabel>Only Show</FieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              defaultValue="30"
              style={{
                width: 160,
              }}
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeNumOfEntries}
            >
              <Select.Option value="10">10 Entries</Select.Option>
              <Select.Option value="30">30 Entries</Select.Option>
              <Select.Option value="100">100 Entries</Select.Option>
            </StyledSelect>
          </ToolItem>
          <div className="flex-1"></div>
          <ToolItem span={5}>
            <StyledSearch
              placeholder="Enter Order ID/Patient Name"
              allowClear
              suffix={<SearchIcon />}
              value={searchQuery}
              onChange={(e) => {
                handleChangeSearchQuery(e);
              }}
              onPressEnter={getCategories}
              // style={{ width: 200 }}
            />
          </ToolItem>
          <StyledButton type="primary" onClick={() => handleCSVExport()}>
            Export CSV
          </StyledButton>
        </Toolbar>
        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          defaultPageSize={30}
          pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default LaboratoryTable;

// CSS Styled Components ----------------------

const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

// const StyledRangePicker = styled(DatePicker.RangePicker)`
//   flex-direction: row;
//   border-radius: 12px;
//   border-width: 2px !important;
//   height: 50px;
//   margin-left: auto;

//   .ant-picker:hover,
//   .ant-picker-focused {
//     border-color: #d9d9d9 !important;
//     box-shadow: none !important;
//   }

//   .ant-picker-range-separator {
//     display: block !important;
//   }
// `;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const ToolItem = styled(Col)`
  position: relative;
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #004F99;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  /* width: 320px !important;
  margin-left: auto; */
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;
