import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  Shadow,
  StyleSheet,
  View,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";

import MainLayout from "../_components/MainLayout";
import PhysicalId from "./PhysicalId";
import Profile from "./Profile";
import Subscription from "./Subscription";

const Constants = new APIConstants();

export default class UserGroupMembersCreateQrUser extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "User Group List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: mem.get(UI.Constants.ROLE),
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      contact_no: "",
      dob: "",
      gender: "",
      patient_tab: "profile",
      did_upload: false,
      image:UI.DEFAULT_AVATAR_PATIENT
    });
  }

  onCreate = () => {
    this.load_data()
  }

  load_data = (data) => {
    const edit_user_group_member = JSON.parse(mem.get("edit_user_group_member"));
    const user_group = JSON.parse(mem.get("view_user_group"));
    
    UI.set_input_value("first_name", edit_user_group_member.firstName ?? "");
    UI.set_input_value("middle_name", edit_user_group_member.middleName ?? "");
    UI.set_input_value("last_name", edit_user_group_member.lastName ?? "");
    UI.set_input_value("email", edit_user_group_member.email ?? "");
    UI.set_input_value("contact_no", edit_user_group_member.phoneNumber ?? "");

    UI.set_input_value("province", edit_user_group_member.address ? (edit_user_group_member.address.state ?? "") : "");
    UI.set_input_value("city", edit_user_group_member.address ? (edit_user_group_member.address.cityOrMunicipality ?? "") : "");
    UI.set_input_value("barangay", edit_user_group_member.address ? (edit_user_group_member.address.brgyOrTown ?? "") : "");
    UI.set_input_value("house_number_street", edit_user_group_member.address ? (edit_user_group_member.address.street ?? "") : "");
    UI.set_input_value("postal_code", edit_user_group_member.address ? (edit_user_group_member.address.postalCode ?? "") : "");
    UI.set_input_value("special_condition", edit_user_group_member.specialCondition ?? "");
    // UI.set_select_value("special_condition", edit_user_group_member.specialCondition ? (edit_user_group_member.specialCondition ?? "") : "");

    if (edit_user_group_member.birthDate) {
      UI.set_date_value("dob", new Date(edit_user_group_member.birthDate) );
    }
    const partnerLogoImage = `${Constants.STORAGE_BASE_URL}?path=${user_group.partnerLogoImage}` ?? UI.SAMPLE_PRODUCT
    const profileLogoImage = `${Constants.STORAGE_BASE_URL}?path=${user_group.profileLogoImage}` ?? UI.SAMPLE_PRODUCT

    this.setState({
      gender: edit_user_group_member.gender?.toString() ? edit_user_group_member.gender === 1 ? "Male" : "Female" : "",
      user_group:user_group,
      avatarLink: UI.getUserAvatarLink(edit_user_group_member?.avatarLink),
      image: edit_user_group_member?.avatarLink?.includes('https') ? edit_user_group_member?.avatarLink : UI.getUserAvatarLink(edit_user_group_member?.avatarLink),
      user_id:edit_user_group_member._id,
      is_already_scan:edit_user_group_member.isAlreadyScan,
      user_data: edit_user_group_member,
      profile_logo_image: profileLogoImage,
      partner_logo_image: partnerLogoImage,
    })
  };
  
  upload_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        image: file.uri,
        image_file: file.file,
        did_upload: true,
      }
    );
  };

  render() {
    const _this = this;

    return (
      <MainLayout context={this.state.context} additional="usergroup-list" _this={_this}>
        <View style={styles.main_box_content}>
          <div className="flex flex-col relative">
            <div className="flex gap-10 font-semibold mb-4" style={{fontSize:16.5}}>
              {
                _this.state.context !== "merchant"  ? <>
                  <button 
                    onClick={() => _this.setState({patient_tab: 'profile'})} 
                    style={{ borderBottom: _this.state.patient_tab === "profile" && '2px solid rgb(4, 204, 255)', zIndex: 10, paddingBottom:5, color: _this.state.patient_tab === "profile" ? 'rgb(4, 204, 255)' : "" }}
                  >
                    Profile
                  </button>
                  
                  <button 
                    onClick={() => _this.setState({patient_tab: 'physical-id'})} 
                    style={{ borderBottom: _this.state.patient_tab === "physical-id" && '2px solid rgb(4, 204, 255)', zIndex: 10, paddingBottom:5, color: _this.state.patient_tab === "physical-id" ? 'rgb(4, 204, 255)' : "" }}
                  >
                    Physical ID
                  </button>
                </> :
                <button 
                  onClick={() => _this.setState({patient_tab: 'subscription'})} 
                  style={{ borderBottom: _this.state.patient_tab === "subscription" && '2px solid rgb(4, 204, 255)', zIndex: 10, paddingBottom:5, color: (_this.state.patient_tab === "physical-id" || _this.state.context === "merchant") ? 'rgb(4, 204, 255)' : "" }}
                >
                  Subscription
                </button>
              }
            
            </div>
            <div className="border-t-2 absolute inset-0" style={{marginTop:31}}></div>
          </div>

          {
            _this.state.context === "merchant" ? <Subscription _this={_this}/> :
            _this.state.patient_tab === "profile" ? <Profile _this={_this}/> : <PhysicalId _this={_this}/>
          }

          {/* { _this.state.patient_tab === "profile" && _this.state.context === "merchant" && <Subscription _this={_this}/> } 
          { _this.state.patient_tab === "profile" && _this.state.context !== "merchant" ? <Profile _this={_this}/> : <PhysicalId _this={_this}/>}  */}
          {/* <PhysicalId _this={_this}/> */}
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
});

// // borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
