// import { Col, Row, Select, Typography } from "antd";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
// import styled from "styled-components";
// import DropdownIcon from "../../icons/Dropdown";
import UI from "../../shared/Components/UI/js";
// import { StyleSheet, View } from "../../shared/custom-react-native";
import MainLayout from "../_components/MainLayout";
import EcommerceUserGroupSubscriptionBillingApprovalTable from "./EcommerceUserGroupSubscriptionBillingApprovalTable";
// import EcommerceUserGroupSubscriptionBillingStatusTable from "./EcommerceUserGroupSubscriptionBillingStatusTable";
// import "./css.css";


export default class EcommerceUserGroupSubscriptionBilling extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Subscription List",
      is_drawer_open: false,
      selected: null,
      context:"merchant",
      filterByRequestStatus:"",
      filterByBillingStatus:"",
      activeTab: "billing_approval",
      tabs: [
        {tab: "Billing Approval", key:'billing_approval' },
        {tab: "Billing Status", key:'billing_status' }, 
      ],
    });
  }
  
  render() {
    const _this = this;

    return (
      <MainLayout context={this.state.context} additional={"billing"} _this={_this}>
        {/* <FilterBox>
          <Title level={5}>Request Subscription List</Title>
          <Toolbar gutter={14}>
            <ToolItem span={8}>
              <StyledFieldLabel>Status</StyledFieldLabel>
              <StyledSelect
                getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                placeholder="Filter Option"
                style={{
                  width: "100%",
                }}
                allowClear
                suffixIcon={<DropdownIcon />}
                onChange={(value) => this.setState({ filterByRequestStatus: value})}
                // onChange={(value) => setFilterByStatus(value)}
                // onClear={() => handleClearViaSelectField("Status")}
              >
                <Select.Option title="Action" value="APPROVED">
                  Approved
                </Select.Option>
                <Select.Option title="Action" value="CANCELLED">
                  Merchant Cancel
                </Select.Option>
                <Select.Option title="Action" value="CANCELLED">
                  Cancel
                </Select.Option>
                <Select.Option title="Action" value="DISAPPROVED">
                  Disapprove
                </Select.Option>
                <Select.Option title="Action" value="AWAITING_ACTIVATION">
                  Pending
                </Select.Option>
              </StyledSelect>
            </ToolItem>
            <ToolItem span={8}>
              <StyledFieldLabel>Billing</StyledFieldLabel>
              <StyledSelect
                getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                placeholder="Filter Option"
                style={{
                  width: "100%",
                }}
                allowClear
                suffixIcon={<DropdownIcon />}
                onChange={(value) => this.setState({ filterByBillingStatus: value})}
                // onClear={() => handleClearViaSelectField("Status")}
              >
                <Select.Option title="Action" value="Paid">
                  Paid
                </Select.Option>
                <Select.Option title="Action" value="Unpaid">
                  Unpaid
                </Select.Option>
                <Select.Option title="Action" value="Pending">
                  Pending
                </Select.Option>
              </StyledSelect>
            </ToolItem>
          </Toolbar>
        </FilterBox> */}

        {/* <StyledCard
          style={{
            width: '100%',
          }}
          tabList={this.state.tabs}
          activeTabKey={_this.state.activeTab}
          onTabChange={(key) => {
            _this.setState({ activeTab: key })
            console.log(_this.state.activeTab)
          }}
        > */}

          {/* <View style={{ ...styles.main_box_content }}> */}
            {/* {
              _this.state.activeTab === "billing_approval" ? <EcommerceUserGroupSubscriptionBillingApprovalTable _this={_this}/> :
              <EcommerceUserGroupSubscriptionBillingStatusTable _this={_this}/>
            }
             */}
             <EcommerceUserGroupSubscriptionBillingApprovalTable _this={_this}/>
          {/* </View> */}
        
        {/* </StyledCard> */}
        
      </MainLayout>
    );
  }
}

// const StyledCard = styled(Card)`
//   border:none;

//   .ant-card-head {
//     border-bottom: none;
//   }

//   .ant-tabs-nav-list {
//     font-family: Poppins;
//   }

//   .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs-tab-btn:hover {
//     color:#04CCFF ;
//   }

// `;

// const Toolbar = styled(Row)`
//   display: flex;
//   align-items: center;
//   padding-left: 35px;
//   padding-right: 35px;
//   padding-bottom: 33px;
//   font-family: Poppins;
//   font-weight: 500; //medium
//   font-size: 0.9375rem; // 15px
//   line-height: 1.4375rem; // 23px
//   letter-spacing: 0;
// `;

// const FilterBox = styled(Row)`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   background: #ffffff 0% 0% no-repeat padding-box;
//   box-shadow: 0px 3px 10px #bfbfbf29;
//   border-radius: 12px;
//   padding: 33px 0 0 0;
//   margin-bottom: 30px;
// `;

// const ToolItem = styled(Col)`
//   position: relative;
//   .ant-picker {
//     margin-left: 102px;
//   }
//   .ant-picker-range {
//     margin-left: 62px;
//   }
// `;

// const StyledSelect = styled(Select)`
//   .ant-select-selector {
//     border-radius: 12px !important;
//     border-width: 2px !important;
//     height: 50px !important;
//     align-items: center;
//     font-size: 0.9375rem; // 15px
//     line-height: 1.4375rem; // 23px
//     & .ant-select-selection-placeholder {
//       color: #2e2e2e;
//     }
//     // Options
//     & .ant-select-selection-item {
//       z-index: 1;
//     }
//   }

//   // The Wrapper of Icon
//   .ant-select-arrow {
//     margin-right: 10px;
//     top: 47% !important;
//   }
//   // The Wrapper of Icon for clear btn, when there's a value
//   .ant-select-clear {
//     top: 45%;
//     right: 45px;
//     opacity: 1 !important;
//   }
// `;

// const Title = styled(Typography.Title)`
//   font-family: Poppins;
//   font-weight: 500 !important; //medium
//   font-size: 1.4375rem !important; // 15px
//   line-height: 1.4375rem !important; // 23px
//   letter-spacing: 0 !important;
//   color: #004f99 !important;
//   padding-left: 35px;
//   margin-bottom: 25px !important;
// `;

// const StyledFieldLabel = styled(Typography.Paragraph)`
//   color: #65d1ff;
//   margin-bottom: 10px !important;
// `;

// // const styles = StyleSheet.create({
// //   main_box_content: {
// //     width: "100%",
// //     borderRadius: 5,
// //     backgroundColor: "white",
// //     // ...Shadow._3(),
// //     padding: 20,
// //   },
// // });
