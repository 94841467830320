import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import DoctorFileRepository from "../../ComponentsFC/DoctorFileRepository";

const context = "admin";

export default class AdminDoctorFileRepository extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Doctor File Repository",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,
    });
  }

  render() {
    const _this = this;
    return (
      <MainLayout context={context} _this={_this}>
        <DoctorFileRepository />
      </MainLayout>
    );
  }
}
