import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
import InputForm, { Checkbox } from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";

const context = "advocacy";
const Constants = new APIConstants();

export default class EditAdvocacy extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Advocacy List",
      is_drawer_open: false,
      title:"",
      start_date:"",
      start_date_date:"",
      end_date:"",
      end_date_date:"",
      promotion_code:"",
      status:"",
      time_limit:"",
      event_title:"",
      join_button_title:"",
      isShowJoinButton: true,
      description:"",
      healthpass:[],
      comming_soon:false,
      context: context,
      is_change_healthpass_name:false,

      image: null,
      image_file: null,
      did_upload: false,
      mobile_image: UI.null,
      mobiel_image_file: null,
      did_upload_mobile_image:false,

      healthpassData:[],
      consultationAvailability: {
        showConsultNow: false,
        showConsultLater:false
      },
    });
  }

  onCreate = async () => {
    this.loadHealthpass();
  };

  load_data = async () => {
    const data = mem.get("edit_advocacy");
    if (data) {
      const advocacy = JSON.parse(data);

      UI.set_input_value("title", advocacy.title ?? "");
      UI.set_input_value("status", advocacy.status ?? "");
      UI.set_input_value("description", advocacy.description ?? "");
      UI.set_input_value("promotion_code", advocacy.promotionCode ?? "");
      UI.set_input_value("event_title", advocacy.eventTitle ?? "");
      UI.set_input_value("join_button_title", advocacy.buttonTitle ?? "");

      UI.set_select_value("status", advocacy.status ?? "");
      UI.set_select_value("time_limit", advocacy.consultationTimeLimit ?? "");
      UI.set_date_value("start_date", new Date(advocacy.startDate ?? ""));
      UI.set_date_value("end_date", new Date(advocacy.endDate ?? ""));
      // UI.set_select_value("healthpass", advocacy.healthpass ?? "");
      UI.set_multiple_select_value("healthpass", advocacy.healthpass?.map(healthpass => healthpass._id));

      this.setState({
        id: advocacy._id,
        comming_soon: advocacy.comingSoon ?? false,
        consultationAvailability: {
          showConsultNow: advocacy.consultationAvailability?.showConsultNow ?? false,
          showConsultLater: advocacy.consultationAvailability?.showConsultLater ?? false
        },
        isAddSurvey: advocacy.addSurvey ?? true,
        isShowJoinButton: advocacy.isJoinButton ?? true,
        schedule: advocacy.advocacyschedulecms
      });
    }
  };

  loadHealthpass = () => {
    // this.show_loading();
    // console.log('first')
    UI.webRequest({
      method: "get",
      url: "admin/healthpass?isDropdown=true",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            healthpassData: data.data,
          }
        );
        this.load_data();

      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  submit = async () => {
    const {
      title,
      start_date,
      end_date,
      promotion_code,
      status,
      time_limit,
      description,
      image_file,
      healthpass,
      // is_change_healthpass_name,
      start_date_date,
      end_date_date,
      consultationAvailability,
      comming_soon,
      isAddSurvey,
      mobile_image_file, 
      event_title,
      join_button_title,
      isShowJoinButton,

    } = this.state;
    let days = []
   
    const data = mem.get("edit_advocacy");
    const advocacy = JSON.parse(data);
    let schedules = ""
    
    let endDate = moment(end_date_date);
    var startDate = moment(start_date_date).subtract(1, "days");

    while(startDate.date() !== endDate.date()){
      startDate.add(1, 'day'); 
      days.push(
      {
        date: moment(startDate._d).format("LLLL").split(',')[0] + ", " + moment(startDate).format('MMMM D YYYY'),
        morning_start_time: "",
        morning_end_time: "",
        afternoon_start_time: "",
        afternoon_end_time: "",
      }
        // {day: moment(startDate._d).format("LLLL").split(',')[0]}
      )
    }

    if (moment(advocacy.startDate).format('MMMM D YYYY') === moment(start_date).format('MMMM D YYYY') && moment(advocacy.endDate).format('MMMM D YYYY') === moment(end_date).format('MMMM D YYYY')) {
      schedules = {
        schedule: JSON.stringify(this.state.schedule)
      }
    } else {
      schedules = {
        schedule: JSON.stringify(days)
      }
    }

    this.show_loading();

    UI.webRequest({
      method: "put",
      url: "admin/advocacies/update-advocacy/" + this.state.id,
      params: {
        title:title,
        startDate:start_date_date,
        endDate:end_date_date,
        promotionCode:promotion_code,
        status:status,
        consultationTimeLimit:time_limit,
        description:description,
        image: image_file,
        healthpass: JSON.stringify(healthpass),
        //  is_change_healthpass_name ? healthpass : healthpass._id,
        comingSoon:comming_soon,
        consultationAvailability: JSON.stringify(consultationAvailability),
        addSurvey:isAddSurvey,
        mobile_image: mobile_image_file, 
        eventTitle: event_title,
        buttonTitle: join_button_title,
        isJoinButton: isShowJoinButton,
        ...schedules

      },
      isMultiPart: true,  
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.error;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        if (moment(advocacy.startDate).format('MMMM D YYYY') === moment(start_date_date).format('MMMM D YYYY') && moment(advocacy.endDate).format('MMMM D YYYY') === moment(end_date_date).format('MMMM D YYYY')) {
          this.show_modal(
            <Success
              title={"Success!"}
              descriptionCenter={"Successfully updated the advocacy."}
              onDismiss={() => {
                this.hide_modal();
                // const data = UI.extract_data(response);
                // mem.save("view_advocacy", JSON.stringify(data.data));
                // mem.save("advocacy_id", data.data._id);
                // UI.goTo("/advocacy/view")
                UI.goTo("/advocacy/list")
              }}
            />
          );
        } else {
          UI.webRequest({
            method: "put",
            url: "admin/advocacies/update-schedule",
            params: {
              advocacyId:this.state.id,
              schedule: days
            },
            onFail: async (err) => {
              const data = err.data;
              const error_message = data.error;
              this.show_modal(
                <Failed
                  title={"Failed"}
                  description={error_message}
                  onDismiss={() => {
                    this.hide_modal();
                  }}
                />
              );
            },
            onSuccess: async (response) => {
              this.show_modal(
                <Success
                  title={"Success!"}
                  descriptionCenter={"Successfully updated the advocacy."}
                  onDismiss={() => {
                    this.hide_modal();
                    UI.goTo("/advocacy/list")
                  }}
                />
              );
            },
            onFinish: () => {
              this.hide_loading();
            },
          });
        }
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

   upload_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        image: file.uri,
        image_file: file.file,
        did_upload: true,
      }
    );
  };

  upload_mobile_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        mobile_image: file.uri,
        mobile_image_file: file.file,
        did_upload_mobile_image: true,
      }
    );
  };

  render() {
    const _this = this;
    const { did_upload } = _this.state;

    const data = mem.get("edit_advocacy");
    const advocacy = JSON.parse(data);
    const image = `${Constants.STORAGE_BASE_URL}?path=${advocacy.imagePath}` ?? UI.SAMPLE_PRODUCT
    const mobile_image = `${Constants.STORAGE_BASE_URL}?path=${advocacy.mobileImage}` ?? UI.SAMPLE_PRODUCT

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Edit Advocacy
            </Text>

            <Text style={styles.text_2}>
              Update advocacy information here
            </Text>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <View style={{ height: 120, width: 120, justifyContent:'center' }}>
              <Layout.FilePicker
                _this={_this}
                title={""}
                additional={""}
                isRequired={false}
                text={""}
                state_name={"product_image"}
                onChange={async (file) => {
                  const uri = await UI.get_uri_from_file(file);
                  this.setState(
                    {
                      image: uri,
                      image_file: file,
                      did_upload: true,
                    },
                    () => {
                      //this.auth_upload();
                    }
                  );
                }}
                height={120}
                component={
                  this.state.image ?
                  <img
                    style={{ 
                      // height: 150, 
                      // width: 250, 
                      borderRadius: 5
                    }}
                    src={this.state.image}
                    alt=""
                  />
                  : 
                  <img
                    style={{ 
                      // height: 150, 
                      // width: 250, 
                      borderRadius: 5
                    }}
                    src={advocacy.imagePath ? image : UI.SAMPLE_PRODUCT }
                    alt=""
                  />
                }
              />
            </View>
            {UI.box(20)}

            <View style={{ flex: 1.5, justifyContent: "center" }}>
              <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
              >
                {did_upload
                  ? "Web Image Banner Uploaded."
                  : "No Web Image Banner Uploaded."}{" "}
                Recommended Size: 500 px x 500 px
              </Text>
              {UI.box(10)}

              <Layout.Btn
                color={UI.colors.primary}
                text={"Upload Web Image Banner"}
                onClick={() => {
                  this.upload_picture();
                }}
                width={220}
              />
            </View>

            <View style={{ height: 120, width: 120, justifyContent:'center' }}>
              <Layout.FilePicker
                _this={_this}
                title={""}
                additional={""}
                isRequired={false}
                text={""}
                state_name={"product_image"}
                onChange={async (file) => {
                  const uri = await UI.get_uri_from_file(file);
                  this.setState(
                    {
                      mobile_image: uri,
                      mobile_image_file: file,
                      did_upload_mobile_image: true,
                    },
                    () => {
                      //this.auth_upload();
                    }
                  );
                }}
                height={120}
                component={
                  this.state.mobile_image ?
                  <img
                    style={{ 
                      // height: 150, 
                      // width: 250, 
                      borderRadius: 5
                    }}
                    src={this.state.mobile_image}
                    alt=""
                  />
                  : 
                  <img
                    style={{ 
                      // height: 150, 
                      // width: 250, 
                      borderRadius: 5
                    }}
                    src={advocacy.mobileImage ? mobile_image : UI.SAMPLE_PRODUCT }
                    alt=""
                  />
                }
              />
            </View>
            {UI.box(20)}

            <View style={{ flex: 1.5, justifyContent: "center" }}>
              <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
              >
                {did_upload
                  ? "Mobile Image Banner Uploaded."
                  : "No Mobile Image Banner Uploaded."}{" "}
                Recommended Size: 500 px x 500 px
              </Text>
              {UI.box(10)}

              <Layout.Btn
                color={UI.colors.primary}
                text={"Upload Mobile Image Banner"}
                onClick={() => {
                  this.upload_mobile_picture();
                }}
                width={220}
              />
            </View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Advocacy Title"}
              placeholder={"Enter Advocacy Title"}
              state_name={"title"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={100}
            />
            {UI.box(10)}
            <Layout.DatePicker
              _this={_this}
              title={"Advocacy Start Date"}
              // isWithTimePicker={true}
              placeholder={"mm/dd/yyyy"}
              state_name={"start_date"}
              will_correct={true}
            />
            {UI.box(10)}
            <Layout.DatePicker
              _this={_this}
              title={"Advocacy End Date"}
              // isWithTimePicker={true}
              placeholder={"mm/dd/yyyy"}
              state_name={"end_date"}
              will_correct={true}
            />
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
             {/* <Layout.SelectPicker
                _this={_this}
                title={"Healthpass Name"}
                isRequired={false}
                state_name={"healthpass"}
                onChange={() => {
                  this.setState({is_change_healthpass_name: true})
                }}
              >
                {this.state.healthpassData.map((item) => {
                  return item ? (
                    <option value={item._id} selected={this.state.healthpass._id === item._id}>{item.title}</option>
                  ) : (
                    <View></View>
                  );
                })}
              </Layout.SelectPicker> */}
               <Layout.SelectPicker
                _this={_this}
                title={"Healthpass Name"}
                isRequired={false}
                state_name={"healthpass"}
                isMultiple={true}
                key={this.state.key}
              >
              <option value="">Select User Type</option>

              {
                this.state.healthpassData?.map(item => {
                  return(
                    <option value={item._id}>{item.title}</option>
                  )
                })
              }
              </Layout.SelectPicker>
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Advocacy Status"}
              isRequired={false}
              state_name={"status"}
            >
              <option value="">Select Status (Active or Inactive)</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Consultation Time Limit"}
              isRequired={false}
              state_name={"time_limit"}
            >
              <option value="">Select Time Limit</option>
              <option value="30 minutes">30 minutes</option>
            </Layout.SelectPicker>

          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <View style={{flex:1}}>
              <span className="font-semibold" style={{color: UI.colors.primary}}>Coming Soon</span>
              {UI.box(10)}
              <Checkbox
                _this={_this}
                text="Coming Soon"
                onClick={(value) => {
                  _this.setState({
                    comming_soon: !this.state.comming_soon,
                  });
                }}
                is_checked={this.state.comming_soon}
                color={"#2E2E2E"}
              />
            </View>
            {UI.box(10)}
            <View style={{flex: 1}}>
              <span className="font-semibold" style={{color: UI.colors.primary}}>Consultation Availability</span>
                {UI.box(10)}
                <div style={{display:'flex', gap:10}}>
                  <div  style={{display:'flex', gap:10}}>
                    Consult Now
                    {
                      this.state.consultationAvailability?.showConsultNow ?
                      <img src={UI.ON} alt="" className="cursor-pointer" height={50} width={50} onClick={ () => this.setState({
                        consultationAvailability: {
                          showConsultNow: !this.state.consultationAvailability.showConsultNow,
                          showConsultLater: this.state.consultationAvailability.showConsultLater,
                        }
                      })}/> :
                      <img src={UI.OFF} alt="" className="cursor-pointer" height={50} width={50} onClick={ () => this.setState({
                        consultationAvailability: {
                          showConsultNow: !this.state.consultationAvailability.showConsultNow,
                          showConsultLater: this.state.consultationAvailability.showConsultLater,
                        }
                      })}/>
                    }
                  </div>
                  <div style={{display:'flex', gap:10}}>
                    Consult Later
                    {
                      this.state.consultationAvailability?.showConsultLater ?
                      <img src={UI.ON} alt="" className="cursor-pointer" height={50} width={50} onClick={ () => this.setState({
                         consultationAvailability: {
                          showConsultLater: !this.state.consultationAvailability.showConsultLater,
                          showConsultNow: this.state.consultationAvailability.showConsultNow,

                        }
                      })}/> :
                      <img src={UI.OFF} alt="" className="cursor-pointer" height={50} width={50} onClick={ () => this.setState({
                         consultationAvailability: {
                          showConsultLater: !this.state.consultationAvailability.showConsultLater,
                          showConsultNow: this.state.consultationAvailability.showConsultNow,
                        }
                      })}/>
                    }
                  </div>
                </div>
            </View>
            {UI.box(10)}
            <View style={{flex:1}}>
              <span className="font-semibold" style={{color: UI.colors.primary}}>Add Advocacy Survey</span>
              {UI.box(10)}
              <View style={{flexDirection:'row'}}>
                <Checkbox
                  _this={_this}
                  text="Yes"
                  onClick={(value) => {
                    _this.setState({
                      isAddSurvey: true,
                    });
                  }}
                  is_checked={this.state.isAddSurvey}
                  color={"#2E2E2E"}
                />
                {UI.box(10)}
                <Checkbox
                  _this={_this}
                  text="No"
                  onClick={(value) => {
                    _this.setState({
                      isAddSurvey: false,
                    });
                  }}
                  is_checked={!this.state.isAddSurvey}
                  color={"#2E2E2E"}
                />
              </View>
            </View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              defaultValue={this.state.title}
              _this={_this}
              title={"Event Title"}
              placeholder={"Enter Event Title"}
              state_name={"event_title"}
              style={{ flex: 1 }}
              // isRequired={true}
              maxLength={100}
            />
            {UI.box(10)}
            <InputForm
              defaultValue={this.state.title}
              _this={_this}
              title={"Advocacy Join Button Title"}
              placeholder={"Enter Advocacy Join Button Title"}
              state_name={"join_button_title"}
              style={{ flex: 1 }}
              // isRequired={true}
              maxLength={100}
            />
            {UI.box(10)}
            <View style={{flex:1}}>
              <span className="font-semibold" style={{color: UI.colors.primary}}>Show Advocacy Join Button</span>
              {UI.box(10)}
              <View style={{flexDirection:'row'}}>
                <Checkbox
                  _this={_this}
                  text="Yes"
                  onClick={(value) => {
                    _this.setState({
                      isShowJoinButton: true,
                    });
                  }}
                  is_checked={this.state.isShowJoinButton}
                  color={"#2E2E2E"}
                />
                {UI.box(10)}
                <Checkbox
                  _this={_this}
                  text="No"
                  onClick={(value) => {
                    _this.setState({
                      isShowJoinButton: false,
                    });
                  }}
                  is_checked={!this.state.isShowJoinButton}
                  color={"#2E2E2E"}
                />
              </View>
            </View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Advocacy Description"}
              placeholder={"Enter Description Here"}
              state_name={"description"}
              style={{ flex: 1 }}
              height={100}
              isRequired={false}
              type={"big"}
              maxLength={UI.Constants.limits.description}
            />
          </UI.Row>
          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"SAVE CHANGES"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />

            {UI.box(20)}

          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});