import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  // ImageBackground,
  // ImageFit,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { 
  // Failed, 
  Success } from "../_components/Modal";
import MedicalAssociationApi from "../../api_services/medical_associations";

const context = "admin";

export default class AdminCmsMedicalAssociationsAdd extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "App Content",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      name: "",
      status: "",
      image: UI.PRODUCT_BOX,
      professionalType: "",

    });
  }

  onCreate = () => {};

  upload_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        image: file.uri,
        image_file: file.file,
        did_upload: true,
      }
    );
  };

  submit = async () => {
    UI.clear_errors();
    const { name } = this.state;

    if (!name) {
      await UI.error_form("name", "Medical Association Name is required.");
    }


    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    const payload = {
      name,
    }

    this.show_loading();

    // UI.webRequest({
    //   method: "post",
    //   url: "admin/lgu",
    //   params: payload,
    //   isMultiPart: true,  
    //   onFail: async (err) => {
    //     const data = err.data;
    //     const error_message = data.error;
    //     this.show_modal(
    //       <Failed
    //         title={"Failed"}
    //         description={error_message}
    //         onDismiss={() => {
    //           this.hide_modal();
    //         }}
    //       />
    //     );
    //   },
    //   onSuccess: async (response) => {
    //     this.show_modal(
    //       <Success
    //         title={"Success!"}
    //         description={"Successfully add item"}
    //         onDismiss={() => {
    //           this.hide_modal();
    //           UI.goBack();
    //         }}
    //       />
    //     )
    //   },
    //   onFinish: () => {
    //     this.hide_loading();
    //   },
    // });

    MedicalAssociationApi.addMedicalAssociation(payload).then( res => {
      this.hide_loading();
      if (res.status === 200) {
        // let data = res;
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully add item"}
            onDismiss={() => {
              this.hide_modal();
              UI.goBack();
            }}
          />
        )
      }
    });
  };
  
  render() {
    const _this = this;
    // const { height, width, isMobile } = _this.state;
    // const {  did_upload } = _this.state;

    return (
      <MainLayout context={context} additional={"medical-associations"} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Add Medical Association
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add item
            </Text>
          </UI.Row>
       
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Medical Association Name"}
              placeholder={"Enter Medical Association Name"}
              state_name={"name"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.name}
            />
            {UI.box(10)}
            {/* <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={true}
              state_name={"status"}
            >
              <option value="">Item Status (Active or Inactive)</option>
              <option value="Active" selected={this.state.status ? true : false}>Active</option>
              <option value="Inactive" selected={this.state.status ? true : false}>Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)} */}
            <View style = {{flex: 1}}></View>
            <View style={{ flex: 1 }}></View>
          </UI.Row>

          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.secondary}
              onClick={() => {
                this.submit();
              }}
              text={"SAVE"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  }
});