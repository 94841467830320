import UI from "../../shared/Components/UI/js";
import ServiceApi from "../service";

class LaboratoryPartnerApi {
  baseURL = "admin";

  async getAllLaboratoryPartner (params) {
    const response = await ServiceApi.get(this.baseURL + `/merchant/laboratory/partners`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async getAllLaboratoryPartnerTransactions (params) {
    const response = await ServiceApi.get(this.baseURL + `/transactions`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async addLaboratoryPartner (payload) {
 
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }

    let formData = new FormData();

    Object.keys(payload).forEach((key) => {
      if (key === "productCategory") {
        payload[key] = JSON.stringify(payload.productCategory)
      }
      formData.append(key, payload[key]);
    });

    const response = await ServiceApi.post(this.baseURL + `/merchant/create/lab-partner`, formData, config);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async updateLaboratoryPartner (laboratoryId, payload) {

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }

    let formData = new FormData();
    
    Object.keys(payload).forEach((key) => {
      if (key === "productCategory") {
        payload[key] = JSON.stringify(payload.productCategory)
      }
      formData.append(key, payload[key]);
    });

    const response = await ServiceApi.put(this.baseURL + `/merchant/update/lab-partner/${laboratoryId}`, formData, config);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async deleteLaboratoryPartner (laboratoryId, payload) {

    const response = await ServiceApi.delete(this.baseURL + `/merchant/${laboratoryId}`);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

}

export default new LaboratoryPartnerApi();
