import React, { useCallback, useState, useEffect } from "react";
import DataTable from "../../ComponentsFC/DataTable";
import UI from "../../shared/Components/UI/js";
import moment from "moment";
import styled from "styled-components";
import { Row, Col, Button, DatePicker, Input, Select, Typography } from "antd";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import ServicePatientsApi from "../../api_services/patients";

const FinanceUserGroupSubscriptionBillingTable = ({ _this }) => {
  const [tableData, setTableData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(30);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [filterByStatus, setFilterByStatus] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [sortByStartEndDate, setSortByStartEndDate] = useState([]);

  const getPatients = useCallback(async () => {
    try {
      setIsLoading(true);

      let params = {};

      if (filterByStatus !== "") {
        params.gender = filterByStatus;
      }


      if (searchQuery) {
        params.fullname = searchQuery;
      }

      if (currentPageNum) {
        params.page = currentPageNum;
        params.limit = tableEntries;
      }

      ServicePatientsApi.getTest(params).then( res => {
        setIsLoading(false);
        if (res.status === 200) {
          let data = res.data;
          setTotalRecords(data.length);
          setTableData(data);
        }
      });

    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }, [currentPageNum, tableEntries, filterByStatus, searchQuery]);

  useEffect(() => {
    getPatients();
  }, [getPatients]);

  const handleFilterByShiftDateRange = (dates, dateStrings) => {
    console.log(dates);
    console.log(dateStrings);
    setSortByStartEndDate(dateStrings);
  };

  const handlePaginateTable = (pageNum) => {
    // console.log("Currently Selected Page # of Antd Table: ", pageNum);
    setCurrentPageNum(pageNum);
  };

  const tableColumnHeader = [
    {
      title: "Group Name",
      dataIndex: "name",
      key: "name",
      render: (name) => " Group 1",
      width: 200,
    },
    {
      title: "Subscriptio Type",
      dataIndex: "user",
      key: "userName",
      render: (user) => {
        return "Plan One"
      },
      width: 200,
    },
    {
      title: "Start Date/ End Date",
      dataIndex: "relationshipToUser",
      key: "relationshipToUser",
      render: (user) => {
        return (
          <StyledRangePicker
            separator={<span className="mr-6">-</span>}
            size="large"
            ranges={{
              Today: [moment(), moment()],
              "This Month": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
            }}
            onChange={handleFilterByShiftDateRange}
          />
        )
      },
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "gender",
      key: "gender",
      render: (user) => {
        return UI.get_color_status_text("Requested")
      },
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "createdAt",
      key: "date_added",
      render: (data) => {
        return (
          <ActionStyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            placeholder="Select Option"
            style={{
              width: "100%",
              height: "20px !important"
            }}
            value={"Pending"}
            // allowClear
            suffixIcon={<DropdownIcon />}
            onChange={(value) => setFilterByStatus(value)}
            onClear={() => handleClearViaSelectField("Status")}
          >
            <Select.Option title="Action" value="male">
              Pending
            </Select.Option>
            <Select.Option title="Action" value="male">
              Unpaid
            </Select.Option>
            <Select.Option title="Action" value="female">
              Paid
            </Select.Option>
          </ActionStyledSelect>
        )
      },
      width: 200,
    },
  ];

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCSVExport = () => {
    const csv_data = tableData.map((item, index) => {
      const d = {
        "Patient Name": UI.DataHelper.carespan.get_name(item.name) ?? " ",
        "User Account Name":
          item.user?.firstName || item.user?.lastName
            ? item.user?.firstName + " " + item.user?.lastName
            : " ",
        "Relationship to Patient": item?.relationshipToUser ?? " ",
        Email: item?.user?.email ?? " ",
        "Mobile Number": item?.user?.phoneNumber ?? " ",
        Gender: item?.gender ?? " ",
        "Registration Date": moment(item.createdAt).format("MM/DD/YYYY"),
        "Missed Consultations": item.missedConsultations,
        "Cancelled Consultations": item.cancelledConsultations,
        "Completed Consultations": item.completedConsultations,
      };

      return d;
    });

    UI.DownloadCSV("Patient Account " + UI.get_current_date_string(), csv_data);
  };

  const handleClearViaSelectField = (fieldName) => {
    console.log("Handle CLEAR", fieldName);
    if (fieldName === "Date Filter by") {
      setSearchResults([]);
    }
  };

  return (
    <>
      <FilterBox>
        <Title level={5}>User Group Subscription Billing</Title>
        <Toolbar gutter={14}>
          <ToolItem span={8}>
            <StyledFieldLabel>Status</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Select Option"
              style={{
                width: "100%",
              }}
              value="Active"
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => setFilterByStatus(value)}
              onClear={() => handleClearViaSelectField("Status")}
            >
              <Select.Option title="Action" value="male">
                Active
              </Select.Option>
              <Select.Option title="Action" value="male">
                Inactive
              </Select.Option>
              <Select.Option title="Action" value="male">
                Overdue
              </Select.Option>
            </StyledSelect>
          </ToolItem>
        </Toolbar>
      </FilterBox>
      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="30"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="5">5 Entries</Select.Option>
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>
          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getPatients}
            style={{ width: 200 }}
          />
          <StyledButton type="primary" onClick={handleCSVExport}>
            Export to CSV
          </StyledButton>
        </Toolbar>
        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          defaultPageSize={30}
          pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default FinanceUserGroupSubscriptionBillingTable;

// CSS Styled Components ----------------------
const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const ToolItem = styled(Col)`
  position: relative;
  .ant-picker {
    margin-left: 102px;
  }
  .ant-picker-range {
    margin-left: 62px;
  }
`;

const Title = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.4375rem !important; // 15px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
  padding-left: 35px;
  margin-bottom: 25px !important;
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const ActionStyledSelect = styled(Select)`
  width: 130px !important;

  .ant-select-selector {
    border-radius: 10px !important;
    border-width: 2px !important;
    height: 40px !important;
    align-items: center;
    font-size: 10px;
    line-height: 1.4375rem;
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  flex-direction: row;
  border-radius: 10px;
  border-width: 2px !important;
  height: 45px;
  margin-left: auto;

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  .ant-picker-range-separator {
    display: block !important;
  }
`;