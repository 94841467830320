import React, { Component } from "react";
import { Select, Tag } from 'antd';
import styled from "styled-components"; 
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import TouchableOpacity, {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { 
  // CustomFailed,
  Failed,
  Success } from "../_components/Modal";
import "./css.css";

// const role = mem.get(UI.Constants.ROLE);
// const context = role;
export default class VoucherUserGroupAdd extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "User Group List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: mem.get(UI.Constants.ROLE), // RESUSEABLE PAGES TO ADMIN, USER GROUP ADMIN, VOUCHER ADMIN ROLE
      tagging:[],
      allowed_tagging: [],
      data:[],
      all_data:[],
      members:[],
      name: "",
      status:"Active",
      description: "",
      users: [],
      allowed_user: "",
      parent_user_groups: [],
      allowed_parent_user_group: "",
      isShowMembers: false,
      isShowSeeMemberButton: false,
      user_group_type: "mwell",
      domainWhitelist: []
    });
  }
 
  onCreate = () => {
    this.loadTagging();
  };

  loadUserGroup = async () => {

    UI.webRequest({
      method: "post",
      url: "admin/usergroup/all",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        const aktivo = data.filter(item => item.hasOwnProperty('tags') && item?.tags[0]?.name === 'aktivo-organization-group')

        this.setState({ parent_user_groups: aktivo });
      },
      onFinish: () => {
      },
    });
  }

  // validate_csv = async (items) => {
  //   console.log(items)
  //   return new Promise((resolve, reject) => {
  //     if (items.length === 0) {
  //       return;
  //     }

  //     const uploadUserTemplate =
  //       "ID,Fullname,Email";

  //     if (items[0] === uploadUserTemplate || items[0].includes(uploadUserTemplate)) { 
  //       resolve();
  //     } else {
  //       this.show_modal(
  //         <CustomFailed
  //           title={"Process Failed"}
  //           description_title={""}
  //           description={"Invalid CSV Format"}
  //           _this={this}
  //           onDismiss={() => {
  //             this.hide_modal();
  //           }}
  //         />
  //       );
  //     }
  //   });
  // };

  // proccess_users_csv = async (content) => {
  //   return new Promise((resolve, reject) => {
  //     if (content.length === 0) {
  //       resolve([]);
  //       return;
  //     }
  //     const regex1 = "/n/n/";
  //     const regex2 = "\r\n";
  //     content = UI.replaceAll(content, regex2, regex1);
  //     content = UI.replaceAll(content, "\n", "");
  //     content = UI.replaceAll(content, regex1, regex2);
  //     var array = content.split(regex2);
  //     let result = [];

  //     let headers = array[0].split(",");

  //     for (let i = 1; i < array.length - 1; i++) {
  //       let obj = {};
  //       let str = array[i];
  //       let s = "";
  //       let flag = 0;
  //       for (let ch of str) {
  //         if (ch === '"' && flag === 0) {
  //           flag = 1;
  //         } else if (ch === '"' && flag === 1) flag = 0;
  //         if (ch === "," && flag === 0) ch = "|";
  //         if (ch !== '"') s += ch;
  //       }

  //       let properties = s.split("|");

  //       for (let j in headers) {
  //         obj[headers[j]] = properties[j];
  //       }
  //       result.push(obj);
  //     }

  //     // result.shift();
  //     // result.pop();
  //     resolve(result);
  //   });
  // };

  // process_user_name_address = async (arr = []) => {
  //   return new Promise((resolve, reject) => {
  //     if (arr.length === 0) {
  //       resolve([]);
  //     }

  //     const new_data = [];
  //     arr.forEach((item, index) => {
  //       item.id = item["ID"] ?? ""
  //       item.fullname = item["Fullname"]
  //       item.email = item["Email"]
 
  //       delete item["ID"]
  //       delete item["Fullname"]
  //       delete item["Email"]
  //       item.id && new_data.push(item);
  //       if (index + 1 === arr.length) {
  //         resolve(new_data);
  //       }
  //     });
  //   });
  // };

  // import_csv = async () => {
  //   const content = await UI.get_csv_contents();
  //   const all_text = content.split("\r\n");
  //   await this.validate_csv(all_text);
  //   const new_list = await this.proccess_users_csv(content);
  //   console.log(content)
  //   const user_list = await this.process_user_name_address(new_list);
    
  //   this.setState({
  //     members: user_list,
  //     data: user_list,
  //     all_data: user_list,
  //     isShowSeeMemberButton: true
  //   },
  //     () => {
  //       this.reload_table("member_list_add");
  //     })
  // };

  loadTagging = async () => {

    UI.webRequest({
      method: "get",
      url: "admin/tags",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        this.setState({
          tagging: data
        });
      },
      onFinish: () => {},
    });
  }

  submit = async () => {
    UI.clear_errors();

    let {
      name,
      members,
      description,
      allowed_tagging,
      status,
      allowed_parent_user_group,
      user_group_type,
      domainWhitelist
    } = this.state;

    const membersId = members.map((item => item.id))

    let payload = {
      name,
      description,
      tags: allowed_tagging,
      domainWhitelist
    }

    if (!name) {
      await UI.error_form("name", "Group Name is required.");
    }

    if(!description) {
      await UI.error_form("description", "Description is required.");
    }

    if (allowed_tagging.length === 0) {
      await UI.error_form("allowed_tagging", "User Group Category is required.");
    } else {

      if(
        user_group_type.startsWith('aktivo-')
      ) {
        if (!status) {
          await UI.error_form("status", "Status is required.");
        }

        payload.status = status
      }
    }

    if(user_group_type === 'aktivo-department') {
      payload.parentUserGroup = allowed_parent_user_group

      if (!allowed_parent_user_group) {
        await UI.error_form("allowed_parent_user_group", "User Group Category is required.");
      }
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      this.hide_loading();
      return;
    }

    this.show_loading();
    UI.webRequest({
      method: "post",
      url: "admin/usergroup",
      params: {
        payload,
        members: membersId
      },
      onFail: async (err) => {
        this.show_modal(<Failed description={err.data.m} onDismiss={this.hide_modal} />);
      },
      onSuccess: async (response) => {
        this.show_modal(
          <Success
            descriptionCenter={"Successfully added the user group."}
            onDismiss={() => {
              UI.goBack();
              this.hide_modal();
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;

    const {
      user_group_type,
      tagging
    } = this.state
    
    return (
      <MainLayout context={this.state.context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Add New Group
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to complete registration
            </Text>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Group Name"}
              placeholder={"Enter Group Name"}
              state_name={"name"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.title}
            />
            {UI.box(20)}
            <Layout.SelectPicker
              _this={this}
              title={"User Group Category"}
              state_name={"allowed_tagging"}
              isMultiple={true}
              isRequired={true}
              onChange={(e) => {

                if(e.length) {

                  let user_group_type;
                  let _tagging;

                  switch(e[0]?.label) {
                    case 'aktivo-department-group':
                      this.loadUserGroup();

                      user_group_type = 'aktivo-department'
                      _tagging = []

                      break;
                    
                    case 'aktivo-organization-group':
                      user_group_type = 'aktivo-organization'
                      _tagging = []

                      break;
                    
                    default:
                      user_group_type = 'mwell'
                      _tagging = tagging.filter(item => 
                        item.name !== 'aktivo-department-group' &&
                        item.name !== 'aktivo-organization-group'
                      )

                      break;
                  }

                  this.setState({
                    user_group_type,
                    tagging: _tagging
                  })

                } else {
                  this.loadTagging();
                }
              }}
            >
              <option value="">Select User Group Type</option>
              {
                this.state.tagging.map(item => {
                  return(
                    <option value={item._id}>{item.name}</option>
                  )
                })
              }
            </Layout.SelectPicker>
            {UI.box(20)}
            {user_group_type === 'aktivo-department' ?
            <Layout.SelectPicker
              _this={this}
              title={"Parent User Group"}
              state_name={"allowed_parent_user_group"}
              isRequired={"true"}
            >
              <option value="">Select User Group Type</option>
              {
                this.state.parent_user_groups.map((item) => {
                  return (
                    <option value={item._id}>{item.name}</option>
                  )
                })
              }
            </Layout.SelectPicker>
            : <View style={{ flex: 1 }}></View>}
          </UI.Row>
          {/* {UI.box(20)} */}

         
          {user_group_type.startsWith('aktivo-') && (
            <>
              {UI.box(20)}
              <UI.Row breakpoint={970} _this={_this}>
                <Layout.SelectPicker
                  _this={this}
                  title={"Select Status"}
                  state_name={"status"}
                >
                  {['Active', 'Inactive'].map((value, index) => {
                    return (
                      <option 
                        key={index} 
                        value={value}
                      >{value}</option>
                    )
                  })}
                </Layout.SelectPicker>
                {UI.box(20)}

                {/* {user_group_type === 'mwell' ?
                  <View style={{ justifyContent: "end", flex: 1 }}>
                    <Layout.Btn
                      color={UI.colors.primary}
                      id="importProduct"
                      text={"Import Members CSV"}
                      onClick={() => {
                        this.import_csv()
                      }}
                      height={50}
                      width={180}
                    />
                  </View>
                : <View style={{ flex: 1 }}></View> } */}
                {/* <View style={{ flex: 1 }}></View> */}
              </UI.Row>
            </>
          )}
          
          {user_group_type.startsWith('aktivo-organization') && (
            <>
              {UI.box(20)}
              <UI.Row breakpoint={970} _this={_this}>
                <div className="flex flex-col w-full">
                  <Text
                    style={{
                      color: UI.colors.primary,
                      fontWeight: 500,
                      marginBottom: 10,
                      fontFamily: "Poppins",
                      fontSize: "15px"
                    }}
                  >
                    White List Domain
                  </Text>
                  <StyledSelect
                    mode="tags"
                    style={{
                      width: '100%',
                      height: 100
                    }}
                    tagRender={(props) => {
                      const { label, closable, onClose } = props;
                      const onPreventMouseDown = (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      };

                      return (
                        <StyledTags
                          onMouseDown={onPreventMouseDown}
                          closable={closable}
                          onClose={onClose}
                        >
                          {label}
                        </StyledTags>
                      )
                    }}
                    onChange={(value) => {
                      this.setState({
                        domainWhitelist: value
                      })
                    }}
                    defaultValue={this.state.domainWhitelist}
                    open={false}
                  />
                  {UI.box(20)}
                </div>
              </UI.Row>
            </>
          )}

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row>

          {UI.box(10)}

          { this.state.isShowSeeMemberButton &&
            <div style={{ color: UI.colors.secondary}}>
              <button onClick={() => this.setState({isShowMembers: !this.state.isShowMembers})} style={{ textDecoration:'underline', borderRadius:'7px', padding:'5px 0px', fontSize:'14px', flex:1,}}>
                Show Members Import
              </button>
            </div>
          }

          {
            this.state.isShowMembers && <>
              <UI.Row
                style={styles.main_container_header}
                breakpoint={950}
                _this={_this}
              >
                <View
                  style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
                >
                  <Text style={styles.entry_header}>Only Show</Text>
                  {UI.box(10)}
                  <View>
                    <EntryPicker table_name={"member_list_add"} _this={_this} />
                  </View>
                </View>
              </UI.Row>
              {UI.box(10)}
              <CustomDatatable
                state_name={"member_list_add"}
                _this={_this}
                columns={[
                  { "Fullname": "fullname" },
                  { "Email": "email" },
                  { Action : "action"}
                ]}
                data={_this.state.data.map((item, index) => {
                  return {
                    fullname: item.fullname,
                    email: item.email,
                    action: (
                      <View key={index} style={{ flexDirection: "row" }}>
                        <TouchableOpacity
                          onClick={() => {
                            const filter = _this.state.data.filter((a, f) =>  a.id !== item.id)
                            _this.setState({
                              members: filter,
                              data:filter,
                              all_data:filter,
                            },
                            () => {
                              _this.reload_table("member_list_add");
                            });
                          }}
                        >
                          <Image
                            height={28}
                            width={28}
                            tintColor={"transparent"}
                            source={UI.DELETE_BTN}
                          />
                        </TouchableOpacity>
                      </View>
                    ),
                  };
                })}
                on_page={(page, callback, table_name) => {
                  console.log(page, callback, table_name)
                  UI.on_page(this.state.all_data, callback, table_name, page);
                }}
              />

            </>
          }
          
          {UI.box(10)}

           <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Description"}
              placeholder={"Enter Description Here"}
              state_name={"description"}
              style={{ flex: 1 }}
              height={100}
              isRequired={true}
              type={"big"}
              maxLength={UI.Constants.limits.description}
            />
          </UI.Row>

          {UI.box(20)}

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"ADD USER GROUP"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});

const StyledSelect = styled(Select)`

  font-weight: bold;
  font-family: "Poppins";

  &:not(.ant-select-disabled):hover,
  &:not(.ant-select-disabled):focus,
  &:not(.ant-select-disabled):active {
    .ant-select-selector {
      border-color: #04ccff !important;
    }
  }

  .ant-select-selector {
    padding: 7px;
    border-radius: 12px !important;
    border-style: solid !important;
    border-width: 2px !important;
  }

  .ant-select-selection-overflow-item {
    height: 100px;
  }
`
const StyledTags = styled(Tag)`
  color: #000;
  display: flex;
  margin-right: 0.5rem;
  align-items: center;
  background: #e6e6e6;
  margin-top: 3px;
`