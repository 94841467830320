import interpolate from "interpolate-range";
import React, { Component } from "react";
import { animated, Transition } from "react-spring";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import CalendarHelper from "./CalendarHelper";
import "./css.css";
import DayView from "./DayView";
import MonthView from "./MonthView";
import WeekView from "./WeekView";

const border_color = "#EBEBEB";

const date_int_str = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
// const date_str_int = {
//   January: 0,
//   February: 1,
//   March: 2,
//   April: 3,
//   May: 4,
//   June: 5,
//   July: 6,
//   August: 7,
//   September: 8,
//   October: 9,
//   November: 10,
//   December: 11,
// };

// const day_int_str = [
//   "Sunday",
//   "Monday",
//   "Tuesday",
//   "Wednesday",
//   "Thursday",
//   "Friday",
//   "Saturday",
// ];

// const total_days = 42;

let width_limit = 670;
const side_width = 230;

export default class CalendarSchedule extends Component {
  constructor(props) {
    super(props);
    const createdDate = new Date();
    this.state = {
      show_menu: false,
      sidebar_animation: 0, //0 to 100
      current_month: createdDate.getMonth(),
      current_date: createdDate.getDate(),
      current_year: createdDate.getFullYear(),

      selected_month: createdDate.getMonth(),
      selected_date: createdDate.getDate(),
      selected_year: createdDate.getFullYear(),
      selected_events: [],
      calendar_type: "month", //can be month, week or day

      selected_week_items: [],
      week_items: [],
      selected_timestamp: Date.now(),
      current_timestamp: Date.now(),
    };
  }

  get_week_item = (weeks, timestamp) => {
    return new Promise((resolve, reject) => {
      if (weeks.length === 0) {
        resolve([]);
      }

      weeks.forEach((week) => {
        week.forEach((day) => {
          const range = day.range;
          const { start, end } = range;
          if (timestamp >= start && timestamp < end) {
            resolve(week);
          }
        });
      });
    });
  };

  get_weekly_header_text = (week) => {
    if (week.length !== 7) {
      return "";
    } else {
      let start = week[0];
      let end = week[6];
      let start_timestamp = start.range.start;
      let end_timestamp = end.range.start;

      let start_date = new Date(start_timestamp);
      let end_date = new Date(end_timestamp);

      let year = end_date.getFullYear();

      let start_month_str = date_int_str[start_date.getMonth()];
      let end_month_str = date_int_str[end_date.getMonth()];
      let header_text = "";
      if (start_month_str === end_month_str) {
        header_text =
          start_month_str +
          " " +
          start_date.getDate() +
          " - " +
          end_date.getDate() +
          ", " +
          year;
      } else {
        header_text =
          start_month_str +
          " " +
          start_date.getDate() +
          " - " +
          end_month_str +
          " " +
          end_date.getDate() +
          ", " +
          year;
      }

      return header_text;
    }
  };

  set_week_items = async () => {
    const week_days = CalendarHelper.get_weekly_days(
      this.state.current_month,
      this.state.current_year
    );
    const { current_timestamp } = this.state;

    const current_week = await this.get_week_item(week_days, current_timestamp);

    this.get_weekly_header_text(current_week);

    this.setState({
      week_items: week_days,
      selected_week_items: current_week,
    });
  };

  anim_timeout = setTimeout(() => {}, 10);

  animate_sidebar = () => {
    let anim = this.state.sidebar_animation;
    if (anim < 100) {
      this.anim_timeout = setTimeout(() => {
        this.setState(
          {
            show_menu: true,
            sidebar_animation: anim + 10,
          },
          () => {
            this.animate_sidebar();
          }
        );
      }, 1);
    } else {
      clearTimeout(this.anim_timeout);
    }
  };

  close_sidebar = () => {
    let anim = this.state.sidebar_animation;
    if (anim > 0) {
      this.anim_timeout = setTimeout(() => {
        this.setState(
          {
            show_menu: false,
            sidebar_animation: anim - 10,
          },
          () => {
            this.close_sidebar();
          }
        );
      }, 1);
    } else {
      clearTimeout(this.anim_timeout);
    }
  };

  componentDidMount = () => {
    const _this = this.props._this;
    _this.add_resize_function("close_calendar_sidebar", this.close_sidebar);
    _this.add_scroll_function("close_calendar_sidebar", this.close_sidebar);

    this.set_week_items();
  };

  render() {
    const _this = this.props._this;
    let { id } = this.props;
    const { 
      // height,
       width,
        // isMobile,
         current_scroll } = _this.state;
    let { show_menu, calendar_type } = this.state;

    id = "calendar_" + id;
    let exact_id = "exact_" + id;

    let container_width = UI.measure(id).width;
    if (!container_width) {
      container_width = width;
    }
    let container_height = UI.measure(id).height;
    if (!container_height) {
      container_height = 0;
    }
    let constant_width = container_width < 755 ? 230 : 300;
    let sidebar_width = constant_width;

    // const animate_opacity = interpolate({
    //   inputRange: [0, 100],
    //   outputRange: [0, 0.75],
    //   clamp: true,
    // });
    // const animate_sidebar_opacity = interpolate({
    //   inputRange: [0, 100],
    //   outputRange: [0.5, 1],
    //   clamp: true,
    // });
    const animate_width = interpolate({
      inputRange: [0, 100],
      outputRange: [0, sidebar_width],
      clamp: true,
    });

    // const bg_op = animate_opacity(this.state.sidebar_animation);
    // let sidebar_opacity = animate_sidebar_opacity(this.state.sidebar_animation);

    let show_side = true;

    if (calendar_type === "week") {
      width_limit = 850;
    }

    if (container_width <= width_limit) {
      show_side = false;

      sidebar_width = animate_width(this.state.sidebar_animation);
    } else {
      // sidebar_opacity = 1;
    }

    return (
      <View id={id} style={{ ...styles.main_content_container }}>
        <View style={{ width: "100%", height: "100%", flexDirection: "row" }}>
          <Transition
            items={show_menu}
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{ opacity: 0 }}
            delay={0}
            config={{ duration: 200 }}
          >
            {(style, item) => {
              if (item) {
                return (
                  <animated.div style={{ ...style }}>
                    <View
                      onClick={() => {
                        this.close_sidebar();
                      }}
                      style={{
                        height: container_height,
                        width: container_width,
                        backgroundColor: "rgba(0,0,0,0.75)",
                        position: "absolute",
                        flexDirection: "row",
                        marginTop: -current_scroll,
                      }}
                    ></View>
                  </animated.div>
                );
              }
            }}
          </Transition>

          <Transition
            items={show_menu}
            from={{ marginLeft: -side_width }}
            enter={{ marginLeft: 0 }}
            leave={{ marginLeft: -side_width }}
            delay={150}
            config={{ duration: 100 }}
          >
            {(style, item) => {
              if (item) {
                return (
                  <animated.div style={{ ...style, position: "absolute" }}>
                    <View
                      style={{
                        ...styles.side_bar,
                        position: "absolute",
                        width: side_width,
                        height: container_height,
                        opacity: 1,
                        marginTop: -current_scroll,
                      }}
                    >
                      <View
                        style={{
                          ...styles.bottom_border,
                          display: show_side
                            ? "flex"
                            : this.state.sidebar_animation < 100
                            ? "none"
                            : "flex",
                          width: side_width,
                          paddingBottom: 18,
                        }}
                      >
                        <Text style={{ ...styles.box_header_text, flex: null }}>
                          Appointments
                        </Text>
                        {UI.box(10)}
                        <Text style={{ ...styles.box_header_text, flex: null }}>
                          {date_int_str[this.state.selected_month]}{" "}
                          {this.state.selected_date}, {this.state.selected_year}
                        </Text>
                      </View>
                      <View
                        style={{
                          display: show_side
                            ? "flex"
                            : this.state.sidebar_animation < 100
                            ? "none"
                            : "flex",
                          width: side_width,
                        }}
                      >
                        {this.state.selected_events.map((item) => {
                          return (
                            <ScheduleItem
                              key={UI.uniqid()}
                              name={"Patient Name Here"}
                              type={"Consultation - Specialty"}
                              time={"10:00 am"}
                              image={UI.SAMPLE_USER}
                              item={item}
                              context={this}
                            />
                          );
                        })}
                        {this.state.selected_events.length === 0 && (
                          <View style={styles.no_events}>
                            <Image
                              height={100}
                              width={100}
                              tintColor={"transparent"}
                              source={UI.NO_INFO}
                            />
                            <Text style={styles.empty_text}>
                              NO CONSULTATION DATA AVAILABLE YET
                            </Text>
                          </View>
                        )}
                      </View>
                    </View>
                  </animated.div>
                );
              }
            }}
          </Transition>
          <View style={{ flex: 1 }}>
            <CalendarHeader
              basis={container_width}
              _this={_this}
              context={this}
            />
            {this.state.calendar_type === "month" && (
              <MonthView
                month={this.state.current_month}
                year={this.state.current_year}
                _this={_this}
                context={this}
                container_width={container_width}
              />
            )}
            {this.state.calendar_type === "week" && (
              <WeekView
                month={this.state.current_month}
                year={this.state.current_year}
                _this={_this}
                context={this}
                container_width={container_width}
                exact_id={exact_id}
              />
            )}
            {this.state.calendar_type === "day" && (
              <DayView
                month={this.state.current_month}
                year={this.state.current_year}
                _this={_this}
                context={this}
                container_width={container_width}
                exact_id={exact_id}
              />
            )}
          </View>
        </View>
      </View>
    );
  }
}

const ScheduleItem = (
  props = { name: "", type: "", time: "", image: "", item: {} }
) => {
  const { context } = props;
  return (
    <View style={styles.patient_item}>
      <Image
        height={45}
        width={45}
        tintColor={"transparent"}
        source={props.image}
      />

      {UI.box(15)}

      <View style={{ flex: 1 }}>
        <Text style={styles.header_1}>{props.name}</Text>
        <Text style={{ ...styles.header_1, color: UI.colors.primary }}>
          {props.type}
        </Text>
        <Text style={{ ...styles.header_1, color: "#BFBFBF" }}>
          {props.time}
        </Text>
      </View>

      <TouchableOpacity
        onClick={() => {
          if (context.props.onItemClick) {
            context.props.onItemClick(props.item);
          }
        }}
        style={{ alignSelf: "center" }}
      >
        <Image
          height={30}
          width={30}
          tintColor={UI.colors.primary}
          source={UI.CHEVRON_RIGHT}
        />
      </TouchableOpacity>
    </View>
  );
};

const CalendarHeader = (props = { _this: null, basis: 0, context: null }) => {
  const _this = props._this;
  const context = props.context;
  // const { height, width, isMobile } = _this.state;
  let filter_id = "calendar_ " + props.context.props.id;
  let show_side = true;
  if (props.basis <= 670) {
    show_side = false;
  }

  let header_text = "";
  if (context.state.calendar_type === "month") {
    header_text =
      date_int_str[context.state.current_month] +
      " " +
      context.state.current_year;
  }
  if (context.state.calendar_type === "week") {
    header_text = context.get_weekly_header_text(
      context.state.selected_week_items
    );
  }

  if (context.state.calendar_type === "day") {
    header_text =
      date_int_str[context.state.selected_month] +
      " " +
      context.state.selected_date +
      ", " +
      context.state.selected_year;
  }

  const month_prev = () => {
    let d = new Date();
    d.setFullYear(context.state.current_year);
    d.setMonth(context.state.current_month - 1);

    context.setState({
      current_month: d.getMonth(),
      current_year: d.getFullYear(),
    });
  };

  const month_next = () => {
    let d = new Date();
    d.setFullYear(context.state.current_year);
    d.setMonth(context.state.current_month + 1);

    context.setState({
      current_month: d.getMonth(),
      current_year: d.getFullYear(),
    });
  };

  const prev_arrow = () => {
    if (context.state.calendar_type === "month") {
      month_prev();
    }
  };
  const next_arrow = () => {
    if (context.state.calendar_type === "month") {
      month_next();
    }
  };

  return (
    <UI.Row
      basis={props.basis}
      breakpoint={685}
      _this={_this}
      style={{
        padding: 20,
        paddingBottom: 10,
      }}
    >
      <View style={{ flexDirection: "row", flex: 1, alignSelf: "flex-start" }}>
        <TouchableOpacity
          onClick={() => {
            context.animate_sidebar();
          }}
          style={{
            ...styles.menu_btn,
            display: show_side ? "none" : "flex",
          }}
        >
          <Image
            height={25}
            width={25}
            tintColor={UI.colors.primary}
            source={UI.MENU}
          />
        </TouchableOpacity>
        <TouchableOpacity onClick={prev_arrow}>
          <Image
            height={20}
            width={20}
            tintColor={UI.colors.primary}
            source={UI.CHEVRON_LEFT}
          />
        </TouchableOpacity>
        {UI.box(5)}
        <TouchableOpacity onClick={next_arrow}>
          <Image
            height={20}
            width={20}
            tintColor={UI.colors.primary}
            source={UI.CHEVRON_RIGHT}
          />
        </TouchableOpacity>
        {UI.box(20)}

        <Text style={{ ...styles.box_header_text, flex: null }}>
          {header_text}
        </Text>
      </View>
      {UI.box(10)}
      <View style={{ alignSelf: "flex-end" }}>
        <Layout.SortFilter
          btn_1={{
            text: "Month",
            onClick: (text) => {},
          }}
          btn_2={{
            text: "Week",
            onClick: (text) => {},
          }}
          btn_3={{
            text: "Day",
            onClick: (text) => {},
          }}
          onChange={(val) => {
            _this.setState({
              ["sort_filter_" + filter_id]: val,
            });
            context.setState({
              calendar_type: val,
              my_filter_id: "sort_filter_" + filter_id,
            });
          }}
          _this={_this}
          state_name={filter_id}
        />
      </View>
    </UI.Row>
  );
};

const styles = StyleSheet.create({
  no_events: {
    width: "100%",
    alignItems: "center",
    paddingTop: 40,
  },
  empty_text: {
    color: "#BFBFBF",
    width: 200,
    textAlign: "center",
    fontSize: 15,
    fontWeight: "bold",
    marginTop: 30,
  },
  event_text: {
    fontSize: 10,
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  event_item: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: UI.colors.primary,
    alignSelf: "flex-end",
    margin: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  month_row: {
    borderColor: border_color,
    flex: 1,
    borderStyle: "solid",
    borderWidth: 2,
    marginTop: -2,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    flexDirection: "row",
  },
  calendar_cell: {
    minHeight: 91,
    maxHeight: 91,
    flex: 1,
    borderStyle: "solid",
    borderWidth: 0,
  },
  trailing_date: {
    minHeight: 91,
    maxHeight: 91,
    flex: 1,
    borderStyle: "solid",
    borderWidth: 0,
  },
  patient_item: {
    flexDirection: "row",
    padding: 10,
    paddingBottom: 15,
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  bottom_border: {
    padding: 20,
    borderStyle: "solid",
    borderColor: border_color,
    borderBottomWidth: 2,
  },
  overlay: {
    position: "absolute",
    backgroundColor: "black",
    borderRadius: 5,
  },
  menu_btn: {
    height: 25,
    width: 25,
    justifyContent: "center",
    alignItems: "center",
    marginTop: -2,
    marginRight: 20,
  },
  header_item: {
    flex: 1,
    borderColor: border_color,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
  },
  header_text: {
    fontSize: 15,
    color: "#2E2E2E",
    fontWeight: "bold",
    textAlign: "center",
  },
  header_container: {
    minHeight: 35,
    width: "100%",
    borderColor: border_color,
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    flexDirection: "row",
  },
  side_bar: {
    height: "100%",
    width: side_width,
    borderRightWidth: 2,
    borderRightStyle: "solid",
    borderColor: border_color,
    backgroundColor: "white",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    zIndex: 9,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  main_content_container: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    width: "100%",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
