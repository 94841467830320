import React, { useState } from "react";
import styled from "styled-components";
import { Select, Row, Col } from "antd";
import UI from "../../../shared/Components/UI/js";
import TouchableOpacity, {
  mem,
  StyleSheet,
  Text,
  View,
} from "../../../shared/custom-react-native";
import Layout from "../../_components/Layout";
import { Failed, Success } from "../../_components/Modal";
import AdminInvitedList from "./AdminInvitedList";
import AdminList from "./AdminList";
import ServiceUsergroupsApi from "../../../api_services/usergroups";

const Admin = (props) => {
  const [showTab, setShowTab] = useState("Admin List");
  const [isSuccessInvite, setIsSuccessInvite] = useState(false);

  const email_value = props._this.state.admins_allowed_email && {
    value: props._this.state.admins_allowed_email,
  };

  const inviteUserGroupAdmin = () => {
    let data = mem.get("view_user_group");
    let user_group = JSON.parse(data);

    const usergroupadmins = props._this.state.admins_allowed_email.map(
      (email) => {
        const item = {
          email: email,
        };
        return item;
      }
    );

    const payload = {
      userRequests: usergroupadmins,
      userGroups: [user_group._id],
    };

    props._this.show_loading();
    ServiceUsergroupsApi.userGroupInviteUserGroupAdmin(payload).then((res) => {
      if (res.status === 200) {
        // let data = res.data;
        props._this.show_modal(
          <Success
            descriptionCenter={"Successfully Add/Invite user group admin"}
            onDismiss={() => {
              setIsSuccessInvite(true);
              props._this.setState({
                admins_allowed_email: [],
              });
              props._this.hide_loading();
              props._this.hide_modal();
            }}
          />
        );
      } else {
        props._this.show_modal(
          <Failed
            description={res.message}
            onDismiss={() => {
              props._this.hide_loading();
              props._this.hide_modal();
            }}
          />
        );
      }
    });
  };

  return (
    <>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 15,
          width: "100%",
        }}
      >
        <Toolbar gutter={16}>
          <Col span={12}>
            <Layout.Btn
              onClick={() => {
                setShowTab("Admin List");
              }}
              text={"Admin List"}
              color={
                showTab === "Admin List"
                  ? UI.colors.primary
                  : UI.colors.secondary
              }
              style={{
                // ...showTab === "Member List" ? { background: UI.colors.primary } : { background: UI.colors.secondary },
                cursor: "pointer",
                fontWeight: "bold",
                fontSize: 16,
              }}
            ></Layout.Btn>
            <Layout.Btn
              onClick={() => {
                setShowTab("Invited List");
              }}
              text={"Invited List"}
              color={
                showTab === "Invited List"
                  ? UI.colors.primary
                  : UI.colors.secondary
              }
              style={{
                // ...showTab === "Member List" ? { background: UI.colors.primary } : { background: UI.colors.secondary },
                cursor: "pointer",
                fontWeight: "bold",
                fontSize: 16,
                marginLeft: 40,
              }}
            ></Layout.Btn>
          </Col>

          <Col
            span={12}
            style={{
              marginLeft: "auto",
              padding: 0,
            }}
          >
            <Row style={{ justifyContent: "right" }}>
              <div className="admin-input-email flex flex-1 items-start flex-col">
                {/* <Text style={{fontSize: 14, fontWeight: "bold", color: UI.colors.primary }}>Email Address</Text> */}
                <Select
                  mode="tags"
                  size={props._this.state.admins_allowed_email}
                  placeholder="Enter Email Address"
                  autoClearSearchValue={true}
                  {...email_value}
                  onChange={(e) => {
                    for (const item of e) {
                      if (!UI.validate_email(item)) {
                        return e.pop();
                      }
                    }
                    props._this.setState({ admins_allowed_email: e });
                  }}
                  style={{
                    padding: "10px 10px 0px 0px",
                    width: "100%",
                    flex: 1,
                  }}
                >
                  <Select.Option></Select.Option>
                </Select>
              </div>

              <div className="flex items-center mt-3">
                <TouchableOpacity
                  onClick={() => {
                    if (props._this.state.admins_allowed_email.length === 0) {
                      return true;
                    }

                    inviteUserGroupAdmin();
                  }}
                  style={{
                    ...styles.md_button,
                    padding: 12,
                    paddingLeft: 45,
                    paddingRight: 45,
                    fontSize: 16,
                    color: "white",
                    cursor:
                      props._this.state.admins_allowed_email.length === 0
                        ? "not-allowed"
                        : "pointer",
                    backgroundColor:
                      props._this.state.admins_allowed_email.length === 0
                        ? "#CACACA"
                        : UI.colors.secondary,
                  }}
                >
                  <Text>Invite</Text>
                </TouchableOpacity>
              </div>
            </Row>
          </Col>
        </Toolbar>
      </View>

      {showTab === "Admin List" ? (
        <AdminList
          _this={props._this}
          isSuccessInvite={isSuccessInvite}
          setIsSuccessInvite={setIsSuccessInvite}
        />
      ) : (
        <AdminInvitedList
          _this={props._this}
          isSuccessInvite={isSuccessInvite}
          setIsSuccessInvite={setIsSuccessInvite}
        />
      )}
    </>
  );
};

export default Admin;

const Toolbar = styled(Row)`
  width: 100%;
  padding-left: 30.5px;
  padding-right: 30.5px;
`;

const styles = StyleSheet.create({
  md_button: {
    padding: 30,
    // backgroundColor: "#004F99",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
});
