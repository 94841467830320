import React from 'react'
import UI from '../../shared/Components/UI/js';
import { mem, Text, View } from '../../shared/custom-react-native'
import Layout from '../_components/Layout';
import QRCode from "qrcode.react";
import moment from 'moment';
import { useState } from 'react';
// import { Failed } from '../_components/Modal';
import ServicePatientsApi from "../../api_services/patients";
import { useReactToPrint } from "react-to-print";
import { Failed } from '../_components/Modal';

const PhysicalId = ({_this}) => {
    const [isGenerateQRCode, setisGenerateQRCode] = useState(_this.state.is_already_scan)
    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called"); // tslint:disable-line no-console
      }, []);
    
      const handleBeforePrint = React.useCallback(() => {
        _this.hide_loading()
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
      }, [_this]);
    
      const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
        _this.show_loading("", "Processing Request...");
    
        return new Promise((resolve) => {
          onBeforeGetContentResolve.current = resolve;
    
          setTimeout(() => {
            resolve();
          }, 2000);
        });
      }, [_this]);

      const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [componentRef.current]);

        const download = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: `${UI.capitalizeName(_this.state.first_name) + " " + UI.capitalizeName(_this.state.last_name)} QR ID`,
        onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
      });

      const generateQrId = () => {

        if (isGenerateQRCode) return true

        // if (!_this.state.isCompletedFillUp) {
        //     return _this.show_modal(
        //         <Failed
        //           title={"Failed"}
        //           description={"Please fill up required profile details before generate QR/ID"}
        //           onDismiss={() => {
        //             _this.setState({patient_tab: 'profile'})
        //             _this.hide_modal();
        //           }}
        //         />
        //     );
        // }

        _this.show_loading("", "Generating QR/ID...")

        ServicePatientsApi.updatePatientAccount(_this.state.user_id, { isAlreadyScan : true }).then( res => {
            if (res.status === 200) {
            //   let data = res.data;
            //   console.log(data);
              setTimeout(() => {
                setisGenerateQRCode(true)
                mem.update('edit_user_group_member', 'isAlreadyScan', true)
                _this.hide_loading();
            }, 1000);
            } else {
              _this.show_modal(
                <Failed 
                  description={res.message} 
                  onDismiss={() => { 
                    _this.hide_loading();
                    _this.hide_modal()
                  }} 
                />
              );
            }
          });
      

        return
        // setTimeout(() => {
        //     _this.hide_loading()
        //     UI.webRequest({
        //     method: "post",
        //     url: "admin/cms/users",
        //     params: {
        //         firstName: _this.state.first_name,
        //         lastName: _this.state.last_name,
        //         email: _this.state.email,
        //         isQrUser: isGenerateQRCode,

        //     },
        //     onFail: async (err) => {},
        //     onSuccess: async (response) => {
        //         const data = UI.extract_data(response);
        //         console.log(data);
        //         // getUserScanId(data._id)
        //     },
        //     onFinish: () => {
        //         _this.hide_loading();
        //     },
        //     });
        //     setisGenerateQRCode(true)
        // }, 3000);
    }

    
       let printingPages = [];
   
    for (const item of items) {
      const tempTemplate = item;
      printingPages.push(tempTemplate);
    }
  return (
    <div className='p-10'>
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent:'flex-end', width: "100%", marginBottom:10 }}>
            <Layout.Btn
                disabled={true}
                // color={UI.colors.primary}
                style={{background: isGenerateQRCode ? UI.colors.unset : UI.colors.primary, cursor: isGenerateQRCode ? "not-allowed" : "pointer"}}
                onClick={generateQrId}
                text={
                    "Generate QR/ID"
                }
            />

            {UI.box(20)}

       
            <Layout.Btn
                style={{width: 100, cursor: isGenerateQRCode ? 'pointer' : 'not-allowed'}}
                color={isGenerateQRCode ? UI.colors.secondary : UI.colors.unset}
                onClick={() => {
                    if (isGenerateQRCode) {
                        download("print")
                    } else {
                        return ""
                    }
                }}
                text={"Print"}
            />
            

            
        </View>
        <div className='flex justify-center items-center gap-16' ref={componentRef} id={"main_qr_id_content"}  style={{height: 600}}>

            <View>
                <strong className='text-center mb-5' style={{fontSize: 17 }}>Front</strong>
                {
                            isGenerateQRCode ?
                            <>
                                <div className='rounded-md relative' style={{height:440, width: 290, backgroundImage:`${`url(${UI.SCAN_ID_FRONT})`} `, backgroundSize:'cover'}}>
                                    <div className=" p-5 relative rounded-md flex items-center justify-center h-full">
                                        <img
                                            className='mb-44'
                                            style={{ height: 120, width: 120, borderRadius: 5 }}
                                            // src={picture_filename ? picture : UI.DEFAULT_AVATAR_DOCTOR}
                                            // src={picture_filename ? picture : UI.DEFAULT_AVATAR_DOCTOR}
                                            src={_this.state.image}
                                            onError={(e)=> {
                                                if (e) {
                                                    _this.setState({image: UI.DEFAULT_AVATAR_PATIENT})
                                                }
                                            }}
                                            alt="N/A"
                                        />
                                        <div className='absolute flex flex-col gap-5 items-center justify-center text-center font-semibold h-full mt-48'>
                                            <Text className="font-bold" style={{color: UI.colors.secondary, fontSize:16, fontWeight:'bold'}}>
                                                {UI.capitalizeName(_this.state.first_name) + " " + UI.capitalizeName(_this.state.last_name)}
                                            </Text>
                                            <div className='flex gap-10 text-center font-semibold'>
                                                <div className='flex flex-col'>
                                                    <Text style={{color: UI.colors.secondary, fontSize:15}}>{_this.state.gender}</Text>
                                                    <Text className="" style={{ display:'flex', alignItems:'center', gap: 5}}>
                                                    <img
                                                        width={15}
                                                        // style={{ marginTop:_this.state.is_rendering && 15 }}
                                                        src={UI.GENDER_ICON}
                                                        alt="N/A"
                                                    />
                                                    Gender</Text>
                                                </div>
                                                <div className='flex flex-col'>
                                                    <Text style={{color: UI.colors.secondary, fontSize:15}}>
                                                        {moment(_this.state.user_data?.birthDate).format('MMM D, YYYY')}
                                                        </Text>
                                                    <Text className="" style={{ display:'flex', alignItems:'center', gap: 5}}>
                                                    <img
                                                        width={10}
                                                        // style={{ marginTop:_this.state.is_rendering && 15 }}
                                                        src={UI.CALENDAR_ICON}
                                                        alt="N/A"
                                                    />Birthdate</Text>
                                                </div>
                                               
                                            </div>

                                            {
                                                ((_this.state.profile_logo_image || _this.state.partner_logo_image) && (_this.state.user_group.partnerLogoImage || _this.state.user_group.profileLogoImage)) &&
                                                
                                                    <div className='flex flex-col gap-2 mt-5 h-24'>
                                                        <Text className="" style={{ display:'flex', alignItems:'center', gap: 5, color: UI.colors.secondary}}>in partnership with</Text>
                                                        <div className='flex justify-between'>
                                                            {
                                                                _this.state.profile_logo_image && 

                                                                <img
                                                                    width={50}
                                                                    // style={{ marginTop:_this.state.is_rendering && 5 }}
                                                                    src={_this.state.profile_logo_image}
                                                                    alt="N/A"
                                                                />
                                                            }

        {
                                                                _this.state.partner_logo_image && 
                                                                
                                                                <img
                                                                    width={50}
                                                                    // style={{ marginTop:_this.state.is_rendering && 5 }}
                                                                    src={_this.state.partner_logo_image}
                                                                    alt="N/A"
                                                                />
                                                            }
                                                            
                                                        
                                                        </div>
                                                    </div>
                                            }
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                            </>
                            : 
                            <>
                                <div className='rounded-md border-2 relative' style={{height:440, width: 290,}}>
                                    <div className='flex flex-col items-center justify-center h-full '>
                                        <img
                                            style={{ height: 70, width: 70, borderRadius: 5 }}
                                            src={UI.CAMERA_ICON}
                                            alt="N/A"
                                        />
                                        <p className='w-36 text-center  font-bold'>No Current QR Code Available</p>
                                    </div>
                                </div>
                            </>
                        }
            </View>

            <View>
                <strong className='text-center mb-5' style={{fontSize: 17 }}>Back</strong>
                        {
                            isGenerateQRCode ?
                            <>
                                <div className='relative' style={{height:440, width: 290, backgroundImage:`${`url(${UI.SCAN_ID_BACK})`} `, backgroundSize:'cover'}}>
                                    <div className="relative flex items-center justify-center h-full">
                                        <QRCode
                                            includeMargin={true}
                                            size={225}
                                            level="H" id="qr-gen"
                                            style={{ borderRadius: 12 }}  
                                            value={`https://mwell.com.ph/deeplink?type=QR_Score&platform=patient&id=:id${_this.state.user_id ?? ""}`}
                                            renderAs="canvas" 
                                        />
                                        <div className='absolute flex flex-col gap-5 items-center justify-end text-center font-semibold h-full'>
                                            <div className='flex flex-col mb-16'>
                                                <Text style={{color: UI.colors.secondary, fontSize:16}}>
                                                    {UI.capitalizeName(_this.state.first_name) + " " + UI.capitalizeName(_this.state.last_name)}
                                                </Text>
                                                {/* <Text style={{color: UI.colors.secondary, fontSize:15}}>User ID: {userScanId}</Text> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : 
                            <>
                                <div className='rounded-md border-2 relative' style={{height:440, width: 290,}}>
                                    <div className='flex flex-col items-center justify-center h-full '>
                                        <img
                                            style={{ height: 70, width: 70, borderRadius: 5 }}
                                            src={UI.CAMERA_ICON}
                                            alt="N/A"
                                        />
                                        <p className='w-36 text-center  font-bold'>No Current QR Code Available</p>
                                    </div>lo
                                </div>
                            </>
                        }
                          
            </View>
        </div>
    </div>
  )
}

export default PhysicalId

const items = [<div className='rounded-md border-2 relative' style={{height:440, width: 290,}}>
<div className='flex flex-col items-center justify-center h-full '>
    <img
        style={{ height: 70, width: 70, borderRadius: 5 }}
        src={UI.CAMERA_ICON}
        alt="N/A"
    />
    <p className='w-36 text-center  font-bold'>No Current QR Code Available</p>
</div>
</div>, <div className='rounded-md border-2 relative' style={{height:440, width: 290,}}>
<div className='flex flex-col items-center justify-center h-full '>
    <img
        style={{ height: 70, width: 70, borderRadius: 5 }}
        src={UI.CAMERA_ICON}
        alt="N/A"
    />
    <p className='w-36 text-center  font-bold'>No Current QR Code Available</p>
</div>
</div>]