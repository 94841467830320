import React from "react";
import DataTable from "../../../../../ComponentsFC/DataTable";
import { Row } from "antd";
import styled from "styled-components";

const MedicationHistory = ({ medication_history }) => {
  const tableColumnHeader = [
    {
      title: "Date Prescribed",
      dataIndex: "date_prescribed",
      key: "date_prescribed",
      render: (data) => data || "n/a",
    },
    {
      title: "Generic Name",
      dataIndex: "generic_name",
      key: "generic_name",
      render: (data) => data || "n/a",
    },
    {
      title: "Strength/ Dosage Form",
      dataIndex: "str_form",
      key: "str_form",
      render: (data) => data || "n/a",
    },
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
      render: (data) => data || "n/a",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (data) => data || "n/a",
    },
    {
      title: "Signa",
      dataIndex: "signa",
      key: "signa",
      render: (data) => data || "n/a",
    },
    {
      title: "Prescribed By",
      dataIndex: "prescribed_by",
      key: "prescribed_by",
      render: (data) => data || "Coming soon",
    },
  ];

  return (
    <TableBox>
      <div className="p-6 text-lg font-medium">
        <div className="text-base 3xl:text-lg">Significant Medications History</div>
        <div className="text-gray-1200 text-sm 3xl:text-base">
          Add here pertinent medications patient is taking that was not
          prescribed by you
        </div>
      </div>
      <DataTable
        tableColumnHeader={tableColumnHeader}
        dataSource={medication_history}
        defaultPageSize={5}
        pageSizeOptions={["5", "10", "30"]}
        hideTotalSummaryOnSinglePage={medication_history.length <= 5}
        hidePaginationOnSinglePage={medication_history.length <= 5}
      />
    </TableBox>
  );
};

export default MedicationHistory;

const TableBox = styled(Row)`
  position: relative;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  min-width: 100%;
  .ant-table-cell {
    font-size: 20px;
  }
`;
