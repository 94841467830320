import UI from "../shared/Components/UI/js";
import ServiceApi from "./service";

class UsersGroupSubscriptionApi {

    baseURL = 'admin';

    async getAllUserGroupSubscriptionsByMerchant (params, merchantId) {
        const response = await ServiceApi.get(this.baseURL+`/subscription/${merchantId}`, params);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getMembersLineItem (merchantId) {
        const response = await ServiceApi.get(this.baseURL+`/subscription/merchant/member/${merchantId}`);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async updateMembersLineItem (payload) {
        const response = await ServiceApi.put(this.baseURL+`/subscription/merchant/update/fulfillment`, payload);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    

    async getAllSubscriptionsByUserGroupId (userGroupId) {
        const response = await ServiceApi.get(this.baseURL+`/subscription/usergroup/${userGroupId}?limit=2000`);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getAllUserGroupSubscriptionsRequest (params) {
        const response = await ServiceApi.get(this.baseURL+`/subscription/merchant/get-requests`, params);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getAllBillingSubscriptions (params) {
        const response = await ServiceApi.get(this.baseURL+`/subscription-billing`, params);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getAllBillingSubscriptionsByLoggedInMerchant (params) {
        const response = await ServiceApi.get(this.baseURL+`/subscription-billing/merchant/get-billing`, params);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getSpecificSubscriptionBillingBySpecificUsergroup (params) {
        const response = await ServiceApi.get(this.baseURL+`/subscription-billing/merchant/get-billing`, params);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async addUserGroupToSubscriptionPlan (payload) {
        const response = await ServiceApi.post(this.baseURL+`/subscription/subscribe-user-group/`, payload);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async updateUserGroupSubscriptionPlan (subsrciptionId, payload) {
        const response = await ServiceApi.put(this.baseURL+`/subscription/subscribe-user-group/${subsrciptionId}`, payload);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async cancelUserGroupSubscriptionPlan (payload) {
        const response = await ServiceApi.put(this.baseURL+`/subscription/subscriber-user-group/cancel`, payload);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async cancelAheadUserGroupSubscriptionPlan (payload) {
        const response = await ServiceApi.put(this.baseURL+`/subscription/validate-cancellation/${payload.subscriptionId}/${payload.userGroupId}`, { allowed: true });

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async cancelUserGroupWithValidateSubscriptionPlan (payload) {
        const response = await ServiceApi.put(this.baseURL+`/subscription/subscriber-user-group/cancel/${payload.subscriptionId}/${payload.userGroupId}`, { date: payload.date });

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async updateUserGroupSubscriptionPaymentStatusByMerchant (billingId, payload) {
        const response = await ServiceApi.put(this.baseURL+`/subscription-billing/payment/${billingId}`, payload);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async deleteSubscriptionPlan (subsrciptionId) {
        const response = await ServiceApi.delete(this.baseURL+`/subscription/${subsrciptionId}`);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getAlltargetGroups () {
        const response = await ServiceApi.get(this.baseURL+`/subscription/target-groups`);
        
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getAllBundleItems () {
        const response = await ServiceApi.get(this.baseURL+`/subscription/bundle-item`);
        
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async addSubscriptionList (image, payload) {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }

        let formData = new FormData();
        formData.append("image", image);
        formData.append("request", JSON.stringify(payload));
        // for (const pkey in payload) {
        //     formData.append(pkey, payload[pkey]);
        // }

        const response = await ServiceApi.post(this.baseURL+`/subscription/`, formData, config);

        if (response?.status === 200) { 
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async updateSubscriptionList (subsrciptionId, image, payload) {
        
        let formData = new FormData();
        if(image) {
            formData.append("image", image);
        }
        formData.append("request", JSON.stringify(payload));
        
        const response = await ServiceApi.put(this.baseURL+`/subscription/${subsrciptionId}`, formData);
        
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }
}

export default new UsersGroupSubscriptionApi()
