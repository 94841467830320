import React from "react";
import { Image, StyleSheet, Text, View, Font } from "react-pdf-renderer-v2";
import EDocsWrapperComponent from "./EDocsWrapperComponent";

const EPrescriptionModal = ({
  appointment,
  encounterNotesData,
  onHideModal,
}) => {
  return (
    <EDocsWrapperComponent
      title="E-Prescription"
      appointment={appointment}
      onHideModal={onHideModal}
    >
      <View>
        <View style={{ paddingTop: 8 }}>
          <Image
            style={{ width: 100 }}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAABmJLR0QA/wD/AP+gvaeTAAAFG0lEQVR4nO2dXYgWVRjHf66mWa0bFdi3SWaUFFkUFCEVkWnkRUThjVdd1EWYQVBRQV8Q1EUXBV0UGJQKkhZUeFMUhYGlUWJEQmBfUq3malnrtk4XZxdetn3fnfPMc75mnx88d++c5+M/M+c9Z86ZAcMwDMMwDMMwjLjMaHBspRbF1IwAfwI/AN8BnwEfAF9HjCF7qgxsD7AOOCVwrkWQWoxOGwTWArOCZpw5qUWYzHYCF4VMOmdSF7+bHQZuC5h3tqQufC87BqwOl3qepC56HVFWBMs+Q1IXvI4dAS4OVYDcSF3suvY5MDNQDbIidaF97P5ANVCnlJF6Uw4AF+BG+1nTlzqASJwO3JM6iND43jaacDKwFHgM+E3guwJ2N4whe2IK0sk84C2B/wpYohhHdqQSBNytViLKWuU4siKlIAAD+N++NgWIQ5WSO/Uh4CXPY1o9SEx9hYDr6H1i+D1QHFmQgyD9njH8EygONUq+ZYH/wHY0SBSKlC7IIs/f/xEkCkVKF+R2z98PBokiE1L3IQO4TtonhtcDxKFKqVdIH7AeOMPzuB0BYsmGVFfIALBF4L8CLlGMIztiCtIPXAk8gf9tatx2NowhCjHXMYUai9Ql+/4Dps8Dqv24v8hHUwcyFaV26r48SwFiNEVyH09hn2KLHLKxIWBhqALkRupiT2XDwPJg2WdI6oL3smPAneFSz5PURe9mB4CbAuadLakLP5l9gv8McGtIXfyJ9jAt+BtffAIdLACOpw4iJamviIk2ClwXNOPM8S2YD7cK2q9wu3JPaJDTRNYJ49iqGENtQgoCsFngowIekiY0gbOBQwL/f+EWdkcntCBn4p6BSwqiMTrfKvBd4a6qJIQWBNy+DklR3hf6G2eF0O9X6N4yvYghSB/urQ2S4twh9HkS8L3A3yhwrdCnCjEEAbgc92oNX3+/4B73+vKCwFeF/7JWdWIJAvCiwF81dpwPl+HmwXz97AdOlSanRUxB+oEfBT7/Ba6q6aMP2C7wUQF3NcxPhZiCgOsTJMX6gnoPqO4Ttr9NITcVYgsC8I7Ab8XUu3DnAwcF7R4FLlTKrTEpBDkf9zIAX9+HgXN6tLtR0GYFPKKUlwopBAE3EpcUb3OX9m4RtvctMEcxr8akEmQW8KXAf8X/F2fPwRXWt53jwI2KOamQShCAa3ADMd8Y9uG2WI/zjKCNCnhVOZ9W8AqyYj43dvxi3I4q3+MH8V/kPS0YAH7Gv6AjwBW4l2hKBF0TI7lSWY2sqL8Kj/uQZstvpwXvISuurw3T8u0MWiwC/ia8IE/GSqgNPE5YMfYCJ0bLpgXMBr4hnCA3x0ulPSzDDdi0xXgjZhJtYz26YhwCzoqaQctYgq4gB3EzwYaQN7FbVjbcQJg+xDp1AdJZ27pmf3s9eYpwYoybDQxrspg4I3WbOqnBDOSzthL7GJtc7MkaZIX9aMwkx9r0exdOQzaNPgxcij2gUuc1ZGf40x1t2CNcJa5HNubYC8ztaKdVixxSMRv3uTzJmb1ykvZaswwoFY8iK+CGHm22YqFcChbgvgHiW7gh3Ba1brRiKWkK3kV2Jt9bo+3iF1vH5m5kBdtBvf34xW9HiMk84Cf8CzWCez98XYresBOTl5Gduc8LfBW7pS0WV+N2QvkWaB+yr0gXu+kzBjOBXcjO2FUN/Ba5LToGDyIrzBYF38W9OCA05yHfLXWugv/iXq0RmreRnaEPKMZQ1MtnDMMwDMMwDMMwJPwH3FYZRuizt6YAAAAASUVORK5CYII="
            alt="prescription"
          />
        </View>
        <View style={{ marginTop: 16 }}>
          {encounterNotesData?.prescriptions.map((prescription) => (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginTop: 15,
              }}
            >
              <View style={{ flex: 1 }}>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text style={styles.subtitle}>{prescription.medication}</Text>
                  <Text style={{ ...styles.text, marginLeft: 24 }}>
                    {prescription.number ? `#${prescription.number}` : ""}
                  </Text>
                </View>
                <Text style={styles.subtitle2}>{prescription.brandname}</Text>
                <Text style={{ ...styles.text }}>{prescription.notes}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </EDocsWrapperComponent>
  );
};

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  subtitle: {
    fontSize: 18,
  },
  subtitle2: {
    fontSize: 14,
  },
  text: {
    fontSize: 14,
  },
});

export default EPrescriptionModal;
