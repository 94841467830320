import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker, 
{ 
  // autoPopulate 
} 
from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import MainLayout from "../_components/MainLayout";
// import Layout from "../_components/Layout";
import "./css.css";

const context = "merchant";

export default class EcommerceViewCustomer extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Customers",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      context: context,
      image: "",
      item: {
        _id: "",
        name: "",
        email: "",
        mobile_number: "",
        birthday: "",
        products_ordered: [],
      },
      all_data: [],
    });
  }

  onCreate = async () => {
    this.load_data();
  };

  get_transactions = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "admin/merchant/customer-orders/" + this.state.id,
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            all_data: data, // TO-DO: get actual response data
          }
        );
        this.reload_table("product_list")
      },
      onFinish: () => {
        //this.hide_loading();
        // console.log("my state:",this.state);
      },
    });
  };

  load_data = async () => {
    const data = mem.get("view_customer");
    if (data) {
      const customer = JSON.parse(data);
      console.log("customer data:", customer);
      this.setState(
        {
          id: customer._id,
        }
      );
      const image = await UI.download_image(customer.imagePath, "ecommerce");
      
      this.setState(
        {
          imagePath: customer.imagePath,
          image,
          item: customer,
        },
        () => {
          this.get_transactions();
          // console.log("image?",this.state.imagePath);
        }
      );
    }
  };

  customer = async (data) => {
    UI.set_images(data, "imagePath", "customer", "product_list", "ecommerce");
  };

  getBirthday = (item) => {
    let birthday = UI.timestampToDate(
      new Date(item.birthDate).getTime()
    );
    return  birthday.month + "/" + birthday.day + "/" + birthday.year;
  }

  render() {
    const _this = this;
    const {
      // height,
      width,
      // isMobile,
      image,
      item,
    } = _this.state;

    // console.log("my state in render:",this.state.products_bought);
    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <Text style={styles.box_header_text}>Customer Details</Text>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                borderRightWidth: width >= 1250 ? 2 : 0,
                borderBottomWidth: width < 1250 ? 2 : 0,
                paddingRight: width < 1250 ? 0 : 20,
                marginRight: width < 1250 ? 0 : 20,
                paddingBottom: 10,
                paddingTop: 15,
                marginBottom: width < 1250 ? 10 : 0,
              }}
            >
              <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
                <img
                  style={{ height: 80, width: 80, borderRadius: 5 }}
                  src={image}
                  alt="Sample User"
                  onError={(e) => {
                    if (e) {
                      this.setState({image: UI.SAMPLE_USER})
                    }
                  }}
                />
                {UI.box(width < 980 ? 5 : 10)}
                <View style={{margin: "auto"}}>
                  <Text
                    style={{
                      ...styles.customer_name,
                      color: UI.colors.black,
                    }}
                  >
                    Product Inquiry by: {item.firstName + " " + item.lastName}
                  </Text>
                  <View>
                    <Text style={styles.sub_text}>{item.email} | {item.phone}</Text>
                  </View>
                </View>
              </UI.Row>
            </View>
            
            <View style={{ flex: 1, justifyContent:'center' }}>
              <UI.Row _this={_this}>
                <UI.Row breakpoint={970} _this={_this} style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"Birth of date:"}
                    value={UI.getDateByMMDDYYYY(item.birthDate)}
                    width={160}
                  />
                  <UserDetail
                    icon={UI.SYMBOL_MARRIAGE}
                    name={"Marital Status:"}
                    value={item.maritalStatus ? item.maritalStatus : "N/A"}
                    width={160}
                  />
                </UI.Row>
              </UI.Row>
              <UI.Row _this={_this}>
                <UI.Row breakpoint={840} _this={_this} style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.DETAILS_GENDER}
                    name={"Gender:"}
                    value={item.gender ? item.gender === "1" ? 'Male' : 'Female' : "N/A"}
                    width={200}
                  />
                  <UserDetail
                    icon={UI.INFO_TRAININGS}
                    name={"Contact number:"}
                    value={item.phone ? item.phone : "N/A"}
                    width={200}
                  />
                </UI.Row>
              </UI.Row>
              {/* <UI.Row breakpoint={970} _this={_this} style={{ flex: 1 }}>
                <UserDetail
                  icon={UI.DETAILS_AGE}
                  name={"Birth of date:"}
                  value={this.getBirthday(item)}
                  width={160}
                />
                <UserDetail
                  icon={UI.SYMBOL_MARRIAGE}
                  name={"Marital Status:"}
                  value={item.maritalStatus ? item.maritalStatus : "N/A"}
                  width={160}
                />
              </UI.Row>
              <UI.Row breakpoint={970} _this={_this} style={{ flex: 1 }}>
                <UserDetail
                  icon={UI.DETAILS_GENDER}
                  name={"Gender:"}
                  value={item.gender ?? item.gender === "1" ? 'Male' : 'Female'}
                  width={200}
                />
              </UI.Row> */}
            </View>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={850}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"product_list"} _this={_this}  />
              </View>
            </View>

            <InputForm
              onChange={(e) => {
                UI.search_table("product_list", e, this.state.all_data, "all_data");
              }}
              is_keyword={true}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
            />
          </UI.Row>
          
          <CustomDatatable
            state_name={"product_list"}
            _this={_this}
            columns={[
              { "Order ID": "order_id" }, //TO-DO: Finalize: end points
              { "Product Name": "product_name" },
              { "Date Purchased": "date_purchased" },
              { "Total Paid": "total_paid" },
              { "Payment Method": "payment_method" },
              { "Payment Status": "payment_status" },
              { "Order Status": "order_status" },
              { Action: "action" },
            ]}
            data={this.state.all_data.map((item) => {
              let datePurchased = UI.timestampToDate(
                new Date(item.createdAt).getTime() // TO-DO: date purchased?
              );

              const paymentStatus = {status: item.status };
              const orderStatus = {status: item.orderStatus };

              if (item.updatedAt) {
                UI.timestampToDate(new Date(item.updatedAt).getTime());
              }

              let quantity = item.quantity;
              if (!quantity) {
                quantity = 0;
              }
              quantity = Number.parseInt(quantity);

              // **Comment out, currently unused
              // const image = this.state["preview" + item._id] ?? UI.PRODUCT_BOX;

              return {
                order_id: item._id,
                product_name: item.product.name,
                date_purchased:
                  datePurchased.month +
                  "/" +
                  datePurchased.day +
                  "/" +
                  datePurchased.year,
                total_paid: item.amount,
                payment_method: item.method,
                payment_status: UI.get_transaction_status_text(paymentStatus),
                order_status: UI.get_transaction_status_text(orderStatus), // TO-DO: product status
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        const customerDetails = JSON.parse(mem.get("view_customer"));
                        const combinedDetails = {...customerDetails, customerImagePath: this.state.imagePath, ...item};
                        mem.save("view_transaction_details", JSON.stringify(combinedDetails));
                        mem.save("product_id", item.order_id);  
                        UI.goTo("/merchant/view-transaction-details");
                        /*    mem.save("edit_product", JSON.stringify(item));
                        UI.goTo("/merchant/view-inventory"); */
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.VIEW_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.all_data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const UserDetail = (props = { icon: "", name: "", value: "", width: null }) => {
  const myWidth = props.width ? props.width : 140;
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
        flex: 1,
      }}
    >
      <View style={{ ...styles.fl_ai, width: myWidth, flex:"50%" }}>
        <View style={styles.detail_icon}>
          <Image
            height={16}
            width={16}
            tintColor={UI.colors.primary}
            source={props.icon}
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={{...styles.details_value, flex:"50%"}}>{props.value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  customer_name: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#535353",
  },
  sub_text: {
    fontSize: 13,
    fontWeight: "bold",
    color: UI.colors.black,
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 13,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
