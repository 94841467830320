import Icon from "@ant-design/icons";
import React from "react";

const DatePickerCalendar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.969"
    height="29.18"
    viewBox="0 0 25.969 29.18"
  >
    <path
      id="calendar"
      d="M7.352,29.18C2.818,29.18,0,26.385,0,21.886V9.834C0,5.648,2.514,2.962,6.612,2.676V1a1,1,0,0,1,2,0V2.651h8.76V1a1,1,0,0,1,2,0V2.676a7.169,7.169,0,0,1,4.7,1.945,7.154,7.154,0,0,1,1.9,5.216V21.986c0,4.437-2.818,7.194-7.352,7.194ZM2,21.886c0,3.417,1.9,5.3,5.354,5.3H18.617c3.453,0,5.354-1.846,5.354-5.2V11.858H2ZM23.971,9.86V9.834a5.147,5.147,0,0,0-1.314-3.8,5.148,5.148,0,0,0-3.289-1.353v.7a1,1,0,0,1-2,0V4.649H8.61v.733a1,1,0,0,1-2,0v-.7C3.629,4.93,2,6.738,2,9.834V9.86ZM17.908,21.235a.994.994,0,0,1,.993-1h.012a1,1,0,1,1-1,1Zm-5.908,0a.993.993,0,0,1,.992-1H13a1,1,0,1,1-1,1Zm-5.924,0a1,1,0,0,1,.995-1h.012a1,1,0,1,1-1.006,1ZM17.908,16.06a.994.994,0,0,1,.993-1h.012a1,1,0,1,1-1,1ZM12,16.06a.993.993,0,0,1,.992-1H13a1,1,0,1,1-1,1Zm-5.924,0a1,1,0,0,1,.995-1h.012a1,1,0,1,1-1.006,1Z"
      fill="#55ccfc"
    />
  </svg>
);

const DatePickerCalendarIcon = (props) => {
  return <Icon component={DatePickerCalendar} {...props} />;
};

export default DatePickerCalendarIcon;
