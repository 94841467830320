import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import AreaChart from "../_components/AreaChart";
import Layout from "../_components/Layout";
import "./css.css";

export default class OnCallConsultation extends Component {
  // **Commented out, currently not used. No need to define empty constructor
  // constructor(props) {
  //   super(props);
  // }

  onCreate = () => {};

  render() {
    const _this = this.props._this;
    const {
      // height,
      width,
      //  isMobile
    } = _this.state;
    let container_width = 0;
    // **Commented out, currently not used
    // **Alternatively you can use media queries (e.g. @media only screen (min-width: 470px){ cssSelector { cssPropsHere: cssValue }})
    // let add_style = {};
    // if (width <= 470) {
    //   add_style = { flexDirection: "row" };
    // }

    let container = UI.measure("booked_consultation_container");
    if (container) {
      container_width = container.width;
    }

    const data = [
      {
        title: "Successful",
        color: "#00CBFF",
        data: [
          20.140477700433657, 93.07142369521986, 9.845218293831625,
          82.75891713200969, 51.25793562343124, 63.9132732282856,
        ],
      },
      {
        title: "Upcoming",
        color: "#004F99",
        data: [
          95.7696418070232, 6.543317737979937, 88.41367996011928,
          61.59446757374465, 2.788895036022132, 99.01247508635977,
        ],
      },
      {
        title: "Missed",
        color: "#EBB500",
        data: [
          87.3396771868964, 82.97854617758145, 33.662776548412985,
          47.084688897444636, 46.46022731111308, 88.16790505315433,
        ],
      },
      {
        title: "Cancelled",
        color: "#D90000",
        data: [
          63.462069127729556, 45.35635969944569, 36.9639460366473,
          77.36328750340242, 8.658129653123359, 82.5430871645756,
        ],
      },
    ];

    return (
      <View
        id={"booked_consultation_container"}
        style={styles.main_box_content}
      >
        <UI.Row
          style={{
            padding: 10,
            alignItems: width > 800 ? "center" : "flex-start",
          }}
          breakpoint={800}
          breakpoint_2={720}
          breakpoint_3={470}
          _this={_this}
        >
          <UI.Row breakpoint={1150} _this={_this}>
            <Text
              style={{
                ...styles.box_header_text,
                flex: null,
                marginRight: width > 1300 ? 0 : 10,
              }}
            >
              On-Call Consultations
            </Text>
            {UI.box(10)}

            <UI.Row breakpoint={1300} breakpoint_2={1150} _this={_this}>
              <Legend color={"#00CBFF"} text={"Successful"} />
              <Legend color={"#004F99"} text={"Upcoming"} />
            </UI.Row>

            <UI.Row breakpoint={1300} breakpoint_2={1150} _this={_this}>
              <Legend color={"#EBB500"} text={"Missed"} />
              <Legend color={"#D90000"} text={"Cancelled"} />
            </UI.Row>
          </UI.Row>

          {UI.box(20)}

          <UI.Row
            breakpoint={1150}
            _this={_this}
            style={{
              flex: 1,
              alignItems: width > 1150 ? "center" : "flex-end",
            }}
          >
            <View style={{ flexDirection: "row", flex: 1 }}>
              <TouchableOpacity>
                <Image
                  height={20}
                  width={20}
                  tintColor={UI.colors.primary}
                  source={UI.CHEVRON_LEFT}
                />
              </TouchableOpacity>
              {UI.box(5)}
              <TouchableOpacity>
                <Image
                  height={20}
                  width={20}
                  tintColor={UI.colors.primary}
                  source={UI.CHEVRON_RIGHT}
                />
              </TouchableOpacity>
              {UI.box(20)}

              <Text style={{ ...styles.box_header_text, flex: null }}>
                December 8, 2021
              </Text>
            </View>
            {UI.box(10)}
            <Layout.SortFilter
              btn_1={{
                text: "Year",
                onClick: (text) => {},
              }}
              btn_2={{
                text: "Month",
                onClick: (text) => {},
              }}
              btn_3={{
                text: "Day",
                onClick: (text) => {},
              }}
              onChange={(val) => {
                _this.setState({
                  // **Unexpected string concatenation of literals.eslint
                  // **by changing "sort_filter_" + "oncall_consultation" to `${"sort_filter_"}${"oncall_consultation"}`
                  [`${"sort_filter_"}${"oncall_consultation"}`]: val,
                });
              }}
              _this={_this}
              state_name={"oncall_consultation"}
            />
          </UI.Row>
        </UI.Row>

        <View
          style={{
            width: container_width - 50,
            alignSelf: "center",
            paddingBottom: 20,
          }}
        >
          <AreaChart
            id={"oncall_consultation"}
            _this={_this}
            labels={["Jan", "Feb", "Mar", "Apr", "May", "Jun"]}
            data={data}
          />
        </View>
      </View>
    );
  }
}

const Legend = (props = { text: "", color: "" }) => {
  return (
    <View style={styles.legend_container}>
      <View
        style={{ ...styles.dot_color, backgroundColor: props.color }}
      ></View>
      <Text style={styles.legend_text}>{props.text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  legend_text: {
    color: "#535353",
    fontWeight: "bold",
    fontSize: 12,
    marginLeft: 10,
  },
  legend_container: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  dot_color: {
    height: 12,
    width: 12,
    borderRadius: 10,
    backgroundColor: UI.colors.primary,
  },
  main_box_content: {
    marginTop: 20,
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
  },
  box_header_subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#C9C9C9",
    alignSelf: "flex-end",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    backgroundColor: "white",
    padding: 20,
    ...Shadow._3(),
    flexDirection: "row",
    maxHeight: 180,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
