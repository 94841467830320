
import React from 'react'
import UI from '../../shared/Components/UI/js';
import { StyleSheet, Text, View } from '../../shared/custom-react-native';
import InputForm, { Checkbox } from '../_components/InputForm';
import Layout from '../_components/Layout';
import { Failed, Success } from '../_components/Modal';
import ServicePatientsApi from "../../api_services/patients";

const Profile = ({ _this }) => {
    let age = ""; //Age is 24 y/o

    if (_this.state.dob) {
      age = "Age is " + UI.get_age(_this.state.dob) + " y/o";
    }

    const updateUserDetails = async () => {
      UI.clear_errors();

      const { first_name, middle_name, last_name, email, contact_no, gender, dob, province, city, barangay, house_number_street, postal_code, special_condition, user_id } = _this.state
      const address = {
        street: house_number_street,
        brgyOrTown: barangay,
        cityOrMunicipality: city,
        state: province,
        postalCode: postal_code
        // region: "National Capital Region (NCR)",
      }
 
        if (!first_name) {
          await UI.error_form("first_name", "Firstname is required.");
        }  if (!last_name) {
          await UI.error_form("last_name", "Lastname is required.");
        }  if (!email) {
          await UI.error_form("email", "Email is required.");
        } if (email) {
          if (!UI.validate_email(email)) {
            await UI.error_form("email", "Please enter a valid email.");
          }
        } if (!contact_no) {
          await UI.error_form("contact_no", "Contact Number is required.");
        } if(!dob) {
          await UI.error_form("dob", "Birth Date is required.");
        } 

        if (UI.is_check_special_characters(first_name)) {
          await UI.error_form("first_name", "Characters is not allowed.");
        }

        if (UI.is_check_special_characters(middle_name)) {
          await UI.error_form("middle_name", "Characters is not allowed.");
        }

        if (UI.is_check_special_characters(last_name)) {
          await UI.error_form("last_name", "Characters is not allowed.");
        }
    
        const error_count = _this.state.error_count;

        if (error_count > 0) {
          _this.hide_loading();
          return;
        }

        if (!gender) {
          return  _this.show_modal(
            <Failed
              title={"Failed"}
              description={"Gender is required."}
              onDismiss={() => {
                _this.hide_modal();
              }}
            />
          );
        }

        const payload = {
          firstName: first_name,
          middleName: middle_name,
          lastName: last_name,
          phoneNumber: contact_no,
          phone: contact_no,
          address: address,
          gender: gender.toLowerCase() === "male" ? 1 : 0,
          birthDate: dob,
          specialCondition: special_condition,
          isAlreadyScan: true,
          email
        }
       
        ServicePatientsApi.updatePatientAccount(user_id, payload).then( res => {
          if (res.status === 200) {
            if (_this.state.image_file) {
              UI.webRequest({
                method: "post",
                url: `admin/cms/users/${user_id}`,
                params: {
                  image: _this.state.image_file,
                },
                isMultiPart: true,
                onFail: async (err) => {
                  _this.show_modal(
                    <Failed description={err.data.message} onDismiss={_this.hide_modal} />
                  );
                },
                onSuccess: async (response) => {
                  _this.show_modal(
                    <Success
                      title={"Success!"}
                      description={"Successfully Update"}
                      onDismiss={() => {
                        _this.hide_modal();
                        UI.goBack();
                      }}
                    />)
                },
                onFinish: () => {
                  _this.hide_loading();
                },
              });
            } else {
              _this.show_modal(
                <Success
                  title={"Success!"}
                  description={"Successfully Update"}
                  onDismiss={() => {
                    _this.hide_modal();
                    UI.goBack();
                  }}
                />
              )
            }
          } else {
            _this.show_modal(
              <Failed 
                description={res.message} 
                onDismiss={() => { 
                  _this.hide_loading();
                  _this.hide_modal()
                }} 
              />
            );
          }
        });
    }

  return (
    <>
    <View style={{ ...styles.fl_ai }}>
        <Text style={{ ...styles.title, fontSize: 17, margin:"15px 0px" }}>
            Update Patient
        </Text>
         <View style={{ height: 120, width: 120 }}>
            <Layout.FilePicker
                _this={_this}
                title={""}
                additional={""}
                text={""}
                state_name={"web_banner_image"}
                onChange={async (file) => {
                const uri = await UI.get_uri_from_file(file);
                _this.setState(
                    {
                    image: uri,
                    image_file: file,
                    did_upload: true,
                    }
                );
                }}
                height={120}
                component={
                  <img
                    className='mb-44'
                    style={{ height: 120, width: 120, borderRadius: 5 }}
                    // src={picture_filename ? picture : UI.DEFAULT_AVATAR_DOCTOR}
                    // src={picture_filename ? picture : UI.DEFAULT_AVATAR_DOCTOR}
                    src={_this.state.image}
                    onError={(e)=> {
                        if (e) {
                            _this.setState({image: UI.DEFAULT_AVATAR_PATIENT})
                        }
                    }}
                    alt="N/A"
                />
                // <ImageBackground
                //     style={{ height: 120, width: 120, borderRadius: 10 }}
                //     source={_this.state.image}
                //     imageFit={ImageFit.CONTAIN}
                // />
                }
            />
            </View>
            {UI.box(20)}

            <View style={{ flex: 1.5, justifyContent: "center" }}>
            <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
            >
                {_this.state.did_upload
                ? "Profile Picture Uploaded."
                : "No Profile Picture Uploaded."}{" "}
                Recommended Size: 500 px x 500 px
            </Text>
            {UI.box(10)}

            <Layout.Btn
                color={UI.colors.primary}
                text={"Upload Profile Picture"}
                onClick={async () => {
                  _this.upload_picture();
                }}
                width={220}
            />
            </View>

    </View>
    {UI.box(20)}
    <UI.Row breakpoint={970} _this={_this}>
        <InputForm
            _this={_this}
            title={"First Name"}
            placeholder={"First Name"}
            state_name={"first_name"}
            style={{ flex: 1 }}
            isRequired={true}
            maxLength={UI.Constants.limits.name}
        />
        {UI.box(10)}
        <InputForm
            _this={_this}
            title={"Middle Name"}
            placeholder={"Middle Name"}
            state_name={"middle_name"}
            style={{ flex: 1 }}
            isRequired={false}
            maxLength={UI.Constants.limits.name}
        />
        {UI.box(10)}
        <InputForm
            _this={_this}
            title={"Last Name"}
            placeholder={"Last Name"}
            state_name={"last_name"}
            style={{ flex: 1 }}
            isRequired={true}
            maxLength={UI.Constants.limits.name}
        />
        </UI.Row>
        {UI.box(20)}
        <UI.Row breakpoint={970} _this={_this}>
          <InputForm
            _this={_this}
            title={"Email Address"}
            placeholder={"Email Address"}
            state_name={"email"}
            style={{ flex: 1 }}
            isRequired={true}
            disabled={true}
            maxLength={UI.Constants.limits.email}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Contact Number"}
            placeholder={"Contact Number"}
            state_name={"contact_no"}
            prefix={"+63"}
            isRequired={true}
            isNumber={true}
            maxLength={10}
            style={{ flex: 1 }}
          />
          {UI.box(10)}

          <Layout.DatePicker
            _this={_this}
            title={"Date of Birth"}
            isRequired={true}
            additional={age}
            placeholder={"mm/dd/yyyy"}
            state_name={"dob"}
            date_props={{
            maxDate: new Date(),
            }}
            will_correct = {true}
          />
        </UI.Row>
        {UI.box(20)}
        <UI.Row
            style={{ justifyContent: "space-between" }}
            _this={_this}
            breakpoint={1000}
        >
          <View style={{ flex: 1 }}>
              <Text style={styles.title}>
                  Gender<Text style={{color:'red'}}> *</Text>
              </Text>
              {UI.box(8)}

              <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Checkbox
                  _this={_this}
                  text={"Male"}
                  onClick={(value) => {
                  _this.setState({
                      gender: "Male",
                  });
                  }}
                  is_checked={_this.state.gender === "Male" ? true : false}
                  color={"#2E2E2E"}
              />
              <View style={{ width: 50 }}></View>
              <Checkbox
                  _this={_this}
                  text={"Female"}
                  onClick={(value) => {
                  _this.setState({
                      gender: "Female",
                  });
                  }}
                  is_checked={_this.state.gender === "Female" ? true : false}
                  color={"#2E2E2E"}
              />
              </View>
          </View>

          {UI.box(10)}

          <InputForm
            _this={_this}
            title={"Province"}
            placeholder={"Province"}
            state_name={"province"}
            style={{ flex: 1 }}
            maxLength={UI.Constants.limits.name}
          />

          {UI.box(10)}

          <InputForm
            _this={_this}
            title={"City"}
            placeholder={"City"}
            state_name={"city"}
            style={{ flex: 1 }}
            maxLength={UI.Constants.limits.name}
          />
        </UI.Row>

        {UI.box(20)}

        <UI.Row breakpoint={970} _this={_this}>
          <InputForm
            _this={_this}
            title={"Barangay"}
            placeholder={"Barangay"}
            state_name={"barangay"}
            style={{ flex: 1 }}
            maxLength={UI.Constants.limits.name}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"House Number/Street"}
            placeholder={"House Number/Street"}
            state_name={"house_number_street"}
            style={{ flex: 1 }}
            maxLength={UI.Constants.limits.name}
          />
          {UI.box(10)}

          <InputForm
            _this={_this}
            title={"Postal Code"}
            placeholder={"Postal Code"}
            state_name={"postal_code"}
            style={{ flex: 1 }}
            isNumber={true}
            maxLength={5}
        />
        </UI.Row>

        {UI.box(20)}
        
        <UI.Row breakpoint={970} _this={_this}>
          {/* <Layout.SelectPicker
            _this={_this}
            title={"Special Condition"}
            // isRequired={true}
            state_name={"special_condition"}
          >
            <option value="">Special Condition</option>
            <option value="Senior" >Senior</option>
            <option value="Cancer" >Cancer</option>
            <option value="Traumatic Brain Injury" >Traumatic Brain Injury</option>
            <option value="Stroke" >Stroke</option>
            <option value="Cholesterol" >Cholesterol</option>
          </Layout.SelectPicker> */}
           <InputForm
            _this={_this}
            title={"Special Condition"}
            placeholder={"Special Condition"}
            state_name={"special_condition"}
            style={{ flex: 1 }}
            // isNumber={true}
            maxLength={UI.Constants.limits.name}
        />
          
          {UI.box(10)}
          <View style={{flex: 1}}></View>
          <View style={{flex: 1}}></View>
        </UI.Row>

        {UI.box(20)}

        <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                updateUserDetails()
              }}
              text={
                "UPDATE"
              }
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
    </>
  )
}

export default Profile

const styles = StyleSheet.create({
    title: {
      fontSize: 14,
      fontWeight: "bold",
      color: UI.colors.primary,
    },
  });