import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
// import { StyleSheet } from "../../shared/custom-react-native";
import MainLayout from "../_components/MainLayout";
import "./css.css";
import TransactionAmount from "./TransactionAmount";
import TransactionNumber from "./TransactionNumber";

const context = "finance";

export default class FinanceDashboard extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Dashboard",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,

      item: {
        successTransaction: 0,
        pending: 0,
        refund: 0,
        failed: 0,
        totalTransacCount: 0,
        totalSuccessTrasactionPrice: 0,
        totalRefundPrice: 0,
        totalPendingPrice: 0,
        totalFailedPrice: 0,
        overAllTotalPrice: 0,
        overAllMonth: 0,
        overAllMonthPrice: 0,
      },
    });
  }

  onCreate = () => {
    this.get_data({
      duration: "year",
    });
  };

  get_data = async (query) => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "admin/finance/stats",
      params: query,
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState({
          item: data,
        });
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  filter = (text) => {
    this.get_data({
      duration: text,
    });
  };

  render() {
    const _this = this;
    // **Commented out, currently unused
    // **Alternatively you can use @media screen only (min-width: 470px) { cssSelector {cssPropsHere: cssValue}}
    // const { height, width, isMobile } = _this.state;
    // let add_style = {};
    // if (width <= 470) {
    //   add_style = { flexDirection: "row" };
    // }

    return (
      <MainLayout context={context} _this={_this}>
        <TransactionNumber _this={_this} />
        <TransactionAmount _this={_this} />
      </MainLayout>
    );
  }
}

// const styles = StyleSheet.create({});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
