import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  DatePicker,
  Image,
  Input,
  Select,
  Typography,
  Tooltip,
} from "antd";
import styled from "styled-components";
import UI from "../../shared/Components/UI/js";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import DatePickerCalendarIcon from "../../icons/DatePickerCalendar";
import DataTable from "../../ComponentsFC/DataTable";
import ServiceAttendanceApi from "../../api_services/Admin/AttendanceMgmtApi.js";
import FileDownload from "js-file-download";
import Loading from "../_components/Loading";

const DoctorDailyTimeReport = () => {
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableEntries, setTableEntries] = useState(30);
  const [totalRecords, setTotalRecords] = useState();
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [isScreenLoading, setIsScreenLoading] = useState();
  const [screenMsg, setIsScreenMsg] = useState("");
  const [isTableLoading, setTableIsLoading] = useState(false);
  const [isExportAllCSV, setIsExportAllCSV] = useState(false);
  const [currSelectedRowKeys, setCurrSelectedRowKeys] = useState([]);
  const [selectedDoctorId, setSelectedDoctorId] = useState();
  const [specificKey, setSpecificKey] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [appliedStartDate, setAppliedStartDate] = useState();
  const [appliedEndDate, setAppliedEndDate] = useState();

  const getDoctorsDailyTimeReport = useCallback(async () => {
    try {
      let urlParams = {};

      if (currentPageNum) {
        urlParams.page = currentPageNum;
        urlParams.limit = tableEntries;
      }

      if (appliedStartDate && appliedEndDate) {
        urlParams.startDate = appliedStartDate;
        urlParams.endDate = appliedEndDate;
      }

      if (searchQuery) {
        urlParams.searchString = searchQuery;
      }

      setTableIsLoading(true);
      const apiResponse = await ServiceAttendanceApi.getDoctorsDailyTimeReport(
        urlParams
      );

      setTableIsLoading(false);
      // console.log(apiResponse);
      setTotalRecords(apiResponse.results.total);
      // Data from API by default is sorted based on updatedAt
      setTableData(
        apiResponse.data.map((obj, index) => ({ ...obj, key: index }))
      );
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }, [
    currentPageNum,
    tableEntries,
    appliedStartDate,
    appliedEndDate,
    searchQuery,
  ]);

  useEffect(() => {
    getDoctorsDailyTimeReport();
  }, [getDoctorsDailyTimeReport]);

  const handleApplyShiftDateRange = (startDate, endDate) => {
    setAppliedStartDate(startDate);
    setAppliedEndDate(endDate);
  };
  const handleChangeFilterByShiftDateRange = (dates, dateStrings) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setTimeout(setSearchQuery(e.target.value), 3000);
  };

  const handleChangeRowSelectionOfDoctorOrAllDoctors = (rowKeys) => {
    const arrFromObj = Object.values(rowKeys);
    if (arrFromObj.length === 2) {
      arrFromObj.shift();
    }
    setCurrSelectedRowKeys(arrFromObj);
  };

  const handlePaginateTable = (antdSelectedPageNum) => {
    setCurrentPageNum(antdSelectedPageNum);
  };

  const tableColumnHeader = [
    {
      title: "Doctor Name",
      dataIndex: ["doctorDetails"],
      key: ["doctorDetails"],
      render: (text) => {
        if (text) {
          return `${text?.firstName} ${text?.lastName}`;
        } else {
          return "--";
        }
      },
    },
    {
      title: "Specialty",
      dataIndex: ["doctorDetails", "specialty"],
      key: ["doctorDetails", "specialty"],
      render: (text) => {
        if (text) {
          return text.join("\r,\n");
        } else {
          return "--";
        }
      },
      width: 150,
    },
    {
      title: "Date of Shift",
      dataIndex: "shiftDate",
      key: "shiftDate",
      render: (date) => {
        if (date) {
          return date;
        } else {
          return "--";
        }
      },
    },
    {
      title: (
        <>
          {"Day of"} <br /> {"Shift"}
        </>
      ),
      dataIndex: "shiftDay",
      key: "shiftDay",
      render: (day) => {
        if (day) {
          return day;
        } else {
          return "--";
        }
      },
    },
    {
      title: (
        <>
          {"Type of"} <br /> {"Shift"}
        </>
      ),
      dataIndex: "shiftType",
      key: "shiftType",
      render: (type) => {
        if (type.length === 0) return "--";
        if (type) {
          if (type[0] === "day") return "Day";
          if (type[0] === "mid") return "Mid";
          if (type[0] === "graveyard") return "Graveyard";
        } else {
          return "--";
        }
      },
    },
    {
      title: "Time In",
      dataIndex: "login",
      key: "login",
      render: (loginTimeStamp) => {
        if (loginTimeStamp) {
          return moment(loginTimeStamp).format("hh:mm A").toLowerCase();
        } else {
          return "--";
        }
      },
    },
    {
      title: "Time Out",
      dataIndex: "logout",
      key: "logout",
      render: (logoutTimeStamp) => {
        if (logoutTimeStamp) {
          return moment(logoutTimeStamp).format("hh:mm A").toLowerCase();
        } else {
          return "--";
        }
      },
    },
    {
      title: (
        <>
          {"Number"} <br /> {"of Hour"}
        </>
      ),
      dataIndex: "duration",
      key: "duration",
      render: (durationTime) => {
        if (durationTime) {
          const hrs = durationTime?.slice(0, 2).replace(/^0+/, ""); //Get hrs and remove 0 at the start (not the end)
          const mins = durationTime?.slice(3, 5).replace(/^0+/, ""); //Get mins and remove 0 at the start (not the end)
          const secs = durationTime?.slice(6, 8).replace(/^0+/, ""); //Get secs and remove 0 at the start (not the end)
          const is1Hr = hrs === "1";
          const is1Min = mins === "1";
          const is1Sec = secs === "1";
          const is0Sec = secs === "";
          return (
            <>
              {/* SHOW HOURS IF VALUE IS NOT 0 */}
              {hrs === "" ? "" : `${hrs ?? "--"} ${is1Hr ? "hr" : "hrs"}`}
              {hrs === "" ? "" : <br />}
              {/* SHOW MINS IF VALUE IS NOT 0 and SHOW SECS IF MINS === 0*/}
              {mins === ""
                ? `${secs ?? "--"} ${
                    is1Sec ? "sec" : is0Sec ? "0 sec" : "secs"
                  }`
                : `${mins ?? "--"} ${is1Min ? "min" : "mins"}`}
            </>
          );
        } else {
          return "--";
        }
      },
    },
    // {
    //   title: (
    //     <>
    //       {"Hours per"}
    //       <br />
    //       {"Shift"}
    //     </>
    //   ),
    //   dataIndex: "duration",
    //   key: "duration",
    //   render: (durationTime) => {
    //     if (durationTime) {
    //       const hrs = durationTime?.slice(0, 2).replace(/^0+/, ""); //Get hrs and remove 0 at the start (not the end)
    //       const mins = durationTime?.slice(3, 5).replace(/^0+/, ""); //Get mins and remove 0 at the start (not the end)
    //       // const secs = hoursPerShift?.slice(6, 8).replace(/^0+/, ""); //Get secs and remove 0 at the start (not the end)
    //       const is1Hr = hrs === "1";
    //       const is1Min = mins === "1";
    //       // const is1Sec = secs === "1";
    //       // const is0Sec = secs === "";
    //       return (
    //         <>
    //           {/* SHOW HOURS IF VALUE IS NOT 0 */}
    //           {hrs === "" ? "" : `${hrs ?? "--"} ${is1Hr ? "hr" : "hrs"}`}
    //           {/* {hrs === "" ? "" : <br />} */}
    //           {hrs === "" ? "0 hr" : <br />}
    //           {hrs === "" && <br />}
    //           {/* SHOW MINS IF VALUE IS NOT 0 and SHOW SEC/S IF MINS === 0*/}
    //           {mins === ""
    //             ? `0 min`
    //             : // `${secs ?? "--"} ${is1Sec ? "sec" : is0Sec ? "0 sec" : "secs"}`
    //               `${mins ?? "--"} ${is1Min ? "min" : "mins"}`}
    //         </>
    //       );
    //     } else {
    //       return (
    //         <>
    //           {"0 hr"}
    //           <br />
    //           {"0 min"}
    //         </>
    //       );
    //     }
    //   },
    // },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        if (amount) {
          return amount ?? "--";
        } else {
          return "--";
        }
      },
    },
    {
      title: (
        <>
          {"Computation"}
          <br />
          {"(Num of Hrs * Amount)"}
        </>
      ),
      dataIndex: "computation",
      key: "computation",
      render: (computationResult) => computationResult ?? "--",
    },
    {
      title: (
        <>
          {"Practitioner"} <br /> {"Missed"}
        </>
      ),
      dataIndex: "practitionerMissed",
      key: "practitionerMissed",
      render: (practitionerMissed) => practitionerMissed ?? "--",
    },
    {
      title: (
        <>
          {"Total"}
          <br />
          {"Monthly Time"}
        </>
      ),
      dataIndex: "totalMonthHours",
      key: "totalMonthHours",
      render: (totalMonthHours) => {
        // const dummy_time = "10 hrs 59 mins";
        if (totalMonthHours) {
          const hrs = totalMonthHours
            ?.toString()
            .slice(0, 2)
            .replace(/^0+/, ""); //Get hrs and starting 0
          // const mins = totalMonthHours?.slice(7, 9).replace(/^0+/, "") ?? " "; //Get mins and and starting 0
          const mins = "";
          return (
            <>
              {/* SHOW HOURS IF VALUE IS NOT 0 */}
              {hrs === "" ? "" : `${hrs ?? "--"} hrs`}
              {hrs === "" ? "" : <br />}
              {/* SHOW MINS IF VALUE IS NOT 0 and SHOW SECS IF MINS === 0*/}
              {mins === "" ? "" : `${mins ?? "--"} mins`}
            </>
          );
        } else {
          return "--";
        }
      },
    },
  ];

  const generateDailyTimeReport = async () => {
    try {
      // setTableIsLoading(true);
      setIsScreenMsg("Exporting Daily Time Report...");
      setIsScreenLoading(true);
      let urlParams = {};
      if (currentPageNum) {
        urlParams.page = currentPageNum;
        urlParams.limit = tableEntries;
      }

      urlParams.startDate = startDate;
      urlParams.endDate = endDate;

      if (isExportAllCSV === true) {
        urlParams.selectAll = true;
        urlParams.export = true;
      }

      if (currSelectedRowKeys.length > 1 && isExportAllCSV === false) {
        urlParams.export = true;
      }

      if (specificKey.length === 1 && isExportAllCSV === false) {
        urlParams.export = true; // flag daw as per BE Steven para per doctor
        urlParams.doctorId = selectedDoctorId;
      }

      const apiResponse = await ServiceAttendanceApi.getDtrCSV(urlParams);
      FileDownload(apiResponse.data, `DTR ${UI.get_current_date_string()}.csv`);
      setIsScreenLoading(false);
      console.log("Export to CSV status: ", apiResponse.status);
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) {
          console.log(`Error: ${statusCode} ${errorMsg}`);
        }
      }
    }
  };

  const generateUtilizationReport = async () => {
    try {
      // setTableIsLoading(true);
      setIsScreenMsg("Exporting Utilization Report...");
      setIsScreenLoading(true);
      let urlParams = {};
      if (currentPageNum) {
        urlParams.page = currentPageNum;
        urlParams.limit = tableEntries;
      }

      urlParams.startDate = startDate;
      urlParams.endDate = endDate;

      if (isExportAllCSV === true) {
        urlParams.selectAll = true;
        urlParams.export = "util";
      }

      if (currSelectedRowKeys.length > 1  && isExportAllCSV === false) {
        urlParams.export = "util";
      }

      if (specificKey.length === 1  && isExportAllCSV === false) {
        urlParams.export = "util"; // flag daw as per BE Steven para per doctor
        urlParams.doctorId = selectedDoctorId;
      }

      const apiResponse = await ServiceAttendanceApi.getDtrCSV(urlParams);
      FileDownload(
        apiResponse.data,
        `Util Report ${UI.get_current_date_string()}.csv`
      );
      setIsScreenLoading(false);
      console.log("Export to CSV status: ", apiResponse.status);
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) {
          console.log(`Error: ${statusCode} ${errorMsg}`);
        }
      }
    }
  };

  let toolTipMsg = <span>Select 1 or All doctors to enable</span>;
  if (currSelectedRowKeys.length > 1 || specificKey.length === 1) {
    toolTipMsg = "";
  }

  return (
    <>
      <Loading
        visible={isScreenLoading}
        title={""}
        text={screenMsg}
        modal_opaque={false}
      />
      <StyledRow>
        <FilterBox>
          <Title level={5}>Daily Time Report</Title>
          <Toolbar gutter={14}>
            <ToolItem span={24}>
              <StyledFieldLabel>Date Filter by</StyledFieldLabel>
              <StyledRangePicker
                separator={<DatePickerCalendarIcon />}
                suffixIcon={<DatePickerCalendarIcon />}
                placeholder={["Start Date", "End Date"]}
                ranges={{
                  Today: [moment(), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                onChange={handleChangeFilterByShiftDateRange}
              />
              <ApplyButton
                type="primary"
                onClick={() => {
                  handleApplyShiftDateRange(startDate, endDate);
                }}
              >
                Apply
              </ApplyButton>
            </ToolItem>
          </Toolbar>
        </FilterBox>
        <StyledCol position={"row"}>
          <Col span={18}>
            <Title level={5}>Leave Approval List</Title>
            <ApplyButton
              style={{ marginTop: "35px" }}
              type="primary"
              onClick={() => {
                UI.goTo("/admin/doctor-leave");
              }}
            >
              Check List
            </ApplyButton>
          </Col>
          <Col span={6}>
            <Image
              preview={false}
              height={"133px"}
              width={"133px"}
              src={UI.DOCTOR_LEAVEREQUEST}
            />
          </Col>
        </StyledCol>
      </StyledRow>

      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="30"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="5">5 Entries</Select.Option>
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>
          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getDoctorsDailyTimeReport}
            style={{ width: 200 }}
          />
          <Tooltip placement="top" title={toolTipMsg}>
            <StyledButton
              type="primary"
              onClick={generateDailyTimeReport}
              disabled={
                currSelectedRowKeys.length > 0 || specificKey.length === 1
                  ? false
                  : true
              }
            >
              Export to CSV
            </StyledButton>
          </Tooltip>
          <Tooltip placement="top" title={toolTipMsg}>
            <StyledButton
              type="primary"
              onClick={generateUtilizationReport}
              disabled={
                currSelectedRowKeys.length > 0 || specificKey.length === 1
                  ? false
                  : true
              }
            >
              Generate Utilization Report
            </StyledButton>
          </Tooltip>
        </Toolbar>

        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={tableData}
          defaultPageSize={30}
          pageSizeOptions={["5", "10", "30"]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isTableLoading}
          scroll={{
            x: 600,
          }}
          // Antd Checkbox
          rowSelection={{
            type: "checkbox",
            selectedRowKeys:
              specificKey.length === 1 ? specificKey : currSelectedRowKeys,
            onSelect: (record, selected, selectedRows, nativeEvent) => {
              setSelectedDoctorId(record.doctorID._id);
              setSpecificKey([record.key]);
              setCurrSelectedRowKeys([]);
              if (record.key === specificKey[0]) {
                setSpecificKey([]);
                setCurrSelectedRowKeys([]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              setSpecificKey([]);
              setIsExportAllCSV((prevVal) => !prevVal);
              handleChangeRowSelectionOfDoctorOrAllDoctors(
                selectedRows.map((el) => el.key)
              ); // selectedRowKeys type is object
            },
          }}
        />
      </TableBox>
    </>
  );
};

export default DoctorDailyTimeReport;

// CSS Styled Components ----------------------

const StyledRow = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: row;
  background: transparent;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
  justify-content: space-between;
`;
const StyledCol = styled(Col)`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.position === "row" ? "row" : "column")};
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
  width: 48%;
`;

const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
  width: 48%;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
  // ALL TABLE BODY CELLS
  .ant-table-tbody > tr > td {
    height: 77px;
  }
  // 3RD COLUMN OF TABLE (DOCTOR'S SPECIALTY)
  .ant-table-row .ant-table-cell:nth-child(3) {
    white-space: pre;
  }
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const ToolItem = styled(Col)`
  /* position: relative; */
`;

const Title = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.4375rem !important; // 15px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
  padding-left: 35px;
  margin-bottom: 25px !important;
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  flex-direction: row;
  border-radius: 12px;
  border: 1px solid transparent;
  box-shadow: none !important;
  height: 50px;
  &:hover {
    border-color: transparent !important;
  }
  .ant-picker-input {
    border: 2px solid #d9d9d9;
    border-radius: 12px;
    padding-left: 10px;
    padding-right: 10px;
    height: 50px;
    width: 160px;
  }
  .ant-picker-range-separator {
    display: block !important; //Need this bcoz the this selector css.css interferes globally
    margin-left: -43px; //To insert built-in icon inside input field
    margin-right: 16px;
  }
  .ant-picker-suffix {
    margin-left: -35px; //To insert built-in icon inside input field
  }
  .ant-picker-active-bar {
    display: none;
  }
  .ant-picker-clear {
    display: none;
  }
`;

const ApplyButton = styled(Button)`
  height: 50px;
  width: 140px;
  border-radius: 12px;
  border: none;
  background-color: #02488d;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover,
  &:focus {
    background-color: #02488d;
  }
`;
