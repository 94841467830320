import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  ImageBackground,
  ImageFit,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm, { Checkbox } from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import "./css.css";

const context = "admin";

// const specialties_array = [
//   {
//     name: "Acupuncturist",
//     system: "hipaa",
//     code: "171100000X",
//   },
//   {
//     name: "Admitting Physician",
//     system: "snomed",
//     code: "421841007",
//   },
//   {
//     name: "Aerospace Medicine Physician",
//     system: "hipaa",
//     code: "2083A0100X",
//   },
//   {
//     name: "Anesthesiologist",
//     system: "snomed",
//     code: "88189002",
//   },
//   {
//     name: "Attending Physician",
//     system: "snomed",
//     code: "405279007",
//   },
//   {
//     name: "Cardiologist",
//     system: "snomed",
//     code: "17561000",
//   },
//   {
//     name: "Care Assistant",
//     system: "snomed",
//     code: "184152007",
//   },
//   {
//     name: "Care Manager",
//     system: "snomed",
//     code: "184154008",
//   },
//   {
//     name: "Caregiver",
//     system: "snomed",
//     code: "133932002",
//   },
//   {
//     name: "Certified Registered Nurse Anesthetist",
//     system: "snomed",
//     code: "405278004",
//   },
//   {
//     name: "Chaplain",
//     system: "snomed",
//     code: "225725005",
//   },
//   {
//     name: "Chinese Herbal Medicine",
//     system: "snomed",
//     code: "395114000",
//   },
//   {
//     name: "Chiropractor",
//     system: "snomed",
//     code: "3842006",
//   },
//   {
//     name: "Clinical Immunologist",
//     system: "snomed",
//     code: "63098009",
//   },
//   {
//     name: "Clinical Oncologist",
//     system: "snomed",
//     code: "309295000",
//   },
//   {
//     name: "Clinical Psychologist",
//     system: "snomed",
//     code: "310191001",
//   },
//   {
//     name: "Complementary Therapist",
//     system: "snomed",
//     code: "225423004",
//   },
//   {
//     name: "Consultant Physician",
//     system: "snomed",
//     code: "158967008",
//   },
//   {
//     name: "Dental Hygienist",
//     system: "snomed",
//     code: "26042002",
//   },
//   {
//     name: "Dentist",
//     system: "snomed",
//     code: "106289002",
//   },
//   {
//     name: "Dermatologist",
//     system: "snomed",
//     code: "18803008",
//   },
//   {
//     name: "Dietitian",
//     system: "snomed",
//     code: "159033005",
//   },
//   {
//     name: "Ear, Nose and Throat Surgeon",
//     system: "snomed",
//     code: "309372007",
//   },
//   {
//     name: "Emergency Medicine Physician",
//     system: "hipaa",
//     code: "207P00000X",
//   },
//   {
//     name: "Endocrinologist",
//     system: "snomed",
//     code: "61894003",
//   },
//   {
//     name: "Family Medicine Specialist",
//     system: "snomed",
//     code: "62247001",
//   },
//   {
//     name: "Gastroenterologist",
//     system: "snomed",
//     code: "71838004",
//   },
//   {
//     name: "Gen Physician",
//     system: "snomed",
//     code: "4.53231E+14",
//   },
//   {
//     name: "General Medicine",
//     system: "hipaa",
//     code: "208D00000X",
//   },
//   {
//     name: "General Surgeon",
//     system: "snomed",
//     code: "78703002",
//   },
//   {
//     name: "Genetic Counselor",
//     system: "snomed",
//     code: "310189009",
//   },
//   {
//     name: "Geriatrics Specialist",
//     system: "snomed",
//     code: "90655003",
//   },
//   {
//     name: "Gynecologist",
//     system: "snomed",
//     code: "83685006",
//   },
//   {
//     name: "Hand Surgeon",
//     system: "hipaa",
//     code: "2086S0105X",
//   },
//   {
//     name: "Health Coach",
//     system: "snomed",
//     code: "432100008",
//   },
//   {
//     name: "Health Educator",
//     system: "snomed",
//     code: "409974004",
//   },
//   {
//     name: "Hematology & Oncologist",
//     system: "hipaa",
//     code: "207RH0003X",
//   },
//   {
//     name: "Infectious Disease Specialist",
//     system: "snomed",
//     code: "76899008",
//   },
//   {
//     name: "Internal Medicine Specialist",
//     system: "snomed",
//     code: "39677007",
//   },
//   {
//     name: "Medical Assistant",
//     system: "snomed",
//     code: "22515006",
//   },
//   {
//     name: "Medical Oncologist",
//     system: "hipaa",
//     code: "207RX0202X",
//   },
//   {
//     name: "Medical Practitioner",
//     system: "snomed",
//     code: "158965000",
//   },
//   {
//     name: "Neonatologist",
//     system: "snomed",
//     code: "28411006",
//   },
//   {
//     name: "Nephrologist",
//     system: "snomed",
//     code: "11911009",
//   },
//   {
//     name: "Neurologist",
//     system: "snomed",
//     code: "56397003",
//   },
//   {
//     name: "Neurosurgeon",
//     system: "snomed",
//     code: "45544007",
//   },
//   {
//     name: "Nurse Case Manager",
//     system: "snomed",
//     code: "445451001",
//   },
//   {
//     name: "Nurse Practitioner",
//     system: "snomed",
//     code: "224571005",
//   },
//   {
//     name: "Nursing Assistant",
//     system: "snomed",
//     code: "224576000",
//   },
//   {
//     name: "Obstetrician",
//     system: "snomed",
//     code: "11935004",
//   },
//   {
//     name: "Obstetrician And Gynecologist",
//     system: "snomed",
//     code: "309367003",
//   },
//   {
//     name: "Occupational Medicine Physician",
//     system: "hipaa",
//     code: "2083X0100X",
//   },
//   {
//     name: "Occupational Therapist",
//     system: "snomed",
//     code: "80546007",
//   },
//   {
//     name: "Office Manager",
//     system: "snomed",
//     code: "159385002",
//   },
//   {
//     name: "Ophthalmologist",
//     system: "snomed",
//     code: "422234006",
//   },
//   {
//     name: "Oral Surgeon",
//     system: "snomed",
//     code: "49993003",
//   },
//   {
//     name: "Orthopedic Surgeon",
//     system: "snomed",
//     code: "22731001",
//   },
//   {
//     name: "Otorhinolaryngologist",
//     system: "snomed",
//     code: "61345009",
//   },
//   {
//     name: "Pain Management Specialist",
//     system: "snomed",
//     code: "309337009",
//   },
//   {
//     name: "Paramedic",
//     system: "snomed",
//     code: "397897005",
//   },
//   {
//     name: "Patient",
//     system: "snomed",
//     code: "116154003",
//   },
//   {
//     name: "Patient Advocate",
//     system: "snomed",
//     code: "429577009",
//   },
//   {
//     name: "Pediatric Cardiologist",
//     system: "snomed",
//     code: "309330006",
//   },
//   {
//     name: "Pediatric Gastroenterologist",
//     system: "snomed",
//     code: "309332003",
//   },
//   {
//     name: "Pediatrician",
//     system: "snomed",
//     code: "82296001",
//   },
//   {
//     name: "Pharmacist",
//     system: "snomed",
//     code: "46255001",
//   },
//   {
//     name: "Physical Therapist",
//     system: "hipaa",
//     code: "225100000X",
//   },
//   {
//     name: "Physician",
//     system: "snomed",
//     code: "309343006",
//   },
//   {
//     name: "Physician Assistant",
//     system: "snomed",
//     code: "449161006",
//   },
//   {
//     name: "Physiotherapist",
//     system: "snomed",
//     code: "36682004",
//   },
//   {
//     name: "Plastic Surgeon",
//     system: "snomed",
//     code: "8724009",
//   },
//   {
//     name: "Podiatrist",
//     system: "snomed",
//     code: "159034004",
//   },
//   {
//     name: "Professional Nurse",
//     system: "snomed",
//     code: "106292003",
//   },
//   {
//     name: "Psychiatrist",
//     system: "snomed",
//     code: "80584001",
//   },
//   {
//     name: "Psychology",
//     system: "snomed",
//     code: "59944000",
//   },
//   {
//     name: "Public Health Nurse",
//     system: "snomed",
//     code: "26369006",
//   },
//   {
//     name: "Public Health Nutritionist",
//     system: "snomed",
//     code: "60008001",
//   },
//   {
//     name: "Pulmonary Disease Physician",
//     system: "hipaa",
//     code: "207RP1001X",
//   },
//   {
//     name: "Radiation Oncologist",
//     system: "hipaa",
//     code: "2085R0001X",
//   },
//   {
//     name: "Radiation Therapist",
//     system: "snomed",
//     code: "3430008",
//   },
//   {
//     name: "Radiologist",
//     system: "snomed",
//     code: "66862007",
//   },
//   {
//     name: "Registered Midwife",
//     system: "snomed",
//     code: "309453006",
//   },
//   {
//     name: "Resident Physician",
//     system: "snomed",
//     code: "405277009",
//   },
//   {
//     name: "Respiratory Disease Specialist",
//     system: "snomed",
//     code: "41672002",
//   },
//   {
//     name: "Rheumatologist",
//     system: "snomed",
//     code: "45440000",
//   },
//   {
//     name: "School Nurse",
//     system: "snomed",
//     code: "159003003",
//   },
//   {
//     name: "Scrub Nurse",
//     system: "snomed",
//     code: "415506007",
//   },
//   {
//     name: "Social Worker",
//     system: "snomed",
//     code: "106328005",
//   },
//   {
//     name: "Surgeon",
//     system: "snomed",
//     code: "304292004",
//   },
//   {
//     name: "Thoracic Surgeon",
//     system: "snomed",
//     code: "22983004",
//   },
//   {
//     name: "Urologist",
//     system: "snomed",
//     code: "24590004",
//   },
//   {
//     name: "Vascular Surgeon",
//     system: "snomed",
//     code: "309388007",
//   },
//   {
//     name: "Wound Management",
//     system: "hipaa",
//     code: "163WW0000X",
//   },
// ];

export default class AdminAddSpecialty extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "App Content",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      label: "",
      system: "",
      code: "",
      professionalType: "",
      // image:UI.PRODUCT_BOX,
      icon_url: UI.PRODUCT_BOX,
      image_file:"",

      status: "",
    });
  }

  onCreate = () => {};

  // add_all_specialty = async () => {
  //   specialties_array.map(async (item) => {
  //     const { code, name, system } = item;
  //     setTimeout(() => {
  //       UI.webRequest({
  //         method: "post",
  //         url: "admin/specializations",
  //         params: {
  //           label: name,
  //           system: system,
  //           code: code,
  //         },
  //         isMultiPart: false,
  //         onFail: (err) => {},
  //         onSuccess: (response) => {
  //           console.log("Added: " + name);
  //         },
  //       });
  //     }, 2000);
  //   });
  // };

  pick_image = async () => {
    // const _this = this.props._this;
    let image = await UI.get_image();
    this.auth_upload("icon_url", image.file);
    // this.setState(
    //   {
    //     icon: image.uri,
    //     icon_file: image.file,
    //     image_file: image.file,
    //   },
    //   () => {
    //     this.auth_upload("icon_url", image.file);
    //   }
    // );
    // this.setState({
    //   image_key: "key_" + Date.now(),
    // });
  };

  auth_upload = async (state_name, file, callback) => {
    UI.upload_image({
      url: "admin/specializations/upload",
      file: file,
      onFail: (err) => {
        this.show_modal(
          <Failed
            title={"Failed"}
            description={"Upload Failed. Application Error."}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (path, response) => {
        const data = UI.extract_data(response);

        console.log(path)
        console.log(response)
        this.setState(
          {
            [state_name]: data.url,
            icon_path: path
          },
          // () => {
          //   if (callback) {
          //     callback(path);
          //   }
          //   this.process_file(state_name, file, path);
          // }
        );
      },
    });
  };

  
  // process_file = async (state_name, file, path) => {
  //   const filename = UI.container_filename(path);
  //   const extension = await UI.get_file_extension(path);
  //   const url = URL.createObjectURL(file);
  //   const obj = {
  //     url: url,
  //     extension: extension,
  //     filename: filename,
  //   };

  //   this.setState({
  //     ["file_" + state_name]: obj,
  //   });
  // };

  submit = async () => {
    UI.clear_errors();
    const { label, status,  
      // image_file, 
      professionalType, icon_url, icon_path } = this.state;

    //     console.log(this.state.icon)
    //     console.log(this.state.icon_file)
    //     console.log(this.state.image_file)
    //     console.log(this.state.icon_url)
    // return
    if (!icon_url) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Upload Icon Specialty"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      return;
    }

    if (!label) {
      await UI.error_form("label", "Specialty Name is required.");
    }

    if (!status) {
      await UI.error_form("status", "Status is required.");
    }
  /*   if (!system) {
      await UI.error_form("system", "System is required.");
    }
    if (!code) {
      await UI.error_form("code", "System is required.");
    } */
    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    this.show_loading();
    UI.webRequest({
      method: "post",
      url: "admin/specializations",
      params: {
        label: label,
        status: status,
        professionalType: professionalType,
        imagePath: icon_path,
        stytem:"N/A"
      },
      isMultiPart: false,
      onFail: (err) => {
        console.log(err);
        const data = err.data;
        const error_message = data.error;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (response) => {
        console.log(response);
        UI.clear_forms();
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully Added the Specialty"}
            onDismiss={() => {
              UI.clear_forms();
              this.hide_modal();
            }}
          />
        );
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };
  
  // upload_picture = async () => {
  //   const file = await UI.get_image();

  //   this.setState(
  //     {
  //       image: file.uri,
  //       image_file: file.file,
  //       did_upload: true,
  //     }
  //   );

  //   console.log(this.state.image_file)
  // };
  
  render() {
    const _this = this;
    // const { height, width, isMobile } = _this.state;
    const {  did_upload } = _this.state;

    return (
      <MainLayout context={context} additional={"specialties"} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Add Specialty
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add item
            </Text>
          </UI.Row>
          {UI.box(10)}
          <UI.Row breakpoint={970} _this={_this}>
              <View style={{ height: 120, width: 120 }}>
                <Layout.FilePicker
                  _this={_this}
                  title={""}
                  additional={""}
                  // isRequired={true}
                  text={""}
                  state_name={"icon_specialty"}
                  onChange={async (file) => {
                    // this.pick_image();

                    // const uri = await UI.get_uri_from_file(file);
                    // this.setState(
                    //   {
                    //     image: uri,
                    //     image_file: file,
                    //     did_upload: true,
                    //   }
                    // );
                  }}
                  height={120}
                  component={
                    <ImageBackground
                      style={{ height: 120, width: 120, borderRadius: 10 }}
                      source={this.state.icon_url}
                      imageFit={ImageFit.CONTAIN}
                    />
                  }
                />
              </View>
              {UI.box(20)}
              <strong className="text-red-500">*</strong>
              <View style={{ flex: 1.5, justifyContent: "center" }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
                >
                  {did_upload
                    ? "Specialty Icon Uploaded."
                    : "No Icon Uploaded."}{" "}
                  {/* No Icon Uploaded.  */}
                  Recommended Size: 500 px x 500 px
                </Text>
                {UI.box(10)}

                <Layout.Btn
                  color={UI.colors.secondary}
                  text={"Upload Quicklink/Button Icon"}
                  onClick={() => {
                    this.pick_image();

                    // this.upload_picture();
                  }}
                  width={220}
                />
              </View>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
          
            <View style={{ flex: 1 }}>
              <Text style={{fontSize: 14, fontWeight: "bold", color: UI.colors.primary,}}>
                Professional Type <strong className="text-red-500">*</strong>
              </Text>
              {UI.box(8)}

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Checkbox
                  _this={_this}
                  text={"Medical"}
                  onClick={(value) => {
                    _this.setState({
                      professionalType: _this.state.professionalType === "Medical" ?  "" : "Medical",
                    });
                  }}
                  is_checked={_this.state.professionalType === "Medical" ? true : false}
                  color={"#2E2E2E"}
                />
                <View style={{ width: 50 }}></View>
                <Checkbox
                  _this={_this}
                  text={"Non Medical"}
                  onClick={(value) => {
                    _this.setState({
                      professionalType: _this.state.professionalType === "Non Medical"  ?  "" : "Non Medical" ,
                    });
                  }}
                  is_checked={_this.state.professionalType === "Non Medical" ? true : false}
                  color={"#2E2E2E"}
                />
              </View>
            </View>
          </UI.Row>

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Specialty Name"}
              placeholder={"Enter Specialty Name"}
              state_name={"label"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.name}
            />
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={true}
              state_name={"status"}
            >
              <option value="">Item Status (Active or Inactive)</option>
              <option value="Active" selected={this.state.status ? true : false}>Active</option>
              <option value="Inactive" selected={this.state.status ? true : false}>Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style = {{flex: 1}}></View>
         {/*    {UI.box(10)}

            <InputForm
              _this={_this}
              title={"System"}
              placeholder={"hipaa"}
              state_name={"system"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.code}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Code"}
              placeholder={"171100000X"}
              state_name={"code"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.code}
            /> */}

            <View style={{ flex: 1 }}></View>
          </UI.Row>

          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"UPDATE ITEM"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
