import React from "react";
import {
  Image,
  Document,
  Page,
  StyleSheet,
  Text,
  View,
} from "react-pdf-renderer-v2";
import moment from "moment";

const MOAContent = ({ _this }) => {
  const {
    first_name,
    middle_name,
    last_name,
    file_signature_path,
    // floor_number,
    house_number,
    street_name,
    barangay,
    city_municipality,
    province,
    zip_code,
  } = _this.state;

  return (
    <Document size="LETTER">
      <Page style={styles.page}>
        <Text style={styles.heading}>Memorandum Of Agreement</Text>
        <Text style={[styles.text, styles.indent]}>
          This MEMORANDUM OF AGREEMENT (hereinafter this "Agreement"), is
          entered into this{" "}
          <Text style={styles.underline}>{moment().format("MMMM")}</Text>
          &nbsp; day of &nbsp;
          <Text style={styles.underline}>{moment().format("DD")}</Text>&nbsp;
          <Text>{moment().format("YYYY")}</Text> between:
        </Text>
        <View style={{ paddingLeft: 55, paddingRight: 55 }}>
          <Text style={{ ...styles.text }}>
            <Text style={styles.bold}>
              METRO PACIFIC HEALTH TECH CORPORATION DOING BUSINESS UNDER MWELL
              PH
            </Text>
            , a corporation organized and existing under Philippine laws, with
            business address at 10th Floor MGO Building, Legazpi cor. Dela Rosa
            Streets, Legazpi Village, Makati City, (hereinafter referred to as{" "}
            <Text style={styles.bold}>"MPHTC"</Text>
          </Text>
          <Text style={[styles.text, styles.center]}>and</Text>
          <Text style={[styles.text]}>
            <Text
              style={styles.underline}
            >{`${first_name} ${middle_name} ${last_name}`}</Text>
            , Filipino, of legal age, with office address at{" "}
            <Text
              style={styles.underline}
            >{`${house_number} ${street_name} ${barangay} ${city_municipality} ${province} ${zip_code}`}</Text>{" "}
            (hereinafter referred to as{" "}
            <Text style={styles.bold}>"Physician"</Text>).
          </Text>

          <Text style={styles.text}>
            MPHTC and the Physician shall be individually referred to as a{" "}
            <Text style={styles.bold}>"Party"</Text>
            and collectively as the <Text style={styles.bold}>"Parties"</Text>.
          </Text>
        </View>
        <Text style={[styles.text, styles.center]}>RECITALS:</Text>

        <Text style={[styles.text, styles.indent]}>
          A. &nbsp;&nbsp;&nbsp; MPHTC is the owner of a mobile health
          application (<Text style={styles.bold}>“Application”</Text>) which
          offers, among others, telemedicine services to its users (the{" "}
          <Text style={styles.bold}>“Users”</Text>,{" "}
          <Text style={styles.bold}>“Patients”</Text>);
        </Text>
        <Text style={[styles.text, styles.indent]}>
          B. &nbsp;&nbsp;&nbsp; MPHTC has offered the Application as a
          telemedicine platform and the Physician has accepted under the terms
          and conditions set forth in this Agreement.{" "}
        </Text>
        <Text style={[styles.text, styles.indent]}>
          <Text style={[styles.bold, styles.indent]}>NOW, THEREFORE</Text>, for
          and in consideration of the foregoing premises, the Parties hereby
          agree as follows:
        </Text>
        <Text style={[styles.text, styles.indent]}>
          <Text style={[styles.bold, styles.indent]}>
            Section 1. Use of the Application
          </Text>
          . The Physician shall access and use the Application for sole purposes
          of offering and providing medical consultations to the Users. The
          Application shall allow the Physician to maintain an online presence
          on the Application, answer queries of Patients, access medical profile
          and history of Patients, and conduct online consultations through the
          Application.
        </Text>
        <Text style={[styles.text, styles.indent]}>
          To access and use the Application as a medical practitioner, the
          Physician must share the following information and submit the
          following documents to MPHTC upon execution of this Agreement:{" "}
        </Text>
        <View style={styles.list}>
          <Text style={[styles.text]}>
            (1) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Area of specialization;
          </Text>
          <Text style={styles.text}>
            (2) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Affiliated hospitals and/or
            clinics;
          </Text>
          <Text style={styles.text}>
            (3) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Years of work experience;
          </Text>
          <Text style={styles.text}>
            (4) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Professional Regdivatory
            Commission identification card;
          </Text>
          <Text style={styles.text}>
            (5) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Any government-issued
            identification card with photo and signature; and
          </Text>
          <Text style={styles.text}>
            (6) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Other information and documents
            as may be reasonably required by MPHTC.
          </Text>
        </View>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>
            Section 2. Professional Fees of Independent Physicians.
          </Text>{" "}
          The professional fee of Independent Physicians (as defined below)
          shall amount to __________. Independent Physicians may, in their sole
          discretion, change the amount of the professional fee, effective
          immediately.
        </Text>
        <Text style={[styles.text, styles.indent]}>
          The Independent Physician shall be paid in accordance with the
          Schedule of Payment indicated in Schedule A, which forms part of this
          Agreement. The professional fees shall be paid only upon completion of
          the consultation. Professional fees shall be remitted to the
          Independent Physician’s account in accordance with the schedule of
          payments indicated in Schedule A. For remittance purposes, the
          Independent Physician shall provide his/her bank account details to
          MPHTC.
        </Text>
        <Text style={[styles.text, styles.indent]}>
          It is understood and agreed that the amount remitted shall be
          inclusive of applicable taxes and fees, dispute costs, refunds, and
          discount provided, if any. MPHTC reserves the right at any time to
          withhold or cancel any remittance for any act or omission which
          violate the terms of this Agreement.
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>
            Section 3. Professional Fees of hospital/clinic-acquired Physicians.{" "}
          </Text>
          The rate of the professional fees of hospital/clinic-acquired
          Physicians (as defined below), including any changes to its amount and
          effectivity shall be subject to written negotiations between MPHTC and
          MPHTC Partners. The professional fees of hospital/clinic-acquired
          Physicians shall be paid directly to the MPHTC Partners in accordance
          with their agreement with MPHTC.{" "}
        </Text>
        <Text style={[styles.text, styles.indent]}>
          For purposes of this Agreement,{" "}
          <Text style={styles.bold}>“Independent Physicians”</Text> shall mean
          the Physicians who are solo practitioners and{" "}
          <Text style={styles.bold}>“hospital/clinic-acquired Physicians”</Text>{" "}
          means Physicians employed and/or salaried by partner hospitals/clinics
          of MPHTC (“
          <Text style={styles.bold}>MPHTC Partners”</Text>).
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>
            Section 4. Service Fees.{" "}
          </Text>
          In consideration of the Platform services, MPHTC shall charge the
          Patients a service fee on top of the Physician’s professional fee (the{" "}
          <Text style={styles.bold}>“Service Fee”</Text>). The Service Fee shall
          range from Fifty Pesos to Five Hundred Pesos (Php50 to Php 500), which
          may be subject to change depending on MPHTC’s sole discretion.
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>Section 5. Term.</Text>{" "}
          This Agreement shall become effective on the date of its execution and
          shall continue in force and effect for a period of one year (the{" "}
          <Text style={styles.bold}>“Initial Term”</Text>) from the date hereof,
          unless sooner pre-terminated by the appropriate Party in accordance
          with Section 11 of this Agreement. After the Initial Term, this
          Agreement shall be deemed renewed automatically each year for an
          additional one-year period unless MPHTC or the Physician elects not to
          renew this Agreement.{" "}
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>
            Section 6. Representations and Warranties of the Physician.
          </Text>{" "}
          The Physician represents and warrants that:
        </Text>
        <View style={styles.list}>
          <View style={[styles.text, styles.row]}>
            <Text>(a)</Text>
            <Text style={styles.margin}>
              He/she is duly licensed to practice medicine by the Philippine
              Regulatory Commission;
            </Text>
          </View>
          <View style={[styles.text, styles.row]}>
            <Text>(a)</Text>
            <Text style={styles.margin}>
              He/she is a member in good standing of the Philippine Medical
              Association;
            </Text>
          </View>
          <View style={[styles.text, styles.row]}>
            <Text>(c)</Text>
            <Text style={styles.margin}>
              He/she has the specialized skills, expertise and experience to
              provide medical advice to the Users on this background;
            </Text>
          </View>
          <View style={[styles.text, styles.row]}>
            <Text>(d)</Text>
            <Text style={styles.margin}>
              He/she has no pending or decided cases and/or complaint for
              medical negligence and/or medical offenses; and
            </Text>
          </View>
          <View style={[styles.text, styles.row]}>
            <Text>(e)</Text>
            <Text style={styles.margin}>
              He/she is complying with all applicable laws and regulations while
              using the Application or while providing services through the
              Application.
            </Text>
          </View>
        </View>
        <Text style={[styles.text, styles.indent]}>
          The foregoing representations and warranties of the Physician are
          deemed made on the date of this Agreement and shall continue to be
          effective for the duration of this Agreement.
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>
            Section 7. Obligations of MPHTC.
          </Text>{" "}
          In consideration of the Client’s undertakings under this Agreement,
          MPHTC shall provide the Application as a telemedicine platform in
          accordance with this Agreement and applicable laws, and procure all
          required certificates, permits, licenses and registrations as may be
          required for the proper conduct of its business.
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>
            Section 8. Obligations of the Physician.
          </Text>
          The Physician undertakes the following:
        </Text>
        <View style={styles.list}>
          <View style={[styles.text, styles.row]}>
            <Text>(a)</Text>
            <Text style={styles.margin}>
              conduct himself in a professional manner and shall observe the
              International Code of Medical Ethics and the Code of Ethics as
              approved by the Philippine Medical Association;
            </Text>
          </View>
          <View style={[styles.text, styles.row]}>
            <Text>(b)</Text>
            <Text style={styles.margin}>
              comply with all laws and regulations relating to the practice of
              medicine;
            </Text>
          </View>
          <View style={[styles.text, styles.row]}>
            <Text>(c)</Text>
            <Text style={styles.margin}>
              comply with applicable industry standards imposed by the
              Department of Health, including standards and regulations for
              Coronavirus (COVID-19);
            </Text>
          </View>
          <View style={[styles.text, styles.row]}>
            <Text>(d)</Text>
            <Text style={styles.margin}>
              comply with other relevant laws, including, but not limited to,
              the Data Privacy Act of 2012 and its implementing rules and
              regulations;
            </Text>
          </View>
          <View style={[styles.text, styles.row]}>
            <Text>(e)</Text>
            <Text style={styles.margin}>
              comply with all guidelines that MPHTC shall prescribe in
              connection with the use of the Application;
            </Text>
          </View>
          <View style={[styles.text, styles.row]}>
            <Text>(f)</Text>
            <Text style={styles.margin}>
              make himself available at the days and times scheduled for
              teleconsultations; and
            </Text>
          </View>
          <View style={[styles.text, styles.row]}>
            <Text>(g)</Text>
            <Text style={styles.margin}>
              comply with the terms of Service Level Agreement attached herein
              as Annex “A”, as may be amended from time to time.
            </Text>
          </View>
        </View>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>
            Section 9. Schedule; Disclosure of Information.
          </Text>
          To ensure effective and efficient service to the Users, the Physician
          is expected to attend all confirmed booked appointments. For clarity,
          hospital/clinicacquired Physicians should be available at the days and
          times scheduled for teleconsultations with the Users.
        </Text>
        <Text style={[styles.text, styles.indent]}>
          The Physician hereby consents to the disclosure of his/her relevant
          personal information and details (such as full name, specialty,
          contact details, address, etc.) in the Application and MPHTC’s website
          and other platforms.
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>
            Section 10. Service Level Agreement.
          </Text>
          The Parties shall comply with the terms of the Service Level
          Indicators attached as Annex “A”, which shall form an integral part of
          this Agreement. Violation of the Service Level Indicators as provided
          in Annex A shall be considered as a ground for termination of the
          Agreement.
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>
            Section 11. Suspension.
          </Text>
          MPHTC may, at its sole discretion, suspend the Physician’s ability to
          use or access the Application at any time pending investigation of any
          potential violation of this Agreement or other applicable laws and
          regulations.
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>
            Section 12. Termination.
          </Text>
          Notwithstanding the Term provided in Section 4, this Agreement may be
          terminated:
        </Text>
        <View style={styles.list}>
          <Text style={styles.text}>(a) By the Physician:</Text>
          <View style={styles.list}>
            <View style={[styles.text, styles.row]}>
              <Text>(i)</Text>
              <Text style={styles.margin}>
                in case MPHTC commits a breach of any of its warranties or
                material obligations under this Agreement, which breach is not
                remediable, or if remediable, is not cured by the MPHTC within
                thirty (30) days from receipt of written notice from the
                Physician;
              </Text>
            </View>
            <View style={[styles.text, styles.row]}>
              <Text>(ii)</Text>
              <Text style={styles.margin}>
                in case any act or proceeding by or against MPHTC for
                bankruptcy, insolvency, relief of debtors or the appointment of
                a receiver, trustee or custodian for it or for all or any
                substantial part of its assets is commenced and such action or
                proceeding (if commenced by a third party) is not dismissed or
                set aside within sixty (60) days from the institution thereof or
                if MPHTC has been adjudged bankrupt or insolvent, or if any
                execution affecting a substantial part of its assets is issued
                against it, or if MPHTC assigns its assets for the benefit of
                its creditors;
              </Text>
            </View>
            <View style={[styles.text, styles.row]}>
              <Text>(iii)</Text>
              <Text style={styles.margin}>
                in case of Force Majeure as provided in Section 12 of this
                Agreement;
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.list}>
          <Text style={styles.text}>(a) By MPHTC:</Text>
          <View style={styles.list}>
            <View style={[styles.text, styles.row]}>
              <Text>(i)</Text>
              <Text style={styles.margin}>
                in case the services of the Physician be found unsatisfactory on
                the basis of general professional standards within thirty (30)
                days from receipt of written notice from MPHTC, including, but
                not limited to, the Physician committing five (5) consecutive no
                shows as provided in Section 8;
              </Text>
            </View>
            <View style={[styles.text, styles.row]}>
              <Text>(ii)</Text>
              <Text style={styles.margin}>
                in case the Physician is permanently disabled or incapacitated
                to perform his services;
              </Text>
            </View>
            <View style={[styles.text, styles.row]}>
              <Text>(iii)</Text>
              <Text style={styles.margin}>
                in case the Physician commits a breach of any of its material
                obligations under this Agreement (other than (i) above), which
                breach is not remediable, or if remediable, is not cured by the
                Physician within thirty (30) days from receipt of written notice
                from MPHTC;
              </Text>
            </View>
            <View style={[styles.text, styles.row]}>
              <Text>(iv)</Text>
              <Text style={styles.margin}>
                in case the Physician is found guilty of medical malpractice
                and/or medical negligence, effective immediately;
              </Text>
            </View>
            <View style={[styles.text, styles.row]}>
              <Text>(v)</Text>
              <Text style={styles.margin}>
                in case of Force Majeure as provided in Section 12 of this
                Agreement;
              </Text>
            </View>
            <View style={[styles.text, styles.row]}>
              <Text>(vi)</Text>
              <Text style={styles.margin}>
                in case of the revocation or suspension of the Physician’s
                license to practice medicine for any reason, effective
                immediately, and for this purpose, the Physician undertakes to
                promptly inform MPHTC of any revocation or suspension of his/her
                license to practice medicine; or
              </Text>
            </View>
          </View>
        </View>
        <Text style={[styles.list, styles.text]}>
          (c) by mutual agreement of the Parties
        </Text>
        <Text style={[styles.text, styles.indent]}>
          Termination of hospital/clinic-acquired Physicians shall be governed
          by the definitive agreement between MPHTC and MPHTC Partners.
        </Text>
        <Text style={[styles.text]}>
          <Text style={[styles.bold, styles.indent]}>
            Section 13. Data Privacy.
          </Text>
          The Parties agree to comply with, and have adequate measures in place
          to ensure that its directors, officers, employees, and representatives
          comply at all times with: (a) the provisions and obligations contained
          in Republic Act No. 10173, otherwise known as the Data Privacy Act of
          the Philippines and its implementing rules and regulations with
          respect to any personal data, and (b) other applicable data privacy
          laws and regulations, as may be promulgated and/or amended from time
          to time. By entering into this Agreement, the Parties agree that any
          information exchanged among them may be collected, processed, shared
          and used but only (i) for the performance of the services, and (ii)
          for statistical, marketing, and scientific research purposes. Each
          Party warrants that all consents necessary to be obtained for the
          disclosure of personal data required under all relevant privacy laws
          and regulations have been obtained prior to any disclosure thereof,
          and agrees to hold the other Party free and harmless from any costs or
          liability arising from its failure to comply with the requirements of
          the Data Privacy Act of the Philippines.
        </Text>
        <Text style={[styles.text, styles.indent]}>
          By accepting the healthcare platform services of MPHTC, the Physician
          further agrees to be bound by the terms and conditions, and privacy
          policy of the Application in accordance with the Data Privacy Act of
          the Philippines.
        </Text>
        <Text style={[styles.text, styles.indent]}>
          The Parties shall likewise abide by the terms of the data sharing
          agreement provided in Schedule B, which shall form an integral part of
          this Agreement.
        </Text>
        <Text style={[styles.text, styles.bold, styles.indent]}>
          Section 14. Force Majeure
        </Text>
        <View style={styles.list}>
          <View style={[styles.row, styles.text]}>
            <Text>(a)</Text>
            <Text style={styles.margin}>
              Neither party shall be considered in breach of this Agreement to
              the extent that performance of their respective obligations
              (excluding payment obligations) is prevented by an Event of Force
              Majeure that arises after the commencement date. The Party
              (“Affected Party”) prevented from carrying out its obligations
              hereunder shall give notice to the other Party of an Event of
              Force Majeure upon it being foreseen by, or becoming known to, the
              Affected Party.
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>(b)</Text>
            <Text style={styles.margin}>
              If and to the extent that a Party is prevented from providing the
              Services by the Event of Force Majeure, while such Party is so
              prevented, it shall be relieved of its obligations to provide the
              services but shall endeavor to continue to perform its obligations
              as far as reasonably practicable. Should the Party incur
              additional costs in so doing, such Party shall be entitled to the
              amount of such actual, reasonable and documented costs incurred
              provided notice shall as much as practicable be given to the other
              Party prior to incurring such cost.
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>(c)</Text>
            <Text style={styles.margin}>
              In case the Event of Force Majeure prevents a Party from rendering
              the Services or any material portion thereof for a continuous
              period of sixty (60) days, the Parties may terminate this
              Agreement.
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>(d)</Text>
            <Text style={styles.margin}>
              For this purpose, the term “Event of Force Majeure” means an event
              beyond the control of either Party, which prevents a party from
              complying with its obligations
            </Text>
          </View>
        </View>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>
            Section 15. Indemnification.
          </Text>{" "}
          The Physician undertakes to indemnify, defend and hold harmless MPHTC
          and its officers, directors, shareholders, employees and agents (the
          “MPHTC Representatives”) from and against any and all demands, claims,
          complaints, actions or causes of action, suits, proceedings,
          investigations, arbitrations, assessments, losses, damages,
          liabilities, costs and expenses (including, but not limited to,
          interest, penalties and reasonable attorneys’ fees and disbursements),
          asserted against, imposed upon or incurred by MPHTC, by reason of or
          resulting from material breach or gross negligence by the Physician
          with or of any covenants, agreements or undertakings of the Physician
          contained in, or made pursuant to, this Agreement.
        </Text>
        <Text style={[styles.text, styles.indent]}>
          Without prejudice to the generality of the above, the Physician shall
          indemnify, defend and save harmless MPHTC and the MPHTC
          Representatives from any loss, damages, costs, charges, expenses and
          injury that MPHTC may suffer due to the Physician’s acts, including
          the following acts:
        </Text>
        <View style={styles.list}>
          <View style={[styles.row, styles.text]}>
            <Text>(i)</Text>
            <Text style={styles.margin}>
              any wrong medication or treatment, or any medical negligence on
              part of the Physician;
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>(ii)</Text>
            <Text style={styles.margin}>
              any misrepresentations, misconduct or inappropriate behavior by
              the Physician;
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>(iii)</Text>
            <Text style={styles.margin}>
              cancellation or rescheduling of booked appointment or any variance
              in the fees charged; and;
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>(iv)</Text>
            <Text style={styles.margin}>
              any direct or indirect medical eventualities that might occur
              subsequent to the provision of services by the Physician to the
              Users through the Application
            </Text>
          </View>
        </View>
        <Text style={[styles.text, styles.bold, styles.indent]}>
          Section 16. Miscellaneous.
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>
            (a) Entire Agreement.{" "}
          </Text>
          This Agreement constitutes the entire agreement and understanding
          between the Parties and supersedes all previous agreements,
          understandings and undertakings.
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>(b) Governing Law. </Text>
          This Agreement shall be governed, interpreted and construed in
          accordance with the laws of the Philippines.
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>
            (c) Dispute Resolution.{" "}
          </Text>
          Any dispute, controversy, difference or claim arising out of or
          relating to this contract, including the existence, validity,
          interpretation, performance, breach or termination thereof or any
          dispute regarding non-contractual obligations arising out of or
          relating to it (<Text style={styles.bold}>“Dispute”</Text>) shall be
          settled, to the extent possible, through amicable negotiations or
          discussions of the Parties senior executive officers.
        </Text>
        <Text style={[styles.text, styles.indent]}>
          In case the Dispute cannot be resolved in this manner within thirty
          (30) days from referral of the same for settlement, the Dispute shall
          be resolved by arbitration in accordance with the rules of the
          Philippine Dispute Resolution, Inc. (“
          <Text style={styles.bold}>PDRCI Rules</Text>”) in force when the
          notice of arbitration is submitted. Any award rendered by the
          arbitrator(s) shall be final and binding upon the Parties, and
          judgment upon the award rendered may be entered in any court having
          jurisdiction thereof or having jurisdiction over the Parties or their
          assets. The seat of arbitration shall be in Makati City. The
          proceedings shall be conducted by three (3) arbitrators, with each
          Party appointing an arbitrator, and such appointed arbitrators
          selecting the third. The arbitration proceedings shall be conducted in
          the English language.
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>(d) No Waiver. </Text>
          No waiver of any breach of these provisions will be effective unless
          such waiver is in writing and signed by the Party against whom such
          waiver is claimed. No waiver of any breach shall be deemed to be a
          waiver of any other or subsequent breach.
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>(e) Amendment </Text>
          No amendment, modifications, waiver, change or addition hereto shall
          be effective and binding on any of the Parties hereto unless the same
          is in writing and signed by the Parties.
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>
            (f) Relation of the Parties.{" "}
          </Text>
          Unless otherwise agreed, no Party shall be deemed to be a
          representative, an agent or an employee of any other Party or to have
          any fiduciary obligations toward any other Party. Unless otherwise
          expressly specified herein, no Party shall have any authority or right
          to bind any other Party in any respect, without the specific prior
          written authorization of the other Party.
        </Text>
        <Text style={styles.text}>
          <Text style={[styles.bold, styles.indent]}>(g) Counterparts. </Text>
          This Agreement may be executed in counterparts, which when taken
          together, shall constitute one instrument.
        </Text>
        <View style={{ paddingRight: 50 }}>
          <Text style={[styles.text, styles.bold, styles.indent]}>
            METROPACIFIC HEALTH TECH
          </Text>
          <Text
            style={[styles.text, styles.bold, styles.indent, { marginTop: 0 }]}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CORPORATION
          </Text>
          <Text style={styles.text}>By:</Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ width: "20%" }}>
              <Text style={[styles.text, styles.indent]}>
                _____________________
              </Text>
            </View>
            <View style={{ width: "30%" }}>
              <View
                style={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {file_signature_path.url && (
                  <Image style={{...styles.image, margin: "0 auto"}} src={file_signature_path.url} />
                )}
                <Text style={{ ...styles.text, marginTop: 0 }}>
                  _____________________
                </Text>
                <Text style={[styles.text, styles.right, { marginTop: 0 }]}>
                  {`${first_name} ${middle_name} ${last_name}`}
                </Text>
                <Text style={[styles.text, styles.right, { marginTop: 0 }]}>
                  Physician
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
      <Page style={styles.page}>
        <Text style={[styles.text, styles.bold, styles.center]}>
          SCHEDULE A
        </Text>
        <Text style={[styles.text, styles.bold, styles.center]}>
          SCHEDULE OF PAYMENT
        </Text>
        <Text style={styles.text}>
          The Physician shall be paid a professional fee net of MPHTC’s
          commission every 15th and 30th calendar day. Payment shall be
          deposited in the bank provided in the Application. In the event a User
          cancels the scheduled appointment with the Physician within six (6)
          hours before the appointment, the Physician shall be entitled to fifty
          percent (50%) of his professional fee.
        </Text>
      </Page>
      <Page style={styles.page}>
        <Text style={[styles.text, styles.bold, styles.center]}>
          SCHEDULE B
        </Text>
        <Text style={[styles.text, styles.bold, styles.center]}>
          DATA SHARING AGREEMENT
        </Text>
        <Text style={[styles.text, styles.indent]}>
          This Data Sharing Agreement (the{" "}
          <Text style={styles.bold}>“Agreement”</Text>) is executed and entered
          this <Text style={styles.underline}>{moment().format("MMMM")}</Text>
          &nbsp; day of &nbsp;{" "}
          <Text style={styles.underline}>
            {moment().format("DD")}
          </Text>&nbsp; <Text>{moment().format("YYYY")}</Text> in __________, by
          and between:
        </Text>
        <View style={{ paddingLeft: 45, paddingRight: 45 }}>
          <Text style={[styles.text]}>
            <Text style={styles.bold}>
              METRO PACIFIC HEALTH TECH CORPORATION
            </Text>
            , a corporation existing under and by virtue of the laws of the
            Philippines, with principal offices at 10/F MGO Bldg., Legaspi cor.
            Dela Rosa St. Legaspi Village, Makati, represented herein by its
            President, Jose Ma. K. Lim (hereinafter referred to as
            <Text style={styles.bold}>“MPHTC”</Text>);
          </Text>
          <Text style={[styles.text, styles.center]}>and</Text>
          <Text style={[styles.text]}>
            <Text
              style={styles.underline}
            >{`${first_name} ${middle_name} ${last_name}`}</Text>
            , Filipino, of legal age, with office address at{" "}
            <Text
              style={styles.underline}
            >{`${house_number} ${street_name} ${barangay} ${city_municipality} ${province} ${zip_code}`}</Text>{" "}
            (hereinafter referred to as{" "}
            <Text style={styles.bold}>"PHYSICIAN"</Text>).
          </Text>
          <Text style={[styles.text]}>
            Each <Text style={styles.bold}>MPHTC</Text> and the{" "}
            <Text style={styles.bold}>PHYSICIAN</Text> shall be individually
            referred to as a <Text style={styles.bold}>"Party"</Text>
            and collectively as the <Text style={styles.bold}>"Parties"</Text>.
          </Text>
        </View>
        <Text style={styles.text}>RECITALS:</Text>
        <View style={styles.list}>
          <View style={[styles.list, styles.row, styles.text]}>
            <Text>A.</Text>
            <Text style={styles.margin}>
              MPHTC and the PHYSICIAN intend to share Personal Information and
              Sensitive Personal Information which is owned, collected, and
              processed by MPHTC from its customers (the{" "}
              <Text style={styles.bold}>“Data Subjects”</Text>
              ).
            </Text>
          </View>
          <View style={[styles.list, styles.row, styles.text]}>
            <Text>B.</Text>
            <Text style={styles.margin}>
              The Parties are aware of Republic Act No. 10173, otherwise known
              as the “Data Privacy Act of 2012 (the{" "}
              <Text style={styles.bold}>“Data Privacy Act”</Text>
              ), and understand the need to comply with the requirements of the
              same, including the execution of a data sharing agreement. ).
            </Text>
          </View>
          <View style={[styles.list, styles.row, styles.text]}>
            <Text>C.</Text>
            <Text style={styles.margin}>
              The Parties have agreed that the further processing, access,
              safekeeping, sharing, transfer, and disposal of any Personal
              Information and/or Sensitive Personal Information collected from
              the Data Subjects shall be governed by terms of this Agreement and
              all applicable laws.
            </Text>
          </View>
        </View>
        <Text style={[styles.text, styles.indent]}>
          NOW, THEREFORE, for and in consideration of the foregoing premises and
          the provisions hereinafter contained, the parties to this Agreement
          hereby agree, as follows:
        </Text>
        <Text style={[styles.text, styles.bold]}>1. DEFINITION OF TERMS</Text>
        <Text style={styles.text}>
          As used herein, the following terms shall have the following meanings:
        </Text>
        <Text style={styles.text}>
          <Text style={styles.bold}>“Agreement”</Text> shall have the meaning
          ascribed to it in the preamble of this Agreement.
        </Text>
        <Text style={styles.text}>
          <Text style={styles.bold}>"Data”</Text> shall have the meaning
          ascribed to it in Section 2 of this Agreement.
        </Text>
        <Text style={styles.text}>
          <Text style={styles.bold}>“Data Privacy Act”</Text> shall refer to
          Republic Act No. 10173, otherwise known as the “Data Privacy Act of
          2012, approved on 15 August 2012, and subsequent amendments thereto.
        </Text>
        <Text style={styles.text}>
          <Text style={styles.bold}>“Data Subject”</Text> shall refer to MPHTC’s
          customers whose personal information is collected and processed.
        </Text>
        <Text style={styles.text}>
          <Text style={styles.bold}>“NPC”</Text> shall refer to the National
          Privacy Commission of the Philippines.
        </Text>
        <Text style={styles.text}>
          <Text style={styles.bold}>“Party”</Text> or{" "}
          <Text style={styles.bold}>“Parties”</Text> shall have the meaning
          ascribed to it in the preamble of this Agreement.
        </Text>
        <Text style={styles.text}>
          <Text style={styles.bold}>“Personal Data Breach”</Text> shall refer to
          a breach of security leading to the accidental or unlawful
          destruction, loss, alteration, unauthorized disclosure of, or access
          to Personal Information and/or Sensitive Personal Information
          transmitted, stored, or otherwise processed.
        </Text>
        <Text style={styles.text}>
          <Text style={styles.bold}>“Personal Information”</Text> shallrefer to
          any information whether recorded in a material form or not, from which
          the identity of an individual is apparent or can be reasonably and
          directly ascertained by the entity holding the information, or when
          put together with other information would directly and certainly
          identify an individual.
        </Text>
        <Text style={styles.text}>
          <Text style={styles.bold}>“Processing”</Text> shall refer to any
          operation or any set of operations performed upon personal information
          including, but not limited to, the collection, recording,
          organization, storage, updating or modification, retrieval,
          consultation, use, consolidation, blocking, erasure, or destruction of
          data.
        </Text>
        <Text style={styles.text}>
          <Text style={styles.bold}>“Sensitive Personal Information”</Text>{" "}
          shall refer to personal information: (a) about an individual’s race,
          ethnic origin, marital status, age, color, and religious,
          philosophical, or political affiliations; (b) about an individual’s
          health, education, genetic or sexual life of a person, or to any
          proceeding for any offense committed or alleged to have been committed
          by such individual, the disposal of such proceedings, or the sentence
          of any court in such proceedings; (c) issued by government agencies
          peculiar to an individual which includes, but is not limited to,
          social security numbers, previous or current health records, licenses
          or its denials, suspension or revocation, and tax returns; and (d)
          specifically established by an executive order or an act of Congress
          to be kept classified.
        </Text>
        <Text style={[styles.text, styles.bold]}>2. COVERAGE</Text>
        <Text style={styles.text}>
          The Parties agree that the Personal Information and/or Sensitive
          Personal Information of the Data Subjects, subject of this Agreement,
          that is owned, collected, and otherwise processed by MPHTC from the
          Data Subjects shall be limited to the following (collectively referred
          to as the <Text style={styles.bold}>“Data”</Text>):
        </Text>
        <View style={[styles.list, styles.text]}>
          <Text>a. Name;</Text>
          <Text>b. Age;</Text>
          <Text>c. Gender;</Text>
          <Text>d. Medical information and medical history;</Text>
          <Text>e. Major health complaint/s;</Text>
          <Text>f. Vital signs;</Text>
          <Text>
            g. Result of x-ray, scans, laboratory tests and other diagnostic
            procedures; and
          </Text>
          <Text>
            h. Other information which is necessary for accurate diagnosis of
            users of MPHTC’s mobile application.
          </Text>
        </View>
      </Page>
      <Page style={styles.page}>
        <Text style={[styles.text, styles.bold]}>
          3. DUTIES AND OBLIGATIONS OF THE PARTIES
        </Text>
        <View style={[styles.row]}>
          <Text style={styles.text}>3.1 </Text>
          <View>
            <Text style={styles.text}>
              MPHTC shall share with, or otherwise to transfer or transmit to
              the PHYSICIAN the Data described in Section 2 of this Agreement.
            </Text>

            <Text style={styles.text}>
              The Data collected, processed and shared pursuant to this
              Agreement shall be processed and shared exclusively for the
              purpose of the performance of the services, unless otherwise
              provided by applicable laws, rules, regulations, or relevant
              issuances by the National Privacy Commission of the Philippines (
              <Text style={styles.bold}>“NPC”</Text>) or any separate agreement
              with the Data Subject.
            </Text>

            <Text style={styles.text}>
              The Data may be accessed through a database dashboard by the
              employees and authorized representatives of both Parties to render
              the services to the Data Subjects. Authorized personnel with
              access to the Data shall verify identity using a secure encrypted
              link and multi-level authentication.
            </Text>
          </View>
        </View>
        <Text style={styles.text}>3.2. The PHYSICIAN shall:</Text>
        <View style={styles.list}>
          <View style={[styles.row, styles.text]}>
            <Text>a.</Text>
            <Text>
              Unless otherwise provided by law, not disclose, trade, sell, or
              transfer any Data to any person or entity without the written
              instructions of MPHTC;
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>b.</Text>
            <Text>
              Not use or process the Data, except in connection with the
              purposes as provided under this Agreement;
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>c.</Text>
            <Text>
              As applicable, allow Data Subjects to access and correct the Data
              in its custody and to exercise their rights as provided under the
              Data Privacy Act, its IRR and relevant issuances of the NPC;and
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>d.</Text>
            <Text>
              Notify MPHTC, if through mistake or negligence, information not
              covered by this Agreement was sent to it by MPHTC.
            </Text>
          </View>
        </View>
        <Text style={[styles.text, styles.bold]}>4. MODE OF DATA SHARING</Text>
        <Text style={styles.text}>
          The Data shall be shared between MPHTC and the PHYSICIAN through a
          database dashboard using a secure email facility with encryption of
          the data or other means of preventing unauthorized access, including
          any or all attachments. Facsimile technology shall not be used for
          transmitting documents containing personal data.
        </Text>
      </Page>
      <Page style={styles.page}>
        <Text style={[styles.text, styles.bold]}>
          5. REPRESENTATIONS AND WARRANTIES
        </Text>
        <Text style={styles.text}>
          5.1. Each of MPHTC and the PHYSICIAN represent and warrant that:
        </Text>
        <View styles={{ paddingLeft: 15 }}>
          <View style={[styles.row, styles.text]}>
            <Text>a. </Text>
            <Text>
              The collection and processing of the Data under this Agreement
              adheres to the principles of transparency, legitimate purpose, and
              proportionality.
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>b. </Text>
            <Text>
              It shall fully comply with the provisions of this Agreement, the
              Data Privacy Act and its IRR, and all other issuances of the NPC.
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>c. </Text>
            <Text>
              This Agreement shall be made available for the review of the NPC
              in cases required under the Data Privacy Act.
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>d. </Text>
            <Text>
              The rights of the Data Subject shall be upheld without
              compromising research integrity. Personal Data collected from
              parties other than the Data Subjects for the purpose of research
              was either publicly available or obtained through the consent of
              the Data Subject.
            </Text>
          </View>
        </View>
        <Text style={styles.text}>5.2.MPHTC represents and warrants that:</Text>
        <View style={{ paddingLeft: 30 }}>
          <View style={[styles.row, styles.text]}>
            <Text>a.</Text>
            <Text>
              Prior to collecting the Data, or prior to the sharing of Data, it
              shall secure the consent of the Data Subject with respect to the
              collection and further processing of the Data and the sharing,
              storage, transfer, and transmission of the same in accordance with
              this Agreement. It shall secure the Data Subject’s consent through
              written, electronic, or recorded means.
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>b.</Text>
            <Text>
              It has provided the following information to the Data Subjects
              prior to collection or sharing of the Data:
            </Text>
          </View>
          <View style={{ paddingLeft: 15 }}>
            <Text style={styles.text}>
              (i) &nbsp;&nbsp;&nbsp; Identity of the third parties that will be
              given access to the Data;
            </Text>
            <Text style={styles.text}>
              (ii) &nbsp;&nbsp;&nbsp; Purpose of the data sharing;
            </Text>
            <Text style={styles.text}>
              (iii) &nbsp;&nbsp;&nbsp; Categories of Data concerned;
            </Text>
            <Text style={styles.text}>
              (iv) &nbsp;&nbsp;&nbsp; Intended recipients or categories of
              recipients of the Data;
            </Text>
            <Text style={styles.text}>
              (v) &nbsp;&nbsp;&nbsp; Existence of the rights of the Data
              Subjects, including the right to access and to correct the Data,
              and the right to object to data sharing or further processing;
            </Text>
            <Text style={styles.text}>
              (vi) &nbsp;&nbsp;&nbsp; If applicable, the automated processing of
              the Data for profiling; and
            </Text>
            <Text style={styles.text}>
              (vii) &nbsp;&nbsp;&nbsp; Other information that would sufficiently
              notify the Data Subject of the nature and extent of data sharing
              and the manner of processing.6.
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>c.</Text>
            <Text>
              This Agreement shall be made available for the review of the NPC
              in cases required under the Data Privacy Act.
            </Text>
          </View>
          <View style={[styles.row, styles.text]}>
            <Text>d.</Text>
            <Text>
              The rights of the Data Subject shall be upheld without
              compromising research integrity. Personal Data collected from
              parties other than the Data Subjects for the purpose of research
              was either publicly available or obtained through the consent of
              the Data Subject.
            </Text>
          </View>
        </View>
        <Text style={[styles.text, styles.bold]}>6. SECURITY MEASURES</Text>
        <Text style={styles.text}>
          Each Party, as a personal information controller, shall implement
          reasonable and appropriate physical, technical, and organizational
          measures for the protection of personal data. Security measures aim to
          maintain the availability, integrity, and confidentiality of personal
          data and protect them against natural dangers such as accidental loss
          or destruction, and human dangers such as unlawful access, fraudulent
          misuse, unlawful destruction, alteration, and contamination.
        </Text>
        <Text style={[styles.text, styles.bold]}>7. PERSONAL DATA BREACH</Text>
        <Text style={styles.text}>
          In the event of a Personal Data Breach involving the Data while the
          same is being processed by or is in the custody or control of the
          PHYSICIAN, the PHYSICIAN shall notify MPHTC in writing within six (6)
          hours upon knowledge of, or when there is reasonable belief by the
          PHYSICIAN that a Personal Data Breach has occurred.
        </Text>
        <Text style={styles.text}>
          The notification shall describe the nature of the breach, the Personal
          Information and/or Sensitive Personal Information possibly involved,
          the list of Data Subjects involved, the possible risks, harm, or
          damage to the Data Subjects, and the measures taken by the PHYSICIAN
          to address the breach. The notification shall also state whether the
          PHYSICIAN shall need to notify the NPC and the Data Subjects of the
          breach andshall attach a draft of the notification for MPHTC’s
          comment.
        </Text>
        <Text style={[styles.text, styles.bold]}>8. TERM</Text>
        <Text style={styles.text}>
          This Agreement shall be effective on the date of its execution. This
          Agreement shall be coterminous with the Memorandum of Agreement
          between MPHTC and the PHYSICIAN. This Agreement may be renewed if the
          purposes of this Agreement subsists. The term of this Agreement or any
          subsequent extension thereof shall in no case be for more than five
          (5) years, without prejudice to a new data sharing agreement that may
          be entered into by the Parties.
        </Text>
        <Text style={[styles.text, styles.bold]}>
          9. DESTRUCTION OR DISPOSAL OF THE SHARED DATA
        </Text>
        <Text style={styles.text}>
          Upon the termination of this Agreement, or upon MPHTC’s request, the
          PHYSICIAN shall promptly destroy, dispose, or surrender to MPHTC, as
          applicable, the Data and any other property, information, and
          documents containing the Data and/or copies thereof in the PHYSICIAN’s
          possession, custody, or control. If requested by MPHTC, the PHYSICIAN
          shall deliver to MPHTC a certificate confirming the PHYSICIAN’s
          compliance with the obligation to destroy, dispose, surrender, or
          return under this Section.
        </Text>
        <Text style={[styles.text, styles.bold]}>
          10. INDEMNITY AND LIABILITY
        </Text>
        <Text style={styles.text}>
          The PHYSICIAN shall indemnify, defend and/or settle, and hold harmless
          MPHTC, its directors, officers, employees, agents, and
          representatives, from and against any and all actual, reasonable, and
          documented assessments, claims, demands or suits, liabilities,
          damages, and obligations (including, without limitation, reasonable
          attorneys’ fees) resulting from or arising out of any breach by the
          PHYSICIAN of its obligations, duties, representations, or warranties
          under this Agreement; provided, however that the indemnity in this
          Section shall not apply to any loss, claim, damage, or liability
          caused by the negligence or wilful misconduct of the PHYSICIAN.
        </Text>
      </Page>
      <Page style={styles.page}>
        <Text style={[styles.text, styles.bold]}>
          11. RIGHTS OF A DATA SUBJECT
        </Text>
        <Text style={styles.text}>
          As applicable, each of MPHTC and the PHYSICIAN shall ensure that the
          following rights of the Data Subjects are upheld:
        </Text>
        <Text style={{ ...styles.text, paddingLeft: 15 }}>
          a. &nbsp;&nbsp; Availability of the Agreement
        </Text>
        <Text style={styles.text}>
          A copy of this Agreement may be accessed by a Data Subject through
          email upon written request, provided that the Parties have a right to
          prevent the disclosure of any detail or information that could
          endanger the Security Measures set out in Section 6 of this Agreement
          or expose to harm the integrity, availability, or confidentiality of
          Data under its control or custody.
        </Text>
        <Text style={{ ...styles.text, paddingLeft: 15 }}>
          b. &nbsp;&nbsp; Access to Information
        </Text>
        <Text style={styles.text}>
          Upon the request of a Data Subject, the concerned Party may release to
          the requesting Data Subject the following information with respect to
          his Data:
        </Text>
        <View style={[styles.list, styles.text]}>
          <Text>(i) &nbsp; Contents of his/her Data that were processed;</Text>
          <Text>
            (ii) &nbsp; Sources from which his/her Data were obtained;
          </Text>
          <Text>
            (iii) &nbsp; Names and addresses of the recipients of his/her Data;
          </Text>
          <Text>(iv) &nbsp; Manner by which his/her Data was processed;</Text>
          <Text>
            (v) &nbsp; Reasons for the disclosure of his/her Data to recipients,
            if any;
          </Text>
          <Text>
            (vi) &nbsp; Date when his/her Data was last accessed or modified;
            and
          </Text>
          <Text>
            (vii) &nbsp; The identity and address of the owner of the Data.
          </Text>
        </View>
        <Text style={{ ...styles.text, paddingLeft: 15 }}>
          c. &nbsp; Correction of Data
        </Text>
        <Text style={styles.text}>
          The concerned Party may provide to the Data Subject the means and
          methods whereby the Data Subject may dispute the inaccuracy or error
          in the Data and correct the Data immediately and accordingly, unless
          the request is vexatious or otherwise unreasonable. The concerned
          Party shall inform the other Party of thecorrection or rectification.
        </Text>
        <Text style={{ ...styles.text, paddingLeft: 15 }}>
          d. &nbsp; Deletion of Data
        </Text>
        <Text style={styles.text}>
          The concerned Party may provide to the Data Subject the means and
          methods whereby the Data Subject may order the blocking, removal, or
          destruction of his/her Data.
        </Text>
        <Text style={{ ...styles.text, paddingLeft: 15 }}>
          export. &nbsp; Remedies
        </Text>
        <Text style={styles.text}>
          The concerned Party may provide to the Data Subject the means and
          methods whereby the Data Subject may report any security breach or
          violation of his rights with respect to the Processing of his/her
          Data, as well as the procedures whereby such reports are timely and
          adequately addressed.
        </Text>
        <Text style={[styles.text, styles.bold]}>12. AMENDMENTS</Text>
        <Text style={styles.text}>
          This Agreement cannot be modified, amended, or changed except in
          writing and signed by the Parties.
        </Text>
      </Page>
      <Page style={styles.page}>
        <Text style={[styles.text, styles.bold]}>13. SEVERABILITY</Text>
        <Text style={styles.text}>
          In case any one or more of the provisions contained in this Agreement
          shall be invalid, illegal, or unenforceable in any respect, the
          validity, legality, or enforceability of the remaining provisions
          herein and therein shall not in any way be affected or impaired
          hereby.
        </Text>
        <Text style={[styles.text, styles.bold]}>14. ASSIGNMENT</Text>
        <Text style={styles.text}>
          Neither the rights nor the obligations of either party may be assigned
          or delegated in whole or in part without the prior written consent of
          the other party unless otherwise expressly permitted under the
          Agreement. Any delegation without written permission shall be null and
          void and of no effect unless otherwise expressly permitted under the
          Agreement.
        </Text>
        <Text style={[styles.text, styles.bold]}>15. GOVERNING LAW</Text>
        <Text style={styles.text}>
          This Agreement and any dispute or claim arising out of or in
          connection with it (including any non-contractual disputes or claims
          obligations arising out of or in connection with the same) shall be
          governed by and construed in accordance with the laws of the
          Philippines. The parties hereby submit irrevocably to the exclusive
          jurisdiction of the Philippine Courts in respect of any claim,
          dispute, or difference concerning this Agreement or any matter arising
          therefrom.
        </Text>
        <Text style={[styles.text, styles.center]}>
          [Signature page follows.]
        </Text>
        <Text style={styles.text}>
          IN WITNESS WHEREOF, the Parties, acting through their representatives,
          have caused this Data Sharing Agreement to be duly executed at
          ___________, this{" "}
          <Text style={styles.underline}>{moment().format("MMMM")}</Text>
          &nbsp; day of &nbsp;
          <Text style={styles.underline}>{moment().format("DD")}</Text>&nbsp;
          <Text>{moment().format("YYYY")}</Text>.
        </Text>

        <Text style={[styles.text, styles.bold]}>
          METRO PACIFIC HEALTH TECH &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Text>
        <Text style={[styles.text, styles.bold, { marginTop: 0 }]}>
          CORPORATION
        </Text>
        <View
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Text style={{ ...styles.text }}>By:</Text>
          <View
            style={{
              marginTop: -30,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "150%",
            }}
          >
            {file_signature_path.url && (
              <Image style={{...styles.image, margin: "0 auto"}} src={file_signature_path.url} />
            )}
            <Text style={{ ...styles.text, marginTop: 0 }}>
              _____________________
            </Text>
            <Text style={{ ...styles.text, marginTop: 0 }}>
              [{`${first_name} ${middle_name} ${last_name}`}]
            </Text>
          </View>
        </View>

        <Text style={[styles.text, styles.center, { marginTop: 40 }]}>
          SIGNED IN THE PRESENCE OF:
        </Text>
        <View
          style={{
            ...styles.text,
            marginTop: 50,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              marginTop: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              float: "left",
            }}
          >
            <Text style={styles.text}>
              &nbsp;&nbsp;&nbsp;&nbsp;_____________________&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
          </View>
          <View
            style={{
              marginTop: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Text style={styles.text}>
              &nbsp;&nbsp;&nbsp;&nbsp;_____________________&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
          </View>
        </View>
      </Page>
      <Page style={styles.page}>
        <Text style={styles.text}>
          Republic of the Philippines &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
        </Text>
        <Text style={styles.text}>
          ________ City)
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;S.S.
        </Text>

        <Text style={[styles.text, styles.bold, styles.center]}>
          ACKNOWLEDGMENT
        </Text>
        <Text style={styles.text}>
          BEFORE ME, a Notary Public for and in _____________, this
          _______________ personally appeared the following:
        </Text>
        <View style={[styles.row, styles.tableRow, { marginTop: 20 }]}>
          <View style={[styles.column, { borderRight: "1px solid black" }]}>
            <Text style={[styles.text, styles.td, { paddingBottom: 19 }]}>
              Name
            </Text>
            <View
              style={[
                styles.text,
                styles.column,
                styles.td,
                {
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                },
              ]}
            >
              <Text
                style={[
                  styles.text,
                  styles.bold,
                  { marginTop: 0, paddingRight: 20 },
                ]}
              >
                METRO PACIFIC HEALTH
              </Text>
              <Text style={[styles.text, styles.bold, { marginTop: 0 }]}>
                TECH CORPORATION
              </Text>
              <Text style={{ ...styles.text, marginTop: 0, paddingBottom: 50 }}>
                By:
              </Text>
            </View>
            <Text style={[styles.text, styles.td]}>[Name]</Text>
          </View>
          <View style={[styles.column]}>
            <View
              style={[
                styles.text,
                styles.td,
                {
                  paddingRight: 20,
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                },
              ]}
            >
              <Text>Community Tax Certificate No. /</Text>
              <Text>Passport No</Text>
            </View>
            <View
              style={[
                styles.text,
                styles.td,
                {
                  borderBottom: "1px solid black",
                  paddingBottom: 98,
                  width: "100%",
                },
              ]}
            ></View>
            <Text style={[styles.text, styles.td]}></Text>
          </View>
          <View style={[styles.column, { paddingRight: 10 }]}>
            <Text
              style={[
                styles.text,
                styles.td,
                {
                  borderBottom: "1px solid black",
                  paddingBottom: 19,
                  width: "100%",
                },
              ]}
            >
              Date / Place Issued
            </Text>
            <View
              style={[
                styles.text,
                styles.td,
                {
                  borderLeft: "1px solid black",
                  borderBottom: "1px solid black",
                  paddingBottom: 98,
                },
              ]}
            ></View>
            <Text
              style={[
                styles.text,
                styles.td,
                {
                  borderLeft: "1px solid black",
                  paddingBottom: 20,
                },
              ]}
            ></Text>
          </View>
        </View>
        <Text style={styles.text}>
          All known to me, and to me known to be the same persons who executed
          the foregoing Data Sharing Agreement, and they acknowledged to me that
          the same is their free and voluntary act and deed as well as that of
          the corporations herein represented.
        </Text>
        <Text style={styles.text}>
          The foregoing instrument pertains to a Data Sharing Agreement and
          consists of [●] ([●]) pages, including the page on which this
          Acknowledgment is written, and is signed by the parties and their
          material witnesses on each and every page thereof.
        </Text>
        <Text style={styles.text}>
          WITNESS MY HAND AND SEAL on the date and place first above-written.
        </Text>
        <Text style={styles.text}>Doc. No. &nbsp;&nbsp;&nbsp;____;</Text>
        <Text style={styles.text}>Page No. &nbsp;&nbsp;&nbsp;____;</Text>
        <Text style={styles.text}>Book No. &nbsp;&nbsp;&nbsp;____;</Text>
        <Text style={styles.text}>Series of &nbsp;&nbsp;&nbsp;____;</Text>
      </Page>
      <Page style={styles.page}>
        <Text style={styles.heading}>ANNEX A</Text>
        <Text style={styles.heading}>SERVICE LEVEL INDICATORS</Text>
        <Text style={[styles.text, styles.bold]}>I. Training</Text>
        <Text style={styles.text}>
          PHYSICIAN should complete the MPHTC training module before account is
          activated.
        </Text>
        <Text style={[styles.text, styles.bold]}>
          II. Technical requirements
        </Text>
        <Text style={styles.text}>
          The PHYSICIAN shall comply, and continue to comply during the duration
          of this Agreement, with the following technical requirements:
        </Text>
        <View style={[styles.list, styles.text]}>
          <Text>a. &nbsp;&nbsp;minimum internet speed of 2Mbps;</Text>
          <Text>
            b. &nbsp;&nbsp;access to a laptop, desktop, tablet, or a smartphone;
            and
          </Text>
          <Text>
            c. &nbsp;&nbsp;ability to access and use Google Chrome or Safari
            browser.
          </Text>
        </View>
        <Text style={[styles.text, styles.bold]}>III. Availability</Text>
        <Text style={styles.text}>
          The PHYSICIAN should be readily available during the scheduled agreed
          upon in Section 8 of the Agreement. In case the PHYSICIAN arrives late
          for at least three (3) consultations in a month, a warning shall be
          issued by MPHTC. If the PHYSICIAN is late for an appointment for the
          fourth time in a month, MPHTC may terminate this Agreement.
        </Text>
        <Text style={styles.text}>
          Failure to show up for five (5) consultations in a month shall also be
          a ground for termination of this Agreement.
        </Text>
        <Text style={[styles.text, styles.bold]}>
          IV. Consultation Guidelines
        </Text>
        <View style={styles.list}>
          <View style={[styles.text, styles.row]}>
            <Text>a.</Text>
            <Text>
              PHYSICIAN must be able to respond to the User’s queries within the
              allotted consultation timeslot;
            </Text>
          </View>
          <View style={[styles.text, styles.row]}>
            <Text>b. </Text>
            <Text>
              PHYSICIAN must ensure that s/he is performing the consultation in
              a conducive environment (e.g. well lit, minimal noise, etc.);
            </Text>
          </View>
          <View style={[styles.text, styles.row]}>
            <Text>c. </Text>
            <Text>
              PHYSICIAN must provide a thorough diagnosis as permitted by the
              telemedicine platform. When necessary, s/he has the ability to
              provide the following through the same platform:
            </Text>
          </View>

          <View style={[styles.list, styles.text]}>
            <Text>1. &nbsp;&nbsp;&nbsp; e-prescription</Text>
            <Text>2. &nbsp;&nbsp;&nbsp; e-laboratory request</Text>
            <Text>3. &nbsp;&nbsp;&nbsp; e-medical certificate</Text>
          </View>
          <View style={[styles.text, styles.row]}>
            <Text>d. </Text>
            <Text>
              When necessary, PHYSICIAN should always seek the expert medical
              advice/opinion of an appropriate specialist.
            </Text>
          </View>
          <View style={[styles.text, styles.row]}>
            <Text>e. </Text>
            <Text>
              PHYSICIAN should be upright, diligent, sober, modest and
              well-versed when speaking to a User.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    paddingLeft: 80,
    paddingRight: 80,
    paddingTop: 50,
    paddingBottom: 16,
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  tableRow: {
    borderWidth: 1,
    borderColor: "rgba(0,0,0)",
    borderStyle: "solid",
    alignItems: "flex-start",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  td: {
    padding: 5,
    minWidth: 100,
    alignSelf: "flex-start",
    marginTop: 0,
  },
  heading: {
    textTransform: "uppercase",
    textAlign: "center",
    fontFamily: "Times-Bold",
    fontSize: 14,
    display: "block",
  },
  text: {
    fontFamily: "Times-Roman",
    display: "block",
    marginTop: 15,
    fontSize: 12,
    lineHeight: 1.2,
  },
  image: {
    width: "100%",
    maxWidth: 100,
    height: "auto",
    display: "block",
  },
  bold: {
    fontFamily: "Times-Bold",
  },
  center: {
    textAlign: "center",
  },
  list: {
    paddingLeft: 30,
  },
  margin: {
    marginLeft: 20,
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  right: {
    textAlign: "right",
  },
  underline: {
    textDecoration: "underline",
  },
  indent: {
    textIndent: 40,
  },
});

export default MOAContent;
