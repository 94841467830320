import React from "react";
// import styled from "styled-components";
import { Row, Col } from "antd";
import Container from "../../ui/Container";
import ContainerLabelPrimary from "../../ui/ContainerLabelPrimary";
import ContainerLabelSecondary from "../../ui/ContainerLabelSecondary";
import LeaveStatistics from "./LeaveStatistics";
import LeaveRequestListNavigation from "./LeaveRequestListNavigation";
import LeaveApplicationForm from "./LeaveApplicationForm";

const LeaveRequest = () => {
  return (
    <>
      <Row gutter={[37, 8]}>
        <Col span={12}>
          <Container style={{ paddingBottom: 0, width: "100%" }}>
            <ContainerLabelSecondary level={5}>
              Leave Statistics
            </ContainerLabelSecondary>
            <LeaveStatistics />
          </Container>
        </Col>
        <Col span={12}>
          <Container style={{ paddingBottom: 40, width: "100%" }}>
            <ContainerLabelSecondary level={5}>
              Leave Request
            </ContainerLabelSecondary>
            <LeaveRequestListNavigation />
          </Container>
        </Col>
      </Row>
      <Container>
        <ContainerLabelPrimary level={5}>
          Leave Application Form
        </ContainerLabelPrimary>
        <LeaveApplicationForm />
      </Container>
    </>
  );
};

export default LeaveRequest;
