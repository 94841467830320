import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { Row, Col, Button, DatePicker, Input, Select, Typography } from "antd";
import styled from "styled-components";
import UI from "../../shared/Components/UI/js";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import DataTable from "../../ComponentsFC/DataTable";
import ServiceAttendanceApi from "../../api_services/Admin/AttendanceMgmtApi.js";
import FileDownload from "js-file-download";
import Loading from "../_components/Loading";

const DoctorAttendance = () => {
  const [tableData, setTableData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableEntries, setTableEntries] = useState(30);
  const [totalRecords, setTotalRecords] = useState();
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isScreenLoading, setIsScreenLoading] = useState();
  const [screenMsg, setIsScreenMsg] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isRangePickerOpen, setIsRangePickerOpen] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [appliedStartDate, setAppliedStartDate] = useState();
  const [appliedEndDate, setAppliedEndDate] = useState();
  const [shiftType, setShiftType] = useState();

  const getDoctorsAttendance = useCallback(async () => {
    try {
      let urlParams = {};

      if (currentPageNum) {
        urlParams.page = currentPageNum;
        urlParams.limit = tableEntries;
      }

      if (appliedStartDate && appliedEndDate) {
        urlParams.startDate = appliedStartDate;
        urlParams.endDate = appliedEndDate;
      }

      if (searchQuery) {
        urlParams.searchString = searchQuery;
      }

      if (shiftType) {
        urlParams.shiftType = shiftType;
      }

      setIsTableLoading(true);
      const apiResponse = await ServiceAttendanceApi.getDoctorsAttendance(
        urlParams
      );
      setIsTableLoading(false);
      // console.log(apiResponse);
      setTotalRecords(apiResponse.results.total);
      setTableData(apiResponse.data.map((obj) => ({ ...obj, key: obj._id })));
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }, [
    currentPageNum,
    tableEntries,
    appliedStartDate,
    appliedEndDate,
    shiftType,
    searchQuery,
  ]);

  useEffect(() => {
    getDoctorsAttendance();
  }, [getDoctorsAttendance]);

  const sortDataByNameAscDesc = (orderBy) => {
    let sortedData;
    const sortedDataByDoctorsName = tableData.sort((a, b) => {
      if (orderBy === "asc")
        //x2 performance vs localCompare() alpha/numeric sorting
        sortedData = new Intl.Collator("en", {
          numeric: true,
          sensitivity: "accent",
        }).compare(
          a.doctorID.name[0].given.join(" "),
          b.doctorID.name[0].given.join(" ")
        );
      //a.name[0].given.join(" ")
      else if (orderBy === "desc")
        sortedData = new Intl.Collator("en", {
          numeric: true,
          sensitivity: "accent",
        }).compare(
          b.doctorID.name[0].given.join(" "),
          a.doctorID.name[0].given.join(" ")
        );
      return sortedData;
    });
    setTableData(new Array(...sortedDataByDoctorsName));
  };

  const handleChangeFilterByShiftDate = (date, dateString) => {
    // setSingleDate(dateString);
    setStartDate(dateString);
    setEndDate(dateString);
  };

  const handleApplyShiftDateRange = (startDate, endDate) => {
    setAppliedStartDate(startDate);
    setAppliedEndDate(endDate);
  };
  const handleChangeFilterByShiftDateRange = (dates, dateStrings) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  const handleChangeFilterByShiftType = (shiftType) => {
    setShiftType(shiftType);
  };

  const handleClearViaSelectField = (fieldName) => {
    switch (fieldName) {
      case "Date Filter by":
        setIsDatePickerOpen(false);
        setIsRangePickerOpen(false);
        setAppliedStartDate("");
        setAppliedEndDate("");
        setSearchResults([]);
        break;

      case "Shift Type":
        setShiftType("");
        setSearchResults([]);
        break;

      default:
        setSearchResults([]);
    }
  };
  const handleChangeTableDataViaSelectOptions = (value, option) => {
    // Used for multiple prio filters
    // console.log("Sort Table Data VALUE", value);
    // console.log("Sort Table Data OPTION", option);

    if (option === undefined) return;

    if (option.title === "Doctor's Name") {
      if (option.value === "asc") sortDataByNameAscDesc("asc");
      if (option.value === "desc") sortDataByNameAscDesc("desc");
    }

    if (option.title === "Date Filter by") {
      if (option.value === "singleDate") {
        setSearchResults([]);
        setIsRangePickerOpen(false);
        setIsDatePickerOpen(true);
      }
      if (option.value === "range") {
        setSearchResults([]);
        setIsDatePickerOpen(false);
        setIsRangePickerOpen(true);
      }
    }

    if (option.title === "Shift Type") {
      if (option.value === "dayShift") handleChangeFilterByShiftType("day");
      if (option.value === "nightShift") handleChangeFilterByShiftType("night");
      if (option.value === "allShifts") handleChangeFilterByShiftType("");
    }
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePaginateTable = (antdSelectedPageNum) => {
    setCurrentPageNum(antdSelectedPageNum);
  };

  const tableColumnHeader = [
    {
      title: "Doctor Name",
      dataIndex: ["doctorDetails"],
      key: ["doctorDetails"],
      render: (text) => {
        if (text) {
          return `${text?.firstName} ${text?.lastName}`;
        } else {
          return "--";
        }
      },
    },
    {
      title: "Specialty",
      dataIndex: ["doctorDetails", "specialty"],
      key: ["doctorDetails", "specialty"],
      render: (text) => {
        if (text) {
          return text.join("\r,\n");
        } else {
          return "--";
        }
      },
      width: 150,
    },
    {
      title: "Status",
      dataIndex: ["doctorID", "encounter", "communication", "status"],
      key: ["doctorID", "encounter", "communication", "status"],
      render: (text) => <CellStyle status={text}>{text}</CellStyle>,
    },
    {
      title: "Shift Date",
      dataIndex: "shiftDate",
      key: "shiftDate",
      render: (date) => {
        if (date) {
          return date;
        } else {
          return "--";
        }
      },
    },
    {
      title: "Shift Day",
      dataIndex: "shiftDay",
      key: "shiftDay",
      render: (day) => {
        if (day) {
          return day;
        } else {
          return "--";
        }
      },
    },
    {
      title: "Time In",
      dataIndex: "login",
      key: "login",
      render: (loginTimeStamp) => {
        if (loginTimeStamp) {
          return moment(loginTimeStamp).format("hh:mm A").toLowerCase();
        } else {
          return "--";
        }
      },
    },
    {
      title: "Time Out",
      dataIndex: "logout",
      key: "logout",
      render: (logoutTimeStamp) => {
        if (logoutTimeStamp) {
          return moment(logoutTimeStamp).format("hh:mm A").toLowerCase();
        } else {
          return "--";
        }
      },
    },
    // {
    //   title: "Shift Hours",
    //   dataIndex: "shiftHours",
    //   key: "shiftHours",
    //   render: (shiftHours) => {
    //     if (shiftHours) {
    //       return (
    //         <>
    //           {`Today: ${shiftHours.today ?? "--"}`} <br />
    //           {`Last Week: ${shiftHours.lastWeek ?? "--"}`} <br />
    //           {`Last Month: ${shiftHours.lastMonth ?? "--"}`} <br />
    //         </>
    //       );
    //     } else {
    //       return (
    //         <>
    //           {`Today: 0`} <br />
    //           {`Last Week: 0`} <br />
    //           {`Last Month: 0`} <br />
    //         </>
    //       );
    //     }
    //   },
    // },
    {
      title: (
        <>
          {"Total Hours"} <br /> {"This Month"}
        </>
      ),
      dataIndex: "totalMonthHours",
      key: "totalMonthHours",
      render: (totalMonthHours) => `${totalMonthHours ?? "--"} hrs` ?? "--",
    },
    {
      title: (
        <>
          {"Missed"}<br />{"Consults"}
        </>
      ),
      dataIndex: "practitionerMissed",
      key: "practitionerMissed",
      render: (practitionerMissed) => practitionerMissed ?? "--",
    },
  ];

  const generateDoctorsAttendanceCSV = async () => {
    try {
      setIsScreenMsg("Exporting Doctor's Attendance...");
      setIsScreenLoading(true);
      let urlParams = {};
      if (currentPageNum) {
        urlParams.page = currentPageNum;
        urlParams.limit = tableEntries;
      }

      if (appliedStartDate && appliedEndDate) {
        urlParams.startDate = appliedStartDate;
        urlParams.endDate = appliedEndDate;
      }

      if (searchQuery) {
        urlParams.searchString = searchQuery;
      }

      urlParams.export = "attendanceReport";

      const apiResponse = await ServiceAttendanceApi.getDoctorsAttendanceCSV(
        urlParams
      );
      FileDownload(
        apiResponse.data,
        `Doctor's Attendance ${UI.get_current_date_string()}.csv`
      );
      setIsScreenLoading(false);
      console.log("Export to CSV status: ", apiResponse.status);
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) {
          console.log(`Error: ${statusCode} ${errorMsg}`);
        }
      }
    }
  };

  const generateTotalOnlineOfflineDoctorsCSV = async () => {
    try {
      setIsScreenMsg("Exporting Total Online and Offline...");
      setIsScreenLoading(true);
      let urlParams = {};
      if (currentPageNum) {
        urlParams.page = currentPageNum;
        urlParams.limit = tableEntries;
      }

      if (appliedStartDate && appliedEndDate) {
        urlParams.startDate = appliedStartDate;
        urlParams.endDate = appliedEndDate;
      }

      if (searchQuery) {
        urlParams.searchString = searchQuery;
      }

      urlParams.export = "doctorStatus";

      const apiResponse = await ServiceAttendanceApi.getDoctorsAttendanceCSV(
        urlParams
      );
      FileDownload(
        apiResponse.data,
        `Total Online and Offline ${UI.get_current_date_string()}.csv`
      );
      setIsScreenLoading(false);
      console.log("Export to CSV status: ", apiResponse.status);
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) {
          console.log(`Error: ${statusCode} ${errorMsg}`);
        }
      }
    }
  };

  return (
    <>
      <Loading
        visible={isScreenLoading}
        title={""}
        text={screenMsg}
        modal_opaque={false}
      />
      <FilterBox>
        <Title level={5}>Doctor's Attendance</Title>
        <Toolbar gutter={14}>
          <ToolItem xxl={8} xl={6}>
            <StyledFieldLabel>Doctor's Name </StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Select Option"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeTableDataViaSelectOptions}
              onClear={() => handleClearViaSelectField("Doctor's Name")}
            >
              <Select.Option title="Doctor's Name" value="asc">
                Ascending
              </Select.Option>
              <Select.Option title="Doctor's Name" value="desc">
                Descending
              </Select.Option>
            </StyledSelect>
          </ToolItem>
          <ToolItem xxl={8} xl={10}>
            <StyledFieldLabel>Date Filter by</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Select Option"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeTableDataViaSelectOptions}
              onClear={() => handleClearViaSelectField("Date Filter by")}
            >
              {/* <Select.Option title="Date Filter by" value="asc">
                Ascending
              </Select.Option>
              <Select.Option title="Date Filter by" value="desc">
                Descending
              </Select.Option> */}
              <Select.Option title="Date Filter by" value="singleDate">
                Single Date
              </Select.Option>
              <Select.Option title="Date Filter by" value="range">
                Range
              </Select.Option>
            </StyledSelect>
            {isDatePickerOpen && (
              <FlexRow>
                <StyledDatePicker onChange={handleChangeFilterByShiftDate} />{" "}
                <Button
                  type="primary"
                  onClick={() => {
                    handleApplyShiftDateRange(startDate, endDate);
                  }}
                >
                  Apply
                </Button>
              </FlexRow>
            )}
            {isRangePickerOpen && (
              <FlexRow>
                <StyledRangePicker
                  ranges={{
                    Today: [moment(), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                  }}
                  onChange={handleChangeFilterByShiftDateRange}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    handleApplyShiftDateRange(startDate, endDate);
                  }}
                >
                  Apply
                </Button>
              </FlexRow>
            )}
          </ToolItem>
          <ToolItem span={8}>
            <StyledFieldLabel>Shift Type</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Select Option"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeTableDataViaSelectOptions}
              onClear={() => handleClearViaSelectField("Shift Type")}
            >
              <Select.Option title="Shift Type" value="allShifts">
                All Shifts
              </Select.Option>
              <Select.Option title="Shift Type" value="dayShift">
                Day Shift
              </Select.Option>
              <Select.Option title="Shift Type" value="nightShift">
                Graveyard Shift
              </Select.Option>
            </StyledSelect>
          </ToolItem>
        </Toolbar>
      </FilterBox>

      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="30"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="5">5 Entries</Select.Option>
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>
          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getDoctorsAttendance}
            style={{ width: 200 }}
          />
          <StyledButton type="primary" onClick={generateDoctorsAttendanceCSV}>
            Export to CSV
          </StyledButton>
          <StyledButton type="primary" onClick={generateTotalOnlineOfflineDoctorsCSV}>
          Generate List of Online/Offline Doctors
          </StyledButton>
        </Toolbar>

        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          defaultPageSize={30}
          pageSizeOptions={["5", "10", "30"]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isTableLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default DoctorAttendance;

// CSS Styled Components ----------------------
const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
  // 2ND COLUMN OF TABLE (DOCTOR'S SPECIALTY)
  .ant-table-row .ant-table-cell:nth-child(2) {
    white-space: pre;
  }
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const ToolItem = styled(Col)`
  position: relative;
  .ant-picker {
    margin-left: 102px;
  }
  .ant-picker-range {
    margin-left: 62px;
  }
`;

const Title = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.4375rem !important; // 15px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
  padding-left: 35px;
  margin-bottom: 25px !important;
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const FlexRow = styled(Row)`
  flex-direction: row;
  position: absolute;
  left: 9px;
  bottom: 2px;
  z-index: 1;
  align-items: center;
`;

const StyledDatePicker = styled(DatePicker)`
  flex-direction: row;
  border-radius: 12px;
  border: 1px solid transparent;
  box-shadow: none !important;
  height: 46px;

  &:hover {
    border-color: transparent !important;
  }

  // Macbook Pro 11-inch (laptops, 1200px and up)
@media only screen and (min-width: 1200px) {
  width: 80px;
  .ant-picker-input input{
    font-size: 11px;
  }
}
// Macbook Pro 13-inch (laptops, 1280px and up)
@media only screen and (min-width: 1280px) {
  width: 110px;
  .ant-picker-input input{
    font-size: 11px;
  }
}
// Laptops 15.6inches (larger laptops, 1366px and up)
@media only screen and (min-width: 1366px) {
  width: 150px;
  .ant-picker-input input{
    font-size: 14px;
  }
}
// XX-Large devices (larger desktops, 1536 and up)
@media only screen and (min-width: 1536px) {
  width: 162px;
  .ant-picker-input input{
    font-size: 14px;
  }
}
// XXX-Large devices (larger desktops, 1920px and up)
@media only screen and (min-width: 1920px) {
  width: 162px;
  .ant-picker-input input{
    font-size: 14px;
  }
} 
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  flex-direction: row;
  border-radius: 12px;
  border: 1px solid transparent;
  box-shadow: none !important;
  height: 46px;

  &:hover {
    border-color: transparent !important;
  }
  
// Macbook Pro 11-inch (laptops, 1200px and up)
@media only screen and (min-width: 1200px) {
  width: 120px;
  .ant-picker-input input{
    font-size: 11px;
  }
}
// Macbook Pro 13-inch (laptops, 1280px and up)
@media only screen and (min-width: 1280px) {
  width: 150px;
  .ant-picker-input input{
    font-size: 11px;
  }
}
// Laptops 15.6inches (larger laptops, 1366px and up)
@media only screen and (min-width: 1366px) {
  width: 190px;
  .ant-picker-input input{
    font-size: 11px;
  }
}
// XX-Large devices (larger desktops, 1536 and up)
@media only screen and (min-width: 1536px) {
  width: 250px;
  .ant-picker-input input{
    font-size: 14px;
  }
}
// XXX-Large devices (larger desktops, 1920px and up)
@media only screen and (min-width: 1920px) {
  width: 282px;
  .ant-picker-input input{
    font-size: 14px;
  }
} 
`;

const CellStyle = styled(Typography.Text)`
  // Dynamically render style using props of this component
  color: ${(props) => (props.status === "Online" ? "#26AD26" : "#BBBBBB")};
`;
