import React, { useCallback, useState, useEffect } from "react";
import DataTable from "../../ComponentsFC/DataTable";
import UI from "../../shared/Components/UI/js";
import styled from "styled-components";
import { Row, Col, Input, Select, Typography, Button} from "antd";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import TouchableOpacity, { Image, mem, Text, View } from "../../shared/custom-react-native";
import LaboratoryPartnerApi from "../../api_services/Merchant/LaboratoryPartnerApi";
import { DeleteConfirmation, Failed, Success } from "../_components/Modal";

const LaboratoryTable = ({ _this }) => {
  const [tableData, setTableData] = useState([]);
  const [searchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortByName, setSoryByName] = useState("");
  const [sortByStatus, setSortByStatus] = useState("");
  const [filterByServiceType, setFilterByServiceType] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(30);
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const getCategories = useCallback(async () => {
      setIsLoading(true);

      let params = {};

      if (searchQuery) {
        params.keyword = searchQuery;
      }

      if (sortByName) {
        params.name = sortByName;
      }

      if (sortByStatus) {
        params.status = sortByStatus;
      }
      
      if (currentPageNum) {
        params.page = currentPageNum;
        params.limit = tableEntries;
      }

      if (filterByServiceType) {
        params.serviceType = filterByServiceType;
      }

      LaboratoryPartnerApi.getAllLaboratoryPartner(params).then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setTotalRecords(res.count)
          console.log(res)
          setTableData(res.data);
        }
      });
  }, [currentPageNum, tableEntries, searchQuery, sortByName, sortByStatus, filterByServiceType]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const handlePaginateTable = (pageNum) => {
    setCurrentPageNum(pageNum);
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  // const handleCSVExport = () => {
  //   const csv_data = tableData.map((item, index) => {
  //     const d = {
  //       "Patient Name": item?.name ?? "",
  //       "Email": item?.email ?? "",
  //       "Phone": item?.phone ?? "",
  //       "Referrals count": item.referralCount ?? "--",
  //       "Date Registered": item?.registeredDate ? UI.momentPHTimezone(item?.registeredDate).format('MM/DD/YYYY') : "--",
  //     };

  //     return d;
  //   });

  //   UI.DownloadCSV(`Patient Referrals ${UI.get_current_date_string()}`, csv_data);
  // };

  const tableColumnHeader = [
    {
      title: "Laboratory Name",
      dataIndex: "name",
      key: "name",
      render: (name) => name ?? "--",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      render: (email) => email ?? "--",
    },
    {
      title: "Contact Number",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => phone ?? "--",
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      key: "serviceType",
      render: (serviceType) => serviceType ?? "--",
    },
    {
      title: "Date Added",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => createdAt ? UI.momentPHTimezone(createdAt).format('MM/DD/YYYY LT') : "--",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Text style={{ color: status ? status === "Active" ? UI.colors.green : UI.colors.red : "" }}>{ status ? status : "--" }</Text>
      ),
    },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (key, item) => {
            return (
              <View style={{ flexDirection: "row", gap: 10 }}>
                {/* <TouchableOpacity
                  onClick={() => {
                    UI.goTo('/merchant/categories-view')
                    mem.save('categories_edit', JSON.stringify(item))
                  }}
                >
                  <Image
                    height={28}
                    width={28}
                    tintColor={"transparent"}
                    source={UI.VIEW_BTN_1}
                  />
                </TouchableOpacity> */}
                <TouchableOpacity
                  onClick={() => {
                    UI.goTo('/admin/laboratory-edit')
                    mem.save('laboratory_edit', JSON.stringify(item))
                  }}
                >
                  <Image
                    height={28}
                    width={28}
                    tintColor={"transparent"}
                    source={UI.EDIT_BTN_1}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onClick={() => {
                    deleteLaboratoryPartner(item)
                  }}
                >
                  <Image
                    height={28}
                    width={28}
                    tintColor={"transparent"}
                    source={UI.DELETE_BTN}
                  />
                </TouchableOpacity>
              </View>
            )
        },
    },
  ];

  const deleteLaboratoryPartner = (item) => {
    _this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this laboratory partner?"}
        onCancel={() => {
          _this.hide_modal();
        }}
        onDelete={async () => {
          LaboratoryPartnerApi.deleteLaboratoryPartner(item._id).then((res) => {
            // setIsLoading(false);
            if (res.status === 200) {
              _this.show_modal(<Success
                title={"Success"}
                description={`Successfully deleted laboratory partner account`}
                onDismiss={() => {
                  getCategories();
                  _this.hide_modal()
                }}
              />);
            }  else {
              _this.show_modal(
                <Failed
                  description={res.message} 
                  onDismiss={() => { 
                    _this.hide_loading();
                    _this.hide_modal()
                  }} 
                />
              );
            }
          });
        }}
      />
    );
   
  }

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPageNum(1);
    setTableEntries(30);
  };


  return (
    <>
      <FilterBox>
        <Text style={{
            fontSize: 18,
            fontWeight: "bold",
            color: UI.colors.secondary,
            flex: 1,
            paddingLeft: 35,
            paddingBottom: 35,
          }}
        >
          Laboratory List
        </Text>
        <Toolbar gutter={14}>
          <ToolItem span={8}>
            <StyledFieldLabel>Laboratory Name</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Sort Options"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setSoryByName(value)
              }}
            //   onClear={() => handleClearViaSelectField("Registration Date")}
            >
              <Select.Option value="asc">Ascending</Select.Option>
              <Select.Option value="desc">Descending</Select.Option>
            </StyledSelect>
          </ToolItem>
          <ToolItem span={8}>
            <StyledFieldLabel>Service Type</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Filter Options"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setFilterByServiceType(value)
              }}
            //   onClear={() => handleClearViaSelectField("Registration Date")}
            >
              <Select.Option value="Home">Home</Select.Option>
              <Select.Option value="Clinic">Clinic</Select.Option>
              <Select.Option value="Home and Clinic">Home and Clinic</Select.Option>
            </StyledSelect>
          </ToolItem>
          <ToolItem span={8}>
            <StyledFieldLabel>Status</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Filter Options"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setSortByStatus(value)
                // setSortByReferralCount(value)
              }}
            //   onClear={() => handleClearViaSelectField("Registration Date")}
            >
              {/* <Select.Option value="All">All</Select.Option> */}
              <Select.Option value="Active">Active</Select.Option>
              <Select.Option value="Inactive">Inactive</Select.Option>
            </StyledSelect>
           
          </ToolItem>
          {/* <ToolItem span={4}>
            <StyledFieldLabel style={{ color: "white" }}>.</StyledFieldLabel>
            <StyledButton type="primary" onClick={() => false}>
              Apply
            </StyledButton>
          </ToolItem> */}
        </Toolbar>
      </FilterBox>

      <TableBox>
        <Toolbar>
          <ToolItem span={8} style={{display:'flex', gap: 10, alignItems:'center'}}>
            <FieldLabel>Only Show</FieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              defaultValue="30"
              style={{
                width: 160,
              }}
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeNumOfEntries}
            >
              <Select.Option value="10">10 Entries</Select.Option>
              <Select.Option value="30">30 Entries</Select.Option>
              <Select.Option value="100">100 Entries</Select.Option>
            </StyledSelect>
          </ToolItem>
          <div className="flex-1"></div>
          <ToolItem span={5}>
            <StyledSearch
              placeholder="Enter Keyword"
              allowClear
              suffix={<SearchIcon />}
              value={searchQuery}
              onChange={(e) => {
                handleChangeSearchQuery(e);
              }}
              onPressEnter={getCategories}
              // style={{ width: 200 }}
            />
          </ToolItem>
          <StyledButton type="primary" onClick={() => UI.goTo('/admin/laboratory-add')}>
            Add Laboratory
          </StyledButton>
        </Toolbar>
        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          defaultPageSize={30}
          pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default LaboratoryTable;

// CSS Styled Components ----------------------

const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

// const StyledRangePicker = styled(DatePicker.RangePicker)`
//   flex-direction: row;
//   border-radius: 12px;
//   border-width: 2px !important;
//   height: 50px;
//   margin-left: auto;

//   .ant-picker:hover,
//   .ant-picker-focused {
//     border-color: #d9d9d9 !important;
//     box-shadow: none !important;
//   }

//   .ant-picker-range-separator {
//     display: block !important;
//   }
// `;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const ToolItem = styled(Col)`
  position: relative;
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #004F99;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  /* width: 320px !important;
  margin-left: auto; */
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;
