import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  ImageBackground,
  ImageFit,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm, { Checkbox } from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed } from "../_components/Modal";
import AddAdvocacyScheule from "./AddAdvocacyScheule";
import AddQuestionnaire from "./AddQuestionnaire";
import "./css.css";

const context = "advocacy";
export default class AddAvocacy extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Advocacy List",
      is_drawer_open: false,
      title:"",
      start_date:"",
      end_date:"",
      promotion_code:"",
      status:"",
      consultationTimeLimit:"",
      description:"",
      event_title:"",
      join_button_title:"",
      isNext: 1,
      did_upload: false,
      did_upload_mobile_image:false,
      comming_soon: false,
      context: context,
      image: UI.PRODUCT_BOX,
      image_file: null,
      mobile_image: UI.PRODUCT_BOX,
      mobiel_image_file: null,
      healthpassData:[],
      advocacySchedule:[],
      healthpass:"",
      consultationAvailability: true,
      isAddSurvey: true,
      isShowJoinButton: true,
      isConsultNow: true,
      isConsultLater:false
    });
  }

  upload_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        image: file.uri,
        image_file: file.file,
        did_upload: true,
      }
    );
  };

  upload_mobile_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        mobile_image: file.uri,
        mobile_image_file: file.file,
        did_upload_mobile_image: true,
      }
    );
  };

  onCreate = () => {
    this.loadHealthpass();
  };

  loadHealthpass = () => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/healthpass?isDropdown=true",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            healthpassData: data.data,
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }


  submit = async () => {
    UI.clear_errors();

    const { title, start_date, end_date, status, consultationTimeLimit, description, image_file, healthpass, 
      // isAddSurvey 
    } = this.state;
    
    if (!image_file) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Upload Advocacy Picture"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      return;
    }

    if (start_date > end_date) {
      return this.show_modal(
        <Failed
          description={"Invalid Start Date and End Date."}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      )
    }

    if (!title) {
      await UI.error_form("title", "Advocacy Title is required.");
    }  if (!start_date) {
      await UI.error_form("start_date", "Start Date is required.");
    }  if (!end_date) {
      await UI.error_form("end_date", "End Date is required.");
    } if (!status) {
      await UI.error_form("status", "Status is required.");
    }  if (!consultationTimeLimit) {
      await UI.error_form("consultationTimeLimit", "Consultation Time Limit is required.");
    }  if(!description) {
      await UI.error_form("description", "Description is required.");
    } if(!healthpass) {
      await UI.error_form("healthpass", "Healthpass is required.");
    } 

    const error_count = this.state.error_count;

    if (error_count > 0) {
      this.hide_loading();
      return;
    }

    this.setState({isNext: 2})

  }

  render() {
    const _this = this;
    const {  did_upload, did_upload_mobile_image } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          {
            this.state.isNext === 1 ?  
            <>
            <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
              <Text style={{ ...styles.box_header_text, flex: 1 }}>
                Add New Advocacy
              </Text>

              <Text style={styles.text_2}>
                Enter the required information below to complete registration
              </Text>
            </UI.Row>
            {UI.box(20)}
              <UI.Row breakpoint={970} _this={_this}>
              <View style={{ height: 120, width: 120 }}>
                <Layout.FilePicker
                  _this={_this}
                  title={""}
                  additional={""}
                  // isRequired={true}
                  text={""}
                  state_name={"web_banner_image"}
                  onChange={async (file) => {
                    const uri = await UI.get_uri_from_file(file);
                    this.setState(
                      {
                        image: uri,
                        image_file: file,
                        did_upload: true,
                      }
                    );
                  }}
                  height={120}
                  component={
                    <ImageBackground
                      style={{ height: 120, width: 120, borderRadius: 10 }}
                      source={this.state.image}
                      imageFit={ImageFit.CONTAIN}
                    />
                  }
                />
              </View>
              {UI.box(20)}

              <View style={{ flex: 1.5, justifyContent: "center" }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
                >
                  {did_upload
                    ? "Web Image Banner Uploaded."
                    : "No Web Image Banner Uploaded."}{" "}
                  Recommended Size: 500 px x 500 px
                </Text>
                {UI.box(10)}

                <Layout.Btn
                  color={UI.colors.primary}
                  text={"Upload Web Image Banner"}
                  onClick={() => {
                    this.upload_picture();
                  }}
                  width={220}
                />
              </View>

              <View style={{ height: 120, width: 120 }}>
                <Layout.FilePicker
                  _this={_this}
                  title={""}
                  additional={""}
                  // isRequired={true}
                  text={""}
                  state_name={"mobile_banner_image"}
                  onChange={async (file) => {
                    const uri = await UI.get_uri_from_file(file);
                    this.setState(
                      {
                        mobile_image: uri,
                        mobile_image_file: file,
                        did_upload_mobile_image: true,
                      }
                    );
                  }}
                  height={120}
                  component={
                    <ImageBackground
                      style={{ height: 120, width: 120, borderRadius: 10 }}
                      source={this.state.mobile_image}
                      imageFit={ImageFit.CONTAIN}
                    />
                  }
                />
              </View>
              {UI.box(20)}

              <View style={{ flex: 1.5, justifyContent: "center" }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
                >
                  {did_upload_mobile_image
                    ? "Mobile Image Banner Uploaded."
                    : "No Mobile Image Banner Uploaded."}{" "}
                  Recommended Size: 500 px x 500 px
                </Text>
                {UI.box(10)}

                <Layout.Btn
                  color={UI.colors.primary}
                  text={"Upload Mobile Image Banner"}
                  onClick={() => {
                    this.upload_mobile_picture();
                  }}
                  width={220}
                />
              </View>
            </UI.Row>
            {UI.box(20)}
            <UI.Row breakpoint={970} _this={_this}>
              <InputForm
                defaultValue={this.state.title}
                _this={_this}
                title={"Advocacy Title"}
                placeholder={"Enter Advocacy Title"}
                state_name={"title"}
                style={{ flex: 1 }}
                isRequired={true}
                maxLength={100}
              />
              {UI.box(10)}
              <Layout.DatePicker
                disabledPastDates={true}
                _this={_this}
                title={"Advocacy Start Date"}
                // isWithTimePicker={true}
                placeholder={"mm/dd/yyyy"}
                state_name={"start_date"}
                will_correct={true}
                isRequired={true}
              />
              {UI.box(10)}
              <Layout.DatePicker
                disabledPastDates={true}
                _this={_this}
                title={"Advocacy End Date"}
                // isWithTimePicker={true}
                placeholder={"mm/dd/yyyy"}
                isRequired={true}
                state_name={"end_date"}
                will_correct={true}
              />
            </UI.Row>
            {UI.box(20)}
            <UI.Row breakpoint={970} _this={_this}>
            <Layout.SelectPicker
              _this={this}
              title={"Healthpass Name"}
              state_name={"healthpass"}
              isMultiple={true}
              isRequired={true}
            >
              <option value="">Select Healthpass</option>
                {this.state.healthpassData.map((item) => {
                  return item ? (
                    <option value={item._id}>{item.title}</option>
                  ) : (
                    <View></View>
                  );
                })}
            </Layout.SelectPicker>
              {UI.box(10)}
              <Layout.SelectPicker
                _this={_this}
                title={"Advocacy Status"}
                isRequired={true}
                state_name={"status"}
              >
                <option value="">Select Status (Active or Inactive)</option>
                <option value="Active" selected={this.state.status ? true : false}>Active</option>
                <option value="Inactive" selected={this.state.status ? true : false}>Inactive</option>
              </Layout.SelectPicker>

              {UI.box(10)}
              <Layout.SelectPicker
                _this={_this}
                title={"Consultation Time Limit"}
                isRequired={true}
                state_name={"consultationTimeLimit"}
              >
                <option value="">Select Time Limit</option>
                <option value="30 minutes" selected={this.state.consultationTimeLimit ? true : false}>30 minutes</option>
              </Layout.SelectPicker>

            </UI.Row>
            {UI.box(20)}
            <UI.Row breakpoint={970} _this={_this}>
              <View style={{flex:1}}>
                <span className="font-semibold" style={{color: UI.colors.primary}}>Coming Soon</span>
                {UI.box(10)}
                <Checkbox
                  _this={_this}
                  text="Coming Soon"
                  onClick={(value) => {
                    _this.setState({
                      comming_soon: !this.state.comming_soon,
                    });
                  }}
                  is_checked={this.state.comming_soon}
                  color={"#2E2E2E"}
                />
              </View>
              {UI.box(10)}
              <View style={{flex: 1}}>
                <span className="font-semibold" style={{color: UI.colors.primary}}>Consultation Availability</span>
                {UI.box(10)}

                <div style={{display:'flex', gap:10}}>
                  <div  style={{display:'flex', gap:10}}>
                    Consult Now
                    {
                      this.state.isConsultNow ?
                      <img src={UI.ON} alt="" className="cursor-pointer" height={50} width={50} onClick={ () => this.setState({isConsultNow: !this.state.isConsultNow})}/> :
                      <div className="cursor-pointer bg-gray-200 flex items-center rounded-full" style={{userSelect:'none', height:'23px', width:'50px'}} 
                        onClick={ () => this.setState({isConsultNow: !this.state.isConsultNow})}
                      >
                        <div className="bg-white rounded-full" style={{userSelect:'none', height:'16px', width:'16px', marginLeft:'3px'}}></div>
                      </div>
                    // <span className="text-gray-400">Offline</span>
                      // <img src={UI.OFF} alt="" className="cursor-pointer" height={50} width={50} onClick={ () => this.setState({isConsultNow: !this.state.isConsultNow})}/>
                    }
                  </div>
                  <div style={{display:'flex', gap:10}}>
                    Consult Later
                    {
                      this.state.isConsultLater ?
                      <img src={UI.ON} alt="" className="cursor-pointer" height={50} width={50} onClick={ () => this.setState({isConsultLater: !this.state.isConsultLater})}/> :
                      <div className="cursor-pointer bg-gray-200 flex items-center rounded-full" style={{userSelect:'none', height:'23px', width:'50px'}} 
                        onClick={ () => this.setState({isConsultLater: !this.state.isConsultLater})}
                      >
                        <div className="bg-white rounded-full" style={{userSelect:'none', height:'16px', width:'16px', marginLeft:'3px'}}></div>
                      </div>
                      // <img src={UI.OFF} alt="" className="cursor-pointer" height={50} width={50} onClick={ () => this.setState({isConsultLater: !this.state.isConsultLater})}/>
                    }
                  </div>
                </div>
              {/* 
                <View style={{flexDirection:'row'}}>
                  <Checkbox
                    _this={_this}
                    text="Consult Now"
                    onClick={(value) => {
                      _this.setState({
                        consultationAvailability: true,
                      });
                    }}
                    is_checked={this.state.consultationAvailability}
                    color={"#2E2E2E"}
                  />
                  {UI.box(10)}
                  <Checkbox
                    _this={_this}
                    text="Consult Later"
                    onClick={(value) => {
                      _this.setState({
                        consultationAvailability: false,
                      });
                    }}
                    is_checked={!this.state.consultationAvailability}
                    color={"#2E2E2E"}
                  />
                </View> */}
              </View>
              {UI.box(10)}
              <View style={{flex:1}}>
                <span className="font-semibold" style={{color: UI.colors.primary}}>Add Advocacy Survey</span>
                {UI.box(10)}
                <View style={{flexDirection:'row'}}>
                  <Checkbox
                    _this={_this}
                    text="Yes"
                    onClick={(value) => {
                      _this.setState({
                        isAddSurvey: true,
                      });
                    }}
                    is_checked={this.state.isAddSurvey}
                    color={"#2E2E2E"}
                  />
                  {UI.box(10)}
                  <Checkbox
                    _this={_this}
                    text="No"
                    onClick={(value) => {
                      _this.setState({
                        isAddSurvey: false,
                      });
                    }}
                    is_checked={!this.state.isAddSurvey}
                    color={"#2E2E2E"}
                  />
                </View>
              </View>
            </UI.Row>
            {UI.box(20)}
            <UI.Row breakpoint={970} _this={_this}>
              <InputForm
                defaultValue={this.state.title}
                _this={_this}
                title={"Event Title"}
                placeholder={"Enter Event Title"}
                state_name={"event_title"}
                style={{ flex: 1 }}
                // isRequired={true}
                maxLength={100}
              />
              {UI.box(10)}
              <InputForm
                defaultValue={this.state.title}
                _this={_this}
                title={"Advocacy Join Button Title"}
                placeholder={"Enter Advocacy Join Button Title"}
                state_name={"join_button_title"}
                style={{ flex: 1 }}
                // isRequired={true}
                maxLength={100}
              />
              {UI.box(10)}
              <View style={{flex:1}}>
                <span className="font-semibold" style={{color: UI.colors.primary}}>Show Advocacy Join Button</span>
                {UI.box(10)}
                <View style={{flexDirection:'row'}}>
                  <Checkbox
                    _this={_this}
                    text="Yes"
                    onClick={(value) => {
                      _this.setState({
                        isShowJoinButton: true,
                      });
                    }}
                    is_checked={this.state.isShowJoinButton}
                    color={"#2E2E2E"}
                  />
                  {UI.box(10)}
                  <Checkbox
                    _this={_this}
                    text="No"
                    onClick={(value) => {
                      _this.setState({
                        isShowJoinButton: false,
                      });
                    }}
                    is_checked={!this.state.isShowJoinButton}
                    color={"#2E2E2E"}
                  />
                </View>
              </View>
            </UI.Row>
            {UI.box(20)}
            <UI.Row breakpoint={970} _this={_this}>
              <InputForm
                _this={_this}
                defaultValue={this.state.description}
                title={"Advocacy Description"}
                placeholder={"Enter Description Here"}
                state_name={"description"}
                style={{ flex: 1 }}
                height={100}
                isRequired={false}
                type={"big"}
                maxLength={UI.Constants.limits.description}
              />
            </UI.Row>
            {UI.box(20)}
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Layout.Btn
                color={UI.colors.primary}
                onClick={() => {
                  this.submit();
                  const { start_date_date, end_date_date} = this.state
                  let endDate = moment(end_date_date);
                  var startDate = moment(start_date_date).subtract(1, "days");
                  let days = []
              
                  while(endDate.date() !== startDate.date()){
                    startDate.add(1, 'day'); 
                    days.push(
                    {
                      date: moment(startDate._d).format("LLLL").split(',')[0] + ", " + moment(startDate).format('MMMM D YYYY'),
                      morning_start_time: "",
                      morning_end_time: "",
                      afternoon_start_time: "",
                      afternoon_end_time: "",
                    }
                    )
                  }
              
                  this.setState({ advocacySchedule: days})
                  // mem.save('advocacy_schedule', JSON.stringify(days))
                }}
                text={"NEXT"}
              />
              {UI.box(20)}

              <Layout.Btn
                color={"#E00000"}
                onClick={() => {
                  UI.goBack();
                }}
                text={"CANCEL"}
              />

              {UI.box(20)}

              <Text style={styles.text_2}>
                Step 1 of {this.state.isAddSurvey ? "3" : "2"}
                {/* Next */}
              </Text>
            </View>
          </> 
          : this.state.isNext === 2 ? <AddAdvocacyScheule _this={_this}/> :
            <AddQuestionnaire 
              _this={_this}
            />
          }
          
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: UI.colors.secondary,
    fontWeight:'bold'
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});
