import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { DeleteConfirmation } from "../_components/Modal";
import "./css.css";

const context = "voucher";

export default class VoucherCampaignList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Campaign List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,
    });
  }

  onCreate = () => {
    setTimeout(() => {
      this.get_data({
        page: 1,
        limit:30,
      });
    }, 100);
  };

  get_data = async (params) => {
    let limit = parseInt(params.limit);
    params.limit = limit >= 30 ? 30 : limit

    this.show_loading();

    if (!params) {
      params = {};
    }
    this.setState({
      healthpass_data_current_page: params?.page ?? 1,
    });

    UI.webRequest({
      method: "get",
      url: "admin/campaigns",
      params: params,
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            data: data.data,
            all_data: data.data,
          },
          () => {
            this.reload_table("healthpass_list");
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  delete = async (id) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this item?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();
          this.show_loading();
          UI.webRequest({
            method: "delete",
            url: "admin/campaigns/" + id,
            params: {},
            onFail: async (err) => {},
            onSuccess: async (response) => {},
            onFinish: () => {
              this.get_data({
                page: 1,
                limit:30,
              })
              this.hide_loading();
            },
          });
        }}
      />
    );
  };

  render() {
    const _this = this;
    const { width } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={{ ...styles.main_box_content, padding: 0 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={950}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"healthpass_list"} _this={_this} />
              </View>
            </View>

            <View style={{ alignSelf: "center", marginTop: 8, marginLeft: 10 }}>
              <Layout.Btn
                color={UI.colors.primary}
                text={"Add Campaign"}
                onClick={() => {
                  UI.goTo("/voucher/campaign-add");
                }}
                height={40}
                width={140}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            state_name={"healthpass_list"}
            _this={_this}
            columns={[
              // { ID: "id" },
              { Title: "title" },
              { "Creator Name": "creator_name"},
              { Status: "status" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              return {
                title: item.title,
                creator_name: (item.creator ? item.creator?.firstName : "") + " " + (item.creator ? item.creator?.lastName : ""),
                status: item.status ? (
                  <Text style={{ color: "#00C700" }}>Active</Text>
                ) : (
                  <Text style={{ color: "#f39c12" }}>Inactive</Text>
                ),
                action: (
                  <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                      onClick={() => {
                        console.log(item)
                        mem.save("view_campaign", JSON.stringify(item));
                        UI.goTo("/voucher/campaign-view");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.VIEW_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={() => {
                        mem.save("edit_campaign", JSON.stringify(item));
                        UI.goTo("/voucher/campaign-edit");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={() => {
                        this.delete(item._id);
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.DELETE_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
});
