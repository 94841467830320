import React from "react";
import styled from "styled-components";
import { Row, Col, Divider, Image } from "antd";
import { Container, ContainerLabelSecondary } from "../ui";
import ContainerLabelTertiary from "../ui/ContainerLabelTertiary";
import UI from "../../shared/Components/UI/js";

const UserGroupHeader = (props) => {
  const profileLogoImg = props.this.state?.profile_logo_image;
  const userGroupProfileLogoImg = props.this.state.user_group?.profileLogoImage;
  const partnerLogoImg = props.this.state.partner_logo_image;
  const userGroupPartnerLogoImg = props.this.state.user_group?.partnerLogoImage;

  const userGroupName = props.this.state.user_group?.name;
  const userGroupContactNum = props.this.state.user_group?.contactNumber;

  return (
    <StyledContainer>
      <Row style={{ alignItems: "center" }}>
        {profileLogoImg || partnerLogoImg ? (
          <Col>
            <FlexContent>
              {profileLogoImg && userGroupProfileLogoImg && (
                <Image
                  width={100}
                  src={profileLogoImg}
                  preview={false}
                  alt="N/A"
                />
              )}
              <HeaderTitle>
                <span>{userGroupName}</span>
                <span>{userGroupContactNum}</span>
              </HeaderTitle>
              {partnerLogoImg && userGroupPartnerLogoImg && (
                <Image
                  width={100}
                  src={partnerLogoImg}
                  preview={false}
                  alt="N/A"
                />
              )}
            </FlexContent>
          </Col>
        ) : (
          <HeaderTitle>
            <span>{userGroupName ?? "--"}</span>
            <span>{userGroupContactNum ?? "- -"}</span>
          </HeaderTitle>
        )}

        <VerticalDivider type="vertical" />

        <Col style={{ padding: "6.5px 0 9.5px 0" }}>
          <StatsTitle>Patient Statistics</StatsTitle>
          <Row style={{ alignItems: "center" }}>
            <Image
              height={60}
              width={60}
              src={UI.IC_DASH_1}
              preview={false}
              alt="N/A"
            />

            <Col style={{ marginLeft: 10 }}>
              <NumOfMembers>
                {UI.pad(props.this.state.members?.length, 3)}
              </NumOfMembers>
              <ContainerLabelTertiary style={{ margin: "0 0 0 0" }}>
                Total Members
              </ContainerLabelTertiary>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default UserGroupHeader;

const StyledContainer = styled(Container)`
  padding: 20px 20px 20px 20px;
`;

const HeaderTitle = styled(Row)`
  padding-left: 10px;
  min-width: 149px; //-20px of divider
  max-width: 149px; //-20px of divider
  font-size: 18px;
`;

const FlexContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
`;

const StatsTitle = styled(ContainerLabelSecondary)`
  color: #02488d;
  font-size: 18px !important;
  margin-bottom: 10px;
`;

const NumOfMembers = styled(ContainerLabelSecondary)`
  color: #00cbff !important;
  font-size: 20px !important;
  margin-bottom: 0 !important;
`;

const VerticalDivider = styled(Divider)`
  background-color: #e5e7eb;
  width: 2;
  height: 100%;
  margin-left: 30px;
  margin-right: 30px;
`;
