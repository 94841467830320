import { Button, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import TouchableOpacity, { mem, Shadow, StyleSheet, Text, View } from '../../shared/custom-react-native';
import UI from '../../shared/Components/UI/js';
import { Failed, Success } from '../_components/Modal';
import DropdownIcon from '../../icons/Dropdown';
import moment from 'moment';
import ServiceDoctosApi from "../../api_services/doctors";

// import SpecializationsnApi from "../../api_services/specializations.js";

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

const DoctorRescheduleAppointmentModal = ({ appointment, _this }) => {
  console.log(appointment);
  const formRef = React.createRef();
  const doctor = JSON.parse(mem.get('onboard_data'))
  const [selectedDate, setSelectedDate] = useState(null)
  const [doctorSlots, setDoctorSlots] = useState([])

  const getDoctorSlots = () => {
    _this.show_loading();
      ServiceDoctosApi.getDoctorScheduleAvailability(doctor.doctor._id).then( res => {
        if (res.status === 200) {
          let data = res;
          setDoctorSlots(data.entry)
          _this.hide_loading();
        } else {
          _this.show_modal(
            <Failed 
              description={res.message} 
              onDismiss={() => { 
                _this.hide_loading();
                _this.hide_modal()
              }} 
            />
          );
        }
      })
  }

  const applyReschedule = () => {

    const splitDate = selectedDate

    if (!selectedDate) {
      return _this.show_modal(
        <Failed 
          description={"Please select schedule"} 
          onDismiss={() => { 
            _this.hide_loading();
            _this.hide_modal()
          }} 
        />
      );
    }

    const payload = {
      start: splitDate.split('=')[0],
      end: splitDate.split('=')[1],
    }

    _this.show_loading();
    _this.hide_modal();

      ServiceDoctosApi.rescheduleDoctorAppointment(appointment._id, payload).then( res => {
        if (res.status === 200) {
          // let data = res.data;
          // setDoctorSlots(data.entry)
          _this.show_modal(<Success
            descriptionCenter={"You have Successfully Rescheduled an Appointment."}
            onDismiss={() => {
              _this.hide_loading();
              _this.hide_modal();
              _this.load_consultations();
            }}
          />);
        } else {
          _this.show_modal(
            <Failed 
              description={res.message} 
              onDismiss={() => { 
                _this.hide_loading();
                _this.hide_modal()
              }} 
            />
          );
        }
      })
  }

  useEffect(() => {
    getDoctorSlots()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="" style={{
      backgroundColor: "white",
      borderRadius: "18px",
      ...Shadow._3(),
      zIndex: 10003 + 1,
      alignItems: "center",
      width: 600,
      maxWidth: 1200,
    }}>
      <div style={{width:'100%'}}>
        <View
          style={{
            ...styles.md_header,
            borderTopRightRadius:12,
            borderTopLeftRadius:12,
          }}
          >
          <TouchableOpacity
            onClick={() => {
              _this.hide_modal()
            }}
            style={styles.md_close}
          >
            <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt=""/>
          </TouchableOpacity>

          <View style={styles.md_header_text_container}>
            <Text style={styles.md_header_text}>RESCHEDULE APPOINTMENT</Text>
          </View>
          {UI.box(60)}
        </View>

        <div className="p-5 flex flex-col gap-5" >
        <Form 
          ref={formRef}
            name="validate_other"
            style={{
              marginBottom: "10px !important",
              fontSize: 18,
              fontWeight: "bold",
              }} 
            validateMessages={validateMessages}
            onFinish={applyReschedule}
          >

          <StyledFormItem
           name="date"
           label="Available Date and Time"
           rules={[
              {
                required: true,
                message: 'Please select Date and Time',
              },
            ]}
          >
            {/* <StyledFieldLabel>Available Date and Time</StyledFieldLabel> */}
              <StyledSelect
                getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                placeholder="Select Date and Time"
                style={{
                  width: "100%",
                }}
                suffixIcon={<DropdownIcon />}
                dropdownStyle={{  }}
                onChange={(value) => setSelectedDate(value)}
              >
                {
                  doctorSlots?.map((slot) => {
                    return (
                      <Select.Option title="File Type" value={`${slot.resource.start}=${slot.resource.end}`}>
                        {moment(slot.resource.start).local().format('MMMM Do YYYY, h:mm:ss a')}
                      </Select.Option>
                    )
                  })
                }
            </StyledSelect>
          </StyledFormItem>
         
          <span style={{color: UI.colors.unset, fontSize:14}}>Note: only one time limit for reschedule</span>

            <div className='items-center mt-10 flex justify-end border-t-2'>
              <div className='mt-5'>
              <StyledFormItem >
                <StyledButton type="primary" htmlType="submit" 
                  // onClick={applyReschedule}
                >
                  Apply
                </StyledButton>
              </StyledFormItem>
              </div>
            </div>

          </Form>
        </div>

      </div>
    </div>
  )
}

export default DoctorRescheduleAppointmentModal

const StyledFormItem = styled(Form.Item)`
  flex-direction: column !important;
  justify-content: flex-start !important;
  width:100%;
  
 .ant-form-item-label {
    text-align: left !important;
  }

  .ant-form-item-label > label {
    color:rgb(4, 204, 255) !important;
  }
`;

// const StyledFieldLabel = styled(Typography.Paragraph)`
//   color: #65d1ff;
//   margin-bottom: 10px !important;
// `;

const StyledButton = styled(Button)`
  height: 55px;
  width: 170px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    height: 50px !important;
    display: flex;
    align-items: center;
  }
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }
  
  .ant-select-selector input {
    height: 50px !important;
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;


// const StyledFieldLabel = styled(Typography.Paragraph)`
//   margin-bottom: 10px !important;
//   font-size: 18; // 15px
//   font-weight: bold;
// `;

const styles = StyleSheet.create({
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: UI.colors.secondary,
    // borderTopRightRadius: 10,
    // borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
});