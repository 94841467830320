import { React } from "react";
import moment from "moment";
import { Row, Col, Button, Typography } from "antd";
import styled from "styled-components";
// import UI from "../../../shared/Components/UI/js";
import { Success, Disapprove } from "../../_components/Modal";
import toTitleCase from "../../../utils/toTitleCase";
import ServiceLeaveRequestApi from "../../../api_services/Admin/DoctorLeaveRequestApi";

const LeaveDetailsModal = ({ _this, data, leaveList }) => {
  // const {
  //   status,
  //   requestor,
  //   leavetype,
  //   name,
  //   reason,
  //   start_date,
  //   end_date,
  //   attachment,
  //   duration,

  // } = data.state

  // const signature = downloaded_signature || file_signature_path;
  const approveLeave = async (id) => {
    let params = {};
    params.type = "Approved";

    changeLeaveStatus(id, "Approved");
  };

  const disapproveLeave = async (id) => {
    let params = {};
    params.type = "Disapproved";
    let reason = document.getElementById("declinedReason").value;
    if (reason) {
      params.declinedReason = reason;
    }

    changeLeaveStatus(id, "Disapproved", params);
  };

  // const changeLeaveStatus = async (id,status,params) => {
  //   const apiResponse = await ServiceLeaveRequestApi.changeLeaveStatus(id,status,params)
  //   console.log("apiResponse",apiResponse)
  //   if(apiResponse.status === 201 && status === "Approved"){

  const handleViewDoctorFile = async (file) => {
    console.log("TRIGGERED VIEW DOCTOR FILE", file);
    try {
      // ?container=doctors-onboarding&path=doctors/636136ef833125c4b354f405/leave_attachments/2770867498867686-Stitch.png
      let urlParams = {};

      urlParams.container = "doctors-onboarding";
      urlParams.path = file?.path;

      const apiResponse = await ServiceLeaveRequestApi.viewDoctorFile(
        urlParams
      );
      console.log("View Doctor File: ", apiResponse);
      if (apiResponse?.status === 200) {
        //Create blob from file
        const fileBlob = new Blob([apiResponse?.data], {
          type: apiResponse?.data?.type,
        });
        //Create URL from fileBlob
        const fileURL = URL.createObjectURL(fileBlob);
        //Open the URL on new Window
        window.open(fileURL);
      }

      if (apiResponse?.status === 404) {
        //Create blob from file
        const fileBlob = new Blob(
          [apiResponse?.data?.attachment?.file?.originFileObj],
          {
            type: apiResponse?.data?.attachment?.file?.type,
          }
        );
        //Create URL from fileBlob
        const fileURL = URL.createObjectURL(fileBlob);
        //Open the URL on new Window
        window.open(fileURL);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const changeLeaveStatus = async (id, status, params) => {
    const apiResponse = await ServiceLeaveRequestApi.changeLeaveStatus(
      id,
      status,
      params
    );
    if (apiResponse.data.c === 201 && status === "Approved") {
      _this.show_modal(
        <Success
          title={"APPROVED"}
          description={"You approve the leave request."}
          onDismiss={() => {
            _this.hide_modal();
            leaveList();
          }}
        />
      );
    } else if (apiResponse.data.c === 201 && status === "Disapproved") {
      // _this.show_modal(
      // <Disapprove
      //   title={"DISAPPROVED"}
      //   description={"You disapproved the leave request."}
      //   onDismiss={() => {
      //     _this.hide_modal();
      //     leaveList()
      //   }}
      // />
      // )
      _this.hide_modal();
      leaveList();
    }
  };

  return (
    <div style={{ width: "800px" }}>
      <DataRow position={"center"}>
        <MainTitle status={data.status}>
          {data.status === "Approved"
            ? "Leave Request Approved"
            : data.status === "Disapproved"
            ? "Leave Request Disapproved"
            : "Leave Request Pending"}
          {/* {data.shiftDate} */}
        </MainTitle>
      </DataRow>
      <DataRow>
        <Col span={12}>
          <Title>Requestor</Title>
          <StyledFieldLabel>
            {data.requestor === "" ? "--" : data.requestor}
          </StyledFieldLabel>
        </Col>
        <Col span={12}>
          <Title>Leave Type</Title>
          <StyledFieldLabel>
            {data.leaveType === "vacationLeave"
              ? "Vacation Leave"
              : data.leaveType === "sickLeave"
              ? "Sick Leave"
              : data.leaveType === "bereavementLeave"
              ? "Bereavement Leave"
              : data.leaveType === "maternityLeave"
              ? "Maternity Leave"
              : data.leaveType === "paternityLeave"
              ? "Paternity Leave"
              : data.leaveType === "emergencyLeave"
              ? "Emergency Leave"
              : ""}
          </StyledFieldLabel>
        </Col>
      </DataRow>
      <DataRow>
        <Col span={12}>
          <Title>Start Date</Title>
          <StyledFieldLabel>
            {moment(data.startDate).format("DD/MM/YYYY")}
          </StyledFieldLabel>
        </Col>
        <Col span={12}>
          <Title>End Date</Title>
          <StyledFieldLabel>
            {moment(data.endDate).format("DD/MM/YYYY")}
          </StyledFieldLabel>
        </Col>
      </DataRow>
      <DataRow>
        <Col span={12}>
          <Title>Duration</Title>
          <StyledFieldLabel>{toTitleCase(data.duration)}</StyledFieldLabel>
        </Col>
        <Col span={12}>
          <Title>Attachment</Title>
          {data?.attachment.length >= 1 ? (
            <>
              {data.attachment.map((file) => (
                <StyledFileName onClick={() => handleViewDoctorFile(file)}>
                  {file?.originalFilename}
                </StyledFileName>
              ))}
            </>
          ) : (
            "No Attachment"
          )}
        </Col>
      </DataRow>
      <DataRow>
        <Col span={24}>
          <Title>Reason</Title>
          <StyledFieldLabel>
            {data.reason === "" ? "No Reason Filed" : data.reason}
          </StyledFieldLabel>
        </Col>
      </DataRow>
      {data.status === "Pending" && (
        <DataRow position={"right"}>
          <StyledButton approve onClick={() => approveLeave(data._id)}>
            Approve
          </StyledButton>
          <StyledButton
            disapprove
            onClick={() =>
              _this.show_modal(
                <Disapprove
                  title={"DISAPPROVED"}
                  description={"You are about to disapprove the leave request."}
                  onDismiss={() => {
                    disapproveLeave(data._id);
                  }}
                />
              )
            }
          >
            Disapprove
          </StyledButton>
        </DataRow>
      )}
    </div>
  );
};

export default LeaveDetailsModal;

const DataRow = styled(Row)`
  display: flex;
  align-items: start;
  justify-content: ${(props) =>
    props.position === "center"
      ? "center"
      : props.position === "right"
      ? "end"
      : "space-between"};
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 20px;
  margin-top: 35px !important;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const StyledFileName = styled(Typography.Paragraph)`
  color: #55ccff;
  font-weight: 400 !important;
  font-size: 1.3375rem !important;
  margin-bottom: 10px !important;
  cursor: pointer;
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  font-weight: 400 !important;
  font-size: 1.3375rem !important;
  margin-bottom: 10px !important;
`;

const Title = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 600 !important; //medium
  font-size: 1.3375rem !important; // 15px
  line-height: 1.3375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #000 !important;
  margin-bottom: 15px !important;
`;
const MainTitle = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.1375rem !important; // 15px
  line-height: 1.1375rem !important; // 23px
  letter-spacing: 0 !important;
  margin-bottom: 15px !important;
  background-color: ${(props) =>
    props.status === "Pending"
      ? "rgba(243, 234, 39, 0.5)"
      : props.status === "Disapproved"
      ? "#F7A9B2"
      : props.status === "Approved"
      ? "#C5E9B2"
      : ""};
  color: ${(props) =>
    props.status === "Pending"
      ? "#ED7A23"
      : props.status === "Disapproved"
      ? "#C32026"
      : props.status === "Approved"
      ? "#007755"
      : ""} !important;
  padding: 20px 70px;
`;

const StyledButton = styled(Button)`
  border-radius: 12px;
  border: none;
  padding: 40px !important;
  background-color: ${(props) =>
    props.approve ? "#02488D" : props.disapprove ? "#CC0404" : ""};
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.4375rem; // 15px
  // line-height: 1.4375rem; // 23px
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-left: 20px;
  color: #fff !important;
  padding-right: 20px;
  margin-left: 35px;
  &:hover {
    background-color: ${(props) =>
      props.approve ? "#02488D" : props.disapprove ? "#CC0404" : ""};
  }
`;
