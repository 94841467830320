import { Typography } from 'antd';
import React from 'react'
import styled from 'styled-components';

const Text = (props) => {
  return (
    <SyledTypographyTitleSecondary {...props}>
      {props.children}
    </SyledTypographyTitleSecondary>
  )
}

export default Text

const SyledTypographyTitleSecondary = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 400 !important; //medium
  line-height: 1.25rem !important; // 23p
  letter-spacing: 0 !important;
`;
