import React from 'react'
import UI from '../../shared/Components/UI/js';
import { View, TouchableOpacity, Text, StyleSheet } from "../../shared/custom-react-native";
import Layout from "../_components/Layout";

const PTR = ({ _this }) => {

  const { downloaded_ptr, downloaded_s2 } = _this.state;

  return (
    <UI.Row _this={_this} style={styles.row}>
      <View style={{ flex: 1 }}>
        <Layout.FilePicker
          _this={_this}
          title={"PTR Number"}
          additional={
            <View style={{ flexDirection: "row" }}>
              (JPEG/PNG)
              <TouchableOpacity
                onClick={() => {
                  _this.download_file_object(downloaded_ptr);
                }}
                style={styles.download_button}
              >
                <Text style={styles.download_text}>Download</Text>
              </TouchableOpacity>
            </View>
          }
          text={"Drop Your File Here"}
          state_name={"ptr"}
          onChange={(file) => {
            _this.auth_upload("ptr", file);
          }}
          allowed_files={["jpg", "jpeg", "png"]}
          display_type={"filename_size"}
        />
      </View>
      {UI.box(20)}
      <View style={{ flex: 1 }}>
        <Layout.FilePicker
          _this={_this}
          title={"S2 Number"}
          additional={
            <View style={{ flexDirection: "row" }}>
              (JPEG/PNG)
              <TouchableOpacity
                onClick={() => { 
                  _this.download_file_object(downloaded_s2);
                }}
                style={styles.download_button}
              >
                <Text style={styles.download_text}>Download</Text>
              </TouchableOpacity>
            </View>
          }
          text={"Drop Your File Here"}
          state_name={"s2"}
          onChange={(file) => {
            _this.auth_upload("s2", file);
          }}
          allowed_files={["jpg", "jpeg", "png"]}
          display_type={"filename_size"}
        />
      </View>
      <View style={{ flex: 1 }}></View>
    </UI.Row>
  )
}

const styles = StyleSheet.create({
  row: {
    marginTop: 20
  },
  download_text: {
    color: "white",
    fontWeight: "bold",
    fontSize: 12,
  },
  download_button: {
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: UI.colors.primary,
    marginLeft: 10,
    borderRadius: 5,
    height: 30,
    marginTop: -5,
  }
});

export default PTR;