import React from "react";

const ChevronRightSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.076"
      height="14.357"
      viewBox="0 0 8.076 14.357"
      className="w-full"
    >
      <path
        id="drop_down"
        data-name="drop down"
        d="M.216.721A.728.728,0,0,1,1.178.648l.083.073L7.178,6.757,13.1.721a.728.728,0,0,1,.962-.073l.083.073a.765.765,0,0,1,.072.981l-.072.084L7.7,8.355a.728.728,0,0,1-.962.073l-.083-.073L.216,1.786A.764.764,0,0,1,.216.721Z"
        transform="translate(-0.5 14.357) rotate(-90)"
        fill="#fff"
      />
    </svg>
  );
};

export default ChevronRightSvg;
