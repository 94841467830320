import Icon from "@ant-design/icons";
import React from "react";

const FieldGenderSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.917"
    height="19.064"
    viewBox="0 0 17.917 19.064"
  >
    <path
      id="gender"
      d="M217.143,167.332c0-2.87,3.559-6.51,8.953-6.51s8.964,3.64,8.964,6.51c0,1.237-.882,1.825-2.677,1.825H219.81C218.015,169.157,217.143,168.569,217.143,167.332Zm15.463-.091c.223,0,.3-.081.3-.253,0-1.47-2.464-4.239-6.814-4.239s-6.8,2.768-6.8,4.239c0,.172.081.253.294.253Zm-10.981-12.382a4.491,4.491,0,1,1,4.482,4.837A4.635,4.635,0,0,1,221.625,154.859Zm6.967-.02a2.505,2.505,0,1,0-4.969.01,2.518,2.518,0,1,0,4.969-.01Z"
      transform="translate(-217.143 -150.093)"
      fill="#4fc2ed"
    />
  </svg>
);

const FieldGenderIcon = (props) => {
  return <Icon component={FieldGenderSvg} {...props} />;
};

export default FieldGenderIcon;
