import React, { useState, useEffect } from "react";
import { Image, StyleSheet, Text, View } from "react-pdf-renderer-v2";
import UI from "../../../../shared/Components/UI/js";

const Footer = ({ appointment }) => {
  const [signature, setSignature] = useState(null);

  const doctor = appointment.practitioner;

  const doctorName =
    doctor?.name[0].given +
    " " +
    (doctor?.name[0].middle ?? "") +
    " " +
    doctor?.name[0].family;

  const download_file = async (path, state) => {
    if (path) {
      const image_link = await UI.download_image(path, "doctors-onboarding");
      setSignature(image_link);
    }
  };

  useEffect(() => {
    download_file(appointment.practitioner.signature);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={styles.footer} fixed>
      <View style={{ ...styles.row }}>
        <View
          style={{
            ...styles.text,
            marginLeft: "auto",
            marginTop: 20,
            marginBottom: 20,
            marginRight: 20,
          }}
        >
          <View style={{ display: "flex", alignItems: "center" }}>
            <Image src={signature} style={{ width: 100 }} />
            <Text
              style={{
                textDecoration: "underline",
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dr. {doctorName}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
          </View>
          <Text style={{ marginTop: 10 }}>
            PRC No.: {appointment.practitioner.prcLicenseNumber}
          </Text>
          <Text style={{ marginTop: 3 }}>
            PTR: {appointment.practitioner.ptrNumber}
          </Text>
          <Text style={{ marginTop: 3 }}>
            S2: {appointment.practitioner.s2Number}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...styles.row,
          borderTop: "1px solid black",
          padding: 16,
        }}
      >
        <View style={styles.footerRight}>
          <Text
            style={{
              marginTop: 8,
              color: "#004f99",
              fontSize: 18,
            }}
          >
            powered by
          </Text>
          <Text>support@mwell.com.ph</Text>
          <Text>Hotline: +632-8888-0888</Text>
          <Image
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: 130,
            }}
            src="/img/login_logomwell.png"
          />
        </View>
        <Text
          style={{
            marginLeft: 12,
          }}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
  },
  footer: {
    position: "absolute",
    bottom: 50,
    left: 0,
    right: 0,
    fontSize: 14,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  itemsStart: {
    alignItems: "flex-end",
  },
  footerRight: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: 270,
  },
});

export default Footer;
