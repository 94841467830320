import Icon from "@ant-design/icons";
import React from "react";

const FieldBirthDateSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.331"
    height="19.887"
    viewBox="0 0 18.331 19.887"
  >
    <path
      id="birthdate"
      d="M209.21,122.164v-5.451h-.047a1.892,1.892,0,0,1-1.206-2.021V112.4a1.97,1.97,0,0,1,2.086-2.179h1.419a2.829,2.829,0,0,1-.8-2,3.249,3.249,0,0,1,3.384-3.227,3.122,3.122,0,0,1,3.078,2.253,3.122,3.122,0,0,1,3.078-2.253,3.246,3.246,0,0,1,3.375,3.227,2.831,2.831,0,0,1-.8,2H224.2a1.952,1.952,0,0,1,2.086,2.179v2.29a1.891,1.891,0,0,1-1.206,2.021h-.046v5.451a2.466,2.466,0,0,1-2.745,2.716H211.964A2.473,2.473,0,0,1,209.21,122.164Zm6.9-10.124H210.6c-.427,0-.612.194-.612.621v1.761c0,.427.185.622.612.622h5.508Zm0,4.83H211.24v5.063a1,1,0,0,0,1.1,1.085h3.765Zm.056-7.853a1.964,1.964,0,0,0-1.9-2.124,1.509,1.509,0,0,0-1.651,1.568c0,.991.8,1.761,2.2,1.761h1.354Zm3.282,1.205c1.391,0,2.188-.769,2.188-1.761a1.505,1.505,0,0,0-1.641-1.568,1.97,1.97,0,0,0-1.91,2.124v1.205Zm4.209,4.822c.427,0,.6-.168.6-.594v-1.79c0-.427-.176-.621-.6-.621h-5.517v3Zm-1.753,7.973a1,1,0,0,0,1.1-1.085V116.87h-4.867v6.147Z"
      transform="translate(-207.958 -104.993)"
      fill="#4fc2ed"
    />
  </svg>
);

const FieldBirthDateIcon = (props) => {
  return <Icon component={FieldBirthDateSvg} {...props} />;
};

export default FieldBirthDateIcon;
