import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import
// TouchableOpacity,
 {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
// import CustomDatatable from "../_components/CustomDatatable";
// import EntryPicker from "../_components/EntryPicker";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
// import { ComingSoon } from "../_components/Modal";
// import RegisteredDoctors from "./RegisteredDoctors";
// import RegisteredPatients from "./RegisteredPatients";
// import Statistics from "./Statistics";

const context = "advocacy";
const Constants = new APIConstants();

export default class AdvocacyView extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Advocacy List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
      advocacy: null,
      doctors:[],
      patients:[],
      doctor_entries: 10,
      patient_entries: 10,
    });
  }

  onCreate = () => {
    this.getRegisteredDoctors()
    this.getRegisteredPatients()
  }

  getRegisteredDoctors = (params) => {
    console.log(params)
    const data = mem.get("view_advocacy");
    const advocacy = JSON.parse(data);
    let page = params?.current_page || 1,
    per_page = params?.per_page || this.state.doctor_entries,
    offset = (page - 1) * per_page,
    paginatedItems = advocacy.doctor.slice(offset).slice(0, params?.per_page ? params?.per_page : 10),
    total_pages = Math.ceil(advocacy.doctor.length / per_page);
    console.log(total_pages, paginatedItems)
    this.setState({
      doctors: paginatedItems,
    }
    , () => {
      // this.reload_table('advocacy_doctors')
      UI.get_entries(
        paginatedItems,
        params?.per_page || this.state.doctor_entries,
        "advocacy_doctors",
        params?.current_page || 1,
        {
          total: advocacy.doctor.length,
          pages: total_pages
        }
      );
    })
  }

  getRegisteredPatients = (params) => {
    const data = mem.get("view_advocacy");
    const advocacy = JSON.parse(data);
    let page = params?.current_page || 1,
    per_page = params?.per_page || this.state.patient_entries,
    offset = (page - 1) * per_page,
    paginatedItems = advocacy.patient.slice(offset).slice(0, params?.per_page ? params?.per_page : 10),
    total_pages = Math.ceil(advocacy.patient.length / per_page);

    this.setState({
      patients: paginatedItems,
    }
    , () => {
      UI.get_entries(
        advocacy.patient,
        params?.per_page || this.state.patient_entries,
        "advocacy_patients",
        params?.current_page || 1,
        {
          total: advocacy.patient.length,
          pages: total_pages
        }
      );
    }
    )
  }

  render() {
    const _this = this;
    const {
      width,
    } = _this.state;
    const data = mem.get("view_advocacy");
    const advocacy = JSON.parse(data);
    const start_date = moment(advocacy.startDate).format('MM/DD/YYYY');
    const valid_until = moment(advocacy.endDate).format('MM/DD/YYYY');
    const image = `${Constants.STORAGE_BASE_URL}?path=${advocacy.imagePath}` ?? UI.SAMPLE_PRODUCT 

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                marginRight: width < 1250 ? 0 : 20,
                marginBottom: width < 1250 ? 10 : 0,
                justifyContent:'center',
                alignItems:'center'
              }}
            >
              <View style={styles.fl_ai}>
                <img
                  width={350}
                  height={400}
                  style={{ borderRadius: 5 }}
                  src={advocacy.imagePath ? image : UI.SAMPLE_PRODUCT}
                  alt=""
                />

              </View>
            </View>

            <View style={{ flex: 1, justifyContent:'space-around' }}>

            <View style={{ flexDirection: 'column', alignItems: "start", justifyContent:'space-between' }}>

              <View style={{ flexDirection: "row", alignItems: "center"}}>
                <Text style={{...styles.details_name, fontSize:'20px'}}>{advocacy.title}</Text>
                {UI.box(20)}
              </View>

              {UI.box(10)}

              <View style={{ flexDirection: "row", alignItems: "center"}}>
                <Layout.Btn
                  color={UI.colors.primary}
                  onClick={() => {
                    mem.save("edit_advocacy", JSON.stringify(advocacy));
                    mem.save("advocacy_id", advocacy._id);
                    UI.goTo("/advocacy/edit");
                  }}
                  text={"UPDATE ADVOCACY"}
                />

                {UI.box(20)}

                <Layout.Btn
                  color={UI.colors.primary}
                  onClick={() => {
                    console.log(advocacy)
                    mem.save("edit_questionnaire", JSON.stringify(advocacy));
                    mem.save("advocacy_id", advocacy._id);
                    UI.goTo("/advocacy/edit-questionnaire");
                  }}
                  text={"UPDATE QUESTIONNAIRE"}
                />

                {UI.box(20)}

                <Layout.Btn
                  color={UI.colors.primary}
                  onClick={() => {
                    mem.save("edit_schedule", JSON.stringify(advocacy));
                    UI.goTo("/advocacy/edit-schedule");
                  }}
                  text={"UPDATE SCHEDULE"}
                />
              </View>

            </View>

            {UI.box(20)}

            </View>
          </UI.Row>

          {UI.box(20)}
          
          <View style={{border:'2px dashed #C9C9C9', padding:'18px', borderRadius:'12px'}}>
            <Text style={{color:UI.colors.primary, fontWeight:600, fontSize:'18px'}}>Advocacy Description</Text>

            {UI.box(10)}

            <UI.Row _this={_this}>
              <UI.Row breakpoint={970} _this={_this} style={{ flex: 1, justifyContent:'space-between', alignItems:'center' }}>
                <View style={{textAlign:'center'}}>
                  <Text style={{...styles.details_name, display:'flex', fontSize:'18px'}}>
                    <Image
                      height={25}
                      width={25}
                      tintColor={UI.colors.primary}
                      source={UI.CALENDAR_3}
                    />
                  {UI.box(5)}
                    {start_date}
                  </Text>
                  <Text style={{color:UI.colors.unset, fontWeight:600}}>Start Date</Text>
                </View>

                {UI.box(20)}

                <View style={{textAlign:'center'}}>
                  <Text style={{...styles.details_name, display:'flex', fontSize:'18px'}}>
                    <Image
                      height={25}
                      width={25}
                      tintColor={UI.colors.primary}
                      source={UI.CALENDAR_3}
                    />
                  {UI.box(5)}
                    {valid_until}
                  </Text>
                  <Text style={{color:UI.colors.unset, fontWeight:600}}>End Date</Text>
                </View>

                {UI.box(20)}

                <View style={{textAlign:'center'}}>
                  <Text style={{...styles.details_name, display:'flex', fontSize:'18px'}}>
                    <Image
                      height={25}
                      width={25}
                      tintColor={UI.colors.primary}
                      source={UI.SETTINGS}
                    />
                  {UI.box(5)}
                  {advocacy.healthpass?.title ?? '0001212021012'}
                    
                  </Text>
                  <Text style={{color:UI.colors.unset, fontWeight:600}}>Healthpass Name</Text>
                  
                </View>

                {UI.box(20)}

                <View style={{textAlign:'center'}}>
                  <Text style={{...styles.details_name, display:'flex', color:advocacy.status === "Active" ? UI.colors.active : UI.colors.inactive, fontSize:'18px'}}>
                    <Image
                      height={25}
                      width={25}
                      tintColor={UI.colors.primary}
                      source={UI.FILE}
                    />
                  {UI.box(5)}
                    {advocacy.status}
                  </Text>
                  <Text style={{color:UI.colors.unset, fontWeight:600}}>Activity Status</Text>
                  
                </View>

                <View style={{textAlign:'center'}}>
                  <Text style={{...styles.details_name, display:'flex', color:advocacy.comingSoon ? UI.colors.inactive : UI.colors.active, fontSize:'18px'}}>
                    <Image
                      height={25}
                      width={25}
                      tintColor={UI.colors.primary}
                      source={UI.FILE}
                    />
                  {UI.box(5)}
                    {advocacy.comingSoon ? "Not Available" : "Available"}
                  </Text>
                  <Text style={{color:UI.colors.unset, fontWeight:600}}>Advocacy Availability</Text>
                  
                </View>

              </UI.Row>
            </UI.Row>

            {UI.box(20)}
            
            <Text style={{color:UI.colors.primary, fontWeight:600, fontSize:'18px'}}>Advocacy Schedule</Text>

            {UI.box(10)}

            <UI.Row breakpoint={970} _this={_this} style={{ flex: 1, gap: 50, alignItems:'center' }}>
              {
                advocacy.advocacyschedulecms?.map(res => {
                  return (
                    <>
                      <View style={{textAlign:'center'}}>
                        <Text style={{...styles.details_name, display:'flex', fontSize:'18px'}}>
                          <Image
                            height={25}
                            width={25}
                            tintColor={UI.colors.primary}
                            source={UI.CALENDAR_3}
                          />
                        {UI.box(5)}
                          {res.date}
                        </Text>
                        <Text style={{color:UI.colors.secondary, fontWeight:600, marginLeft: 30}}>AM {res.morning_start_time} – {res.morning_end_time} | PM {res.afternoon_start_time} – {res.afternoon_end_time}</Text>
                      </View>
                    </>
                  )
                })
              }
              
            </UI.Row>

            {UI.box(20)}
            <Text style={{color:UI.colors.primary, fontWeight:600, fontSize:'18px'}}>Advocacy Description</Text>
            {UI.box(10)}
            <Text style={{fontWeight:700, fontSize:'16px'}}>
              {advocacy.description}
            </Text>
          </View>
        </View>
        
        <View>        
          {UI.box(20)}
        </View>

        {/* <Statistics _this={_this}/>

        <RegisteredDoctors _this={_this} advocacy={advocacy}/>

        <View>        
        </View>

        <RegisteredPatients _this={_this} advocacy={advocacy}/> */}

      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    border:"2px dashed #C9C9C9",
    borderRadius:"12px",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
});
