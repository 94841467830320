import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  ImageBackground,
  ImageFit,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import "./css.css";
import ProductDescription from "./ProductDescription";

const context = "merchant";
export default class EcommerceAddProduct extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Products",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      image: UI.PRODUCT_BOX,
      image_file: null,
      did_upload: false,

      banner: UI.PRODUCT_BOX,
      banner_file: null,
      did_upload_banner: false,

      merchant: "",
      name: "",
      price: "",
      imagePath: "",
      quantity: "",
      description: "",
      purchase_limit: "",
      isComingSoonButton: "",
      tag: "",
      tags: [],
    });
  }

  onCreate = async () => {};

  submit = async () => {
    UI.clear_errors();
    //no merchant yet
    const {
      image_file,
      banner_file,
      name,
      sku,
      category,
      price,
      sale_price,
      // mwell_service,
      status,
      purchase_limit,
      // stock,
      description,
      // other_info,
      // sku_link,
      // tags,
      // merchant,
      // imagePath,
      quantity,
      isComingSoonButton,
    } = this.state;

    if (!name) {
      await UI.error_form("name", "Product Name is required.");
    }
    if (!price) {
      await UI.error_form("price", "Product Price is required.");
    }
    if (!status) {
      await UI.error_form("status", "Status is required.");
    }
    if (!isComingSoonButton) {
      await UI.error_form("isComingSoonButton", "Coming Soon is required.");
    }

    if (!description) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Product Description is required"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      return;
    }
    if (!purchase_limit) {
      await UI.error_form("purchase_limit", "Purchase Limit is required.");
    }

    if (!image_file) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Invalid Product Picture"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      return;
    }

    if (!banner_file) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Invalid Product Banner"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      return;
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    this.show_loading();

    // let base64Description = Buffer.from(description);

    UI.webRequest({
      method: "post",
      url: "admin/products",
      params: {
        image: image_file,
        bannerImage: banner_file,
        name: name,
        sku: sku,
        category: category,
        price: price,
        salePrice: sale_price,
        // mwellService: mwell_service,
        status: status,
        limit: purchase_limit,
        // stock: stock,
        description: description,
        // otherInformation: other_info,
        // skuWebLink: sku_link,
        inventoryCount: parseInt(quantity) ?? 0,
        quantity: parseInt(quantity) ?? 0,
        isComingSoon: isComingSoonButton === "true" ? true : false,
        // tags: tags
      },
      isMultiPart: true,
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.error;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        UI.clear_forms();
        UI.set_select_value("status", "");

        this.setState({
          image: UI.PRODUCT_BOX,
          image_file: null,
          did_upload: false,

          banner: UI.PRODUCT,
          banner_file: null,
          did_upload_banner: false,
        });
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully added the product."}
            onDismiss={() => {
              this.hide_modal();
              UI.goBack();
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  upload_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        image: file.uri,
        image_file: file.file,
        did_upload: true,
      },
      () => {
        //this.auth_upload();
      }
    );
  };

  upload_banner = async () => {
    const file = await UI.get_image();

    this.setState({
      banner: file.uri,
      banner_file: file.file,
      did_upload_banner: true,
    });
  };

  auth_upload = async () => {
    UI.upload_image({
      url: "admin/products/upload",
      file: this.state.image_file,
      onFail: (err) => {},
      onSuccess: (path, response) => {
        this.setState({
          imagePath: path,
        });
      },
    });
  };

  render() {
    const _this = this;
    const {
      // height,
      // width,
      // isMobile,
      did_upload,
      did_upload_banner,
    } = _this.state;
    const Constants = new APIConstants();

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Add New Product
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to complete registration
            </Text>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <View style={{ height: 120, width: 120 }}>
              <Layout.FilePicker
                _this={_this}
                title={""}
                additional={""}
                isRequired={false}
                text={""}
                state_name={"product_image"}
                onChange={async (file) => {
                  const uri = await UI.get_uri_from_file(file);
                  this.setState(
                    {
                      image: uri,
                      image_file: file,
                      did_upload: true,
                    },
                    () => {
                      //this.auth_upload();
                    }
                  );
                }}
                height={120}
                component={
                  <ImageBackground
                    style={{ height: 120, width: 120, borderRadius: 10 }}
                    source={this.state.image}
                    imageFit={ImageFit.CONTAIN}
                  />
                }
              />
            </View>
            {UI.box(20)}

            <View style={{ flex: 1.5, justifyContent: "center" }}>
              <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
              >
                {did_upload
                  ? "Product Picture Uploaded."
                  : "No Product Picture Uploaded."}{" "}
                Recommended Size: 500 px x 500 px <span className="text-red-500 text-base">*</span>
              </Text>
              {UI.box(10)}

              <Layout.Btn
                color={UI.colors.header}
                text={"Upload Product Picture"}
                onClick={() => {
                  this.upload_picture();
                }}
                width={200}
              />
            </View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <View style={{ height: 120, width: 120 }}>
              <Layout.FilePicker
                _this={_this}
                title={""}
                additional={""}
                isRequired={false}
                text={""}
                state_name={"product_banner"}
                onChange={async (file) => {
                  const uri = await UI.get_uri_from_file(file);
                  this.setState(
                    {
                      banner: uri,
                      banner_file: file,
                      did_upload_banner: true,
                    },
                    () => {
                      //this.auth_upload();
                    }
                  );
                }}
                height={120}
                component={
                  <ImageBackground
                    style={{ height: 120, width: 120, borderRadius: 10 }}
                    source={this.state.banner}
                    imageFit={ImageFit.CONTAIN}
                  />
                }
              />
            </View>
            {UI.box(20)}

            <View style={{ flex: 1.5, justifyContent: "center" }}>
              <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
              >
                {did_upload_banner
                  ? "Product Banner Uploaded."
                  : "No Product Banner Uploaded."}{" "}
                Recommended Size: 930 px x 375 px <span className="text-red-500 text-base">*</span>
              </Text>
              {UI.box(10)}

              <Layout.Btn
                color={UI.colors.header}
                text={"Upload Product Banner"}
                onClick={() => {
                  this.upload_banner();
                }}
                width={200}
              />
            </View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Product Name"}
              placeholder={"Enter Product Name"}
              state_name={"name"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.name}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Product SKU"}
              placeholder={"Enter Product SKU"}
              state_name={"sku"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.price}
            />
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Product Category"}
              placeholder={"Select Product Category"}
              state_name={"category"}
              isRequired={false}
            >
              {Constants.product_category?.map((category) => {
                const value =
                  category === "Labs and Clinic" ? "Laboratory" : category;
                const option = category;

                return <option value={value}>{option}</option>;
              })}
            </Layout.SelectPicker>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Product Price"}
              placeholder={"Enter Product Price"}
              state_name={"price"}
              isNumber={true}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.price}
              prefix={"PHP"}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Sale Price(if applicable)"}
              placeholder={"Enter Product Sale Price"}
              state_name={"sale_price"}
              isNumber={true}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.price}
              prefix={"PHP"}
            />
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"mWell Service"}
              placeholder={"Select mWell Service"}
              state_name={"mwell_service"}
              isRequired={false}
            >
              <option value="commissionRate">Commission Rate</option>
            </Layout.SelectPicker>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Product Status"}
              isRequired={true}
              state_name={"status"}
            >
              <option value="">Set Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Purchase Limit (per transaction)"}
              placeholder={"Enter Product Purchase Limit"}
              state_name={"purchase_limit"}
              isNumber={true}
              isRequired={true}
              style={{ flex: 1 }}
              maxLength={UI.Constants.limits.price}
            />

            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Product Inventory/Stock"}
              placeholder={"Enter Product Inventory/Stock"}
              state_name={"quantity"}
              isNumber={true}
              style={{ flex: 1 }}
              maxLength={UI.Constants.limits.price}
            />
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Coming Soon"}
              isRequired={true}
              state_name={"isComingSoonButton"}
            >
              <option value="">Set Status</option>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <div style={{ width: 1150, height: 350 }}>
              <ProductDescription _this={_this} />
            </div>
          </UI.Row>
          {/* {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Other Information"}
              placeholder={"Enter Other Information Here"}
              state_name={"other_info"}
              style={{ flex: 1 }}
              height={150}
              isRequired={false}
              type={"big"}
              // maxLength={UI.Constants.limits.description}
            />
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"SKU Web Link"}
              placeholder={"Enter Link"}
              state_name={"sku_link"}
              style={{ flex: 1 }}
            />
            
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>

            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row> */}

          {/* {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <form onSubmit={(e) => {
              e.preventDefault()
              // this.setState({tags: tags})
              const item = this.state.tags
              item.push(this.state.tag)
              this.setState({tags: item})
              UI.clear_forms()
            }}>
              <InputForm
                _this={_this}
                title={"Tags"}
                placeholder={"Enter to add Tags"}
                state_name={"tag"}
                // state_name={"sku_link"}
                style={{ flex: 1 }}
              />
            </form>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row>

          {UI.box(20)}

          <div className="flex flex-wrap">
        
            { this.state.tags.map(tag => { 
                return (
                  <Text 
                    style={{
                      color:'white',
                      marginRight:10,
                      marginBottom:10,
                      padding:8, 
                      background: 'rgb(167 173 173)',
                      fontWeight:'bold',
                      borderRadius:'5px', 
                    }}
                  >
                      {tag} 
                      <strong className='icd_10_remove' style={{marginLeft: 5,  fontSize:17, cursor:'pointer'}} onClick={(e) => {
                        const tags = this.state.tags
                        const res = tags.filter(item => item !== tag)
                        this.setState({tags: res})
                        // consultation.icd = res
                        // setconsultationNotes({...consultationNotes, consultation});
                      }
                      }>x</strong>
                  </Text>
                )
              }) 
            }
         
          </div> */}

          {UI.box(20)}

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.header}
              onClick={() => {
                this.submit();
              }}
              text={"ADD PRODUCT"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 18,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
