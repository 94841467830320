import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import "./css.css";
export default class Statistics extends Component {
   // **Commented out, currently not used. No need to define empty constructor
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const _this = this.props._this;
    // **Commented out, currently not used
    // const { height, width, isMobile } = _this.state;

    return (
      <UI.Row
        breakpoint={1000}
        _this={_this}
        style={{ width: "100%", marginBottom: 20 }}
      >
        <View style={styles.earnings_container}>
          <View style={{ flex: 1, minWidth: 160 }}>
            <Text style={styles.header_1}>Total Booked Consults</Text>
            <Text style={styles.main_header}>100</Text>
            {UI.box(10)}
            <Text style={styles.header_1}>Total On-Call Consults</Text>
            <Text style={styles.main_header}>100</Text>
          </View>
          {UI.box(25)}
          <Image
            height={100}
            width={100}
            tintColor={"transparent"}
            source={UI.CALENDAR_1}
          />
        </View>
        {UI.box(20)}
        <View
          style={{
            padding: 20,
            borderRadius: 5,
            backgroundColor: "white",
            ...Shadow._3(),
            flex: 1,
          }}
        >
          <UI.Row breakpoint={1170} _this={_this} style={{ width: "100%" }}>
            <Text style={styles.box_header_text}>
              Site Stats: December 10, 2021
            </Text>
            {UI.box(10)}
            <Layout.SortFilter
              btn_1={{
                text: "Year",
                onClick: (text) => {},
              }}
              btn_2={{
                text: "Month",
                onClick: (text) => {},
              }}
              btn_3={{
                text: "Day",
                onClick: (text) => {},
              }}
              onChange={(val) => {
                _this.setState({
                   // **Unexpected string concatenation of literals.eslint
                  // **by changing "sort_filter_" + "site_stats" to `${"sort_filter_"}${"site_stats"}`
                  [`${"sort_filter_"}${"site_stats"}`]: val,
                });
              }}
              _this={_this}
              state_name={"site_stats"}
            />
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={1175} _this={_this}>
            <UI.Row _this={_this} breakpoint={1300}>
              <DashItem
                _this={_this}
                icon={UI.IC_DASH_1}
                num={"000"}
                color={UI.colors.primary}
                text={"Successful Sessions"}
              />
              {UI.box(10)}
              <DashItem
                _this={_this}
                icon={UI.IC_DASH_7}
                num={"000"}
                color={"#004F99"}
                text={"Upcoming Sessions"}
              />
            </UI.Row>
            {UI.box(10)}
            <UI.Row _this={_this} breakpoint={1300}>
              <DashItem
                _this={_this}
                icon={UI.IC_DASH_5}
                num={"000"}
                color={"#E6BA00"}
                text={"Missed Sessions"}
              />
              {UI.box(10)}
              <DashItem
                _this={_this}
                icon={UI.IC_DASH_8}
                num={"000"}
                color={"#D90000"}
                text={"Cancelled Sessions"}
              />
            </UI.Row>
          </UI.Row>
        </View>
      </UI.Row>
    );
  }
}

const DashItem = (
  props = { _this: null, icon: "", num: "", text: "", color: "" }
) => {
  return (
    <View style={{ flexDirection: "row", flex: 1 }}>
      <Image
        height={60}
        width={60}
        tintColor={"transparent"}
        source={props.icon}
      />

      <View style={{ marginLeft: 15 }}>
        <Text
          style={{ ...styles.main_header, color: props.color, fontSize: 22 }}
        >
          {props.num}
        </Text>
        <Text style={{ ...styles.header_2, fontSize: 12 }}>{props.text}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sort_filter_text: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#BFBFBF",
  },
  sort_filter: {
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 2,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#C9C9C9",
  },
  box_header_subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#C9C9C9",
    alignSelf: "flex-end",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.secondary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    backgroundColor: "white",
    padding: 20,
    ...Shadow._3(),
    flexDirection: "row",
    maxHeight: 180,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
