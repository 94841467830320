import UI from "../../shared/Components/UI/js";
import ServiceApi from "../service";

class DPAppointmentsApi {
  baseURL = "doctors_onboarding";

  async getTeleconsultationRecords(params) {
    const response = await ServiceApi.get(this.baseURL + `/appointments/patient/teleconsult-records?${params}`);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }
}

export default new DPAppointmentsApi();
