import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  ImageBackground,
  ImageFit,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import { DownloadPDF } from "../_components/CustomPDF";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";
import moment from "moment";

const context = "admin";

export default class AdminViewTransaction extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Transactions",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      item: {
        _id: "",
        product: {
          _id: "",
          name: "",
          price: "",
          imagePath: "",
        },
        merchant: null,
        user: {
          _id: "",
          name: "",
          firstName: "",
          lastName: "",
          email: "",
          id: "",
        },
        method: "",
        referenceNumber: "",
        paymentId: "",
        currency: "",
        amount: "",
        quantity: "",
        status: "",
        createdAt: "",
        __v: 0,
      },

      customer_image: UI.TRANSACTION_USER,

      product_image: UI.PRODUCT_BOX,

      is_rendering: false,
    });
  }

  onCreate = async () => {
    const data = mem.get("view_transaction");

    if (data) {
      const transaction = JSON.parse(data);
      this.setState(
        {
          item: transaction,
        },
        this.load_data
      );
    }
  };

  load_data = async () => {
    const { item } = this.state;

    setTimeout(() => {
      this.reload_table("transaction");
    }, 100);

    this.customer(item);
    this.product(item);
  };

  customer = async (item) => {
    const image = await UI.download_image(
      item.user?.imagePath,
      "ecommerce",
      UI.TRANSACTION_USER
    );
    this.setState({
      customer_image: image,
    });
  };
  product = async (item) => {
    const image = await UI.download_image(
      item.product?.imagePath,
      "ecommerce",
      UI.PRODUCT_BOX
    );
    this.setState({
      product_image: image,
    });
  };

  download = async (type) => {
    this.show_loading("", "Processing Request...", true);
    this.setState(
      {
        is_rendering: true,
      },
      () => {
        UI.execute_resizes(this);
        setTimeout(() => {
          DownloadPDF(
            "main_transaction_content",
            "Transaction_" + this.state.item.referenceNumber,
            type
          );
          this.hide_loading();
          this.setState(
            {
              is_rendering: false,
            },
            () => {
              setTimeout(() => {
                UI.execute_resizes(this);
              }, 100);
              setTimeout(() => {
                UI.execute_resizes(this);
              }, 200);
              setTimeout(() => {
                UI.execute_resizes(this);
              }, 300);
            }
          );
        }, 300);
      }
    );

    //DownloadPDF("main_transaction_content")
  };

  renderCustomerDetails = (item) => {

    if (item.formData?.formId) {
      return <>
        <Text style={styles.patient_name}>
          { item.formData?.firstName ? item.formData?.firstName + " " + item.formData?.lastName : "N/A"}
        </Text>
        <Text style={styles.patient_details}>{item.formData?.emailAddress}</Text>
        <Text style={styles.patient_details}>
          {item.formData?.mobileNumber ?? ""}
        </Text>
      </>
    } else if (item.formDataLaboratory) {
      return <>
        <Text style={styles.patient_name}>
          { item.formDataLaboratory?.firstName ? item.formDataLaboratory?.firstName + " " + item.formDataLaboratory?.lastName : "N/A"}
        </Text>
        <Text style={styles.patient_details}>{item.formDataLaboratory?.email}</Text>
        <Text style={styles.patient_details}>
          {item.formDataLaboratory?.phone ?? ""}
        </Text>
      </>
    } else {
      return <>
        <Text style={styles.patient_name}>
          { item.user?.firstName ? item.user?.firstName + " " + item.user?.lastName : "N/A"}
        </Text>
        <Text style={styles.patient_details}>{item.user?.email}</Text>
        <Text style={styles.patient_details}>
          {item.user?.phone ?? ""}
        </Text>
      </>
    }

  }

  render() {
    const _this = this;
    const {
      // height,
      width,
      // isMobile,
      item,
      customer_image,
      product_image,
      is_rendering,
    } = _this.state;

    const fixed_style = is_rendering
      ? {
          width: 874.54541015625,
          height: 539.84375,
          flex: null,
          ...Shadow._0(),
        }
      : {};

    return (
      <MainLayout context={context} _this={_this}>
        <UI.Row breakpoint={1220} _this={_this} style={{ width: "100%" }}>
          <View
            id={"main_transaction_content"}
            style={{
              ...styles.main_box_content,
              flex: 1,
              padding: 0,
              ...fixed_style,
            }}
          >
            <UI.Row
              _this={_this}
              style={{
                width: "100%",
                padding: 20,
                justifyContent: "space-between",
              }}
              breakpoint={is_rendering ? 10 : 930}
            >
              <ImageBackground
                style={{ height: 90 * 0.45, width: 537 * 0.45 }}
                source={UI.METRO_WELL}
                imageFit={ImageFit.CONTAIN}
              />
              {UI.box(10)}

              <View style={{ alignItems: "flex-end" }}>
                <Text
                  style={{ ...styles.box_header_text, textAlign: "center" }}
                >
                  Trasaction {item.referenceNumber}
                </Text>

                <View style={styles.transaction_date_container}>
                  <img
                    style={{
                      height: 25,
                      width: 25,
                      marginTop: is_rendering ? 12 : 0,
                    }}
                    src={UI.DETAILS_AGE}
                    alt="details age"
                  />
                  <Text style={styles.transaction_date}>
                    Transaction Date:{" "}
                    {moment(item.createdAt).format("YYYY/MM/DD")}
                  </Text>
                </View>
              </View>
            </UI.Row>

            <View style={styles.border_line}></View>

            <UI.Row
              breakpoint={is_rendering ? 10 : 940}
              style={{ width: "100%", padding: 20 }}
              _this={_this}
            >
              <View
                id={"sample"}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <img style={{ height: 80, width: 80 }} src={customer_image} alt="customer" onError={(err) => _this.setState({ customer_image: UI.TRANSACTION_USER })}/>
                {UI.box(15)}

                <View>
                  {
                    _this.renderCustomerDetails(item)
                  }
                </View>
                
              </View>

              <View style={{ flex: 1 }}></View>

              <View>
                {UI.box(width > 940 ? 0 : 20)}

                <UserDetail
                  icon={UI.CONSULT_PRICE}
                  name={"Total"}
                  value={"Php " + UI.pad(item.amount, 3, true)}
                  is_rendering={is_rendering}
                />
                <UserDetail
                  icon={UI.PAYMENT_METHOD}
                  name={"Payment Method"}
                  value={item.method}
                  is_rendering={is_rendering}
                />
                <UserDetail
                  icon={UI.BOOKING_DATE}
                  name={"Booking Date"}
                  value={moment(item.createdAt).format("YYYY/MM/DD")}
                  is_rendering={is_rendering}
                />
              </View>
            </UI.Row>

            <CustomDatatable
              state_name={"transaction"}
              _this={_this}
              columns={[
                // { ID: "id" },
                { Preview: "preview" },
                { "Product Name": "product_name" },
                { Qty: "quantity" },
                { Price: "price" },
              ]}
              data={[
                {
                  id: item.product?._id,
                  preview: (
                    <img
                      style={{ height: 35, width: 35 }}
                      src={product_image}
                      alt="product"
                    />
                  ),
                  product_name: item.product?.name,
                  quantity: "x" + (item.quantity ?? 1),
                  price: "Php " + UI.pad(item.amount, 3, true),
                },
              ]}
              current_page={1}
              number_of_pages={20}
              on_page={(page, callback) => {}}
              disable_pagination={true}
            />
            <View style={styles.border_line}></View>

            <View style={{ width: "100%", alignItems: "flex-end" }}>
              <View style={{ padding: 20 }}>
                <View style={{ flexDirection: "row", padding: 20 }}>
                  <View style={{ alignItems: "flex-end" }}>
                    <Text style={styles.receipt_legend}>Price</Text>
                    <Text style={styles.receipt_legend}>Service Fee</Text>
                    <Text style={styles.receipt_legend}>Discount</Text>
                    <Text style={styles.receipt_legend}>Tax</Text>
                  </View>
                  {UI.box(50)}
                  <View style={{ alignItems: "flex-end", minWidth: 50 }}>
                    {/* <Text style={styles.receipt_legend}>
                      {"Php " + UI.pad((parseInt(item.amount) + (item.transactionFee ?? 0)), 4, true)}
                    </Text> */}
                    <Text style={styles.receipt_legend}>
                      {"Php " + UI.pad(parseInt(item.amount), 0, true)}
                    </Text>
                    <Text style={styles.receipt_legend}>
                      {"Php " + UI.pad((item.transactionFee ?? 0), 0, true)}
                    </Text>
                    <Text style={styles.receipt_legend}>None</Text>
                    <Text style={styles.receipt_legend}>Php 000.00</Text>
                  </View>
                </View>

                <View style={styles.total_border_line}></View>

                <View style={styles.total_container}>
                  <Text style={styles.total_text}>TOTAL</Text>
                  <Text style={styles.total_text_value}>
                    {"Php " + UI.pad((parseInt(item.amount) + (item.transactionFee ?? 0)), 0, true)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {UI.box(20)}
          <View
            style={{
              ...styles.main_box_content,
              width: 220,
              alignSelf: "flex-start",
            }}
          >
            <Text style={{ ...styles.box_header_text, textAlign: "center" }}>
              Other Options
            </Text>

            {UI.box(10)}
            <Layout.Btn
              color={UI.colors.primary}
              text={"Download"}
              onClick={() => {
                this.download("download");
              }}
            />
            {UI.box(10)}
            <Layout.Btn
              color={UI.colors.primary}
              text={"Print File"}
              onClick={() => {
                this.download("print");
              }}
            />
          </View>
        </UI.Row>
      </MainLayout>
    );
  }
}

const UserDetail = (
  props = { icon: "", name: "", value: "", is_rendering: false }
) => {
  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}
    >
      <View style={{ width: 180, ...styles.fl_ai }}>
        <View style={styles.detail_icon}>
          <img
            style={{
              height: 16,
              width: 16,
              marginTop: props.is_rendering ? 8 : 0,
            }}
            src={props.icon}
            alt="detail icon"
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};
const styles = StyleSheet.create({
  total_border_line: {
    height: 2,
    width: "100%",
    backgroundColor: "#888888",
  },
  total_text_value: {
    fontSize: 17,
    fontWeight: "bold",
    color: UI.colors.secondary,
  },
  total_text: {
    fontSize: 17,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  total_container: {
    width: "100%",
    padding: 15,
    flexDirection: "row",
  },
  receipt_legend: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#535353",
  },
  border_line: {
    height: 1,
    width: "100%",
    backgroundColor: "#EBEBEB",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  transaction_date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
  transaction_date: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 10,
  },
  timeline_circle: {
    height: 22,
    width: 22,
    borderRadius: 100,
    backgroundColor: UI.colors.primary,
    borderWidth: 4,
    borderColor: "#E3E3E3",
    marginTop: -10,
    marginLeft: -12,
  },
  timeline_item: {
    height: 100,
    width: "100%",
    borderLeftStyle: "solid",
    borderLeftWidth: 2,
    borderLeftColor: "#E3E3E3",
    marginLeft: 10,
  },
  detail_value: {
    color: "black",
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  detail_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  flex_1: {
    flex: 1,
  },
  detail_left: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  detail_item: {
    flexDirection: "row",
    alignItems: "center",
    width: 300,
    marginBottom: 5,
  },
  line: {
    width: "100%",
    height: 3,
    backgroundColor: "#EFEFEF",
  },
  ai_fl: {
    flexDirection: "row",
    alignItems: "center",
  },
  sub_name: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#898989",
  },
  main_name: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
