import React from 'react'
import styled from "styled-components";
import { Typography } from "antd";

const ContainerLabelPrimary = (props) => {
  return (
    <SyledTypographyTitlePrimary {...props}>{props.children}</SyledTypographyTitlePrimary>
  )
}

export default ContainerLabelPrimary;

const SyledTypographyTitlePrimary = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.5rem !important; // 24px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
  margin-bottom: 25px !important;
`;