import UI from "../shared/Components/UI/js";
import ServiceApi from "./service";

class PatientsApi {

    baseURL = 'admin';

    async getPatients (params) {
        const response = await ServiceApi.get(this.baseURL+'/patients', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async getTest (params) {
        const response = await ServiceApi.get(this.baseURL+'/doctors', params);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async updatePatientAccount (id, payload) {
        const response = await ServiceApi.put(this.baseURL+`/cms/users/${id}`, payload);
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    // async updatePatientAppAccount (id, payload) {
    //     const response = await ServiceApi.put(this.baseURL+`app/mwell/users/${id}`, payload);
    //     if (response?.status === 200) {
    //         const data = UI.extract_data(response);
    //         data.status = 200;
    //         return data;
    //     } else {
    //         return {
    //             status: response.status,
    //             data: response.data.d,
    //             message: response.data.m
    //         }
    //     }
    // }
}

export default new PatientsApi()