
import React, { useEffect, useState } from 'react'
import { mem } from '../../../shared/custom-react-native';
// import { DeleteConfirmation } from '../_components/Modal';
import DataTable from "../../../ComponentsFC/DataTable";
import { Row, Select, Typography } from "antd";
import styled from 'styled-components';
import DropdownIcon from '../../../icons/Dropdown';
import ServiceUsergroupsApi from "../../../api_services/usergroups";

const AdminInvitedList = ({ isSuccessInvite, setIsSuccessInvite }) => {
  
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(30);
  // eslint-disable-next-line no-unused-vars
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const getAdminInvitedList = (params) => {
    const data = mem.get("view_user_group");
    const user_group = JSON.parse(data);
    const id = user_group?._id

    try {
      setIsLoading(true);
      ServiceUsergroupsApi.getInvitedUserGroupAdminByUserGroupId(id).then( res => {
        setIsLoading(false);
        if (res.status === 200) {
          let data = res;
          setIsSuccessInvite(false);
          setTotalRecords(data.length);
          setTableData(data);
        }
      });
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }
  useEffect(() => {
    getAdminInvitedList();
    if (isSuccessInvite) {
      getAdminInvitedList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessInvite]);

  const handlePaginateTable = (pageNum) => {
    setCurrentPageNum(pageNum);
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const tableColumnHeader = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => email ?? " ",
      width: 200,
    },
  ];

  return (
    <>
      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="30"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="5">5 Entries</Select.Option>
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>
        </Toolbar>
        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={tableData}
          defaultPageSize={30}
          // pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>

    </>
  )
}

export default AdminInvitedList

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

// import React from 'react'
// import UI from '../../shared/Components/UI/js'
// import TouchableOpacity, { Image, StyleSheet, Text, View } from '../../shared/custom-react-native'
// import CustomDatatable from '../_components/CustomDatatable'
// import Layout from '../_components/Layout'

// const AdminInvitedList = (props) => {
//   return (
//     <>
//       <UI.Row
//         style={styles.main_container_header}
//         breakpoint={950}
//         _this={props._this}
//       >
//         {/* <View
//           style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
//         >
//           <Text style={styles.entry_header}>Only Show</Text>
//           {UI.box(10)}
//           <View>
//             <EntryPicker table_name={"invited_admin_list"} _this={props._this} />
//           </View>
//         </View> */}
//           <View
//             style={{ flexDirection: "row", alignItems: "center", flex: 1, justifyContent:'flex-start' }}
//           >
//             <Text style={styles.entry_header}>Only Show</Text>
//             {UI.box(10)}
//             <View>
//               <View style={{ width: 120 }}>
//                 <Layout.SelectPicker
//                   _this={props._this}
//                   onChange={(e) => {
//                     props._this.setState({ members_entries: parseInt(e)})
//                     props._this.get_members({ current_page:1, per_page: parseInt(e)})
//                   }}
//                 >
//                   <option value={"5"} selected={props._this.state.members_entries === 5}>5 entries</option>
//                   <option value={"10"} selected={props._this.state.members_entries === 10}>10 entries</option>
//                   <option value={"30"} selected={props._this.state.members_entries === 30}>30 entries</option>
//                 </Layout.SelectPicker>
//               </View>
//             </View>
//           </View>
//       </UI.Row>

//       <CustomDatatable
//         state_name={"invited_admin_list"}
//         _this={props._this}
//         columns={[
//           // { "Fullname": "fullname" },
//           { "Email": "email" },
//           // { "Status": "status" },
//           // { Action : "action"}
//         ]}
//         data={props._this.state.invited_admins.map((item, index) => {
//           return {
//             // fullname: item.name ?? item.firstName + " " + item.lastName,
//             email: item.email,
//             // action: (
//             //   <View style={{ flexDirection: "row" }}>
//             //     <TouchableOpacity
//             //       onClick={() => {
//             //         this.deleteMembers(item, index)
//             //       }}
//             //     >
//             //       <Image
//             //         height={28}
//             //         width={28}
//             //         tintColor={"transparent"}
//             //         source={UI.DELETE_BTN}
//             //       />
//             //     </TouchableOpacity>
//             //   </View>
//             // ),
//           };
//         })}
//         on_page={(page, callback, table_name) => {
//           props._this.get_invited_admins({
//             current_page:page,
//             per_page: props._this.state.invited_admins_entries
//           })
//           // UI.on_page(props._this.state.all_data, callback, table_name, page);
//         }}
//       />

//       <View
//         style={{
//           ...styles.no_info_container,
//           display: props._this.state.invited_admins?.length > 0 ? "none" : "flex",
//         }}
//       >
//         <UI.Row
//           breakpoint={820}
//           style={{ maxWidth: 360, alignItems: "center" }}
//           _this={props._this}
//         >
//           <Image
//             height={100}
//             width={100}
//             tintColor={"transparent"}
//             source={UI.NO_INFO}
//           />
//           {UI.box(10)}
//           <Text
//             style={{
//               fontSize: 20,
//               fontWeight: "bold",
//               color: "#BFBFBF",
//               textAlign: props._this.state.width > 820 ? "left" : "center",
//               marginLeft: props._this.state.width > 820 ? 20 : 0,
//             }}
//           >
//             NO DATA AVAILABLE YET
//           </Text>
//         </UI.Row>
//       </View>
//     </>
//   )
// }

// export default AdminInvitedList

// const styles = StyleSheet.create({
//   no_info_container: {
//     paddingTop: 50,
//     paddingBottom: 50,
//     alignItems: "center",
//   },
//   entry_header: {
//     fontSize: 14,
//     fontWeight: "bold",
//     color: "#2E2E2E",
//   },
//   main_container_header: {
//     padding: 10,
//     paddingLeft: 20,
//     paddingRight: 20,
//   },
//   main_box_content: {
//     borderRadius: 5,
//     backgroundColor: "white",
//     // ...Shadow._3(),
//     padding: 20,
//   },
//   detail_icon: {
//     height: 16,
//     width: 16,
//     marginRight: 12,
//   },
//   details_name: {
//     color: UI.colors.primary,
//     fontSize: 14,
//     fontWeight: "bold",
//   },
//   details_value: {
//     color: "#333333",
//     fontSize: 14,
//     fontWeight: "bold",
//   },
//   border_style: {
//     borderStyle: "solid",
//     borderColor: "#EFEFEF",
//   },
//   fl_ai: {
//     flexDirection: "row",
//     alignItems: "center",
//   },
// });