import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  // StyleSheet,
  // Text,
  // TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout
// , { PreviewFile }
 from "../_components/Layout";
import "./css.css";

export default class ThirdForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: UI.uniqid(),
    };
  }

  reset_key = () => {
    this.setState({
      key: UI.uniqid(),
    });
  };

  render() {
    const _this = this.props._this;
    const {
      // height,
      width,
      // isMobile,
      // prcLicensePhoto,
      // picture,
      // signature,
      languanges,
      hmo_affiliations,
      medicalAssociations,
    } = _this.state;

    // const { downloaded_prc_license_photo } = _this.state;
    const row_width = width > 1000 ? width - 400 : "100%";

    const Languanges = languanges?.map((item) => {
      return <option value={item}>{item}</option>;
    });

    const HmoAffiliation = hmo_affiliations?.map((item) => {
      return <option value={item}>{item}</option>;
    });

    const MedicalAssociations = medicalAssociations?.map((item) => {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      );
    });

    return (
      <UI.Column
        style={{ marginBottom: 10, marginTop: 10 }}
        _this={_this}
        breakpoint={1000}
        breakpoint_2={720}
      >
        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <Layout.SelectPicker
            _this={_this}
            title={"Medical Association"}
            state_name={"medicalAssociation"}
            isMultiple={true}
          >
            <option value="">Select Medical Association</option>
            {MedicalAssociations}
          </Layout.SelectPicker>
          {UI.box(10)}
          <View style={{ flex: 1 }}>
            <Layout.SelectPicker
              _this={_this}
              title={"Languages/Dialects"}
              isRequired={true}
              state_name={"languange"}
              isMultiple={true}
            >
              <option value="">Select Language</option>
              {Languanges}
            </Layout.SelectPicker>
          </View>
        </UI.Row>
        {UI.box(20)}
        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <View style={{ flex: 1 }}>
            <Layout.SelectPicker
              _this={_this}
              title={"HMO Affiliation "}
              // isRequired={true}
              state_name={"hmo_affiliation"}
              isMultiple={true}
              key={this.state.key}
            >
              <option value="">Select HMO Affiliation</option>
              {HmoAffiliation}
            </Layout.SelectPicker>
          </View>
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Years of Practice"}
            placeholder={"Years of Practice "}
            state_name={"years_experience"}
            isRequired={true}
            isNumber={true}
            maxLength={13}
            style={{ flex: 1 }}
          />
        </UI.Row>
      </UI.Column>
    );
  }
}
