import Icon from "@ant-design/icons";
import React from "react";

const ActionViewSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <g id="view" transform="translate(-0.5)">
      <rect
        id="Rectangle_1914"
        data-name="Rectangle 1914"
        width="50"
        height="50"
        rx="11.5"
        transform="translate(0.5)"
        fill="#035099"
      />
      <path
        id="view-2"
        data-name="view"
        d="M144.53,361.293v-2.967c0-2.709,1.453-4.138,4.174-4.138h2.967a1.373,1.373,0,1,1,0,2.745h-2.733a1.479,1.479,0,0,0-1.663,1.675v2.684a1.373,1.373,0,1,1-2.745,0Zm0,13.373v-2.98a1.373,1.373,0,1,1,2.745,0v2.7a1.479,1.479,0,0,0,1.663,1.675h2.733a1.373,1.373,0,1,1,0,2.745H148.7C145.983,378.8,144.53,377.362,144.53,374.666ZM166.4,361.293v-2.684a1.483,1.483,0,0,0-1.662-1.675H162a1.373,1.373,0,1,1,0-2.745h2.967c2.733,0,4.174,1.44,4.174,4.138v2.967a1.373,1.373,0,1,1-2.746,0Zm-5.788,16.13A1.247,1.247,0,0,1,162,376.057h2.733a1.483,1.483,0,0,0,1.662-1.675v-2.7a1.373,1.373,0,1,1,2.746,0v2.98c0,2.7-1.441,4.137-4.174,4.137H162A1.25,1.25,0,0,1,160.611,377.423Z"
        transform="translate(-131.338 -341.495)"
        fill="#fff"
      />
    </g>
  </svg>
);

const ActionViewIcon = (props) => {
  return <Icon component={ActionViewSvg} {...props} />;
};

export default ActionViewIcon;
