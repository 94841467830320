import React, { useState, useEffect, useCallback }  from "react";
import moment from "moment";
import { Row, Col, Select, Typography } from "antd";
import styled from "styled-components";
import DropdownIcon from "../../icons/Dropdown";
import DataTable from "../../ComponentsFC/DataTable";
import mem from "../../shared/Components/Memory/js";
import { saveAs } from "file-saver";
import UI from "../../shared/Components/UI/js";
import _ from "lodash";
// import ServiceDoctorsApi from "../../api_services/doctors";

const OnboardTaxTable = () => {
  const [tableData, setTableData] = useState([]);
  const [tableEntries, setTableEntries] = useState(30);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  // const [isLoading, setIsLoading] = useState(false);

  const getDoctorTaxFiles = useCallback(async () => {
    try {
      const data = mem.get("user_doctor");
      const apiResponse = JSON.parse(data);
      
      setTableData(apiResponse.files);
      console.log(apiResponse.files)
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
    // try {
    //   // const data = mem.get("user_doctor");
    //   // const apiResponse = JSON.parse(data);
      
    //   // setTableData(apiResponse.files);
    //   ServiceDoctorsApi.getDoctorBirForms().then( res => {
    //     console.log(res);
    //     // setIsLoading(false);
    //     // if (res.status === 200) {
    //     //   let data = res.data;
    //     //   console.log(data);
    //     //   setTotalRecords(res.results.total);
    //     //   setTableData(data);
    //     // }
    //   });
    // } catch (err) {
    //   // Handle Errors Here
    //   console.error(err);
    //   if (err.response) {
    //     let statusCode = err.response.data.statusCode;
    //     let errorMsg = err.response.data.message;
    //     if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
    //     if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
    //   }
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNum, tableEntries]);

  const dateUploadFiltering = (sortType) => {
    const res = _.orderBy(tableData, "createdAt", sortType);
    setTableData(res)
  }

  useEffect(() => {
    getDoctorTaxFiles();
  }, [getDoctorTaxFiles]);

  const download_file = async (path,filename) => {
    const downloadResult = await fetch(UI.getDoctorFileURL(path));

    const blob = await downloadResult.blob();
    const file_type = blob.type.split("/")[1];
    if (file_type) {
      if (file_type === "html") {
        return false;
      } else {
        saveAs(
          blob,
          filename
        );
      }
    } else {
      return false;
    }
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));

  };

  const tableColumnHeader = [
    { title: "Date Upload", dataIndex: "createdAt", key: "createdAt", 
      render: (data) => {
        return moment(data).format("MM/DD/YYYY");
      },
    },
    { title: "File Name", dataIndex: ["originalFilename","path"], key: ["originalFilename","path"],
      render:(text, record,index) => {
        return (
          <TableTitle onClick={(e) => {download_file(record.path,record.originalFilename)} }>{record.originalFilename}</TableTitle>
        )
      }
    },
  ];

  const handlePaginateTable = (pageNum) => {
    console.log("Currently Selected Page # of Antd Table: ", pageNum);
    setCurrentPageNum(pageNum);
  };

  return (
    <>
      <FilterBox>
        <Title level={5}>Tax</Title>
        <Toolbar gutter={14}>
          <ToolItem span={8}>
            <StyledFieldLabel>Date Upload </StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Select Option"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(e) => {dateUploadFiltering(e)}}
            >
              <Select.Option title="Doctor's Name" value="asc">
                Ascending
              </Select.Option>
              <Select.Option title="Doctor's Name" value="desc">
                Descending
              </Select.Option>
            </StyledSelect>
          </ToolItem>
        </Toolbar>
      </FilterBox>

      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            defaultValue="5"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="5">5 Entries</Select.Option>
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>
        </Toolbar>

        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={tableData}
          defaultPageSize={30}
          pageSizeOptions={["5", "10", "30"]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
        //   totalRecords={totalRecords}
        //   onPaginate={handlePaginateTable}
        //   loading={isLoading}
          onPaginate={handlePaginateTable}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>

  );
};

export default OnboardTaxTable;

// CSS Styled Components ----------------------
const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const ToolItem = styled(Col)`
  position: relative;
  .ant-picker {
    margin-left: 102px;
  }
  .ant-picker-range {
    margin-left: 62px;
  }
`;

const Title = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.4375rem !important; // 15px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
  padding-left: 35px;
  margin-bottom: 5px !important;
`;


const TableTitle = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1rem !important; // 15px
  line-height: 1rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
  margin-bottom: 5px !important;
  cursor:pointer;
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;
