import React from "react";
import DataTable from "../../../../../ComponentsFC/DataTable";
import { Row } from "antd";
import styled from "styled-components";

const FamilyHistory = ({ family_history }) => {
  const tableColumnHeader = [
    {
      title: "Relationship",
      dataIndex: "relationship_uid_name",
      key: "relationship_uid_name",
      render: (data) => data || "n/a",
    },
    {
      title: "Condition/Illness",
      dataIndex: "condition",
      key: "condition",
      render: (data) => data || "n/a",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (data) => data || "n/a",
    },
  ];
  return (
    <TableBox>
      <DataTable
        tableColumnHeader={tableColumnHeader}
        dataSource={family_history}
        defaultPageSize={5}
        pageSizeOptions={["5", "10", "30"]}
        hideTotalSummaryOnSinglePage={family_history.length <= 5}
        hidePaginationOnSinglePage={family_history.length <= 5}
      />
    </TableBox>
  );
};

export default FamilyHistory;

const TableBox = styled(Row)`
  position: relative;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  min-width: 100%;
  .ant-table-cell {
    font-size: 20px;
  }
`;
