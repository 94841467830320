import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import ForgetPasswordStep1 from "../../ComponentsFC/forget_password_step_1/ForgetPasswordStep1";
const context = "signup";

export default class OnboardForgetPasswordStep1 extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      remember_me: false,
      context: context,
      show_loading: false
    });
  }

  show_modal = (modal_content) => {
    this.setState({
      show_modal: true,
      modal_content: modal_content,
    });
  };

  hide_modal = () => {
    this.setState({
      show_modal: false,
      modal_content: "",
    });
  };

  show_loading = (title, text) => {
    this.setState({
      show_loading: true,
      loading_title: title,
      loading_text: text,
    });
  };

  hide_loading = () => {
    this.setState({
      show_loading: false,
    });
  };

  componentDidMount = () => {
    const _this = this.props._this;
    _this.show_loading = this.show_loading;
    _this.hide_loading = this.hide_loading;

    _this.show_modal = this.show_modal;
    _this.hide_modal = this.hide_modal;
  };

  onCreate = async () => {};

  render() {
    return (
      <>
        <ForgetPasswordStep1 _this={this} />
      </>
    );
  }
}
