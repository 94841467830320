import React, { useState, useEffect, useRef, useCallback } from "react"; // useState
import usePreviousValue from "../../../hooks/use-previous-value";
import styled from "styled-components";
import mem from "../../../shared/Components/Memory/js";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { Form, Row, Col, message, Tooltip, Divider } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import FieldLabel from "../../ui/forms/FieldLabel";
import FormItem from "../../ui/forms/FormItem";
import InputField from "../../ui/forms/InputField";
import TextArea from "../../ui/forms/TextArea";
import SelectField from "../../ui/forms/SelectField";
import DatePickerFC from "../../ui/forms/DatePicker";
import DropFiles from "../../ui/forms/DropFiles";
import SubmitButton from "../../ui/forms/SubmitButton";
import SuccessModal from "../../ui/modals/SuccessModal";
import SuccessModalCheckIcon from "../../../icons/SuccessModalCheck";
import ProcessFailedModal from "../../ui/modals/ProcessFailedModal";
import ProcessFailedModalCrossIcon from "../../../icons/ProcessFailedModalCross";
import Loading from "../../../modules/_components/Loading";

import ServiceAttendanceApi from "../../../api_services/Doctors-Portal/DPAttendanceApi";

const leaveTypeSelectOptions = [
  { type: "Vacation Leave", value: "vacationLeave" },
  { type: "Sick Leave", value: "sickLeave" },
  { type: "Emergency Leave", value: "emergencyLeave" },
  { type: "Maternity Leave", value: "maternityLeave" },
  { type: "Bereavement Leave", value: "bereavementLeave" },
  { type: "Paternity Leave", value: "paternityLeave" },
];

// let durationSelectOptions = [
//   { type: "Wholeday", value: "wholeday" },
//   { type: "Halfday", value: "halfday" },
// ];

const LeaveRequest = React.memo(() => {
  const [form] = Form.useForm();
  const [durationSelectOptions, setDurationSelectOptions] = useState([
    { type: "Wholeday", value: "wholeday" },
    // { type: "Halfday", value: "halfday" },
  ]);

  const fileState = useRef();
  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploadFilesApiResponse, setUploadFilesApiResponse] = useState([]);

  const previousFilesCount = usePreviousValue(uploadFiles.length);

  const [doctorData, setDoctorData] = useState();
  const [initialFormValues, setInitialFormValues] = useState({});
  const [startLeaveDate, setStartLeaveDate] = useState();
  const [endLeaveDate, setEndLeaveDate] = useState();

  const [isScreenLoading, setIsScreenLoading] = useState();
  const [submitProgress, setSubmitProgress] = useState();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const [processFailedBody, setProcessFailedBody] = useState(<></>);
  const [processFailedModalWidth, setProcessFailedModalWidth] = useState();

  const getDoctorData = useCallback(() => {
    const data = mem.get("onboard_data");
    if (data) {
      const onboard_data = JSON.parse(data);
      if (onboard_data) {
        setDoctorData(onboard_data?.doctor);
      }
    }
  }, []);

  useEffect(() => {
    getDoctorData();
  }, [getDoctorData]);

  useEffect(() => {
    // console.log("TRIGGER RESET FIELDS ");
    form.resetFields();
  }, [initialFormValues, form]); //to remount form component
  useEffect(() => {
    //FieldName: Value
    setInitialFormValues({
      requestor: `${doctorData?.name[0]?.given?.join(" ")} ${
        doctorData?.name[0]?.family
      }`,
    });
  }, [doctorData]);

  const handleCloseModal = () => {
    setIsSuccessModalOpen(false);
    setIsFailedModalOpen(false);
  };

  const updateFiles = (list, setState) => {
    let fileList;
    const _ = (list, setState) => {
      if (!fileList) {
        fileList = list;
        setState && setState([...uploadFiles, ...list]);
      }
      return {
        fileList,
        reset() {
          fileList = false;
        },
      };
    };
    return _(list, setState);
  };

  const uploadProps = {
    beforeUpload: (_, fileList) => {
      // console.log("BEFORE UPLOAD TRIGGERED", fileList);
      fileState.current = updateFiles(fileList, setUploadFiles);
      return false;
    },
    onPreview: (file) => {
      // console.log("PREVIEW", file);
      handleViewDoctorFile(file);
    },
    onRemove: (file) => {
      // console.log("ON REMOVE TRIGGERED", file.uid);
      setUploadFiles((prevVal) =>
        prevVal.filter((prevFile) => prevFile.uid !== file.uid)
      );
    },
    itemRender: (existingComponent, file, fileList, action) => {
      const truncatedFileName = (fileName) => {
        const charsLimit = 40;
        return fileName.length > charsLimit
          ? fileName.substring(0, charsLimit) + "..." + fileName.slice(-7)
          : fileName;
      };

      return (
        <Row style={{ fontSize: "15px", width: "454px" }}>
          <Tooltip
            placement="top"
            title={file.name}
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
          >
            <p
              onClick={() => action.preview()}
              style={{ color: "#00b7ff", cursor: "pointer" }}
            >
              {truncatedFileName(file.name)}
            </p>
          </Tooltip>
          <p
            onClick={() => action.remove()}
            style={{ marginLeft: "auto", cursor: "pointer" }}
          >
            <DeleteTwoTone
              twoToneColor="#c41a1a"
              style={{ fontSize: "23px" }}
            />
          </p>
        </Row>
      );
    },
    multiple: true,
  };

  useEffect(() => {
    if (startLeaveDate && endLeaveDate) {
      if (startLeaveDate.diff(endLeaveDate, "days") !== 0) {
        // durationSelectOptions = [{ type: "Wholeday", value: "wholeday" }];
        setDurationSelectOptions([{ type: "Wholeday", value: "wholeday" }]);
      } else {
        setDurationSelectOptions([
          { type: "Wholeday", value: "wholeday" },
          // { type: "Halfday", value: "halfday" }, // remove nlng daw yung halfday
        ]);
      }
    }
  }, [startLeaveDate, endLeaveDate]);

  const handleChangeStartDate = (date, dateString) => {
    // console.log("startDate",date);
    setStartLeaveDate(date);
  };
  const handleChangeEndDate = (date, dateString) => {
    // console.log("startDate",date);
    setEndLeaveDate(date);
  };
  const handleDisabledStartDates = (current) => {
    // Disable dates before today
    // console.log("DATE PICKER: ", current.format("YYYY-MM-DD"));
    return moment().add(-1, "days") >= current;
  };
  const handleDisabledEndDates = (current) => {
    // Disable dates before today
    // console.log("DATE PICKER: ", current.format("YYYY-MM-DD"));
    if (current && startLeaveDate) {
      // console.log("END DATES", startLeaveDate.valueOf());
      return current.valueOf() < startLeaveDate.valueOf();
    } else {
      return moment().add(-1, "days") >= current;
    }
  };

  const handleUploadFilesCustomRequest = useCallback(async () => {
    // Trigger uploadFile once not per file upload, bypassing ant design bug.
    // console.log("HANDLE UPLOAD FILES API CALL (uploadFiles): ", uploadFiles);
    const formData = new FormData();
    // formData.append("multi-files", uploadFiles);
    for (const key of Object.keys(uploadFiles)) {
      formData.append("multi-files", uploadFiles[key]);
    }
    formData.append("container", "doctors-onboarding");

    try {
      let payload = formData;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const apiResponse = await ServiceAttendanceApi.uploadLeaveRequestFiles(
        // urlParams,
        payload,
        config
      );
      // console.log("HANDLE UPLOAD FILES API CALL (apiResponse): ", apiResponse);
      setUploadFilesApiResponse(apiResponse);
      if (apiResponse.status === 200) {
        if (previousFilesCount && previousFilesCount !== uploadFiles.length) {
          message.success("Files Updated Successfully!");
        } else {
          message.success(
            uploadFiles.length === 1
              ? "File Uploaded Successfully!"
              : "Files Uploaded Successfully!"
          );
        }
      }
      if (apiResponse.status > 400) {
        message.error("Upload Failed");
      }
    } catch (err) {
      console.error(err);
    }
  }, [uploadFiles, previousFilesCount]);

  useEffect(() => {
    // console.log("USE EFFECT (uploadFiles): ", uploadFiles);
    if (uploadFiles?.length > 0) {
      // Avoid fn execution if the Form re-renders due to setState
      if (previousFilesCount === uploadFiles.length) return;
      handleUploadFilesCustomRequest();
      fileState.current.reset();
    }
  }, [uploadFiles, handleUploadFilesCustomRequest, previousFilesCount]);

  const handleViewDoctorFile = async (file) => {
    //Create blob from file
    const fileBlob = new Blob([file.originFileObj], {
      type: file.type,
    });
    //Create URL from fileBlob
    const fileURL = URL.createObjectURL(fileBlob);
    //Open the URL on new Window
    window.open(fileURL);
  };

  const handleOnSubmitLeaveRequest = async (fieldValues) => {
    // console.log("SUBMIT LEAVE REQUEST: ", uploadFilesApiResponse?.d)
    try {
      const { leaveType, startDate, endDate, duration, reason } = fieldValues;
      // let urlParams = `?name=${requestor.trim()}`;

      let payload = {
        leaveType: leaveType?.trim(),
        startDate: startDate.format("YYYY-MM-DD[T]00:00:00.000[Z]"),
        endDate: endDate.format("YYYY-MM-DD[T]00:00:00.000[Z]"),
        duration: duration?.trim() ?? "wholeday",
        reason: reason?.trim() ?? "",
        attachment: uploadFilesApiResponse?.d ?? [],
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        onUploadProgress: (axiosProgressEvent) => {
          // console.log("onUploadProgress", axiosProgressEvent);
          const { loaded, total } = axiosProgressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setSubmitProgress(percentage);
        },
      };

      // console.log("On Submit: ", fieldValues);
      // console.log("Payload: ", payload);
      setIsScreenLoading(true);
      const apiResponse = await ServiceAttendanceApi.createLeaveRequest(
        // urlParams,
        payload,
        config
      );
      // console.log(apiResponse);
      setIsScreenLoading(false);

      const pendingRequest = (
        <>
          {"You still have a pending"}
          <br /> {"leave request"}
        </>
      );

      const upcomingAppointment = (
        <StyledContent>
          <p>
            {apiResponse?.data?.withUpcomingAppointments?.length === 1
              ? "You have an upcoming appointment"
              : "You have an upcoming appointments"}
          </p>
          {apiResponse?.data?.withUpcomingAppointments?.map(
            (appointment, index) => (
              <>
                <SubDescription>
                  {`Patient Name: ${appointment?.patient}`}
                </SubDescription>
                <SubDescription>
                  {/* {`Appointment: ${appointment?.start?.slice(
                    0,
                    -2
                  )}${appointment?.start?.slice(-2).toLowerCase()}`} */}
                  {`Appointment: ${momentTimezone(appointment?.start)
                    .tz("Asia/Manila")
                    .format("DD/MM/YYYY h:mm a")}`}
                </SubDescription>
                {apiResponse?.data?.withUpcomingAppointments?.length > 1 && (
                  <Divider plain>{`${index + 1}`}</Divider>
                )}
              </>
            )
          )}
          <FooterDescription>
            Contact support@mwell.com.ph for details
          </FooterDescription>
        </StyledContent>
      );

      if (apiResponse?.status === 200 || apiResponse?.status === 201) {
        setIsSuccessModalOpen(true);
      } else if (
        apiResponse?.status === 400 &&
        apiResponse?.message === "End date value is less than Start date"
      ) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {"Invalid date range. The end date cannot be before the start date"}
          </>
        );
        setProcessFailedModalWidth(530);
      } else if (
        apiResponse?.status === 400 &&
        (apiResponse?.message === "You still have pending request" ||
          apiResponse?.message === "You still have a pending request")
      ) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(pendingRequest);
        setProcessFailedModalWidth(530);
      } else if (
        apiResponse?.status === 400 &&
        apiResponse?.data?.withUpcomingAppointments
      ) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(upcomingAppointment);
        setProcessFailedModalWidth(570);
      } else if (apiResponse?.status === 403) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>{"Submit Failed. Forbidden Request. Please try again. (403)"}</>
        );
        setProcessFailedModalWidth(530);
      } else if (apiResponse?.status === 404) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {"Submit Failed. API resource not found. Please try again. (404)"}
          </>
        );
        setProcessFailedModalWidth(530);
      } else if (apiResponse?.status === 500) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>{"Something went wrong!. Server Error. Please try again. (500)"}</>
        );
        setProcessFailedModalWidth(530);
      } else if (
        apiResponse?.status > 503 ||
        apiResponse === undefined ||
        apiResponse?.status === undefined
      ) {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>
            {
              "Service Unavailable/Forbidden Request. Please try again later. (503/403)"
            }
          </>
        );
        setProcessFailedModalWidth(530);
      } else {
        setIsFailedModalOpen(true);
        setProcessFailedBody(
          <>{`${apiResponse?.status}. ${apiResponse?.message}`}</>
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Loading
        visible={isScreenLoading}
        title={""}
        text={`Submitting Leave Request... ${submitProgress}%`}
        modal_opaque={false}
      />
      <SuccessModal
        title={"SUCCESS!"}
        banner={<SuccessModalCheckIcon />}
        description={"Leave request has been submitted and is for approval"}
        buttonText={"Done"}
        buttoncolor={"#004f99"}
        visible={isSuccessModalOpen}
        width={530}
        // visible={true}
        onOk={handleCloseModal}
        onCancel={handleCloseModal} // x and cancel button
      />
      <ProcessFailedModal
        title={"PROCESS FAILED"}
        banner={<ProcessFailedModalCrossIcon />}
        description={processFailedBody}
        buttonText={"Dismiss"}
        buttoncolor={"#004f99"}
        visible={isFailedModalOpen}
        width={processFailedModalWidth}
        // visible={true}
        onOk={handleCloseModal}
        onCancel={handleCloseModal} // x and cancel button
      />
      <Form
        form={form}
        layout={"vertical"}
        onFinish={handleOnSubmitLeaveRequest}
        // onFinishFailed={() => console.log("Submit Failed")}
        initialValues={initialFormValues}
      >
        <StyledRow gutter={30}>
          <Col span={12}>
            <FormItem
              name="requestor"
              label={<FieldLabel>Requestor</FieldLabel>}
            >
              <InputField disabled placeholder="Requestor" />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              name="leaveType"
              label={<FieldLabel>Leave Type</FieldLabel>}
              rules={[
                {
                  required: true,
                  message: "Leave Type is required",
                },
              ]}
            >
              <SelectField
                placeholder="Select Leave Type"
                allowClear
                selectoptions={leaveTypeSelectOptions}
                // onChange={(e) => console.log(e)}
                // onClear={() => handleClearViaSelectField("Select Leave Type")}
              />
            </FormItem>
          </Col>
        </StyledRow>

        <StyledRow gutter={30}>
          <Col span={12}>
            <FormItem
              name="startDate"
              label={<FieldLabel>Start Date</FieldLabel>}
              rules={[
                {
                  required: true,
                  message: "Start Date is required",
                },
              ]}
            >
              <DatePickerFC
                placeholder={"mm/dd/yyyy"}
                format={"MM/DD/YYYY"}
                disabledDate={handleDisabledStartDates}
                onChange={handleChangeStartDate} // use to add disabled dates
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name="endDate"
              label={<FieldLabel>End Date</FieldLabel>}
              rules={[
                {
                  required: true,
                  message: "End Date is required",
                },
              ]}
            >
              <DatePickerFC
                placeholder={"mm/dd/yyyy"}
                format={"MM/DD/YYYY"}
                disabledDate={handleDisabledEndDates}
                onChange={handleChangeEndDate} // use to mutate Duration Options
              />
            </FormItem>
          </Col>
        </StyledRow>

        <StyledRow gutter={30}>
          <Col span={12}>
            <FormItem name="duration" label={<FieldLabel>Duration</FieldLabel>}>
              <SelectField
                placeholder="Select Duration"
                allowClear
                selectoptions={durationSelectOptions}
                // onChange={(e) => console.log(e)}
                // onClear={() => handleClearViaSelectField("Select Leave Type")}
              />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              name="attachment"
              label={<FieldLabel>Attachment</FieldLabel>}
              valuePropName="fileList" // To avoid antd Upload.js error Warning: [ant upload] 'value' is not a valid prop, do you mean fileList?
              getValueFromEvent={(e) => e?.fileList} // To avoid Uncaught TypeError: (fileList || []).forEach is not a fn
            >
              <DropFiles
                {...uploadProps}
                accept=".jpeg,.png,.pdf"
                extradescription="(.jpg | .png | .pdf)"
              />
            </FormItem>
          </Col>
        </StyledRow>

        <StyledRow style={{ marginBottom: "6px" }}>
          <Col span={24}>
            <FormItem name="reason" label={<FieldLabel>Reason</FieldLabel>}>
              <TextArea
                placeholder=""
                autoSize={{
                  minRows: 4,
                }}
              />
            </FormItem>
          </Col>
        </StyledRow>

        <FormItem>
          <SubmitButton>Submit</SubmitButton>
        </FormItem>
      </Form>
    </>
  );
});

export default LeaveRequest;

const StyledRow = styled(Row)`
  margin-bottom: 30px;
`;

const StyledContent = styled.div`
  text-align: left;
`;

const SubDescription = styled.p`
  font-size: 1.25rem; //20px
  margin-bottom: 0 !important;
`;

const FooterDescription = styled.p`
  font-size: 0.8125rem; //13px
  margin-top: 40px;
`;
