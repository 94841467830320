import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import
//  TouchableOpacity, 
{
  // mem,
  // ScrollView,
  // Shadow,
  // StyleSheet,
  View,
  // Text,
  // Image,
} from "../../shared/custom-react-native";

// import Layout, { PreviewFile } from "../_components/Layout";

export class PRCValidator extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    const { _this } = this.props;
    UI.set_context(_this);
    _this.PRCValidator = this;
  };

  validate_prc = async (path) => {
    const { _this } = this.props;
    const url = await UI.download_image(path, "doctors-onboarding");
    _this.hide_modal();
    _this.show_loading("", "Validating, please wait...");
    this.analyze_image(url);
  };

  analyze_image = async (url) => {
    const { _this } = this.props;
    _this.show_loading("", "Analyzing Image...");
    UI.simpleRequest({
      method: "post",
      url: "https://formrecog-mwell-hippo-001.cognitiveservices.azure.com/formrecognizer/documentModels/prc-id-formrecognizer-hippo-001-2:analyze",
      params: {
        "api-version": "2022-08-31",
        urlSource: url,
      },
      isMultiPart: false,
      onFail: (err) => {
        console.log(err);
        _this.hide_loading();
      },
      onSuccess: (response) => {
        const location = response.headers["operation-location"];
        this.get_analyze_results(location);
      },
      onFinish: async () => {},
      subscription_key: UI.Constants.FORM_RECOGNIZER_SUBSCRIPTION,
    });
  };

  get_analyze_results = async (location) => {
    const { _this } = this.props;
    _this.show_loading("", "Getting Results...");
    UI.simpleRequest({
      method: "get",
      url: location,
      params: {},
      isMultiPart: false,
      strict: true,
      onFail: (err) => {
        console.log(err);
        _this.hide_loading();
      },
      onSuccess: (response) => {
        if (response.data.status === "running") {
          setTimeout(() => {
            this.get_analyze_results(location);
          }, 4000);
        } else if (response.data.status === "succeeded") {
          this.load_analyze_results(response.data.analyzeResult);
        } else {
          _this.hide_loading();
        }
      },
      onFinish: async () => {},
      subscription_key: UI.Constants.FORM_RECOGNIZER_SUBSCRIPTION,
    });
  };

  load_analyze_results = async (data) => {
    const { _this } = this.props;
    const fields = data.documents[0].fields;
    const last_name = fields["LastName"]?.valueString ?? "";
    if (last_name) {
      UI.set_input_value("last_name", UI.title_case(last_name ?? ""));
    }

    const first_name = fields["FirstName"]?.valueString ?? "";
    if (first_name) {
      UI.set_input_value("first_name", UI.title_case(first_name ?? ""));
    }

    const middle_name = fields["MiddleName"]?.valueString ?? "";
    if (middle_name) {
      UI.set_input_value("middle_name", UI.title_case(middle_name ?? ""));
    }

    const prc_license = fields["RegistrationNumber"]?.valueString ?? "";
    if (prc_license) {
      UI.set_input_value("prc_number", prc_license ?? "");
    }
    /*     UI.set_input_value(
      "registration_date",
      fields["RegistrationDate"]?.valueString ?? ""
    ); */
    const reg_date = fields["RegistrationDate"]?.valueString;
    if (reg_date) {
      UI.set_date_value(
        "license_start_date",
        UI.get_dateobj_from_string(reg_date ?? "")
      );
    }

    const valid_until = fields["ValidUntil"]?.valueString;
    if (valid_until) {
      UI.set_date_value(
        "license_end_date",
        UI.get_dateobj_from_string(valid_until ?? "")
      );
    }

    _this.hide_loading();
  };

  render() {
    // const { _this } = this.props;
    // const { file_prc_license_path } = _this.state;
    return (
      <View
        style={{
          borderRadius: 20,
          position: "absolute",
          height: 0.1,
          width: 0.1,
        }}
      ></View>
    );
  }
}
