import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Shadow,
  StyleSheet,
  Text,
  View,
  Image,
} from "../../shared/custom-react-native";
import CustomPieChart from "../_components/CustomPieChart";
import MainLayout from "../_components/MainLayout";
import "./css.css";
import Statistics from "./Statistics";
import CustomDatatable from "../_components/CustomDatatable";
// import Layout from "../_components/Layout";
import EntryPicker from "../_components/EntryPicker";
// import { marginBottom } from "styled-system";

const context = "admin";

export default class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Dashboard",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
      item: {
        totalCustomers: 0,
        totalFinishedPurchases: 0,
        totalActiveProducts: 0,
        totalInquiries: 0,
        totalPatients: 0,
        totalNewPatients: 0,
        totalDoctors: 0,
        totalNewDoctors: 0,
        totalUpcommingSessions: 0,
        totalMissedSessions: 0,
        totalCompletedSessions: 0,
        totalCancelledSessions: 0,
        totalOrderUnpaid: 0,
        totalOrderCompleted: 0,
        totalOrderCancelled: 0,
      },

      isLoading: true,

      current_date: UI.timestampToDate(new Date().getTime()),

      duration: "year", //month, day, year
      data: [],
    });
  }

  onCreate = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "admin/dashboards",
      params: {
        duration: this.state.duration,
      },
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState({
          item: data,
        });
      },
      onFinish: () => {
        this.hide_loading();
        this.setState({
          isLoading: false,
        });
        //this.get_transactions();
        this.get_on_call();
      },
    });
  };

  get_on_call = async () => {
    UI.webRequest({
      method: "get",
      url: "admin/doctors/oncalls",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        let data = UI.extract_data(response);
        console.log(data);
        /*
        data = data.d; //uncomment this for realistic data
        data = data.data; */

        const new_data = data.map((item) => {
          item.doctor_name = item.displayName;
          return item;
        });
        this.setState(
          {
            data: new_data,
            all_data: new_data,
          },
          () => {
            this.reload_table("on_call");
          }
        );
      },
      onFinish: () => {
        this.timeout = setTimeout(() => {
          this.get_on_call();
        }, 60000);
        //60 seconds interval
        this.hide_loading();
      },
    });
  };

  onDestroy = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  };

  timeout = null;

  render() {
    const _this = this;
    const {
      //  height,
      width,
      // isMobile,
      item,
      isLoading,
    } = _this.state;
    // let add_style = {};
    // if (width <= 470) {
    //   add_style = { flexDirection: "row" };
    // }

    let user_stats_container = UI.measure("user_stats_container").width;

    return (
      <MainLayout context={context} _this={_this}>
        {/* <UI.Row breakpoint={1070} _this={_this} style={{ width: "100%", marginBottom:"20px"}}>
        <View
            id={"user_stats_container"}
            style={{ ...styles.main_content_container, flex: 1, padding: 0, height:"700px"  }}
          >
          <iframe title="mWell Daily Report Final" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiNTY3N2M0ODktZjdmMS00Mjg2LWI1MTgtMGI3ZjZkZWYwYzUzIiwidCI6IjcwMGEzYTY5LWYxMzUtNGExNS1hOWEzLWVmMjg5ODQ4ZDBkNCIsImMiOjEwfQ%3D%3D&pageName=ReportSection" frameborder="0" allowFullScreen="true"></iframe>
        </View>
        </UI.Row> */}

        <Statistics _this={_this} />

        <UI.Row breakpoint={1070} _this={_this} style={{ width: "100%" }}>
          <View
            id={"user_stats_container"}
            style={{ ...styles.main_content_container, flex: 1, padding: 0 }}
          >
            <UI.Row
              breakpoint={420}
              _this={_this}
              basis={user_stats_container}
              style={{ width: "100%", padding: 20 }}
            >
              <Text style={styles.box_header_text}>User Statistics</Text>
              {UI.box(10)}
              <View></View>
            </UI.Row>

            {!isLoading && (
              <CustomPieChart
                ref={(pie) => {
                  this.pie = pie;
                }}
                data={[
                  {
                    color: "#0064C3",
                    label: "New Doctors",
                    count: item.totalNewDoctors,
                    onSelect: (item) => {},
                  },
                  {
                    color: "#004F99",
                    label: "Doctors",
                    count: item.totalDoctors,
                    onSelect: (item) => {},
                  },
                  {
                    color: "#48DAFF",
                    label: "New Patients",
                    count: item.totalNewPatients,
                    onSelect: (item) => {},
                  },
                  {
                    color: "#00CBFF",
                    label: "Patients",
                    count: item.totalPatients,
                    onSelect: (item) => {},
                  },
                ]}
                _this={_this}
                id={"user_stats"}
              />
            )}

            <View
              style={{
                alignSelf: "center",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                padding: 20,
              }}
            >
              <UI.Row
                breakpoint={415}
                basis={user_stats_container}
                style={{ flex: 1, justifyContent: "space-around" }}
                _this={_this}
              >
                <Legend color={"#0064C3"} text={"New Doctors"} />
                {UI.box(5)}
                <Legend color={"#004F99"} text={"Doctors"} />
              </UI.Row>
              <UI.Row
                breakpoint={415}
                basis={user_stats_container}
                style={{ flex: 1, justifyContent: "space-around" }}
                _this={_this}
              >
                <Legend color={"#48DAFF"} text={"New Patients"} />
                {UI.box(5)}
                <Legend color={"#00CBFF"} text={"Patients"} />
              </UI.Row>
            </View>
          </View>
          {UI.box(20)}

          <View
            style={{
              borderRadius: 5,
              backgroundColor: "white",
              ...Shadow._3(),
              flex: 1,
              width: width > 1070 ? null : "100%",
              alignSelf: "flex-start",
            }}
          >
            <View
              _this={_this}
              style={{ width: "100%", padding: 20, paddingBottom: 10 }}
            >
              <Text style={styles.box_header_text}>On Call Doctors</Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                padding: 20,
                alignSelf: "flex-start",
                paddingTop: 0,
              }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"on_call"} _this={_this} />
              </View>
            </View>

            <CustomDatatable
              disable_pagination={true}
              state_name={"on_call"}
              _this={_this}
              columns={[
                { "Doctor Name": "doctor_name" },
                { "Conference Mode": "conference_mode" },
                // { "Patient Name": "patient_name" },
              ]}
              data={this.state.data.map((item) => {
                return {
                  doctor_name: item.doctor_name,
                  conference_mode: item.conferenceMode,
                };
              })}
              on_page={(page, callback, table_name) => {
                UI.on_page(this.state.all_data, callback, table_name, page);
              }}
            />
            <View
              style={{
                ...styles.no_info_container,
                height: "100%",
                justifyContent:
                  this.state.data.length > 0 ? "flex-start" : "center",
                display: this.state.data.length > 0 ? "none" : "flex",
              }}
            >
              <UI.Row
                breakpoint={820}
                style={{ maxWidth: 360, alignItems: "center" }}
                _this={_this}
              >
                <Image
                  height={100}
                  width={100}
                  tintColor={"transparent"}
                  source={UI.NO_INFO}
                />
                {UI.box(10)}
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#BFBFBF",
                    textAlign: width > 820 ? "left" : "center",
                    marginLeft: width > 820 ? 20 : 0,
                  }}
                >
                  NO DATA AVAILABLE YET
                </Text>
              </UI.Row>
            </View>
          </View>
        </UI.Row>
      </MainLayout>
    );
  }
}

const Legend = (props = { text: "", color: "" }) => {
  return (
    <View style={styles.legend_container}>
      <View
        style={{ ...styles.dot_color, backgroundColor: props.color }}
      ></View>
      <Text style={styles.legend_text}>{props.text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_content_container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    flex: 1,
  },
  legend_text: {
    color: "#535353",
    fontWeight: "bold",
    fontSize: 12,
    marginLeft: 10,
  },
  legend_container: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  dot_color: {
    height: 16,
    width: 16,
    borderRadius: 10,
    backgroundColor: UI.colors.primary,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
  },
  box_header_subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#C9C9C9",
    alignSelf: "flex-end",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    backgroundColor: "white",
    padding: 20,
    ...Shadow._3(),
    flexDirection: "row",
    maxHeight: 180,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
