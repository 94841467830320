import React, { Component } from "react";
// import EmailLogin from "../../ComponentsFC/email_login/EmailLogin";
import DefaultLoginUsingPhoneNumber from "../../ComponentsFC/login/DefaultLoginUsingPhoneNumber";
import UI from "../../shared/Components/UI/js";
import {
  ScrollView,
} from "../../shared/custom-react-native";
import "./css.css";
const context = "onboard";

export default class OnboardLogin extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      remember_me: false,
      context: context,
      show_loading: false
    });
  }

  show_modal = (modal_content) => {
    this.setState({
      show_modal: true,
      modal_content: modal_content,
    });
  };

  hide_modal = () => {
    this.setState({
      show_modal: false,
      modal_content: "",
    });
  };

  show_loading = (title, text) => {
    this.setState({
      show_loading: true,
      loading_title: title,
      loading_text: text,
    });
  };

  hide_loading = () => {
    this.setState({
      show_loading: false,
    });
  };

  componentDidMount = () => {
    const _this = this.props._this;
    _this.show_loading = this.show_loading;
    _this.hide_loading = this.hide_loading;

    _this.show_modal = this.show_modal;
    _this.hide_modal = this.hide_modal;
  };

  render() {
    return (
      <>
        <ScrollView style={{ backgroundColor: "#F7F7F7" }}>
          <DefaultLoginUsingPhoneNumber _this={this}/>
        </ScrollView>
      </>
    );
  }
}