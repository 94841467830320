import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import "./css.css";
// import PromotionStats from "./PromotionStats";
// import ReferralTable from "./ReferralTable";
// import Statistics from "./Statistics";

const context = "marketing";

export default class MarketingDashboard extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Dashboard",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
    });
  }

  onCreate = () => {
    this.setState({});
    if (context === "marketing") {
      UI.goTo('/marketing/registered-referrals')
    }
  };

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        {/* <Statistics _this={_this} />
        <ReferralTable _this={_this}/> */}
      </MainLayout>
    );
  }
}