import React from 'react'
import styled from 'styled-components'
import Text from './Text'

const TermsAndCondition = () => {
  return (
    <TermsAndConditionContainer>
      <StyledText level={4}>
        By proceeding, you agree with 
        <StyledLinkTo href="https://www.mwell.com.ph/support"> mWell Terms & Conditions </StyledLinkTo> 
        and 
        <StyledLinkTo href="https://www.mwell.com.ph/support"> mWell Privacy Policy </StyledLinkTo> 
      </StyledText>
    </TermsAndConditionContainer>
  )
}

export default TermsAndCondition

const StyledText = styled(Text)`
  font-family: Poppins;
  font-weight: 400 !important; //medium
  line-height: 1.60rem !important; // 23p
  letter-spacing: 0 !important;
  color: #323232;
`;

const StyledLinkTo = styled("a")`
`;

const TermsAndConditionContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 441px;

  @media only screen and (max-width: 820px){
    width: 100%;
  }
`;

