import React from "react";
import moment from "moment";

const MOAContent = ({ _this }) => {
  const {
    first_name,
    middle_name,
    last_name,
    file_signature_path,
    // floor_number,
    house_number,
    street_name,
    barangay,
    city_municipality,
    province,
    zip_code,
  } = _this.state;

  return (
    <div className="px-20 py-6">
      <p style={{ textIndent: "2rem" }}>
        This MEMORANDUM OF AGREEMENT (hereinafter this "Agreement"), is entered
        into this <span className="underline">{moment().format("MMMM")}</span>{" "}
        day of <span className="underline">{moment().format("DD")}</span>{" "}
        <span>{moment().format("YYYY")}</span> between:
      </p>

      <div className="px-12 py-2">
        <div>
          <b>
            METRO PACIFIC HEALTH TECH CORPORATION DOING BUSINESS UNDER MWELL PH
          </b>
          , a corporation organized and existing under Philippine laws, with
          business address at 10th Floor MGO Building, Legazpi cor. Dela Rosa
          Streets, Legazpi Village, Makati City, (hereinafter referred to as{" "}
          <b>"MPHTC"</b>);
        </div>

        <p className="text-center py-2">and</p>

        <p>
          <span className="underline">{`${first_name} ${middle_name} ${last_name}`}</span>
          , Filipino, of legal age, with office address at <br></br>
          <span className="underline">{`${house_number} ${street_name} ${barangay} ${city_municipality} ${province} ${zip_code}`}</span>{" "}
          (hereinafter referred to as <b>"Physician"</b>).
        </p>

        <p>
          MPHTC and the Physician shall be individually referred to as a{" "}
          <b>"Party"</b>
          and collectively as the <b>"Parties"</b>.
        </p>
      </div>

      <p class="text-center">RECITALS:</p>

      <p>
        A. MPHTC is the owner of a mobile health application (
        <b>“Application”</b>) which offers, among others, telemedicine services
        to its users (the <b>“Users”</b>, <b>“Patients”</b>);
      </p>
      <p>
        B. MPHTC has offered the Application as a telemedicine platform and the
        Physician has accepted under the terms and conditions set forth in this
        Agreement.{" "}
      </p>
      <p>
        <b>NOW, THEREFORE</b>, for and in consideration of the foregoing
        premises, the Parties hereby agree as follows:
      </p>

      <p>
        <b>Section 1. Use of the Application</b>. The Physician shall access and
        use the Application for sole purposes of offering and providing medical
        consultations to the Users. The Application shall allow the Physician to
        maintain an online presence on the Application, answer queries of
        Patients, access medical profile and history of Patients, and conduct
        online consultations through the Application.
      </p>

      <p>
        To access and use the Application as a medical practitioner, the
        Physician must share the following information and submit the following
        documents to MPHTC upon execution of this Agreement:{" "}
      </p>

      <div className="px-12 py-2">
        <p>
          (1) <span className="ml-4">Area of specialization;</span>
        </p>
        <p>
          (2) <span className="ml-4">Affiliated hospitals and/or clinics;</span>
        </p>
        <p>
          (3) <span className="ml-4">Years of work experience;</span>
        </p>
        <p>
          (4){" "}
          <span className="ml-4">
            Professional Regdivatory Commission identification card;
          </span>
        </p>
        <p>
          (5){" "}
          <span className="ml-4">
            Any government-issued identification card with photo and signature;
            and
          </span>
        </p>
        <p>
          (6){" "}
          <span className="ml-4">
            Other information and documents as may be reasonably required by
            MPHTC.
          </span>
        </p>
      </div>

      <p style={{ textIndent: "3rem" }}>
        <b>Section 2. Professional Fees of Independent Physicians.</b> The
        professional fee of Independent Physicians (as defined below) shall
        amount to __________. Independent Physicians may, in their sole
        discretion, change the amount of the professional fee, effective
        immediately.{" "}
      </p>

      <p style={{ textIndent: "3rem" }}>
        The Independent Physician shall be paid in accordance with the Schedule
        of Payment indicated in Schedule A, which forms part of this Agreement.
        The professional fees shall be paid only upon completion of the
        consultation. Professional fees shall be remitted to the Independent
        Physician’s account in accordance with the schedule of payments
        indicated in Schedule A. For remittance purposes, the Independent
        Physician shall provide his/her bank account details to MPHTC.
      </p>

      <p style={{ textIndent: "3rem" }}>
        It is understood and agreed that the amount remitted shall be inclusive
        of applicable taxes and fees, dispute costs, refunds, and discount
        provided, if any. MPHTC reserves the right at any time to withhold or
        cancel any remittance for any act or omission which violate the terms of
        this Agreement.
      </p>

      <p style={{ textIndent: "3rem" }}>
        <b>
          Section 3. Professional Fees of hospital/clinic-acquired Physicians.{" "}
        </b>
        The rate of the professional fees of hospital/clinic-acquired Physicians
        (as defined below), including any changes to its amount and effectivity
        shall be subject to written negotiations between MPHTC and MPHTC
        Partners. The professional fees of hospital/clinic-acquired Physicians
        shall be paid directly to the MPHTC Partners in accordance with their
        agreement with MPHTC.{" "}
      </p>

      <p style={{ textIndent: "3rem" }}>
        For purposes of this Agreement, <b>“Independent Physicians”</b> shall
        mean the Physicians who are solo practitioners and{" "}
        <b>“hospital/clinic-acquired Physicians”</b> means Physicians employed
        and/or salaried by partner hospitals/clinics of MPHTC (“
        <b>MPHTC Partners”</b>).
      </p>

      <p style={{ textIndent: "3rem" }}>
        <b>Section 4. Service Fees. </b>In consideration of the Platform
        services, MPHTC shall charge the Patients a service fee on top of the
        Physician’s professional fee (the <b>“Service Fee”</b>). The Service Fee
        shall range from Fifty Pesos to Five Hundred Pesos (Php50 to Php 500),
        which may be subject to change depending on MPHTC’s sole discretion.
      </p>

      <p style={{ textIndent: "3rem" }}>
        <b>Section 5. Term.</b> This Agreement shall become effective on the
        date of its execution and shall continue in force and effect for a
        period of one year (the <b>“Initial Term”</b>) from the date hereof,
        unless sooner pre-terminated by the appropriate Party in accordance with
        Section 11 of this Agreement. After the Initial Term, this Agreement
        shall be deemed renewed automatically each year for an additional
        one-year period unless MPHTC or the Physician elects not to renew this
        Agreement.{" "}
      </p>

      <p style={{ textIndent: "3rem" }}>
        <b>Section 6. Representations and Warranties of the Physician.</b> The
        Physician represents and warrants that:
      </p>

      <div className="px-12 py-2">
        <p>
          (a){" "}
          <span className="ml-4">
            He/she is duly licensed to practice medicine by the Philippine
            Regulatory Commission;
          </span>
        </p>
        <p>
          (b){" "}
          <span className="ml-4">
            He/she is a member in good standing of the Philippine Medical
            Association;
          </span>
        </p>
        <p>
          (c){" "}
          <span className="ml-4">
            He/she has the specialized skills, expertise and experience to
            provide medical advice to the Users on this background;{" "}
          </span>
        </p>
        <p>
          (d){" "}
          <span className="ml-4">
            He/she has no pending or decided cases and/or complaint for medical
            negligence and/or medical offenses; and
          </span>
        </p>
        <p>
          (e){" "}
          <span className="ml-4">
            He/she is complying with all applicable laws and regulations while
            using the Application or while providing services through the
            Application.
          </span>
        </p>
      </div>

      <p style={{ textIndent: "3rem" }}>
        The foregoing representations and warranties of the Physician are deemed
        made on the date of this Agreement and shall continue to be effective
        for the duration of this Agreement.
      </p>

      <p style={{ textIndent: "3rem" }}>
        <b>Section 7. Obligations of MPHTC.</b> In consideration of the Client’s
        undertakings under this Agreement, MPHTC shall provide the Application
        as a telemedicine platform in accordance with this Agreement and
        applicable laws, and procure all required certificates, permits,
        licenses and registrations as may be required for the proper conduct of
        its business.
      </p>

      <p style={{ textIndent: "3rem" }}>
        <b>Section 8. Obligations of the Physician.</b> The Physician undertakes
        the following:
      </p>

      <div className="px-12 py-2">
        <p>
          (a){" "}
          <span className="ml-4">
            conduct himself in a professional manner and shall observe the
            International Code of Medical Ethics and the Code of Ethics as
            approved by the Philippine Medical Association;
          </span>
        </p>
        <p>
          (b){" "}
          <span className="ml-4">
            comply with all laws and regulations relating to the practice of
            medicine;
          </span>
        </p>
        <p>
          (c){" "}
          <span className="ml-4">
            comply with applicable industry standards imposed by the Department
            of Health, including standards and regulations for Coronavirus
            (COVID-19);
          </span>
        </p>
        <p>
          (d){" "}
          <span className="ml-4">
            comply with other relevant laws, including, but not limited to, the
            Data Privacy Act of 2012 and its implementing rules and regulations;
          </span>
        </p>
        <p>
          (e){" "}
          <span className="ml-4">
            comply with all guidelines that MPHTC shall prescribe in connection
            with the use of the Application;
          </span>
        </p>
        <p>
          (f){" "}
          <span className="ml-4">
            make himself available at the days and times scheduled for
            teleconsultations; and
          </span>
        </p>
        <p>
          (g){" "}
          <span className="ml-4">
            comply with the terms of Service Level Agreement attached herein as
            Annex “A”, as may be amended from time to time.
          </span>
        </p>
      </div>

      <p style={{ textIndent: "3rem" }}>
        <b>Section 9. Schedule; Disclosure of Information. </b>To ensure
        effective and efficient service to the Users, the Physician is expected
        to attend all confirmed booked appointments. For clarity,
        hospital/clinicacquired Physicians should be available at the days and
        times scheduled for teleconsultations with the Users.
      </p>

      <p style={{ textIndent: "3rem" }}>
        The Physician hereby consents to the disclosure of his/her relevant
        personal information and details (such as full name, specialty, contact
        details, address, etc.) in the Application and MPHTC’s website and other
        platforms.
      </p>

      <p style={{ textIndent: "3rem" }}>
        <b>Section 10. Service Level Agreement.</b> The Parties shall comply
        with the terms of the Service Level Indicators attached as Annex “A”,
        which shall form an integral part of this Agreement. Violation of the
        Service Level Indicators as provided in Annex A shall be considered as a
        ground for termination of the Agreement.
      </p>

      <p style={{ textIndent: "3rem" }}>
        <b>Section 11. Suspension. </b>MPHTC may, at its sole discretion,
        suspend the Physician’s ability to use or access the Application at any
        time pending investigation of any potential violation of this Agreement
        or other applicable laws and regulations.{" "}
      </p>

      <p style={{ textIndent: "3rem" }}>
        <b>Section 12. Termination.</b> Notwithstanding the Term provided in
        Section 4, this Agreement may be terminated:
      </p>

      <div className="px-12 py-2">
        <div>
          <p>
            (a) <span className="ml-4">By the Physician:</span>
          </p>
          <p className="flex ml-4">
            <div>(i)</div>
            <div className="ml-8">
              in case MPHTC commits a breach of any of its warranties or
              material obligations under this Agreement, which breach is not
              remediable, or if remediable, is not cured by the MPHTC within
              thirty (30) days from receipt of written notice from the
              Physician;
            </div>
          </p>
          <p className="flex ml-4">
            <div>(ii)</div>
            <div className="ml-8">
              in case any act or proceeding by or against MPHTC for bankruptcy,
              insolvency, relief of debtors or the appointment of a receiver,
              trustee or custodian for it or for all or any substantial part of
              its assets is commenced and such action or proceeding (if
              commenced by a third party) is not dismissed or set aside within
              sixty (60) days from the institution thereof or if MPHTC has been
              adjudged bankrupt or insolvent, or if any execution affecting a
              substantial part of its assets is issued against it, or if MPHTC
              assigns its assets for the benefit of its creditors;
            </div>
          </p>
          <p className="flex ml-4">
            <div>(iii)</div>
            <div className="ml-8">
              in case of Force Majeure as provided in Section 12 of this
              Agreement;
            </div>
          </p>
        </div>
        <div>
          <p>
            (b) <span className="ml-4">By MPHTC:</span>
          </p>
          <p className="flex ml-4">
            <div>(i)</div>
            <div className="ml-8">
              in case the services of the Physician be found unsatisfactory on
              the basis of general professional standards within thirty (30)
              days from receipt of written notice from MPHTC, including, but not
              limited to, the Physician committing five (5) consecutive no shows
              as provided in Section 8;
            </div>
          </p>
          <p className="flex ml-4">
            <div>(ii)</div>
            <div className="ml-8">
              in case the Physician is permanently disabled or incapacitated to
              perform his services;
            </div>
          </p>
          <p className="flex ml-4">
            <div>(iii)</div>
            <div className="ml-8">
              in case the Physician commits a breach of any of its material
              obligations under this Agreement (other than (i) above), which
              breach is not remediable, or if remediable, is not cured by the
              Physician within thirty (30) days from receipt of written notice
              from MPHTC;
            </div>
          </p>
          <p className="flex ml-4">
            <div>(iv)</div>
            <div className="ml-8">
              in case the Physician is found guilty of medical malpractice
              and/or medical negligence, effective immediately;
            </div>
          </p>
          <p className="flex ml-4">
            <div>(v)</div>
            <div className="ml-8">
              in case of Force Majeure as provided in Section 12 of this
              Agreement;{" "}
            </div>
          </p>
          <p className="flex ml-4">
            <div>(vi)</div>
            <div className="ml-8">
              in case of the revocation or suspension of the Physician’s license
              to practice medicine for any reason, effective immediately, and
              for this purpose, the Physician undertakes to promptly inform
              MPHTC of any revocation or suspension of his/her license to
              practice medicine; or{" "}
            </div>
          </p>
        </div>
        <div>
          <p>
            (c) <span className="ml-4">by mutual agreement of the Parties</span>
          </p>
        </div>
        <p style={{ textIndent: "3rem" }}>
          Termination of hospital/clinic-acquired Physicians shall be governed
          by the definitive agreement between MPHTC and MPHTC Partners.
        </p>
      </div>
      <p style={{ textIndent: "3rem" }}>
        <b>Section 13. Data Privacy.</b> The Parties agree to comply with, and
        have adequate measures in place to ensure that its directors, officers,
        employees, and representatives comply at all times with: (a) the
        provisions and obligations contained in Republic Act No. 10173,
        otherwise known as the Data Privacy Act of the Philippines and its
        implementing rules and regulations with respect to any personal data,
        and (b) other applicable data privacy laws and regulations, as may be
        promulgated and/or amended from time to time. By entering into this
        Agreement, the Parties agree that any information exchanged among them
        may be collected, processed, shared and used but only (i) for the
        performance of the services, and (ii) for statistical, marketing, and
        scientific research purposes. Each Party warrants that all consents
        necessary to be obtained for the disclosure of personal data required
        under all relevant privacy laws and regulations have been obtained prior
        to any disclosure thereof, and agrees to hold the other Party free and
        harmless from any costs or liability arising from its failure to comply
        with the requirements of the Data Privacy Act of the Philippines.
      </p>
      <p style={{ textIndent: "3rem" }}>
        By accepting the healthcare platform services of MPHTC, the Physician
        further agrees to be bound by the terms and conditions, and privacy
        policy of the Application in accordance with the Data Privacy Act of the
        Philippines.
      </p>
      <p style={{ textIndent: "3rem" }}>
        The Parties shall likewise abide by the terms of the data sharing
        agreement provided in Schedule B, which shall form an integral part of
        this Agreement.
      </p>
      <p style={{ textIndent: "3rem" }}>
        <b>Section 14. Force Majeure</b>{" "}
      </p>
      <div>
        <p style={{ textIndent: "3rem" }}>
          (a){" "}
          <span className="ml-4">
            Neither party shall be considered in breach of this Agreement to the
            extent that performance of their respective obligations (excluding
            payment obligations) is prevented by an Event of Force Majeure that
            arises after the commencement date. The Party (“Affected Party”)
            prevented from carrying out its obligations hereunder shall give
            notice to the other Party of an Event of Force Majeure upon it being
            foreseen by, or becoming known to, the Affected Party.
          </span>
        </p>
        <p style={{ textIndent: "3rem" }}>
          (b){" "}
          <span className="ml-4">
            If and to the extent that a Party is prevented from providing the
            Services by the Event of Force Majeure, while such Party is so
            prevented, it shall be relieved of its obligations to provide the
            services but shall endeavor to continue to perform its obligations
            as far as reasonably practicable. Should the Party incur additional
            costs in so doing, such Party shall be entitled to the amount of
            such actual, reasonable and documented costs incurred provided
            notice shall as much as practicable be given to the other Party
            prior to incurring such cost.
          </span>
        </p>
        <p style={{ textIndent: "3rem" }}>
          (c){" "}
          <span className="ml-4">
            In case the Event of Force Majeure prevents a Party from rendering
            the Services or any material portion thereof for a continuous period
            of sixty (60) days, the Parties may terminate this Agreement.
          </span>
        </p>
        <p style={{ textIndent: "3rem" }}>
          (d){" "}
          <span className="ml-4">
            For this purpose, the term “Event of Force Majeure” means an event
            beyond the control of either Party, which prevents a party from
            complying with its obligations
          </span>
        </p>
      </div>
      <p style={{ textIndent: "3rem" }}>
        <b>Section 15. Indemnification.</b> The Physician undertakes to
        indemnify, defend and hold harmless MPHTC and its officers, directors,
        shareholders, employees and agents (the “MPHTC Representatives”) from
        and against any and all demands, claims, complaints, actions or causes
        of action, suits, proceedings, investigations, arbitrations,
        assessments, losses, damages, liabilities, costs and expenses
        (including, but not limited to, interest, penalties and reasonable
        attorneys’ fees and disbursements), asserted against, imposed upon or
        incurred by MPHTC, by reason of or resulting from material breach or
        gross negligence by the Physician with or of any covenants, agreements
        or undertakings of the Physician contained in, or made pursuant to, this
        Agreement.
      </p>
      <p style={{ textIndent: "3rem" }}>
        Without prejudice to the generality of the above, the Physician shall
        indemnify, defend and save harmless MPHTC and the MPHTC Representatives
        from any loss, damages, costs, charges, expenses and injury that MPHTC
        may suffer due to the Physician’s acts, including the following acts:
      </p>
      <p className="flex ml-4">
        <div>(i)</div>
        <div className="ml-8">
          any wrong medication or treatment, or any medical negligence on part
          of the Physician;
        </div>
      </p>
      <p className="flex ml-4">
        <div>(ii)</div>
        <div className="ml-8">
          any misrepresentations, misconduct or inappropriate behavior by the
          Physician;
        </div>
      </p>
      <p className="flex ml-4">
        <div>(iii)</div>
        <div className="ml-8">
          cancellation or rescheduling of booked appointment or any variance in
          the fees charged; and;
        </div>
      </p>
      <p className="flex ml-4">
        <div>(iv)</div>
        <div className="ml-8">
          any direct or indirect medical eventualities that might occur
          subsequent to the provision of services by the Physician to the Users
          through the Application
        </div>
      </p>
      <p>
        <b>Section 16. Miscellaneous. </b>
      </p>
      <p style={{ textIndent: "3rem" }}>
        <b>(a)</b>
        <span className="ml-4">
          <b>Entire Agreement.</b> This Agreement constitutes the entire
          agreement and understanding between the Parties and supersedes all
          previous agreements, understandings and undertakings.
        </span>
      </p>
      <p style={{ textIndent: "3rem" }}>
        <b>(b)</b>
        <span className="ml-4">
          <b>Governing Law.</b> This Agreement shall be governed, interpreted
          and construed in accordance with the laws of the Philippines.
        </span>
      </p>
      <p style={{ textIndent: "3rem" }}>
        <b>(c)</b>
        <span className="ml-4">
          <b>Dispute Resolution. </b>Any dispute, controversy, difference or
          claim arising out of or relating to this contract, including the
          existence, validity, interpretation, performance, breach or
          termination thereof or any dispute regarding non-contractual
          obligations arising out of or relating to it (<b>“Dispute”</b>) shall
          be settled, to the extent possible, through amicable negotiations or
          discussions of the Parties senior executive officers.
        </span>
      </p>
      <p style={{ textIndent: "3rem" }}>
        In case the Dispute cannot be resolved in this manner within thirty (30)
        days from referral of the same for settlement, the Dispute shall be
        resolved by arbitration in accordance with the rules of the Philippine
        Dispute Resolution, Inc. (“<b>PDRCI Rules</b>”) in force when the notice
        of arbitration is submitted. Any award rendered by the arbitrator(s)
        shall be final and binding upon the Parties, and judgment upon the award
        rendered may be entered in any court having jurisdiction thereof or
        having jurisdiction over the Parties or their assets. The seat of
        arbitration shall be in Makati City. The proceedings shall be conducted
        by three (3) arbitrators, with each Party appointing an arbitrator, and
        such appointed arbitrators selecting the third. The arbitration
        proceedings shall be conducted in the English language.
      </p>
      <p style={{ textIndent: "3rem" }}>
        <b>(d)</b>
        <span className="ml-4">
          <b>No Waiver.</b> No waiver of any breach of these provisions will be
          effective unless such waiver is in writing and signed by the Party
          against whom such waiver is claimed. No waiver of any breach shall be
          deemed to be a waiver of any other or subsequent breach.
        </span>
      </p>
      <p style={{ textIndent: "3rem" }}>
        <b>(e)</b>
        <span className="ml-4">
          <b>Amendment.</b> No amendment, modifications, waiver, change or
          addition hereto shall be effective and binding on any of the Parties
          hereto unless the same is in writing and signed by the Parties.
        </span>
      </p>
      <p style={{ textIndent: "3rem" }}>
        <b>(f)</b>
        <span className="ml-4">
          <b>Relation of the Parties.</b> Unless otherwise agreed, no Party
          shall be deemed to be a representative, an agent or an employee of any
          other Party or to have any fiduciary obligations toward any other
          Party. Unless otherwise expressly specified herein, no Party shall
          have any authority or right to bind any other Party in any respect,
          without the specific prior written authorization of the other Party.
        </span>
      </p>
      <p>
        <b>(g)</b>
        <span className="ml-4">
          <b>Counterparts.</b> This Agreement may be executed in counterparts,
          which when taken together, shall constitute one instrument.
        </span>
      </p>
      <p>
        <b>
          METROPACIFIC HEALTH TECH <br /> CORPORATION
        </b>
      </p>

      <p>By:</p>
      <div className="flex justify-between px-12">
        <p>____________________</p>
        <div className="text-center">
          {file_signature_path.url && (
            <img
              className="w-32 mx-auto"
              src={file_signature_path.url}
              alt="signature"
            />
          )}
          <div>____________________</div>
          <b>{`${first_name} ${middle_name} ${last_name}`}</b>
          <div>Physician</div>
        </div>
      </div>

      <p className="text-center mt-4">
        <b>SCHEDULE A</b>
      </p>
      <p className="text-center mt-4">
        <b>SCHEDULE OF PAYMENT</b>
      </p>
      <p>
        The Physician shall be paid a professional fee net of MPHTC’s commission
        every 15th and 30th calendar day. Payment shall be deposited in the bank
        provided in the Application. In the event a User cancels the scheduled
        appointment with the Physician within six (6) hours before the
        appointment, the Physician shall be entitled to fifty percent (50%) of
        his professional fee.
      </p>

      <p className="text-center mt-4">
        <b>SCHEDULE B</b>
      </p>
      <p className="text-center mt-4">
        <b>DATA SHARING AGREEMENT</b>
      </p>

      <p style={{ textIndent: "2rem" }}>
        This Data Sharing Agreement (the <b>“Agreement”</b>) is executed and
        entered this{" "}
        <span className="underline">{moment().format("MMMM")}</span> day of{" "}
        <span className="underline">{moment().format("DD")}</span>{" "}
        <span>{moment().format("YYYY")}</span> in __________, by and between:
      </p>
      <div className="px-12 py-2">
        <p>
          <b>METRO PACIFIC HEALTH TECH CORPORATION</b>, a corporation existing
          under and by virtue of the laws of the Philippines, with principal
          offices at 10/F MGO Bldg., Legaspi cor. Dela Rosa St. Legaspi Village,
          Makati, represented herein by its President, Jose Ma. K. Lim
          (hereinafter referred to as <b>“MPHTC”</b>);
        </p>
        <p className="text-center py-2">and</p>

        <p>
          <span className="underline">{`${first_name} ${middle_name} ${last_name}`}</span>
          , Filipino, of legal age, with office address at <br></br>
          <span className="underline">{`${house_number} ${street_name} ${barangay} ${city_municipality} ${province} ${zip_code}`}</span>{" "}
          (hereinafter referred to as <b>"PHYSICIAN"</b>).
        </p>

        <p>
          Each <b>MPHTC</b> and the <b>PHYSICIAN</b> shall be individually
          referred to as a <b>"Party"</b>
          and collectively as the <b>"Parties"</b>.
        </p>
      </div>
      <p>RECITALS:</p>
      <div className="px-10 py-2">
        <p className="flex">
          <div>A.</div>
          <p className="ml-2">
            {" "}
            MPHTC and the PHYSICIAN intend to share Personal Information and
            Sensitive Personal Information which is owned, collected, and
            processed by MPHTC from its customers (the <b>“Data Subjects”</b>
            ).
          </p>
        </p>
        <p className="flex">
          <div>B.</div>
          <p className="ml-2">
            {" "}
            The Parties are aware of Republic Act No. 10173, otherwise known as
            the “Data Privacy Act of 2012 (the <b>“Data Privacy Act”</b>
            ), and understand the need to comply with the requirements of the
            same, including the execution of a data sharing agreement.
          </p>
        </p>
        <p className="flex">
          <div>C.</div>
          <p className="ml-2">
            The Parties have agreed that the further processing, access,
            safekeeping, sharing, transfer, and disposal of any Personal
            Information and/or Sensitive Personal Information collected from the
            Data Subjects shall be governed by terms of this Agreement and all
            applicable laws.
          </p>
        </p>
      </div>
      <p style={{ textIndent: "3rem" }}>
        NOW, THEREFORE, for and in consideration of the foregoing premises and
        the provisions hereinafter contained, the parties to this Agreement
        hereby agree, as follows:
      </p>

      <p>
        <b>1. DEFINITION OF TERMS</b>
      </p>

      <p>
        As used herein, the following terms shall have the following meanings:
      </p>
      <p>
        <b>“Agreement”</b> shall have the meaning ascribed to it in the preamble
        of this Agreement.
      </p>
      <p>
        <b>"Data”</b> shall have the meaning ascribed to it in Section 2 of this
        Agreement.
      </p>
      <p>
        <b>“Data Privacy Act”</b> shall refer to Republic Act No. 10173,
        otherwise known as the “Data Privacy Act of 2012, approved on 15 August
        2012, and subsequent amendments thereto.{" "}
      </p>
      <p>
        <b>“Data Subject”</b> shall refer to MPHTC’s customers whose personal
        information is collected and processed.{" "}
      </p>
      <p>
        <b>“IRR”</b> shall refer to the Implementing Rules and Regulations of
        the Data Privacy Act, which was promulgated on 24 August 2016, and
        subsequent amendments thereto.
      </p>
      <p>
        <b>“NPC”</b> shall refer to the National Privacy Commission of the
        Philippines.
      </p>
      <p>
        <b>“Party”</b> or <b>“Parties”</b> shall have the meaning ascribed to it
        in the preamble of this Agreement.
      </p>
      <p>
        <b>“Personal Data Breach”</b> shall refer to a breach of security
        leading to the accidental or unlawful destruction, loss, alteration,
        unauthorized disclosure of, or access to Personal Information and/or
        Sensitive Personal Information transmitted, stored, or otherwise
        processed.
      </p>
      <p>
        <b>“Personal Information”</b> shallrefer to any information whether
        recorded in a material form or not, from which the identity of an
        individual is apparent or can be reasonably and directly ascertained by
        the entity holding the information, or when put together with other
        information would directly and certainly identify an individual.
      </p>
      <p>
        <b>“Processing”</b> shall refer to any operation or any set of
        operations performed upon personal information including, but not
        limited to, the collection, recording, organization, storage, updating
        or modification, retrieval, consultation, use, consolidation, blocking,
        erasure, or destruction of data.
      </p>
      <p>
        <b>“Sensitive Personal Information”</b> shall refer to personal
        information: (a) about an individual’s race, ethnic origin, marital
        status, age, color, and religious, philosophical, or political
        affiliations; (b) about an individual’s health, education, genetic or
        sexual life of a person, or to any proceeding for any offense committed
        or alleged to have been committed by such individual, the disposal of
        such proceedings, or the sentence of any court in such proceedings; (c)
        issued by government agencies peculiar to an individual which includes,
        but is not limited to, social security numbers, previous or current
        health records, licenses or its denials, suspension or revocation, and
        tax returns; and (d) specifically established by an executive order or
        an act of Congress to be kept classified.
      </p>

      <p>
        <b>2. COVERAGE</b>
      </p>

      <p>
        The Parties agree that the Personal Information and/or Sensitive
        Personal Information of the Data Subjects, subject of this Agreement,
        that is owned, collected, and otherwise processed by MPHTC from the Data
        Subjects shall be limited to the following (collectively referred to as
        the <b>“Data”</b>):{" "}
      </p>
      <ul className="pl-16 mt-4">
        <li>a. Name;</li>
        <li>b. Age;</li>
        <li>c. Gender;</li>
        <li>d. Medical information and medical history;</li>
        <li>e. Major health complaint/s;</li>
        <li>f. Vital signs;</li>
        <li>
          g. Result of x-ray, scans, laboratory tests and other diagnostic
          procedures; and
        </li>
        <li>
          h. Other information which is necessary for accurate diagnosis of
          users of MPHTC’s mobile application.{" "}
        </li>
      </ul>
      <p>
        <b>3. DUTIES AND OBLIGATIONS OF THE PARTIES</b>
      </p>

      <p>
        3.1. MPHTC shall share with, or otherwise to transfer or transmit to the
        PHYSICIAN the Data described in Section 2 of this Agreement.
      </p>

      <div className="pl-16 mt-4">
        <p>
          The Data collected, processed and shared pursuant to this Agreement
          shall be processed and shared exclusively for the purpose of the
          performance of the services, unless otherwise provided by applicable
          laws, rules, regulations, or relevant issuances by the National
          Privacy Commission of the Philippines (<b>“NPC”</b>) or any separate
          agreement with the Data Subject.{" "}
        </p>

        <p>
          The Data may be accessed through a database dashboard by the employees
          and authorized representatives of both Parties to render the services
          to the Data Subjects. Authorized personnel with access to the Data
          shall verify identity using a secure encrypted link and multi-level
          authentication.
        </p>
      </div>
      <p>3.2. The PHYSICIAN shall:</p>
      <div className="pl-16 mt-4">
        <div className="flex">
          <div>a. </div>
          <p className="ml-4">
            Unless otherwise provided by law, not disclose, trade, sell, or
            transfer any Data to any person or entity without the written
            instructions of MPHTC;{" "}
          </p>
        </div>
        <div className="flex">
          <div>b. </div>
          <p className="ml-4">
            Not use or process the Data, except in connection with the purposes
            as provided under this Agreement;
          </p>
        </div>
        <div className="flex">
          <div>c. </div>
          <p className="ml-4">
            As applicable, allow Data Subjects to access and correct the Data in
            its custody and to exercise their rights as provided under the Data
            Privacy Act, its IRR and relevant issuances of the NPC;and
          </p>
        </div>
        <div className="flex">
          <div>d. </div>
          <p className="ml-4">
            Notify MPHTC, if through mistake or negligence, information not
            covered by this Agreement was sent to it by MPHTC.
          </p>
        </div>
      </div>
      <p>
        <b>4. MODE OF DATA SHARING</b>
      </p>
      <p>
        The Data shall be shared between MPHTC and the PHYSICIAN through a
        database dashboard using a secure email facility with encryption of the
        data or other means of preventing unauthorized access, including any or
        all attachments. Facsimile technology shall not be used for transmitting
        documents containing personal data.
      </p>
      <p>
        <b>5. REPRESENTATIONS AND WARRANTIES</b>
      </p>
      <p>5.1. Each of MPHTC and the PHYSICIAN represent and warrant that:</p>

      <div className="pl-16 mt-4">
        <div className="flex">
          <div>a. </div>
          <p className="ml-4">
            The collection and processing of the Data under this Agreement
            adheres to the principles of transparency, legitimate purpose, and
            proportionality.
          </p>
        </div>
        <div className="flex">
          <div>b. </div>
          <p className="ml-4">
            It shall fully comply with the provisions of this Agreement, the
            Data Privacy Act and its IRR, and all other issuances of the NPC.
          </p>
        </div>
        <div className="flex">
          <div>c. </div>
          <p className="ml-4">
            This Agreement shall be made available for the review of the NPC in
            cases required under the Data Privacy Act.
          </p>
        </div>
        <div className="flex">
          <div>d. </div>
          <p className="ml-4">
            The rights of the Data Subject shall be upheld without compromising
            research integrity. Personal Data collected from parties other than
            the Data Subjects for the purpose of research was either publicly
            available or obtained through the consent of the Data Subject.
          </p>
        </div>
      </div>
      <p>5.2.MPHTC represents and warrants that:</p>

      <div className="pl-16 mt-4">
        <div className="flex">
          <div>a. </div>
          <p className="ml-4">
            Prior to collecting the Data, or prior to the sharing of Data, it
            shall secure the consent of the Data Subject with respect to the
            collection and further processing of the Data and the sharing,
            storage, transfer, and transmission of the same in accordance with
            this Agreement. It shall secure the Data Subject’s consent through
            written, electronic, or recorded means.
          </p>
        </div>
        <div className="flex">
          <div>b. </div>
          <p className="ml-4">
            It has provided the following information to the Data Subjects prior
            to collection or sharing of the Data:
          </p>
        </div>
        <ul className="pl-12">
          <li className="flex">
            <div>(i)</div>
            <div className="ml-2">
              Identity of the third parties that will be given access to the
              Data;
            </div>
          </li>
          <li className="flex">
            <div>(ii)</div>
            <div className="ml-2">Purpose of the data sharing;</div>
          </li>
          <li className="flex">
            <div>(iii)</div>
            <div className="ml-2">Categories of Data concerned;</div>
          </li>
          <li className="flex">
            <div>(iv)</div>
            <div className="ml-2">
              Intended recipients or categories of recipients of the Data;
            </div>
          </li>
          <li className="flex">
            <div>(v)</div>
            <div className="ml-2">
              Existence of the rights of the Data Subjects, including the right
              to access and to correct the Data, and the right to object to data
              sharing or further processing;
            </div>
          </li>
          <li className="flex">
            <div>(vi)</div>
            <div className="ml-2">
              If applicable, the automated processing of the Data for profiling;
              and
            </div>
          </li>
          <li className="flex">
            <div>(vii)</div>
            <div className="ml-2">
              Other information that would sufficiently notify the Data Subject
              of the nature and extent of data sharing and the manner of
              processing.6.
            </div>
          </li>
        </ul>
        <div className="flex">
          <div>c. </div>
          <p className="ml-4">
            This Agreement shall be made available for the review of the NPC in
            cases required under the Data Privacy Act.
          </p>
        </div>
        <div className="flex">
          <div>d. </div>
          <p className="ml-4">
            The rights of the Data Subject shall be upheld without compromising
            research integrity. Personal Data collected from parties other than
            the Data Subjects for the purpose of research was either publicly
            available or obtained through the consent of the Data Subject.
          </p>
        </div>
      </div>
      <p>
        <b>6. SECURITY MEASURES</b>
      </p>
      <p>
        Each Party, as a personal information controller, shall implement
        reasonable and appropriate physical, technical, and organizational
        measures for the protection of personal data. Security measures aim to
        maintain the availability, integrity, and confidentiality of personal
        data and protect them against natural dangers such as accidental loss or
        destruction, and human dangers such as unlawful access, fraudulent
        misuse, unlawful destruction, alteration, and contamination.{" "}
      </p>
      <p>
        <b>7.PERSONAL DATA BREACH</b>
      </p>
      <p>
        In the event of a Personal Data Breach involving the Data while the same
        is being processed by or is in the custody or control of the PHYSICIAN,
        the PHYSICIAN shall notify MPHTC in writing within six (6) hours upon
        knowledge of, or when there is reasonable belief by the PHYSICIAN that a
        Personal Data Breach has occurred.
      </p>
      <p>
        The notification shall describe the nature of the breach, the Personal
        Information and/or Sensitive Personal Information possibly involved, the
        list of Data Subjects involved, the possible risks, harm, or damage to
        the Data Subjects, and the measures taken by the PHYSICIAN to address
        the breach. The notification shall also state whether the PHYSICIAN
        shall need to notify the NPC and the Data Subjects of the breach
        andshall attach a draft of the notification for MPHTC’s comment.
      </p>
      <p>
        <b>8.TERM</b>
      </p>
      <p>
        This Agreement shall be effective on the date of its execution. This
        Agreement shall be coterminous with the Memorandum of Agreement between
        MPHTC and the PHYSICIAN. This Agreement may be renewed if the purposes
        of this Agreement subsists. The term of this Agreement or any subsequent
        extension thereof shall in no case be for more than five (5) years,
        without prejudice to a new data sharing agreement that may be entered
        into by the Parties.
      </p>
      <p>
        <b>9.DESTRUCTION OR DISPOSAL OF THE SHARED DATA</b>
      </p>
      <p>
        Upon the termination of this Agreement, or upon MPHTC’s request, the
        PHYSICIAN shall promptly destroy, dispose, or surrender to MPHTC, as
        applicable, the Data and any other property, information, and documents
        containing the Data and/or copies thereof in the PHYSICIAN’s possession,
        custody, or control. If requested by MPHTC, the PHYSICIAN shall deliver
        to MPHTC a certificate confirming the PHYSICIAN’s compliance with the
        obligation to destroy, dispose, surrender, or return under this Section.
      </p>
      <p>
        <b>10.INDEMNITY AND LIABILITY</b>
      </p>
      <p>
        The PHYSICIAN shall indemnify, defend and/or settle, and hold harmless
        MPHTC, its directors, officers, employees, agents, and representatives,
        from and against any and all actual, reasonable, and documented
        assessments, claims, demands or suits, liabilities, damages, and
        obligations (including, without limitation, reasonable attorneys’ fees)
        resulting from or arising out of any breach by the PHYSICIAN of its
        obligations, duties, representations, or warranties under this
        Agreement; provided, however that the indemnity in this Section shall
        not apply to any loss, claim, damage, or liability caused by the
        negligence or wilful misconduct of the PHYSICIAN.
      </p>
      <p>
        <b>11.RIGHTS OF A DATA SUBJECT</b>
      </p>
      <p>
        As applicable, each of MPHTC and the PHYSICIAN shall ensure that the
        following rights of the Data Subjects are upheld:
      </p>
      <div className="pl-16 mt-4">
        <div className="flex">
          <div>a. </div>
          <p className="ml-4">Availability of the Agreement</p>
        </div>
      </div>
      <p>
        A copy of this Agreement may be accessed by a Data Subject through email
        upon written request, provided that the Parties have a right to prevent
        the disclosure of any detail or information that could endanger the
        Security Measures set out in Section 6 of this Agreement or expose to
        harm the integrity, availability, or confidentiality of Data under its
        control or custody.
      </p>
      <div className="pl-16 mt-4">
        <div className="flex">
          <div>b. </div>
          <p className="ml-4">Access to Information</p>
        </div>
      </div>
      <p>
        Upon the request of a Data Subject, the concerned Party may release to
        the requesting Data Subject the following information with respect to
        his Data:
      </p>
      <ul className="pl-28">
        <li className="flex">
          <div>(i)</div>
          <div className="ml-2">
            Contents of his/her Data that were processed;
          </div>
        </li>
        <li className="flex">
          <div>(ii)</div>
          <div className="ml-2">
            Sources from which his/her Data were obtained;
          </div>
        </li>
        <li className="flex">
          <div>(iii)</div>
          <div className="ml-2">
            Names and addresses of the recipients of his/her Data;
          </div>
        </li>
        <li className="flex">
          <div>(iv)</div>
          <div className="ml-2">
            Manner by which his/her Data was processed;
          </div>
        </li>
        <li className="flex">
          <div>(v)</div>
          <div className="ml-2">
            Reasons for the disclosure of his/her Data to recipients, if any;
          </div>
        </li>
        <li className="flex">
          <div>(vi)</div>
          <div className="ml-2">
            Date when his/her Data was last accessed or modified; and
          </div>
        </li>
        <li className="flex">
          <div>(vii)</div>
          <div className="ml-2">
            The identity and address of the owner of the Data.
          </div>
        </li>
      </ul>
      <div className="pl-16 mt-4">
        <div className="flex">
          <div>c. </div>
          <p className="ml-4">Correction of Data</p>
        </div>
      </div>
      <p>
        The concerned Party may provide to the Data Subject the means and
        methods whereby the Data Subject may dispute the inaccuracy or error in
        the Data and correct the Data immediately and accordingly, unless the
        request is vexatious or otherwise unreasonable. The concerned Party
        shall inform the other Party of thecorrection or rectification.
      </p>
      <div className="pl-16 mt-4">
        <div className="flex">
          <div>d. </div>
          <p className="ml-4">Deletion of Data</p>
        </div>
      </div>
      <p>
        The concerned Party may provide to the Data Subject the means and
        methods whereby the Data Subject may order the blocking, removal, or
        destruction of his/her Data.
      </p>
      <div className="pl-16 mt-4">
        <div className="flex">
          <div>export. </div>
          <p className="ml-4">Remedies</p>
        </div>
      </div>
      <p>
        The concerned Party may provide to the Data Subject the means and
        methods whereby the Data Subject may report any security breach or
        violation of his rights with respect to the Processing of his/her Data,
        as well as the procedures whereby such reports are timely and adequately
        addressed.
      </p>
      <p>
        <b>12. AMENDMENTS</b>
      </p>
      <p>
        This Agreement cannot be modified, amended, or changed except in writing
        and signed by the Parties.
      </p>
      <p>
        <b>13. SEVERABILITY</b>
      </p>
      <p>
        In case any one or more of the provisions contained in this Agreement
        shall be invalid, illegal, or unenforceable in any respect, the
        validity, legality, or enforceability of the remaining provisions herein
        and therein shall not in any way be affected or impaired hereby.
      </p>
      <p>
        <b>14.ASSIGNMENT</b>
      </p>
      <p>
        Neither the rights nor the obligations of either party may be assigned
        or delegated in whole or in part without the prior written consent of
        the other party unless otherwise expressly permitted under the
        Agreement. Any delegation without written permission shall be null and
        void and of no effect unless otherwise expressly permitted under the
        Agreement.
      </p>
      <p>
        <b>15. GOVERNING LAW</b>
      </p>
      <p>
        This Agreement and any dispute or claim arising out of or in connection
        with it (including any non-contractual disputes or claims obligations
        arising out of or in connection with the same) shall be governed by and
        construed in accordance with the laws of the Philippines. The parties
        hereby submit irrevocably to the exclusive jurisdiction of the
        Philippine Courts in respect of any claim, dispute, or difference
        concerning this Agreement or any matter arising therefrom.
      </p>
      <p className="text-center">
        <i>[Signature page follows.]</i>
      </p>
      <p>
        IN WITNESS WHEREOF, the Parties, acting through their representatives,
        have caused this Data Sharing Agreement to be duly executed at
        ___________, this{" "}
        <span className="underline">{moment().format("MMMM")}</span> day of{" "}
        <span className="underline">{moment().format("DD")}</span>{" "}
        <span>{moment().format("YYYY")}</span>.
      </p>
      <div className="flex mt-8">
        <b>
          METRO PACIFIC HEALTH TECH <br /> CORPORATION
        </b>
      </div>
      <div className="flex justify-between">
        <p>By:</p>
        <div className="flex flex-col items-center pr-48 -mt-8">
          <div className="text-center">
            {file_signature_path.url && (
              <img
                className="w-32 mx-auto"
                src={file_signature_path.url}
                alt="signature"
              />
            )}
            <div>____________________</div>
            <b>
              [<span>{`${first_name} ${middle_name} ${last_name}`}</span>]
            </b>
          </div>
        </div>
      </div>

      <p className="text-center mt-28">SIGNED IN THE PRESENCE OF:</p>
      <div className="flex justify-center mt-12 px-16">
        <div className="text-center mr-12">
          <div>____________________</div>
        </div>
        <div className="text-center ml-12">
          <div>____________________</div>
        </div>
      </div>
      <p className="mt-16">
        Republic of the Philippines &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
      </p>
      <p>
        ________ City)
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;S.S.
      </p>

      <p className="text-center mt-12">
        <b>ACKNOWLEDGMENT</b>
      </p>
      <p style={{ textIndent: "3rem" }}>
        BEFORE ME, a Notary Public for and in _____________, this
        _______________ personally appeared the following:
      </p>

      <table className="border w-full text-left">
        <tr className="border border-b">
          <th className="w-1/3 p-2">Name</th>
          <th className="w-1/3 p-2 border-l">
            Community Tax Certificate No. / Passport No.{" "}
          </th>
          <th className="w-1/3 p-2 border-l">Date / Place Issued</th>
        </tr>
        <tr>
          <td className="p-2">
            <b>METRO PACIFIC HEALTH TECH CORPORATION</b>
            <p>By:</p>
            <p>Jose Ma. K. Lim</p>
          </td>
          <td className="border-l"></td>
          <td className="border-l"></td>
        </tr>
        <tr className="border-t">
          <td className="p-2">[Name]</td>
          <td className="border-l"></td>
          <td className="border-l"></td>
        </tr>
      </table>

      <p className="mt-8">
        All known to me, and to me known to be the same persons who executed the
        foregoing Data Sharing Agreement, and they acknowledged to me that the
        same is their free and voluntary act and deed as well as that of the
        corporations herein represented.
      </p>

      <p style={{ textIndent: "3rem" }}>
        The foregoing instrument pertains to a Data Sharing Agreement and
        consists of [●] ([●]) pages, including the page on which this
        Acknowledgment is written, and is signed by the parties and their
        material witnesses on each and every page thereof.{" "}
      </p>

      <p style={{ textIndent: "3rem" }}>
        WITNESS MY HAND AND SEAL on the date and place first above-written.
      </p>

      <div>Doc. No. &nbsp;&nbsp;&nbsp;____;</div>
      <div>Page No. &nbsp;&nbsp;&nbsp;____;</div>
      <div>Book No. &nbsp;&nbsp;&nbsp;____;</div>
      <div>Series of &nbsp;&nbsp;&nbsp;____;</div>

      <div className="text-center mt-16">
        <b>ANNEX A</b>
      </div>
      <p className="text-center">
        <b>SERVICE LEVEL INDICATORS</b>
      </p>

      <p>
        <b>
          I. <span className="ml-4">Training</span>
        </b>
      </p>
      <p className="pl-8">
        PHYSICIAN should complete the MPHTC training module before account is
        activated.
      </p>
      <p>
        <b>
          II. <span className="ml-4">Technical requirements</span>
        </b>
      </p>
      <p className="pl-8">
        The PHYSICIAN shall comply, and continue to comply during the duration
        of this Agreement, with the following technical requirements:
      </p>
      <ul className="pl-16">
        <li>a. &nbsp;&nbsp;minimum internet speed of 2Mbps;</li>
        <li>
          b. &nbsp;&nbsp;access to a laptop, desktop, tablet, or a smartphone;
          and
        </li>
        <li>
          c. &nbsp;&nbsp;ability to access and use Google Chrome or Safari
          browser.
        </li>
      </ul>
      <p>
        <b>
          III. <span className="ml-4">Availability</span>
        </b>
      </p>
      <p className="pl-8">
        The PHYSICIAN should be readily available during the scheduled agreed
        upon in Section 8 of the Agreement. In case the PHYSICIAN arrives late
        for at least three (3) consultations in a month, a warning shall be
        issued by MPHTC. If the PHYSICIAN is late for an appointment for the
        fourth time in a month, MPHTC may terminate this Agreement.
      </p>
      <p>
        Failure to show up for five (5) consultations in a month shall also be a
        ground for termination of this Agreement.
      </p>
      <p>
        <b>
          IV. <span className="ml-4">Consultation Guidelines</span>
        </b>
      </p>
      <div className="pl-12">
        <div className="flex">
          <div>a. </div>
          <p className="ml-4">
            PHYSICIAN must be able to respond to the User’s queries within the
            allotted consultation timeslot;
          </p>
        </div>
        <div className="flex">
          <div>b. </div>
          <p className="ml-4">
            PHYSICIAN must ensure that s/he is performing the consultation in a
            conducive environment (e.g. well lit, minimal noise, etc.);
          </p>
        </div>
        <div className="flex">
          <div>c. </div>
          <p className="ml-4">
            PHYSICIAN must provide a thorough diagnosis as permitted by the
            telemedicine platform. When necessary, s/he has the ability to
            provide the following through the same platform:
          </p>
        </div>
        <ol className="pl-16">
          <li>1. &nbsp;&nbsp;&nbsp; e-prescription</li>
          <li>2. &nbsp;&nbsp;&nbsp; e-laboratory request</li>
          <li>3. &nbsp;&nbsp;&nbsp; e-medical certificate</li>
        </ol>
        <div className="flex">
          <div>d. </div>
          <p className="ml-4">
            When necessary, PHYSICIAN should always seek the expert medical
            advice/opinion of an appropriate specialist.
          </p>
        </div>
        <div className="flex">
          <div>e. </div>
          <p className="ml-4">
            PHYSICIAN should be upright, diligent, sober, modest and well-versed
            when speaking to a User.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MOAContent;
