import React from "react";
import styled from "styled-components";
import { Typography } from "antd";

const ContainerLabelTertiary = (props) => {
  return (
    <SyledTypographyTitleTertiary {...props}>
      {props.children}
    </SyledTypographyTitleTertiary>
  );
};

export default ContainerLabelTertiary;

const SyledTypographyTitleTertiary = styled(Typography.Title)`
  font-family: "AvenirLTPro";
  font-weight: 400 !important; //medium
  font-size: 15px !important; // 15px
  letter-spacing: 0 !important;
  color: #2e2e2e !important;
`;
