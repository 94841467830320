import React, { useState } from "react";
import { Tabs, Row } from "antd";
import styled from "styled-components";
import ContainerLabelSecondary from "../../../../../ComponentsFC/ui/ContainerLabelSecondary";
import UI from "../../../../../shared/Components/UI/js";
import PatientProfileTab from "./PatientProfileTab";
import MedicalHistoryTab from "./MedicalHistoryTab";
import ClinicalDocumentationsTab from "./ClinicalDocumentationsTab";
import ServiceMwellMDApi from "../../../../../api_services/Doctors-Portal/DPMwellMDApi";
import { mem } from "../../../../../shared/custom-react-native";
import "./css.css";

const ElectronicMedicalRecords = ({
  electronicMedicalRecords,
  setElectronicMedicalRecords,
  patient,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const getPatientMedicalRecords = async () => {
    setIsGenerating(true);

    const doctor = JSON.parse(mem.get("onboard_data"));

    if (doctor) {
      let params = {
        first_name: patient?.name[0]?.given[0],
        last_name: patient?.name[0]?.family,
        date_of_birth: patient?.birthDate,
        sex_code: patient?.gender === "male" ? "M" : "F",
        md_code: doctor?.id,
      };

      // let params = {
      //   first_name: "Cerinne",
      //   last_name: "Coronel",
      //   date_of_birth: "2001-05-31",
      //   sex_code: "F",
      // };

      // let params = {
      //   first_name: "Felicia",
      //   last_name: "Fermin",
      //   date_of_birth: "1956-05-08",
      //   sex_code: "F",
      //   md_code: "641bf2caf65c0a7643104530",
      // };

      params = Object.keys(params)
        // eslint-disable-next-line array-callback-return
        .map((key) => {
          if (params[key]) {
            return key + "=" + params[key];
          }
        })
        .join("&");

      try {
        const apiResponse = await ServiceMwellMDApi.getPatientMedicalRecords(
          params
        );

        setElectronicMedicalRecords(apiResponse);

        setIsGenerating(false);
      } catch (error) {}
    }
  };

  return (
    <Container>
      <div
        className={`flex ${
          electronicMedicalRecords
            ? "flex-col"
            : "flex-row items-center justify-between"
        }`}
      >
        <ContainerLabelSecondary>
          Electronic Medical Record
        </ContainerLabelSecondary>

        {!electronicMedicalRecords ? (
          <button
            onClick={getPatientMedicalRecords}
            className={`py-2 px-6 font-semibold rounded-md text-white ${
              isGenerating ? "cursor-wait" : ""
            }`}
            style={{ background: UI.colors.secondary }}
          >
            View
          </button>
        ) : electronicMedicalRecords?.status === 500 ? (
          <div className="mt-6">No available medical records</div>
        ) : (
          <div className="mt-6">
            <StyledTabs defaultActiveKey="1">
              <Tabs.TabPane tab="Patient Profile" key="1">
                <PatientProfileTab
                  patient={electronicMedicalRecords.patient_pofile.demographics}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Medical History" key="2">
                <MedicalHistoryTab
                  medical_history={
                    electronicMedicalRecords.patient_pofile.medical_history
                  }
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="Clinical Documentation"
                key="3"
                disabled={true}
              >
                <ClinicalDocumentationsTab />
              </Tabs.TabPane>
            </StyledTabs>
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 37px;
  padding: 40px 35px 40px 35px;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  // width: 60vw;

  min-width: 100%;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    color: #0e4d94;
    border: 2px solid #0e4d94;
    border-radius: 12px;
    font-weight: 500;

    .ant-tabs-tab {
      padding: 1rem 2rem;
      font-size: 0.875rem;

      @media screen and (min-width: 1680px) {
        font-size: 1.125rem;
      }

      &:first-child.ant-tabs-tab-active {
        border-radius: 8px 0 0 8px;
      }

      &:last-child.ant-tabs-tab-active {
        border-radius: 0 8px 8px 0;
      }

      &: not(.ant-tabs-tab-disabled):hover {
        color: #0e4d94;
      }

      &.ant-tabs-tab-active {
        background: #0e4d94;

        .ant-tabs-tab-btn {
          color: #fff;
        }
      }

      & + .ant-tabs-tab {
        margin: 0;
      }

      &:not(:nth-child(3)) {
        border-right: 2px solid #0e4d94;
      }
    }
    .ant-tabs-ink-bar {
      background-color: transparent;
    }
  }

  .ant-tabs-content {

    .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      left: auto;
      right: 1.5rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
    }

    .ant-collapse-item {
      background-color: #f5faff;
      border-bottom: 1px solid #d1d1d1 !important;

        &-active > .ant-collapse-header  {
          border-bottom: 1px solid #d1d1d1 !important;
          border-top: none;
        }

        .ant-collapse-header {
          font-size: 0.875rem;
          color: #0e4d94 !important;
          font-weight: 500;
          padding: 1rem 1.5rem !important;

          @media screen and (min-width: 1680px) {
            font-size: 1.125rem;
          }
        }

        .ant-collapse-content-box {
          padding: 1rem 0 !important;
          background-color: #fff !important;
        }
      }
    }
  }
`;

export default ElectronicMedicalRecords;
