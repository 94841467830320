import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import EmailTable from "./EmailTable";

const context = "onboard";

export default class DoctorNotificationCMS extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Notification Hub CMS",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context
    });
  }

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <EmailTable _this={_this} />
      </MainLayout>
    );
  }
}
