import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";
const context = "admin";

export default class AdminViewMerchant extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Store Merchant List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;
    const { 
      // height, 
      width,
      //  isMobile 
      } = _this.state;

    return (
      <MainLayout
        context={context}
        additional={"store-merchant-list"}
        _this={_this}
      >
        <View style={styles.main_box_content}>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                borderRightWidth: width >= 1250 ? 2 : 0,
                borderBottomWidth: width < 1250 ? 2 : 0,
                paddingRight: width < 1250 ? 0 : 20,
                marginRight: width < 1250 ? 0 : 20,
                paddingBottom: 10,
                marginBottom: width < 1250 ? 10 : 0,
              }}
            >
              <View style={styles.fl_ai}>
                <Image
                  height={100}
                  width={100}
                  tintColor={"transparent"}
                  source={UI.SAMPLE_PRODUCT}
                />

                {UI.box(width < 980 ? 5 : 10)}

                <View>
                  <Text style={styles.patient_name}>Merchant Name Here</Text>
                  <Text
                    style={{
                      ...styles.patient_name,
                      color: "#454545",
                      fontSize: 14,
                    }}
                  >
                    emailaddress@email.com
                  </Text>
                  <Text
                    style={{
                      ...styles.patient_name,
                      color: "#454545",
                      fontSize: 14,
                    }}
                  >
                    +63 995 123 4567
                  </Text>
                </View>
              </View>

              <View style={styles.date_container}>
                <Image
                  height={30}
                  width={30}
                  tintColor={UI.colors.primary}
                  source={UI.CALENDAR_3}
                />
                <Text style={styles.appointment_text}>
                  Status: <Text style={{ color: "#20D620" }}>Active</Text>{" "}
                  <Text style={{ color: "#C9C9C9", fontWeight: "normal" }}>
                    (Added mm/dd/yyy)
                  </Text>
                </Text>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <UI.Row breakpoint={800} _this={_this} style={{ width: "100%" }}>
                <Text style={styles.box_header_text}>Merchant Statistics</Text>
                <Text style={{ color: "#C9C9C9", fontWeight: "normal" }}>
                  Updated as of: mm/dd/yyyy
                </Text>
              </UI.Row>

              {UI.box(30)}

              <UI.Row
                style={{ width: "100%", justifyContent: "space-around" }}
                breakpoint={800}
                _this={_this}
              >
                <UI.Row _this={_this} breakpoint={1300}>
                  <DashItem
                    _this={_this}
                    icon={UI.IC_DASH_1}
                    num={"000"}
                    color={UI.colors.primary}
                    text={"Successful Purchases"}
                  />
                  {UI.box(10)}
                  <DashItem
                    _this={_this}
                    icon={UI.IC_DASH_10}
                    num={"000"}
                    color={"#004F99"}
                    text={"Total Active Products"}
                  />
                </UI.Row>
                {UI.box(10)}
                <UI.Row _this={_this} breakpoint={1300}>
                  <DashItem
                    _this={_this}
                    icon={UI.IC_DASH_12}
                    num={"000"}
                    color={"#C70000"}
                    text={"Total Inactive Products"}
                  />
                  {UI.box(10)}
                  <DashItem
                    _this={_this}
                    icon={UI.IC_DASH_11}
                    num={"000"}
                    color={"#00EB2D"}
                    text={"Customer Inquiries"}
                  />
                </UI.Row>
              </UI.Row>
            </View>
          </UI.Row>
        </View>
        {UI.box(20)}

        <View
          style={{
            ...styles.main_content_container,
            flex: 1,
            padding: 0,
            marginTop: 20,
          }}
        >
          <UI.Row
            breakpoint={1070}
            _this={_this}
            style={{ width: "100%", padding: 20 }}
          >
            <Text style={styles.box_header_text}>Product List</Text>

            <View style={{ marginTop: -10, width: 150 }}>
              <Layout.SelectPicker _this={_this}>
                <option>Filter By</option>
              </Layout.SelectPicker>
            </View>
          </UI.Row>
          <CustomDatatable
            state_name={"nutrition_programs"}
            _this={_this}
            columns={[
              { ID: "id" },
              { Preview: "preview" },
              { "Product Name": "product_name" },
              { Price: "price" },
              { Limit: "limit" },
              { "Date Added": "date_added" },
              { Status: "status" },
              { Action: "action" },
            ]}
            data={[
              {
                id: "001010",
                preview: (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image
                      height={35}
                      width={35}
                      tintColor={"transparent"}
                      source={UI.SAMPLE_USER}
                    />
                  </View>
                ),
                product_name: "Product Name Here",
                price: "Php 000.00",
                limit: "000",
                date_added: "mm/dd/yyyy",
                status: <Text style={{ color: "#00C700" }}>Active</Text>,
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        UI.goTo("/admin/view-product");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.VIEW_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity onClick={() => {}}>
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity>
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.DELETE_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              },
            ]}
            current_page={1}
            number_of_pages={20}
            on_page={(page, callback) => {}}
          />
        </View>

        <UI.Row
          breakpoint={1300}
          _this={_this}
          style={{ width: "100%", marginTop: 20 }}
        >
          <View
            style={{ ...styles.main_content_container, flex: 1, padding: 0 }}
          >
            <UI.Row
              breakpoint={1070}
              _this={_this}
              style={{ width: "100%", padding: 20 }}
            >
              <Text style={styles.box_header_text}>Recent Transactions</Text>

              <View style={{ marginTop: -10, width: 150 }}>
                <Layout.SelectPicker _this={_this}>
                  <option>Filter By</option>
                </Layout.SelectPicker>
              </View>
            </UI.Row>
            <CustomDatatable
              state_name={"recent_transaction"}
              _this={_this}
              columns={[
                { "Customer Name": "customer_name" },
                { "Product Name": "product_name" },
                { "Purchase Date": "purchase_date" },
                { Action: "action" },
              ]}
              data={[
                {
                  customer_name: (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Image
                        height={35}
                        width={35}
                        tintColor={"transparent"}
                        source={UI.SAMPLE_USER}
                      />
                      {UI.box(8)}
                      <Text>Patient Name</Text>
                    </View>
                  ),
                  product_name: "Product Name",
                  purchase_date: "mm/dd/yyyy",
                  action: (
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        onClick={() => {
                          UI.goTo("/admin/view-transaction-details");
                        }}
                      >
                        <Image
                          height={32}
                          width={32}
                          tintColor={"transparent"}
                          source={UI.VIEW_BTN}
                        />
                      </TouchableOpacity>
                    </View>
                  ),
                },
              ]}
              current_page={1}
              number_of_pages={20}
              on_page={(page, callback) => {}}
            />
          </View>
          {UI.box(20)}
          <View
            style={{ ...styles.main_content_container, flex: 1, padding: 0 }}
          >
            <UI.Row
              breakpoint={1070}
              _this={_this}
              style={{ width: "100%", padding: 20 }}
            >
              <Text style={styles.box_header_text}>Recent Inquiries</Text>

              <View style={{ marginTop: -10, width: 150 }}>
                <Layout.SelectPicker _this={_this}>
                  <option>Filter By</option>
                </Layout.SelectPicker>
              </View>
            </UI.Row>
            <CustomDatatable
              state_name={"recent_inquiry"}
              _this={_this}
              columns={[
                { "Customer Name": "customer_name" },
                { "Product Name": "product_name" },
                { Subject: "subject" },
                { Action: "action" },
              ]}
              data={[
                {
                  customer_name: (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Image
                        height={35}
                        width={35}
                        tintColor={"transparent"}
                        source={UI.SAMPLE_USER}
                      />
                      {UI.box(8)}
                      <Text>Patient Name</Text>
                    </View>
                  ),
                  product_name: "Product Name",
                  subject: "Subject Here",
                  action: (
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        onClick={() => {
                          UI.goTo("/admin/view-inquiry");
                        }}
                      >
                        <Image
                          height={32}
                          width={32}
                          tintColor={"transparent"}
                          source={UI.VIEW_BTN}
                        />
                      </TouchableOpacity>
                    </View>
                  ),
                },
              ]}
              current_page={1}
              number_of_pages={20}
              on_page={(page, callback) => {}}
            />
          </View>
        </UI.Row>
      </MainLayout>
    );
  }
}
const DashItem = (
  props = { _this: null, icon: "", num: "", text: "", color: "" }
) => {
  return (
    <View style={{ flexDirection: "row", flex: 1 }}>
      <Image
        height={60}
        width={60}
        tintColor={"transparent"}
        source={props.icon}
      />

      <View style={{ marginLeft: 15 }}>
        <Text
          style={{ ...styles.main_header, color: props.color, fontSize: 22 }}
        >
          {props.num}
        </Text>
        <Text style={{ ...styles.header_2, fontSize: 12 }}>{props.text}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  main_content_container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    flex: 1,
  },
  product_stat_item: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  product_stats_text: {
    color: UI.colors.primary,
    fontWeight: "bold",
    fontSize: 32,
    marginLeft: 15,
    flex: 1,
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
