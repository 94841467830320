import React, { useState } from "react";
import UI from "../../../../shared/Components/UI/js";
import { Text, 
  // View
 } from "../../../../shared/custom-react-native";
 import { RadioBtn, Checkbox } from "../../../_components/InputForm";
 import { MedicalCertificateModal } from "../EDocs";

const MedicalCertificate = ({
  _this,
  onAddMedicalCertifcateFormInput,
  setMedicalCertifcateFormInput,
  medicalCertifcateFormInput,
  saveEntry,
  encounterNotesData,
  isIssueMedicalCertificateToPatient,
  setIsIssueMedicalCertificateToPatient
}) => {
  const [validationMessage] = useState({
    error: "",
    success: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    medicalCertifcateFormInput[name] = value;
    setMedicalCertifcateFormInput({ ...medicalCertifcateFormInput });
    autoSaveOnChange();
  };

  const autoSaveOnChange = async () => {
    const payload = onAddMedicalCertifcateFormInput(medicalCertifcateFormInput);
    await saveEntry("medical certificate", payload);
  };

  const autoSaveRadioButton = async (status) => {
    const customPayload = onAddMedicalCertifcateFormInput(
      medicalCertifcateFormInput
    );
    customPayload.medicalcertificates[0].fitToWorkStatus = status;
    await saveEntry("medical certificate", customPayload);
  };

  return (
    <div style={{ background: "#F5FAFF " }} className="rounded-b-lg p-3">
      {UI.box(10)}

      <Text
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#0E4D94",
        }}
      >
        Consult Details for Medical Certificate
      </Text>

      {UI.box(0)}

      <div
        className="grid p-3 bg-white rounded-lg my-2"
        style={{
          border: "2px solid #C9C9C9",
        }}
      >
        <div className="flex items-center gap-2">
          <RadioBtn
            style={{
              borderColor: "#444444",
              borderWidth: 2,
              borderRadius: 7,
              height: 25,
              width: 25,
              color: "#444444",
            }}
            _this={_this}
            text={"Fit to Work"}
            onClick={async (value) => {
              setMedicalCertifcateFormInput({
                ...medicalCertifcateFormInput,
                fitToWorkStatus: "Fit to Work",
              });
              await autoSaveRadioButton("Fit to Work");
            }}
            is_checked={
              medicalCertifcateFormInput.fitToWorkStatus === "Fit to Work"
            }
            color={"#2E2E2E"}
          />
          {/* <input type="radio" value="Fit to Work" name="fitToWorkStatus" onChange={onChange} checked={medicalCertifcateFormInput.fitToWorkStatus === "Fit to Work"}/>
          Fit to Work */}
        </div>
        {UI.box(10)}
        <div className="flex items-center gap-2">
          {/* <input type="radio" value="Fit to Work with Restrictions" name="fitToWorkStatus" onChange={onChange} checked={medicalCertifcateFormInput.fitToWorkStatus === "Fit to Work with Restrictions"}/>
          Fit to Work with Restrictions */}
          <RadioBtn
            style={{
              borderColor: "#444444",
              borderWidth: 2,
              borderRadius: 7,
              height: 25,
              width: 25,
              color: "#444444",
            }}
            _this={_this}
            text={"Fit to Work with Restrictions"}
            onClick={async (value) => {
              setMedicalCertifcateFormInput({
                ...medicalCertifcateFormInput,
                fitToWorkStatus: "Fit to Work with Restrictions",
              });
              await autoSaveRadioButton("Fit to Work with Restrictions");
            }}
            is_checked={
              medicalCertifcateFormInput.fitToWorkStatus ===
              "Fit to Work with Restrictions"
            }
            color={"#2E2E2E"}
          />
        </div>
        {UI.box(10)}
        <div className="flex items-center gap-2">
          <RadioBtn
            style={{
              borderColor: "#444444",
              borderWidth: 2,
              borderRadius: 7,
              height: 25,
              width: 25,
              color: "#444444",
            }}
            _this={_this}
            text={"Not Fit to Work"}
            onClick={async (value) => {
              setMedicalCertifcateFormInput({
                ...medicalCertifcateFormInput,
                fitToWorkStatus: "Not Fit to Work",
              });
              await autoSaveRadioButton("Not Fit to Work");
            }}
            is_checked={
              medicalCertifcateFormInput.fitToWorkStatus === "Not Fit to Work"
            }
            color={"#2E2E2E"}
          />
          {/* <input type="radio" value="Not Fit to Work" name="fitToWorkStatus" onChange={onChange} checked={medicalCertifcateFormInput.fitToWorkStatus === "Not Fit to Work"}/>
          Not Fit to Work */}
        </div>
      </div>

      <textarea
        style={{
          fontSize: "14px",
          resize: "none",
          color: "black",
          height: "100px",
          width: "100%",
          padding: "10px 14.44px",
          borderRadius: "10px",
          border: "2px solid #C9C9C9",
          marginTop: "5px",
        }}
        placeholder={"Others"}
        name="fitToWorkOthers"
        onChange={onChange}
        value={medicalCertifcateFormInput.fitToWorkOthers}
      ></textarea>
      {UI.box(10)}

      <Text
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#0E4D94",
        }}
      >
        Notes and other Patient Instructions
      </Text>

      {UI.box(10)}

      <textarea
        style={{
          fontSize: "14px",
          resize: "none",
          color: "black",
          height: "119px",
          width: "100%",
          padding: "10px 14.44px",
          borderRadius: "10px",
          border: "2px solid #C9C9C9",
          marginTop: "5px",
        }}
        placeholder={"Notes and other Patient Instructions"}
        name="recommendation"
        onChange={onChange}
        value={medicalCertifcateFormInput.recommendation}
      ></textarea>

      <div className="flex justify-between items-center">
        <Checkbox
          text="Issue to Patient"
          is_disabled={Object.values(medicalCertifcateFormInput).every(value => value === "")}
          is_checked={isIssueMedicalCertificateToPatient} 
          onClick={() => setIsIssueMedicalCertificateToPatient(!isIssueMedicalCertificateToPatient)}
        />
        <button
          onClick={() =>
            _this.show_modal(
              <MedicalCertificateModal
                appointment={_this.state.appointment}
                encounterNotesData={encounterNotesData}
                onHideModal={() => _this.hide_modal()}
              />
            )
          }
          className="p-3 font-semibold flex rounded-md text-white"
          style={{ background: "#0E4D94" }}
        >
          View Document
        </button>
      </div>

      {UI.box(10)}

      {
        <>
          <p className="text-lg text-red-500 m-0 font-semibold">
            {validationMessage.error}
          </p>
          <p className="text-lg text-green-500 m-0 font-semibold">
            {validationMessage.success}
          </p>
        </>
      }

      {/* <View
      style={{
        // justifyContent: "center",
        width: "100%",
        flexDirection: "row",
        justifyContent:'flex-end'
      }}
    >
      <button  className='my-3 flex items-center gap-2 py-2 px-4 rounded-md font-semibold'style={{background:'#0E4D94'}} onClick={submit}>    
        <img height={15} width={15} src={UI.UPDATE_PEN} alt=""/>
        <strong className='text-white' style={{fontSize:'15px'}}>Save Entry</strong>
      </button>
    </View> */}
    </div>
  );
};

export default MedicalCertificate;
