import React, { useState } from 'react'
import UI from '../../shared/Components/UI/js'
import { Image, mem } from '../../shared/custom-react-native'
import FormItem from '../ui/forms/FormItem'
import Text from '../ui/Text'
import InputField from '../ui/forms/InputField'
import { Button, Col, Form, Row } from 'antd'
import styled from 'styled-components'
import { Image as AntdImage } from 'antd';
import ProcessFailedModal from '../ui/modals/ProcessFailedModal';
import ProcessFailedModalCrossIcon from '../../icons/ProcessFailedModalCross';
import SuccessModal from '../ui/modals/SuccessModal';
import SuccessModalCheckIcon from '../../icons/SuccessModalCheck';
import Loading from '../../modules/_components/Loading';
import ServiceAuthApi from '../../api_services/auth'

const VerifyEmailForm = (props) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  // const sendVerficationCode = () => {
  //   setIsSendVerificationCode(true)
  // }

  // const verifyCode = () => {
  //   setUserId('213213')
  //   setIsVerifyCode(true)
  // }

  const submit = (e) => {
    const payload = {
      ...e,
      role: "provider",
    };

    setIsScreenLoading(true);
    ServiceAuthApi.forgetPasswordStep1UsingEmail(payload).then(res => {
      if (res.status === 200) {
        setIsScreenLoading(false);
        setIsSuccess(true)
        setSuccessMessage("Successfully send. Please check your email address")
        UI.goTo(`/onboard/forget-password-step-1?userId=${res.user}`);
      } else {
        setIsScreenLoading(false);
        setIsFailed(true);
        setErrorMessage(res.message);
      }
    });
  }

  const goBackAction = () => {
    const hasJwtToken = Boolean(mem.get("jwt_token"));
    const role = mem.get("role")
    if (hasJwtToken) {
      const current_role = UI.role_map[role];
      UI.goTo(`/${current_role}/dashboard`);
    } else {
      UI.goBack();
    }
  }

  return (
    <>
     <Loading
        visible={isScreenLoading}
        title={""}
        text={`Loading...`}
        modal_opaque={false}
      />
      <SuccessModal
        title={"Success!"}
        banner={<SuccessModalCheckIcon />}
        description={successMessage}
        buttonText={"Done"}
        buttoncolor={"#004f99"}
        visible={isSuccess}
        width={530}
        onOk={() => setIsSuccess(false)}
        onCancel={() => setIsSuccess(false)} // x and cancel button
      />
      <ProcessFailedModal
        title={"Process Failed"}
        banner={<ProcessFailedModalCrossIcon />}
        description={errorMessage}
        buttonText={"Dismiss"}
        buttoncolor={"#004f99"}
        visible={isFailed}
        onOk={() => setIsFailed(false)}
        onCancel={() => setIsFailed(false)} // x and cancel button
      />
      <Col>
        <Row className='cursor-pointer' onClick={goBackAction}>
          <Image
            height={20}
            width={20}
            tintColor={"#999999"}
            source={UI.CHEVRON_LEFT}
          />
          <Text level={5} style={{color: "#999999"}}>Cancel</Text>
        </Row>
        <Col className='flex flex-col items-center gap-5'>
          <Col className='flex flex-col items-center gap-5'>
            <AntdImage src={UI.MWELL_LOGO_1} preview={false} alt="login_logo"/>
            <StyledText level={4}>Enter your email and we’ll send you an email link to reset your password</StyledText>
          </Col>
          <StyledForm
            layout={"vertical"}
            onFinish={(e) => {
              submit(e)
            }}
          >
            <FormItem 
              name="email"
              style={{ display: "flex", alignSelf: "end"}}
              rules={[
                {
                  type: "email",
                  message: "Please input a valid email",
                },
                {
                  required: true,
                  message: "Email Address is required",
                },
              ]}
            >
              <StyledInputField 
                style={{ width: "100%" }}
                placeholder="Email Address"
              />
            </FormItem>

            {/* {
              isSendVerificationCode ? 
              <>
                <FormItem 
                  name="code"
                  style={{ display: "flex", alignSelf: "end"}}
                  rules={[
                    {
                      required: true,
                      message: "Verification Code is required",
                    },
                  ]}
                  >
                  <StyledInputNumericField 
                    style={{ width: "100%" }}
                    placeholder="Verification Code"
                  />
                </FormItem>
                <FormItem>
                  <Row style={{ flexFlow: "nowrap", gap: 17 }}>
                    <StyledButton
                      htmlType="submit"
                    >
                      Verify Code
                    </StyledButton>
                    <StyledButton
                      onClick={sendVerficationCode}
                    >
                      Send new code
                    </StyledButton>
                  </Row>
                </FormItem>
              </> :
              <FormItem>
                <StyledButton
                  htmlType="submit"
                >
                  Send verification code
                </StyledButton>
              </FormItem>
            }      */}
            
            <FormItem>
              <StyledButton
                htmlType="submit"
                // onClick={submit}
                // disabled={isVerifyCode ? false : true}
              >
                Continue
              </StyledButton>
            </FormItem>

          </StyledForm>
        </Col>
      </Col>
    </>
    
  )
}

export default VerifyEmailForm

const StyledText = styled(Text)`
  /* & h4.ant-typography, .ant-typography h4 {
    fon
  } */
  line-height: 30px !important;
  text-align: center;
  width: 90%;
`

const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledInputField = styled(InputField)`
  font-size: 18px;
  font-weight: bold;
`

const StyledButton = styled(Button)`
  background-color: #004F99;
  border-radius: 12px;
  width: 100%;
  height: 70px;

  color: #ffffff;
  font-weight: 600;
  font: normal normal medium 18px/19px Avenir LT Pro;
  font-size: 18px;

  &.ant-btn[disabled] { 
    background-color: rgb(199, 199, 199);
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #004F99;
    color: #ffffff;
    border-color: #004F99;
  }
`;