import moment from "moment";
import _ from "lodash";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";

import TouchableOpacity, {
  Image,
  Shadow,
  StyleSheet,
  Text,
  ScrollView,
  View,
  mem
} from "../../shared/custom-react-native";
import { CancelCLAppointment } from "../onboard-create-user/Instructions";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Success, FileLister } from "../_components/Modal";
import { ImageViewer } from "../_components/ImageViewerModal";
import "./css.css";
import Statistics from "./Statistics";
import APIConstants from "../../shared/modules/APIConstants";
import PatientRescheduleAppointmentModal from "./PatientRescheduleAppointmentModal";
import DoctorRescheduleAppointmentModal from "./DoctorRescheduleAppointmentModal";
import PatientRescheduleAppointmentDeclineModal from "./PatientRescheduleAppointmentDeclineModal";
import PatientRescheduleAppointmentAcceptedModal from "./PatientRescheduleAppointmentAcceptedModal";

const context = "onboard";
const Constants = new APIConstants();

var currentDate = moment();
var futureMonth = moment(currentDate).add(12, 'M');
var futureMonthEnd = moment(futureMonth).endOf('month').format('YYYY-MM-DD');

const default_startdate = "1970-01-01";
const default_enddate = futureMonthEnd;

export default class OnboardDashboard extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Dashboard",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,

      data: [],
      all_data: [],

      totalEarningMonth: 0,
      totalEarnings: 0,
      totalConsultations: 0,
      totalPendingNutritionPlans: 0,
      totalCompletedNutritionPlans: 0,

      start_date_dashed: default_startdate,
      end_date_dashed: default_enddate,
      isCancel: false,
      appointmentID: null
    });

  }

  onCreate = async () => {
    this.load_stats();
    this.load_consultations();
    const onboard_data = JSON.parse(mem.get("onboard_data"))
    const doctorLoginSelected = mem.get("doctorLoginSelected") ? mem.get("doctorLoginSelected") : null
    
    if (onboard_data?.isDoctorsOnboardingApproved === "Approved" && !doctorLoginSelected) {
      UI.goTo("/onboard/login-select")
    }
  };

  apply_date_filter = async () => {
    await UI.clear_errors();
    // if (default_startdate == this.state.start_date_dashed) {
    //   await UI.error_form("start_date", "Please enter start date");
    // }
    // if (default_enddate == this.state.end_date_dashed) {
    //   await UI.error_form("end_date", "Please enter end date");
    // } else {
      UI.webRequest({
        method: "get",
        url: "doctors_onboarding/appointments",
        params: {
          startDate: this.state.start_date_dashed,
          endDate: this.state.end_date_dashed,
        },
        onFail: async (err) => {},
        onSuccess: async (response) => {
          const data = UI.extract_data(response);
          this.setState(
            {
              data: data.data,
              all_data: data.data,
            },
            () => {
              this.reload_table("consultation_records");
            }
          );
        },
        onFinish: () => {
          this.load_stats();
          this.hide_loading();
        },
      });
    // }
  };

  load_consultations = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/appointments",
      params: {
        startDate: this.state.start_date_dashed,
        endDate: this.state.end_date_dashed,
      },
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        // const currentDate = new Date()
        // let dateFilter = data.data.filter((item) => UI.get_date_string_by_date(currentDate) === UI.get_date_string_by_date(item.createdAt));
        // const sortedByTime = dateFilter.sort((a,b) =>  new Date(b.start) - new Date(a.start))
        const newData = _.orderBy(data.data, [
          (u) => new Date(u.start),
        ], ['desc']);
        this.setState(
          {
            data: newData,
            all_data: data.data
            // all_data: sortedByTime,
          },
          () => {
            this.reload_table("consultation_records");
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  load_stats = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/dashboards/stats", 
      params: {
        startDate: this.state.start_date_dashed,
        endDate: this.state.end_date_dashed,
      },
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        this.setState({
          ...data,
        });
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  cancelAppointmnent =  function (reason) {
    this.show_loading();
    this.setState({ isCancel: false, appointmentID: null });
    UI.webRequest({
      method: "put",
      url: "doctors_onboarding/appointments/doctor/arrived/"+this.state?.appointmentID,
      params: {
        action:  "cancel",
        reason: reason
      },
      onSuccess: async (response) => {
        this.show_modal(
          <Success
          title={"Success!"}
          descriptionCenter={"Cancelled successfully"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />);
        this.load_consultations();
        this.hide_loading();
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  closeCancelAppointmentModal = () => {
    this.setState({ isCancel: false });
  }

  showFileListerModal = (patient) => {
    this.show_modal(
      <FileLister
        title={"Add Patient"}
        customBody={
          <View
            style={{ marginTop: 20 }}
          >
            <View 
              style={{width: "100%" }}  
            >
              <View onClick={ () => {
                console.log("I am clicked!"); // TO-DO: What does this button do?
              }}>
                <Text style={{cursor: "pointer", color: UI.colors.primary, textAlign: "center"}}>
                  View Patient Files
                </Text>
              </View>

              <View style={{ display: "block", margin: "30px 0" }}>
                {
                  (patient.files && patient.files.length > 0) ?
                  <ScrollView style={{flex: 1, height: 200}}>
                    {
                      patient.files.map((item, i) => {
                        const fileName = item.filename;
                        const fileExtension = fileName.split('.').pop().toLowerCase();
                        const isImage = fileExtension.match(/^(jpg|jpeg|png)$/);
                        const baseContent = `${Constants.STORAGE_BASE_URL}?path=${item.path}&container=users`;
                        
                        return (
                          <View style={{ display: "flex", flex: "1", flexDirection: "row", gap: 10, marginTop: 10}}>
                            <Image
                              height={28}
                              width={28}
                              tintColor={UI.colors.lightGray}
                              source={ isImage ? UI.IMG_ICON : UI.FILE_2}
                            />
                            <Text style={{flex: 1, color: UI.colors.header}}>{fileName}</Text>
                            
                            {
                              isImage ?
                              <div style={{cursor: "pointer"}} 
                                onClick={() => {
                                  this.show_image_viewer_modal(
                                    <ImageViewer
                                      title={fileName}
                                      content={baseContent}
                                      onCancel={{
                                        method: () => {
                                          this.hide_image_viewer_modal();
                                        },
                                      }}
                                    />
                                  )
                                }}
                              >
                                <Image
                                  height={28}
                                  width={28}
                                  tintColor={UI.colors.lightGray}
                                  source={UI.IC_VIEW}
                                />
                              </div>
                              :
                              <div style={{flex: 1}} />
                            }
                            <div style={{cursor: "pointer"}} onClick={() => this.downloadPatientFile(item.path, item.filename)}> 
                              <Image
                                height={28}
                                width={28}
                                tintColor={UI.colors.lightGray}
                                source={UI.IC_DOWNLOAD}
                              />
                            </div>
                          </View>
                        )
                      })
                    }
                  </ScrollView>
                  :
                  <View
                    style={{
                      ...styles.no_info_container,
                      display: "flex",
                    }}
                  >
                    <UI.Row
                      breakpoint={820}
                      style={{ maxWidth: 760, height: 10, alignItems: "center" }}
                      _this={this}
                    >
                      <Image
                        height={100}
                        width={100}
                        tintColor={"transparent"}
                        source={UI.NO_INFO}
                      />
                      {UI.box(10)}
                      <Text
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          color: "#333333",
                          textAlign: "center",
                        }}
                      >
                        NO PATIENT FILES AVAILABLE
                      </Text>
                    </UI.Row>
                  </View>
                }
              </View>
            </View>
          </View>
        }
        onCancel={{
          label: "CANCEL",
          method: () => {
            this.hide_modal();
          },
        }}
      />
    );
  }

  downloadPatientFile = (path, fileName) => {
    const url = `${Constants.STORAGE_BASE_URL}?path=${path}&container=users`; // TO-DO: get proper base url
    fetch(url, {
      method: "GET",
      headers: {}
    })
    .then(response => {
      response.arrayBuffer().then(function(buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch(err => {
      console.log(err);
    });
  }

  getColorStatus = (value) => {
    if(value === "Started") {
      return <Text style={{color:UI.colors.primary}}>{value}</Text>
    }
    if(value === "Booked") {
      return <Text style={{color:UI.colors.primary}}>{value}</Text>
    }
    if(value === "Upcoming") {
      return <Text style={{color:UI.colors.primary}}>{value}</Text>
    }
    if(value === "Completed") {
      return <Text style={{color:UI.colors.active}}>{value}</Text>
    }
    if(value === "Patient cancelled") {
      return <Text style={{color:"red"}}>{value}</Text>
    }
    if(value === "Practitioner cancelled") {
      return <Text style={{color:"red"}}>{value}</Text>
    }
    if(value === "Patient missed") {
      return <Text style={{color:UI.colors.inactive}}>{value}</Text>
    }
    if(value === "Result pending") {
      return <Text style={{color:UI.colors.inactive}}>{value}</Text>
    }
    if(value === "Practitioner missed") {
      return <Text style={{color:UI.colors.inactive}}>{value}</Text>
    }
    if(value === "Both missed") {
      return <Text style={{color:UI.colors.inactive}}>{value}</Text>
    }
  }

  render() {
    const _this = this;
    const { 
      // height,
       width,
        // isMobile 
      } = _this.state
    let consultation = 1024;
    const cons_width = UI.measure("consultation").width;
    if (cons_width) {
      consultation = cons_width;
    }

    return (
      <MainLayout context={context} _this={_this}>
        { this.state.isCancel ? <CancelCLAppointment _this={_this}/> : "" }
        <Statistics _this={_this} />

        <View id={"consultation"} style={styles.main_box_content}>
          <UI.Row style={{ padding: 10 }} breakpoint={1053} _this={_this}>
            <View style={{ flex: 1 }}>
              <Text style={styles.box_header_text}>Consultation Records</Text>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text style={styles.entry_header}>Only Show</Text>
                {UI.box(10)}
                <View>
                  <EntryPicker
                    table_name={"consultation_records"}
                    _this={_this}
                  />
                </View>
              </View>
            </View>

              {/* <View style={{ width: 200, justifyContent:'center' }}>
                <Layout.SelectPicker
                  _this={_this}
                  // title={"Product Name"}
                  isRequired={false}
                  onChange={(e) => {
                    let dateFilter = this.state.all_data.filter((item) => UI.get_date_string_by_date(e) === UI.get_date_string_by_date(item.createdAt));
                    const sortedByTime = dateFilter.sort((a,b) =>  new Date(b.start) - new Date(a.start))
                    if(e === "default") {
                      this.reload_table("consultation_records", this.state.all_data);
                    } else {
                      this.reload_table("consultation_records", sortedByTime);
                    }
                  }}
                >
                  <option value="default">Sorted By Date</option>
                  <option value={new Date()}>Current Date</option>
                </Layout.SelectPicker>
              </View>
              {UI.box(10)} */}

            {UI.box(5)}
            <UI.Row  basis={consultation} breakpoint={430} _this={_this}>
              <View style={{ width: 200, marginTop: 15 }}>
                <Layout.DatePicker
                  _this={_this}
                  placeholder={"Start Date"}
                  state_name={"start_date"}
                  will_correct={true}
                    style={{
                      height: 200
                    }}
                />
              </View>
              {UI.box(15)}
              <View style={{ width: 200, marginTop: 15 }}>
                <Layout.DatePicker
                  _this={_this}
                  placeholder={"End Date"}
                  state_name={"end_date"}
                  will_correct={true}
                />
              </View>
            </UI.Row>
            {UI.box(10)}
            <View style={{ marginTop: 23 }}>
              <Layout.Btn
                color={"#035099"}
                onClick={() => {
                  this.apply_date_filter();
                }}
                text={"Apply"}
                height={50}
              />
            </View>
          </UI.Row>
          {/* moment(res.start).format("YYYY-MM-DD h:mm").slice(0, 10) !== moment(res.end).format("YYYY-MM-DD h:mm").slice(0, 10) && */}
          <CustomDatatable
            // data_cell_key={
            //   this.state.data.map((res, key) => (  
            //     res.status === "Booked" && (
            //       (res.reschedule?.patient?.isRequest && res.reschedule?.patient?.acceptStatus === "Pending" ) || 
            //       (res.reschedule?.practitioner?.acceptStatus === "Accept" && !res.reschedule?.practitioner?.isAcceptRead) ||
            //       (res.reschedule?.practitioner?.acceptStatus === "Decline" && !res.reschedule?.practitioner?.isDeclineRead)
            //     )
            //   )&& key)
            // }
            state_name={"consultation_records"}
            _this={_this}
            columns={[
              { Patient: "patient" },
              { "Booking Type": "booking_type" },
              { Status: "status" },
              { "Created On": "created_on" },
              { "Start Date/Time": "start_date" },
              // { Duration: "duration" },
              // { "Conference Mode": "conference_mode" },
              // { "Pay Method": "pay_method" },
              // { "Price": "price" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              let created_on = UI.getCurrentTimeStampPHConsult(item.createdAt).slice(0, 10);
              let start_date = UI.getCurrentTimeStampPHConsult(item.start);
             
              item.start = moment(item.start);
              item.end = moment(item.end);
              // let duration = moment.duration(item.end.diff(item.start))
              // duration = duration.asMinutes();

              let status = item.status;
              // console.log(status)
              if (status === "Booked") {
                const dateToday = moment().startOf("day").format("YYYY-MM-DD h:mm");
                const dateStart = moment(item.start).format("YYYY-MM-DD h:mm");
                const dateEnd = moment(item.end).format("YYYY-MM-DD h:mm");
                status = (dateToday.slice(0, 10) === dateStart.slice(0, 10)) ? "Upcoming" : "Booked";

                if (status === "Upcoming") {
                  if (dateStart >= dateToday && dateEnd <= dateToday) {
                    status = "Inprogress";
                  }
                }
                
              } else {
                status = UI.replaceAll(item.status, "_", " ");
              }
              return {
                id: item._id,
                patient: UI.getPatientName(item),
                booking_type: item?.appointmentType === "instant" ? "Consult now" : "Consult later",
                status: this.getColorStatus(status),
                created_on: created_on,
                start_date: start_date,
                // duration: duration,
                // pay_method: item.payType,
                // price: item.price ?? " ",
                // conference_mode: item?.conferenceMode === "ITS" ? "ITS" : "Carespan",
                action: (
                  <div className="flex flex-wrap gap-2">
                    {
                      item.conferenceMode === 'ITS' && (item.status === "Started") &&
                      <View style={{ flexDirection: "row" }} onClick={() => { window.location.href = `/onboard/encounter?appointment_id=${item._id}`}}>
                        <TouchableOpacity >
                          <Text 
                          style={{
                            cursor: 'pointer', 
                            display: 'inline-flex', 
                            userSelect: 'none', 
                            flexDirection: 'column', 
                            padding: '12px 15px', 
                            backgroundColor: 'rgb(4, 204, 255)', 
                            borderRadius: '8px', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            height: '50px',
                            color:'#ffff'
                          }}
                          >Start Now</Text>
                        </TouchableOpacity>
                      </View>
                    }
                    {
                      item.conferenceMode === 'ITS' && 
                      (item.status === "Completed" || item.status === "Result pending" || item.status === "Booked") &&
                      <View style={{ flexDirection: "row", flexWrap: "wrap", rowGap: "3px" }}>
                        <TouchableOpacity
                          onClick={ () => { 
                            if (item.appointment === "instant") {
                              window.location.href = `/onboard/encounter?appointment_id=${item._id}`
                            } else if(moment(new Date()).add('15', 'minutes').format() <= moment(item.start).format()) {
                              this.setState({ isCancel: true, appointmentID: item._id });
                            } else {
                              window.location.href = `/onboard/encounter?appointment_id=${item._id}`
                            }
                          }}
                        >
                          <Text 
                          style={{
                            ...styles.action_button_styles,
                            backgroundColor: 
                            (item.appointment === "instant") 
                              ? "#035099" 
                              : (moment(new Date()).add('15', 'minutes').format() >= moment(item.start).format()) ? "#035099" : "#ffc107",
                          }}
                          >
                            {
                              (item.appointment === "instant") 
                              ? "Go to Appointment" 
                              : (moment(new Date()).add('15', 'minutes').format() >= moment(item.start).format()) ? "Go to Appointment" : "Cancel Appointment"
                            } 
                          </Text>
                        </TouchableOpacity>

                        {
                          item.files?.length ?
                            <TouchableOpacity onClick={ () => this.showFileListerModal(item) }>
                              <Text 
                              style={{
                                ...styles.action_button_styles,
                                backgroundColor: 
                                (item.appointment === "instant") 
                                  ? "#035099" 
                                  : (moment(new Date()).add('15', 'minutes').format() >= moment(item.start).format()) ? "#035099" : "#ffc107",
                              }}
                              >
                                View Files
                              </Text>
                            </TouchableOpacity>
                            : ""
                        }
                      </View>
                    }
                    {
                      status === "Booked" &&
                      <View style={{ flexDirection: "row", gap: 5 }}>

                        {
                          (item.reschedule?.patient?.isRequest && item.reschedule?.patient?.acceptStatus === "Pending") &&

                          <TouchableOpacity 
                            onClick={() => {
                              _this.show_modal(<PatientRescheduleAppointmentModal appointment={item} _this={_this}/>)
                            }}
                          >
                          <Text 
                            style={{
                              cursor: 'pointer', 
                              display: 'inline-flex', 
                              userSelect: 'none', 
                              flexDirection: 'column', 
                              padding: '8px 10px', 
                              backgroundColor: UI.colors.red, 
                              borderRadius: '8px', 
                              justifyContent: 'center', 
                              alignItems: 'center', 
                              color:'#ffff'
                            }}
                            >
                              <img src={UI.CALENDAR_6} alt="CALENDAR_6"/>
                            </Text>
                          </TouchableOpacity>
                         
                        }
                       
                        {

                          (item.reschedule?.practitioner?.acceptStatus === "Decline" && !item.reschedule?.practitioner?.isDeclineRead) &&

                          <TouchableOpacity 
                            onClick={() => {
                              _this.show_modal(<PatientRescheduleAppointmentDeclineModal appointment={item} _this={_this}/>)
                            }}
                          >
                            <Text 
                            style={{
                              cursor: 'pointer', 
                              display: 'inline-flex', 
                              userSelect: 'none', 
                              flexDirection: 'column', 
                              padding: '8px 10px', 
                              backgroundColor: UI.colors.red, 
                              borderRadius: '8px', 
                              justifyContent: 'center', 
                              alignItems: 'center', 
                              color:'#ffff'
                            }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                              </svg>
                            </Text>
                          </TouchableOpacity>
                          
                        }

                        {

                          (item.reschedule?.practitioner?.acceptStatus === "Accept" && !item.reschedule?.practitioner?.isAcceptRead) &&

                          <TouchableOpacity 
                            onClick={() => {
                              _this.show_modal(<PatientRescheduleAppointmentAcceptedModal appointment={item} _this={_this}/>)
                            }}
                          >
                            <Text 
                            style={{
                              cursor: 'pointer', 
                              display: 'inline-flex', 
                              userSelect: 'none', 
                              flexDirection: 'column', 
                              padding: '8px 10px', 
                              backgroundColor: UI.colors.red, 
                              borderRadius: '8px', 
                              justifyContent: 'center', 
                              alignItems: 'center', 
                              color:'#ffff'
                            }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                              </svg>
                            </Text>
                          </TouchableOpacity>

                        }

                        {

                          (!item.reschedule?.practitioner && !item.reschedule?.patient?.isRequest) &&

                          <TouchableOpacity 
                            onClick={() => {
                              _this.show_modal(<DoctorRescheduleAppointmentModal appointment={item} _this={_this}/>)
                            }}
                          >
                            <Text 
                            style={{
                              cursor: 'pointer', 
                              display: 'inline-flex', 
                              userSelect: 'none', 
                              flexDirection: 'column', 
                              padding: '8px 10px', 
                              backgroundColor: UI.colors.secondary, 
                              borderRadius: '8px', 
                              justifyContent: 'center', 
                              alignItems: 'center', 
                              color:'#ffff'
                            }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                              </svg>
                            </Text>
                          </TouchableOpacity>
                           
                        }
                        
                      </View>
                    }
                  </div>
                  )
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />
          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 760, height: 10, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
                {/* OR You don't have any current appointment */}
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
  },
  box_header_subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#C9C9C9",
    alignSelf: "flex-end",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    backgroundColor: "white",
    padding: 20,
    ...Shadow._3(),
    flexDirection: "row",
    maxHeight: 180,
  },
  action_button_styles: {
    cursor: 'pointer', 
    display: 'inline-flex', 
    userSelect: 'none', 
    flexDirection: 'column', 
    padding: '12px 15px',
    margin: '0px 3px',
    borderRadius: '8px',
    justifyContent: 'center', 
    textAlign: 'center',
    flexGrow: 1,
    // alignItems: 'center', 
    // marginLeft: '-40px',
    // height: '50px',
    fontSize: '10px',
    // width: '120px',
    wordBreak: 'normal',
    color:'#ffff',
    backgroundColor: '#035099',
  }
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
