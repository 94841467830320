import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  // Col,
  Button,
  // DatePicker,
  Input,
  Select,
  Typography,
  // Tooltip,
} from "antd";
import styled from "styled-components";
import UI from "../../shared/Components/UI/js";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import DataTable from "../../ComponentsFC/DataTable";
// import ServiceAttendanceApi from "../../api_services/Admin/AttendanceMgmtApi.js";
import ServiceLeaveRequestApi from "../../api_services/Admin/DoctorLeaveRequestApi.js";
import FileDownload from "js-file-download";
import Loading from "../_components/Loading";
import LeaveDetails from "./Modal/LeaveDetailsModal";
import moment from "moment";
import { DoctorLeaveRequest } from "../_components/Modal";

const DoctorLeaveList = ({ _this }) => {
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableEntries, setTableEntries] = useState(30);
  const [totalRecords, setTotalRecords] = useState();
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [isScreenLoading, setIsScreenLoading] = useState();
  const [screenMsg, setIsScreenMsg] = useState("");
  const [isTableLoading, setTableIsLoading] = useState(false);
  // const [currSelectedRowKeys, setCurrSelectedRowKeys] = useState([]);
  // const [selectedDoctorId, setSelectedDoctorId] = useState();
  // const [specificKey, setSpecificKey] = useState([]);

  const getDoctorsLeaveRequest = useCallback(async () => {
    try {
      let urlParams = {};
      urlParams.sort = "createdAt";

      if (currentPageNum) {
        urlParams.page = currentPageNum;
        urlParams.limit = tableEntries;
      }

      if (searchQuery) {
        urlParams.searchString = searchQuery;
      }

      setTableIsLoading(true);
      const apiResponse = await ServiceLeaveRequestApi.getDoctorsLeaveRequest(
        urlParams
      );

      setTableIsLoading(false);

      // console.log(apiResponse);
      setTotalRecords(apiResponse.count);
      // Data from API by default is sorted based on updatedAt
      setTableData(
        apiResponse.data.map((obj, index) => ({ ...obj, key: index }))
      );
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }, [currentPageNum, tableEntries, searchQuery]);

  useEffect(() => {
    getDoctorsLeaveRequest();
  }, [getDoctorsLeaveRequest]);

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setTimeout(setSearchQuery(e.target.value), 3000);
  };

  // const handleChangeRowSelectionOfDoctorOrAllDoctors = (rowKeys) => {
  //   const arrFromObj = Object.values(rowKeys);
  //   if (arrFromObj.length === 2) {
  //     arrFromObj.shift();
  //   }
  //   setCurrSelectedRowKeys(arrFromObj);
  // };

  const handlePaginateTable = (antdSelectedPageNum) => {
    setCurrentPageNum(antdSelectedPageNum);
  };

  const tableColumnHeader = [
    {
      title: "Doctors Name",
      dataIndex: ["requestor"],
      key: ["requestor"],
      render: (text) => {
        if (text) {
          return text;
        } else {
          return "--";
        }
      },
    },
    {
      title: "Leave Type",
      dataIndex: ["leaveType"],
      key: ["leaveType"],
      render: (text) => {
        if (text) {
          return text === "sickLeave"
            ? "Sick Leave"
            : text === "vacationLeave"
            ? "Vacation Leave"
            : text === "bereavementLeave"
            ? "Bereavement Leave"
            : text === "maternityLeave"
            ? "Maternity Leave"
            : text === "paternityLeave"
            ? "Paternity Leave"
            : text === "emergencyLeave"
            ? "Emergency Leave"
            : "";
        } else {
          return "--";
        }
      },
      width: 150,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (date) => {
        if (date) {
          return moment(date).utc().format("DD/MM/YYYY");
        } else {
          return "--";
        }
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (day) => {
        if (day) {
          return moment(day).utc().format("DD/MM/YYYY");
        } else {
          return "--";
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return <CellStatus status={text}>{text}</CellStatus>;
      },
    },
    {
      title: "Action",
      dataIndex: [
        "_id",
        "requestor",
        "leaveType",
        "startDate",
        "endDate",
        "status",
        "duration",
        "reason",
      ],
      key: [
        "_id",
        "requestor",
        "leaveType",
        "startDate",
        "endDate",
        "status",
        "duration",
        "reason",
      ],
      render: (text, record, index) => {
        return (
          <StyledButton
            type="primary"
            style={{ marginLeft: "0" }}
            onClick={() =>
              _this.show_modal(
                <DoctorLeaveRequest
                  title="LEAVE REQUEST"
                  onDismiss={() => {
                    _this.hide_modal();
                  }}
                >
                  <LeaveDetails
                    _this={_this}
                    data={record}
                    leaveList={getDoctorsLeaveRequest}
                  />
                </DoctorLeaveRequest>
              )
            }
          >
            view request
          </StyledButton>
        );
      },
    },
  ];

  const generateLeaveRequestListReport = async () => {
    try {
      // setTableIsLoading(true);
      setIsScreenMsg("Exporting Leave Request List...");
      setIsScreenLoading(true);
      let urlParams = {};
      urlParams.sort = "createdAt";

      if (currentPageNum) {
        urlParams.page = currentPageNum;
        urlParams.limit = tableEntries;
      }

      urlParams.export = true;

      const apiResponse = await ServiceLeaveRequestApi.getLeaveRequestListCSV(
        urlParams
      );
      FileDownload(
        apiResponse.data,
        `Leave Request List ${UI.get_current_date_string()}.csv`
      );
      setIsScreenLoading(false);
      console.log("Export to CSV status: ", apiResponse.status);
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) {
          console.log(`Error: ${statusCode} ${errorMsg}`);
        }
      }
    }
  };

  // let toolTipMsg = <span>Select 1 or All doctors to enable</span>;
  // if (currSelectedRowKeys.length > 1 || specificKey.length === 1) {
  //   toolTipMsg = "";
  // }

  return (
    <>
      <Loading
        visible={isScreenLoading}
        title={""}
        text={screenMsg}
        modal_opaque={false}
      />
      <FilterBox>
        <Title level={5}>Doctor Leave Management</Title>
        {/* <Toolbar >
            <ToolItem span={8}>
              <StyledFieldLabel>For Approval</StyledFieldLabel>
              <StyledSelect
                getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                placeholder="Select Option"
                style={{
                  width: "100%",
                }}
                allowClear
                suffixIcon={<DropdownIcon />}
                onChange={""}
                onClear={""}
              >
                <Select.Option title="Leave Approval" value="asc">
                  Ascending
                </Select.Option>
                <Select.Option title="Leave Approval" value="desc">
                  Descending
                </Select.Option>
              </StyledSelect>
          </ToolItem>
          </Toolbar> */}
      </FilterBox>

      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="30"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="5">5 Entries</Select.Option>
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>
          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getDoctorsLeaveRequest}
            style={{ width: 200 }}
          />
          {/* <Tooltip placement="top" title={toolTipMsg}> */}
          <StyledButton
            type="primary"
            onClick={generateLeaveRequestListReport}
            // disabled={
            //   currSelectedRowKeys.length > 0 || specificKey.length === 1
            //     ? false
            //     : true
            // }
          >
            Export to CSV
          </StyledButton>
          {/* </Tooltip> */}
        </Toolbar>

        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={tableData}
          defaultPageSize={30}
          pageSizeOptions={["5", "10", "30"]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isTableLoading}
          scroll={{
            x: 600,
          }}
          // Antd Checkbox
          // rowSelection={{
          //   type: "checkbox",
          //   selectedRowKeys:
          //     specificKey.length === 1 ? specificKey : currSelectedRowKeys,
          //   onSelect: (record, selected, selectedRows, nativeEvent) => {
          //     setSelectedDoctorId(record.doctorID._id);
          //     setSpecificKey([record.key]);
          //     setCurrSelectedRowKeys([]);
          //     if (record.key === specificKey[0]) {
          //       setSpecificKey([]);
          //       setCurrSelectedRowKeys([]);
          //     }
          //   },
          //   onSelectAll: (selected, selectedRows, changeRows) => {
          //     setSpecificKey([]);
          //     handleChangeRowSelectionOfDoctorOrAllDoctors(
          //       selectedRows.map((el) => el.key)
          //     ); // selectedRowKeys type is object
          //   },
          // }}
        />
      </TableBox>
    </>
  );
};

export default DoctorLeaveList;

// CSS Styled Components ----------------------
const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;
// const twoColRow = styled(Row)`
//   position: relative;
//   display: flex;
//   flex-direction: row;
//   background: #ffffff 0% 0% no-repeat padding-box;
//   box-shadow: 0px 3px 10px #bfbfbf29;
//   border-radius: 12px;
//   padding: 33px 0 0 0;
//   margin-bottom: 30px;
// `;
// const StyledCol = styled(Col)`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   background: #ffffff 0% 0% no-repeat padding-box;
//   box-shadow: 0px 3px 10px #bfbfbf29;
//   border-radius: 12px;
//   padding: 33px 0 0 0;
//   margin-bottom: 30px;
// `;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
  // ALL TABLE BODY CELLS
  .ant-table-tbody > tr > td {
    height: 77px;
  }
  // 3RD COLUMN OF TABLE (DOCTOR'S SPECIALTY)
  .ant-table-row .ant-table-cell:nth-child(3) {
    white-space: pre;
  }
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const CellStatus = styled(Typography.Paragraph)`
  color: ${(props) =>
    props.status === "Approved"
      ? "green"
      : props.status === "Disapproved"
      ? "red"
      : "orange"};
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

// const ToolItem = styled(Col)`
//   position: relative;
//   .ant-picker {
//     margin-left: 102px;
//   }
//   .ant-picker-range {
//     margin-left: 62px;
//   }
// `;

const Title = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.4375rem !important; // 15px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  padding-left: 35px;
  color: #004f99 !important;
  margin-bottom: 30px !important;
`;

// const StyledFieldLabel = styled(Typography.Paragraph)`
//   color: #65d1ff;
//   margin-bottom: 10px !important;
// `;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

// const StyledRangePicker = styled(DatePicker.RangePicker)`
//   flex-direction: row;
//   border-radius: 12px;
//   border: 1px solid transparent;
//   box-shadow: none !important;
//   height: 50px;
//   &:hover {
//     border-color: transparent !important;
//   }
//   .ant-picker-input {
//     border: 2px solid #d9d9d9;
//     border-radius: 12px;
//     padding-left: 10px;
//     padding-right: 10px;
//     height: 50px;
//     width: 160px;
//   }
//   .ant-picker-range-separator {
//     display: block !important; //Need this bcoz the this selector css.css interferes globally
//     margin-left: -43px; //To insert built-in icon inside input field
//     margin-right: 16px;
//   }
//   .ant-picker-suffix {
//     margin-left: -35px; //To insert built-in icon inside input field
//   }
//   .ant-picker-active-bar {
//     display: none;
//   }
//   .ant-picker-clear {
//     display: none;
//   }
// `;

// const ApplyButton = styled(Button)`
//   height: 50px;
//   width: 140px;
//   border-radius: 12px;
//   border: none;
//   background-color: #02488d;
//   font-family: Poppins;
//   font-weight: 500;
//   font-size: 0.9375rem; // 15px
//   line-height: 1.4375rem; // 23px
//   margin-left: 20px;
//   padding-left: 20px;
//   padding-right: 20px;
//   &:hover,
//   &:focus {
//     background-color: #02488d;
//   }
// `;
