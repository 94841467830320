import UI from "../../shared/Components/UI/js";
import ServiceApi from "../service";

class AttendanceMgmtApi {
  baseURL = "admin";

  async getDoctorsAttendance(params) {
    const response = await ServiceApi.get(this.baseURL + `/doctors/attendance-reports`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async getDoctorsAttendanceCSV(params) {
    const response = await ServiceApi.getBlobFile(this.baseURL + `/doctors/attendance-reports`, params);

    if (response?.status === 200) {
      return response;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async getDoctorsDailyTimeReport(params) {
    const response = await ServiceApi.get(this.baseURL + `/doctors/daily-time-reports`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async getDtrCSV(params) {
    const response = await ServiceApi.getBlobFile(this.baseURL + `/doctors/daily-time-reports`, params);

    if (response?.status === 200) {
      return response;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }
}

export default new AttendanceMgmtApi();
