import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  ImageBackground,
  ImageFit,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import "./css.css";

const context = "admin";

export default class AdminEditMerchant extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Merchant List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      image: UI.PRODUCT_BOX,

      merchant_name: "",
      email_address: "",
      contact_number: "",
      password: "",

      key: context + "_admin",
      id: "",
      imagePath: "",
      imageFilename: "",

      did_upload: false,

      image_file: null,
      status: "",
    });
  }

  onCreate = async () => {
    this.load_data();
  };

  load_data = async () => {
    const data = mem.get("edit_merchant");
    if (data) {
      const merchant = JSON.parse(data);
      const { imagePath, imageFilename } = merchant;

      UI.set_input_value("merchant_name", merchant.name ?? "");
      UI.set_input_value("email_address", merchant.email ?? "");
      UI.set_input_value("contact_number", merchant.phone ?? "");
      UI.set_select_value("status", merchant.status ?? "");

      const image = await UI.download_image(merchant.imagePath, "admin");

      this.setState({
        id: merchant._id,
        image: image,
        imagePath,
        imageFilename,
      });
    }
  };

  upload_picture = async () => {
    const file = await UI.get_image();
    this.setState({
      image: file.uri,
      image_file: file.file,
      did_upload: true,
    });
  };

  submit = async () => {
    UI.clear_errors();
    const {
      merchant_name,
      email_address,
      contact_number,
      id,
      did_upload,
      image_file,
      imagePath,
      imageFilename,
      status,
    } = this.state;

    if (!merchant_name) {
      await UI.error_form("merchant_name", "Merchant Name is required.");
    }
    if (!email_address) {
      await UI.error_form("email_address", "Email Address is required.");
    }
    if (!status) {
      await UI.error_form("status", "Status is required.");
    }

    if (!UI.validate_email(email_address)) {
      await UI.error_form(
        "email_address",
        "Please enter a valid email address."
      );
    }

    if (!contact_number) {
      await UI.error_form("contact_number", "Contact Number is required.");
    }
    if (contact_number.length !== 10) {
      await UI.error_form(
        "contact_number",
        "Contact Number should be in 9XX XXX XXXX format."
      );
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    let image_obj = {
      imagePath,
      imageFilename,
    };

    if (did_upload) {
      image_obj = {
        image: image_file,
      };
    }

    this.show_loading();

    UI.webRequest({
      method: "put",
      url: "admin/merchant/" + id,
      params: {
        name: merchant_name,
        phone: contact_number,
        email: email_address,
        ...image_obj,
        status: status,
      },
      isMultiPart: true,
      onFail: (err) => {
        const data = err.data;
        const error_message = data.error;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (response) => {
        if (response.data.c < 400) {
          UI.clear_forms();
          this.show_modal(
            <Success
              title={"Success!!!"}
              description={"Successfully Updated the Merchant"}
              onDismiss={() => {
                UI.goBack();
              }}
            />
          );
        }
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;
    // const { height, width, isMobile } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Update Merchant
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to update merchant
            </Text>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <View style={{ height: 120, width: 120 }}>
              <Layout.FilePicker
                _this={_this}
                title={""}
                additional={""}
                isRequired={false}
                text={""}
                state_name={"product_image"}
                onChange={async (file) => {
                  const uri = await UI.get_uri_from_file(file);
                  this.setState({
                    image: uri,
                    image_file: file,
                    did_upload: true,
                  });
                }}
                height={120}
                component={
                  <ImageBackground
                    style={{ height: 120, width: 120, borderRadius: 10 }}
                    source={this.state.image}
                    imageFit={ImageFit.CONTAIN}
                  />
                }
              />
            </View>
            {UI.box(20)}

            <View style={{ flex: 1.5, justifyContent: "center" }}>
              <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
              >
                Profile Picture Uploaded. Recommended Size: 500 px x 500 px
                (Optional, for Merchant Edit)
              </Text>
              {UI.box(10)}

              <Layout.Btn
                color={UI.colors.primary}
                text={"Upload Merchant Picture"}
                onClick={() => {
                  this.upload_picture();
                }}
                width={200}
              />
            </View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={1090} _this={_this}>
            <InputForm
              _this={_this}
              title={"Merchant Name"}
              placeholder={"Enter Merchant Name"}
              state_name={"merchant_name"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.name}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Email Address (Used as username)"}
              placeholder={"Enter Email Address"}
              state_name={"email_address"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.email}
              disabled={true}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Contact Number"}
              placeholder={"Enter Contact Number"}
              state_name={"contact_number"}
              style={{ flex: 1 }}
              isNumber={true}
              prefix={"+63"}
              isRequired={false}
              maxLength={10}
            />
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={1130} _this={_this}>
            <View style={{ alignSelf: "flex-start", width: 280 }}>
              <Layout.SelectPicker
                _this={_this}
                title={"User Status"}
                isRequired={false}
                state_name={"status"}
              >
                <option value=""></option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Layout.SelectPicker>
            </View>
          </UI.Row>

          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"EDIT MERCHANT"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  ai_fl_100: {
    alignItems: "center",
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
