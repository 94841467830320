import UI from "../shared/Components/UI/js";
import ServiceApi from "./service";
import APIConstants from "../shared/modules/APIConstants";
const Constants = new APIConstants();
class UsersApi {
    baseURLApp = Constants.API_HOST;
    async getAuthUser () {
        // const isMobileRequest = true;
        // const response = await ServiceApi.request(isMobileRequest).get('/auth/me');
        const response = await ServiceApi.get(this.baseURLApp + 'app/mwell/auth/me');
        if (response?.status === 200) {
            const data = UI.extract_data(response);
            if(data) {
                data.status = 200;
                return data;
            } else {
                return response;
            }
        } else {
            return {
                status: response?.status,
                data: response?.data?.d,
                message: response?.data?.m
            }
        }
    }
}

export default new UsersApi()