import Icon from "@ant-design/icons";
import React from "react";

const FieldDateSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.109"
    height="17.486"
    viewBox="0 0 19.109 17.486"
  >
    <path
      id="date"
      d="M151.066,126.346V115.081a2.8,2.8,0,0,1,3.143-3.115h12.823a2.8,2.8,0,0,1,3.143,3.115v11.265a2.793,2.793,0,0,1-3.143,3.106H154.209A2.8,2.8,0,0,1,151.066,126.346Zm15.892,1.048a1.024,1.024,0,0,0,1.149-1.159v-8.419a1.021,1.021,0,0,0-1.149-1.15H154.274a1.015,1.015,0,0,0-1.14,1.15v8.419a1.017,1.017,0,0,0,1.14,1.159Zm-11.553-5.09v-.548c0-.334.12-.436.454-.436h.547c.334,0,.445.1.445.436v.548c0,.333-.111.436-.445.436h-.547C155.526,122.74,155.406,122.637,155.406,122.3Zm0,2.948v-.547c0-.334.12-.436.454-.436h.547c.334,0,.445.1.445.436v.547c0,.334-.111.436-.445.436h-.547C155.526,125.688,155.406,125.586,155.406,125.252Zm3-5.9v-.547c0-.334.111-.436.445-.436h.547c.334,0,.445.1.445.436v.547c0,.334-.111.436-.445.436h-.547C158.521,119.791,158.409,119.689,158.409,119.355Zm0,2.949v-.548c0-.334.111-.436.445-.436h.547c.334,0,.445.1.445.436v.548c0,.333-.111.436-.445.436h-.547C158.521,122.74,158.409,122.637,158.409,122.3Zm0,2.948v-.547c0-.334.111-.436.445-.436h.547c.334,0,.445.1.445.436v.547c0,.334-.111.436-.445.436h-.547C158.521,125.688,158.409,125.586,158.409,125.252Zm2.995-5.9v-.547c0-.334.111-.436.445-.436h.547c.334,0,.445.1.445.436v.547c0,.334-.111.436-.445.436h-.547C161.515,119.791,161.4,119.689,161.4,119.355Zm0,2.949v-.548c0-.334.111-.436.445-.436h.547c.334,0,.445.1.445.436v.548c0,.333-.111.436-.445.436h-.547C161.515,122.74,161.4,122.637,161.4,122.3Zm0,2.948v-.547c0-.334.111-.436.445-.436h.547c.334,0,.445.1.445.436v.547c0,.334-.111.436-.445.436h-.547C161.515,125.688,161.4,125.586,161.4,125.252Zm3-5.9v-.547c0-.334.111-.436.445-.436h.548c.333,0,.454.1.454.436v.547c0,.334-.121.436-.454.436h-.548C164.51,119.791,164.4,119.689,164.4,119.355Zm0,2.949v-.548c0-.334.111-.436.445-.436h.548c.333,0,.454.1.454.436v.548c0,.333-.121.436-.454.436h-.548C164.51,122.74,164.4,122.637,164.4,122.3Z"
      transform="translate(-151.066 -111.966)"
      fill="#4fc2ed"
    />
  </svg>
);

const FieldDateIcon = (props) => {
  return <Icon component={FieldDateSvg} {...props} />;
};

export default FieldDateIcon;
