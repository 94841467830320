class Memory {
  save = (key, value) => {
    localStorage.setItem(key, value);
  };

  remove = (key) => {
    localStorage.removeItem(key);
  };

  get = (key) => {
    return localStorage.getItem(key);
  };

  clear = () => {
    localStorage.clear();
  };

  /**
   * 
   * @param {*} key local storage key
   * @param {*} prop property to update
   * @param {*} data data
   */
  update = (key, prop, data) => {
    let existing = localStorage.getItem(key);

    // If no existing data, create an array
    // Otherwise, convert the localStorage string to an array
    existing = existing ? JSON.parse(existing) : {};

    // Add new data to localStorage Array
    existing[prop] = data;

    // Save back to localStorage
    localStorage.setItem(key, JSON.stringify(existing));
  }
}

const mem = new Memory();
export default mem;
