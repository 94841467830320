/* eslint-disable react/no-direct-mutation-state */
import { TimePicker } from "antd";
// import Item from "antd/lib/list/Item";
import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";

import {
  mem,
  Shadow,
  StyleSheet,
  Text,
  View
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import "./css.css";

const context = "onboard";

export default class OnboardAdvocacyAvailability extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Advocacy Availability",
      is_drawer_open: false,
      errorMessage: null,
      slots: [],
      advocacySchedule:[]
    });

  }

  onCreate = () => {
    this.getAdvocacySchedule();
  };

  getAdvocacySchedule = () => {
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/doctors/advocacy-schedule",
      params: {
        slots: this.state.slots
      },
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.m;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        mem.save('advocacy_schedule', JSON.stringify(data[0].advocacyschedulecms));
        this.setState({ advocacySchedule : data })
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  getSessionTime = (timeString) => {
    const session = moment(timeString, ["h:mm a"]).format("a");
    return session === "am" ? "morning" : "afternoon";
  }

  get24HoursFormat = (timeString) => {
    return moment(timeString, ["h:mm a"]).format("HH:mm");
  }

  onChangeTime = (time, timeString, day, session, advocacy, isStart=false) => {
    day = day.toLowerCase();

    if (timeString !== "") {
      advocacy.date = `${moment(advocacy.date).format("YYYY-MM-DD")} ${this.get24HoursFormat(timeString)}`;
    }
   
    const findAdvocacyIndex = this.state.slots.findIndex(row => row?.advocacyID === advocacy?.advocacyID);
    if (findAdvocacyIndex === -1) {
          this.state.slots.push({
            advocacyID: advocacy?.advocacyID,
            slots: [{
              day: UI.capitalize(day),
              session: this.getSessionTime(timeString),
              startTime: this.get24HoursFormat(timeString),
              startDateGMTTimestamp: moment(advocacy.date, 'YYYY-MM-DD HH:mm')
            }]
          })

        // console.log(findAdvocacyIndex, this.state.slots)
    } else {
      this.state.slots[findAdvocacyIndex].advocacyID = advocacy?.advocacyID;
      // if start slots index have start time morning and afternoon and slots have an object

      if (this.state.slots[findAdvocacyIndex].slots.length !== 0) {
        // start slot morning and afternoon

        if (!isStart) {
          const findIndex = this.state.slots[findAdvocacyIndex].slots.findIndex(row => row.session === session && row.day?.toLowerCase() === day);
            
          if (findIndex !== -1) {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.slots[findAdvocacyIndex].slots[findIndex].startTime = (time === null && timeString === "") ? "" : this.get24HoursFormat(timeString);
            this.state.slots[findAdvocacyIndex].slots[findIndex].startDateGMTTimestamp = (time === null && timeString === "") ? "" : moment(advocacy.date, 'YYYY-MM-DD HH:mm');
            this.state.slots[findAdvocacyIndex].slots[findIndex].session = (time === null && timeString === "") ? "morning" : this.getSessionTime(timeString);

          } else {
             // eslint-disable-next-line react/no-direct-mutation-state
             this.state.slots[findAdvocacyIndex].slots.push({
                day: UI.capitalize(day),
                startTime: this.get24HoursFormat(timeString),
                startDateGMTTimestamp: moment(advocacy.date, 'YYYY-MM-DD HH:mm'),
                session: this.getSessionTime(timeString),
             })
          }
         
        }

        // end slots morning and afternoon
        if (isStart) {
          const findIndex = this.state.slots[findAdvocacyIndex].slots.findIndex(row => row.session === session && row.day?.toLowerCase() === day);
          // if end slot is existing
          if (findIndex !== -1) {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.slots[findAdvocacyIndex].slots[findIndex].endTime = (time === null && timeString === "") ? "" : this.get24HoursFormat(timeString);
            this.state.slots[findAdvocacyIndex].slots[findIndex].endDateGMTTimestamp = (time === null && timeString === "") ? "" : moment(advocacy.date, 'YYYY-MM-DD HH:mm');
          } else {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.slots[findAdvocacyIndex].slots.push({
              day: UI.capitalize(day),
              endTime: this.get24HoursFormat(timeString),
              endDateGMTTimestamp: moment(advocacy.date, 'YYYY-MM-DD HH:mm'),
              session: this.getSessionTime(timeString),
           })
          }
        }
      } else {
         // eslint-disable-next-line react/no-direct-mutation-state
        this.state.slots[findAdvocacyIndex].slots.push({
          day: UI.capitalize(day),
          startTime: this.get24HoursFormat(timeString),
          startDateGMTTimestamp: moment(advocacy.date, 'YYYY-MM-DD HH:mm'),
          session: this.getSessionTime(timeString)
        });
      }
      
    }
    
    this.setState({ slots: this.state.slots })
    this.reload_table("advocacy_availability"+advocacy?.advocacyID);
  }

  isValidSchedule = (day) => {
    const filterDayMorning = this.state.slots.filter(row => row.day === day && row.session === "morning");
    const filterDayAfternoon = this.state.slots.filter(row => row.day === day && row.session === "afternoon");

    if (filterDayMorning.length > 0 && filterDayAfternoon.length > 0) {
      if (moment(filterDayMorning[0].endTime, "HH:mm") >= moment(filterDayAfternoon[0].startTime, "HH:mm")) {
        return true;
      }
    }

    return false
  }

  disabledHours = () => {
    // const start =parseInt(moment(this.state.advocacySchedule[0]?.startDate).format('h')) -1
    // const end = parseInt(moment(this.state.advocacySchedule[0]?.endDate).format('h')) + 1

    const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].filter(hour => {
      return hour !== 8 && hour !== 9 && hour !== 10 && hour !== 11 && hour !== 12

      // if (start > end) {
      //   return hour <= start && hour >= end
      // } else {
      //   return hour <= start + 5 && hour >= end 
      // }
    })
    return hours;
  }
  
  submitTimeAvailibity = () => {
    // eslint-disable-next-line react/no-direct-mutation-state
   if (this.state.slots.length > 0) {
    this.state.slots = this.state.slots.filter(row => row.slots.length !== 0); // remove empty slots
    this.state.slots = this.state.slots.map((row) => {
      row.slots = row.slots.filter(val => val.startTime !== "" && val.endTime !== "")
      row.slots = row.slots.map(val => {
        val.gmtStartTime =  moment(val.startTime, 'HH:mm').tz('Etc/GMT').format('HH:mm')
        val.gmtEndTime =  moment(val.endTime, 'HH:mm').tz('Etc/GMT').format('HH:mm')
        return val
      })
      return row
    });

    for(const validateSlot of this.state.slots) {
      if (validateSlot.slots.length === 0) {
        return this.show_modal(
          <Failed
            title={"Failed"}
            description={`Schedule is empty`}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      }

      for(const slot of validateSlot.slots) {
        if (slot.startTime === "" || slot.endTime === "") {
          return this.show_modal(
            <Failed
              title={"Failed"}
              description={`Invalid time format on ${UI.capitalize(slot.day)} ${UI.capitalize(slot.session)}`}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        }

        if (slot.startTime >= slot.endTime) {
          return this.show_modal(
            <Failed
              title={"Failed"}
              description={`Invalid time format on ${UI.capitalize(slot.day)} ${UI.capitalize(slot.session)}`}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        }
      }

    }
   }

    UI.webRequest({
      method: "post",
      url: "doctors_onboarding/doctors/advocacy-slots",
      params: {
        slots: this.state.slots
      },
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.m;
        console.log(data)
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.show_modal(
          <Success
          title={"Success!"}
          descriptionCenter={"Schedule Updated"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />)
        console.log(data)
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  setDefaultValueTime = (slots, day, session, start) => {
    if (slots) {
      if (slots.length > 0) {
        let res = slots.filter(slot => slot?.day?.toLowerCase() === day?.toLowerCase() && slot?.session?.toLowerCase() === session?.toLowerCase());
        if (res.length > 0) {
    
          const m = moment(new Date());
          if (start === "start") {
            if(res[0].startTime) {
              const minutes = res[0].startTime.split(":")[1];
              const hourFomatTo24Hours = res[0].startTime.slice(0, 2);
              m.set({ h: hourFomatTo24Hours, m: parseFloat(minutes) });
              return moment(m);
            }
          }

          if (start === "end") {
            if (res[0]?.endTime) {
              const minutes = res[0].endTime.split(":")[1];
              const hourFomatTo24Hours = res[0].endTime.slice(0, 2);
              m.set({ h: parseFloat(hourFomatTo24Hours), m: parseFloat(minutes) });
              return moment(m);
            }
          }
        }
      }
    }

    return "";
  }

  validateTimePreviousTimeAdvocacy = (_this, time) => {
    if (moment(moment().toDate()) > time) {
      return {
        isError: true,
        showError:  _this.show_modal(
          <Failed
            title={"Failed"}
            description="Invalid time format advocacy"
            onDismiss={() => {
              _this.hide_modal();
            }}
          />
        )
      }
    }
    return {
      isError: false
    }
  }

  render() {
    const _this = this;
    const data = mem.get("user_doctor");
    // const dataSchedule = mem.get("advocacy_schedule");
    const user_doctor = JSON.parse(data);
    let slots = user_doctor.slotsAdvocacy;

    if (slots) {
      
      if (slots.length > 0 && this.state.slots.length === 0) {

        slots.forEach(slot => {
          this.state.slots.push({
            advocacyID: slot.advocacyID,
            slots: slot.slots
          })
        });

      } else if (this.state.slots.length >= slots.length) {
        slots = this.state.slots.map(slot => {
            return {
              advocacyID: slot.advocacyID,
              slots: slot.slots
            }
        })
      }
    }

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          {/* <UI.Row style={{ padding: "30px 30px 0px 30px", justifyContent:'space-between' }} breakpoint={1053} _this={_this}>
          </UI.Row> */}
        

        {
          (this.state.advocacySchedule.length > 0) ?

            this.state.advocacySchedule.map((advocacy) => {

              let columns = [];
              let dataRows = {};

              for(const val of advocacy.advocacyschedulecms) {
                
                let advocacyID = advocacy._id;
                // add advocacy id to val object
                val.advocacyID = advocacyID;

                const day = moment(val.date).format("LLLL").split(',')[0];
                columns.push({ [day]: day })
                
                // filter advocacy slot value display
                let slotAdvocacy = slots.find(row => row.advocacyID === advocacyID);
                dataRows = 
                {
                  ...dataRows, 
                  [day]:  (<div id="view_advocacy_availability">
                            {UI.box(10)}
                            <p style={{color: UI.colors.primary}}> Morning, from {val.morning_start_time} to {val.morning_end_time}</p>
                            {UI.box(10)}
                              <TimePicker
                                value={this.setDefaultValueTime(slotAdvocacy?.slots, day.toLowerCase(), "morning", "start")} 
                                status="warning" 
                                minuteStep={30}
                                use12Hours
                                format="h:mm a" 
                                placeholder="Start Time"
                                onChange={(time, timeString) => {
                                  // if (parseInt(val.morning_start_time.split(':')[0]) < parseInt(timeString.split(':')[0]) && timeString !== "" && !timeString.includes('12')) {
                                  if (moment(val.morning_start_time, ["h:mm a"]) > moment(timeString, ["h:mm a"]) && timeString !== "" && !timeString.includes('12')) {
                                    this.setState({ errorMessage: `Invalid time format advocacy on ${day} `})
                                    return this.show_modal(
                                      <Failed
                                        title={"Failed"}
                                        description="Invalid time format advocacy"
                                        onDismiss={() => {
                                          this.hide_modal();
                                        }}
                                      />
                                    );
                                  }

                                  // let validateTimePreviousTimeAdvocacy = this.validateTimePreviousTimeAdvocacy(_this, time);
                                  // if (validateTimePreviousTimeAdvocacy.isError) {
                                  //   return validateTimePreviousTimeAdvocacy.showError;
                                  // }

                                  this.onChangeTime(time, timeString, day, "morning", val);
                                }}
                              />
                            {UI.box(10)}
                              <TimePicker 
                                value={this.setDefaultValueTime(slotAdvocacy?.slots, day.toLowerCase(), "morning", "end")}
                                minuteStep={30}
                                use12Hours 
                                format="h:mm a" 
                                placeholder="End Time"
                                onChange={(time, timeString) => { 
                                  if (moment(val.morning_end_time, ["h:mm a"]) < moment(timeString, ["h:mm a"]) && timeString !== "") {
                                    this.setState({ errorMessage: `Invalid time format advocacy on ${day} `})
                                    return this.show_modal(
                                      <Failed
                                        title={"Failed"}
                                        description="Invalid time format advocacy"
                                        onDismiss={() => {
                                          this.hide_modal();
                                        }}
                                      />
                                    );
                                  }

                                  // let validateTimePreviousTimeAdvocacy = this.validateTimePreviousTimeAdvocacy(_this, time);
                                  // if (validateTimePreviousTimeAdvocacy.isError) {
                                  //   return validateTimePreviousTimeAdvocacy.showError;
                                  // }

                                  this.onChangeTime(time, timeString, day, "morning", val, true);
                                }}
                              />
                            {UI.box(20)}
                            <p style={{color: UI.colors.primary}}> Afternoon, from {val.afternoon_start_time} to {val.afternoon_end_time}</p>
                            {UI.box(10)}
                              <TimePicker
                                value={this.setDefaultValueTime(slotAdvocacy?.slots, day.toLowerCase(), "afternoon", "start")}
                                minuteStep={30}
                                use12Hours 
                                format="h:mm a"
                                placeholder="Start Time"
                                onChange={(time, timeString) => {
                                  if (moment(val.afternoon_start_time, ["h:mm a"]) > moment(timeString, ["h:mm a"]) && timeString !== "") {
                                    this.setState({ errorMessage: `Invalid time format advocacy on ${day} `})
                                    return this.show_modal(
                                      <Failed
                                        title={"Failed"}
                                        description="Invalid time format advocacy"
                                        onDismiss={() => {
                                          this.hide_modal();
                                        }}
                                      />
                                    );
                                  }

                                  // let validateTimePreviousTimeAdvocacy = this.validateTimePreviousTimeAdvocacy(_this, time);
                                  // if (validateTimePreviousTimeAdvocacy.isError) {
                                  //   return validateTimePreviousTimeAdvocacy.showError;
                                  // }

                                  this.onChangeTime(time, timeString, day, "afternoon", val);
                                }}
                              />
                            {UI.box(10)}
                              <TimePicker
                                value={this.setDefaultValueTime(slotAdvocacy?.slots, day.toLowerCase(), "afternoon", "end")}
                                minuteStep={30} 
                                use12Hours 
                                format="h:mm a" 
                                placeholder="End Time"
                                onChange={(time, timeString) => {
                                  if (moment(val.afternoon_end_time, ["h:mm a"]) < moment(timeString, ["h:mm a"]) && timeString !== "") {
                                    this.setState({ errorMessage: `Invalid time format advocacy on ${day} `})
                                    return this.show_modal(
                                      <Failed
                                        title={"Failed"}
                                        description="Invalid time format advocacys"
                                        onDismiss={() => {
                                          this.hide_modal();
                                        }}
                                      />
                                    );
                                  }

                                  // let validateTimePreviousTimeAdvocacy = this.validateTimePreviousTimeAdvocacy(_this, time);
                                  // if (validateTimePreviousTimeAdvocacy.isError) {
                                  //   return validateTimePreviousTimeAdvocacy.showError;
                                  // }
                                  this.onChangeTime(time, timeString, day, "afternoon", val, true);
                                }}
                              />
                          </div>)
                }
              }

              function isObjEmpty(obj) {
                for (var prop in obj) {
                  if (obj.hasOwnProperty(prop)) return false;
                }
              
                return true;
              }

              if (!isObjEmpty(dataRows)) {
                return (
                  <>
                    <UI.Row _this={_this} breakpoint={950} style={{ width: "100%", padding: "20px 20px 10px 20px" }}>
                      <View style={{flex:1}}>
                        <Text style={styles.box_header_text}>{advocacy?.title}</Text>
                        </View>
                        <View >
                          <strong className="text-gray-600 font-semibold m-0" style={{textAlign:'start', display:'flex', gap:'5px', alignItems:'center', fontSize:17}}>
                            <img src={UI.AVAILABILITY} alt="" width={17}/>
                            From: {moment(advocacy.startDate).format('MMMM D YYYY')} - {moment(advocacy.endDate).format('MMMM D YYYY')}
                          </strong>
                        </View>
                    </UI.Row>
                    <CustomDatatable
                      state_name={"advocacy_availability"+advocacy._id}
                      _this={_this}
                      columns={columns}
                      data={[dataRows]}
                    />
                  </>
                )
              }
              return true
            })
          : (<></>)
          
        }
        
          <UI.Row style={{ padding: "10px 30px 30px 15px" }} breakpoint={1053} _this={_this}>
            <View style={{ marginTop: 7 }}>
              <Layout.Btn
                color={UI.colors.primary}
                onClick={() => {
                  this.submitTimeAvailibity();
                }}
                disable={true}
                text={"UPDATE SCHEDULE"}
                height={50}
              />
            </View>
          </UI.Row>

        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.primary,
    flex: 1,
  },
});
