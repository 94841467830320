import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import UI from "../../../shared/Components/UI/js";
import TouchableOpacity, {
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../../shared/custom-react-native";
import Layout from "../../_components/Layout";
import { CustomFailed, Failed, Success } from "../../_components/Modal";
import MemberInvitedList from "./MemberInvitedList";
import MemberList from "./MemberList";
import ServiceUsergroupsApi from "../../../api_services/usergroups";
import { Select } from "antd";
import _ from "lodash";
import styled from 'styled-components';
import { Image as AntdImage } from 'antd';
import countries from '../../../shared/mockAPI/countries.json'
import SelectField from "../../../ComponentsFC/ui/forms/SelectField";

const Member = (props) => {
  const [showTab, setShowTab] = useState("Member List");
  const [isSuccessInviteMembers, setIsSuccessInviteMembers] = useState(false);
  const [isClickInviteMember, setIsClickInviteMember] = useState(false);
  const [inviteMemberVia, setInviteMemberVia] = useState("");
  const [countriesData, setCountriesData] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState({
    name: "Philippines",
    flag: "🇵🇭",
    code: "ph",
    dial_code: "+63"
});

  const email_value = props._this.state.members_allowed_email && {
    value: props._this.state.members_allowed_email,
  };
  const phone_number_value = props._this.state.members_allowed_phone_number && {
    value: props._this.state.members_allowed_phone_number,
  };

  const getCountries =  async () => {
    const newData = countries.map((country, index) => {
      const code = country.code.toLowerCase()
      return {
        ...country,
        index:index,
        name:country.dial_code,
        value: country.dial_code,
        type: <>
          <AntdImage
            style={{ borderRadius: "0%", display: "inline-block", margin: "0 5px", width: 20 }}
            src={`https://hatscripts.github.io/circle-flags/flags/${code}.svg`}
            alt=""
            preview={false}
          /> <strong>{country.code}</strong> <span style={{ color: UI.colors.primary}}>{country.dial_code}</span>
        </>,
        code: code,
      };
    });
    setCountriesData(_.orderBy(newData, ['code'], ['asc']));
  };

  useEffect(() => {
    getCountries();
  }, []);

  const validate_csv = async (items) => {
    return new Promise((resolve, reject) => {
      if (items.length === 0) {
        return;
      }

      const uploaUserTemplate =
        "Email,First Name,Middle Name,Last Name,Phone Number Prefix,Phone Number,Date of Birth,Gender,Special Condition";

      if (
        items[0] === uploaUserTemplate
        // items[0].includes("Email")
      ) {
        resolve();
      } else {
        props._this.show_modal(
          <CustomFailed
            title={"Process Failed"}
            description_title={""}
            description={"Invalid CSV Format"}
            _this={this}
            onDismiss={() => {
              props._this.hide_modal();
            }}
          />
        );
      }
    });
  };

  const proccess_users_csv = async (content) => {
    return new Promise((resolve, reject) => {
      if (content.length === 0) {
        resolve([]);
        return;
      }
      const regex1 = "/n/n/";
      const regex2 = "\r\n";
      content = UI.replaceAll(content, regex2, regex1);
      content = UI.replaceAll(content, "\n", "");
      content = UI.replaceAll(content, regex1, regex2);
      var array = content.split(regex2);
      let result = [];

      let headers = array[0].split(",");

      for (let i = 1; i < array.length - 1; i++) {
        let obj = {};
        let str = array[i];
        let s = "";
        let flag = 0;
        for (let ch of str) {
          if (ch === '"' && flag === 0) {
            flag = 1;
          } else if (ch === '"' && flag === 1) flag = 0;
          if (ch === "," && flag === 0) ch = "|";
          if (ch !== '"') s += ch;
        }

        let properties = s.split("|");

        for (let j in headers) {
          obj[headers[j]] = properties[j];
        }
        result.push(obj);
      }

      // result.shift();
      // result.pop();
      resolve(result);
    });
  };

  const process_user_name_address = async (arr = []) => {
    return new Promise((resolve, reject) => {
      if (arr.length === 0) {
        resolve([]);
      }

      const new_data = [];
      arr.forEach((item, index) => {
        item.email = item["Email"] ?? "";
        item.firstName = item["First Name"] ?? "";
        item.middleName = item["Middle Name"] ?? "";
        item.lastName = item["Last Name"] ?? "";
        item.phoneNumberPrefix = item["Phone Number Prefix"] ?? "";
        item.phoneNumber = item["Phone Number"] ?? "";
        item.birthDate = item["Date of Birth"] ?? "";
        item.gender = item["Gender"] ?? "";
        item.specialCondition = item["Special Condition"] ?? "";

        delete item["Email"];
        delete item["First Name"];
        delete item["Middle Name"];
        delete item["Last Name"];
        delete item["Phone Number Prefix"];
        delete item["Phone Number"];
        delete item["Date of Birth"];
        delete item["Gender"];
        delete item["Special Condition"];

        new_data.push(item);

        if (index + 1 === arr.length) {
          resolve(new_data);
        }
      });
    });
  };

  const importCsv = () => {
    props._this.show_modal(
      <View style={{ backgroundColor: "white", borderRadius: 10 }}>
        <View
          style={{ ...styles.md_header, backgroundColor: UI.colors.secondary }}
        >
          <TouchableOpacity
            onClick={() => props._this.hide_modal()}
            style={styles.md_close}
          >
            <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="Close" />
          </TouchableOpacity>

          <View style={styles.md_header_text_container}>
            <Text style={{ ...styles.md_header_text, textAlign: "center" }}>
              Create User Group Admin
            </Text>
          </View>

          {UI.box(60)}
        </View>

        <View style={{ ...styles.md_content_container, width: "100%" }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div className="flex flex-col gap-2">
              <TouchableOpacity
                onClick={() => {
                  const csv_data = [""].map((item, index) => {
                    const d = {
                      Email: "",
                      "First Name": "",
                      "Middle Name": "",
                      "Last Name": "",
                      "Phone Number Prefix": "",
                      "Phone Number": "",
                      "Date of Birth": "",
                      Gender: "",
                      "Special Condition": "",
                    };

                    return d;
                  });

                  UI.DownloadCSV(
                    "Sample CSV Format" + UI.get_current_date_string(),
                    csv_data
                  );
                }}
                style={{
                  ...styles.md_button,
                  backgroundColor: UI.colors.primary,
                }}
              >
                <Text style={styles.md_btn_text}>Download Sample CSV Format</Text>
              </TouchableOpacity>
              <Text style={{ color: "white", width:200 }}>(Recommended file size: max 10mb; 500 number of records)</Text>
            </div>
            {UI.box(10)}
            <div className="flex flex-col gap-2">
              <TouchableOpacity
                onClick={async () => {
                  const content = await UI.get_csv_contents();
                  console.log(content);
                  const all_text = content.split("\r\n");
                  console.log(all_text);
                  await validate_csv(all_text);
                  const new_list = await proccess_users_csv(content);
                  console.log(new_list);
                  // const user_list =
                  const member_list = await process_user_name_address(new_list);
                  console.log(member_list);
                  console.log(new_list);
                  let invalidEmail = [];

                  // for (const user of new_list) {
                  //   if (!UI.validate_email(user.email)) {
                  //     invalidEmail.push(user.email)
                  //   }

                  //   if (user.email === "") {
                  //     return props._this.show_modal(
                  //       <Failed
                  //       description={`One of the email is empty.`}
                  //       onDismiss={props._this.hide_modal} />
                  //     )
                  //   }
                  // }

                  if (invalidEmail.length > 0) {
                    return props._this.show_modal(
                      <Failed
                        description={`
                        
                        ${invalidEmail
                          .map((email) => `${email}`)
                          .join(", ")} is invalid email address`}
                        onDismiss={props._this.hide_modal}
                      />
                    );
                  }
                  const email_only = new_list
                    .map((res) => res.email.trim())
                    .filter((email) => email); // Filter out empty email addresses
                  const get_usergroup = JSON.parse(mem.get("view_user_group"));

                  function hasDuplicatesEmail(a) {
                    return _.uniq(email_only).length !== email_only.length;
                  }

                  if (hasDuplicatesEmail()) {
                    return props._this.show_modal(
                      <Failed
                        description={`Duplicate email address for bulk upload`}
                        onDismiss={props._this.hide_modal}
                      />
                    );
                  }

                  const payload = {
                    userRequests: new_list,
                    userGroups: [get_usergroup._id],
                  };

                  props._this.show_loading("", "Importing members... please wait...");
                  props._this.hide_modal();
                  ServiceUsergroupsApi.userGroupInviteMembers(payload).then(
                    (res) => {
                      if (res.status === 200) {
                        // let data = res.data;
                        props._this.show_modal(
                          <Success
                            descriptionCenter={"Successfully Invite/Add Members."}
                            onDismiss={() => {
                              props._this.hide_loading();
                              setIsSuccessInviteMembers(true);
                              // setInviteMembers(new_list)
                              props._this.hide_modal();
                            }}
                          />
                        );
                      } else {
                        console.log(res)
                        props._this.show_modal(
                          <Failed
                            description={res.message || (`${res.status} Server Error`)}
                            onDismiss={() => {
                              props._this.hide_loading();
                              props._this.hide_modal();
                            }}
                          />
                        );
                      }
                    }
                  ).catch(err =>  {
                    return props._this.show_modal(
                      <Failed
                        description={"502 Bad Gateway"}
                        onDismiss={() => {
                          props._this.hide_loading();
                          props._this.hide_modal();
                        }}
                      />
                    )
                  });
                }}
                style={{
                  ...styles.md_button,
                  backgroundColor: UI.colors.secondary,
                  // border:'1px solid ' + UI.colors.secondary,
                }}
              >
                <Text style={styles.md_btn_text}>Bulk Upload</Text>
              </TouchableOpacity>
              <Text style={{ width:230, color: "#BEBEBE"}}>(Recommended file size: max 10mb; 1000 number of records)</Text>
            </div>
          </View>
        </View>
      </View>
    );
  };

  const inviteMembers = () => {
    let data = mem.get("view_user_group");
    let user_group = JSON.parse(data);

    const membersEmail = props._this.state.members_allowed_email.map(
      (email) => {
        const item = {
          email: email,
        };
        return item;
      }
    );

    const membersPhoneNumber =
      props._this.state.members_allowed_phone_number.map((number) => {
        const item = {
          phoneNumber: number,
          phoneNumberPrefix: selectedCountryCode.dial_code,
        };
        return item;
      });

    const members =
      inviteMemberVia === "email" ? membersEmail : membersPhoneNumber;

    props._this.show_loading();
    const payload = {
      userRequests: members,
      userGroups: [user_group._id],
    };

    ServiceUsergroupsApi.userGroupInviteMembers(payload).then((res) => {
      if (res.status === 200) {
        // let data = res.data;
        props._this.show_modal(
          <Success
            descriptionCenter={"Successfully Invite/Add Members."}
            onDismiss={() => {
              setIsSuccessInviteMembers(true);
              props._this.hide_loading();
              // setInviteMembers(payload)
              props._this.hide_modal();
              props._this.setState({
                members_allowed_email: [],
                members_allowed_phone_number: [],
              });
            }}
          />
        );
      } else {
        props._this.show_modal(
          <Failed
            description={res.message}
            onDismiss={() => {
              props._this.hide_loading();
              props._this.hide_modal();
            }}
          />
        );
      }
    });
  };

  const showModalSelectionInviteType = () => {
    props._this.show_modal(
      <View style={{ backgroundColor: "white", borderRadius: 10 }}>
        <View
          style={{ ...styles.md_header, backgroundColor: UI.colors.secondary }}
        >
          <TouchableOpacity
            onClick={() => props._this.hide_modal()}
            style={styles.md_close}
          >
            <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="Close" />
          </TouchableOpacity>

          <View style={styles.md_header_text_container}>
            <Text style={{ ...styles.md_header_text, textAlign: "center" }}>
              Invite Via
            </Text>
          </View>

          {UI.box(60)}
        </View>

        <View style={{ ...styles.md_content_container, width: "100%" }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <TouchableOpacity
              onClick={() => {
                setIsClickInviteMember(true);
                setInviteMemberVia("email");
                props._this.hide_modal();
              }}
              style={{
                ...styles.md_button,
                backgroundColor: UI.colors.secondary,
              }}
            >
              <Text style={styles.md_btn_text}>Email Address</Text>
            </TouchableOpacity>
            {UI.box(10)}
            <TouchableOpacity
              onClick={async () => {
                setIsClickInviteMember(true);
                setInviteMemberVia("phone");
                props._this.hide_modal();
              }}
              style={{
                ...styles.md_button,
                backgroundColor: UI.colors.secondary,
                // border:'1px solid ' + UI.colors.secondary,
              }}
            >
              <Text style={styles.md_btn_text}>Phone Number</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  return (
    <>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 15,
          width: "100%",
        }}
      >
        {/* <div className="flex flex-1 gap-10"> */}
        <Toolbar gutter={16}>
          <Col span={12}>
            <Layout.Btn
              onClick={() => {
                setShowTab("Member List");
              }}
              text={"Member List"}
              color={
                showTab === "Member List"
                  ? UI.colors.primary
                  : UI.colors.secondary
              }
              style={{
                // ...showTab === "Member List" ? { background: UI.colors.primary } : { background: UI.colors.secondary },
                cursor: "pointer",
                fontWeight: "bold",
                fontSize: 16,
              }}
            ></Layout.Btn>
            {/* <Layout.Btn 
            onClick={() => {
              setShowTab("Invited List")
            }}
            text={"Invited List"}
            color={showTab === "Invited List" ? UI.colors.primary : UI.colors.secondary }
            style={{
            // ...showTab === "Member List" ? { background: UI.colors.primary } : { background: UI.colors.secondary },
              cursor:'pointer', fontWeight:'bold', fontSize: 16
            }}
          >
          </Layout.Btn> */}
            {/* </div> */}
          </Col>
          <Col
            span={12}
            style={{
              marginLeft: "auto",
              padding: 0,
            }}
          >
            <Row style={{ justifyContent: "right" }}>
              {isClickInviteMember && (
                <div className="member-input-email flex flex-1 items-start gap-3">
                  {
                    inviteMemberVia === "phone" &&
                    <StyledSelectField 
                      showArrow={false}
                      style={{ width: 125, height:"100%" }}
                      value={<>
                        <AntdImage
                          style={{  display: "inline-block", margin: "0 5px", width: 20 }}
                          preview={false}
                          src={`https://hatscripts.github.io/circle-flags/flags/${selectedCountryCode.code}.svg`}
                          alt=""
                        /> {selectedCountryCode.dial_code}</>
                      }
                      selectoptions={countriesData}
                      onChange={(value) => {
                        const find = countriesData.find(country => country.value === value)
                        setSelectedCountryCode(find)
                      }}
                    />
                  }
                  <Select
                    mode="tags"
                    size={
                      inviteMemberVia === "email"
                        ? props._this.state.members_allowed_email
                        : phone_number_value
                    }
                    placeholder={`Enter ${
                      inviteMemberVia === "email"
                        ? "Email Address"
                        : "Mobile Number"
                    } `}
                    autoClearSearchValue={true}
                    {...(inviteMemberVia === "email"
                      ? email_value
                      : phone_number_value)}
                    onChange={(e) => {
                      if (inviteMemberVia === "email") {
                        for (const item of e) {
                          if (!UI.validate_email(item)) {
                            return e.pop();
                          }
                        }
                        props._this.setState({ members_allowed_email: e });
                      } else {
                        props._this.setState({
                          members_allowed_phone_number: e,
                        });
                      }
                    }}
                    style={{
                      padding: "0px 10px 0px 0px",
                      width: "100%",
                      flex: 1,
                    }}
                  >
                    <Select.Option></Select.Option>
                  </Select>
                </div>
              )}

              <div className="flex items-center gap-3">
                {isClickInviteMember ? (
                  <TouchableOpacity
                    onClick={() => {
                      if (inviteMemberVia === "email") {
                        if (
                          props._this.state.members_allowed_email.length === 0
                        ) {
                          return true;
                        }
                        inviteMembers();
                      } else {
                        if (
                          props._this.state.members_allowed_phone_number.length === 0
                        ) {
                          return true;
                        }
                        inviteMembers();
                      }
                    }}
                    style={{
                      ...styles.md_button,
                      // height: 40,
                      padding: 12,
                      paddingLeft: 45,
                      paddingRight: 45,
                      fontSize: 16,
                      color: "white",
                      // cursor: (props._this.state.members_allowed_email.length === 0) ? "not-allowed" : "pointer",
                      // backgroundColor: (props._this.state.members_allowed_email.length === 0) ? "#CACACA" : UI.colors.secondary
                      cursor: "pointer",
                      backgroundColor: UI.colors.secondary,
                    }}
                  >
                    <Text>Invite</Text>
                  </TouchableOpacity>
                ) : (
                  <Layout.Btn
                    color={UI.colors.secondary}
                    text={"Invite"}
                    onClick={() => {
                      showModalSelectionInviteType();
                    }}
                    height={60}
                    width={130}
                  />
                )}

                <Layout.Btn
                  color={UI.colors.secondary}
                  text={"Bulk Invite"}
                  onClick={() => {
                    importCsv();
                    // UI.goTo("/voucher/usergroup-add");
                  }}
                  height={60}
                  width={130}
                />
              </div>
            </Row>
          </Col>
        </Toolbar>
      </View>

      {showTab === "Member List" ? (
        <MemberList
          _this={props._this}
          isSuccessInviteMembers={isSuccessInviteMembers}
          // invitedMembers={invitedMembers}
        />
      ) : (
        <MemberInvitedList
          _this={props._this}
          isSuccessInviteMembers={isSuccessInviteMembers}
        />
      )}
    </>
  );
};

export default Member;

const Toolbar = styled(Row)`
  width: 100%;
  padding-left: 30.5px;
  padding-right: 30.5px;
`;

const StyledSelectField = styled(SelectField)`
  display: flex;
  .ant-select-selector {
    border: 2px solid #DFE5EB !important;

    & .ant-select-selection-search-input {
      height: 100% !important;
      text-align: center;
      background: red !important;
    }

    & .ant-select-selection-item {
      display: flex;
      justify-content: space-around;
    }

    & .ant-select-selection-item input {
      background: red !important;
    }
  }

`;

const styles = StyleSheet.create({
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  md_button: {
    height: "60px",
    padding: "0px 30px",
    // backgroundColor: "#004F99",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: "#00CBFF",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
  md_content_container: {
    padding: 25,
    alignItems: "start",
  },
  md_btn_text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  modal_container: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    // backgroundColor: "rgba(255,255,255,0.85)",
  },
});
