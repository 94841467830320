import React from 'react'
import UI from '../../shared/Components/UI/js'
// import TouchableOpacity, { Text } from '../../shared/custom-react-native'
// import { ComingSoon } from '../_components/Modal'

const Header = (props) => {
  const { isMobile } = props._this.state
  return (
    <header className={`flex justify-between ${isMobile ? 'px-2 py-2' : 'px-4 py-4'} `}>
      <a href='https://www.mwell.com.ph' style={{color:"#0274BE"}} rel="noopener noreferrer">
        <img src={UI.MWELL_LOGO} alt="mwell_logo" width={isMobile ? 80 :120}/>
      </a>
      <div className="flex gap-5 items-center">
        {/* <Text style={{fontWeight:'bold', display:isMobile && 'none'}}>Already Joined an Advocacy?</Text>
        <TouchableOpacity>
          <button className="flex items-center px-4 py-3 text-white gap-2 rounded-md" style={{backgroundColor:'#0056AE'}}
            onClick={() => {
              props._this.show_modal(<ComingSoon onCancel={props._this.hide_modal} />);
            }}
          >
            <img src={UI.LOGOUT} alt="logout_icon" height={18} width={18}/>
            Sign In
          </button>
        </TouchableOpacity> */}
      </div>
    </header>
  )
}

export default Header