import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import MainLayout from "../_components/MainLayout";
import "./css.css";
import Statistics from "./Statistics";

// **Commented out due to currently unused
// const SampleTransactions = [
//   {
//     user: {
//       firstName: "Kurt Matthew",
//       lastName: "San Miguel",
//     },
//     createdAt: new Date(),
//   },
// ];

const context = "merchant";
export default class EcommerceDashboard extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Dashboard",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,

      data: {
        totalCustomers: null,
        totalFinishedPurchases: 0,
        totalActiveProducts: 0,
        totalInquiries: 0,
        totalMerchantProducts: 0,
        totalPaidPurchases: 0,
        totalCompletedPurchases: 0,
        topPurchasedProduct: {
          productID: "",
          count: 0,
          name: "",
          imagePath: "",
        },
      },

      top_product_image: UI.PRODUCT_BOX,
      current_date: UI.get_current_date(),
      current_date_string: UI.get_current_date_string(),

      transactions: [],

      inquiries: [],

      custom_active_products: 0,

      customer_ids: [],
    });
  }

  onCreate = async () => {
    this.get_data();
  };

  get_data = async () => {
    this.get_transactions();
    // this.get_inquiries();
    //this.get_products();
  };

  // get_products = async () => {
  //   UI.webRequest({
  //     method: "get",
  //     url: "admin/products/merchant/me",
  //     params: {
  //       sort: "-createdAt",
  //     },
  //     onFail: async (err) => {},
  //     onSuccess: async (response) => {
  //       const data = UI.extract_data(response);
  //       const active = data.data.filter((item) => item.status == "Active");
  //       this.setState({
  //         custom_active_products: active.length,
  //       });
  //     },
  //     onFinish: () => {
  //       this.hide_loading();
  //     },
  //   });
  // };

  get_transactions = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "admin/transactions/merchant/me",
      params: {
        sort: "-createdAt",
      },
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        const final_data = data.data.filter((obj, index) => index < 5);

        data.data.forEach((item) => {
          if (!this.state.customer_ids.includes(item.user?._id)) {
            this.setState({
              customer_ids: [...this.state.customer_ids, item.user?._id],
            });
          }
        });

        this.transaction_previews(final_data);
        this.setState({ transactions: final_data }, () => {
          this.reload_table("transaction");
        });
        this.load_data_by_duration("year");
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  transaction_previews = async (data) => {
    UI.set_images(
      data,
      "user.imagePath",
      "transaction",
      "transaction",
      "ecommerce",
      UI.TRANSACTION_USER
    );
  };

  // get_inquiries = async () => {
  //   this.show_loading();
  //   UI.webRequest({
  //     method: "get",
  //     url: "admin/inquiries/merchant/me",
  //     params: {
  //       sort: "-createdAt",
  //     },
  //     onFail: async (err) => {},
  //     onSuccess: async (response) => {
  //       const data = UI.extract_data(response);
  //       const final_data = data.data.filter((obj, index) => index < 5);

  //       this.inquiries_previews(final_data);
  //       this.setState({ inquiries: final_data }, () => {
  //         this.reload_table("inquiry");
  //       });
  //     },
  //     onFinish: () => {
  //       this.hide_loading();
  //     },
  //   });
  // };

  inquiries_previews = async (data) => {
    UI.set_images(
      data,
      "customer.imagePath",
      "inquiry",
      "inquiry",
      "ecommerce",
      UI.TRANSACTION_USER
    );
  };

  load_data_by_duration = (duration) => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "admin/merchant-dashboards/stats/me?duration=" + duration,
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        console.log(data);
        this.setState(
          {
            data: data,
          },
          () => {
            this.load_image();
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  load_image = async () => {
    const { data } = this.state;

    if (data.topPurchasedProduct) {
      if (data.topPurchasedProduct.imagePath) {
        const img = await UI.download_image(
          data.topPurchasedProduct.imagePath,
          "ecommerce",
          UI.PRODUCT_BOX
        );
        this.setState({
          top_product_image: img,
        });
      }
    }
  };

  render() {
    const _this = this;
    const { 
      // height, 
      width, 
      // isMobile, 
      // custom_active_products 
    } = _this.state;

    // **Commented out due to currently unused 
    // let add_style = {};
    // if (width <= 470) {
    //   add_style = { flexDirection: "row" };
    // }

    return (
      <MainLayout context={context} _this={_this}>
        <Statistics _this={_this} />

        <UI.Row breakpoint={1300} _this={_this} style={{ width: "100%" }}>
          <View
            style={{ ...styles.main_content_container, flex: 1, padding: 0 }}
          >
            <View>
              <UI.Row
                breakpoint={1070}
                _this={_this}
                style={{ width: "100%", padding: 20 }}
              >
                <Text style={styles.box_header_text}>Recent Transactions</Text>
              </UI.Row>

              <div hidden>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 20,
                    paddingTop: 0,
                  }}
                >
                  <Text style={styles.entry_header}>Only Show</Text>
                  {UI.box(10)}
                  <View>
                    <EntryPicker table_name={"transaction"} _this={_this} />
                  </View>
                </View>
              </div>
            </View>
            <CustomDatatable
              state_name={"transaction"}
              _this={_this}
              disable_pagination={true}
              columns={[
                { "Customer Name": "customer_name" },
                { "Purchase Date": "purchase_date" },
                { "Product Purchased": "product_name" },
                { "Payment Status": "status" },
                { "Order Status": "order_status" },
                { Action: "action" },
              ]}
              data={this.state.transactions.map((item) => {
                const image =
                  this.state["transaction" + item._id] ?? UI.PRODUCT_BOX;
                const date_added = UI.timestampToDate(
                  new Date(item.createdAt).getTime()
                );
                return {
                  customer_name: (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <img style={{ height: 35, width: 35 }} src={image} alt="Customer"/>
                      {UI.box(8)}
                      <Text>
                        {item.user?.firstName ?? ""} {item.user?.lastName ?? ""}
                      </Text>
                    </View>
                  ),
                  purchase_date:
                    date_added.month +
                    "/" +
                    date_added.day +
                    "/" +
                    date_added.year,
                  product_name: item.product?.name ?? "-",
                  order_status: item.orderStatus ?? " ",
                  status: UI.get_transaction_status_text(item),
                  action: (
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        onClick={() => {
                          mem.save("view_transaction", JSON.stringify(item));
                          UI.goTo("/merchant/view-transaction");

                          // UI.goTo("/merchant/view-transaction-details");
                        }}
                      >
                        <Image
                          height={32}
                          width={32}
                          tintColor={"transparent"}
                          source={UI.VIEW_BTN_1}
                        />
                      </TouchableOpacity>
                    </View>
                  ),
                };
              })}
              on_page={(page, callback) => {}}
            />
            <View
              style={{
                ...styles.no_info_container,
                display: this.state.transactions.length > 0 ? "none" : "flex",
              }}
            >
              <UI.Row
                breakpoint={820}
                style={{ maxWidth: 360, alignItems: "center" }}
                _this={_this}
              >
                <Image
                  height={100}
                  width={100}
                  tintColor={"transparent"}
                  source={UI.NO_INFO}
                />
                {UI.box(10)}
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#BFBFBF",
                    textAlign: width > 820 ? "left" : "center",
                    marginLeft: width > 820 ? 20 : 0,
                  }}
                >
                  NO DATA AVAILABLE YET
                </Text>
              </UI.Row>
            </View>
          </View>
          {/* {UI.box(20)} */}
          {/* <View
            style={{ ...styles.main_content_container, flex: 1, padding: 0 }}
          >
            <View>
              <UI.Row
                breakpoint={1070}
                _this={_this}
                style={{ width: "100%", padding: 20 }}
              >
                <Text style={styles.box_header_text}>Recent Inquiries</Text>
              </UI.Row>
              <div hidden>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 20,
                    paddingTop: 0,
                  }}
                >
                  <Text style={styles.entry_header}>Only Show</Text>
                  {UI.box(10)}
                  <View>
                    <EntryPicker table_name={"inquiry"} _this={_this} />
                  </View>
                </View>
              </div>
            </View>
            <CustomDatatable
              state_name={"inquiry"}
              _this={_this}
              disable_pagination={true}
              columns={[
                { "Customer Name": "customer_name" },
                { "Product Name": "product_name" },
                { Subject: "subject" },
                { Action: "action" },
              ]}
              data={this.state.inquiries.map((item) => {
                const image =
                  this.state["inquiry" + item._id] ?? UI.PRODUCT_BOX;

                return {
                  customer_name: (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <img style={{ height: 35, width: 35 }} src={image} />
                      {UI.box(8)}
                      <Text>
                        {item.customer?.firstName} {item.customer?.lastName}{" "}
                      </Text>
                    </View>
                  ),
                  product_name: item.product?.name ?? "-",
                  subject: item.description ?? "-",
                  action: (
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        onClick={() => {
                          mem.save("view_inquiry", JSON.stringify(item));
                          UI.goTo("/merchant/view-inquiry");
                          //UI.goTo("/merchant/view-inquiry");
                        }}
                      >
                        <Image
                          height={32}
                          width={32}
                          tintColor={"transparent"}
                          source={UI.VIEW_BTN}
                        />
                      </TouchableOpacity>
                    </View>
                  ),
                };
              })}
              on_page={(page, callback) => {}}
            />
            <View
              style={{
                ...styles.no_info_container,
                display: this.state.inquiries.length > 0 ? "none" : "flex",
              }}
            >
              <UI.Row
                breakpoint={820}
                style={{ maxWidth: 360, alignItems: "center" }}
                _this={_this}
              >
                <Image
                  height={100}
                  width={100}
                  tintColor={"transparent"}
                  source={UI.NO_INFO}
                />
                {UI.box(10)}
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#BFBFBF",
                    textAlign: width > 820 ? "left" : "center",
                    marginLeft: width > 820 ? 20 : 0,
                  }}
                >
                  NO DATA AVAILABLE YET
                </Text>
              </UI.Row>
            </View>
          </View> */}
        </UI.Row>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  main_content_container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    flex: 1,
  },
  legend_text: {
    color: "#535353",
    fontWeight: "bold",
    fontSize: 12,
    marginLeft: 10,
  },
  legend_container: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  dot_color: {
    height: 12,
    width: 12,
    borderRadius: 10,
    backgroundColor: UI.colors.primary,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
  },
  box_header_subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#C9C9C9",
    alignSelf: "flex-end",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    backgroundColor: "white",
    padding: 20,
    ...Shadow._3(),
    flexDirection: "row",
    maxHeight: 180,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
