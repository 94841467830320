import Icon from "@ant-design/icons";
import React from "react";

const TrashBinModalSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="218.852"
    height="157.486"
    viewBox="0 0 218.852 157.486"
  >
    <g id="gfx" transform="translate(-196.402 -246.674)">
      <rect
        id="Rectangle_239"
        data-name="Rectangle 239"
        width="218.852"
        height="48.664"
        rx="24"
        transform="translate(196.402 355.496)"
        fill="rgba(67,200,255,0.1)"
      />
      <path
        id="Path_3362"
        data-name="Path 3362"
        d="M60.041,188.6a3.59,3.59,0,0,1-1.274-2.1l-1.37-5.792c-.43-.148-.854-.3-1.272-.476s-.819-.334-1.19-.5L49.9,182.834a3.73,3.73,0,0,1-2.352.586,3.467,3.467,0,0,1-2.18-1.063L41.787,178.8a3.628,3.628,0,0,1-1.048-2.224,3.535,3.535,0,0,1,.63-2.392l3.048-5c-.168-.392-.331-.784-.491-1.176s-.3-.793-.433-1.2l-5.818-1.367a3.465,3.465,0,0,1-2.1-1.248,3.678,3.678,0,0,1-.755-2.334v-5.008a3.51,3.51,0,0,1,2.854-3.553l5.763-1.37a8.652,8.652,0,0,1,.462-1.286c.177-.392.34-.766.488-1.121l-3.074-5.121a3.612,3.612,0,0,1-.644-2.375,3.3,3.3,0,0,1,1.089-2.154l3.611-3.58a3.639,3.639,0,0,1,4.419-.56l5.118,3.19c.374-.189.775-.369,1.2-.549s.856-.34,1.286-.491l1.37-5.818a3.628,3.628,0,0,1,1.274-2.081,3.576,3.576,0,0,1,2.308-.769h5.118a3.588,3.588,0,0,1,2.308.769,3.5,3.5,0,0,1,1.245,2.081l1.4,5.876c.45.151.877.308,1.289.476s.793.345,1.147.531l5.2-3.217a3.628,3.628,0,0,1,2.267-.462,3.9,3.9,0,0,1,2.1.993l3.638,3.609a3.249,3.249,0,0,1,1.077,2.154,3.9,3.9,0,0,1-.6,2.375l-3.106,5.121c.148.354.308.729.476,1.121s.325.819.476,1.286l5.789,1.37a3.514,3.514,0,0,1,2.825,3.553v5.008a3.676,3.676,0,0,1-.752,2.334,3.459,3.459,0,0,1-2.073,1.248L86.323,166.8c-.131.412-.276.813-.435,1.2s-.331.784-.517,1.176l3.077,5a3.627,3.627,0,0,1,.645,2.392,3.5,3.5,0,0,1-1.065,2.224l-3.58,3.553a3.481,3.481,0,0,1-2.183,1.063,3.722,3.722,0,0,1-2.349-.586l-5.063-3.106c-.375.168-.766.337-1.176.5s-.83.328-1.26.476l-1.4,5.792a3.47,3.47,0,0,1-1.245,2.1,3.643,3.643,0,0,1-2.308.755H62.349A3.632,3.632,0,0,1,60.041,188.6Zm9.749-20.9a9.943,9.943,0,0,0,3.524-3.539,9.473,9.473,0,0,0,1.315-4.909,9.263,9.263,0,0,0-1.315-4.854,10.027,10.027,0,0,0-3.524-3.51,9.691,9.691,0,0,0-9.749,0,10.181,10.181,0,0,0-3.527,3.51,9.192,9.192,0,0,0-1.327,4.854,9.473,9.473,0,0,0,1.315,4.909,9.92,9.92,0,0,0,3.524,3.539,9.717,9.717,0,0,0,9.763,0Z"
        transform="translate(300.995 117.466)"
        fill="#b90c0c"
        opacity="0.24"
      />
      <path
        id="Path_3363"
        data-name="Path 3363"
        d="M60.041,188.6a3.59,3.59,0,0,1-1.274-2.1l-1.37-5.792c-.43-.148-.854-.3-1.272-.476s-.819-.334-1.19-.5L49.9,182.834a3.73,3.73,0,0,1-2.352.586,3.467,3.467,0,0,1-2.18-1.063L41.787,178.8a3.628,3.628,0,0,1-1.048-2.224,3.535,3.535,0,0,1,.63-2.392l3.048-5c-.168-.392-.331-.784-.491-1.176s-.3-.793-.433-1.2l-5.818-1.367a3.465,3.465,0,0,1-2.1-1.248,3.678,3.678,0,0,1-.755-2.334v-5.008a3.51,3.51,0,0,1,2.854-3.553l5.763-1.37a8.652,8.652,0,0,1,.462-1.286c.177-.392.34-.766.488-1.121l-3.074-5.121a3.612,3.612,0,0,1-.644-2.375,3.3,3.3,0,0,1,1.089-2.154l3.611-3.58a3.639,3.639,0,0,1,4.419-.56l5.118,3.19c.374-.189.775-.369,1.2-.549s.856-.34,1.286-.491l1.37-5.818a3.628,3.628,0,0,1,1.274-2.081,3.576,3.576,0,0,1,2.308-.769h5.118a3.588,3.588,0,0,1,2.308.769,3.5,3.5,0,0,1,1.245,2.081l1.4,5.876c.45.151.877.308,1.289.476s.793.345,1.147.531l5.2-3.217a3.628,3.628,0,0,1,2.267-.462,3.9,3.9,0,0,1,2.1.993l3.638,3.609a3.249,3.249,0,0,1,1.077,2.154,3.9,3.9,0,0,1-.6,2.375l-3.106,5.121c.148.354.308.729.476,1.121s.325.819.476,1.286l5.789,1.37a3.514,3.514,0,0,1,2.825,3.553v5.008a3.676,3.676,0,0,1-.752,2.334,3.459,3.459,0,0,1-2.073,1.248L86.323,166.8c-.131.412-.276.813-.435,1.2s-.331.784-.517,1.176l3.077,5a3.627,3.627,0,0,1,.645,2.392,3.5,3.5,0,0,1-1.065,2.224l-3.58,3.553a3.481,3.481,0,0,1-2.183,1.063,3.722,3.722,0,0,1-2.349-.586l-5.063-3.106c-.375.168-.766.337-1.176.5s-.83.328-1.26.476l-1.4,5.792a3.47,3.47,0,0,1-1.245,2.1,3.643,3.643,0,0,1-2.308.755H62.349A3.632,3.632,0,0,1,60.041,188.6Zm9.749-20.9a9.943,9.943,0,0,0,3.524-3.539,9.473,9.473,0,0,0,1.315-4.909,9.263,9.263,0,0,0-1.315-4.854,10.027,10.027,0,0,0-3.524-3.51,9.691,9.691,0,0,0-9.749,0,10.181,10.181,0,0,0-3.527,3.51,9.192,9.192,0,0,0-1.327,4.854,9.473,9.473,0,0,0,1.315,4.909,9.92,9.92,0,0,0,3.524,3.539,9.717,9.717,0,0,0,9.763,0Z"
        transform="translate(173.576 137.763)"
        fill="#b90c0c"
        opacity="0.08"
      />
      <path
        id="Path_3364"
        data-name="Path 3364"
        d="M111.762,283.778q-3.212-2.948-3.424-8.625L105,199.044H98a5.144,5.144,0,0,1-3.71-1.486,4.918,4.918,0,0,1-1.541-3.7,4.864,4.864,0,0,1,1.541-3.66A5.148,5.148,0,0,1,98,188.7h95.523a5.166,5.166,0,0,1,3.737,1.464,4.877,4.877,0,0,1,1.514,3.688,4.956,4.956,0,0,1-1.514,3.7,5.118,5.118,0,0,1-3.737,1.486h-6.947l-3.341,76.109q-.264,5.68-3.446,8.625t-8.967,2.939H120.7Q114.966,286.717,111.762,283.778Zm8.724-102.926q0-6.523,4-10.178t11.168-3.66h20.151q7.158,0,11.141,3.66t3.974,10.178v9.864H159.045v-9.385a3.4,3.4,0,0,0-1.062-2.626,4.146,4.146,0,0,0-2.917-.98H136.449a4.256,4.256,0,0,0-2.945.98,3.376,3.376,0,0,0-1.084,2.626v9.385H120.487Zm11.031,87.937a3.553,3.553,0,0,0,1.007-2.758l-1.591-55.473a3.684,3.684,0,0,0-1.139-2.708,3.97,3.97,0,0,0-2.785-1.007,3.826,3.826,0,0,0-2.835,1.035,3.677,3.677,0,0,0-1.035,2.73l1.64,55.478a3.582,3.582,0,0,0,1.09,2.73,4.326,4.326,0,0,0,5.647-.028Zm17.1.028a3.582,3.582,0,0,0,1.09-2.73V210.559a3.53,3.53,0,0,0-1.09-2.681,3.941,3.941,0,0,0-2.835-1.035,4.022,4.022,0,0,0-2.89,1.035,3.53,3.53,0,0,0-1.09,2.681v55.528a3.582,3.582,0,0,0,1.09,2.73,4,4,0,0,0,2.89,1.035A3.918,3.918,0,0,0,148.62,268.817Zm17.03,0a3.785,3.785,0,0,0,1.139-2.73l1.591-55.478a3.7,3.7,0,0,0-1.035-2.73,3.835,3.835,0,0,0-2.835-1.035,3.975,3.975,0,0,0-2.791,1.007,3.764,3.764,0,0,0-1.139,2.758l-1.591,55.423A3.573,3.573,0,0,0,160,268.789a4.333,4.333,0,0,0,5.653.028Z"
        transform="translate(160.071 99.957)"
        fill="#b90c0c"
      />
    </g>
  </svg>
);

const TrashBinModalIcon = (props) => {
  return <Icon component={TrashBinModalSVG} {...props} />;
};

export default TrashBinModalIcon;
