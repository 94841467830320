import React, { Component } from "react";
import Otp from "../../ComponentsFC/otp/Otp";
import UI from "../../shared/Components/UI/js";
const context = "signup";

export default class OnboardOtp extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      remember_me: false,
      context: context,
    });
  }

  onCreate = async () => {};

  render() {
    return (
      <>
        <Otp _this={this} />
      </>
    );
  }
}
