import React, { Component } from "react";
import { Select, Tag } from 'antd';
import styled from "styled-components"; 
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import TouchableOpacity, {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm
// , { Checkbox } 
from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { 
  // CustomFailed,
  Failed, 
  Success } from "../_components/Modal";
import MemberList from './MemberList';
import "./css.css";
export default class VoucherUserGroupEdit extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "User Group List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: mem.get(UI.Constants.ROLE), // RESUSEABLE PAGES TO ADMIN, USER GROUP ADMIN, VOUCHER ADMIN ROLE
      id:"",
      name: "",
      description:"",
      status:"",
      members:[],
      users: [],
      allowed_user: "",
      parent_user_group: [],
      allowed_parent_user_group: "",
      data:[],
      all_data:[],
      tagging: [],
      allowed_tagging: [],
      isOverwriteMember: false,
      isShowSeeMemberButton: false,
      isShowMembers: false,
      domainWhitelist: null
    });
  }

  onCreate = () => {
    this.loadTagging();
    this.loadUserGroup();
  };

  get_data = async () => {
    this.show_loading();
  }

  load_data = () => {
    const data = mem.get("edit_user_group");
    
    if (data) {
      let user_group = JSON.parse(data);

      const {
        _id,
        name,
        description,
        tags,
        members,
        status,
        parentUserGroup,
        domainWhitelist
      } = user_group;

      UI.set_input_value("name", name ?? "");
      UI.set_input_value("description", description ?? "");
      UI.set_multiple_select_value("allowed_tagging", tags.map(tag => tag._id));
      UI.set_input_value("status", status ?? "");
      UI.set_input_value("allowed_parent_user_group", parentUserGroup ?? "");

      this.setState({
        domainWhitelist: domainWhitelist ?? []
      })

      this.setState({
        id: _id,
        data: members,
        all_data: members,
      }, () => {
        this.reload_table("aktivo_members_list");
      });
    }
  };

  loadTagging = () => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/tags",
      params: {},
      onFail:  (err) => {},
      onSuccess:  (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            tagging: data,
          }
        );
        this.load_data();
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  // validate_csv = async (items) => {
  //   return new Promise((resolve, reject) => {
  //     if (items.length === 0) {
  //       return;
  //     }

  //     const uploadUserTemplate =
  //       "ID,Fullname,Email";

  //     if (items[0] === uploadUserTemplate || items[0].includes(uploadUserTemplate)) { 
  //       resolve();
  //     } else {
  //       this.show_modal(
  //         <CustomFailed
  //           title={"Process Failed"}
  //           description_title={""}
  //           description={"Invalid CSV Format"}
  //           _this={this}
  //           onDismiss={() => {
  //             this.hide_modal();
  //           }}
  //         />
  //       );
  //     }
  //   });
  // };

  // proccess_users_csv = async (content) => {
  //   return new Promise((resolve, reject) => {
  //     if (content.length === 0) {
  //       resolve([]);
  //       return;
  //     }
  //     const regex1 = "/n/n/";
  //     const regex2 = "\r\n";
  //     content = UI.replaceAll(content, regex2, regex1);
  //     content = UI.replaceAll(content, "\n", "");
  //     content = UI.replaceAll(content, regex1, regex2);
  //     var array = content.split(regex2);
  //     let result = [];

  //     let headers = array[0].split(",");

  //     for (let i = 1; i < array.length - 1; i++) {
  //       let obj = {};
  //       let str = array[i];
  //       let s = "";
  //       let flag = 0;
  //       for (let ch of str) {
  //         if (ch === '"' && flag === 0) {
  //           flag = 1;
  //         } else if (ch === '"' && flag === 1) flag = 0;
  //         if (ch === "," && flag === 0) ch = "|";
  //         if (ch !== '"') s += ch;
  //       }

  //       let properties = s.split("|");

  //       for (let j in headers) {
  //         obj[headers[j]] = properties[j];
  //       }
  //       result.push(obj);
  //     }

  //     // result.shift();
  //     // result.pop();
  //     resolve(result);
  //   });
  // };

  // process_user_name_address = async (arr = []) => {
  //   return new Promise((resolve, reject) => {
  //     if (arr.length === 0) {
  //       resolve([]);
  //     }

  //     const new_data = [];
  //     arr.forEach((item, index) => { 
  //       item.id = item["ID"] ?? ""
  //       item.fullname = item["Fullname"]
  //       item.email = item["Email"]
 
  //       delete item["ID"]
  //       delete item["Fullname"]
  //       delete item["Email"]
  //       item.id && new_data.push(item);
  //       if (index + 1 === arr.length) {
  //         resolve(new_data);
  //       }
  //     });
  //   });
  // };

  // import_csv = async () => {
  //   const content = await UI.get_csv_contents();
  //   const all_text = content.split("\r\n");
  //   await this.validate_csv(all_text);
  //   const new_list = await this.proccess_users_csv(content);
  //   const user_list = await this.process_user_name_address(new_list);

  //   this.setState({ 
  //     members: user_list,
  //     data: user_list,
  //     all_data: user_list,
  //     isShowSeeMemberButton: true},
  //     () => {
  //       this.reload_table("member_list_edit");
  //     })
  // };

  submit = async () => {
    UI.clear_errors();

    let {
      name,
      description,
      members,
      status,
      allowed_tagging,
      domainWhitelist
    } = this.state;

    if (!name) {
      await UI.error_form("name", "Doctor Group Name is required.");
    }

    if (allowed_tagging.length === 0) {
      await UI.error_form("allowed_tagging", "Category Name is required.");
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      this.hide_loading();
      return;
    }

    this.show_loading();

    UI.webRequest({
      method: "put",
      url: "admin/usergroup/" + this.state.id,
      params: {
        overwrite: this.state.isOverwriteMember,
        payload: {
           name,
           description,
           status,
           tags: allowed_tagging,
           domainWhitelist
         },
        members: members
      },
      onFail: async (err) => {
        this.show_modal(
          <Failed description={err.data.message} onDismiss={this.hide_modal} />
        );
      },
      onSuccess: async (response) => {
        this.show_modal(
          <Success
            descriptionCenter={"Successfully edited the user group."}
            onDismiss={() => {
              UI.clear_forms();
              this.setState({
                title: "",
              });
              this.hide_modal();
              UI.goBack();
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  loadUserGroup = async () => {

    UI.webRequest({
      method: "post",
      url: "admin/usergroup/all",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        const aktivo = data.filter(item => item.hasOwnProperty('tags') && item?.tags[0]?.name === 'aktivo-organization-group')

        this.setState({ parent_user_group: aktivo });
      },
      onFinish: () => {
      },
    });
  }

  render() {
    const _this = this;
    const data = mem.get("edit_user_group");
    const user_group = JSON.parse(data);

    const {
      status,
      allowed_parent_user_group,
      // width,
      // members
    } = this.state;

    return (
      <MainLayout context={this.state.context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Edit User Group
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to complete registration
            </Text>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Group Name"}
              placeholder={"Enter Group Name"}
              state_name={"name"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.title}
            />
            {UI.box(20)}
            <Layout.SelectPicker
              _this={_this}
              title={"User Group Category"}
              isRequired={true}
              state_name={"allowed_tagging"}
              isMultiple={true}
              // isDisabled={true}
            >
            <option value="">Select User Type</option>
            {
              this.state.tagging.map(item => {
                return(
                  <option key={item._id} value={item._id}>{item.name}</option>
                )
              })
            }
            </Layout.SelectPicker>
            {UI.box(20)}
            {user_group.tags[0]?.name === 'aktivo-department-group' ?
            <Layout.SelectPicker
              _this={this}
              isRequired={true}
              title={"Parent User Group"}
              state_name={"allowed_parent_user_group"}
              isDisabled={true}
            >
              <option value="">Select User Group Type</option>
              {
                this.state.parent_user_group.map(item => {
                  return(
                    <option 
                      key={item._id}
                      value={item._id}
                      selected={item._id === allowed_parent_user_group ? 'selected' : ''} 
                    >{item.name}</option>
                  )
                })
              }
            </Layout.SelectPicker>
            : <View style={{ flex: 1 }}></View>}
          </UI.Row>

          {user_group.tags[0]?.name.startsWith('aktivo-')
            && (
            <>
            <UI.Row breakpoint={970} _this={_this} style={{  marginTop: 20 }}>
              <Layout.SelectPicker
                _this={this}
                title={"Select Status"}
                state_name={"status"}
              >
                <option value="">Please Select Status</option>

                {['Active', 'Inactive'].map((value, index) => {
                  return (
                    <option 
                      key={index} 
                      value={value}
                      selected={value === status ? 'selected' : ''} 
                    >{value}</option>
                  )
                })}
              </Layout.SelectPicker>
                {UI.box(20)}
                <View style={{ flex: 1 }}></View>
                {UI.box(10)}
                <View style={{ flex: 1 }}></View>
            </UI.Row>
          </>
          )}
          
          {(user_group.tags[0]?.name.startsWith('aktivo-organization') && 
            Array.isArray(this.state.domainWhitelist)) && (
            <>
              {UI.box(20)}
              <UI.Row breakpoint={970} _this={_this}>
                <div className="flex flex-col w-full">
                  <Text
                    style={{
                      color: UI.colors.primary,
                      fontWeight: 500,
                      marginBottom: 10,
                      fontFamily: "Poppins",
                      fontSize: "15px"
                    }}
                  >
                    White List Domain
                  </Text>
                  <StyledSelect
                    mode="tags"
                    style={{
                      width: '100%',
                      height: 100
                    }}
                    tagRender={(props) => {

                      const { label, closable, onClose } = props;

                      const onPreventMouseDown = (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      };

                      return (
                        <StyledTags
                          onMouseDown={onPreventMouseDown}
                          closable={closable}
                          onClose={onClose}
                        >
                          {label}
                        </StyledTags>
                      )
                    }}
                    onChange={(value) => {
                      this.setState({
                        domainWhitelist: value
                      })
                    }}
                    defaultValue={this.state.domainWhitelist}
                    open={false}
                  />
                  {UI.box(20)}
                </div>
              </UI.Row>
            </>
          )}
          {/* {!user_group.tags[0]?.name.startsWith('aktivo-') && 
            <UI.Row breakpoint={970} _this={_this}>
              <View style={{flex:1, marginTop: 20 }}>

                <span className="font-semibold" style={{color: UI.colors.primary}}>
                  Overwrite members?
                </span>

                {UI.box(10)}

                <UI.Row _this={_this} breakpoint={1070}>
                  <Checkbox
                    _this={_this}
                    text={"Yes"}
                    onClick={(value) => {
                      this.setState({
                        isOverwriteMember: true,
                      });
                    }}
                    is_checked={_this.state.isOverwriteMember}
                    color={"#2E2E2E"}
                  />
                  {UI.box(10)}
                  <Checkbox
                    _this={_this}
                    text={
                      "No"
                    }
                    onClick={(value) => {
                      this.setState({
                        isOverwriteMember: false,
                      });
                    }}
                    is_checked={!_this.state.isOverwriteMember}
                    color={"#2E2E2E"}
                  />
                  {UI.box(10)}
                  <View style={{justifyContent:"end"}}>
                    <Layout.Btn
                      color={UI.colors.primary}
                      id="importProduct"
                      text={"Import Members CSV"}
                      onClick={() => {
                        this.import_csv()
                      }}
                      height={40}
                    />
                  </View>
                </UI.Row>
              </View>
              {UI.box(10)}
              <View style={{ flex: 1 }}></View>
              {UI.box(10)}
              <View style={{ flex: 1 }}></View>
            </UI.Row>
          }
          {UI.box(10)} */}

          { this.state.isShowSeeMemberButton &&  
            <div style={{ color: UI.colors.secondary}}>
              <button onClick={() => this.setState({isShowMembers: !this.state.isShowMembers})} style={{ textDecoration:'underline', borderRadius:'7px', padding:'5px 0px', fontSize:'14px', flex:1}} href="##">
                Show Members Import
              </button>
            </div>
          }

          {
            this.state.isShowMembers && <>
              <UI.Row
                style={styles.main_container_header}
                breakpoint={950}
                _this={_this}
              >
                <View
                  style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
                >
                  <Text style={styles.entry_header}>Only Show</Text>
                  {UI.box(10)}
                  <View>
                    <EntryPicker table_name={"member_list_edit"} _this={_this} />
                  </View>
                </View>
              </UI.Row>
              {UI.box(10)}
              <CustomDatatable
                state_name={"member_list_edit"}
                _this={_this}
                columns={[
                  { "Fullname": "fullname" },
                  { "Email": "email" },
                  { Action : "action"}
                ]}
                data={_this.state.data.map(item => {

                  return {
                    fullname: item.fullname,
                    email: item.email,
                    action: (
                      <View style={{ flexDirection: "row" }}>
                        <TouchableOpacity
                          onClick={() => {
                            const filter = this.state.data.filter((a, f) =>  a.id !== item.id )
                            _this.setState({
                              members: filter,
                              data:filter,
                              all_data:filter,
                            },
                            () => {
                              _this.reload_table("member_list_edit");
                            });
                          }}
                        >
                          <Image
                            height={28}
                            width={28}
                            tintColor={"transparent"}
                            source={UI.DELETE_BTN}
                          />
                        </TouchableOpacity>
                      </View>
                    ),
                  };
                })}
                on_page={(page, callback, table_name) => {
                  UI.on_page(this.state.all_data, callback, table_name, page);
                }}
              />

            </>
          }

          {UI.box(10)}
          
           <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Description"}
              placeholder={"Enter Description Here"}
              state_name={"description"}
              style={{ flex: 1 }}
              height={100}
              isRequired={false}
              type={"big"}
              maxLength={UI.Constants.limits.description}
            />
          </UI.Row>

          {UI.box(20)}

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"EDIT USER GROUP"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>

        {user_group.tags[0]?.name.startsWith('aktivo-') && 
          <MemberList _this={_this}/>
        }
        
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});

const StyledSelect = styled(Select)`

  font-weight: bold;
  font-family: "Poppins";

  &:not(.ant-select-disabled):hover,
  &:not(.ant-select-disabled):focus,
  &:not(.ant-select-disabled):active {
    .ant-select-selector {
      border-color: #04ccff !important;
    }
  }

  .ant-select-selector {
    padding: 7px;
    border-radius: 12px !important;
    border-style: solid !important;
    border-width: 2px !important;
  }

  .ant-select-selection-overflow-item {
    height: 100px;
  }
`
const StyledTags = styled(Tag)`
  color: #000;
  display: flex;
  margin-right: 0.5rem;
  align-items: center;
  background: #e6e6e6;
  margin-top: 3px;
`