import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Success } from "../_components/Modal";
import "./css.css";

const context = "finance";

export default class FinanceDoctorsDisbursement extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Doctors Disbursement",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,

      sort: "-createdAt",
      status: "",
      name: "",
    });
  }

  onCreate = async () => {
    setTimeout(() => {
      this.get_data("Daily");
    }, 100);
  };

  get_data = async (sched) => {
    this.show_loading();
    sched = sched ?? "Daily";

    UI.webRequest({
      method: "get",
      url: "admin/disbursements/disbursables?disbursement_schedule=" + sched,
      params: {},
      onFail: (err) => {},
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        // const doctors_disbursement = [{id: "1231", name: "", disbursement_schedule: "Daily",  disbursements: []}]

        let new_data = data.map((item, index) => {
          const doctor_name =
            UI.DataHelper.carespan.get_name(item?.name) ?? " ";
          // **Commented out, currently not used
          // const id = item._id;
          const withholdingTax = item.withholdingTax;
          const disbursement_schedule = item.disbursement_schedule;
          const financialInformation = item.financialInformation;

          const bankName = financialInformation?.bankName ?? " ";
          const bankBranch = financialInformation?.bankBranch ?? " ";
          const bankAccountName = financialInformation?.bankAccountName ?? " ";
          const bankAccountNumber =
            financialInformation?.bankAccountNumber ?? " ";

          const vatable = item.vatable;

          item.fullname = doctor_name ?? " ";
          item.bankName = bankName ?? " ";
          item.bankBranch = bankBranch ?? " ";
          item.bankAccountName = bankAccountName ?? " ";
          item.bankAccountNumber = bankAccountNumber ?? " ";
          item.withholdingTax = withholdingTax ?? " ";
          item.disbursement_schedule = disbursement_schedule ?? " ";
          item.gross_amount = item?.grossWithrawableAmount ?? 0;
          item.net_amount = item?.netWithrawableAmount ?? 0;
          item.vatable = vatable ?? true;

          return item;
        });

        this.setState(
          {
            all_data: new_data,
            filtered_data: new_data,
          },
          () => {
            this.reload_table("doctors_list");
          }
        );
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  sort_by = (format, key) => {
    //format can be asc or desc
    const { all_data } = this.state;

    all_data.sort(UI.dynamicSort(format === "asc" ? key : "-" + key));
    UI.set_input_value("search", "");

    this.setState(
      {
        data: all_data,
      },
      () => {
        this.reload_table("doctors_list");
      }
    );
  };

  custom_sort = (key, value) => {
    const { all_data } = this.state;
    let items = all_data.filter((item) => item[key] + "" === value);
    UI.set_input_value("search", "");
    if (value) {
      this.setState(
        {
          data: items,
        },
        () => {
          this.reload_table("doctors_list", items);
        }
      );
    } else {
      this.setState(
        {
          data: all_data,
        },
        () => {
          this.reload_table("doctors_list", all_data);
        }
      );
    }
  };

  get_total_amount = (disbursements) => {
    let amount = 0;
    let totalAmount = 0;
    for (let i = 0; i < disbursements.length; i++) {
      const j = disbursements[i];
      let disimbursableAmount = j?.disimbursableAmount ?? 0;
      const currentAmount = j?.amount ?? 0;

      if (j.deductions.length === 0) {
        disimbursableAmount = j?.amount ?? 0;
      }
      amount = amount + disimbursableAmount;
      totalAmount = totalAmount + currentAmount;
    }
    return { amount, totalAmount };
  };

  export_to_csv = async () => {
    const { all_data } = this.state;

    const csv_data = all_data.map((item, index) => {
      const d = {
        Row: index + 1,
        "Doctor Name": item?.fullname ?? " ",
        "Bank Name": item?.bankName ?? " ",
        "Bank Branch": item?.bankBranch ?? " ",
        "Bank Account Name": item?.bankAccountName ?? " ",
        "Bank Account Number": item?.bankAccountNumber ?? " ",
        "Withholding Tax %": item.withholdingTax + "%",
        "Disbursement Schedule": item.disbursement_schedule ?? " ",
        "Gross Amount": item.grossWithrawableAmount ?? 0,
        "Net Amount": item.netWithrawableAmount ?? 0,
        Vatable: item.vatable ? "Yes" : "No",
      };

      return d;
    });

    UI.DownloadCSV(
      "Doctors Disbursement " + UI.get_current_date_string(),
      csv_data
    );
  };

  export_disbursements = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "admin/disbursements/process",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        const csv_data = data.map((item, index) => {
          // **Commented out, currently not used
          // const id = item._id;

          const doctor_name =
            UI.DataHelper.carespan.get_name(item?.doctor?.name) ?? " ";
          const withholdingTax = item.doctor?.withholdingTax ?? 10;
          const disbursement_schedule = item.doctor?.disbursement_schedule;
          const financialInformation = item.doctor?.financialInformation;

          const bankName = financialInformation?.bankName ?? " ";
          const bankBranch = financialInformation?.bankBranch ?? " ";
          const bankAccountName = financialInformation?.bankAccountName ?? " ";
          const bankAccountNumber =
            financialInformation?.bankAccountNumber ?? " ";

          const d = {
            Row: index + 1,
            "Doctor Name": doctor_name ?? " ",
            "Bank Name": bankName ?? " ",
            "Bank Branch": bankBranch ?? " ",
            "Bank Account Name": bankAccountName ?? " ",
            "Bank Account Number": bankAccountNumber ?? " ",
            "Withholding Tax %": withholdingTax + "%",
            "Disbursement Schedule": disbursement_schedule ?? " ",
            "Gross Amount": item.amount ?? "0",
            "Net Amount": item.disbursableAmount ?? "0",
            Vatable: item.doctor?.vatable ? "Yes" : "No",
            "Date Disbursed": UI.get_date_string_by_date(item.createdAt) ?? " ",
          };
          return d;
        });

        UI.DownloadCSV(
          "Processed Disbursements " + UI.get_current_date_hr_mm_string(),
          csv_data
        );

        if (data.length === 0) {
          this.show_modal(
            <Success
              title={"Success!"}
              description={"No data found to process the disbursement."}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        }
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;
    const {
      // height,
      width,
      //  isMobile
    } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <Text style={styles.box_header_text}>Doctors Disbursement</Text>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Schedule"}
              isRequired={false}
              onChange={(e) => {
                this.get_data(e);
              }}
            >
              <option value="">Select Schedule</option>
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Gross Amount"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "gross_amount");
              }}
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Doctor Name"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "fullname");
              }}
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={1135}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"doctors_list"} _this={_this} />
              </View>
            </View>

            <InputForm
              onChange={(e) => {
                UI.search_table("doctors_list", e, this.state.all_data, "data");
              }}
              is_keyword={true}
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
            />

            <UI.Row
              breakpoint={600}
              _this={_this}
              style={{
                marginTop: 8,
                marginLeft: 10,
                alignSelf: width > 1135 ? "flex-start" : "center",
                alignItems: "center",
              }}
            >
              <Layout.Btn
                color={UI.colors.primary}
                text={"Export to CSV"}
                onClick={() => {
                  this.export_to_csv();
                }}
                height={40}
                width={130}
              />
              {UI.box(10)}
              <Layout.Btn
                color={UI.colors.primary}
                text={"Process Disbursements"}
                onClick={() => {
                  this.export_disbursements();
                }}
                height={40}
                width={180}
              />
            </UI.Row>
          </UI.Row>

          <CustomDatatable
            disable_pagination={false}
            state_name={"doctors_list"}
            _this={_this}
            columns={[
              // { ID: "id" },
              { "Doctor Name": "name" },
              { "Bank Name": "bankName" },
              { "Bank Branch": "bankBranch" },
              { "Bank Account Name": "bankAccountName" },
              { "Bank Account Number": "bankAccountNumber" },
              { "Withholding Tax %": "withholding_tax" },
              { "Disbursement Schedule": "disbursement_schedule" },
              { "Gross Amount": "gross_amount" },
              { "Net Amount": "net_amount" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              const edit_doctor = async () => {
                mem.save("edit_doctor", JSON.stringify(item));

                UI.goTo("/finance/disburse-doctor-payment");
              };

              return {
                bankName: item.bankName,
                bankBranch: item.bankBranch,
                bankAccountName: item.bankAccountName,
                bankAccountNumber: item.bankAccountNumber,
                name: (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginLeft: 10,
                    }}
                  >
                    <Text
                      style={{
                        maxWidth: 80,
                        marginRight: 10,
                        textAlign: "center",
                      }}
                    >
                      {item.fullname}
                    </Text>
                  </View>
                ),
                disbursement_schedule: item.disbursement_schedule,
                withholding_tax: item.withholdingTax,
                gross_amount: item.gross_amount,
                net_amount: item.net_amount,
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onClick={edit_doctor}>
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
