const day_int_str = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const day_int_str_3 = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const total_days = 42;

class Helper {
  get_active_days = (month, year) => {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date).getDate());
      date.setDate(date.getDate() + 1);
    }
    return days;
  };
  get_inactive_days = (month, year) => {
    let d = new Date();
    d.setDate(1);
    d.setMonth(month);
    d.setFullYear(year);
    //Day is 0 = Monday 6 = Sunday
    let day = d.getDay();
    let days = this.get_active_days(month, year);
    let diff = total_days - (day + days.length);

    let leading_day_num = day;
    let last_day_num = diff;

    let { prev_month, prev_year } = this.get_prev_month_year(month, year);
    let { next_month, next_year } = this.get_next_month_year(month, year);

    let leading_days = this.get_last_days(
      prev_month,
      prev_year,
      leading_day_num
    );
    let trailing_days = this.get_first_days(
      next_month,
      next_year,
      last_day_num
    );

    return { leading: leading_days, trailing: trailing_days };
  };

  get_last_days = (month, year, num) => {
    //month: January = 0 December = 11
    let days = this.get_active_days(month, year);
    return days.slice(Math.max(days.length - num, 1));
  };

  get_first_days = (month, year, num) => {
    //month: January = 0 December = 11
    let days = this.get_active_days(month, year);
    return days.slice(0, num);
  };

  date_to_day = (month, year, date) => {
    let d = new Date();
    d.setDate(date);
    d.setMonth(month);
    d.setFullYear(year);

    return day_int_str[d.getDay()];
  };

  get_prev_month_year = (month, year) => {
    month = month - 1;
    if (month < 0) {
      month = 11;
      year = year - 1;
    }
    return { prev_month: month, prev_year: year };
  };

  get_next_month_year = (month, year) => {
    month = month + 1;
    if (month > 11) {
      month = 0;
      year = year + 1;
    }
    return { next_month: month, next_year: year };
  };

  get_calendar_days = (month, year) => {
    let d = new Date();
    d.setDate(1);
    d.setMonth(month);
    d.setFullYear(year);
    //Day is 0 = Monday 6 = Sunday
    let day = d.getDay();
    let days = this.get_active_days(month, year);
    let diff = total_days - (day + days.length);

    let leading_day_num = day;
    let last_day_num = diff;

    let { prev_month, prev_year } = this.get_prev_month_year(month, year);
    let { next_month, next_year } = this.get_next_month_year(month, year);

    let leading_days = this.get_last_days(
      prev_month,
      prev_year,
      leading_day_num
    );
    let trailing_days = this.get_first_days(
      next_month,
      next_year,
      last_day_num
    );

    let calendar_days = [...leading_days, ...days, ...trailing_days];
    return calendar_days;
  };

  get_prev_month = (month, year) => {
    let d = new Date();
    d.setFullYear(year);
    d.setMonth(month - 1);
    return { month: d.getMonth(), year: d.getFullYear() };
  };
  get_current_month = () => {
    //get the current month and year
    let d = new Date();
    return { month: d.getMonth(), year: d.getFullYear() };
  };
  get_next_month = (month, year) => {
    let d = new Date();
    d.setFullYear(year);
    d.setMonth(month + 1);
    return { month: d.getMonth(), year: d.getFullYear() };
  };

  get_prev_calendar = (month, year) => {
    let d = this.get_prev_month(month, year);
    return this.get_calendar_days(d.month, d.year);
  };
  get_current_calendar = () => {
    let d = this.get_current_month();
    return this.get_calendar_days(d.month, d.year);
  };

  get_next_calendar = (month, year) => {
    let d = this.get_next_month(month, year);
    return this.get_calendar_days(d.month, d.year);
  };

  get_date_range = (month, date, year) => {
    let start = new Date();
    start.setFullYear(year);
    start.setMonth(month);
    start.setDate(date);
    start.setHours(0, 0, 0);

    let end = new Date();
    end.setFullYear(year);
    end.setMonth(month);
    end.setDate(date);
    end.setHours(23, 59, 59);

    return { start: start.getTime(), end: end.getTime() };
  };

  is_in_range = (timestamp, start, end) => {
    if (timestamp >= start && timestamp <= end) {
      return true;
    } else {
      return false;
    }
  };

  get_week_str_3 = (timestamp) => {
    const date = new Date(timestamp);
    return day_int_str_3[date.getDay()];
  };

  get_week_str_full = (timestamp) => {
    const date = new Date(timestamp);
    return day_int_str[date.getDay()];
  };

  get_calendar_items_days = (month, year) => {
    const inactive = CalendarHelper.get_inactive_days(month, year);
    const active = CalendarHelper.get_active_days(month, year);
    const { leading, trailing } = inactive;

    const leading_obj = leading.map((item) => {
      const range = CalendarHelper.get_date_range(month - 1, item, year);
      return {
        day: item,
        is_active: false,
        timestamp: range.start,
        range: range,
        is_leading: true,
        is_trailing: false,
        month: month - 1,
        year: year,
        day_str: this.get_week_str_3(range.start),
        day_str_full: this.get_week_str_full(range.start),
      };
    });
    const trailing_obj = trailing.map((item) => {
      const range = CalendarHelper.get_date_range(month + 1, item, year);
      return {
        day: item,
        is_active: false,
        timestamp: range.start,
        range: range,
        is_leading: false,
        is_trailing: true,
        month: month + 1,
        year: year,
        day_str: this.get_week_str_3(range.start),
        day_str_full: this.get_week_str_full(range.start),
      };
    });

    const active_obj = active.map((item) => {
      const range = CalendarHelper.get_date_range(month, item, year);
      return {
        day: item,
        is_active: true,
        timestamp: range.start,
        range: range,
        is_leading: false,
        is_trailing: false,
        month: month,
        year: year,
        day_str: this.get_week_str_3(range.start),
        day_str_full: this.get_week_str_full(range.start),
      };
    });

    const calendar_days = [...leading_obj, ...active_obj, ...trailing_obj];

    return calendar_days;
  };

  get_weekly_days = (month, year) => {
    const calendar_days = this.get_calendar_items_days(month, year);

    let i,
      j,
      temporary,
      chunk = 7;

    let all_week = [];
    for (i = 0, j = calendar_days.length; i < j; i += chunk) {
      temporary = calendar_days.slice(i, i + chunk);
      // do whatever
      all_week.push(temporary);
    }

    return all_week;
  };

  get_is_today = (start) => {
    const curr = new Date();
    const today_range = this.get_date_range(
      curr.getMonth(),
      curr.getDate(),
      curr.getFullYear()
    );

    const is_today = this.is_in_range(
      start + 1000,
      today_range.start,
      today_range.end
    );

    return is_today;
  };
}

const CalendarHelper = new Helper();
export default CalendarHelper;
