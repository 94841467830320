import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker"; // autoPopulate
import InputForm from "../_components/InputForm";
import MainLayout from "../_components/MainLayout";
import Layout from "../_components/Layout";
import "./css.css";

const context = "merchant";

export default class EcommerceViewProduct extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Products",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
      item: {
        _id: "",
        name: "",
        sku: "samp sku",
        category: "",
        price: "",
        sale_price: "",
        mwell_service: "",
        stock: 0,
        quantity: 0,
        limit: 0,
        description: "",
        other_information: "",
        sku_link: "",
        imagePath: "",
        imageFilename: "",
        createdAt: "",
        __v: 0,
        status: "",
        merchant: "",
      },

      obj: {
        product: {
          _id: "",
          name: "",
          sku: "",
          category: "",
          price: "",
          sale_price: "",
          mwell_service: "",
          stock: 0,
          quantity: 0,
          limit: 0,
          description: "",
          other_information: "",
          sku_link: "",
          imagePath: "",
          imageFilename: "",
          createdAt: "",
          __v: 0,
          status: "",
          merchant: "",
          updatedAt: "",
        },
        user: [],
        productStats: {
          // totalFinished: 0,
          // totalPurchase: 0,
          // inquiries: 0,
          totalCompletedPurchases: 0,
          totalCustomers: 0,
          totalPaidPurchases: 0,
        },
      },

      // user_obj: [],
      /*** Temp data ***/
      user_obj: [],
      /*** ***/
      all_data: [],

      transactions: [],
      inquiries: [],

      custom_finished_purchases: 0,

      purchasing_customers_id: [],
      total_inquiries: [],
    });
  }
  onCreate = async () => {
    this.load_data();
  };

  get_inquiries = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "admin/inquiries",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        if (this.state.all_data.length === 0) {
          this.setState({
            all_data: data.data,
          });
        }

        this.setState(
          {
            inquiries: data.data,
          },
          () => {
            this.process_product_inquiries();
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  process_product_inquiries = async () => {
    const { inquiries, id } = this.state;

    const total_inquiries = inquiries.filter(
      (item) => item?.product?._id === id
    );
    this.setState({
      total_inquiries,
    });
  };

  get_transactions = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "admin/transactions",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        this.setState(
          {
            transactions: data.data,
          },
          () => {
            this.get_inquiries();
            this.process_product_purchases();
          }
        );
      },
      onFinish: () => {
        //this.hide_loading();
      },
    });
  };

  process_product_purchases = async () => {
    const { transactions, id } = this.state;

    const finished_transactions = transactions.filter((item) => {
      if (item.product?._id === id) {
        return true;
      } else {
        return false;
      }
    });

    transactions.forEach((item) => {
      if (
        !this.state.purchasing_customers_id.includes(item.user?._id) &&
        item.product?._id === id
      ) {
        this.setState({
          purchasing_customers_id: [
            ...this.state.purchasing_customers_id,
            item.user?._id,
          ],
        });
      }
    });

    this.setState({
      custom_finished_purchases: finished_transactions.length,
    });
  };

  load_data = async () => {
    const data = mem.get("view_product");
    if (data) {
      const product = JSON.parse(data);

      UI.set_input_value("quantity", product.quantity ?? 0);
      UI.set_select_value("status", product.status ?? "");

      this.setState(
        {
          id: product._id,
        },
        () => {
          this.get_product();
        }
      );
      const image = await UI.download_image(product.imagePath, "ecommerce");
      this.setState(
        {
          imagePath: product.imagePath,
          image,
          item: product,
        },
        () => {
          this.get_transactions();
        }
      );
    }
  };

  get_product = async (params = "this-month") => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: `admin/products/${this.state.id}?duration=${params}`,
      params: {},
      isMultiPart: true,
      onFail: async (err) => {
        // const data = err.data;
      },
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.customers(data.user);
        let new_data = data.user;

        this.setState(
          {
            obj: data,
            user_obj: new_data,
            all_data: new_data,
          },
          () => {
            this.reload_table("transaction", new_data, "user_obj");
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  customers = async (data) => {
    UI.set_images(data, "imagePath", "customer", "inquiries", "ecommerce");
  };

  render() {
    const _this = this;
    const {
      // height,
      width,
      // isMobile,
      image,
      item,
      obj,
      // custom_finished_purchases,
      // purchasing_customers_id,
      // total_inquiries,
      user_obj,
    } = _this.state;

    const {
      productStats,
      // user
    } = obj;
    const { totalCompletedPurchases, totalCustomers, totalPaidPurchases } =
      productStats;

    console.log(user_obj);
    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                borderRightWidth: width >= 1250 ? 2 : 0,
                borderBottomWidth: width < 1250 ? 2 : 0,
                paddingRight: width < 1250 ? 0 : 20,
                marginRight: width < 1250 ? 0 : 20,
                paddingBottom: 10,
                marginBottom: width < 1250 ? 10 : 0,
              }}
            >
              <View style={styles.fl_ai}>
                <img
                  style={{ height: 100, width: 100, borderRadius: 5 }}
                  onError={(e) => {
                    if (e) {
                      this.setState({ image: UI.PRODUCT_BOX });
                    }
                  }}
                  src={image}
                  alt="Product"
                />

                {UI.box(width < 980 ? 5 : 10)}

                <View>
                  <Text style={styles.patient_name}>{item.name}</Text>
                  <Text
                    style={{
                      ...styles.patient_name,
                      color: UI.colors.primary,
                      fontSize: 14,
                    }}
                  >
                    Product SKU {UI.change_text_color(item.sku)}
                  </Text>
                  <Text
                    style={{
                      ...styles.patient_name,
                      color: UI.colors.primary,
                      fontSize: 14,
                    }}
                  >
                    Product Category {UI.change_text_color(item.category)}
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <UI.Row _this={_this}>
                <UI.Row breakpoint={970} _this={_this} style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.PAYMENT_METHOD}
                    name={"Product Price/Sale Price"}
                    value={UI.pad(item.price ?? 0, 3)}
                    width={200}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"Product Status"}
                    value={UI.get_active_text(item)}
                  />
                  <UserDetail />
                </UI.Row>
              </UI.Row>
              <UI.Row _this={_this}>
                <UI.Row breakpoint={840} _this={_this} style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.COMMERCE_INVENTORY}
                    name={"Product Inventory"}
                    value={UI.pad(item.quantity ?? 0, 3)}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"Date Created"}
                    width={120}
                    value={UI.get_date_string_by_date(item.createdAt)}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"Date Updated"}
                    width={120}
                    value={UI.get_date_string_by_date(item.createdAt)} // TO-DO: endpoint for date updated
                  />
                </UI.Row>
              </UI.Row>
              {/* {UI.box(10)}
              <Text style={styles.details_name}>Product Description</Text>

              <Text
                style={{
                  ...styles.patient_name,
                  color: "#454545",
                  fontSize: 14,
                }}
              >
                {item.description}
              </Text> */}
            </View>
          </UI.Row>
        </View>

        <UI.Row
          _this={_this}
          breakpoint={1080}
          style={{ width: "100%", flexDirection: "row", marginTop: 20 }}
        >
          <View
            style={{
              ...styles.main_box_content,
              padding: 20,
              flex: 1,
              maxWidth: width > 1080 ? 340 : null,
            }}
          >
            <UI.Row _this={_this}>
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Text style={styles.box_header_text}>Product Stats</Text>
                <Layout.SelectPicker
                  _this={_this}
                  onChange={(e) => {
                    this.get_product(e);
                  }}
                >
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="last-week">Last Week</option>
                  <option value="this-week">This Week</option>
                  <option value="last-month">Last Month</option>
                  <option value="this-month">This Month</option>
                </Layout.SelectPicker>
              </View>
            </UI.Row>

            <ProductStat
              image={UI.IC_DASH_1}
              main={UI.pad(totalCompletedPurchases ?? 0, 3)}
              color={UI.colors.primary}
              text={"Total paid purchases"}
            />

            <ProductStat
              image={UI.IC_DASH_11}
              main={UI.pad(totalCustomers ?? 0, 3)}
              color={"#00EB2D"}
              text={"Total completed purchases"}
            />

            <ProductStat
              image={UI.IC_DASH_6}
              main={UI.pad(totalPaidPurchases ?? 0, 3)}
              color={"#00EBBE"}
              text={"Total customers"}
            />
          </View>

          {UI.box(20)}
          <View
            style={{
              ...styles.main_box_content,
              padding: 0,
              flex: 1,
              alignSelf: width <= 1080 ? "stretch" : "flex-start",
            }}
          >
            <UI.Row
              style={styles.main_container_header}
              breakpoint={1200}
              _this={_this}
            >
              <View
                style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
              >
                <Text style={styles.entry_header}>Only Show</Text>
                {UI.box(10)}
                <View>
                  <EntryPicker
                    data_name={"user_obj"}
                    table_name={"transaction"}
                    _this={_this}
                  />
                </View>
              </View>

              <InputForm
                onChange={(e) => {
                  UI.search_table(
                    "transaction",
                    e,
                    this.state.all_data,
                    "user_obj"
                  );
                }}
                _this={_this}
                title={""}
                placeholder={"Enter Keyword"}
                state_name={"search"}
                right={
                  <TouchableOpacity style={styles.search_btn}>
                    <Image
                      height={17}
                      width={17}
                      tintColor={"transparent"}
                      source={UI.SEARCH}
                    />
                  </TouchableOpacity>
                }
                maxLength={UI.Constants.limits.keyword}
                disableLength={true}
              />
            </UI.Row>
            {/* {console.log("view item?:",item)} */}
            <CustomDatatable
              state_name={"transaction"}
              _this={_this}
              columns={[
                // TO-DO: match real data with api response
                { "Order ID": "id" },
                { "Purchase Date": "purchased_date" },
                { "Customer Name": "customer_name" },
                { "Payment Status": "payment_status" },
                { "Order Status": "order_status" },
                { Action: "action" },
              ]}
              data={user_obj.map((item) => {
                let date_added = UI.timestampToDate(
                  new Date(item.createdAt).getTime()
                );

                /* mock data */
                const payStatus = [
                  "Paid",
                  "Complete",
                  "Completed",
                  "Pending",
                  "Refund",
                  "Unpaid",
                ];
                const ordStatus = ["Confirmed", "Completed", "Cancelled"];
                item["payment_status"] = {
                  status:
                    payStatus[Math.floor(Math.random() * payStatus.length)],
                };
                item["order_status"] = {
                  status:
                    ordStatus[Math.floor(Math.random() * ordStatus.length)],
                };
                /* */

                // const email = item.user?.email ?? "-";
                // const phone = item.user?.phone ?? "-";

                const customer_image =
                  this.state["customer" + item._id] ?? UI.TRANSACTION_USER;
                return {
                  id: item._id,
                  // email: email,
                  // contact_number: phone,
                  customer_name: (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <img
                        style={{ height: 35, width: 35 }}
                        src={customer_image}
                        alt="Customer"
                      />
                      <Text style={{ marginLeft: 10 }}>
                        {(item.user?.firstName ?? "") +
                          " " +
                          (item.user?.lastName ?? "")}
                      </Text>
                    </View>
                  ),
                  purchased_date:
                    date_added.month +
                    "/" +
                    date_added.day +
                    "/" +
                    date_added.year,
                  payment_status: (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Text style={{ marginLeft: 10 }}>
                        {UI.get_transaction_status_text(item.payment_status)}
                      </Text>
                    </View>
                  ),
                  order_status: (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Text style={{ marginLeft: 10 }}>
                        {UI.get_transaction_status_text(item.order_status)}
                      </Text>
                    </View>
                  ),
                  purchased_with: item.method,
                  action: (
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        onClick={() => {
                          mem.save(
                            "view_transaction_details",
                            JSON.stringify(item)
                          );
                          UI.goTo("/merchant/view-transaction-details");
                        }}
                      >
                        <Image
                          height={32}
                          width={32}
                          tintColor={"transparent"}
                          source={UI.VIEW_BTN}
                        />
                      </TouchableOpacity>
                    </View>
                  ),
                };
              })}
              on_page={(page, callback, table_name) => {
                UI.on_page(
                  this.state.all_data,
                  callback,
                  table_name,
                  page,
                  "user_obj"
                );
              }}
            />

            <View
              style={{
                ...styles.no_info_container,
                display: user_obj.length > 0 ? "none" : "flex",
              }}
            >
              <UI.Row
                breakpoint={820}
                style={{ maxWidth: 360, alignItems: "center" }}
                _this={_this}
              >
                <Image
                  height={100}
                  width={100}
                  tintColor={"transparent"}
                  source={UI.NO_INFO}
                />
                {UI.box(10)}
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#BFBFBF",
                    textAlign: width > 820 ? "left" : "center",
                    marginLeft: width > 820 ? 20 : 0,
                  }}
                >
                  NO DATA AVAILABLE YET
                </Text>
              </UI.Row>
            </View>
          </View>
        </UI.Row>
      </MainLayout>
    );
  }
}

const ProductStat = (
  props = { image: null, main: "", color: "", text: "" }
) => {
  return (
    <View style={styles.product_stat_item}>
      <Image
        height={70}
        width={70}
        tintColor={"transparent"}
        source={props.image}
      />
      <Text style={{ ...styles.product_stats_text, color: props.color }}>
        {props.main}
      </Text>

      <Text
        style={{
          ...styles.patient_name,
          color: "#535353",
          fontSize: 14,
          textAlign: "right",
          maxWidth: 130,
        }}
      >
        {props.text}
      </Text>
    </View>
  );
};

const UserDetail = (props = { icon: "", name: "", value: "", width: null }) => {
  const myWidth = props.width ? props.width : 140;
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
        flex: 1,
      }}
    >
      <View style={{ ...styles.fl_ai, width: myWidth }}>
        <View style={styles.detail_icon}>
          <Image
            height={16}
            width={16}
            tintColor={UI.colors.primary}
            source={props.icon}
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  product_stat_item: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  product_stats_text: {
    color: UI.colors.primary,
    fontWeight: "bold",
    fontSize: 32,
    marginLeft: 15,
    flex: 1,
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 13,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
