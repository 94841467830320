import React from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';

import DigitalESignatureCanvas from './DigitalESignatureCanvas';
import SelectFile from './SelectFile';

const DigitalESignatureTabs = ({ _this }) => {

  return (
    <div className='p-8'>
      <StyledTabs 
        defaultActiveKey="1"
        centered
      >
        <StyledTabPane tab="Draw" key="1">
          <DigitalESignatureCanvas _this={_this} />
        </StyledTabPane>
        <StyledTabPane tab="Upload" key="2">
          <SelectFile _this={_this} />
        </StyledTabPane>
      </StyledTabs>
    </div>
  )
};

const StyledTabs = styled(Tabs)`
  width: 500px;
  font-family: Poppins !important;
  color: #737373;

  .ant-tabs-nav-list {
    border-bottom: 1px solid #D0D0D0;
  }

  .ant-tabs-tab {
    font-size: 16px;
    padding: 16px 70px;
    font-weight: bold;
    color: #737373;

    &:hover {
      color: inherit !important;
    }
  }

  .ant-tabs-tab-active {

    .ant-tabs-tab-btn {
      color: #02488D;
    }
  }

  .ant-tabs-ink-bar {
    border-radius: 20px 20px 0px 0px;
    background: #02488D;
    height: 5px !important;
  }
`;

const StyledTabPane = styled(Tabs.TabPane)`
`;

export default DigitalESignatureTabs;