import React from "react";
import DataTable from "../../../../../ComponentsFC/DataTable";
import { Row } from "antd";
import styled from "styled-components";

const ImmunizationHistory = ({ immunization_history }) => {
  const tableColumnHeader = [
    {
      title: "Vaccine Name",
      dataIndex: "vaccine",
      key: "vaccine",
      render: (data) => data || "n/a",
    },
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
      render: (data) => data || "n/a",
    },
    {
      title: "Series",
      dataIndex: "series",
      key: "series",
      render: (data) => data || "n/a",
    },
    {
      title: "Site Given",
      dataIndex: "site_given",
      key: "site_given",
      render: (data) => data || "n/a",
    },
    {
      title: "Route",
      dataIndex: "route",
      key: "route",
      render: (data) => data || "n/a",
    },
    {
      title: "Next Dose On",
      dataIndex: "next_dose_on",
      key: "next_dose_on",
      render: (data) => data || "n/a",
    },
  ];
  return (
    <TableBox>
      <DataTable
        tableColumnHeader={tableColumnHeader}
        dataSource={immunization_history}
        defaultPageSize={5}
        pageSizeOptions={["5", "10", "30"]}
        hideTotalSummaryOnSinglePage={immunization_history.length <= 5}
        hidePaginationOnSinglePage={immunization_history.length <= 5}
      />
    </TableBox>
  );
};

export default ImmunizationHistory;

const TableBox = styled(Row)`
  position: relative;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  min-width: 100%;
  .ant-table-cell {
    font-size: 20px;
  }
`;
