import React from "react";
import { StyleSheet, Text, View, Font } from "react-pdf-renderer-v2";
import moment from "moment";
import EDocsWrapperComponent from "./EDocsWrapperComponent";

const DoctorsOrderModal = ({
  appointment,
  encounterNotesData,
  onHideModal,
}) => {
  return (
    <EDocsWrapperComponent
      title="Doctor's Order"
      appointment={appointment}
      onHideModal={onHideModal}
    >
      <View style={styles.body}>
        <View style={styles.content}>
          <Text style={styles.subtitle}>Assessment:</Text>
          <Text style={styles.text}>
            {encounterNotesData.consultations[0].assessment ?? ""}
          </Text>
        </View>

        <View style={styles.content}>
          <Text style={styles.subtitle}>Exam Name:</Text>
          {encounterNotesData.orders.map((order) => (
            <Text style={{ ...styles.text, marginTop: 5 }}>
              {order.diagnosticsItem}
            </Text>
          ))}
        </View>

        <View
          style={{
            ...styles.content,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Text style={{ ...styles.subtitle }}>Return for follow-up on: </Text>
          <Text style={{ ...styles.text }}>
            {encounterNotesData.consultations[0]?.followupSchedule
              ? moment(
                  encounterNotesData.consultations[0]?.followupSchedule
                ).format("MMMM D, YYYY")
              : ""}
          </Text>
        </View>
      </View>
    </EDocsWrapperComponent>
  );
};

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {},
  content: {
    marginTop: 20,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: "bold",
  },
  text: {
    fontSize: 14,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    borderTop: "1px solid black",
    paddingTop: 10,
  },
});

export default DoctorsOrderModal;
