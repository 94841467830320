import React, { useCallback, useState, useEffect } from "react";
import DataTable from "../../ComponentsFC/DataTable";
import UI from "../../shared/Components/UI/js";
import styled from "styled-components";
import { Row, Input, Select, Typography } from "antd";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
// import ServicePatientsApi from "../../api_services/patients";
import  { mem } from "../../shared/custom-react-native";
import ServiceUsergroupsApi from "../../api_services/usergroups";

const AdminCmsLguPartnersDoctorsTable = ({ _this }) => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(5);
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const getMemberList = useCallback( async () => {

    const data = mem.get("edit_lgu_partner");
    const user_group = JSON.parse(data);
    const id = user_group?.userGroup

    let params = {};
    if (currentPageNum) {
      params.page = currentPageNum;
      params.limit = tableEntries;
    }

    if (searchQuery) {
      params.name = searchQuery;
    }

    try {
      setIsLoading(true);
      ServiceUsergroupsApi.getUserGroupMembersByUserGroupId(id, params).then( res => {
        setIsLoading(false);
        if (res.status === 200) {
          let data = res.data;
          console.log(res);
          setTotalRecords(res.results.total);
          setTableData(data);
        }
      });
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }, [currentPageNum, tableEntries, searchQuery])

//   const getPatients = useCallback(async () => {
//     try {
//       setIsLoading(true);

//       let params = {};

//       if (searchQuery) {
//         params.fullname = searchQuery;
//       }

//       if (currentPageNum) {
//         params.page = currentPageNum;
//         params.limit = tableEntries;
//       }

//       ServicePatientsApi.getPatients(params).then( res => {
//         setIsLoading(false);
//         if (res.status === 200) {
//           let data = res.data;
//           console.log(data);
//           setTotalRecords(res.results.total);
//           setTableData(data);
//         }
//       });

//     } catch (err) {
//       // Handle Errors Here
//       console.error(err);
//       if (err.response) {
//         let statusCode = err.response.data.statusCode;
//         let errorMsg = err.response.data.message;
//         if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
//         if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
//       }
//     }
//   }, [currentPageNum, tableEntries, searchQuery]);

  useEffect(() => {
    getMemberList();
  }, [getMemberList]);

  const handlePaginateTable = (pageNum) => {
    // console.log("Currently Selected Page # of Antd Table: ", pageNum);
    setCurrentPageNum(pageNum);
  };

  const tableColumnHeader = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (key, item) => UI.get_fullname(item.firstName, item.middleName, item.lastName) ?? " ",
      width: 200,
    },
    {
      title: "Specialty",
      dataIndex: "specializations",
      key: "specializations",
      render: (fieldSpecialties, item) => {
        return (
          <span className="">{item.specializations && UI.DataHelper.carespan.get_specialties(item.specializations)}</span>
        )
      },
      width: 200,
    },
    
    // {
    //   title: "Action",
    //   dataIndex: "relationshipToUser",
    //   key: "relationshipToUser",
    //   render: () => {
    //     return (
    //       <View style={{ flexDirection: "row", gap: 5 }}>
    //         <TouchableOpacity onClick={() => console.log('object')}>
    //           <Image
    //             height={30}
    //             width={30}
    //             tintColor={"transparent"}
    //             source={UI.DELETE_BTN}
    //           />
    //         </TouchableOpacity>
    //       </View>
    //     )
    //   },
    //   width: 200,
    // },
  ];

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };


  return (
    <>
      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="5"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="5">5 Entries</Select.Option>
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>
          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getMemberList}
            style={{ width: 200 }}
          />
          {/* <StyledButton type="primary" onClick={() => UI.goTo('/admin/cms-lgu-partners-doctors-add')}>
            Add Doctor
          </StyledButton> */}
        </Toolbar>

        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={tableData}
          defaultPageSize={30}
          pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />

      </TableBox>
    </>
  );
};

export default AdminCmsLguPartnersDoctorsTable;

// CSS Styled Components ----------------------

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

// const StyledButton = styled(Button)`
//   height: 50px;
//   border-radius: 12px;
//   border: none;
//   background-color: #0e4d94;
//   font-family: Poppins;
//   font-weight: 500;
//   font-size: 0.9375rem; // 15px
//   line-height: 1.4375rem; // 23px
//   margin-left: 20px;
//   padding-left: 20px;
//   padding-right: 20px;
//   &:hover {
//     background-color: #43c8ff;
//   }
// `;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;
