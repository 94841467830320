import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import { Text, View } from "../../shared/custom-react-native";
import Layout, { PreviewFile } from "../_components/Layout";
import { AgreementConfirmation } from "../_components/Modal";
import MOAContent from "./Modal/MOAContent";
import MarketingConsent from "./MarketingConsent";
import MOAContentPDF from "./PDF/MOAContent";
import { pdf } from "react-pdf-renderer-v2";
export default class MOA extends Component {
  render() {
    const _this = this.props._this;
    const { 
      // height, 
      width, 
      // isMobile,
      //  file_moa_path,
        file_mpc 
      } = _this.state;
    const row_width = width > 1000 ? width - 400 : "100%";

    return (
      <UI.Column
        style={{ marginBottom: 10 }}
        _this={_this}
        breakpoint={360}
        breakpoint_2={720}
      >
        <UI.Row
          style={{
            width: row_width,
            justifyContent: "space-between",
            marginTop: 40,
          }}
          _this={_this}
          breakpoint={1110}
        >
          {UI.box(5)}
          <View style={{ flex: 1 }}>
            <Layout.FilePicker
              _this={_this}
              title={"Upload Medical Practice Document"}
              // isRequired={true}
              text={"Drop Your File Here"}
              state_name={"mpc"}
              onChange={(file) => {
                _this.auth_upload("mpc", file);
              }}
              allowed_files={["jpg", "jpeg", "pdf", "png"]}
              display_type={"filename_size"}
            />
            {UI.box(10)}
            <Text
              style={{
                width: "90%",
                fontSize: 14,
                fontWeight: "bold",
                color: "rgb(191, 191, 191)",
              }}
            >
              Any certificate that shows additional level of learning received
              like being a Diplomate or Fellow.
            </Text>
            <PreviewFile _this={_this} {...file_mpc} />
          </View>
          {UI.box(60)}
          <View style={{}}>
            <Text
              style={{
                color: UI.colors.primary,
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              Memorandum of Agreement{" "}
              <span style={{ color: "rgb(255, 0, 0)", opacity: 1 }}>*</span>
            </Text>
            <Text
              style={{ color: "#333333", fontSize: 14, fontWeight: "bold" }}
            >
              Please sign the Memorandum of Agreement.
            </Text>
            {UI.box(20)}

            <View>
              <Layout.Btn
                color={UI.colors.primary}
                onClick={() => {
                  _this.show_modal(
                    <AgreementConfirmation
                      isConfirm={_this.state.confirmMOA}
                      setIsConfirm={(cb) => _this.setState({ confirmMOA: cb })}
                      title="Memorandum Of Agreement"
                      checkboxTitle="By clicking this box, I agree to the terms and conditions of the MOA and to displaying my e-signature on it."
                      onOk={{
                        text: "Proceed",
                        method: () => {
                          pdf(<MOAContentPDF _this={_this} />)
                            .toBlob()
                            .then((response) => {
                              return response;
                            })
                            .then((myBlob) => {
                              var file = new File([myBlob], "MOA-Template.pdf");

                              _this.auth_upload("moa_path", file);
                            });

                          _this.hide_modal();
                        },
                      }}
                      onDismiss={() => {
                        _this.hide_modal();
                      }}
                    >
                      <MOAContent _this={_this} />
                    </AgreementConfirmation>
                  );
                }}
                width={200}
                text={"View and sign MOA"}
              />
              {_this.state?.form_errors?.includes("moa_path") && (
                <div className="flex mt-1 items-center">
                  <img
                    src="/img/warning.png"
                    style={{ height: "15px", width: "15px" }}
                    alt="warning"
                  />
                  <span
                    className="ml-1 text-xs"
                    style={{ color: "rgb(236, 123, 111)" }}
                  >
                    {
                      _this.state.form_messages.filter((f) => {
                        const key = Object.keys(f)[0];
                        return key === "moa_path";
                      })[0]["moa_path"]
                    }
                  </span>
                </div>
              )}
              {UI.box(20)}
            </View>
          </View>
          {UI.box(60)}
          <MarketingConsent _this={_this} />
        </UI.Row>
      </UI.Column>
    );
  }
}
