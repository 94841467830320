import { Row, Select, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components';
import DataTable from '../../../../ComponentsFC/DataTable';
import DropdownIcon from '../../../../icons/Dropdown';
import UI from '../../../../shared/Components/UI/js';
import TouchableOpacity, { 
  // Image, 
  Text
} from '../../../../shared/custom-react-native';
import APIConstants from '../../../../shared/modules/APIConstants';
// import { ImageViewer } from '../../../_components/ImageViewerModal';
const Constants = new APIConstants();

const PatientUploadFilesTable = ({ _this, files }) => {
	const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableEntries] = useState(5);
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const getSubscriptionViewBilling = useCallback( async () => {

		let params = {};
    if (currentPageNum) {
      params.page = currentPageNum;
      params.limit = 5;
    }
		setIsLoading(false)
		setTableData(files);
		

		return
    // try {
    //   setIsLoading(true);
    //   ServiceUsergroupsApi.getUserGroupMembersByUserGroupId().then( res => {
    //     setIsLoading(false);
    //     if (res.status === 200) {
    //       let data = res.data;
    //       console.log(res);
    //       setTotalRecords(res.results.total);
    //       setTableData(data);
    //     }
    //   });
    // } catch (err) {
    //   // Handle Errors Here
    //   console.error(err);
    //   if (err.response) {
    //     let statusCode = err.response.data.statusCode;
    //     let errorMsg = err.response.data.message;
    //     if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
    //     if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
    //   }
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNum])

  useEffect(() => {
    getSubscriptionViewBilling();
  }, [getSubscriptionViewBilling]);

  const handlePaginateTable = (pageNum) => {
    setCurrentPageNum(pageNum);
  };

  const tableColumnHeader = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt, item) => {
        // const fileName = item?.filename;
        // const fileExtension = fileName.split('.').pop().toLowerCase();
        // const allowViewing = fileExtension.match(/^(jpg|jpeg|png)$/);
        return (
          <div className='flex gap-3' style={{ color: UI.colors.header}}>
            { _this.state.appointment?.start ? UI.momentPHTimezone(_this.state.appointment?.start).format('MM/DD/YYYY') : "--" }
          </div>
        )
      },
      width: 200,
    },
    {
      title: "Filename",
      dataIndex: "description",
      key: "description",
      render: (description, item) => {
        // const fileName = item?.filename;
        // const fileExtension = fileName.split('.').pop().toLowerCase();
        // const allowViewing = fileExtension.match(/^(jpg|jpeg|png)$/);
        return (
          <div className='flex gap-3'>
            {/* <Image
              height={28}
              width={28}
              tintColor={UI.colors.lightGray}
              source={ allowViewing ? UI.IMG_ICON : UI.FILE_2}
            /> */}
            {item?.filename ?? "--"}
          </div>
        )
      },
      width: 200,
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      render: (file, item) => {
        const baseContent = `${Constants.STORAGE_BASE_URL}?path=${item.path}&container=users`

        return (
          <TouchableOpacity
            onClick={() => {
              if (item.path.includes("jpg") || item.path.includes("jpeg") || item.path.includes("png")) {
                return window.open(baseContent, "_blank");
              } else {
                _this.downloadPatientFile(item.path, item.filename)
              }
            }}
            style={{ textDecoration: "underline", textDecorationColor: UI.colors.primary }}
          >
            <Text style={{color:UI.colors.primary}} >{item.filename ?? "--"}</Text>
          </TouchableOpacity>
        )
        // const fileName = item?.filename;
        // const fileExtension = fileName.split('.').pop().toLowerCase();
        // const allowViewing = fileExtension.match(/^(jpg|jpeg|png)$/);
        // const baseContent = `${Constants.STORAGE_BASE_URL}?path=${item.path}&container=users`;
        // return (
        //   <div className='flex gap-3 items-center'>
        //     <div style={{cursor: "pointer"}} onClick={() => _this.downloadPatientFile(item.path, item.filename)}> 
        //       <Image
        //         height={28}
        //         width={28}
        //         tintColor={UI.colors.lightGray}
        //         source={UI.IC_DOWNLOAD}
        //       />
        //     </div>
        //     {
        //       allowViewing &&
        //       <div style={{cursor: "pointer"}} 
        //         onClick={() => {
        //           _this.show_image_viewer_modal(
        //             <ImageViewer
        //               title={fileName}
        //               content={baseContent}
        //               onCancel={{
        //                 method: () => {
        //                   _this.hide_image_viewer_modal();
        //                 },
        //               }}
        //             />
        //           )
        //         }}
        //       >
        //         <Image
        //           height={28}
        //           width={28}
        //           tintColor={UI.colors.lightGray}
        //           source={UI.IC_VIEW}
        //         />
        //       </div>
        //     }
        //   </div>
        // )
      },
      width: 200,
    },
  ];

  return (
    <div className=''>
			<TableBox>
				<Toolbar>
					<Text
						style={{
							fontWeight: "bold",
							fontSize: '15px',
							color: "#0E4D94",
						}}
					>
						Patient Uploaded Files
					</Text>

					{UI.box(10)}

          <div className='flex items-center'>
            <FieldLabel>Only Show</FieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              defaultValue="5"
              style={{
                width: 160,
              }}
              suffixIcon={<DropdownIcon />}
              // onChange={handleChangeNumOfEntries}
            >
              <Select.Option value="5">5 Entries</Select.Option>
              {/* <Select.Option value="10">10 Entries</Select.Option> */}
              {/* <Select.Option value="30">30 Entries</Select.Option> */}
            </StyledSelect>
          </div>
				</Toolbar>

					<DataTable
						tableColumnHeader={tableColumnHeader}
						dataSource={tableData}
						defaultPageSize={5}
						pageSizeOptions={["5", "10", "30"]}
						tableEntries={tableEntries}
						isBackendPaginated={true}
						// totalRecords={totalRecords}
						onPaginate={handlePaginateTable}
						loading={isLoading}
						scroll={{
								x: 600,
						}}
					/> 
			</TableBox>
    </div>
  )
}

export default PatientUploadFilesTable

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 20px 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  /* min-width: 50%; */
  /* height: 100%; */
  flex:1;
  margin-bottom: 25px;
`;

const Toolbar = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;