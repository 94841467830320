import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import MainLayout from "../_components/MainLayout";
import "./css.css";

const context = "admin";

export default class AdminViewHospitalAdmin extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Hospital Admins",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],

      is_resizing: false,
      context: context,

      item: {
        _id: "",
        name: "",
        imagePath: "",
        email: "",
        phone: "",
        hospital: {
          _id: "",
          name: "",
        },
        status: "",
        createdAt: "",
        __v: 0,
      },

      user_image: UI.DOCTOR_PROFILE,
    });
  }

  resize_timeout = setTimeout(() => {}, 10);

  onCreate = () => {
    this.add_resize_function("admin_view_hospital", () => {
      this.setState({
        is_resizing: true,
      });
      clearTimeout(this.resize_timeout);
      this.resize_timeout = setTimeout(() => {
        this.setState({
          is_resizing: false,
        });
      }, 100);
    });
    this.get_data();
  };

  get_data = async (params) => {
    const data = mem.get("hospital_admin");
    if (data) {
      const record = JSON.parse(data);

      this.show_loading();

      UI.webRequest({
        method: "get",
        url: "admin/hospital-admins/" + record._id + "/affiliates",
        params: params,
        onFail: (err) => {},
        onSuccess: (response) => {
          const data = UI.extract_data(response);

          const new_data = data.map((item) => {
            item.fullname = UI.replaceAll(
              UI.DataHelper.carespan.get_name(item.name),
              "  ",
              " "
            );
            return item;
          });

          console.log(new_data);

          this.setState(
            {
              all_data: new_data,
              data: new_data,
            },
            () => {
              this.reload_table("affiliated");
            }
          );
        },
        onFinish: async () => {
          this.load_data();
        },
      });
    } else {
      UI.goBack();
    }
  };

  load_data = async () => {
    const data = mem.get("hospital_admin");
    if (data) {
      const record = JSON.parse(data);
      console.log(record);

      const image = await UI.download_image(record.imagePath, "admin");

      this.setState({
        item: record,
        user_image: image,
      });
    }

    this.hide_loading();
  };

  render() {
    const _this = this;
    const { 
      // height, 
      width, 
      // isMobile,
       is_resizing, user_image, item } =
      _this.state;
    let date = "";
    if (item.createdAt) {
      const date_added = UI.timestampToDate(new Date(item.createdAt).getTime());

      date = date_added.month + "/" + date_added.day + "/" + date_added.year;
    }
    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                borderRightWidth: width >= 1250 ? 2 : 0,
                borderBottomWidth: width < 1250 ? 2 : 0,
                paddingRight: width < 1250 ? 0 : 20,
                marginRight: width < 1250 ? 0 : 20,
                paddingBottom: 10,
                marginBottom: width < 1250 ? 10 : 0,
              }}
            >
              <View style={styles.fl_ai}>
                <img
                  style={{ height: 100, width: 100, borderRadius: 10 }}
                  src={user_image}
                  alt="user"
                />

                {UI.box(width < 980 ? 5 : 10)}

                <View>
                  <Text style={styles.patient_name}>{item.name}</Text>
                  <Text
                    style={{
                      ...styles.patient_name,
                      color: "#454545",
                      fontSize: 14,
                    }}
                  >
                    {item.email}
                  </Text>
                  <Text
                    style={{
                      ...styles.patient_name,
                      color: "#454545",
                      fontSize: 14,
                    }}
                  >
                    {item.phone && "+63" + item.phone}
                  </Text>
                </View>
              </View>

              <View style={styles.date_container}>
                <Image
                  height={30}
                  width={30}
                  tintColor={UI.colors.primary}
                  source={UI.CALENDAR_3}
                />
                <Text style={styles.appointment_text}>
                  Status: {UI.get_active_text(item)}{" "}
                  <Text style={{ color: "#C9C9C9", fontWeight: "normal" }}>
                    (Added {date})
                  </Text>
                </Text>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <UserDetail
                icon={UI.DETAILS_HOSPITAL}
                name={"Hospital Name"}
                value={item.hospital.name}
              />
              {UI.box(10)}
            </View>
          </UI.Row>
        </View>
        {UI.box(20)}

        <View
          style={{
            ...styles.main_content_container,
            padding: 0,
          }}
        >
          <View style={{ padding: 20, paddingBottom: 0 }}>
            <Text style={styles.box_header_text}>Affiliated Doctors</Text>
          </View>

          <UI.Row
            breakpoint={1070}
            _this={_this}
            style={{ width: "100%", padding: 20 }}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"affiliated"} _this={_this} />
              </View>
              <View style={{ flex: 1 }}></View>

              <InputForm
                onChange={(e) => {
                  UI.search_table("affiliated", e, this.state.all_data, "data");
                }}
                is_keyword={true}
                disableLength={true}
                maxLength={UI.Constants.limits.keyword}
                _this={_this}
                title={""}
                placeholder={"Enter Keyword"}
                state_name={"search"}
                right={
                  <TouchableOpacity style={styles.search_btn}>
                    <Image
                      height={17}
                      width={17}
                      tintColor={"transparent"}
                      source={UI.SEARCH}
                    />
                  </TouchableOpacity>
                }
              />
            </View>
          </UI.Row>
          {!is_resizing && (
            <CustomDatatable
              state_name={"affiliated"}
              _this={_this}
              columns={[
                // { ID: "id" },
                { "Doctor Name": "doctor_name" },
                { "Email Address": "email_address" },
                { "Contact Number": "contact_number" },
                { "Date Added": "date_added" },
                // { Status: "status" },
                { Action: "action" },
              ]}
              data={this.state.data.map((item) => {
                const date_added = UI.timestampToDate(
                  new Date(item.createdAt).getTime()
                );
                return {
                  doctor_name: (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      {UI.DataHelper.carespan.get_name(item.name)}
                    </View>
                  ),
                  email_address: item.provider.email,
                  contact_number: UI.DataHelper.carespan.get_phone(
                    item.telecom
                  ),
                  date_added:
                    date_added.month +
                    "/" +
                    date_added.day +
                    "/" +
                    date_added.year,
                  status: <Text style={{ color: "#00C700" }}>Active</Text>,
                  action: (
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        onClick={() => {
                          mem.save("edit_doctor", JSON.stringify(item));
                          UI.goTo("/admin/view-doctor-record");
                          //UI.goTo("/admin/view-doctor-details");
                        }}
                      >
                        <Image
                          height={28}
                          width={28}
                          tintColor={"transparent"}
                          source={UI.VIEW_BTN}
                        />
                      </TouchableOpacity>
                    </View>
                  ),
                };
              })}
              on_page={(page, callback, table_name) => {
                UI.on_page(this.state.all_data, callback, table_name, page);
              }}
            />
          )}
          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const UserDetail = (props = { icon: "", name: "", value: "" }) => {
  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}
    >
      <View style={{ width: 150, ...styles.fl_ai }}>
        <View style={styles.detail_icon}>
          <Image
            height={16}
            width={16}
            tintColor={UI.colors.primary}
            source={props.icon}
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  main_content_container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
  },
  product_stat_item: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  product_stats_text: {
    color: UI.colors.primary,
    fontWeight: "bold",
    fontSize: 32,
    marginLeft: 15,
    flex: 1,
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
