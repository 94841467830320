import Icon from "@ant-design/icons";
import React from "react";

const FieldHeartSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.979"
    height="17.589"
    viewBox="0 0 18.979 17.589"
  >
    <path
      id="blood_type"
      data-name="blood type"
      d="M129.264,201.271a4.707,4.707,0,0,1,4.006,2.012,4.665,4.665,0,0,1,4-2.012c3.06,0,5.489,2.411,5.489,5.916,0,3.968-3.338,7.9-8.484,11.237a2.281,2.281,0,0,1-1,.436,2.305,2.305,0,0,1-1.011-.436c-5.146-3.337-8.484-7.269-8.484-11.237C123.775,203.682,126.213,201.271,129.264,201.271Zm3.838,15.215a.34.34,0,0,0,.168.083.361.361,0,0,0,.157-.083c3.922-2.605,7.176-6.156,7.176-9.318a3.526,3.526,0,0,0-3.532-3.792,3.688,3.688,0,0,0-3.1,1.817c-.278.361-.445.51-.7.51s-.455-.139-.714-.51a3.584,3.584,0,0,0-3.1-1.817,3.52,3.52,0,0,0-3.524,3.792C125.935,210.33,129.18,213.881,133.1,216.486Z"
      transform="translate(-123.775 -201.271)"
      fill="#4fc2ed"
    />
  </svg>
);

const FieldHeartIcon = (props) => {
  return <Icon component={FieldHeartSvg} {...props} />;
};

export default FieldHeartIcon;
