import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  // StyleSheet, 
  View } from "../../shared/custom-react-native";
import "./css.css";
// const context = "admin";

export default class UserRetrieve extends Component {
  constructor(props) {
    super(props);
    this.state = {
      main_component: <View></View>,
    };
  }

  componentDidMount = async () => {
    const width = UI.getWidth();
    const height = UI.getHeight();
    const path = UI.get_param("path");
    const ext = await UI.get_file_extension(path);
    const isVideo = UI.isVideo(ext);
    const file = await UI.download_image(path, "admin");

    if (!isVideo) {
      this.setState({
        main_component: (
          <img
            style={{ height: height, width: width, objectFit: "cover" }}
            src={file}
            alt="cover"
          ></img>
        ),
      });
    } else {
      this.setState({
        main_component: (
          <video
            style={{ objectFit: "contain" }}
            width={width}
            height={height}
            controls
          >
            <source src={file} type="video/mp4" />
            <source src={file} type="video/ogg" />
          </video>
        ),
      });
    }
    document.body.style.backgroundColor = "transparent";
  };

  render() {
    // const _this = this;
    // const { height, width, isMobile } = _this.state;

    return (
      <View
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "transparent",
          justifyContent: "center",
        }}
      >
        {this.state.main_component}
      </View>
    );
  }
}

// const styles = StyleSheet.create({});
