import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { CustomFailed, DeleteConfirmation, Failed, Success } from "../_components/Modal";
import "./css.css";

const context = "merchant";

export default class EcommerceInventory extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Inventory",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,

      // isChangeProductName:false,
      // isChangeDateCreated:false,
      // isChangeDateUpdated:false,
      // isChangeProductInventory:false,
      // isChangeProductCategory:false,
      // isChangeProductStatus:false,
      // isChangeProductStockStatus:false,
      groupSortAscDesc: [
        'name',
        'createdAt',
        'updatedAt'
      ], 
      groupSortLowToHigh: [
        'price',
        'salePrice',
        'quantity',
      ],
      groupSortAscDescSelected: '',
      groupSortLowToHighSelected: '',
      productCategorySelected: '',
      productStatusSelected: '',
      productStockStatusSelected:'',

      isChangeProductCategory:false,
      isChangeProductStatus:false,
      isChangeProductStock:false,

      sort_status: "",
      sort_product: "",
      sort_quantity: "-quantity",

      product_names: [],
    });
  }

  onCreate = () => {
    this.get_data(
      {
        sort: "-createdAt",
      },
      true
    );
  };

  get_data = async (params, reload) => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/products/merchant/me",
      params: params,
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.previews(data.data);
        let new_data = data.data.map((item) => {
          item.timestamp = new Date(item.createdAt).getTime();
          return item;
        });

        if (reload) {
          this.setState({
            all_data: new_data,
            inventory_data: new_data,
          });
        }
        this.reload_table("products");
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  previews = async (data) => {
    UI.set_images(data, "imagePath", "preview", "products", "ecommerce");
  };

  sortGroupItems = (group, key, value) => {
    this.setState({[group + 'Selected']: key + ":" + value});
    
    let counter = 0;
    this.state[group].forEach((keyName) => {
      this.setState({
        [keyName] : (keyName === key) ? true : false,
      },
      () => {
        counter++;
        if(counter === this.state[group].length) {
          this.filterBySelections();
        }
      })
    })
  }

  filterBySelections = () => {
    const { 
      // all_data, 
      productCategorySelected,
      productStatusSelected,
      groupSortAscDescSelected,
      groupSortLowToHighSelected,
      productStockStatusSelected,
      // isChangeProductCategory,
      // isChangeProductStatus,
      // isChangeProductStock,
      inventory_data
    } = this.state;

    // if (isChangeProductCategory) {
    //   if (productStatusSelected && isChangeProductCategory) {
    //     const data = this.state.all_data
    //     let result = data.filter((item) => item['category'] == productCategorySelected);
    //     this.setState({
    //       all_data:result,
    //       data:result
    //     }, () => {
    //       this.setState({isChangeProductCategory: false})
    //       this.reload_table("products");
    //     })
    //   } else  
    //   this.onCreate()
    //     setTimeout(() => {
    //       const data = this.state.all_data
    //       let result = data.filter((item) => item['category'] == productCategorySelected);
    //       this.setState({
    //         all_data:result,
    //         data:result
    //       }, () => {
    //         this.setState({isChangeProductCategory: false})
    //         this.reload_table("products");
    //       })
    //     }, 1000)
    // }  
    // else if (productCategorySelected && isChangeProductStatus) {
    //   const data = this.state.all_data
    //   let result = data.filter((item) => item['status'] == productStatusSelected);
    //   this.setState({
    //     all_data:result,
    //     data:result
    //   }, () => {
    //     this.setState({isChangeProductStatus: false})
    //     this.reload_table("products");
    //   })
    // } else if (isChangeProductStatus) {
    //   if (productCategorySelected && isChangeProductStatus) {
    //     const data = this.state.all_data
    //     let result = data.filter((item) => item['status'] == productStatusSelected);
    //     this.setState({
    //       all_data:result,
    //       data:result
    //     }, () => {
    //       this.setState({isChangeProductStatus: false})
    //       this.reload_table("products");
    //     })
    //   }
    //   this.onCreate()
    //   setTimeout(() => {
    //     const data = this.state.all_data
    //     let result = data.filter((item) => item['status'] == productStatusSelected);
    //     this.setState({
    //       all_data:result,
    //       data:result
    //     }, () => {
    //       this.setState({isChangeProductStatus: false})
    //       this.reload_table("products");
    //     })
    //   }, 1000)
    // } else if (productStatusSelected && isChangeProductCategory) {
    //   const data = this.state.all_data
    //   let result = data.filter((item) => item['category'] == productCategorySelected);
    //   this.setState({
    //     all_data:result,
    //     data:result
    //   }, () => {
    //     this.setState({isChangeProductCategory: false})
    //     this.reload_table("products");
    //   })
    // } else if (isChangeProductStock) {
    //   this.onCreate()
    //   setTimeout(() => {
    //     const data = this.state.all_data
    //     let result3 = []
        
    //     if (productStockStatusSelected == "in_stock") {
    //       const items = data.filter((item) => item.quantity > 0);
    //       result3 = items

    //     } else if (productStockStatusSelected == "out_of_stock") {
    //       const items = data.filter((item) => item.quantity == 0);
    //       result3 = items
    //     }
    //     // let result = data.filter((item) => item['status'] == productStatusSelected);
    //     this.setState({
    //       all_data:result3,
    //       data:result3
    //     }, () => {
    //       this.setState({isChangeProductStock: false})
    //       this.reload_table("products");
    //     })
    //   }, 1000)
    // }
    // else {
    //   const data = this.state.all_data
    //   const groupResult = groupSortAscDescSelected.split(":");
    //   let result3 = data.sort(UI.dynamicSort(groupResult[1] == "asc" ? groupResult[0] : "-" + groupResult[0]));
  
    //   const groupResult2 = groupSortLowToHighSelected.split(":");
    //   let finalResult = result3.sort(UI.dynamicSort(groupResult2[1] == "asc" ? groupResult2[0] : "-" + groupResult2[0]));
  
    //   this.reload_table("products", finalResult);
    // }

    
    let result = inventory_data.filter((item) => item['category'] === productCategorySelected);
    if (!productCategorySelected) {
      result = inventory_data;
    }

    let result2 = result.filter((item) => item['status'] === productStatusSelected);
    if (!productStatusSelected) {
      result2 = result;
    }

    let result3 = []

    if (productStockStatusSelected === "in_stock") {
      const items = result2.filter((item) => item.quantity > 0);
      result3 = items

    } else if (productStockStatusSelected === "out_of_stock") {
      const items = result2.filter((item) => item.quantity === 0);
      result3 = items
    } else {
      result3 = result2
    }
    
    if (!productStockStatusSelected) {
      result3 = result2;
    }

    const groupResult = groupSortAscDescSelected.split(":");
    let result4 = result3.sort(UI.dynamicSort(groupResult[1] === "asc" ? groupResult[0] : "-" + groupResult[0]));

    const groupResult3 = groupSortLowToHighSelected.split(":");
    let finalResult = result4.sort(UI.dynamicSort(groupResult3[1] === "asc" ? groupResult3[0] : "-" + groupResult3[0]));

    this.setState({
      all_data:finalResult,
    })

    this.reload_table("products", finalResult);
  }

  set_sort_value = (key, value) => {
    this.setState({
      [key]: value,
    },
    () => {
      this.filterBySelections();
    }
    );
  }

  delete = async (id) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this item?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();
          this.show_loading();
          UI.webRequest({
            method: "delete",
            url: "admin/products/" + id,
            params: {},
            onFail: async (err) => {},
            onSuccess: async (response) => {},
            onFinish: () => {
              this.onCreate();
            },
          });
        }}
      />
    );
  };

  clear_filters = () => {
    this.get_data({
      name: "",
      sort: "-quantity",
      status: "",
    });
  };

  filter_by = (state, value) => {
    if (state === "status") {
      this.sort_by_stock(value);
    } else {
      this.setState(
        {
          ["sort_" + state]: value,
        },
        () => {
          this.filter_all();
        }
      );
    }
  };

  filter_all = () => {
    const { sort_status, sort_product, sort_quantity } = this.state;
    this.get_data({
      name: sort_product,
      sort: sort_quantity,
      status: sort_status,
    });
  };

  sort_by_stock = (status) => {
    //in_stock, out_of_stock

    const { all_data } = this.state;

    if (status === "in_stock") {
      const items = all_data.filter((item) => item.quantity > 0);
      this.setState(
        {
          data: items,
        },
        () => {
          this.reload_table("products", items);
        }
      );
    } else if (status === "out_of_stock") {
      const items = all_data.filter((item) => item.quantity === 0);
      this.setState(
        {
          data: items,
        },
        () => {
          this.reload_table("products", items);
        }
      );
    } else {
      this.setState(
        {
          data: all_data,
        },
        () => {
          this.reload_table("products", all_data);
        }
      );
    }
  };

  // sort_by = (format, key) => {
  //   //format can be asc or desc
  //   const { all_data } = this.state;

  //   all_data.sort(UI.dynamicSort(format == "asc" ? key : "-" + key));
  //   UI.set_input_value("search", "");

  //   this.setState(
  //     {
  //       data: all_data,
  //     },
  //     () => {
  //       this.reload_table("products");
  //     }
  //   );
  // };

  // custom_sort = (key, value) => {
  //   const { all_data } = this.state;
  //   let items = all_data.filter((item) => item[key] + "" == value);
  //   UI.set_input_value("search", "");
  //   if (value) {
  //     this.reload_table("products", items);
  //   } else {
  //     this.reload_table("products", all_data);
  //   }
  // };

  import_csv = async () => {
    this.show_modal(
      <View style={{backgroundColor:'white', borderRadius: 10}}>

        <View style={{ ...styles.md_header, backgroundColor: UI.colors.secondary }}>
          <TouchableOpacity onClick={() => this.hide_modal()} style={styles.md_close}>
            <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="Close" />
          </TouchableOpacity>

          <View style={styles.md_header_text_container}>
            <Text style={{...styles.md_header_text, textAlign:'center'}}>{""}</Text>
          </View>

          {UI.box(60)}
        </View>

        <View style={{...styles.md_content_container, width:'100%'}}>
   
          <View style={{ flexDirection: "row", alignItems: "center", justifyContent:'center', width:'100%' }}>
            <TouchableOpacity
              onClick={() => {
                const csv_data = [""].map((item, index) => {
                  const d = {
                    "SKU" : "",
                    "Inventory Count": "",
                  };
            
                  return d;
                });
            
                UI.DownloadCSV(
                  "Sample CSV Format" + UI.get_current_date_string(),
                  csv_data
                );
                // this.deleteTransaction(item._id);
              }}
              style={{
                ...styles.md_button,
                backgroundColor: UI.colors.secondary ,
              }}
            >
              <Text style={styles.md_btn_text}>Download Sample CSV</Text>
            </TouchableOpacity>
            {UI.box(10)}
            <TouchableOpacity
              onClick={async () => {
                const content = await UI.get_csv_contents();
                const all_text = content.split("\r\n");
                await this.validate_csv(all_text);
                const new_list = await this.proccess_products_csv(content);
                const product_list = await this.process_product_name_address(new_list);
                UI.webRequest({
                  method: "post",
                  url: "admin/products/bulk-upload-inventory",
                  params: {
                    bulk: product_list
                  },
                  onFail: async (err) => {
                    const data = err.data;
                    const error_message = data.error;
                    this.show_modal(
                      <Failed
                        title={"Failed"}
                        description={error_message}
                        onDismiss={() => {
                          this.hide_modal();
                        }}
                      />
                    );
                  },
                  onSuccess: async (response) => {
                    this.onCreate();
                    this.show_modal(
                      <Success
                        title={"Success!"}
                        description={"Successfully upload the product."}
                        onDismiss={() => {
                          this.hide_modal();
                        }}
                      />
                    );
                  },
                  onFinish: () => {
                    this.hide_loading();
                  },
                });
              }}
              style={{
                ...styles.md_button,
                backgroundColor: UI.colors.secondary ,
                // border:'1px solid ' + UI.colors.secondary,
              }}
            >
              <Text style={styles.md_btn_text}>Upload Product CSV</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  };
  
  validate_csv = async (items) => {
    return new Promise((resolve, reject) => {
      if (items.length === 0) {
        return;
      }

      const uploadProductTemplate = "SKU,Inventory Count";
      if (items[0] === uploadProductTemplate || items[0].includes(uploadProductTemplate)) { 
        resolve();
      } else {
        this.show_modal(
          <CustomFailed
            title={"Process Failed"}
            description_title={""}
            description={"Invalid CSV Format"}
            _this={this}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      }
    });
  };

  proccess_products_csv = async (content) => {
    return new Promise((resolve, reject) => {
      if (content.length === 0) {
        resolve([]);
        return;
      }
      const regex1 = "/n/n/";
      const regex2 = "\r\n";
      content = UI.replaceAll(content, regex2, regex1);
      content = UI.replaceAll(content, "\n", "");
      content = UI.replaceAll(content, regex1, regex2);
      var array = content.split(regex2);
      let result = [];

      let headers = array[0].split(",");

      for (let i = 1; i < array.length - 1; i++) {
        let obj = {};
        let str = array[i];
        let s = "";
        let flag = 0;
        for (let ch of str) {
          if (ch === '"' && flag === 0) {
            flag = 1;
          } else if (ch === '"' && flag === 1) flag = 0;
          if (ch === "," && flag === 0) ch = "|";
          if (ch !== '"') s += ch;
        }

        let properties = s.split("|");

        for (let j in headers) {
          obj[headers[j]] = properties[j];
        }
        result.push(obj);
      }

      // result.shift();
      // result.pop();
      resolve(result);
    });
  };

  process_product_name_address = async (arr = []) => {
    return new Promise((resolve, reject) => {
      if (arr.length === 0) {
        resolve([]);
      }

      const new_data = [];
      arr.forEach((item, index) => {
        // item.name = item["Name"] ?? ""
        item.sku = item["SKU"] ?? ""
        // item.category = item["Category"] ?? ""
        // item.description = item["Description"] ?? ""
        // item.status = item["Status"] ?? ""
        item.inventoryCount = parseInt(item["Inventory Count"])
        // item.price = item["Regular Price"] ?? ""
        // item.salePrice = item["Sale Price"] ?? ""

        delete item["Name"]
        delete item["SKU"]
        delete item["Category"]
        delete item["Description"]
        delete item["Status"]
        delete item["Inventory Count"]
        delete item["Regular Price"]
        delete item["Sale Price"]
        delete item["Purchase Limit"]
        delete item["SKU Web Link"]
        new_data.push(item);
        if (index + 1 === arr.length) {
          resolve(new_data);
        }
      });
    });
  };

  render() {
    const _this = this;
    const { 
      // height, 
      width, 
      // isMobile 
    } = _this.state;
    const Constants = new APIConstants();

    let product_id = 0;

    const {
      name,
      createdAt,
      updatedAt,
      quantity,
    } = this.state

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <Text style={styles.box_header_text}>Product Inventory</Text>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Product Name"}
              isRequired={false}
              onChange={(e) => {
                this.sortGroupItems('groupSortAscDesc','name', e)
              }}
            >
              <option value="" selected={!name}>Sort by</option>
              <option value="asc">A-Z</option>
              <option value="desc">Z-A</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Date Created"}
              isRequired={false}
              onChange={(e) => {
                this.sortGroupItems('groupSortAscDesc','createdAt', e)
              }}
            >
              <option value="" selected={!createdAt}>Sort by</option>
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Date Updated"}
              isRequired={false}
              onChange={(e) => {
                this.sortGroupItems('groupSortAscDesc','updatedAt', e)
              }}
            >
              <option value="" selected={!updatedAt}>Sort by</option>
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Layout.SelectPicker>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Product Inventory"}
              isRequired={false}
              onChange={(e) => {
                this.sortGroupItems('groupSortLowToHigh','quantity', e)
              }}
            >
              <option value="" selected={!quantity}>Sort by</option>
              <option value="asc">Low to High</option>
              <option value="desc">High to Low</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Product Category"}
              isRequired={false}
              onChange={(e) => {
                // if (!e) {
                //   this.setState({productCategorySelected: ''})
                //   return this.onCreate()
                // } 
                // this.setState({isChangeProductCategory: true})
                this.set_sort_value('productCategorySelected',e);
              }}
            >
              <option value="" >Filter All</option>
              {
                Constants.product_category?.map(category => {
                  const value = category === "Labs and Clinic" ? "Laboratory" : category
                  const option = category;
                  
                  return (
                    <option value={value}>{option}</option>
                  )
                })
              }
              
           
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Product Status"}
              isRequired={false}
              onChange={(e) => {
                // this.setState({isChangeProductStatus: true})
                // if (!e) { 
                //   this.setState({productStatusSelected: ''})
                //   return this.onCreate()
                // } 
                this.set_sort_value('productStatusSelected',e);
              }}
            >
              <option value="">Filter All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Layout.SelectPicker>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Product Stock Status"}
              isRequired={false}
              onChange={(e) => {
                // if (!e) {
                //   // this.setState({productStockStatusSelected: ''})
                //   return this.onCreate() 
                // }
                this.setState({isChangeProductStock: true})
                this.set_sort_value('productStockStatusSelected',e);
              }}
            >
              <option value="">Filter All</option>
              <option value="in_stock">In Stock</option>
              <option value="out_of_stock">Out of Stock</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={850}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"products"} _this={_this} />
              </View>
            </View>

            <InputForm
              onChange={(e) => {
                UI.search_table("products", e, this.state.all_data, "data");
              }}
              is_keyword={true}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
            />

            <View
              style={{
                alignSelf: width > 950 ? "flex-start" : "center",
                marginTop: 8,
                marginLeft: 10,
              }}
            >
              <Layout.Btn
                color={UI.colors.header}
                text={"Bulk Upload"}
                onClick={() => {
                  this.import_csv()
                  // UI.goTo("/merchant/"); // TO-DO: Bulk Upload functionality
                }}
                height={40}
                width={120}
              />
            </View>
          </UI.Row>
          
          <CustomDatatable
            state_name={"products"}
            _this={_this}
            columns={[
              // { "ID ": "id" },
              { "Product Name": "product_name" }, //TO-DO: Finalize: end points
              { "Product SKU": "product_sku" },
              { "Product Status": "status" },
              { "Product Inventory": "product_inventory" },
              { "Product Stock Status": "stock_status" },
              { "Date Created": "date_created" },
              { "Date Updated": "date_updated" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              let date_added = UI.timestampToDate(
                new Date(item.createdAt).getTime()
              );
              let date_updated = moment(item.updatedAt).format('M/D/YYYY')
              // UI.timestampToDate(
              //   new Date(item.updatedAt).getTime()
              // );
              if (item.updatedAt) {
                UI.timestampToDate(new Date(item.updatedAt).getTime());
              }

              let quantity = item.quantity;
              if (!quantity) {
                quantity = 0;
              }
              quantity = Number.parseInt(quantity);

              const image = this.state["preview" + item._id] ?? UI.PRODUCT_BOX;
              product_id++;
              const id = UI.pad(product_id, 12);
              return {
                // id: id,
                // id: item._id,
                preview: (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <img style={{ height: 35, width: 35 }} src={image} alt="Product" />
                  </View>
                ),
                product_name: item.name,
                product_sku: item.sku ?? "N/A",
                status: UI.get_active_text(item),
                product_inventory: UI.pad(item.quantity, 3),
                date_created:
                  date_added.month +
                  "/" +
                  date_added.day +
                  "/" +
                  date_added.year,
                stock_status:
                  quantity > 0 ? (
                    <Text style={{ color: UI.colors.active }}>In Stock</Text>
                  ) : (
                    <Text style={{ color: UI.colors.inactive }}>
                      Out of Stock
                    </Text>
                  ),
                date_updated: date_updated
                  // date_updated.month +
                  // "/" +
                  // date_updated.day +
                  // "/" +
                  // date_updated.year
                  ,
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        mem.save("view_product_inventory", JSON.stringify(item));
                        mem.save("product_id", id);
                        UI.goTo("/merchant/view-product-inventory");
                        /*    mem.save("edit_product", JSON.stringify(item));
                        UI.goTo("/merchant/view-inventory"); */
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.VIEW_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={() => {
                        mem.save("edit_inventory", JSON.stringify(item));
                        mem.save("product_id", id);
                        UI.goTo("/merchant/view-inventory");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: "#00CBFF",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
  md_content_container: {
    padding: 25,
    alignItems: "start",
  },
  md_btn_text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
  md_button: {
    padding: 30,
    // backgroundColor: "#004F99",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
