import UI from "../../shared/Components/UI/js";
import ServiceApi from "../service";

class LaboratoryServicesApi {
  baseURL = "admin";

  async getAllCategory (params) {
    const response = await ServiceApi.get(this.baseURL + `/category/all`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async getAllCategoryByMerchant (params) {
    const response = await ServiceApi.get(this.baseURL + `/category/`, params);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }
  

  async addCategory (payload) {
    const response = await ServiceApi.post(this.baseURL + `/category/`, payload);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async updateCategory (categoryId, payload) {
    const response = await ServiceApi.put(this.baseURL + `/category/${categoryId}`, payload);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

  async deleteCategory (categoryId) {
    const response = await ServiceApi.delete(this.baseURL + `/category/${categoryId}`);

    if (response?.status === 200) {
      const data = UI.extract_data(response);
      data.status = 200;
      return data;
    } else {
      return {
        status: response.status,
        data: response.data.d,
        message: response.data.m
      }
    }
  }

}

export default new LaboratoryServicesApi();
