import React from 'react'
import ReactFacebookLogin from 'react-facebook-login'
import ServiceAuthApi from '../../../api_services/auth'
import UI from '../../../shared/Components/UI/js';
import TouchableOpacity, { mem } from '../../../shared/custom-react-native';
import { Image } from 'antd';

const LoginUsingFacebook = ({ _this }) => {

  const login = (accessToken) => {

    const payload = {
      accessToken,
      role: "provider",
    };
    
    _this.setState({ isLoading:true });
    ServiceAuthApi.signInUsingFacebook(payload).then(res => {
      _this.setState({ isLoading:false });
      if (res.status === 200) {
        let role = res.role || "provider"
        const current_role = UI.role_map[role];
        mem.save("jwt_token", res.token);
        mem.save("role", role);
        UI.goTo(`/${current_role}/dashboard`);
      }
    });
  };

  const responseFacebook = (response) => {
    if (response.status === 'unknown') {
      return true
    } else {
      login(response.accessToken);
    }
  };

  return (
    <TouchableOpacity>
      <ReactFacebookLogin
        cssClass="fb-login-button"
        appId={UI.Constants.FB_APP_ID}
        redirectUri={UI.Constants.WEB_HOST_NAME_URL}
        fields="name,email,picture"
        scope="public_profile,user_friends"
        callback={responseFacebook}
        icon={<Image preview={false} src={UI.FB_ICON} alt="fb_icon" className='cursor-pointer' />}
        textButton={""}
      />
    </TouchableOpacity>
  )
}

export default LoginUsingFacebook
