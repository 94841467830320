import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";
import MarketingCampaignReferralsTable from "./MarketingCampaignReferralsTable";
import ServiceMarketingsApi from "../../api_services/marketings";
import { Failed, PreviewMarketingQrCodeReferralModal } from "../_components/Modal";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "antd";

const context = "marketing";

export default class MarketingCampaignReferralsView extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Campaign Referrals",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
      link: "",
      name: "",
      status: "",
      createdAt: "",
      totalQrScan: "",
      stats: {
        totalNumberOfEvents: 0,
        totalCodeReferrals: 0,
        totalQrReferrals: 0,
        totalReferrals: 0,
      }
    });
  }

  onCreate = () => {
    this.singleCampaignReferralStats()
    this.setState({});
    this.getCampaignReferralById();
  };

  getCampaignReferralById = () => {
    const data = JSON.parse(mem.get('campaign_referral_view'))

    this.setState({ 
      item: data,
      link: data.link ?? "",
      name: data.name ?? "",
      status: data.status ?? "",
      createdAt: UI.momentPHTimezone(data.createdAt).format('MM/DD/YYYY'),
      totalQrScan: data.totalQrScan ?? "",
      totalJoined: data.totalJoin ?? ""
    })
  }
  
  singleCampaignReferralStats = () => {
    // console.log('first')
    const data = JSON.parse(mem.get('campaign_referral_view'))
    console.log(data)
    // console.log(data)
    this.show_loading();
    ServiceMarketingsApi.singleCampaignReferralStats(data._id, {
      duration: this.state.duration,
      start: this.state.startDate,
      end: this.state.endDate,
    }).then( res => {
      if (res.status === 200) {
        console.log(res)
        this.setState({ 
          stats: res
        })
      } else {
        this.show_modal(
          <Failed
            description={res.message} 
            onDismiss={() => { 
              UI.goBack()
              this.hide_loading();
            }} 
          />
        );
      }
    })
  }

  render() {
    const _this = this;

    const {
      item,
      link,
      name,
      createdAt,
      // totalQrScan,
      // totalJoined,
    } = this.state;
    console.log(item)
    return (
      <MainLayout context={context} _this={_this}>
        <UI.Row
          _this={_this}
          breakpoint={1080}
          style={{  flexDirection: "row", gap: 20 }}
        >
          <View style={{ gap: 20, flex: 1 }}>
            <View style={{
              backgroundColor: "white",
              borderRadius: 5,
              gap:10,
              ...Shadow._3(),
              padding: 20,
            }}>
              <Text style={styles.box_header_text}>
                Campaign Details
              </Text>
              <Text style={styles.header_1}> Campaign Name: <strong style={{ color:UI.colors.primary }}>{ name } </strong></Text>
              <Text style={styles.header_1}> Status: <strong style={{ color:UI.colors.primary }}>{UI.get_active_text(item ?? "")} </strong></Text>
              <Text style={styles.header_1}> Date Created: <strong style={{ color:UI.colors.primary }}>{ createdAt }</strong></Text>
              <Text style={{...styles.header_1, position: 'relative', cursor: 'pointer'}}> Link: <strong style={{ color:UI.colors.primary }}>{ link.length > 40 ? link.slice(0, 40) + "..." : link }</strong>
                <Tooltip title={link}
                  onClick={() => {
                    this.setState({ isCopyLink: true });
                    setTimeout(() => {
                      this.setState({ isCopyLink: false });
                    }, 2000);
                  }}
                >
                  <div className="absolute inset-0 flex justify-end items-center mr-3 mb-2" style={{cursor: 'pointer'}}>
                    <CopyToClipboard
                      text={this.state.link}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg"
                          
                        fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer" style={{ color: "#AAB4BF"}}>
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                        </svg>
                    </CopyToClipboard>
                  </div>
                
                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      left: "80%",
                      marginTop: -30,
                      color: UI.colors.secondary,
                    }}
                  >
                    {this.state.isCopyLink && "Copied"}
                  </span>
                </Tooltip>
              </Text>
              
              <Layout.Btn
                color={UI.colors.secondary}
                text={"View QR Code"}
                onClick={() => {
                  this.show_modal(
                    <PreviewMarketingQrCodeReferralModal _this={this}/>
                  )
                }}
                width={"100%"}
              />
              
            </View>

            <View
              style={{
                ...styles.main_box_content,
                padding: 20,
                // flex: 1,
                // maxWidth: width > 1080 ? 340 : null,
              }}
            >
              <UI.Row _this={_this}>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Text style={styles.box_header_text}>
                    Campaign Stats
                  </Text>
                </View>
              </UI.Row>

              <ReferralStat
                image={UI.IC_DASH_18}
                main={UI.pad(item?.members?.length ?? 0, 3)}
                color={UI.colors.primary}
                text={"Total Number of Referrals"}
              />
            </View>
          </View>
          <MarketingCampaignReferralsTable _this={_this}/>
        </UI.Row>
      </MainLayout>
    );
  }
}

const ReferralStat = (
  props = { image: null, main: "", color: "", text: "" }
) => {
  return (
    <View style={styles.referral_stat_item}>
      <Image
        height={70}
        width={70}
        tintColor={"transparent"}
        source={props.image}
      />
      <Text style={{ ...styles.referral_stats_text, color: props.color }}>
        {props.main}
      </Text>

      <Text
        style={{
          ...styles.stats_name,
          color: "#535353",
          fontSize: 14,
          textAlign: "right",
          maxWidth: 130,
        }}
      >
        {props.text}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  referral_stat_item: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  referral_stats_text: {
    color: UI.colors.primary,
    fontWeight: "bold",
    fontSize: 32,
    marginLeft: 15,
    flex: 1,
  },
  stats_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  legend_text: {
    color: "#535353",
    fontWeight: "bold",
    fontSize: 12,
    marginLeft: 10,
  },
  legend_container: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  dot_color: {
    height: 12,
    width: 12,
    borderRadius: 10,
    backgroundColor: UI.colors.primary,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
  },
  box_header_subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#C9C9C9",
    alignSelf: "flex-end",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    flexDirection: "row",
    maxHeight: 180,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
