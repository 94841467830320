import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import "./css.css";
export default class Statistics extends Component {
  render() {
    const _this = this.props._this;
    const {
      totalEarnings,
      totalConsultations,
      totalCompletedConsultations,
      totalScheduledConsultations,
      totalEarningMonth,
      // all_data
    } = _this.state;

    // const completedConsultationCount =  all_data.filter((completed =>  completed.status.includes("Completed")))
    // const scheduledConsultationCount =  all_data.filter((completed =>  completed.status.includes("Booked")))

    return (
      <UI.Row
        breakpoint={1000}
        _this={_this}
        style={{ width: "100%", marginBottom: 20 }}
      >
        <View style={styles.earnings_container}>
          <View style={{ flex: 1, minWidth: 160 }}>
            <Text style={styles.header_1}>Total Earnings</Text>
            <Text style={styles.main_header}>
              Php {UI.pad(totalEarnings, 3, totalEarnings.toString().includes('.') ? false : true)}
            </Text>

            {UI.box(20)}

            <Text style={styles.fade_header}>
              Php {UI.pad(totalEarningMonth, 3, true)}
            </Text>
            <Text style={styles.header_2}>This Month</Text>
          </View>
          {UI.box(25)}
          <Image
            height={100}
            width={100}
            tintColor={"transparent"}
            source={UI.PAY}
          />
        </View>
        {UI.box(20)}
        <View
          style={{
            padding: 20,
            borderRadius: 5,
            backgroundColor: "white",
            ...Shadow._3(),
            flex: 1,
          }}
        >
          <UI.Row breakpoint={980} _this={_this} style={{ width: "100%" }}>
            <Text style={styles.box_header_text}>Doctor's Site Statistics</Text>
            <Text style={styles.box_header_subtitle}>Updated just now</Text>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={1175} _this={_this}>
            <DashItem
              _this={_this}
              icon={UI.IC_DASH_1}
              num={UI.pad(totalConsultations, 3)}
              color={UI.colors.primary}
              text={"Total # of Consultation"}
            />
            {UI.box(20)}
            <DashItem
              _this={_this}
              icon={UI.IC_DASH_2}
              num={UI.pad(totalScheduledConsultations ?? 0, 3)}
              color={UI.colors.secondary}
              text={"Scheduled Consultations"}
            />
            {UI.box(30)}
            <DashItem
              _this={_this}
              icon={UI.IC_DASH_3}
              num={UI.pad(totalCompletedConsultations ?? 0, 3)}
              color={UI.colors.secondary}
              text={"Completed Consultations"}
            />
          </UI.Row>
        </View>
      </UI.Row>
    );
  }
}

const DashItem = (
  props = { _this: null, icon: "", num: "", text: "", color: "" }
) => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Image
        height={70}
        width={70}
        tintColor={"transparent"}
        source={props.icon}
      />

      <View style={{ marginLeft: 15 }}>
        <Text style={{ ...styles.main_header, color: props.color }}>
          {props.num}
        </Text>
        <Text style={styles.header_2}>{props.text}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  box_header_subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#C9C9C9",
    alignSelf: "flex-end",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    backgroundColor: "white",
    padding: 20,
    ...Shadow._3(),
    flexDirection: "row",
    maxHeight: 180,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
