import React, { useCallback, useState, useEffect } from "react";
import DataTable from "../../ComponentsFC/DataTable";
import UI from "../../shared/Components/UI/js";
import moment from "moment";
import styled from "styled-components";
import { Row, Col, Button, Input, Select, Typography, DatePicker } from "antd";
import DropdownIcon from "../../icons/Dropdown";
import SearchIcon from "../../icons/Search";
import ServiceMerchantTransactionsApi from "../../api_services/Merchant/transactionsApi";
import TouchableOpacity, {
  Image,
  mem,
  View,
} from "../../shared/custom-react-native";
import { CustomConfirmation, Failed } from "../_components/Modal";

const EcommerceTransactionsTable = ({ _this }) => {
  const [tableData, setTableData] = useState([]);
  const [searchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableEntries, setTableEntries] = useState(30);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [sortByStartEndDate, setSortByStartEndDate] = useState([]);
  const [sortByDatePurchased, setSortByByDatePurchased] = useState([]);
  const [filterByOrderStatus, setFilterByOrderStatus] = useState("");
  const [filterByPaymentStatus, setFilterByPaymentStatus] = useState("");
  const [dateFilterType, setDateFilterType] = useState("");

  const getAllTransactions = useCallback(async () => {
    setIsLoading(true);

    let params = {};

    if (sortByStartEndDate.length > 1) {
      if (dateFilterType === "Date Purchased") {
        params.startDate = sortByStartEndDate[0];
        params.endDate = sortByStartEndDate[1];
      } else {
        params.labScheduleStartDate = sortByStartEndDate[0];
        params.labScheduleEndDate = sortByStartEndDate[1];
      }
    }

    if (searchQuery) {
      params.filterString = searchQuery;
    }

    if (sortByDatePurchased) {
      params.datePurchaseSort = sortByDatePurchased;
    }

    if (filterByOrderStatus) {
      params.orderStatus = filterByOrderStatus;
    }

    if (filterByPaymentStatus) {
      params.paymentStatus = filterByPaymentStatus;
    }

    if (currentPageNum) {
      params.page = currentPageNum;
      params.limit = tableEntries;
    }

    ServiceMerchantTransactionsApi.getAllTransactionsByMerchant(params).then(
      (res) => {
        console.log(res);
        setIsLoading(false);
        if (res.status === 200) {
          let data = res.data;
          setTotalRecords(res.count);
          setTableData(data);
        }
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPageNum,
    tableEntries,
    sortByStartEndDate,
    searchQuery,
    filterByOrderStatus,
    filterByPaymentStatus,
    sortByDatePurchased,
  ]);

  useEffect(() => {
    getAllTransactions();
  }, [getAllTransactions]);

  const handleCSVExport = () => {
    const admin = JSON.parse(mem.get("admin"));

    const csv_data = tableData.map((item, index) => {
      const maritalStatus = item.formData?.maritalStatus ?? "";
      const phone = item.user?.phone ?? "";
      const email = item.user?.email ?? "";
      const formDataAddress = `${item?.formData?.street ?? " "} ${
        item?.formData?.brgyOrTown ?? " "
      } ${item?.formData?.cityOrMunicipality ?? " "} ${
        item?.formData?.region ?? " "
      } ${item?.formData?.postalCode ?? " "}`;
      const formDataLabAddress = `${item?.formDataLaboratory?.street ?? " "} ${
        item?.formDataLaboratory?.brgyOrTown ?? " "
      } ${item?.formDataLaboratory?.cityOrMunicipality ?? " "} ${
        item?.formDataLaboratory?.region ?? " "
      } ${item?.formDataLaboratory?.postalCode ?? " "}`;

      const d = {
        Row: index + 1,
        "Payment Reference ID": item.referenceNumber ?? " ",
        "Order ID": item.id ?? " ",
        "Purchase Date": UI.get_date_string_by_date(item.createdAt) ?? " ",
        "Customer Name": item.user?.firstName
          ? item.user?.firstName + " " + item.user?.lastName
          : "N/A",
        Gender: item.user?.gender ? "Male" : "Female",
        Address: item?.formDataLaboratory ? formDataLabAddress  :  item?.formData ? formDataAddress : " ",
        Email: email,
        Phone: phone,
        "Marital Status": maritalStatus,
        "Product/s Purchased": item.product?.name.replace(",", " ") ?? "--",
        Merchant: admin.name ?? " ",
        "Quantity Purchased": item.quantity ?? " ",
        "Unit Price": item.product?.price ?? " ",
        "Total Price": item.amount ?? " ",
        "Transaction Fee": item.transactionFee ?? 0,
        "Total Fee": parseInt(item.amount) + item.transactionFee ?? 0,
        "Amount Paid": parseInt(item.amount) + item.transactionFee ?? 0,
        "Payment Method":
          item.method !== "HealthLink" ? item?.payType || "" : item.method,
        "Payment Status": item.status ?? " ",
        "Order Status": item.orderStatus ?? " ",
        "Healthpass ID": item?.healthpassId?._id ?? " ",
        "Healthpass Activation Date":
          moment(item?.healthpassId?.activationDate).format(
            "MMMM Do YYYY - h:mm A"
          ) ?? " ",
        "Appointment ID": item.formData?.appointmentId ?? " ",
      };

      return d;
    });

    UI.DownloadCSV(
      `Transactions History ${UI.get_current_date_string()}`,
      csv_data
    );
  };

  const handlePaginateTable = (pageNum) => {
    setCurrentPageNum(pageNum);
  };

  const tableColumnHeader = [
    {
      title: "Payment Reference ID",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
      render: (referenceNumber) => referenceNumber ?? "--",
    },
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
      render: (id) => id ?? "--",
    },
    {

      title: "Patient Name",
      dataIndex: "name",
      key: "name",
      render: (name, item) =>
        item.user?.firstName
          ? item.user?.firstName + " " + item.user?.lastName
          : "N/A",
    },
    {
      title: "Product",
      dataIndex: "name",
      key: "name",
      render: (name, item) => item.product?.name ?? "--",
    },
    {
      title: "Date Purchased",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) =>
        createdAt ? moment(createdAt).format("YYYY/MM/DD") : "--",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (data, item) => parseInt(item.amount),
    },
    {
      title: "Appointment Date",
      dataIndex: "appointmentDate",
      key: "appointmentDate",
      render: (data, item) => item.formDataLaboratory?.preferredSchededule ? moment(item.formDataLaboratory.preferredSchededule).format("YYYY/MM/DD") : "--",
    },
    // {
    //   title: "Payment Method",
    //   dataIndex: "method",
    //   key: "method",
    //   render: (method) => method ?? "--",
    // },
    {
      title: "Payment Status",
      dataIndex: "status",
      key: "status",
      render: (data, item) => UI.get_transaction_status_text(item),
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (orderStatus) => orderStatus ?? "--",
    },
    {
      title: "Action",
      dataIndex: "createdAt",
      key: "date_added",
      render: (data, item) => {
        return (
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              onClick={() => {
                mem.save("view_transaction", JSON.stringify(item));
                UI.goTo("/merchant/view-transaction");
              }}
            >
              <Image
                height={32}
                width={32}
                tintColor={"transparent"}
                source={UI.VIEW_BTN_1}
              />
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                display:
                  item.orderStatus === "Completed"
                    ? "none"
                    : item.status === "Paid"
                    ? "flex"
                    : "none",
                marginLeft: 5,
              }}
              onClick={() => {
                _this.show_modal(
                  <CustomConfirmation
                    title={"Confirmation"}
                    text={"Are you sure you want to complete this transaction?"}
                    backgroundColor={"#2ECC71"}
                    onOk={{
                      text: "CONTINUE",
                      method: () => {
                        _this.hide_modal();
                        completeTransaction(item);
                      },
                    }}
                    onCancel={{
                      text: "CANCEL",
                      method: () => {
                        _this.hide_modal();
                      },
                    }}
                  />
                );
              }}
            >
              <Image
                height={32}
                width={32}
                tintColor={"transparent"}
                source={UI.COMPLETE_BTN}
              />
            </TouchableOpacity>

            {/* <TouchableOpacity
              style={{
                display: item.status === "Refund" ? "flex" : "none",
                marginLeft: 5,
              }}
              onClick={() => {
                _this.show_modal(
                  <CustomConfirmation
                    title={"Confirmation"}
                    text={
                      "Are you sure you want to refund this transaction?"
                    }
                    backgroundColor={"#f39c12"}
                    onOk={{
                      text: "CONTINUE",
                      method: () => {
                        _this.hide_modal();
                        completeTransaction(item)
                      },
                    }}
                    onCancel={{
                      text: "CANCEL",
                      method: () => {
                        _this.hide_modal();
                      },
                    }}
                  />
                );
              }}
            >
              <Image
                height={32}
                width={32}
                tintColor={"transparent"}
                source={UI.REFUND_BTN}
              />
            </TouchableOpacity> */}
          </View>
        );
      },
    },
  ];

  const completeTransaction = async (item) => {
    setIsLoading(true);

    let payload = {
      orderStatus: "Completed",
    };

    ServiceMerchantTransactionsApi.updateTransacitonOrderStatus(
      item._id,
      payload
    ).then((res) => {
      setIsLoading(false);
      if (res.status === 200) {
        getAllTransactions();
      } else {
        _this.show_modal(
          <Failed
            description={res.message}
            onDismiss={() => {
              _this.hide_loading();
            }}
          />
        );
      }
    });
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPageNum(1);
    setTableEntries(30);
  };

  const handleFilterByShiftDateRange = (dates, dateStrings) => {
    console.log(dates);
    console.log(dateStrings);
    setSortByStartEndDate(dateStrings);
  };

  // const handleClearViaSelectField = (fieldName) => {
  //   console.log("Handle CLEAR", fieldName);
  //   if (fieldName === "Date Filter by") {
  //     setSearchResults([]);
  //   }
  // };

  return (
    <>
      <FilterBox>
        <Title level={5}>Transactions History</Title>
        <Toolbar gutter={14}>
          <ToolItem span={8}>
            <StyledFieldLabel>Date Purchased</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Sort By"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setSortByByDatePurchased(value);
              }}
            >
              <Select.Option value="asc">Ascending</Select.Option>
              <Select.Option value="desc">Descending</Select.Option>
            </StyledSelect>
          </ToolItem>
          <ToolItem span={8}>
            <StyledFieldLabel>Payment Status</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Filter By"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setFilterByPaymentStatus(value);
                // setSortByCreatedAt("")
              }}
            >
              <Select.Option value="Paid">Paid</Select.Option>
              <Select.Option value="Unpaid">Unpaid</Select.Option>
              <Select.Option value="Failed">Failed</Select.Option>
            </StyledSelect>
          </ToolItem>
          <ToolItem span={8}>
            <StyledFieldLabel>Order Status</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Filter By"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setFilterByOrderStatus(value);
              }}
            >
              <Select.Option value="Confirmed">Confirmed</Select.Option>
              <Select.Option value="Completed">Completed</Select.Option>
              <Select.Option value="Cancelled">Cancelled</Select.Option>
            </StyledSelect>
          </ToolItem>
        </Toolbar>
        <Toolbar gutter={14}>
          <ToolItem span={8}>
            <StyledFieldLabel>Date Filter</StyledFieldLabel>
            <StyledSelect
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Date Filter Type"
              style={{
                width: "100%",
              }}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={(value) => {
                setDateFilterType(value);
              }}
            >
              <Select.Option value="Date Purchased">
                Date Purchased
              </Select.Option>
              <Select.Option value="Date Lab Schedule">
                Date Lab Schedule
              </Select.Option>
            </StyledSelect>
          </ToolItem>
          {dateFilterType && (
            <ToolItem span={8}>
              <StyledFieldLabel>{dateFilterType}</StyledFieldLabel>
              <StyledRangePicker
                separator={<span className="mr-6">-</span>}
                size="large"
                ranges={{
                  Today: [moment(), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                onChange={handleFilterByShiftDateRange}
              />
            </ToolItem>
          )}
        </Toolbar>
      </FilterBox>
      <TableBox>
        <Toolbar style={{ marginBottom: 20 }}>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="30"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
            <Select.Option value="100">100 Entries</Select.Option>
            {/* <Select.Option value={totalRecords}>All Entries</Select.Option> */}
          </StyledSelect>
          <StyledSearch
            placeholder="Enter Keyword"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getAllTransactions}
            style={{ width: 200 }}
          />
          <StyledButton type="primary" onClick={handleCSVExport}>
            Export to CSV
          </StyledButton>
        </Toolbar>
        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          defaultPageSize={30}
          pageSizeOptions={["30", "100", totalRecords]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default EcommerceTransactionsTable;

// CSS Styled Components ----------------------
const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const ToolItem = styled(Col)`
  position: relative;
`;

const Title = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.4375rem !important; // 15px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
  padding-left: 35px;
  margin-bottom: 25px !important;
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 220px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;

// const FlexRow = styled(Row)`
//   flex-direction: row;
//   position: absolute;
//   left: 9px;
//   bottom: 2px;
//   z-index: 1;
//   align-items: center;
// `;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  flex-direction: row;
  border-radius: 12px;
  border-width: 2px !important;
  height: 50px;
  margin-left: auto;

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  .ant-picker-range-separator {
    display: block !important;
  }
`;
