import React from "react";
import DataTable from "../../../../../ComponentsFC/DataTable";
import { Row } from "antd";
import styled from "styled-components";

const SurgicalHistory = ({ surgical_history }) => {
  const tableColumnHeader = [
    {
      title: "Operation/Procedure",
      dataIndex: "operation",
      key: "operation_illness",
      render: (data) => data || "n/a",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (data) => data || "n/a",
    },
    {
      title: "Indication",
      dataIndex: "indication",
      key: "indication",
      render: (data) => data || "n/a",
    },
    {
      title: "Hospital",
      dataIndex: "hospital",
      key: "hospital",
      render: (data) => data || "n/a",
    },
    {
      title: "Remarks",
      dataIndex: "location_remarks",
      key: "location_remarks",
      render: (data) => data || "n/a",
    },
  ];
  return (
    <TableBox>
      <DataTable
        tableColumnHeader={tableColumnHeader}
        dataSource={surgical_history}
        defaultPageSize={5}
        pageSizeOptions={["5", "10", "30"]}
        hideTotalSummaryOnSinglePage={surgical_history.length <= 5}
        hidePaginationOnSinglePage={surgical_history.length <= 5}
      />
    </TableBox>
  );
};

export default SurgicalHistory;

const TableBox = styled(Row)`
  position: relative;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  min-width: 100%;
  .ant-table-cell {
    font-size: 20px;
  }
`;
