import React, { useState } from "react";
import { Row, Image } from "antd";
import styled from "styled-components";
import DataTable from "../../../../../ComponentsFC/DataTable";

const IdentifyDocuments = ({ documents }) => {
  const [showDocument, setShowDocument] = useState(false);

  const tableColumnHeader = [
    {
      title: "Filename",
      dataIndex: "type_name",
      key: "type_name",
      render: (data) => data || "n/a",
    },
    {
      title: "File",
      dataIndex: "file_url",
      key: "file_url",
      render: (data) => {
        return data ? (
          <>
            <Image
              width={0}
              style={{ display: "none" }}
              preview={{
                visible: showDocument,
                onVisibleChange: (visible) => setShowDocument(visible),
              }}
              src={data}
            />
            <button onClick={() => setShowDocument(!showDocument)}>
              <span className="underline text-blue-400 font-medium">view</span>
            </button>
          </>
        ) : (
          "n/a"
        );
      },
    },
  ];

  return (
    <TableBox>
      <DataTable
        tableColumnHeader={tableColumnHeader}
        dataSource={documents}
        // defaultPageSize={5}
        // pageSizeOptions={["5", "10", "30"]}
        hideTotalSummaryOnSinglePage={true}
        hidePaginationOnSinglePage={true}
      />
    </TableBox>
  );
};

export default IdentifyDocuments;

const TableBox = styled(Row)`
  position: relative;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  min-width: 100%;
  .ant-table-cell {
    font-size: 20px;
  }
`;
