import React from "react";
import { StyleSheet, Text, View } from "react-pdf-renderer-v2";
import EDocsWrapperComponent from "./EDocsWrapperComponent";
import UI from "../../../../shared/Components/UI/js";

const MedicalCertificateModal = ({
  appointment,
  encounterNotesData,
  onHideModal,
}) => {
  const medical_certificate = encounterNotesData.medicalcertificates[0];

  return (
    <EDocsWrapperComponent
      title="Medical Certificate"
      appointment={appointment}
      onHideModal={onHideModal}
    >
      <Text style={styles.text}>
        This is to certify that I have seen and examined the patient on{" "}
        {UI.momentPHTimezone(appointment.start).format("MMMM DD, YYYY")}
      </Text>

      <View style={styles.body}>
        <Text style={styles.subtitle}>Consultation Details:</Text>
        <Text style={styles.text}>{medical_certificate?.fitToWorkStatus}</Text>

        <Text style={styles.subtitle}>Diagnosis:</Text>
        <Text style={styles.text}>{medical_certificate?.fitToWorkOthers}</Text>

        <Text style={styles.subtitle}>Recommendation:</Text>
        <Text style={styles.text}>{medical_certificate?.recommendation}</Text>
      </View>
      {/* <Text style={{ ...styles.text, display: "block" }}>
            This medical certificate is issued upon the request of the patient
            for whatever purpose it may serve EXCEPT for medico-legal purposes.
          </Text> */}
      {/* <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `Powered by MWELL: ${pageNumber} / ${totalPages}`
            }
            fixed
          /> */}
    </EDocsWrapperComponent>
  );
};

const styles = StyleSheet.create({
  body: {},
  subtitle: {
    fontSize: 14,
    marginTop: 20,
  },
  text: {
    marginTop: 12,
    marginBottom: 12,
    fontSize: 14,
    textAlign: "justify",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    borderTop: "1px solid black",
    paddingTop: 10,
  },
});

export default MedicalCertificateModal;
