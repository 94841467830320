import { useEffect } from "react";
import { AutoLogout } from "../modules/onboard-create-user/Instructions";
import UI from "../shared/Components/UI/js";
import APIConstants from "../shared/modules/APIConstants";
const Constants = new APIConstants();

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const AutoLogoutApp = ({ children, _this }) => {
  let timer;

// this function sets the timer that logs out the user after 10 secs
const handleLogoutTimer = () => {
  let default_idle_time = 120 // 120 = 2 hours
  if (Constants.ENV_TYPE === "PRODUCTION-RIZAL") {
    default_idle_time = 120; // 120 = 2 hours
  } else {
    default_idle_time = 1440; // 1440 = 24 hours/1 day
  }

  const minutes_millis = default_idle_time; //1 = 1min, 15 = 15min
  let timeout = minutes_millis * 60 * 1000;

  timer = setTimeout(() => {
    // clears any pending timer.
    resetTimer();
    // Listener clean up. Removes the existing event listener from the window
    Object.values(events).forEach((item) => {
      window.removeEventListener(item, resetTimer);
    });
    // logs out user
    logoutAction();
  }, timeout); // 10000ms = 10secs. You can change the time.
};

// this resets the timer if it exists.
const resetTimer = () => {
  if (timer) clearTimeout(timer);
};
// when component mounts, it adds an event listeners to the window
// each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
// However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
useEffect(() => {
  Object.values(events).forEach((item) => {
    window.addEventListener(item, () => {
      resetTimer();
      handleLogoutTimer();
    });
  });
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

// logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
const logoutAction = () => {
  _this.show_modal(<AutoLogout _this={this} />);
  setTimeout(() => {
    UI.logout();
  }, 60000)
};

  return children;
};

export default AutoLogoutApp;