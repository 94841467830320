import React from "react";
// import styled from "styled-components";

import ReferralsList from "./ReferralsList";

const Referrals = () => {
  return (
    <>
      <ReferralsList />
    </>
  );
};

export default Referrals;
