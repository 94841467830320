import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import UI from '../../shared/Components/UI/js'
// import { View } from '../../shared/custom-react-native'
import './css.css';

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    // const { isMobile, width } = this.props._this.state

    return (
      <footer>
        <div className='m-auto' style={{width:'70%'}}>
          <div className='grid gap-x-5 justify-center lg:grid-cols-4 md:grid-cols-2'>

            <div className=''>
              <div className='footer-contact' style={{fontSize:'15.3px'}}>
                <img src={UI.LOGO_ADVOCACY} alt="mwell_logo" width={170} />
                {/* <p>{footerData.contact.number}</p> */}
                <p>
                  <a href="mailto:support@mwell.com.ph">
                    support@mwell.com.ph
                  </a>
                </p>
                <p>
                  9/F, Rockwell Business Center Tower 1,
                  Ortigas Ave., Ortigas Center, Brgy. Ugong,
                  1604, Pasig City, Philippines
                </p>
              </div>
            </div>

            <div className=''>
              <div className='footer-quick-links' style={{fontSize:'15.3px'}}>
                <h4>mWell support</h4>
                <div className='footer-links'>
                  <Link
                    to="https://www.mwell.com.ph/support"
                  >
                    FAQs
                  </Link>
                  <Link
                    to="https://www.mwell.com.ph/support"
                  >
                    Contact Us
                  </Link>
                  <Link
                    to="https://www.mwell.com.ph/privacy-policy"
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    to="https://www.mwell.com.ph/terms-and-conditions"
                  >
                    Terms and Conditions
                  </Link>
                </div>
              </div>
            </div>
            {/* col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12 */}
            <div className=''>
              <div className='footer-quick-links' style={{fontSize:'15.3px'}}>
                <h4>Quick Links</h4>
                <div className='footer-links'>
                  <Link
                    to="https://www.mwell.com.ph/doctor-consultation"
                  >
                    Doctors Consultation
                  </Link>
                  <Link
                    to="https://www.mwell.com.ph/wellness"
                  >
                    Wellness
                  </Link>
                  <Link
                    to="https://www.mwell.com.ph/about-us"
                  >
                    About Us
                  </Link>
                </div>
              </div>
            </div>

            <div className=''>
              <div className='footer-quick-links'>
                <h4 className='subscribe-label'>Download</h4>

                {/* <form className="newsletter-subcribe" onSubmit={handleSubscribe}>
                                  <input className="newsletter-input" name="email" type="email" required placeholder="Email Address" />
                                  <button className="submit" type="submit">Subcribe Now</button>
                              </form> */}

                <div className='footer-download-links'>
                  <a target='_blank' href="https://mwellph.onelink.me/lnFL/?af_js_web=true&af_ss_ver=2_2_0&pid=web_install_button&c=web_campaign&af_channel=smart_script&af_ad=web_ad&af_adset=web_adset" rel='noreferrer'>
                    <img src={UI.APPSTORE} alt='Apple Store Button' />
                  </a>
                  <a target='_blank' href="https://mwellph.onelink.me/lnFL/?af_js_web=true&af_ss_ver=2_2_0&pid=web_install_button&c=web_campaign&af_channel=smart_script&af_ad=web_ad&af_adset=web_adset" rel='noreferrer'>
                    <img src={UI.GPLAY} alt='Google Play Store Button' />
                  </a>
                </div>

                <div className='social-cont'>
                  <a href='https://www.facebook.com/mwellph' target="_blank" rel="noopener noreferrer"><img src="https://www.mwell.com.ph/fb.svg" alt=""/></a>
                  <a href='https://www.instagram.com/mwellph/' target="_blank" rel="noopener noreferrer"><img src="https://www.mwell.com.ph/ig.svg" alt=""/></a>
                  <a href='https://www.twitter.com/mWellph' target="_blank" rel="noopener noreferrer"><img src="https://www.mwell.com.ph/tw.svg" alt=""/></a>
                  <a href='https://www.linkedin.com/company/metro-pacific-health-tech-mwell/' target="_blank" rel="noopener noreferrer"><img src="https://www.mwell.com.ph/linkedin.svg" alt=""/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
