/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import UI from '../../shared/Components/UI/js'
import TouchableOpacity, { Image } from '../../shared/custom-react-native'
import FormItem from '../ui/forms/FormItem'
import Text from '../ui/Text'
import { Badge, Button, Col, Form, Row } from 'antd'
import styled from 'styled-components'
import OtpInput from 'react-otp-input'
import ServiceAuthApi from '../../api_services/auth'
import ProcessFailedModal from '../ui/modals/ProcessFailedModal'
import ProcessFailedModalCrossIcon from '../../icons/ProcessFailedModalCross'
import { mem } from '../../shared/custom-react-native'
import Loading from '../../modules/_components/Loading'
import { Image as AntdImage } from 'antd';
import jwt from 'jsonwebtoken'

const OtpForm = () => {
  const [otpCode, setOtpCode] = useState("");
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); 
  const [count, setCount] = useState(60);
  const queryParams = new URLSearchParams(window.location.search);
  const loginType = queryParams.get("loginType");
  const counterValid = count > 0;
  const payloadCurrentInput = jwt.decode(queryParams.get("credentials"));

  useEffect(() => {
    const interval = setInterval(() => {
      counterValid && setCount(count => count - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [counterValid]);

  const handleChange = otp => {
    setErrorMessage("");
    setOtpCode(otp);
  };

  const verifyOtpCode = (e) => {

    const payload = {
      otpNumber: otpCode,
      userId: payloadCurrentInput.userId,
      role: "provider"
    };

    setIsScreenLoading(true);

    if (loginType) {
      if (loginType === "email-signup") {
        ServiceAuthApi.verifyOtpUsingEmailSignup(payload).then(res => {
          if (res.status === 200) {
            setIsScreenLoading(false);
            let role = res.role || "provider";
            const current_role = UI.role_map[role];
            mem.save("jwt_token", res.token);
            mem.save("role", role);
            UI.goTo(`/${current_role}/dashboard`);
          } else {
            setIsScreenLoading(false);
            if (res.message.includes("wrong") || res.message.includes("expired")) {
              setErrorMessage(res.message);
            } else {
              setIsFailed(true);
              setErrorMessage(res.message);
            }
          }
        });
      };
    } else {
      ServiceAuthApi.verifyOtpUsingMobileNumber(payload).then(res => {
        if (res.status === 200) {
          setIsScreenLoading(false);
          if (res.role === 'user') {
            setIsFailed(true)
            setErrorMessage("You're not allowed to access this app please contact the system administrator to check your permission")
          } else {
            let role = res.role || "provider";
            const current_role = UI.role_map[role];
            mem.save("jwt_token", res.token);
            mem.save("role", role);
            UI.goTo(`/${current_role}/dashboard`);
          }
        } else {
          setIsScreenLoading(false);
          if (res.message.includes("wrong") || res.message.includes("expired")) {
            setErrorMessage(res.message);
          } else {
            setIsFailed(true);
            setErrorMessage(res.message);
          }
        }
      });
    };
  };

  const resendCode = () => {
    setIsScreenLoading(true)
    if (loginType) {
      if (loginType === "email-signup") {
        const payload = payloadCurrentInput;

        setIsScreenLoading(true);
        ServiceAuthApi.signupUsingEmail(payload).then(res => {
          if (res.status === 200) {
            setIsScreenLoading(false);
          } else {
            setIsScreenLoading(false);
            setIsFailed(true);
            setErrorMessage(res.message);
          }
        });
      }
    } else {
      const payload = payloadCurrentInput
      ServiceAuthApi.signInUsingMobileNumber(payload).then(res => {
        if (res.status === 200) {
          console.log(res)
          setIsScreenLoading(false)
        } else {
          setIsScreenLoading(false)
          setIsFailed(true)
          setErrorMessage(res.message)
        }
      })
    }
  };

  return (
    <>
      <Loading
        visible={isScreenLoading}
        title={""}
        text={`Verifying OTP...`}
        modal_opaque={false}
      />
      <ProcessFailedModal
        title={"Process Failed"}
        banner={<ProcessFailedModalCrossIcon />}
        description={errorMessage}
        buttonText={"Dismiss"}
        buttoncolor={"#004f99"}
        visible={isFailed}
        onOk={() => {
          UI.goTo(`/onboard/login`)
          setIsFailed(false)
        }}
        onCancel={() => setIsFailed(false)} // x and cancel button
      />
      <Col>
        <Row className='gap-2 cursor-pointer'
          onClick={() => {
            UI.goBack()
          }}
        >
          <Image
            height={20}
            width={20}
            tintColor={"#999999"}
            source={UI.CHEVRON_LEFT}
          />
          <Text level={5} style={{ color: "#999999" }}>Cancel</Text>
        </Row>
        <Col className='flex flex-col items-center gap-5'>
          <Col className='flex flex-col items-center gap-5'>
            <AntdImage preview={false} src={UI.MWELL_LOGO_1} alt="login_logo" />
            <StyledText level={4} >Please enter the Verification Code sent to your {loginType === "email-signup" ? "email address" : "mobile number"}</StyledText>
          </Col>
          <Text level={4}> {loginType === "email-signup" ? payloadCurrentInput?.email : (payloadCurrentInput?.phoneNumberPrefix + payloadCurrentInput?.phoneNumber) ?? ""} </Text>
          <StyledForm
            layout={"vertical"}
            onFinish={(e) => { }}
          >
            <OtpInput
              placeholder={"------"}
              containerStyle={{
                display: "flex",
                justifyContent: "space-between"
              }}
              inputStyle={{
                border: "2px solid #DFE5EB",
                color: "#000000",
                fontWeight: "bold",
                fontSize: 20,
                borderRadius: "10px",
                width: 70,
                height: 70
              }}
              hasErrored={true}
              errorStyle={{
                border: errorMessage ? "2px solid #CC0415" : "2px solid #DFE5EB"
              }}
              value={otpCode}
              onChange={handleChange}
              numInputs={6}
              separator={<span> </span>}
            />

            <Text level={4} style={{ textAlign: "center", color: "red", margin: 20, lineHeight: 100 }}>{errorMessage}</Text>

            <Text level={5} style={{ color: "#00CBFF", textAlign: "center", margin: 20, display: "flex", alignItems: "center", gap: 10, justifyContent: "center", }}>
              <TouchableOpacity
                style={{ ...count !== 0 && { cursor: "not-allowed", fontSize: 14, fontWeight: "bold", backgroundColor: "#ffff", color: "rgba(0, 0, 0, 0.25)" } }}
                onClick={() => {
                  if (count === 0) {
                    resendCode()
                    return setCount(60)
                  }
                }}
              >Resend Code
              </TouchableOpacity>
              <StyledBadge count={count}
                style={{ backgroundColor: '#00CBFF', borderRadius: 6 }}
              />
            </Text>

            <FormItem>
              <StyledButton
                disabled={otpCode.length > 5 ? false : true}
                id="submit-button"
                htmlType="submit"
                onClick={() => {
                  verifyOtpCode()
                }}
              >
                VERIFY
              </StyledButton>
            </FormItem>
          </StyledForm>
        </Col>
      </Col>
    </>
  )
}

export default OtpForm

const StyledBadge = styled(Badge)`
  
`;

const StyledText = styled(Text)`
  text-align: center;
  line-height: 30px !important;
  width: 390px;
`

const StyledForm = styled(Form)`
  width: 100%;
`;

// const StyledInputField = styled(InputField)`
//   font-size: 18px;
//   font-weight: bold;
//   text-align: center;
// `

// const StyledOtpInput = styled(OtpInput)`

//   .sc-cKajLJ {
//     background-color: red !important;
//   }
// `

// const StyledLinkTo = styled("a")`
// `;

// const StyledInputPasswordField = styled(InputPasswordField)`
//   .ant-input {
//     font-size: 18px !important;
//   }
// `

const StyledButton = styled(Button)`
  background-color: #004F99;
  border-radius: 12px;
  width: 100%;
  height: 70px;
  border: none !important;

  color: #ffffff;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
 
  &:disabled {
    background-color: #D7E2EB;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #00CBFF;
    color: #ffffff;
    border: none !important;
  }
`;