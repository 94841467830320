// import { NotApproved } from "../../modules/_components/Modal"; // currently not used
import UI from "../Components/UI/js";
import { mem } from "../custom-react-native";
import APIConstants from "./APIConstants";

// const get_param = (param_name) => {
//   var url_string = window.location.href;
//   var url = new URL(url_string);
//   var c = url.searchParams.get(param_name);
//   return c;
// };

class APINavigation extends APIConstants {
  constructor(props) {
    super(props);
    const { _this } = props;
    this._this = _this;
    _this.check_onboard_details = this.check_onboard_details;
  }

  _this = null;

  account_check = async () => {
    return new Promise((resolve, reject) => {
      const token = mem.get(this.JWT_TOKEN);
      if (token) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  init = (props) => {
    return new Promise(async (resolve, reject) => {
      const code = mem.get(this.JWT_TOKEN);
      const role = mem.get(this.ROLE);
      const current_role = UI.role_map[role];
      //check if code is existing in url

      if (code) {
        //check if already logged in
        if (await this.account_check()) {
          //logged in
          if (current_role === "onboard") {
            await this.check_onboard_details();
          } else {
            this.get_user_details();
          }
          resolve();
        } else {
          await this.azure_b2c_auth(code);
          resolve();
        }
      } else {
        //check if already logged in
        if (await this.account_check()) {
          //logged in
          if (current_role === "onboard") {
            await this.check_onboard_details();
          } else {
            this.get_user_details();
          }
          resolve();
        } else {
          this.redirect_to_login();
        }
      }
    });
  };

  get_user_details = async () => {
    const _this = this._this;
    _this.show_loading();
    UI.newAuthRequest({
      method: "get",
      url: "app/mwell/auth/me",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = response.data.d;
        mem.save("user_data", JSON.stringify({ name: data.name }));
        mem.save("admin", JSON.stringify(data));
      },
      onFinish: () => {
        _this.hide_loading();
      },
    });
  };

  // azure_b2c_auth = async (
  //   code,
  //   policy_name = "B2C_1_Authentication_Doctor"
  // ) => {
  //   return new Promise(async (resolve, reject) => {
  //     const _this = this._this;
  //     if (_this.show_loading) {
  //       _this.show_loading("", "Logging in...");
  //     }

  //     UI.authRequest({
  //       method: "post",
  //       url: "app/mwell/auth/web/token",
  //       params: {
  //         code,
  //         policy_name: policy_name,
  //         platform: "web",
  //       },
  //       onFail: async (err) => {
  //         console.log(err);
  //         this.handle_api_failure({
  //           err: err,
  //           onInvalidGrant: () => {
  //             let policy = "B2C_1_Registration_Doctor";

  //             const auth_btn_type = mem.get("auth_btn_type");
  //             if (auth_btn_type === "signup") {
  //               policy_name = "B2C_1_Registration_Doctor";
  //             }

  //             this.azure_b2c_auth(code, policy);
  //           },
  //           onFailure: () => {
  //             if (_this.hide_loading) {
  //               _this.hide_loading();
  //             }
  //             this.redirect_to_login();
  //           },
  //         });
  //       },
  //       onSuccess: async (response) => {
  //         let {
  //           token,
  //           role,
  //           url,
  //           prcTextREminder,
  //           birTextReminder,
  //           // allRole
  //         } = response.data.d;

  //         // if (role === "user") {
  //         //   if (allRole.length > 0) {
  //         //     token = allRole[0]
  //         //   } else {
  //         //     token = "user"
  //         //   }
  //         // }

  //         // console.log(role);
  //         await mem.save(this.JWT_TOKEN, token);
  //         await mem.save(this.ROLE, role);

  //         const current_role = UI.role_map[role];
  //         if (current_role === "onboard") {
  //           await this.check_onboard_details(prcTextREminder, birTextReminder);
  //         } else {
  //           if (url) {
  //             window.location.reload();
  //             //window.location.href = url;
  //           } else {
  //             if (_this.hide_loading) {
  //               _this.hide_loading();
  //             }
  //             resolve();
  //           }
  //         }
  //       },
  //       onFinish: async () => {},
  //     });
  //   });
  // };

  handle_api_failure = (
    props = { err: null, onInvalidGrant: () => {}, onFailure: () => {} }
  ) => {
    const { err, onInvalidGrant, onFailure } = props;
    if (err) {
      if (err.data) {
        if (err.data.m) {
          // err.data.m.error.includes("invalid_grant")
          if (err?.data?.m?.includes("AADB2C90088")) {
            onInvalidGrant(err);
          } else {
            onFailure(err);
          }
        } else {
          onFailure(err);
        }
      } else {
        onFailure(err);
      }
    } else {
      onFailure(err);
    }
  };

  check_onboard_details = async (
    prcTextREminder = null,
    birTextReminder = null
  ) => {
    return new Promise(async (resolve, reject) => {
      const _this = this._this;
      _this.show_loading("", "Loading...");
      UI.newAuthRequest({
        method: "get",
        url: "app/mwell/auth/me",
        params: {},
        onFail: async (err) => {},
        onSuccess: async (response) => {
          const data = response.data.d;
          if (data.isDoctorsOnboardingApproved === "") {
            data.isDoctorsOnboardingApproved = "Pending";
          }
          mem.save("user_data", JSON.stringify({ name: data.name }));
          await mem.save("onboard_data", JSON.stringify(data));
          // console.log("Doctor Status", data.isDoctorsOnboardingApproved);
          if (data.doctor) {
            if (
              data.isAdvocacyOnboardingApproved?.step3?.status === "Approved" ||
              data.isAdvocacyOnboardingApproved?.step3?.status === "Approve"
            ) {
              data.isDoctorsOnboardingApproved = "Approved";
            }
            // console.log(data)
            //already submitted, check if already approved
            if (data.isDoctorsOnboardingApproved === "Approved") {
              //doctor is approved, continue to dashboard
              const current_link = this.get_second_slash();
              const required_pages = [
                "dashboard",
                "user-profile",
                "availability",
                "advocacy-availability",
                "referral",
                "calendar",
                "nutrition-program",
                "encounter",
                "notification",
                "attendance",
                "tax",
                "settings",
              ];
              mem.save(this.USER_DOCTOR, JSON.stringify(data.doctor));
              mem.save("create_user", JSON.stringify(data));
              // console.log(required_pages, current_link)
              if (required_pages.includes(current_link)) {
              } else {
                if (prcTextREminder) {
                  mem.save("prcTextREminder", prcTextREminder);
                  if (birTextReminder) {
                    mem.save("birTextReminder", birTextReminder);
                  }
                } else {
                  if (
                    _this.props.location.pathname ===
                    "/onboard/forget-password-step-1"
                  ) {
                    return UI.goTo("/onboard/forget-password-step-1");
                  } else {
                    return UI.goTo(`/onboard/login-select`);
                  }
                }
              }

              resolve();
            } else if (data.isDoctorsOnboardingApproved === "Disapproved") {
              //failed submission
              mem.save(this.USER_DOCTOR, JSON.stringify(data.doctor));
              mem.save("create_user", JSON.stringify(data));
              this.redirect_to_update_user();
              resolve();
            } /*  else if (data.isDoctorsOnboardingApproved == false) {
              //edit submission
              mem.save(this.USER_DOCTOR, JSON.stringify(data.doctor));
              this.redirect_to_update_user();
              resolve();
            } */ else {
              mem.save(this.USER_DOCTOR, JSON.stringify(data.doctor));
              mem.save("create_user", JSON.stringify(data));
              this.redirect_to_update_user();
              resolve();

              /* mem.clear();
              _this.show_modal(
                <NotApproved
                  onDismiss={() => {
                    mem.clear();
                    UI.logout_context(_this);
                    _this.hide_modal();
                    this.redirect_to_login();
                  }}
                />
              ); */
            }
          } else {
            //not yet submitting
            this.redirect_to_create_user();
            resolve(JSON.stringify(data));
          }
        },
        onFinish: () => {
          _this.hide_loading();
        },
      });
    });
  };

  get_second_slash = () => {
    const url = window.location.pathname;
    const arr = url.split("/");
    if (arr.length > 2) {
      return arr[2];
    } else {
      return "";
    }
  };

  redirect_to_create_user = () => {
    const _this = this._this;
    if (_this) {
      _this.props.history.push("/onboard/create-user");
    }
  };

  redirect_to_update_user = () => {
    const _this = this._this;
    if (_this) {
      // _this.props.history.push("/onboard/user-profile");
      _this.props.history.push("/onboard/create-user");
    }
  };

  redirect_to_login = () => {
    const _this = this._this;
    if (_this) {
      mem.clear();
      const allowed_pages = ["signup"];
      if (allowed_pages.includes(_this.state.context)) {
        return;
      }
      _this.props.history.push("/" + _this.state.context + "/login");
    }
    //
  };

  // azure_b2c_login = async () => {
  //   const _this = this._this;

  //   mem.save("auth_btn_type", "login");
  //   _this.show_loading("", "Redirecting to Azure...");

  //   UI.authRequest({
  //     method: "get",
  //     url: "app/mwell/auth/authorize?policy_name=B2C_1_Authentication_Doctor&platform=web",
  //     params: {
  //       ...UI.Constants.ADDITIONAL_ENVIRONMENT,
  //     },
  //     onFail: async (err) => {
  //       _this.hide_loading();
  //     },
  //     onSuccess: async (response) => {
  //       window.location.href = response.data.d.url;
  //     },
  //     onFinish: () => {},
  //   });
  // };
}

export default APINavigation;
