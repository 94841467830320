import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import { Text } from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import "./css.css";

export default class AddressInformation extends Component {
  render() {
    const _this = this.props._this;
    const { width } = _this.state;
    const row_width = width > 1000 ? width - 400 : "100%";

    return (
      <UI.Column
        style={{ marginBottom: 10 }}
        _this={_this}
        breakpoint={360}
        breakpoint_2={820}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: UI.colors.primary,
            marginTop: 30,
          }}
        >
          ADDRESS INFORMATION
        </Text>
        <Text style={{ color: "rgb(191, 191, 191)", marginBottom: 20 }}>
          Address provided will be displayed in the MOA.
        </Text>

        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          {/* <InputForm
            _this={_this}
            title={"Floor Number"}
            placeholder={"Floor Number"}
            state_name={"floor_number"}
            isRequired={true}
            isNumber={true}
            maxLength={13}
            style={{ flex: 1 }}
          />
          {UI.box(10)} */}
          <InputForm
            _this={_this}
            title={"House/Unit Number"}
            placeholder={"House/Unit Number"}
            state_name={"house_number"}
            style={{ flex: 1 }}
            // isRequired={true}
            // isNumber={true}
            maxLength={UI.Constants.limits.name}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Street Name"}
            placeholder={"Street Name"}
            state_name={"street_name"}
            style={{ flex: 1 }}
            isRequired={true}
            maxLength={UI.Constants.limits.name}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Barangay"}
            placeholder={"Barangay"}
            state_name={"barangay"}
            style={{ flex: 1 }}
            isRequired={true}
            maxLength={UI.Constants.limits.name}
          />
        </UI.Row>
        {UI.box(20)}
        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <InputForm
            _this={_this}
            title={"City/Municipality"}
            placeholder={"City/Municipality"}
            state_name={"city_municipality"}
            isRequired={true}
            style={{ flex: 1 }}
            maxLength={UI.Constants.limits.name}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Province"}
            placeholder={"Province"}
            state_name={"province"}
            isRequired={true}
            style={{ flex: 1 }}
            maxLength={UI.Constants.limits.name}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Zip Code"}
            placeholder={"Zip Code"}
            state_name={"zip_code"}
            style={{ flex: 1 }}
            isRequired={true}
            isNumber={true}
            maxLength={UI.Constants.limits.name}
          />
        </UI.Row>
      </UI.Column>
    );
  }
}
