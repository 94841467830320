import React, {  useEffect, useState } from 'react'
import UI from "../../shared/Components/UI/js";
import TouchableOpacity, { mem, Shadow, StyleSheet, Text, View } from '../../shared/custom-react-native';
import { Indicator } from '../_components/Layout';
import { LoadingEncounter } from '../_components/Loading';
import PatientHistory from './PatientHistory';

const Web = (props) => {
  const [isShowSideBar, setIsShowSideBar] = useState(false)
  const { localVideo, remoteVideo } = props;
  const { width, will_show_video, show_remote_video, appointment, show_loading_initialize } = props._this.state;
  const [timeDuration, setTimeDuration] = useState(null)
  const data = mem.get("onboard_data");
  const user_data = JSON.parse(data);
  
  const onReturnToCall = () => {
    setIsShowSideBar(false)
  }

  const getDurationTimeCall = () => {
    let seconds = 0;
    let minutes = 0
    
    setInterval(() => {
      seconds++;
      if(seconds === 60) {
        minutes++
        seconds = 0
        setTimeDuration({minutes})
      }
      setTimeDuration({minutes, seconds})
    }, 1000)
  }
  
  useEffect(() => {
    if(timeDuration === null) {
      if(will_show_video) {
        getDurationTimeCall()
      }
    }
  }, [timeDuration, will_show_video])

  return (
      <div className={`grid ${width > 800 ? "grid-cols-5": "grid-cols-1"} `} style={styles.main_container}>

        <View
          className="col-span-2"
          style={{  position:'relative', justifyContent: "center", alignItems: "center",
          display: isShowSideBar === true ? 'none' : 'flex'
        }}
        >
          <LoadingEncounter 
            _this={props._this}
            visible={ show_loading_initialize }
            title={"Please wait while preparing your device..."}
            text={props._this.state.loading_msg}
            modal_opaque={false}
          />
          {!show_remote_video && <img
            style={{ 
              height: width > 800 ? 180 : 90, 
              width: width > 800 ? 200 : 110, 
              background:'rgb(4, 204, 255)',
              borderRadius: 10,
              }}
            src={UI.DEFAULT_AVATAR_PATIENT}
            alt=""
          />}
          
            {remoteVideo}

          <View style={{...styles.host_container, top: width > 800 ? 60 : 20,
              left:  width > 800 ? 38 : 10,
          }}>
            <View
              style={{
                flexDirection:'row',
                height: 180 ,
                // width: width > 800 ? 200 : 105 ,
                borderRadius: 10,
                // justifyContent: "center",
                // alignItems: "center",
              }}
            >
              
              {localVideo}  
            <img
              style={{
                display: will_show_video ? "none" : "flex",
                height: width > 800 ? 180 : 90,
                width: width > 800 ? 200 : 110,
              }}
              src={UI.DEFAULT_AVATAR_DOCTOR}
              alt=""
            />
            
            <Indicator text={'You'} position={{
              position: 'absolute',
              right: width > 800 ? 5 : 2,
              bottom: width > 800 ? 10 : 80 ,
              backgroundImage:"linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2))",
              padding: width > 800 ? '5px 20px' : '0px 10px',
              borderTopLeftRadius:'10px',
              fontSize:18
            }} />

            </View>


          </View>

          <img src={UI.LOGO_MWELL} alt="" style={{position:"absolute", right:0, top: 0, padding: "60px 60px"}}/>

          {
            (props._this.state.appointment?.status === "Started" || props._this.state.appointment?.status === "Booked") 
            
            ? 

            <>
              <Indicator text={
                `00: ${(timeDuration?.minutes >= 10 ? timeDuration?.minutes : `0${timeDuration?.minutes || 0}`) || 0} 
                : 
                ${(timeDuration?.seconds >= 10 ?  timeDuration?.seconds : `0${timeDuration?.seconds || 0}`) || 0}
                `
              }
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top:"calc(100% - 220px)",
                  display: 'flex',
                  textAlign:'center',
                  // backgroundImage:`linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2))`,
                  padding:'10px 20px',
                  borderBottomRightRadius:'10px',
                  fontSize:'30px !important'
                }}
              />
              <View style={styles.btns_container}>
                <TouchableOpacity
                  onClick={() => {
                    props._this.toggle_camera();
                  }}
                >
                  <img
                    style={{ height: 60, width: 60 }}
                    src={
                      props._this.state.will_show_video
                        ? UI.CALL_VIDEO_ACTIVE
                        : UI.CALL_VIDEO_INACTIVE
                    }
                    alt=""
                  />
                </TouchableOpacity>
                {UI.box(30)}
            
                <TouchableOpacity
                  onClick={() => {
                    props._this.toggle_audio();
                  }}
                >
                  <img
                    style={{ height: 60, width: 60 }}
                    src={props._this.state.will_talk ? UI.CALL_AUDIO_ACTIVE : UI.CALL_AUDIO_INACTIVE}
                    alt=""
                  />
                </TouchableOpacity> 
                {UI.box(30)}
                <TouchableOpacity
                  onClick={() => {
                    props._this.end_call(appointment._id);
                  }}
                >
                  <img style={{ height: 60, width: 60 }} src={UI.CALL_ENDED} 
                    alt=""
                  />
                </TouchableOpacity>
              </View>
            </>
            : '' 
          }
          
          <TouchableOpacity
            onClick={() => {
              setIsShowSideBar(true)
            }}
            style={{
              ...styles.call_notes, 
              display: width > 800 ? 'none' : 'flex',
              bottom: width > 800 ? 90 : 40,
              left: width > 800 ? 30 : 20 ,
            }}
          >
          <img style={{ height: width > 800 ? 80 : 30, width: width > 800 ? 80 : 30 }} src={UI.MENU} alt=""/>
          </TouchableOpacity>
        </View>

        <View
          className={`${width > 800 ? "col-span-3" : "col-span-5"}`}
          style={{
            ...styles.side_bar,
            ...Shadow._3(),
            display: width > 800 || isShowSideBar === true ? 'flex' : 'none',
            height: "100vh"
          }}
        >
          <div className='p-4 flex items-center justify-between'>
            <div>
              <img
                height={80}
                width={80}
                src={UI.LOGO_MWELL}
                alt=""
              />
            </div>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                fontWeight:700,
                alignSelf: width > 840 ? "center" : "flex-end",
              }}
            >
              <Text style={styles.header_text}>Welcome, {user_data?.name ?? " "}</Text>
              {UI.box(20)}
              <img style={{ height: 40, width: 40 }} src={UI.DOCTOR_PROFILE} alt=""/>
              {UI.box(10)}
            </View>
          </div>
          <PatientHistory _this={props._this} width={width} onReturnToCall={onReturnToCall} appointment={appointment}/>
        </View>

      </div>
  )
}

export default Web

const styles = StyleSheet.create({
  my_notes_text: {
    color: "#00CBFF",
    fontSize: '15px',
    fontWeight: "bold",
  },
  my_notes_container: {
    height: 350,
    width: 360,
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "white",
    borderTopLeftRadius: 26,
    ...Shadow._5(),
  },
  call_notes: {
    position: "absolute",
  },
  btns_container: {
    position: "absolute",
    bottom: 90,
    alignSelf: "center",
    flexDirection: "row",
  },
  host_img: {
    height: "100%",
    width: "100%",
    borderRadius: 10,
    objectFit: "cover",
  },
  side_bar: {
    flex: 1,
    // width: 1200,
    backgroundColor: "white",
  },
  main_container: {
    background:'#34495E',
    height: "100%",
    width: "100%",
    flexDirection: "row",
  },
  host_container: {
    // height: 170,
    // width: 170,
    // backgroundColor: "white",
    borderRadius: 10,
    // ...Shadow._3("#646464"),
    position: "absolute",
 
  },
});