import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import TouchableOpacity, {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";

const context = "advocacy";
export default class EditQuestionnaire extends Component {
  constructor(props) {
    super(props); 
    UI.initiateView(this, {
      active_tab: "Advocacy List",
      is_drawer_open: false,
      textFieldArray:[],
      dropDownSelectionArray:[],
      typeOfQuestion:null,
    });
  }

  onCreate = async () => {
    this.load_data();
  };

  load_data = async () => {
    const data = mem.get("edit_questionnaire");
    const advocacy = JSON.parse(data);
    const questionnaire = advocacy.advocacysurveycms
    let textFieldArray =[]
    let dropDownSelectionArray =[]

    const result = questionnaire.map(a => {
      if(a.answerType === "DropDownSelection") {
        dropDownSelectionArray.push(a)
      }
      if(a.answerType === "TextField") {
        textFieldArray.push(a)
      }
      return a
    });

    if(result) {
      this.setState({
        textFieldArray:textFieldArray,
        dropDownSelectionArray: dropDownSelectionArray,
      });
    }
  };

  onChange = (e,  type, index, option) => {
    const { 
      name, value
    } = e.target;

    if(type === "textField") {
      const list = this.state.textFieldArray
      list[index][name] = value;
        this.setState({textFieldArray: [...list]})
    }
    
    if(type === "dropDownField") {
      const list = this.state.dropDownSelectionArray

      if (name === "answerOptions") {
        list[index].answerOptions[option] = value
        this.setState({dropDownSelectionArray:  [...list]})
      }
      if (name === "question") {
        list[index].question = value;
        this.setState({dropDownSelectionArray: [...list]})
      }
    }
  }

  addQuestion = () => {
    if(this.state.typeOfQuestion === "TextField") {
      this.setState({textFieldArray: [...this.state.textFieldArray, { question:"", answerType: this.state.typeOfQuestion, answerOptions:[]}]})
    }
    if(this.state.typeOfQuestion === "DropDownSelection") {
      this.setState({dropDownSelectionArray: [...this.state.dropDownSelectionArray, { question:"", answerType: this.state.typeOfQuestion, answerOptions: [""]}]})
    }
  }

  removeQuestion = (i, type) => {
    if(type === "textField") {
      const result = this.state.textFieldArray.filter((a, f) =>  f !== i)
      this.setState({textFieldArray: result})
    }

    if(type === "dropDownField") {
      const result = this.state.dropDownSelectionArray.filter((a, f) =>  f !== i)
      this.setState({dropDownSelectionArray: result})
    }
  }

  addOption = (indexdropDown) => {
    this.state.dropDownSelectionArray[indexdropDown].answerOptions.push('')
    this.setState({dropDownSelectionArray: this.state.dropDownSelectionArray})
  }
  
  removeOption = (indexdropDown, optionIndex) => {
    const result = this.state.dropDownSelectionArray[indexdropDown].answerOptions.filter((a, f) =>  optionIndex !== f)
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.dropDownSelectionArray[indexdropDown].answerOptions = result
    this.setState({dropDownSelectionArray: this.state.dropDownSelectionArray})
  }

  submit = () => {
    const data = mem.get("edit_questionnaire");
    const advocacy = JSON.parse(data);
    const advocacyId = advocacy._id

    const payload = [...this.state.textFieldArray, ...this.state.dropDownSelectionArray]
    const result = payload.map(res => {
      console.log(res)
      delete res.createdAt
      delete res.__v
      delete res._id
      return res
    })

   UI.webRequest({
      method: "put",
      url: "admin/advocacies/update-questionnaire",
      params: {
        advocacyId: advocacyId,
        survey:result
      },
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.error;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        this.show_modal(
          <Success
            title={"Success!"}
            descriptionCenter={"Successfully updated the questionnaire."}
            onDismiss={() => {
              this.hide_modal();
              const data = UI.extract_data(response);
              mem.save("view_advocacy", JSON.stringify(data.data));
              mem.save("advocacy_id", data.data._id);
              UI.goTo("/advocacy/view")
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  render() {
    const _this = this;
    const { width } = _this.state;
    const data = mem.get("edit_questionnaire");
    const advocacy = JSON.parse(data);
    const questionnaire = advocacy.advocacysurveycms
    return (
    <MainLayout context={context} _this={_this}>
      <View style={styles.main_box_content}>
        <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
          <Text style={{ ...styles.box_header_text, flex: 1 }}>
            Update Advocacy Survey
          </Text>

          <Text style={styles.text_2}>
            Customize your advocacy survey here
          </Text>
        </UI.Row>

        <View style={{flexDirection:'row'}}>
          <View View style={{  width: 300 }}>
            <Layout.SelectPicker
              _this={_this}
              isRequired={false}
              onChange={(e) => {
                this.setState({typeOfQuestion: e})
              }}
            >
              <option value="">Select Questionnaire Type</option>
              <option value="TextField">Text Field</option>
              <option value="DropDownSelection">Drop Down Selection</option>
            </Layout.SelectPicker>
          </View>
          {UI.box(20)}
          <Layout.Btn
            color={UI.colors.primary}
            onClick={() => {
              this.addQuestion()
            }}
            text={"ADD QUESTION"}
          />
        </View>
   
        {UI.box(20)}
            
            {/* START TEXTFIELD */}
            {  this.state.textFieldArray && this.state.textFieldArray.length > 0 && 
              <View style={{
                border: "2px dashed #C9C9C9",
                borderRadius: "12px",
                padding:'25px'
              }}>
                {
                  this.state.textFieldArray && this.state.textFieldArray.map((text, index) => 
                      <View>
                        <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
                          <Text style={{color: UI.colors.primary, fontWeight:'bold', fontSize:'15px', flex:1}}>
                            Item # {index + 1} ( Text Field )
                          </Text>
                          
                          <View style={{ flexDirection: "row" }}>
                            {/* <TouchableOpacity
                              onClick={async () => {
                                // this.delete(item._id);
                                // const res = this.s tate.data.filter(a => a.id !== item.id)
                                // console.log(res)
                                // this.setState({
                                //   data: res,
                                //   all_data: res,
                                // })
                                // this.reload_table("advocacy")
                                // console.log(first)
                              }}
                            >
                            <Image
                              height={28}
                              width={28}
                              tintColor={"transparent"}
                              source={UI.MOVEDOWN}
                            />
                            </TouchableOpacity>
                            {UI.box(10)}
                            <TouchableOpacity
                            onClick={async () => {
                              // this.delete(item._id);
                              // const res = this.state.data.filter(a => a.id !== item.id)
                              // console.log(res)
                              // this.setState({
                              //   data: res,
                              //   all_data: res,
                              // })
                              // this.reload_table("advocacy")
                              // console.log(first)
                            }}
                          >
                            <Image
                              height={28}
                              width={28}
                              tintColor={"transparent"}
                              source={UI.MOVEUP}
                            />
                          </TouchableOpacity> */}
                          {UI.box(10)}
                          <TouchableOpacity
                            onClick={async () => {
                              this.removeQuestion(index, 'textField')
                            }}
                          >
                          <Image
                            height={28}
                            width={28}
                            tintColor={"transparent"}
                            source={UI.DELETE_BTN}
                          />
                        </TouchableOpacity>
                        </View>
                  
                      </UI.Row>
            
                      {UI.box(5)}
                      
                      <Text style={{color: UI.colors.primary, fontWeight:'bold', fontSize:'15px'}}>
                        Enter Question Description Here
                      </Text>
            
                      <UI.Row breakpoint={970} _this={_this}>
                        <input
                          autocomplete="off"
                          style={{
                            fontSize: '14px',
                            resize: "none",
                            color: 'black',
                            width: "100%",
                            padding:'10px 14.44px' ,
                            borderRadius:'10px',
                            border: '2px solid #C9C9C9',
                            marginTop:'5px'
                          }}
                          placeholder={"Enter Question Description"}
                          onChange={(e) => this.onChange(e,  'textField', index)}
                          name="question"
                          value={text.question}
                        />
                      </UI.Row>
            
                      {UI.box(10)}
                      </View>
                  )
                }
              </View>
            }

            {UI.box(20)}

            { 
              this.state.dropDownSelectionArray && this.state.dropDownSelectionArray.length > 0 && 
                <View style={{
                  border: "2px dashed #C9C9C9",
                  borderRadius: "12px",
                  padding:'25px'
                }}> 
                  {
                    this.state.dropDownSelectionArray && this.state.dropDownSelectionArray.map((dropdown, indexdropDown) => 
                    <View>
                      <View>
                        <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
                          <Text style={{color: UI.colors.primary, fontWeight:'bold', fontSize:'15px', flex:1}}>
                            Item # {indexdropDown + 1} ( Drop Down Selection )
                          </Text>
                          
                          <View style={{ flexDirection: "row" }}>
                            {/* <TouchableOpacity
                              onClick={async () => {
                                }}
                              >
                              <Image
                                height={28}
                                width={28}
                                tintColor={"transparent"}
                                source={UI.MOVEDOWN}
                              />
                            </TouchableOpacity>
                              {UI.box(10)}
                              <TouchableOpacity
                              onClick={async () => {
                              }}
                            >
                            <Image
                              height={28}
                              width={28}
                              tintColor={"transparent"}
                              source={UI.MOVEUP}
                            />
                            </TouchableOpacity> */}
                            {UI.box(10)}
                            <TouchableOpacity
                              onClick={async () => {
                                this.removeQuestion(indexdropDown, 'dropDownField')
                              }}
                            >
                              <Image
                                height={28}
                                width={28}
                                tintColor={"transparent"}
                                source={UI.DELETE_BTN}
                              />
                            </TouchableOpacity>
                          </View>

                        </UI.Row>
          
                        {UI.box(5)}
                        
                        <Text style={{color: UI.colors.primary, fontWeight:'bold', fontSize:'15px'}}>
                          Enter Question Description Here
                        </Text>
              
                        <UI.Row breakpoint={970} _this={_this}>
                          <input
                            autocomplete="off"
                            style={{
                              fontSize: '14px',
                              resize: "none",
                              color: 'black',
                              width: "100%",
                              padding:'10px 14.44px' ,
                              borderRadius:'10px',
                              border: '2px solid #C9C9C9',
                              marginTop:'5px'
                            }}
                            placeholder={"Enter Question Description"}
                            onChange={(e) => this.onChange(e,  'dropDownField', indexdropDown)}
                            name="question"
                            value={dropdown.question}
                          />
                        </UI.Row>
                        {UI.box(10)}
                      </View>
                      
                      <Text Text style={{color: UI.colors.primary, fontWeight:'bold', fontSize:'15px'}}>
                        Enter Selection Items
                      </Text>

                      <UI.Row breakpoint={970} _this={_this}>
                        <View>
                          {
                            dropdown.answerOptions.map((option, optionIndex) => (
                              <View style={{flexDirection:'row'}}>
                                <input
                                  autocomplete="off"
                                  style={{
                                    fontSize: '14px',
                                    resize: "none",
                                    color: 'black',
                                    width: 400,
                                    padding:'10px 14.44px' ,
                                    borderRadius:'10px',
                                    border: '2px solid #C9C9C9',
                                    marginTop:'5px'
                                  }}
                                  placeholder={"Enter Option Description"}
                                  onChange={(e) => this.onChange(e,  'dropDownField', indexdropDown, optionIndex)}
                                  name="answerOptions"
                                  value={option}
                                  // defaultValue={option}
                                />

                                {UI.box(20)}

                                <View style={{justifyContent:'center'}}>
                                  <TouchableOpacity
                                    onClick={() => {
                                      this.removeOption(indexdropDown,optionIndex)
                                    }}
                                  >
                                    <Image
                                      height={28}
                                      width={28}
                                      tintColor={"transparent"}
                                      source={UI.DELETE_BTN}
                                    />
                                  </TouchableOpacity>
                                </View>
                              </View>
                            ))
                          }
                        </View>
                      </UI.Row>

                      {UI.box(20)}
                      <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Layout.Btn
                          color={UI.colors.primary}
                          onClick={() => {
                            this.addOption(indexdropDown)
                          }}
                          text={"ADD OPTION"}
                        />
                      </View>
                      {UI.box(10)}
                    </View>
                    )
                  }
                </View>
            }
        {UI.box(20)}

        <View
            style={{
              ...styles.no_info_container,
              display: questionnaire.length > 0 ? "none" : "flex",
            }}
          >
          <UI.Row
            breakpoint={820}
            style={{ maxWidth: 340, alignItems: "center" }}
            _this={_this}
          >
            <Image
              height={100}
              width={100}
              tintColor={"transparent"}
              source={UI.NO_INFO}
            />
            {UI.box(10)}
            <Text
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#BFBFBF",
                textAlign: width > 820 ? "left" : "center",
                marginLeft: width > 820 ? 20 : 0,
              }}
            >
              NO QUESTIONS ADDED YET
            </Text>
          </UI.Row>
        </View>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Layout.Btn
            color={
              // this.state.textFieldArray.length > 0 || this.state.dropDownSelectionArray.length > 0 ? UI.colors.primary : UI.colors.unset
              UI.colors.primary
            }
            onClick={() => {
                this.submit();
              // if(this.state.textFieldArray.length > 0 || this.state.dropDownSelectionArray.length > 0) {
              //   this.submit();
              // }
            }}
            text={"SAVE CHANGES"}
          />
        
          {UI.box(20)}

          <Layout.Btn
            color={"#E00000"}
            onClick={() => {
              UI.goBack();
            }}
            text={"CANCEL"}
          />

        </View>

        {UI.box(10)}
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});
