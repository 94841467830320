import UI from "../shared/Components/UI/js";
import ServiceApi from "./service";

class MedicalAssociationApi {

    baseURL = 'admin';

    async getAllMedicalAssociations (params) {
        const response = await ServiceApi.get(`${this.baseURL}/medical-associations`, params);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async addMedicalAssociation (params) {
        const response = await ServiceApi.post(`${this.baseURL}/medical-associations`, params);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async updateMedicalAssociation (id, params) {
        const response = await ServiceApi.put(`${this.baseURL}/medical-associations/${id}`, params);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }

    async deleteMedicalAssociation (id) {
        const response = await ServiceApi.delete(`${this.baseURL}/medical-associations/${id}`);

        if (response?.status === 200) {
            const data = UI.extract_data(response);
            data.status = 200;
            return data;
        } else {
            return {
                status: response.status,
                data: response.data.d,
                message: response.data.m
            }
        }
    }
    
}

export default new MedicalAssociationApi()