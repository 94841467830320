import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import MainLayout from "../_components/MainLayout";
import "./css.css";
const context = "admin";

export default class AdminViewInquiry extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Inquiries",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;
    const { 
      // height,
       width, 
      // isMobile
     } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                borderRightWidth: width >= 1250 ? 2 : 0,
                borderBottomWidth: width < 1250 ? 2 : 0,
                paddingRight: width < 1250 ? 0 : 20,
                marginRight: width < 1250 ? 0 : 20,
                paddingBottom: 10,
                marginBottom: width < 1250 ? 10 : 0,
              }}
            >
              <View style={styles.fl_ai}>
                <Image
                  height={100}
                  width={100}
                  tintColor={"transparent"}
                  source={UI.SAMPLE_PRODUCT}
                />

                {UI.box(width < 980 ? 5 : 10)}

                <View>
                  <Text style={styles.patient_name}>Product Name Here</Text>
                  <Text
                    style={{
                      ...styles.patient_name,
                      color: UI.colors.primary,
                      fontSize: 14,
                    }}
                  >
                    Php 000.00
                  </Text>
                  <Text
                    style={{
                      ...styles.patient_name,
                      color: "#454545",
                      fontSize: 14,
                    }}
                  >
                    Status: <Text style={{ color: "#00EB2D" }}>Active</Text>
                  </Text>
                </View>
              </View>

              <View style={styles.date_container}>
                <Image
                  height={30}
                  width={30}
                  tintColor={UI.colors.primary}
                  source={UI.CALENDAR_3}
                />
                <Text style={styles.appointment_text}>
                  Product ID: 00001010101
                </Text>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <UI.Row _this={_this}>
                <UI.Row breakpoint={970} _this={_this} style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.PAYMENT_METHOD}
                    name={"Purchase Limit"}
                    value={"000"}
                  />
                  <UserDetail
                    icon={UI.COMMERCE_INVENTORY}
                    name={"Stock"}
                    value={"000"}
                  />
                  <UserDetail
                    icon={UI.DETAILS_AGE}
                    name={"Date Added"}
                    value={"mm/dd/yyyy"}
                  />
                </UI.Row>
              </UI.Row>
              {UI.box(10)}
              <Text style={styles.details_name}>Product Description</Text>

              <Text
                style={{
                  ...styles.patient_name,
                  color: "#454545",
                  fontSize: 14,
                }}
              >
                Enter product description here. Lorem Ipsum is simply dummy text
                of the printing and typesetting industry. Lorem Ipsum has been
                the industry’s standard dummy text ever since the 1500s.
              </Text>
            </View>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, marginTop: 20 }}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Product Inquiry
            </Text>

            <Text style={styles.text_2}>View Product Inquiry Here</Text>
          </UI.Row>

          <UI.Row breakpoint={1010} _this={_this} style={{ width: "100%" }}>
            <UI.Row
              _this={_this}
              breakpoint={480}
              style={{
                paddingTop: 20,
                paddingBottom: 20,
                alignItems: "center",
              }}
            >
              <Image
                height={60}
                width={60}
                tintColor={"transparent"}
                source={UI.SAMPLE_USER}
              />

              {UI.box(15)}

              <View>
                <Text style={styles.patient_name}>
                  Product Inquiry by: Patient Name Here
                </Text>
                <Text style={styles.patient_details}>
                  emailaddress@email.com | +63 995 123 4567
                </Text>
              </View>
            </UI.Row>

            {UI.box(width <= 1010 ? 0 : 30)}

            <UI.Row
              breakpoint={1280}
              _this={_this}
              style={{ flexDirection: "row", flex: 1 }}
            >
              <View style={{ ...styles.fl_ai, flex: 1 }}>
                <Image
                  height={25}
                  width={25}
                  tintColor={"transparent"}
                  source={UI.DETAILS_AGE}
                />
                {UI.box(10)}

                <Text style={styles.details_value}>
                  Transaction Date: mm/dd/yyyy
                </Text>
              </View>
              {UI.box(10)}
              <View style={{ ...styles.fl_ai, flex: 1 }}>
                <Image
                  height={25}
                  width={25}
                  tintColor={"transparent"}
                  source={UI.DETAILS_AGE}
                />
                {UI.box(10)}

                <Text style={styles.details_value}>
                  Inquiry Date: mm/dd/yyyy
                </Text>
              </View>
            </UI.Row>
          </UI.Row>

          {UI.box(10)}

          <Text style={styles.sub_header}>Customer Inquiry</Text>

          <View style={styles.inquiry_container}>
            <Text style={styles.inquiry_description}>
              Enter customer inquiry here. Lorem Ipsum is simply dummy text of
              the printing and typesetting industry. Lorem Ipsum has been the
              industry’s standard dummy text ever since the 1500s.
            </Text>
          </View>
        </View>
      </MainLayout>
    );
  }
}
const UserDetail = (props = { icon: "", name: "", value: "" }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
        flex: 1,
      }}
    >
      <View style={{ ...styles.fl_ai, width: 140 }}>
        <View style={styles.detail_icon}>
          <Image
            height={16}
            width={16}
            tintColor={UI.colors.primary}
            source={props.icon}
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  inquiry_description: {
    color: "#454545",
    fontSize: 16,
    fontWeight: "bold",
  },
  sub_header: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  inquiry_container: {
    minHeight: 120,
    width: "100%",
    borderRadius: 10,
    borderWidth: 2,
    borderColor: "#C9C9C9",
    marginTop: 10,
    borderStyle: "dashed",
    padding: 12,
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  product_stat_item: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  product_stats_text: {
    color: UI.colors.primary,
    fontWeight: "bold",
    fontSize: 32,
    marginLeft: 15,
    flex: 1,
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
