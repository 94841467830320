class APIConstants {
  CODE = "code";
  ROLE = "role";
  JWT_TOKEN = "jwt_token";
  USER_DOCTOR = "user_doctor";
  FORM_RECOGNIZER_SUBSCRIPTION = process.env.REACT_APP_FORM_RECOGNIZER_SUBSCRIPTION;

  // START ENV HERE -------------------------------------------
  ENV_TYPE= process.env.REACT_APP_ENV_TYPE;
  LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL;
  SUBSCRIBTION_KEY = process.env.REACT_APP_SUBSCRIPTION_KEY;
  API_HOST = process.env.REACT_APP_API_HOST;
  API_HOST_WEB = process.env.REACT_APP_API_HOST_WEB;
  API_HOST_WEB_SPECIAL = process.env.REACT_APP_API_HOST_WEB_SPECIAL;
  STORAGE_BASE_URL = process.env.REACT_APP_STORAGE_BASE_URL;
  API_HOST_WEB_SOCKET = process.env.REACT_APP_API_HOST_WEB_SOCKET;
  WEB_HOST_NAME_URL = process.env.REACT_APP_WEB_HOST_NAME_URL;
  MWELL_WEBSITE_URL = process.env.REACT_APP_MWELL_WEBSITE_URL;
  MWELLMD_WEBSITE_URL = process.env.REACT_APP_MWELLMD_WEBSITE_URL;
  FB_APP_ID = process.env.REACT_APP_FB_APP_ID;
  GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;

  // END ENV HERE -------------------------------------------

  video_formats = ["m4v", "mkv", "avi", "mpg", "mp4", "webm"];

  specialties = [
    "Nutritionist",
    "Cardiologist",
    "Ear, Nose & Throat (ENT)",
    "Primary Care",
    "Pulmonology",
    "Ob-Gyn",
    "Gastroenterology",
    "Endocrinology",
    "Orthopedics",
    "Rehabilitation Medicine",
    "Dentistry",
    "Pediatrics",
  ];

  product_category = [
    "Healthsavers",
    "ePharmacy",
    "Insurance",
    "Emergency",
    "Labs and Clinic",
    "Hospital",
    "Wellness",
    "Challenges",
    "Meal Plan",
    "Fitness Program",
    "Others",
  ];

  month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  languanges = [
    "Aklanon",
    "Bikol",
    "Cebuano",
    "Chavacano",
    "English",
    "Filipino",
    "Hiligaynon",
    "Ibanag",
    "Ilocano",
    "Ivatan",
    "Kapampangan",
    "Kinaray-a",
    "Maguindanao",
    "Maranao",
    "Pangasinan",
    "Sambal",
    "Surigaonon",
    "Tagalog",
    "Tausug",
    "Waray",
    "Yakan",
  ];

  hmo_affiliations = [
    "Avega",
    "Intellicare",
    "Etiqa",
    "Asian life",
    "Carehealth Plus",
    "Caritas",
    "Cocolife",
    "East west",
    "Flexicare",
    "Generali",
    "HMI",
    "HPPI",
    "Insular Health Care",
    "Kaiser",
    "Maxicare",
    "Medasia",
    "Medicard",
    "Medicare Plus",
    "Medilink",
    "Medicare",
    "Philamlife",
    "Philcare",
    "Sunlife",
    "Valucare",
    "Wellcare",
  ];

  limits = {
    title: 32,
    description: 4000,
    price: 13,
    purchase_limit: 5,
    keyword: 32,
    quantity: 13,
    name: 50,
    email: 62,
    address: 95,
    city: 35,
    code: 15,
    max_int: 900719925474099,
    minutes: 4,
    bank_account_number: 17,
  };

  default_entries = 30;
  consultation_fee = 350;
  consultation_fee_validation = `Minimum amount allowed is P${this.consultation_fee}.00 and should be in multiples of 50 only`
  
  // ENV_TYPE="PRODUCTION-RIZAL"
  // LOGOUT_URL = "https://mwellb2c.b2clogin.com/mwellb2c.onmicrosoft.com/B2C_1_Authentication_Doctor/oauth2/v2.0/logout?post_logout_redirect_uri=https://platform.mwell.com.ph";
  // SUBSCRIBTION_KEY = "0a57846786b34b0a89328c39f584892b";
  // API_HOST = "https://gw.mwell.com.ph/api/v2/";
  // API_HOST_WEB = "https://gw.mwell.com.ph/api/v2/web/";
  // STORAGE_BASE_URL = "https://gw.mwell.com.ph/api/v2/app/mwell/public/files/image/retrieve";
  // API_HOST_WEB_SOCKET = "wss://api-web-mwell-doctors-onboarding-rizal-002.azurewebsites.net/?subscription=13213";
  // WEB_HOST_NAME_URL = "http://platform.mwell.com.ph";
  // MWELL_WEBSITE_URL = "https://www.mwell.com.ph";
  // MWELLMD_WEBSITE_URL = "https://emr.mwellmd.com.ph";
  // ADDITIONAL_ENVIRONMENT = {};

  //PROD - RIZAL

  // ENV_TYPE="PRODUCTION-RIZAL"
  // LOGOUT_URL = "https://mwellb2c.b2clogin.com/mwellb2c.onmicrosoft.com/B2C_1_Authentication_Doctor/oauth2/v2.0/logout?post_logout_redirect_uri=https://platform.mwell.com.ph";
  // SUBSCRIBTION_KEY = "0a57846786b34b0a89328c39f584892b";
  // API_HOST = "https://gw.mwell.com.ph/api/v2/";
  // API_HOST_WEB = "https://gw.mwell.com.ph/api/v2/web/";
  // STORAGE_BASE_URL = "https://gw.mwell.com.ph/api/v2/app/mwell/public/files/image/retrieve";
  // API_HOST_WEB_SOCKET = "wss://api-web-mwell-doctors-onboarding-rizal-002.azurewebsites.net/?subscription=13213";
  // WEB_HOST_NAME_URL = "http://platform.mwell.com.ph";
  // MWELL_WEBSITE_URL = "https://www.mwell.com.ph";
  // MWELLMD_WEBSITE_URL = "https://emr.mwellmd.com.ph";
  // ADDITIONAL_ENVIRONMENT = {};

  //PRE PRODUCTION - OSLER

  // ENV_TYPE="PREPRODUCTION-OSLER"
  // LOGOUT_URL = "https://mwellb2c.b2clogin.com/mwellb2c.onmicrosoft.com/B2C_1_Authentication_Doctor/oauth2/v2.0/logout?post_logout_redirect_uri=https://appsvc-backoffice-osler-001.azurewebsites.net/";
  // SUBSCRIBTION_KEY = "0a57846786b34b0a89328c39f584892b";
  // API_HOST = "https://gw.mwell.com.ph/preprod/api/v2/";
  // API_HOST_WEB = "https://gw.mwell.com.ph/preprod/api/v2/web/";
  // API_HOST_WEB_SOCKET = "wss://api-web-mwell-doctors-onboarding-rizal-0-pre-prod.azurewebsites.net/?subscription=13213";
  // STORAGE_BASE_URL = "https://gw.mwell.com.ph/preprod/api/v2/app/mwell/public/files/image/retrieve";
  // WEB_HOST_NAME_URL = "http://appsvc-backoffice-osler-001.azurewebsites.net";
  // MWELL_WEBSITE_URL = "https://www.mwell.com.ph";
  // MWELLMD_WEBSITE_URL = "https://emr.mwellmd.com.ph";
  // ADDITIONAL_ENVIRONMENT = {
  //   environment: "pre-prod",
  // };

  // UAT

  //  ENV_TYPE="UAT"
  //  LOGOUT_URL = "https://mwelltelemedapp.b2clogin.com/mwelltelemedapp.onmicrosoft.com/B2C_1_Authentication_Doctor/oauth2/v2.0/logout?post_logout_redirect_uri=https://appsvc-backoffice-osler-002.azurewebsites.net";
  //  SUBSCRIBTION_KEY = "27d6424c69ac4c638f9d2bae5c9b2986";
  //  API_HOST = "https://apimgmt-mwelltelemed-osler-001.azure-api.net/api/v2/";
  //  API_HOST_WEB =  "https://apimgmt-mwelltelemed-osler-001.azure-api.net/api/v2/web/";
  //  STORAGE_BASE_URL = "https://apimgmt-mwelltelemed-osler-001.azure-api.net/api/v2/app/mwell/public/files/image/retrieve";
  //  API_HOST_WEB_SOCKET = "wss://apimgmt-mwelltelemed-osler-001.azure-api.net/doctors?subscription-key=" + this.SUBSCRIBTION_KEY;
  //  WEB_HOST_NAME_URL = "https://appsvc-backoffice-osler-002.azurewebsites.net";
  //  MWELL_WEBSITE_URL = "http://internal.mwell-web-portal.highlysucceed.com";
  //  MWELLMD_WEBSITE_URL = "http://mwellmd-dev.southeastasia.cloudapp.azure.com";
  //  ADDITIONAL_ENVIRONMENT = {};

  //QA -> GALEN

  // ENV_TYPE="QA-GALEN"
  // LOGOUT_URL = "https://mwelltelemedapp.b2clogin.com/mwelltelemedapp.onmicrosoft.com/B2C_1_Authentication_Doctor/oauth2/v2.0/logout?post_logout_redirect_uri=https://appsvc-backoffice-galen-001.azurewebsites.net";
  // SUBSCRIBTION_KEY = "a22488b80fd3403293de4b7f810a22c6";
  // API_HOST = "https://apimgmt-mwelltelemed-hippo-001.azure-api.net/qa/api/v1/";
  // API_HOST_WEB =  "https://apimgmt-mwelltelemed-hippo-001.azure-api.net/qa/api/v1/web/";
  // STORAGE_BASE_URL = "https://apimgmt-mwelltelemed-hippo-001.azure-api.net/qa/api/v1/app/mwell/public/files/image/retrieve";
  // API_HOST_WEB_SOCKET = "wss://apimgmt-mwelltelemed-hippo-001.azure-api.net/doctors?subscription-key=" + this.SUBSCRIBTION_KEY;
  // WEB_HOST_NAME_URL = "https://appsvc-backoffice-galen-001.azurewebsites.net";
  // MWELL_WEBSITE_URL = "http://internal.mwell-web-portal.highlysucceed.com";
  // MWELLMD_WEBSITE_URL = "http://mwellmd-dev.southeastasia.cloudapp.azure.com";
  // ADDITIONAL_ENVIRONMENT = {};

  // DEV -> Hippo

  // ENV_TYPE="DEV-HIPPO"
  // LOGOUT_URL = "https://mwelltelemedapp.b2clogin.com/mwelltelemedapp.onmicrosoft.com/B2C_1_Authentication_Doctor/oauth2/v2.0/logout?post_logout_redirect_uri=https://appsvc-backoffice-hippo-001.azurewebsites.net";
  // SUBSCRIBTION_KEY = "a22488b80fd3403293de4b7f810a22c6";
  // API_HOST = "https://apimgmt-mwelltelemed-hippo-001.azure-api.net/dev/api/v1/";
  // API_HOST_WEB = "https://apimgmt-mwelltelemed-hippo-001.azure-api.net/dev/api/v1/web/";
  // STORAGE_BASE_URL = "https://apimgmt-mwelltelemed-hippo-001.azure-api.net/dev/api/v1/app/mwell/public/files/image/retrieve";
  // API_HOST_WEB_SOCKET = "wss://apimgmt-mwelltelemed-hippo-001.azure-api.net/doctors?subscription-key=" + this.SUBSCRIBTION_KEY;
  // WEB_HOST_NAME_URL = "https://appsvc-backoffice-hippo-001.azurewebsites.net";
  // MWELL_WEBSITE_URL = "http://internal.mwell-web-portal.highlysucceed.com";
  // MWELLMD_WEBSITE_URL = "http://mwellmd-dev.southeastasia.cloudapp.azure.com";
  // ADDITIONAL_ENVIRONMENT = {};

  // END OF DEPLOYMENT SECTION -----------------------------------------------------------------------------
}

export default APIConstants;
