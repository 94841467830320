import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import "./css.css";
export default class Statistics extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const _this = this.props._this;
    const {
      // width,
      // healthpassData,
      mostUsedHealthpass,
      activeHealthpass,
      inActiveHealthpass,
      usedHealthpass,
      date_time
    } = _this.state;
    // const activeHealthpass = healthpassData?.filter((healthpass) => healthpass.status === "Active")
    // const inActiveHealthpass = healthpassData?.filter((healthpass) => healthpass.status === "Inactive")

    return (
      <UI.Row breakpoint={1175} _this={_this}>
        <View style={styles.earnings_container}>
          <View style={{ flex: 1, minWidth: 160 }}>
            <Text style={styles.header_1}>Most Used Voucher</Text>
            <Text style={styles.main_header}>
              {UI.pad(mostUsedHealthpass[0]?.usageAudit?.length ?? 0, 4)}
            </Text>
            {UI.box(10)}
            {/* <Text style={styles.header_1}>{mostUsedHealthpass[0]?.title ?? ""}</Text> */}
            <Text style={styles.header_1}>Free Doctor Consultation</Text>
            {UI.box(10)}
            <Text style={styles.header_1}>As of: {date_time} </Text>
          </View>
          {UI.box(25)}
          <Image
            height={100}
            width={100}
            tintColor={"transparent"}
            source={UI.PAY}
          />
        </View>

        {UI.box(20)}
        
        <View
          style={{
            padding: 20,
            borderRadius: 5,
            backgroundColor: "white",
            ...Shadow._3(),
            flex: 1,
          }}
        >
          <UI.Row breakpoint={1170} _this={_this} style={{ width: "100%" }}>
            <Text style={styles.box_header_text}>
              Site Stats: {date_time}
              {/* {UI.get_current_date_string()} */}
            </Text>
            {UI.box(10)}
            <Layout.SortFilter
              btn_1={{
                text: "Year",
                onClick: (text) => {
                    _this.getSiteStats('year')
                },
              }}
              btn_2={{
                text: "Month",
                onClick: (text) => {
                    _this.getSiteStats('month')
                },
              }}
              btn_3={{
                text: "Day",
                onClick: (text) => {
                    _this.getSiteStats('day')
                },
              }}
              onChange={(val) => {
                _this.setState({
                  [`${"sort_filter_"}${"site_stats"}`]: val,
                });
              }}
              _this={_this}
              state_name={"site_stats"}
            />
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={1175} _this={_this} style={{justifyContent:'space-around'}}>
            <DashItem
              _this={_this}
              icon={UI.IC_DASH_1}
              num={UI.pad(activeHealthpass, 3)}
              color={UI.colors.primary}
              text={"Active Health Pass"}
            />
            {UI.box(10)}
            <DashItem
              _this={_this}
              icon={UI.IC_DASH_8}
              num={UI.pad(inActiveHealthpass, 3)}
              color={"#D90000"}
              text={"Inactive Health Pass"}
            />
          {UI.box(10)}
            <DashItem
              _this={_this}
              icon={UI.IC_DASH_4}
              num={UI.pad(usedHealthpass, 3)}
              color={UI.colors.active}
              text={"Used Health Pass"}
            />
          </UI.Row>
        </View>
      </UI.Row>
    );
  }
}

const DashItem = (
  props = { _this: null, icon: "", num: "", text: "", color: "" }
) => {
  return (
    <View style={{ flexDirection: "row", flex: 1 }}>
      <Image
        height={60}
        width={60}
        tintColor={"transparent"}
        source={props.icon}
      />

      <View style={{ marginLeft: 15 }}>
        <Text
          style={{ ...styles.main_header, color: props.color, fontSize: 22 }}
        >
          {props.num}
        </Text>
        <Text style={{ ...styles.header_2, fontSize: 12 }}>{props.text}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sort_filter_text: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#BFBFBF",
  },
  sort_filter: {
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 2,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#C9C9C9",
  },
  box_header_subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#C9C9C9",
    alignSelf: "flex-end",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.secondary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 12,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    backgroundColor: "white",
    padding: 20,
    ...Shadow._3(),
    flexDirection: "row",
    maxHeight: 180,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
