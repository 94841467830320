import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";

export default class OnboardHeader extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const _this = this.props._this;

    return (
      <UI.Row breakpoint={940} _this={_this} style={styles.header_container}>
        <View style={styles.header_left}>
          <Image
            height={78 * 0.3}
            width={247 * 0.3}
            source={UI.MWELL_LOGO}
            tintColor={"transparent"}
          />
          {UI.box(10)}
          <Text style={styles.header_1}>DOCTOR ONBOARDING</Text>
        </View>
        {UI.box(10)}

        <Text style={styles.header_2}>
          Note: Registration will be checked for approval before you can use the
          Doctor's Portal
        </Text>
      </UI.Row>
    );
  }
}

const styles = StyleSheet.create({
  header_2: {
    color: "#535353",
    fontSize: 15,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 15,
    color: UI.colors.primary,
    marginTop: 2,
    fontWeight: "bold",
  },
  header_left: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  header_container: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 10,
    ...Shadow._2(),
  },
});
