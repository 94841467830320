import React, { useState, useEffect, useCallback }  from "react";
import moment from "moment";
import { Row, Select, Typography } from "antd";
import styled from "styled-components";
import UI from "../../shared/Components/UI/js"
import DropdownIcon from "../../icons/Dropdown";
import DataTable from "../../ComponentsFC/DataTable";
import mem from "../../shared/Components/Memory/js";
import { saveAs } from "file-saver";

const OnboardTaxTable = ({_this}) => {
  const [tableData, setTableData] = useState(_this.state.bir_files);
  const [tableEntries, setTableEntries] = useState(30);
  const [totalRecords, setTotalRecords] = useState();
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const getDoctorTaxFiles = useCallback(async () => {
    try {
      // getDoctorBirForms
      const data = mem.get("edit_doctor");;
      setIsLoading(true);
       const doctor = JSON.parse(data);
       console.log("Doctor", doctor.files)
      setIsLoading(false);
      setTotalRecords(doctor.files.length);

      setTableData(doctor.files);
    //   setTableData(realApiResponse.data.d.data)
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNum, tableEntries]);

  useEffect(() => {
    getDoctorTaxFiles();
  }, [getDoctorTaxFiles, _this.state.bir_files]);

  const download_file = async (path,filename) => {
    const downloadResult = await fetch(UI.getDoctorFileURL(path));
    const blob = await downloadResult.blob();
    const file_type = blob.type.split("/")[1];
    if (file_type) {
      if (file_type === "html") {
        return false;
      } else {
        saveAs(
          blob,
          filename
        );
      }
    } else {
      return false;
    }
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));

  };


  const handlePaginateTable = (pageNum) => {
    console.log("Currently Selected Page # of Antd Table: ", pageNum);
    setCurrentPageNum(pageNum);
  };
// console.log("TABLE DATA ", tableData);

  const tableColumnHeader = [
    { title: "Date Upload", dataIndex: "createdAt", key: "createdAt", 
      render: (data) => {
        return moment(data).format("MM/DD/YYYY");
      },
    },
    { title: "File Name", dataIndex: ["originalFilename","path"], key: ["originalFilename","path"],
      render:(text, record,index) => {
        return (
          <Title onClick={(e) => {download_file(record.path,record.originalFilename)} }>{record.originalFilename}</Title>
        )
      }
    },
  ];

  return (
    <>
      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            defaultValue="5"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="5">5 Entries</Select.Option>
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>
        
        </Toolbar>

        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={tableData}
          defaultPageSize={30}
          pageSizeOptions={["5", "10", "30"]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>

  );
};

export default OnboardTaxTable;

// CSS Styled Components ----------------------
// const FilterBox = styled(Row)`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   background: #ffffff 0% 0% no-repeat padding-box;
//   box-shadow: 0px 3px 10px #bfbfbf29;
//   border-radius: 12px;
//   padding: 33px 0 0 0;
//   margin-bottom: 30px;
// `;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;

const Title = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 0.75rem !important; // 15px
  line-height: 0.75rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
  margin-bottom: 5px !important;
  cursor:pointer;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }`
