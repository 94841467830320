import React from "react";
import { Document, Page, StyleSheet, PDFViewer } from "react-pdf-renderer-v2";
import Header from "./Header";
import Footer from "./Footer";
import { SimpleModal } from "../../../_components/Modal";

const EDocsWrapperComponent = ({
  children,
  title,
  appointment,
  onHideModal,
}) => {
  return (
    <SimpleModal title={title} onDismiss={onHideModal}>
      <PDFViewer showToolbar={false} width={600}>
        <Document>
          <Page style={styles.page}>
            <Header appointment={appointment} title={title} />
            {children}
            <Footer appointment={appointment} />
          </Page>
        </Document>
      </PDFViewer>
    </SimpleModal>
  );
};

const styles = StyleSheet.create({
  page: {
    paddingTop: 10,
    paddingBottom: 320,
    paddingHorizontal: 35,
  },
});

export default EDocsWrapperComponent;
