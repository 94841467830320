import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm
// , { Checkbox }
 from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import "./css.css";
const context = "admin";

export default class AdminEditHospital extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "App Content",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],

      selected_specialties: [],
      context: context,
      specialties: [],

      hospital_name: "",
      hospital_address: "",
      isMPHHI: "",
    });
  }

  onCreate = () => {
    this.load_specialties();
  };

  load_data = async () => {
    const data = mem.get("view_hospital");
    if (data) {
      const record = JSON.parse(data);

      UI.set_input_value("hospital_name", record.name ?? "");
      UI.set_input_value("hospital_address", record.address ?? "");
      UI.set_select_value("isMPHHI", record.isMPHHI ?? "");
      console.log(record)
      this.setState({
        selected_specialties: record.fieldSpecialties,
      });
      this.setState({
        id: record._id,
      });

      /* 
      this.setState({
        id: merchant._id,
        image: image,
        imagePath,
        imageFilename,
      }); */
    }
  };

  load_specialties = async () => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/specializations",
      params: {},
      onFail: (err) => {},
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        this.setState({ specialties: data.data }, () => {});
      },
      onFinish: async () => {
        this.hide_loading();
        this.load_data();
      },
    });
  };

  submit = async () => {
    UI.clear_errors();
    const {
      selected_specialties,
      hospital_name,
      hospital_address,
      id,
      isMPHHI,
    } = this.state;

    if (isMPHHI === "") {
      await UI.error_form("isMPHHI", "Is MPHHI is required.");
    }

    if (!hospital_name) {
      await UI.error_form("hospital_name", "Hospital Name is required.");
    }
    if (!hospital_address) {
      await UI.error_form("hospital_address", "Hospital Address is required.");
    }
    
    /*     if (selected_specialties.length == 0) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Please select at least one specialty."}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      await UI.error_form(
        "selected_specialties",
        "Please select at least one specialty."
      );
    } */
    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    this.show_loading();
    UI.webRequest({
      method: "put",
      url: "admin/hospitals/" + id,
      params: {
        name: hospital_name,
        address: hospital_address,
        fieldSpecialties: selected_specialties,
        isMPHHI: isMPHHI,
      },
      isMultiPart: false,
      onFail: (err) => {
        const data = err.data;
        const error_message = data.error;
        console.log(err);
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (response) => {
        console.log(response);
        UI.clear_forms();
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully Updated the Hospital"}
            onDismiss={() => {
              UI.clear_forms();
              UI.goBack();
              this.hide_modal();
            }}
          />
        );
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;
    // const { height, width, isMobile, specialties } = _this.state;

    return (
      <MainLayout context={context} additional={"hospitals"} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Update Hospital
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add item
            </Text>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Hospital Name"}
              placeholder={"Enter Hospital Name"}
              state_name={"hospital_name"}
              style={{ flex: 1 }}
              isRequired={false}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Hospital Address"}
              placeholder={"Enter Hospital Address"}
              state_name={"hospital_address"}
              style={{ flex: 2 }}
              isRequired={false}
            />
          </UI.Row>

          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Is MPHHI Group Member?"}
              isRequired={false}
              onChange={(e) => {
                this.setState({
                  isMPHHI: e ? e === "true" ? true : false : "",
                });
              }}
              state_name={"isMPHHI"}
            >
              <option value="">Select One</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Layout.SelectPicker>
            <View style={{ flex: 1 }}></View>
            <View style={{ flex: 1 }}></View>
          </UI.Row>

          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"UPDATE HOSPITAL"}
              width={150}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

// const FlexWrap = (props) => {
//   return (
//     <View style={{ alignSelf: "flex-start" }}>
//       <View
//         style={{
//           display: "block",
//         }}
//       >
//         {props.children}
//       </View>
//     </View>
//   );
// };

// const SpecialtyItem = (props = { text: "", _this: null }) => {
//   const { _this, text } = props;
//   const specialties = _this.state.selected_specialties;
//   const self = text;

//   const is_active = specialties.includes(self);

//   const onClick = () => {
//     if (is_active) {
//       let temp = specialties;
//       const index = temp.indexOf(self);
//       if (index > -1) {
//         temp.splice(index, 1);
//       }
//       _this.setState({
//         selected_specialties: temp,
//       });
//     } else {
//       _this.setState({
//         selected_specialties: [self, ...specialties],
//       });
//     }
//   };

//   return (
//     <View
//       style={{
//         paddingTop: 10,
//         paddingBottom: 10,
//         paddingRight: 25,
//         width: 230,
//       }}
//     >
//       <Checkbox
//         _this={_this}
//         onClick={onClick}
//         is_checked={is_active}
//         text={text}
//         color={"#3E3E3E"}
//       />
//     </View>
//   );
// };

const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
