import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import { Row, Col, Image } from "antd";
import styled from "styled-components";
import { mem } from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
import Container from "../../ComponentsFC/ui/Container";
import FieldBirthDate from "../../icons/FieldBirthDate";
import FieldDateIcon from "../../icons/FieldDate";
import FieldGenderIcon from "../../icons/FieldGender";
import FieldHeightIcon from "../../icons/FieldHeight";
import FieldWeightIcon from "../../icons/FieldWeight";
import FieldBMIIcon from "../../icons/FieldBMI";
// import FieldHeartIcon from "../../icons/FieldHeart";
import FieldAddressIcon from "../../icons/FieldAddress";

const context = "marketing";

export default class MarketingPromoWinnersView extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Promo Winners",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
      user: null,
    });
  }

  onCreate = () => {
    const user = JSON.parse(mem.get('promo-winners-view'));
    this.setState({
      user: user
    }, () => {
      // console.log('user',this.state.user);
    })
  };

  getFullName = (item, form) => {
    let name = item?.fullname;
    const user = item?.user;
    if(name === '-- --') {
      name = form ? `${form?.firstName} ${form?.middleName} ${form?.lastName}` : 
      `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
    }
    return name ?? 'Name not provided';
  }
  
  getEmailAddress = (item, email) => {
    let emailAddress = email;
    if(!emailAddress) {
      emailAddress = item?.user?.email;
    }
    return emailAddress ?? 'No email address filled';
  }

  getContactNumber = (item, phoneNumber) => {
    let phone = phoneNumber;
    if(!phone) {
      phone = item?.user?.phoneNumber ?? item?.user?.phone;
      return (phone) ? `+63 ${phone}` : 'No contact number filed';
    } else {
      return `+63 ${phoneNumber}`;
    }
  }

  getGender = (value) => {
    if(value === 0) {
      return 'Female';
    }
    if(value === 1) {
      return 'Male';
    }
    return '--';
  }

  render() {
    const _this = this;
    const Constants = new APIConstants();
    const imageStorage = Constants.STORAGE_BASE_URL;
    const imageContainer = "doctors-onboarding";
    const { user } = this.state;

    return (
      <MainLayout context={context} _this={_this}>
        {/* ANTD ROW 24 GRID LAYOUT 8x8x8 (span) = 24 */}
        <Container>
          <RowWrapper>
            <FirstColumn>
              <Row>
                <Col style={{ marginRight: 25 }}>
                  <StyledImage
                    src={
                      user?.user?.avatarLink
                        ? `${imageStorage}?container=${imageContainer}&path=${user?.user?.avatarLink}`
                        : UI.DEFAULT_AVATAR_PATIENT
                    }
                    preview={false}
                    width={121}
                    height={124}
                  />
                </Col>
                <StyledColWrapper>
                  <StyledUserName>
                    {this.getFullName(user, user?.formData)}
                  </StyledUserName>
                  <StyledUserContactDetails>
                    {this.getEmailAddress(user, user?.formData?.emailAddress)}
                  </StyledUserContactDetails>
                  <StyledUserContactDetails>
                    {this.getContactNumber(user, user?.formData?.mobileNumber)}
                  </StyledUserContactDetails>
                </StyledColWrapper>
              </Row>
            </FirstColumn>

            <SecondColumn>
              <Row>
                <Col style={{ marginRight: 120 }}>
                  <FieldWrapper>
                    <Col>
                      <FieldLabel>
                        <FieldBirthDate
                          style={{ marginRight: 20, verticalAlign: 0 }}
                        />
                        Birthdate
                      </FieldLabel>
                      <FieldLabel>
                        <FieldGenderIcon
                          style={{ marginRight: 20, verticalAlign: 0 }}
                        />
                        Gender
                      </FieldLabel>
                      <FieldLabel>
                        <FieldHeightIcon
                          style={{ marginRight: 20, verticalAlign: 0 }}
                        />
                        Registration Date
                      </FieldLabel>
                      <FieldLabel>
                        <FieldWeightIcon
                          style={{ marginRight: 20, verticalAlign: 0 }}
                        />
                        Registration Time
                      </FieldLabel>
                      <FieldLabel>
                        <FieldBMIIcon
                          style={{ marginRight: 20, verticalAlign: 0 }}
                        />
                        Prize Won
                      </FieldLabel>
                    </Col>
                    <Col style={{ marginLeft: 39 }}>
                      <FieldValue>{user?.user?.birthDate ? UI.momentPHTimezone(user?.user?.birthDate).format('LL') : "--"}</FieldValue>
                      <FieldValue>{this.getGender(user?.user?.gender)}</FieldValue>
                      <FieldValue>{UI.momentPHTimezone(user?.createdAt).format('LL') ?? "--"}</FieldValue>
                      <FieldValue>{UI.momentPHTimezone(user?.createdAt).format('LT') ?? "--"}</FieldValue>
                      <FieldValue>{user?.prizeName}</FieldValue>
                    </Col>
                  </FieldWrapper>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <FieldLabel>
                        <FieldAddressIcon
                          style={{ marginRight: 20, verticalAlign: 0 }}
                        />
                        Street Address
                      </FieldLabel>
                      <FieldLabel>
                        <FieldDateIcon
                          style={{
                            marginRight: 20,
                            verticalAlign: 0,
                            visibility: "hidden",
                          }}
                        />
                        Barangay
                      </FieldLabel>
                      <FieldLabel>
                        <FieldDateIcon
                          style={{
                            marginRight: 20,
                            verticalAlign: 0,
                            visibility: "hidden",
                          }}
                        />
                        City
                      </FieldLabel>
                      <FieldLabel>
                        <FieldDateIcon
                          style={{
                            marginRight: 20,
                            verticalAlign: 0,
                            visibility: "hidden",
                          }}
                        />
                        Province
                      </FieldLabel>
                      <FieldLabel>
                        <FieldDateIcon
                          style={{
                            marginRight: 20,
                            verticalAlign: 0,
                            visibility: "hidden",
                          }}
                        />
                        Zip Code
                      </FieldLabel>
                    </Col>
                    <Col style={{ marginLeft: 39 }}>
                      <FieldValue>{user?.formData?.street ?? "N/A"}</FieldValue>
                      <FieldValue>{user?.formData?.brgyOrTown ?? "N/A"}</FieldValue>
                      <FieldValue>{user?.formData?.cityOrMunicipality ?? "N/A"}</FieldValue>
                      <FieldValue>{user?.formData?.region ?? "N/A"}</FieldValue>
                      <FieldValue>{user?.formData?.postalCode ?? "N/A"}</FieldValue>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </SecondColumn>
          </RowWrapper>
        </Container>
      </MainLayout>
    );
  }
}

// CSS Styled Components ----------------------

const RowWrapper = styled(Row)``;

const StyledImage = styled(Image)`
  border-radius: 14px;
`;

const FirstColumn = styled(Col)`
  width: 462.26px; // 497 (based on UI Design) - 35px (existing padding), 462.26px
  border-right: 3px solid #efefef;

  // Breakpoint where AntD Row wraps (tablets, 1755px) and down)
  @media only screen and (max-width: 1755px) {
    border-right: none;
    border-bottom: 3px solid #efefef;
  }
`;

const SecondColumn = styled(Col)`
  padding-left: 46.44px; //based on UI Design Spacing

  @media only screen and (max-width: 1755px) {
    padding-top: 20px;
  }

  // Breakpoint where AntD Row wraps (Larger Laptops, 1440px) and down)
  @media only screen and (max-width: 1440px) {
    padding-top: 20px;
    padding-left: 0;
  }
`;

const StyledColWrapper = styled(Col)`
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledUserName = styled.p`
  margin-bottom: 10px;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  color: #535353;
`;

const StyledUserContactDetails = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 500;
  color: #898989;
`;

const FieldWrapper = styled(Row)`
  /* width: 400px; //382.25px */
`;

const FieldLabel = styled.p`
  margin-bottom: 6.5px;

  font-family: Poppins;
  font-size: 14px; //18px
  font-weight: 500;
  color: #4fc2ed;
`;

const FieldValue = styled.p`
  margin-bottom: 6.5px;

  font-family: Poppins;
  font-size: 14px; //18px
  font-weight: 500;
  color: #333333;
`;