import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import TouchableOpacity, {
  Shadow,
  StyleSheet,
  Text,
  View,
  Image,
  mem,
} from "../../shared/custom-react-native";
import CustomPieChart from "../_components/CustomPieChart";
import MainLayout from "../_components/MainLayout";
import "./css.css";
import Statistics from "./Statistics";
import CustomDatatable from "../_components/CustomDatatable";
import Layout from "../_components/Layout";
import moment from "moment";

const context = "advocacy";

export default class AdvocacyDashboard extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Dashboard",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
      top5Advocacies: [],
      recentAdvocacies: [],
      siteStats:{},
      isLoading: true,

      data: [],
    });
  }

  onCreate = async () => {
    this.getRecentAdvocacies()
    this.getSiteStats()
    this.getTop5Advocacies()
  };

  getTop5Advocacies = (params="all") => {
    UI.webRequest({
      method: "get",
      url: "admin/advocacies/top5-advocacies-data",
      params: {
        participants:params
      },
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            top5Advocacies: data.data,
          },
          () => {
            this.reload_table("advocacy");
          }
        );
      },
      onFinish: () => {
        this.setState({
          isLoading: false,
        });
        this.hide_loading();
      },
    });
  }

  getSiteStats = (params="year") => {
    UI.webRequest({
      method: "get",
      url: "admin/advocacies/home-stats",
      params: {
        date: params
      },
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            siteStats: data.data,
          },
          () => {
            this.reload_table("advocacy");
          }
        );
      },
      onFinish: () => {
        this.setState({
          isLoading: false,
        });
        this.hide_loading();
      },
    });
  }

  getRecentAdvocacies = () => {
    UI.webRequest({
      method: "get",
      url: "admin/advocacies/recent-advocacies",
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            recentAdvocacies: data.data
          },
          () => {
            this.reload_table("advocacy");
          }
        );
      },
      onFinish: () => {
        this.setState({
          isLoading: false,
        });
        this.hide_loading();
      },
    });
  }

  render() {
    const _this = this;
    const { width, isLoading } = _this.state;

    let user_stats_container = UI.measure("user_stats_container").width;

    return (
      <MainLayout context={context} _this={_this}>
        <Statistics _this={_this} />

        <UI.Row breakpoint={1070} _this={_this} style={{ width: "100%" }}>
          <View
            id={"user_stats_container"}
            style={{ ...styles.main_content_container, flex: 1, padding: 0 }}
          >
            <UI.Row
              breakpoint={420}
              _this={_this}
              basis={user_stats_container}
              style={{ width: "100%", padding: 20 }}
            >
              {UI.box(10)}
              <UI.Row breakpoint={1170} _this={_this} style={{ width: "100%" }}>
              <Text style={styles.box_header_text}>Top 5 Advocacies</Text>
              {UI.box(10)}
              <Layout.SortFilter
                btn_1={{
                  text: "All",
                  onClick: () => {
                    this.getTop5Advocacies('all')
                  },
                }}
                btn_2={{
                  text: "By Patients",
                  onClick: () => {
                    this.getTop5Advocacies('patients')
                  },
                }}
                btn_3={{
                  text: "By Doctors",
                  onClick: () => {
                    this.getTop5Advocacies('doctors')
                  },
                }}
                onChange={(val) => {
                  _this.setState({
                    [`${"sort_filter_"}${"top5_stats"}`]: val,
                  });
                }}
                _this={_this}
                state_name={"top5_stats"}
              />
            </UI.Row>
              
            </UI.Row>

            {!isLoading && (
              <CustomPieChart
                ref={(pie) => {
                  this.pie = pie;
                }}
                data={[
                  {
                    color: "#94E9FF",
                    labelColor: UI.colors.secondary,
                    label: this.state.top5Advocacies[0]?.title,
                    count: this.state.top5Advocacies[0]?.count,
                    // count: 15,
                    onSelect: (item) => {},
                  },
                  {
                    color: "#48DAFF",
                    labelColor: UI.colors.secondary,
                    label: this.state.top5Advocacies[1]?.title,
                    count: this.state.top5Advocacies[1]?.count,
                    // count: 10,
                    onSelect: (item) => {},
                  },
                  {
                    color: "#0079EB",
                    labelColor: UI.colors.secondary,
                    label: this.state.top5Advocacies[2]?.title,
                    count: this.state.top5Advocacies[2]?.count,
                    // count: 10,
                    onSelect: (item) => {},
                  },
                  {
                    color: "#0064C3",
                    labelColor: UI.colors.secondary,
                    label: this.state.top5Advocacies[3]?.title,
                    count: this.state.top5Advocacies[3]?.count,
                    // count: 10,
                    onSelect: (item) => {},
                  },
                  {
                    color: "#004F99",
                    labelColor: UI.colors.secondary,
                    label: this.state.top5Advocacies[4]?.title,
                    count: this.state.top5Advocacies[4]?.count,
                    // count: 10,
                    onSelect: (item) => {},
                  },
                ]}
                _this={_this}
                id={"user_stats"}
              />
            )}

            <View
              style={{
                alignSelf: "center",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                padding: 20,
              }}
            >
              <UI.Row
                breakpoint={415}
                basis={user_stats_container}
                style={{ flex: 1, justifyContent: "space-around" }}
                _this={_this}
              >
                <Legend color={"#94E9FF"} text={"1st"} />
                {UI.box(5)}
                <Legend color={"#48DAFF"} text={"2nd"} />
                {UI.box(5)}
                <Legend color={"#0079EB "} text={"3rd"} />
                {UI.box(5)}
                <Legend color={"#0064C3"} text={"4th"} />
                {UI.box(5)}
                <Legend color={"#004F99"} text={"5th"} />
              </UI.Row>
            </View>
          </View>
          {UI.box(20)}

          <View
            style={{
              borderRadius: 5,
              backgroundColor: "white",
              ...Shadow._3(),
              flex: 1,
              width: width > 1070 ? null : "100%",
              alignSelf: "flex-start",
            }}
          >
            <View
              _this={_this}
              style={{ width: "100%", padding: 20, paddingBottom: 10, flexDirection:"row", justifyContent:'space-between', alignItems:'center' }}
            >
              <Text style={styles.box_header_text}>Recent Advocacies</Text>
              <View
                style={{
                  alignSelf: width > 950 ? "flex-start" : "center",
                  marginTop: 8,
                  marginLeft: 10,
                }}
              >
                <Layout.Btn
                  color={UI.colors.primary}
                  text={"View All"}
                  onClick={() => {
                    UI.goTo("/advocacy/list");
                  }}
                  height={40}
                  width={90}
                />
              </View>

            </View>

            <CustomDatatable
              disable_pagination={true}
              state_name={"advocacy"}
              _this={_this}
              columns={[
                { "Advocacy Name": "advocacy_name" },
                { "Valid Until": "valid_until" },
                { "Action": "action" },
              ]}
              data={this.state.recentAdvocacies.map((item) => {
              const valid_until = moment(item.endDate).format('MM/DD/YYYY');

                return {
                  advocacy_name: item.title,
                  valid_until: valid_until,
                  action: (
                    <View style={{ flexDirection: "row" }}>
                     <TouchableOpacity
                      onClick={() => {
                        mem.save("view_advocacy", JSON.stringify(item));
                        mem.save("advocacy_id", item.id);
                        UI.goTo("/advocacy/view");
                      }}
                      >
                        <Image
                          height={28}
                          width={28}
                          tintColor={"transparent"}
                          source={UI.VIEW_BTN_1}
                        />
                      </TouchableOpacity>
                      {UI.box(5)}
                      <TouchableOpacity
                        onClick={async () => {
                          mem.save("edit_advocacy", JSON.stringify(item));
                          mem.save("advocacy_id", item.id);
                          UI.goTo("/advocacy/edit");
                        }}
                      >
                        <Image
                          height={28}
                          width={28}
                          tintColor={"transparent"}
                          source={UI.EDIT_BTN_1}
                        />
                      </TouchableOpacity>
                    </View>
                  )
                };
              })}
              on_page={(page, callback, table_name) => {
                UI.on_page(this.state.recentAdvocacies, callback, table_name, page);
              }}
            />
            <View
              style={{
                ...styles.no_info_container,
                height: "100%",
                justifyContent:
                  this.state.recentAdvocacies.length > 0 ? "flex-start" : "center",
                display: this.state.recentAdvocacies.length > 0 ? "none" : "flex",
              }}
            >
              <UI.Row
                breakpoint={820}
                style={{ maxWidth: 360, alignItems: "center" }}
                _this={_this}
              >
                <Image
                  height={100}
                  width={100}
                  tintColor={"transparent"}
                  source={UI.NO_INFO}
                />
                {UI.box(10)}
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#BFBFBF",
                    textAlign: width > 820 ? "left" : "center",
                    marginLeft: width > 820 ? 20 : 0,
                  }}
                >
                  NO DATA AVAILABLE YET
                </Text>
              </UI.Row>
            </View>
          </View>
        </UI.Row>
      </MainLayout>
    );
  }
}

const Legend = (props = { text: "", color: "" }) => {
  return (
    <View style={styles.legend_container}>
      <View
        style={{ ...styles.dot_color, backgroundColor: props.color }}
      ></View>
      <Text style={styles.legend_text}>{props.text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  main_content_container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    flex: 1,
  },
  legend_text: {
    color: "#535353",
    fontWeight: "bold",
    fontSize: 12,
    marginLeft: 10,
  },
  legend_container: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  dot_color: {
    height: 16,
    width: 16,
    borderRadius: 10,
    backgroundColor: UI.colors.primary,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});
