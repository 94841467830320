import React from "react";
import DataTable from "../../../../../ComponentsFC/DataTable";
import { Row } from "antd";
import styled from "styled-components";
import DatePickerFC from "../../../../../ComponentsFC/ui/forms/DatePicker";

const ClinicalDocuments = ({ tableData = [] }) => {
  // const [tableData, setTableData] = useState([]);
  // const [isTableLoading, setIsTableLoading] = useState(false);

  const tableColumnHeader = [
    {
      title: "Document Name",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Date Created",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Action",
      dataIndex: "indication",
      key: "indication",
    },
  ];
  return (
    <div className="font-medium">
      <div className="grid grid-cols-3 grid-flow-row gap-6 border p-6">
        <div>
          <div className="text-lg">Visit Date</div>
          <DatePickerFC placeholder="Select Date" />
        </div>
        <div>
          <div className="text-lg">Document Name</div>
          <DatePickerFC placeholder="Select Date" />
        </div>
        <div>
          <div className="text-lg">Document Type</div>
          <DatePickerFC placeholder="Select Date" />
        </div>
      </div>
      <TableBox>
        <div className="mt-8">
          <DataTable
            tableColumnHeader={tableColumnHeader}
            dataSource={tableData}
          />
        </div>
      </TableBox>
    </div>
  );
};

export default ClinicalDocuments;

const TableBox = styled(Row)`
  position: relative;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  min-width: 100%;
  margin-top: 1.5rem;
  .ant-table-cell {
    font-size: 20px;
  }
`;
