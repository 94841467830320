import Icon from "@ant-design/icons";
import React from "react";

const FieldQualificationSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.888"
    height="19.887"
    viewBox="0 0 19.888 19.887"
  >
    <path
      id="qualification"
      d="M293.571,401.886V400.05a.511.511,0,0,0-.156-.389l-1.3-1.3a2.094,2.094,0,0,1,0-3.393l1.3-1.307a.5.5,0,0,0,.156-.389v-1.836a2.094,2.094,0,0,1,2.4-2.4h1.844a.471.471,0,0,0,.381-.164l1.306-1.289a2.094,2.094,0,0,1,3.394,0l1.3,1.289a.5.5,0,0,0,.389.164h1.835a2.106,2.106,0,0,1,2.406,2.4v1.836a.507.507,0,0,0,.156.389l1.3,1.307a2.1,2.1,0,0,1,0,3.393l-1.3,1.3a.511.511,0,0,0-.156.389v1.836a2.109,2.109,0,0,1-2.406,2.406h-1.835a.49.49,0,0,0-.389.156l-1.3,1.3a2.1,2.1,0,0,1-3.394,0l-1.306-1.3a.463.463,0,0,0-.381-.156h-1.844A2.1,2.1,0,0,1,293.571,401.886Zm4.821.684a.79.79,0,0,1,.624.26l1.558,1.558c.545.537.7.537,1.246,0l1.549-1.558a.819.819,0,0,1,.632-.26h2.216c.753,0,.874-.121.874-.883l.009-2.216a.819.819,0,0,1,.259-.623l1.558-1.558c.537-.545.537-.7,0-1.246l-1.558-1.558a.86.86,0,0,1-.268-.623v-2.216c0-.762-.122-.883-.874-.883H304a.807.807,0,0,1-.632-.259l-1.549-1.558c-.545-.545-.7-.537-1.246,0l-1.558,1.558a.789.789,0,0,1-.624.259h-2.216c-.77,0-.883.112-.883.883v2.216a.816.816,0,0,1-.26.623l-1.567,1.558c-.537.545-.537.7,0,1.246l1.567,1.558a.818.818,0,0,1,.26.623v2.216c0,.762.121.883.883.883Zm1.039-2.242-2-2.415a.945.945,0,0,1-.242-.623.834.834,0,0,1,1.523-.459l1.541,1.939,3.281-5.28a.866.866,0,0,1,.736-.477.814.814,0,0,1,.849.78,1.233,1.233,0,0,1-.216.615l-3.809,5.911a.956.956,0,0,1-.813.459A1.042,1.042,0,0,1,299.431,400.328Z"
      transform="translate(-291.25 -386.719)"
      fill="#4fc2ed"
    />
  </svg>
);

const FieldQualificationIcon = (props) => {
  return <Icon component={FieldQualificationSvg} {...props} />;
};

export default FieldQualificationIcon;
