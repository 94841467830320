import React from 'react'
import styled from 'styled-components';
// import ForgetPasswordForm from './ForgetPasswordForm';
import { Col } from 'antd';
import UI from '../../shared/Components/UI/js';
// import VerifyEmailForm from './VerifyEmailForm';
import Loading from '../../modules/_components/Loading';
import ProcessFailedModal from '../ui/modals/ProcessFailedModal';
import ProcessFailedModalCrossIcon from '../../icons/ProcessFailedModalCross';
import { useState } from 'react';
import ForgetPasswordForm from './ForgetPasswordForm';

const ForgetPasswordStep2 = ({ _this }) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <>
      <Loading
        visible={isScreenLoading}
        title={""}
        text={`Signing Up...`}
        modal_opaque={false}
      />
      <ProcessFailedModal
        title={"Process Failed"}
        banner={<ProcessFailedModalCrossIcon />}
        description={errorMessage}
        buttonText={"Dismiss"}
        buttoncolor={"#004f99"}
        visible={isFailed}
        onOk={() => setIsFailed(false)}
        onCancel={() => setIsFailed(false)} // x and cancel button
      />
      <StyledImage image={UI.BG_BLUE_MWELL}>
        <Col className='w-full h-full flex justify-center items-center'>
          <LoginContainer className='bg-white'>
            <ForgetPasswordForm 
              _this={_this}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
              setIsFailed={setIsFailed}
              isFailed={isFailed}
              setIsScreenLoading={setIsScreenLoading}
              isScreenLoading={isScreenLoading}
            />
          </LoginContainer>
        </Col>
      </StyledImage>
    </>
  )
}

export default ForgetPasswordStep2

const StyledImage = styled(`div`)`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  background-image: ${(props) => `url(${props.image})`};
`;

const LoginContainer = styled(`div`)`
  border-radius: 10px;
  padding: 47px 32.3px 53px 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 571px;
  width: 100%;
  float: none;
  display: inline-block;
  vertical-align: top;
`;
