import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { DeleteConfirmation, Failed } from "../_components/Modal";
import "./css.css";

const context = "voucher";

export default class VoucherHealthpassList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Health Pass List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,
      campaigns: [],
      healthpass_data_number_of_pages: 1,
      healthpass_data_current_page: 1,
      keyword: "",
      debounceTimeout: null, // Initialize debounce timeout state
    });
  }

  onCreate = () => {
    setTimeout(() => {
      this.get_data({
        page: 1,
        limit: 30,
      });
    }, 100);
    this.loadCampaigns();
  };

  loadCampaigns = async () => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/campaigns",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState({
          campaigns: data.data,
        });
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  get_data = async (params) => {
    let limit = parseInt(params.limit);
    params.limit = limit >= 30 ? 30 : limit;

    this.show_loading();
    if (!params) {
      params = {};
    }
    this.setState({
      healthpass_data_current_page: params?.page ?? 1,
    });

    UI.webRequest({
      method: "get",
      url: "admin/healthpass",
      params: params,
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response).data;
        const results = UI.get_results(response);
        console.log(results);
        if (!results?.total) {
          results.total = results.docCount;
        }

        this.setState(
          {
            data: data,
            all_data: data,
          },
          () => {
            UI.get_entries(
              data,
              params.limit ?? 30,
              "healthpass_list",
              params.page,
              {
                total: results.total,
                pages: results.pages,
              }
            );
            this.hide_loading();
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  handleSearch = (keyword) => {
    // Clear previous timeout to debounce
    if (this.state.debounceTimeout) {
      clearTimeout(this.state.debounceTimeout);
    }

    // Set new timeout to call get_data after 500ms
    const timeout = setTimeout(() => {
      this.get_data({
        page: 1,
        title: keyword,
      });
    }, 500);

    // Update debounceTimeout state with new timeout ID
    this.setState({
      keyword: keyword,
      debounceTimeout: timeout,
    });
  };

  exportHealthPassCode = (item) => {
    console.log(item);
    this.show_loading();

    // id = "61efacb0ab0eb492a7b389fd";
    UI.webRequest({
      method: "get",
      url: `admin/healthpass/${item._id}/vouchers`,
      // /healthpass/" + item._id
      params: {},
      onFail: async (err) => {
        const data = err.data;
        let error_message = data.message;
        if (!error_message) {
          error_message = data.m;
        }
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        console.log(data);
        const csv_data = data.result.map((item, index) => {
          const d = {
            Row: index + 1,
            "Generate Code": item?.title ?? " ",
          };

          return d;
        });
        UI.DownloadCSV(
          "Healthpass Generate Code Record-" +
            item.title +
            " " +
            UI.get_current_date_hr_mm_string(),
          csv_data
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  delete = async (id) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this item?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();
          this.show_loading();
          UI.webRequest({
            method: "delete",
            url: "admin/healthpass/" + id,
            params: {},
            onFail: async (err) => {},
            onSuccess: async (response) => {},
            onFinish: () => {
              this.hide_modal();
              this.get_data();
            },
          });
        }}
      />
    );
  };

  exportAllCSV = () => {
    const csv_data = this.state.data.map((item, index) => {
      const startDate = UI.get_date_string_by_date(item.startDate);
      const endDate = UI.get_date_string_by_date(item.endDate);

      const d = {
        Row: item._id + 1,
        "Health Pass Name": item.title ?? " ",
        "Start Date": startDate ?? " ",
        "End Date": endDate ?? " ",
        "Campaign Title": item.campaign?.title ?? " ",
        Discount: item.discount + "%" ?? " ",
        Supply: item?.createUniqueVouchers
          ? item?.numVouchersToGenerate
          : item?.initialQuantity ?? " ",
        Price: item.price ?? " ",
        Status: item.status ?? " ",
      };

      return d;
    });

    console.log(csv_data);
    UI.DownloadCSV(
      "Healthpass List " + UI.get_current_date_hr_mm_string(),
      csv_data
    );
  };

  custom_sort = (key, value) => {
    this.get_data({
      campaignName: value,
      // page: page ? page : 1,
      limit: this.state[`${"healthpass_list"}${"_entries"}`] ?? 30,
    });
    this.setState({
      filteredPaymentStatus: {
        key,
        value,
      },
    });
  };

  render() {
    const _this = this;
    const { width } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <Text
            style={{
              color: UI.colors.secondary,
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            Health Pass List
          </Text>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Campaign Name"}
              isRequired={false}
              onChange={(e) => {
                this.custom_sort("campaign_name", e);
              }}
            >
              <option value="">Filter By Campaign Name</option>
              {this.state.campaigns.map((item) => {
                return item ? (
                  <option value={item.title}>{item.title}</option>
                ) : (
                  <View></View>
                );
              })}
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={950}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"healthpass_list"} _this={_this} />
              </View>
            </View>

            <View style={{ alignSelf: "center", marginTop: 8, marginLeft: 10 }}>
              <Layout.Btn
                color={UI.colors.primary}
                text={"Download Report"}
                onClick={() => {
                  this.exportAllCSV();
                }}
                height={40}
                width={150}
              />
            </View>

            {UI.box(10)}

            <InputForm
              _this={_this}
              title={""}
              is_keyword={true}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              // onChange={(e) => {
              //   UI.search_table(
              //     "healthpass_list",
              //     e,
              //     this.state.all_data,
              //     "data"
              //   );
              // }}
              onChange={(e) => this.handleSearch(e)}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
            />

            <View style={{ alignSelf: "center", marginTop: 8, marginLeft: 10 }}>
              <Layout.Btn
                color={UI.colors.primary}
                text={"Add Healthpass"}
                onClick={() => {
                  UI.goTo("/voucher/add-healthpass");
                }}
                height={40}
                width={140}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            state_name={"healthpass_list"}
            _this={_this}
            columns={[
              // { ID: "id" },
              { "Healthpass Name": "healthpass_title" },
              { "Generation Progress": "percentage" },
              // { "Total Count": "initialQuantity" },
              { "Start Date": "startDate" },
              { "End Date": "endDate" },
              { "Campaign Title": "campaign_title" },
              { Discount: "percent_discount" },
              { Supply: "supply" },
              { "No. of Vouchers Utilized": "number_of_vouchers_utilized" },
              { Price: "price" },
              { Status: "status" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              const startDate = moment(item.startDate).format("MM/DD/YYYY");
              const endDate = moment(item.endDate).format("MM/DD/YYYY");
              let percentage = "";

              if (item.numVouchersToGenerate === 0) {
                percentage = "100%";
              } else {
                percentage =
                  Math.round(
                    (item.vouchersGenerated / item.numVouchersToGenerate) * 100
                  ) + "%";
              }

              const is_active = item.status === "Active" ? true : false;
              return {
                healthpass_title: item.title,
                percentage: percentage,
                startDate: startDate,
                endDate: endDate,
                campaign_title: item.campaign?.title ?? " ",
                percent_discount: item.discount + "%" ?? " ",
                supply: item?.createUniqueVouchers
                  ? item?.numVouchersToGenerate
                  : item?.initialQuantity ?? " ",
                number_of_vouchers_utilized: item?.vouchersUtilized,
                price: item.price ?? " ",
                status: is_active ? (
                  <Text style={{ color: "#00C700" }}>Active</Text>
                ) : (
                  <Text style={{ color: "#f39c12" }}>Inactive</Text>
                ),
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        mem.save("view_healthpass", JSON.stringify(item));
                        UI.goTo("/voucher/healthpass-view");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.VIEW_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={() => {
                        mem.save("edit_healthpass", JSON.stringify(item));
                        UI.goTo("/voucher/healthpass-edit");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    {
                      // item.numVouchersToGenerate > 1 &&
                      <TouchableOpacity
                        onClick={() => {
                          this.exportHealthPassCode(item);
                        }}
                      >
                        <Image
                          height={28}
                          width={28}
                          tintColor={"transparent"}
                          source={UI.EXPORT_BTN}
                        />
                      </TouchableOpacity>
                    }

                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={() => {
                        this.delete(item._id);
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.DELETE_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              this.get_data({
                page: page,
                limit: parseInt(this.state.entries),
              });
              // UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
});
