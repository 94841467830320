import Icon from "@ant-design/icons";
import React from "react";

const SearchSvg = () => (
  // SVG PATH OF ICON REQUIRED NOT IMAGE, temporary image.
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="21px"
    height="20px"
    viewBox="0 0 47 46"
    enableBackground="new 0 0 47 46"
    xmlSpace="preserve"
  >
    <image
      id="image0"
      width="47"
      height="46"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAuCAAAAACdEB/VAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
cwAACxMAAAsTAQCanBgAAAAHdElNRQfmCgUHCgiA3MyRAAADhnpUWHRSYXcgcHJvZmlsZSB0eXBl
IHhtcAAASImlVkt24zgM3PMUcwQKAAHxOLIl7ea9WfbxpwqUY8t2upO09UIxJAgUCh+x/Pr3v/IP
fpO1uehV95ij+uTqF29hUl28eXj3TVeRbb9cLrsI1rsbV1pos1WrrVFNITt7LzbHEjjYNBbbmjne
UKiKQyK66yZVrzHrErPjoK805pNU/u9X30K5V2gBaMx34tBlbHyIJ5K7GqxdeMI+Tkhts62tFiG4
PXJJm2zqsgLPpKYzVkI71iZ1/F9VsXvFqmA+YWfHe8Y4qRZZc3HB6BjhodSnRw73BCgg08TM/Mk1
KblJ9+YwPFUXuLNH/mQLSMmWiCMtdz6JRDAKxnUYAKLQQHzISMxwCxa4f0YBCAgVAiHek6kOhiBx
2/epgLA9QCxRDWIfY0GCX/Gmse0eIsy1YLrClRl4KsGD2nqL1bNKW5E9n6gtZ73v1ULh+ka5hlkL
H96UP4N+r5zJHeALMnuDZAlNjgQHGvUj4UE86T7bh2p9E92aZ6907QgtBTE2HGzMa6oDuoFgeuNY
RYXtiB+wcVa+GpXDwafjd2fL3RbVGDOlgQ2KSqOi3a8v1fWqrhZk36CvytyydfAA0lqlf2KimY03
DWTdYWStTVDDimvNMG9Zdx0jWolcWJa5Uk9qj+7gQ+VkVl51/0x1gW5GrLtHfwf5q2rLo94sgcVY
wprAt8wuGKDqIYMI9my5iyLptGHW8DhcQ8UzDdM6lkOZUSvio5ihzaGbsc3JOAhMkzJ97QygZIvY
0SA+0vCG4NSAU2Ygec2ldI1fj/Szwr7Bfoc6AOGMDZZrQNvACJlyyCMJiAk/+uHcRdE+8cOC6eNT
hCPXz9B+8GUDJTokAIME4gAqycZPO2Ap36Z28OW5S5aIr+NvdkaYjbMnoqxr6MzyXZkAyVDnHNha
VtiGwia5b/jhV8ivWf1HLo38uffrR5fxLc2UgHT0p68KUiSmcl5Ek+iwe+99C4Nxa/aJ9KHmjhRI
6XKIfzEJQcAnnJbvknrGGwGmGrGWHxfrozrQXVCsBr0TPbeMEvPlK63s3MnKSyvbv0s/VE+BhDxx
/z3qH+u/sHr+qv4PAOXM/c+pL2fuf079n78ij9zxGHmjgXF/2W/hwa1W9nPVjKjl1/63tXY/x9tT
eb0+PYuNq95d/fP9duyUN7fsRvcy3DIuyOV/U+aoKErb2NIAAAJWSURBVEjHjZVdd5pAEIbfXZBK
/CqxpkbjsU1z7H3//9+op0lqm6ZV25hUqZgILG4vZoEFwePcMLs87zA7w+4yiYwFrh8GgpkVy24Z
2DdTH/iP7iYdsbrzZk/C0vhittjl3hq9M17Gz+dRwfetwWkhH939RbH1+gV8eLtBmTnvjTwffg7j
iVeObZlSBBs3iKfqIyPLR9cqOmt37eSVN/2nvPZllp+o3OvDk0wm6+8+Of2ezs9/kd8ZslzqYqI+
8bGpZjgg5uRevMvjMEeqmvcy5WdU9855QW3YZR0A8PKU8NsHlXthMdmVBQCY7mL+SQIA20+GrDIA
AASrmCenbaPETqlmruKDZwBAF6VG64rj07Nql/MtBgDCI34LAHDKcRgNAACBXAAADoQHagCAUOVP
VTjE0x6kwJxk1tE87bcdjjSui0ss1FLmlElwiBdaVpxk3iHe03mqpHsovKdVlVP3gvLtDtp8lq3W
S7pZKS5/AwBeq/ooZ7Uu4x/8LN+hw+IuKsa3UwCA3Yx58y3NT2QRHt1SnAsW8zinkro/CgTRDf2W
DZUOOMBpx2Fxvdfl7ZhqyQZIebTVybAeL7OV+TOm6Kgl5xiTAOTXlRrWu634wBfLuZ9InQ9M4xF9
eU4+2KiZlZ0Q601mObFAnefRtxUOmxIk98u0pMOs+qIL0vtode8X4I1B9cbTBNp9Jx+nYY62+w4Q
6QKmr2q3dN20B1bLaTJqWipguaZKz/eF4KZZOUnvjkRwNmSFf03eYgH7xI/BYYzoGpDyOD4WNIzj
8gEQ/VygdmX9BxPNz1P41ymfAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTEwLTA1VDA3OjEwOjA4
KzAwOjAwsuDe3QAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0xMC0wNVQwNzoxMDowOCswMDowMMO9
ZmEAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjItMTAtMDVUMDc6MTA6MDgrMDA6MDCUqEe+AAAA
AElFTkSuQmCC"
    />
  </svg>
);

const SearchIcon = (props) => {
  return <Icon component={SearchSvg} {...props} />;
};

export default SearchIcon;
