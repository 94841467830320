import React from "react";
import styled from "styled-components";
import { Typography } from "antd";

const ContainerLabelSecondary = (props) => {
  return (
    <SyledTypographyTitleSecondary {...props}>
      {props.children}
    </SyledTypographyTitleSecondary>
  );
};

export default ContainerLabelSecondary;

const SyledTypographyTitleSecondary = styled(Typography.Title)`
  font-family: "AvenirLTPro";
  font-weight: 500 !important; //medium
  font-size: 1.25rem !important; // 20px
  line-height: 1.25rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
`;
