import React from "react";
import styled from "styled-components";
import { Card } from "antd";

const Container = (props) => {
  return <StyledCard {...props}>{props.children}</StyledCard>;
};

export default Container;

const StyledCard = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 37px;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;

  .ant-card-head {
    border-bottom: none;
    padding: 0 30.5px;
  }

  .ant-card-body {
    padding: 20px 0 0 0;
  }

  // All individual tabs
  .ant-tabs-tab {
    padding-top: 29px !important;
    font-size: 18px !important;
    font-family: "AvenirLTPro";
    font-weight: 500;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:hover {
    color: #04ccff;
  }
`;
