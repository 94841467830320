import React from "react";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  mem
} from "../../shared/custom-react-native";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import CustomDatatable from "../_components/CustomDatatable";

const MemberList = ({ _this }) => {

  const {
    data,
    all_data,
    width,
    id
  } = _this.state

  const deleteMember = (item, index) => {
    _this.show_loading();

    UI.webRequest({
      method: "delete",
      url: `admin/usergroup/${id}/member/${item._id}`,
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {

        const filter = data.filter((a, f) =>  f !== index);
        mem.update('edit_user_group', 'members', filter)

        _this.setState({
          data: filter,
          all_data: filter,
        },
        () => {
          _this.reload_table("aktivo_members_list");
        });
      },
      onFinish: () => {
        _this.hide_loading();
      },
    });
  }

  return(
    <View style={{...styles.main_box_content, marginTop: 20}}>
          
      <Text style={{color:UI.colors.secondary, fontWeight:600, fontSize:'18px'}}>Member List</Text>
      
      <UI.Row
        style={styles.main_container_header}
        breakpoint={950}
        _this={_this}
      >

        <View
          style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
        >
          <Text style={styles.entry_header}>Only Show</Text>
          {UI.box(10)}
          <EntryPicker table_name={"aktivo_members_list"} _this={_this} />
        </View>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <InputForm
            _this={_this}
            title={""}
            is_keyword={true}
            placeholder={"Enter Keyword"}
            state_name={"search"}
            onChange={(e) => {
              UI.search_table("aktivo_members_list", e, all_data, "data");
            }}
            right={
              <TouchableOpacity style={styles.search_btn}>
                <Image
                  height={17}
                  width={17}
                  tintColor={"transparent"}
                  source={UI.SEARCH}
                />
              </TouchableOpacity>
            }
            disableLength={true}
            maxLength={UI.Constants.limits.keyword}
          />

          {UI.box(15)}
        </View>
      </UI.Row>
      {UI.box(20)}

      <CustomDatatable
        state_name={"aktivo_members_list"}
        _this={_this}
        columns={[
          { ID: "id" },
          { Name: "name" },
          { "Email Address": "email_add" },
          { "Contact Number": "contact_number" },
          { Action: "action" },
        ]}
        data={data.map((item, index) => {

          return {
            id: item?._id ?? '',
            name: item?.name ?? '',
            email_add: item?.email ?? '',
            contact_number: item?.phone ?? '',
            action: (
              <View key={index} style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  onClick={() => { 
                    // handle_approval(item?._id, "Disapproved");
                    deleteMember(item, index)
                  }}
                >
                  <Image
                    height={28}
                    width={28}
                    tintColor={"transparent"}
                    source={UI.X_SQUARE_FILL}
                  />
                </TouchableOpacity>
              </View>
            ),
          };
        })}
        on_page={(page, callback, table_name) => {
          UI.on_page(all_data, callback, table_name, page);
        }}
      />

      <View
        style={{
          ...styles.no_info_container,
          display: all_data.length > 0 ? "none" : "flex",
        }}
      >
        <UI.Row
          breakpoint={820}
          style={{ maxWidth: 360, alignItems: "center" }}
          _this={_this}
        >
          <Image
            height={100}
            width={100}
            tintColor={"transparent"}
            source={UI.NO_INFO}
          />
          {UI.box(10)}
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: "#BFBFBF",
              textAlign: width > 820 ? "left" : "center",
              marginLeft: width > 820 ? 20 : 0,
            }}
          >
            NO DATA AVAILABLE YET
          </Text>
        </UI.Row>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
});


export default MemberList;