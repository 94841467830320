import React, { useState } from 'react'
import UI from '../../shared/Components/UI/js'
import { Image, mem } from '../../shared/custom-react-native'
import FormItem from '../ui/forms/FormItem'
import Text from '../ui/Text'
import InputField from '../ui/forms/InputField'
import { Button, Col, Form, Row } from 'antd'
import styled from 'styled-components'
import InputPasswordField from '../ui/forms/InputPasswordField'
import { Link } from 'react-router-dom'
import Loading from '../../modules/_components/Loading'
import ServiceAuthApi from '../../api_services/auth'
import ProcessFailedModal from '../ui/modals/ProcessFailedModal'
import ProcessFailedModalCrossIcon from '../../icons/ProcessFailedModalCross'
import { Image as AntdImage } from 'antd';

const LoginForm = ({ _this }) => {
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const login = (e) => {
    const payload = {
      ...e,
      role: "provider",
    }
    setIsScreenLoading(true);
    ServiceAuthApi.signinUsingEmail(payload).then(res => {
      setIsScreenLoading(false);
      if (res.status === 200) {
        if (res.role === 'user') {
          setIsFailed(true);
          setErrorMessage("You're not allowed to access this app please contact the system administrator to check your permission");
        } else {
          let role = res.role || "provider";
          const current_role = UI.role_map[role];
          mem.save("jwt_token", res.token);
          mem.save("role", role);
          UI.goTo(`/${current_role}/dashboard`);
        }
      } else {
        setIsFailed(true);
        setErrorMessage(res.message);
      }
    })
  }

  return (
    <>
      <Loading
        visible={isScreenLoading}
        title={""}
        text={`Logging In...`}
        modal_opaque={false}
      />
      <ProcessFailedModal
        title={"Process Failed"}
        banner={<ProcessFailedModalCrossIcon />}
        description={errorMessage}
        buttonText={"Dismiss"}
        buttoncolor={"#004f99"}
        visible={isFailed}
        onOk={() => {
          setIsFailed(false)
        }}
        onCancel={() => setIsFailed(false)} // x and cancel button
      />
      <Col>
        <Link to="/onboard/login">
          <Row>
            <Image
              height={20}
              width={20}
              tintColor={"#999999"}
              source={UI.CHEVRON_LEFT}
            />
            <Text level={5} style={{ color: "#999999" }}>Cancel</Text>
          </Row>
        </Link>
        <Col className='gap-5'>
          <Col className='flex flex-col items-center gap-5 mb-5 text-center'>
            <AntdImage src={UI.MWELL_LOGO_1} preview={false} alt="login_logo" />
            <Text level={4}>Login with your email address</Text>
          </Col>
          <StyledForm
            layout={"vertical"}
            onFinish={(e) => {
              login(e)
            }}
          >
            <FormItem
              name="email"
              style={{ display: "flex", alignSelf: "end" }}
              rules={[
                {
                  type: "email",
                  message: "Please input a valid email",
                },
                {
                  required: true,
                  message: "Email Address is required",
                },
              ]}
            >
              <StyledInputField
                style={{ width: "100%" }}
                placeholder="Email Address"
              />
            </FormItem>
            <FormItem
              name="password"
              style={{ display: "flex", alignSelf: "end" }}
              rules={[
                {
                  required: true,
                  message: "Password is required",
                }
              ]}
            >
              <StyledInputPasswordField placeholder="Password" />
            </FormItem>

            <Link to="/onboard/forget-password-step-1">
              <Text level={5} style={{ color: "#00CBFF", textAlign: "center", margin: 20 }}>Forget your password?</Text>
            </Link>

            <FormItem>
              <StyledButton
                id="submit-button"
                htmlType="submit"
              >
                LOGIN
              </StyledButton>
            </FormItem>

            <StyledText level={5}>
              No account yet? <Link to="/onboard/signup"> Sign up </Link>
            </StyledText>

          </StyledForm>
        </Col>
      </Col>
    </>
  )
}

export default LoginForm

const StyledText = styled(Text)`
  text-align: center;
  font-weight: 500 !important;
  font-family: Poppins;
  font-weight: 400 !important; //medium
  line-height: 1.60rem !important; // 23p
  letter-spacing: 0 !important;
  color: #323232;
`;


const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledInputField = styled(InputField)`
  font-size: 18px;
  font-weight: bold;
`

// const StyledLinkTo = styled("a")`
// `;

const StyledInputPasswordField = styled(InputPasswordField)`
  .ant-input {
    font-size: 18px !important;
  }
`

const StyledButton = styled(Button)`
  background-color: #004F99;
  border-radius: 12px;
  width: 100%;
  height: 70px;

  color: #ffffff;
  font-weight: 600;
  font: normal normal medium 18px/19px Avenir LT Pro;
  font-size: 18px;

  &:hover,
  &:active,
  &:focus {
    background-color: #00cbff;
    color: #ffffff;
    border-color: #00cbff;
  }
`;