import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import TouchableOpacity, {
  Image,
  // mem,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout, { PreviewFile } from "../_components/Layout";
import { MemorandumOfAgrrementModal } from "../_components/Modal";
import "./css.css";
import OfficialReceiptModal from "./OfficialReceiptModal";
import DoctorTypeEdit from "./DoctorTypeEdit";
import APIConstants from "../../shared/modules/APIConstants";

const Constants = new APIConstants();
export default class FifthForm extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const _this = this.props._this;
    const {
      // height,
      width,
      // isMobile,
      downloaded_cor,
      // downloaded_or,
      doctorType,
      onboard_data,
    } = _this.state;
    // const user_doctor = JSON.parse(mem.get("user_doctor"));
    const row_width = width > 1000 ? width - 400 : "100%";
   
    return (
      <UI.Column
        style={{ marginBottom: 10 }}
        _this={_this}
        breakpoint={360}
        breakpoint_2={820}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: UI.colors.secondary,
            marginTop: 30,
            marginBottom: 10,
          }}
        >
          FINANCIAL DETAILS
          <Text style={{ color: "#c9c9c9" }}></Text>
        </Text>

        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <DoctorTypeEdit
            currentType={doctorType}
            onChangeType={(e) => this.props.onChangeDoctorType(e)}
          />

          <InputForm
            _this={_this}
            title={"Price Per Consultation"}
            placeholder={"Price Per Consultation"}
            state_name={"consultation_fee"}
            isRequired={true}
            isNumber={true}
            maxLength={13}
            style={{ flex: 1 }}
            is_onblur={true}
            onblur_message={
              Constants.consultation_fee_validation
            }
            disabled={doctorType ? true : false}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Bank Name"}
            placeholder={"Bank Name"}
            state_name={"bankName"}
            style={{ flex: 1 }}
            isRequired={false}
            maxLength={UI.Constants.limits.name}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Bank Branch"}
            placeholder={"Bank Branch"}
            state_name={"bankBranch"}
            style={{ flex: 1 }}
            isRequired={false}
            maxLength={UI.Constants.limits.name}
          />
        </UI.Row>
        {UI.box(20)}
        <UI.Row
          style={{ width: row_width, justifyContent: "space-between" }}
          _this={_this}
          breakpoint={1000}
        >
          <InputForm
            _this={_this}
            title={"Name of the Bank Account"}
            placeholder={"Name of the Bank Account"}
            state_name={"bankAccountName"}
            style={{ flex: 1 }}
            isRequired={false}
            maxLength={UI.Constants.limits.name}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Bank Account Number"}
            placeholder={"Bank Account Number"}
            state_name={"bankAccountNumber"}
            isRequired={false}
            isNumber={true}
            style={{ flex: 1 }}
            maxLength={UI.Constants.limits.bank_account_number}
          />
          {UI.box(10)}
          <InputForm
            _this={_this}
            title={"Maya Account Number"}
            placeholder={"Maya Account Number"}
            state_name={"paymayaAccountNumber"}
            isRequired={false}
            isNumber={true}
            style={{ flex: 1 }}
            maxLength={UI.Constants.limits.bank_account_number}
          />
        </UI.Row>

        <UI.Row
          style={{
            width: row_width,
            justifyContent: "space-between",
            marginTop: 20,
          }}
          _this={_this}
          breakpoint={1350}
        >
          <View style={{ flex: 1 }}>
            <Layout.FilePicker
              _this={_this}
              title={"BIR Form 2303 (Certificate of registration)"}
              isRequired={false}
              additional={
                <View style={{ flexDirection: "row" }}>
                  (.jpeg, .png or .pdf only)
                  {/* <TouchableOpacity
                    onClick={() => {
                      _this.download_file_object(downloaded_cor);
                    }}
                    style={styles.download_button}
                  >
                    <Text style={styles.download_text}>Download</Text>
                  </TouchableOpacity> */}
                </View>
              }
              text={"Drop Your File Here"}
              state_name={"cor"}
              onChange={(file) => {
                // console.log(file);
                _this.auth_upload("cor", file);
              }}
              allowed_files={["jpg", "jpeg", "pdf", "png"]}
              display_type={"filename_size"}
            />

            <PreviewFile _this={_this} {...downloaded_cor} />
          </View>
          {UI.box(10)}
          <View style={{ flex: 1 }}>
            {/* <Layout.FilePicker
              _this={_this}
              title={"Official Receipt"}
              isRequired={false}
              additional={
                <View style={{ flexDirection: "row" }}>
                  (.jpeg, .png or .pdf only)
                </View>
              }
              text={"Drop Your File Here"}
              state_name={"or"}
              onChange={(file) => {
                // _this.auth_upload("or", file);
              }}
              allowed_files={["jpg", "jpeg", "pdf", "png"]}
              display_type={"filename_size"}
            /> */}
            <View style={{ width: "100%" }}>
              <Text style={styles.title}>
                Official Receipt
                <Text style={styles.fade}>(.jpeg, or .png)</Text>
              </Text>
              {UI.box(8)}
              <View
                onClick={() => {
                  _this.show_modal(<OfficialReceiptModal _this={_this} />);
                  // this.pickFile();
                }}
                style={{
                  ...styles.file_container,
                  height: 80,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderColor: "#c9c9c9",
                  maxWidth: 400,
                }}
              >
                <Image
                  height={59.35 * 0.6}
                  width={53.29 * 0.6}
                  source={UI.FOLDER}
                  tintColor={"transparent"}
                />
                {UI.box(20)}
                <Text style={styles.file_text}>Drop your file here</Text>
              </View>

              <View style={{ marginTop: 10, flexDirection: "flex-start" }}>
                <TouchableOpacity
                  style={{ width: 100 }}
                  onClick={() => {
                    _this.show_modal(<OfficialReceiptModal _this={_this} />);
                  }}
                >
                  <Text style={{ color: UI.colors.secondary }}>Preview</Text>
                </TouchableOpacity>
              </View>
              {/* <PreviewFile _this={_this} {...downloaded_or} /> */}
            </View>
          </View>
          {UI.box(10)}
          <View style={{ flex: 1 }}></View>
        </UI.Row>

        {
          // (doctor_onboard_data?.isDoctorsOnboardingApproved === "Pending" || onboard_data.isDoctorsOnboardingApproved === "Approved") &&
          // (onboard_data?.isAdvocacyOnboardingApproved?.step3?.status === "Approved" || onboard_data?.isAdvocacyOnboardingApproved?.step3?.status === "Approve")
          onboard_data.isAdvocacyOnboardingApproved?.hasOwnProperty(
            "step3"
          ) && (
            <UI.Row
              style={{
                width: row_width,
                justifyContent: "space-between",
                marginTop: 20,
              }}
              _this={_this}
              breakpoint={1350}
            >
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    color: UI.colors.secondary,
                    marginTop: 30,
                    marginBottom: 10,
                  }}
                >
                  Memorandum of Agreement{" "}
                  <span className="text-red-500"> *</span>
                </Text>
                <Text>
                  Please review the Terms of Use and accept. You cannot proceed
                  with registration without this step
                </Text>
                {UI.box(15)}
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Layout.Btn
                    color={UI.colors.primary}
                    onClick={() => {
                      _this.show_modal(
                        <MemorandumOfAgrrementModal
                          type="userprofileMOA"
                          _this={_this}
                          title="Memorandum of Agreement"
                          checkboxTitle="I confirm that I have read and that I agree to the terms stated in this Terms of Use."
                          onOk={{
                            text: "Continue",
                            method: () => {
                              _this.hide_modal();
                              // window.location.href = "http://www.mwell.com.ph/advocacy/"
                            },
                          }}
                          onDismiss={() => {
                            _this.hide_modal();
                          }}
                        />
                      );
                    }}
                    text={"View and Accept Terms"}
                  />
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    color: UI.colors.secondary,
                    marginTop: 30,
                    marginBottom: 10,
                  }}
                >
                  Marketing Consent <span className="text-red-500"> *</span>
                </Text>
                <Text>
                  Please review the Terms of Use and accept. You cannot proceed
                  with registration without this step
                </Text>
                {UI.box(15)}
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Layout.Btn
                    color={UI.colors.primary}
                    onClick={() => {
                      _this.show_modal(
                        <MemorandumOfAgrrementModal
                          _this={_this}
                          title="Marketing Consent"
                          checkboxTitle="I confirm that I have read and that I agree to the terms stated in this Terms of Use."
                          onOk={{
                            text: "Continue",
                            method: () => {
                              _this.hide_modal();
                              // window.location.href = "http://www.mwell.com.ph/advocacy/"
                            },
                          }}
                          onDismiss={() => {
                            _this.hide_modal();
                          }}
                        />
                      );
                    }}
                    text={"View and Accept Terms"}
                  />
                </View>
              </View>
              <View style={{ flex: 1 }}></View>
            </UI.Row>
          )
        }
      </UI.Column>
    );
  }
}

const styles = StyleSheet.create({
  asterisk: {
    color: "#FF0000",
  },
  option_container: {
    height: 40,
    width: "100%",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 2,
    padding: 5,
    borderColor: "#CACACA",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  btn_text_1: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
  },
  blue_btn_2: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  text_1: {
    color: "#AFAFAF",
    fontSize: 14,
  },
  image_container: {
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  header_3: {
    fontSize: 18,
    color: UI.colors.primary,
    marginTop: 2,
    fontWeight: "bold",
  },
  download_button: {
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: UI.colors.primary,
    marginLeft: 10,
    borderRadius: 5,
    height: 30,
    marginTop: -5,
  },
  download_text: {
    color: "white",
    fontWeight: "bold",
    fontSize: 12,
  },
  file_container: {
    width: "100%",
    height: 80,
    backgroundColor: "#EFF3F7",
    borderRadius: 8,
    borderWidth: 2,
    borderColor: "#C9C9C9",
    borderStyle: "dashed",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  fade: {
    color: "#BFBFBF",
  },
  file_text: {
    fontSize: 15,
    fontWeight: "bold",
    color: UI.colors.secondary,
  },
});
