import React from 'react'
import TouchableOpacity, { mem } from '../../../shared/custom-react-native'
import UI from '../../../shared/Components/UI/js'
import AppleLogin from 'react-apple-login';
import ServiceAuthApi from '../../../api_services/auth'
import { Image } from 'antd';

const LoginUsingApple = ({ _this }) => {
  const handleAppleLoginSuccess = (response) => {

    const payload = {
      accessToken: response.authorization.id_token,
      role: "provider",
    };
    
    _this.setState({ isLoading:true });
    ServiceAuthApi.signInUsingAppleId(payload).then(res => {
      _this.setState({ isLoading:false });
      if (res.status === 200) {
        let role = res.role || "provider"
        const current_role = UI.role_map[role];
        mem.save("jwt_token", res.token);
        mem.save("role", role);
        UI.goTo(`/${current_role}/dashboard`);
      }
    });
  };
  return (
    <TouchableOpacity>
      <AppleLogin
        clientId={UI.Constants.APPLE_CLIENT_ID}
        redirectURI={UI.Constants.WEB_HOST_NAME_URL}
        scope="email name"
        responseType="code"
        responseMode="form_post"
        usePopup={true}
        callback={handleAppleLoginSuccess}
        onFailure={console.error}
        render={(props) => (
          <Image preview={false} {...props} src={UI.APPLE_ICON} alt="apple_icon" className='cursor-pointer'/>
        )}
      />
    </TouchableOpacity>
  )
}

export default LoginUsingApple