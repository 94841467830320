import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import UI from "../../../shared/Components/UI/js";
import { Row, Col, Image } from "antd";
import NavigationButton from "../../ui/NavigationButton";

const LeaveRequestListNavigation = () => {
  const history = useHistory();

  const handleNavigate = () => {
    history.push("/onboard/attendance/leave-request/list");
  };

  return (
    <LeaveRequestBox>
      <NavigationButton
        style={{ marginTop: "26px" }}
        type="primary"
        onClick={handleNavigate}
      >
        View Leave Request
      </NavigationButton>
      <StyledCol>
        <Image
          preview={false}
          height={"133px"}
          width={"133px"}
          src={UI.DOCTOR_LEAVEREQUEST}
        />
      </StyledCol>
    </LeaveRequestBox>
  );
};

export default LeaveRequestListNavigation;

const LeaveRequestBox = styled(Row)`
  /* position: relative;
  display: flex;
  flex-direction: row;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
  width: 100%; */
`;

const StyledCol = styled(Col)`
  margin-left: auto;
  .ant-image {
    position: absolute;
    right: 0;
    bottom: -10px;
  }
`;
