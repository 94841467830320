import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import "./css.css";
const context = "admin";

export default class AdminTermsAndConditions extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Terms & Conditions",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
      message: "",
      id: "",
      status: "",

      is_updated: false,

      date_updated: "",
    });
  }

  onCreate = async () => {
    this.get_data();
  };

  get_data = async () => {
    this.show_loading();
    UI.webRequest({
      method: "get",
      url: "admin/cms/terms-and-conditions",
      params: {},
      onFail: (err) => {},
      onSuccess: (response) => {
        const data = UI.extract_data(response);

        UI.set_input_value("message", data[0].message);
        this.setState({
          message: data[0].message,
          id: data[0]._id,
          date_updated: data[0].updatedAt,
        });
        UI.set_select_value("status", data[0].status);
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  submit = async () => {
    UI.clear_errors();
    const { message, id, status } = this.state;

    if (!status) {
      await UI.error_form("status", "Status is required.");
    }

    if (!message) {
      await UI.error_form(
        "message",
        "Terms & Conditions Description is required."
      );
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    this.show_loading();

    UI.webRequest({
      method: "put",
      url: "admin/cms/terms-and-conditions/" + id,
      params: {
        message,
        status: status,
      },
      onFail: (err) => {
        const data = err.data;
        const error_message = data.message;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: (response) => {
        if (response.data.c < 400) {
          //UI.clear_forms();
          this.show_modal(
            <Success
              title={"Success!"}
              description={"Successfully Updated the Terms and Conditions."}
              onDismiss={() => {
                this.get_data();
                this.hide_modal();
              }}
            />
          );
        }
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;
    const { 
      // height,
       width,
        // isMobile,
        //  is_updated,
          date_updated } = _this.state;

    const updated = UI.timestampToDate(new Date(date_updated).getTime());
    let updated_at = "";
    if (!updated.fail) {
      updated_at =
        updated.month +
        "/" +
        updated.day +
        "/" +
        updated.year +
        "  " +
        updated.h_m +
        " " +
        updated.am_pm;
    }

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Update Terms & Conditions
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below
            </Text>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={1050} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Item Status"}
              isRequired={false}
              state_name={"status"}
            >
              <option value=""></option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#3E3E3E",
                  fontWeight: "bold",
                  marginTop: width > 1050 ? 30 : 0,
                }}
              >
                Terms & Conditions Updated:{" "}
                <Text style={{ color: "#C1C1C1", fontWeight: "normal" }}>
                  {updated_at}
                </Text>
              </Text>
            </View>
            {UI.box(10)}
            <View style={{ flex: 0.5 }}></View>
          </UI.Row>
          {UI.box(10)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Terms & Conditions Description"}
              placeholder={"Enter Description Here"}
              state_name={"message"}
              style={{ flex: 1 }}
              height={200}
              isRequired={false}
              type={"big"}
              onChange={(text) => {
                this.setState({
                  is_updated: true,
                });
              }}
              maxLength={4000}
            />
          </UI.Row>
          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"SAVE CHANGES"}
            />
            {UI.box(20)}

            <Layout.Btn color={"#E00000"} onClick={() => {}} text={"CANCEL"} />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
