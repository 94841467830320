import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { DeleteConfirmation } from "../_components/Modal";
import "./css.css";

const context = "admin";

export default class AdminFAQItems extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "FAQs",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,
      categories: [],
    });
  }

  onCreate = () => {
    this.get_data(true);
  };

  get_data = async (reload) => {
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: "admin/cms/faqs",
      params: {},
      onFail: (err) => {},
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        this.setState({
          categories: data,
        });
        const data2 = data.map((item) => {
          return item.faqs.map((faq) => {
            let temp_faq = faq;
            temp_faq.category_name = item.name;
            temp_faq.category_id = item.id;
            return temp_faq;
          });
        });

        let final_array = [];
        let index = 0;
        data2.forEach((item) => {
          index++;
          item.forEach((obj) => {
            obj.timestamp = new Date(obj.createdAt).getTime();

            final_array.push(obj);
          });
          if (data2.length === index) {
            if (reload) {
              this.setState({
                all_data: final_array,
              });
            }

            this.reload_table("faq_list");
          }
        });
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  delete = async (id) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this item?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();
          this.show_loading();
          UI.webRequest({
            method: "delete",
            url: "admin/cms/faqs/" + id,
            params: {},
            onFail: async (err) => {},
            onSuccess: async (response) => {},
            onFinish: async () => {
              this.get_data(true);
            },
          });
        }}
      />
    );
  };

  sort_by = (format, key) => {
    //format can be asc or desc
    const { all_data } = this.state;

    all_data.sort(UI.dynamicSort(format === "asc" ? key : "-" + key));
    UI.set_input_value("search", "");

    this.setState(
      {
        data: all_data,
        will_filter: false,
      },
      () => {
        this.reload_table("faq_list");
      }
    );
  };

  custom_sort = (key, value) => {
    const { all_data } = this.state;
    let items = all_data.filter((item) => item[key] + "" === value);
    UI.set_input_value("search", "");
    if (value) {
      this.setState(
        {
          data: items,
        },
        () => {
          this.reload_table("faq_list", items);
        }
      );
    } else {
      this.setState(
        {
          data: all_data,
        },
        () => {
          this.reload_table("faq_list", all_data);
        }
      );
    }
  };

  render() {
    const _this = this;
    const { 
      // height,
       width,
      // isMobile 
    } = _this.state;

    return (
      <MainLayout context={context} additional={"faq"} _this={_this}>
        <View style={styles.main_box_content}>
          <Text style={styles.box_header_text}>
            Frequently Asked Questions List
          </Text>
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={false}
              onChange={(e) => {
                this.custom_sort("status", e);
              }}
            >
              <option value="">Select Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Date Added"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "timestamp");
              }}
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Category Name"}
              isRequired={false}
              onChange={(e) => {
                this.custom_sort("category_id", e);
              }}
            >
              <option value="">Select Category</option>
              {this.state.categories.map((item) => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </Layout.SelectPicker>
          </UI.Row>
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={950}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"faq_list"} _this={_this} />
              </View>
            </View>

            <InputForm
              onChange={(e) => {
                UI.search_table("faq_list", e, this.state.all_data, "data");
              }}
              is_keyword={true}
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
            />

            <View style={{ alignSelf: "center", marginTop: 8, marginLeft: 10 }}>
              <Layout.Btn
                color={UI.colors.primary}
                text={"ADD FAQ ITEM"}
                onClick={() => {
                  UI.goTo("/admin/add-faq-item");
                }}
                height={40}
                width={140}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            state_name={"faq_list"}
            _this={_this}
            columns={[
              // { ID: "id" },
              { "FAQ Question": "faq_question" },
              { "FAQ Category": "faq_category" },
              { "Date Added": "date_added" },
              { Status: "status" },
              { Action: "action" },
            ]}
            data={this.state.data.map((item) => {
              const date_added = UI.timestampToDate(
                new Date(item.createdAt).getTime()
              );

              return {
                id: item._id,
                faq_question: item.question,
                faq_category: item.category_name,
                date_added:
                  date_added.month +
                  "/" +
                  date_added.day +
                  "/" +
                  date_added.year,
                status: UI.get_active_text(item),
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onClick={() => {
                        mem.save("faq_item", JSON.stringify(item));
                        UI.goTo("/admin/edit-faq-item");
                      }}
                    >
                      <Image
                        height={32}
                        width={32}
                        tintColor={"transparent"}
                        source={UI.EDIT_BTN}
                      />
                    </TouchableOpacity>
                    {UI.box(5)}
                    <TouchableOpacity
                      onClick={() => {
                        this.delete(item._id);
                      }}
                    >
                      <Image
                        height={32}
                        width={32}
                        tintColor={"transparent"}
                        source={UI.DELETE_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                ),
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
