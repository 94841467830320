import React from 'react'
import UI from '../../shared/Components/UI/js'
import { View, TouchableOpacity, Text, 
  // Shadow,
} from '../../shared/custom-react-native'
import Layout from "../_components/Layout";
import InputForm
// , {RadioBtn} 
from "../_components/InputForm";

const PatientDetailsModal = (props) => {
  // const { appointment, width } = props._this.state;

  let _this = props._this;
  let { visible } = props;

  return (
    <View
      style={{
        display: visible ? "flex" : "none",
        ...styles.modal_container,
      }}
    >
      <View
        style={{
          width: 800,
          background: "white",
          borderRadius: 15
        }}
      >
        <View
          style={{
            ...styles.md_header,
            backgroundColor: UI.colors.header,
          }}
          >
          <TouchableOpacity
            onClick={() => {
              props._this.hidePatientDetails();
            }}
            style={styles.md_close}
          >
            <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="close"/>
          </TouchableOpacity>

          <View style={styles.md_header_text_container}>
            <Text style={styles.md_header_text}>EDIT PATIENT DETAILS</Text>
          </View>
          {UI.box(60)}
        </View>
        <View style={{width: "100%", padding: 40}}>
          <UI.Column
            style={{ marginBottom: 10 }}
            _this={_this}
            breakpoint={1000}
            breakpoint_2={820}
          >
            <UI.Row
              style={{ justifyContent: "space-between" }}
              _this={_this}
              breakpoint={1000}
            >
              <InputForm
                _this={_this}
                title={"First Name"}
                placeholder={"First Name"}
                state_name={"patientFirstName"}
                style={{ flex: 1 }}
                isRequired={true}
                maxLength={UI.Constants.limits.name}
              />
              {UI.box(10)}
              <InputForm
                _this={_this}
                title={"Middle Name"}
                placeholder={"Middle Name"}
                state_name={"patientMiddleName"}
                style={{ flex: 1 }}
                isRequired={false}
                maxLength={UI.Constants.limits.name}
              />
              {UI.box(10)}
              <InputForm
                _this={_this}
                title={"Last Name"}
                placeholder={"Last Name"}
                state_name={"patientLastName"}
                style={{ flex: 1 }}
                isRequired={true}
                maxLength={UI.Constants.limits.name}
              />
            </UI.Row>
            {UI.box(20)}
            <UI.Row
              style={{ justifyContent: "space-between" }}
              _this={_this}
              breakpoint={1000}
            >
              <View style={{flex: 1}}>
                <Text style={styles.title}>Gender</Text>
                {UI.box(8)}
                <View style={{flexDirection: "row", alignItems: "center", margin: "auto 0"}}>
                  <input
                      type="radio"
                      value={0}
                      name={"options_patient_gender"}
                      style={{...styles.option_button}}
                      checked={!_this.state.genderSelected}
                      onChange={() => _this.setState({genderSelected: 0})}
                    />
                    <label style={{...styles.optionLabel}}>Female</label>

                    <input
                      type="radio"
                      value={1}
                      name={"options_patient_gender"}
                      style={{...styles.option_button, marginLeft: 20}}
                      checked={_this.state.genderSelected}
                      onChange={() => _this.setState({genderSelected: 1})}
                    />
                    <label style={{...styles.optionLabel}}>Male</label>
                </View>
              </View>
                
              {UI.box(10)}
              <Layout.DatePicker
                _this={_this}
                title={"Date of Birth"}
                isRequired={true}
                placeholder={"mm/dd/yyyy"}
                state_name={"patientDOB"}
                date_props={{
                  maxDate: new Date(),
                }}
                will_correct={true}
              />
              {UI.box(10)}
              <View style={{ flex: 1 }}></View>
            </UI.Row>
          </UI.Column>

          <View style={{ flexDirection: "row", alignItems: "center", marginTop: 30 }}>
            <TouchableOpacity
              onClick={() => {
                props._this.savePatientData();
              }}
              style={{
                ...styles.md_button,
                ...props.submitButtonStyles ?? "",
              }}
            >
              <Text style={styles.md_btn_text}>Save Changes</Text>
            </TouchableOpacity>
            {UI.box(10)}
            <TouchableOpacity
              onClick={() => {
                props._this.hidePatientDetails();
              }}
              style={{...styles.md_button, backgroundColor: "red", color: "white"}}
            >
              <Text>Cancel</Text>
            </TouchableOpacity>
          </View>
          {UI.box(30)}
        </View>
      </View>
    </View>
  )
}

export default PatientDetailsModal

const styles = {
  modal_during: {
    opacity: 1,
    minHeight: 220,
    minWidth: 220,
    marginTop: 0,
  },
  modal_before: {
    opacity: 0,
    minHeight: 100,
    minWidth: 100,
    marginTop: 200,
  },
  md_button: {
    padding: 30,
    backgroundColor: "#004F99",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  md_btn_text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: "#00CBFF",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
  },
  md_content_container: {
    padding: 25,
    alignItems: "center",
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  optionLabel: {
    marginLeft: "1em",
    color: "black",
    fontWeight: "bold",
    fontSize: 18,
  },
  option_button: {
    width: 30,
    height: 30,
    border: 0,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  modal_container: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    // backgroundColor: "rgba(255,255,255,0.85)",
  },
}