import moment from 'moment';
import React from 'react'
import LaboratoryOrdersApi from '../../../../api_services/Doctors-Portal/LaboratoryOrdersApi';
import UI from '../../../../shared/Components/UI/js';
import { Text, View } from '../../../../shared/custom-react-native';
import { Failed, Success } from '../../../_components/Modal';
import LaboratoryOrderTableList from './LaboratoryOrderTableList';
// import { Checkbox } from '../../../_components/InputForm';
// import ServiceUsergroupsApi from "../../api_services/usergroups";


const LaboratoryOrder = ({ 
  _this,
  laboratorySelected,
  setLaboratorySelected,
  // isIssueLaboratoryOrderToPatient,
  // setIsIssueLaboratoryOrderToPatient
 }) => {

  const issueRequest = () => {
    const laboratorySelect = laboratorySelected.map(item => {
      return {
        id: item.id || item._id,
        name : item.name,
      }
    })

    const payload = {
      laboratoryOrders: laboratorySelect
    }

    _this.show_loading();
    LaboratoryOrdersApi.requestLaboratoryOrders(_this.state.appointment._id, payload).then( res => {
      _this.hide_loading();
      if (res.status === 200) {
        _this.show_modal(<Success
          title={"Success!"}
          description={"You have successfully updated the selected information"}
          onDismiss={() => {
            _this.get_appointment(_this.state.appointment?._id)
            _this.hide_modal();
          }}
        />)
      } else {
        _this.show_modal(
          <Failed 
            description={res.message} 
            onDismiss={() => { 
              _this.hide_loading();
              _this.hide_modal()
            }} 
          />
        );
      }
    });
  }

  return (
    <div style={{ width:'100%', background:'#F5FAFF'}} className="rounded-b-lg p-3">
	
      {UI.box(10)}

			<Text
        style={{
            fontWeight: "bold",
            fontSize: '15px',
            color: "#0E4D94",
          }}
        >
          Laboratory Order
      </Text>

			<div id='icd_10' 
        style={{
          fontSize: '14px',
          resize: "none",
          color: 'black',
          minHeight: '50px',
          width: "100%",
          padding:'10px 14.44px' ,
          borderRadius:'10px',
          border: '2px solid #C9C9C9',
          marginTop:'5px',
          position: 'relative'
        }}
				onClick={() => _this.show_modal(
          <LaboratoryOrderTableList _this={_this} setLaboratorySelected={setLaboratorySelected} laboratorySelected={laboratorySelected}/>
				)}
			>
				{
        laboratorySelected.length > 0 ? 
        laboratorySelected.map((laboratoryItem) => {
        return <Text 
          style={{
            color: UI.colors.secondary, 
            background: "rgb(203 231 255)",  
            marginRight:10, padding:5, 
            fontWeight:'bold',
            borderRadius:'5px'}}
          >
            {  }
            {laboratoryItem.name ?? ""}

            <strong className='icd_10_remove' style={{marginLeft: 5, fontSize:17, cursor:'pointer'}} onClick={(e) => {
              e.stopPropagation();
              const res = laboratorySelected.filter(item => laboratoryItem.name !== item.name)
              setLaboratorySelected(res);
            }
            }>x</strong>
          </Text>
        })
          : <Text style={{color:'black'}}>Select Item</Text>
        }
			</div>

			{UI.box(10)}

			{
				laboratorySelected.length > 0 && 
					<View style={{
						width:'100%',
						marginTop: 10
					}}>
						<Text
								style={{
									fontWeight: "bold",
									fontSize: '15px',
									color: "#0E4D94",
								}}
							>
								Laboratory Order Summary
						</Text>
		
						<View style={{
								marginBottom:'0px',
								border: "2px dashed #E6E6E6",
								borderRadius: '10px',
								padding:'15px 20px',
								position: 'relative',
								marginTop: 10
							}}
						>
							{
								laboratorySelected.map((laboratoryItem) => {
									return (
										<div className='flex items-center gap-3'> 
											<strong style={{ color: UI.colors.secondary}}> &#x2022; </strong>
											<Text
												style={{
													fontWeight: "bold",
													fontSize: '15px',
													color: "#0E4D94",
												}}
											>
												{laboratoryItem?.name} 
											</Text> 
										</div>
									)
								})
							}
						</View>
					</View>
			}

			<div className='flex justify-end items-center mt-5'>
        {/* <Checkbox
          text="Issue to Patient"
          is_disabled={laboratorySelected.length > 0 ? false : true}
          is_checked={isIssueLaboratoryOrderToPatient} 
          onClick={() => setIsIssueLaboratoryOrderToPatient(!isIssueLaboratoryOrderToPatient)}
        /> */}
        <div className='flex items-center gap-5'>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: '15px',
              color: "#0E4D94",
            }}
          >
            {_this.state.appointment.notes?.laboratory?.orders.length > 0 && "Request issued " + moment(_this.state.appointment.notes?.laboratory.createdAt).fromNow()}
          </Text>
          <button
            onClick={() => {
              if (laboratorySelected.length === 0) {
                return _this.show_modal(
                  <Failed description={`Select Laboratory Order`} onDismiss={_this.hide_modal} />
                )
              }
              issueRequest()
            }}
            className="py-3 px-5 font-semibold flex gap-3 items-center rounded-md text-white"
            style={{ background: UI.colors.secondary }}
          >
            <img width={15} src={UI.UPDATE_PEN} alt="UPDATE_PEN"/>
            {_this.state.appointment.notes?.laboratory?.orders.length > 0 ? "Update" : "Issue"}  Request
          </button>
        </div>
        </div>
		</div>
  )

}

export default LaboratoryOrder
