import React from "react";
import styled from "styled-components";
import { Form } from "antd";

const FormItem = React.memo((props) => {
  return <StyledFormItem {...props}>{props.children}</StyledFormItem>;
});

export default FormItem;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-required:before {
    display: none !important; // remove default antd asterisk position (left of label)
  }

  // Add asterisk after the label if required: true
  .ant-form-item-required div:after {
    margin-left: 4px;
    color: #b90c0c;
    font-family: Poppins;
    content: "*";
  }
`;
