import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";
const context = "admin";

export default class AdminCMSHomescreen extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "App Content",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],

      selected_tab: "quicklinks", //can be quicklinks, banner_slide, mwell_banner
      context: context,
    });
  }

  onCreate = () => {
    setTimeout(() => {
      this.execute_resizes(this);
      this.execute_resizes(this);
    }, 300);
  };

  render() {
    const _this = this;
    const { 
      // height, 
      width, 
      // isMobile, 
      selected_tab } = _this.state;

    const content_main_container = UI.measure("content_main_container").width;

    return (
      <MainLayout context={context} additional={"homescreen"} _this={_this}>
        <View
          id={"content_main_container"}
          style={{ ...styles.main_box_content, padding: 0, minHeight: 600 }}
        >
          <UI.Row
            style={{ height: "100%" }}
            _this={_this}
            breakpoint={620}
            basis={content_main_container}
          >
            <View
              style={{
                borderColor: "#EBEBEB",
                borderStyle: "solid",
                borderRightWidth: content_main_container > 620 ? 2 : 0,
                borderBottomWidth: content_main_container <= 620 ? 2 : 0,
                width: content_main_container > 620 ? 250 : "100%",
                height: content_main_container > 620 ? "100%" : null,
              }}
            >
              <Text style={styles.menu_main_text}>App Content Menu</Text>

              <MenuButton
                text={"Quicklinks"}
                is_active={selected_tab === "quicklinks"}
                onClick={() => {
                  this.setState({
                    selected_tab: "quicklinks",
                  });
                }}
              />
              <MenuButton
                text={"Banner Slide"}
                is_active={selected_tab === "banner_slide"}
                onClick={() => {
                  this.setState({
                    selected_tab: "banner_slide",
                  });
                }}
              />

              <MenuButton
                text={"Mwell + Banner"}
                is_active={selected_tab === "mwell_banner"}
                onClick={() => {
                  this.setState({
                    selected_tab: "mwell_banner",
                  });
                }}
              />
            </View>

            <View style={{ flex: 1 }}>
              {/* Main contents here */}

              {/* For quick links */}
              <View
                style={{
                  width: "100%",
                  display: selected_tab === "quicklinks" ? "flex" : "none",
                }}
              >
                <UI.Row
                  style={{
                    padding: 20,
                    alignItems: width > 800 ? "center" : "flex-start",
                  }}
                  _this={_this}
                  breakpoint={800}
                >
                  <Text
                    style={{ ...styles.menu_main_text, padding: 0, flex: 1 }}
                  >
                    Homescreen Quicklinks
                  </Text>
                  {UI.box(10)}

                  <Layout.Btn
                    color={UI.colors.primary}
                    text={"ADD ITEM"}
                    onClick={() => {
                      UI.goTo("/admin/add-quicklink");
                    }}
                    width={100}
                    height={40}
                  />
                </UI.Row>
              </View>

              {/* For banner slide */}
              <View
                style={{
                  width: "100%",
                  display: selected_tab === "banner_slide" ? "flex" : "none",
                }}
              >
                <UI.Row
                  style={{
                    padding: 20,
                    alignItems: width > 800 ? "center" : "flex-start",
                  }}
                  _this={_this}
                  breakpoint={800}
                >
                  <Text
                    style={{ ...styles.menu_main_text, padding: 0, flex: 1 }}
                  >
                    Homescreen Banners
                  </Text>
                  {UI.box(10)}

                  <Layout.Btn
                    color={UI.colors.primary}
                    text={"ADD ITEM"}
                    onClick={() => {
                      UI.goTo("/admin/add-banner");
                    }}
                    width={100}
                    height={40}
                  />
                </UI.Row>
              </View>

              {/* For mwell + banner */}
              <View
                style={{
                  width: "100%",
                  display: selected_tab === "mwell_banner" ? "flex" : "none",
                }}
              >
                <UI.Row
                  style={{
                    padding: 20,
                    alignItems: width > 800 ? "center" : "flex-start",
                  }}
                  _this={_this}
                  breakpoint={800}
                >
                  <Text
                    style={{ ...styles.menu_main_text, padding: 0, flex: 1 }}
                  >
                    mWell+ Banner
                  </Text>
                  {UI.box(10)}

                  <Layout.Btn
                    color={UI.colors.primary}
                    text={"ADD ITEM"}
                    onClick={() => {
                      UI.goTo("/admin/add-mwell-banner");
                    }}
                    width={100}
                    height={40}
                  />
                </UI.Row>
              </View>

              <CustomDatatable
                disable_pagination={true}
                state_name={"quicklinks_table"}
                _this={_this}
                columns={[
                  { "#": "id" },
                  { Preview: "preview" },
                  { "Item Name": "item_name" },
                  { "Date Added/Updated": "date_added" },
                  { Status: "status" },
                  { Action: "action" },
                ]}
                data={[
                  {
                    id: "001",
                    preview: (
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Image
                          height={35}
                          width={35}
                          tintColor={"transparent"}
                          source={UI.TRANSACTION_USER}
                        />
                      </View>
                    ),
                    item_name: "Item Name",
                    date_added: "mm/dd/yyyy 10:00am",
                    status: <Text style={{ color: "#004F99" }}>Active</Text>,
                    action: (
                      <View style={{ flexDirection: "row" }}>
                        <TouchableOpacity onClick={() => {}}>
                          <Image
                            height={28}
                            width={28}
                            tintColor={"transparent"}
                            source={UI.VIEW_BTN}
                          />
                        </TouchableOpacity>
                        {UI.box(5)}
                        <TouchableOpacity onClick={() => {}}>
                          <Image
                            height={28}
                            width={28}
                            tintColor={"transparent"}
                            source={UI.EDIT_BTN}
                          />
                        </TouchableOpacity>
                        {UI.box(5)}
                        <TouchableOpacity>
                          <Image
                            height={28}
                            width={28}
                            tintColor={"transparent"}
                            source={UI.DELETE_BTN}
                          />
                        </TouchableOpacity>
                      </View>
                    ),
                  },
                ]}
                current_page={1}
                number_of_pages={20}
                on_page={(page, callback) => {}}
              />
            </View>
          </UI.Row>
        </View>
      </MainLayout>
    );
  }
}

const MenuButton = (
  props = { text: "", is_active: false, onClick: () => {} }
) => {
  const { is_active } = props;

  return (
    <TouchableOpacity onClick={props.onClick} style={{ flexDirection: "row" }}>
      {is_active && <View style={styles.active_design}></View>}
      <Text
        style={{
          color: is_active ? UI.colors.primary : "#3E3E3E",
          ...styles.menu_btn,
        }}
      >
        {props.text}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  menu_main_text: {
    fontSize: 18,
    fontWeight: "bold",
    color: UI.colors.secondary,
    padding: 20,
  },
  active_design: {
    height: "100%",
    width: 4,
    backgroundColor: UI.colors.primary,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  menu_btn: {
    fontSize: 16,
    fontWeight: "bold",
    padding: 10,
    paddingLeft: 20,
  },
  timeline_circle: {
    height: 22,
    width: 22,
    borderRadius: 100,
    backgroundColor: UI.colors.primary,
    borderWidth: 4,
    borderColor: "#E3E3E3",
    marginTop: -10,
    marginLeft: -12,
  },
  timeline_item: {
    height: 100,
    width: "100%",
    borderLeftStyle: "solid",
    borderLeftWidth: 2,
    borderLeftColor: "#E3E3E3",
    marginLeft: 10,
  },
  unused_text: {
    fontSize: 12,
    fontWeight: "bold",
    color: "white",
  },
  unused_box: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 10,
    backgroundColor: "#D3D3D3",
    justifyContent: "center",
    alignItems: "center",
  },
  detail_value: {
    color: "black",
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  detail_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  flex_1: {
    flex: 1,
  },
  detail_left: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  detail_item: {
    flexDirection: "row",
    alignItems: "center",
    width: 370,
    marginBottom: 5,
  },
  line: {
    width: "100%",
    height: 3,
    backgroundColor: "#EFEFEF",
  },
  ai_fl: {
    flexDirection: "row",
    alignItems: "center",
  },
  sub_name: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#898989",
  },
  main_name: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
