import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { Row, Col, Typography, Tooltip } from "antd";
import styled from "styled-components";
// import UI from "../../../shared/Components/UI/js";
import DropdownIcon from "../../../icons/Dropdown";
// import SearchIcon from "../../../icons/Search";
import Container from "../../../ComponentsFC/ui/Container";
import ContainerLabelPrimary from "../../../ComponentsFC/ui/ContainerLabelPrimary";
import CustomDatePicker from "../../../ComponentsFC/ui/forms/DatePicker";
import CustomFieldLabel from "../../../ComponentsFC/ui/forms/FieldLabel";
// import CustomInputField from "../../../ComponentsFC/ui/forms/InputField";
import CustomSelectField from "../../../ComponentsFC/ui/forms/SelectField";
import DataTable from "../../../ComponentsFC/DataTable";
import toTitleCase from "../../../utils/toTitleCase";
import ServiceDPAttendanceApi from "../../../api_services/Doctors-Portal/DPAttendanceApi";
// import FileDownload from "js-file-download";
import Loading from "../../../modules/_components/Loading";

const leaveRequestStatusSelectOptions = [
  { type: "Approved", value: "Approved" },
  { type: "Disapproved", value: "Disapproved" },
  { type: "Pending", value: "Pending" },
];

const onlyShowEntriesSelectOptions = [
  { type: "5 Entries", value: "5" },
  { type: "10 Entries", value: "10" },
  { type: "30 Entries", value: "30" },
];

const LeaveRequestList = () => {
  const [tableData, setTableData] = useState([]);
  const [tableEntries, setTableEntries] = useState(30);
  const [totalRecords, setTotalRecords] = useState();
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isScreenLoading, setIsScreenLoading] = useState();

  const [leaveRequestStatus, setLeaveRequestStatus] = useState();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [appliedStartDate, setAppliedStartDate] = useState();
  const [appliedEndDate, setAppliedEndDate] = useState();

  // const [searchQuery, setSearchQuery] = useState("");

  const getLeaveRequestList = useCallback(async () => {
    try {
      let urlParams = {};
      urlParams.sort = "createdAt";

      if (currentPageNum) {
        urlParams.page = currentPageNum;
        urlParams.limit = tableEntries;
      }

      if (appliedStartDate && appliedEndDate) {
        urlParams.startDate = appliedStartDate;
        urlParams.endDate = appliedEndDate;
      }

      if (leaveRequestStatus) {
        urlParams.status = leaveRequestStatus;
      }

      // if (searchQuery) {
      //   urlParams.searchString = searchQuery;
      // }

      setIsScreenLoading(true);
      setIsTableLoading(true);
      const apiResponse = await ServiceDPAttendanceApi.getLeaveRequestList(
        urlParams
      );
      setIsScreenLoading(false);
      setIsTableLoading(false);
      // console.log("GET LEAVE REQUEST LIST: ", apiResponse);
      setTotalRecords(apiResponse?.results?.total);
      setTableData(apiResponse?.data?.map((obj) => ({ ...obj, key: obj._id })));
    } catch (err) {
      console.error(err);
    } finally {
      setIsScreenLoading(false);
      setIsTableLoading(false);
    }
  }, [
    currentPageNum,
    tableEntries,
    appliedStartDate,
    appliedEndDate,
    leaveRequestStatus,
  ]);

  useEffect(() => {
    getLeaveRequestList();
  }, [getLeaveRequestList]);

  useEffect(() => {
    const isStartEndDateNotEmpty = startDate && endDate;
    const isStartEndDateEmpty = startDate === "" && endDate === "";

    if (isStartEndDateNotEmpty) {
      setAppliedStartDate(startDate);
      setAppliedEndDate(endDate);
    }

    // When Date Range select fields are cleared (x btn)
    // then triggers useEffect of getLeaveRequestList() due to arr dependency changes
    if (isStartEndDateEmpty) {
      setAppliedStartDate("");
      setAppliedEndDate("");
    }
  }, [startDate, endDate]);

  const handleChangeStartDate = (_, dateString) => {
    setStartDate(dateString);
  };
  const handleChangeEndDate = (_, dateString) => {
    setEndDate(dateString);
  };

  const handleChangeFilterByStatus = (status) => {
    setLeaveRequestStatus(status);
  };

  const handleViewDoctorFile = async (file) => {
    // console.log("TRIGGERED VIEW DOCTOR FILE", file);
    try {
      // ?container=doctors-onboarding&path=doctors/636136ef833125c4b354f405/leave_attachments/2770867498867686-Stitch.png
      let urlParams = {};

      urlParams.container = "doctors-onboarding";
      urlParams.path = file?.path;
      setIsScreenLoading(true);
      const apiResponse = await ServiceDPAttendanceApi.viewDoctorFile(
        urlParams
      );
      setIsScreenLoading(false);
      // console.log("View Doctor File: ", apiResponse);
      if (apiResponse?.status === 200) {
        //Create blob from file
        const fileBlob = new Blob([apiResponse?.data], {
          type: apiResponse?.data?.type,
        });
        //Create URL from fileBlob
        const fileURL = URL.createObjectURL(fileBlob);
        //Open the URL on new Window
        window.open(fileURL);
      }

      if (apiResponse?.status >= 400) {
        //Create blob from file
        const fileBlob = new Blob(
          [apiResponse?.data?.attachment?.file?.originFileObj],
          {
            type: apiResponse?.data?.attachment?.file?.type,
          }
        );
        //Create URL from fileBlob
        const fileURL = URL.createObjectURL(fileBlob);
        //Open the URL on new Window
        window.open(fileURL);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsScreenLoading(false);
    }
  };

  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  // const handleChangeSearchQuery = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  const handlePaginateTable = (antdSelectedPageNum) => {
    setCurrentPageNum(antdSelectedPageNum);
  };

  const tableColumnHeader = [
    {
      title: "Leave Type",
      dataIndex: "leaveType",
      key: "leaveType",
      render: (type) => {
        if (type) {
          if (type === "vacationLeave") return "Vacation Leave";
          if (type === "sickLeave") return "Sick Leave";
          if (type === "emergencyLeave") return "emergencyLeave";
          if (type === "maternityLeave") return "Maternity Leave";
          if (type === "bereavementLeave") return "Bereavement Leave";
          if (type === "paternityLeave") return "Paternity Leave";
        } else {
          return "--";
        }
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate) => {
        if (startDate) {
          return moment(startDate).utc().format("MM/DD/YYYY");
        } else {
          return "--";
        }
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate) => {
        if (endDate) {
          return moment(endDate).utc().format("MM/DD/YYYY");
        } else {
          return "--";
        }
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (duration) => {
        if (duration) {
          return toTitleCase(duration);
        } else {
          return "--";
        }
      },
    },
    {
      title: "Attachment",
      dataIndex: "attachment",
      key: "attachment",
      render: (files) => {
        const truncatedFileName = (fileName) => {
          const charsLimit = 10;
          return fileName.length > charsLimit
            ? fileName.substring(0, charsLimit) + "..." + fileName.slice(-7)
            : fileName;
        };

        // const dummyFiles = [
        //   {
        //     name: "alsdkfjalsdkfjasldkfjaldkjasdfasdlfkj.png",
        //     path: "sample/fileName.png",
        //   },
        //   {
        //     name: "alsdkfjalsdkfjasldkfjaldkjasdfasdlfkj.png",
        //     path: "sample/fileName.png",
        //   },
        //   {
        //     name: "alsdkfjalsdkfjasldkfjaldkjasdfasdlfkj.png",
        //     path: "sample/fileName.png",
        //   },
        // ];

        if (files?.length > 0) {
          return (
            <>
              {files?.map((file, index) => (
                <StyledTooltip
                  key={index}
                  placement="top"
                  title={
                    <p style={{ whiteSpace: "normal", marginBottom: 0 }}>
                      {file?.originalFilename}
                    </p>
                  }
                  getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                >
                  <p
                    onClick={() => handleViewDoctorFile(file)}
                    style={{
                      color: "#00b7ff",
                      cursor: "pointer",
                      marginBottom: 0,
                    }}
                  >
                    {truncatedFileName(file?.originalFilename)}
                  </p>
                </StyledTooltip>
              ))}
            </>
          );
        } else {
          return "No attachment";
        }
      },
    },
    {
      title: "Leave Reason",
      dataIndex: "reason",
      key: "reason",
      render: (text) => {
        if (text) {
          return (
            <p style={{ whiteSpace: "normal", marginBottom: 0 }}>{text}</p>
          );
        } else {
          return "No reason";
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        if (status) {
          return <CellStyle status={status}>{status}</CellStyle>;
        } else {
          return "--";
        }
      },
    },
    {
      title: "Disapproved Reason",
      dataIndex: "declinedReason",
      key: "declinedReason",
      render: (text) => {
        if (text) {
          return (
            <p style={{ whiteSpace: "normal", marginBottom: 0 }}>{text}</p>
          );
        } else {
          return "--";
        }
      },
    },
  ];

  // const generateLeaveRequestListCSV = async () => {
  //   try {
  //     setIsScreenMsg("Exporting Leave Request List...");
  //     setIsScreenLoading(true);
  //     let urlParams = {};
  //     if (currentPageNum) {
  //       urlParams.page = currentPageNum;
  //       urlParams.limit = tableEntries;
  //     }

  //     if (searchQuery) {
  //       urlParams.searchString = searchQuery;
  //     }

  //     urlParams.export = "LeaveRequestList";

  //     const apiResponse = await ServiceAttendanceApi.getDoctorsAttendanceCSV(
  //       urlParams
  //     );
  //     FileDownload(
  //       apiResponse.data,
  //       `Leave Request List ${UI.get_current_date_string()}.csv`
  //     );
  //     setIsScreenLoading(false);
  //     console.log("Export to CSV status: ", apiResponse.status);
  //   } catch (err) {
  //     // Handle Errors Here
  //     console.error(err);
  //     if (err.response) {
  //       let statusCode = err.response.data.statusCode;
  //       let errorMsg = err.response.data.message;
  //       if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
  //       if (statusCode === 500) {
  //         console.log(`Error: ${statusCode} ${errorMsg}`);
  //       }
  //     }
  //   }
  // };

  return (
    <>
      <Loading
        visible={isScreenLoading}
        title={""}
        text={""}
        modal_opaque={false}
      />
      <Container>
        <ContainerLabelPrimary>Leave Request List</ContainerLabelPrimary>
        {/* ANTD ROW 24 GRID LAYOUT 8x8x8 (span) = 24 */}
        <Row>
          <Col span={11}>
            <CustomFieldLabel>Filter by Date Range</CustomFieldLabel>
            <Row>
              <CustomDatePicker
                placeholder="Start Date"
                style={{
                  width: 150,
                }}
                onChange={handleChangeStartDate}
              />
              <CustomDatePicker
                placeholder="End Date"
                style={{
                  width: 150,
                  marginLeft: "20px",
                }}
                onChange={handleChangeEndDate}
              />
            </Row>
          </Col>
          <Col span={8}>
            <CustomFieldLabel>Filter by Status</CustomFieldLabel>
            <CustomSelectField
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              placeholder="Select Option"
              style={{
                width: 441,
              }}
              selectoptions={leaveRequestStatusSelectOptions}
              allowClear
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeFilterByStatus}
            />
          </Col>
        </Row>
      </Container>

      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <Col span={3}>
            <CustomSelectField
              getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
              defaultValue="30"
              style={{
                width: 190,
              }}
              selectoptions={onlyShowEntriesSelectOptions}
              suffixIcon={<DropdownIcon />}
              onChange={handleChangeNumOfEntries}
            />
          </Col>
          {/* <Col
            span={8}
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "auto",
            }}
          >
            <CustomInputField
              placeholder="Enter Keyword"
              allowClear
              suffix={<SearchIcon />}
              value={searchQuery}
              onChange={(e) => {
                handleChangeSearchQuery(e);
              }}
              onPressEnter={getLeaveRequestList}
              style={{ width: 285 }}
            />
            <StyledButton type="primary" onClick={generateLeaveRequestListCSV}>
              Export to CSV
            </StyledButton>
          </Col> */}
        </Toolbar>

        <DataTable
          tableColumnHeader={tableColumnHeader}
          dataSource={tableData}
          defaultPageSize={30}
          pageSizeOptions={["5", "10", "30"]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isTableLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default LeaveRequestList;

// CSS Styled Components ----------------------

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
  .ant-table-cell {
    font-size: 20px !important;
  }

  /* // 2ND COLUMN OF TABLE
  .ant-table-row .ant-table-cell:nth-child(2) {
    white-space: pre;
  } */
`;

const Toolbar = styled(Row)`
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 20px; // 20px
  line-height: 20px; // 20px
  letter-spacing: 0;
`;

// const StyledButton = styled(Button)`
//   height: 60px;
//   border-radius: 12px;
//   border: none;
//   background-color: #0e4d94;
//   font-family: Poppins;
//   font-weight: 500;
//   font-size: 20px; // 20px
//   margin-left: 20px;
//   padding-left: 20px;
//   padding-right: 20px;
//   &:hover {
//     background-color: #43c8ff;
//   }
// `;

const CellStyle = styled(Typography.Text)`
  // Dynamically render style using props of this component
  color: ${(props) =>
    props.status === "Approved"
      ? "#64C31E"
      : props.status === "Pending"
      ? "#00CBFF"
      : props.status === "Disapproved"
      ? "#CC0404"
      : "#000000"};
`;

const StyledTooltip = styled(Tooltip)`
  /* div.ant-tooltip-inner {
    white-space: normal;
  } */
`;
