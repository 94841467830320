import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";

const context = "transaction";
export default class TransactionOncallDetails extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "On-Call Consults",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;
    // **Commented out, currently not used.
    // const { height, width, isMobile } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <UI.Row breakpoint={1144} _this={_this} style={{ width: "100%" }}>
          <View style={{ ...styles.main_box_content, flex: 1 }}>
            <Text style={styles.box_header_text}>Consultation Details</Text>
            {UI.box(20)}

            <UserBox
              name={"Patient Name Here"}
              next={"emailaddress@email.com"}
              sub={"+63 995 123 4567"}
              image={UI.SAMPLE_USER}
            />

            {UI.box(20)}
            <View style={styles.line}></View>
            {UI.box(20)}
            <UserBox
              name={"Doctor Name Here"}
              next={"Doctor's Specialty"}
              sub={"Hospital Name Here"}
              image={UI.DOCTOR_USER}
            />
            {UI.box(20)}

            <DetailItem
              name={"Consult Price"}
              value={"Php 000.0"}
              image={UI.CONSULT_PRICE}
            />
            <DetailItem
              name={"Payment Method"}
              value={"Bayad App"}
              image={UI.PAYMENT_METHOD}
            />
            <DetailItem
              name={"Booking Type"}
              value={"Booked"}
              image={UI.BOOKING_TYPE}
            />
            <DetailItem
              name={"Booking Date"}
              value={"mm/dd/yyyy"}
              image={UI.BOOKING_DATE}
            />
            <DetailItem
              name={"Booking Time"}
              value={"00:00 am"}
              image={UI.BOOKING_TIME}
            />
          </View>
          {UI.box(20)}
          <View
            style={{
              ...styles.main_box_content,
              flex: 1.4,
              alignSelf: "flex-start",
            }}
          >
            <UI.Row _this={_this}>
              <Text style={styles.box_header_text}>Consultation Details</Text>
              {UI.box(10)}
              <Layout.Btn
                text={"Download File"}
                onClick={() => {}}
                color={UI.colors.primary}
              />
            </UI.Row>
            {UI.box(20)}

            <Title text={"Doctor's Notes:"} />
            <Description
              text={
                "Doctor’s Notes: Enter doctors notes here. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s."
              }
            />

            {UI.box(20)}
            <Title text={"Doctor's Prescription:"} />
            <Description
              text={"Doctor’s Precription: Amoxicillin + Clavulanic Acid"}
            />
            <Description text={"(Co-Amoxiclav) 21 Pieces 500/125 mg/tab"} />
            <Description text={"Take one with food every 8 Hours for 7 Days"} />
            <Description
              text={
                "Paracetamol 5 Pieces 500 mg/tab Take one with food every 4 Hours as needed for fever (temp > 37.8 C)"
              }
            />
          </View>
        </UI.Row>
      </MainLayout>
    );
  }
}

const Description = (props = { text: "" }) => {
  return (
    <Text
      style={{
        ...styles.box_header_text,
        fontSize: 14,
        color: "#333333",
        flex: null,
      }}
    >
      {props.text}
    </Text>
  );
};

const Title = (props = { text: "" }) => {
  return (
    <Text
      style={{
        ...styles.box_header_text,
        fontSize: 14,
        flex: null,
        marginBottom: 15,
      }}
    >
      {props.text}
    </Text>
  );
};

const DetailItem = (props = { name: "", value: "", image: "" }) => {
  return (
    <View style={styles.detail_item}>
      <View style={styles.detail_left}>
        <img src={props.image} alt="Detail Item" />
        <Text style={styles.detail_name}>{props.name}</Text>
      </View>
      <View style={styles.flex_1}>
        <Text style={styles.detail_value}>{props.value}</Text>
      </View>
    </View>
  );
};

const UserBox = (props = { name: "", next: "", sub: "", image: "" }) => {
  return (
    <View style={styles.ai_fl}>
      <Image
        height={90}
        width={90}
        tintColor={"transparent"}
        source={props.image}
      />
      {UI.box(15)}

      <View>
        <Text style={styles.main_name}>{props.name}</Text>
        <Text style={styles.sub_name}>{props.next}</Text>
        <Text style={styles.sub_name}>{props.sub}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  detail_value: {
    color: "black",
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  detail_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 12,
  },
  flex_1: {
    flex: 1,
  },
  detail_left: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  detail_item: {
    flexDirection: "row",
    alignItems: "center",
    width: 300,
    marginBottom: 5,
  },
  line: {
    width: "100%",
    height: 3,
    backgroundColor: "#EFEFEF",
  },
  ai_fl: {
    flexDirection: "row",
    alignItems: "center",
  },
  sub_name: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#898989",
  },
  main_name: {
    fontSize: 15,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
