import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import { LeaveRequestList } from "../../ComponentsFC/onboard/attendance/index";

const context = "onboard";
export default class OnboardAttendancePage extends Component {
  constructor() {
    super();
    this.state = {};
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Attendance",
      context: context,
    });
  }

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <LeaveRequestList />
      </MainLayout>
    );
  }
}
