import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import "./css.css";
export default class Statistics extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const _this = this.props._this;
    const { width, siteStats } = _this.state;

    return (
      <UI.Row
        breakpoint={1000}
        _this={_this}
        style={{ width: "100%", marginBottom: 20 }}
      >
        <View style={styles.earnings_container}>
          <View style={{ flex: 1, minWidth: 160 }}>
            <Text style={styles.header_1}>Total Advocacies</Text>
            <Text style={styles.main_header}>
              {UI.pad(siteStats.totalAdvocacies, 4)}
            </Text>
           {UI.box(20)}
            <View
              style={{
                alignSelf: width > 950 ? "flex-start" : "center",
              }}
            >
              <Layout.Btn
                color={UI.colors.primary}
                text={"View Promotions"}
                onClick={() => {
                  UI.goTo("/advocacy/list");
                }}
                height={40}
                width={140}
              />
            </View>
          </View>
          {UI.box(25)}
          <Image
            height={100}
            width={100}
            tintColor={"transparent"}
            source={UI.CALENDAR_1}
          />
        </View>
        {UI.box(20)}
        <View
          style={{
            padding: 20,
            borderRadius: 5,
            backgroundColor: "white",
            ...Shadow._3(),
            flex: 1,
          }}
        >
          <UI.Row breakpoint={1170} _this={_this} style={{ width: "100%" }}>
            <Text style={styles.box_header_text}>
              Site Stats: {UI.get_current_date_string()}
            </Text>
            {UI.box(10)}
            <Layout.SortFilter
              btn_1={{
                text: "Year",
                onClick: (text) => {
                    _this.getSiteStats('year')
                },
              }}
              btn_2={{
                text: "Month",
                onClick: (text) => {
                    _this.getSiteStats('month')
                },
              }}
              btn_3={{
                text: "Day",
                onClick: (text) => {
                    _this.getSiteStats('day')
                },
              }}
              onChange={(val) => {
                _this.setState({
                  [`${"sort_filter_"}${"site_stats"}`]: val,
                });
              }}
              _this={_this}
              state_name={"site_stats"}
            />
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={1175} _this={_this} style={{justifyContent:'space-around'}}>
            <DashItem
              _this={_this}
              icon={UI.IC_DASH_4}
              num={UI.pad(siteStats.activeAdvocaciesCount, 3)}
              color={UI.colors.active}
              text={"Active Advocacy"}
            />
            {UI.box(10)}
            <DashItem
              _this={_this}
              icon={UI.IC_DASH_8}
              num={UI.pad(siteStats.expiredeAdvocaciesCount, 3)}
              color={"#D90000"}
              text={"Expired Advocacy"}
            />
          {UI.box(10)}
            <DashItem
              _this={_this}
              icon={UI.IC_PATIENT}
              num={UI.pad(siteStats.doctorsAndPatientsCount?.patient, 3)}
              color={UI.colors.primary}
              text={"Patients Registered"}
            />
            {UI.box(10)}
            <DashItem
              _this={_this}
              icon={UI.IC_DOCTOR}
              num={UI.pad(siteStats.doctorsAndPatientsCount?.doctor, 3)}
              color={"#004F99"}
              text={"Doctors Registered"}
            />
          </UI.Row>
        </View>
      </UI.Row>
    );
  }
}

const DashItem = (
  props = { _this: null, icon: "", num: "", text: "", color: "" }
) => {
  return (
    <View style={{ flexDirection: "row", flex: 1 }}>
      <Image
        height={60}
        width={60}
        tintColor={"transparent"}
        source={props.icon}
      />

      <View style={{ marginLeft: 15 }}>
        <Text
          style={{ ...styles.main_header, color: props.color, fontSize: 22 }}
        >
          {props.num}
        </Text>
        <Text style={{ ...styles.header_2, fontSize: 12 }}>{props.text}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sort_filter: {
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 2,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#C9C9C9",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  main_header: {
    color: UI.colors.secondary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    backgroundColor: "white",
    padding: 20,
    ...Shadow._3(),
    flexDirection: "row",
    maxHeight: 180,
  },
});