import { Col, DatePicker, Typography } from "antd";
import moment from "moment";
import React, { Component } from "react";
import styled from "styled-components";
import UI from "../../shared/Components/UI/js";
import  TouchableOpacity, {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import "./css.css";
export default class Statistics extends Component {

  render() {
    const _this = this.props._this;

    return (
      <UI.Row
        breakpoint={1000}
        _this={_this}
        style={{ width: "100%", marginBottom: 20 }}
      >
        {/* <View style={{
          backgroundColor: "white",
          borderRadius: 5,
          gap:10,
          ...Shadow._3(),
          padding: 20,
        }}>
          <Text style={styles.header_1}>Status: Active (Added mm/dd/yyy) </Text>
        </View>
        {UI.box(20)} */}
        <View
          style={{
            padding: 20,
            borderRadius: 5,
            backgroundColor: "white",
            ...Shadow._3(),
            flex: 1,
          }}
        >
          <UI.Row breakpoint={1170} _this={_this} style={{ width: "100%" }}>
            <Text style={styles.box_header_text}>
              Site Stats: {UI.get_current_date_string()} - {moment().clone().endOf('month').format('LL')}
            </Text>
            {UI.box(10)}
            <Layout.SortFilter
              btn_1={{
                text: "Year",
                onClick: (text) => {
                  _this.setState(
                    { duration: text },
                    () => {
                      _this.onCreate()
                    }
                  );
                },
              }}
              btn_2={{
                text: "Month",
                onClick: (text) => {
                  _this.setState(
                    { duration: text },
                    () => {
                      _this.onCreate()
                    }
                  );
                },
              }}
              btn_3={{
                text: "Set",
                onClick: (text) => {
                  UI.clear_forms()
                  this.props._this.show_modal(
                    <View style={{backgroundColor:'white', borderRadius: 10, width:450}}>
                      <View style={{...styles.md_content_container, width:'100%'}}>

                        <Text style={{fontSize:20, fontWeight:600}}>Set Your Date</Text>
                        {UI.box(10)}

                        <View style={{flexDirection:'row', width:'100%'}}>
                          <ToolItem span={25}>
                            <StyledFieldLabel style={{ color:UI.colors.primary }}>Start Date</StyledFieldLabel>
                            <StyledDatePicker 
                              onChange={(e) => {
                                  console.log()
                                this.props._this.setState({ startDate: UI.momentPHTimezone(e).format('MM/DD/YYYY')})
                              }} 
                              popupStyle={{zIndex: 20000}}
                            />
                          </ToolItem>
                          {UI.box(10)}

                          <ToolItem span={25}>
                            <StyledFieldLabel style={{ color:UI.colors.primary }}>End Date</StyledFieldLabel>
                            <StyledDatePicker 
                              onChange={(e) => {
                                _this.setState({ endDate: UI.momentPHTimezone(e).format('MM/DD/YYYY') })
                              }} 
                              popupStyle={{zIndex: 20000}}
                            />
                          </ToolItem>
                        </View>
                        {UI.box(20)}

                        <View style={{ flexDirection: "row", justifyContent:'flex-end', width:'100%' }}>
                          <TouchableOpacity
                            onClick={() => {
                              _this.setState(
                                { duration: text },
                                () => {
                                  _this.onCreate()
                                }
                              );
                              _this.hide_modal()
                            }}
                            style={{
                              ...styles.md_button,
                              backgroundColor: UI.colors.secondary ,
                            }}
                          >
                            <Text style={styles.md_btn_text}>Save</Text>
                          </TouchableOpacity>
                          {UI.box(10)}
                          <TouchableOpacity
                            onClick={() => {
                              this.props._this.hide_modal()
                            }}
                            style={{
                              ...styles.md_button,
                              border:'2px solid ' + UI.colors.secondary,
                            }}
                          >
                            <Text style={{...styles.md_btn_text, color: UI.colors.secondary}}>Cancel</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  )
                  // _this.load_data_by_duration("day");
                },
              }}
              btn_4={{
                text: "Day",
                onClick: (text) => {
                  _this.setState(
                    { duration: text },
                    () => {
                      _this.onCreate()
                    }
                  );
                },
              }}
              onChange={(val) => {
                _this.setState({
                  sort_filter_site_stats: val,
                });
              }}
              _this={_this}
              state_name={"site_stats"}
            />
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={1175} _this={_this}>
            <UI.Row _this={_this} breakpoint={1300}>
              <DashItem
                _this={_this}
                icon={UI.IC_DASH_17}
                num={UI.pad( _this.state.stats.totalNumberOfEvents ?? 0, 3)}
                color={UI.colors.secondary}
                text={"Total Number of Campaigns"}
              />
              {UI.box(10)}
              <DashItem
                _this={_this}
                icon={UI.IC_DASH_18}
                num={UI.pad( _this.state.stats.totalReferrals ?? 0, 3)}
                color={UI.colors.primary}
                text={"Total Numbers of Registered Referrals"}
              />
              {UI.box(10)}
              {/* <DashItem
                _this={_this}
                icon={UI.IC_DASH_19}
                num={UI.pad( _this.state.stats.totalCodeReferrals ?? 0, 3)}
                color={"#E6BA00"}
                text={"Total Scans & Link Referrals"}
              /> */}
            </UI.Row>
          </UI.Row>
        </View>
      </UI.Row>
    );
  }
}

const DashItem = (
  props = { _this: null, icon: "", num: "", text: "", color: "" }
) => {
  return (
    <View style={{ flexDirection: "row", flex: 1 }}>
      <Image
        height={70}
        width={70}
        tintColor={"transparent"}
        source={props.icon}
      />

      <View style={{ marginLeft: 15 }}>
        <Text
          style={{ ...styles.main_header, color: props.color, fontSize: 22 }}
        >
          {props.num}
        </Text>
        <Text style={{ ...styles.header_2, fontSize: 12 }}>{props.text}</Text>
      </View>
    </View>
  );
};

const ToolItem = styled(Col)`
  flex:1;
  position: relative;
  .ant-picker {
    margin-left: 102px;
  }
  .ant-picker-range {
    margin-left: 62px;
  }
`; 

const StyledDatePicker = styled(DatePicker)`
    margin-left: 0px !important;  
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    width:100%;
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  margin-bottom: 10px !important;
  font-size: 18; // 15px
  font-weight: bold;
`;

const styles = StyleSheet.create({
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  main_header: {
    color: UI.colors.secondary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  md_content_container: {
    padding: 25,
    alignItems: "start",
  },
  md_button: {
    padding: "5px 15px",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  md_btn_text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
});
