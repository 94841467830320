import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { DeleteConfirmation, Failed, Success } from "../_components/Modal";
import "./css.css";
import ProductDescription from "../ecommerce-add-product/ProductDescription";

const context = "merchant";
export default class EcommerceEditProduct extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Products",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      image: UI.PRODUCT_BOX,
      image_file: null,

      banner: UI.PRODUCT_BOX,
      banner_file: null,

      merchant: "",
      name: "",
      price: "",
      imagePath: "",
      bannerPath: "",
      quantity: "",
      description: "",
      purchase_limit: "",
      isComingSoonButton:"",
      id: "",

      did_upload: false,
      did_upload_banner: false,
    });
  }

  onCreate = async () => {
    this.load_data();
  };

  load_data = async () => {
    const data = mem.get("edit_product");
    if (data) {
      const product = JSON.parse(data);
      console.log("check edit data:",product);

      UI.set_input_value("name", product.name ?? "");
      UI.set_input_value("sku", product.sku ?? "");
      UI.set_input_value("price", product.price ?? "");
      UI.set_input_value("sale_price", product.salePrice ?? "");
      UI.set_input_value("purchase_limit", product?.limit === 0 ? "" : product.limit);
      UI.set_input_value("quantity", product.quantity ?? 0);
      UI.set_input_value("stock", product.quantity ?? 0);
      UI.set_input_value("description", product.description ?? "");
      // UI.set_input_value("other_info", product.otherInformation ?? "");
      // UI.set_input_value("sku_link", product.skuWebLink ?? "");
      // UI.set_input_value("purchase_limit", product.limit ?? "");
      UI.set_select_value("category", product.category ?? "");
      UI.set_select_value("status", product.status ?? "");
      UI.set_select_value("isComingSoonButton", product.isComingSoon ? true : false);

      if(product.imagePath) {
        const image = await UI.download_image(product.imagePath, "ecommerce");

        this.setState({
          imagePath: product.imagePath,
          id: product._id,
          image,
        });
      }

      if(product.bannerPath) {
        const banner = await UI.download_image(product.bannerPath, "ecommerce");

        this.setState({
          banner,
          bannerPath: product.bannerPath,
        })
      }
    }
  };

  submit = async () => {
    UI.clear_errors();
    //no merchant yet
    const {
      name,
      price,
      // merchant,
      imagePath,
      bannerPath,
      // quantity,
      description,
      purchase_limit,
      image_file,
      banner_file,
      // id,
      category,
      did_upload,
      did_upload_banner,
      status,
      sku_link,
      other_info,
      sale_price,
      sku,
      stock,
      isComingSoonButton
    } = this.state;
 
    if (!name) {
      await UI.error_form("name", "Product Name is required.");
    }
    if (!price) {
      await UI.error_form("price", "Product Price is required.");
    }
    if (!status) {
      await UI.error_form("status", "Status is required.");
    }
    if (isComingSoonButton === "") {
      await UI.error_form("isComingSoonButton", "Coming Soon is required.");
    }
    if (!purchase_limit || parseInt(purchase_limit) < 0) {
      await UI.error_form("purchase_limit", "Purchase Limit is required.");
    }

    /* if (!quantity) {
      await UI.error_form("quantity", "Product Quantity is required.");
    } */
   
    if (!imagePath) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Invalid Product Picture"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      return;
    }

    if (!bannerPath && !banner_file) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Invalid Product Banner"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      return;
    }
    
    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    let image_obj = {};

    if (did_upload) {
      image_obj = {
        image: image_file,
      };
    }

    if (did_upload_banner) {
      image_obj = {
        bannerImage: banner_file,
      };
    }

    let base64Description = Buffer.from(description);
    
    this.show_loading();

    UI.webRequest({
      method: "put",
      url: "admin/products/" + this.state.id,
      params: {
        name: name,
        price: price,
        imagePath: imagePath,
        bannerPath,
        ...image_obj,
        description: base64Description.toString("base64").replace(/\//g,'slash').replace(/\+/g,'plus'),
        status: status,
        limit: purchase_limit,
        salePrice:sale_price,
        otherInformation: other_info,
        skuWebLink: sku_link,
        sku:sku,
        category:category,
        quantity: stock,
        isComingSoon: (isComingSoonButton === "true" || isComingSoonButton === true) ? true : false
      },
      isMultiPart: true,
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.error;
        if (data.success === false) {
          this.show_modal(
            <Failed
              title={"Failed"}
              description={error_message}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        }
      },
      onSuccess: async (response) => {
        UI.clear_forms();
        this.setState({
          image: UI.PRODUCT_BOX,
          banner: UI.PRODUCT_BOX
        });
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully updated the product."}
            onDismiss={() => {
              this.hide_modal();
              window.history.back();
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  upload_picture = async () => {
    const file = await UI.get_image();
    if (file) {
      this.setState(
        {
          image: file.uri,
          image_file: file.file,
          did_upload: true,
        },
        () => {
          // this.auth_upload();
        }
      );
    }
  };

  upload_banner = async () => {
    const file = await UI.get_image();
    if (file) {
      this.setState(
        {
          banner: file.uri,
          banner_file: file.file,
          did_upload_banner: true,
        }
      );
    }
  };

  auth_upload = async () => {
    /* const imagePath = await UI.upload_image(
      "admin/products/upload",
      this.state.image_file
    );
    this.setState({
      imagePath: imagePath,
    }); */
  };

  delete = async (id) => {
    this.show_modal(
      <DeleteConfirmation
        text={"Are you sure you want to delete this item?"}
        onCancel={() => {
          this.hide_modal();
        }}
        onDelete={async () => {
          this.hide_modal();
          this.show_loading();
          UI.webRequest({
            method: "delete",
            url: "admin/products/" + id,
            params: {},
            onFail: async (err) => {},
            onSuccess: async (response) => {
              this.show_modal(
                <Success
                  title={"Success!"}
                  description={"Successfully deleted the product."}
                  onDismiss={() => {
                    this.hide_modal();
                    window.history.back();
                  }}
                />
              )
            },
            onFinish: () => {
              // UI.goBack();
            },
          });
        }}
      />
    );
  };

  render() {
    const _this = this;
    // **Comment out, currently not used
    // const { height, width, isMobile } = _this.state;
    const Constants = new APIConstants();

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Update Product
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to complete registration
            </Text>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <View style={{ height: 120, width: 120 }}>
              <Layout.FilePicker
                _this={_this}
                title={""}
                additional={""}
                isRequired={false}
                text={""}
                state_name={"product_image"}
                onChange={async (file) => {
                  const uri = await UI.get_uri_from_file(file);
                  if (file) {
                    this.setState(
                      {
                        image: uri,
                        image_file: file,
                        did_upload: true,
                      },
                      () => {}
                    );
                  }
                }}
                height={120}
                component={
                  <img 
                    src={this.state.image}
                    alt="product img"
                    style={{ height: 120, width: 120 }}
                    className="object-cover object-center"
                  />
                }
              />
            </View>
            {UI.box(20)}

            <View style={{ flex: 1.5, justifyContent: "center" }}>
              <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
              >
                Product Picture Uploaded. Recommended Size: 500 px x 500 px <span className="text-red-500 text-base">*</span>
              </Text>
              {UI.box(10)}

              <Layout.Btn
                color={UI.colors.header}
                text={"Upload Product Picture"}
                onClick={() => {
                  this.upload_picture();
                }}
                width={200}
              />
            </View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <View style={{ height: 120, width: 120 }}>
              <Layout.FilePicker
                _this={_this}
                title={""}
                additional={""}
                isRequired={false}
                text={""}
                state_name={"product_banner"}
                onChange={async (file) => {
                  const uri = await UI.get_uri_from_file(file);
                  if (file) {
                    this.setState(
                      {
                        banner: uri,
                        banner_file: file,
                        did_upload_banner: true,
                      },
                      () => {}
                    );
                  }
                }}
                height={120}
                component={
                  <img
                    src={this.state.banner}
                    alt="product img"
                    style={{ height: 120, width: 120 }}
                    className="object-cover object-center"
                  />
                }
              />
            </View>
            {UI.box(20)}

            <View style={{ flex: 1.5, justifyContent: "center" }}>
              <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
              >
                Product Banner Uploaded. Recommended Size: 930 px x 375 px <span className="text-red-500 text-base">*</span>
              </Text>
              {UI.box(10)}

              <Layout.Btn
                color={UI.colors.header}
                text={"Upload Product Banner"}
                onClick={() => {
                  this.upload_banner();
                }}
                width={200}
              />
            </View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Product Name"}
              placeholder={"Enter Product Name"}
              state_name={"name"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.name}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Product SKU"}
              placeholder={"Enter Product SKU"}
              state_name={"sku"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.price}
            />
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Product Category"}
              placeholder={"Select Product Category"}
              state_name={"category"}
              isRequired={false}
            >
              {
                Constants.product_category?.map(category => {
                  const value = category === "Labs and Clinic" ? "Laboratory" : category
                  const option = category;

                  return (
                    <option value={value}>{option}</option>
                  )
                })
              }
            </Layout.SelectPicker>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Product Price"}
              placeholder={"Enter Product Price"}
              state_name={"price"}
              isNumber={true}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.price}
              prefix={"PHP"}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Sale Price(if applicable)"}
              placeholder={"Enter Product Sale Price"}
              state_name={"sale_price"} 
              isNumber={true}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.price}
              prefix={"PHP"}
            />
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"mWell Service"}
              placeholder={"Select mWell Service"}
              isRequired={false}
            >
              <option value="commissionRate">Commission Rate</option>
            </Layout.SelectPicker>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Product Status"}
              isRequired={true}
              state_name={"status"}
            >
              <option value=""></option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Purchase Limit(per transaction)"}
              placeholder={"Enter Product Purchase Limit"}
              state_name={"purchase_limit"}
              isRequired={true}
              isNumber={true}
              style={{ flex: 1 }}
              maxLength={UI.Constants.limits.price}
            />
            
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Product Inventory/Stock"}
              placeholder={"Enter Product Inventory/Stock"}
              state_name={"stock"}
              isNumber={true}
              style={{ flex: 1 }}
              maxLength={UI.Constants.limits.price}
            />
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Coming Soon"}
              isRequired={true}
              state_name={"isComingSoonButton"}
            >
              <option value="">Set Status</option>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <div style={{ width: 1150, height: 350 }}>
              <ProductDescription _this={_this} />
            </div>
          </UI.Row>
          {/* {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Other Information"}
              placeholder={"Enter Other Information Here"}
              state_name={"other_info"} // TO-DO: Other Info end point
              style={{ flex: 1 }}
              height={150}
              isRequired={false}
              type={"big"}
            />
          </UI.Row>
          {UI.box(20)} */}
          {/* <UI.Row breakpoint={970} _this={_this}>
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"SKU Web Link"}
              placeholder={"Enter Link"}
              state_name={"sku_link"}
              style={{ flex: 1 }}
            />
            
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>

            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row> */}
          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center", justifyContent:'space-between' }}>
            <div className="flex">
              <Layout.Btn
                color={UI.colors.primary}
                onClick={() => {
                  this.submit();
                }}
                text={"SAVE CHANGES"}
              />
              {UI.box(20)}

              <Layout.Btn
                color={"#E00000"}
                onClick={() => {
                  UI.goBack();
                }}
                text={"CANCEL"}
              />
            </div>
            <div>
              <Layout.Btn
                color={"#E00000"}
                onClick={() => {
                  this.delete(this.state.id);
                }}
                text={"DELETE PRODUCT"}
              />
            </div>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
