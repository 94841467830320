import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import TouchableOpacity, {
  Image,
  // mem,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import { Failed, Success } from "../_components/Modal";

const context = "advocacy";
export default class AddQuestionnaire extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      textFieldArray:[],
      dropDownSelectionArray:[],
      typeOfQuestion:null,
      question:null,
      context:context
    }
  }

  onChange = (e, type, i, optionIndex) => {
    const { 
      name, value
    } = e.target;

    if(type === "textField") {
      const list = this.state.textFieldArray
      list[i][name] = value;
    }

    if(type === "dropDownField") {
      const list = this.state.dropDownSelectionArray

      if (name === "answerOptions") {
        list[i].answerOptions[optionIndex] = value
        this.setState({dropDownSelectionArray:  [...list]})
      }
      if (name === "question") {
        list[i].question = value;
      }
    }

  }

  addQuestion = () => {
    if(this.state.typeOfQuestion === "TextField") {
      this.setState({textFieldArray: [...this.state.textFieldArray, { question:"", answerType: this.state.typeOfQuestion, isShow:true}]})
    }
    if(this.state.typeOfQuestion === "DropDownSelection") {
      this.setState({dropDownSelectionArray: [...this.state.dropDownSelectionArray, { question:"", answerType: this.state.typeOfQuestion, answerOptions: [""], isShow:true}]})
    }
  }

  addOption = (i) => {
    this.state.dropDownSelectionArray[i].answerOptions.push('')
    this.setState({dropDownSelectionArray: this.state.dropDownSelectionArray})
  }

  removeOption = (i, b) => {
    const list = this.state.dropDownSelectionArray
    const result = this.state.dropDownSelectionArray[i].answerOptions.filter((a, f) =>  b !== f)
    list[i].answerOptions = result
    this.setState({dropDownSelectionArray: list})
  }

  // dropDown = (a, i, type) => {
  //     const result = this.state.textFieldArray.map(text => {
  //       if(text.isShow) {
  //         a.isShow = type === "up" ? true : false
  //       }
  //       return text
  //     })
  //     this.setState({textFieldArray: result})
  //   // }
  //   // if(type === "down") {
  //   //   const result = this.state.textFieldArray.map(text => {
  //   //     if(text.isShow) {
  //   //       a.isShow = true
  //   //     }
  //   //     return text
  //   //   })
  //   //   this.setState({textFieldArray: result})
  //   // }
  // }

  removeQuestion = (i, type) => {
    if(type === "textField") {
      const result = this.state.textFieldArray.filter((a, f) =>  f !== i)
      this.setState({textFieldArray: result})
    }

    if(type === "dropDownField") {
      const result = this.state.dropDownSelectionArray.filter((a, f) =>  f !== i)
      this.setState({dropDownSelectionArray: result})
    }
  }

  submit = async () => {
    const { 
      title, 
      start_date_date, 
      end_date_date, 
      advocacySchedule,
      isAddSurvey, 
      // start_date, 
      // end_date, 
      // consultationAvailability, 
      // promotion_code, 
      status, 
      isConsultNow, 
      isConsultLater, 
      consultationTimeLimit, 
      description, 
      image_file, 
      healthpass, 
      comming_soon,
      mobile_image_file, 
      event_title,
      join_button_title,
      isShowJoinButton,
    } = this.props._this.state;
    let { dropDownSelectionArray, textFieldArray } = this.state;

    const payload = {
      title: title,
      startDate: start_date_date,
      validUntil: end_date_date,
      // startDate: start_date,
      // validUntil: end_date,
      schedule: JSON.stringify(advocacySchedule),
      consultationTimeLimit: consultationTimeLimit,
      description: description,
      status:status,
      comingSoon: comming_soon,
      healthpass: JSON.stringify(healthpass),
      consultationAvailability: JSON.stringify({
        showConsultNow: isConsultNow,
        showConsultLater: isConsultLater
      }),
      survey: JSON.stringify([...dropDownSelectionArray, ...textFieldArray]),
      image: image_file,
      isAddSurvey: isAddSurvey,
      mobile_image: mobile_image_file, 
      eventTitle: event_title,
      buttonTitle: join_button_title,
      isJoinButton: isShowJoinButton,
    }

    this.props._this.show_loading();

    UI.webRequest({
      method: "post",
      url: "admin/advocacies/create-advocacy",
      params: payload,
      isMultiPart: true,  
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.error;
        this.props._this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.props._this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        this.props._this.show_modal(
          <Success
            title={"Success!"}
            descriptionCenter={"Successfully added the advocacy."}
            onDismiss={() => {
              this.props._this.hide_modal();
              UI.goTo("/advocacy/list");
            }}
          />
        );
      },
      onFinish: () => {
        this.props._this.hide_loading();
      },
    });
  }

  render() {
    const _this = this.props._this;
    const { width } = _this.state;
    const { dropDownSelectionArray, textFieldArray } = this.state

    return (
      <>
        <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
          <Text style={{ ...styles.box_header_text, flex: 1 }}>
            Create Advocacy Survey
          </Text>

          <Text style={styles.text_2}>
            Customize your advocacy survey here
          </Text>
        </UI.Row>

        <View style={{flexDirection:'row'}}>
          <View View style={{  width: 300 }}>
            <Layout.SelectPicker
              _this={_this}
              isRequired={false}
              onChange={(e) => {
                this.setState({typeOfQuestion: e})
              }}
            >
              <option value="">Select Questionnaire Type</option>
              <option value="TextField">Text Field</option>
              <option value="DropDownSelection">Drop Down Selection</option>
            </Layout.SelectPicker>
          </View>
          {UI.box(20)}
          <Layout.Btn
            color={UI.colors.primary}
            onClick={(e) => {
              this.addQuestion()
            }}
            text={"ADD QUESTION"}
          />
        </View>
   
        {UI.box(20)}

        {
          (textFieldArray.length > 0 || dropDownSelectionArray.length > 0) &&
          <View style={{
            border: "2px dashed #C9C9C9",
            borderRadius: "12px",
            padding:'25px'
          }}>
  
          {/* START OF TEXTFIELD */}
  
          {
            textFieldArray.map((text, indexText) => (
              <View>
                <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
                  <Text style={{color: UI.colors.primary, fontWeight:'bold', fontSize:'15px', flex:1}}>
                    Item # {indexText + 1} ( Text Field )
                  </Text>
                  
                  <View style={{ flexDirection: "row" }}>
                    {/* <TouchableOpacity
                      onClick={async () => {
                        this.dropDown(a, i, 'down')
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.MOVEDOWN}
                      />
                    </TouchableOpacity>
                    {UI.box(10)}
                    <TouchableOpacity
                    onClick={async () => {
                      this.dropDown(a, i, 'up')
                    }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.MOVEUP}
                      />
                    </TouchableOpacity> */}
                    {UI.box(10)}
                    <TouchableOpacity
                      onClick={async () => {
                        this.removeQuestion(indexText, 'textField')
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.DELETE_BTN}
                      />
                    </TouchableOpacity>
                  </View>
                </UI.Row>

              {UI.box(10)}
            
              {
              text.isShow === true &&
              <>
                {UI.box(5)}
                
                <Text style={{color: UI.colors.primary, fontWeight:'bold', fontSize:'15px'}}>
                  Enter Question Description Here
                </Text>
      
                <UI.Row breakpoint={970} _this={_this}>
                  <input
                    autocomplete="off"
                    style={{
                      fontSize: '14px',
                      resize: "none",
                      color: 'black',
                      width: "100%",
                      padding:'10px 14.44px' ,
                      borderRadius:'10px',
                      border: '2px solid #C9C9C9',
                      marginTop:'5px'
                    }}
                    placeholder={"Enter Question Description"}
                    onChange={(e) => this.onChange(e,  'textField', indexText)}
                    name="question"
                  />
                </UI.Row>
      
                {UI.box(10)} 
              </>
            }
            
            </View>
            ))
          }
          
          {UI.box(5)} 
  
          {/* END OF TEXTFIELD */}
  
          {/* START OF DROPDOWN SELECTION */}

            {
              dropDownSelectionArray.map((dropDown, indexdropDown) => {
                console.log(dropDown)
                return (
                <View>
                  
                  <View>
               <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
                <Text style={{color: UI.colors.primary, fontWeight:'bold', fontSize:'15px', flex:1}}>
                  Item # {indexdropDown + 1} ( Drop Down Selection )
                </Text>
                {UI.box(10)}
                
                <View style={{ flexDirection: "row" }}>
                  {/* <TouchableOpacity
                    onClick={async () => {
                      // this.delete(item._id);
                      // const res = this.state.data.filter(a => a.id !== item.id)
                      // this.setState({
                      //   data: res,
                      //   all_data: res,
                      // })
                      // this.reload_table("advocacy")
                    }}
                  >
                  <Image
                    height={28}
                    width={28}
                    tintColor={"transparent"}
                    source={UI.MOVEDOWN}
                  />
                </TouchableOpacity>
                {UI.box(10)}
                <TouchableOpacity
                onClick={async () => {
                  // this.delete(item._id);
                  // const res = this.state.data.filter(a => a.id !== item.id)
                  // this.setState({
                  //   data: res,
                  //   all_data: res,
                  // })
                  // this.reload_table("advocacy")
                }}
              >
                <Image
                  height={28}
                  width={28}
                  tintColor={"transparent"}
                  source={UI.MOVEUP}
                />
              </TouchableOpacity> */}
              {UI.box(10)}
              <TouchableOpacity
                onClick={async () => {
                  this.removeQuestion(indexdropDown, 'dropDownField')
                }}
              >
                <Image
                  height={28}
                  width={28}
                  tintColor={"transparent"}
                  source={UI.DELETE_BTN}
                />
              </TouchableOpacity>
              </View>
        
            </UI.Row>
  
            {UI.box(5)}
            
            <Text style={{color: UI.colors.primary, fontWeight:'bold', fontSize:'15px'}}>
              Enter Question Description Here
            </Text>
  
            <UI.Row breakpoint={970} _this={_this}>
              <input
                autocomplete="off"
                style={{
                  fontSize: '14px',
                  resize: "none",
                  color: 'black',
                  width: "100%",
                  padding:'10px 14.44px' ,
                  borderRadius:'10px',
                  border: '2px solid #C9C9C9',
                  marginTop:'5px'
                }}
                placeholder={"Enter Question Description"}
                onChange={(e) => this.onChange(e,  'dropDownField', indexdropDown)}
                name="question"
              />
            </UI.Row>
  
            {UI.box(10)}
            </View>
              <Text Text style={{color: UI.colors.primary, fontWeight:'bold', fontSize:'15px'}}>
                Enter Selection Items
              </Text>
              <UI.Row breakpoint={970} _this={_this}>

                <View style={{width:'25%'}}>
                  {UI.box(10)}
                  {
                    dropDown.answerOptions.map((option, optionIndex) => {
                      return (
                      <View style={{justifyContent:'center', flexDirection:'row', alignItems:'center'}}>
                        <input
                        autocomplete="off"
                        style={{
                          fontSize: '14px',
                          resize: "none",
                          color: 'black',
                          width: "100%",
                          padding:'10px 14.44px' ,
                          borderRadius:'10px',
                          border: '2px solid #C9C9C9',
                          marginTop:'5px'
                        }}
                        placeholder={"Enter Option Description"}
                        onChange={(e) => this.onChange(e,  'dropDownField', indexdropDown, optionIndex)}
                        name="answerOptions"
                        value={option}
                        />

                        {UI.box(10)}
                        <TouchableOpacity
                          onClick={() => {
                            this.removeOption(indexdropDown, optionIndex)
                          }}
                        >
                          <Image
                            height={28}
                            width={28}
                            tintColor={"transparent"}
                            source={UI.DELETE_BTN}
                          />
                        </TouchableOpacity>
                      </View>
                     
                    )})
                  }
                  
                </View>

             
              </UI.Row>

                {UI.box(20)}
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Layout.Btn
                    color={UI.colors.primary}
                    onClick={() => {
                      this.addOption(indexdropDown)
                    }}
                    text={"ADD OPTION"}
                  />
                </View>
                {UI.box(10)}
              </View>
              )})
            }
  
            {/* END OF DROPDOWN SELECTION */}
            
          </View>
        }
        
        {UI.box(20)}

        <View
            style={{
              ...styles.no_info_container,
              display: textFieldArray.length > 0 || dropDownSelectionArray.length > 0 ? "none" : "flex",
            }}
          >
          <UI.Row
            breakpoint={820}
            style={{ maxWidth: 340, alignItems: "center" }}
            _this={_this}
          >
            <Image
              height={100}
              width={100}
              tintColor={"transparent"}
              source={UI.NO_INFO}
            />
            {UI.box(10)}
            <Text
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#BFBFBF",
                textAlign: width > 820 ? "left" : "center",
                marginLeft: width > 820 ? 20 : 0,
              }}
            >
              NO QUESTIONS ADDED YET
            </Text>
          </UI.Row>
        </View>
             
        <View style={{ flexDirection: "row", alignItems: "center" }} >
          <Layout.Btn
            color={"#E00000"}
            onClick={() => {
              this.props._this.setState({ isNext: 2 })
            }}
            text={"BACK"}
          />

          {UI.box(20)}
          <Layout.Btn
            color={textFieldArray.length > 0 || dropDownSelectionArray.length > 0 ? UI.colors.primary : UI.colors.unset}
            onClick={() => {
              if(textFieldArray.length > 0 || dropDownSelectionArray.length > 0) {
                this.submit();
              }
            }}
            text={"SAVE CHANGES"}
          />

          {UI.box(20)}

          <Text style={styles.text_2}>
            Step 3 of 3
            {/* Next */}
          </Text>

        </View>

        {UI.box(10)}
      </>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: UI.colors.secondary,
    fontWeight:'bold'
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
});
