import React from "react";
import { BrowserRouter } from "react-router-dom";

import "./App.css";
import AppRouter from "./AppRouter";
import ErrorBoundary from "./modules/_components/ErrorBoundary";

export default function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <AppRouter />
      </ErrorBoundary>
    </BrowserRouter>
  );
}
