import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import DiskartechTable from "../../ComponentsFC/admin/diskartech/DiskartechTable";

const context = "admin";
export default class DiskartechPage extends Component {
  constructor() {
    super();
    this.state = {};
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Diskartech",
      context: context,
    });
  }

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <DiskartechTable _this={_this}/>
      </MainLayout>
    );
  }
}
