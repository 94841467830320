import moment from "moment";
import FileDownload from "js-file-download";

import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { CustomConfirmation, Failed } from "../_components/Modal";
import "./css.css";

const Constants = new APIConstants();

// import CSVFormat from "./CSVFormat";
const context = "admin";
const default_enddate = "2050-09-29";
const default_startdate = "1970-01-01";
export default class AdminAppointments extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Appointments",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      csv_data:[],
      context: context,

      sort: "-createdAt",
      status: "",
      name: "",

      sortDateAdded:null,
      sortPatientName:null,
      filteredPaymentStatus:null,
      advocacies: [],

      start_date_dashed: default_startdate,
      end_date_dashed: default_enddate,

      appointments_number_of_pages: 1,
      appointments_current_page: 1,
      totalEntries: "",
      currentLimit: 30,
      search: ""
    });
  }

  onCreate = async (e) => {
    let entryNum = e;
    this.get_advocacies();
    this.setState({currentLimit: entryNum})
    this.get_data({
      page: this.state.appointments_current_page,
      limit: entryNum,
    })


    // setTimeout(() => {
    //   this.get_data({
    //     page: 1,
    //     limit:
    //       this.state["appointments" + "_entries"] ??
    //       30,
    //   });
    // }, 100);
  };

  get_advocacies = async () => {
    UI.webRequest({
      method: "get",
      url: "admin/advocacies",
      onFail: async (err) => {},
      onSuccess: async (response) => {

        const data = UI.extract_data(response).data;
        const advocacies = data.filter(item => 
            item.title === "National mWellness Day: The biggest nationwide online medical mission" ||
            item.title === "Gabay Kalusugan: Year-round medical missions"
        )

        this.setState({
          advocacies,
        });
      }
    });
  }

  apply_date_filter = async (type, e, page) => {
    // await UI.clear_errors();
    // if (default_startdate == this.state.start_date_dashed) {
    //   await UI.error_form("start_date", "Please enter start date");
    // }
    // if (default_enddate == this.state.end_date_dashed) {
    //   await UI.error_form("end_date", "Please enter end date");
    // } else {
      let params = {
        page: page,
      }

      if (this.state.consultType) {
        params.consultType = this.state.consultType
      }

      if (type === "date") {
        params.startDate = this.state.start_date_dashed
        params.endDate = this.state.end_date_dashed
      } 

      if (type === "booking_date") {
        params.bookingStartDate = this.state.booking_start_date_dashed
        params.bookingEndDate = this.state.booking_end_date_dashed
      }

      if (type === "booking_month_year") {
        params.bookingStartDate = moment(`${this.state.month} ${this.state.year}`, ['MMMM YYYY']).format('YYYY-MM-DD');
        params.bookingEndDate = moment(`${this.state.month} ${this.state.year}`, ['MMMM YYYY']).endOf('month').format('YYYY-MM-DD');
      }

      if (type === "booking_year") {
        params.bookingStartDate = moment(`${e}`, ['YYYY']).startOf('year').format('YYYY-MM-DD');
        params.bookingEndDate = moment(`${e}`, ['YYYY']).endOf('year').format('YYYY-MM-DD');
      }

      params.limit = this.state["appointment_entries"] ?? 30

      this.get_data(params);
  };

  get_data = async (params, callback) => {
    // Commented out due to forcing params.limit to be 30 only
    // let limit = parseInt(params.limit);
    // params.limit = limit >= 30 ? 30 : limit
    this.show_loading();
    if (!params) {
      params = {};
    }
    this.setState({
      appointments_current_page: params?.page ?? 1,
    });
    UI.webRequest({
      method: "get",
      url: "admin/appointments",
      params: params,
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response).data;
        const results = UI.get_results(response);
        // if(this.state.start_date_dashed !== default_startdate) {
          // results.total = data.length;
        // }

        if (!results?.total) {
          results.total = results.docCount;
        }

        let new_data = data.map((item) => {
          item.timestamp = new Date(item?.payment?.createdAt).getTime();
          item.createdAt = item?.payment?.createdAt;
          item.order_date = UI.get_date_string_by_timestamp(item.timestamp);
          item.consult_date = item?.start;
          item.consult_date_string = UI.get_date_string_by_date(
            item.consult_date
          );
          item.patient_name =
            UI.DataHelper.carespan.get_name(item?.patient?.name) ?? " ";
          item.doctor_name = UI.DataHelper.carespan.get_name(
            item?.practitioner?.name
          );
          item.reference_number = item?.payment?.referenceNumber;
          item.paymentId = item?.payment?.paymentId;
          item.amount = item?.payment?.amount;
          item.status_order = item.status;
          item.appointment_status =
            UI.replaceAll(item?.status ?? "", "_", " ") ?? "Completed";

          if (!item.payment) {
            item.status =
              item.payType === "Voucher" ? "UseVoucher" : item?.payType;
          } else {
            item.status =
              item.payment.method === "Voucher"
                ? "UseVoucher"
                : item?.payment?.status?.status;
          }

          if (item.status === "Complete") {
            item.status = "Completed";
          }

          item.trigger_scenario = " ";
          if (
            item.appointment_status === "Patient_cancelled" ||
            item.appointment_status === "Practitioner_cancelled"
          ) {
            if (item.appointmentType === "appointment") {
              item.trigger_scenario = "Waiting Room";
            }

            if (item.appointmentType === "instant") {
              item.trigger_scenario = "Booked Transaction";
            }
          }
          item.carespan_id = item?.patient?.carespanID;
          return item;
        });

        //new_data = autoPopulate(new_data);
        //new_data = new_data.filter((item) => item.reference_number == "162781218789");
        this.setState({totalEntries: results.total})
        this.setState(
          {
            data: new_data,
            all_data: new_data,
            totalPages: results.total,
          },
          () => {
            //this.reload_table("appointments");
            UI.get_entries(
              new_data,
              this.state.currentLimit ?? 30,
              // params.limit ?? 30,
              "appointments",
              params.page,
              {
                total: results.total,
                pages: results.pages,
              }
            );
            this.hide_loading();
          }
        );
      },
      onFinish: () => {
        if (callback) {
          callback();
        }
        this.hide_loading();
      },
    });
  };

  sort_by = (format, key, page) => {
    //format can be asc or desc

    if(key === "timestamp") {
      this.get_data({
        startDate: this.state.start_date_dashed,
        endDate: this.state.end_date_dashed,
        dateAdded:format,
        page: page ? page : 1,
        limit:
          this.state["appointments_entries"] ??
          30,
      });
      this.setState({
        sortDateAdded: {
          format,
          key,
        }
      })
    } else if (key === "patient_name") {
      this.get_data({
        startDate: this.state.start_date_dashed,
        endDate: this.state.end_date_dashed,
        patientName:format,
        page: page ? page : 1,
        limit:
          this.state["appointments_entries"] ??
          30,
      });
      this.setState({
        sortPatientName: {
          format,
          key,
        }
      })
    }
  };

  custom_sort = (key, value, page) => {
    // const { all_data } = this.state;
    // let items = all_data.filter((item) => item[key] + "" == value);
    // UI.set_input_value("search", "");
    // if (value) {
    //   this.reload_table("appointments", items);
    // } else {
    //   this.reload_table("appointments", all_data);
    // }

    this.get_data({
      startDate: this.state.start_date_dashed,
      endDate: this.state.end_date_dashed,
      [key]: value,
      page: page ? page : 1,
      limit:
        this.state["appointments_entries"] ??
        30,
    });
    this.setState({
      filteredPaymentStatus: {
        key,
        value,
      },
      [key]: value
    })
  };

  refund_confirm = () => {
    this.show_modal(
      <CustomConfirmation
        title={"Confirmation"}
        text={"Are you sure you want to refund this transaction?"}
        backgroundColor={"#3498db"}
        onOk={{
          text: "CONTINUE",
          method: () => {
            this.hide_modal();
          },
        }}
        onCancel={{
          text: "CANCEL",
          method: () => {
            this.hide_modal();
          },
        }}
      />
    );
  };

  export_to_csv = async () => {
    this.show_loading("", "Exporting all appointments...");
    let additonal_params = {}

    if (this.state.consultType) {
      additonal_params.consultType = this.state.consultType
    }

    let params = {
      ...additonal_params
    }
    
    if(this.state.search) {
      params.id = this.state.search
    }

    if (default_startdate !== this.state.start_date_dashed || default_enddate !== this.state.end_date_dashed ) {
      params.startDate = this.state.start_date_dashed
      params.endDate = this.state.end_date_dashed
    }

    if (this.state.booking_start_date_dashed || this.state.booking_end_date_dashed ) {
      params.bookingStartDate = this.state.booking_start_date_dashed
      params.bookingEndDate = this.state.booking_end_date_dashed
    }

    if (this.state.month && this.state.year) {
      params.bookingStartDate = moment(`${this.state.month} ${this.state.year}`, ['MMMM YYYY']).format('YYYY-MM-DD')
      params.bookingEndDate = moment(`${this.state.month} ${this.state.year}`, ['MMMM YYYY']).endOf('month').format('YYYY-MM-DD')
    }

    if (this.state.year && !this.state.month) {
      params.bookingStartDate = moment(`${this.state.year}`, ['YYYY']).startOf('year').format('YYYY-MM-DD')
      params.bookingEndDate = moment(`${this.state.year}`, ['YYYY']).endOf('year').format('YYYY-MM-DD')
    }

    let maximum_export_limit = 10000;
    
    if (this.state.totalPages >= maximum_export_limit ) {
      return this.show_modal(
        <Failed
          title={"Failed"}
          description={`Sorry! You are exporting ${this.state.totalPages} total of appointments and we are experiencing limitations with our appointments exporting capabilities and are only able to export up to ${maximum_export_limit} appointments.`}
          onDismiss={() => {
            this.hide_loading();
            this.hide_modal();
          }}
        />
      );
    }

    params.export = "true"
    const url = `${Constants.ENV_TYPE === "PRODUCTION-RIZAL" ?  "appointments" : "admin/appointments"}`
    UI.webRequest({
      method: "get",
      url: url,
      params: {
        ...params,
        page:1,
        limit: this.state.totalPages
      },
      isSpecialCall:true,
      responseType: 'blob',
      onFail: async (err) => {},
      onSuccess: async (response) => {
        FileDownload(response.data, 'appointments.csv');
        this.hide_modal();
     
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  show_appointment_details_modal = (item) => {
    this.show_modal(
      <View
        style={{
          borderRadius: 10,
          margin: "auto",
        }}
      >
        <div style={{ width: "80%" }}>
          <View
            style={{ ...styles.md_header, backgroundColor: UI.colors.secondary }}
          >
            <TouchableOpacity
              onClick={() => {
                this.hide_modal();
              }}
              style={styles.md_close}
            >
              <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="CLOSE" />
            </TouchableOpacity>

            <View style={{ ...styles.md_header_text_container, marginLeft: 50 }}>
              <Text style={styles.md_header_text}>Appointment Details</Text>
            </View>
          </View>

          <View style={{ ...styles.md_content_container, width: "100%", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, }}>
            <View style={{flex:1, width:'100%', gap: 8}}>
              <div className="flex-1 flex">
                <strong className="flex-1 flex items-center gap-2"><img src={UI.APPOINTMENT_ICON} alt="APPOINTMENT_ICON"/>Appointment ID:</strong>
                <strong style={{color:UI.colors.primary}}>{item?._id ?? ""}</strong>
              </div>
              <div className="flex-1 flex">
                <strong className="flex-1 flex items-center gap-2"><img src={UI.CHECK_ICON} alt="CHECK_ICON"/>Decision:</strong>
                <strong style={{color:UI.colors.primary}}>{item?.decision ?? "N/A"}</strong>
              </div>
              <div className="flex-1 flex">
                <strong className="flex-1 flex items-center gap-2"><img src={UI.CHECK_ICON} alt="CHECK_ICON"/>Sub Decision</strong>
                <strong style={{color:UI.colors.primary}}>{item?.subDecision ?? "N/A"}</strong>
              </div>
              <div className="flex-1 flex">
                <strong className="flex-1 flex items-center gap-2"><img src={UI.CHECK_ICON} alt="CHECK_ICON"/>End Reason:</strong>
                <strong style={{color:UI.colors.primary}}>{item.cancelationReason?.text ?? "N/A"} </strong>
              </div>
            </View>
            <TouchableOpacity
              onClick={() => {
                this.hide_modal();
              }}
              style={{
                backgroundColor:
                  this.props.backgroundColor ?? "#B90C0C",
                borderRadius: "8px",
                marginTop:20
              }}
            >
              <button
                className=" px-6 py-3 text-white gap-2 rounded-md font-semibold"
                style={{ backgroundColor: UI.colors.secondary }}
              >
                Continue
              </button>
            </TouchableOpacity>
          </View>
        </div>
      </View>
    )
  }

  render() {
    const _this = this;
    const { width } = _this.state;
    let filters_appointments = UI.measure("filters_appointments").width ?? width;
    const Constants = new APIConstants()

    return (
      <MainLayout context={context} _this={_this}>
        <View id={"filters_appointments"} style={styles.main_box_content}>
          <Text style={styles.box_header_text}>All Appointments</Text>
          {/* <Text style={styles.box_header_text}>{UI.get_hhmmss_from_date(new Date())}</Text> */}
          {UI.box(20)}
          <UI.Row breakpoint={840} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              onChange={(e) => {
                this.custom_sort("paymentStatus", e);
              }}
            >
              <option value="">Select Option</option>
              <option value="Completed">Completed</option>
              <option value="UseVoucher">Voucher Used</option>
              <option value="Pending">Pending</option>
              <option value="Refund">For Refund</option>
              <option value="Failed">Failed</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Date Added"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "timestamp");
              }}
            >
              <option value="">Select Option</option>
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Patient Name"}
              isRequired={false}
              onChange={(e) => {
                this.sort_by(e, "patient_name");
              }}
            >
              <option value="">Select Option</option>
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </Layout.SelectPicker>

            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Doctor Type"}
              onChange={(e) => {
                this.custom_sort("doctorType", e);
              }}
            >
              <option value="">Select Option</option>
              <option value="Consulting">Consulting</option>
              <option value="Contracted">Contracted</option>
            </Layout.SelectPicker>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={540} _this={_this} basis={filters_appointments}>
            <Layout.SelectPicker
              _this={_this}
              title={"Consult Type"}
              onChange={(e) => {
                this.custom_sort("consultType", e);
              }}
            >
              <option value="">Select Option</option>
              <option value="Consult Now">Consult Now</option>
              <option value="Consult Later">Consult Later</option>
              {this.state.advocacies.map(advocacy => (
                <option 
                  key={advocacy._id} 
                  value={advocacy._id}
                >Advocacy: {advocacy.title === "Gabay Kalusugan: Year-round medical missions" ? "Gabay Kalusugan" : "NMD"}</option>
              ))}

            </Layout.SelectPicker>
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Booking Filter"}
              isRequired={false}
              onChange={(e) => {
                this.setState({
                  filter_booking: e, 
                  start_date_dashed: default_startdate, 
                  end_date_dashed: default_enddate, 
                  booking_start_date_dashed: '',
                  booking_end_date_dashed: '',
                  year:'', 
                  month: ''
                })
              }}
            >
              <option value="">Select Option</option>
              <option value="book_date">Booking Date(Range)</option>
              <option value="booking_month">Booking Month</option>
              <option value="booking_year">Booking Year</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            
            {
              this.state.filter_booking === "book_date" ? 
              <>
                <View style={{flex:1}}>
                  <Text style={styles.title}>Booking Date Filter</Text>

                  <UI.Row breakpoint={540} _this={_this} basis={filters_appointments}>
                    <View style={{ width: 200 }}>
                      <Layout.DatePicker
                        _this={_this}
                        placeholder={"Booking Start Date"}
                        state_name={"booking_start_date"}
                        will_correct={true}
                      />
                    </View>
                    {UI.box(10)}
                    <View style={{ width: 200 }}>
                      <Layout.DatePicker
                        _this={_this}
                        placeholder={"Booking End Date"}
                        state_name={"booking_end_date"}
                        will_correct={true}
                      />
                    </View>
                    {UI.box(10)}
                    <Layout.Btn
                      style={{
                        alignSelf: filters_appointments < 540 ? "flex-start" : "center",
                        marginTop: 5,
                        width: filters_appointments < 540 ? 200 : null,
                      }}
                      color={UI.colors.secondary}
                      onClick={() => {
                        this.apply_date_filter('booking_date');
                      }}
                      text={"Apply"}
                      height={50}
                    />
                  </UI.Row> 
                </View>
                {UI.box(10)}
                <View style={{flex:1}}></View>
              </> :  this.state.filter_booking === "booking_month" ?   <> 
              <View style={{flex:1}}>
                <Layout.SelectPicker
                  _this={_this}
                  title={"Month"}
                  isRequired={false}
                  onChange={(e) => {
                    this.setState({month: e})
                  }}
                >
                  <option value="">Select Month</option>
                  {Constants.month.map(item => <option value={item}>{item}</option>)}
                </Layout.SelectPicker>
             
              </View> 
              {UI.box(10)}
              <View style={{flex:1}}>
                <Layout.SelectPicker
                  _this={_this}
                  title={"Year"}
                  isRequired={false}
                  onChange={(e) => {
                    this.setState({year: e})
                  }}
                >
                  <option value="">Select Year</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                </Layout.SelectPicker>
              </View>
              {UI.box(10)}
              <View style={{display:'flex', justifyContent:'flex-end'}}>
                <Layout.Btn
                  style={{
                    display:'flex',
                    alignItems:'center'
                    // alignSelf: filters_appointments < 540 ? "flex-start" : "center",
                    // // marginTop: 5,
                    // width: filters_appointments < 540 ? 200 : null,
                  }}
                  color={UI.colors.secondary}
                  onClick={() => {
                    this.apply_date_filter('booking_month_year');
                  }}
                  text={"Apply"}
                  height={50}
                />
              </View>
              </> :  this.state.filter_booking === "booking_year" ?   <> 
              <View style={{flex:1}}>
                <Layout.SelectPicker
                  _this={_this}
                  title={"Year"}
                  isRequired={false}
                  onChange={(e) => {
                    this.setState({year: e})
                    this.apply_date_filter('booking_year', e);
                  }}
                >
                  <option value="">Select Year</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                </Layout.SelectPicker>
              </View> 
              {UI.box(10)}
              <View style={{flex:1}}></View>
              </> :
              <>
                {UI.box(10)}
                <View style={{flex:1}}></View>
                {UI.box(10)}
                <View style={{flex:1}}></View> 
              </>
            }

            {/* <View style={{flex:1}}></View>
            <View style={{flex:1}}></View> */}
          </UI.Row>

          {UI.box(20)}

          <Text style={styles.title}>Date Filter</Text>

          <UI.Row breakpoint={540} _this={_this} basis={filters_appointments}>
            <View style={{ width: 200 }}>
              <Layout.DatePicker
                _this={_this}
                placeholder={"Start Date"}
                state_name={"start_date"}
                will_correct={true}
              />
            </View>
            {UI.box(10)}
            <View style={{ width: 200 }}>
              <Layout.DatePicker
                _this={_this}
                placeholder={"End Date"}
                state_name={"end_date"}
                will_correct={true}
              />
            </View>
            {UI.box(10)}
            <Layout.Btn
              style={{
                alignSelf: filters_appointments < 540 ? "flex-start" : "center",
                marginTop: 5,
                width: filters_appointments < 540 ? 200 : null,
              }}
              color={UI.colors.secondary}
              onClick={() => {
                this.apply_date_filter('date');
                this.setState({booking_start_date_dashed: '', booking_end_date_dashed: ''})
              }}
              text={"Apply Date Filter"}
              height={50}
            />
          </UI.Row>
          {UI.box(10)}

          {/* {UI.box(20)}
          <Text style={styles.title}>Booking Date Filter</Text>

          <UI.Row breakpoint={540} _this={_this} basis={filters_appointments}>
            <View style={{ width: 200 }}>
              <Layout.DatePicker
                _this={_this}
                placeholder={"Booking Start Date"}
                state_name={"start_date"}
                will_correct={true}
              />
            </View>
            {UI.box(10)}
            <View style={{ width: 200 }}>
              <Layout.DatePicker
                _this={_this}
                placeholder={"Booking End Date"}
                state_name={"end_date"}
                will_correct={true}
              />
            </View>
            {UI.box(10)}
            <Layout.Btn
              style={{
                alignSelf: filters_appointments < 540 ? "flex-start" : "center",
                marginTop: 5,
                width: filters_appointments < 540 ? 200 : null,
              }}
              color={UI.colors.primary}
              onClick={() => {
                this.apply_date_filter();
              }}
              text={"Apply"}
              height={50}
            />
          </UI.Row> */}
        </View>

        <View style={{ ...styles.main_box_content, padding: 0, marginTop: 20 }}>
          <UI.Row
            style={styles.main_container_header}
            breakpoint={880}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker
                  isAppointmentPage={true}
                  stateless={true}
                  callback={this.onCreate}
                  totalEntries = {this.state.totalEntries}
                  table_name={"appointments"}
                  _this={_this}
                />
              </View>
            </View>
            <InputForm
              onChange={(e) => {
                // UI.search_table("appointments", e, this.state.all_data, "data");
                  this.get_data({
                    id: e,
                    page: this.state.current_page,
                    limit: this.state["appointments_entries"] ??
                    30,
                  });
              }}
              is_keyword={true}
              disableLength={true}
              maxLength={UI.Constants.limits.keyword}
              _this={_this}
              title={""}
              placeholder={"Enter Keyword"}
              state_name={"search"}
              right={
                <TouchableOpacity style={styles.search_btn}>
                  <Image
                    height={17}
                    width={17}
                    tintColor={"transparent"}
                    source={UI.SEARCH}
                  />
                </TouchableOpacity>
              }
            />
            <View
              style={{
                alignSelf: width > 880 ? "flex-start" : "center",
                marginTop: 8,
                marginLeft: 10,
              }}
            >
              <Layout.Btn
                color={UI.colors.secondary}
                text={"Export to CSV"}
                onClick={() => {
                  this.export_to_csv();
                }}
                isDisabled={this.state.data.length > 0 ? false : true}
                height={40}
                width={150}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            disable_pagination={false}
            state_name={"appointments"}
            _this={_this}
            columns={[
              // { "Appointment ID": "id" },
              { "Appointment ID ": "appointment_id" },
              { "Consult Date": "consult_date" },
              { "Consult Time": "consult_time" },
              { "Patient Name": "patient_name" },
              { "Contact Number": "patient_mobile" },
              { "Doctor Name": "doctor_name" },
              { "Doctor Type": "doctor_type" },
              { "Total Amount Paid": "totalFee" },
              { "Payment Status": "payment_status" },
              { "Order Status - \nmWell": "mwell_order_status" },
              // { "Order Status - \nCS": "cs_order_status" },
              { "Consult Type": "appointment_type" },
              { "Advocacy": "advocacy" },
              { "Action": "action" },
              // { Decision: "decision" },
              // { subDecision: "subDecision" },
              // { endReason: "endReason" }
            ]}
            data={this.state.data.map((item) => {

              // const edit_refund = async () => {
              //   mem.save("edit_transaction", JSON.stringify(item));
              //   UI.goTo("/finance/refund-doctor-transaction");
              // };

              const date_added = UI.timestampToDate(
                new Date(item.createdAt).getTime()
              );
              
              const consult_date = item.consult_date ? UI.momentPHTimezone(item.consult_date).format('MM/DD/YYYY') : "";

              let status = "";

              const payment = item.payment;
              let totalFee = 0;
              if (payment) {
                totalFee =
                  parseInt(payment?.price?.consultationFee ?? 0) +
                    parseInt(payment?.price?.platformFee ?? 0) +
                    parseInt(payment?.price?.VATFee ?? 0) ?? 0;
              }
              // let refund = 0;

              // if (parseInt(payment?.penalty?.refundAmount ?? "0")) {
              //   refund = payment?.penalty?.refundAmount;
              // } else {
              //   refund = -totalFee;
              //   if (totalFee === 0) {
              //     refund = "0";
              //   }
              // }

              if (totalFee === 0) {
                totalFee = "0";
              }

              if (item.status === "Completed") {
                status = <Text style={{ color: "#27ae60" }}>Completed</Text>;
              } else if (item.status === "Complete") {
                status = <Text style={{ color: "#27ae60" }}>Completed</Text>;
              } else if (item.status === "UseVoucher") {
                status = <Text style={{ color: "#3498db" }}>Voucher Used</Text>;
              } else if (item.status === "Pending") {
                status = <Text style={{ color: "#FFCE00" }}>Pending</Text>;
              } else if (item.status === "Refund") {
                status = <Text style={{ color: "#3498db" }}>For Refund</Text>;
              } else if (item.status === "Failed") {
                status = <Text style={{ color: "#e74c3c" }}>Failed</Text>;
              }

              return {
                doctor_name: (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginLeft: 10,
                    }}
                  >
                    <Text
                      style={{
                        maxWidth: 80,
                        marginRight: 10,
                        textAlign: "center",
                      }}
                    >
                      {item.doctor_name}
                    </Text>
                  </View>
                ),
                doctor_type: item?.doctorType ?? " ",
                id: item?._id ?? "",
                decision: item?.decision ?? "",
                subDecision: item?.subDecision ?? "",
                endReason: item?.endReason ?? "",
                // appointment_id: item?.carespanAppointmentID ?? " ",
                appointment_id: item?._id ?? " ",
                appointment_type: item.appointmentType === "instant" ? "Consult Now" : "Consult Later" ?? " ",
                cs_order_status: item.carespanData?.status ?? " ",
                mwell_order_status: item.status_order ?? " ",
                payment_status: payment?.status?.status ?? " ",
                totalFee: `Php ${UI.pad(totalFee, 3, true)}`,
                trigger_scenario: item.trigger_scenario,
                relationshipToUser: item?.patient?.relationshipToUser,
                patient_name: item.patient_name,
                patient_mobile: item.patient?.relationshipToUser === 'Myself' ? item?.user?.phone : "",
                reference_number: item.reference_number,
                amount: item.amount,
                appointment_status: item.appointment_status,
                date_added:
                  date_added.month +
                  "/" +
                  date_added.day +
                  "/" +
                  date_added.year,
                consult_date: consult_date,
                consult_time: UI.get_hhmmss_from_date(item.start) ?? " ",
                status: status,
                advocacy: item.advocacies?.title ?? "",
                action: (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity onClick={() => {
                      this.show_appointment_details_modal(item)
                    }}>
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.VIEW_BTN_1}
                      />
                    </TouchableOpacity>
                  </View>
                )
              };
            })}
            number_of_pages={this.state.appointments_number_of_pages}
            current_page={this.state.appointments_current_page}
            on_page={(page, callback, table_name) => {
              if(this.state.sortPatientName) {
                this.sort_by(this.state.sortPatientName.format, this.state.sortPatientName.key, page)
              } else if(this.state.sortDateAdded) {
                this.sort_by(this.state.sortDateAdded.format, this.state.sortDateAdded.key, page)
                // if(this.state.filteredPaymentStatus) {
                //   this.custom_sort(this.state.filteredPaymentStatus.key, this.state.filteredPaymentStatus.value, page)
                //   this.sort_by(this.state.sortDateAdded.format, this.state.sortDateAdded.key, page)
                // } else {
                //   this.sort_by(this.state.sortDateAdded.format, this.state.sortDateAdded.key, page)
                // }
              } else if (this.state.filteredPaymentStatus) {
                this.custom_sort(this.state.filteredPaymentStatus.key, this.state.filteredPaymentStatus.value, page)
              } else if (this.state.start_date_dashed !== default_startdate) {
                this.apply_date_filter("date", '', page)
              } else if (this.state.booking_start_date_dashed || this.state.month || this.state.year) {
                this.apply_date_filter("booking_date" || "booking_month_year" || "booking_year", '', page)
              } else {
                this.get_data({
                  page: page,
                  limit:
                    30,
                });
              }
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  md_content_container: {
    padding: 25,
    alignItems: "center",
    backgroundColor:'white'
  },
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: "#00CBFF",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()

/* 
  Sample Usage

  <CustomDatatable
    state_name={"consultation_records"}
    _this={_this}
    columns={[
      { ID: "id" },
      { Patient: "patient" },
      { Status: "status" },
      { "Created On": "created_on" },
      { "Start Date/Time": "start_date" },
      { Duration: "duration" },
      { "Pay Method": "pay_method" },
      { "Offer ID": "offer_id" },
      { "Offer Name": "offer_name" },
      { Actions: "actions" },
    ]}
    data={[
      {
        id: "13121",
        patient: "John Doe",
        status: "Scheduled",
        created_on: "mm/dd/yyyy",
        start_date: "mm/dd/yyyy/00:00am",
        duration: "N/A",
        pay_method: "Bayad App",
        offer_id: "001010",
        offer_name: "Offer Name Here",
        actions: (
          <TouchableOpacity>
            <Text>Testing</Text>
          </TouchableOpacity>
        ),
      },
    ]}
    current_page={this.state.current_page}
    number_of_pages={20}
    on_page={(page, callback) => {
      this.setState(
        {
          current_page: page,
        },
        () => {
          callback();
        }
      );
    }}
  />


*/
