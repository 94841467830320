import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import EntryPicker from "../_components/EntryPicker";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed } from "../_components/Modal";
import "./css.css";

const context = "voucher";

export default class VoucherHealthpassList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Health Pass List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      all_data: [],
      context: context,
    });
  }

  onCreate = () => {
    this.get_data();
  };

  get_data = async () => {
    const data = await mem.get("view_healthpass");
    let healthpass = JSON.parse(data);
    this.show_loading();

    UI.webRequest({
      method: "get",
      url: `admin/healthpass/${healthpass._id}/vouchers`,
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            data: data.result,
            all_data: data.result,
          },
          () => {
            this.reload_table("healthpass_code_list");
          }
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  exportHealthPassCode = (item) => {
    this.show_loading();

    // id = "61efacb0ab0eb492a7b389fd";
    UI.webRequest({
      method: "get",
      url: `admin/healthpass/${item._id}/vouchers`
      // /healthpass/" + item._id
      ,
      params: {},
      onFail: async (err) => {
        const data = err.data;
        let error_message = data.message;
        if (!error_message) {
          error_message = data.m;
        }
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        const data = UI.extract_data(response);

        const csv_data = data.result.map((item, index) => {

          const d = {
            Row: index + 1,
            "Generate Code": item?.code ? item?.code : item?.title,
          };

          return d;
        });
        UI.DownloadCSV(
          "Healthpass Generate Code Record-" + item.title + " " + UI.get_current_date_hr_mm_string(),
          csv_data
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  }

  render() {
    const _this = this;
    const { width } = _this.state;
    const data = mem.get("view_healthpass");
    let healthpass = JSON.parse(data);
    const start_date = moment(healthpass.startDate).format('MM/DD/YYYY');
    const end_date = moment(healthpass.endDate).format('MM/DD/YYYY');

    return (
      <MainLayout context={context} _this={_this}>

        <View style={{ ...styles.main_box_content, padding: 0 }}>
          <View style={styles.main_box_content}>
            <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
              <View
                style={{
                  ...styles.border_style,
                  borderRightWidth: width >= 1250 ? 2 : 0,
                  borderBottomWidth: width < 1250 ? 2 : 0,
                  paddingRight: width < 1250 ? 0 : 20,
                  marginRight: width < 1250 ? 0 : 20,
                  paddingBottom: 10,
                  marginBottom: width < 1250 ? 10 : 0,
                  justifyContent:'center',
                  alignItems:'center'
                }}
              >
                <View style={styles.fl_ai}>
                  <View style={{textAlign:'center'}}>
                    <Text style={{...styles.details_name, display:'flex', fontSize:'18px'}}>
                      <Text style={{color:UI.colors.primary, fontWeight:600}}>Health Pass Name</Text>
                    </Text>
                    <Text style={{color:UI.colors.unset, fontWeight:600}}>{healthpass.title}</Text>
                  </View>
                </View>
              </View>

              <View style={{ flex: 1 }}>
                <UI.Row
                  breakpoint={980}
                  breakpoint_2={720}
                  breakpoint_3={590}
                  _this={_this}
                  // style={{flexDirection: width < 1250 ? "row" : "column"}}
                >
                  <View style={{ flex: 1 }}>
                    <HealthPassDetail
                      icon={UI.DETAIL_QUALIFICATION}
                      name={"Validity Service"}
                      value={healthpass.validityService.map(res => res + " ")}
                    />
                    <HealthPassDetail
                      icon={UI.DETAILS_BMI}
                      name={"Price"}
                      value={healthpass.price}
                    />
                    <HealthPassDetail
                      icon={UI.DETAIL_QUALIFICATION}
                      name={"Discount"}
                      value={healthpass.discount + "%"}
                    />
                  </View>
                  <View style={{ flex: 1 }}>
                    <HealthPassDetail
                      icon={UI.DETAILS_BMI}
                      name={"Max Usage Per User"}
                      value={healthpass.maxUsagePerPerson}
                    />
                    <HealthPassDetail
                      icon={UI.DETAILS_AGE}
                      name={"Start Date"}
                      value={start_date}
                    />
                    <HealthPassDetail
                      icon={UI.DETAILS_AGE}
                      name={"End Date"}
                      value={end_date}
                    />
                  </View>
                  <View style={{ flex: 1 }}>
                    <HealthPassDetail
                      icon={UI.DETAIL_QUALIFICATION}
                      name={"Is 24 Hours?"}
                      value={healthpass.is24Hours  ? "Yes" : "No"}
                    />
                    <HealthPassDetail
                      icon={UI.DETAIL_QUALIFICATION}
                      name={"Is Allowed All Users"}
                      value={healthpass.allowAllUsers ? "Yes" : "No"}
                    />
                    <HealthPassDetail
                      icon={UI.DETAIL_QUALIFICATION}
                      name={"Is Transferable"}
                      value={healthpass.isTransferrable ? "Yes" : "No"}
                    />
                  </View>
                  <View style={{ flex: 1 }}>
                    {/* <HealthPassDetail
                      icon={UI.DETAIL_QUALIFICATION}
                      name={"Status"}
                      value={healthpass.usedQuantity}
                    /> */}
                    <HealthPassDetail
                      icon={UI.DETAIL_QUALIFICATION}
                      name={"Supply Stock"}
                      value={healthpass?.createUniqueVouchers ? healthpass?.numVouchersToGenerate : healthpass?.initialQuantity ?? " "}
                    />
                    <HealthPassDetail
                      icon={UI.DETAILS_PRC}
                      name={"Status"}
                      value={healthpass.status ? (
                        <Text style={{ color: "#00C700" }}>Active</Text>
                      ) : (
                        <Text style={{ color: "#f39c12" }}>Inactive</Text>
                      )}
                    />
                  </View>
                </UI.Row>
              </View>
            </UI.Row>
          </View>

          <View style={{ padding:'0px 18px 18px 18px', color:UI.colors.unset}}>
            <Text style={{color:UI.colors.primary, fontWeight:600, fontSize:'18px'}}>Description</Text>
            {UI.box(10)}
            <Text style={{fontWeight:700, fontSize:'16px'}}>
              {healthpass.description}
            </Text>
          </View>

          {UI.box(10)}

          <Text style={{color:UI.colors.secondary, paddingLeft:18, fontWeight:600, fontSize:'18px'}}>Vouchers List</Text>

          <UI.Row
            style={styles.main_container_header}
            breakpoint={950}
            _this={_this}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <Text style={styles.entry_header}>Only Show</Text>
              {UI.box(10)}
              <View>
                <EntryPicker table_name={"healthpass_code_list"} _this={_this} />
              </View>
            </View>

            <View style={{ alignSelf: "center", marginTop: 8, marginLeft: 10 }}>
              <Layout.Btn
                color={UI.colors.primary}
                text={"Export To CSV"}
                onClick={() => {
                  this.exportHealthPassCode(healthpass)
                }}
                height={40}
                width={140}
              />
            </View>
          </UI.Row>

          <CustomDatatable
            state_name={"healthpass_code_list"}
            _this={_this}
            columns={[
              // { ID: "id" },
              { "Voucher Code": "healthpass_code" },
            ]}
            data={this.state.data.map((item) => {
              console.log(item)
              return {
                healthpass_code: item?.code ? item.code : item.title,
              };
            })}
            on_page={(page, callback, table_name) => {
              UI.on_page(this.state.all_data, callback, table_name, page);
            }}
          />

          <View
            style={{
              ...styles.no_info_container,
              display: this.state.data.length > 0 ? "none" : "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 360, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#BFBFBF",
                  textAlign: width > 820 ? "left" : "center",
                  marginLeft: width > 820 ? 20 : 0,
                }}
              >
                NO DATA AVAILABLE YET
              </Text>
            </UI.Row>
          </View>
        </View>
      </MainLayout>
    );
  }
}

const HealthPassDetail = (props = { icon: "", name: "", value: "" }) => {
  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}
    >
      <View style={{ width: 200, ...styles.fl_ai }}>
        <View style={styles.detail_icon}>
          <Image
            height={16}
            width={16}
            tintColor={UI.colors.primary}
            source={props.icon}
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    // ...Shadow._3(),
    padding: 20,
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
});
