import ChartDataLabels from "chartjs-plugin-datalabels";
import interpolate from "interpolate-range";
import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import UI from "../../shared/Components/UI/js";
import { 
  // StyleSheet,
   Text, View } from "../../shared/custom-react-native";

export default class CustomPieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_resizing: false,
      myKey: "key_" + Date.now() + "_" + UI.uniqid(),
      borderColor: UI.colors.primary,

      color: UI.colors.primary,
      label: "",
      value: 0,
      will_update: true,
    };
  }

  update_key = () => {
    this.setState({
      myKey: "key_" + Date.now() + "_" + UI.uniqid(),
    });
  };

  componentDidMount = () => {
    let _this = this.props._this;
    _this.setState({
      current_scroll: 0,
    });
    _this.add_resize_function("pie_resize_" + this.props.id, this.on_resize);
    this.set_initial_data();

    this.initiate_graph();
  };

  initiate_graph = () => {
    setTimeout(() => {
      // let myPieChart = this.pie;
    }, 20);
  };

  set_initial_data = () => {
    if (this.props.data.length > 0) {
      let initial_object = this.props.data[0];
      let { color, count, label, labelColor } = initial_object;
      this.setState({
        color: color,
        label,
        value: count,
        labelColor
      });
      setTimeout(() => {
        this.setState({});
      }, 100);
    }
  };

  resizing_timeout = setTimeout(() => {}, 300);
  on_resize = () => {
    let _this = this.props._this;
    this.setState(
      {
        is_resizing: true,
      },
      () => {
        if (this.resizing_timeout) {
          clearTimeout(this.resizing_timeout);
        }

        this.resizing_timeout = setTimeout(() => {
          this.setState(
            {
              is_resizing: false,
            },
            () => {
              setTimeout(() => {
                _this.setState({
                  current_scroll:
                    document.getElementById("main_layout").scrollTop,
                });
                this.update_key();
              }, 100);
            }
          );
        }, 100);
      }
    );
  };

  hover_timeout = setTimeout(() => {}, 10);

  processActive = (tooltipItem) => {
    let dataset = tooltipItem.dataset;
    let index = tooltipItem.dataIndex;
    let label = tooltipItem.label;
    let value = dataset.data[index];
    let color = dataset.backgroundColor[index];

    if (this.state.will_update) {
      this.setState(
        {
          label,
          color,
          value,
          will_update: false,
        },
        () => {
          this.setState({
            label,
            color,
            value,
            will_update: false,
          });
        }
      );
    }

    clearTimeout(this.hover_timeout);

    this.hover_timeout = setTimeout(() => {
      this.setState({
        will_update: true,
      });
    }, 150);
  };

  render() {
    let props = this.props;
    let _this = props._this;
    const { width, 
      // height,
      //  isMobile 
      } = _this.state;
    let { is_resizing } = this.state;
    // let { state_name } = props;

    const pie_info = UI.measure("pie_" + this.props.id);
    const pie_width = pie_info.width;

    // let graph_radius = 500;

    if (width <= 500) {
      // graph_radius = width - 250;
    }

    if (pie_width >= 400 && pie_width < 455) {
      // graph_radius = 200;
    }

    if (pie_width <= 380) {
      // graph_radius = 150;
    }

    if (pie_width >= 378 && pie_width < 410) {
      // graph_radius = 120;
    }
    let additional_style = {};

    if (pie_width) {
      let marginTopInterpolate = interpolate({
        inputRange: [540, 725],
        outputRange: [0, 100],
        clamp: true,
      });
      let interpolate_2 = interpolate({
        inputRange: [0, 100],
        outputRange: [0, 140],
        clamp: true,
      });
      if (pie_width >= 540 && pie_width < 725) {
        additional_style = {
          marginTop: -interpolate_2(marginTopInterpolate(pie_width)),
          height: pie_width / 1.2,
        };
      }
    }
    if (is_resizing) {
      return <View></View>;
    }

    if (pie_width >= 538 && pie_width < 664) {
      additional_style.marginTop = -60;
      additional_style.height = pie_width / 1.1;
    }

    let text_info = UI.measure("text_info_" + this.props.id);

    let pie_x = pie_info.x;
    let pie_y = pie_info.y;

    pie_x = pie_x + (pie_info.width / 2 - text_info.width / 2);
    pie_y = pie_y + (pie_info.height / 2 - text_info.height / 2);

    let datasets = this.props.data.map((item) => {
      return item.count;
    });
    let labels = this.props.data.map((item) => {
      return item.label;
    });

    let colors = this.props.data.map((item) => {
      return item.color;
    });

    const externalTooltipHandler = (item) => {
      if (item.tooltip.dataPoints) {
        this.processActive(item.tooltip.dataPoints[0]);
      }
    };

    return (
      <View
        myKey={this.state.myKey}
        style={{ alignSelf: "center", height: 320, width: 320 }}
      >
        <View style={{}} id={"pie_" + this.props.id}>
          <View
            id={"text_info_" + this.props.id}
            style={{
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: pie_y,
              left: pie_x,
            }}
          >
            <Text
              style={{
                fontSize: 60,
                color: this.state.color,
                fontWeight: "bold",
                marginTop: -20,
              }}
            >
              {this.state.value}
            </Text>
            <Text
              style={{
                fontSize: 16,
                color: this.state.labelColor || this.state.color,
                fontWeight: "bold",
                marginTop: -20,
              }}
            >
              {this.state.label}
            </Text>
          </View>
          <Doughnut
            ref={(pie) => {
              this.pie = pie;
            }}
            data={{
              labels: labels,

              datasets: [
                {
                  label: "Pie Graph",
                  data: datasets,
                  backgroundColor: colors,
                  borderWidth: 0,
                },
              ],
            }}
            plugins={[ChartDataLabels]}
            options={{
              cutout: 100,
              radius: 140,
              plugins: {
                filler: {
                  propagate: true,
                },
                legend: {
                  display: false,
                },

                tooltip: {
                  enabled: false,
                  position: "average",
                  external: externalTooltipHandler,
                },
                datalabels: {
                  formatter: (value, ctx) => {
                    return value;
                  },
                  textStrokeWidth: 0.2,
                  color: "#fff",
                },
              },

              datasets: {
                doughnut: {
                  hoverBorderWidth: 6,
                  hoverBorderColor: this.state.color,
                  hoverBackgroundColor: this.state.color,
                },
              },
            }}
          />
        </View>
      </View>
    );
  }
}

// const styles = StyleSheet.create({
//   legend_text: {
//     color: "#535353",
//     fontWeight: "bold",
//     fontSize: 12,
//     marginLeft: 10,
//   },
//   legend_container: {
//     flexDirection: "row",
//     alignItems: "center",
//     marginRight: 10,
//   },
//   dot_color: {
//     height: 12,
//     width: 12,
//     borderRadius: 10,
//     backgroundColor: UI.colors.primary,
//   },
// });
