import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import TouchableOpacity, {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import MainLayout from "../_components/MainLayout";
import "./css.css";

const context = "voucher";

export default class VoucherCampaignView extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Campaign List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
      data:[],
      all_data: [],
      campaign: null
    });
  }

  onCreate = () => {
    this.loadHealthpassUnderCampaign()
  }

  loadHealthpassUnderCampaign = () => {
    const data = mem.get("view_campaign");
    const campaign = JSON.parse(data);
    this.setState({
      all_data: campaign.healthpass,
      data: campaign.healthpass, 
      campaign: campaign
    }, () => {
      this.reload_table("campaign_healthpass_list")
    })
    console.log(this.state)
  }

  render() {
    const _this = this;
    const {
      width,
      // campaign
    } = _this.state;
    const data = mem.get("view_campaign");
    const campaign = JSON.parse(data);
    const createdAt = moment(campaign.createdAt).format('MM/DD/YYYY');
    console.log(this.state.data)
    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>

            <View style={{ flex: 1, justifyContent:'space-around' }}>

            {UI.box(20)}

              <UI.Row _this={_this}>
                <UI.Row breakpoint={970} _this={_this} style={{ flex: 1, justifyContent:'space-between', alignItems:'center' }}>
                  <View style={{textAlign:'center'}}>
                    <Text style={{...styles.details_name, display:'flex', fontSize:'18px'}}>
                      {campaign.title}
                    </Text>
                    <Text style={{color:UI.colors.unset, fontWeight:600}}>Campaign Title</Text>
                  </View>

                  
                  {UI.box(20)}

                  <View style={{textAlign:'center'}}>
                    <Text style={{...styles.details_name, display:'flex', fontSize:'18px'}}>
                      {campaign?.creator?.firstName + " " + campaign?.creator?.lastName}
                    </Text>
                    <Text style={{color:UI.colors.unset, fontWeight:600}}>Creator Name</Text>
                  </View>

                  {UI.box(20)}

                  <View style={{textAlign:'center'}}>
                    <Text style={{...styles.details_name, display:'flex', fontSize:'18px'}}>
                      {createdAt}
                    </Text>
                    <Text style={{color:UI.colors.unset, fontWeight:600}}>Created At</Text>
                  </View>

                  {UI.box(20)}

                  <View style={{textAlign:'center'}}>
                    <Text style={{...styles.details_name, display:'flex', color:campaign.status === true ? UI.colors.active : UI.colors.inactive, fontSize:'18px'}}>
                      {campaign.status === true ? "Active" : "Inactive"}
                    </Text>
                    <Text style={{color:UI.colors.unset, fontWeight:600}}>Activity Status</Text>
                    
                  </View>

                </UI.Row>
              </UI.Row>
              {UI.box(10)}

            </View>
          </UI.Row>

          {UI.box(20)}
          
          <View style={{border:'2px dashed #C9C9C9', padding:'18px', borderRadius:'12px'}}>
            <Text style={{color:UI.colors.primary, fontWeight:600, fontSize:'18px'}}>Description</Text>
            {UI.box(10)}
            <Text style={{color:UI.colors.unset, fontWeight:700, fontSize:'16px'}}>
              {campaign.description}
            </Text>
          </View>
          
          <View style={{marginTop:20}}>

            <Text style={{color:UI.colors.secondary, fontWeight:600, fontSize:'18px'}}>Health Pass</Text>

            {UI.box(20)}

            <UI.Row
              style={styles.main_container_header}
              breakpoint={950}
              _this={_this}
            >
              {/* <View
                style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
              >
                <Text style={styles.entry_header}>Only Show</Text>
                {UI.box(10)}
                <View>
                  <EntryPicker table_name={"members_list"} _this={_this} />
                </View>
              </View> */}
            </UI.Row>

            <CustomDatatable
              state_name={"campaign_healthpass_list"}
              _this={_this}
              columns={[
                // { ID: "id" },
                { "Healthpass Name": "healthpass_title" },
                // { "Total Count": "initialQuantity" },
                { "Start Date": "startDate" },
                { "End Date": "endDate" },
                { Status: "status" },
                { Action: "action" },
              ]}
              data={this.state.all_data.map((item) => {
                console.log(item.title)
                const startDate = moment(item.startDate).format('MM/DD/YYYY');
                const endDate = moment(item.endDate).format('MM/DD/YYYY');

                const is_active = item.status === "Active" ? true : false;
                return {
                  healthpass_title: item.title,
                  startDate: startDate,
                  endDate: endDate,
                  status: is_active ? (
                    <Text style={{ color: "#00C700" }}>Active</Text>
                  ) : (
                    <Text style={{ color: "#f39c12" }}>Inactive</Text>
                  ),
                  action:
                    <TouchableOpacity
                      onClick={() => {
                        mem.save("view_healthpass", JSON.stringify(item));
                        UI.goTo("/voucher/healthpass-view");
                      }}
                    >
                      <Image
                        height={28}
                        width={28}
                        tintColor={"transparent"}
                        source={UI.VIEW_BTN}
                      />
                    </TouchableOpacity>
                };
              })}
              on_page={(page, callback, table_name) => {
                UI.on_page(this.state.all_data, callback, table_name, page);
              }}
            />

            <View
              style={{
                ...styles.no_info_container,
                display: this.state.all_data.length > 0 ? "none" : "flex",
              }}
            >
              <UI.Row
                breakpoint={820}
                style={{ maxWidth: 360, alignItems: "center" }}
                _this={_this}
              >
                <Image
                  height={100}
                  width={100}
                  tintColor={"transparent"}
                  source={UI.NO_INFO}
                />
                {UI.box(10)}
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#BFBFBF",
                    textAlign: width > 820 ? "left" : "center",
                    marginLeft: width > 820 ? 20 : 0,
                  }}
                >
                  NO DATA AVAILABLE YET
                </Text>
              </UI.Row>
            </View>
          </View>
        </View>
        
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    border:"2px dashed #C9C9C9",
    borderRadius:"12px",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
});
