import React from "react";
import styled from "styled-components";
import { DatePicker } from "antd";
import DatePickerCalendarIcon from "../../../icons/DatePickerCalendar";

const DatePickerFC = React.memo((props) => {
  return (
    <StyledDatePicker
      {...props}
      suffixIcon={<DatePickerCalendarIcon />}
      getPopupContainer={(trigger) => trigger.parentNode} // Avoid Date picker options moves when scrolling
    />
  );
});

export default DatePickerFC;

const StyledDatePicker = styled(DatePicker)`
  width: 100%; // occupy and stretch to remaining spaces
  min-width: 300px;
  height: 60px;
  border-radius: 12px;
  border: 2px solid #cacaca;
  .ant-picker-input input {
    font-size: 1.25rem; // 20px
    font-family: Poppins;
    line-height: 1.25rem; // 23px
    font-weight: 500; //medium
    letter-spacing: 0px;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-picker-clear {
    top: 50%;
    right: -1px;
    font-size: 21px;
    opacity: 1 !important;
    color: #cacaca;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
