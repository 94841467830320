import React from "react";

const DoctorsOrderSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.345"
      height="33.794"
      viewBox="0 0 25.345 33.794"
    >
      <path
        id="file-contract-solid"
        d="M4.224,0A4.228,4.228,0,0,0,0,4.224V29.569a4.228,4.228,0,0,0,4.224,4.224h16.9a4.228,4.228,0,0,0,4.224-4.224V10.561H16.9a2.11,2.11,0,0,1-2.112-2.112V0ZM16.9,0V8.448h8.448ZM5.28,4.224H9.5a1.056,1.056,0,1,1,0,2.112H5.28a1.056,1.056,0,1,1,0-2.112Zm0,4.224H9.5a1.056,1.056,0,0,1,0,2.112H5.28a1.056,1.056,0,0,1,0-2.112ZM8.858,25.2a3.17,3.17,0,0,1-3.036,2.257H5.28a1.056,1.056,0,0,1,0-2.112h.541a1.047,1.047,0,0,0,1.01-.752l.983-3.267a1.763,1.763,0,0,1,3.379,0l.766,2.548a2.765,2.765,0,0,1,4.244.891l.29.581h3.571a1.056,1.056,0,0,1,0,2.112H15.841a1.051,1.051,0,0,1-.944-.581l-.581-1.168a.65.65,0,0,0-.581-.363.642.642,0,0,0-.581.363l-.581,1.168a1.055,1.055,0,0,1-1.954-.165L9.5,23.035Z"
        fill="#035099"
      />
    </svg>
  );
};

export default DoctorsOrderSvg;
