import React from 'react'
import ReactQuill from 'react-quill';
import styled from 'styled-components';

import FieldLabel from '../../ComponentsFC/ui/forms/FieldLabel';
import 'react-quill/dist/quill.snow.css';

const ProductDescription = ({ _this }) => {

  const handleChange = (html) => {
    _this.setState({
      description: html
    })
  }

  return (
    <>
      <FieldLabel style={{ fontSize: 15 }}>Product Description <Required>*</Required></FieldLabel>
      <StyledReactQuill
        className='h-60'
        theme="snow"
        modules={ProductDescription.modules}
        format={ProductDescription.formats}
        readOnly={false}
        value={_this.state.description}
        onChange={handleChange}
        placeholder={"Enter Description Here..."}
      />
    </>
  )
}

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
ProductDescription.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}

/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
ProductDescription.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]

const StyledReactQuill = styled(ReactQuill) `
  .ql-toolbar {
    border-top-right-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
  }

  .ql-container {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .quill  .ql-container{
    max-height: calc(100% - 42px);
  }
`

export default ProductDescription

const Required = styled.span`
 color: red;
`