import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import "./css.css";
export default class Statistics extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const _this = this.props._this;
    const {
      // height,
      // width,
      // isMobile,
      // item,
      total_active_vouchers,
      total_available_vouchers,
    } = _this.state;

    return (
      <UI.Row breakpoint={1175} _this={_this}>
        <View style={styles.earnings_container}>
          <View style={{ flex: 1, minWidth: 160 }}>
            <Text style={styles.header_1}>Total Active Vouchers</Text>
            <Text style={styles.main_header}>
              {UI.pad(total_active_vouchers, 4)}
            </Text>
            {UI.box(10)}
            <Text style={styles.header_1}>Total Available Vouchers</Text>
            <Text style={styles.main_header}>
              {UI.pad(total_available_vouchers, 4)}
            </Text>
          </View>
          {UI.box(25)}
          <Image
            height={100}
            width={100}
            tintColor={"transparent"}
            source={UI.PAY}
          />
        </View>
      </UI.Row>
    );
  }
}

// const DashItem = (
//   props = { _this: null, icon: "", num: "", text: "", color: "" }
// ) => {
//   return (
//     <View style={{ flexDirection: "row", flex: 1 }}>
//       <Image
//         height={60}
//         width={60}
//         tintColor={"transparent"}
//         source={props.icon}
//       />

//       <View style={{ marginLeft: 15 }}>
//         <Text
//           style={{ ...styles.main_header, color: props.color, fontSize: 22 }}
//         >
//           {props.num}
//         </Text>
//         <Text style={{ ...styles.header_2, fontSize: 12 }}>{props.text}</Text>
//       </View>
//     </View>
//   );
// };

const styles = StyleSheet.create({
  sort_filter_text: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#BFBFBF",
  },
  sort_filter: {
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 2,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#C9C9C9",
  },
  box_header_subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#C9C9C9",
    alignSelf: "flex-end",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.secondary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    backgroundColor: "white",
    padding: 20,
    ...Shadow._3(),
    flexDirection: "row",
    maxHeight: 180,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
