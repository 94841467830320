import React from 'react'
import InputForm from "../_components/InputForm";
import UI from "../../shared/Components/UI/js";
import { View } from "../../shared/custom-react-native";
import Layout from "../_components/Layout";

const PTRForm = ({ _this }) => {
  return (
    <UI.Row
      style={{ width: 1000 }}
      _this={_this}
      breakpoint={1000}
    >
      <InputForm
        _this={_this}
        title={"PTR Number"}
        placeholder={"PTR Number"}
        state_name={"ptrNumber"}
        style={{ flex: 1 }}
      />
      {UI.box(10)}
      <View style={{ flex: 1 }}>
        <Layout.FilePicker
          _this={_this}
          title={"Upload PTR"}
          additional={"(jpeg or png)"}
          text={"Drop Your File Here"}
          state_name={"ptr"}
          onChange={async (file) => {
            _this.auth_upload("ptr", file);
          }}
          allowed_files={["jpg", "jpeg", "png"]}
          display_type={"filename_size"}
        />
      </View>
      <View style={{ flex: 1 }}></View>
    </UI.Row>
  )
}

export default PTRForm;