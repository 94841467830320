import React, { Component } from "react";
import "./css.css";
import "react-datepicker/dist/react-datepicker.css";
import { Row } from "antd";
import { ContainerCardWithTabs } from "../../ComponentsFC/ui";
import { UserGroupHeaderV2 } from "../../ComponentsFC/user-group/index";
import UI from "../../shared/Components/UI/js";
import { mem } from "../../shared/custom-react-native";
import MainLayout from "../_components/MainLayout";
import Admin from "././admin/Admin";
import Member from "././member/Member";
// import Subscriptions from "././subscription/Subscriptions";
import APIConstants from "../../shared/modules/APIConstants";
// import BillingTable from "./BillingTable";
// import { UserGroupHeader } from "../../ComponentsFC/user-group";
import ServiceUsergroupsApi from "../../api_services/usergroups";

// const role = mem.get(UI.Constants.ROLE);

// const context = role;

const Constants = new APIConstants();
export default class UserGroupView extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "User Group List",
      is_drawer_open: false,
      selected: null,

      admins_allowed_email: [],
      members_allowed_email: [],
      members_allowed_phone_number: [],
      activeTab: "Members",
      members: [],
      user_group: null,
      tabs: [
        { tab: "Members", key: "Members" },
        { tab: "Admins", key: "Admins" },
        // {tab: "Subscriptions", key:'Subscriptions' },
      ],
      context: mem.get(UI.Constants.ROLE),
    });
  }

  onCreate = () => {
    const data = mem.get("view_user_group");
    const activeTab = UI.get_param("activeTab");
    if (activeTab) {
      this.setState({ activeTab: activeTab });
    }
    let user_group = JSON.parse(data);
    const partnerLogoImage = user_group.partnerLogoImage
      ? `${Constants.STORAGE_BASE_URL}?path=${user_group.partnerLogoImage}`
      : UI.SAMPLE_PRODUCT;
    const profileLogoImage = user_group.profileLogoImage
      ? `${Constants.STORAGE_BASE_URL}?path=${user_group.profileLogoImage}`
      : UI.SAMPLE_PRODUCT;

    this.setState(
      {
        user_group: user_group,
        profile_logo_image: profileLogoImage,
        partner_logo_image: partnerLogoImage,
      },
      () => this.getMember()
    );
  };

  getMember = () => {
    ServiceUsergroupsApi.getUserGroupMembersByUserGroupId(
      this.state.user_group._id,
      {
        page: 1,
        limit: 9999,
      }
    ).then((res) => {
      if (res.status === 200) {
        let data = res.data;
        this.setState({ members: data });
      }
    });
  };

  render() {
    const _this = this;
    const { activeTab, context, tabs } = _this.state;

    return (
      <MainLayout context={context} additional="usergroup-list" _this={_this}>
        <UserGroupHeaderV2 this={_this} />

        <ContainerCardWithTabs
          tabList={tabs}
          activeTabKey={activeTab}
          onTabChange={(key) => {
            _this.setState({ activeTab: key });
          }}
        >
          <Row>
            {
              activeTab === "Members" ? (
                <Member _this={_this} />
              ) : activeTab === "Admins" ? (
                <Admin _this={_this} />
              ) : (
                ""
              )
              // <Subscriptions _this={_this}/>
            }
          </Row>
        </ContainerCardWithTabs>
      </MainLayout>
    );
  }
}
