import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { CustomConfirmation, Failed, Success } from "../_components/Modal";
import "./css.css";

const context = "finance";
export default class FinanceDisbursePayment extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Doctors Disbursement",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: [],
      context: context,

      gender: "",
      birthdate: "",
      qualification: "",
      specialty: "",
      hospital: "",
      prc_number: "",
      prc_start_date: "",
      prc_end_date: "",

      name: "",
      email: "",
      phone: "",

      status: "",

      picture: UI.DOCTOR_PROFILE,
      prcLicensePhoto: "",
      signature: "",
      mpc: "",
      moaPath: "",

      picture_filename: "",
      prcLicensePhoto_filename: "",
      signature_filename: "",
      moa_filename: "",
      mpc_filename: "",

      picture_size: "",
      prcLicensePhoto_size: "",
      signature_size: "",
      moa_size: "",
      mpc_size: "",

      remarks: "",

      disbursement_amount: 0,
      disbursement_withholding: 0,
      disbursement_withholding_percentage: 0,
      disbursement_total: 0,

      id: "",
    });
  }

  onCreate = () => {
    this.load_data();
  };
  load_data = async () => {
    const data = mem.get("edit_doctor");
    if (data) {
      const doctor = JSON.parse(data);
      this.setState({
        name: doctor.fullname,
        bankName: doctor.bankName,
        bankBranch: doctor.bankBranch,
        bankAccountName: doctor.bankAccountName,
        bankAccountNumber: doctor.bankAccountNumber,
        amount: doctor.net_amount,
        totalAmount: doctor.gross_amount,
        disbursements: doctor.disbursements,
        withholdingTax: doctor.withholdingTax,
        id: doctor._id,
      });

      UI.set_input_value("amount", doctor.gross_amount);
    }
  };

  submit = async () => {
    UI.clear_errors();
    //no merchant yet

    this.show_modal(
      <CustomConfirmation
        title={"Confirmation"}
        text={"Are you sure you want to disburse the selected doctor?"}
        backgroundColor={UI.colors.secondary}
        onCancel={{
          text: "Cancel",
          method: () => {
            this.hide_modal();
            this.hide_loading();
          },
        }}
        onOk={{
          text: "Proceed",
          method: () => {
            this.hide_modal();
            this.show_loading();
            UI.webRequest({
              method: "post",
              url: "admin/disbursements",
              params: {
                doctor: this.state.id,
                amount: this.state.totalAmount,
                currency: "PHP",
              },
              onFail: async (err) => {
                console.log(err);
                const data = err.data;
                const error_message = data.error;
                this.show_modal(
                  <Failed
                    title={"Failed"}
                    description={error_message}
                    onDismiss={() => {
                      this.hide_modal();
                    }}
                  />
                );
              },
              onSuccess: async (response) => {
                console.log(response);
                this.show_modal(
                  <Success
                    title={"Success!"}
                    description={"Doctor is successfully disbursed"}
                    onDismiss={() => {
                      this.hide_modal();
                      UI.goBack();
                    }}
                  />
                );
              },
              onFinish: () => {
                this.hide_loading();
              },
            });
          },
        }}
      />
    );
  };

  render() {
    const _this = this;
    const { 
      // height, 
      width, 
      // isMobile 
    } = _this.state;

    const {
      name,
      // email,
      // phone,
      picture,

      // disbursement_amount,
      // disbursement_withholding,
      // disbursement_total,
      // disbursement_withholding_percentage,

      bankName,
      bankBranch,
      bankAccountName,
      bankAccountNumber,

      amount,
      totalAmount,

      withholdingTax,
    } = _this.state;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row breakpoint={1250} style={{ width: "100%" }} _this={_this}>
            <View
              style={{
                ...styles.border_style,
                borderRightWidth: width >= 1250 ? 2 : 0,
                borderBottomWidth: width < 1250 ? 2 : 0,
                paddingRight: width < 1250 ? 0 : 20,
                marginRight: width < 1250 ? 0 : 20,
                paddingBottom: 10,
                marginBottom: width < 1250 ? 10 : 0,
              }}
            >
              <View style={styles.fl_ai}>
                <img
                  style={{ height: 120, width: 120, borderRadius: 5 }}
                  src={picture}
                  alt="Patient"
                />

                {UI.box(width < 980 ? 5 : 10)}

                <View>
                  <Text style={styles.patient_name}>{name}</Text>
                </View>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <UI.Row
                breakpoint={980}
                breakpoint_2={720}
                breakpoint_3={590}
                _this={_this}
              >
                <View style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.PAYMENT_METHOD}
                    name={"Bank Name"}
                    value={bankName}
                  />
                  <UserDetail
                    icon={UI.PAYMENT_METHOD}
                    name={"Bank Branch"}
                    value={bankBranch}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <UserDetail
                    icon={UI.PAYMENT_METHOD}
                    name={"Bank Account Name"}
                    value={bankAccountName}
                  />

                  <UserDetail
                    icon={UI.PAYMENT_METHOD}
                    name={"Bank Account Number"}
                    value={bankAccountNumber}
                  />
                </View>
              </UI.Row>
            </View>
          </UI.Row>
        </View>

        <UI.Row
          _this={_this}
          breakpoint={1000}
          style={{ width: "100%", marginTop: 20 }}
        >
          <View
            style={{
              ...styles.main_box_content,
              width: width > 1000 ? 300 : "100%",
            }}
          >
            <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
              <Text style={{ ...styles.box_header_text, flex: 1 }}>
                Disbursement Details
              </Text>
            </UI.Row>
            {UI.box(20)}

            <View style={styles.amount_container}>
              <Text style={styles.amount_text}>Amount</Text>
              <Text style={styles.amount_value}>
                {UI.pad(totalAmount, 4, true)}
              </Text>
            </View>
            <View style={styles.amount_container}>
              <Text style={styles.amount_text}>
                Withholding Tax ({withholdingTax}%)
              </Text>
              <Text style={{ ...styles.amount_value, color: "#e74c3c" }}>
                -{(totalAmount - amount).toFixed(2)}
              </Text>
            </View>

            {UI.box(30)}

            <View style={styles.line}></View>
            {UI.box(10)}
            <View style={styles.amount_container}>
              <Text style={styles.amount_text}>Total</Text>
              <Text style={{ ...styles.amount_value }}>
                {UI.pad(amount, 4, false)}
              </Text>
            </View>
          </View>

          {UI.box(20)}
          <View style={{ ...styles.main_box_content, flex: 1 }}>
            <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
              <Text style={{ ...styles.box_header_text, flex: 1 }}>
                Disbursement Amount
              </Text>
            </UI.Row>

            {UI.box(20)}

            <View style={{ alignSelf: "flex-start", width: 280 }}>
              <InputForm
                disabled={true}
                _this={_this}
                title={"Amount To Be Disbursed"}
                placeholder={"Enter Amount To Be Disbursed"}
                state_name={"amount"}
                style={{ flex: 1 }}
                isNumber={true}
                isRequired={false}
                maxLength={UI.Constants.limits.price}
              />
            </View>

            {UI.box(20)}

            <View style={{ width: width > 720 ? 300 : "100%" }}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Layout.Btn
                  color={UI.colors.primary}
                  onClick={() => {
                    this.submit();
                  }}
                  text={"SUBMIT"}
                />
                {UI.box(20)}

                <Layout.Btn
                  color={"#E00000"}
                  onClick={() => {
                    UI.goBack();
                  }}
                  text={"CANCEL"}
                />
              </View>
            </View>
          </View>
        </UI.Row>
      </MainLayout>
    );
  }
}

const UserDetail = (props = { icon: "", name: "", value: "" }) => {
  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}
    >
      <View style={{ width: 180, ...styles.fl_ai }}>
        <View style={styles.detail_icon}>
          <Image
            height={16}
            width={16}
            tintColor={UI.colors.primary}
            source={props.icon}
          />
        </View>
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  line: {
    height: 2,
    width: "100%",
    backgroundColor: "#34495e",
    opacity: 0.5,
  },
  amount_value: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "right",
  },
  amount_text: {
    fontSize: 16,
    fontWeight: "bold",
    width: 190,
    flex: 1,
  },
  amount_container: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
  border_style: {
    borderStyle: "solid",
    borderColor: "#EFEFEF",
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  appointment_text: {
    fontSize: 14,
    color: "#333333",
    fontWeight: "bold",
    marginLeft: 10,
  },
  date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 15,
  },
  patient_details: {
    fontSize: 14,
    color: "#898989",
  },
  patient_name: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#535353",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
