import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, DatePicker, Input, Row, Select, Typography } from "antd";
import styled from "styled-components";
import DropdownIcon from "../../../icons/Dropdown";
import DataTable from "../../DataTable";
import moment from "moment";
import FinancialWellnessApi from "../../../api_services/Admin/FinancialWellnessApi";
import UdbHeader from "./UdbHeader";
import DatePickerCalendarIcon from "../../../icons/DatePickerCalendar";
import SearchIcon from "../../../icons/Search";
import UI from "../../../shared/Components/UI/js";

const UdbTable = () => {
  const [tableData, setTableData] = useState([]);
  const [searchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableEntries, setTableEntries] = useState(30);
  const [totalRecords, setTotalRecords] = useState();
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [appliedStartDate, setAppliedStartDate] = useState();
  const [appliedEndDate, setAppliedEndDate] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getFinancialWellnessByUdb = useCallback(async (limit) => {
    try {
      let urlParams = {
        type: "udb"
      };

      if (currentPageNum) {
        urlParams.page = currentPageNum;
        urlParams.limit = limit ? limit : tableEntries;
      }

      if (appliedStartDate && appliedEndDate) {
        urlParams.startDate = appliedStartDate;
        urlParams.endDate = appliedEndDate;
      }

      if (searchQuery) {
        urlParams.searchString = searchQuery;
      }

      setIsTableLoading(true);
      const apiResponse = await FinancialWellnessApi.getFinancialWellness(
        urlParams
      );

      setIsTableLoading(false);
      // console.log(apiResponse);
      setTotalRecords(apiResponse.totalDocs);
      setTableData(apiResponse.list);

      return apiResponse.list
    } catch (err) {
      // Handle Errors Here
      console.error(err);
      if (err.response) {
        let statusCode = err.response.data.statusCode;
        let errorMsg = err.response.data.message;
        if (statusCode === 404) console.log(`Error: ${statusCode} ${errorMsg}`);
        if (statusCode === 500) console.log(`Error: ${statusCode} ${errorMsg}`);
      }
    }
  }, [
    currentPageNum,
    tableEntries,
    searchQuery,
    appliedStartDate,
    appliedEndDate
  ]);

  useEffect(() => {
    getFinancialWellnessByUdb();
  }, [getFinancialWellnessByUdb]);


  const handleChangeNumOfEntries = (value) => {
    setTableEntries(Number(value));
  };

  const handleApplyShiftDateRange = (startDate, endDate) => {
    setAppliedStartDate(startDate);
    setAppliedEndDate(endDate);
  };

  const handleChangeFilterByShiftDateRange = (dates, dateStrings) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  const handleChangeSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePaginateTable = (antdSelectedPageNum) => {
    setCurrentPageNum(antdSelectedPageNum);
  };
  console.log(tableData)
  const tableColumnHeader = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (firstName) => firstName ?? "",
    },
    {
      title: "Middle Name",
      dataIndex: "middleName",
      key: "middleName",
      render: (middleName) => middleName ?? "",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (lastName) => lastName ?? "",
    },
    {
      title: "Birth Date",
      dataIndex: "birthDate",
      key: "birthDate",
      render: (birthDate) => birthDate ? birthDate : "--",
    },
    {
      title: "Email Address",
      dataIndex: "emailAddress",
      key: "emailAddress",
      render: (emailAddress) => emailAddress ?? "",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (mobileNumber) => mobileNumber ?? "",
    },
    {
      title: "Date of Submission",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("MM/DD/YYYY").toLowerCase(),
    }
  ];

  const handleCSVExport = async () => {
    const data = await getFinancialWellnessByUdb(totalRecords);
 
    const csv_data = data.map((item, index) => {
      const d = {
        "First Name": item.firstName ?? "--",
        "Middle Name": item.middleName ?? "--",
        "Last Name": item.lastName ?? "--",
        "Birth Date":  item.birthDate ? item.birthDate : "--",
        "Email Address": item.emailAddress ?? "--",
        "Mobile Number": item.mobileNumber ?? "--",
        "Date of Submission": moment(item.createdAt).format("MM/DD/YYYY").toLowerCase(),
      };

      return d;
    });

    UI.DownloadCSV(`UDB List ${UI.get_current_date_string()}`, csv_data);
  };

  return (
    <>
      <UdbHeader membersCount={totalRecords}/>
      <FilterBox>
        <Title level={5}>Filter</Title>
          <Toolbar gutter={14}>
            <Col span={24}>
              <StyledFieldLabel>Select Date Range</StyledFieldLabel>
              <StyledRangePicker
                separator={<DatePickerCalendarIcon />}
                suffixIcon={<DatePickerCalendarIcon />}
                placeholder={["Start Date", "End Date"]}
                ranges={{
                  Today: [moment(), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                onChange={handleChangeFilterByShiftDateRange}
              />
              <ApplyButton
                type="primary"
                onClick={() => {
                  handleApplyShiftDateRange(startDate, endDate);
                }}
              >
                Apply Date Filter
              </ApplyButton>
            </Col>
          </Toolbar>
      </FilterBox>

      <TableBox>
        <Toolbar>
          <FieldLabel>Only Show</FieldLabel>
          <StyledSelect
            getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
            defaultValue="30"
            style={{
              width: 160,
            }}
            suffixIcon={<DropdownIcon />}
            onChange={handleChangeNumOfEntries}
          >
            <Select.Option value="5">5 Entries</Select.Option>
            <Select.Option value="10">10 Entries</Select.Option>
            <Select.Option value="30">30 Entries</Select.Option>
          </StyledSelect>
          <StyledSearch
            placeholder="Enter Keyword/Name"
            allowClear
            suffix={<SearchIcon />}
            value={searchQuery}
            onChange={(e) => {
              handleChangeSearchQuery(e);
            }}
            onPressEnter={getFinancialWellnessByUdb}
            style={{ width: 250 }}
          />
          <StyledButton type="primary" 
            onClick={() => handleCSVExport()}
          >
            Export CSV
          </StyledButton>
        </Toolbar>

        <DataTable
          dataSource={searchResults.length > 0 ? searchResults : tableData}
          tableColumnHeader={tableColumnHeader}
          defaultPageSize={30}
          pageSizeOptions={["5", "10", "30"]}
          tableEntries={tableEntries}
          isBackendPaginated={true}
          totalRecords={totalRecords}
          onPaginate={handlePaginateTable}
          loading={isTableLoading}
          scroll={{
            x: 600,
          }}
        />
      </TableBox>
    </>
  );
};

export default UdbTable;

// CSS Styled Components ----------------------
const FilterBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #bfbfbf29;
  border-radius: 12px;
  padding: 33px 0 0 0;
  margin-bottom: 30px;
`;

const TableBox = styled(Row)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 33px 0 33px 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  width: 60vw;
  min-width: 100%;
  // 2ND COLUMN OF TABLE (DOCTOR'S SPECIALTY)
  .ant-table-row .ant-table-cell:nth-child(2) {
    white-space: pre;
  }
`;

const Toolbar = styled(Row)`
  display: flex;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 33px;
  font-family: Poppins;
  font-weight: 500; //medium
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  letter-spacing: 0;
`;

const FieldLabel = styled(Typography.Paragraph)`
  color: #2e2e2e;
  margin-right: 30px;
  margin-bottom: 0 !important;
`;


const Title = styled(Typography.Title)`
  font-family: Poppins;
  font-weight: 500 !important; //medium
  font-size: 1.4375rem !important; // 15px
  line-height: 1.4375rem !important; // 23px
  letter-spacing: 0 !important;
  color: #004f99 !important;
  padding-left: 35px;
  margin-bottom: 25px !important;
`;


const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
  // The Wrapper of Icon for clear btn, when there's a value
  .ant-select-clear {
    top: 45%;
    right: 45px;
    opacity: 1 !important;
  }
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  flex-direction: row;
  border-radius: 12px;
  border: 1px solid transparent;
  box-shadow: none !important;
  height: 50px;
  &:hover {
    border-color: transparent !important;
  }
  .ant-picker-input {
    border: 2px solid #d9d9d9;
    border-radius: 12px;
    padding-left: 10px;
    padding-right: 10px;
    height: 50px;
    width: 260px;
  }
  .ant-picker-range-separator {
    display: block !important; //Need this bcoz the this selector css.css interferes globally
    margin-left: -43px; //To insert built-in icon inside input field
    margin-right: 16px;
  }
  .ant-picker-suffix {
    margin-left: -35px; //To insert built-in icon inside input field
  }
  .ant-picker-active-bar {
    display: none;
  }
  .ant-picker-clear {
    display: none;
  }
`;

const ApplyButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #02488d;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover,
  &:focus {
    background-color: #02488d;
  }
`;

const StyledSearch = styled(Input)`
  border-radius: 12px !important;
  border-width: 2px !important;
  height: 50px;
  width: 250px !important;
  margin-left: auto;
  .ant-input {
    font-family: Poppins;
    font-weight: 500;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
  }
`;

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

