import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import "./css.css";
const context = "onboard";

export default class OnboardChangePassword extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Change Password",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
    });
  }

  onCreate = () => {};

  render() {
    const _this = this;
    const {
      // height,
      width, 
      // isMobile 
    } = _this.state;

    return (
      <MainLayout context={context} additional={""} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={730} style={{ width: "100%" }}>
            <Text style={{ ...styles.header_3, flex: 1 }}>Update Password</Text>

            <Text style={styles.text_2}>Keep your password updated</Text>
          </UI.Row>

          {UI.box(20)}

          <UI.Row breakpoint={900} style={{ width: "100%" }} _this={_this}>
            <View style={{ flex: 1 }}>
              <InputForm
                _this={_this}
                title={"Current Password"}
                placeholder={"Password"}
                state_name={"current_password"}
                isPassword={true}
              />
            </View>
            {UI.box(30)}
            <View style={{ flex: 1 }}>
              <InputForm
                _this={_this}
                title={"New Password"}
                placeholder={"Password"}
                state_name={"new_password"}
                isPassword={true}
              />
            </View>
            {UI.box(30)}
            <View style={{ flex: 1 }}>
              <InputForm
                _this={_this}
                title={"Re-Enter Password"}
                placeholder={"Password"}
                state_name={"confirm_password"}
                isPassword={true}
              />
            </View>
          </UI.Row>

          {UI.box(20)}

          <View style={{ width: width > 720 ? 300 : "100%" }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Layout.Btn
                color={UI.colors.primary}
                onClick={() => {
                  // UI.goTo("/onboard/dashboard");
                }}
                text={"SAVE CHANGES"}
              />
              {UI.box(20)}

              <Layout.Btn
                color={"#E00000"}
                onClick={() => {}}
                text={"CANCEL"}
              />
            </View>
          </View>
        </View>
      </MainLayout>
    );
  }
}

// const Description = (props = { main: "", sub: "" }) => {
//   return (
//     <View style={{ width: "100%" }}>
//       <Text style={styles.main_text}>{props.main}</Text>
//       <Text style={styles.sub_text}>{props.sub}</Text>
//     </View>
//   );
// };

const styles = StyleSheet.create({
  sub_text: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#B4B4B4",
  },
  main_text: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#535353",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },

  option_container: {
    height: 40,
    width: "100%",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 2,
    padding: 5,
    borderColor: "#CACACA",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  btn_text_1: {
    fontSize: 14,
    color: "white",
    fontWeight: "bold",
  },
  blue_btn_2: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: UI.colors.primary,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  text_1: {
    color: "#AFAFAF",
    fontSize: 14,
  },
  image_container: {
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  header_3: {
    fontSize: 16,
    color: UI.colors.primary,
    marginTop: 2,
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
