import React from 'react'
import { Link } from 'react-router-dom'
import UI from '../../../shared/Components/UI/js'
import { Image } from 'antd'

const LoginUsingEmail = () => {
  return (
    <Link to="/onboard/email-login">
      <Image preview={false} src={UI.EMAIL_ICON} alt="email_icon" className='cursor-pointer'/>
    </Link>
  )
}

export default LoginUsingEmail