// **Commented out due to currently unused
// import { DatePicker } from "antd";
import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import TouchableOpacity, {
  Image,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import "./css.css";
export default class Statistics extends Component {
  // **Commented out due to unused empty constructor, no need to define empty constructor
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const _this = this.props._this;
    const {
      // height,
      width,
      // isMobile,
      // top_product_image,
      data,
      // current_date,
      current_date_string,
      // custom_active_products,
      // customer_ids,
      // startDate,
      // endDate
    } = _this.state;

    const { topPurchasedProduct } = data;
    const {
      totalCustomers,
      // totalFinishedPurchases,
      // totalActiveProducts,
      // totalInquiries,
      totalPaidPurchases,
      totalCompletedPurchases,
      totalMerchantProducts
    } = data;
    return (
      <UI.Row
        breakpoint={1000}
        _this={_this}
        style={{ width: "100%", marginBottom: 20 }}
      >
        <View
          style={{
            ...styles.earnings_container,
            alignSelf: width > 1000 ? "flex-start" : "stretch",
          }}
        >
          <View style={{ flex: 1, minWidth: 160 }}>
            <Text style={styles.header_1}>Top purchased product</Text>
            {UI.box(10)}
            <Text style={styles.main_header}>
              {topPurchasedProduct
                ? topPurchasedProduct.name
                : "No Product Yet"}
            </Text>
            <Text
              style={{
                ...styles.main_header,
                fontSize: 22,
                color: UI.colors.primary,
              }}
            >
              {UI.pad(topPurchasedProduct ? topPurchasedProduct.count : 0, 4)}
            </Text>
            <Text style={{...styles.header_1, height: 20}}>
              {/* Purchases as of: {current_date} */}
            </Text>
          </View>
          {UI.box(25)}
          {/* <img
            style={{ height: 100, width: 100, borderRadius: 10 }}
            src={top_product_image}
          /> */}
        </View>
        {UI.box(20)}
        <View
          style={{
            padding: 20,
            borderRadius: 5,
            backgroundColor: "white",
            ...Shadow._3(),
            flex: 1,
          }}
        >
           
          <UI.Row breakpoint={1170} _this={_this} style={{ width: "100%" }}>
            <Text style={styles.box_header_text}>
              Site Stats: {current_date_string}
            </Text>
         
            {UI.box(10)}

            { this.props._this.state.startDate ? this.props._this.show_modal(
                <View style={{backgroundColor:'white', borderRadius: 10}}>
                  <View style={{...styles.md_content_container, width:'100%'}}>

                    <Text style={{fontSize:20, fontWeight:600}}>Set Your Date</Text>
                    {UI.box(10)}

                    <View style={{flexDirection:'row'}}>
                      <Layout.DatePicker
                        _this={this.props._this}
                        title={"Start Date"}
                        isRequired={false}
                        placeholder={"mm/dd/yyyy"}
                        state_name={"startDate"}
                        date_props={{
                          maxDate: new Date(),
                        }}
                        will_correct = {true}
                      />
                      {UI.box(10)}

                      <Layout.DatePicker
                        _this={this.props._this}
                        title={"End Date"}
                        isRequired={false}
                        placeholder={"mm/dd/yyyy"}
                        state_name={"endDate"}
                        date_props={{
                          maxDate: new Date(),
                        }}
                        will_correct = {true}
                      />
                    </View>
                    {UI.box(20)}

                    <View style={{ flexDirection: "row", justifyContent:'flex-end', width:'100%' }}>
                      <TouchableOpacity
                        onClick={() => {
                          _this.load_data_by_duration(`set&startDate=${this.props._this.state.startDate}&endDate=${this.props._this.state.endDate}`);
                          this.props._this.setState({startDate:'', endDate:''})
                          UI.clear_forms()
                          _this.hide_modal()
                        }}
                        style={{
                          ...styles.md_button,
                          backgroundColor: UI.colors.secondary ,
                        }}
                      >
                        <Text style={styles.md_btn_text}>Save</Text>
                      </TouchableOpacity>
                      {UI.box(10)}
                      <TouchableOpacity
                        onClick={() => {
                          this.props._this.hide_modal()
                        }}
                        style={{
                          ...styles.md_button,
                          border:'2px solid ' + UI.colors.secondary,
                        }}
                      >
                        <Text style={{...styles.md_btn_text, color: UI.colors.secondary}}>Cancel</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              ) : ''
            }
            <Layout.SortFilter
              btn_1={{
                text: "Year",
                onClick: (text) => {
                  _this.load_data_by_duration("year");
                },
              }}
              btn_2={{
                text: "Month",
                onClick: (text) => {
                  _this.load_data_by_duration("month");
                },
              }}
              btn_3={{
                text: "Set",
                onClick: (text) => {
                  UI.clear_forms()
                  this.props._this.show_modal(
                    <View style={{backgroundColor:'white', borderRadius: 10}}>
                      <View style={{...styles.md_content_container, width:'100%'}}>

                        <Text style={{fontSize:20, fontWeight:600}}>Set Your Date</Text>
                        {UI.box(10)}

                        <View style={{flexDirection:'row'}}>
                          <Layout.DatePicker
                            _this={this.props._this}
                            title={"Start Date"}
                            isRequired={false}
                            placeholder={"mm/dd/yyyy"}
                            state_name={"startDate"}
                            date_props={{
                              maxDate: new Date(),
                            }}
                            will_correct = {true}
                          />
                          {UI.box(10)}

                          <Layout.DatePicker
                            _this={this.props._this}
                            title={"End Date"}
                            isRequired={false}
                            placeholder={"mm/dd/yyyy"}
                            state_name={"endDate"}
                            date_props={{
                              maxDate: new Date(),
                            }}
                            will_correct = {true}
                          />
                        </View>
                        {UI.box(20)}

                        <View style={{ flexDirection: "row", justifyContent:'flex-end', width:'100%' }}>
                          <TouchableOpacity
                            onClick={() => {
                              _this.load_data_by_duration(`set&startDate=${this.props._this.state.startDate}&endDate=${this.props._this.state.endDate}`);
                              _this.hide_modal()
                            }}
                            style={{
                              ...styles.md_button,
                              backgroundColor: UI.colors.secondary ,
                            }}
                          >
                            <Text style={styles.md_btn_text}>Save</Text>
                          </TouchableOpacity>
                          {UI.box(10)}
                          <TouchableOpacity
                            onClick={() => {
                              this.props._this.hide_modal()
                            }}
                            style={{
                              ...styles.md_button,
                              border:'2px solid ' + UI.colors.secondary,
                            }}
                          >
                            <Text style={{...styles.md_btn_text, color: UI.colors.secondary}}>Cancel</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  )
                  // _this.load_data_by_duration("day");
                },
              }}
              btn_4={{
                text: "Day",
                onClick: (text) => {
                  _this.load_data_by_duration("day");
                },
              }}
              onChange={(val) => {
                _this.setState({
                  [`${"sort_filter_"}${"site_stats"}`]: val,
                });
              }}
              _this={_this}
              state_name={"site_stats"}
            />

            {UI.box(10)}
            
            {/* <View style={{...styles.md_content_container, padding:0}}>

              <View id="merchant_datepicker" style={{flexDirection:'row'}}>
                <DatePicker
                  placeholder={"Start Date"}
                  format="MM/DD/YYYY"
                  onChange={(date, datestring) => {
                    this.props._this.setState({startDate: datestring})
                  }}
                />
                {UI.box(10)}
                <DatePicker
                  format="MM/DD/YYYY"
                  placeholder={"End Date"}
                  onChange={(date, datestring) => {
                    this.props._this.setState({endDate: datestring})
                  }}
                />
              {UI.box(10)}
              <View style={{ flexDirection: "row", justifyContent:'flex-end'}}>
                <TouchableOpacity
                  onClick={() => {
                    _this.load_data_by_duration(`set&startDate=${this.props._this.state.startDate}&endDate=${this.props._this.state.endDate}`);
                    _this.hide_modal()
                  }}
                  style={{
                    ...styles.md_button,
                    padding: "7px 15px",
                    backgroundColor: UI.colors.secondary ,
                  }}
                >
                  <Text style={styles.md_btn_text}>Set</Text>
                </TouchableOpacity>
              </View>
            </View>

         
            </View> */}
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={1175} _this={_this}>
            <UI.Row _this={_this} breakpoint={1300}>
              <DashItem
                _this={_this}
                icon={UI.IC_DASH_1}
                num={UI.pad(totalPaidPurchases ?? 0, 3)}
                color={UI.colors.primary}
                text={"Total paid purchases"}
              />
              {UI.box(10)}
              <DashItem
                _this={_this}
                icon={UI.IC_DASH_10}
                num={UI.pad(totalCompletedPurchases ?? 0, 3)}
                color={"#004F99"}
                text={"Total completed purchases"}
              />
            </UI.Row>
            {UI.box(10)}
            <UI.Row _this={_this} breakpoint={1300}>
              <DashItem
                _this={_this}
                icon={UI.IC_DASH_6}
                num={UI.pad(totalCustomers ?? 0, 3)}
                color={"#00EBBE"}
                text={"Total customers"}
              />
              {UI.box(10)}
              <DashItem
                _this={_this}
                icon={UI.IC_DASH_11}
                num={UI.pad(totalMerchantProducts ?? 0, 3)}
                color={"#00EB2D"}
                text={"Total products"}
              />
            </UI.Row>
          </UI.Row>
        </View>
      </UI.Row>
    );
  }
}

const DashItem = (
  props = { _this: null, icon: "", num: "", text: "", color: "" }
) => {
  return (
    <View style={{ flexDirection: "row", flex: 1 }}>
      <Image
        height={60}
        width={60}
        tintColor={"transparent"}
        source={props.icon}
      />

      <View style={{ marginLeft: 15 }}>
        <Text
          style={{ ...styles.main_header, color: props.color, fontSize: 22 }}
        >
          {props.num}
        </Text>
        <Text style={{ ...styles.header_2, fontSize: 12 }}>{props.text}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  md_content_container: {
    padding: 25,
    alignItems: "start",
  },
  md_btn_text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
  md_button: {
    padding: "5px 15px",
    // backgroundColor: "#004F99",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    // paddingTop: 10,
    // paddingBottom: 10,
  },
  sort_filter_text: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#BFBFBF",
  },
  sort_filter: {
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 2,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#C9C9C9",
  },
  box_header_subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#C9C9C9",
    alignSelf: "flex-end",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.secondary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    backgroundColor: "white",
    padding: 20,
    ...Shadow._3(),
    flexDirection: "row",
    maxHeight: 220,
    alignSelf: "flex-start",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
