import React, {useState} from "react";
// import styled from "styled-components";
import Loading from "../../../../modules/_components/Loading";
import Container from "../../../ui/Container";
import ContainerLabelPrimary from "../../../ui/ContainerLabelPrimary";
import AccountDeletion from "./AccountDeletion";

const LeaveRequest = () => {
  const [isScreenLoading, setIsScreenLoading] = useState(false);

  return (
    <>
     <Loading
        visible={isScreenLoading}
        // visible={true}
        title={""}
        text={`Deleting Account...`}
        modal_opaque={false}
      />
      <Container
        style={{ paddingBottom: 0, paddingRight: "157px", width: "100%" }}
      >
        <ContainerLabelPrimary level={5}>
          Account Deletion
        </ContainerLabelPrimary>
        <AccountDeletion onScreenIsLoading={setIsScreenLoading}/>
      </Container>
    </>
  );
};

export default LeaveRequest;
