import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import EcommerceUserGroupSubscriptionRequestListTable from "./EcommerceUserGroupSubscriptionRequestListTable";
// import "./css.css";


export default class EcommerceUserGroupSubscriptionRequestList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Subscription List",
      is_drawer_open: false,
      selected: null,
      context:"merchant"
    });
  }
  
  render() {
    const _this = this;

    return (
      <MainLayout context={this.state.context} additional={"subscription-request"} _this={_this}>
        <EcommerceUserGroupSubscriptionRequestListTable _this={_this}/>
      </MainLayout>
    );
  }
}
