// import interpolate from "interpolate-range";
import React, { Component } from "react";
import { animated, Transition } from "react-spring";
import { Dropdown, Button,
  Typography,
  // Select,
  //  Menu,
    Space } from 'antd';
import NotificationsBell from "../../ComponentsFC/mainlayout/NotificationsBell"
import UI from "../../shared/Components/UI/js";
import ringTone from './ringtone.mp3'
// import Websocket from "../../shared/Components/Websocket";
// import QRCode from "qrcode.react";
import {
  Image,
  mem,
  ScrollView,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import APIConstants from "../../shared/modules/APIConstants";
import {  IncomingCall } from "../onboard-create-user/Instructions";
import ReactAudioPlayer from 'react-audio-player';
import "./css.css";
import Loading from "./Loading";
import Modal, { ComingSoon, CustomSubmittedModal, Failed, QrCodeModal, Success,
  //  SwitchRoleModal 
} from "./Modal";
import ImageViewerModal from "./ImageViewerModal";
import { PatientReferralModal } from "../../ComponentsFC/onboard/referral";
import ServiceUsersApi from "../../api_services/users";
import DoctorsApi from "../../api_services/doctors";
import AutoLogout from "../../ComponentsFC/AutoLogoutApp";
import styled from "styled-components";

const m = 0.35;
const sidebar_width = 300;

// let clickRingtone;
const Constants = new APIConstants();

export default class MainLayout extends Component {
  doctorID;
  doctorStatus;
  ws;
  
  constructor(props) {
    super(props);
    this.state = {
      sidebar_margin: -sidebar_width,
      show_loading: false,
      show_modal: false,
      modal_content: "",
      show_image_viewer_modal: false,
      imageViewerModalContent: "",
      doctor_status:'',

      loading_title: "",
      loading_text: "",
      
      allRole:[],
      role: "",

      is_drawer_open: false,
      date_time: "",

      isTeleconsultActive: true,
      isToggle: false,
      isIncomingCall:false,

      app_content_active: false,
      user_group_active: false,
      user_group_admin_active:false,
      laboratory_services_active: false,
      faq_active: false,
      settings_active:false,
      open_sub:false,
      config_active: false,
      additional: "",
      focused_tab: "",
      modal_opaque: false,
      onboard_data: {
        isDoctorsOnboardingApproved: "",
        remarks: "",
      },
      
      isPlay: false,
      isGenerateQRCode:false,
      userDoctor: JSON.parse(mem.get("user_doctor")),
      isTimeIn:false,

      notificationCount: null,
      isNotificationsRefresh: false,
      isPatientReferralModalOpen: false,
      patientDetails: null,
    };
    this.button = React.createRef()
    this.btn_show_modal_qr_code = React.createRef();
  }

  // componentDidMount() {
  //   this.button.current.click()
  // }

  set_onboard_data = (test) => {
    const data = mem.get("onboard_data");
    if (data) {
      const onboard_data = JSON.parse(data);

      if(onboard_data.isDoctorsOnboardingApproved === "Deleted"){
        this.show_modal(
          <Failed
            title={"Failed"}
            description={"Your account has been deleted."}
            onDismiss={() => {
              this.hide_modal();
              UI.logout();
            }}
          />
        );
      }else{
        this.setState({
          onboard_data,
        });
      }
    }
  };

  show_modal = (modal_content) => {
    this.setState({
      show_modal: true,
      modal_content: modal_content,
    });
  };

  hide_modal = () => {
    this.setState({
      show_modal: false,
      modal_content: "",
    });
  };

  show_image_viewer_modal = (imageContent) => {
    this.setState({
      show_image_viewer_modal: true,
      imageViewerModalContent: imageContent,
    });
  }

  hide_image_viewer_modal = () => {
    this.setState({
      show_image_viewer_modal: false,
      imageViewerModalContent: "",
    });
  }
  
  show_loading = (title, text, isOpaque) => {
    this.setState({
      show_loading: true,
      loading_title: title,
      loading_text: text,
      modal_opaque: isOpaque,
    });
  }; 

  hide_loading = () => {
    this.setState({
      show_loading: false,
    });
  };  

  failed_modal = (message) => {
    this.show_modal(
      <Failed description={message} onDismiss={this.hide_modal} />
    );
  };

  success_modal = (message) => {
    this.show_modal(
      <Failed description={message} onDismiss={this.hide_modal} />
    );
  };

  open_carespan = async () => {
    const _this = this.props._this;
    _this.show_loading();

    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/carespan/sso",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        if (data.url) {
          window.open(data.url, "_blank").focus();
          // window.location.href = data.url;
        }
      },
      onFinish: async () => {
        _this.hide_loading();
      },
    });
  };

  time_interval = clearInterval(() => {}, 1);

  coming_soon = () => {
    this.show_modal(<ComingSoon onCancel={this.hide_modal} />);
  };

  componentDidMount = () => {
    this.props._this.layout = this;

    const _this = this.props._this;

    this.getMwellPlusSubscription();  
    this.set_onboard_data();

    _this.failed_modal = this.failed_modal;
    _this.success_modal = this.success_modal;

    _this.show_loading = this.show_loading;
    _this.hide_loading = this.hide_loading;
    _this.show_modal = this.show_modal;
    _this.hide_modal = this.hide_modal;
    _this.show_image_viewer_modal = this.show_image_viewer_modal;
    _this.hide_image_viewer_modal = this.hide_image_viewer_modal;
    _this.add_resize_function("main_layout", () => {
      if (this.state.is_drawer_open) {
        this.close_drawer();
      }

      if (document.getElementById("main_layout")) {
        _this.setState({
          current_scroll: document.getElementById("main_layout").scrollTop,
        });
      }
    });

    this.update_time();
    this.time_interval = setInterval(() => {
      this.update_time();
    }, 5000);

    this.onboard_logic();

    this.set_app_content();

    // this.mouse_listener();

    if(this.props.context === 'onboard') {
      this.handleSyncOnlineStatusMobileApp();
      this.prepare_doctor_status();
      this.set_toggle_status()//
    }

    const query = new URLSearchParams(this.props._this.props.location.search); // get query string from URL
    if (query.get('isPrcTextREminder')) {
      this.show_modal(
        <Success
          title={"Reminder!"}
          description={query.get('isPrcTextREminder')}
          onDismiss={() => {
            if (query.get('birTextReminder')) {
              this.show_modal(
                <Success
                  title={"Reminder!"}
                  description={query.get('birTextReminder')}
                  onDismiss={() => {
                    // this.props._this.props.history.push("/onboard/dashboard");
                    this.hide_modal();
                  }}
                />
              );
            } else {
              // this.props._this.props.history.push("/onboard/dashboard");
              this.hide_modal();
            }
          }}
        />
      );
    }

    //  MULTIPLE ROLES
    const onboard_data = JSON.parse(mem.get("onboard_data"));
    const admin = JSON.parse(mem.get("admin"));
    const role = mem.get("role");

    if (onboard_data) {
      this.setState({ 
        allRole: onboard_data?.allRole?.length > 0 ? [...onboard_data?.allRole, onboard_data?.role] : [],
        role: role 
      })
    }

    if (admin) {
      this.setState({ 
        allRole: admin?.allRole?.length > 0 ? [...admin?.allRole, admin?.role] : [],
        role: role 
      })
    }

  };

  set_toggle_status = () => {
    const data = JSON.parse(mem.get("user_doctor"));
      
    if (data?.conferenceMode === "ITS") {
      const doctorStatus = mem.get('doctorStatus');

      if (doctorStatus === "online") {
        this.setState({ doctor_status: false });
      } else {
        this.setState({ doctor_status: true });
      }

      
      this.doctor_status_toggle();
    }
  }

  onCreate = async () => {
    this.setState({ doctor_status: false, isTeleconsultActive: true })
  }

  // Added UNSAFE_ to suppress dev tools warning
  UNSAFE_componentWillMount = () => {
    this.get_merchant_details()
  }

  get_merchant_details = async (params, reload) => {
    const role = mem.get(UI.Constants.ROLE);
    if (role === "merchant") {
      this.show_loading();

      UI.webRequest({
        method: "get",
        url: "admin/merchant/me",
        onFail: async (err) => {
          console.log("error:",err);
        },
        onSuccess: async (response) => {
          const data = UI.extract_data(response);
          this.setState({merchant_details: data})
        },
        onFinish: () => {
          this.hide_loading();
        },
      });
    }
  };

  isOnline = false;

  getMwellPlusSubscription = () => {
    try {
      DoctorsApi.getMwellPlusSubscription().then((res) => {
        this.setState({...res}, () => {
          // console.log("mainlayout:",this.state);
        });
      })
    } catch(err) {
      // console.log("error:",err);
    }
  }

  handleSyncOnlineStatusMobileApp = () => {
      setTimeout(() => {
        return ServiceUsersApi.getAuthUser().then(res => {
          const onlineStatus = res?.doctor?.encounter?.communication?.status;
          const role = res?.role;
          if (role === "provider") {
            if (onlineStatus === "Offline") {
              mem.save('doctorStatus', 'offline');
              this.setState({ doctor_status: true })
              if(this.isOnline) {
                clearInterval(this.check_oncall_status_interval);
              }
              this.isOnline = false;
            } else {
              if(!this.isOnline) {
                let doctorStatusOnline = mem.get('doctorStatus');
                let isClick = true;
                if (doctorStatusOnline === "offline") {
                  this.doctor_status_toggle(isClick);
                }
              }
              this.isOnline = true;
            }
            this.handleSyncOnlineStatusMobileApp();
          }
        });
      }, 3000)
      
  }

  check_oncall_status = () => {
    UI.webRequest({
      method: "get",
      url: "doctors_onboarding/appointments/doctor/oncall/patient",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        if(data?.appointment?.appointmentType === "instant" && data?.appointment?.conferenceMode === "ITS") {
          this.setState({ isIncomingCall: true, isPlay: true})
          this.setState({ appointment_id: data.appointment._id });
          mem.save('doctorStatus', 'offline');
        } else {
          this.setState({ appointment_id: null })
          this.setState({ isIncomingCall: false })
        }
      }
    });
  }

  check_oncall_status_interval = setInterval(() => {}, 1000)

  doctor_status_toggle = (isClick=false) => {
    let status = this.state.doctor_status ? 'online' : 'offline';
    if (isClick !== true) {
      status = mem.get('doctorStatus');
    }

    return UI.webRequest({
      method: "put",
      url: "doctors_onboarding/doctors/update-status",
      params: {
        status: status,
      },
      onFail: (err) => {
        if (isClick) {
          const message = err.data?.m;
          this.show_modal(
            <Failed
              title={"Failed"}
              description={message || "Server error please refresh the browser and try again..."}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        }

        const data = JSON.parse(mem.get("user_doctor"));

        if (data?.conferenceMode === "ITS") {
          this.check_oncall_status();
        }

      },
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        const onlineStatus = data?.encounter?.communication?.status?.toLowerCase() || 'offline';

        mem.save('doctorStatus', onlineStatus);
        mem.save('user_doctor', JSON.stringify(data)); 
        console.log(onlineStatus)
        if (onlineStatus === "online") {
          this.check_oncall_status_interval = setInterval(this.check_oncall_status, 3000);
          this.setState({ doctor_status: false });
        } else {
          this.setState({ doctor_status: true });
          clearInterval(this.check_oncall_status_interval);
          if (data?.conferenceMode === "ITS") {
            this.check_oncall_status();
          }
        }

        // if (!this.ws) {
        //   this.ws = new Websocket({
        //     onmessage: function() {
        //       this.ws.send(JSON.stringify({ action: 'update_doctor_to_' + status }));
        //     }
        //   });
        // } else {
        //   this.ws.send(JSON.stringify({ action: 'update_doctor_to_' + status }));
        // }
      }
    });
  };

  prepare_doctor_status = () => {
    if(this.props.context === 'onboard') {
      const data = JSON.parse(mem.get("user_doctor"));
      if (data?.conferenceMode === "ITS") {

        const doctorStatus = mem.get("doctorStatus");
        this.doctorID = data._id;
        
        if (data?.encounter?.communication?.status === "Online") {
          this.setState({ doctor_status: false });
        } else {
          // set offline toggle doctor status and remove memory tagging doctor status
          this.setState({ doctor_status: true });
          this.doctorStatus = doctorStatus === "true" ? true : false;
          // const onClick = false;
          // this.doctor_status_toggle(onClick);
        }
  
      };
    }
  }

  reject_oncall = async function (appointment_id) {
    if(!this.state.appointment_id) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Sorry! You missed this appointment or patient cancelled the appointment."}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
      this.setState({ isIncomingCall: false })
    } else {
      UI.webRequest({
        method: "put",
        url: "doctors_onboarding/appointments/doctor/arrived/"+appointment_id,
        params: {
          action:  "reject",
        },
        onSuccess: async (response) => {
          this.setState({ isIncomingCall: false })
          window.location.href = "/onboard/dashboard"
        }
      });
    }
  }

  accept_call = function () {
    this.setState({ isIncomingCall: false })
    if(!this.state.appointment_id) {
      this.show_modal(
        <Failed
          title={"Failed"}
          description={"Sorry! You missed this appointment or patient cancelled the appointment."}
          onDismiss={() => {
            this.hide_modal();
          }}
        />
      );
    } else {
      window.location.href = "/onboard/encounter?appointment_id="+this.state.appointment_id
    }
  }

  // auto_logout = () => {
  //   this.show_modal(<AutoLogout _this={this} />);
  //   setTimeout(() => {
  //     UI.logout();
  //   }, 60000)
  // };

  // idle_timeout = setTimeout(() => {}, 1);

  // mouse_listener = () => {
  //   const minutes_millis = 30000; //1 = 1min, 15 = 15min
  //   let timeout = minutes_millis * 60 * 1000;

  //   const el = document.getElementById("main_layout_root");

  //   this.idle_timeout = setTimeout(this.auto_logout, timeout);
  //   el.onmousemove = () => {
  //     if (this.idle_timeout) {
  //       clearTimeout(this.idle_timeout);
  //     }
  //     this.mouse_listener()
  //   };
  // };

  set_app_content = () => {
    let props = this.props;
    let { context, additional } = props;

    if (
      context === "admin" &&
      ["homescreen", "specialties", "hospitals", "lgu-partners", "medical-associations"].includes(additional)
    ) {
      this.setState({
        app_content_active: true,
      });
    }
    if (context === "admin" && ["categories", "faq"].includes(additional)) {
      this.setState({
        faq_active: true,
      });
    }
    if (context === "onboard" && ["deactivate-account","delete-account"].includes(additional)) {
      this.setState({
        settings_active: true,
      });
    }
    if (
      context === "admin" &&
      ["maintenance_alert", "force_update"].includes(additional)
    ) {
      this.setState({
        config_active: true,
      });
    }
    // if (
    //   context === "admin" &&
    //   ["user_group_admin", "user_group_list", "add_user_list"].includes(additional)
    // ) {
    //   this.setState({
    //     user_group_active: true,
    //   });
    // }

    if (
      context === "merchant" &&
      ["subscriptions", "subscription-request", "usergroup-list", "billing"].includes(additional)
    ) {
      this.setState({
        user_group_active: true,
      });
    }

    if (
      context === "usergroupadmin" &&
      [ "user_group_list", "add_user_list"].includes(additional)
    ) {
      this.setState({
        user_group_admin_active: true,
      });
    }

    if (
      context === "marketing" &&
      [ "main-banners", "registration-popup","mind-health","mwellness-score"].includes(additional)
    ) {
      this.setState({
        app_content_active: true,
      });
    }

    if (
      context === "merchant" &&
      [ "categories_list", "services_list"].includes(additional)
    ) {
      this.setState({
        laboratory_services_active: true,
      });
    }
    
    this.setState({
      additional,
    });
  };

  onboard_logic = () => {
    let onboard_type = UI.get_param("onboard_type");
    this.setState({
      onboard_type: onboard_type,
    });
  };

  componentWillUnmount = () => {
    clearInterval(this.time_interval);
  };

  update_time = async () => {
    this.setState({
      date_time: UI.getTimezone({ format: "MMMM D, YYYY | h:mm a" }),
    });
  };

  open_drawer = () => {
    this.show_drawer();
  };

  set_timeIn = (isClick=false) =>{
    this.show_loading();
    UI.webRequest({
      method: "put",
      url: "doctors/add-timelogs?type=in",
      onFail: (err) => {
        if (isClick) {
          const message = err.data?.m;
          this.show_modal(
            <Failed
              title={"Failed"}
              description={message || "Server error please refresh the browser and try again..."}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        }
      },
      onSuccess: async (response) => {
        this.setState({
          isTimeIn: true,
        })
        this.hide_loading();
      }
    });
  }

  show_timeout = setTimeout(() => {}, 10);

  show_drawer = () => {
    let margin = this.state.sidebar_margin;
    if (margin < 0) {
      this.show_timeout = setTimeout(() => {
        this.setState(
          {
            is_drawer_open: true,
            sidebar_margin: margin + 20,
          },
          () => {
            this.show_drawer();
          }
        );
      }, 1);
    } else {
      clearTimeout(this.show_timeout);
    }
  };

  close_drawer = () => {
    this.hide_drawer();
  };

  hide_timeout = setTimeout(() => {}, 10);

  hide_drawer = () => {
    let margin = this.state.sidebar_margin;
    if (margin > -sidebar_width) {
      this.hide_timeout = setTimeout(() => {
        this.setState(
          {
            is_drawer_open: false,
            sidebar_margin: margin - 50,
          },
          () => {
            this.hide_drawer();
          }
        );
      }, 1);
    } else {
      clearTimeout(this.hide_timeout);
    }
  };

  downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${this.state.onboard_data?.doctor?._id}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  render() {
    const _this = this.props._this;
    const { 
      // height,
       width, isMobile } = _this.state;
    const data = JSON.parse(mem.get("onboard_data"));
    const doctor = JSON.parse(mem.get("user_doctor"));

    let { context } = this.props;
    // let drawer_bg_style = {};

    // const bg_op = interpolate({
    //   inputRange: [-300, 0],
    //   outputRange: [0, 0.5],
    //   clamp: true,
    // });
    // const bg_opacity = bg_op(this.state.sidebar_margin);

    if (isMobile) {
      if (this.state.is_drawer_open) {
        // drawer_bg_style = {
        //   position: "absolute",
        //   top: 0,
        //   left: 0,
        //   backgroundColor: "rgba(0,0,0,1)",
        //   height: "100%",
        //   width: "100%",
        //   opacity: bg_opacity,
        // };
      }
    }

    let date_font_size = 14;
    if (width <= 420) {
      date_font_size = 12;
    }
    const {
      show_loading,
      loading_title,
      loading_text,
      show_modal,
      modal_opaque,
      show_image_viewer_modal,
    } = this.state;

    const role = mem.get(UI.Constants.ROLE);
    const merchantLogoImage = this.state.merchant_details?.logoPath ? `${Constants.STORAGE_BASE_URL}?path=${this.state.merchant_details?.logoPath}` : UI.TRANSACTION_USER 

    return (
      <AutoLogout _this={this}>
        <View id={"main_layout_root"} style={{ ...styles.main_container }}>
        
          { this.state.isIncomingCall && <IncomingCall _this={this}/> } 
          
          <Loading
            visible={show_loading}
            title={loading_title}
            text={loading_text}
            modal_opaque={modal_opaque}
          />
          <Modal _this={this} visible={show_modal} />
          <ImageViewerModal _this={this} visible={show_image_viewer_modal} />
          
          <SideBar _this={_this} context={this} ctx={context} role={role} />

          {
            this.state.isPlay === true ?  <ReactAudioPlayer
              src={ringTone}
              autoPlay
              loop
              style={ { display: 'none'} }
              controls
            /> : ""
          }

          <View style={{ flex: 1 }}>
                  
            <UI.Row
              breakpoint={840}
              _this={_this}
              style={styles.header_container}
            >
              <View style={styles.menu_container}>

                <TouchableOpacity
                  onClick={this.open_drawer}
                  style={{
                    ...styles.menu_btn,
                    display: isMobile ? "flex" : "none",
                  }}
                >
                  <Image
                    height={25}
                    width={25}
                    tintColor={UI.colors.white}
                    source={UI.MENU}
                  />
                </TouchableOpacity>
                {UI.box(10)}

              
              {
                role === 'provider' && doctor?.conferenceMode === "ITS" && (data?.isDoctorsOnboardingApproved === "Approved" || data?.isAdvocacyOnboardingApproved?.step3.status === "Approve" || data?.isAdvocacyOnboardingApproved?.step3.status === "Approved") &&
                <View style={styles.doctor_status} className="font-semibold w-44">
                  {UI.box(5)}
                  <div className="flex justify-center"> 
                    <div className="form-check form-switch flex gap-2 items-center">
                      <label className="form-check-label inline-block text-white" htmlFor="toggle_switch">Doctor Status</label>
                      {
                        !this.state.doctor_status ? 
                        <>
                          <img src={UI.ON} alt="" className="cursor-pointer" height={30} width={30} onClick={ () => this.doctor_status_toggle (true)}/>
                          <span style={{color:'#33D347'}}>Online</span>
                        </>:
                        <>
                          <div className="cursor-pointer bg-gray-200 flex items-center rounded-full" style={{userSelect:'none', height:'15px', width:'30px'}} onClick={() => this.doctor_status_toggle (true)}>
                            <div className="bg-white w-3 rounded-full" style={{userSelect:'none', height:'12px', marginLeft:'3px'}}></div>
                          </div>
                          <span className="text-gray-400">Offline</span>
                        </>
                      }
                    </div>
                  </div>
                {UI.box(5)}
                </View>
              }
              </View>
              {/* {role === 'provider' && doctor?.conferenceMode === "ITS" && (data?.isDoctorsOnboardingApproved === "Approved" || data?.isAdvocacyOnboardingApproved?.step3.status === "Approve" || data?.isAdvocacyOnboardingApproved?.step3.status === "Approved") &&
              <View>
                    <div className="flex justify-between">
                      <button onClick={this.set_timeIn} disabled={this.state.isTimeIn ? true : false } className="flex items-center px-2 py-2 text-whiteap-2 font-semibold mx-1 w-max" style={{ backgroundColor:'#87ceeb', color:'#ffffff', bottom:0, right:0, borderRadius:'5px'}}>
                        Time In
                      </button>
                      <button onClick={() => {}} disabled={this.state.isTimeIn ? false : true } className="flex items-center px-2 py-2 text-white gap-2 font-semibold mx-1 w-max" style={{ backgroundColor:'#87ceeb', color:'#ffffff', bottom:0, right:0, borderRadius:'5px'}}>
                        Time Out
                      </button>
                    </div>
                </View>
              } */}
              <View style={{
                ...styles.menu_container, 
                display: !isMobile ? "flex" : "none",
              }}>
                {UI.box(5)}
                <Text
                  style={{ ...styles.header_text, fontSize: date_font_size }}
                >
                  Today is: {this.state.date_time}
                </Text>
              </View>
              {/* {UI.box(10)} */}

              {role === "merchant" && (
                <UserImage
                  text={"Merchant"}
                  user_name={this.state.merchant_details ?? ""}
                  image={merchantLogoImage}
                  width={width}
                  _this={this}
                />
              )}
              {role === "admin" && (
                <UserImage
                  text={"Admin"}
                  image={UI.TRANSACTION_USER}
                  width={width}
                  _this={this}
                />
              )}
              {role === "voucher" && (
                <UserImage
                  text={"Voucher Admin"}
                  image={UI.TRANSACTION_USER}
                  width={width}
                  _this={this}
                />
              )}
              {role === "advocacy" && (
                <UserImage
                  text={"Advocacy"}
                  image={UI.TRANSACTION_USER}
                  width={width}
                  _this={this}
                />
              )}
              {role === "finance" && (
                <UserImage
                  text={"Finance"}
                  image={UI.TRANSACTION_USER}
                  width={width}
                  _this={this}
                />
              )}
              {role === "provider" && (
                <UserImage
                  text={"Doctor"}
                  image={UI.DOCTOR_PROFILE}
                  width={width}
                  _this={this}
                  // style={{ cursor: 'pointer'}}
                />
            //     </Space>
            // </Dropdown>
              )}
              {role === "usergroupadmin" && (
                <UserImage
                  text={"User Group Admin"}
                  image={UI.TRANSACTION_USER}
                  width={width}
                  _this={this}
                />
              )}
            </UI.Row>
            <ScrollView
              onScroll={(scroll) => {
                _this.setState(
                  {
                    current_scroll: scroll,
                  },
                  () => {
                    if (_this.execute_scrolls) {
                      _this.execute_scrolls(_this);
                    }
                  }
                );
              }}
              id={"main_layout"}
              style={{ padding: 20 }}
            >
              {this.props.children}
            </ScrollView>
          </View>
        </View>
      </AutoLogout>
    );
  }
}

const PopoverContent = ({ user_data }) => {
  return (
    user_data?.isDoctorsOnboardingApproved === "Approved" ?
      <View
      style={{
        borderRadius: '10px',
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        backgroundColor: "white"
      }}
    >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor: "rgba(67, 200, 255, 0.5)",
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
            padding: 10,
            cursor: 'pointer'

          }}
        >
          <Image
            height={20}
            width={20}
            marginRight={5}
            tintColor={UI.colors.secondary}
            source={UI.CURRENT}
          />
          {UI.box(5)}
          <Text style={{
            ...styles.header_text, 
            color: UI.colors.secondary
          }}>
            Currently on: mWell Basic
          </Text>
        </div>

        <div
            // onClick={() => {
            //   UI.goTo('https://mwellmd.com.ph');
            // }}
          onClick={() => { window.location.href = `${Constants.MWELLMD_WEBSITE_URL}/login?token=${mem.get('jwt_token')}`}}
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            padding: 10,
            cursor: 'pointer'
          }}
        >
        <Image
          height={20}
          width={20}
          marginRight={5}
          tintColor={UI.colors.secondary}
          source={UI.DOC}
        />
        {UI.box(5)}
          <Text style={{
            ...styles.header_text, 
            color: UI.colors.secondary            
          }}
          > Switch to mWellMD</Text>
        </div>
      </View>
      :
      <></>
)}

const UserImage = (props = { text: "", user_name: "", image: "", width: 0, style: {} }) => {
  const { width, 
    // text,
     image, user_name } = props;
  const data = mem.get("onboard_data");
  const admin = JSON.parse(mem.get("admin"));
  const role = mem.get(UI.Constants.ROLE);
  const user_data = JSON.parse(data);

  let name = "User";
  if (data) {

    // name = user_data.name || "Dr " + user_data.firstName + " " + user_data.lastName;
    name = user_data.name || "Dr " + UI.DataHelper.carespan.get_name(user_data.doctor?.name)
  }

  if (admin) {
    if (role === "merchant") {
      name = user_name?.merchantName ? user_name?.merchantName : admin.name
    } else {
      name = admin.name;
    }
  }

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        alignSelf: width > 840 ? "center" : "flex-start",
        justifyContent: "space-around",
        ...props.style
      }}
    >
      { role === "provider" &&
        <div 
          ref={props._this.btn_show_modal_qr_code}   
          onClick={ () => {
          props._this.show_modal(
            <QrCodeModal _this={props._this} name={name} isGenerateQRCode={props._this.state.isGenerateQRCode}/>)
        }
        }>
          <img src={UI.QR_CODE_JOIN} alt="QR_CODE_BG" height={40} width={40} style={{ background:'white', padding:5, borderRadius: '8px', cursor:'pointer', marginRight:'10px'}}/>
        </div>
      }
      {
        role === "provider" && (user_data?.isAdvocacyOnboardingApproved?.step3?.status === "Approved" || user_data?.isAdvocacyOnboardingApproved?.step3?.status === "Approve") && 
        <>
          {
            (user_data.isDoctorsOnboardingApproved === "Pending" || user_data.isDoctorsOnboardingApproved === "Disapproved") &&

            <TouchableOpacity style={{display:'block', marginRight:10}}>
              <button onClick={() => {
                if  (user_data.isDoctorsOnboardingApproved === "Approved" || user_data.isDoctorsOnboardingApproved === "Approve") {
                  props._this.show_modal(
                    <CustomSubmittedModal 
                      descriptionOne="You have completed your advocacy consultation."
                      onOk={{
                        text:'Return to Dashboard',
                        method: () => {
                          props._this.hide_modal();
                          window.location.href = `${Constants.WEB_HOST_NAME_URL}/onboard/dashboard`
                          // UI.goTo('/onboard/dashboard')
                        },
                      }}
                    />
                  );
                } else {
                  props._this.show_modal(
                    <CustomSubmittedModal 
                      headerTitle="Be an mWell Doctor Today!"
                      descriptionOne="Thank you for taking part in National mWellness Day! You have helped bring healthcare closer to more Filipinos by volunteering your time and service."
                      descriptionTwo="If you would like to have your own virtual clinic for your private practice with mWell, just click the link below to add your consultation fee, input your bank details, and sign the MOA."
                      // descriptionTwo="If you would like to have your own private virtual clinic with mWell for your private practice, just click the link below to complete your profile details."
                      onOk={{
                        text:'Yes, I want to be an mWell Doctor',
                        method: () => {
                          props._this.hide_modal();
                          UI.goTo('/onboard/user-profile')
                        },
                      }}
                    />
                  );
                }
              
              }} className="flex items-center px-3 py-2 text-white gap-2 font-semibold" style={{ backgroundColor:'#43C8FF', bottom:0, right:0, borderRadius:'5px'}}>
                Be an mWell Doctor
              </button>
            </TouchableOpacity>
          }
       </>
      }
      {
        role === "provider"  &&
        <>
          <OnboardHeaderButton
            onClick={() => {
              // window.location.href = `https://internal.mwell-web-portal.highlysucceed.com/advocacy/advocacy/${user_data.isDoctorsOnboardingApproved === "Approved"}`
              window.location.href = `${Constants.MWELL_WEBSITE_URL}/advocacy/${user_data.isDoctorsOnboardingApproved === "Approved" ? "mwelldoctor" :""}`
              // window.location.href = `${Constants.MWELL_WEBSITE_URL}/advocacy`
            }}
          >
            Join our Advocacy
            <Image customClassName={"button-icons"} width={25} height={20} source={UI.ICON_STARS} tintColor={"yellow"} />
          </OnboardHeaderButton>
          <OnboardHeaderButton
            className='switch-to-advanced'
            onClick={() => {
              window.location.href = `${Constants.MWELLMD_WEBSITE_URL}/login?token=${mem.get('jwt_token')}`;
            }}
          >
            <Image customClassName={"button-icons"} width={25} height={20} source={UI.ICON_CROWN} tintColor={"yellow"} />
            Switch to mWellMD Advanced
          </OnboardHeaderButton>
          {/* <TouchableOpacity style={{display:'block', marginRight:10}}>
            <button onClick={() => {
              
              // window.location.href = `https://internal.mwell-web-portal.highlysucceed.com/advocacy/advocacy/${user_data.isDoctorsOnboardingApproved === "Approved"}`
              window.location.href = `${Constants.MWELL_WEBSITE_URL}/advocacy/${user_data.isDoctorsOnboardingApproved === "Approved" ? "mwelldoctor" :""}`
              // window.location.href = `${Constants.MWELL_WEBSITE_URL}/advocacy`
            }} className="flex items-center px-3 py-2 text-white gap-2 font-semibold" style={{ backgroundColor:'#43C8FF', bottom:0, right:0, borderRadius:'5px'}}>
              Join Advocacy
            </button>
          </TouchableOpacity> */}
        </>
      }
      
      {/* {
        admin && admin.allRole.length > 0 && 
        <TouchableOpacity style={{display:'block', marginRight:10}}>
          <button className="flex items-center px-3 py-2 text-white gap-2 font-semibold" style={{ backgroundColor:'#43C8FF', bottom:0, right:0, borderRadius:'5px'}}
            onClick={() => { 
                props._this.show_modal(<SwitchRoleModal _this={props._this}/>)
              } 
            }
          >
            Switch Role
          </button>
        </TouchableOpacity>
      } */}

      {/* {
        user_data && user_data.allRole.length > 0 && 
        <TouchableOpacity style={{display:'block', marginRight:10}}>
          <button className="flex items-center px-3 py-2 text-white gap-2 font-semibold" style={{ backgroundColor:'#43C8FF', bottom:0, right:0, borderRadius:'5px'}}
            onClick={() => { 
                props._this.show_modal(<SwitchRoleModal _this={props._this}/>)
              } 
            }
          >
            Switch Role
          </button>
        </TouchableOpacity>
      } */}

      {
      role !== "merchant" && role !== "provider" &&
        <img
          style={{
            height: 20,
            width: 20,
            source: UI.BELL
          }}
          src={UI.BELL}
          alt="notif bell"
        />
      }

    {role === "provider" && 
      <>
        <NotificationsBell 
          onPatientReferralModalOpen={(e) => 
            props._this.setState({isPatientReferralModalOpen: true, patientDetails: e}
            )}
          isRefresh={props._this.state.isNotificationsRefresh}
          resetRefreshNotificationsBell={() => props._this.setState({isNotificationsRefresh: false})}
        />
        <PatientReferralModal 
          open={props._this.state.isPatientReferralModalOpen}
          onChangeOpenPatientReferral={() => 
            props._this.setState({isPatientReferralModalOpen: false, patientDetails: null})
          } 
          data={props._this.state.patientDetails}
          refreshNotificationsBell={() => props._this.setState({isNotificationsRefresh: true})}
        />
      </>
      }
     
     {UI.box(5)}
     {UI.box(5)}
      <Text style={styles.header_text}>Kamusta ka, {name}</Text>
      {UI.box(20)}
      {
        role === "provider" ?
        <Dropdown
          placement="bottomRight"
          overlay={<PopoverContent user_data={user_data}/>} trigger="click"
        >
          <Space>
          <img style={{ height: 30, width: 30, borderRadius: '50%', cursor: 'pointer'}} src={image} alt="doctor_icon"/>
          </Space>
        </Dropdown> 
        : role === "merchant" ? 
          <div 
            // style={{background:'white', padding:2, borderRadius:3}}
          >
            <img
              style={{
                height: 30,
                width: 30,
                source: image, 
                borderRadius: '10%'
              }}
              src={image}
              alt="merchant"
            />
          </div>
        :
        <img style={{ height: 30, width: 30, borderRadius: '10%'}} src={image} alt="transaction_icon" />
      }
      {UI.box(5)}
      {UI.box(20)}
    </View>
  );
};

const SideBar = (props = { _this: null, ctx: "", role: "" }) => {
  const _this = props._this;
  const context = props.context;
  const context_state = context.state;
  const role = props.role;
  const doctor = JSON.parse(mem.get("user_doctor"));
  const doctor_onboard_data = JSON.parse(mem.get("onboard_data"));
  // const admin = JSON.parse(mem.get("admin"));

  const {
    app_content_active,
    // laboratory_services_active,
    additional,
    faq_active,
    config_active,
    is_drawer_open,
    settings_active,
    open_sub,
    // user_group_active,
    // user_group_admin_active
    user_group_active,
    user_group_admin_active,
    freeTrial
  } = context_state;
  const { height, width, isMobile, isTeleconsultActive,
    // onboard_data 
  } = _this.state;
 
  const { isDoctorsOnboardingApproved } = context.state.onboard_data;

  let sidebar_style = {
    width: sidebar_width,
    ...styles.side_bar,
    height: height,
    zIndex: 10000,
    padding: 0
  };

  if (isMobile) {
    sidebar_style = {
      width: sidebar_width,
      ...styles.side_bar,
      height: height ?? "100%",
      position: "absolute",
      // height: "100%",
      zIndex: 10000,
    };
  }
  /*   if (isMobile) {
    sidebar_style = {
      width: sidebar_width,
      ...styles.side_bar,
      height: height,
      position: "absolute",
      height: "100%",
      left: context_state.sidebar_margin,
      zIndex: 10000,
    };
  } */
  
  // if(context.state.onboard_data.isAdvocacyOnboardingApproved?.step3?.status === "Approved") {
  //   context.setState({
  //     onboard_data:  context.state.onboard_data.isDoctorsOnboardingApproved = "Approved"
  //   })
  // }

  let will_show_dashboard = true;
  if (role === "provider") {
    if(context.state.onboard_data.isAdvocacyOnboardingApproved?.step3?.status === "Approved" || context.state.onboard_data.isAdvocacyOnboardingApproved?.step3?.status === "Approve") {
      context.state.onboard_data.isDoctorsOnboardingApproved = "Approved"
    }
    if (
      doctor_onboard_data?.isDoctorsOnboardingApproved !== "Approved" 
      // || isDoctorsOnboardingApproved !== "Approved"
    ) {
      will_show_dashboard = false;
    }
  }

  const EXCLUDE_DASHBOARD = ["legal", "usergroupadmin", "marketing"];

  let ctx = props.ctx;
  const sidebar_content = (
    <ScrollView style={{ padding: 10, height: _this.state.height }}>
      <View style={{ alignSelf: "center", marginTop: 10 }}>
        <Image
          height={90 * m}
          width={537 * m}
          tintColor={"transparent"}
          source={UI.METRO_WELL}
        />
      </View>

      {["onboard"].includes(ctx) && (
        <View style={styles.top_left_nav}>
          <OnboardHeaderButton
            icon={<img width={25} height={20} src={UI.ICON_TELECONSULTATION} alt='teleconsultation' />}
            className={ 'top-left-nav teleconsult-active'}
            onClick={() => {
              window.location.href = `${Constants.WEB_HOST_NAME_URL}/login?token=${mem.get('jwt_token')}`;
            }}
            checked={isTeleconsultActive}
            defaultChecked
          >
            Teleconsult
          </OnboardHeaderButton>
          <OnboardHeaderButton
            icon={<img width={25} height={20} src={UI.ICON_EMS} alt='ems' />}
            className='top-left-nav'
            onClick={() => {
              window.location.href = `${Constants.MWELLMD_WEBSITE_URL}/login?token=${mem.get('jwt_token')}`;
            }}
            checked={!isTeleconsultActive}
          >
            EMR
          </OnboardHeaderButton>
        </View>
      )}

      {will_show_dashboard && (
        <View style={{ marginTop: 30, marginBottom: 20 }}>
          <Text style={styles.title}>Dashboard</Text>
        </View>
      )}

      {will_show_dashboard && !EXCLUDE_DASHBOARD.includes(role) && (
        <View>
        <NavBtn
          _this={_this}
          text={"Dashboard"}
          icon={UI.HOME_1}
          onClick={() => {
            UI.goTo("/" + ctx + "/dashboard");
          }}
        />
          </View>
      )}

      {[
        "onboard",
        "transaction",
        // "marketing",
        "doctor",
        "finance",
        "merchant",
        // "usergroupadmin"
      ].includes(ctx) && (
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Text style={styles.title}>Navigation Panel</Text>
        </View>
      )}

      {["voucher"].includes(ctx) && (
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Text style={styles.title}>Voucher CMS</Text>
        </View>
      )}

      {["voucher"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Health Pass Dashboard"}
            icon={UI.HOME}
            onClick={() => {
              UI.goTo("/" + ctx + "/healthpass-dashboard");
            }}
          />
        </View>
      )}

      {["voucher"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Membership Requests"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/usergroup-dashboard");
            }}
          />
        </View>
      )}

      {["voucher"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Voucher List"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/voucher-list");
            }}
          />
        </View>
      )}

      {["voucher"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Campaign List"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/campaign-list");
            }}
          />
        </View>
      )}

      {/* {["voucher"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Doctor Group List"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/doctorgroup-list");
            }}
          />
        </View>
      )} */}

      {/* {["voucher"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"User Group List"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/usergroup-list");
            }}
          />
        </View>
      )} */}

      {["voucher"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Health Pass List"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/healthpass-list");
            }}
          />
        </View>
      )}

      {["voucher"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Health Pass History"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/healthpass-history-list");
            }}
          />
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Text style={styles.title}>User Management</Text>
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Patient Accounts"}
            icon={UI.USER}
            onClick={() => {
              UI.goTo("/" + ctx + "/patient-accounts");
            }}
          />
          <NavBtn
            _this={_this}
            text={"Doctor Accounts"}
            icon={UI.USER}
            onClick={() => {
              UI.goTo("/" + ctx + "/doctor-accounts");
            }}
          />
          {/* <NavBtn
            _this={_this}
            text={"User Group List"}
            icon={UI.USER}
            onClick={() => {
              UI.goTo("/" + ctx + "/usergroup-list");
            }}
          /> */}
          <NavBtn
            _this={_this}
            text={"Doctor File Repository"}
            icon={UI.USER}
            onClick={() => {
              UI.goTo("/" + ctx + "/doctor-file-repository");
            }}
          />
          <NavBtn
            _this={_this}
            text={"User Group List"}
            icon={UI.USER}
            onClick={() => {
              UI.goTo("/" + ctx + "/usergroup-list");
            }}
            is_active={user_group_active}
          />
         
          <View
            style={{
              padding: 10,
              display: user_group_active ? "flex" : "none",
            }}
          >
            <TouchableOpacity
              onClick={() => {
                UI.goTo("/" + ctx + "/usergroup-admin");
              }}
              style={styles.small_btn_padding}
            >
              <Text
                style={{
                  ...styles.additional_btn,
                  color:
                    additional === "user_group_admin" ? UI.colors.primary : "#bfbfbf",
                }}
              >
                User Group Admin
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onClick={() => {
                UI.goTo("/" + ctx + "/usergroup-list");
              }}
              style={styles.small_btn_padding}
            >
              <Text
                style={{
                  ...styles.additional_btn,
                  color:
                    additional === "user_group_list" ? UI.colors.primary : "#bfbfbf",
                }}
              >
                User Group List
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onClick={() => {
                UI.goTo("/" + ctx + "/usergroup-add-user-list");
              }}
              style={styles.small_btn_padding}
            >
              <Text
                style={{
                  ...styles.additional_btn,
                  color:
                    additional === "add_user_list" ? UI.colors.primary : "#bfbfbf",
                }}
              >
                Add User List
              </Text>
            </TouchableOpacity>
          </View>

          <NavBtn
            _this={_this}
            text={"Merchant List"}
            icon={UI.USER}
            onClick={() => {
              UI.goTo("/" + ctx + "/merchant-list");
            }}
          />
          <NavBtn
            _this={_this}
            text={"Hospital Admins"}
            icon={UI.USER}
            onClick={() => {
              UI.goTo("/" + ctx + "/hospital-admins");
            }}
          />
          <NavBtn
            _this={_this}
            text={"DOH Stats"}
            icon={UI.USER}
            onClick={() => {
              UI.goTo("/" + ctx + "/doh-stats");
            }}
          />
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Text style={styles.title}>CMS Management</Text>
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"App Content"}
            icon={UI.FILE}
            onClick={() => {
              context.setState({
                app_content_active: !context.state.app_content_active,
              });
            }}
            is_active={app_content_active}
          />

          <View
            style={{
              padding: 10,
              display: app_content_active ? "flex" : "none",
            }}
          >
            <TouchableOpacity
              onClick={() => {
                console.log(additional)
                UI.goTo("/" + ctx + "/cms-specialties");
              }}
              style={styles.small_btn_padding}
            >
              <Text
                style={{
                  ...styles.additional_btn,
                  color:
                    additional === "specialties" ? UI.colors.primary : "#bfbfbf",
                }}
              >
                Specialties
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onClick={() => {
                UI.goTo("/" + ctx + "/cms-hospitals");
              }}
              style={styles.small_btn_padding}
            >
              <Text
                style={{
                  ...styles.additional_btn,
                  color:
                    additional === "hospitals" ? UI.colors.primary : "#bfbfbf",
                }}
              >
                Hospitals
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onClick={() => {
                UI.goTo("/" + ctx + "/cms-medical-associations");
              }}
              style={styles.small_btn_padding}
            >
              <Text
                style={{
                  ...styles.additional_btn,
                  color:
                    additional === "medical-associations" ? UI.colors.primary : "#bfbfbf",
                }}
              >
                Medical Association
              </Text>
            </TouchableOpacity>
            {/* <TouchableOpacity
              onClick={() => {
                UI.goTo("/" + ctx + "/cms-lgu-partners");
              }}
              style={styles.small_btn_padding}
            >
              <Text
                style={{
                  ...styles.additional_btn,
                  color:
                    additional === "lgu-partners" ? UI.colors.primary : "#bfbfbf",
                }}
              >
                LGU partners
              </Text>
            </TouchableOpacity> */}
            <TouchableOpacity
              onClick={() => {
                UI.goTo("/" + ctx + "/cms-lgu-partners");
              }}
              style={styles.small_btn_padding}
            >
              <Text
                style={{
                  ...styles.additional_btn,
                  color:
                    additional === "lgu-partners" ? UI.colors.primary : "#bfbfbf",
                }}
              >
                LGU partners
              </Text>
            </TouchableOpacity>
          </View>

          <NavBtn
            _this={_this}
            text={"Waiting Room CMS"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/waiting-room");
            }}
          />
          <NavBtn
            _this={_this}
            text={"FAQs"}
            icon={UI.FILE}
            onClick={() => {
              context.setState({
                faq_active: !context.state.faq_active,
              });
            }}
            is_active={faq_active}
          />
          <View
            style={{
              padding: 10,
              display: faq_active ? "flex" : "none",
            }}
          >
            <TouchableOpacity
              onClick={() => {
                UI.goTo("/" + ctx + "/faq-categories");
              }}
              style={styles.small_btn_padding}
            >
              <Text
                style={{
                  ...styles.additional_btn,
                  color:
                    additional === "categories" ? UI.colors.primary : "#bfbfbf",
                }}
              >
                Categories
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onClick={() => {
                UI.goTo("/" + ctx + "/faq-items");
              }}
              style={styles.small_btn_padding}
            >
              <Text
                style={{
                  ...styles.additional_btn,
                  color: additional === "faq" ? UI.colors.primary : "#bfbfbf",
                }}
              >
                FAQ Items
              </Text>
            </TouchableOpacity>
          </View>
          <NavBtn
            _this={_this}
            text={"Terms & Conditions"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/terms-and-conditions");
            }}
          />
          <View
            style={{
              padding: 10,
              display: config_active ? "flex" : "none",
            }}
          ></View>
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Text style={styles.title}>Laboratory Management</Text>
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Laboratory List"}
            icon={UI.USER}
            onClick={() => {
              UI.goTo("/" + ctx + "/laboratory-list");
            }}
          />
          <NavBtn
            _this={_this}
            text={"Transaction"}
            icon={UI.COMMERCE_PRODUCTS}
            onClick={() => {
              UI.goTo("/" + ctx + "/laboratory-transaction-list");
            }}
            // is_active={context.state.additional == "store-merchant-list"}
          />
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Text style={styles.title}>Store Management</Text>
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Transactions"}
            icon={UI.COMMERCE_PRODUCTS}
            onClick={() => {
              UI.goTo("/" + ctx + "/transactions");
            }}
            // is_active={context.state.additional == "store-merchant-list"}
          />
        </View>
      )}
      {["admin"].includes(ctx) && (
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Text style={styles.title}>Appointment Management</Text>
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Appointments"}
            icon={UI.CALENDAR_3}
            onClick={() => {
              UI.goTo("/" + ctx + "/appointments");
            }}
          />
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Doctor's Attendance"}
            icon={UI.CALENDAR_3}
            onClick={() => {
              UI.goTo("/" + ctx + "/doctor-attendance");
            }}
          />
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Daily Time Report"}
            icon={UI.CALENDAR_3}
            onClick={() => {
              UI.goTo("/" + ctx + "/doctor-dtr");
            }}
          />
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Doctors On Call Availability"}
            icon={UI.CALENDAR_3}
            onClick={() => {
              UI.goTo("/" + ctx + "/doctors-on-call-availability");
            }}
          />
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Patients Refund"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/refund");
              window.location.reload()
            }}
          />
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Text style={styles.title}>Data Management</Text>
        </View>
      )}

      
      {["admin"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"UDB"}
            icon={"/img/cms_file_icon.svg"}
            onClick={() => {
              UI.goTo("/" + ctx + "/udb");
            }}
          />
        </View>
      )}

      
      {["admin"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Diskartech"}
            icon={"/img/cms_file_icon.svg"}
            onClick={() => {
              UI.goTo("/" + ctx + "/diskartech");
            }}
          />
        </View>
      )}

      {["usergroupadmin"].includes(ctx) && (
        <View>
           <NavBtn
              _this={_this}
              text={"User Group List"}
              icon={UI.USER}
              onClick={() => {
                UI.goTo("/" + ctx + "/usergroup-list");
              }}
          
              is_active={user_group_admin_active}
            />
        </View>
      )}
      
      {["advocacy"].includes(ctx) && (
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Text style={styles.title}>CMS Management</Text>
        </View>
      )}

      {["advocacy"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Advocacy List"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/list");
            }}
          />
        </View>
      )}

      {["advocacy"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Transactions"}
            icon={UI.CALENDAR_3}
            onClick={() => {
              UI.goTo("/" + ctx + "/transactions");
            }}
          />
        </View>
      )}

    {["advocacy"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Reports"}
            icon={UI.SETTINGS}
            onClick={() => {
              UI.goTo("/" + ctx + "/reports");
            }}
          />
        </View>
      )}

      {/* {["advocacy"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Transactions"}
            icon={UI.CALENDAR_3}
            onClick={() => {
              UI.goTo("/" + ctx + "/doctors-on-call-availability");
            }}
          />
        </View>
      )}

      {["advocacy"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Reports"}
            icon={UI.CALENDAR_3}
            onClick={() => {
              UI.goTo("/" + ctx + "/doctors-on-call-availability");
            }}
          />
        </View>
      )} */}

      {/*       {["admin"].includes(ctx) && (
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Text style={styles.title}>Finance Management</Text>
        </View>
      )}

      {["admin"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Payments & Disbursements"}
            icon={UI.CALENDAR_3}
            onClick={() => {
              UI.goTo("/" + ctx + "/disbursements");
            }}
          />
        </View>
      )} */}

      {["merchant"].includes(ctx) && (
        <View>
           {/* <NavBtn
            _this={_this}
            text={"Product Categories"}
            icon={UI.COMMERCE_PRODUCTS}
            onClick={() => {
              UI.goTo("/" + ctx + "/categories-list");
            }}
          /> */}
          <NavBtn
            _this={_this}
            text={"Products"}
            icon={UI.COMMERCE_PRODUCTS}
            onClick={() => {
              UI.goTo("/" + ctx + "/products");
            }}
          />
          <NavBtn
            _this={_this}
            text={"Inventory"}
            icon={UI.COMMERCE_INVENTORY}
            onClick={() => {
              UI.goTo("/" + ctx + "/inventory");
            }}
          />
          <NavBtn
            _this={_this}
            text={"Transactions"}
            icon={UI.COMMERCE_TRANSACTIONS}
            onClick={() => {
              UI.goTo("/" + ctx + "/transactions");
            }}
          />
          <NavBtn
            _this={_this}
            text={"Customers"}
            icon={UI.COMMERCE_INQUIRY}
            onClick={() => {
              UI.goTo("/" + ctx + "/customers");
            }}
          />
          {/* {
            admin?.isMwellMerchant &&
            <View>
              <NavBtn
                _this={_this}
                text={"User Group List"}
                icon={UI.FILE}
                onClick={() => {
                  context.setState({
                    user_group_active: !context.state.user_group_active,
                  });
                }}
                is_active={user_group_active}
              />
              <View
                style={{
                  padding: 10,
                  display: user_group_active ? "flex" : "none",
                }}
              >
                <TouchableOpacity
                  onClick={() => {
                    UI.goTo("/" + ctx + "/usergroup-list");
                  }}
                  style={styles.small_btn_padding}
                >
                  <Text
                    style={{
                      ...styles.additional_btn,
                      color:
                        additional === "usergroup-list" ? UI.colors.primary : "#bfbfbf",
                    }}
                  >
                    Group List
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onClick={() => {
                    UI.goTo("/" + ctx + "/user-group-subscriptions");
                  }}
                  style={styles.small_btn_padding}
                >
                  <Text
                    style={{
                      ...styles.additional_btn,
                      color:
                        additional === "subscriptions" ? UI.colors.primary : "#bfbfbf",
                    }}
                  >
                    Subscription List
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onClick={() => {
                    UI.goTo("/" + ctx + "/user-group-subscription-request-list");
                  }}
                  style={styles.small_btn_padding}
                >
                  <Text
                    style={{
                      ...styles.additional_btn,
                      color:
                        additional === "subscription-request" ? UI.colors.primary : "#bfbfbf",
                    }}
                  >
                    Subscription Request
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onClick={() => {
                    UI.goTo("/" + ctx + "/user-group-subscription-billing");
                  }}
                  style={styles.small_btn_padding}
                >
                  <Text
                    style={{
                      ...styles.additional_btn,
                      color:
                        additional === "billing" ? UI.colors.primary : "#bfbfbf",
                    }}
                  >
                    Billing
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          } */}
          
          <NavBtn
            _this={_this}
            text={"Account"}
            icon={UI.PROFILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/account");
            }}
          />
          {/* <NavBtn
            _this={_this}
            text={"Inquiries"}
            icon={UI.COMMERCE_INQUIRY}
            onClick={() => {
              UI.goTo("/" + ctx + "/inquiries");
            }}
          /> */}
        </View>
      )}
      {["finance"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Doctors Masterfile"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/doctors-masterfile");
            }}
          />
          <NavBtn
            _this={_this}
            text={"Doctors Transactions"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/doctors-transactions");
            }}
          />
          <NavBtn
            _this={_this}
            text={"Patients Refund"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/refund");
              window.location.reload()
            }}
          />
          <NavBtn
            _this={_this}
            text={"Doctors Disbursement"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/doctors-disbursement");
            }}
          />
          <View>
            <NavBtn
              _this={_this}
              text={"User Group"}
              icon={UI.FILE}
              onClick={() => {
                context.setState({
                  user_group_active: !context.state.user_group_active,
                });
              }}
              is_active={user_group_active}
            />

            <View
              style={{
                padding: 10,
                display: user_group_active ? "flex" : "none",
              }}
            >
              <TouchableOpacity
                onClick={() => {
                  console.log(additional)
                  UI.goTo("/" + ctx + "/user-group-subscriptions");
                }}
                style={styles.small_btn_padding}
              >
                <Text
                  style={{
                    ...styles.additional_btn,
                    color:
                      additional === "subscriptions" ? UI.colors.primary : "#bfbfbf",
                  }}
                >
                  Subscriptions
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onClick={() => {
                  UI.goTo("/" + ctx + "/user-group-subscription-request-list");
                }}
                style={styles.small_btn_padding}
              >
                <Text
                  style={{
                    ...styles.additional_btn,
                    color:
                      additional === "subscription-request" ? UI.colors.primary : "#bfbfbf",
                  }}
                >
                  Subscription Request
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onClick={() => {
                  UI.goTo("/" + ctx + "/user-group-subscription-billing");
                }}
                style={styles.small_btn_padding}
              >
                <Text
                  style={{
                    ...styles.additional_btn,
                    color:
                      additional === "billing" ? UI.colors.primary : "#bfbfbf",
                  }}
                >
                  Billing
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )}
      {["doctor"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Telemed"}
            icon={UI.MESSAGE}
            onClick={() => {
              UI.goTo("/" + ctx + "/telemed");
            }}
          />
          <NavBtn
            _this={_this}
            text={"Calendar"}
            icon={UI.CALENDAR_3}
            onClick={() => {
              UI.goTo("/" + ctx + "/calendar");
            }}
          />
          <NavBtn
            _this={_this}
            text={"Finance"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/finance");
            }}
          />
          <NavBtn
            _this={_this}
            text={"Settings"}
            icon={UI.SETTINGS}
            onClick={() => {
              UI.goTo("/" + ctx + "/settings");
            }}
          />
        </View>
      )}
      {["marketing"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Registered Referrals"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/registered-referrals");
            }}
          />
          <NavBtn
            _this={_this}
            text={"Patient Referrals"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/patient-referrals");
            }}
          />
           <NavBtn
            _this={_this}
            text={"Doctor Referrals"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/doctor-referrals");
            }}
          />
          <NavBtn
            _this={_this}
            text={"Promo Winners"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/promo-winners");
            }}
          />
          {/* <NavBtn
            _this={_this}
            text={"Campaign Referrals"}
            icon={UI.FILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/campaign-referrals");
            }}
          /> */}

          <View style={{ marginTop: 20, marginBottom: 20 }}>
              <Text style={styles.title}>CMS Management</Text>
          </View>

          <NavBtn
            _this={_this}
            text={"App Content"}
            icon={UI.FILE}
            onClick={() => {
              context.setState({
                app_content_active: !context.state.app_content_active,
              });
            }}
            is_active={app_content_active}
          />

          <View
            style={{
              padding: 10,
              display: app_content_active ? "flex" : "none",
            }}
          >
            <TouchableOpacity
              onClick={() => {
                console.log(additional)
                UI.goTo("/" + ctx + "/main-banners");
              }}
              style={styles.small_btn_padding}
            >
              <Text
                style={{
                  ...styles.additional_btn,
                  color:
                    additional === "main-banners" ? UI.colors.primary : "#bfbfbf",
                }}
              >
                Main Banners
              </Text>

            </TouchableOpacity>
            <TouchableOpacity
              onClick={() => {
                UI.goTo("/" + ctx + "/registration-popup");
              }}
              style={styles.small_btn_padding}
            >
              <Text
                style={{
                  ...styles.additional_btn,
                  color:
                    additional === "registration-popup" ? UI.colors.primary : "#bfbfbf",
                }}
              >
                Registration Pop Up
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onClick={() => {
                UI.goTo("/" + ctx + "/mind-health");
              }}
              style={styles.small_btn_padding}
            >
              <Text
                style={{
                  ...styles.additional_btn,
                  color:
                    additional === "mind-health" ? UI.colors.primary : "#bfbfbf",
                }}
              >
                Mind Health
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onClick={() => {
                UI.goTo("/" + ctx + "/mwellness-score");
              }}
              style={styles.small_btn_padding}
            >
              <Text
                style={{
                  ...styles.additional_btn,
                  color:
                    additional === "mwellness-score" ? UI.colors.primary : "#bfbfbf",
                }}
              >
                mWellness Score
              </Text>
            </TouchableOpacity>
          </View>
        </View>

      )}
      {["transaction"].includes(ctx) && (
        <View>
          <NavBtn
            _this={_this}
            text={"Booked Consults"}
            icon={UI.CALENDAR_3}
            onClick={() => {
              UI.goTo("/" + ctx + "/booked-consults");
            }}
          />
          <NavBtn
            _this={_this}
            text={"On-Call Consults"}
            icon={UI.CALENDAR_3}
            onClick={() => {
              UI.goTo("/" + ctx + "/oncall-consults");
            }}
          />
        </View>
      )}
      {["onboard"].includes(ctx) && (
        <View>
          { isDoctorsOnboardingApproved === "Approved" && (doctor?.conferenceModeCL === "Carespan" || doctor?.conferenceMode === "Carespan") && (
            <View>
              <NavBtn
                _this={_this}
                text={"Consultation"}
                icon={UI.CALENDAR_3}
                onClick={() => {
                  context.open_carespan();
                  //UI.goTo("/" + ctx + "/consultation");
                }}
              />
            </View>
          )}

          

          { (  doctor?.conferenceModeCL === "ITS") &&
              (
              
              <View>
                {
                  doctor_onboard_data?.isDoctorsOnboardingApproved === "Approved" && 
                  <NavBtn
                    _this={_this}
                    text={"Availability"}
                    icon={UI.AVAILABILITY_1}
                    onClick={() => {
                      UI.goTo("/" + ctx + "/availability");
                    } } />
                }

                {
                  (context.state.onboard_data.isAdvocacyOnboardingApproved || doctor_onboard_data?.isDoctorsOnboardingApproved === "Approved") && 
                    <NavBtn
                      _this={_this}
                      text={"Calendar"}
                      icon={UI.CALENDAR_5}
                      onClick={() => {
                        UI.goTo("/" + ctx + "/calendar");
                    } } />
                }

                {
                  doctor_onboard_data?.isDoctorsOnboardingApproved === "Approved" && 
                    <NavBtn
                      _this={_this}
                      text={"Referral"}
                      icon={UI.PROFILE}
                      onClick={() => {
                        UI.goTo("/" + ctx + "/referral");
                      }} 
                    />
                }
               
              </View>
          )}
          { (context.state.onboard_data.isAdvocacyOnboardingApproved?.step3?.status === "Approved" || context.state.onboard_data.isAdvocacyOnboardingApproved?.step3?.status === "Approve") &&
              <View>
                <NavBtn
                _this={_this}
                text={"Advocacy Availability"}
                icon={UI.AVAILABILITY_1}
                onClick={() => {
                  UI.goTo("/" + ctx + "/advocacy-availability");
                } } />
              </View>
          }
         
          {
            (doctor_onboard_data?.isDoctorsOnboardingApproved === "Approved" || !context.state.onboard_data.isAdvocacyOnboardingApproved) && (doctor_onboard_data?.doctor?.isMwellPlus === true || doctor_onboard_data?.doctor?.isCounselor === true) &&
              <NavBtn
                _this={_this}
                text={"Attendance"}
                icon={UI.DP_NAV_ATTENDANCE}
                onClick={() => {
                  UI.goTo("/" + ctx + "/attendance");
                }}
              />
          }

          <NavBtn
            _this={_this}
            text={"Notification Hub CMS"}
            icon={UI.PROFILE}
            onClick={() => {
              UI.goTo("/" + ctx + "/notification");
            }}
          />
          
          {
            (doctor_onboard_data?.isDoctorsOnboardingApproved === "Approved" || !context.state.onboard_data.isAdvocacyOnboardingApproved) &&
              <NavBtn
                _this={_this}
                text={"Tax"}
                icon={UI.PROFILE}
                onClick={() => {
                  UI.goTo("/" + ctx + "/tax");
                }}
              />
          }

          {
              (doctor_onboard_data?.isDoctorsOnboardingApproved === "Approved" || !context.state.onboard_data.isAdvocacyOnboardingApproved) &&
                <NavBtn
                  _this={_this}
                  text={"User Profile"}
                  icon={UI.PROFILE}
                  onClick={() => {
                    UI.goTo("/" + ctx + "/user-profile");
                  }}
                />
            } 

          {/* <NavBtn
            _this={_this}
            text={"Settings"}
            icon={UI.SETTINGS}
            onClick={() => {
              UI.goTo("/" + ctx + "/settings/delete-account");
            }}
          /> */}
                    <View>
                      <NavBtn
                      _this={_this}
                      text={"Settings"}
                      icon={UI.SETTINGS_DP}
                      onClick={() => {
                        context.setState({
                          open_sub: !context.state.open_sub,
                        });
                      }}
                      is_active={settings_active}
                      />
                      
                      <View
                      style={{
                        padding: 10,
                        display: open_sub || settings_active ? "flex" : "none",
                      }}>
                        {/* <TouchableOpacity
                          onClick={() => {
                            context.setState({
                              settings_active: !context.state.settings_active,
                            });
                            console.log(context.state.open_sub);
                            UI.goTo("/" + ctx + "/settings/deactivate-account");
                          }} 
                          style={styles.small_btn_padding}
                        >
                          <Text
                            style={{
                              ...styles.additional_btn,
                              fontSize: "1rem",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              color: additional === "deactivate-account" ? "#004F99" : "#bfbfbf",
                            }}
                          >
                            Deactivate Account
                          </Text>
                        </TouchableOpacity> */}
                        <TouchableOpacity
                          onClick={() => {
                            context.setState({
                              settings_active: !context.state.settings_active,
                            });
                            UI.goTo("/" + ctx + "/settings/delete-account");
                          }} 
                          style={styles.small_btn_padding}
                        >
                          <Text
                            style={{
                              ...styles.additional_btn,
                              fontSize: "1rem",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              color:  additional === "delete-account" ? "#004F99" :"#bfbfbf",
                            }}
                          >
                            Delete Account
                          </Text>
                        </TouchableOpacity>
                      </View>
                  </View>

          
           {/* {isDoctorsOnboardingApproved == "Approved" && (
            <NavBtn
              _this={_this}
              text={"Nutrition Program"}
              isDisabled={true}
              icon={UI.FILE}
              onClick={() => {
                //UI.goTo("/" + ctx + "/consultation");
              }}
            />
          )} */}
        </View>
      )}

      <View style={{ flex: 1 }}>
        {
          ctx === "onboard" &&
          <View className="flex justify-end h-full">
            <h1 className="font-semibold text-yellow-500 text-xl">Reminders</h1>
            <Text style={{color:'rgb(191, 191, 191)', padding:"5px 10px", fontSize:'0.875rem'}}>Doctors are required to sign in again if the session became idle for at least 15 minutes.</Text>
            <Text style={{color:'rgb(191, 191, 191)', padding:"5px 10px", fontSize:'0.875rem'}}>Account will be locked out after 5 failed sign-in attempts. We recommend that you change your password to avoid being locked out.</Text>
            <Text style={{color:'rgb(191, 191, 191)', padding:"5px 10px", fontSize:'0.875rem'}}>Password expires after 90 days. You will be required to change password.</Text>
          </View>
        }
      </View>
      <View style={{ marginTop: 30 }}>
        <NavBtn
          _this={_this}
          text={"Change Password"}
          icon={UI.PASSWORD_ICON}
          onClick={() => {
            // mem.clear();
            UI.goTo(`/onboard/forget-password-step-1`)
            // UI.change_password(_this);
          }}
          is_active={true}
        />
        <NavBtn
          _this={_this}
          text={"Sign Out"}
          icon={UI.LOGOUT}
          onClick={() => {
            UI.logout();
          }}
          is_active={true}
          color={"#CC0404"}
        />
      </View>
      {
        (mem.get("role") === 'provider') &&
        <View style={styles.expiring}>
          <img style={{width: 65, height: 65, marginRight: 10}} src={UI.ICON_MWELL_MD} alt='mwell-md' />
          {/* TO-DO: Dynamic display of remaining days */}
          <SmallText>
            Your current mWellMD Advanced will expire in {freeTrial} days.
            <br />
            <BorderlessButton 
              onClick={() => {
                window.location.href = `${Constants.MWELLMD_WEBSITE_URL}/login?token=${mem.get('jwt_token')}`;
              }}
            >
              Learn More
            </BorderlessButton>
          </SmallText>
        </View>
      }
    </ScrollView>
  );

  if (isMobile) {
    return (
      <Transition
        items={is_drawer_open}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
        delay={0}
        config={{ duration: 200 }}
      >
        {(style, item) => {
          if (item) {
            return (
              <animated.div style={{ ...style }}>
                <View
                  style={{
                    height: height,
                    width: width,
                    backgroundColor: "rgba(0,0,0,0.75)",
                    position: "absolute",
                    flexDirection: "row",
                  }}
                >
                  <Transition
                    items={is_drawer_open}
                    from={{ marginLeft: -sidebar_width }}
                    enter={{ marginLeft: 0 }}
                    leave={{ marginLeft: -sidebar_width }}
                    delay={180}
                    config={{ duration: 100 }}
                  >
                    {(style, item) => {
                      return (
                        <animated.div style={style}>
                          <View style={{ ...sidebar_style }}>
                            {sidebar_content}
                          </View>
                        </animated.div>
                      );
                    }}
                  </Transition>
                  <View
                    onClick={() => {
                      context.close_drawer();
                    }}
                    style={{ flex: 1 }}
                  ></View>
                </View>
              </animated.div>
            );
          }
        }}
      </Transition>
    );
  }

  return <View style={sidebar_style}>{sidebar_content}</View>;
};

const NavBtn = (
  props = {
    _this: null,
    text: "",
    icon: "",
    onClick: () => {},
    is_active: false,
    isDisabled:false,
    color: UI.colors.primary,
  }
) => {
  let is_active = false;
  let active_tab = props._this.state.active_tab;
  const { _this } = props;
  active_tab === props.text ? (is_active = true) : (is_active = false);

  if (props.is_active) {
    is_active = props.is_active;
  }
  let bg_color = is_active ? UI.colors.secondary : "white";

  if (props.color) {
    bg_color = props.color;
  }
  let isFocused = false;

  let focused_tab = "";
  if (_this.layout) {
    focused_tab = _this.layout.state.focused_tab;

    if (focused_tab === props.text) {
      isFocused = true;
    }
  }

  const nav_item = (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        pointerEvents: "none",
      }}
    >
      {is_active && (
        <Image height={25} width={25} tintColor={"white"} source={props.icon} />
      )}
      {!is_active && (
        <Image
          height={25}
          width={25}
          tintColor={"#BFBFBF"}
          source={props.icon}
        />
      )}
      {UI.box(10)}
      <Text
        style={{
          ...styles.nav_btn_text,
          color: is_active ? "white" : "#BFBFBF",
        }}
      >
        {props.text}
      </Text>
    </div>
  );
  if (isFocused) {
    return (
      <Transition
        items={isFocused}
        from={{ paddingLeft: 0 }}
        enter={{ paddingLeft: 20 }}
        leave={{ paddingLeft: 0 }}
      >
        {(style, item) => (
          <animated.div
            onMouseOut={() => {
              setTimeout(() => {
                if (isFocused) {
                  _this.layout.setState({
                    focused_tab: "",
                  });
                }
              }, 10);
            }}
            onClick={!props.isDisabled && props.onClick}
            style={{
              cursor: `${props.isDisabled ? 'not-allowed' : 'pointer'}`,
              display: "inline-flex",
              userSelect: "none",
              ...styles.nav_btn,
              backgroundColor: bg_color,
              ...style,
            }}
          >
            {nav_item}
          </animated.div>
        )}
      </Transition>
    );
  } else {
    return (
      <div
        onMouseOver={() => {
          if (!isFocused) {
            _this.layout.setState({
              focused_tab: props.text,
            });
          }
        }}
      >
        <TouchableOpacity
          onClick={props.onClick}
          style={{
            ...styles.nav_btn,
            backgroundColor: bg_color,
          }}
        >
          {nav_item}
        </TouchableOpacity>
      </div>
    );
  }
};

const styles = StyleSheet.create({
  small_btn_padding: {
    paddingLeft: 45,
    paddingTop: 10,
    paddingBottom: 10,
  },
  additional_btn: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#bfbfbf",
  },
  menu_container: {
    display:'flex',
    flexDirection:'row',
    alignItems: "center",
    // justifyContent:'space-between'
    // justifyContent:'center',
    // flexDirection: "row",
    // alignItems: "center",
    // flex: 1,
    // alignSelf: "flex-end",
    // width: "100%",
  },
  menu_btn: {
    height: 40,
    width: 40,
    justifyContent: "center",
    alignItems: "center",
  },
  doctor_status: {

  },
  header_container: {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    padding: 10,
    width: "100%",
    ...Shadow._3(),
    backgroundColor: "rgb(0, 79, 153)",
    marginBottom: 20
  },
  header_text: {
    fontWeight: "bold",
    color: "white",
  },
  nav_btn_text: {
    fontSize: 16,
    color: "white",
    fontWeight: "bold",
  },
  nav_btn: {
    width: "100%",
    height: 50,
    backgroundColor: UI.colors.primary,
    borderRadius: 5,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    padding: 10,
    marginBottom: 10,
  },  
  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.primary,
  },
  side_bar: {
    backgroundColor: "white",
    ...Shadow._3(),
  },
  main_container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#F7F7F7",
    flexDirection: "row",
  },
  md_content_container: {
    padding: 25,
    alignItems: "start",
  },
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: "#00CBFF",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
  top_left_nav: {
    display: "inline-flex",
    flexDirection: "row",
    margin: "20px 0 0",
    justifyContent: "center",
    alignItems: "center",
  },
  expiring: {
    display: "inline-flex",
    flexDirection: "row",
    padding: "5px 10px",
    backgroundColor: "#EFEFEF",
    justifyContent: "center",
    alignItems: "center",
  }
});

// ----- antd styles ----- //
const OnboardHeaderButton = styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #43C8FF;
  border-radius: 5px;
  border-width: 0;
  color: #FFF;
  font-family: Poppins;
  font-weight: 500;
  margin-left: 5px;
  font-size: 0.9rem;

  &.top-left-nav {
    background-color: #FFF;
    color: #035099;
    border-radius: 5px 5px 0 0;
    height: 50px;
    margin: 0;

    &.teleconsult-active,
    &:hover {
      background-color: #035099;
      color: #FFF;

      img {
        filter: brightness(100);
      }
    }

    img {
      margin: 0 10px 0 0;
    }
  }

  &.switch-to-advanced {
    margin: 0 5px;
    background-color: white;
    color: rgb(0, 79, 153);

    .button-icons {
      margin: 0 5px 0 0;
    }
  }

  .button-icons {
    margin-top: 1px;
    margin-left: 5px;
  }
`;

const SmallText = styled(Typography.Text)`
  font-family: Poppins;
  font-size: 0.875rem;
`;

const BorderlessButton = styled(Button)`
  font-family: Poppins;
  font-size: 0.875rem;
  background: none;
  height: auto;
  width: auto;
  border: none;
  padding: 0;
  margin: 0;
  color: #035099;

  span {
    text-decoration: underline !important;
  }

  &:hover {
    background-color: inherit;
  }
`;