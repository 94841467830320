// import moment from "moment";
import { Button } from "antd";
import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  ImageBackground,
  ImageFit,
  mem,
  // Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import CustomDatatable from "../_components/CustomDatatable";
import { DownloadPDF } from "../_components/CustomPDF";
// import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import {
  // Failed,
  Success,
} from "../_components/Modal";
// import "./css.css";

const context = "admin";

export default class AdminLaboratoryTransactionView extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Transaction",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
      orderStatus: "",

      item: {
        _id: "",
        product: {
          _id: "",
          name: "",
          price: "",
          imagePath: "",
        },
        merchant: null,
        user: {
          _id: "",
          name: "",
          firstName: "",
          lastName: "",
          email: "",
          id: "",
        },
        method: "",
        referenceNumber: "",
        paymentId: "",
        currency: "",
        amount: "",
        quantity: "",
        status: "",
        createdAt: "",
        __v: 0,
      },

      is_this_gift: "",
      recipient_name: "",
      recipient_email_address: "",
      recipient_mobile_number: "",
      recipient_date_of_birth: "",

      customer_image: UI.TRANSACTION_USER,

      product_image: UI.PRODUCT_BOX,

      is_rendering: false,
    });
  }

  onCreate = async () => {
    const data = mem.get("laboratory_transaction_view");

    if (data) {
      const transaction = JSON.parse(data);
      this.setState(
        {
          item: transaction,
          orderStatus: transaction.orderStatus,
          testResults: transaction?.laboratoryResultPath ?? []
        },
        this.load_data
      );
    }
  };

  load_data = async () => {
    const { item } = this.state;

    setTimeout(() => {
      this.reload_table("transaction");
    }, 100);

    this.customer(item);
    this.product(item);
  };

  customer = async (item) => {
    const image = await UI.download_image(
      item.user?.imagePath,
      "ecommerce",
      UI.TRANSACTION_USER
    );
    this.setState({
      customer_image: image,
    });
    console.log("my state:",this.state.item);
  };
  product = async (item) => {
    const image = await UI.download_image(
      item.product?.imagePath,
      "ecommerce",
      UI.PRODUCT_BOX
    );
    this.setState({
      product_image: image,
    });
  };

  download = async (type) => {
    this.show_loading("", "Processing Request...", true);
    this.setState(
      {
        is_rendering: true,
      },
      () => {
        UI.execute_resizes(this);
        setTimeout(() => {
          DownloadPDF(
            "main_transaction_content",
            "Transaction_" + this.state.item.referenceNumber,
            type
          );
          this.hide_loading();
          this.setState(
            {
              is_rendering: false,
            },
            () => {
              setTimeout(() => {
                UI.execute_resizes(this);
              }, 100);
              setTimeout(() => {
                UI.execute_resizes(this);
              }, 200);
              setTimeout(() => {
                UI.execute_resizes(this);
              }, 300);
            }
          );
        }, 300);
      }
    );
  };


  deleteTransaction = (item) => {
    this.show_loading();
    UI.webRequest({
      method: "delete",
      url: "admin/transactions/" + item,
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        this.show_modal(
          <Success
            title={"Success!"}
            description={"Successfully delete the transaction."}
            onDismiss={() => {
              UI.goTo("/merchant/transactions");
              this.hide_modal();
            }}
          />
        );
      },
      onFinish: () => {
        // this.onCreate();
      },
    });
  };

  render() {
    const _this = this;
    const {
      item,
      is_rendering,
    } = _this.state;

    const price = parseInt(item.amount ?? 0);
    const transactionFee = parseInt(item.transactionFee ?? 0);
    const discount = parseInt(item.discount ?? 0);
    const tax = parseInt(item.vat_amount ?? 0);

    const fixed_style = is_rendering
      ? {
          width: 874.54541015625,
          height: 539.84375,
          flex: null,
          // ...Shadow._0(),
        }
      : {};

    const getTotal = () => {
      return (transactionFee + price + tax) - discount;
    };

    const getLabAddress = () => {
      const formDataLabAddress = `${item?.formDataLaboratory?.street ?? " "} ${
        item?.formDataLaboratory?.brgyOrTown ?? " "
      } ${item?.formDataLaboratory?.cityOrMunicipality ?? " "} ${
        item?.formDataLaboratory?.region ?? " "
      } ${item?.formDataLaboratory?.postalCode ?? " "}`;
      return formDataLabAddress
    }
  
    return (
      <MainLayout context={context} _this={_this}>
        <UI.Row breakpoint={1220} _this={_this} style={{ width: "100%" }}>
          <View
            id={"main_transaction_content"}
            style={{
              ...styles.main_box_content,
              flex: 1,
              padding: 0,
              ...fixed_style,
            }}
          >
            <UI.Row
              _this={_this}
              style={{
                width: "100%",
                padding: 20,
                justifyContent: "space-between",
              }}
              breakpoint={is_rendering ? 10 : 930}
            >
              <ImageBackground
                style={{ height: 65, width: 202 }}
                source={UI.MWELL_LOGO}
                imageFit={ImageFit.CONTAIN}
              />
              {UI.box(10)}

              <View style={{ alignItems: "flex-end" }}>
                <Text
                  style={{ ...styles.box_header_text, textAlign: "center" }}
                >
                  Transaction {item.referenceNumber}
                </Text>

                <View style={styles.transaction_date_container}>
                  <Text style={styles.transaction_date}>
                    Transaction Date:{" "}
                    {UI.get_date_string_by_date(item.createdAt)}
                  </Text>
                </View>
              </View>
            </UI.Row>

            <View style={styles.border_line}></View>

            <UI.Row
              style={{ width: "100%", padding: 20 }}
              _this={_this}
            >
              <View
                id={"sample"}
                style={{ flexDirection: "row", alignItems: "center", gap: 30, flex: 1 }}
              >
                <View>
                  <Text
                    style={{ ...styles.box_header_text }}
                  >
                    Laboratory Test Details
                  </Text>

                  {UI.box(10)}

                  <UserDetail
                    icon={UI.INFO_HOSPITAL}
                    name={"Clinic/Hospital"}
                    value={item.merchant?.name ?? "None"}
                    is_rendering={is_rendering}
                  />
                   <UserDetail
                   icon={UI.AVAILABILITY}
                      name={"Booking Schedule"}
                      value={
                        item.formDataLaboratory?.preferredSchededule
                          ? new Intl.DateTimeFormat('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                              timeZone: 'UTC',
                            }).format(
                              new Date(item.formDataLaboratory?.preferredSchededule)
                            )
                          : "None"
                      }
                      is_rendering={is_rendering}
                  />
                  <UserDetail
                    icon={UI.INFO_SOURCE}
                    name={"Booking Type"}
                    value={item.formDataLaboratory?.laboratoryTestHeldType ?? "None"}
                    is_rendering={is_rendering}
                  />
                  <UserDetail
                    icon={UI.INFO_SOURCE}
                    name={"Patient Type"}
                    value={item?.formDataLaboratory?.relationship || "None"}
                    is_rendering={is_rendering}
                  />
                  <UserDetail
                    icon={UI.INFO_SOURCE}
                    name={"Patient Name"}
                    value={(`${item?.formDataLaboratory?.firstName} ${item?.formDataLaboratory?.middleName} ${item?.formDataLaboratory?.lastName}`)}
                    is_rendering={is_rendering}
                  />
                  <UserDetail
                    icon={UI.INFO_SOURCE}
                    name={"Address"}
                    value={getLabAddress()}
                    is_rendering={is_rendering}
                  />
                  <UserDetail
                    icon={UI.INFO_SOURCE}
                    name={"Contact Number"}
                    value={item?.formDataLaboratory?.mobileNumber || "None"}
                    is_rendering={is_rendering}
                  />
                  
                </View>
                <View style={{ flex: 1 }}></View>
                 <View>
                    <UserDetail
                      // icon={UI.PAYMENT_METHOD}
                      name={"Order ID"}
                      value={item.referenceNumber}
                      is_rendering={is_rendering}
                    />
                    <UserDetail
                      // icon={UI.BOOKING_DATE}
                      name={"Date Purchased"}
                      value={moment(item.createdAt).format("MM/DD/YYYY")}
                      // value={UI.get_date_string_by_date(item.createdAt)}
                      is_rendering={is_rendering}
                    />
                    <UserDetail
                      // icon={UI.CONSULT_PRICE}
                      name={"Total Paid"}
                      value={"Php " + UI.pad(item.amount, 3, true)}
                      is_rendering={is_rendering}
                    />
                    <UserDetail
                      // icon={UI.PAYMENT_METHOD}
                      name={"Payment Method"}
                      value={item.method}
                      is_rendering={is_rendering}
                    />
                    <UserDetail
                      // icon={UI.PAYMENT_METHOD}
                      name={"Payment Status"}
                      value={UI.get_transaction_status_text(item) ?? ""}
                      is_rendering={is_rendering}
                    />
                    {/* {!this.state.is_rendering && (
                      <Layout.SelectPicker
                        _this={_this}
                        title={"Order Status"}
                        isRequired={false}
                        onChange={(e) => {
                          if (!e) {
                            return true;
                          }
                          if (e === "Cancelled") {
                            return this.cancelOrderStatus(e)
                          }
                          this.changeOrderStatus(e)
                        }}
                      >
                        <option value="">
                          Change Order Status
                        </option>
                        <option
                          value="Confirmed"
                          selected={this.state.orderStatus === "Confirmed"}
                        >
                          Confirmed
                        </option>
                        <option
                          value="Completed"
                          selected={this.state.orderStatus === "Completed"}
                        >
                          Completed
                        </option>
                        <option
                          value="Cancelled"
                          selected={this.state.orderStatus === "Cancelled"}
                        >
                          Cancelled{" "}
                        </option>
                      </Layout.SelectPicker>
                    )} */}
                  </View>
         
              </View>
            </UI.Row>


            <CustomDatatable
              state_name={"transaction"}
              _this={_this}
              columns={[
                { "Product Name": "product_name" },
                { "Product SKU": "product_sku" },
                { Quantity: "quantity" },
                { "Unit Price": "unit_price" },
                { "Total Price": "price" },
              ]}
              data={[
                {
                  ids: item._id ?? "--",
                  product_name: item.product?.name ?? "--",
                  product_sku: item.product?.sku ?? "--",
                  quantity: "x" + (item.quantity ?? "--"),
                  unit_price: item.product?.price ?? "--",
                  price: "Php " + UI.pad(item.amount, 3, true),
                  order_status: this.state.orderStatus
                    ? this.state.orderStatus
                    : item.orderStatus ?? " ",
                },
              ]}
              current_page={1}
              number_of_pages={20}
              on_page={(page, callback) => {}}
              disable_pagination={true}
            />
            <View style={styles.border_line}></View>

            <View style={{ width: "100%", alignItems: "flex-end" }}>
              <View
                style={{
                  padding: 7,
                  // paddingTop: !this.state.is_rendering && 0,
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View>
                    <Text style={styles.receipt_legend}>Price: </Text>
                    <Text style={styles.receipt_legend}>Service fee: </Text>
                    <Text style={styles.receipt_legend}>Discount: </Text>
                    <Text style={styles.receipt_legend}>Tax: </Text>
                    {UI.box(10)}
                  </View>
                  {UI.box(20)}
                  <View style={{ minWidth: 50, alignItems: "flex-end" }}>
                    <Text style={{ fontWeight: 'bold'}}>
                      {"Php " + UI.pad(price, price.toString().length, true)}
                    </Text>
                    <Text style={{ fontWeight: 'bold'}}>
                      {"Php " + UI.pad(transactionFee, transactionFee.toString().length, true)}
                    </Text>
                    <Text style={{ fontWeight: 'bold'}}>
                      {"Php " + UI.pad(discount, discount.toString().length, true)}
                    </Text>
                    <Text style={{ fontWeight: 'bold'}}>
                      {"Php " + UI.pad(tax, tax.toString().length, true)}
                    </Text>
                  </View>
                </View>

                <View style={styles.total_border_line}></View>

                <View style={{...styles.total_container}}>
                  <Text style={styles.total_text}>TOTAL</Text>
                  <Text style={styles.total_text_value}>
                    {"Php " + UI.pad(getTotal() ?? 0, 3, true)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {UI.box(20)}
          <View
            style={{
              ...styles.main_box_content,
              width: 300,
              alignSelf: "flex-start",
            }}
          >
            <Text style={{ ...styles.box_header_text, textAlign: "center" }}>
              Other Options
            </Text>

            {UI.box(10)}
            <Layout.Btn
              color={UI.colors.secondary}
              text={"DOWNLOAD"}
              onClick={() => {
                this.download("download");
              }}
            />
            {UI.box(10)}
            <Layout.Btn
              color={UI.colors.secondary}
              text={"PRINT FILE"}
              onClick={() => {
                this.download("print");
              }}
            />

            {
              // ((this.state.orderStatus === "Confirmed" || this.state.orderStatus === "Completed")) &&
              this.state.testResults?.length > 0 &&
              <>
                {UI.box(50)}
                {/* <Layout.Btn
                  color={UI.colors.secondary}
                  text={"Upload Results"}
                  onClick={() => {
                    this.upload_picture();
                  }}
                /> */}
                {UI.box(10)}
                <Text style={{ ...styles.box_header_text, textAlign: "center" }}>
                  Lab Results
                </Text>
                {UI.box(10)}
                {
                  this.state.testResults?.map(item => (
                    <Button
                      type="link"
                      style={{ color: UI.colors.primary, width: "30%" }}
                      onClick={async () => {
                        const image = await UI.download_image(item.path, "merchants");
                        window.open(image, "_blank");
                      }}
                    >
                      <Text style={{ textDecoration: "underline"}}>{item.filename ?? ""}</Text>
                    </Button>
                  ))
                }
                
              </>
            }

          </View>
        </UI.Row>
      
      </MainLayout>
    );
  }
}

const UserDetail = (
  props = { icon: "", name: "", value: "", is_rendering: false }
) => {
  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}
    >
      <View style={{ width: 180, ...styles.fl_ai }}>
        {
          props.icon &&
          <View style={styles.detail_icon}>
            <img
              style={{
                height: 16,
                width: 16,
                marginTop: props.is_rendering ? 8 : 0,
              }}
              src={props.icon}
              alt="User Detail"
            />
          </View>
        }
     
        <Text style={styles.details_name}>{props.name}</Text>
      </View>
      <Text style={styles.details_value}>{props.value}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  total_border_line: {
    height: 1,
    width: "100%",
    backgroundColor: "#888888",
  },
  total_text_value: {
    fontSize: 17,
    fontWeight: "bold",
    color: UI.colors.secondary,
  },
  total_text: {
    fontSize: 17,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  total_container: {
    width: "100%",
    flexDirection: "row",
  },
  receipt_legend: {
    fontSize: 14,
    fontWeight: "bold",
    color: UI.colors.secondary,
  },
  border_line: {
    height: 1,
    width: "100%",
    backgroundColor: "#EBEBEB",
  },
  transaction_date_container: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
  transaction_date: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 10,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    // ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  fl_ai: {
    flexDirection: "row",
    alignItems: "center",
  },
  detail_icon: {
    height: 16,
    width: 16,
    marginRight: 12,
  },
  details_name: {
    color: UI.colors.primary,
    fontSize: 14,
    fontWeight: "bold",
  },
  details_value: {
    color: "#333333",
    fontSize: 14,
    fontWeight: "bold",
  },
});