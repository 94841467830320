import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  ImageBackground,
  ImageFit,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
// import UsersGroupCategoryApi from "../../api_services/subscriptions";
import { Failed, Success } from "../_components/Modal";
import InputForm from "../_components/InputForm";
import LaboratoryPartnerApi from "../../api_services/Merchant/LaboratoryPartnerApi";
import laboratoryServices from "../../api_services/Merchant/laboratoryServices";
import styled from "styled-components";
import { Col, Select, Typography } from "antd";

const context = "admin";

export default class AdminLaboratoryAdd extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Laboratory List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,

      name: "",
      emailAddress: "",
      contactNumber: "",
      productCategory: "",
      status: "",
      // serviceType: "",
      image: UI.PRODUCT_BOX,
      product_categories: [],
    });
  }

  onCreate = () => {
    this.get_categories()
  };

  submit = async () => {
    UI.clear_errors();
    const {
      image_file,
      name,
      emailAddress,
      contactNumber,
      productCategory,
      status,
      serviceType
    } = this.state;

    const payload = {
      image: image_file,
      name: name,
      email: emailAddress,
      phone: contactNumber,
      role: "merchant",
      merchantType: "laboratory",
      productCategory: productCategory,
      serviceType,
      status: status === 'true' ? "Active" : "Inactive",
    }
    
    if (!name) {
      await UI.error_form("name", "Laboratory name is required.");
    }
    
    if (!serviceType) {
      await UI.error_form("serviceType", "Service Type is required.");
    }

    if (!status) {
      await UI.error_form("status", "Status is required.");
    }

    if (!productCategory) {
      this.show_modal(
        <Failed
          description={"Product Category is required"} 
          onDismiss={() => { 
            this.hide_loading();
            this.hide_modal()
          }} 
        />
      );
    }

    if (!emailAddress) {
      if (!UI.validate_email(emailAddress)) {
        await UI.error_form("emailAddress", "Please enter a valid email.");
      }
    }

    const error_count = this.state.error_count;

    if (error_count > 0) {
      return true
    }

    LaboratoryPartnerApi.addLaboratoryPartner(payload).then((res) => {
      // setIsLoading(false);
      if (res.status === 200) {
        this.show_modal(<Success
          title={"Success"}
          description={`Successfully created laboratory`}
          onDismiss={() => {
            UI.goBack()
          }}
        />);
      }  else {
        this.show_modal(
          <Failed
            description={res.message} 
            onDismiss={() => { 
              this.hide_loading();
              this.hide_modal()
            }} 
          />
        );
      }
    });
  };

  get_categories = () => {
    laboratoryServices.getAllCategory().then((res) => {
      // setIsLoading(false);
      if (res.status === 200) {
        this.setState({ product_categories: res.data })
        console.log(res)
      }  else {
        this.show_modal(
          <Failed
            description={res.message} 
            onDismiss={() => { 
              this.hide_loading();
              this.hide_modal()
            }} 
          />
        );
      }
    });
  }

  handleTargetProductCategory = (val) => {
    this.setState({
      productCategory: val,
    });
  }

  upload_picture = async () => {
    const file = await UI.get_image();

    this.setState(
      {
        image: file.uri,
        image_file: file.file,
        did_upload: true,
      }
    );

    console.log(this.state.image_file)
  };

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Add Laboratory Account
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add item
            </Text>
          </UI.Row>

          <UI.Row breakpoint={970} _this={_this}>
              <View style={{ height: 120, width: 120 }}>
                <Layout.FilePicker
                  _this={_this}
                  title={""}
                  additional={""}
                  // isRequired={true}
                  text={""}
                  state_name={"laboratoryImage"}
                  onChange={async (file) => {
                    // this.pick_image();

                    // const uri = await UI.get_uri_from_file(file);
                    // this.setState(
                    //   {
                    //     image: uri,
                    //     image_file: file,
                    //     did_upload: true,
                    //   }
                    // );
                  }}
                  height={120}
                  component={
                    <ImageBackground
                      style={{ height: 120, width: 120, borderRadius: 10 }}
                      source={this.state.image}
                      imageFit={ImageFit.CONTAIN}
                    />
                  }
                />
              </View>
              {UI.box(20)}
              <strong className="text-red-500">*</strong>
              <View style={{ flex: 1.5, justifyContent: "center" }}>
                <Text
                  style={{ fontSize: 14, fontWeight: "bold", color: "#AFAFAF" }}
                >
                  {_this.state.did_upload
                    ? "Upload Product Listing Image."
                    : "No Icon Uploaded."}{" "}
                  {/* No Icon Uploaded.  */}
                  Recommended Size: 500 px x 500 px
                </Text>
                {UI.box(10)}

                <Layout.Btn
                  color={UI.colors.secondary}
                  text={"Upload Image"}
                  onClick={() => {
                    this.upload_picture();
                  }}
                  width={130}
                />
              </View>
          </UI.Row>

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Laboratory Name"}
              placeholder={"Enter Laboratory Name"}
              state_name={"name"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.name}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Email Address"}
              placeholder={"Enter Email Address"}
              state_name={"emailAddress"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.email}
            />
            {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Contact Number"}
              placeholder={"Contact Number"}
              state_name={"contactNumber"}
              prefix={"+63"}
              // isRequired={true}
              isNumber={true}
              maxLength={10}
              style={{ flex: 1 }}
            />
            {UI.box(10)}
          </UI.Row>

          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <ToolItem className="gutter-row" span={8}>
              <StyledFieldLabel>Product Category</StyledFieldLabel>
              <StyledSelect
                getPopupContainer={(trigger) => trigger.parentNode} // Avoid Select dropdown moves when scrolling
                placeholder="Select Group"
                mode="multiple"
                maxTagCount={'responsive'}
                defaultValue={[]}
                style={{
                  width: "100%",
                }}
                isRequired={true}
                allowClear
                onChange={(value, items) => {
                  const getIds = items.map(item => item.id);
                  this.handleTargetProductCategory(getIds)
                }}
              >
                {
                  this.state.product_categories.map(category => {
                    return (
                      <Select.Option title="Action" value={category.name} id={category._id}>{category.name}</Select.Option>
                      // <option value={category._id}>{category.name}</option>
                    )
                  })
                }
              </StyledSelect>
            </ToolItem>
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Service Type"}
              isRequired={true}
              state_name={"serviceType"}
            >
              <option value="">Select Service Type</option>
              <option value="Home">Home</option>
              <option value="Clinic">Clinic</option>
              <option value="Home and Clinic">Home and Clinic</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={true}
              state_name={"status"}
            >
              <option value="">Select Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)}
          </UI.Row>
          {UI.box(20)}
        </View>

        {UI.box(20)}

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Layout.Btn
            color={UI.colors.secondary}
            onClick={() => {
              this.submit();
            }}
            text={"Save Changes"}
          />
          {UI.box(20)}

          <Layout.Btn
            color={"#E00000"}
            onClick={() => {
              UI.goBack();
            }}
            text={"Cancel"}
          />
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});

const ToolItem = styled(Col)`
  position: relative;
  .ant-picker {
    margin-left: 102px;
  }
  .ant-picker-range {
    margin-left: 62px;
  }
`;

const StyledFieldLabel = styled(Typography.Paragraph)`
  color: #65d1ff;
  margin-bottom: 10px !important;
  font-weight: 600;
  font-family: Poppins;
`;

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    color: #0E4D94;
    background-color:#E3EDF8;
  }

  .ant-select-selection-item-remove {
    color: #0E4D94;
    font-size: 10px;
  }

  .anticon {
    vertical-align: -0em !important;
  }

  .ant-select-selector {
    border-radius: 12px !important;
    border-width: 2px !important;
    height: 50px !important;
    align-items: center;
    font-size: 0.9375rem; // 15px
    line-height: 1.4375rem; // 23px
    & .ant-select-selection-placeholder {
      color: #2e2e2e;
    }
    // Options
    & .ant-select-selection-item {
      z-index: 1;
    }
  }

  // The Wrapper of Icon
  .ant-select-arrow {
    margin-right: 10px;
    top: 47% !important;
  }
`;