import React from 'react';
import UI from '../../../../shared/Components/UI/js';
import { 
  // Text, 
  View, 
  // Image, 
  // ScrollView 
} from '../../../../shared/custom-react-native';
// import { ImageViewer } from "../../../_components/ImageViewerModal";
// import AwesomeDebouncePromise from 'awesome-debounce-promise';
// import APIConstants from '../../../../shared/modules/APIConstants';
import PatientUploadFilesTable from './PatientUploadFilesTable';
import LaboratoryOrderFilesTable from './LaboratoryOrderFilesTable';
import DoctorAppointmentUploadFilesTable from './DoctorAppointmentUploadFilesTable';

// const Constants = new APIConstants();

const ViewFiles = ({ _this, saveEntry}) => {
  const files = _this.state.appointment.files;

  return (
    <div style={{background:'#F5FAFF '}} className="rounded-b-lg p-3">
      {UI.box(10)}
      {/*
      <Text
        style={{
          fontWeight: "bold",
          fontSize: '15px',
          color: "#0E4D94",
        }}
      >
        Patient Uploaded Files
      </Text>

      {UI.box(0)} */}

      <View style={{ display: "block" }}>
        <PatientUploadFilesTable _this={_this} files={files}/>
        <LaboratoryOrderFilesTable _this={_this} />
        <DoctorAppointmentUploadFilesTable />
        {/* {
          (files && files.length > 0) ?
            <ScrollView style={{flex: 1, height: 200}}>
            {
              files.map((item, i) => {
                const fileName = item.filename;
                const fileExtension = fileName.split('.').pop().toLowerCase();
                const allowViewing = fileExtension.match(/^(jpg|jpeg|png)$/);
                const baseContent = `${Constants.STORAGE_BASE_URL}?path=${item.path}&container=users`;

                return (
                  <View style={{ display: "flex", flex: "1", flexDirection: "row", gap: 10, marginTop: 10}}>
                    <Image
                      height={28}
                      width={28}
                      tintColor={UI.colors.lightGray}
                      source={ allowViewing ? UI.IMG_ICON : UI.FILE_2}
                    />
                    <Text style={{flex: 1, color: UI.colors.header}}>{fileName}</Text>
                    
                    {
                      allowViewing ?
                      <div style={{cursor: "pointer"}} 
                        onClick={() => {
                          _this.show_image_viewer_modal(
                            <ImageViewer
                              title={fileName}
                              content={baseContent}
                              onCancel={{
                                method: () => {
                                  _this.hide_image_viewer_modal();
                                },
                              }}
                            />
                          )
                        }}
                      >
                        <Image
                          height={28}
                          width={28}
                          tintColor={UI.colors.lightGray}
                          source={UI.IC_VIEW}
                        />
                      </div>
                      :
                      <div style={{flex: 1}} />
                    }
                    
                    <div style={{cursor: "pointer"}} onClick={() => _this.downloadPatientFile(item.path, item.filename)}> 
                      <Image
                        height={28}
                        width={28}
                        tintColor={UI.colors.lightGray}
                        source={UI.IC_DOWNLOAD}
                      />
                    </div>
                  </View>
                )
              })
            }
          </ScrollView>
          :
          <View
            style={{
              paddingTop: 50,
              paddingBottom: 50,
              alignItems: "center",
              display: "flex",
            }}
          >
            <UI.Row
              breakpoint={820}
              style={{ maxWidth: 760, height: 10, alignItems: "center" }}
              _this={_this}
            >
              <Image
                height={100}
                width={100}
                tintColor={"transparent"}
                source={UI.NO_INFO}
              />
              {UI.box(10)}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  color: "#333333",
                  textAlign: "center",
                }}
              >
                NO PATIENT FILES AVAILABLE
              </Text>
            </UI.Row>
          </View>
        } */}
      </View>
  </div>
  )
}

export default ViewFiles
