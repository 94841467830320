import {
  Button,
  Checkbox,
  Radio,
  Row,
  Space,
  Input,
  // Radio
} from "antd";
import React, { Component, useState } from "react";
import { animated, Spring } from "react-spring";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  ScrollView,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import QRCode from "qrcode.react";
import "./css.css";
import Loading from "./Loading";
import _ from "lodash";
import styled from "styled-components";
import html2canvas from 'html2canvas';
// import InputForm from "./InputForm";
// import Layout from "./Layout";
const zIndex = 10002;

const { TextArea } = Input;
export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speed: 1.1,
    };
  }

  componentDidMount = () => {};

  render() {
    let props = this.props;
    let { visible, _this } = props;
    return (
      <View
        style={{
          display: visible ? "flex" : "none",
          ...styles.modal_container,
        }}
      >
        {_this.state.modal_content}
      </View>
    );
  }
}

export class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    let props = this.props;
    const initial = { ...styles.default_modal, width: 300, padding: 0 };
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    const onCancel = () => {
      if (props.onDismiss) {
        props.onDismiss();
      }
      if (props.onCancel) {
        props.onCancel();
      }
    };
    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style }}>
            <View style={{ ...styles.md_header, backgroundColor: "#64C31E" }}>
              <TouchableOpacity onClick={onCancel} style={styles.md_close}>
                <img
                  style={{ height: 15, width: 15 }}
                  src={UI.CLOSE}
                  alt="close"
                />
              </TouchableOpacity>

              <View style={styles.md_header_text_container}>
                <Text style={{ ...styles.md_header_text, textAlign: "center" }}>
                  {this.props.title}
                </Text>
              </View>

              {UI.box(60)}
            </View>

            <View style={styles.md_content_container}>
              <img
                style={{ width: 219 * 0.5, height: 158 * 0.5 }}
                src={UI.SUCCESS_BANNER}
                alt="success banner"
              />
              {UI.box(20)}

              <Text style={{ ...styles.md_text_content, fontSize: 20 }}>
                {this.props.descriptionTop}
              </Text>
              {UI.box(20)}
              <Text style={{ ...styles.md_text_content, fontSize: 15 }}>
                {this.props.description}
              </Text>
              {UI.box(20)}
              <Text
                style={{
                  ...styles.md_text_content,
                  fontSize: 15,
                  width: "240px",
                }}
              >
                {this.props.descriptionCenter}
              </Text>
              {UI.box(20)}
              <Text style={{ ...styles.md_text_content, fontSize: 12 }}>
                {this.props.descriptionBottom}
              </Text>

              {UI.box(20)}

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TouchableOpacity
                  onClick={onCancel}
                  style={{ ...styles.md_button, backgroundColor: "#004F99" }}
                >
                  <Text style={styles.md_btn_text}>Continue</Text>
                </TouchableOpacity>
              </View>
            </View>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export class Disapprove extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    let props = this.props;
    const initial = { ...styles.default_modal, maxWidth:"500px", padding: 0 };
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    const onCancel = () => {
      if (props.onDismiss) {
        props.onDismiss();
      }
      if (props.onCancel) {
        props.onCancel();
      }
    };
    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style }}>
            <View style={{ ...styles.md_header, backgroundColor: "#B90C0C" }}>
              <TouchableOpacity onClick={onCancel} style={styles.md_close}>
                <img
                  style={{ height: 15, width: 15 }}
                  src={UI.CLOSE}
                  alt="close"
                />
              </TouchableOpacity>

              <View style={styles.md_header_text_container}>
                <Text style={{ ...styles.md_header_text, textAlign: "center" }}>
                  {this.props.title}
                </Text>
              </View>

              {UI.box(60)}
            </View>

            <View style={styles.md_content_container}>
              <img
                style={{ width: 219 * 0.5, height: 158 * 0.5 }}
                src={UI.TRASH_BANNER}
                alt="Disapprove Banner"
              />
              {UI.box(20)}

              <Text style={{ ...styles.md_text_content, fontSize: 20 }}>
                {this.props.descriptionTop}
              </Text>
              {UI.box(20)}
              <Text style={{ ...styles.md_text_content, width:"100%", fontSize: 15 }}>
                {this.props.description}
              </Text>
              {UI.box(20)}
              {/* <Text
                style={{
                  ...styles.md_text_content,
                  fontSize: 15,
                  width: "240px",
                }}
              >
                {this.props.descriptionCenter}
              </Text> */}
              {/* {UI.box(20)}
              <Text style={{ ...styles.md_text_content, fontSize: 12 }}>
                {this.props.descriptionBottom}
              </Text> */}

              {/* {UI.box(20)} */}
              {/* <textarea row="10" width="100%" style={{border:"1px solid #ccc", marginBottom:"30px"}}>Testing</textarea> */}
              <TextArea id="declinedReason" class="declinedreason" rows={4} placeholder="Input reason here"  />
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TouchableOpacity
                  onClick={onCancel}
                  style={{ ...styles.md_button, backgroundColor: "#B90C0C", marginTop:"30px"}}
                >
                  <Text style={styles.md_btn_text}>Proceed</Text>
                </TouchableOpacity>
              </View>
            </View>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export class Failed extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let props = this.props;
    const initial = { ...styles.default_modal, width: 300, padding: 0 };
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    const onCancel = () => {
      if (props.onDismiss) {
        props.onDismiss();
      }
    };
  
    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style }}>
            <View style={{ ...styles.md_header, backgroundColor: "#B90C0C" }}>
              <TouchableOpacity onClick={onCancel} style={styles.md_close}>
                <img
                  style={{ height: 15, width: 15 }}
                  src={UI.CLOSE}
                  alt="close"
                />
              </TouchableOpacity>

              <View style={styles.md_header_text_container}>
                 
                {props.savedButNoEmail ? (
                  <Text style={{ ...styles.md_header_text, fontSize: 15 }}>
                    {props.header_title ?? "Successfully Saved as Draft"}
                  </Text>
                ) : props.unableToSaveAsDraft ? (
                  <Text style={{ ...styles.md_header_text, fontSize: 15 }}>
                    {props.header_title ?? "Unable To Save as Draft"}
                  </Text>
                ) : (
                  <Text style={{ ...styles.md_header_text, fontSize: 15 }}>
                    {props.header_title ?? "Error"}
                  </Text>
                )}
              </View>

              {UI.box(60)}
            </View>

            <View style={styles.md_content_container}>

              {!props.hideIcon && (
                <>
                  <img
                    style={{ width: 219 * 0.5, height: 158 * 0.5 }}
                    src={this.props.modal_icon || UI.TRASH_BANNER}
                    alt="trash banner"
                  />
                  {UI.box(20)}
                </>
              )}

              <Text style={styles.md_text_content}>
                {this.props.description}
              </Text>

              <Text
                style={{
                  ...styles.md_text_content,
                  fontWeight: 600,
                  color: "red",
                }}
              >
                {this.props.descriptionOne}
              </Text>

              <Text
                style={{
                  ...styles.md_text_content,
                  fontWeight: 600,
                  color: "red",
                }}
              >
                {this.props.descriptionTwo}
              </Text>

              {this.props.descriptionThree &&
                this.props.descriptionThree.map((res) => (
                  <Text
                    style={{
                      ...styles.md_text_content,
                      fontWeight: 600,
                      color: "red",
                    }}
                  >
                    {res.a} - {res.b}
                  </Text>
                ))}

              {UI.box(20)}

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                {props.onOk?.method && (
                  <>
                    <TouchableOpacity
                      onClick={props.onOk.method}
                      style={{
                        ...styles.md_button,
                        backgroundColor: UI.colors.primary,
                        ...props.onOk.style,
                      }}
                    >
                      <Text style={styles.md_btn_text}>{props.onOk?.text}</Text>
                    </TouchableOpacity>
                    {UI.box(20)}
                  </>
                )}
                {/* {UI.box(20)} */}
                <TouchableOpacity
                  onClick={onCancel}
                  style={{ ...styles.md_button, backgroundColor: "#004F99" }}
                >
                  <Text style={styles.md_btn_text}>
                    {props.onDismissText ? props.onDismissText : "Dismiss"}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export class CustomFailed extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let props = this.props;
    const initial = { ...styles.default_modal, width: 300, padding: 0 };
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    const onCancel = () => {
      if (props.onDismiss) {
        props.onDismiss();
      }
    };

    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style }}>
            <View style={{ ...styles.md_header, backgroundColor: "#B90C0C" }}>
              <TouchableOpacity onClick={onCancel} style={styles.md_close}>
                <img
                  style={{ height: 15, width: 15 }}
                  src={UI.CLOSE}
                  alt="close"
                />
              </TouchableOpacity>

              <View style={styles.md_header_text_container}>
                <Text style={styles.md_header_text}>{this.props.title}</Text>
              </View>

              {UI.box(60)}
            </View>

            <View style={styles.md_content_container}>
              <img
                style={{ width: 219 * 0.5, height: 158 * 0.5 }}
                src={
                  props?.imageBanner ? props?.imageBanner : UI.WARNING_BANNER
                }
                alt="warning banner"
              />
              {UI.box(20)}

              <Text style={styles.md_text_content}>
                {this.props.description_title}
              </Text>
              {UI.box(10)}
              <Text style={styles.md_text_content}>
                {this.props.description}
              </Text>

              {UI.box(20)}

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TouchableOpacity
                  onClick={onCancel}
                  style={{ ...styles.md_button, backgroundColor: "#004F99" }}
                >
                  <Text style={styles.md_btn_text}>
                    {props?.buttonText ? props.buttonText : "Dismiss"}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export class SystemNoticeCustomFailedModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let props = this.props;
    const initial = { ...styles.default_modal, width: 400, maxWidth: 420, padding: 0 };
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    const onCancel = () => {
      if (props.onDismiss) {
        props.onDismiss();
      }
    };

    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style }}>
            <View style={{ ...styles.md_header, backgroundColor: "#B90C0C" }}>
              <TouchableOpacity onClick={onCancel} style={styles.md_close}>
                <img
                  style={{ height: 15, width: 15 }}
                  src={UI.CLOSE}
                  alt="close"
                />
              </TouchableOpacity>

              <View style={styles.md_header_text_container}>
                <Text style={styles.md_header_text}>{this.props.title}</Text>
              </View>

              {UI.box(60)}
            </View>

            <View style={styles.md_content_container}>
              <img
                style={{ width: 219 * 0.5, height: 158 * 0.5 }}
                src={
                  props?.imageBanner ? props?.imageBanner : UI.WARNING_BANNER
                }
                alt="warning banner"
              />
              {UI.box(20)}

              <Text style={{...styles.md_text_content, width: 260}}>
                {this.props.description_title}
              </Text>
              {UI.box(10)}
              <Text style={{...styles.md_text_content, width: 260}}>
                {this.props.description}
              </Text>

              {UI.box(20)}

              <View style={{ gap: 10, alignItems: "center", width: "100%" }}>
                <TouchableOpacity
                  onClick={this.props.onOk}
                  style={{ ...styles.md_button, backgroundColor: "#004F99", width: "100%" }}
                >
                  <Text style={styles.md_btn_text}>
                    {props?.buttonTextOk ? props.buttonTextOk : "Yes"}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onClick={onCancel}
                  style={{ ...styles.md_button, background: "transparent", border: "2px solid #004F99", width: "100%" }}
                >
                  <Text style={{ ...styles.md_btn_text, color: "#004F99"}}>
                    {props?.buttonTextCancel ? props.buttonTextCancel : "Cancel"}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export class DeleteConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let props = this.props;
    const initial = { ...styles.default_modal, width: 300, padding: 0 };
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    const onCancel = () => {
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    };
    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style, justifyContent: "flex-start" }}>
            <View style={{ ...styles.md_header, backgroundColor: "#B90C0C" }}>
              <TouchableOpacity onClick={onCancel} style={styles.md_close}>
                <img
                  style={{ height: 15, width: 15 }}
                  src={UI.CLOSE}
                  alt="close"
                />
              </TouchableOpacity>

              <View style={styles.md_header_text_container}>
                <Text style={styles.md_header_text}>DELETE ITEM</Text>
              </View>

              {UI.box(60)}
            </View>

            <View style={styles.md_content_container}>
              <img
                style={{ width: 219 * 0.5, height: 158 * 0.5 }}
                src={UI.TRASH_BANNER}
                alt="trash banner"
              />
              {UI.box(20)}

              <Text style={styles.md_text_content}>{this.props.text}</Text>

              {UI.box(20)}

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TouchableOpacity
                  onClick={() => {
                    if (props.onDelete) {
                      props.onDelete();
                    }
                  }}
                  style={{ ...styles.md_button, backgroundColor: "#B90C0C" }}
                >
                  <Text style={styles.md_btn_text}>Delete</Text>
                </TouchableOpacity>
                {UI.box(10)}
                <TouchableOpacity onClick={onCancel} style={styles.md_button}>
                  <Text style={styles.md_btn_text}>Cancel</Text>
                </TouchableOpacity>
              </View>
            </View>
          </animated.div>
        )}
      </Spring>
    );
  }
}
export class CustomConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let props = this.props;
    const initial = { ...styles.default_modal, width: 320, maxWidth: 350, padding: 0 };
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    // const onCancel = () => {
    //   if (this.props.onCancel) {
    //     this.props.onCancel();
    //   }
    // };
    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style, justifyContent: "flex-start" }}>
            <View
              style={{
                ...styles.md_header,
                backgroundColor: this.props.backgroundColor ?? "#B90C0C",
              }}
            >
              <TouchableOpacity
                onClick={() => {
                  if (props.onCancel.method) {
                    props.onCancel.method();
                  }
                }}
                style={styles.md_close}
              >
                <img
                  style={{ height: 15, width: 15 }}
                  src={UI.CLOSE}
                  alt="close"
                />
              </TouchableOpacity>

              <View style={styles.md_header_text_container}>
                <Text style={styles.md_header_text}>{this.props.title}</Text>
              </View>

              {UI.box(60)}
            </View>

            <View style={styles.md_content_container}>
              <img
                style={{ width: 219 * 0.5, height: 158 * 0.5 }}
                src={props?.imageBanner ? props?.imageBanner : UI.COMING_SOON}
                alt="coming soon"
              />
              {UI.box(20)}

              <Text style={styles.md_text_content}>{this.props.text}</Text>

              {UI.box(20)}

              <Text style={styles.md_text_content}>{this.props.description}</Text>

              {UI.box(20)}

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <TouchableOpacity
                  onClick={() => {
                    if (props.onOk.method) {
                      props.onOk.method();
                    }
                  }}
                  style={{
                    ...styles.md_button,
                    backgroundColor: "#004F99",
                  }}
                >
                  <Text style={styles.md_btn_text}>{props.onOk.text}</Text>
                </TouchableOpacity>
                {UI.box(10)}
                {props.onCancel.text && (
                  <TouchableOpacity
                    onClick={() => {
                      if (props.onCancel.method) {
                        props.onCancel.method();
                      }
                    }}
                    style={{ ...styles.md_button, background: "transparent", border: "2px solid #004F99"}}
                  >
                    <Text style={{ ...styles.md_btn_text, color: "#004F99"}}>
                      {props.onCancel.text}
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export class CustomSubmittedModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let props = this.props;
    const initial = {
      backgroundColor: "white",
      borderRadius: "18px",
      ...Shadow._3(),
      zIndex: zIndex + 1,
      display: "inline-flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // padding: 20,
      maxWidth: 800,
    };
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    // const onCancel = () => {
    //   if (this.props.onCancel) {
    //     this.props.onCancel();
    //   }
    // };
    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style, justifyContent: "flex-start" }}>
            <View
              style={{
                flexDirection: "row",
                borderRadius: "18px 0px 0px 18px !important",
              }}
            >
              <div style={{ flex: "50%" }}>
                <img
                  src={UI.ADVOCACY_DOCTOR}
                  style={{
                    borderRadius: "18px 0px 0px 18px",
                    backgroundSize: "cover",
                  }}
                  alt=""
                />
              </div>

              <div
                className="py-5 px-8 flex items-center"
                style={{ flex: "50%" }}
              >
                <View className="gap-3">
                  <strong
                    className="font-semibold"
                    style={{ color: "#004F99", fontSize: "21px" }}
                  >
                    {props.headerTitle}
                  </strong>
                  <span
                    className=""
                    style={{ fontSize: "16px", fontWeight: 600 }}
                  >
                    {props.descriptionOne} {props.title ?? " "}
                  </span>
                  <span style={{ fontSize: "16px", fontWeight: 600 }}>
                    {props.descriptionTwo} {props.email ?? " "}
                  </span>
                  <span style={{ fontSize: "16px", fontWeight: 600 }}>
                    {props.descriptionThree}
                  </span>
                  <div>
                    <TouchableOpacity
                      onClick={() => {
                        if (props.onOk.method) {
                          props.onOk.method();
                        }
                      }}
                      style={{
                        backgroundColor:
                          this.props.backgroundColor ?? "#B90C0C",
                        borderRadius: "8px",
                      }}
                    >
                      <button
                        className="flex items-center px-4 py-3 text-white gap-2 rounded-md font-semibold"
                        style={{ backgroundColor: "#0056AE" }}
                      >
                        {props.onOk?.text}
                      </button>
                    </TouchableOpacity>
                  </div>
                </View>
              </div>
            </View>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export class RadioButtonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: props.defaultOptionChoice,
    };
  }

  render() {
    let props = this.props;
    const initial = {
      backgroundColor: "white",
      borderRadius: "18px",
      ...Shadow._3(),
      zIndex: zIndex + 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: props.width,
      maxWidth: props.maxWidth ?? 800,
    };
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    const onItemSelected = (e) => {
      this.setState({
        optionSelected: e.currentTarget.value,
      });
    };

    let op_id = 0;

    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style, justifyContent: "flex-start" }}>
            {props.title ? (
              <View
                style={{
                  ...styles.md_header,
                  ...(props.titleStyles ?? ""),
                }}
              >
                <TouchableOpacity
                  onClick={() => {
                    if (props.onCancel.method) {
                      props.onCancel.method();
                    }
                  }}
                  style={styles.md_close}
                >
                  <img
                    style={{ height: 15, width: 15 }}
                    src={UI.CLOSE}
                    alt="close"
                  />
                </TouchableOpacity>

                <View style={styles.md_header_text_container}>
                  <Text style={styles.md_header_text}>{this.props.title}</Text>
                </View>
                {UI.box(60)}
              </View>
            ) : (
              ""
            )}

            <View style={{ paddingLeft: 30, paddingRight: 30 }}>
              {props.logoSource ? (
                <View style={{ alignItems: "center", marginTop: 30 }}>
                  <img
                    style={{ width: 259 * 0.5, height: 198 * 0.5 }}
                    src={props.logoSource}
                    alt="source"
                  />
                </View>
              ) : (
                ""
              )}
              <Text
                style={{
                  ...styles.modal_title,
                  ...(props.bodyStyles ?? ""),
                }}
              >
                {props.body}
              </Text>
              {/* start radio list */}
              <form style={{ marginTop: 10 }}>
                {this.props.options.map((item) => {
                  op_id++;
                  return (
                    <View style={{ display: "block" }} key={op_id}>
                      <input
                        type="radio"
                        value={item.value}
                        name={"options_" + props.name}
                        style={{ ...(props.optionButtonStyles ?? "") }}
                        checked={this.state.optionSelected === item.label}
                        onChange={onItemSelected}
                      />
                      <label
                        style={{
                          ...styles.optionLabel,
                          ...(props.optionLabelStyles ?? ""),
                        }}
                      >
                        {item.label}
                      </label>
                    </View>
                  );
                })}
              </form>

              {/* custom content goes here */}
              {props.customContent ?? ""}

              {/* buttons */}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 30,
                }}
              >
                <TouchableOpacity
                  onClick={() => {
                    if (props.onOk.method) {
                      props.onOk.method(this.state.optionSelected);
                    }
                  }}
                  style={{
                    ...styles.md_button,
                    ...(props.submitButtonStyles ?? ""),
                  }}
                >
                  <Text style={styles.md_btn_text}>{props.onOk.label}</Text>
                </TouchableOpacity>
                {UI.box(10)}
                {props.onCancel.label && (
                  <TouchableOpacity
                    onClick={() => {
                      if (props.onCancel.method) {
                        props.onCancel.method();
                      }
                    }}
                    style={styles.md_button_outline}
                  >
                    <Text>{props.onCancel.label}</Text>
                  </TouchableOpacity>
                )}
              </View>
              {UI.box(30)}
            </View>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export class SimpleDeleteConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let props = this.props;
    const initial = {
      backgroundColor: "white",
      borderRadius: "18px",
      ...Shadow._3(),
      zIndex: zIndex + 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // padding: 20,
      maxWidth: 800,
    };
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style, justifyContent: "center" }}>
            <View style={{ paddingLeft: 30, paddingRight: 30 }}>
              <Text style={{ ...styles.modal_title, marginTop: 0 }}>
                {props.title}
              </Text>
              {/* message body */}
              <View style={styles.simpleBody}>
                <Text>{props.text}</Text>
              </View>
              {/* buttons */}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "auto",
                  marginTop: 30,
                }}
              >
                <TouchableOpacity
                  onClick={() => {
                    if (props.onOk.method) {
                      props.onOk.method();
                    }
                  }}
                  style={{
                    ...styles.md_button,
                    backgroundColor:
                      this.props.backgroundColor ?? UI.colors.secondary,
                  }}
                >
                  <Text style={styles.md_btn_text}>{props.onOk.label}</Text>
                </TouchableOpacity>
                {UI.box(10)}
                {props.onCancel.label && (
                  <TouchableOpacity
                    onClick={() => {
                      if (props.onCancel.method) {
                        props.onCancel.method();
                      }
                    }}
                    style={styles.md_button_outline}
                  >
                    <Text>{props.onCancel.label}</Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let props = this.props;
    const initial = { ...styles.default_modal, width: 300, padding: 0 };
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    const onCancel = () => {
      if (props.onDismiss) {
        props.onDismiss();
      }
    };

    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style }}>
            <View style={{ ...styles.md_header, ...this.props.headerStyle }}>
              <TouchableOpacity onClick={onCancel} style={styles.md_close}>
                <img
                  style={{ height: 15, width: 15 }}
                  src={UI.CLOSE}
                  alt="close"
                />
              </TouchableOpacity>

              <View style={styles.md_header_text_container}>
                <Text style={styles.md_header_text}>{this.props.title}</Text>
              </View>

              {UI.box(60)}
            </View>

            <View style={styles.md_content_container}>
              
              {
                this.props.bannerImage && this.props.bannerImage
              }
              
              {UI.box(20)}

              <Text style={styles.md_text_content}>
                {this.props.description_title}
              </Text>
              {UI.box(10)}
              <Text style={styles.md_text_content}>
                {this.props.description}
              </Text>

              {UI.box(20)}

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                {
                  props.buttons.map(button => {
                    return (
                      <>
                        <TouchableOpacity
                          onClick={button.onClick}
                          style={{  color: "white",  backgroundColor: "#004F99", ...styles.md_button, ...button.style, }}
                        >
                          <Text style={{
                            fontSize: 14,
                            fontWeight: "bold",
                          }}>{button?.title}</Text>
                        </TouchableOpacity>
                        {UI.box(10)}
                      </>
                    )
                  })
                }
              </View>
            </View>
          </animated.div>
        )}
      </Spring>
    );
  }
}
export class FileLister extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let props = this.props;
    const initial = {
      backgroundColor: "white",
      borderRadius: "18px",
      ...Shadow._3(),
      zIndex: zIndex + 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // padding: 20,
      width: 400,
      maxWidth: 800,
      maxHeight: 700,
    };
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 0 }}>
        {(style) => (
          <animated.div style={{ ...style, justifyContent: "flex-start" }}>
            {props.title ? (
              <View
                style={{
                  ...styles.md_header,
                  ...(props.titleStyles ?? ""),
                  backgroundColor: "#035099",
                }}
              >
                <TouchableOpacity
                  onClick={() => {
                    if (props.onCancel.method) {
                      props.onCancel.method();
                    }
                  }}
                  style={styles.md_close}
                >
                  <img
                    style={{ height: 15, width: 15 }}
                    src={UI.CLOSE}
                    alt="close"
                  />
                </TouchableOpacity>

                <View style={styles.md_header_text_container}>
                  <Text style={styles.md_header_text}>{this.props.title}</Text>
                </View>
                {UI.box(60)}
              </View>
            ) : (
              ""
            )}

            <View style={{ width: "100%", paddingLeft: 30, paddingRight: 30 }}>
              {props.customBody ?? ""}
            </View>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export class MemorandumOfAgrrementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheckMOAStep3: false,
      isCheckMOAUserProfile: false,
      isCheckMarketingConsent: false,
    };
  }

  render() {
    let props = this.props;
    const initial = {
      backgroundColor: "white",
      borderRadius: "18px",
      ...Shadow._3(),
      zIndex: zIndex + 1,
      display: "inline-flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // padding: 20,
      maxWidth: 800,
    };
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    const onCancel = () => {
      if (this.props.onDismiss) {
        this.props.onDismiss();
      }
    };

    console.log(this.props._this.state);
    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style, justifyContent: "flex-start" }}>
            <View
              style={{
                ...styles.md_header,
                backgroundColor: UI.colors.secondary,
              }}
            >
              <TouchableOpacity onClick={onCancel} style={styles.md_close}>
                <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="" />
              </TouchableOpacity>

              <View style={styles.md_header_text_container}>
                <Text style={{ ...styles.md_header_text, textAlign: "center" }}>
                  {this.props.title}
                </Text>
              </View>

              {UI.box(60)}
            </View>
            {this.props.title === "Memorandum Of Agreement" && (
              <ScrollView style={{ height: 700 }}>
                <View className="gap-3" style={{ padding: 35, height: "100%" }}>
                  <View
                    className=""
                    style={{ fontSize: "16px", fontWeight: 600 }}
                  >
                    <AdvocacyMoaTextStep3 />
                  </View>
                </View>
              </ScrollView>
            )}

            {this.props.title === "Marketing Consent" && (
              <ScrollView style={{ height: 500 }}>
                <View className="gap-3" style={{ padding: 35, height: "100%" }}>
                  <View
                    className=""
                    style={{ fontSize: "16px", fontWeight: 600 }}
                  >
                    <AdvocacyMarketingConsent _this={this.props._this} />
                  </View>
                </View>
              </ScrollView>
            )}

            {this.props.type === "userprofileMOA" && (
              <ScrollView style={{ height: 500 }}>
                <View className="gap-3" style={{ padding: 35, height: "100%" }}>
                  <View
                    className=""
                    style={{ fontSize: "16px", fontWeight: 600 }}
                  >
                    <AdvocacyMoaProfile />
                  </View>
                </View>
              </ScrollView>
            )}

            <div className="flex justify-between gap-5 p-6">
              <div className="flex items-center">
                {this.props.title === "Memorandum Of Agreement" && (
                  <Checkbox
                    onChange={(value) => {
                      this.setState({
                        isCheckMOAStep3: !this.state.isCheckMOAStep3,
                      });
                      this.props._this.setState({
                        is_check_moa: !this.props._this.state.is_check_moa,
                      });
                    }}
                    checked={this.props._this.state.is_check_moa}
                    color={"#2E2E2E"}
                  />
                )}

                {this.props.type === "userprofileMOA" && (
                  <Checkbox
                    // style={{ border: '3px solid gray'}}
                    onChange={(value) => {
                      this.setState({
                        isCheckMOAUserProfile:
                          !this.state.isCheckMOAUserProfile,
                      });
                      this.props._this.setState({
                        is_check_moa_user:
                          !this.props._this.state.is_check_moa_user,
                      });
                    }}
                    checked={this.props._this.state.is_check_moa_user}
                    color={"#2E2E2E"}
                  />
                )}

                {this.props.title === "Marketing Consent" && (
                  <Checkbox
                    onChange={(value) => {
                      this.setState({
                        isCheckMarketingConsent:
                          !this.state.isCheckMarketingConsent,
                      });
                      this.props._this.setState({
                        is_check_marketing_consent:
                          !this.props._this.state.is_check_marketing_consent,
                      });
                    }}
                    checked={this.props._this.state.is_check_marketing_consent}
                    color={"#2E2E2E"}
                  />
                )}

                {UI.box(10)}

                <span style={{ fontSize: "16px", fontWeight: 600 }}>
                  {this.props.checkboxTitle ?? ""}
                </span>
              </div>

              <TouchableOpacity
                onClick={() => {
                  if (props.onOk.method) {
                    props.onOk.method();
                  }
                }}
                style={{
                  backgroundColor: this.props.backgroundColor ?? "#B90C0C",
                  borderRadius: "8px",
                }}
              >
                <button
                  className="flex items-center px-4 py-3 text-white gap-2 rounded-md font-semibold"
                  style={{ backgroundColor: "#0056AE" }}
                >
                  {props.onOk?.text}
                </button>
              </TouchableOpacity>
            </div>
            {UI.box(10)}
          </animated.div>
        )}
      </Spring>
    );
  }
}

export class ComingSoon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // let props = this.props;
    const initial = { ...styles.default_modal, width: 300, padding: 0 };
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    const onCancel = () => {
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    };

    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style, justifyContent: "flex-start" }}>
            <View style={styles.md_header}>
              <TouchableOpacity onClick={onCancel} style={styles.md_close}>
                <img
                  style={{ height: 15, width: 15 }}
                  src={UI.CLOSE}
                  alt="close"
                />
              </TouchableOpacity>

              <View style={styles.md_header_text_container}>
                <Text style={styles.md_header_text}>COMING SOON</Text>
              </View>

              {UI.box(60)}
            </View>

            <View style={styles.md_content_container}>
              <img
                style={{ width: 219 * 0.5, height: 158 * 0.5 }}
                src={UI.COMING_SOON}
                alt="coming soon"
              />
              {UI.box(20)}

              <Text style={styles.md_text_content}>
                This page is still under development We’ll get back to you soon.
              </Text>

              {UI.box(20)}

              <TouchableOpacity onClick={onCancel} style={styles.md_button}>
                <Text style={styles.md_btn_text}>Close</Text>
              </TouchableOpacity>
            </View>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export class NotApproved extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    // let props = this.props;
    const initial = styles.default_modal;
    const before = { ...styles.modal_before, ...initial };
    const during = { ...styles.modal_during, ...initial };

    return (
      <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
        {(style) => (
          <animated.div style={{ ...style }}>
            <img
              style={{ height: 50, width: 50 }}
              src={UI.TRANSACTION_ITEM}
              alt="transaction item"
            />

            <Text style={styles.modal_title}>Waiting for approval</Text>
            {UI.box(10)}
            <Text
              style={{
                ...styles.modal_description,
                textAlign: "left",
                width: "100%",
              }}
            >
              Kindly wait for a maximum of 24 hours for our team to validate the
              documents that you have submitted. Thank you.
            </Text>
            {UI.box(30)}
            <Text
              style={{
                ...styles.modal_description,
                textAlign: "left",
                width: "100%",
              }}
            >
              Regards,
            </Text>

            <Text
              style={{
                ...styles.modal_description,
                textAlign: "left",
                width: "100%",
              }}
            >
              mWell Management
            </Text>

            <TouchableOpacity
              onClick={() => {
                if (this.props.onDismiss) {
                  this.props.onDismiss();
                }
              }}
              style={styles.modal_black_btn}
            >
              <Text style={styles.modal_btn_text}>Dismiss</Text>
            </TouchableOpacity>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export const QrCodeModal = (props) => {
  const [isGenerateQRCode, setIsGenerateQRCode] = useState(
    props.isGenerateQRCode
  );
  const [isShow_loading, setIsShow_loading] = useState(false);

  const show_loading = () => {
    setIsShow_loading(true);
  };

  const hide_loading = () => {
    setIsShow_loading(false);
  };

  return (
    <View
      style={{
        backgroundImage: `url(${UI.QR_CODE_BG})`,
        borderRadius: 10,
        margin: "auto",
      }}
    >
      <Loading
        visible={isShow_loading}
        // title={loading_title}
        // text={loading_text}
        // modal_opaque={modal_opaque}
      />
      <div style={{ width: "100%" }}>
        <View
          style={{ ...styles.md_header, backgroundColor: UI.colors.secondary }}
        >
          <TouchableOpacity
            onClick={() => {
              props._this.hide_modal();
            }}
            style={styles.md_close}
          >
            <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="CLOSE" />
          </TouchableOpacity>

          <View style={{ ...styles.md_header_text_container, marginLeft: 50 }}>
            <Text style={styles.md_header_text}>QR CODE</Text>
          </View>
        </View>

        <View style={{ ...styles.md_content_container, width: "100%" }}>
          {isGenerateQRCode ? (
            <>
              <div style={{ width: "100%", height: "70%" }}>
                <div className=" bg-white p-2 h-60 w-60 m-auto">
                  <QRCode
                    level="H"
                    id="qr-gen"
                    style={{
                      margin: "auto",
                      width: "100%",
                      height: "100%",
                      color: "gray",
                    }}
                    value={`https://mwell.com.ph/deeplink?type=page&name=doctor_details&doctor_id=${props._this.state.onboard_data?.doctor?._id}`}
                    renderAs="canvas"
                  />
                </div>
                <span
                  className="text-2xl flex justify-center my-5 font-semibold"
                  style={{ color: UI.colors.secondary }}
                >
                  Dr. {props.name ?? ""}{" "}
                </span>
              </div>
              <TouchableOpacity
                style={{ display: "flex", width: "90%", margin: "auto" }}
              >
                <button
                  onClick={() => {
                    props._this.downloadQRCode();
                  }}
                  className="flex items-center justify-center px-3 py-2 text-center text-white gap-2 font-semibold"
                  style={{
                    backgroundColor: UI.colors.secondary,
                    bottom: 0,
                    right: 0,
                    borderRadius: "5px",
                    width: "100%",
                  }}
                >
                  Download QR Code
                </button>
              </TouchableOpacity>
            </>
          ) : (
            <>
              <View
                style={{
                  padding: 50,
                  width: "60%",
                  height: "70%",
                  margin: "auto",
                  background: "white",
                }}
              >
                <img
                  src={UI.QR_CODE_JOIN}
                  alt="QR_CODE_BG"
                  style={{
                    background: "white",
                    margin: "auto",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                />
                <span
                  className="text-md flex justify-center font-semibold mt-5"
                  style={{ color: "#777777" }}
                >
                  {" "}
                  No Current QR Code{" "}
                </span>
                <span
                  className="text-md flex justify-center font-semibold"
                  style={{ color: "#777777" }}
                >
                  {" "}
                  Available{" "}
                </span>
              </View>
              <TouchableOpacity
                style={{ display: "flex", marginTop: 30, width: "100%" }}
              >
                <button
                  onClick={() => {
                    show_loading();
                    setTimeout(() => {
                      setIsGenerateQRCode({ isGenerateQRCode: true });
                      hide_loading();
                    }, 2000);
                  }}
                  className="flex items-center justify-center px-3 py-2 text-center text-white gap-2 font-semibold"
                  style={{
                    backgroundColor: UI.colors.secondary,
                    bottom: 0,
                    right: 0,
                    borderRadius: "5px",
                    width: "100%",
                  }}
                >
                  Generate QR Code
                </button>
              </TouchableOpacity>
            </>
          )}
        </View>
      </div>
    </View>
  );
};

const AdvocacyMarketingConsent = ({ _this }) => {
  return (
    <View style={{ fontWeight: 600 }}>
      <span style={{ color: UI.colors.secondary }}>MPHTC Consent Form</span>
      {UI.box(15)}

      <span>
        I consent to Metro Pacific Health Tech Corporation (“MPHTC”) using my
        photographs and/or video recordings including my name, employment, field
        of medical expertise, and years of medical practice experience to allow
        MPHTC to share to the public my images and personal information for
        marketing and promotional purposes. I agree that these images and
        personal information will be used in digital media formats, which shall
        be published in its website, mobile application, and social media
        accounts.
      </span>

      {UI.box(15)}

      <span>
        I hereby agree to irrevocably assign all property rights to the
        aforementioned photographs and recordings to MPHTC.
      </span>

      {UI.box(15)}

      <span>
        I understand that images on MPHTC’s website and mobile application can
        be viewed throughout the world and not just in the Philippines and that
        some overseas countries may not provide the same level of protection to
        the rights of individuals as the Data Privacy Act of 2012 provides.
      </span>

      {UI.box(15)}

      <span>
        I understand that some images or recordings may be kept permanently once
        they are published and be kept as an archive of MPHTC.
      </span>

      {UI.box(15)}

      <span>
        I have read and understand the conditions and consent to my images
        and/or recordings being used as described.
      </span>

      {/* {UI.box(15)}

      <UI.Row
        style={{ justifyContent: "space-between" }}
        _this={_this}
        breakpoint={1000}
        >
        <InputForm
          _this={_this}
          title={"Printed Name"}
          placeholder={"Printed Name"}
          state_name={"printed_name"}
          style={{ flex: 1 }}
          disabled={true}
          isRequired={false}
          maxLength={UI.Constants.limits.name}
        />
        {UI.box(10)}
        <InputForm
          _this={_this}
          title={"Printed Name"}
          placeholder={"Printed Name"}
          state_name={"printed_name"}
          style={{ flex: 1 }}
          disabled={true}
          isRequired={false}
          maxLength={UI.Constants.limits.name}
        />
        {UI.box(10)}
        <Layout.DatePicker
          _this={_this}
          disabled={true}
          title={"Date"}
          isRequired={true}
          placeholder={"mm/dd/yyyy"}
          state_name={"date"}
          date_props={{
            maxDate: new Date(),
          }}
          will_correct = {true}
        />
      </UI.Row> */}

      {UI.box(20)}

      <span>
        MPHTC is committed to processing information in accordance with the Data
        Privacy Act of 2012. The images and video footage will not be used for
        any commercial purposes outside of MPHTC. The information that you give
        us here will only be used to contact you about the images or video
        footage that we have taken. We will not pass the details record on this
        form to any third party without your permission.
      </span>
    </View>
  );
};

export const SimpleModal = ({ title, onDismiss, children }) => {
  const initial = {
    zIndex: zIndex + 1,
    display: "inline-flex",
    flexDirection: "column",
    maxWidth: 800,
    minWidth: 600,
  };

  const before = { ...styles.modal_before, ...initial };
  const during = { ...styles.modal_during, ...initial };

  const onCancel = () => {
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
      {(style) => (
        <animated.div style={{ ...style, justifyContent: "flex-start" }}>
          <View
            style={{
              ...styles.md_header,
              backgroundColor: "transparent",
            }}
          >
            <TouchableOpacity onClick={onCancel} style={styles.md_close}>
              <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="" />
            </TouchableOpacity>
            {UI.box(60)}
          </View>
          <ScrollView style={{ minHeight: 600 }}>{children}</ScrollView>
        </animated.div>
      )}
    </Spring>
  );
};

export const SwitchRoleModal = (props) => {
  const [role, setRole] = useState(props._this.state.role);
  const roleFromStorage = mem.get("role");

  return (
    <View
      style={{
        // display: visible ? "flex" : "none",
        ...styles.modal_container,
      }}
    >
      <View
        style={{
          width: 500,
          background: "white",
          borderRadius: 15,
        }}
      >
        <View
          style={{
            ...styles.md_header,
            backgroundColor: UI.colors.header,
          }}
        >
          <TouchableOpacity
            onClick={() => {
              props._this.hide_modal();
              props._this.setState({ role: roleFromStorage });
              // props._this.hidePatientDetails();
            }}
            style={styles.md_close}
          >
            <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="close" />
          </TouchableOpacity>

          <View style={styles.md_header_text_container}>
            <Text style={styles.md_header_text}>Switch Role</Text>
          </View>
          {UI.box(60)}
        </View>
        <View style={{ width: "100%", padding: 40 }}>
          <strong style={{ marginBottom: 10, fontSize: 18 }}>
            Currently {_.capitalize(roleFromStorage ?? "")}
          </strong>
          <h1>Swtich To: </h1>
          <Radio.Group
            onChange={(e) => {
              setRole(e.target.value);
              props._this.setState({ role: e.target.value });
              // setTimeout(() => {
              // }, 3000)
            }}
            value={role}
          >
            <Space direction="vertical">
              {props._this.state.allRole.map((role) => {
                return <Radio value={role}>{role}</Radio>;
              })}
            </Space>
          </Radio.Group>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 30,
              justifyContent: "flex-end",
            }}
          >
            <TouchableOpacity
              onClick={() => {
                props._this.hide_modal();
                props._this.show_loading("", "Changing Role");

                if (props._this.state.role === "provider") {
                  mem.remove("admin");
                }

                setTimeout(() => {
                  mem.save("role", props._this.state.role);
                  window.location.href = `/${
                    props._this.state.role === "provider"
                      ? "onboard"
                      : props._this.state.role
                  }/dashboard`;
                }, 2000);
              }}
              style={{
                padding: 30,
                backgroundColor: "#004F99",
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Save Changes
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

export const AgreementConfirmation = ({
  title,
  onDismiss,
  children,
  onOk,
  checkboxTitle,
  isConfirm,
  setIsConfirm,
}) => {
  const [confirm, setConfirm] = useState(isConfirm);

  const initial = {
    backgroundColor: "white",
    borderRadius: "18px",
    ...Shadow._3(),
    zIndex: zIndex + 1,
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 800,
  };

  const before = { ...styles.modal_before, ...initial };
  const during = { ...styles.modal_during, ...initial };

  const onCancel = () => {
    if (onDismiss) {
      onDismiss();
    }

    // setConfirm(false);
    // setIsConfirm(false);
  };

  return (
    <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
      {(style) => (
        <animated.div style={{ ...style, justifyContent: "flex-start" }}>
          <View
            style={{
              ...styles.md_header,
              backgroundColor: UI.colors.secondary,
            }}
          >
            <TouchableOpacity onClick={onCancel} style={styles.md_close}>
              <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="" />
            </TouchableOpacity>

            <View style={styles.md_header_text_container}>
              <Text style={{ ...styles.md_header_text, textAlign: "center" }}>
                {title}
              </Text>
            </View>

            {UI.box(60)}
          </View>
          <ScrollView style={{ height: 700 }}>{children}</ScrollView>
          <div className="flex justify-between gap-5 p-6">
            <div className="flex items-center">
              <Checkbox
                onChange={(value) => {
                  setConfirm(!confirm);
                  setIsConfirm(!isConfirm);
                }}
                checked={confirm}
                color={"#2E2E2E"}
              />
              <span className="text-base font-black ml-10">
                {checkboxTitle ?? ""}
              </span>
            </div>
            <TouchableOpacity
              onClick={() => {
                if (onOk.method) {
                  onOk.method();
                }
              }}
              style={{
                borderRadius: "8px",
              }}
            >
              <button
                disabled={!confirm}
                className={`px-4 py-3 text-white rounded-md font-semibold ${
                  confirm ? "cursor-pointer" : "cursor-not-allowed"
                }`}
                style={{
                  backgroundColor: `${confirm ? "#0056AE" : "#e00000"}`,
                }}
              >
                {onOk?.text}
              </button>
            </TouchableOpacity>
          </div>
        </animated.div>
      )}
    </Spring>
  );
};
export const DoctorLeaveRequest = ({
  title,
  onDismiss,
  children,
  // onOk,
  // checkboxTitle,
  // isConfirm,
  // setIsConfirm,
}) => {
  // const [confirm, setConfirm] = useState(isConfirm);

  const initial = {
    backgroundColor: "white",
    borderRadius: "18px",
    ...Shadow._3(),
    zIndex: zIndex + 1,
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 800,
  };

  const before = { ...styles.modal_before, ...initial };
  const during = { ...styles.modal_during, ...initial };

  const onCancel = () => {
    if (onDismiss) {
      onDismiss();
    }

    // setConfirm(false);
    // setIsConfirm(false);
  };

  return (
    <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
      {(style) => (
        <animated.div style={{ ...style, justifyContent: "flex-start" }}>
          <View
            style={{
              ...styles.md_header,
              backgroundColor: UI.colors.secondary,
            }}
          >
            <TouchableOpacity onClick={onCancel} style={styles.md_close}>
              <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="" />
            </TouchableOpacity>

            <View style={styles.md_header_text_container}>
              <Text style={{ ...styles.md_header_text, textAlign: "center" }}>
                {title}
              </Text>
            </View>

            {UI.box(60)}
          </View>

          <ScrollView>{children}</ScrollView>
        </animated.div>
      )}
    </Spring>
  );
};

const AdvocacyMoaProfile = () => {
  return (
    <View style={{ fontWeight: 600, textAlign: "center" }}>
      <span style={{ color: UI.colors.secondary }}>
        MEMORANDUM OF AGREEMENT
      </span>
      {UI.box(15)}

      <span>
        These terms and conditions ("Terms of Use") constitute a legally binding
        agreement made between you <br></br>
        This MEMORANDUM OF AGREEMENT (hHereinafter this "Agreement"), is entered
        into this <br></br>
        _______ day of _______ 2022 between:
      </span>

      {UI.box(15)}

      <span>
        METRO PACIFIC HEALTH TECH CORPORATION DOING <br></br>
        BUSINESS UNDER MWELL PH, a corporation organized and existing <br></br>
        under Philippine laws, with business address at 10th Floor MGO Building,{" "}
        <br></br>
        Legazpi cor. Dela Rosa Streets, Legazpi Village, Makati City,
        (hereinafter referred to as "MPHTC");<br></br>
        and
      </span>

      {UI.box(15)}

      <span>
        _____________________________________, Filipino, of legal age, with
        office address at <br></br>
        ______________________________________ (hereinafter referred to as
        "Physician").
      </span>

      {UI.box(15)}

      <span>
        MPHTC and the Physician shall be individually referred to as a "Party"{" "}
        <br></br>
        and collectively as the "Parties".
      </span>
    </View>
  );
};

const AdvocacyMoaTextStep3 = () => {
  return (
    <View className="font-semibold">
      <span style={{ color: UI.colors.secondary }}>
        mWell Advocacy Terms and Conditions{" "}
      </span>
      {UI.box(10)}

      <span>
        These terms and conditions (“Terms of Use”) constitute a legally binding
        agreement made between you (“you”, “your”) and Metro Pacific Health Tech
        Corporation doing business under mWell PH (the “Company”, “we”, “us”, or
        “our”), concerning your access to and use of our mobile application (the
        “Application”, “mWell PH”) as well as services we may provide through
        this Application and the telehealth and digital health platform for
        purposes of your participation in our health advocacy efforts
        (collectively, the “Service”). You agree that by using the Application,
        you have read, understood, and agree to be bound by all of these Terms
        of Use. If you do not agree with (or cannot comply with) the Terms of
        Use, then you may not access or use the Application.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>
          1. Acceptance of Terms of Use{" "}
        </span>
        <br></br>
        These Terms of Use set out the legally binding terms of your use of and
        subscription to the Service. By using the Service, you acknowledge that
        you have read and understood, and you accept and agree to be bound by
        the terms, conditions, and notices contained or referenced in these
        Terms of Use.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>
          2. Availment of Service / Use of the Application{" "}
        </span>
        <br></br>
        You will avail of the Service and use the Application only for
        legitimate and lawful purposes, and in accordance with these Terms of
        Use, including the terms of the Privacy Policy as defined in Section 7.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>
          3. Guiding Principles{" "}
        </span>
        <br></br>
        The following principles govern these Terms of Use:
        <br></br>
        {UI.box(10)}
        <span>
          a. Telemedicine services shall follow the standards of practice of
          medicine as defined under Republic Act No. 2382 (the “Medical Act”),
          its Implementing Rules and Regulations, DOH-DILG-PHIC Joint
          Administrative Order No. 2021-0001 (“Guidelines on the Implementation
          of Telemedicine in the Delivery of Individual-Based Health Services”),
          and other applicable policies and guidelines, taking into account the
          absence of physical contact;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          b. The patient-physician relationship shall be based on full knowledge
          of the patient’s medical history and a physical examination given the
          circumstances of a lack of physical contact. Telemedicine shall be
          employed when a licensed physician is physically inaccessible in the
          management of chronic health conditions, or follow-up check-ups after
          initial treatment;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          c. The patient-physician relationship shall be founded on mutual trust
          and respect in which they both identify themselves reliably during a
          telemedicine consultation. In case the patient is referred to a health
          facility, the physician who initially sees the patient shall be
          responsible for the coordination of care;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          d. Emergency and serious conditions, where face-to-face assessment and
          physical contact are most essential, should not be managed via
          telemedicine; and
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          e. The use/implementation of telemedicine shall respect the universal
          principles of ethics, legal standards, and guiding principles on
          primacy of human rights and protection of health privacy as defined by
          Philippine laws, international instruments, rules, and other
          applicable policies.
        </span>
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>
          4. Representations and Warranties{" "}
        </span>
        <br></br>
        The following principles govern these Terms of Use:
        <br></br>
        {UI.box(10)}
        <span>
          a. All the information you provided to us are true and correct in all
          material respects, and you consent to the Company’s processing of the
          same for purposes of your participation in mWell PH’s health advocacy
          campaign;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          b. You are duly licensed to practice medicine by the Professional
          Regulation Commission;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          c. You are a member in good standing of the Philippine Medical
          Association;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          d. You have the specialized skills, expertise and experience to
          provide medical advice to the users on this background;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          e. You have no pending or decided cases and/or complaint for medical
          negligence and/or medical offenses; and
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          f. You comply with all applicable laws and regulations while using the
          Application or while providing services through the Application.
        </span>
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>
          5. Obligations of Doctors{" "}
        </span>
        <br></br>
        The following principles govern these Terms of Use:
        <br></br>
        {UI.box(10)}
        <span>
          a. conduct yourself in a professional manner and shall observe the
          International Code of Medical Ethics and the Code of Ethics as
          approved by the Philippine Medical Association;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          b. comply with all laws and regulations relating to the practice of
          medicine;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          c. comply with applicable industry standards imposed by the Department
          of Health, including standards and regulations for Coronavirus
          (COVID-19);
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          d. comply with other relevant laws, including, but not limited to, the
          Data Privacy Act of 2012 and its implementing rules and regulations
          (“DPA”);
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          e. comply with the standards for personal data protection as set by
          the National Privacy Commission (“NPC”) and the Department of
          Information and Communications Technology in the collection and
          processing of health information and management of privileged
          communication for telemedicine services;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          f. comply with all guidelines that MPHTC shall prescribe in connection
          with the use of the Application;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          g. make yourself available at the days and times scheduled for
          teleconsultations;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          h. have skills, qualifications, eligibility and other information and
          requirements, including status in good standing as a licensed medical
          professional;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          i. provide telemedicine consultations personally, and not delegate the
          same to any other person;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          j. verify the identity of the patient at the start of a telemedicine
          consultation;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          k. protect and respect the right to privacy of the patients and Users
          at all times, and secure the consent of the patient before any video
          or audio recording of the telemedicine consultation is allowed;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          l. exercise professional judgment and discretion in gathering the type
          and extent of patient information required to be able to exercise
          proper clinical judgment, at all times in compliance with the DPA;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          m. provide the same level of care as in a face-to-face consultation to
          Users of the Applications, subject to the intrinsic limitations of
          telemedicine consultations
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          n. request the User for a face-to-face consultation if in your
          professional judgment the same is appropriate in a given situation and
          the specific conditions of the User;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          o. recognize and deem equivalent the electronic clinical abstract,
          consultation summary, prescription, and referral form issued by the
          physician for all intents and purposes; ;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          p. keep records of all electronic clinical abstracts/consultation
          summaries, prescriptions and/or referral forms issued in coordination
          with us;
        </span>
        <br></br>
        {UI.box(10)}
        <span>
          q. issue electronic prescriptions in accordance with FDA Circular No.
          2020-007 and any subsequent FDA guidelines; and
        </span>
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>6. Professional Fee </span>
        <br></br>
        By taking part in mWell’s health advocacy campaign, you acknowledge that
        the telemedicine consultations you perform will be free of charge.
        Accordingly, neither you nor mWell shall collect any fees for such
        telemedicine consultations performed under mWell’s health advocacy
        campaign.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>7. Privacy Policy </span>
        <br></br>
        The Company’s Privacy Policy (the “Privacy Policy”), as may be amended
        from time to time and may be accessible through the Application, is
        hereby incorporated by reference to these Terms of Use.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>8. Changes </span>
        <br></br>
        We reserve the right, in our sole discretion, to amend these Terms of
        Use, or to make changes to the functionality, features, or content of
        the Application or Service at any time and for any reason.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>
          9. Intellectual Property{" "}
        </span>
        <br></br>
        The Service, the Application and all proprietary and intellectual
        property rights therein are and shall be owned by the Company. All
        rights reserved.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>
          10. Term and Termination
        </span>
        <br></br>
        These Terms of Use take full force and effect on the date on which you
        commence availing of the Service for participation in mWell PH’s
        advocacy campaign and shall continue while you avail the Service for the
        said purpose. We reserve the right to terminate or suspend your account
        or access to the Service and Application at any time and for any reason.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>11. Force Majeure </span>
        <br></br>
        We shall not be responsible for damages, delays, or non-performance of
        the Service resulting from acts or occurrences beyond our reasonable
        control.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>
          12. Third-Party Content and Sites{" "}
        </span>
        <br></br>
        The Services may include third-party content and links to other websites
        that are not owned by the Company (“Third-Party Content and Sites”). By
        accessing the Third-Party Content and Sites, you agree to their terms
        and conditions and agree to hold harmless and release the Company, its
        shareholders, subsidiaries, affiliates, officers, directors, employees
        and representatives from any claims, demands and damages of every kind
        and nature, known and unknown, suspected and unsuspected, disclosed and
        undisclosed, arising out of or in any way connected with your use of
        Third-Party Content and/or Sites.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>
          13. Disclaimer of Warranties{" "}
        </span>
        <br></br>
        You acknowledge and agree that you avail the Service and use the
        Application at your own risk. The Service is provided on an “as is” and
        “as available” basis, without warranties of any kind, and to the maximum
        extent permitted by law, the Company, its officers, directors,
        employees, agents, subsidiaries, and affiliates, disclaim all warranties
        express or implied, including, but not limited to, the warranties of
        merchantability, fitness for a particular purpose, title,
        non-infringement, and those arising from course of dealing or usage of
        trade. We do not warrant that you will be able to avail of the Service
        or use or access the Application at the times or locations of your
        choosing; that the Service and use of the Application will be
        uninterrupted or error-free, reliable, accurate, timely, useful,
        adequate, complete or suitable; that defects in the performance of
        Service or in the Application will be corrected; or that the Application
        is free of viruses or other harmful components.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>14. Indemnity </span>
        <br></br>
        You agree to defend, indemnify, and hold the Company, its parent,
        subsidiaries, affiliates, officers, employees, agents, partners, and
        licensors harmless from any losses, costs, liabilities, claims, causes
        of action, and expenses (including reasonable attorneys’ fees) relating
        to or arising out of, directly or indirectly, your acts in using the
        Application, including: (a) any wrong medication, treatment or medical
        negligence; (b) any misrepresentation, misconduct or inappropriate
        behavior; and (c) any direct or indirect medical eventualities that
        might occur subsequent to the provision of your service to the Users
        through the Application. The Company reserves the right, at its own
        cost, to assume the exclusive defense and control of any matter
        otherwise subject to indemnification by you, in which event you will
        fully cooperate with the Company in asserting any available defenses.
        You agree that the provisions in this section will survive the
        termination of your account, the Terms of Use, or your access to the
        Application.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>
          15. Limitation on Liability{" "}
        </span>
        <br></br>
        Other than in case of our fraud or gross negligence, we shall not be
        liable to you for any indirect, incidental, consequential, special,
        punitive, remote or other similar damages, including, but not limited
        to, loss of revenues, lost profits, lost data or business interruption
        or other intangible losses (however such losses are qualified), arising
        out of or relating in any way to these Terms of Use, the Services or the
        use of the Application, whether based on contract, tort or any other
        legal theory, and whether or not we have been advised of the possibility
        of such damages. In circumstances where the foregoing limitation is
        finally determined to be inapplicable, you acknowledge and agree that
        our aggregate liability for any damages, losses, fees, charges, expenses
        or liabilities shall not exceed Fifty Thousand Pesos (PhP 50,000.00) or
        the total amount of your unpaid fees in our possession, whichever is
        higher.
        <br></br>
        In the event of any problem with the Service and/or the Application, you
        agree that your sole remedy is to cease the availment of the Service
        and/or use of the Applications.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>
          16. Data Sharing Agreement{" "}
        </span>
        <br></br>
        You hereby agree that the Users’ or patients’ (“Data Subjects”) data
        that you will obtain from us in the course of using the Service may
        include Personal Information and/or Sensitive Personal Information as
        defined under the DPA, including their name, age, gender, medical
        information and medical history, health complaints, vital signs, results
        of laboratory examinations and other information necessary for diagnosis
        (“Data”). You agree that the Data collected, processed and shared
        pursuant hereto shall be processed and shared exclusively for the
        purpose of your performance of services contemplated herein, unless
        otherwise expressly allowed under the DPA.
        <br></br>
        You acknowledge that the Data will be shared between you and us through
        the Application which are encrypted and has security features that will
        prevent unauthorized access. You will not transmit any document
        containing Data through facsimile or other unencrypted means.
        <br></br>
        You agree that you will (a) not disclose, trade, sell or transfer any
        Data to any person without our prior written instruction; (b) not use or
        process the Data except in connection with the purposes provided herein;
        (c) as applicable, allow Data Subjects to access and correct the Data in
        your custody and to exercise their rights under the DPA, (d) notify us
        if you receive from us any Data that is not covered by this clause, and
        (e) comply with our Cybersecurity Policy.
        <br></br>
        Each of you and us represents and warrants that (a) the collection and
        processing of Data contemplated herein adheres to the principles of
        transparency, legitimate purpose and proportionality, (b) we will fully
        comply with the provisions of these Terms of Use and the DPA, (c) these
        Terms of Use shall be made available for review of the NPC in cases
        required under the DPA; and (d) the rights of the Data Subjects shall be
        upheld without compromising data integrity.
        <br></br>
        We represent and warrant that (a) prior to collecting the Data, or prior
        to the sharing of the Data, we will secure the consent of the Data
        Subject with respect to the collection and further processing of the
        Data and the sharing, storage, transfer and transmission of the same in
        accordance with these Terms of Use. We will secure the Data Subject’s
        consent through written, electronic or recorded means, and (b) we will
        provide the following information to the Data Subjects prior to the
        collection or sharing of Data, (i) identity of the third parties who
        will be given access to the Data, (ii) purpose of the data sharing,
        (iii) categories of Data concerned, (iv) intended recipients of
        categories of recipients of the Data, (v) existence of the rights of the
        Data Subjects, including the right to access and to correct the Data,
        and the right to object to Data sharing or further processing, (vi) if
        applicable, the automated processing of the Data for profiling, and
        (vii) other information that would sufficiently notify the Data Subject
        of the nature and extent of Data sharing and manner of processing.
        <br></br>
        Each of you and us will implement reasonable and appropriate physical,
        technical, and organizational measures for the protection of Data, with
        the aim to maintain the availability integrity and confidentiality of
        Data and protect them against natural dangers such as accidental loss or
        destruction, and human dangers such as unlawful access, fraudulent
        misuse, unlawful destruction, alteration and contamination.
        <br></br>
        In the event of a Personal Data Breach involving the Data while the same
        is being processed by you or is in your custody or control, you agree to
        immediately notify us in writing, and in no case later than six (6)
        hours upon knowledge of the same, or when you have reasonable belief
        that a Personal Data Breach has occurred. Your notification shall
        describe the nature of the breach, the type of Data possibly involved,
        the list of Data Subjects involved, the possible risks, harm or damage
        to the Data Subjects and the measures taken by you to address the
        breach. You agree to include as well your assessment of whether there is
        a need to notify the NPC and the Data Subjects of the breach for our
        consideration.
        <br></br>
        This specific clause shall be co-terminus with the Terms of Use which
        constitutes a legally binding agreement between us, provided that this
        Clause 16 and any extension thereof shall in no case be for more than
        five (5) years.
        <br></br>
        Upon the termination of your availment of the Service, or upon our
        request, you shall promptly destroy, dispose or surrender to us, as
        applicable, the Data and other information and documents containing the
        Data in your possession and if requested by us, you agree to certify
        that the Data and/or copies thereof in your possession have been
        destroyed, disposed of, surrendered or returned in compliance with this
        paragraph.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>
          17. Dispute Resolution and Governing Law{" "}
        </span>
        <br></br>
        These Terms of Use shall be governed by and construed in accordance with
        the laws of the Philippines without reference to conflict of laws
        principles, and disputes arising in relation hereto shall be subject to
        the exclusive jurisdiction of the proper courts of Makati City,
        Philippines.
        <br></br>
        You hereby agree that the Users’ or patients’ (“Data Subjects”) data
        that you will obtain from us in the course of using the Service may
        include Personal Information and/or Sensitive Personal Information as
        defined under the DPA, including their name, age, gender, medical
        information and medical history, health complaints, vital signs, results
        of laboratory examinations and other information necessary for diagnosis
        (“Data”). You agree that the Data collected, processed and shared
        pursuant hereto shall be processed and shared exclusively for the
        purpose of your performance of services contemplated herein, unless
        otherwise expressly allowed under the DPA.
        <br></br>
        You acknowledge that the Data will be shared between you and us through
        the Application which are encrypted and has security features that will
        prevent unauthorized access. You will not transmit any document
        containing Data through facsimile or other unencrypted means.
        <br></br>
        You agree that you will (a) not disclose, trade, sell or transfer any
        Data to any person without our prior written instruction; (b) not use or
        process the Data except in connection with the purposes provided herein;
        (c) as applicable, allow Data Subjects to access and correct the Data in
        your custody and to exercise their rights under the DPA, (d) notify us
        if you receive from us any Data that is not covered by this clause, and
        (e) comply with our Cybersecurity Policy.
        <br></br>
        Each of you and us represents and warrants that (a) the collection and
        processing of Data contemplated herein adheres to the principles of
        transparency, legitimate purpose and proportionality, (b) we will fully
        comply with the provisions of these Terms of Use and the DPA, (c) these
        Terms of Use shall be made available for review of the NPC in cases
        required under the DPA; and (d) the rights of the Data Subjects shall be
        upheld without compromising data integrity.
        <br></br>
        We represent and warrant that (a) prior to collecting the Data, or prior
        to the sharing of the Data, we will secure the consent of the Data
        Subject with respect to the collection and further processing of the
        Data and the sharing, storage, transfer and transmission of the same in
        accordance with these Terms of Use. We will secure the Data Subject’s
        consent through written, electronic or recorded means, and (b) we will
        provide the following information to the Data Subjects prior to the
        collection or sharing of Data, (i) identity of the third parties who
        will be given access to the Data, (ii) purpose of the data sharing,
        (iii) categories of Data concerned, (iv) intended recipients of
        categories of recipients of the Data, (v) existence of the rights of the
        Data Subjects, including the right to access and to correct the Data,
        and the right to object to Data sharing or further processing, (vi) if
        applicable, the automated processing of the Data for profiling, and
        (vii) other information that would sufficiently notify the Data Subject
        of the nature and extent of Data sharing and manner of processing.
        <br></br>
        Each of you and us will implement reasonable and appropriate physical,
        technical, and organizational measures for the protection of Data, with
        the aim to maintain the availability integrity and confidentiality of
        Data and protect them against natural dangers such as accidental loss or
        destruction, and human dangers such as unlawful access, fraudulent
        misuse, unlawful destruction, alteration and contamination.
        <br></br>
        In the event of a Personal Data Breach involving the Data while the same
        is being processed by you or is in your custody or control, you agree to
        immediately notify us in writing, and in no case later than six (6)
        hours upon knowledge of the same, or when you have reasonable belief
        that a Personal Data Breach has occurred. Your notification shall
        describe the nature of the breach, the type of Data possibly involved,
        the list of Data Subjects involved, the possible risks, harm or damage
        to the Data Subjects and the measures taken by you to address the
        breach. You agree to include as well your assessment of whether there is
        a need to notify the NPC and the Data Subjects of the breach for our
        consideration.
        <br></br>
        This specific clause shall be co-terminus with the Terms of Use which
        constitutes a legally binding agreement between us, provided that this
        Clause 16 and any extension thereof shall in no case be for more than
        five (5) years.
        <br></br>
        Upon the termination of your availment of the Service, or upon our
        request, you shall promptly destroy, dispose or surrender to us, as
        applicable, the Data and other information and documents containing the
        Data in your possession and if requested by us, you agree to certify
        that the Data and/or copies thereof in your possession have been
        destroyed, disposed of, surrendered or returned in compliance with this
        paragraph.
      </span>
      {UI.box(10)}

      <span>
        <span style={{ color: UI.colors.secondary }}>
          18. Entire Agreement and Severability{" "}
        </span>
        <br></br>
        These Terms of Use shall constitute the entire agreement between you and
        the Company concerning the Service. If any provision of these Terms of
        Use is deemed invalid by a court of competent jurisdiction, the
        invalidity of such provision shall not affect the validity of the
        remaining provisions of these Terms of Use, which shall remain in full
        force and effect.
      </span>
      {UI.box(10)}
    </View>
  );
};

export const DoctorNotificationCMSModal = ({
  type,
  actionType,
  onDismiss,
  email,
  name,
  number,
  setName,
  setEmail,
  setNumber,
  handleSubmit,
}) => {
  const initial = {
    backgroundColor: "white",
    borderRadius: "18px",
    ...Shadow._3(),
    zIndex: zIndex + 1,
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 800,
  };

  const before = { ...styles.modal_before, ...initial };
  const during = { ...styles.modal_during, ...initial };

  const onCancel = () => {
    if (onDismiss) {
      onDismiss();
    }
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  return (
    <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
      {(style) => (
        <animated.div style={{ ...style, justifyContent: "flex-start" }}>
          <View
            style={{
              ...styles.md_header,
              backgroundColor: UI.colors.secondary,
            }}
          >
            <TouchableOpacity onClick={onCancel} style={styles.md_close}>
              <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="" />
            </TouchableOpacity>

            <View style={styles.md_header_text_container}>
              <Text style={{ ...styles.md_header_text, textAlign: "center" }}>
                {actionType} Reference
              </Text>
            </View>

            {UI.box(60)}
          </View>
          <div className="pt-8 pb-10 px-10">
            <div
              className="text-center font-bold text-lg"
              style={{ color: "#535353" }}
            >
              {actionType} Reference Information?
            </div>

            <div className="mt-8">
              <label
                style={{ color: UI.colors.primary }}
                class="block text-base mb-2"
                for="name"
              >
                Name
              </label>
              <input
                id="name"
                style={{ border: "2px solid #CACACA" }}
                class="appearance-none border rounded-xl w-80 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline text-base"
                type="text"
                value={name}
                onChange={handleChange}
              />
            </div>

            {type === "email" && (
              <div className="mt-8">
                <label
                  style={{ color: UI.colors.primary }}
                  class="block text-base mb-2"
                  for="email"
                >
                  Email Address
                </label>
                <input
                  id="email"
                  style={{ border: "2px solid #CACACA" }}
                  class="appearance-none border rounded-xl w-80 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline text-base"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            )}

            {type === "number" && (
              <div className="mt-8">
                <label
                  style={{ color: UI.colors.primary }}
                  class="block text-base mb-2"
                  for="number"
                >
                  Contact Number
                </label>
                <input
                  id="number"
                  style={{ border: "2px solid #CACACA" }}
                  class="appearance-none border rounded-xl w-80 py-2 px-3 leading-tight focus:outline-none focus:shadow-outline text-base"
                  type="text"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </div>
            )}

            <div className="mt-12 flex justify-center">
              <button
                onClick={handleSubmit}
                className="p-3 font-semibold rounded-md text-white text-center flex items-center justify-center"
                style={{ background: "#0E4D94", minWidth: 135 }}
              >
                Save
              </button>
              <button
                className="p-3 font-semibold flex rounded-md bg-white ml-4"
                style={{ color: "#0E4D94", border: "1px solid #0E4D94" }}
              >
                No, Don't Save
              </button>
            </div>
          </div>
        </animated.div>
      )}
    </Spring>
  );
};

// import React, { useState } from 'react'
// import TouchableOpacity, { StyleSheet, Text, View } from '../../shared/custom-react-native';
// import { Button, Row } from "antd";
// import styled from 'styled-components';
// import UI from '../../shared/Components/UI/js';
// import QRCode from "qrcode.react";

export const PreviewMarketingQrCodeReferralModal = ({_this}) => {
  const [isDownload, setIsDownload] = useState(false)
  const download = async () => {
    setIsDownload(true)
    setTimeout(async () => {
      const canvas = await html2canvas(document.getElementById("main_qr_id_content"), {
        useCORS: true,
      });
      // const dataURL = canvas.toDataURL('image/png');
      // downloadjs(dataURL, 'download.png', 'image/png');
      
      let anchor = document.createElement("a");
      anchor.href = canvas.toDataURL("image/png");
      anchor.download = "QRCODE.PNG";
      anchor.click();
      _this.hide_modal()
      setIsDownload(false)
    }, 100);
  }

  return (
    <div className='flex items-center justify-center'>
      <TableBox>
        <View
          style={{ ...styles.md_header, backgroundColor: UI.colors.secondary }}
          >
          <TouchableOpacity
            onClick={() => {
                _this.hide_modal();
            }}
            style={styles.md_close}
          >
              <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="CLOSE" />
          </TouchableOpacity>

          <View style={{ ...styles.md_header_text_container, marginLeft: 50 }}>
              <Text style={styles.md_header_text}>Preview QR CODE</Text>
          </View>
        </View>
        <div 
          className='flex justify-center'
          style={{
            backgroundImage:`${`url(${UI.QR_CODE_BG})`} `, backgroundSize:'cover',
            width: "100%",
            height: "100%",
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10
          }}
          id={"main_qr_id_content"}
        >
          <View style={{ marginTop: 30, marginBottom: 30, gap: 20,  }}>
            <div style={{ width: 290, display:'flex', flexDirection: "column", alignItems:"center", gap: 20 }}>
              <QRCode
                includeMargin={true}
                size={290}
                style={{  color:'gray', background:'red'}}  
                value={_this.state.link}
                renderAs="canvas" 
              />
              <Text style={{color: UI.colors.secondary, fontSize:16}}>
                {_this.state?.name}
              </Text>
            </div>
            {
              <StyledButton 
                type="primary" 
                onClick={download}
                style={{...isDownload && {background: "transparent", color: "transparent"}}}
              >
              Download QR
            </StyledButton>
            }
          </View>
          
        </div>

      </TableBox>
    </div>
  )
}

export const DefaultModal = ({
  title,
  onDismiss,
  children,
}) => {

  const initial = {
    backgroundColor: "white",
    borderRadius: "18px",
    ...Shadow._3(),
    zIndex: zIndex + 1,
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 800,
  };

  const before = { ...styles.modal_before, ...initial };
  const during = { ...styles.modal_during, ...initial };

  const onCancel = () => {
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <Spring from={before} to={during} delay={0} config={{ duration: 200 }}>
      {(style) => (
        <animated.div style={{ ...style, justifyContent: "flex-start" }}>
          <View
            style={{
              ...styles.md_header,
              backgroundColor: UI.colors.secondary,
            }}
          >
            <TouchableOpacity onClick={onCancel} style={styles.md_close}>
              <img style={{ height: 15, width: 15 }} src={UI.CLOSE} alt="" />
            </TouchableOpacity>

            <View style={styles.md_header_text_container}>
              <Text style={{ ...styles.md_header_text, textAlign: "center" }}>
                {title}
              </Text>
            </View>

            {UI.box(60)}
          </View>
          <ScrollView style={{ height: "auto" }}>{children}</ScrollView>
        </animated.div>
      )}
    </Spring>
  );
};

const StyledButton = styled(Button)`
  height: 50px;
  border-radius: 12px;
  border: none;
  background-color: #0e4d94;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.9375rem; // 15px
  line-height: 1.4375rem; // 23px
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    background-color: #43c8ff;
  }
`;

const TableBox = styled(Row)`
  display: flex;
  padding: 0px 0 0 0;
  border-radius: 12px;
  box-shadow: 0px 3px 10px #bfbfbf29;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-family: Poppins;
  align-items: center;
  justify-content: center;
`;
const styles = StyleSheet.create({
  md_btn_text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
  md_button: {
    padding: 30,
    backgroundColor: "#004F99",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  md_button_outline: {
    padding: 30,
    backgroundColor: "#FFFFFF",
    border: "1px solid #004F99",
    color: "#004F99",
    fontWeight: "bold",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  optionLabel: {
    marginLeft: "1em",
    color: "black",
    fontWeight: "bold",
    fontSize: 18,
  },
  simpleBody: {
    padding: 2,
    color: "black",
    fontSize: 16,
  },
  md_text_content: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 14,
    color: "#004F99",
    width: 230,
  },
  md_content_container: {
    padding: 25,
    alignItems: "center",
  },
  md_header_text: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  md_header_text_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  md_close: {
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  md_header: {
    height: 60,
    width: "100%",
    backgroundColor: "#00CBFF",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    flexDirection: "row-reverse",
  },
  modal_description_text: {
    color: "#585858",
    fontSize: 16,
    alignSelf: "flex-start",
    fontWeight: "bold",
  },
  modal_header_text: {
    color: "#3E3E3E",
    fontSize: 22,
    fontWeight: "bold",
    alignSelf: "flex-start",
  },
  modal_btn_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: "white",
  },
  modal_black_btn: {
    padding: 20,
    borderRadius: 10,
    backgroundColor: "#111111",
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 20,
  },
  modal_description: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#434343",
    textAlign: "center",
  },
  modal_title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#0E0E0E",
    marginTop: 20,
    textAlign: "center",
  },
  modal_during: {
    opacity: 1,
    minHeight: 220,
    minWidth: 220,
    marginTop: 0,
  },
  modal_before: {
    opacity: 0,
    minHeight: 100,
    minWidth: 100,
    marginTop: 200,
  },
  default_modal: {
    backgroundColor: "white",
    borderRadius: 10,
    ...Shadow._3(),
    zIndex: zIndex + 1,
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    maxWidth: 320,
  },
  modal_container: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    zIndex: zIndex,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    // backgroundColor: "rgba(255,255,255,0.85)",
  },
  option_label: {
    color: "black",
    fontSize: "1.2em",
  },
  option_button: {
    width: 30,
    height: 30,
    border: 0,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: "black",
  },
});
