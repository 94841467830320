import React, { useState, useEffect } from 'react'
import UI from '../../shared/Components/UI/js'
import { View, Image, TouchableOpacity, } from '../../shared/custom-react-native'

const PatientDetails = (props) => {
  const { appointment, width, } = props._this.state;
  const account = appointment?.user;
  const user = appointment?.patient;
  const phone = account?.phone;
  const gender = UI.capitalize(user?.gender);

  const patientName = user?.name[0].given + " " + (user?.name[0].middle ?? "") + " " + user?.name[0].family;
  // console.log("name:",user?.name[0].middle ?? "");

  const [dialCode, setDialCode] = useState('+63'); // default PH
  useEffect(() => {
    if(appointment?.user?.countryCode) {
      const dialCodePromise = UI.getDialCountryCode(appointment.user.countryCode);
      dialCodePromise.then( (dialCode) => {
        if(dialCode) {
          setDialCode(dialCode);
        }
      }).catch((error) => {
        console.log("error here", error);
      })
    }
  // Since the method is outside useEffect we will disable ruling here and still only executes on Mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const getWeight = (user) => {
  //   const weight = user?.weight  || 0.00
  //   const weightDecimal = (Math.round(weight * 100) / 100).toFixed(2);
  //   return weightDecimal
  // }

  // const getHeight = (user) => {
  //   const height = user?.height  || 0.00
  //   const heightDecimal = (Math.round(height * 100) / 100).toFixed(2);
  //   return heightDecimal
  // }
  
  // {
  //   (user?.weight || user?.height) ? (Math.round(((getWeight(user) / getHeight(user) / getHeight(user)) * 10000) * 100) / 100).toFixed(2) : (0.00).toFixed(2)
  // }

  const formatMobile = (mobile) => {
    mobile = mobile.replace("+63", "");
    return " " + mobile.slice(0, 3) + " " + mobile.slice(3, 6) + " " + mobile.slice(6);
  }

  return (
    <View style={{position: 'relative', borderRadius: '15px', overflow: 'auto'}}>
      <View style={{marginBottom:'0px',
        border: "2px dashed #E6E6E6",
        borderRadius: '15px',
        padding:'15px 20px',
        position: 'relative'
        }}
      >
        <View style={{flexDirection: width > 800 ? 'row' : 'column', gap: width < 800 && 0, marginRight: 10}}>
          
          <View style={{flex: "1 1 30%", marginRight: 10}}>
            <span className='font-semibold' style={{color:'#0E4D94', fontSize:'16px'}}>{`${UI.capitalizeName(patientName)}`}</span>
            <label style={{color:UI.colors.primary, fontSize: 13, fontWeight: "bold"}}>
              <span>{phone ? (dialCode + formatMobile(phone)) : ""}</span>
              <img              
                style={{ borderRadius: "50%", display: "inline-block", margin: "0 5px", width: 20 }}
                src={"https://hatscripts.github.io/circle-flags/flags/ph.svg"}
                alt=""
              />
              <span>PH</span>
            </label>
          </View>

          <View style={{flex: "1 1 10%", marginRight: 10}}>
            <span className='font-semibold' style={{color:'#0E4D94', fontSize:'16px'}}>{UI.getAge(user?.birthDate) || "0"} y/o</span> 
            <label style={{color:UI.colors.unset}}>Age</label>
          </View>

          <View style={{flex: "1 1 10%", marginRight: 10}}>
            <span className='font-semibold' style={{color:'#0E4D94', fontSize:'16px'}}> 
              <strong className="flex-1">{ gender }</strong>
            </span>
            <label style={{color:UI.colors.unset}}>Gender</label>
          </View>

          <View style={{flex: "1 1 15%", marginRight: 10}}>
            <span className='font-semibold' style={{color:'#0E4D94', fontSize:'16px'}}>{UI.getDateOnly(`${user?.birthDate || "" }`) === "Invalid date" ? "N/A" : UI.getDateOnly(`${user?.birthDate ?? "" }`)}</span>
            <label style={{color:UI.colors.unset}}>Birthdate</label>
          </View>

          {
            user?.relationshipToUser === "Myself" &&
            <View style={{flex: "1 1 10%", marginRight: 10}}>
              <span className='font-semibold' style={{color:'#0E4D94', fontSize:'16px'}}><strong className="flex-1">{(Math.round((account?.height  || 0) * 100) / 100).toFixed(2)  } cm</strong></span>
              <label style={{color:UI.colors.unset}}>Height</label>
            </View>
          }
          {
            user?.relationshipToUser === "Myself" &&
            <View style={{flex: "1 1 10%", marginRight: 10}}>
              <span className='font-semibold' style={{color:'#0E4D94', fontSize:'16px'}}><strong className="flex-1">{(Math.round((account?.weight  || 0) * 100) / 100).toFixed(2) } kg</strong></span>
              <label style={{color:UI.colors.unset}}>Weight</label>
            </View>
          }
        </View>

        {UI.box(10)}

        <View>
          <span className='w-4/5 font-semibold' style={{color:'black'}}>{appointment?.comment}</span>
          <h2 style={{color:UI.colors.lightGray, fontSize: 13}}>Reason for Visit</h2>
        </View>
      </View>

      <View style={{position: 'absolute', right: 0}}>
        <TouchableOpacity
          onClick={async () => {
            props._this.showPatientDetails();
          }}
        >
          <Image
            height={42}
            width={42}
            tintColor={"transparent"}
            source={UI.CORNER_EDIT_BTN}
          />
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default PatientDetails

// const styles = {
//   modal_during: {
//     opacity: 1,
//     minHeight: 220,
//     minWidth: 220,
//     marginTop: 0,
//   },
//   modal_before: {
//     opacity: 0,
//     minHeight: 100,
//     minWidth: 100,
//     marginTop: 200,
//   },
//   md_button: {
//     padding: 30,
//     backgroundColor: "#004F99",
//     borderRadius: 10,
//     justifyContent: "center",
//     alignItems: "center",
//     paddingTop: 10,
//     paddingBottom: 10,
//   },
//   md_btn_text: {
//     color: "white",
//     fontSize: 14,
//     fontWeight: "bold",
//   },
//   md_close: {
//     height: 60,
//     width: 60,
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   md_header: {
//     height: 60,
//     width: "100%",
//     backgroundColor: "#00CBFF",
//     borderTopRightRadius: 10,
//     borderTopLeftRadius: 10,
//     flexDirection: "row-reverse",
//   },
//   md_header_text: {
//     fontSize: 18,
//     color: "white",
//     fontWeight: "bold",
//   },
//   md_content_container: {
//     padding: 25,
//     alignItems: "center",
//   },
//   md_header_text_container: {
//     flex: 1,
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   optionLabel: {
//     marginLeft: "1em",
//     color: "black",
//     fontWeight: "bold",
//     fontSize: 18,
//   },
//   option_button: {
//     width: 30,
//     height: 30,
//     border: 0,
//   },
//   title: {
//     fontSize: 14,
//     fontWeight: "bold",
//     color: UI.colors.primary,
//   },
// }