import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TimePicker } from "antd";
import UI from "../../shared/Components/UI/js";
import 
// TouchableOpacity,
 {
  // Image,
  mem,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import Layout from "../_components/Layout";
import { Failed, Success } from "../_components/Modal";

const context = "advocacy";
export default class AddAdvocacyScheule extends Component {
  constructor(props) {
    super(props); 
    this.state = {
      context:context,
      isValidTimeFormat: false,
      startTime:"",
      endTime:""
    }

  }

  componentWillMount = () => {
    // const { start_date_date, end_date_date} = this.props._this.state
    // let endDate = moment(end_date_date);
    // var startDate = moment(start_date_date).subtract(1, "days");
    // let days = []

    // while(endDate.date() !== startDate.date()){
    //   startDate.add(1, 'day'); 
    //   days.push(
    //   {
    //     date: moment(startDate._d).format("LLLL").split(',')[0] + ", " + moment(startDate).format('MMMM D YYYY'),
    //     morning_start_time: "",
    //     morning_end_time: "",
    //     afternoon_start_time: "",
    //     afternoon_end_time: "",
    //   }
    //     // {day: moment(startDate._d).format("LLLL").split(',')[0]}
    //   )
    // }

    // this.props._this.setState({ advocacySchedule: days})
    // mem.save('advocacy_schedule', JSON.stringify(days))
    console.log('first')
  }

  setDefaultValueTime = (slots, session, id) => {
    
    if (id === 1) {
      if (slots.morning_start_time === "") {
        return "";
      } else {
        const format24Hours = moment(slots.morning_start_time, ["h:mm a"]).format('HH:mm')
        const m = moment(new Date());
        const minutes = format24Hours.split(":")[1]
        const hourFomatTo24Hours = format24Hours.slice(0, 2);
        m.set({ h: parseFloat(hourFomatTo24Hours), m: parseFloat(minutes) });
        return moment(m);
      }
    }

    if (id === 2) {
      if (slots.morning_end_time === "") {
        return "";
      } else {
        const format24Hours = moment(slots.morning_end_time, ["h:mm a"]).format('HH:mm')
        const m = moment(new Date());
        const minutes = format24Hours.split(":")[1]
        const hourFomatTo24Hours = format24Hours.slice(0, 2);
        m.set({ h: parseFloat(hourFomatTo24Hours), m: parseFloat(minutes) });
        return moment(m);
      }
    }

    if (id === 3) {
      if (slots.afternoon_start_time === "") {
        return "";
      } else {
        const format24Hours = moment(slots.afternoon_start_time, ["h:mm a"]).format('HH:mm')
        const m = moment(new Date());
        const minutes = format24Hours.split(":")[1]
        const hourFomatTo24Hours = format24Hours.slice(0, 2);
        m.set({ h: parseFloat(hourFomatTo24Hours), m: parseFloat(minutes) });
        return moment(m);
      }
    }

    if (id === 4) {
      if (slots.afternoon_end_time === "") {
        return "";
      } else {
        const format24Hours = moment(slots.afternoon_end_time, ["h:mm a"]).format('HH:mm')
        const m = moment(new Date());
        const minutes = format24Hours.split(":")[1]
        const hourFomatTo24Hours = format24Hours.slice(0, 2);
        m.set({ h: parseFloat(hourFomatTo24Hours), m: parseFloat(minutes) });
        return moment(m);
      }
    }

  }

  onChangeTime = (time, timeString, res, session, index, isStart=false) => {

    if (session === "morning") {
      if(timeString.includes('pm') && !timeString.includes('12')) {
        this.props._this.show_modal(<Failed
          title={"Failed"}
          description={"Invalid Time Format"}
          onDismiss={() => {
            this.props._this.hide_modal();
          }}
        />)
      } else {
        const advocacySchedule = this.props._this.state.advocacySchedule.map((res, i) => {
          if(index === i) {
            if(isStart) {
              res.morning_end_time = timeString
            } else {
              res.morning_start_time = timeString
            }
          }
          return res
        })
        this.props._this.setState({advocacySchedule: advocacySchedule})
      }
      return
    } 

    if (session === "afternoon") {
      if(timeString.includes('am') && !timeString.includes('12')) {
        this.props._this.show_modal(<Failed
          title={"Failed"}
          description={"Invalid Time Format"}
          onDismiss={() => {
            this.props._this.hide_modal();
          }}
        />)
      } else {
        const advocacySchedule = this.props._this.state.advocacySchedule.map((res, i) => {
          if(index === i) {
            if(isStart) {
              res.afternoon_end_time = timeString
            } else {
              res.afternoon_start_time = timeString
            }
          }
          return res
        })
        this.props._this.setState({advocacySchedule: advocacySchedule})
      }
      return
    }


    // console.log(timeString)
    // const advocacySchedule = this.props._this.state.advocacySchedule.map((res, i) => {
    //   if(index === i) {
    //     if (session === "morning") {
    //       if(isStart) {
    //         res.morning_end_time = timeString
    //       } else {
    //         res.morning_start_time = timeString
    //       }
    //       // res.morning_start_time = timeString
    //     }
    //     if (session === "afternoon") {
    //       if(isStart) {
    //         res.afternoon_end_time = timeString
    //       } else {
    //         res.afternoon_start_time = timeString
    //       }
    //     }
    //   }
    //   return res
    // })


    // this.props._this.setState({advocacySchedule: advocacySchedule})
  }
  
  addSchedule = async () => {

    for(const validateSlot of this.props._this.state.advocacySchedule) {
      
      // if (!validateSlot.morning_start_time || !validateSlot.morning_end_time || !validateSlot.afternoon_start_time || !validateSlot.afternoon_end_time) {
      //   return this.props._this.show_modal(
      //     <Failed
      //       title={"Failed"}
      //       description={`Schedule is empty.`}
      //       onDismiss={() => {
      //         this.props._this.hide_modal();
      //       }}
      //     />
      //   );
      // }
      // || (!validateSlot.afternoon_start_time && !validateSlot.afternoon_end_time)
      if (((validateSlot.morning_start_time && !validateSlot.morning_end_time))) {
        return this.props._this.show_modal(
          <Failed
            title={"Failed"}
            description={`Morning end time schedule is empty.`}
            onDismiss={() => {
              this.props._this.hide_modal();
            }}
          />
        );
      }

      if (((!validateSlot.morning_start_time && validateSlot.morning_end_time))) {
        return this.props._this.show_modal(
          <Failed
            title={"Failed"}
            description={`Morning start time schedule is empty.`}
            onDismiss={() => {
              this.props._this.hide_modal();
            }}
          />
        );
      }

      if (((validateSlot.afternoon_start_time && !validateSlot.afternoon_end_time))) {
        return this.props._this.show_modal(
          <Failed
            title={"Failed"}
            description={`Afternoon end time schedule is empty.`}
            onDismiss={() => {
              this.props._this.hide_modal();
            }}
          />
        );
      }

      if (((!validateSlot.afternoon_start_time && validateSlot.afternoon_end_time))) {
        return this.props._this.show_modal(
          <Failed
            title={"Failed"}
            description={`Afternoon start time schedule is empty.`}
            onDismiss={() => {
              this.props._this.hide_modal();
            }}
          />
        );
      }

      if (parseInt(validateSlot.morning_start_time.split(':')[0]) >= parseInt(validateSlot.morning_end_time.split(':')[0]) && !validateSlot.morning_start_time.includes('12')) {
        return this.props._this.show_modal(
          <Failed
            title={"Failed"}
            description={`Invalid time format on morning session`}
            onDismiss={() => {
              this.props._this.hide_modal();
            }}
          />
        );
      }

      if (parseInt(validateSlot.afternoon_start_time.split(':')[0]) >= parseInt(validateSlot.afternoon_end_time.split(':')[0]) && !validateSlot.afternoon_start_time.includes('12')) {
        return this.props._this.show_modal(
          <Failed
            title={"Failed"}
            description={`Invalid time format on afternoon session`}
            onDismiss={() => {
              this.props._this.hide_modal();
            }}
          />
        );
      }
    }

    if (this.props._this.state.isAddSurvey) {
      mem.save('advocacy_schedule', JSON.stringify(this.props._this.state.advocacySchedule))
      this.props._this.setState({isNext: 3})
    } else {
      const { 
        title, 
        start_date_date, 
        end_date_date, 
        // consultationAvailability, 
        advocacySchedule, 
        isAddSurvey, 
        status, 
        consultationTimeLimit, 
        description, 
        image_file, 
        healthpass, 
        comming_soon, 
        isConsultNow,
        isConsultLater,
        mobile_image_file, 
        event_title,
        join_button_title,
        isShowJoinButton,
      } = this.props._this.state;
      
      // return
      const payload = {
        title: title,
        startDate: start_date_date,
        validUntil: end_date_date,
        schedule: JSON.stringify(advocacySchedule),
        survey: JSON.stringify([]),
        consultationTimeLimit: consultationTimeLimit,
        description: description,
        status:status,
        comingSoon: comming_soon,
        healthpass: JSON.stringify(healthpass),
        consultationAvailability: JSON.stringify({
          showConsultNow: isConsultNow,
          showConsultLater: isConsultLater
        }),
        image: image_file,
        isAddSurvey: isAddSurvey,
        mobile_image: mobile_image_file, 
        event_title: event_title,
        button_title: join_button_title,
        is_join_button: isShowJoinButton,
      }
      
      this.props._this.show_loading();
  
      UI.webRequest({
        method: "post",
        url: "admin/advocacies/create-advocacy",
        params: payload,
        isMultiPart: true,  
        onFail: async (err) => {
          const data = err.data;
          const error_message = data.error;
          this.props._this.show_modal(
            <Failed
              title={"Failed"}
              description={error_message}
              onDismiss={() => {
                this.props._this.hide_modal();
              }}
            />
          );
        },
        onSuccess: async (response) => {
          this.props._this.show_modal(
            <Success
              title={"Success!"}
              descriptionCenter={"Successfully added the advocacy."}
              onDismiss={() => {
                this.props._this.hide_modal();
                UI.goTo("/advocacy/list");
              }}
            />
          );
        },
        onFinish: () => {
          this.props._this.hide_loading();
        },
      });
    }
  }

  render() {
    const _this = this.props._this;
    const { advocacySchedule } = _this.state;

    return (
      <View>
         <UI.Row _this={_this} breakpoint={950} style={{ width: "100%", padding: "20px 20px 10px 10px" }}>
          <View style={{flex:1}}>
            <span className="font-semibold" style={{color: UI.colors.secondary, fontSize: 18}}>Set Advocacy Schedule</span>
          </View>
          <View>
            <span className="font-semibold" style={{color: UI.colors.unset, fontSize: 18}}>Set advocacy start/end time per day</span>
          </View>
        </UI.Row>

        
        <View id="advocacy_schedule" style={{ padding: "10px 30px 0px 0px" }} _this={_this}>
          <UI.Row style={{ padding: "0px 10px", gap: 50 }} breakpoint={1053} _this={_this}>
            {
              advocacySchedule?.map((res, index) => {
                return (
                  <View>
                    <span className="font-semibold" style={{fontSize: 16}}>{res.date}</span>
                    {UI.box(10)}
                    <Text style={{color:UI.colors.primary}}>Morning</Text>
                    {UI.box(5)}
                    <TimePicker 
                      status="warning" 
                      value={this.setDefaultValueTime(res, "morning",1)}
                      minuteStep={30} 
                      use12Hours 
                      format="h:mm a" 
                      placeholder="Start Time"
                      onChange={(time, timeString) => { 
                      this.onChangeTime(time, timeString, res, "morning", index);
                    }}/>
                    {UI.box(10)}
                    {UI.box(5)}
                    <TimePicker 
                      value={this.setDefaultValueTime(res, "morning",2)}
                      status="warning" 
                      minuteStep={30} 
                      use12Hours 
                      format="h:mm a" 
                      placeholder="End Time"
                      onChange={(time, timeString) => { 
                      const isStart = true;
                      this.onChangeTime(time, timeString, res, "morning", index, isStart,);
                    }}/>
                    {UI.box(10)}
                    <Text style={{color:UI.colors.primary}}>Afternoon</Text>
                    {UI.box(5)}
                    <TimePicker 
                      // value={this.setDefaultValueTime(slots, "sunday", "morning", "start")}
                      value={this.setDefaultValueTime(res, "afternoon",3)}
                      status="warning" 
                      minuteStep={30} 
                      use12Hours 
                      format="h:mm a" 
                      placeholder="Start Time"
                      onChange={(time, timeString) => { 
                      // const id = 1;
                      this.onChangeTime(time, timeString, res, "afternoon",  index);
                    }}/>
                    {UI.box(10)}
                    {UI.box(5)}
                    <TimePicker 
                      value={this.setDefaultValueTime(res, "afternoon",4)}
                      // value={this.setDefaultValueTime(slots, "sunday", "morning", "start")}
                      status="warning" 
                      minuteStep={30} 
                      use12Hours 
                      format="h:mm a" 
                      placeholder="End Time"
                      onChange={(time, timeString) => { 
                      const isStart = true;
                      this.onChangeTime(time, timeString, res, "afternoon",  index, isStart);
                    }}/>
                  </View>
                )
              })
           
            }
          </UI.Row>
        </View>

        {UI.box(30)}
        
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Layout.Btn
            color={"#E00000"}
            onClick={() => {
              // _this.loadAddAdvocacyFields()
              _this.setState({isNext: 1})
            }}
            text={"BACK"}
          />

          {UI.box(20)}

          <Layout.Btn
            color={UI.colors.primary}
            onClick={() => {
              this.addSchedule();
            }}
            text={this.props._this.state.isAddSurvey ? "NEXT" : "SAVE CHANGES"}
          />
          {UI.box(20)}

          <Text style={styles.text_2}>
            Step 2 of {this.props._this.state.isAddSurvey ? "3" : "2"}
          </Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: UI.colors.secondary,
    fontWeight:'bold'
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
});
