import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Shadow,
  StyleSheet,
  Text,
  View,
  Image,
} from "../../shared/custom-react-native";
import CustomPieChart from "../_components/CustomPieChart";
import MainLayout from "../_components/MainLayout";
import "./css.css";
import Layout from "../_components/Layout";
import { Doughnut, Line } from "react-chartjs-2";

const context = "advocacy";

export default class AdvocacyReports extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Reports",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
      item: {
        participants: {},
        top5Advocacies: [],
      },
      participantsData: [],
      isLoading: true,
      top5Advocacies: [],
      participants:{},
      will_update: true,
      advocacies:[],
      advocacy:'',

      label: "",
      value: null,
      color: UI.colors.primary,

      current_date: UI.timestampToDate(new Date().getTime()),

      duration: "year", //month, day, year
      data: [],
    });
  }


  onCreate = async () => {
    this.getParticipants()
    this.getTop5Advocacies()
    this.get_advocacies()
  };

  get_advocacies = async () => {
    UI.webRequest({
      method: "get",
      url: "admin/advocacies",
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState({advocacies: data.data})
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };
  
  getTop5Advocacies = (params="all") => {
    UI.webRequest({
      method: "get",
      url: "admin/advocacies/top5-advocacies-data",
      params: {
        participants:params
      },
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            top5Advocacies: data.data,
          },
          () => {
            this.reload_table("advocacy");
          }
        );
      },
      onFinish: () => {
        this.setState({
          isLoading: false,
        });
        this.hide_loading();
      },
    });
  }

  getParticipants = (params = "total") => {
    UI.webRequest({
      method: "get",
      url: "admin/advocacies/participants-data",
      params: {
        participants: params
      },
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.setState(
          {
            participants: data.data,
            participantsData:
            [
              {
                color: "#00CBFF",
                label: "Registered Patients",
                count: data.data.patient,
                onSelect: (item) => {},
              },
              {
                color: "#0064C3",
                label: "Registered Doctors",
                count: data.data.doctor,
                onSelect: (item) => {},
              },
            ]
          },
          () => {
            this.reload_table("advocacy");
          }
        );
        
      },
      onFinish: () => {
        this.setState({
          isLoading: false,
        });
        this.hide_loading();
      },
    });
    
  }

  export_to_csv_survey = async () => {
    UI.webRequest({
      method: "get",
      url: `admin/advocacies/survey/${this.state.advocacy_id ? this.state.advocacy_id : this.state.advocacies[0]._id}`,
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response).data;
        let csv_data = []

        for (let [item] of Object.entries(data.surveyData)) {
          csv_data.push({
            Name: item?.user?.firstName +  " " + item?.user?.lastName?? " ",
            Email: item?.user?.email ?? " ",
            SurveyQuestion: item.survey.length > 0 ? item.survey[0].id?.question ?? " " : '',
            SurveyAnswer: item.survey.length > 0 ? item.survey[0].answer : '',
          })
          for (let i = 0; i < item.survey.length; i++) {
            if (i > 0) {
            csv_data.push({
              Name:  " ",
              Email: " ",
              SurveyQuestion:item.survey[i].id?.question ?? " ",
              SurveyAnswer:item.survey[i].answer,
            })
            }
          }
        }

        // const csv_data = data.surveyData.map((item, index) => {
      
        //   let d = {
        //     "Name": item?.user?.firstName +  " " + item?.user?.lastName?? " ",
        //     "Email": item?.user?.email ?? " ",
            
        //     // [item.survey.map(a => "Answer " + count)] : item.survey.map(a => a.answer)
        //     // ...b
        //   };
          
        //   for (let [key, value] of Object.entries(item.survey)) {
        //     const keys = Object.keys(value)[1]
        //     // d = {...d, ...{[keys]: value.answer}}
        //     Object.assign(d, {[`${keys} ${ ++key}_`]: value.answer})
        //   }
        //   console.log(d, "DDDDDD")

        //   return d;
        // });

        UI.DownloadCSV(
          `Survey for ${this.state.advocacy ? this.state.advocacy.title : this.state.advocacies[0].title} ` + UI.get_current_date_string(),
          csv_data
        );
        this.hide_modal();
      },
      onFinish: () => {
        this.hide_loading();
      },
    });

    // const { item } = this.state;

    // const csv_data = item.doctor.map((item, index) => {
    //   const d = {
    //     Row: index + 1,
    //     "Participant Registered Doctor": item.doctorJoined ?? " ",
    //     "Participant Registered Patient": item.consultType ?? " ",
    //     "Consult Date": item.consultDate?? " ",
    //     "Consult Status": item.consultStatus ?? " ",
    //   };

    //   return d;
    // });

    // UI.DownloadCSV(
    //   "All Registered Patients " + UI.get_current_date_string(),
    //   csv_data
    // );
  };
  
  
  processActive = (tooltipItem) => {
    let dataset = tooltipItem.dataset;
    let index = tooltipItem.dataIndex;
    let label = tooltipItem.label;
    let count = tooltipItem.formattedValue;
    let value = dataset.data[index];
    let color = dataset.backgroundColor[index];

    if (this.state.will_update) {
      this.setState(
        {
          label,
          color,
          value,
          count,
          will_update: false,
        },
        () => {
          this.setState({
            label,
            color,
            value,
            count,
            will_update: false,
          });
        }
      );
    }

    clearTimeout(this.hover_timeout);

    this.hover_timeout = setTimeout(() => {
      this.setState({
        will_update: true,
      });
    }, 150);
  };

  render() {
    const _this = this;
    const { width, isLoading, participantsData } = _this.state;

    let datasets = participantsData.map((item) => {
      return item.count;
    });

    let labels = participantsData.map((item) => {
      return item.label;
    });

    let colors = participantsData.map((item) => {
      return item.color;
    });

    const externalTooltipHandlers = (item) => {
      if (item.tooltip.dataPoints) {
        this.processActive(item.tooltip.dataPoints[0]);
      }
    };

    let user_stats_container = UI.measure("user_stats_container").width;
 
    return (
      <MainLayout context={context} _this={_this}>
        <View
          id={"user_stats_container"}
          style={{  display:'flex',
          flexDirection: width > 800 ? 'row' : 'column',
          alignItems: "center",
          backgroundColor:'white',
          padding: "15px 20px",
          borderRadius: 5,
          ...Shadow._3(),
          justifyContent:'space-between'
          }}
        >
          <Text style={{color:UI.colors.secondary, fontWeight:600, fontSize:'19px'}}>System Reports</Text>
          <View style={{flexDirection: width > 800 ? 'row' : 'column', alignItems:'center'}}>
            <Text style={{color:UI.colors.unset, fontWeight:600, fontSize:'14px'}}>View and manage reports here</Text>
            {UI.box(10)}
            <View
              style={{
                alignSelf: width > 950 ? "flex-start" : "center",
                // marginTop: 8,
                marginLeft: 10,
              }}
            >
              {/* <Layout.Btn
                color={UI.colors.primary}
                text={"Download Report"}
                onClick={() => {
                  this.export_to_csv();
                }}
              /> */}
            </View>
          </View>
        </View>
        
        {UI.box(20)}

        <UI.Row breakpoint={1070} _this={_this} style={{ width: "100%" }}>

          {/* START OF LEFT PIE CHART */}

          <View
            id={"user_stats_container"}
            style={{ ...styles.main_content_container, flex: 1, padding: 0, marginTop:20 }}
          >
            <UI.Row
              breakpoint={420}
              _this={_this}
              basis={user_stats_container}
              style={{ width: "100%", padding: 20 }}
            >

              {UI.box(10)}

              <UI.Row breakpoint={1170} _this={_this} style={{ width: "100%" }}>
                <Text style={styles.box_header_text}>Top 5 Advocacies</Text>
                {UI.box(10)}
                <Layout.SortFilter
                  btn_1={{
                    text: "All",
                    onClick: () => {
                      this.getTop5Advocacies('all')
                    },
                  }}
                  btn_2={{
                    text: "By Patients",
                    onClick: () => {
                      this.getTop5Advocacies('patients')
                    },
                  }}
                  btn_3={{
                    text: "By Doctors",
                    onClick: () => {
                      this.getTop5Advocacies('doctors')
                    },
                  }}
                  onChange={(val) => {
                    _this.setState({
                      [`${"sort_filter_"}${"top5_stats"}`]: val,
                    });
                  }}
                  _this={_this}
                  state_name={"top5_stats"}
                />
              </UI.Row>
                
            </UI.Row>

            {!isLoading && (
              <CustomPieChart
                ref={(pie) => {
                  this.pie = pie;
                }}
                data={[
                  {
                    labelColor:UI.colors.secondary,
                    color: "#94E9FF",
                    label: this.state.top5Advocacies[0]?.title,
                    count: this.state.top5Advocacies[0]?.count,
                    // count: 15,
                    onSelect: (item) => {},
                  },
                  {
                    color: "#48DAFF",
                    label: this.state.top5Advocacies[1]?.title,
                    count: this.state.top5Advocacies[1]?.count,
                    // count: 10,
                    onSelect: (item) => {},
                  },
                  {
                    color: "#0079EB",
                    label: this.state.top5Advocacies[2]?.title,
                    count: this.state.top5Advocacies[2]?.count,
                    // count: 10,
                    onSelect: (item) => {},
                  },
                  {
                    color: "#0064C3",
                    label: this.state.top5Advocacies[3]?.title,
                    count: this.state.top5Advocacies[3]?.count,
                    // count: 10,
                    onSelect: (item) => {},
                  },
                  {
                    color: "#004F99",
                    label: this.state.top5Advocacies[4]?.title,
                    count: this.state.top5Advocacies[4]?.count,
                    // count: 10,
                    onSelect: (item) => {},
                  },
                ]}
                _this={_this}
                id={"user_stats"}
              />
            )}

            <View
              style={{
                alignSelf: "center",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                padding: 20,
              }}
            >
              <UI.Row
                breakpoint={415}
                basis={user_stats_container}
                style={{ flex: 1, justifyContent: "space-around" }}
                _this={_this}
              >
                <Legend color={"#94E9FF"} text={"1st"} />
                {UI.box(5)}
                <Legend color={"#48DAFF"} text={"2nd"} />
                {UI.box(5)}
                <Legend color={"#0079EB "} text={"3rd"} />
                {UI.box(5)}
                <Legend color={"#0064C3"} text={"4th"} />
                {UI.box(5)}
                <Legend color={"#004F99"} text={"5th"} />
              </UI.Row>
            </View>
          </View>

          {/* END OF LEFT PIE CHART */}

          {UI.box(20)}

          {/* START OF RIGHT PIE CHART */}

          <View
            id={"user_stats_container"}
            style={{ ...styles.main_content_container, flex: 1, padding: 0, marginTop:20 }}
          >
            <UI.Row
              breakpoint={420}
              _this={_this}
              basis={user_stats_container}
              style={{ width: "100%", padding: 20 }}
            >
              {UI.box(10)}
              <UI.Row breakpoint={1170} _this={_this} style={{ width: "100%" }}>
                <Text style={styles.box_header_text}>Participants</Text>
                {UI.box(10)}
                <Layout.SortFilter
                  btn_1={{
                    text: "Total",
                    onClick: () => {
                      this.getParticipants('total')
                    },
                  }}
                  btn_2={{
                    text: "New",
                    onClick: () => {
                      this.getParticipants('new')
                    },
                  }}
                  onChange={(val) => {
                    _this.setState({
                      [`${"sort_filter_"}${"participants"}`]: val,
                    });
                  }}
                  _this={_this}
                  state_name={"participants"}
                />
              </UI.Row>
            </UI.Row>

            {!isLoading && (
              <View
                style={{ alignSelf: "center", height: 320, width: 320, position: 'relative', zIndex:3 }}
              >
                <div className="inset-0" style={{position:'absolute', display:'flex', justifyContent:'center', alignItems:'center', zIndex:-1}}>
                  <View>
                    <Text
                      style={{
                        fontSize: 60,
                        color: this.state.color,
                        fontWeight: "bold",
                        marginTop: -20,
                        textAlign:'center'
                      }}
                    >
                      {this.state.count ? this.state.count : this.state.participantsData[0]?.count}
                    </Text>
                    <Text
                      style={{
                        fontSize: 16,
                        color: this.state.color,
                        fontWeight: "bold",
                        marginTop: -20,
                      }}
                    >
                      {this.state.label ? this.state.label : this.state.participantsData[0]?.label}
                    </Text>
                  </View>
                </div>
                <Doughnut
                  ref={(pie) => {
                    this.pie = pie;
                  }}
                  data={{
                    labels: labels,
      
                    datasets: [
                      {
                        label: "Pie Graph",
                        data: datasets,
                        backgroundColor: colors,
                        borderWidth: 0,
                      },
                    ],
                  }}
                  plugins={[ChartDataLabels]}
                  options={{
                    cutout: 100,
                    radius: 140,
                    plugins: {
                      filler: {
                        propagate: true,
                      },
                      legend: {
                        display: false,
                      },
      
                      tooltip: {
                        enabled: false,
                        position: "average",
                        external: externalTooltipHandlers,
                      },
                      datalabels: {
                        formatter: (value, ctx) => {
                          return value;
                        },
                        textStrokeWidth: 0.2,
                        color: "#fff",
                      },
                    },
      
                    datasets: {
                      doughnut: {
                        hoverBorderWidth: 6,
                        hoverBorderColor: this.state.color,
                        hoverBackgroundColor: this.state.color,
                      },
                    },
                  }}
                />
              </View>
            )}

            <View
              style={{
                alignSelf: "center",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                padding: 20,
              }}
            >
              <UI.Row
                breakpoint={415}
                basis={user_stats_container}
                style={{ flex: 1, justifyContent: "space-around" }}
                _this={_this}
              >
                <Legend color={"#00CBFF"} text={"Registered Patients"} />
                {UI.box(5)}
                <Legend color={"#0064C3"} text={"Registered Doctors"} />
              </UI.Row>
            </View>

          </View>

          {/* END OF RIGTH PIE CHART*/}

          {UI.box(20)}

        </UI.Row>

        {/* START OF LINE CHART */}

        <UI.Row breakpoint={1070} _this={_this} style={{ width: "100%" }}>

          <View
            id={"user_stats_container"}
            style={{ ...styles.main_content_container, flex: 1, padding: width > 800 ? 20 : 0, marginTop:20 }}
          >
            <UI.Row
              breakpoint={420}
              _this={_this}
              basis={user_stats_container}
              style={{ width: "100%"
              // , padding: 20 
              }}
            >
              {UI.box(10)}
              <UI.Row breakpoint={1170} _this={_this} style={{ width: "100%", justifyContent:'space-between' }}>

              <View style={{flexDirection:'row', alignItems:'center'}}>
                <Text style={styles.box_header_text}>Registration Stats</Text>
                {UI.box(20)}
                <Legend color={"#48DAFF"} text={"Patient"} />
                  {UI.box(5)}
                <Legend color={"#004F99"} text={"Doctor"} />
              </View>
          
              <View style={{flexDirection:'row', alignItems:'center'}}>
                <Image
                  height={20}
                  width={20}
                  tintColor={UI.colors.primary}
                  source={UI.LEFT_ARROW_1}
                />
                {UI.box(20)}
                <Image
                  height={20}
                  width={20}
                  tintColor={UI.colors.unset}
                  source={UI.RIGHT_ARROW_1}
                />
                {UI.box(20)}
                
                <Text style={styles.box_header_text}>2022</Text>
                
                {UI.box(20)}
                <Layout.SortFilter
                  btn_1={{
                    text: "Year",
                    onClick: (text) => {
                    },
                  }}
                  btn_2={{
                    text: "Month",
                    onClick: (text) => {
                    },
                  }}
                  btn_3={{
                    text: "Day",
                    onClick: (text) => {
                    },
                  }}
                  onChange={(val) => {
                    _this.setState({
                      [`${"sort_filter_"}${"site_stats"}`]: val,
                    });
                  }}
                  _this={_this}
                  state_name={"site_stats"}
                />
              </View>
              
            </UI.Row>
              
            </UI.Row>

            {!isLoading && (
              <View
                style={{ alignSelf: "center", height: "95%", width: '95%' }}
              >
                <Line
                data={{
                  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                  datasets: [
                    {
                      // // label: "First dataset",
                      data: [0,0,0,0,datasets[0],datasets[1],0,0,0,0,0,0],
                      fill: true,
                      backgroundColor: colors,
                      // borderColor: "rgba(75,192,192,1)"
                    },
                  ]
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },           
                  },
                  responsive: true,
                  title: {
                    display: true,
                    text: 'Chart.js Line Chart'
                  },
                  tooltips: {
                    mode: 'label'
                  },
                  hover: {
                    mode: 'dataset'
                  },
                }}
                />
              </View>
            )}

            {UI.box(20)}

          </View>
        </UI.Row>

        {/* END OF LINE CHART */}


        <View
          id={"user_stats_container"}
          style={{  display:'flex',
          flexDirection: width > 800 ? 'row' : 'column',
          alignItems: "center",
          backgroundColor:'white',
          padding: "15px 20px",
          borderRadius: 5,
          ...Shadow._3(),
          justifyContent:'space-between',
          marginTop:20
          }}
        >
          <Text style={{color:UI.colors.secondary, fontWeight:600, fontSize:'19px'}}>Survey Response</Text>
          <View style={{flexDirection: width > 800 ? 'row' : 'column', alignItems:'center'}}>
          <Layout.SelectPicker
              _this={_this}
              // title={"Campaign Name"}
              isRequired={false}
              state_name="advocacy_id"
            >
              {/* <option value="">Filter By Campaign Name</option> */}
              {this.state.advocacies?.map((item) => {
                return item.status === "Active" ? (
                  <option value={item._id}>{item.title}</option>
                ) : (
                  <View></View>
                );
              })}
            </Layout.SelectPicker>
     
            {UI.box(10)}
            <View
              style={{
                alignSelf: width > 950 ? "flex-start" : "center",
                
                marginTop: 8,
                marginLeft: 10,
              }}
            >
              <Layout.Btn
                color={UI.colors.primary}
                text={"Download .csv File"}
                onClick={() => {
                  this.export_to_csv_survey();
                }}
              />
            </View>
          </View>
        </View>

      </MainLayout>
    );
  }
}

const Legend = (props = { text: "", color: "" }) => {
  return (
    <View style={styles.legend_container}>
      <View
        style={{ ...styles.dot_color, backgroundColor: props.color }}
      ></View>
      <Text style={styles.legend_text}>{props.text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  main_content_container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    flex: 1,
  },
  legend_text: {
    color: "#535353",
    fontWeight: "bold",
    fontSize: 12,
    marginLeft: 10,
  },
  legend_container: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  dot_color: {
    height: 16,
    width: 16,
    borderRadius: 10,
    backgroundColor: UI.colors.primary,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});