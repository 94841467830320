import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import "./css.css";

const context = "voucher";

export default class VoucherCampaignEdit extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Campaign List",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
      id:"",
      title: "",
      description:"",
      status:"",
      creator_name:"",
    });
  }

  onCreate = () => {
    this.load_data();
  };

  load_data = async () => {
    const data = await mem.get("edit_campaign");
    if (data) {
      let campaign = JSON.parse(data);

      const {
        _id,
        title,
        description,
        status,
      } = campaign;

      this.setState({
        id: _id,
        status: status,
      });

      UI.set_input_value("title", title ?? "");
      UI.set_input_value("description", description ?? "");
      UI.set_select_value("status", status === true ? "Active" : "Inactive" ?? "");
    }
  };


  submit = async () => {
    UI.clear_errors();

    let {
      title,
      description,
      status
    } = this.state;

    if (!title) {
      await UI.error_form("title", "Title is required.");
    }
    const error_count = this.state.error_count;
    if (error_count > 0) {
      this.hide_loading();
      return;
    }

    this.show_loading();

    UI.webRequest({
      method: "put",
      url: "admin/campaigns/" + this.state.id,
      params: {
        title: title,
        description: description,
        status: status === "Active" ? true : false,
      },
      onFail: async (err) => {
        this.show_modal(
          <Failed description={err.data.message} onDismiss={this.hide_modal} />
        );
      },
      onSuccess: async (response) => {
        this.show_modal(
          <Success
            descriptionCenter={"Successfully edited the campaign."}
            onDismiss={() => {
              UI.clear_forms();
              this.setState({
                title: "",
              });
              this.hide_modal();
              UI.goTo("/voucher/campaign-list");
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;

    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Edit Campaign
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to complete registration
            </Text>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Campaign Title"}
              placeholder={"Enter Campaign Title"}
              state_name={"title"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.title}
            />
            {/* {UI.box(10)}
            <InputForm
              _this={_this}
              title={"Name of Creator"}
              placeholder={"Enter Name of Creator"}
              state_name={"creator_name"}
              style={{ flex: 1 }}
              isRequired={true}
              maxLength={UI.Constants.limits.title}
            /> */}
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Status"}
              isRequired={true}
              state_name={"status"}
            >
              <option value="">Select Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
            {/* <View style={{ flex: 1 }}></View>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View> */}
          </UI.Row>

          {UI.box(20)}
           <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"Description"}
              placeholder={"Enter Description Here"}
              state_name={"description"}
              style={{ flex: 1 }}
              height={100}
              isRequired={false}
              type={"big"}
              maxLength={UI.Constants.limits.description}
            />
          </UI.Row>

          {UI.box(20)}

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"EDIT CAMPAIGN"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});
