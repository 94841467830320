import { 
  // Checkbox,
   TimePicker 
  } from "antd";
import moment from "moment";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  mem,
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
// import APIConstants from "../../shared/modules/APIConstants";
// import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";

const context = "advocacy";
// const Constants = new APIConstants();

export default class EditSchedule extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Advocacy List",
      is_drawer_open: false,
      title:"",
      start_date:"",
      start_date_date:"",
      end_date:"",
      end_date_date:"",
      promotion_code:"",
      status:"",
      time_limit:"",
      description:"",
      healthpass:"",
      comming_soon:false,
      context: context,
      schedule: [],

      image: null,
      image_file: null,
      did_upload: false,
      healthpassData:[]
    });
  }

  submit = async () => {
    const {
      advocacyId,
      schedule
    } = this.state;
    this.show_loading();

    UI.webRequest({
      method: "put",
      url: "admin/advocacies/update-schedule",
      params: {
        advocacyId:advocacyId,
        schedule: schedule
      },
      onFail: async (err) => {
        const data = err.data;
        const error_message = data.error;
        this.show_modal(
          <Failed
            title={"Failed"}
            description={error_message}
            onDismiss={() => {
              this.hide_modal();
            }}
          />
        );
      },
      onSuccess: async (response) => {
        this.show_modal(
          <Success
            title={"Success!"}
            descriptionCenter={"Successfully updated the advocacy."}
            onDismiss={() => {
              this.hide_modal();
              // const data = UI.extract_data(response);
              // mem.save("view_advocacy", JSON.stringify(data.data));
              // mem.save("advocacy_id", data.data._id);
              UI.goTo("/advocacy/list")
            }}
          />
        );
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  onCreate = async () => {
    const data = mem.get("edit_schedule");
    const schedule = JSON.parse(data);

    this.setState({schedule: schedule.advocacyschedulecms, advocacyId: schedule._id})
  };

  onChangeTime = (time, timeString, res, session, index, isStart=false) => {

    if (session === "morning") {
      if(timeString.includes('pm') && !timeString.includes('12')) {
        this.show_modal(<Failed
          title={"Failed"}
          description={"Invalid Time Format"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />)
      } else {
        const advocacySchedule = this.state.schedule.map((res, i) => {
          if(index === i) {
            if(isStart) {
              res.morning_end_time = timeString
            } else {
              res.morning_start_time = timeString
            }
          }
          return res
        })
        this.setState({schedule: advocacySchedule})
      }
      return
    } 

    if (session === "afternoon") {
      if(timeString.includes('am') && !timeString.includes('12')) {
        this.show_modal(<Failed
          title={"Failed"}
          description={"Invalid Time Format"}
          onDismiss={() => {
            this.hide_modal();
          }}
        />)
      } else {
        const advocacySchedule = this.state.schedule.map((res, i) => {
          if(index === i) {
            if(isStart) {
              res.afternoon_end_time = timeString
            } else {
              res.afternoon_start_time = timeString
            }
          }
          return res
        })
        this.setState({schedule: advocacySchedule})
      }
      return
    }

    // const advocacySchedule = this.state.schedule.map((res, i) => {
    //   if(index === i) {
    //     if (session === "morning") {
    //       if(isStart) {
    //         res.morning_end_time = timeString
    //       } else {
    //         res.morning_start_time = timeString
    //       }
    //     }
    //     if (session === "afternoon") {
    //       if(isStart) {
    //         res.afternoon_end_time = timeString
    //       } else {
    //         res.afternoon_start_time = timeString
    //       }
    //     }
    //   }
    //   return res
    // })


    // this.setState({schedule: advocacySchedule})
  }

  setDefaultValueTime = (time, session) => {
    const format24Hours = moment(time, ["h:mm a"]).format('HH:mm')
    
    const m = moment(new Date());
    const minutes = format24Hours.split(":")[1];
    const hourFomatTo24Hours = format24Hours.slice(0, 2);
    m.set({ h: hourFomatTo24Hours, m: parseFloat(minutes) });
    return moment(m);

  }

  render() {
    const _this = this;


    return (
      <MainLayout context={context} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Edit Schedule
            </Text>

            <Text style={styles.text_2}>
              Update advocacy information here
            </Text>
          </UI.Row>
          {UI.box(20)}

          <View id="advocacy_schedule" style={{ padding: "10px 30px 0px 0px" }} _this={_this}>
            <UI.Row style={{ padding: "0px 10px", gap: 50 }} breakpoint={1053} _this={_this}>
            {
                this.state.schedule?.map((res, index) => {
                  
                  console.log(moment(res.morning_start_time))
                  return (
                    <View>
                      <span className="font-semibold" style={{fontSize: 16}}>{res.date}</span>
                      {UI.box(10)}
                      <Text style={{color:UI.colors.primary}}>Morning</Text>
                      {UI.box(5)}
                      <TimePicker 
                        value={!res.morning_start_time ? "" : this.setDefaultValueTime(res.morning_start_time, 'morning')}
                        status="warning" 
                        minuteStep={30} 
                        use12Hours 
                        format="h:mm a" 
                        placeholder="Start Time"
                        onChange={(time, timeString) => { 
                        this.onChangeTime(time, timeString, res, "morning", index);
                      }}/>
                      {UI.box(10)}
                      {UI.box(5)}
                      <TimePicker 
                        value={!res.morning_end_time ? "" : this.setDefaultValueTime(res.morning_end_time, 'morning')}
                        status="warning" 
                        minuteStep={30} 
                        use12Hours 
                        format="h:mm a" 
                        placeholder="End Time"
                        onChange={(time, timeString) => { 
                        const isStart = true;
                        this.onChangeTime(time, timeString, res, "morning", index, isStart);
                      }}/>
                      {UI.box(10)}
                      <Text style={{color:UI.colors.primary}}>Afternoon</Text>
                      {UI.box(5)}
                      <TimePicker 
                        value={!res.afternoon_start_time ? "" : this.setDefaultValueTime(res.afternoon_start_time, 'afternoon')}
                        status="warning" 
                        minuteStep={30} 
                        use12Hours 
                        format="h:mm a" 
                        placeholder="Start Time"
                        onChange={(time, timeString) => { 
                        // const id = 1;
                        this.onChangeTime(time, timeString, res, "afternoon",  index);
                      }}/>
                      {UI.box(10)}
                      {UI.box(5)}
                      <TimePicker
                        value={!res.afternoon_end_time ? "" : this.setDefaultValueTime(res.afternoon_end_time, 'afternoon')}
                        status="warning" 
                        minuteStep={30} 
                        use12Hours 
                        format="h:mm a" 
                        placeholder="End Time"
                        onChange={(time, timeString) => { 
                        const isStart = true;
                        this.onChangeTime(time, timeString, res, "afternoon",  index, isStart);
                      }}/>
                    </View>
                  )
                })
              }
            </UI.Row>
          </View>

          {UI.box(20)}

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"UPDATE CHANGES"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />

            {UI.box(20)}

          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
});