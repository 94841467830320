import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import InputForm from "../_components/InputForm";
import Layout from "../_components/Layout";
import MainLayout from "../_components/MainLayout";
import { Failed, Success } from "../_components/Modal";
import "./css.css";
const context = "admin";

export default class AdminAddFAQItem extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "FAQs",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      data: ["1"],
      context: context,
      categories_list: [],

      faqCategoryId: "",

      question: "",
      answer: "",
    });
  }

  onCreate = () => {
    this.get_faq_categories();
  };

  get_faq_categories = async () => {
    UI.webRequest({
      method: "get",
      url: "admin/cms/faq-categories",
      params: {},
      onFail: (err) => {},
      onSuccess: (response) => {
        const data = UI.extract_data(response);
        this.setState({
          categories_list: data,
        });
      },
      onFinish: async () => {},
    });
  };

  submit = async () => {
    UI.clear_errors();
    const { faqCategoryId, question, answer, status } = this.state;

    if (!faqCategoryId) {
      await UI.error_form("faqCategoryId", "FAQ Category is required.");
    }
    if (!question) {
      await UI.error_form("question", "FAQ Question is required.");
    }
    if (!answer) {
      await UI.error_form("answer", "FAQ Answer is required.");
    }

    if (!answer) {
      await UI.error_form("status", "Status is required.");
    }

    const error_count = this.state.error_count;
    if (error_count > 0) {
      return;
    }

    this.show_loading();

    UI.webRequest({
      method: "post",
      url: "admin/cms/faqs",
      params: {
        faqCategoryId: faqCategoryId,
        question: question,
        answer: answer,
        status: status,
      },
      onFail: (err) => {
        const data = err.data;
        const error_message = data.error;
        if (data.success === false) {
          this.show_modal(
            <Failed
              title={"Failed"}
              description={error_message}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        }
      },
      onSuccess: (response) => {
        if (response.status < 400) {
          UI.clear_forms();
          this.setState({
            question: "",
            answer: "",
          });
          UI.set_select_value("status", "");
          UI.set_select_value("faqCategoryId", "");
          this.show_modal(
            <Success
              title={"Success!!!"}
              description={"Successfully Added the FAQ Item"}
              onDismiss={() => {
                this.hide_modal();
              }}
            />
          );
        }
      },
      onFinish: async () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this;
    // const { height, width, isMobile } = _this.state;

    const Categories = this.state.categories_list.map((item) => {
      return <option value={item.id}>{item.name}</option>;
    });

    return (
      <MainLayout context={context} additional={"faq"} _this={_this}>
        <View style={styles.main_box_content}>
          <UI.Row _this={_this} breakpoint={920} style={{ width: "100%" }}>
            <Text style={{ ...styles.box_header_text, flex: 1 }}>
              Add FAQ Item
            </Text>

            <Text style={styles.text_2}>
              Enter the required information below to add item
            </Text>
          </UI.Row>
          {UI.box(20)}

          <UI.Row breakpoint={970} _this={_this}>
            <Layout.SelectPicker
              _this={_this}
              state_name={"faqCategoryId"}
              onChange={(value) => {
                this.setState({
                  faqCategoryId: value,
                });
              }}
              title={"FAQ Catergory"}
              isRequired={false}
            >
              <option value="">Select FAQ Category</option>
              {Categories}
            </Layout.SelectPicker>
            {UI.box(10)}
            <Layout.SelectPicker
              _this={_this}
              title={"Item Status"}
              isRequired={false}
              state_name={"status"}
            >
              <option value=""></option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Layout.SelectPicker>
            {UI.box(10)}
            <View style={{ flex: 1 }}></View>
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"FAQ Question"}
              placeholder={"Enter Question Here"}
              state_name={"question"}
              style={{ flex: 1 }}
              isRequired={false}
              maxLength={UI.Constants.limits.description}
            />
          </UI.Row>
          {UI.box(20)}
          <UI.Row breakpoint={970} _this={_this}>
            <InputForm
              _this={_this}
              title={"FAQ Answer"}
              placeholder={"Enter Answer Here"}
              state_name={"answer"}
              style={{ flex: 1 }}
              height={100}
              isRequired={false}
              type={"big"}
              maxLength={UI.Constants.limits.description}
            />
          </UI.Row>
          {UI.box(20)}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Layout.Btn
              color={UI.colors.primary}
              onClick={() => {
                this.submit();
              }}
              text={"ADD ITEM"}
            />
            {UI.box(20)}

            <Layout.Btn
              color={"#E00000"}
              onClick={() => {
                UI.goBack();
              }}
              text={"CANCEL"}
            />
          </View>
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  text_2: {
    fontSize: 14,
    color: "#D6D6D6",
  },
  no_info_container: {
    paddingTop: 50,
    paddingBottom: 50,
    alignItems: "center",
  },
  search_btn: {
    height: 35,
    width: 35,
    justifyContent: "center",
    alignItems: "center",
    marginRight: -5,
  },
  entry_header: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#2E2E2E",
  },
  main_container_header: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    padding: 20,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
