import React from "react";
import styled from "styled-components";
import { Input } from "antd";

const InputField = React.memo((props) => {
  return <StyledInput {...props} />;
});

export default InputField;

const StyledInput = styled(Input)`
  height: 60px;
  border-radius: 12px;
  border: 2px solid #cacaca;

  font-size: 20px !important; // 20px
  font-family: Poppins;
  font-weight: 500; //medium
  line-height: 1.25rem; // 23px
  letter-spacing: 0px;

  :disabled {
    color: #222222 !important;
    font-size: 1.25rem !important; // 20px
    font-family: Poppins;
    line-height: 1.25rem; // 23px
    font-weight: 500; //medium
    letter-spacing: 0px;
  }
`;
