import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UI from "../../shared/Components/UI/js";
import {
  Shadow,
  StyleSheet,
  Text,
  View,
} from "../../shared/custom-react-native";
import CustomPieChart from "../_components/CustomPieChart";
import MainLayout from "../_components/MainLayout";
import "./css.css";
import Statistics from "./Statistics";

const context = "voucher";

export default class VoucherDashboard extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      active_tab: "Dashboard",
      is_drawer_open: false,
      selected: null,
      current_page: 1,
      context: context,
      item: {},

      total_active_vouchers: 0,
      total_available_vouchers: 0,
      total_voucher_quantity: 0,
      total_voucher_used: 0,

      isLoading: true,
    });
  }

  onCreate = async () => {
    this.show_loading();
    this.setState({
      isLoading: true,
    });
    UI.webRequest({
      method: "get",
      url: "admin/vouchers",
      params: {},
      onFail: async (err) => {},
      onSuccess: async (response) => {
        const data = UI.extract_data(response);
        this.process_data(data.data);
        if (data.length === 0) {
          this.setState({
            isLoading: false,
          });
        }
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  process_data = async (data = []) => {
    this.setState(
      {
        total_active_vouchers: 0,
        total_available_vouchers: 0,
        total_voucher_quantity: 0,
        total_voucher_used: 0,
      },
      () => {
        setTimeout(() => {
          // let test = data.map((item, index) => {
          //   this.setState({
          //     total_active_vouchers: this.state.total_active_vouchers + 1,
          //     total_available_vouchers:
          //       this.state.total_available_vouchers + item.availableQuantity,
          //     total_voucher_quantity:
          //       this.state.total_voucher_quantity + (item.initialQuantity ?? 0),
          //     total_voucher_used:
          //       this.state.total_voucher_used + item.usedQuantity,
          //   });

          //   return null;
          // });
          this.setState({
            isLoading: false,
          });
        }, 100);
      }
    );
  };

  render() {
    const _this = this;
    const {
      // height,
      // width,
      // isMobile,
      // item,
      total_voucher_quantity,
      total_voucher_used,
      isLoading,
    } = _this.state;
    // let add_style = {};
    // if (width <= 470) {
    //   add_style = { flexDirection: "row" };
    // }

    let user_stats_container = UI.measure("user_stats_container").width;

    return (
      <MainLayout context={context} _this={_this}>
        <UI.Row _this={this} breakpoint={1175}>
          <Statistics _this={_this} />
          {UI.box(20)}

          <UI.Row breakpoint={1070} _this={_this} style={{ width: "100%" }}>
            <View
              id={"user_stats_container"}
              style={{ ...styles.main_content_container, flex: 1, padding: 0 }}
            >
              <UI.Row
                breakpoint={420}
                _this={_this}
                basis={user_stats_container}
                style={{ width: "100%", padding: 20 }}
              >
                <Text style={styles.box_header_text}>Voucher Statistics</Text>
                {UI.box(10)}
              </UI.Row>

              {!isLoading && (
                <CustomPieChart
                  ref={(pie) => {
                    this.pie = pie;
                  }}
                  data={[
                    {
                      color: "#0064C3",
                      label: "Total Voucher Count",
                      count: total_voucher_quantity,
                      onSelect: (item) => {},
                    },
                    {
                      color: "#004F99",
                      label: "Total Voucher Used",
                      count: total_voucher_used,
                      onSelect: (item) => {},
                    },
                  ]}
                  _this={_this}
                  id={"voucher_stats"}
                />
              )}

              <View
                style={{
                  alignSelf: "center",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  padding: 20,
                }}
              >
                <UI.Row
                  breakpoint={415}
                  basis={user_stats_container}
                  style={{ flex: 1, justifyContent: "space-around" }}
                  _this={_this}
                >
                  <Legend color={"#0064C3"} text={"Total Voucher Count"} />
                  {UI.box(5)}
                  <Legend color={"#004F99"} text={"Total Voucher Used"} />
                </UI.Row>
              </View>
            </View>
          </UI.Row>
        </UI.Row>
      </MainLayout>
    );
  }
}

const Legend = (props = { text: "", color: "" }) => {
  return (
    <View style={styles.legend_container}>
      <View
        style={{ ...styles.dot_color, backgroundColor: props.color }}
      ></View>
      <Text style={styles.legend_text}>{props.text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  main_content_container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    flex: 1,
  },
  legend_text: {
    color: "#535353",
    fontWeight: "bold",
    fontSize: 12,
    marginLeft: 10,
  },
  legend_container: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  dot_color: {
    height: 16,
    width: 16,
    borderRadius: 10,
    backgroundColor: UI.colors.primary,
  },
  main_box_content: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
  },
  box_header_subtitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#C9C9C9",
    alignSelf: "flex-end",
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  header_2: {
    color: "#535353",
    fontSize: 14,
    fontWeight: "bold",
  },
  fade_header: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#B7B7B7",
  },
  main_header: {
    color: UI.colors.primary,
    fontSize: 25,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  earnings_container: {
    borderRadius: 5,
    backgroundColor: "white",
    padding: 20,
    ...Shadow._3(),
    flexDirection: "row",
    maxHeight: 180,
  },
});

// borderRadius: 5, backgroundColor: "white", padding: 10, ...Shadow._3()
