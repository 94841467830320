import Icon from "@ant-design/icons";
import React from "react";

const FieldBMISvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.039"
    height="17.486"
    viewBox="0 0 22.039 17.486"
  >
    <path
      id="mbi"
      d="M259.7,202.329V191.073a2.8,2.8,0,0,1,3.143-3.115h15.752a2.8,2.8,0,0,1,3.144,3.115v11.256a2.8,2.8,0,0,1-3.144,3.116H262.839A2.8,2.8,0,0,1,259.7,202.329Zm18.719,1.048a1.116,1.116,0,0,0,1.252-1.261v-4.534H277.85l-1.011,3.208a.7.7,0,0,1-1.307.019l-1.891-6.092-.992,2.328a.74.74,0,0,1-.752.537H269.9l-1.02,3.208a.7.7,0,0,1-1.3.019l-1.891-6.082-.945,2.318a.758.758,0,0,1-.76.537h-2.225v-1.362h1.78l1.52-3.709a.686.686,0,0,1,1.28-.019l1.891,5.7.51-1.428a.725.725,0,0,1,.752-.547h2l1.512-3.709a.692.692,0,0,1,1.288-.019l1.882,5.7.519-1.428a.716.716,0,0,1,.752-.547h2.216v-4.933a1.117,1.117,0,0,0-1.252-1.261h-15.4a1.113,1.113,0,0,0-1.252,1.261v10.829a1.113,1.113,0,0,0,1.252,1.261Z"
      transform="translate(-259.696 -187.958)"
      fill="#4fc2ed"
    />
  </svg>
);

const FieldBMIIcon = (props) => {
  return <Icon component={FieldBMISvg} {...props} />;
};

export default FieldBMIIcon;
