import React, { Component } from "react";
import UI from "../../shared/Components/UI/js";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import StartupLayout from "../_components/StartupLayout";
import "./css.css";
// import InputForm, { Checkbox } from "../_components/InputForm";
const context = "finance";
export default class FinanceLogin extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      remember_me: false,
      context: context,
    });
  }

  authenticate = async () => {
    UI.api.azure_b2c_login();
  };

  render() {
    // **Commented out, currently not used
    // const { width, height, isMobile } = this.state;
    // const _this = this;
    return (
      <StartupLayout context={context} image={UI.BG_SIGNIN} _this={this}>
        <ScrollView>
          <View style={styles.content_container}>
            <Text style={styles.header_1}>Welcome to mWell!</Text>
            {UI.box(20)}
            <Text style={styles.header_2}>Please Sign-in to your account.</Text>
            {UI.box(20)}

            <TouchableOpacity
              onClick={() => {
                this.authenticate();
              }}
              style={styles.blue_btn}
            >
              <Text style={styles.blue_text}>SIGN IN</Text>
            </TouchableOpacity>
            {UI.box(25)}
          </View>
        </ScrollView>
      </StartupLayout>
    );
  }
}

const styles = StyleSheet.create({
  blue_text: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
  blue_btn: {
    width: "100%",
    height: 40,
    backgroundColor: UI.colors.secondary,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 360,
    alignSelf: "center"
  },
  header_2: {
    fontSize: 14,
    color: UI.colors.black,
    fontWeight: "bold",
  },
  header_1: {
    fontSize: 20,
    color: UI.colors.primary,
    fontWeight: "bold",
  },
  content_container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },
});
